import React from "react";

import "./../../SCSS/MobilePages/_mobileProductGridSec.scss";

import MobileGridViewProduct from "../../Components/ProductType/MobileGridViewProduct";

import { useTranslation } from "react-i18next";
import { titleChange } from "../../Components/helpers/utils/titleNameChange";

function Mobile_Product_List_Grid({
  filteredProductsData,
  updateCompareProduct,
  handleChangeCartPopup,
  outOfStockConfiguration
}) {
  const { t } = useTranslation();

  const availablityData = filteredProductsData?.availablityData || []

  return (
    <>
      <div className="row d-flex">
        {(filteredProductsData?.total_count?.value || filteredProductsData?.total_count) !== 0 &&
          filteredProductsData?.items?.length !== 0 ? (
          filteredProductsData?.items?.map((product, productIndex) => {
            return (
              <div className="col-6 mb-4" key={titleChange(product.name)}>
                <MobileGridViewProduct
                  updateCompareProduct={updateCompareProduct}
                  product={product}
                  availablityData={availablityData}
                  key={titleChange(product.name)}
                  handleChangeCartPopup={handleChangeCartPopup}
                  outOfStockConfiguration={outOfStockConfiguration}
                  productIndex={productIndex}
                  screenName="Product List"
                />
              </div>
            );
          })
        ) : (
          <div className="PLP_noProduct_box">
            <h1>{t("No_Products_Available")}</h1>
          </div>
        )}
      </div>
    </>
  );
}
export default Mobile_Product_List_Grid;
