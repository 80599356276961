import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./../../SCSS/MobileView/_mobileMainFeatureProductsBlock.scss";
import Heading7 from "../Font/Heading7";
import RatingBlock from "../MostSharedComponent/RatingBlock";
import OldPrice from "../Font/OldPrice";
import Price from "../Font/Price";
import empty_favourite from "./../../assets/Icon/empty_favourite.svg";
import fulfill_favourite from "./../../assets/Icon/fulfill_favourite.svg";
import { useDispatch, useSelector } from "react-redux";
import AddToCart from '../Buttons/AddToCart';

import {
  loadAddToWishlist,
  loadCartData,
  loadDeleteFromWishlist,
  loadWishlistData,
} from "../../redux/appAction";
import { useTranslation } from "react-i18next";
import { addToCart } from "../../services/cart.service";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import FeatureOptionBox from "../MostSharedComponent/FeatureOptionBox";
import TamaraLogoBox from "../MostSharedComponent/TamaraLogoBox";
import { checkWishList } from "../helpers/utils/wishListHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { checkoutEvents } from "../helpers/utils/ga/checkoutEvents";
import LazyImageForProduct from "../MostSharedComponent/LazyImageForProduct";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";
import SoldOutLabel from "../MostSharedComponent/SoldOutLabel";
import ProductDeliveryType from "../MostSharedComponent/ProductDeliveryType";
import { impressionEvents } from "../helpers/utils/ga/impressionEvents";
import HomeVariantOptions from "./HomeVariantOptions";
import SubscribeSection from "../Meza/SubscribeSection";
import SubsSectionContent from "../Meza/SubsSectionContent";

function MobileMainFeatureProduct({
  product,
  handleChangeCartPopup,
  featureOptionsShow,
  outOfStockConfiguration,
  productIndex,
  screenName,
  availablityData,
  customerDetails,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { setCartErr } = useCartErrorMessage();
  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [count, setCount] = useState(0);
  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);
  const [sizeButtonIndex, setSizeButtonIndex] = useState(0);

  const wishlistData = useSelector((state) => state.appData.wishlistData);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, product?.sku));
  }, []);

  const handleFavourite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isFavourite) {
      setIsFavourite(false);
      setCount(count + 1);
   
    } else {
      setIsFavourite(true);
      setCount(count + 1);
      
    }
  };
  useEffect(() => {
    const data = {
      items: [product?.sku],
    };
    if (isFavourite) {
      if (count > 0) {
        const addToWishlistData = dispatch(loadAddToWishlist(data, t)).then(
          (res) => {
            checkoutEvents.addToWishlist(product, screenName);
            dispatch(loadWishlistData());
          }
        );
      }
    } else {
      if (count > 0) {
        if (!isFavourite) {
          checkoutEvents.removeFromWishlist(product, screenName);
          removeFromWL(product?.sku);
        }
      }
    }
  }, [isFavourite]);

  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };

    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };

  const dispatch = useDispatch();

  const AddProductToCart = (sku) => {
    // impressionEvents.impressionAddToCart(
    //   product, 1,"home"
    // );
    setLoaderOn(true);
    if (product?.variants && product?.variants.length > 0) {
      sku = product?.variants[sizeButtonIndex].sku;
    }

    const data = {
      items: [
        {
          sku: sku,
          id: product?.id,
          qty: 1,
        },
      ],
    };

    addToCart(data)
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id, screenName)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
        setLoaderOn(false);
      })
      .finally(() => {
        setLoaderOn(false);
      });
  };
  const targetPrice = product?.price_rounded;
  const matchedProduct = product?.variants?.filter(product => product.price_rounded === targetPrice);
  const newMatchPrice = matchedProduct?.length > 1 ? matchedProduct?.[0] : matchedProduct?.[0];
  const screenSize = matchedProduct && matchedProduct[0]?.options?.screen_size;
  const seletcedScreenSize = product?.variantOptions?.[0].attribute?.options.filter(product => product.id === screenSize);
  const defaultSku = matchedProduct && matchedProduct[0]?.sku;
  const sizeButtonClick = (sizeIndex) => {
    product.sku = product?.variants[sizeIndex]?.sku;
    product.name = product?.variants[sizeIndex]?.name;
    product.description = product?.variants[sizeIndex]?.description;
    product.price = product?.variants[sizeIndex]?.price;
    product.currency = product?.variants[sizeIndex]?.currency;
    product.price_rounded = product?.variants[sizeIndex]?.price_rounded;
    product.discounted_price = product?.variants[sizeIndex]?.discounted_price;
    product.discounted_price_rounded = product?.variants[sizeIndex]?.discounted_price_rounded;
    product.discounted_price_from = product?.variants[sizeIndex]?.discounted_price_from;
    product.discounted_price_to = product?.variants[sizeIndex]?.discounted_price_to;
    product.is_count_down = product?.variants[sizeIndex]?.is_count_down;
    product.stock = product?.variants[sizeIndex]?.stock;
    product.item_type = product?.variants[sizeIndex]?.item_type;
    product.meza = product?.variants[sizeIndex]?.meza;
    product.qitaf = product?.variants[sizeIndex]?.qitaf;
    product.eligible_for_tabby = product?.variants[sizeIndex]?.eligible_for_tabby;
    product.enable_shipping = product?.variants[sizeIndex]?.enable_shipping;
    product.additional_estimated_day = product?.variants[sizeIndex]?.additional_estimated_day;
    product.current_time = product?.variants[sizeIndex]?.current_time
    setSizeButtonIndex(sizeIndex);
  };
  const stringToCheck = product?.name;
  const regex = /\d+$/;
  const hasAnythingAfterDash = regex.test(stringToCheck);
  const productName = (() => {
    if (!hasAnythingAfterDash && newMatchPrice?.name !== undefined) {
    return newMatchPrice.name;
  }
    return product.name;
  })();
  return (
    <>
      {product &&
        <div className="md__product feature__category"
          onClick={() => {navigate(productUrlHelper(product?.english_name, product?.sku));
            impressionEvents.impressionProductClick(
              "select_item", product
            );
          }}
        >
          <span className="fav_icon d-block d-lg-none">
            <img
              onMouseEnter={() => setIsFavouriteHover(true)}
              onClick={handleFavourite}
              onMouseLeave={() => setIsFavouriteHover(false)}
              className={
                !isFavourite
                  ? "productOne__favourite__icon"
                  : "productOne__favourite__icon__disable"
              }
              width={24}
              height={24}
              src={isFavouriteHover ? fulfill_favourite : empty_favourite}
              alt=""
            />
            <img
              onClick={handleFavourite}
              className={
                isFavourite
                  ? "productOne__favourite__icon"
                  : "productOne__favourite__icon__disable"
              }
              width={24}
              height={24}
              src={fulfill_favourite}
              alt=""
            />
          </span>
          <div className="pro__feature__optionBox">
            {featureOptionsShow === true &&
              <FeatureOptionBox
                options={product?.options}
                color_resolution="color_resolution"
                screen_size="screen_size"
              />
            }
          </div>
          <div className="product__pics__area">
            <Link
              to={productUrlHelper(product?.english_name, product?.sku)}
              className="second__md__product__image__block"
              aria-label={product?.sku}
            >
              <LazyImageForProduct
                product={product}
                // srcSetReSize={MobileImageResize}
                alt={product?.name}
                className="second__md__product__image"
              />
            </Link>
            <SoldOutLabel
              t={t}
              product={product}
              outOfStockConfiguration={outOfStockConfiguration}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center mb-1">
            <div className="plp__tamara__box mb-2">
              <TamaraLogoBox
                product={product}
              />
            </div>
          </div>
          <Link
            className="second__md__product__name featureProduct__name"
            to={productUrlHelper(product?.english_name, product?.sku)}
            aria-label={product?.name}
          >
            <Heading7 marginBottom={10} text={titleChange(productName)} />
          </Link>
          <ProductDeliveryType
            product={product}
            availablityData={availablityData}
            t={t}
          />
          <div className="md__product__rating__block">
            <RatingBlock rating={4.2} totalRatings={2183} />
          </div>
          <div className="size__button__block">
          {product?.variantOptions && (
            <HomeVariantOptions
              sizeButtonClick={sizeButtonClick}
              sizeButtonIndex={sizeButtonIndex}
              variantOptions={product?.variantOptions}
              product={product}
            />
          )}
          {!product?.hide_subscriber_price && (
          <SubscribeSection>
            <SubsSectionContent
              t={t}
              meza_subscriber_price={product?.meza_subscriber_price}
              user={customerDetails}
              discounted_price={product.discounted_price_rounded}
              og_price={product.price_rounded}
            />
          </SubscribeSection>
        )}
        </div>
          <div className="md__product__price__block">
            {
              !product?.is_price_hide &&
              <>
                <OldPrice
                  oldPrice={product?.price_rounded}
                  price={
                    product?.discounted_price_rounded === null
                      ? product?.price_rounded
                      : product?.discounted_price_rounded
                  }
                  size="text3"
                  color="#444444"
                  marginBottom={0}
                  // lineThrough={true}
                  span={true}
                  currency={product?.currency}
                />
                <Price
                  price={
                    product?.discounted_price_rounded === null
                      ? product?.price_rounded
                      : product?.discounted_price_rounded
                  }
                  // marginLeft={5}
                  marginBottom={0}
                  size="heading6"
                  span={true}
                  currency={product?.currency}
                />
              </>
            }
          </div>
          <span
            onClick={e => { e.stopPropagation(); e.preventDefault() }}
          >
            {product?.is_bundle_enable === 1 ? (
              <Link
                style={{ textDecoration: "none" }}
                to={productUrlHelper(product?.english_name, product?.sku)}
                aria-label={product?.name}
              >
                <AddToCart
                  product={product}
                  AddProductToCart={AddProductToCart}
                  outOfStockConfiguration={outOfStockConfiguration}
                  loaderOn={loaderOn || spinnerOn}
                  matchedProduct = {matchedProduct}
                />
              </Link>
            ) : (
              <AddToCart
                product={product}
                AddProductToCart={AddProductToCart}
                outOfStockConfiguration={outOfStockConfiguration}
                loaderOn={loaderOn || spinnerOn}
                matchedProduct = {matchedProduct}
              />
            )}
          </span>

          {/* <div
        className="text-end cart_end_icon"
        onClick={() => AddProductToCart(product?.sku)}
      >
        <img src={shopping_cart} alt="cart" />
      </div> */}
        </div>
      }
    </>
  );
}

export default MobileMainFeatureProduct;
