import React, { useEffect, useState, lazy } from "react";
import { useDispatch, useSelector, useRef } from "react-redux";
import { useQuery } from "react-query";

// import Banner from "../Components/Banner";
import "./../SCSS/_home.scss";
import HomePageCategoryBlock from "../Components/HomePageCategoryBlock";
import RecentlyViewedProducts from "../Components/RecentlyViewedProducts";
import LiveChatPopup from "../Components/Popup/LiveChatPopup";
import { categoryAvailableData, loadConfigurationSettingData, saveConfigurationSettingData, saveHomePageData } from "../redux/appAction";
import NewLoader from "../Components/Common/newloader";
import { useTranslation } from "react-i18next";
import MetaComponent from "../Components/Common/MetaComponent";
import useWindowDimensions from "../Components/helpers/utils/useWindowDimensions";
import ReviewTestFreaks from "./ReviewTestFreaks";
import { defaultImageHelper } from "../Components/helpers/utils/defaultImageHelper";
import { getHomePageData } from "../services/homepage.service";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import OutStockHelper from "../Components/helpers/utils/outStockHelper";
import { get, set, del } from "idb-keyval";
import TealiumTag from "../Components/helpers/utils/tealium";
import MainFeatureProducts from "../Components/MainFeatureProducts";
import DealsOfTheWeekMobile from "../Components/DealsOfTheWeekMobile";
import { loaderEnd, loaderStart } from "../services/services";
import { impressionEvents } from "../Components/helpers/utils/ga/impressionEvents";
import ImageRender from "../Components/ImageRender";

const Banner = lazy(() => import("../Components/Banner"));
const MobileHomePage = lazy(() => import("./MobilePages/Mobile_Home_Page"));
const FeatureProducts = lazy(() => import("../Components/FeatureProducts"));
const ProductContainer = lazy(() => import("../Components/ProductContainer"));
const DealsOfTheWeek = lazy(() => import("../Components/DealsOfTheWeek"))

function Home({ handleChangeCartPopup }) {
  const { width,height } = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [liveChatPopup, setLiveChatPopup] = useState(false);
  const [bannerData, setBannerData] = useState("");
  const [mobBannerData, setMobBannerData] = useState("");
  const [featureProductsData, setFeatureProductsData] = useState("");
  const [recentlyViewedProductsData, setRecentlyViewedProductsData] =
    useState("");
  const [recentlyViewedProductsBanner, setRecentlyViewedProductsBanner] =
    useState("");
  const [newArrivalData, setNewArrivalData] = useState("");
  const [topTrendingData, setTopTrendingData] = useState("");
  const [topRatedData, setTopRatedData] = useState("");
  const [dealsOfTheWeekData, setDealsOfTheWeekData] = useState("");
  const [homePageBottomSingleBanner, setHomePageBottomSingleBanner] =
    useState("");
  const [homePagePlayStationSingleBanner, setHomePagePlayStationSingleBanner] =
    useState("");
  const [
    recentlyViewedProductsSingleBanner,
    setRecentlyViewedProductsSingleBanner,
  ] = useState("");
  const [categorySevenData, setCategorySevenData] = useState("");
  const [outOfStockConfiguration, setOutOfStockConfiguration] = useState({});
  const [isCache, setIsCache] = useState(false)
  const [topTVData, setTopTVData] = useState("");
  const [availablityData,setAvailablityData]= useState([])

  const reduxDispatch = useDispatch();

  useEffect(async () => {
    const queryData = await get("sonyDB")
    setIsCache(queryData?.clientState?.queries?.length > 0)
  }, [])

  useEffect(() => {
    reduxDispatch(loaderStart())
    reduxDispatch(loadConfigurationSettingData()).then(()=>{
      reduxDispatch(loaderEnd())
    })
  }, []);

  const { configurationSettingData } = useSelector((state) => state.appData);
  const { customerDetails } = useSelector((state) => state.customerReducer);

  const currentLanguageCode = localStorage.getItem("i18next") || "en";
  const { data: homepageData = {} } = useQuery(
    ["homepageData", currentLanguageCode],
    () => getHomePageData(currentLanguageCode),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10, // 10 minutes
      staleTime: Infinity
    }
  );


  useEffect(() => {
    if (Object.values(configurationSettingData).length !== 0) {
      reduxDispatch(saveConfigurationSettingData(configurationSettingData))
      const getOutOfStock = configurationSettingData?.out_of_stock_configuration || {};
      setOutOfStockConfiguration(getOutOfStock);
    }
  }, [configurationSettingData]);

  useEffect(() => {
    if(loading){
      reduxDispatch(loaderStart())
    }else{
      reduxDispatch(loaderEnd())
    }
  }, [loading, reduxDispatch])
  useEffect(() => {
    impressionEvents.impressionGlobalVariable(
      currentLanguageCode, "Home Page"
    );
  }, [bannerData]);
  let isPageLoad = false;
  useEffect(async () => {
    if (Object.values(homepageData).length !== 0) {

      reduxDispatch(saveHomePageData(homepageData));

      let getBannerData = homepageData?.content?.filter(
        (data) =>
          data.type === "multiple_banner" &&
          data.element_id === "main_banner_sliders"
      );
      if (getBannerData?.length > 0) {
        setBannerData(homepageData?.content[0]);
      }

      let getMobBannerData = homepageData?.content?.filter(
        (data) =>
          data.type === "multiple_banner" &&
          data.element_id === "main_banner_sliders_mobile_menu"
      );
      if (getMobBannerData?.length > 0) {
        setMobBannerData(getMobBannerData[0]);
      }

      const productData = []
      homepageData?.content?.filter((content) => content?.products).forEach((items) => {
        productData.push(...items.products)
      })
      let getCatStatus2
      if (isCache) {
        getCatStatus2 = await OutStockHelper(reduxDispatch, productData, outOfStockConfiguration)
      } else {
        getCatStatus2 = productData
        const availablityData = await categoryAvailableData(productData && productData.map((item) => item.id))
        setAvailablityData(availablityData?.data || [])
      }
      const updatedHomePageData = homepageData?.content?.map((data) => {
        const newData = { ...data }
        newData.products = data?.products?.map((product) =>
          getCatStatus2.find((item) => item.id === product.id)
        )
        return newData
      })
      setLoading(false);
      reduxDispatch(loaderEnd())
      let getFeatureProductsData = updatedHomePageData?.find(
        (data) =>
          data.type === "slider" && data.element_id === "homepage_category_two"
      );
      if (getFeatureProductsData) {
        setFeatureProductsData(getFeatureProductsData);
      }

      let getRecentlyViewedProductsData = updatedHomePageData?.find(
        (data) =>
          data.type === "slider" &&
          data.element_id === "homepage_category_three"
      );
      if (getRecentlyViewedProductsData) {
        setRecentlyViewedProductsData(getRecentlyViewedProductsData);
      }

      let getRecentlyViewedProductsBanner = updatedHomePageData?.find(
        (data) =>
          data.type === "single_banner" &&
          data.element_id === "recently_viewed_section_group"
      );
      if (getRecentlyViewedProductsBanner) {
        setRecentlyViewedProductsBanner(getRecentlyViewedProductsBanner);
      }

      let getNewArrivalData = updatedHomePageData?.find(
        (data) =>
          data.type === "slider" && data.element_id === "homepage_category_four"
      );
      if (getNewArrivalData) {
        setNewArrivalData(getNewArrivalData);
      }

      let getCategorySevenData = updatedHomePageData?.find(
        (data) =>
          data.type === "slider" && data.element_id === "homepage_category_seven"
      );
      if (getCategorySevenData) {
        setCategorySevenData(getCategorySevenData);
      }

      let getTopTrendingData = updatedHomePageData?.find(
        (data) =>
          data.type === "slider" && data.element_id === "homepage_category_five"
      );
      if (getTopTrendingData) {
        setTopTrendingData(getTopTrendingData);
      }

      let getTopTVData = updatedHomePageData?.find(
        (data) =>
          data.type === "slider" && data.element_id === "homepage_tv_category"
      );
      if (getTopTVData) {
        setTopTVData(getTopTVData);
      }

      let getDealsOfTheWeekData = updatedHomePageData?.find(
        (data) =>
          data.type === "multiple_banner" &&
          data.element_id === "deals_of_the_week_slider"
      );
      if (getDealsOfTheWeekData) {
        setDealsOfTheWeekData(getDealsOfTheWeekData);
      }

      let getTopRatedData = updatedHomePageData?.find(
        (data) =>
          data.type === "slider" && data.element_id === "homepage_category_six"
      );
      if (getTopRatedData) {
        setTopRatedData(getTopRatedData);
      }

      let getHomePageBottomSingleBanner = updatedHomePageData?.find(
        (data) =>
          data.type === "single_banner" &&
          data.element_id === "homepage_main_botton_banner"
      );
      if (getHomePageBottomSingleBanner) {
        setHomePageBottomSingleBanner(getHomePageBottomSingleBanner);
      }

      let getHomePagePlayStationSingleBanner = updatedHomePageData?.find(
        (data) =>
          data.type === "single_banner" &&
          data.element_id === "homepage_main_botton_playstation"
      );
      if (getHomePagePlayStationSingleBanner) {
        setHomePagePlayStationSingleBanner(getHomePagePlayStationSingleBanner);
      }

      let getRecentlyViewedProductsSingleBanner = updatedHomePageData?.find(
        (data) =>
          data.type === "single_banner" &&
          data.element_id === "recently_viewed_section_group"
      );
      if (getRecentlyViewedProductsSingleBanner) {
        setRecentlyViewedProductsSingleBanner(
          getRecentlyViewedProductsSingleBanner
        );
      }

      window.scrollTo(0, 0);
    }
    // else {
    //   dispatch(loadHomePageData())
    // }
  }, [homepageData, isCache]);

  const closeLiveChatPopup = () => {
    setLiveChatPopup(false);
  };
  const isMobile = width <= 992;

  const { t } = useTranslation();

  useEffect(() => {
    if(loading){
      reduxDispatch(loaderStart())
    }else{
      reduxDispatch(loaderEnd())
    }
  }, [loading, reduxDispatch])
  let eventTrigger = [];
  let hit = false;
  useEffect(() => {
    
    const triggerEvent = (eventId) =>{
          
      if(eventId == 'section1' && featureProductsData !== ""){
        eventTrigger.push(eventId);
        
          impressionEvents.impressionViewProduct(
            featureProductsData.products !== undefined
              ? featureProductsData?.products
              : [],
              featureProductsData?.title,
              featureProductsData?.element_id,

          );
      }else if(eventId == 'section2' && topTVData !== ""){
        eventTrigger.push(eventId);

        impressionEvents.impressionViewProduct(
            topTVData?.products,
            topTVData?.title,
            topTVData?.element_id,

        );
      }
      else if(eventId == 'section3' && recentlyViewedProductsData !== "" && !eventTrigger.includes(eventId)){
        
          eventTrigger.push(eventId);
          if(hit == false){
            hit = true;
            impressionEvents.impressionViewProduct(
              recentlyViewedProductsData !== ""
              ? recentlyViewedProductsData?.products.slice(0, 4)
              : [],
              recentlyViewedProductsData?.title,
              recentlyViewedProductsData?.element_id
            );
          }
          
        
        
      }
      else if(eventId == 'section4' && homePagePlayStationSingleBanner !== ""){
        eventTrigger.push(eventId);

        impressionEvents.impressionBannerView(
          homePagePlayStationSingleBanner?.items,
          homePagePlayStationSingleBanner?.title,
          homePagePlayStationSingleBanner
        );
      }
      else if(eventId == 'section5' && topTrendingData !== ""){
        eventTrigger.push(eventId);

        impressionEvents.impressionViewProduct(
            topTrendingData?.products,
            topTrendingData?.title,
            topTrendingData?.element_id,

        );
      
      }
      else if(eventId == 'section6' && newArrivalData !== ""){
        eventTrigger.push(eventId);

        impressionEvents.impressionViewProduct(
          newArrivalData?.products,
          newArrivalData?.title,
          newArrivalData?.element_id,

        );
      
      }
      else if(eventId == 'section7' && categorySevenData !== ""){
        eventTrigger.push(eventId);

        impressionEvents.impressionViewProduct(
          categorySevenData?.products,
          categorySevenData?.title,
          categorySevenData?.element_id,

        );
      
      }
      else if(eventId == 'section8' && topRatedData !== ""){
        eventTrigger.push(eventId);

        impressionEvents.impressionViewProduct(
          topRatedData?.products,
          topRatedData?.title,
          topRatedData?.element_id,

        );
      
      }
      else if(eventId == 'section9' && homePageBottomSingleBanner !== ""){
        eventTrigger.push(eventId);

          impressionEvents.impressionBannerView(
            homePageBottomSingleBanner?.items,
            homePageBottomSingleBanner?.title,
            homePageBottomSingleBanner
          );
      }
  }
    const sectionChecking =  () => {
      const sectionIds = ['section1', 'section2', 'section3','section4', 'section5', 'section6', 'section7','section8', 'section9'];

      const options = {
        root: null, // Use the viewport as the root
        rootMargin: '0px',
        threshold: 0.5, // Intersection ratio that triggers the callback
      };

      
      const handleIntersection = (entries) => {
        entries.forEach((entry) => {

          if (entry.isIntersecting) {
           
            if(!eventTrigger.includes(entry.target.id)){
           
              triggerEvent(entry.target.id);
            }
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection, options);

      // Observe each section
      sectionIds.forEach((sectionId , index) => {
        const section = document.getElementById(sectionId);
        if (section) {
          observer.observe(section);
        }
      });
    }
    setTimeout(() => {
      sectionChecking();
     
    }, 1000);
    window.addEventListener('scroll', sectionChecking);


  }, [recentlyViewedProductsData]);
  const bannerClick = (img,index, bannerFullData) =>{
    
    impressionEvents.impressionBannerClick(
      img,
      "Home Page",
      index,
      bannerFullData
    );
  }
  if (loading) {
    return <>
      {!isMobile ? <div className="skeleton-container">
        <Skeleton
          height="356px"
          width="100%"
        />
        <div className="skelaton-loader">
          {Array(5).fill().map(() =>
            <Skeleton
              key={Math.ceil(Math.random() * 9999)}
              height="105px"
              width="200px"
            />
          )}
        </div>
      </div> :
        <div className="skeleton-container">
          <div className="skelaton-loader">
            {Array(4).fill().map(() =>
              <Skeleton
                key={Math.ceil(Math.random() * 9999)}
                height="105px"
                width="200px"
              />
            )}
          </div>
          <br />
          <Skeleton
            height="40vh"
            width="100%"
          />
        </div>
      }
    </>
  }

  return (
    <>
    {!loading &&
      <ReviewTestFreaks />
    }
      <MetaComponent
        page="home"
        title={t("Sony_World")}
        meta_title={t("Sony_World")}
        meta_keywords={t("Sony_World")}
        meta_description={t("Sony_World")}
      />
      <TealiumTag name="home" template="home" />
      {/* page_speed => Mobile view */}
      {width <= 992 && <div className="mobile__home__page d-block d-lg-none">
        <MobileHomePage
          homePageBottomSingleBanner={homePageBottomSingleBanner}
          homePagePlayStationSingleBanner={homePagePlayStationSingleBanner}
          handleChangeCartPopup={handleChangeCartPopup}
          dealsOfTheWeekData={dealsOfTheWeekData}
          topRatedData={topRatedData}
          topTrendingData={topTrendingData}
          bannerData={mobBannerData}
          featureProductsData={featureProductsData}
          recentlyViewedProductsData={recentlyViewedProductsData}
          recentlyViewedProductsBanner={recentlyViewedProductsBanner}
          newArrivalData={newArrivalData}
          categorySevenData={categorySevenData}
          outOfStockConfiguration={outOfStockConfiguration}
          screenName="Home"
          topTVData={topTVData}
          availablityData={availablityData}
          customerDetails={customerDetails}
        />
      </div>}

      {!isMobile &&
        <div className="homePage d-none d-lg-block">
          {bannerData !== "" && <Banner bannerFullData={bannerData} bannerData={bannerData.items} />}

          {dealsOfTheWeekData !== "" && (
            <DealsOfTheWeekMobile
              dealsOfTheWeekData={dealsOfTheWeekData?.items}
              sectionTitle={dealsOfTheWeekData?.title}
            />
          )}

          <div className="d-none d-lg-block">
            <HomePageCategoryBlock />
          </div>
          {featureProductsData !== "" && (
            <div  id="section1" className="sections" >
                <MainFeatureProducts
                featureData={
                  featureProductsData.products !== undefined
                    ? featureProductsData?.products
                    : []
                }
                handleChangeCartPopup={handleChangeCartPopup}
                sectionTitle={featureProductsData?.title}
                outOfStockConfiguration={outOfStockConfiguration}
                screenName="Home"
                availablityData={availablityData}
                sectionId = {featureProductsData?.element_id}
                customerDetails={customerDetails}
              />
            </div>
            
          )}
          {/* homepage_tv_category */}
          {topTVData !== "" && (
            <div id="section2" className={`sections top__trending__section ele_${topTVData?.element_id}`}>
              <ProductContainer
                handleChangeCartPopup={handleChangeCartPopup}
                sectionTitle={topTVData?.title}
                carouselData={topTVData?.products}
                containerClassName="top__trending__pc__block"
                cartIconOnly={true}
                outOfStockConfiguration={outOfStockConfiguration}
                screenName="Home"
                availablityData={availablityData}
                sectionId = {topTVData?.element_id}
                customerDetails={customerDetails}
              />
            </div>
          )}
          <div id="section3">
          <RecentlyViewedProducts
            recentlyViewedProductsData={
              recentlyViewedProductsData !== ""
                ? recentlyViewedProductsData?.products
                : []
            }
            recentlyViewedProductsBanner={
              recentlyViewedProductsBanner !== ""
                ? recentlyViewedProductsBanner?.products
                : []
            }
            recentlyViewedProductsSingleBanner={
              recentlyViewedProductsSingleBanner !== ""
                ? recentlyViewedProductsSingleBanner?.items
                : []
            }
            sectionTitle={recentlyViewedProductsData?.title}
            handleChangeCartPopup={handleChangeCartPopup}
            outOfStockConfiguration={outOfStockConfiguration}
            screenName="Home"
            availablityData={availablityData}
            sectionId = {recentlyViewedProductsData?.element_id}
            customerDetails={customerDetails}
          />
          </div>
          {homePagePlayStationSingleBanner !== "" && (
            <div id="section4" className="sections container-fluid adImage__container playStation_bottom_banner">
              <div className=" adImage"  onClick={()=>bannerClick(homePagePlayStationSingleBanner?.items[0],0,homePagePlayStationSingleBanner)}>
                {homePagePlayStationSingleBanner?.items[0]?.deeplinkUrl !== '' && homePagePlayStationSingleBanner?.items[0]?.deeplinkUrl !== undefined && homePagePlayStationSingleBanner?.items[0]?.deeplinkUrl !== null ?
                  <a
                    href={homePagePlayStationSingleBanner?.items[0]?.deeplinkUrl || "#"}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Product Ad"
                   

                  >
                    <div className="adImage__block">
                      <ImageRender
                        src={homePagePlayStationSingleBanner?.items[0]?.imageUrl}
                        alt="Bottom Ad Banner"
                        className="adImage__image"
                        onError={(e) => defaultImageHelper(e)}
                      />
                    </div>
                  </a> : <div className="adImage__block">
                    <ImageRender
                      src={homePagePlayStationSingleBanner?.items[0]?.imageUrl}
                      alt="Bottom Ad Banner"
                      className="adImage__image"
                      onError={(e) => defaultImageHelper(e)}
                     

                    />
                    {/* <LazyImage
                      imgSrc={homePagePlayStationSingleBanner?.items[0]?.imageUrl}
                      srcSetReSize={BottomBannerImageResize}
                      alt="Bottom Ad Banner"
                      className="adImage__image"
                    /> */}
                  </div>}
              </div>
            </div>
          )}
          {/* homepage_category_five */}
          {topTrendingData !== "" && (
            <div id="section5" className={`sections top__trending__section ele_${topTrendingData?.element_id}`}>
              <ProductContainer
                handleChangeCartPopup={handleChangeCartPopup}
                sectionTitle={topTrendingData?.title}
                carouselData={topTrendingData?.products}
                containerClassName="top__trending__pc__block"
                cartIconOnly={true}
                outOfStockConfiguration={outOfStockConfiguration}
                screenName="Home"
                availablityData={availablityData}
                sectionId = {topTrendingData?.element_id}
                customerDetails={customerDetails}
              />
            </div>
          )}
          {/* homepage_category_four */}
          {newArrivalData !== "" && (
            <div  id="section6" className={`sections top__trending__section ele_${newArrivalData?.element_id}`}>
              <ProductContainer
                handleChangeCartPopup={handleChangeCartPopup}
                sectionTitle={newArrivalData?.title}
                carouselData={newArrivalData?.products}
                containerClassName="new__arrival__pc__block"
                cartIconOnly={true}
                outOfStockConfiguration={outOfStockConfiguration}
                screenName="Home"
                availablityData={availablityData}
                sectionId = {newArrivalData?.element_id}
                customerDetails={customerDetails}
              />
            </div>
          )}
          {categorySevenData !== "" && (
            <div id="section7" className={`sections top__trending__section ele_${categorySevenData?.element_id}`}>
              <ProductContainer
                handleChangeCartPopup={handleChangeCartPopup}
                sectionTitle={categorySevenData?.title}
                carouselData={categorySevenData?.products}
                containerClassName="new__arrival__pc__block"
                cartIconOnly={true}
                outOfStockConfiguration={outOfStockConfiguration}
                screenName="Home"
                availablityData={availablityData}
                sectionId = {categorySevenData?.element_id}
                customerDetails={customerDetails}
              />
            </div>
          )}          
          {topRatedData !== "" && (
            <div id="section8" className={` sections top__ratedProduct__section ele_${topRatedData?.title}`}>
              <ProductContainer
                handleChangeCartPopup={handleChangeCartPopup}
                sectionTitle={topRatedData?.title}
                carouselData={topRatedData?.products}
                containerClassName="top__rated__pc__block"
                cartIconOnly={true}
                outOfStockConfiguration={outOfStockConfiguration}
                screenName="Home"
                availablityData={availablityData}
                sectionId = {topRatedData?.element_id}
                customerDetails={customerDetails}
              />
            </div>
          )}
          {/* <TopRatedProducts productData={topRatedProductsData} /> */}
          {homePageBottomSingleBanner !== "" && (
            <div  id="section9" className="sections container-fluid adImage__container">
              <div className=" adImage" onClick={()=>bannerClick(homePageBottomSingleBanner?.items[0],0,homePageBottomSingleBanner)}>
                {homePageBottomSingleBanner?.items[0]?.deeplinkUrl !== '' && homePageBottomSingleBanner?.items[0]?.deeplinkUrl !== undefined && homePageBottomSingleBanner?.items[0]?.deeplinkUrl !== null ?
                  <a
                    href={homePageBottomSingleBanner?.items[0]?.deeplinkUrl || "#"}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Product Ad"
                   
                  >
                    <div className="adImage__block">
                      <ImageRender
                        src={homePageBottomSingleBanner?.items[0]?.imageUrl}
                        alt="Bottom Ad Banner"
                        className="adImage__image"
                        onError={(e) => defaultImageHelper(e)}
                      />
                    </div>
                  </a> :
                  <ImageRender
                    src={homePageBottomSingleBanner?.items[0]?.imageUrl}
                    alt="Bottom Ad Banner"
                    className="adImage__image"
                    onError={(e) => defaultImageHelper(e)}
                  />
                }
              </div>
            </div>
          )}
        </div>
      }
      <div
        className={
          liveChatPopup
            ? "container-fluid live__chat__popup__container"
            : "container-fluid live__chat__popup__container__disable"
        }
      >
        <LiveChatPopup closeLiveChatPopup={closeLiveChatPopup} />
      </div>
    </>
  );
}

export default React.memo(Home);
