import { dataLayerPush } from './dataLayerPush';

export const pdpEvents = {
  viewItemList: (product) => {
    const data = {
      items: product && product?.length > 0 && product?.map((item) => {
        return (
          {
            item_id: item?.id,
            item_name: item?.name,
            sku: item?.sku,
            price: Number(parseFloat(item?.price).toFixed(2)),
            item_category: item?.categories[0]?.name || "",
            item_category2: item?.categories[1]?.name || "",
            item_category3: item?.categories[2]?.name || "",
            item_category4: item?.categories[3]?.name || "",
            item_category5: item?.categories[4]?.name || "",
            // category: item?.categories,
          }
        )
      })
    };
    // dataLayerPush({ event: 'view_item_list', ecommerce: data, _clear: true });
  },

  viewItem: (product) => {
    const data = {
      items: [
        {
          item_id: product?.id,
          item_name: product?.name,
          sku: product?.sku,
          price: Number(parseFloat(product?.price).toFixed(2)),
          item_category: product?.categories[0]?.name || "",
          item_category2: product?.categories[1]?.name || "",
          item_category3: product?.categories[2]?.name || "",
          item_category4: product?.categories[3]?.name || "",
          item_category5: product?.categories[4]?.name || "",
          // category: product?.categories,
        }
      ]
    };
    dataLayerPush({ event: 'view_item', ecommerce: data, _clear: true });
  },

  viewBundleProduct: (product) => {
    const data = {
      item_list_id: "related_products",
      item_list_name: "Related products",
      items: product.map((item) => {
        return (
          {
            item_id: item.id,
            item_name: item.name,
            sku: item.sku,
            price: Number(parseFloat(item.price).toFixed(2)),
            item_category: item?.categories[0]?.name || "",
            item_category2: item?.categories[1]?.name || "",
            item_category3: item?.categories[2]?.name || "",
            item_category4: item?.categories[3]?.name || "",
            item_category5: item?.categories[4]?.name || "",
            // category: item.categories,
          }
        )
      })
    };
    // dataLayerPush({ event: 'view_item_list', ecommerce: data, _clear: true });
  },
};
