import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './../SCSS/_paymentStatus.scss';
import Heading2 from "../Components/Font/Heading2";
import paymentSuccess from "./../assets/Icon/success_check.svg";
import paymentCancle from './../assets/Icon/cancle-circle.svg';
import { useTranslation } from 'react-i18next';
import apiHelper from '../Components/helpers/utils/apiHelper';
import NewLoader from '../Components/Common/newloader';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Unsubscribe_Page() {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isUnSubscribed, setIsUnSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);

  const query = useQuery();
  const getEmail = query.get("e");

  useEffect(() => {
    if (getEmail) {
      let params = {
        email: getEmail
      }
      guestUnSubscribeNewsletter(params);
    } else {
      navigate("/");
    }
  }, [getEmail]);

  const guestUnSubscribeNewsletter = async (params) => {
    try {
      const response = await apiHelper(
        `/V1/content/newsletter/guest/unsubscribe`,
        "post",
        params,
        {}
      );
      if (response?.data?.success) {
        setLoading(false);
        setIsUnSubscribed(true);
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  };

  if (loading) {
    return <NewLoader />;
  }

  return (
    <div className="payment__status__container container-fluid">
      <div className="payment__status__block">
        <img src={isUnSubscribed ? paymentSuccess : paymentCancle} alt="" className="panyment__success__icon" />
        <Heading2 text={isUnSubscribed ? t("Unsubscribed") : t("Token expired")} marginBottom={20} color="#a3a1a1" />
        <Link to="/" className="continue__shopping__btn">{t("Continue_Shopping")}</Link>
      </div>
    </div>
  )
}

export default Unsubscribe_Page