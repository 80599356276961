import React, {useEffect} from "react";
import "./../SCSS/_recentlyViewedProducts.scss";
import ProductOne from "./ProductType/ProductOne";
import LazyImage from "./MostSharedComponent/LazyImage";
import { HalfBannerImageResize, ImageResize } from "./helpers/utils/imageResize";
import { defaultImageHelper } from "./helpers/utils/defaultImageHelper";
import { impressionEvents } from "./helpers/utils/ga/impressionEvents";

function RecentlyViewedProducts({
  recentlyViewedProductsData,
  handleChangeCartPopup,
  recentlyViewedProductsSingleBanner,
  sectionTitle,
  // isMobile,
  outOfStockConfiguration,
  screenName,
  availablityData,
  sectionId,
  customerDetails
}) {
  useEffect(() => {
    // if(recentlyViewedProductsData && recentlyViewedProductsData.length > 0){
    //   impressionEvents.impressionViewProduct(
    //     recentlyViewedProductsData,
    //     sectionTitle
    //   );
    // }
  }, [recentlyViewedProductsData?.length]);
  return (
    <>
      {recentlyViewedProductsData && recentlyViewedProductsData.length > 0 && (
        <>
          <div className="container-fluid recentlyViewed__container">
            <div className="recentlyViewed__block">
              <div className="main__rv__block">
                <div className="inner__rv__block">
                  <div className="rv__left__block">
                    <p className="section__title asso">{sectionTitle}</p>
                    <div className="row rv__desktop__screen">
                      {recentlyViewedProductsData &&
                        recentlyViewedProductsData
                          .slice(0, 4)
                          .map((cat, catIndex) => {
                            return (
                              <React.Fragment key={cat.id}>
                                {catIndex < 4 && (
                                  <ProductOne
                                    handleChangeCartPopup={
                                      handleChangeCartPopup
                                    }
                                    productDetailPage={true}
                                    key={cat.id}
                                    product={cat}
                                    cartIconOnly={false}
                                    outOfStockConfiguration={outOfStockConfiguration}
                                    productIndex={catIndex}
                                    screenName={screenName}
                                    availablityData={availablityData}
                                    sectionTitle={sectionTitle}
                                    sectionId={sectionId}
                                    customerDetails={customerDetails}
                                  />
                                )}
                              </React.Fragment>
                            );
                          })}
                    </div>
                  </div>
                  {recentlyViewedProductsSingleBanner &&
                    recentlyViewedProductsSingleBanner.length > 0 && (
                      <div className="rv__right__block">
                        {recentlyViewedProductsSingleBanner?.[0]
                          ?.deeplinkUrl !== '' && recentlyViewedProductsSingleBanner?.[0]
                            ?.deeplinkUrl !== undefined && recentlyViewedProductsSingleBanner?.[0]
                              ?.deeplinkUrl !== null ?
                          <a
                            href={
                              recentlyViewedProductsSingleBanner?.[0]
                                ?.deeplinkUrl || "#"
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <LazyImage
                              imgSrc={recentlyViewedProductsSingleBanner[0].imageUrl}
                              srcSetReSize={HalfBannerImageResize}
                              alt="shop now"
                              className="rv__right__part__bg__image"
                            />
                          </a> : 
                          <LazyImage
                            imgSrc={recentlyViewedProductsSingleBanner[0].imageUrl}
                            srcSetReSize={HalfBannerImageResize}
                            alt="shop now"
                            className="rv__right__part__bg__image"
                          />
                          }
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default RecentlyViewedProducts;
