import { dataLayerPush } from './dataLayerPush';
import { addToCartHandler, removeToCartHandler } from './handlers/cartEventsHandlers';
import { impressionEvents } from "./impressionEvents";

export const cartEvents = {
  // 
  addToCart: (productData, cartId, screenName , item_id) => impressionEvents.impressionAddToCart(productData, productData?.qty || productData?.product?.qty || 1 , screenName,item_id),
  addToCartFail: (productData, cartId, screenName) => addToCartHandler(productData, cartId, screenName, 'add_to_cart_fail'),
  changeQuantity: (cartId, itemId, oldQty, newQty, screenName) => {
    const data = {
      item_id: itemId,
      cart_id: cartId,
      old_qty: oldQty,
      new_qty: newQty,
      screen_name: screenName,
    };
    dataLayerPush({ event: 'change_quantity', ...data });
  },
  removeFromCart: (productData, cartId, screenName) => impressionEvents.impressionDeleteFromCart(productData,productData?.qty1),
  displayMiniCart: (screenName) => dataLayerPush({ event: 'display_mini_cart', screen_name: screenName }),
  viewCart: (items, total, currency) => console.log('d'),
};
