import React from "react";
import { HalfBannerImageResize } from "../helpers/utils/imageResize";
import LazyImage from "../MostSharedComponent/LazyImage";

const Category_Three_Banner = (props) => {
  const bannerImage = props?.bannerImage?.items;
  const title = props?.title;
  return (
    <div className="three__ad__banner__container">
      <div className=" three__ad__banner__block">
        {title && <p className="section__title">{title}</p>}
        <div className="row inner__three__ad__banner__block">
          {bannerImage &&
            bannerImage.map((image, index) => {
              return (
                <div className="col-12 col-sm-4 ad__banner__block" key={index}>
                  {image?.deeplinkUrl !== '' && image?.deeplinkUrl !== undefined && image?.deeplinkUrl !== null ?
                    <a
                      href={image?.deeplinkUrl || "#"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LazyImage
                        imgSrc={image.imageUrl}
                        srcSetReSize={HalfBannerImageResize}
                        alt={image?.image_id || "Ad Banner"}
                        className="ad__banner__image"
                      />
                    </a> : <LazyImage
                      imgSrc={image.imageUrl}
                      srcSetReSize={HalfBannerImageResize}
                      alt={image?.image_id || "Ad Banner"}
                      className="ad__banner__image"
                    />}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Category_Three_Banner);
