import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../Components/BreadCrumbs";
import Contact_Info from "../Components/Contact/Contact_Info";
import Contact_Form from "../Components/Contact/Contact_form";
import "./../SCSS/MostSharedComponents/_contactPage.scss";
import { Link } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { impressionEvents } from "../Components/helpers/utils/ga/impressionEvents";

function Contact_Page() {
    const { t } = useTranslation();
    const currentLang = localStorage.getItem("i18next") || "en";
    useEffect(() => {
        impressionEvents.impressionGlobalVariable(
          currentLang, "Contact Page"
        );
      }, []);
    return (
        <>
            <div className="container-fluid contact__page">
                <div className="row">

                    <div className="d-none d-lg-block p-0">
                        <BreadCrumbs
                            back="/"
                            firstBread="/contact"
                            firstBreadTitle={t('Support')}
                            highlight="firstBreadTitle"
                        />
                    </div>
                    <div className="mb_back_section d-block d-lg-none">
                        <Link to="/" className="mb__contact__back__btn"><BsChevronLeft /> Support</Link>
                    </div>
                    <div className="contact__container">
                        <div className="row">
                            <div className="col-md-12 col-lg-5">
                                <Contact_Info />
                            </div>
                            <div className="col-md-12 col-lg-7">
                                <Contact_Form />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Contact_Page;