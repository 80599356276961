import React, { useEffect, useRef, useState } from 'react';
import useInViewPort from '../hooks/use-in-viewport';
import Skeleton from 'react-loading-skeleton';
import { impressionEvents } from './helpers/utils/ga/impressionEvents';
import ImageRender from './ImageRender';
import { defaultImageHelper } from './helpers/utils/defaultImageHelper';
import LazyImage from './MostSharedComponent/LazyImage';
import { MagnifyImageResize } from './helpers/utils/imageResize';

function SingleBanner({ index, bannerClick, item, isMobile = false, cmsStyle = {} } ) {
  const ref = useRef();
  const isVisible = useInViewPort(ref);
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setRendered(true);
      impressionEvents.impressionBannerView(item?.items, item?.title, item);
    }
  }, [isVisible, item]);

  if (!rendered) {
    return (
      <div ref={ref}>
        <Skeleton width={'100%'} height={'calc(100vw * (101/384))'} />
      </div>
    );
  }

  if (isMobile) {
    return (
      <div key={`single_banner_${index}`} className="container-fluid adImage__container adImageMobileHeight"  >
        <div className="adImage">
          {item?.items[0]?.deeplinkUrl !== '' &&
          item?.items[0]?.deeplinkUrl !== undefined &&
          item?.items[0]?.deeplinkUrl !== null ? (
            <a
              href={item?.items[0]?.deeplinkUrl || '#'}
              target="_blank"
              rel="noreferrer"
              aria-label="Product Ad"
            >
              <div className="adImage__block">
                <LazyImage
                  imgSrc={item?.items[0]?.imageUrl}
                  srcSetReSize={MagnifyImageResize}
                  alt="Bottom Ad Banner"
                  className="adImage__image"
                />
              </div>
            </a>
          ) : (
            <div className="adImage__block">
              <LazyImage
                imgSrc={item?.items[0]?.imageUrl}
                srcSetReSize={MagnifyImageResize}
                alt="Bottom Ad Banner"
                className="adImage__image"
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      key={`single_banner_${index}`}
      ref={ref}
      className="sections container-fluid adImage__container adImageHeight"
    >
      <div
        className=" adImage"
        onClick={() => bannerClick(item?.items[0], 0, item)}
      >
        {item?.items[0]?.deeplinkUrl !== '' &&
        item?.items[0]?.deeplinkUrl !== undefined &&
        item?.items[0]?.deeplinkUrl !== null ? (
          <a
            href={item?.items[0]?.deeplinkUrl || '#'}
            target="_blank"
            rel="noreferrer"
            aria-label="Product Ad"
          >
            <div className="adImage__block" style={cmsStyle}>
              <ImageRender
                src={item?.items[0]?.imageUrl}
                alt="Bottom Ad Banner"
                className="adImage__image"
                onError={(e) => defaultImageHelper(e)}
              />
            </div>
          </a>
        ) : (
          <div className="adImage__block" style={cmsStyle}>
            <ImageRender
              src={item?.items[0]?.imageUrl}
              alt="Bottom Ad Banner"
              className="adImage__image"
              onError={(e) => defaultImageHelper(e)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default SingleBanner;
