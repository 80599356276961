import React from 'react';

const PayfortForm = React.forwardRef(({ payfortData }, ref) => (
  <form id="f" name="f" method="post" action={payfortData.url} ref={ref}>
    <input type="hidden" name="card_number" value={payfortData.card_number} />
    <input
      type="hidden"
      name="card_holder_name"
      value={payfortData.card_holder_name}
    />
    <input
      type="hidden"
      name="card_security_code"
      value={payfortData.card_security_code}
    />
    <input type="hidden" name="expiry_date" value={payfortData.expiry_date} />
    <input
      type="hidden"
      name="merchant_identifier"
      value={payfortData.params.merchant_identifier}
    />
    <input
      type="hidden"
      name="access_code"
      value={payfortData.params.access_code}
    />
    <input
      type="hidden"
      name="merchant_reference"
      value={payfortData.order_id}
    />
    <input type="hidden" name="language" value={payfortData.params.language} />
    <input
      type="hidden"
      name="service_command"
      value={payfortData.params.service_command}
    />
    <input
      type="hidden"
      name="return_url"
      value={payfortData.params.return_url}
    />
    <input
      type="hidden"
      name="signature"
      value={payfortData.params.signature}
    />
  </form>
));

export default PayfortForm;
