import React from 'react';
import ErrorBoundaryUI from './ErrorBoundaryUI';
import { errorEvents } from './helpers/utils/ga/errorEvents';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }


  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
    const attributes = {
      location: window.location.pathname,
      componentStack: errorInfo.componentStack,
      errorText: error && error.toString(),
    };
    errorEvents.webWarning('errorBoundary', attributes); // log into ga
  }

  render() {
    const { error, errorInfo } = this.state;
    if (errorInfo) {
      // Error path
      return (
        <ErrorBoundaryUI error={error} errorInfo={errorInfo} />
      );
    }
    // Normally, just render children;
    // use suspense wrapper will go here
    return this.props.children; //eslint-disable-line
  }
}

export default ErrorBoundary;
