import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import Heading3 from "../Font/Heading3";
import Heading7 from "../Font/Heading7";
import Text3 from "../Font/Text3";
import { emailValidator } from "../helpers/utils/validators";
import cancel_grey from "./../../assets/Icon/cancel_grey.svg";
import "./../../SCSS/Login/_newLetterModel.scss";
import * as services from "../../services/services";
import { useTranslation } from "react-i18next";

function NewsLetterModel({ handleChangePopupMode, closeLoginPopup }) {

  const dispatch = useDispatch();
  const { t } = useTranslation()
  const [data, setData] = useState({
    email: "",
  });

  const [errMsg, setErrMsg] = useState({
    email: "",
  });

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    if (value) {
      if (name === "email") {
        let emailStatus = emailValidator(value);
        if (emailStatus === "error") {
          setErrMsg({ ...errMsg, email: t('Invalid_email_address_example') })
        } else {
          setErrMsg({ ...errMsg, email: '' })
        }
      }
    } else {
      setErrMsg({ ...errMsg, email: t('Email_is_missing') })
    }
    setData({ ...data, [name]: value });
  };

  const handleBlur = (e) => {
    let obj = {
      target: {
        value: e.target.value.trim(),
        name: e.target.name
      }
    }
    handleChange(obj)
  }

  const handleSubmit = () => {
    if (data.email === "") {
      setErrMsg({ ...errMsg, email: t('Email_is_missing') })
    } else if (data.email !== "") {
      let checkEmail = emailValidator(data.email);
      if (checkEmail === 'error') {
        setErrMsg({ ...errMsg, email: t('Invalid_email_address_example') })
      } else {
        let params = {
          email: data.email
        }
        dispatch(services.customerNewsletterSubscriptions(params))
      }
    }
  };

  return (
    <div className="newsLetterModel__container">
      <div className="newsLetterModel__header">
        <Heading3 text={t("Newsletter_Subscriptions")} color="#000000" />
        <img
          onClick={() => closeLoginPopup()}
          src={cancel_grey}
          alt="cancel"
          className="cancel__button"
        />
      </div>
      <div className="newsLetterModel__content">
        <Text3 text={t("Weve sent a One Time Password (OTP) to the mobile number above. Please enter it to complete verification")} />
        <div className="main__form__field__block">
          {/* <p className="form__label">Email Address</p> */}
          <Heading7 text={t("Email_Address")} marginBottom={10} />
          <sup className="inputs_validation">*</sup>
          <div className="field__block">
            <input
              type="text"
              placeholder={t("Email_Address")}
              className="form__field"
              id="email"
              name="email"
              value={data.email}
              onChange={(e) => handleChange(e)}
              onBlur={handleBlur}
            />
          </div>
          {errMsg.email && (
            <span className="invalid__message">{errMsg.email}</span>
          )}
        </div>
        <button
          onClick={() => handleSubmit()}
          className="subscribe__button"
        >
          {t("SUBSCRIBE")}
        </button>
      </div>
    </div>
  );
}

export default NewsLetterModel;
