import React from "react";
import { useTranslation } from "react-i18next";

function ProductLabel({ product, labelType }) {

    const { t } = useTranslation();

    return (
        <>
            {labelType === "pdp" &&
                <>
                    {product?.labels?.pdp.map((ban, i) => {
                        <div key={`${ban.position}_${i}`} className={`cardLabel ${ban.position || `left_top`}`}>
                            <img key={0} alt="cardLabel" src={ban.url} />
                        </div>
                    })}
                </>
            }
            {labelType === "plp" &&
                <>
                    {product?.labels?.plp.map((ban, i) => {
                        <div key={`${ban.position}_${i}_${ban.type}`} className={`cardLabel ${ban.position || `left_top`}`}>
                            <img key={0} alt="cardLabel" src={ban.url} />
                        </div>
                    })}
                </>
            }
            {/* <div key={0} className={`cardLabel left_top`}>
                <img key={0} alt="cardLabel" src={'https://beta-m2.mestores.com/pub/media/amasty/amlabel/12YEARSENG.png'} />
            </div>
            <div key={0} className={`cardLabel center_top`}>
                <img key={0} alt="cardLabel" src={'https://beta-m2.mestores.com/pub/media/amasty/amlabel/12YEARSENG.png'} />
            </div>
            <div key={0} className={`cardLabel right_top`}>
                <img key={0} alt="cardLabel" src={'https://beta-m2.mestores.com/pub/media/amasty/amlabel/12YEARSENG.png'} />
            </div>
            <div key={0} className={`cardLabel left_middle`}>
                <img key={0} alt="cardLabel" src={'https://beta-m2.mestores.com/pub/media/amasty/amlabel/12YEARSENG.png'} />
            </div>
            <div key={0} className={`cardLabel center_middle`}>
                <img key={0} alt="cardLabel" src={'https://beta-m2.mestores.com/pub/media/amasty/amlabel/12YEARSENG.png'} />
            </div>
            <div key={0} className={`cardLabel right_middle`}>
                <img key={0} alt="cardLabel" src={'https://beta-m2.mestores.com/pub/media/amasty/amlabel/12YEARSENG.png'} />
            </div>
            <div key={0} className={`cardLabel left_bottom`}>
                <img key={0} alt="cardLabel" src={'https://beta-m2.mestores.com/pub/media/amasty/amlabel/12YEARSENG.png'} />
            </div>
            <div key={0} className={`cardLabel center_bottom`}>
                <img key={0} alt="cardLabel" src={'https://beta-m2.mestores.com/pub/media/amasty/amlabel/12YEARSENG.png'} />
            </div>
            <div key={0} className={`cardLabel right_bottom`}>
                <img key={0} alt="cardLabel" src={'https://beta-m2.mestores.com/pub/media/amasty/amlabel/12YEARSENG.png'} />
            </div> */}
        </>
    );
}

export default ProductLabel;