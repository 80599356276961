import React, { useState, useEffect, useRef } from "react";
import "./../SCSS/_productCarousel.scss";
// import product_carousal_01 from "./../assets/ProductCarousal/product_carousal_01.png";
// import product_carousal_02 from "./../assets/ProductCarousal/product_carousal_02.png";
// import product_carousal_03 from "./../assets/ProductCarousal/product_carousal_03.png";
// import product_carousal_04 from "./../assets/ProductCarousal/product_carousal_04.png";
// import product_carousal_05 from "./../assets/ProductCarousal/product_carousal_05.png";
// import carousel_up_arrow from "./../assets/Icon/carousel_up_arrow.svg";
// import carousel_down_arrow from "./../assets/Icon/carousel_down_arrow.svg";
// import twitter from "./../assets/Icon/twitter.png";
// import messanger from "./../assets/Icon/messanger.png";
// import whatsapp from "./../assets/Icon/whatsapp.png";
// import pinterest from "./../assets/Icon/pinterest.png";
// import { selectedCompareProductStatus } from "./helpers/utils/compareProductsFilter";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { defaultImageHelper } from "./helpers/utils/defaultImageHelper";
import { ImageMagnify } from "./MostSharedComponent/ImageMagnify";
import { checkIfImageExists } from "./helpers/utils/checkIfImageExists";
import { replaceImageHelper } from "./helpers/utils/replaceImageHelper";
import imgLoader from "./../assets/Icon/img-loader.gif";
import ProductLabel from "./MostSharedComponent/ProductLabel";
import DiscountOfferBox from "./MostSharedComponent/DiscountOfferBox";
import { SmallImageResize } from "./helpers/utils/imageResize";
import DefaultImage from './../assets/Icon/default_image.png';
import loaderImg from "./../assets/Icon/loader.gif";
import ImageRender from "./ImageRender";

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "arrow__icon__block slick-prev slick-arrow" +
      (currentSlide === 0 ? " slick-disabled" : "")
    }
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
    style={{ backgroundColor: "red" }}
  >
    Previous
  </button>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "arrow__icon__block slick-next slick-arrow" +
      (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
    style={{ backgroundColor: "green" }}
  >
    Next
  </button>
);

var settings = {
  dots: false,
  infinite: false,
  vertical: false,
  speed: 500,
  slidesToShow: 5,
  arrows: true,
  nextArrow: <SlickArrowLeft />,
  prevArrow: <SlickArrowRight />
  // slidesToScroll: 1
};

// const productDataJSON = [
//   {
//     id: 1,
//     image: product_carousal_01,
//   },
//   {
//     id: 2,
//     image: product_carousal_02,
//   },
//   {
//     id: 3,
//     image: product_carousal_03,
//   },
//   {
//     id: 4,
//     image: product_carousal_04,
//   },
//   {
//     id: 5,
//     image: product_carousal_05,
//   },
//   {
//     id: 6,
//     image: product_carousal_01,
//   },
//   {
//     id: 7,
//     image: product_carousal_02,
//   },
//   {
//     id: 8,
//     image: product_carousal_03,
//   },
//   {
//     id: 9,
//     image: product_carousal_04,
//   },
// ];

const useImageLoaded = () => {
  const [loaded, setLoaded] = useState(false)
  const ref = useRef()

  const onLoad = () => {
    setLoaded(true)
  }

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      onLoad()
    }
  })
  return [ref, loaded]
}

function ProductCarousel({ product, updateCompareProduct, sku, view, setFirstImage }) {
  const { t } = useTranslation()
  const [index, setIndex] = useState(0);
  const [carousel, setcarousel] = useState([]);
  const [loading, setLoading] = useState(true);
  // function refreshPage() {
  //   window.location.reload();
  // }

  const [ref, onLoad] = useImageLoaded()

  useEffect(async () => {
    const externalVideo = product && product?.media && product?.media?.["external-video"].length > 0 ? product?.media?.['external-video'] : [];
    const allImgData =
      product &&
        product?.media &&
        product?.media?.image &&
        product?.media?.image?.screenshots !== undefined
        ? [...product?.media?.image?.screenshots, ...externalVideo]
        : [...externalVideo];

    if (allImgData) {
      let filterImageData = [];
      for (let index = 0; index < allImgData?.length; index++) {
        const ban = allImgData[index]
        await checkIfImageExists(ban.image)
          .then(exists => {
            if (exists) {
              filterImageData.push(ban);
            }
          })
      }
      setcarousel(filterImageData);
      setLoading(false)
      if (filterImageData) {
        let getFirstImg = filterImageData[0]?.image
        setFirstImage(getFirstImg);
      }
    }
  }, [product]);

  useEffect(() => {
    if (index > carousel.length - 1) {
      let newIndex = 0;
      setIndex(newIndex);
    }
    if (index < 0) {
      let newIndex = carousel.length - 1;
      setIndex(newIndex);
    }
  }, [index, carousel]);

  // const upSide = (e) => {
  //   e.preventDefault();
  //   document.querySelector(".carousel__dots__block").scrollTop =
  //     document.querySelector(".carousel__dots__block").scrollTop -
  //     document.querySelector(".carousel__dots__block").clientHeight -
  //     100;
  //   let scroll = document.querySelector(".carousel__dots__block").scrollLeft;
  // };
  // const downSide = (e) => {
  //   e.preventDefault();

  //   document.querySelector(".carousel__dots__block").scrollTop =
  //     document.querySelector(".carousel__dots__block").scrollTop +
  //     document.querySelector(".carousel__dots__block").clientHeight -
  //     100;
  // };

  return (
    <>
    <div className="carousel__main__container">      
      <div className="carousel__main__block">
        <DiscountOfferBox product={product} />
        <div className="carousel__block">
          <ProductLabel product={product} labelType="pdp" />
          {!loading && carousel && carousel.length > 0 ?
            <>
              {carousel.map((ban, banIndex) => {
                let position = "carousel nextCarousel";
                if (banIndex === index) {
                  position = "carousel activeCarousel";
                }
                if (
                  banIndex === index - 1 ||
                  (index === 0 && banIndex === carousel.length - 1)
                ) {
                  position = "carousel prevCarousel";
                }
                if (carousel.length === 1) {
                  position = "carousel activeCarousel";
                }
                return (
                  <div className={position} key={`${ban.image} product carousel`} style={{ direction: 'ltr' }}>
                    {ban.type === "image" ?
                      <div style={{ textAlign: 'center', maxHeight: "100%", overflow: "hidden", direction: 'ltr' }}>
                        <ImageMagnify src={`${ban.image}?${Date.now}`} alt={`${ban.image} product carousel`} view='desktop' />
                      </div>
                      :
                      (
                        <iframe width="100%" height="280" src={ban.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      )
                    }
                  </div>
                );
              })}
            </>
            : loading ? 
              <div style={{textAlign:'center'}}>
                <img
                  className="carousel__image"
                  src={imgLoader}
                  alt={`product carousel`}
                  ref={ref}
                  onLoad={onLoad ? onLoad : undefined}
                  style={{
                    width: "100px"
                  }}
                />
              </div>
              :
              <div style={{textAlign:'center'}}>
                <ImageRender
                  noLazy={true}
                  className="carousel__image"
                  src={DefaultImage}
                  alt={`product carousel`}
                  style={{
                    width: "70%"
                  }}
                />
              </div>
          }
        </div>        
      </div>
      <div className="carousel__dots__main__block">
        <div className="carousel__dots__block">
          <Slider {...settings}>
            {carousel && carousel.map((ban, banIndex) => (
              <div
                key={ban.image}
                className={
                  parseInt(ban.position) === index + 1
                    ? "carousel__dot carousel__dotActive"
                    : "carousel__dot"
                }
                onClick={() => setIndex(banIndex)}
              >
                <ImageRender
                  noLazy={true}
                  // src={ban.image}
                  src={SmallImageResize(ban.image)[0]?.img}
                  srcSet={SmallImageResize(ban.image)[1]?.imgSrc.join(",")}
                  alt={ban.image + index}
                  style={{ cursor: 'pointer' }}
                  className="carousel__thumbnail__image"
                  onError={(e) => replaceImageHelper(e, loaderImg)}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>      
    </div>    
    </>
  );
}

export default ProductCarousel;
