import { dataLayerPush } from './dataLayerPush';

export const loginEvents = {
  // signUp: (screen_name, method) => dataLayerPush({ event: 'sign_up', screen_name, method }),
  signUp: (screen_name, method) => console.log('d'),

  passwordSet: () => dataLayerPush({ event: 'password_set' }),
  login: (screen_name, method) => dataLayerPush({ event: 'login', screen_name, method }),
  passwordLost: () => dataLayerPush({ event: 'password_lost' }),
  userLogin: (loginResponse) => {
    const genders = {
      1: 'm',
      2: 'f',
      3: 'na',
    };

    const data = {
      id: loginResponse.id,
      email: loginResponse.email,
      gender: genders[loginResponse.gender],
      birthDate: loginResponse.dob,
      username: loginResponse.custom_attributes?.username,
      profilePicture: loginResponse.custom_attributes?.profile_picture,
      mobile: loginResponse.custom_attributes?.mobile,
    };

    dataLayerPush({ event: 'user_login', ...data });
  },
  loginError: (screen_name, method, error) => dataLayerPush({
    event: 'login_error', screen_name, method, error,
  }),
  signUpError: (method, error) => dataLayerPush({ event: 'signup_error', method, error }),
  logout: (screen_name) => dataLayerPush({ event: 'logout', screen_name }),
};
