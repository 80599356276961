import React from "react";
import "./../../SCSS/_moreServices.scss";
import moreServicesIcon from "./../../assets/Icon/more-services.png";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InstallationService from "./InstallationService";
import BundleProductCard from "../MostSharedComponent/BundleProduct";
import ExtendedWarranty from "./ExtendedWarranty";

function MoreServices({
    isExWarrantyAvailable,
    product,
    warrantyProduct,
    handleChangeCartPopup,
    warrentyData
}) {
    const [accordionStatus, setAccordionStatus] = useState(false);
    const { t } = useTranslation();
    function warrentyDataMain(param){
        warrentyData(param);
    }

    return (
        <>
            <div className="more__services__section">
                {/* <div className="more__services__label">
                    <img src={moreServicesIcon} />
                    <p>{t("We have more services for you!")}</p>
                </div>
                <Link className="see__More__services__btn">Hide</Link>                 */}

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header
                            onClick={(e) => setAccordionStatus(!accordionStatus)}
                        >
                            <div className="more__services__label">
                                <img src={moreServicesIcon} />
                                <p>{t("We have more services for you!")}</p>
                            </div>
                            <span className="see__More__services__btn">
                                {accordionStatus === true ? t("Hide") : t("Show")}
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <>
                                {isExWarrantyAvailable &&
                                    <ExtendedWarranty
                                        t={t}
                                        title={t("Add extra warranty?")}
                                        product={product}
                                        warrantyProduct={warrantyProduct}
                                        handleChangeCartPopup={handleChangeCartPopup}
                                        warrentyDataMain = {warrentyDataMain}
                                    />
                                }
                            </>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
}

export default MoreServices;
