import React from 'react';
import "./../SCSS/_breadcrumbs.scss";
import { Link } from "react-router-dom";
import BreadCrumbs from "../Components/BreadCrumbs";
import Heading2 from '../../src/Components/Font/Heading2';
import './../SCSS/sitemap_pg.scss';

function Sony_map(){

    return(
        <>
            <BreadCrumbs
                back="/"
                firstBread="/"
                firstBreadTitle="Sitemap"                
                highlight="firstBreadTitle"
            />
            <div className="container-fluid sonyMap__page__container">
                <div className='sonyMap__main__block'>
                    <div className='sonyMap__header'>
                        <Heading2 text="Sitemap" />
                    </div>
                    <div className='sonyMap__body'>
                        <ul className='map__listbox'>
                            <li className='map__itembox'><Link to="" className="map__link">About Us</Link> 
                                <ul className='map__listbox'>
                                    <li className='map__itembox'> <Link to="" className="map__link">Our Purpose, Vision and Values</Link> </li>
                                    <li className='map__itembox'> <Link to="" className="map__link">Careers</Link> 
                                        <ul className='map__listbox'>
                                            <li className='map__itembox'><Link to="" className="map__link">Meet Our People</Link></li>
                                            <li className='map__itembox'><Link to="" className="map__link">Our Approach to Covid-19</Link></li>
                                            <li className='map__itembox'><Link to="" className="map__link">Our Location</Link></li>
                                        </ul>
                                    </li>
                                    <li className='map__itembox'> <Link to="" className="map__link">Company</Link> 
                                        <ul className='map__listbox'>
                                            <li className='map__itembox'> <Link to="" className="map__link">Our Business</Link> </li>
                                            <li className='map__itembox'> <Link to="" className="map__link">Our History</Link> </li>                                        
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className='map__itembox'> <Link to="" className="map__link">Products</Link> 
                                <ul className='map__listbox'>                                    
                                    <li className='map__itembox'> <Link to="" className="map__link">TV</Link> </li>
                                    <li className='map__itembox'> <Link to="" className="map__link">Camara</Link> </li>                                        
                                </ul>
                            </li>
                            <li className='map__itembox'> <Link to="" className="map__link">Technology</Link> </li>
                        </ul>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Sony_map;