import React from "react";
import { useTranslation } from "react-i18next";
import '../../SCSS/MostSharedComponents/_discountOfferBox.scss';

function DiscountOfferBox({ product }) {

    const { t } = useTranslation()

    const discountedPrice = product?.discounted_price_rounded;
    const price = product?.price_rounded;
    const discountPercentage = Math.round(100 - (discountedPrice / price) * 100);
    const discountedAmount = Math.round(price - discountedPrice);

    return (
        <>
            {discountPercentage > 0 && discountPercentage < 100 && (
                <div className="discount__offer__labelbox">
                    {discountPercentage && discountPercentage > 9 ? (
                        <h6>{`${discountPercentage}% ${t('OFF')}`}</h6>
                    ) : (
                        ''
                    )}
                    {discountPercentage < 10 && discountPercentage > 0 ? (
                        <h6>{`${t('SAR')} ${discountedAmount} ${t('OFF')}`}</h6>
                    ) : (
                        ''
                    )}
                </div>
            )}
        </>
    );

}
export default DiscountOfferBox;