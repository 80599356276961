import React from "react";
import { useTranslation } from "react-i18next";

function Camera_Page() {
  const { t } = useTranslation()
  return (
    <>
      <div>{t("Camera_Page")}</div>
    </>
  );
}

export default Camera_Page;
