import React from "react";
import "./../SCSS/_breadcrumbs.scss";
import { Link } from "react-router-dom";
import Home from "./../assets/breadcrumbs/home.svg";
// import LeftArrow from "./../assets/breadcrumbs/left-arrow.svg";
import RightArrow from "./../assets/breadcrumbs/right-arrow.svg";
// import { useTranslation } from "react-i18next";
import { titleChange } from "./helpers/utils/titleNameChange";

function BreadCrumbs({
  back,
  backButton = true,
  firstBread,
  firstBreadTitle,
  secondBread,
  secondBreadTitle,
  thirdBread,
  thirdBreadTitle,
  fourthBread,
  fourthBreadTitle,
  highlight,
  matchedProduct,
  product,
  clickData
}) {
  const newMatchPrice = matchedProduct?.length > 1 ? matchedProduct?.[0] : matchedProduct?.[0];
  const stringToCheck = product?.name;
  const regex = /-(.+)/;
  const hasAnythingAfterDash = regex.test(stringToCheck);
  // const { t } = useTranslation();

  // const gotoBack = ()=>{
  //   window.history.back();
  // }
  return (
    <div className="container-fluid breadcrumbs__container">
      <div className="breadcrumbs__block">
        {/* {backButton ? (
          <button className="back__button" onClick={()=>gotoBack()}><img className="left__arrow" src={LeftArrow} alt="" />
             <p className="back__text">{t('Back')}</p></button>
          // <Link to={back} className="back__button__block">
          //   <div className="back__button">
          //     <img className="left__arrow" src={LeftArrow} alt="" />
          //     <p className="back__text">Back</p>
          //   </div>
          // </Link>
        ) : (
          ""
        )} */}
        <Link to="/">
          <div className="home__image">
            <img src={Home} alt="Home" className="home__icon" />
          </div>
        </Link>
        {/* <div className="right__arrow">
          <img src={RightArrow} alt="Home" className="right__icon" />
        </div>
        <div className="page__title">
          <p className="page__title__text">{props.title || ""}</p>
        </div> */}
        {firstBread && firstBreadTitle ? (
          <>
            <div className="right__arrow">
              <img src={RightArrow} alt="Home" className="right__icon" />
            </div>

            <Link to={`${firstBread}`} className="page__title">
              <p
                className={
                  highlight === "firstBreadTitle"
                    ? "page__title__text breadCrumbs__highlight"
                    : "page__title__text"
                }
              >
                {titleChange(firstBreadTitle) || ""}
              </p>
            </Link>
          </>
        ) : (
          ""
        )}
        {secondBread && secondBreadTitle && !window.location.pathname.includes("/checkout/signin")? (
          <>
            <div className="right__arrow">
              <img src={RightArrow} alt="Home" className="right__icon" />
            </div>

            <Link to={`${secondBread}`} className="page__title">
              <p
                className={
                  highlight === "secondBreadTitle"
                    ? "page__title__text breadCrumbs__highlight"
                    : "page__title__text"
                }
              >
                {/* {!hasAnythingAfterDash ? newMatchPrice ? titleChange(newMatchPrice?.name) : titleChange(product?.name) : titleChange(product?.name) || ""} */}
                {clickData ? titleChange(clickData) : newMatchPrice ? titleChange(newMatchPrice?.name) : titleChange(product?.name) || "" }
              </p>
            </Link>
          </>
        ) : (
          ""
        )}
        {thirdBread && thirdBreadTitle ? (
          <>
            <div className="right__arrow">
              <img src={RightArrow} alt="Home" className="right__icon" />
            </div>

            <Link to={`${thirdBread}`} className="page__title">
              <p
                className={
                  highlight === "thirdBreadTitle"
                    ? "page__title__text breadCrumbs__highlight"
                    : "page__title__text"
                }
              >
                {titleChange(thirdBreadTitle) || ""}
              </p>
            </Link>
          </>
        ) : (
          ""
        )}
        {fourthBread && fourthBreadTitle ? (
          <>
            <div className="right__arrow">
              <img src={RightArrow} alt="Home" className="right__icon" />
            </div>

            <Link to={`${fourthBread}`} className="page__title">
              <p
                className={
                  highlight === "fourthBreadTitle"
                    ? "page__title__text breadCrumbs__highlight"
                    : "page__title__text"
                }
              >
                {titleChange(fourthBreadTitle) || ""}
              </p>
            </Link>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default React.memo(BreadCrumbs);
