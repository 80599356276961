import React, { useEffect, useState, useMemo, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import * as types from "./../redux/actionType";
// import "./../SCSS/ProductListPage/_productListPageFilterProductBlock.scss";
// import "./../SCSS/ProductListPage/_productListBannerSection.scss";
// import "./../SCSS/ProductListPage/_productListCategorySection.scss";
// import "./../SCSS/ProductListPage/_productListFilter.scss";
import "./../SCSS/_productListPage.scss";
// import PLPBannerAndCategorySection from "../Components/ProductListPageComponent/PLPBannerAndCategorySection";
import PLPFilterProductBlock from "../Components/ProductListPageComponent/PLPFilterProductBlock";
import sony_logo from "./../assets/Icon/sony_logo.svg";
import product_01 from "./../assets/Product/product_01.png";
import { useParams } from "react-router";
import {
  configurationSetting,
  // configurationSetting,
  loadApplyFilterProductsData,
  // loadCategoryFilterData,
  loadConfigurationSettingData,
  // loadFilterData,
  saveApplyFilterProductData,
} from "../redux/appAction";
import { getFilterData, getProductsOfCategory } from "../services/plp.service";
import NewLoader from "../Components/Common/newloader";
import _uniqBy from "lodash.uniqby";
import { t } from "i18next";
import { productUrlHelper } from "../Components/helpers/utils/productUrlHelper";
import { DEFAULT_SORT } from './../Components/helpers/utils/commonHelper';
import ReviewTestFreaks from "./ReviewTestFreaks";
import { pdpEvents } from "../Components/helpers/utils/ga/pdpEvents";
import { useQuery } from "react-query";
import useWindowDimensions from "../Components/helpers/utils/useWindowDimensions";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { impressionEvents } from "../Components/helpers/utils/ga/impressionEvents";
import skuEncoder from "../lib/utils/skuEncoder";

const MobileProductListPage = lazy(() => import("./MobilePages/Mobile_Product_List_Page"));
const CarouselTypeTwo = lazy(() => import("../Components/CarouselTypeTwo"));
const PLPProductPopupModal = lazy(() => import("../Components/Popup/PLPProductPopupModal"));
const PLPBannerAndCategorySection = lazy(() => import("../Components/ProductListPageComponent/PLPBannerAndCategorySection"));


const Product_List_Page = ({
  handleChangeCartPopup,
  updateCompareProduct,
}) => {
  const { category } = useParams();
  const memoCategory = useMemo(() => category, [category]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let urlSKU = category.split("-p-")[1];

  if (urlSKU?.includes('/') || urlSKU?.includes('%2F')) {
    urlSKU = skuEncoder(urlSKU);
  }
  useEffect(() => {
    if (urlSKU !== undefined) {
      navigate(`/products/sonyworld-p-${urlSKU}`);
    }
  }, [urlSKU]);

  const [queryParams, setQueryParams] = useSearchParams();
  const { width } = useWindowDimensions();
  // const getCompareProductList = useSelector(
  //   (state) => state.appData.getCompareProductList
  // );

  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [filteredProductsData, setFilteredProductsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterColor, setFilterColor] = useState([]);
  const [filterHDMIInput, setFilterHDMIInput] = useState([]);
  const [filterProcessor, setFilterProcessor] = useState([]);
  const [filterOperatingSystem, setFilterOperatingSystem] = useState([]);
  const [filterScreenSize, setFilterScreenSize] = useState([]);
  const [filterType, setFilterType] = useState([]);
  const [filterColorResolution, setFilterColorResolution] = useState([]);
  const [filterMotionFlow, setFilterMotionFlow] = useState([]);
  const [filterInterface, setFilterInterFace] = useState([]);
  const [filterMegaPixels, setFilterMegaPixels] = useState([]);
  const [filterAutoFocusType, setFilterAutoFocusType] = useState([]);
  const [filterBrand, setFilterBrand] = useState([]);
  const [filterPrice, setFilterPrice] = useState(null);
  const [filterQuery, setFilterQuery] = useState({});
  // const [filterCategory, setFilterCategory] = useState([]);
  const [filterOptionData, setFilterOptionData] = useState([]);
  const [activeBtnName, setActiveBtnName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const { pathname } = useLocation();
  const currentLang = localStorage.getItem("i18next") || "en";
  const setPagNumber = (numbr) => {
    setPage(numbr);
  };

  const [outOfStockConfiguration, setOutOfStockConfiguration] = useState({});

  useEffect(() => {
    if (!loading) {
      // console.log(filteredProductsData)
      pdpEvents?.viewItemList(filteredProductsData && filteredProductsData?.items || []);
    }
  }, [loading, filteredProductsData])


  useEffect(() => {
    dispatch(loadConfigurationSettingData())
    return (() => {
      dispatch(saveApplyFilterProductData({}))
      setFilteredProductsData({});
      setFinalFilter()
    })
  }, []);
  useEffect(() => {
    impressionEvents.impressionGlobalVariable(
      currentLang, "PLP Page"
    );
  }, [filteredProductsData]);
  useEffect(() => {
    const config = async () => {
      try {
        const configuration = await configurationSetting();
        const getOutOfStock = configuration?.out_of_stock_configuration || {};
        setOutOfStockConfiguration(getOutOfStock);
      } catch (error) {
        // console.log("error ", error);
      }
    };
    config();
  }, []);


  useEffect(() => {
    setLoading(true);
  }, [pathname]); // do this on route change

  const loadMore = () => {
    //  if(filteredProductsData?.total_count !== filteredProductsData?.items.length){
    //  alert('d');
    setIsLoading(false);
    const offset = page * finalFilter?.limit;
    const object = finalFilter;
    object.offset = offset;
    object.sortBy.price = filterBlock?.sortBy?.price || DEFAULT_SORT;
    let newData = [];
    newData = filteredProductsData;
    //  const object = {offset,...finalFilter};
    setFinalFilter(object);
    // dispatch();
    dispatch(loadApplyFilterProductsData(object, "loadMore")).then(async (data) => {
      if (data?.items?.length) {
        newData?.items.push(...data?.items);
        setFilteredProductsData(newData);
        dispatch(saveApplyFilterProductData(newData));
        setPagNumber(page + 1);
      } else {
        setPagNumber(1);
      }

      setIsLoading(true);
    });
    //  }
  };
  const [finalFilter, setFinalFilter] = useState({
    filterDetails: { category: [category.split("-").slice(-3)[0]] },
  });
  const memoFinalFilter = useMemo(() => finalFilter, [finalFilter]);

  const selectedCategory = useSelector(
    (state) => state.appData.selectedCategory
  );

  const categoryData = useSelector((state) => state.appData.categoryData);
  // const filterOptionData = useSelector(
  //   (state) => state.appData.filterOptionData
  // );

  const applyFilterProductsData = {} = useSelector(
    (state) => state.appData.applyFilterProductsData
  );

  const { customerDetails } = useSelector((state) => state.customerReducer);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const queryPramsData = handleStoreIntoState(queryParams);
    const urlCategoryId = category.split("-").slice(-3)[0];
    const urlSelectedCategoryId = category.split("-").slice(-3)[2];
    setSelectedCategoryId(urlCategoryId);
    let mainSelectedCategory = categoryData?.children_data.filter(
      (mainCat) => mainCat.id == urlSelectedCategoryId
    );
    dispatch({
      type: types.SET__SELECTED__CATEGORY,
      payload: mainSelectedCategory[0],
    });
    const categoryIdArray = [];
    categoryIdArray.push(urlCategoryId);
    // setFilterCategory(categoryIdArray);
    setFinalFilter({
      filterDetails: {
        keyword: "",
        category: categoryIdArray,
        ...queryPramsData,
      },
      sortBy: {
        price: DEFAULT_SORT,
      },
      limit: 10,
      offset: 0,
    });
    setFilterBlock(finalFilter);
  }, [category, queryParams]);

  useEffect(() => {
    const urlCategoryId = category.split("-").slice(-3)[0];
    setSelectedCategoryId(urlCategoryId);
  }, [selectedCategory, category]);

  useEffect(() => {
    const urlCategoryId = category.split("-").slice(-3)[0];
    if (memoFinalFilter?.filterDetails?.category?.[0] == urlCategoryId) {
      //filter api call reduce
      // dispatch(loadApplyFilterProductsData(memoFinalFilter));
      setFilterBlock(memoFinalFilter);
    }
  }, [memoFinalFilter, memoCategory, dispatch]);

  useEffect(() => {
    const urlCategoryId = category.split("-").slice(-3)[0];
    let reqParams = {
      ...finalFilter,
      filterDetails: {
        ...finalFilter?.filterDetails,
        category: [urlCategoryId],
      },
    }
    getFilterData(reqParams)
      .then((res) => {
        setFilterOptionData(res);
        // handleChangeCartPopup(true)
      })
      .catch((err) => {
        // dispatch(services.notifyError({ message: err.response.data.message }));
      });
  }, [category]);

  useEffect(async () => {
    if (Object.values(applyFilterProductsData).length !== 0) {
      setFilteredProductsData(applyFilterProductsData);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [applyFilterProductsData]);

  // const [currentLimit, setCurrentLimit] = useState(0);
  const [productCategoryId, setProductCategoryId] = useState("");
  const [relatedProducts, setRelatedProducts] = useState([]);

  const urlSubSelectedCategoryId = category.split("-").slice(-3)[0];

  useEffect(() => {
    if (productCategoryId) {
      if (productCategoryId) {
        let reqParam = {
          id: productCategoryId,
        };
        getProductsOfCategory(reqParam)
          .then(async (res) => {
            setRelatedProducts(res?.data?.items);
          })
          .catch((err) => {
            setRelatedProducts([]);
          });
      } else {
        setRelatedProducts([]);
      }
    }
  }, [productCategoryId]);

  useEffect(() => {
    const selectSubCatId = selectedCategory?.children_data?.filter(
      (item) => item?.id == urlSubSelectedCategoryId
    )?.[0]?.display_name;
    if (selectSubCatId) {
      setProductCategoryId(selectSubCatId);
    } else {
      setProductCategoryId(null);
      setRelatedProducts([]);
    }
    handleActiveBtnClass('');
  }, [urlSubSelectedCategoryId, selectedCategory]);

  const updateSelectedSubCategoryId = (subCategory, reset = false) => {
    // if (subCategory?.display_name) {
    //   setProductCategoryId(subCategory?.display_name);
    // } else {
    //   setRelatedProducts([]);
    // }
    setLoading(true);
    if (reset) {
      navigate(pathname);
    }
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el) => (el.checked = false));
    // setCurrentLimit(2);
    setFilterBrand([]);
    setFilterColor([]);
    setFilterHDMIInput([]);
    setFilterProcessor([]);
    setFilterOperatingSystem([]);
    setFilterScreenSize([]);
    setFilterType([]);
    setFilterColorResolution([]);
    setFilterMotionFlow([]);
    setFilterInterFace([]);
    setFilterMegaPixels([]);
    setFilterAutoFocusType([]);
    setFilterPrice(null);
    setFilterQuery({});
    setSelectedCategoryId(subCategory.id);
    const categoryIdArray = [];
    categoryIdArray.push(subCategory.id);
    // setFilterCategory(categoryIdArray);
    // setFinalFilter({
    //   filterDetails: { keyword: "", category: categoryIdArray },
    //   sortBy: {
    //     price: "asc",
    //   },
    //   limit: 10,
    //   offset: 0,
    // });
    setFilterBlock({
      filterDetails: { keyword: "", category: categoryIdArray },
      sortBy: {
        price: DEFAULT_SORT,
      },
      limit: 10,
      offset: 0,
    });
    setFilterBlock({
      filterDetails: { keyword: "", category: categoryIdArray },
      sortBy: {
        price: DEFAULT_SORT,
      },
      limit: 10,
      offset: 0,
    });
    if (!reset) {
      navigate(
        `/${subCategory.url_key}-c-${subCategory.id}-mc-${subCategory.parent_id}`
      );
    }
  };

  const [filterBlock, setFilterBlock] = useState();
  const memoFilterBlock = useMemo(() => filterBlock, [filterBlock]);
  const handleQueryParams = (key, filterValues) => {
    const getParamsValues = queryParams.getAll("filterBy");
    let filterBy = "";
    if (getParamsValues?.length > 0) {
      const filterKey = getParamsValues?.filter((filterValue) => filterValue);
      const findFilterKey = filterKey?.find((obj) => {
        const keySplit = obj.split(":");
        return keySplit?.[0] === key;
      });
      if (findFilterKey) {
        const indexOfKey = filterKey.indexOf(findFilterKey);
        if (filterValues) {
          const newValue = `${key}:${filterValues}`;
          filterKey.splice(indexOfKey, 1, newValue);
        } else {
          filterKey.splice(indexOfKey, 1);
        }
        filterBy = filterKey.join("&filterBy=");
      } else {
        filterBy = filterKey.join("&filterBy=");
        filterBy = `${filterBy}&filterBy=${key}:${filterValues}`;
      }
    } else {
      filterBy = `${key}:${filterValues}`;
    }
    if (filterBy) {
      navigate(`?filterBy=${filterBy}`);
    } else {
      navigate(pathname);
    }
  };

  const onFilter = (attkey, id, selection) => {
    // navigate(`?filterBy=${key}:${data}`)

    handleClearMobileFilterModalUI();

    setLoading(true);
    if (attkey === "order") {
      const newFilter = {
        ...filterBlock,
        sortBy: { price: id },
      };
      setFilterBlock(newFilter);
    }
    if (attkey === "color") {
      const newColorFilter = [
        ...filterColor,
        ...(filterQuery?.color || []),
      ]?.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      if (newColorFilter.includes(id)) {
        let newFilterColor = newColorFilter.filter(
          (filtered) => filtered != id
        );
        setFilterColor(newFilterColor);
        const colorFilter = newFilterColor?.join(",");
        handleQueryParams("color", colorFilter);
      } else {
        let newFilterColor = [...newColorFilter, id];
        setFilterColor(newFilterColor);
        const colorFilter = newFilterColor?.join(",");
        handleQueryParams("color", colorFilter);
      }
    } else if (attkey === "hdmi_input") {
      const newHdmiFilter = [
        ...filterHDMIInput,
        ...(filterQuery?.hdmi_input || []),
      ]?.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      if (newHdmiFilter.includes(id)) {
        let newFilterHDMIInput = newHdmiFilter.filter(
          (filtered) => filtered != id
        );
        setFilterHDMIInput(newFilterHDMIInput);
        const hdmiInputFilter = newFilterHDMIInput?.join(",");
        handleQueryParams("hdmi_input", hdmiInputFilter);
      } else {
        let newFilterHDMIInput = [...newHdmiFilter, id];
        setFilterHDMIInput(newFilterHDMIInput);
        const hdmiInputFilter = newFilterHDMIInput?.join(",");
        handleQueryParams("hdmi_input", hdmiInputFilter);
      }
    } else if (attkey === "processor") {
      const newProcessorFilter = [
        ...filterProcessor,
        ...(filterQuery?.processor || []),
      ]?.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      if (newProcessorFilter.includes(id)) {
        let newFilterProcessor = newProcessorFilter.filter(
          (filtered) => filtered != id
        );
        setFilterProcessor(newFilterProcessor);
        const processorFilter = newFilterProcessor?.join(",");
        handleQueryParams("processor", processorFilter);
      } else {
        let newFilterProcessor = [...newProcessorFilter, id];
        setFilterProcessor(newFilterProcessor);
        const processorFilter = newFilterProcessor?.join(",");
        handleQueryParams("processor", processorFilter);
      }
    } else if (attkey === "operating_system") {
      const newOperationSystemFilter = [
        ...filterOperatingSystem,
        ...(filterQuery?.operating_system || []),
      ]?.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      if (newOperationSystemFilter.includes(id)) {
        let newFilterOperatingSystem = newOperationSystemFilter.filter(
          (filtered) => filtered != id
        );
        setFilterOperatingSystem(newFilterOperatingSystem);
        const operationSystemFilter = newFilterOperatingSystem?.join(",");
        handleQueryParams("operating_system", operationSystemFilter);
      } else {
        let newFilterOperatingSystem = [...newOperationSystemFilter, id];
        setFilterOperatingSystem(newFilterOperatingSystem);
        const operationSystemFilter = newFilterOperatingSystem?.join(",");
        handleQueryParams("operating_system", operationSystemFilter);
      }
    } else if (attkey === "interface") {
      const newInterfaceFilter = [
        ...filterInterface,
        ...(filterQuery?.interface || []),
      ]?.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      if (newInterfaceFilter.includes(id)) {
        let newFilterInterface = newInterfaceFilter.filter(
          (filtered) => filtered != id
        );
        setFilterInterFace(newFilterInterface);
        const interfaceFilter = newFilterInterface?.join(",");
        handleQueryParams("interface", interfaceFilter);
      } else {
        let newFilterInterface = [...newInterfaceFilter, id];
        setFilterInterFace(newFilterInterface);
        const interfaceFilter = newFilterInterface?.join(",");
        handleQueryParams("interface", interfaceFilter);
      }
    } else if (attkey === "megapixels") {
      const newMegaPixelFilter = [
        ...filterMegaPixels,
        ...(filterQuery?.megapixels || []),
      ]?.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      if (newMegaPixelFilter.includes(id)) {
        let newFilterMegaPixels = newMegaPixelFilter.filter(
          (filtered) => filtered != id
        );
        setFilterMegaPixels(newFilterMegaPixels);
        const megaPixelsFilter = newFilterMegaPixels?.join(",");
        handleQueryParams("megapixels", megaPixelsFilter);
      } else {
        let newFilterMegaPixels = [...newMegaPixelFilter, id];
        setFilterMegaPixels(newFilterMegaPixels);
        const megaPixelsFilter = newFilterMegaPixels?.join(",");
        handleQueryParams("megapixels", megaPixelsFilter);
      }
    } else if (attkey === "autofocus_type") {
      const newAutoFocusTypeFilter = [
        ...filterAutoFocusType,
        ...(filterQuery?.autofocus_type || []),
      ]?.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      if (newAutoFocusTypeFilter.includes(id)) {
        let newFilterAutoFocusType = newAutoFocusTypeFilter.filter(
          (filtered) => filtered != id
        );
        setFilterAutoFocusType(newFilterAutoFocusType);
        const autoFocusTypeFilter = newFilterAutoFocusType?.join(",");
        handleQueryParams("autofocus_type", autoFocusTypeFilter);
      } else {
        let newFilterAutoFocusType = [...newAutoFocusTypeFilter, id];
        setFilterAutoFocusType(newFilterAutoFocusType);
        const autoFocusTypeFilter = newFilterAutoFocusType?.join(",");
        handleQueryParams("autofocus_type", autoFocusTypeFilter);
      }
    } else if (attkey === "screen_size") {
      const newScreenSizeFilter = [
        ...filterScreenSize,
        ...(filterQuery?.screen_size || []),
      ]?.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      if (newScreenSizeFilter.includes(id)) {
        let newFilterScreenSize = newScreenSizeFilter.filter(
          (filtered) => filtered != id
        );
        setFilterScreenSize(newFilterScreenSize);
        const newScreenSizeValue = newFilterScreenSize?.join(",");
        handleQueryParams("screen_size", newScreenSizeValue);
      } else {
        let newFilterScreenSize = [...newScreenSizeFilter, id];
        setFilterScreenSize(newFilterScreenSize);
        const newScreenSizeValue = newFilterScreenSize?.join(",");
        handleQueryParams("screen_size", newScreenSizeValue);
      }
    } else if (attkey === "motionflow") {
      const newMotionFlowFilter = [
        ...filterMotionFlow,
        ...(filterQuery?.motionflow || []),
      ]?.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      if (newMotionFlowFilter.includes(id)) {
        let newFilterMotionFlow = newMotionFlowFilter.filter(
          (filtered) => filtered != id
        );
        setFilterMotionFlow(newFilterMotionFlow);
        const newMotionFlowValue = newFilterMotionFlow?.join(",");
        handleQueryParams("motionflow", newMotionFlowValue);
      } else {
        let newFilterMotionFlow = [...newMotionFlowFilter, id];
        setFilterMotionFlow(newFilterMotionFlow);
        const newMotionFlowValue = newFilterMotionFlow?.join(",");
        handleQueryParams("motionflow", newMotionFlowValue);
      }
    } else if (attkey === "brand") {
      const newBrandFilter = [
        ...filterMotionFlow,
        ...(filterQuery?.brand || []),
      ]?.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      if (newBrandFilter.includes(id)) {
        let newFilterBrand = newBrandFilter.filter(
          (filtered) => filtered != id
        );
        setFilterBrand(newFilterBrand);
        const newBrandValue = newFilterBrand?.join(",");
        handleQueryParams("brand", newBrandValue);
      } else {
        let newFilterBrand = [...newBrandFilter, id];
        setFilterBrand(newFilterBrand);
        const newBrandValue = newFilterBrand?.join(",");
        handleQueryParams("brand", newBrandValue);
      }
    } else if (attkey === "price") {
      if (id.includes("-")) {
        //
        if (filterPrice && id == `${filterPrice[0].min}-${filterPrice[0].max}`) {
          setFilterPrice(null)
          handleQueryParams("display_price", "");
        } else {
          setFilterPrice([{ min: id.split("-")[0], max: id.split("-")[1] }])
          handleQueryParams("display_price", id);
        }
      }
    } else if (attkey === "type") {
      const newTypeFilter = [
        ...filterType,
        ...(filterQuery?.type || []),
      ]?.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      if (newTypeFilter.includes(id)) {
        let newFilterType = newTypeFilter.filter(
          (filtered) => filtered != id
        );
        setFilterType(newFilterType);
        const newTypeValue = newFilterType?.join(",");
        handleQueryParams("type", newTypeValue);
      } else {
        let newFilterType = [...newTypeFilter, id];
        setFilterType(newFilterType);
        const newTypeValue = newFilterType?.join(",");
        handleQueryParams("type", newTypeValue);
      }
    } else if (attkey === "color_resolution") {
      const newColorResolutionFilter = [
        ...filterColorResolution,
        ...(filterQuery?.color_resolution || []),
      ]?.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      if (newColorResolutionFilter.includes(id)) {
        let newFilterColorResolution = newColorResolutionFilter.filter(
          (filtered) => filtered != id
        );
        setFilterColorResolution(newFilterColorResolution);
        const newColorResolutionValue = newFilterColorResolution?.join(",");
        handleQueryParams("color_resolution", newColorResolutionValue);
      } else {
        let newFilterColorResolution = [...newColorResolutionFilter, id];
        setFilterColorResolution(newFilterColorResolution);
        const newColorResolutionValue = newFilterColorResolution?.join(",");
        handleQueryParams("color_resolution", newColorResolutionValue);
      }
    }

    // const found = filterBlock?.[attkey]?.filter((filtered) => filtered == id);
  };

  const handleStoreIntoState = (queryParams) => {
    if (queryParams) {
      const queryData = queryParams.getAll("filterBy");
      const newQueryData = {};
      queryData?.forEach((query) => {
        const qd = query.split(":");
        const key = qd?.[0];
        const values = qd?.[1];
        const splitValues = values?.split?.(",");
        const valuesQuery = [];
        splitValues?.forEach((splitValue) => {
          const newSplitValue = splitValue?.split("-");
          if (newSplitValue?.length > 1) {
            valuesQuery.push({ min: newSplitValue[0], max: newSplitValue[1] });
          } else {
            valuesQuery.push(newSplitValue[0]);
          }
        });
        newQueryData[key] = valuesQuery;
      });
      setFilterQuery(newQueryData);
      return newQueryData;
    }
  };

  // useEffect(() => {
  //   const newFilter = {
  //     ...filterBlock,
  //     limit: currentLimit,
  //   };
  //   setFilterBlock(newFilter);
  // }, [currentLimit]);
  useEffect(() => {
    if (filterColor.length > 0) {
      let newFilter = {
        ...filterBlock,
        filterDetails: {
          ...filterBlock?.filterDetails,
          color: filterColor,
        },
      };
      setFilterBlock(newFilter);
    } else if (filterColor.length <= 0) {
      let newFilter = {
        ...filterBlock,
      };
      delete newFilter?.filterDetails?.color;
      setFilterBlock(newFilter);
    }
  }, [filterColor]);
  useEffect(() => {
    if (filterHDMIInput.length > 0) {
      let newFilter = {
        ...filterBlock,
        filterDetails: {
          ...filterBlock?.filterDetails,
          hdmi_input: filterHDMIInput,
        },
      };
      setFilterBlock(newFilter);
    } else if (filterHDMIInput.length <= 0) {
      let newFilter = {
        ...filterBlock,
      };
      delete newFilter?.filterDetails?.hdmi_input;
      setFilterBlock(newFilter);
    }
  }, [filterHDMIInput]);
  useEffect(() => {
    if (filterProcessor.length > 0) {
      let newFilter = {
        ...filterBlock,
        filterDetails: {
          ...filterBlock?.filterDetails,
          processor: filterProcessor,
        },
      };
      setFilterBlock(newFilter);
    } else if (filterProcessor.length <= 0) {
      let newFilter = {
        ...filterBlock,
      };
      delete newFilter?.filterDetails?.processor;
      setFilterBlock(newFilter);
    }
  }, [filterProcessor]);

  useEffect(() => {
    if (filterOperatingSystem.length > 0) {
      let newFilter = {
        ...filterBlock,
        filterDetails: {
          ...filterBlock?.filterDetails,
          operating_system: filterOperatingSystem,
        },
      };
      setFilterBlock(newFilter);
    } else if (filterOperatingSystem.length <= 0) {
      let newFilter = {
        ...filterBlock,
      };
      delete newFilter?.filterDetails?.operating_system;
      setFilterBlock(newFilter);
    }
  }, [filterOperatingSystem]);

  useEffect(() => {
    if (filterInterface.length > 0) {
      let newFilter = {
        ...filterBlock,
        filterDetails: {
          ...filterBlock?.filterDetails,
          interface: filterInterface,
        },
      };
      setFilterBlock(newFilter);
    } else if (filterInterface.length <= 0) {
      let newFilter = {
        ...filterBlock,
      };
      delete newFilter?.filterDetails?.interface;
      setFilterBlock(newFilter);
    }
  }, [filterInterface]);

  useEffect(() => {
    if (filterMegaPixels.length > 0) {
      let newFilter = {
        ...filterBlock,
        filterDetails: {
          ...filterBlock?.filterDetails,
          megapixels: filterMegaPixels,
        },
      };
      setFilterBlock(newFilter);
    } else if (filterMegaPixels.length <= 0) {
      let newFilter = {
        ...filterBlock,
      };
      delete newFilter?.filterDetails?.megapixels;
      setFilterBlock(newFilter);
    }
  }, [filterMegaPixels]);

  useEffect(() => {
    if (filterAutoFocusType.length > 0) {
      let newFilter = {
        ...filterBlock,
        filterDetails: {
          ...filterBlock?.filterDetails,
          autofocus_type: filterAutoFocusType,
        },
      };
      setFilterBlock(newFilter);
    } else if (filterAutoFocusType.length <= 0) {
      let newFilter = {
        ...filterBlock,
      };
      delete newFilter?.filterDetails?.autofocus_type;
      setFilterBlock(newFilter);
    }
  }, [filterAutoFocusType]);

  useEffect(() => {
    if (filterScreenSize.length > 0) {
      let newFilter = {
        ...filterBlock,
        filterDetails: {
          ...filterBlock?.filterDetails,
          screen_size: filterScreenSize,
        },
      };
      setFilterBlock(newFilter);
    } else if (filterScreenSize.length <= 0) {
      let newFilter = {
        ...filterBlock,
      };
      delete newFilter?.filterDetails?.screen_size;

      setFilterBlock(newFilter);
    }
  }, [filterScreenSize]);

  useEffect(() => {
    if (filterType.length > 0) {
      let newFilter = {
        ...filterBlock,
        filterDetails: {
          ...filterBlock?.filterDetails,
          type: filterType,
        },
      };
      setFilterBlock(newFilter);
    } else if (filterType.length <= 0) {
      let newFilter = {
        ...filterBlock,
      };
      delete newFilter?.filterDetails?.type;

      setFilterBlock(newFilter);
    }
  }, [filterType]);

  useEffect(() => {
    if (filterColorResolution.length > 0) {
      let newFilter = {
        ...filterBlock,
        filterDetails: {
          ...filterBlock?.filterDetails,
          type: filterColorResolution,
        },
      };
      setFilterBlock(newFilter);
    } else if (filterColorResolution.length <= 0) {
      let newFilter = {
        ...filterBlock,
      };
      delete newFilter?.filterDetails?.type;

      setFilterBlock(newFilter);
    }
  }, [filterColorResolution]);

  useEffect(() => {
    if (filterMotionFlow.length > 0) {
      let newFilter = {
        ...filterBlock,
        filterDetails: {
          ...filterBlock?.filterDetails,
          motionflow: filterMotionFlow,
        },
      };
      setFilterBlock(newFilter);
    } else if (filterMotionFlow.length <= 0) {
      let newFilter = {
        ...filterBlock,
      };
      delete newFilter?.filterDetails?.motionflow;

      setFilterBlock(newFilter);
    }
  }, [filterMotionFlow]);

  useEffect(() => {
    if (filterBrand.length > 0) {
      const newFilter = {
        ...filterBlock,
        filterDetails: {
          ...filterBlock?.filterDetails,
          brand: filterBrand,
        },
      };
      setFilterBlock(newFilter);
    } else if (filterBrand.length <= 0) {
      let newFilter = {
        ...filterBlock,
      };
      delete newFilter?.filterDetails?.brand;

      setFilterBlock(newFilter);
    }
  }, [filterBrand]);

  useEffect(() => {
    if (filterPrice) {
      const newFilter = {
        ...filterBlock,
        filterDetails: {
          ...filterBlock?.filterDetails,
          display_price: filterPrice,
        },
      };
      setFilterBlock(newFilter);
    } else if (!filterPrice) {
      let newFilter = {
        ...filterBlock,
      };
      delete newFilter?.filterDetails?.display_price;

      setFilterBlock(newFilter);
    }
  }, [filterPrice]);

  useEffect(() => {
    if (memoFilterBlock !== undefined && Object.keys(memoFilterBlock).length !== 0)
      dispatch(loadApplyFilterProductsData(memoFilterBlock));
    // dispatch(loadFilterData(finalFilter));
    // window.scrollTo(0, 0);
  }, [memoFilterBlock, dispatch]);
  //for updating id from filter section
  // useEffect(() => {
  //   let categoryIdArray = []
  //   const urlCategoryId = category.split("-").slice(-1)[0]
  //   categoryIdArray.push(urlCategoryId)
  //   dispatch(loadCategoryFilterData(category.split("-").slice(-1)[0]));
  // }, []);
  const [plpProductPopup, setPlpProductPopup] = useState(false);
  // const [compareProductsData, setCompareProductData] = useState([]);
  const [popupProduct, setPopupProduct] = useState({
    id: 1,
    sku: "KD-65X8577G%2FDIS",
    logo: sony_logo,
    image: product_01,
    productName:
      "Z8H | Full Array LED | 8K | High Dynamic Range (HDR) | Smart TV (Android TV)",
    categoryTagline: "Experience the brilliance of big-screen Sony 8K HDR",
    rating: 4.6,
    totalRatings: 6183,
    price: 799,
    oldPrice: 1050,
    saving: 10,
    monthlySavingTagline: "get it for SAR 500 in 6 equal installments",
    returnPeriod: 15,
    availableOffer: [
      {
        id: 1,
        offerType: "",
        offerText: "Save $50-$300 on a sound bar with TV",
        termsAndConditions: "",
      },
      {
        id: 2,
        offerType: "Bank Offer",
        offerText: "5% Unlimited Cashback on Axis Bank Credit Card",
        termsAndConditions: "T&C",
      },
      {
        id: 3,
        offerType: "Credit Card Offer",
        offerText: "5% Unlimited Cashback on Sony Credit Card",
        termsAndConditions: "T&C",
      },
    ],
    warrantyText: "1 Year Warranty on Product",
    size: [
      {
        id: 1,
        cm: 139,
        inch: 55,
      },
      {
        id: 2,
        cm: 164,
        inch: 65,
      },
      {
        id: 3,
        cm: 195,
        inch: 77,
      },
    ],
    delivery: {
      deliveryText: "Buy in next 2 hours and receive the TV by Tomorrow",
      pickupStore: [
        {
          id: 1,
          pickupText:
            "Available today at Riyadh Act Fast – Only 3 left at your store!>",
        },
        {
          id: 2,
          pickupText:
            "Available today at Riyadh Act Fast – Only 3 left at your store!>",
        },
        {
          id: 3,
          pickupText:
            "Available today at Riyadh Act Fast – Only 3 left at your store!>",
        },
      ],
    },
    protection: [
      {
        id: 1,
        protectionText: "2-Year Standard Geek Squad Protection",
        price: 79,
        month: 12,
      },
      {
        id: 2,
        protectionText: "1-Year Standard Geek Squad Protection",
        price: 89,
        month: 12,
      },
    ],
    productFeatures: [
      {
        id: 1,
        text: "Netflix|Prime Video|Disney+Hotstar|Youtube",
      },
      {
        id: 1,
        text: "Multi-position stand for versatile TV placement",
      },
      {
        id: 1,
        text: "HD Smart 2048 x 1366 Pixels",
      },
    ],
  });
  // useEffect(() => {
  //   setCompareProductData(getCompareProductList);
  // }, [getCompareProductList]);

  const closeProductPopup = () => {
    setPlpProductPopup(false);
  };
  const handleChangeProductPopup = (value, product) => {
    setPlpProductPopup(value);
    setPopupProduct(product);
  };

  const handleActiveBtnClass = (btnName) => {
    setActiveBtnName(btnName);
  };

  const getShuffledArr = (arr) => {
    if (arr.length === 1) {
      return arr;
    }
    const rand = Math.floor(Math.random() * arr.length);
    return [arr[rand], ...getShuffledArr(arr.filter((_, i) => i != rand))];
  };

  const redirectToPdpPage = (url_key, sku) => {
    navigate(productUrlHelper(url_key, sku));
  };

  const handleClearMobileFilterModalUI = () => {
    document.querySelectorAll(".modal-backdrop").forEach(el => el.remove());
    const filterByModal = document.getElementById('filterByModal')
    filterByModal?.classList?.remove('show');
    if (filterByModal) {
      filterByModal.style.display = 'none'
      filterByModal.ariaHidden = true
    }
  }

  if (loading) {
    return <>
      <NewLoader />
      <div className="product__list__page__block mx-auto">
        <Skeleton width={"100%"} height="213px" />
        <Skeleton height={"50px"} />
        {width > 992 ?
          <div className="d-flex">
            <Skeleton containerClassName="w-25 me-1" height={"30vh"} />
            <Skeleton containerClassName="w-75" height={"30vh"} />
          </div>
          :
          <Skeleton width="100%" height={"30vh"} />
        }
      </div>
    </>
  }

  return (
    <>
      <ReviewTestFreaks />
      {width <= 992 && <div
        className={`mb__product__list__container d-block d-lg-none pt-5 ${activeBtnName === "compare" ? "listing_compare_active" : ""
          }`}
      >
        <MobileProductListPage
          categoryType={selectedCategory?.category_event_reporting}
          filterHide={true}
          onFilter={onFilter}
          handleChangeCartPopup={handleChangeCartPopup}
          filteredProductsData={filteredProductsData}
          filterOptionData={filterOptionData}
          // handleChangeProductPopup={handleChangeProductPopup}
          // handleChangeComparePopup={handleChangeComparePopup}
          handleActiveBtnClass={handleActiveBtnClass}
          updateCompareProduct={updateCompareProduct}
          selectedMainCategory={selectedCategory}
          selectedCategoryId={selectedCategoryId}
          updateSelectedSubCategoryId={updateSelectedSubCategoryId}
          filterInitData={filterBlock?.filterDetails || {}}
          sortByPrice={filterBlock?.sortBy?.price}
          handleClearFilter={() => {
            handleClearMobileFilterModalUI()
            updateSelectedSubCategoryId(
              { id: filterBlock?.filterDetails?.category?.[0] },
              true
            )
          }
          }
          loadMore={loadMore}
          setPagNumber={setPagNumber}
          isLoading={isLoading}
          outOfStockConfiguration={outOfStockConfiguration}
          screenName="Product List"
          activeBtnName={activeBtnName}
        />
      </div>}

      {width > 992 && <div className="container-fluid product__list__page__container d-none d-lg-block">
        <div className="product__list__page__block">
          <PLPBannerAndCategorySection
            selectedCategoryId={selectedCategoryId}
            updateSelectedSubCategoryId={updateSelectedSubCategoryId}
            selectedMainCategory={selectedCategory}
          />
          <PLPFilterProductBlock
            categoryType={selectedCategory?.category_event_reporting}
            onFilter={onFilter}
            filteredProductsData={filteredProductsData}
            filterOptionData={filterOptionData}
            handleChangeProductPopup={handleChangeProductPopup}
            updateCompareProduct={updateCompareProduct}
            handleChangeCartPopup={handleChangeCartPopup}
            redirectToPdpPage={redirectToPdpPage}
            filterInitData={filterBlock}
            sortByPrice={filterBlock?.sortBy?.price}
            handleClearFilter={() =>
              updateSelectedSubCategoryId(
                { id: filterBlock?.filterDetails?.category?.[0] },
                true
              )
            }
            loadMore={loadMore}
            setPagNumber={setPagNumber}
            isLoading={isLoading}
            outOfStockConfiguration={outOfStockConfiguration}
            screenName="Product List"
            customerDetails={customerDetails}
          />
          {/* <PLPBannerTwo bannerImage={bannerImg1} /> */}
          {relatedProducts?.length > 0 && (
            <div className="plp__newArrival__block">
              <CarouselTypeTwo
                handleChangeCartPopup={handleChangeCartPopup}
                productDetailPage={true}
                sectionTitle={t("You_Can_Also_Purchase")}
                carouselData={relatedProducts || []}
                productType="productOne"
                containerClassName="plp__youCanAlsoPurchase__block"
                outOfStockConfiguration={outOfStockConfiguration}
                screenName="Product List"
              />
            </div>
          )}
        </div>
      </div>}
      {plpProductPopup === true &&
        <PLPProductPopupModal
          show={plpProductPopup}
          handleChangeCartPopup={handleChangeCartPopup}
          product={popupProduct}
          closeProductPopup={closeProductPopup}
          redirectToPdpPage={redirectToPdpPage}
          outOfStockConfiguration={outOfStockConfiguration}
        />
      }
    </>
  );
};

export default Product_List_Page;
