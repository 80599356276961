import React from 'react';
import Order_Summary_New from '../../Pages/Order_Summary_New';

const OrderSummary = ({
  loyaltyEarnTypeId,
  cartDetail,
  cartTotalData,
  loyaltyEarnType,
}) => {
  return (
    <div className="col-md-12 col-xl-4  checkout__right__block">
      {typeof cartDetail === 'object' && typeof cartTotalData === 'object' && (
        <Order_Summary_New
          cartDetail={cartDetail}
          cartTotalData={cartTotalData}
          loyaltyEarnType={loyaltyEarnType}
          loyaltyEarnTypeId={loyaltyEarnTypeId}
          loyaltyRadioName="loyaltyEarnTypeDesk"
        />
      )}
    </div>
  );
};

export default OrderSummary;
