import React, { useEffect, useState } from 'react'
import "./../../SCSS/ProductType/_searchProduct.scss"
import { Link } from "react-router-dom"
import Heading6 from '../Font/Heading6'
import RatingBlock from '../MostSharedComponent/RatingBlock'
import { useTranslation } from 'react-i18next'
import { productUrlHelper } from '../helpers/utils/productUrlHelper'
import { titleChange } from '../helpers/utils/titleNameChange'
import { SearchSmallImageResize, SmallImageResize } from '../helpers/utils/imageResize'
import LazyImageForProduct from '../MostSharedComponent/LazyImageForProduct'
import FavouriteProduct from '../MostSharedComponent/FavouriteProduct'
import AddToCartButton from '../MostSharedComponent/AddToCartButton'
import Price from '../Font/Price'
import VariantOptions from './VariantOptions'
import ProductDeliveryType from '../MostSharedComponent/ProductDeliveryType'
import { impressionEvents } from "../helpers/utils/ga/impressionEvents";
import PriceBlock from '../MostSharedComponent/PriceBlock'
import SubscribeSection from '../Meza/SubscribeSection'
import SubsSectionContent from '../Meza/SubsSectionContent'
function SearchProduct({ product, availablityData, outOfStockConfiguration, handleChangeCartPopup, customerDetails }) {
    const [sizeButtonIndex, setSizeButtonIndex] = useState(null);
    const [clickData, setClickData] = useState();
    const [clickSkuData, setClickSkuData] = useState();
    const [value,setValue] =  useState(product?.sku)
    

    const sizeButtonClick = (sizeIndex) => {
        product.sku = product?.variants[sizeIndex]?.sku;
        product.name = product?.variants[sizeIndex]?.name;
        product.description = product?.variants[sizeIndex]?.description;
        product.price = product?.variants[sizeIndex]?.price;
        product.currency = product?.variants[sizeIndex]?.currency;
        product.price_rounded = product?.variants[sizeIndex]?.price_rounded;
        product.discounted_price = product?.variants[sizeIndex]?.discounted_price;
        product.discounted_price_rounded = product?.variants[sizeIndex]?.discounted_price_rounded;
        product.discounted_price_from = product?.variants[sizeIndex]?.discounted_price_from;
        product.discounted_price_to = product?.variants[sizeIndex]?.discounted_price_to;
        product.is_count_down = product?.variants[sizeIndex]?.is_count_down;
        product.stock = product?.variants[sizeIndex]?.stock;
        product.item_type = product?.variants[sizeIndex]?.item_type;
        product.meza = product?.variants[sizeIndex]?.meza;
        product.qitaf = product?.variants[sizeIndex]?.qitaf;
        product.eligible_for_tabby = product?.variants[sizeIndex]?.eligible_for_tabby;
        product.enable_shipping = product?.variants[sizeIndex]?.enable_shipping;
        product.additional_estimated_day = product?.variants[sizeIndex]?.additional_estimated_day;
        product.current_time = product?.variants[sizeIndex]?.current_time
        setSizeButtonIndex(sizeIndex);
      };
      const getOptionKeyInfo = (options, keyName) => {
        let checkKeyData =
          options && options?.filter((item) => item.code === keyName);
        //let keyData = "";
        if (checkKeyData?.length > 0) 
          return true;
        else
         return false;
      };
    const targetPrice = product?.price_rounded;
    const matchedProduct = product?.variants?.filter(product => product.price_rounded === targetPrice);
    const newMatchPrice = matchedProduct?.length > 1 ? matchedProduct?.[0] : matchedProduct?.[0];
    const screenSize = matchedProduct && matchedProduct[0]?.options?.screen_size;
    const seletcedScreenSize = product?.variantOptions?.[0].attribute?.options.filter(product => product.id === screenSize);
    const defaultSku = matchedProduct && matchedProduct[0]?.sku;
    useEffect(() => {
        if (product?.variants && product?.variants.length > 0 && sizeButtonIndex >= 0) {
          setClickData(product?.variants[sizeButtonIndex]?.name);
          setClickSkuData(product?.variants[sizeButtonIndex]?.sku);
        }
      }, [product, sizeButtonIndex]);

    const stringToCheck = product?.name;
    const regex = /-(.+)/;
    const hasAnythingAfterDash = regex.test(stringToCheck);
    const { t } = useTranslation()
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const clickImpression = (product) => {
        
        impressionEvents.impressionProductClick(
            "select_item", product,'Search Page','search_page'
          )
    }
    // console.log('vbvbvbvb',product)
    //const matchedTitle = matchedProduct ? titleChange(`${product?.name}`) : titleChange(product?.name);
    return (
        <div onClick={()=>clickImpression(product)} className="row search__product__block" kry={product?.id}>
            <div className="search__pro__favouritebox">
                <FavouriteProduct
                    product={product}
                />
            </div>

            <Link to={productUrlHelper(product?.english_name, product?.sku)} className="col-md-3 col-lg-2 search__product__image__block">
                <LazyImageForProduct
                    product={product}
                    srcSetReSize={SearchSmallImageResize}
                    alt=""
                    className="search__product__image"
                />
            </Link>
            <div className="col-md-9 col-lg-10 search__product__detail__block">
                <Link className='searchProduct__name' to={productUrlHelper(product?.english_name, product?.sku)}>
                    {/* {!hasAnythingAfterDash ? (
                        newMatchPrice?.name !== undefined ? (
                            <Heading6 text={newMatchPrice?.name} marginBottom={20} />
                        ) : (
                            <Heading6 text={product?.name} marginBottom={20} />
                        )
                    ) : (
                        <Heading6 text={product?.name} marginBottom={20} />
                    )} */}
                    {clickData ? <Heading6 text={clickData} marginBottom={20} /> 
                    : newMatchPrice ? <Heading6 text={newMatchPrice?.name} marginBottom={20} /> 
                    : <Heading6 text={product?.name} marginBottom={20} /> }
                    {/* <Heading6 text={product?.name} marginBottom={20} /> */}

                </Link>
                <ProductDeliveryType
                    product={product}
                    t={t}
                    availablityData={availablityData}
                />
                {/* <Text4 text="ZH8 SERIES" color="#808080" marginBottom={10} /> */}
                <RatingBlock rating={6} totalRatings={2222} />
                <div className="size__button__block">
                    {product?.variantOptions && (
                        <VariantOptions
                            sizeButtonClick={sizeButtonClick}
                            sizeButtonIndex={sizeButtonIndex}
                            variantOptions={product?.variantOptions}
                            product={product}
                        />
                    )}
                </div>
                {!product?.hide_subscriber_price && (
                    <SubscribeSection>
                        <SubsSectionContent
                        isPDP={true}
                        t={t}
                        meza_subscriber_price={product?.meza_subscriber_price}
                        user={customerDetails}
                        discounted_price={product.discounted_price_rounded}
                        og_price={product.price_rounded}
                        />
                    </SubscribeSection>
                )}
                <AddToCartButton
                    product={product}
                    outOfStockConfiguration={outOfStockConfiguration}
                    matchedProduct={matchedProduct}
                    handleChangeCartPopup={handleChangeCartPopup}
                />
                {/* { !getOptionKeyInfo(product?.options,"is_price_hide")  && (
                    // <Price currency={product?.currency} price={product?.price_rounded} size="heading4" marginBottom={5} />
                )} */}
                {!getOptionKeyInfo(product?.options,"is_price_hide")  && (
                    <PriceBlock
                        oldPrice={product?.price_rounded}
                        price={
                        product?.discounted_price_rounded === null
                            ? product?.price_rounded
                            : product?.discounted_price_rounded
                        }
                        currency={product?.currency}
                        searchPrice
                    />
                )}
                {/* {product?.description !== "" &&
                    <Text3 text={htmlParseHelper(product?.description)} marginBottom={20} />
                } */}
                {/* <Text3 text={"Experience the brilliance of big-screen Sony 8K HDR"} marginBottom={20} /> */}
                {/* <div className="row search__product__hightlight__link">
                    <Link to="/products/1" className="col-6 col-md-6 col-lg-4 col-xl-3 search__product__link">{t("Reviews_and_Ratings")}</Link>
                    <Link to="/products/1" className="col-6 col-md-6 col-lg-4 col-xl-3 search__product__link">{t("Related_Products")}</Link>
                    <Link to="/products/1" className="col-6 col-md-6 col-lg-4 col-xl-3 search__product__link">{t("Specifications")}</Link>
                    <Link to="/products/1" className="col-6 col-md-6 col-lg-4 col-xl-3 search__product__link">{t("Support")}</Link>
                </div> */}
            </div>

        </div>
    )
}


export default SearchProduct
