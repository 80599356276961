import React from 'react'
import { useTranslation } from 'react-i18next';

function TeleVision_Page() {
  const { t } = useTranslation();
  return (
   <>
   <h1>{t("Television_Page")}</h1>
   </>
  )
}

export default TeleVision_Page