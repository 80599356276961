import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading7 from "../../Components/Font/Heading7";
import Text3 from "../../Components/Font/Text3";
import cancel_grey from "./../../assets/Icon/cancel_grey.svg";
import * as services from "../../services/services";
import apiHelper from "../../Components/helpers/utils/apiHelper";
import { customerSendResetPasswordEmailSuccess } from "../../services/customer/customer";
import { useTranslation } from "react-i18next";
import { LoaderIcone, LoginLoaderIcone } from "../../Pages/Loader";
import { loaderStartEnd } from "../../services/services";
import Heading3 from "../../Components/Font/Heading3";
import { useNavigate } from "react-router";

const T_REQ_EMAIL = "Email or Mobile number is missing";

function Mobile_Reset_Password({ handleChangePopupMode, closeLoginPopup }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loadingStartEnd } = useSelector((state) => state.commonReducer);
  const { t } = useTranslation();

  const [data, setData] = useState({
    email: "",
  });

  const [errMsg, setErrMsg] = useState({
    email: "",
  });

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    if (value) {
      setErrMsg({ ...errMsg, email: "" });
    } else {
      setErrMsg({ ...errMsg, email: T_REQ_EMAIL });
    }
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async () => {
    if (data.email === "") {
      setErrMsg({ ...errMsg, email: T_REQ_EMAIL });
    } else {
      let params = {
        email: data.email,
      };
      try {
        dispatch(loaderStartEnd(true));
        let response = await apiHelper(
          `/V1/customer/send-reset-password-email`,
          "post",
          params,
          {}
        );
        dispatch(
          services.notifySuccess({ message: "Request send successfully" })
        );
        response.data["email"] = params.email;
        localStorage.setItem(
          "forgotPasswordData",
          JSON.stringify(response.data)
        );
        // dispatch(customerSendResetPasswordEmailSuccess(response.data));
        navigate("/mobile-verify-password");
      } catch (error) {
        dispatch(loaderStartEnd(false));
        dispatch(
          services.notifyError({ message: error.response.data.message })
        );
      } finally {
        dispatch(loaderStartEnd(false));
      }
      // dispatch(services.customerSendResetPasswordEmail(params));
    }
  };

  const closeAndResetPopupData = () => {
    setData({
      ...data,
      email: "",
    });
    setErrMsg({
      ...errMsg,
      email: "",
    });
    navigate("/mobile-signin");
  };

  return (
    <div className="resetPwd__page">
      <div className="resetPwd__container">
        <div className="resetPwd__header">
          <Heading3 text={t("Reset_your_password")} color="#000000" />
          <img
            onClick={() => closeAndResetPopupData()}
            src={cancel_grey}
            alt="cancel"
            className="cancel__button"
          />
        </div>
        <div className="resetPwd__content">
          <Text3 text={t("Weve sent a One Time Password (OTP) to the mobile number or email. Please enter it to complete verification")} />
          <div className="main__form__field__block">
            {/* <p className="form__label">Email Address</p> */}
            <Heading7
              text={t("Email_Address_/_Mobile_Number")}
              marginBottom={10}
            />
            <sup className="inputs_validation">*</sup>
            <div className="field__block">
              <input
                type="text"
                placeholder={t("Email_Address_/_Mobile_Number")}
                className="form__field"
                id="email"
                name="email"
                value={data.email}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {errMsg.email && (
              <span className="invalid__message">{errMsg.email}</span>
            )}
          </div>
          <button onClick={() => handleSubmit()} className="subscribe__button">
            {t("SUBMIT")}
            {loadingStartEnd === true ? <LoaderIcone /> : ""}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Mobile_Reset_Password;