import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as services from "../services/services";
import BreadCrumbs from "../Components/BreadCrumbs";
import Heading3 from "../Components/Font/Heading4";
import Heading6 from "../Components/Font/Heading6";
import Heading7 from "../Components/Font/Heading7";
import Price from "../Components/Font/Price";
import Text3 from "../Components/Font/Text3";
import Text4 from "../Components/Font/Text4";
import sony_logo from "./../assets/Icon/sony_logo.svg";
import card_05 from "./../assets/Footer/card_05.png";
import product_01 from "./../assets/Product/product_01.png";
import "./../SCSS/_orderDetails.scss";
import Heading5 from "../Components/Font/Heading5";
import Heading4 from "../Components/Font/Heading4";
import { useParams } from "react-router-dom";
import moment from "moment";
import MyOrderProductV2 from "../Components/MostSharedComponent/MyOrderProductV2";
import ShippingGreenSteps from "../Components/MostSharedComponent/ShippingGreenSteps";
import MetaComponent from "../Components/Common/MetaComponent";
import { useTranslation } from "react-i18next";
import { loadCitiesLocationData } from "../redux/appAction";
import { getEstimatedDelivery } from "../Components/helpers/utils/getEstimatedDelivery";
import TealiumTag from "../Components/helpers/utils/tealium";
import { impressionEvents } from "../Components/helpers/utils/ga/impressionEvents";

const product = {
  id: 1,
  logo: sony_logo,
  name: "Z8H | Full Array LED | 8K | High Dynamic Range (HDR) | Smart TV (Android TV)",
  categoryTagline: "Experience the brilliance of big-screen Sony 8K HDR",
  rating: 4.6,
  totalRatings: 6183,
  price: 799,
  oldPrice: 1050,
  saving: 10,
  monthlySavingTagline: "get it for SAR 500 in 6 equal installments",
  returnPeriod: 15,
  availableOffer: [
    {
      id: 1,
      offerType: "",
      offerText: "Save $50-$300 on a sound bar with TV",
      termsAndConditions: "",
    },
    {
      id: 2,
      offerType: "Bank Offer",
      offerText: "5% Unlimited Cashback on Axis Bank Credit Card",
      termsAndConditions: "T&C",
    },
    {
      id: 3,
      offerType: "Credit Card Offer",
      offerText: "5% Unlimited Cashback on Sony Credit Card",
      termsAndConditions: "T&C",
    },
  ],
  warrantyText: "1 Year Warranty on Product",
  size: [
    {
      id: 1,
      cm: 139,
      inch: 55,
    },
    {
      id: 2,
      cm: 164,
      inch: 65,
    },
    {
      id: 3,
      cm: 195,
      inch: 77,
    },
  ],
  delivery: {
    deliveryText: "Buy in next 2 hours and receive the TV by Tomorrow",
    pickupStore: [
      {
        id: 1,
        pickupText:
          "Available today at Riyadh Act Fast – Only 3 left at your store!>",
      },
      {
        id: 2,
        pickupText:
          "Available today at Riyadh Act Fast – Only 3 left at your store!>",
      },
      {
        id: 3,
        pickupText:
          "Available today at Riyadh Act Fast – Only 3 left at your store!>",
      },
    ],
  },
  protection: [
    {
      id: 1,
      protectionText: "2-Year Standard Geek Squad Protection",
      price: 79,
      month: 12,
    },
    {
      id: 2,
      protectionText: "1-Year Standard Geek Squad Protection",
      price: 89,
      month: 12,
    },
  ],
  sku: "KD-85Z8H IN5",
  orderPlaced: "19 March 2022",
  orderId: "408-2450567-3112347",
  totalAmount: 1699,
};
function Order_Details(props) {
  const { order_id } = useParams();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { customerOrderDetails } = useSelector(
    (state) => state.customerOrdersReducer
  );

  const cityLocationData = useSelector(
    (state) => state.appData.cityLocationData
  );

  const [orderDetails, setOrderDetails] = useState("");
  const [storeCitiesLocationData, setStoreCitiesLocationData] = useState([]);

  useEffect(() => {
    let params = {
      id: order_id,
    };
    dispatch(loadCitiesLocationData());
    dispatch(services.getCustomerOrderDetails(params));
  }, [order_id]);

  useEffect(() => {
    if (customerOrderDetails) {
      setOrderDetails(customerOrderDetails);
    }
  }, [customerOrderDetails]);

  useEffect(() => {
    if (cityLocationData) {
      setStoreCitiesLocationData(cityLocationData);
    }
  }, [cityLocationData]);

  const cityCode = orderDetails?.extension_attributes?.shipping_assignments[0]?.shipping?.address?.city;
  const cityData = storeCitiesLocationData.find((c) => c.cityCode === cityCode);
  const additionalEstimatedDays = orderDetails && Math.max(
    ...orderDetails?.items?.map(item => item.product?.additional_estimated_day || 0)
    );

  const expectedDate = getEstimatedDelivery({
    orderDate: orderDetails?.created_at,
    additionalEstimatedDays,
    cityData,
    format: 'ddd, MMM DD'
  })

  // useEffect(() => {
  //   impressionEvents.impressionPurchases(
  //     orderDetails,
  //     'Order Detail'
  //   );
  // }, [orderDetails]);
  return (
    <>
      <MetaComponent
        title={t("My_Orders")}
        meta_title={t("My_Orders")}
        meta_keywords={t("My_Orders")}
        meta_description={t("My_Orders")}
      />
      <TealiumTag name={t("My_Orders")} template={t("My_Orders")} />
      <BreadCrumbs
        back="/user"
        firstBread="/user"
        firstBreadTitle={t("my_account")}
        secondBread="/user/orders"
        secondBreadTitle={t("My_Orders")}
        thirdBread={`/user/orders/${orderDetails?.increment_id === undefined ? "" : orderDetails?.increment_id}`}
        thirdBreadTitle={`${orderDetails?.increment_id === undefined ? "" : orderDetails?.increment_id}`}
        highlight="thirdBreadTitle"
      />
      {orderDetails !== "" && (
        <div className="container-fluid orderDetails__container">
          <div className="orderDetails__block">
            <div className="orderDetails__inner__block">
              <MyOrderProductV2
                product={orderDetails}
                pageType="order-details"
              />
              {/* <div className="row od__product__block">
              <div className="col-12 col-sm-2 od__product__left__block">
                <div className="od__product__image__block">
                  <img src={product_01} alt="" className="od__product__image" />
                </div>
              </div>
              <div className="col-12 col-sm-7 od__product__middle__block">
                <Heading3 text={titleChange(product.name)} marginBottom={10} />
                <div className="row od__order__details__row">
                  <div className="col-4 col-sm-4 col-md-2">
                    <Heading7 text="SKU" color="#727272" span={true} />
                  </div>
                  <div className="col-8 col-sm-8 col-md-4">
                    <Text3 text={product.sku} span={true} />
                  </div>
                  <div className="col-4 col-sm-4 col-md-2">
                    <Heading7
                      text={`${t("Order_Placed:")}:`}
                      color="#727272"
                      span={true}
                    />
                  </div>
                  <div className="col-8 col-sm-8 col-md-4">
                    <Text3 text={product.orderPlaced} span={true} />
                  </div>
                </div>
                <div className="row od__order__details__row">
                  <div className="col-4 col-sm-4 col-md-2">
                    <Heading7 text={`${t("ORDER_#")}:`} color="#727272" span={true} />
                  </div>
                  <div className="col-8 col-sm-8 col-md-4">
                    <Text3 text={product.orderId} span={true} />
                  </div>
                  <div className="col-4 col-sm-4 col-md-2">
                    <Heading7
                      text={`${t("Total_Amount")}:`}
                      color="#727272"
                      span={true}
                    />
                  </div>
                  <div className="col-8 col-sm-8 col-md-4">
                    <Price
                      price={product.totalAmount}
                      span={true}
                      size="heading7"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-3 od__product__right__block">
                <div className="od__edit__cancel__button__block">
                  <button className="od__edit__cancel__button">Edit</button>
                  <button className="od__edit__cancel__button">Cancel</button>
                </div>
                <Text3
                  text="Write review of this product"
                  color="#07A41B"
                  span={true}
                />
                <div className="od__buy__invoice__button__block">
                  <button className="od__buy__invoice__button">
                    Buy Again
                  </button>
                  <button className="od__download__black__button">
                    DOWNLOAD INVOICE
                  </button>
                </div>
              </div>
            </div> */}
              <hr className="od__page__horizontal__line"></hr>
              <div className="row od__shippingAddress__payment__orderSummary___block">
                <div className="col-12 col-sm-12 col-md-4 od__shippingAddress__block">
                  <Heading6 text={t("Shipping_Address")} marginBottom={20} />
                  <Heading7
                    text={`${orderDetails.billing_address.firstname} ${customerOrderDetails.billing_address.lastname}`}
                    marginBottom={5}
                  />
                  <div className="full__address">
                    <Text4
                      text={`${orderDetails.billing_address.street[0]} ${customerOrderDetails.billing_address.city} ${customerOrderDetails.billing_address.postcode} ${customerOrderDetails.billing_address.country_id}`}
                      marginBottom={20}
                    />
                  </div>
                  <Heading7 text={`${t("Phone_Number")}:`} color="#808080" span={true} />{" "}
                  <Text4
                    text={orderDetails.billing_address.telephone}
                    marginLeft={10}
                    span={true}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-4 od__payment__block">
                  <Heading6 text={t("Payment_Method")} marginBottom={20} />
                  {/* <img src={card_05} alt="credit card" className="od__card" /> */}
                  <Text4
                    color="#808080"
                    text={t(orderDetails.payment.additional_information.message)}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-4 od__orderSummary__block">
                  <Heading6 text={t("Order_Summary")} marginBottom={20} />
                  <div className="checkout__os__detail__block">
                    <div className="checkout__os__detail__inner__block">
                      <Text3 text={t("Shipping")} color="#000000" />
                      {orderDetails.shipping_amount == 0 ? (
                        <Heading4 text={t("Free")} color="#FF4F04" />
                      ) : (
                        <Price
                          price={orderDetails.shipping_amount}
                          currency={orderDetails.order_currency_code}
                          size="heading7"
                        />
                      )}
                    </div>
                    <div className="checkout__os__detail__inner__block">
                      <Text3 text={t('Sub_Total')} color="#000000" />
                      <Price
                        price={orderDetails.subtotal}
                        currency={orderDetails.order_currency_code}
                        size="heading7"
                      />
                    </div>
                   {orderDetails?.extension_attributes?.base_bss_storecredit_amount && <div className="checkout__os__detail__inner__block">
                      <Text3 text={t('Store Credit')} color="#000000" />
                      <Price
                        price={orderDetails?.extension_attributes?.base_bss_storecredit_amount}
                        currency={orderDetails.order_currency_code}
                        size="heading7"
                        preText="-"
                      />
                    </div>}
                    <div className="checkout__os__detail__inner__block">
                      <Text3 text={t("Discount")} color="#000000" />
                      <Price
                        price={orderDetails?.discount_amount - (-orderDetails?.discount_amount * 0.15)}
                        currency={orderDetails.order_currency_code}
                        size="heading7"
                      />
                    </div>
                    <div className="checkout__os__detail__inner__block">
                      <Text3 text={t('Tax')} color="#000000" />
                      <Price
                        price={orderDetails.tax_amount}
                        currency={orderDetails.order_currency_code}
                        size="heading7"
                      />
                    </div>
                    <div className="checkout__os__detail__total__order__block">
                      <Heading5 text={t("Order_Total")} color="#000000" />
                      <Price
                        price={orderDetails.grand_total}
                        currency={orderDetails.order_currency_code}
                        size="heading5"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {orderDetails.status !== "canceled_cc_attempt" && orderDetails.status !== "canceled" && orderDetails.is_virtual === 0 &&
                <>
                  <div className="od__order__track__map__block">
                    <hr className="od__page__horizontal__line"></hr>
                    <div className="od__order__track__title__block">
                      <div className="od__order__track__title__text">
                        <Heading7
                          text={orderDetails?.extension_attributes?.shipping_assignments && orderDetails?.extension_attributes?.shipping_assignments[0]?.shipping?.method === "storepickup_storepickup"
                            ? t('storepickup_message') : t('Your_item_has_been_on_the_way_expected_arriving_date_is')}
                          textAlign="center"
                          span={true}
                          color="#ffffff"
                        />
                        <Heading3
                          text={expectedDate}
                          textAlign="center"
                          span={true}
                          color="#ffffff"
                          marginLeft={10}
                        />
                      </div>
                      {/* <div className="od__order__track__title__text">
                        <Heading7
                          text="Your Delivery Person Name is Shadab Khan & Contact Details is"
                          textAlign="center"
                          span={true}
                          color="#ffffff"
                        />
                        <Heading3
                          text="+966 50 655 2835"
                          textAlign="center"
                          span={true}
                          color="#ffffff"
                          marginLeft={10}
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="ship__green__steps__container px-3">
                    <ShippingGreenSteps orderDetails={orderDetails} />
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      )}
      {/* <NewsLetter /> */}
      {/* <Footer /> */}
    </>
  );
}

export default Order_Details;
