export const handlePayfortFortCC = async (
  dispatch,
  loadCartData,
  setPayfortData,
  getPayfortInformation,
  cardErrMsg,
  allFeildValidate,
  setCardErrMsg,
  BASE_PAYMENT_REDIRECT_URL,
  selectedPaymentMethod,
  custEmailAddress,
  card,
  payfortForm
) => {
  const validateFeild = ['cardNumber', 'cardHolder', 'monthYear', 'cvv'];
  const formStatus = allFeildValidate(validateFeild, cardErrMsg);
  setCardErrMsg(formStatus.allErrMsg);
  if (formStatus.checkCardStatus === true) {
    const payload = {
      paymentMethod: {
        method: selectedPaymentMethod,
        referer_url:
          process.env.NODE_ENV === 'production'
            ? BASE_PAYMENT_REDIRECT_URL
            : BASE_PAYMENT_REDIRECT_URL,
        email: custEmailAddress,
        number: card.cardNumber.replace(/ /g, ''),
      },
    };

    const res = await getPayfortInformation(payload);
    dispatch(loadCartData());
    localStorage.setItem('OrderID', JSON.stringify(res.data.entity_id));
    localStorage.setItem('lod', JSON.stringify(res.data.entity_id));

    setPayfortData({
      ...res.data,
      card_number: card.cardNumber.replace(/ /g, ''),
      card_holder_name: card.cardHolder,
      card_security_code: card.cvv,
      expiry_date: `${card.monthYear.slice(3, 5)}${card.monthYear.slice(0, 2)}`,
    });

    payfortForm.current.submit();
  }
};

export const handleCheckoutcomCC = async (
  card,
  allFeildValidate,
  cardErrMsg,
  setCardErrMsg,
  selectedPaymentMethod,
  BASE_PAYMENT_REDIRECT_URL,
  getCheckoutInformation,
  dispatch,
  loadCartData
) => {
  const validateFeild = ['cardNumber', 'cardHolder', 'monthYear', 'cvv'];
  const formStatus = allFeildValidate(validateFeild, cardErrMsg);
  setCardErrMsg(formStatus.allErrMsg);
  if (formStatus.checkCardStatus === true) {
    const payload = {
      paymentMethod: {
        payment_method: selectedPaymentMethod,
        type: 'card',
        number: card.cardNumber.replace(/ /g, ''),
        expiry_month: card.monthYear.substr(0, 2),
        expiry_year: card.monthYear.substr(3, 2),
        name: card.cardHolder,
        cvv: card.cvv,
        success_url: `${BASE_PAYMENT_REDIRECT_URL}/checkout/success`,
        failure_url: `${BASE_PAYMENT_REDIRECT_URL}/checkout/failure`,
      },
    };

    const res = await getCheckoutInformation(payload);
    dispatch(loadCartData());
    localStorage.setItem('OrderID', JSON.stringify(res.order_id));
    localStorage.setItem('lod', JSON.stringify(res.order_id));
    if (res.threeds_url) {
      window.location.href = res.threeds_url;
    }
  }
};

export const handlePayfortInstallments = async (
  dispatch,
  card,
  loadCartData,
  selectedPaymentMethod,
  setPayfortData,
  custEmailAddress,
  getPayfortInformation,
  BASE_PAYMENT_REDIRECT_URL,
  payfortForm
) => {
  const newPaymentMethodForPayfort = {
    paymentMethod: {
      method: selectedPaymentMethod,
      referer_url:
        process.env.NODE_ENV === 'production'
          ? BASE_PAYMENT_REDIRECT_URL
          : BASE_PAYMENT_REDIRECT_URL,
      email: custEmailAddress,
      number: card.cardNumber.replace(/ /g, ''),
    },
  };
  const res = await getPayfortInformation(newPaymentMethodForPayfort);
  dispatch(loadCartData());
  localStorage.setItem('OrderID', JSON.stringify(res.data.entity_id));
  localStorage.setItem('lod', JSON.stringify(res.data.entity_id));

  setPayfortData({
    ...res.data,
    card_number: card.cardNumber.replace(/ /g, ''),
    card_holder_name: card.cardHolder,
    card_security_code: card.cvv,
    expiry_date: `${card.monthYear.slice(3, 5)}${card.monthYear.slice(0, 2)}`,
  });
  payfortForm.current.submit();
};

export const handleCashOnDelivery = async (
  selectedPaymentMethod,
  dispatch,
  placeOrderCOD,
  loadCartData,
  setRedirectPageUrl
) => {
  const params = {
    paymentMethod: {
      method: selectedPaymentMethod,
    },
  };

  const res = await placeOrderCOD(params);

  if (res.data.orderId !== undefined) {
    dispatch(loadCartData());
    localStorage.setItem('OrderID', JSON.stringify(res.data.orderId));
    localStorage.setItem('lod', JSON.stringify(res.data.orderId));
    const redirecPage = `/checkout/success`;
    setRedirectPageUrl(redirecPage);
  }
};

export const handleFree = async (
  dispatch,
  selectedPaymentMethod,
  placeOrderCOD,
  loadCartData,
  setRedirectPageUrl
) => {
  const params = {
    paymentMethod: {
      method: selectedPaymentMethod,
    },
  };

  const res = await placeOrderCOD(params);

  if (res.data.orderId !== undefined) {
    dispatch(loadCartData());
    let redirecPage = `/user/orders/${res.data.orderId}/confirm`;
    setRedirectPageUrl(redirecPage);
  }
};

export const handleTamaraInstalments = async (
  dispatch,
  custEmailAddress,
  loadCartData,
  getTamaraData,
  selectedPaymentMethod,
  BASE_PAYMENT_REDIRECT_URL
) => {
  const tamaraParams = {
    paymentMethod: {
      method: selectedPaymentMethod,
      referer_url:
        process.env.NODE_ENV === 'production'
          ? BASE_PAYMENT_REDIRECT_URL
          : BASE_PAYMENT_REDIRECT_URL,
      email: custEmailAddress,
    },
  };
  const res = await getTamaraData(tamaraParams);
  localStorage.setItem('OrderID', JSON.stringify(res.data.entity_id));
  localStorage.setItem('lod', JSON.stringify(res.data.entity_id));
  dispatch(loadCartData());
  window.location.href = res.data.url;
};

export const handleTabbyPayment = async (
  selectedPaymentMethod,
  services,
  navigate,
  placeOrderCOD,
  dispatch
) => {
  const params = {
    paymentMethod: {
      method: selectedPaymentMethod,
      development: process.env.NODE_ENV === 'development',
    },
  };

  const res = await placeOrderCOD(params);

  const { status, message, orderId, url } = res?.data;
  let path = '';
  localStorage.setItem('OrderID', JSON.stringify(orderId));
  localStorage.setItem('lod', JSON.stringify(orderId));
  setTimeout(() => {
    if (orderId) {
      path = '/checkout/failure';
    } else {
      path = '/';
      dispatch(services.notifyError({ message: message }));
    }
    if (status === true) {
      window.location.replace(url);
    } else {
      navigate(path, { replace: true });
    }
  }, 1000);
};
