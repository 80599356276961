import axios from "axios";

export const getOrderDetails = async (id, isOrderFailed) => {
  if (!isOrderFailed) {
    isOrderFailed = false;
  }
  let responseData = {};
  await axios
    .get(
      `${process.env.REACT_APP_PROJECT_API_URL}/V1/customer/orders/${id}/${isOrderFailed}`
    )
    .then((res) => (responseData = res.data));
  return responseData;
};
