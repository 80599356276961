import React, { useState, useEffect } from "react";
import "../../SCSS/MostSharedComponents/_CountdownBox.scss";
import Countdown from "react-countdown";

function CountdownBox({
  t,
  current,
  start,
  end,
  price,
  discountedPrice,
  product,
}) {
  const [started, setStarted] = useState(false);
  const [targetDate, setTargetDate] = useState(null);

  useEffect(() => {
    if (!start || !end) return;

    const now = new Date(current);
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (endDate < now) return;

    setStarted(startDate < now);
    setTargetDate(startDate < now ? endDate : startDate);

    return () => {
      setStarted(false);
      setTargetDate(null);
    };
  }, [start, end]);

  const discountPercentage = Math.round(100 - (discountedPrice / price) * 100);

  const discountedAmount = Math.round(price - discountedPrice);

  const now = new Date(current);
  const tDate = new Date(targetDate);
  const isGrtSeven = now >= new Date(tDate.setDate(tDate.getDate() - 7));

  return targetDate && isGrtSeven ? (
    <>
      {discountPercentage < 100 && discountPercentage > 0 && (
        <>
          <div className="countdown__area">
            {started && (
              <div className="discount__label__box">
                {discountPercentage < 100 && discountPercentage > 9 ? (
                  <h6 className="discount__amount">{`${discountPercentage}% ${t(
                    "OFF"
                  )}`}</h6>
                ) : (
                  ""
                )}
                {discountPercentage < 10 && discountPercentage > 0 ? (
                  <h6 className="discount__amount">{`${t(
                    "SAR"
                  )} ${discountedAmount} ${t("OFF")}`}</h6>
                ) : (
                  ""
                )}
                <p className="sale__label">
                  {t(started ? "Sale ends in" : "Sale starts in")}
                </p>
              </div>
            )}
            <Countdown
              key="pdp-countdown"
              date={targetDate}
              autoStart
              className="countdown__time__box"
              renderer={({ days, hours, minutes, seconds }) => (
                <div className="countdown__time__box">
                  <div className="countdown__itembox days">
                    <h6>{days}</h6>
                    <p>{t("DAYS")}</p>
                  </div>
                  <div className="countdown__itembox hours">
                    <h6>{hours}</h6>
                    <p>{t("HRS")}</p>
                  </div>
                  <div className="countdown__itembox minutes">
                    <h6>{minutes}</h6>
                    <p>{t("MINS")}</p>
                  </div>
                  <div className="countdown__itembox seconds">
                    <h6>{seconds}</h6>
                    <p>{t("SECS")}</p>
                  </div>
                </div>
              )}
            />
          </div>
        </>
      )}

      {/* <div className="countdown__area">
                <div className="discount__label__box">
                    <h6 className="discount__amount">50% OFF</h6>
                    <p className="sale__label">Sale ends in</p>
                </div>
                <div className="countdown__time__box">
                    <div className="countdown__itembox days">
                        <h6>5</h6>
                        <p>DAYS</p>
                    </div>
                    <div className="countdown__itembox hours">
                        <h6>9</h6>
                        <p>HRS</p>
                    </div>
                    <div className="countdown__itembox minutes">
                        <h6>19</h6>
                        <p>MINS</p>
                    </div>
                    <div className="countdown__itembox seconds">
                        <h6>40</h6>
                        <p>SECS</p>
                    </div>
                </div>
            </div> */}
    </>
  ) : null;
}
export default CountdownBox;
