import React, { useState, useEffect } from "react";
import PLPFilter from "./PLPFilter";
import "./../../SCSS/ProductListPage/_plpFilterProductBlock.scss";
import list_grey from "./../../assets/Icon/list_grey.svg";
import grid_grey from "./../../assets/Icon/grid_grey.svg";
import list_orange from "./../../assets/Icon/list_orange.svg";
import grid_orange from "./../../assets/Icon/grid_orange.svg";
import Heading7 from "../Font/Heading7";
import Text4 from "../Font/Text4";
import ProductNine from "../ProductType/ProductNine";
import ProductTen from "../ProductType/ProductTen";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from '../../lib/useInfiniteScroll'
import { DEFAULT_SORT } from "../helpers/utils/commonHelper";

const PLPFilterProductBlock = ({
  onFilter,
  filteredProductsData = {},
  handleChangeProductPopup,
  filterOptionData,
  updateCompareProduct,
  handleChangeCartPopup,
  redirectToPdpPage,
  filterInitData,
  handleClearFilter,
  loadMore,
  setPagNumber,
  isLoading,
  sortByPrice,
  categoryType,
  outOfStockConfiguration,
  screenName,
  customerDetails
}) => {
  const [selectedOption, setSelectedOption] = useState(DEFAULT_SORT);
  const [productListView, setProductListView] = useState("grid");
  const [isDone, setIsDone] = useState(true);
  // const memoSelectedOption = useMemo(()=>selectedOption,[selectedOption])

  const { t } = useTranslation()

const dropdownOptions = [
  {
    label: t('Low_to_High'),
    value: "asc",
  },
  {
    label: t('High_to_Low'),
    value: "desc",
  },
];

  useEffect(() => {
    setSelectedOption(sortByPrice)
  }, [sortByPrice]);
  const onSelectSortby = (e) => {
    onFilter("order", e.target.value);
    setSelectedOption(e.target.value);
    // onFilter("order", memoSelectedOption);
  };
  const fetchMoreData = () => {
    if((filteredProductsData?.total_count?.value || filteredProductsData?.total_count) === filteredProductsData?.items?.length  ){
      setIsDone(false);
      setPagNumber(1);
      return;
    }
    else {
      if(isLoading === true && (filteredProductsData?.total_count?.value || filteredProductsData?.total_count) !== 0){

        loadMore();
      }
    }
  }

  const onClickGridList = (type) => {
    if (type === "grid") {
      setProductListView("grid");
    }
    if (type === "list") {
      setProductListView("list");
    }
  };


  useInfiniteScroll(isDone, fetchMoreData);

  if (filteredProductsData && (filteredProductsData?.total_count?.value || filteredProductsData?.total_count) === 0) {
    return (
      <div className="PLP_dsk_noProduct_box">
        <h1>{t("No_Products_Available")}</h1>
      </div>
    )
  }

  return (
    <>
      <div className="plp__filter__product__container">
        <div className="plp__filter__product__title__block">
          <Heading7
            text={`${(filteredProductsData?.total_count?.value || filteredProductsData?.total_count) !== 0 &&
                filteredProductsData?.items.length
                ? (filteredProductsData?.total_count?.value || filteredProductsData?.total_count)
                : 0
              } ${t('Products')}`}
          />
          <div className="plp__filter__product__sortby__grid__button">
            <Text4 text={`${t('Sort_By')} : `} />
            {/* <label className="label">Sort by: &nbsp;</label> */}
            <select
              onChange={(e) => onSelectSortby(e)}
              className="plp__sortby__select__field"
            // className="_customselect"
              value={selectedOption}
            >
              {dropdownOptions.map(({ label, value }) => (
                <option
                  className="plp__sortby__options"
                  key={value + label}
                  value={value}
                  // selected={label === t('Low_to_High') ? "selected" : ""}
                >
                  {label}
                </option>
              ))}
            </select>

            <div className="plp__grid__button">
              <div
                className={
                  productListView === "grid"
                    ? "plp__grid__button__active"
                    : "plp__grid__button"
                }
                onClick={() => onClickGridList("grid")}
              >
                <img
                  className="grid__icon"
                  src={productListView === "grid" ? grid_orange : grid_grey}
                  alt=""
                />
              </div>
              <div
                className={
                  productListView === "list"
                    ? "plp__list__button__active"
                    : "plp__list__button"
                }
                onClick={() => onClickGridList("list")}
              >
                <img
                  className="list__icon"
                  src={productListView === "list" ? list_orange : list_grey}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row plp__filter__product__block">
          <div className="col-sm-3 plp__filter__block">
            <PLPFilter
              categoryType={categoryType}
              onFilter={onFilter}
              filterOptionData={filterOptionData}
              filterInitData={filterInitData?.filterDetails}
              handleClearFilter={handleClearFilter}
              filterHide={true}
            />
          </div>
          <div className="col-sm-9 plp__product__block">
           
            <div className="row plp__inner__product__block">
            
              {productListView === "grid" ? (
                <>
                  {(filteredProductsData?.total_count?.value || filteredProductsData?.total_count) !== 0 &&
                    filteredProductsData?.items.length !== 0 ? (
                    filteredProductsData?.items.map((product, productIndex) => {
                      return (
                        <div
                          key={productIndex}
                          className="col-xxl-4 col-lg-6 mb-3"
                        >
                          <ProductNine
                            product={product}
                            availablityData={filteredProductsData?.availablityData || []}
                            handleChangeProductPopup={handleChangeProductPopup}
                            updateCompareProduct={updateCompareProduct}
                            handleChangeCartPopup={handleChangeCartPopup}
                            redirectToPdpPage={redirectToPdpPage}
                            outOfStockConfiguration={outOfStockConfiguration}
                            productIndex={productIndex}
                            screenName={screenName}
                            customerDetails={customerDetails}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="PLP_dsk_noProduct_box">
                      <h1>{t("No_Products_Available")}</h1>
                    </div>
                  )}
                  {/* <PLPProduct productsData={peopleUltimatelyBoughtData} /> */}
                </>
              ) : productListView === "list" ? (
                <>
                  {(filteredProductsData?.total_count?.value || filteredProductsData?.total_count) !== 0 &&
                    filteredProductsData?.items.length !== 0 ? (
                    filteredProductsData?.items.map((product, productIndex) => {
                      return (
                        <div key={productIndex} className="col-sm-12 mb-3">
                          <ProductTen
                            product={product}
                            availablityData={filteredProductsData?.availablityData || []}
                            handleChangeProductPopup={handleChangeProductPopup}
                            updateCompareProduct={updateCompareProduct}
                            handleChangeCartPopup={handleChangeCartPopup}
                            redirectToPdpPage={redirectToPdpPage}
                            outOfStockConfiguration={outOfStockConfiguration}
                            productIndex={productIndex}
                            screenName={screenName}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <h1>{t("No_Products_Available")}</h1>
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
              {
                isLoading === false && isDone===true ? (
                    <h4 className="text-center">Loading... </h4>
                ) : ""
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PLPFilterProductBlock;
