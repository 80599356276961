import React from 'react';
import { useTranslation } from 'react-i18next'

const ErrorBoundaryUI = ({ error, errorInfo }) => {
  const { t } = useTranslation();
  return (
    <div style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: '20px', margin: '60px 20px',
    }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="326" height="128" viewBox="0 0 326 128">
        <g fill="none" fill-rule="evenodd">
          <g>
            <g transform="translate(-547 -279) translate(547 281)">
              <path fill="#6212BA" fill-opacity=".091" d="M179.623 9.75c5.835 0 10.566 4.746 10.566 10.6 0 5.854-4.73 10.6-10.566 10.6h96.603c5.836 0 10.566 4.746 10.566 10.6 0 5.854-4.73 10.6-10.566 10.6h33.208c5.835 0 10.566 4.746 10.566 10.6 0 5.854-4.73 10.6-10.566 10.6h-28.68c-5.835 0-10.565 4.746-10.565 10.6 0 5.854 4.73 10.6 10.566 10.6h9.056c5.836 0 10.566 4.746 10.566 10.6 0 5.854-4.73 10.6-10.566 10.6H69.434c-5.835 0-10.566-4.746-10.566-10.6 0-5.854 4.73-10.6 10.566-10.6H10.566C4.731 94.55 0 89.804 0 83.95c0-5.854 4.73-10.6 10.566-10.6h60.377c5.836 0 10.566-4.746 10.566-10.6 0-5.854-4.73-10.6-10.566-10.6H33.208c-5.836 0-10.566-4.746-10.566-10.6 0-5.854 4.73-10.6 10.566-10.6h60.377c-5.836 0-10.566-4.746-10.566-10.6 0-5.854 4.73-10.6 10.566-10.6h86.038zm135.877 85c5.799 0 10.5 4.701 10.5 10.5s-4.701 10.5-10.5 10.5-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5z"/>
              <path fill="#FFF" stroke="#1B2630" stroke-linecap="round" stroke-width="2.5" d="M113.268 124.75C94.895 124.75 80 110.253 80 92.37c0-17.882 14.895-32.379 33.268-32.379.782 0 1.557.026 2.324.078-.533-2.958-.812-6.004-.812-9.114 0-28.28 23.02-51.205 51.415-51.205 22.67 0 41.912 14.611 48.751 34.894 1.62-.169 3.266-.255 4.932-.255 25.472 0 46.122 20.228 46.122 45.18 0 23.706-18.637 43.264-42.341 45.148v.033H138.23m-6.231 0h-11"/>
              <circle cx="167" cy="96" r="8" stroke="#1B2630" stroke-width="2.5"/>
              <path stroke="#1B2630" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M144 78L154 69.803 144 62"/>
              <path stroke="#1B2630" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M180 78L190 69.803 180 62" transform="matrix(-1 0 0 1 370 0)"/>
              <path stroke="#1B2630" stroke-linecap="round" stroke-width="2.5" d="M181 15c9.686 2.592 17.333 10.25 20 20"/>
            </g>
          </g>
        </g>
      </svg>
      <p>{t('Something went wrong.')}</p>
      {process.env.NODE_ENV !== 'production' && (
      <details style={{ whiteSpace: 'pre-wrap' }}>
        {error && error.toString()}
        <br />
        {errorInfo?.componentStack}
      </details>
      )}
    </div>
  );
};

export default ErrorBoundaryUI;