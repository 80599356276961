import React, { useEffect } from 'react';
import {
    useNavigate
} from "react-router-dom";

function Page_Not_Found() {

    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    });

    return (
        <>
            
        </>
    );
}

export default Page_Not_Found;