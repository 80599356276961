import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import styles from '../SCSS/components/Slider.module.scss';

const Slider = ({
  align = 'start',
  type = 'carousel',
  interval = 0,
  currentLang,
  children,
  isStorePickup = false,
}) => {
  const scrollerRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const [scrollable, setScrollable] = useState(false);
  const [hovered, setHovered] = useState(false);

  const scrollRotation = (currentLang === 'ar' ? -1 : 1);

  const prev = () => {
    scrollerRef.current.scrollBy({ left: (scrollerRef.current.clientWidth * -scrollRotation), top: 0, behavior: 'smooth' });
  };

  const next = React.useCallback(() => {
    scrollerRef.current.scrollBy({ left: (scrollerRef.current.clientWidth * scrollRotation), top: 0, behavior: 'smooth' });
  },[scrollRotation]);



  useEffect(() => {
    const currentScrollerRef = scrollerRef?.current;
    if (!currentScrollerRef) return;

    const handleScrollable = () => {
      setScrollable(currentScrollerRef?.clientWidth < currentScrollerRef?.scrollWidth - 5);
    };
    const hideNavigation = () => {
      if (!prevRef?.current || !nextRef?.current) return;

      if (currentScrollerRef.scrollLeft * scrollRotation <= 5) {
        prevRef.current.style.display = 'none';
      } else {
        prevRef.current.style.display = 'flex';

        if (((currentScrollerRef.scrollLeft * scrollRotation) + currentScrollerRef.clientWidth) < currentScrollerRef.scrollWidth - 5) {
          nextRef.current.style.display = 'flex';
        } else {
          nextRef.current.style.display = 'none';
        }
      }
    };

    // Delay the call to handleScrollable
    setTimeout(handleScrollable, 0);
    const observer = new MutationObserver(handleScrollable);

    // Start observing the scrollerRef for child list changes
    observer.observe(currentScrollerRef, { childList: true });

    window.addEventListener('resize', handleScrollable);
    currentScrollerRef.addEventListener('scroll', hideNavigation);
    return () => {
      window.removeEventListener('resize', handleScrollable);
      if (currentScrollerRef) { currentScrollerRef.removeEventListener('scroll', hideNavigation); }
      // Disconnect the observer when the component is unmounted
      observer.disconnect();
    };
  }, [scrollerRef, prevRef, nextRef, scrollRotation]);
  useEffect(() => {
    const slide = () => {
      if (!hovered) {
        const scrolled = (scrollerRef.current.scrollLeft * scrollRotation) + scrollerRef.current.clientWidth;
        if (scrolled < scrollerRef.current.scrollWidth - 5) {
          next();
        } else {
          scrollerRef.current.scrollBy({ left: scrolled * -scrollRotation, top: 0, behavior: 'smooth' });
        }
      }
    };
    if (!scrollable || hovered || interval === 0) return;
    const timer = setInterval(() => {
      slide();
    }, interval);
    return () => clearInterval(timer);
  }, [scrollable, hovered, interval, next, scrollRotation]);

  return (
    <div
      className={clsx(styles.wrapper, type === 'carousel' && styles.isFullWidth)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {scrollable && <div className={clsx(styles.navigation, styles.prev, type === 'carousel' && styles.moveNav, isStorePickup && styles.storeNavigation, isStorePickup && styles.prevStore)} onClick={prev} ref={prevRef} style={{ display: 'none' }}>&lt;</div>}
      <div className={clsx(styles.scroller, styles[type], styles[align], scrollable && styles.scrollable)} ref={scrollerRef}>{children}</div>
      {scrollable && <div className={clsx(styles.navigation, styles.next, type === 'carousel' && styles.moveNav, isStorePickup && styles.storeNavigation, isStorePickup && styles.nextStore)} onClick={next} ref={nextRef}>&gt;</div>}
    </div>
  );
};

export default Slider;
