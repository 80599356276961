import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Heading3 from "../Font/Heading3";
import Heading5 from "../Font/Heading5";
import Heading7 from "../Font/Heading7";
import Text3 from "../Font/Text3";
import Text4 from "../Font/Text4";
import cancel_grey from "./../../assets/Icon/cancel_grey.svg";
import "./../../SCSS/Login/_otpModel.scss";
import * as services from './../../services/services'
import hide_password from "./../../assets/Icon/hide_password.svg";
import see_password from "./../../assets/Icon/see_password.svg";
import { customerSendResetPasswordEmailSuccess } from "../../services/customer/customer";
import { useTranslation } from "react-i18next";
import apiHelper from "../helpers/utils/apiHelper";
import { Spinner } from "../helpers/utils/spinner";

function ResetPasswordVerifyModel({ handleChangePopupMode, closeLoginPopup }) {

  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { loadingStartEnd } = useSelector((state) => state.commonReducer);
  const T_REQ_EMAIL = t('Email_or_Mobile_number_is_missing');
  const T_REQ_OTP = t('Otp_is_missing');
  const T_REQ_PASSWORD = t('Password_is_missing');
  const T_REQ_CONFIRM_PASSWORD = t('Confirm_Password_is_missing');
  const T_WEAK_PASSWORD = t('Password_must_contain_1_uppercase_1_lowercase_1_number_and_at_least_8_characters_Do_not_add_more_than_5_consecutive_characters_123456_qwerty');
  const T_PASSWORD_NOT_MATCHED = t('Password_not_matched');
  const { customerResetPasswordOTP } = useSelector((state) => state.customerReducer);

  const [isPassword, setIsPassword] = useState(true);
  const [isConfirmPassword, setIsConfirmPassword] = useState(true);
  const [updateErrMsg, setUpdateErrMsg] = useState(false);


  const [data, setData] = useState({
    otp: "",
    password: "",
    confirmPassword: ""
  });

  const [errMsg, setErrMsg] = useState({
    otp: "",
    password: "",
    confirmPassword: ""
  });

  useEffect(() => {
    if (updateErrMsg === true) {
      setErrMsg(errMsg);
      setUpdateErrMsg(!updateErrMsg)
    }
  }, [updateErrMsg]);

  const handleChange = async (event) => {
    let value = event.target.value;
    let name = event.target.name;
    if (name === "otp") {
      if (!isNaN(value)) {
        if (value.length < 7) {
          setData({ ...data, [name]: value });
        }
      }
    } else {
      setData({ ...data, [name]: value });
    }
    let manageErrMsg = await validateForm(event, errMsg, name, value);
    setErrMsg(manageErrMsg);
  };

  const validateForm = async (event, newErrObj, name, value) => {

    //A function to validate each input values
    switch (name) {
      case 'otp':
        if (isNaN(value) || !value) {
          newErrObj = { ...newErrObj, [name]: T_REQ_OTP }
        } else {
          newErrObj = { ...newErrObj, [name]: '' }
        }
        break;
      case 'password':
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_PASSWORD }
        } else {
          let params = {
            password: value,
            username: ''
          }
          let pwdStrength = await services.checkPasswordStrength(params);
          if (pwdStrength?.strength === "fair" || pwdStrength?.strength === "strong") {
            if (data.confirmPassword) {
              if (value === data.confirmPassword) {
                newErrObj = { ...newErrObj, [name]: "", confirmPassword: "" }
              } else {
                newErrObj = { ...newErrObj, [name]: "", confirmPassword: T_PASSWORD_NOT_MATCHED }
              }
            } else {
              newErrObj = { ...newErrObj, [name]: '' }
            }
          } else {
            newErrObj = { ...newErrObj, [name]: T_WEAK_PASSWORD }
          }
        }
        break;
      case 'confirmPassword':
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_CONFIRM_PASSWORD }
        } else {
          if (value === data.password) {
            newErrObj = { ...newErrObj, [name]: '' }
          } else {
            newErrObj = { ...newErrObj, [name]: T_PASSWORD_NOT_MATCHED }
          }
        }
        break;
      default:
        break;
    }
    return newErrObj;
  }

  const allFeildValidate = () => {

    let validateFeild = [
      {
        keyName: "otp",
        defaultMsg: T_REQ_OTP
      },
      {
        keyName: "password",
        defaultMsg: T_REQ_PASSWORD
      },
      {
        keyName: "confirmPassword",
        defaultMsg: T_REQ_CONFIRM_PASSWORD
      }
    ];

    let checkValueStatus = [];
    let checkErrStatus = [];

    let blankErrMsg = errMsg;
    validateFeild && validateFeild.map((val, i) => {
      let keyVal = data[val.keyName];
      let errVal = errMsg[val.keyName];
      if (keyVal === "") {
        blankErrMsg[val.keyName] = val.defaultMsg
      }

      if (keyVal !== "") {
        checkValueStatus.push('suc')
      }
      if (errVal === "") {
        checkErrStatus.push('err')
      }
    })
    setErrMsg(blankErrMsg);
    let checkPassStatus = false;
    if (checkValueStatus.length === validateFeild.length && checkErrStatus.length === validateFeild.length) {
      checkPassStatus = true;
    }
    setUpdateErrMsg(true);

    return checkPassStatus;
  };

  const handleSubmit = async () => {

    let checkPassStatus = allFeildValidate();

    if (checkPassStatus === true) {
      let params = {
        email: JSON.parse(localStorage.getItem("forgotPasswordData")).email,
        newPassword: data.password,
        resetToken: data.otp
      }
      dispatch(services.customerChangePasswordWithToken(params, t))
    }
  };

  const resendOtp = async () => {
    let params = {
      email: JSON.parse(localStorage.getItem("forgotPasswordData")).email
    }
    try {
      let response = await apiHelper(`/V1/customer/send-reset-password-email`, 'post', params, {});
      setCounter(20);
      dispatch(services.notifySuccess({ message: "Request send successfully" }));
      response.data['email'] = params.email
      localStorage.setItem("forgotPasswordData", JSON.stringify(response.data));
      // dispatch(customerSendResetPasswordEmailSuccess(response.data));
    } catch (error) {
      dispatch(services.notifyError({ message: error.response.data.message }));
    }
    // dispatch(services.customerSendResetPasswordEmail(params))
  }

  const closeAndResetPopupData = () => {
    setData({
      ...data,
      otp: "",
      password: "",
      confirmPassword: ""
    })
    setErrMsg({
      ...errMsg,
      otp: "",
      password: "",
      confirmPassword: ""
    })
    // dispatch(customerSendResetPasswordEmailSuccess(''))
    // closeLoginPopup();
    handleChangePopupMode("resetpassword")
  }


  const togglePassword = () => setIsPassword(!isPassword);
  const toggleConfirmPassword = () => setIsConfirmPassword(!isConfirmPassword);

  const [counter, setCounter] = useState(20);
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <div className="otpModel__container">
      <div className="otpModel__header">
        <Heading5 text={t("Reset_your_password")} color="#000000" />
        <img
          onClick={() => closeAndResetPopupData()}
          src={cancel_grey}
          alt="cancel"
          className="cancel__button"
        />
      </div>
      <div className="otpModel__content">
        <div className="otp__number__block">
          <Heading7 text={`${JSON.parse(localStorage.getItem("forgotPasswordData")).email}`} span={true} />
          {/* <button className="change__button">Change</button> */}
        </div>
        <Text4 text={t("Weve sent a One Time Password (OTP) to the mobile number or email. Please enter it to complete verification")} marginBottom={10} />
        <div className="main__form__field__block">
          {/* <p className="form__label">First Name</p> */}
          <Heading7 text={t("OTP")} marginBottom={10} />
          <sup className="inputs_validation">*</sup>
          <div className="field__block">
            <input
              type="text"
              placeholder={t("OTP")}
              className="form__field"
              id="otp"
              name="otp"
              value={data.otp}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {errMsg.otp && <p className="invalid__message">{errMsg.otp}</p>}
        </div>
        <div className="main__form__field__block">
          {/* <p className="form__label">Password</p> */}
          <Heading7 text={t("Password")} marginBottom={10} />
          <sup className="inputs_validation">*</sup>
          <div className="field__block">
            <input
              type={isPassword ? "password" : "text"}
              placeholder={t("Password")}
              autocomplete="new-password"
              className="form__field sentry-mask"
              id="password"
              name="password"
              value={data.password}
              onChange={(e) => handleChange(e)}
            />
            <a onClick={() => togglePassword()}>
              {isPassword ? (
                <img src={see_password} alt="" />
              ) : (
                <img src={hide_password} alt="" />
              )}
            </a>
          </div>
          {errMsg.password && <p className="invalid__message">{errMsg.password}</p>}
        </div>
        <div className="main__form__field__block">
          {/* <p className="form__label">Confirm Password</p> */}
          <Heading7 text={t("Confirm_Password")} marginBottom={10} />
          <sup className="inputs_validation">*</sup>
          <div className="field__block">
            <input
              type={isConfirmPassword ? "password" : "text"}
              placeholder={t("Confirm_Password")}
              className="form__field sentry-mask"
              autocomplete="new-password"
              id="confirmPassword"
              name="confirmPassword"
              value={data.confirmPassword}
              onChange={(e) => handleChange(e)}
            />
            <a onClick={() => toggleConfirmPassword()}>
              {isConfirmPassword ? (
                <img src={see_password} alt="" />
              ) : (
                <img src={hide_password} alt="" />
              )}
            </a>
          </div>
          {errMsg.confirmPassword && <p className="invalid__message">{errMsg.confirmPassword}</p>}
        </div>
        <button onClick={() => handleSubmit()} className="signup__button">
          {loadingStartEnd === true ? <Spinner /> : t("Change_My_Password")}
        </button>
        {counter !== 0 &&
          <div className="resendOtp__msg">
            <p>
              {t("Resend OTP in")}
              <span className="timer"> 00:{counter}</span>
            </p>
          </div>
        }
        {counter === 0 &&
          <button
            onClick={() => resendOtp()}
            className="resendOtp__button"
          >
            {t("Resend_OTP")}
          </button>
        }
        <div className="otp__or__block">
          <div className="otp__or__text__block">
            <p className="otp__or__text">{t("OR")}</p>
          </div>
          <div className="otp__mid__line"></div>
        </div>

        <button
          onClick={() => handleChangePopupMode("signin")}
          className="signin__with__password__button"
        >
          {t("Sign_In_with_Password")}
        </button>
      </div>
    </div>
  );
}

export default ResetPasswordVerifyModel;
