import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';


function SortByFacet({onFilter,mobileView=false}) {
    const constructParamFn = (item) => `${item.key}`;
    const {t} = useTranslation()

    const dropdownOptions = [
      {
        label: t('Low_to_High'),
        value: "asc",
      },
      {
        label: t('High_to_Low'),
        value: "desc",
      },
    ];
    
    const childrenFn = (item) => (
      <>
        <label>{item.label}</label>
      </>
    );
    // const handleChangeSortBy = (facetKey, item) => {
    //   onFilter(
    //     facetKey,
    //     item.value
       
    //   );
    // };
    const[sortBy,setSortBy] = useState("")

useEffect(()=>{
    onFilter("order",sortBy)
},[sortBy])
  return (
    <div className="main__facets__block">
    {!mobileView && <h1 className="main__facets__title">{t("Sort_By")}</h1>}
    {dropdownOptions.map((item, itemIndex) => {
      return (
        // <SingleFacet
        //   constructParamFn={constructParamFn}
        //   childrenFn={childrenFn}
        //   // key={`${Math.random(Math.random() * 100) + 1}${facetKey}`}
        //   key={itemIndex}
        //   facetData={facetData}
        //   facetKey={facetKey}
        //   item={item}
        //   onFilter={onFilter}
        // />
        <div className="facet__block" key={itemIndex}>
               <input type="radio" id="itemIndex" name="sortBy" value={item.value} onChange={()=>setSortBy(item.value)} />
         
          <p
            className="facet__item__label"
            // onClick={() => onFilter(facetKey, constructParamFn(item), facetData[facetKey].multiple_selectable)}
          >
            {childrenFn(item)}
          </p>
        </div>
      );
    })}
  </div>
  )
}

export default SortByFacet