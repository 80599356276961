import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CitySelection from "../Cards/CitySelection";
import Text4 from "../Font/Text4";
import Text5 from "../Font/Text5";
import BundleModal from "../MostSharedComponent/BundleModal";
import DeliveryInfo from "../MostSharedComponent/DeliveryInfoBox";

function MainStoreDelivery({ t, product, pageType }) {

    const [storeCodes, setStoreCodes] = useState(null);

    const [cityModal, setCityModal] = useState(false);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectCity, setSelectCity] = useState(null);
    const [cities, setCities] = useState([]);
    const [deliveryEnableMsg, setDeliveryEnableMsg] = useState(false);

    const cityLocationData = useSelector(
        (state) => state.appData.cityLocationData
    );
    useEffect(() => {
        setCities(cityLocationData);
    }, [cityLocationData]);

    useEffect(() => {
        if (product?.installmentMethod) {
            setStoreCodes(
                product.inventory_qty
                    ?.map((item) => Object.keys(item)[0])
                    .filter(
                        (code) => code !== 'RS97' && code !== 'RW10' && code !== 'RW20',
                    ),
            );
        }
    }, [product]);

    useEffect(() => {
        if (cities.length <= 0) return;

        const defaultCity = cities.find(
            (item) =>
                item.estimatedDays &&
                item.deliveryDate &&
                ((item.id === "23" && !item.isDefaultForDelivery) ||
                    item.isDefaultForDelivery)
        );
        if (defaultCity?.estimatedDays) {
            setSelectedCity(defaultCity);
            setSelectCity(defaultCity);
        } else {
            setSelectedCity(cities[0]);
            setSelectCity(cities[0]);
        }

        return () => setSelectedCity(null);
    }, [cities]);

    const isOutOfStock =
        product?.orderType === "out-of-stock" || product?.stock === 0;
    let isShippingEnabled = product?.enable_shipping;
    let productType = product?.item_type;

    const isDeliveryEnabled =
        !isOutOfStock && isShippingEnabled && productType === "simple";
    let additionalEstimatedDays = product?.additional_estimated_day;

    useEffect(() => {
        if (storeCodes?.length > 0 || isDeliveryEnabled) {
            setDeliveryEnableMsg(true);
        }
    }, [storeCodes, product]);

    return (
        <div className="delivery__wrapper">
            {pageType === "compare" && !deliveryEnableMsg &&
                <div className="row delivery__taken__area">
                    <div className="col-md-12 mb-2">
                        <Text5 text={t("Eligible For Delivery")} marginLeft={10} color="#9b9b9b" />
                        <Text4 text={t("Not Available")} marginLeft={10} />
                    </div>
                </div>
            }
            {(storeCodes?.length > 0 || isDeliveryEnabled) && (
                <DeliveryInfo
                    setCityModal={setCityModal}
                    t={t}
                    productId={product.id}
                    additionalEstimatedDays={additionalEstimatedDays}
                    // user={user}
                    selectedCity={selectedCity}
                />
            )}
            {(storeCodes?.length > 0 || isDeliveryEnabled) && (
                <BundleModal
                    title={t("Select your delivery location")}
                    modalShow={cityModal}
                    onClose={() => setCityModal(false)}
                    t={t}
                    content={
                        <div className="bundleCity">
                            <CitySelection
                                cityModal={cityModal}
                                productId={product.id}
                                allCities={cities}
                                // storeList={stores}
                                cities={cities.filter(
                                    (item) => !!item.estimatedDays
                                )}
                                onCancel={() => setCityModal(false)}
                                selectedCityId={selectedCity?.id}
                                t={t}
                                // storeCities={storeCities}
                                defaultCity={selectedCity}
                                selectedCity={selectedCity}
                                setSelectCity={setSelectCity}
                                selectCity={selectCity}
                            />
                        </div>
                    }
                    onConfirm={() => {
                        // setSelectBundleCity(selectedBundleCity);
                        setSelectedCity(selectCity);
                        setCityModal(false);
                    }}
                    onHide={() => {
                        setCityModal(false);
                        setSelectCity(selectedCity);
                    }}
                    size="md"
                    scrollable={true}
                ></BundleModal>
            )}
        </div>
    );
}

export default MainStoreDelivery;