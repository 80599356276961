import React from 'react';
import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function OpeningHoursModal({ modalShow, val, onHide }) {

  const { t } = useTranslation();

  const constructHour = (item) => {
    const [start, end] = item.split(' - ');
    const [startHour, startPeriod] = start.split(' ');
    const [endHour, endPeriod] = end.split(' ');
    return ` ${startHour} ${startPeriod} - ${endHour} ${endPeriod} `;
  };

  return (
    <>
      <Modal
        show={modalShow}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Opening_hours")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper" style={{ backgroundColor: "white" }}>
            <div className="item name">{val.name}</div>
            {val.openingHours && (
              <div className="hours">
                <div className="items">
                  {Object.keys(val.openingHours).map((item) => (
                    <div key={item} className="item">
                      <span>{item}</span>
                      <div className="hourItems">
                        {[...val.openingHours[item]].map((hour) => <label key={hour}>{constructHour(hour)}</label>)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default React.memo(OpeningHoursModal);
