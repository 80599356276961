import newVatPdf from "./../../src/assets/Flyer/newVat.pdf"

const currentLang = localStorage.getItem("i18next") || "en";

export const socialConfig = [
  {
    iconPath: "assets/Footer/facebook-footer.png",
    action: "https://www.facebook.com/109473638527400/posts/109488195192611/?substory_index=0&sfnsn=mo",
    alt: "facebook",
  },
  {
    iconPath: "assets/Footer/instagram.png",
    action: "https://www.linkedin.com/company/modern-electronics/",
    alt: "linkedin",
  },
  {
    iconPath: "assets/Footer/twitter-footer.png",
    action: "https://twitter.com/SonyWorldSA?t=O087GgrWJXQAUyBj1dPKYA&s=08",
    alt: "twitter",
  },
  {
    iconPath: "assets/Footer/whatsapp-footer.png",
    action: "https://api.whatsapp.com/send?phone=966535313461",
    alt: "whatsapp",
  },
];

// export const paymentCardConfig = [
//   {
//     iconPath: "assets/Footer/card_01.png",
//     alt: "american-express",
//   },
//   {
//     iconPath: "assets/Footer/card_02.png",
//     alt: "sa-local",
//   },
//   {
//     iconPath: "assets/Footer/card_03.png",
//     alt: "mada",
//   },
//   {
//     iconPath: "assets/Footer/card_04.png",
//     alt: "qitafa",
//   },
//   {
//     iconPath: "assets/Footer/card_05.png",
//     alt: "visa",
//   },
// ];

export const paymentCardConfig = [
  // {
  //   iconPath: "assets/Footer/maroof.png",
  //   alt: "maroof",
  //   externalUrl: "https://maroof.sa/250269",
  // },
  {
    iconPath: "assets/Footer/mada.png",
    alt: "mada",
  },
  // {
  //   iconPath: "assets/Footer/icn-amex@2x.png",
  //   alt: "icn-amex@2x",
  // },
  {
    iconPath: "assets/Footer/visa.png",
    alt: "visa",
  },
  {
    iconPath: "assets/Footer/mastercard-white.png",
    alt: "mastercard",
  },
  {
    iconPath: `assets/Footer/icon-qitaf-${currentLang}.png`,
    alt: "icn-qitaf@2x",
  },
  {
    iconPath: "assets/Footer/tamaraNew.png",
    alt: "tamaraNew",
  },
  {
    iconPath: "assets/Footer/meza_reward_footer.png",
    alt: "meza-en-green@2x",
    externalUrl: "https://eu.sharingan.capillarytech.com/app/mezarewards#!/home",
  },
  {
    iconPath: "assets/Footer/vat.png",
    alt: "vat",
    externalUrl: newVatPdf,
  },
];
