import React, { useState, useEffect } from "react";
import "./../../SCSS/Common/_footer.scss";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import footer_logo from "./../../assets/Footer/footer_logo.svg";
import mestores_footer_logo from "./../../assets/Footer/mestores_footer_logo.png";
import Text4 from "../Font/Text4";
import Heading7 from "../Font/Heading7";
import { paymentCardConfig, socialConfig } from "../../assetsConfig/footer";
import { useTranslation } from "react-i18next";
import installmentsPdf from "./../../assets/Flyer/installments-en.pdf";
import useWindowDimensions from "./../helpers/utils/useWindowDimensions";
import LoginWrapper from "../Login/LoginWrapper";
import { FOOTER_URL } from "../../globalConst";
import NotifySnackbar from "./notifySnackbar";

function Footer() {

  const currentLang = localStorage.getItem("i18next") || "en";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { customerDetails } = useSelector((state) => state.customerReducer);
  const [userLoginPopup, setUserLoginPopup] = useState(false);
  const { height, width } = useWindowDimensions();
  const [loginPopup, setLoginPopup] = useState(false);
  const [categoryPopup, setCategoryPopup] = useState(false);
  const [loginMode, setLoginMode] = useState("");
  const [loginWrapper, setLoginWrapper] = useState(false);

  useEffect(() => {
    // debugger
    function init() {
      const latestLoginWrapper = localStorage.getItem("loginWrapper");
      const latestLoginPopup = localStorage.getItem("loginPopup");
      setLoginWrapper(JSON.parse(latestLoginWrapper));
      setLoginPopup(JSON.parse(latestLoginPopup));
    }
    init();
  }, [
    JSON.parse(localStorage.getItem("loginWrapper")),
    JSON.parse(localStorage.getItem("loginPopup")),
    JSON.parse(localStorage.getItem("loginMode")),
  ]);
  const openLoginPopup = () => {
    setUserLoginPopup(!userLoginPopup);
    if (width > 992) {
      setLoginPopup(userLoginPopup ? false : true);
      localStorage.setItem("loginPopup", JSON.stringify(true));
      setCategoryPopup(false);
      setLoginMode("");
    }
  };


  const openLoginWrapper = (mode) => {
    // setLoginMode("signin");
    // setUserLoginPopup(!userLoginPopup);
    // if (width > 992) {
    // desktop
    setLoginPopup(userLoginPopup ? false : true);
    setCategoryPopup(false);
    setLoginMode(mode);
    setLoginWrapper(true);
    setUserLoginPopup(false);
    localStorage.setItem("loginMode", JSON.stringify(mode));
    localStorage.setItem("loginWrapper", JSON.stringify(true));
    localStorage.setItem("loginPopup", JSON.stringify(true));
    // } else {
    //   //mobile
    //   if (mode === "signup") {
    //     navigate("/mobile-signup");
    //   } else if (mode === "signin") {
    //     navigate("/mobile-signin");
    //   }
    // }
  };

  const closeLoginPopup = () => {
    if (document.querySelector(".signin__signup__popup")) {
      // reloadingHeader()
      const element = document.querySelector(".signin__signup__popup");
      element.classList.remove("signin__signup__popup");
      element.classList.add("signin__signup__popup__disable");
    }
    localStorage.setItem("loginMode", JSON.stringify("signin"));
    localStorage.setItem("loginWrapper", JSON.stringify(false));
    localStorage.setItem("loginPopup", JSON.stringify(false));
    setLoginMode("");
    setLoginPopup(false);
    setLoginWrapper(false);
  };

  const checkLoginAndRedirect = (event, path) => {
    event.preventDefault()
    if (customerDetails === "") {
      openLoginWrapper("signin");
    } else {
      navigate(path);
    }
    window.scrollTo(0, 0);
  };

  const d = new Date();
  let currentYear = d.getFullYear();

  return (
    <>
      <Helmet>
        <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v13.0"
          nonce="QjJ8D37F"></script>
      </Helmet>

      <div className="container-fluid footer__container">
        <div className="footer__block">
          <div className="row footer__row">
            <div className="col-12 col-sm-12 col-md-3 logo__social__row">
              <Link className="footer__logo___link" to="/">
                <img src={footer_logo} alt="logo" className="footer__logo" width="169.968" height="30" />
              </Link>
              {/* <div className="social__group">
                {socialConfig.map(({ iconPath, action, alt }, socialIndex) => {
                  return (
                    <a key={alt} href={action} target="_" aria-label={alt}>
                      <img
                        key={alt}
                        src={require(`./../../${iconPath}`)}
                        alt={alt}
                        className="social__icon"
                      />
                    </a>
                  );
                })}
              </div> */}
            </div>
            <div className="col-12 col-sm-12 col-md-9 footer__navbar__row">
              <div className="row">              
                <div className=" col-sm-6 col-md-3 footer__link__block">
                  <p className="footer__link__title">{t("about")}</p>
                  <Link
                    className="footer___link"
                    onClick={() => window.scrollTo(0, 0)}
                    to="/cms/about-us"
                  >
                    {t("about_us")}
                  </Link>
                  <Link
                    className="footer___link"
                    onClick={() => window.scrollTo(0, 0)}
                    to="/cms/terms-and-conditions"
                  >
                    {t("terms_and_conditions")}
                  </Link>
                  <Link
                    className="footer___link"
                    onClick={() => window.scrollTo(0, 0)}
                    to="/cms/privacy-policy"
                  >
                    {t("privacy_policy")}
                  </Link>
                  <Link
                    className="footer___link"
                    onClick={() => window.scrollTo(0, 0)}
                    to="/cms/secured-payment"
                  >
                    {t("secured_payment")}
                  </Link>
                  <Link
                    className="footer___link"
                    onClick={() => window.scrollTo(0, 0)}
                    to="/stores?id=stores"
                  >
                    {t("our_stores")}
                  </Link>
                  <Link
                    className="footer___link"
                    onClick={() => window.scrollTo(0, 0)}
                    to="/stores?id=centers"
                  >
                    {t("service_centers")}
                  </Link>
                </div>
                <div className="col-sm-6 col-md-3 footer__link__block">
                  <p className="footer__link__title">{t("account_info")}</p>
                  <Link
                    className="footer___link"
                    onClick={(e) => checkLoginAndRedirect(e, "/user/profile")}
                    to="#"
                  >
                    {t("my_account")}
                  </Link>
                  <Link
                    className="footer___link"
                    onClick={(e) => checkLoginAndRedirect(e, "/user/orders")}
                    to="#"
                  >
                    {t("order_history")}
                  </Link>
                  <Link
                    className="footer___link"
                    onClick={(e) => checkLoginAndRedirect(e, "/user/profile?page=addresses")}
                    to="#"
                  >
                    {t("address_book")}
                  </Link>
                  <Link
                    className="footer___link"
                    onClick={(e) => checkLoginAndRedirect(e, "/user/profile?page=change-password")}
                    to="#"
                  >
                    {t("change_passwords")}
                  </Link>
                  {/* <Link className="footer___link" to="/contact">
                    {t("contact_us")}
                  </Link> */}
                </div>
                <div className=" col-sm-6 col-md-3 footer__link__block">
                  <p className="footer__link__title">{t("quick_links")}</p>
                  <Link
                    className="footer___link"
                    onClick={() => window.scrollTo(0, 0)}
                    to="/contact"
                  >
                    {t("support")}
                  </Link>
                  <Link
                    className="footer___link"
                    onClick={() => window.scrollTo(0, 0)}
                    to="/cms/returns-and-exchange"
                  >
                    {t("exchange_and_return_policy")}
                  </Link>
                  <Link
                    className="footer___link"
                    onClick={() => window.scrollTo(0, 0)}
                    to="/cms/warranty-policy"
                  >
                    {t("warranty_policy")}
                  </Link>
                  {/* <Link
                    className="footer___link"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      window.open(require(`./../../assets/Flyer/installments-${currentLang}.pdf`));
                    }}
                    // target="_blank"
                    // href={require(`./../../assets/Flyer/installments-${currentLang}.pdf`)}
                    as={require(`./../../assets/Flyer/installments-${currentLang}.pdf`)}
                    to="#"
                  >
                    {t("installment")}
                  </Link> */}
                  <Link
                    className="footer___link"
                    onClick={() => window.scrollTo(0, 0)}
                    to="/cms/tamara"
                  >
                    {t("Tamara")}
                  </Link>
                  <Link
                    className="footer___link"
                    onClick={() => window.scrollTo(0, 0)}
                    to="/cms/tabby"
                  >
                    {t("Tabby")}
                  </Link>
                  <Link
                    className="footer___link"
                    onClick={() => window.scrollTo(0, 0)}
                    to="/cms/delivery-and-installation"
                  >
                    {t("Delivery_and_Installation")}
                  </Link>
                  {/* <Link
                    className="footer___link"
                    onClick={() => window.scrollTo(0, 0)}
                    to="/product-register"
                  >
                    {t("Warranty Product")}
                  </Link> */}
                </div>
                <div className=" col-sm-6 col-md-3 footer__link__block">
                  <p className="footer__email__title">{t("email")}</p>
                  <a
                    className="footer__email"
                    href="mailto: sonyworldsa@modern-electronics.com"
                  >
                    sonyworldsa@modern-electronics.com
                  </a>
                  <p className="footer__mobile__title">{t("toll_free_number")}</p>
                  <a className="footer__mobile" href="tel:8001207669">
                    8001207669
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row card__and__live__row">
            <div className="col-12 col-md-12 card__and__live__block">
              <div className="card__block">
                {paymentCardConfig.map(
                  ({ iconPath, alt, externalUrl }, socialIndex) => {
                    return (
                      <React.Fragment key={alt}>
                        {externalUrl !== "" ? (
                          <a href={externalUrl} target="_blank" rel="noreferrer">
                            <img
                              key={alt}
                              src={require(`./../../${iconPath}`)}
                              alt={alt}
                              className="card"
                              width='auto'
                              height={27}
                            />
                          </a>
                        ) : (
                          <img
                            key={alt}
                            src={require(`./../../${iconPath}`)}
                            alt={alt}
                            className="card"
                            width='auto'
                            height={27}
                          />
                        )}
                      </React.Fragment>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom__container">
        <div className="footer__bottom__block">
          <div className="row inner__footer__bottom__block">
            <div className=" col-12 col-sm-9 col-lg-10 copyright__text__block">
              <div className="copyright__text">
                <Text4
                  text={t("COPYRIGHT © {{ currentYear }} SONY MARKET DEVELOPMENT MEA FZCO. ALL RIGHTS RESERVED", { currentYear: currentYear, interpolation: { escapeValue: false } })}
                  textTransform="uppercase"
                  marginBottom={0}
                />
                <Heading7 text={t("disclaimer_1")} span={true} />
                <a
                  href={FOOTER_URL}
                  className="footer__bottom__link"
                >
                  <Heading7
                    text={t("sonyworld.sa")}
                    span={true}
                    marginLeft={5}
                  />
                </a>
                <Heading7
                  text={t("disclaimer_2")}
                  span={true}
                  marginLeft={5}
                />
              </div>
              <div className="terms-policy-area">
                <Link to="/cms/terms-and-conditions">
                  <Text4
                    text={t("terms_conditions")}
                    textTransform="uppercase"
                    span={true}
                  />
                </Link>
                <Link to="/cms/privacy-policy">
                  <Text4
                    text={t("privacy_policy")}
                    textTransform="uppercase"
                    span={true}
                    marginLeft={70}
                  />
                </Link>
              </div>
            </div>
            <img
              src={mestores_footer_logo}
              alt="mestore logo"
              className="col-6 col-sm-3 col-lg-2 mestores__footer__logo"
            />
          </div>
        </div>
      </div>
      <div className="footer__main__navbar__container">
      </div>
      <NotifySnackbar />
    </>
  );
}

export default Footer;
