export const TamaraCalculate = (months, total) => {
    const periods = [];
    const numberOfInstalments = months;
    const grandTotal = total * 100;
    const mod = grandTotal % numberOfInstalments;
    const payForEachMonth = (grandTotal - mod) / numberOfInstalments / 100;
    const dueToDay = parseFloat(((grandTotal - mod) / numberOfInstalments / 100) + (mod / 100)).toFixed(2);
    const myDate = new Date();
  
    periods.push({ label: 'Due today', amount: dueToDay, formatted_amount: dueToDay });
    for (let i = 1; i < numberOfInstalments; i++) {
      let label = i;
      if (i > 1) {
        label = myDate.setDate(myDate.getDate() + 30);
      } else {
        label = myDate.setDate(myDate.getDate() + 30);
      }
      periods.push({ label, amount: payForEachMonth, formatted_amount: payForEachMonth });
    }
    return periods;
  };
  