import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as services from "./../services/services";
import "./../SCSS/_myOrders.scss";
import sony_logo from "./../assets/Icon/sony_logo.svg";
import search from "./../assets/Icon/search.svg";
import BreadCrumbs from "../Components/BreadCrumbs";
import MyOrderProduct from "../Components/MostSharedComponent/MyOrderProduct";
import MyOrderProductV2 from "../Components/MostSharedComponent/MyOrderProductV2";
import Heading3 from "../Components/Font/Heading4";
import Heading7 from "../Components/Font/Heading7";
import Text3 from "../Components/Font/Text3";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MetaComponent from "../Components/Common/MetaComponent";
import NewLoader from "../Components/Common/newloader";
import TealiumTag from "../Components/helpers/utils/tealium";
import { impressionEvents } from "../Components/helpers/utils/ga/impressionEvents";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function My_Orders() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const orderCategory = [
    t("Recent_Orders"),
    t("Past_Orders"),
    t("Cancelled_Orders"),
    // "Buy Again"
  ];

  const query = useQuery();
  const getPage = query.get("page");

  const {
    customerOrderList,
    loading: customerOrderLoading,
  } = useSelector((state) => state.customerOrdersReducer);

  const [orderFilterList, setOrderFilterList] = useState({});

  const [orderList, setOrderList] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paginationArray, setPaginationArray] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [placedIn, setPlacedIn] = useState({ value: "", isFrom: "" });
  const navigate = useNavigate();
  const canceledStatus = ["canceled_cc_attempt", "canceled"];
  const currentLang = localStorage.getItem("i18next") || "en";
  const handleFilterStatus = (
    orderData,
    type = 0,
    page,
    search = "",
    place_date = ""
  ) => {
    let getOff = page || (getPage === null ? 1 : getPage);
    getOff = parseInt(getOff);
    let newOrderData = [];
    if (type === 0) {
      const last3Month = moment()
        .subtract(3, "months")
        .subtract(1, "day")
        .utc()
        .startOf("day")
        .format("YYYY/MM/DD");
      newOrderData = orderData?.items?.filter((item) => {
        const createdAt = moment(item?.created_at)
          .startOf("day")
          .format("YYYY/MM/DD");
        const isBetween = moment(createdAt).isBetween(
          last3Month,
          moment().add(1, "day").startOf("day").format("YYYY/MM/DD")
        );
        return isBetween && !canceledStatus?.includes(item?.state);
      });
    } else if (type === 1) {
      const last3Month = moment()
        .subtract(3, "months")
        .utc()
        .startOf("day")
        .format("YYYY/MM/DD");
      newOrderData = orderData?.items?.filter((item) => {
        const createdAt = moment(item?.created_at)
          .startOf("day")
          .format("YYYY/MM/DD");
        const isBefore = moment(createdAt).isBefore(last3Month);
        return isBefore && !canceledStatus?.includes(item?.state);
      });
    } else if (type === 2) {
      newOrderData = orderData?.items?.filter((item) =>
        canceledStatus?.includes(item?.state)
      );
    } else if (type === 3) {
      newOrderData = orderData?.items?.filter((item) => {
        let filter = null;
        if (search) {
          filter = item?.increment_id?.includes(search);
        }
        if (place_date) {
          const createdAt = moment(item?.created_at)
            .startOf("day")
            .format("YYYY/MM/DD");
          const isBetween = moment(createdAt).isBetween(
            place_date,
            moment().add(1, "day").startOf("day").format("YYYY/MM/DD")
          );
          if (filter != null) {
            filter = filter && isBetween;
          } else {
            filter = isBetween;
          }
        }
        return filter;
      });
    }
    setOrderFilterList({
      items: newOrderData?.slice((getOff - 1) * limit, getOff * limit),
    });
    setOrderList({ items: newOrderData });
    setTotalCount(newOrderData?.length);
    setOffset(parseInt(getOff));
  };

  useEffect(() => {
    if(customerOrderLoading){
      dispatch(services.loaderStart())
    }else{
      dispatch(services.loaderEnd())
    }
  }, [customerOrderLoading])
  
  useEffect(() => {
    let total_pages = Math.ceil(totalCount / limit);
    let pagiArray = Array.from({ length: total_pages }, (_, i) => i + 1);
    setPaginationArray(pagiArray);
  }, [totalCount, limit, offset]);

  useEffect(() => {
    let getOff = getPage === null ? 1 : getPage;
    let params = {
      limit: 500,
      offset: getOff,
      sort: "created_at",
      order: "DESC",
    };
    setOffset(parseInt(getOff));
    dispatch(services.getCustomerOrdersList(params));
  }, []);

  useEffect(() => {
    if (customerOrderList) {
      handleFilterStatus(customerOrderList);
      // setOrderList(customerOrderList);
    }
  }, [customerOrderList]);

  useEffect(() => {
    let getOff = getPage === null ? 1 : getPage;
    getOff = parseInt(getOff);
    setOffset(parseInt(getOff));
    setOrderFilterList({
      items: orderList?.items?.slice((getOff - 1) * limit, getOff * limit),
    });
  }, [getPage, limit]);
  useEffect(() => {
    impressionEvents.impressionGlobalVariable(
      currentLang, "Order Page"
    );
  }, [orderFilterList]);
  const [selectCategoryIndex, setSelectCategoryIndex] = useState(0);
  const orderCategorySelect = (categoryIndex, category) => {
    navigate(`?page=1`);
    setSelectCategoryIndex(categoryIndex);
    setSearchData("");
    handleFilterStatus(customerOrderList, categoryIndex, 1);
  };
  const handleChange = (e) => {
    let FromParam = [];
    if (e.target.value === "3_months") {
      FromParam = [3, "months"];
    } else if (e.target.value === "6_months") {
      FromParam = [6, "months"];
    } else if (e.target.value === "9_months") {
      FromParam = [9, "months"];
    } else if (e.target.value === "1_year") {
      FromParam = [1, "year"];
    }
    const isFrom = moment()
      .utc()
      .subtract(FromParam[0], FromParam[1])
      .format("YYYY/MM/DD");

    setPlacedIn({ value: e.target.value, isFrom });
    handleFilterStatus(customerOrderList, 3, 1, searchData, isFrom);
  };
  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchData(searchValue);
    if (searchValue || placedIn?.isFrom) {
      handleFilterStatus(
        customerOrderList,
        3,
        1,
        e.target.value,
        placedIn?.isFrom
      );
    } else {
      setSelectCategoryIndex(0);
      handleFilterStatus(customerOrderList, 0, 1);

    }
  };
  const handleResetFilter = () => {
    setSelectCategoryIndex(0);
    handleFilterStatus(customerOrderList, 0, 1);
    setPlacedIn({ value: "", isFrom: "" });
    setSearchData("");
  };
  return (
    <>
      <MetaComponent
        title={t("My_Orders")}
        meta_title={t("My_Orders")}
        meta_keywords={t("My_Orders")}
        meta_description={t("My_Orders")}
      />
     <TealiumTag name={t("My_Orders")} template={t("My_Orders")} />
      <BreadCrumbs
        back="/user"
        firstBread="/user"
        firstBreadTitle={t("my_account")}
        secondBread="/user/orders"
        secondBreadTitle={t("My_Orders")}
        highlight="secondBreadTitle"
      />
      {customerOrderLoading ? (
        <NewLoader />
      ) : (
        <div className="container-fluid myOrders__container">
          <div className="myOrders__block">
            <div className=" myOrders__inner__block">
              <div className="myOrders__title__block">
                <div className="myOrders__title">
                  <Heading3 text={t("My_Orders")} />
                </div>
                <div className="myOrders__right__part">
                  <Heading7 text={totalCount} span={true} />
                  <Text3
                    text={t("Order_placed_in")}
                    span={true}
                    marginLeft={5}
                  />
                  <select
                    onChange={handleChange}
                    className="mo__select__block"
                    name="timeDuration"
                    id="timeDuration"
                    value={placedIn?.value}
                  >
                    <option value="" disabled>
                      {t("Select")}
                    </option>
                    <option value="3_months">{t("Last_3_months")}</option>
                    <option value="6_months">{t("Last_6_months")}</option>
                    <option value="9_months">{t("Last_9_months")}</option>
                    <option value="1_year">1 {t("Year")}</option>
                  </select>
                  <div className="mo__search__box__block">
                    <div className="mo__search__box">
                      <input
                        type="text"
                        name="search"
                        className="mo__search__input"
                        placeholder={t("Search")}
                        onChange={handleSearch}
                        value={searchData}
                      />
                      <img src={search} alt="" className="header__icon" />
                    </div>
                  </div>
                  <button
                    className="mo__search__reset_filter"
                    onClick={handleResetFilter}
                  >
                    {t("clear_filter")}
                  </button>
                </div>
              </div>
              <hr className="mo__page__horizontal__line"></hr>

              <div className="mo__page__category__button__block">
                <div className="mo__page__button__main__block">
                  <div className="mo__page__button__block">
                    {!searchData &&
                      !placedIn?.value &&
                      orderCategory.map((category, categoryIndex) => {
                        return (
                          <button
                            key={categoryIndex}
                            onClick={() =>
                              orderCategorySelect(categoryIndex, category)
                            }
                            className={
                              selectCategoryIndex === categoryIndex
                                ? "mo__page__button__active"
                                : "mo__page__button"
                            }
                          >
                            {category}
                          </button>
                        );
                      })}
                  </div>
                </div>
              </div>

              {/* <MyOrderProduct product={product} />
            <MyOrderProduct product={product} />
            <MyOrderProduct product={product} /> */}
              <div className="mo__product__block__new">
                <div className="row ">
                  <div className="col-12">
                    <div className="row mo__order__details__row ">
                      <div className="col-lg-9">
                        <ul className="align-align-items-lg-start list-unstyled order__detail__list d-flex">
                          <li>
                            <Heading7
                              text={t("ORDER_#")}
                              color="#727272"
                              span={true}
                            />
                          </li>
                          <li>
                            <Heading7
                              text={t("Order_Placed")}
                              color="#727272"
                              span={true}
                            />
                          </li>
                          <li>
                            <Heading7
                              text={t("Total_Amount")}
                              color="#727272"
                              span={true}
                            />
                          </li>
                          <li>
                            <Heading7
                              text={t("Order_Status")}
                              color="#727272"
                              span={true}
                            />
                          </li>
                          <li>
                            <Heading7
                              text={t("Payment_Method")}
                              color="#727272"
                              span={true}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {orderFilterList.items &&
                orderFilterList.items.map((val, i) => {
                  return (
                    <MyOrderProductV2 product={val} pageType="order-list" key={i} />
                  );
                })}

              {/* <MyOrderProductV2 />
            <MyOrderProductV2 />
            <MyOrderProductV2 />
            <MyOrderProductV2 /> */}
              <div className="custom__pagination">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    {offset !== 1 && paginationArray.length > 0 && (
                      <li className="page-item ">
                        <Link
                          className="page-link"
                          to={`?page=${offset - 1}`}
                          tabindex="-1"
                          aria-disabled="true"
                        >
                          &lt;
                        </Link>
                      </li>
                    )}
                    {paginationArray &&
                      paginationArray.map((item, i) => {
                        return (
                          <React.Fragment key={item}>
                            <li
                              key={item}
                              className={`page-item ${item === offset ? "active" : ""
                                }`}
                            >
                              <Link className="page-link" to={`?page=${item}`}>
                                {item}
                              </Link>
                            </li>
                          </React.Fragment>
                        );
                      })}
                    {offset !== paginationArray.length &&
                      paginationArray.length >= offset && (
                        <li className="page-item">
                          <Link className="page-link" to={`?page=${offset + 1}`}>
                            &gt;
                          </Link>
                        </li>
                      )}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <NewsLetter /> */}
      {/* <Footer /> */}
    </>
  );
}

export default My_Orders;
