import React from 'react';
import styles from '../../SCSS/components/Meza/SubsSectionContent.module.scss';
import { useDispatch } from 'react-redux';
import InfoMeza from './InfoMeza';
import { setGeneralModalData, toggleLogistrationMode, toggleLogistrationState } from '../../redux/appAction';

const SubsSectionContent = ({
  t, meza_subscriber_price, user, discounted_price, og_price, isPDP,
}) => {
  const dispatch = useDispatch();
  const setLogistrationMode = (status) => dispatch(toggleLogistrationMode(status));
  const setLogistrationState = () => dispatch(toggleLogistrationState('register'));

  let price = Number(meza_subscriber_price) || 0;
  const priceCheck = price === 0;
  if (price < 1) {
    price = parseFloat(discounted_price || og_price).toFixed(2);
  }

  const openRegisterModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setGeneralModalData({ type: 'logistration' });
    setLogistrationMode(true); 
    setLogistrationState();
  };

  return (
    <div className={isPDP ? styles.subscribeSectionContentPDP : styles.subscribeSectionContent}>
      <div className={styles.textSection}>
        <span className={styles.text}>
          <h2>
            {t('MEZA')}
            <InfoMeza btnTitle={t('MEZA')} location={isPDP ? 'pdp' : 'plp'} />
          </h2>
          <h3>{priceCheck ? (user ? t('PURCHASE TO EARN') : t('SUBSCRIBE TO EARN')) : t('SUBSCRIBER PRICE')}</h3>
          {isPDP && !priceCheck && <span className={styles.price}>{`${parseFloat(price).toFixed(2)} SR`}</span>}
        </span>
      </div>
      {user && !priceCheck && <div className={styles.tick} />}

      <div
        className={styles.priceSection}
        style={!isPDP ? { justifyContent: ((priceCheck || user) ? 'center' : 'space-between') } : {}}
      >
        {!isPDP && !priceCheck && <span className={styles.price}>{`${parseFloat(price).toFixed(2)} SR`}</span>}
        {!user && (
        <span className={styles.highlight} onClick={openRegisterModal}>
          {t('Subscribe Now!')}
        </span>
        )}
      </div>
    </div>
  );
};

export default SubsSectionContent;
