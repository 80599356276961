import Banner from '../Banner';
import DealsOfTheWeekMobile from '../DealsOfTheWeekMobile';
import HomePageCategoryBlock from '../HomePageCategoryBlock';
import ProductContainer from '../ProductContainer';
import { impressionEvents } from '../helpers/utils/ga/impressionEvents';
import SingleBanner from '../SingleBanner';
import MainFeatureProducts from "../MainFeatureProducts";
import ErrorBoundary from '../ErrorBoundary';
import '../../SCSS/_homeEntertainment.scss';

const CmsHomeWeb = (
  item,
  index,
  handleChangeCartPopup,
  outOfStockConfiguration,
  availablityData,
  bannerClick,
  currentLanguageCode,
  customerDetails,
  t
) => {
  // no need to do intersection checks since banner is loaded in viewport always
  if (
    item?.type === 'multiple_banner' &&
    item?.element_id === 'main_banner_sliders'
  ) {
    impressionEvents.impressionGlobalVariable(currentLanguageCode, 'Home Page');
    return (
      <ErrorBoundary>
        <Banner bannerFullData={item} bannerData={item.items} />
      </ErrorBoundary>
    );
  }


  const renderer = () => {
    switch (item?.type) {
      case 'multiple_banner':
        if (item?.element_id === 'deals_of_the_week_slider') {
          return (
            <ErrorBoundary>
              <DealsOfTheWeekMobile
                dealsOfTheWeekData={item?.items}
                sectionTitle={item?.title}
              />
            </ErrorBoundary>
          );
        }
        break;
      case 'rich_text':
          return (
            <ErrorBoundary>
              {item?.content && <div className="container" dangerouslySetInnerHTML={{ __html: item?.content }}/>}
            </ErrorBoundary>
          );
      case 'slider':
        if(item?.element_id === "homepage_category_two"){
          return(
            <ErrorBoundary>
              <MainFeatureProducts
                featureData={
                  item?.products !== undefined
                    ? item?.products
                    : []
                }
                handleChangeCartPopup={handleChangeCartPopup}
                sectionTitle={item?.title}
                outOfStockConfiguration={outOfStockConfiguration}
                screenName="Home"
                availablityData={availablityData}
                sectionId = {item?.element_id}
                t={t}
                customerDetails={customerDetails}
              />
           </ErrorBoundary>
          );
        }
        return (
          <ErrorBoundary>
              <ProductContainer
                index={index}
                handleChangeCartPopup={handleChangeCartPopup}
                sectionTitle={item?.title}
                carouselData={item?.products}
                containerClassName={'new__arrival__pc__block'}
                cartIconOnly={true}
                outOfStockConfiguration={outOfStockConfiguration}
                screenName="Home"
                availablityData={availablityData}
                sectionId={item?.element_id}
                t={t}
                customerDetails={customerDetails}
              />
           </ErrorBoundary>
        );
      case 'category':
        return (
          <ErrorBoundary>
            <div className="d-none d-lg-block">
              <HomePageCategoryBlock sectionId={item?.element_id} />
            </div>
          </ErrorBoundary>
        );
      case 'single_banner':
        if(item?.element_id === "recently_viewed_section_group"){
          return;
        }
        return (
          <ErrorBoundary>
            <SingleBanner 
              index={index}
              bannerClick={bannerClick}
              item={item}
            />
         </ErrorBoundary>
        );
      default:
        return <></>;
    }
  };

  return <div key={`renderer_${item?.element_id}_${index}`}>{renderer()}</div>;
};

export default CmsHomeWeb;
