import ImageRender from '../ImageRender';
import React from 'react';
import styles from '../../SCSS/components/BannerCard.module.scss';
import clsx from 'clsx';

// .replace('pub/', `cdn-cgi/image/w=${isMobile ? 480 : 960},q=60,f=auto/pub/`)
const BannerCard = ({
  data, className, innerType, idx,
}) => (
  <>
    {data?.deeplinkUrl ? (
      <a key={`${data?.deeplinkUrl}_cms_${idx}`} className={clsx(styles.wrapper, innerType === 'slider' && styles.isFullWidth, className)} href={data?.deeplinkUrl} aria-label={data?.deeplinkUrl}>
        <ImageRender src={data?.imageUrl} alt={data?.imageUrl} />
      </a>
    ) : (
      <div key={`banner_picture_cms_${idx}`} className={clsx(styles.wrapper, innerType === 'slider' && styles.isFullWidth, className)}>
        <ImageRender src={data?.imageUrl} alt={data?.imageUrl} />
      </div>
    )}
  </>
);

export default BannerCard;
