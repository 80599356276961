import { dataLayerPush as dataLayerPushAction, dataLayerGroupPush as dataLayerGroupPushAction } from './dataLayerPush';

export const dataLayerPush = dataLayerPushAction;
export const dataLayerGroupPush = dataLayerGroupPushAction;

export const ga = (arg1, arg2, arg3) => {
  if (typeof window !== 'undefined' && window.ga) {
    // console.log('ga', arg1, arg2, arg3);
    window.ga(arg1, arg2, arg3);
  }
};
