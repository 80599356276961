import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "react-bootstrap";
import LoginWrapper from "../Login/LoginWrapper";
import { useEffect } from "react";
import { VscChromeClose } from "react-icons/vsc";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { customerSignUpOtpSuccess } from "../../services/customer/customer";
import { useDispatch, useSelector } from "react-redux";

function SignSignupTabModal({ handleChangeCartIconTotal, loginMode, closeLoginPopup, loginPopup, setLoginMode }) {

    const { t } = useTranslation();
    const [key, setKey] = useState('login');
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { customerSignUpOtpData } = useSelector(
        (state) => state.customerReducer
    );

    const redirectToCartPage = () => {
        closeLoginPopup();
        navigate("/cart");
    };

    useEffect(() => {
        if (loginMode) {
            if (loginMode === 'otp') {
                setKey('signup');
                setLoginMode('signup');
            } else {
                if (loginMode === 'signup') {
                    if (customerSignUpOtpData?.mobileNumber) {
                        dispatch(customerSignUpOtpSuccess(""));
                        setKey('signup');
                    }
                }
                setKey(loginMode);
            }
        }
    }, [loginMode]);

    const handleChangeKey = (k) => {
        setKey(k)
        setLoginMode(k)
    }

    return (
        <>
            {location.pathname !== "/checkout/delivery" ? (
                <button onClick={() => closeLoginPopup()} className="signin__signup__popup__closeBtn" >
                    <VscChromeClose />
                </button>
            ) : (
                <button onClick={() => redirectToCartPage()} className="signin__signup__popup__closeBtn" >
                    <VscChromeClose />
                </button>
            )}
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => handleChangeKey(k)}
            // className="mb-3"
            >
                <Tab eventKey="login" title={t("Login")}>
                    <LoginWrapper
                        handleChangeCartIconTotal={handleChangeCartIconTotal}
                        loginMode={loginMode}
                        closeLoginPopup={closeLoginPopup}
                    />
                </Tab>
                <Tab eventKey="signup" title={t("Register")}>
                    <LoginWrapper
                        handleChangeCartIconTotal={handleChangeCartIconTotal}
                        loginMode={loginMode}
                        closeLoginPopup={closeLoginPopup}
                    />
                </Tab>
            </Tabs>
        </>
    );
}

export default SignSignupTabModal;