import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./../../SCSS/Popup/_cartPopup.scss";
import cancel_grey from "./../../assets/Icon/cancel_grey.svg";
import success_orange from "./../../assets/Icon/success_orange.svg";
import Heading3 from "../Font/Heading3";
import Heading7 from "../Font/Heading7";
import Heading6 from "../Font/Heading6";
import Price from "../Font/Price";
import Text4 from "../Font/Text4";
import { Link, useNavigate } from "react-router-dom";
import { loadCartData } from "../../redux/appAction";
import { useTranslation } from "react-i18next";
import { SmallImageResize } from "../helpers/utils/imageResize";
import LazyImageForProduct from "../MostSharedComponent/LazyImageForProduct";
import CartPopupProduct from "../MostSharedComponent/CartPopupProduct";
import * as services from "../../services/services";
import { Button } from "react-bootstrap";
import ApplePay from "../ApplePay/ApplePay";
import VisaHolderNote from "../ApplePay/VisaHolderNote";
import { isApplePaySupported } from "../helpers/utils/applePay";

function CartPopup({ closeCartPopup, handleChangeCartIconTotal }) {
  const [cartProductData, setCartProductData] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [bundleStatus, setBundleStatus] = useState(0);
  const [qtyIsDisabled, setQtyIsDisabled] = useState(false);
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(null);
  const [cartTotalData, setCartTotalData] = useState();
  let getData = JSON.parse(localStorage.getItem("handShakeConfig"));

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { t } = useTranslation();

  useEffect(() => {
    //page_speed=>Multiple api call of home so,Remove this api 
    // dispatch(loadHomePageData());
    // if(!window.location.pathname.includes("/checkout/payment")){
    dispatch(loadCartData())
    // }
  }, [])

  const cartData = useSelector((state) => state.appData.cartData);

  // useEffect(() => {
  //   if (Object.values(homepageData)?.length !== 0) {
  //     let getTopTrendingData = homepageData?.content?.filter(data => data.type === "slider" && data.element_id === "homepage_category_five");
  //     if (getTopTrendingData?.length > 0) {
  //       let firstFiveData = getTopTrendingData[0]?.products?.filter(
  //         (val, i) => i < 5
  //       );
  //       setTopTrendingData(firstFiveData);
  //     }
  //   }
  // }, [homepageData]);
  useEffect(() => {
    setCartTotalData(cartData.totals_data);
  }, [cartData])
  useEffect(() => {
    if (Object.values(cartData)?.length !== 0) {
      setCartProductData(cartData?.items);
      // setCartTotal(cartData?.totals_data?.grand_total);
      setCartTotal(cartData?.totals_data?.base_grand_total);


      handleChangeCartIconTotal(cartData?.items?.length);
      setBundleStatus(cartData?.conflict_status);
      // setCartTotalData(cartData.totals_data);
      // setLoading(false);
    }
  }, [cartData]);

  useEffect(() => {
    const checkApplePay = async () => {
      setIsApplePayAvailable(await isApplePaySupported());
    };
    checkApplePay();
  }, []);

  // if (loading) {
  //   return <h1>Product Loading...</h1>;
  // }
  useEffect(() => {
    if (cartProductData?.length == 0) {
      closeCartPopup();
    }

  }, [cartProductData?.length])

  return (
    <div className="cart__popup__block">
      <div className="cart__popup__header">
        <Heading3 text={t('Preview')} color="#000000" />
        <img
          onClick={() => closeCartPopup()}
          src={cancel_grey}
          alt="cancel"
          className="cancel__button"
        />
      </div>
      <div className={`cart__popup__content ${cartData?.is_virtual && isApplePayAvailable && "apple_pay_active"}`}>
        {
          bundleStatus === 1 && cartData?.totals_data?.extension_attributes?.is_bundle && (
            <div class="alert alert-success  green-alert-info margin-alert" role="alert" style={{ width: '80%' }}>
              {t("success_alert")}
            </div>
          )
        }
        {
          bundleStatus === 2 && !cartData?.totals_data?.extension_attributes?.is_bundle && (
            <div class="alert alert-danger red-alert-info margin-alert" role="alert" style={{ width: '80%' }}>
              {t("failed_alert")}
            </div>
          )
        }
        <div className="cart__popup__listing__area">
          {cartProductData?.length !== 0 && cartProductData?.map((product, productIndex) => {
            return (
              <div key={product.item_id} className={`row cart__popup__product__block ${qtyIsDisabled === true ? "is_disabled_product_qty" : ""}`}>
                <div className="col-4 cart__popup__product__image__block">
                  <LazyImageForProduct
                    product={product?.product}
                    srcSetReSize={SmallImageResize}
                    alt=""
                    className="cart__popup__product__image"
                  />
                </div>
                <div className="col-8">
                  <Heading7
                    text={product?.name}
                    marginLeft={0}
                    marginBottom={10}
                  />
                  <div className="align-middle">
                    <img src={success_orange} alt="" className="align-bottom" />{" "}
                    <Text4 text={t('Added_to_Cart')} span={true} />
                  </div>
                  <CartPopupProduct
                    product={product}
                    setQtyIsDisabled={setQtyIsDisabled}
                    closeCartPopup={closeCartPopup}
                  />
                </div>
              </div>
            );
          })}
        </div>
        {cartTotalData?.discount_amount !== 0 && (
          <div className="cart__discount__block">
            <Heading6 text={t('Discount')} />
            <Price
              price={cartTotalData?.discount_amount - (-cartTotalData?.discount_amount * 0.15)}
              size="heading7"
              currency={cartTotalData && cartTotalData?.base_currency_code}
            />
          </div>
        )}

        <div className="cart__total__block">
          <Heading6 text={t('Cart_Total')} />
          <Price price={cartTotal && cartTotal} size="heading6" currency="SAR" />
        </div>
        {/* {cartData?.is_virtual && isApplePayAvailable && (
          <VisaHolderNote t={t} />
        )} */}
        <div className="cart__popup__button__block">
          <Button className="checkout__button"
            onClick={() => {
              localStorage.removeItem("last-dt");
              // { getData?.user?.userLoggedIn == true ? navigate("/checkout/delivery") : navigate("/checkout/signin")}
              navigate("/cart")
              closeCartPopup()
            }}
          >
            {t("Go to Cart")}
          </Button>
          <Link className="continue__shopping__button" to="#" onClick={() => closeCartPopup()}>
            {t("Continue_Shopping")}
          </Link>
        </div>
        <div className="cart__popup__button__block" >
          {/* {cartData?.is_virtual && <ApplePay cart={cartData} t={t} closeCartPopup={closeCartPopup} total={cartData?.totals_data?.base_grand_total} />} */}
        </div>
      </div>
    </div>
  );
}

export default CartPopup;
