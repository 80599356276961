import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./../../SCSS/ProductComparison/_productComparisonBlock.scss";
import Text4 from "./../Font/Text4";
import Text5 from "./../Font/Text5";
import Heading5 from "../Font/Heading5";
import Heading3 from "../Font/Heading3";
import Heading7 from "../Font/Heading7";
import pickup_store from "./../../assets/Icon/pickup_store.svg";
import _uniqBy from "lodash.uniqby";
import { useTranslation } from "react-i18next";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import ProductNine from "../ProductType/ProductNine";
import MainStoreDelivery from "../Pdp/MainStoreDelivery";
import MainStorePickup from "../Pdp/MainStorePickup";

const ProductComparisonBlock = ({
  productList,
  removeProductFromCompare,
  handleChangeCartPopup,
  outOfStockConfiguration,
  keySpecList,
  storePickupGlobalConfiguration,
  customerDetails
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getProductOptionFilter = (productObj, filterId) => {
    let productValue = productObj?.options?.filter(
      (item) => item.code === filterId
    );
    let valData = "-";
    if (productValue?.length > 0) {
      valData = productValue[0]?.selected_option?.labelArr[0];
      if (productValue[0].code === "express_shipping_eligible" || productValue[0].code === "lighting_shipping_eligible") {
        valData = productValue[0].visibility === true ? 'Yes' : 'No';
      }
    }
    return valData;
  };

  const redirectToPdpPage = (url_key, sku) => {
    navigate(productUrlHelper(url_key, sku));
  }

  return (
    <>
      {productList && productList?.length > 0 &&
        <div className="row product__comparison__info__area" key="product__comparison">
          <div className="col-sm-12 product__comparison__itembox_listbox">
            <div className="row">
              {productList && productList?.length > 0 && productList.map((product, index) => {
                return (
                  <div
                    key={product?.name}
                    className="col-10 col-md-4 col-xl-3 mb-3"
                  >
                    <ProductNine
                      product={product}
                      // handleChangeProductPopup={handleChangeProductPopup}
                      // updateCompareProduct={updateCompareProduct}
                      handleChangeCartPopup={handleChangeCartPopup}
                      redirectToPdpPage={redirectToPdpPage}
                      outOfStockConfiguration={outOfStockConfiguration}
                      productIndex={index}
                      screenName="Compare"
                      isCompareShow={false}
                      removeProductFromCompare={removeProductFromCompare}
                      availablityData={product?.availablityData}
                      customerDetails={customerDetails}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-sm-12 product__comparison__all__specification__block">
            <Heading3 text={t("All_Specification")} marginBottom={20} />
            <div className="row specification__title__block">
              <div className="col-sm-12">
                <Heading5 text={t("Key_Specs")} marginBottom={26} />
                <div className="col-md-12 px-3">
                  <div className="row specification__listing__area mt-2 mb-3 ">
                    {keySpecList &&
                      keySpecList.map((item, i) => {
                        return (
                          <div className={`col-sm-12 specification__category__block ${(i + 1) % 2 == 0 ? `even` : `odd`}`} key={`specification_${i}`}>
                            <div className="row specification__subcategory__block">
                              {productList &&
                                productList.map((pro, j) => {
                                  return (
                                    <div className="col-sm-3 specification__subcategory__title" key={j}>
                                      <Text5 text={item.key} textAlign="left" marginLeft={10} color="#9b9b9b" />
                                      <Text4 text={getProductOptionFilter(pro, item.id)} textAlign="left" marginLeft={10} />
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 product__comparison__availability">
            <Heading3 text={t("Availability")} marginBottom={20} />
            <div className="row product__comparison__availability__container">
              {productList &&
                productList.map((pro, j) => {
                  let estimatedDay = 0;
                  let estimatedData = pro?.options?.filter(val => val.code === "estimated_time");
                  if (estimatedData?.length > 0) {
                    estimatedDay = estimatedData[0].selected_option.labelArr[0]
                  }
                  return (
                    <div className="col-sm-3 product__comparison__availability__block" key={`availability_${j}`}>
                      <MainStoreDelivery t={t} product={pro} pageType="compare" />
                      {/* <div className="row "> */}
                      {/* <div className="col-sm-12 product__comparison__availability__block__item"> */}
                      <MainStorePickup t={t} product={pro} storePickupGlobalConfiguration={storePickupGlobalConfiguration} pageType="compare" />
                      {/* </div> */}
                      {/* </div> */}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default ProductComparisonBlock;
