import React, { Suspense, lazy } from "react";
import Skeleton from "react-loading-skeleton";
import {
  Route,
  Routes,
} from "react-router-dom";
import NewLoader from "../Components/Common/newloader";
import { PrivateRoutes, BeforeLoginRoutes } from "../Components/helpers/utils/privateRoutes";
import CheckoutSignIn from "../Pages/Checkout/CheckoutSignIn";
import Delivery from "../Pages/Checkout/Delivery";
import Payment from "../Pages/Checkout/Payment";
import Unsubscribe_Page from "../Pages/Unsubscribe_Page"

const Home = lazy(() => import("./../Pages/Home"));
const Services = lazy(() => import("./../Pages/Services"));
const Product_Details_Page = lazy(() => import("./../Pages/Product_Details_Page"));
const Create_Bundle_Page = lazy(() => import("./../Pages/Create_Bundle_Page"));
const Cart_Page = lazy(() => import("./../Pages/Cart_Page"));
const Dashboard = lazy(() => import("./../Pages/Dashboard"));
const My_Orders = lazy(() => import("./../Pages/My_Orders"));
const Order_Details = lazy(() => import("./../Pages/Order_Details"));
const All_Category_Page = lazy(() => import("./../Pages/All_Category_Page"));
const Product_List_Page = lazy(() => import("./../Pages/Product_List_Page"));
const My_Wishlists_Page = lazy(() => import("../Pages/My_Wishlists_Page"));
const My_Profile_Page = lazy(() => import("../Pages/My_Profile_Page"));
const Product_Comparison = lazy(() => import("../Pages/Product_Comparison"));
const Confirm_Order_Page = lazy(() => import("../Pages/Confirm_Order_Page"));
const Sony_Store_Page = lazy(() => import("../Pages/Sony_Store_Page"));
const Search__Page = lazy(() => import("../Pages/Search__Page"));
const Flyer_Page = lazy(() => import("../Pages/Flyer_Page"));
const Cms_Page = lazy(() => import("../Pages/Cms_Page"));
const Payment_Success_Page = lazy(() => import("../Pages/Payment_Success_Page"));
const Payment_Failure_Page = lazy(() => import("../Pages/Payment_Failure_Page"));
const Payfort_Merchant_Page = lazy(() => import("../Pages/Payfort_Merchant_Page"));
const Page_Not_Found = lazy(() => import("../Pages/Page_Not_Found"));
const Sony_map = lazy(() => import("../Components/Sonymap"));
const User_credit = lazy(() => import("../Components/UserCredits"));
const TabbyCancel = lazy(() => import("../Pages/Tabby/cancel"));
const TabbyFailure = lazy(() => import("../Pages/Tabby/failure"));
const TabbySuccess = lazy(() => import("../Pages/Tabby/success"));
const TabbySummaryPage = lazy(() => import("../Pages/Tabby/summary"));
const Contact_Page = lazy(() => import("../Pages/Contact_Page"));
const Home_Entertainment_Page = lazy(() => import("../Pages/Home_Entertainment_Page"));
const Ps5EliteGamersPage = lazy(() => import("../Pages/Ps5EliteGamersPage"));


const FallbackLoader = (
  <>
    <NewLoader />
    <Skeleton
      height="100vh"
      width="100%"
    />
  </>
)

function AllRoutes({ reloadingHandle, handleChangeCartPopup, handleChangeComparePopup, updateCompareProduct, closeComparePopup, setRefreshcomparePopup, setOpenLogin, setOpenRegister ,handleChangeCartIconTotal}) {
  return (
    <Suspense fallback={FallbackLoader}>
      <Routes>
        <Route index element={<Home handleChangeCartPopup={handleChangeCartPopup} log="log" />}></Route>
        <Route path="/" element={<Home handleChangeCartPopup={handleChangeCartPopup} log="log" />}></Route>
        <Route path="/flyer" element={<Flyer_Page />}></Route>
        <Route path="/:category" element={<Product_List_Page handleChangeCartPopup={handleChangeCartPopup} handleChangeComparePopup={handleChangeComparePopup} updateCompareProduct={updateCompareProduct} />} ></Route>
        <Route path="/products/compare" element={<Product_Comparison handleChangeCartPopup={handleChangeCartPopup} />} ></Route>
        <Route path="/category/:categoryId" element={<All_Category_Page handleChangeCartPopup={handleChangeCartPopup} handleChangeComparePopup={handleChangeComparePopup} updateCompareProduct={updateCompareProduct} />} ></Route>
        {/* <Route path="/services" element={<Services />}></Route> */}
        {/* <Route  path="/support" element={<Support />}></Route> */}
        <Route path="/products/:id" element={<Product_Details_Page handleChangeCartPopup={handleChangeCartPopup} handleChangeComparePopup={handleChangeComparePopup} updateCompareProduct={updateCompareProduct} closeComparePopup={closeComparePopup} setRefreshcomparePopup={setRefreshcomparePopup} />}></Route>
        <Route path="/create-bundle/:id" element={<Create_Bundle_Page handleChangeCartPopup={handleChangeCartPopup} />}></Route>
        <Route path="/cart" element={<Cart_Page handleChangeCartPopup={handleChangeCartPopup} />}></Route>
        <Route path="/contact" element={<Contact_Page reloadingHandle={reloadingHandle} />}></Route>
        <Route path="/checkout/signin" element={<CheckoutSignIn handleChangeCartIconTotal={handleChangeCartIconTotal} />}></Route>
        <Route path="/checkout/delivery" element={<Delivery />}></Route>
        <Route path="/checkout/payment" element={<Payment />}></Route>

        <Route path="/payfort/merchant" element={<Payfort_Merchant_Page reloadingHandle={reloadingHandle} />}></Route>
        <Route path="/checkout/success" element={<Payment_Success_Page reloadingHandle={reloadingHandle} />}></Route>
        <Route path="/checkout/failure" element={<Payment_Failure_Page reloadingHandle={reloadingHandle} />}></Route>
        <Route path="/unsubscribe" element={<Unsubscribe_Page />}></Route>

        <Route path="/tabby/result/cancel" element={<TabbyCancel reloadingHandle={reloadingHandle} />}></Route>
        <Route path="/tabby/result/failure" element={<TabbyFailure reloadingHandle={reloadingHandle} />}></Route>
        <Route path="/tabby/result/success" element={<TabbySuccess reloadingHandle={reloadingHandle} />}></Route>
        <Route path="/tabby/result/summary" element={<TabbySummaryPage reloadingHandle={reloadingHandle} />}></Route>

        <Route path="/ps5-elite-gamers/registration/" element={<Ps5EliteGamersPage  />}></Route>


        <Route element={<PrivateRoutes />} >
          <Route path="/user" element={<Dashboard />}></Route>
          <Route path="/user/orders" element={<My_Orders />}></Route>
          <Route path="/user/orders/:order_id" element={<Order_Details />}></Route>
          <Route path="/user/orders/:order_id/confirm" element={<Confirm_Order_Page />}></Route>
          <Route path="/user/profile" element={<My_Profile_Page />}></Route>
          <Route path="/user/credits" element={<User_credit />}></Route>
        </Route>
        <Route path="/user/wishlist" element={<My_Wishlists_Page handleChangeCartPopup={handleChangeCartPopup} />}></Route>

        <Route path="/stores" element={<Sony_Store_Page />}></Route>
        <Route path="/home-entertainment" element={<Home_Entertainment_Page handleChangeCartPopup={handleChangeCartPopup} />}></Route>
        <Route path="/search" element={<Search__Page handleChangeCartPopup={handleChangeCartPopup}/>}></Route>
        {/* <Route  path="/contact" element={<Contact />}></Route> */}
        <Route path="/cms/about-us" element={<Cms_Page cmsId="about-us-9696" />}></Route>
        <Route path="/cms/terms-and-conditions" element={<Cms_Page cmsId="terms-and-conditions-9696" />}></Route>
        <Route path="/cms/ps5/terms-and-conditions" element={<Cms_Page cmsId="terms-and-conditions-gemers-elite" />}></Route>
        <Route path="/cms/secured-payment" element={<Cms_Page cmsId="secured-payment-9696" />}></Route>
        <Route path="/cms/tamara" element={<Cms_Page cmsId="tamara-9696" />}></Route>
        <Route path="/cms/tabby" element={<Cms_Page cmsId="tabby-9696" />}></Route>
        <Route path="/cms/warranty-policy" element={<Cms_Page cmsId="warranty-policy-9696" />}></Route>
        <Route path="/cms/returns-and-exchange" element={<Cms_Page cmsId="returns-and-exchange-9696" />}></Route>
        <Route path="/cms/delivery-and-installation" element={<Cms_Page cmsId="delivery-and-installation-9696" />}></Route>
        <Route path="/cms/privacy-policy" element={<Cms_Page cmsId="privacy-policy" />}></Route>
        <Route path="/cms/meza-subscription" element={<Cms_Page cmsId="meza-subscription" />}></Route>
        <Route path="/contact" element={<Cms_Page cmsId="support-9696" />}></Route>
        <Route path="/sitemap" element={<Sony_map cmsId="sonymap" />}></Route>
        <Route path="*" element={<Page_Not_Found />}></Route>
      </Routes>
    </Suspense>
  )
}

export default React.memo(AllRoutes);
