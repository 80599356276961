import { dataLayerPush } from './dataLayerPush';

export const selectEvents = {
  selectContent: (bannerType, bannerData, screenName) => {
    const data = {
      screen_name: screenName,
      type: bannerType,
      url: bannerData.deeplinkUrl,
    };

    dataLayerPush({ event: 'select_content', ...data });
  },
  selectPlaystoreAppDownload: () => dataLayerPush({ event: 'select_playstore_app_download' }),
  selectAppstoreAppDownload: () => dataLayerPush({ event: 'select_appstore_app_download' }),
  selectedDeliveryCity: (data) => dataLayerPush({ event: 'selected_delivery_city', ...data }),
  selectLanguage: (location, lang) => {
    const data = {
      screen_name: location,
      type: lang,
    };
    dataLayerPush({ event: 'language_change', ...data });
  },
  scroll: (tournament_code, screen_name) => dataLayerPush({ event: 'scroll_down', tournament_code, screen_name }),
};
