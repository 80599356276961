import moment from "moment";
import React, { } from "react";
import { Link } from "react-router-dom";
import './../../SCSS/MostSharedComponents/_delivertyInfoBox.scss';
import { getEstimatedDelivery } from "../helpers/utils/getEstimatedDelivery";

function DeliveryInfo({ additionalEstimatedDays, t, selectedCity, setCityModal,
}) {
    const deliveryDate = getEstimatedDelivery({
        orderDate: Date.now(),
        additionalEstimatedDays,
        cityData: selectedCity,
        format: 'ddd, MMM DD'
    });

    const langTimezone = localStorage.getItem("i18next") === "en" ? "en-sg" : "ar-sa" ;
    require(`moment/locale/${langTimezone}`)
    moment.locale(langTimezone)

    return (
        <>
            <div className="col-md-12">
                <div className="row delivery__taken__area">
                    <div className="col-6 col-md-6 delivery__to__box">
                        <label>{t("deliver_to")}:</label>
                        <strong>{selectedCity?.cityName || ''}</strong>
                        <Link to='#' className="delivery__to__change__btn" onClick={() => {
                            setCityModal(true);
                        }}>{t("Change")}</Link>
                    </div>
                    <div className="col-6 col-md-6 delivery__day__box">
                        <p>{t("estimated_delivery")}: <strong>{deliveryDate ? <span>{deliveryDate}</span> : ""}</strong></p>
                    </div>
                </div>
            </div>

        </>
    );
}
export default DeliveryInfo;