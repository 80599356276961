import React, { useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import navbar_logo from "./../../assets/Logo/navbar_logo.png";
import "./../../SCSS/Common/_checkoutHeader.scss";
import { useTranslation } from "react-i18next";

function CheckoutHeader() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    function handleGoBack() {
        navigate(-1);
    }

    const languages = [
        {
            code: "en",
            name: "English",
            country_code: "gb",
        },
        {
            code: "ar",
            name: "العربية",
            dir: "rtl",
            country_code: "sa",
        },
    ];

    // Find Current language from {language} object
    const currentLanguageCode = localStorage.getItem("i18next") || "ar";

    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)

    // changing layout from right to left for arabic language"
    useEffect(() => {
        document.body.dir = currentLanguage.dir || "ltr";
        document.body.classList.remove("englishFonts");
        document.body.classList.remove("arabicFonts");
        if (currentLanguage.code === "en") {
            document.body.classList.add("englishFonts");
        } else {
            document.body.classList.add("arabicFonts");
        }
        document.title = t("Sony_World_KSA");
    }, [currentLanguage, t]);

    return (
        <>
            <div className="container-fluid checkout__header__section">
                <div className="checkout__header__container">
                    <button className="go_back_btn" onClick={handleGoBack}><BiChevronLeft /> <span>{t("goBack")}</span></button>
                    <div className="logo__box">
                        <button className="checkout__header__logo" onClick={() => navigate("/")}>
                            <img src={navbar_logo} alt="logo" className="header2_logo" />
                        </button>
                    </div>
                    <p className="secure__checkout__label">{t("secureCheckout")}</p>
                </div>
            </div>
        </>
    );
}

export default CheckoutHeader;