import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import store from "./redux/store";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { handleLanguageChangeHelper } from "./Components/helpers/utils/handleLanguageChangeHelper";
import { setHeader } from "./services/config";
import { setHandsakeConfig } from "./Components/helpers/utils/handsakeConfig";
import { getHandshake } from "./services/auth";
import { languageList } from "./Components/helpers/utils/commonHelper";
import NewLoader from "./Components/Common/newloader";
import ScrollToTop from "./Components/scrollToTop";
import TagManager from 'react-gtm-module'
import { QueryClient, QueryClientProvider } from "react-query";
import { Hydrate } from "react-query/hydration";
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { get, set, del } from "idb-keyval";
import i18next from "i18next";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_URL}`,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", `${process.env.REACT_APP_PROJECT_API_URL}`],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
    new Sentry.BrowserProfilingIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

let IsUrl = !window.location.pathname.includes("/en-sa/") && !window.location.pathname.includes("/ar-sa/") && !window.location.pathname.includes("/en-sa") && !window.location.pathname.includes("/ar-sa")

if (IsUrl) {
  if(localStorage.getItem("i18next") === "en"){
    window.location.href = `/en-sa${window.location.pathname === "/" ? "/" : window.location.pathname}${window.location.search}`
    localStorage.setItem("i18next", "en")
  }else{
    window.location.href = `/ar-sa${window.location.pathname === "/" ? "/" : window.location.pathname}${window.location.search}`
    localStorage.setItem("i18next", "ar")
  }
}

function createIDBPersister(idbValidKey = "sonyDB") {
  return {
    persistClient: async (client) => {
      set(idbValidKey, client);
    },
    restoreClient: async () => {
      return await get(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  };
}

const storage = createIDBPersister()

const queryClient = new QueryClient();

persistQueryClient({
  queryClient,
  persistor: storage,
})


const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
}

TagManager.initialize(tagManagerArgs)

const Root = () => {
  const history = createBrowserHistory();
  const [basename, setBasename] = useState('/ar-sa');
  const [token, setToken] = useState();

  const currentLanguageCode = localStorage.getItem("i18next")
  const splitUrl = window.location.pathname.split('/');
  const getCurrentUrlLngCode = splitUrl[1];

  const localStorageHandshakeToken = localStorage.getItem("handShakeToken");

  // const checkHandshakeAfterLngChange = () => {
  //   if ((localStorageHandshakeToken === null || undefined) && !IsUrl) {
  //     getHandshake().then((res) => {
  //       setHeader("X-Access-Token", res.data.token);
  //       localStorage.setItem("handShakeToken", res.data.token);
  //       setHandsakeConfig(res.data);
  //       setTimeout(() => {
  //         replaceCurrentUrl();
  //       }, 200);
  //     });
  //   } else {
  //     replaceCurrentUrl()
  //   }
  // }

  const checkHandshakeAfterLngChange = () => {
    if (!IsUrl) {
      if ((localStorageHandshakeToken === null || undefined)) {
        getHandshake().then((res) => {
          setHeader("X-Access-Token", res?.data?.token);
          localStorage.setItem("handShakeToken", res?.data?.token);
          localStorage.setItem("i18next", getCurrentUrlLngCode === "en-sa" ? "en" : "ar");
          setToken(res?.data?.token)
          setHandsakeConfig(res.data);
          i18next.changeLanguage(getCurrentUrlLngCode === "en-sa" ? "en" : "ar");
          setBasename(getCurrentUrlLngCode !== '/' ? getCurrentUrlLngCode : '/ar-sa')
        });
      }
      else {
        setHeader("X-Access-Token",localStorageHandshakeToken);
        localStorage.setItem("handShakeToken", localStorageHandshakeToken);
        setToken(localStorageHandshakeToken)
        setBasename(getCurrentUrlLngCode !== '/' ? getCurrentUrlLngCode : '/ar-sa')
        handleLanguageChangeHelper(getCurrentUrlLngCode === "en-sa" ? "en" : "ar", "");
      }
    }
  }


  const replaceCurrentUrl = (urlKey) => {
    let checkLngUrlKey = languageList && languageList.filter(val => val.urlKey === getCurrentUrlLngCode);
    if (checkLngUrlKey.length > 0) {
      if (!getCurrentUrlLngCode) {
        if (!currentLanguageCode) {
          setBasename("/en-sa")
          handleLanguageChangeHelper("en", "");
        } else {
          let getLang = languageList.filter(val => val.code === currentLanguageCode);
          if (getLang.length > 0) {
            window.location.replace(`/${getLang[0].urlKey}${window.location.pathname}${window.location.search}`);
            localStorage.setItem("i18next", getLang[0].code);
            setBasename(`/${getLang[0].urlKey}`)
          }
        }
      } else if (getCurrentUrlLngCode) {
        let getLang = languageList.filter(val => val.urlKey === getCurrentUrlLngCode);
        if (getLang.length > 0) {
          if (getLang[0].code === currentLanguageCode) {

          } else {
            localStorage.setItem("i18next", getLang[0].code);
            handleLanguageChangeHelper(getLang[0].code, currentLanguageCode)
          }
          setBasename(`/${getLang[0].urlKey}`)
        }
      }
    } else {
      if (!currentLanguageCode) {
        setBasename("/en-sa")
        handleLanguageChangeHelper("en", "");
      } else {
        let getLang = languageList.filter(val => val.code === currentLanguageCode);
        if (getLang.length > 0) {
          window.location.replace(`/${getLang[0].urlKey}${window.location.pathname}${window.location.search}`);
          localStorage.setItem("i18next", getLang[0].code);
          setBasename(`/${getLang[0].urlKey}`)
        }
      }
    }
  }

  useEffect(() => {
    checkHandshakeAfterLngChange();
  }, [IsUrl]);

  return (
    <HelmetProvider>
      {window.location.pathname === "/" && <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      }
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={null}>
            <React.StrictMode>
              <Suspense fallback={<NewLoader />}>
                <Router history={history} basename={basename}>
                  <ScrollToTop />
                  <App setToken={setToken} token={token}/>
                </Router>
              </Suspense>
            </React.StrictMode>
          </Hydrate>
          {/* added for teting purpose, remove this in prod */}
        </QueryClientProvider>
      </Provider>
    </HelmetProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

// ReactDOM.render(
//   <HelmetProvider>
//     <Provider store={store}>
//       <Router history={history}>
//         <React.StrictMode>
//           <Suspense fallback={<h1>Loading...</h1>}>
//             <App />
//           </Suspense>
//         </React.StrictMode>
//       </Router>
//     </Provider>
//   </HelmetProvider>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();