import React from 'react'
import Visa from "./../../assets/Footer/visa.png"
import "./../../SCSS/_visaHolderNote.scss"

export default function VisaHolderNote({t}) {
  return (
    <div className="visa__notes__box">
    <img src={Visa} alt="visa" />
    <div className="visa__contentbox">
      <p>
        {t('noteVisaHolder')}
        <b>{t('noteVisaHolderDesc')}</b>
      </p>
    </div>
  </div>
  )
}
