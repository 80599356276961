import React from 'react'
import { useTranslation } from 'react-i18next'

function PriceRange() {
  const { t } = useTranslation()
  return (
    <div>{t("PriceRange")}</div>
  )
}

export default PriceRange