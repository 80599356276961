import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./../../SCSS/MobileView/_mobileHomeCategoryBannerProductsBlock.scss";
import RatingBlock from "../MostSharedComponent/RatingBlock";
import OldPrice from "../Font/OldPrice";
import Price from "../Font/Price";
import empty_favourite from "./../../assets/Icon/empty_favourite.svg";
import fulfill_favourite from "./../../assets/Icon/fulfill_favourite.svg";
import shopping_cart from "./../../assets/Icon/shopping-cart-red.svg";
import Heading7 from "../Font/Heading7";
import {
  loadAddToWishlist,
  loadDeleteFromWishlist,
  loadWishlistData,
} from "../../redux/appAction";
import { useDispatch, useSelector } from "react-redux";
import { checkWishList } from "../helpers/utils/wishListHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
import { useTranslation } from "react-i18next";
import { checkoutEvents } from "../helpers/utils/ga/checkoutEvents";
  
function MobileHomeCAtegoryBannerProduct({ product, screenName }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [count, setCount] = useState(0);

  const wishlistData = useSelector((state) => state.appData.wishlistData);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, product?.sku));
  }, []);

  const handleFavourite = () => {
    if (isFavourite) {
      setIsFavourite(false);
      setCount(count + 1);
    } else {
      setIsFavourite(true);
      setCount(count + 1);
    }
  };
  useEffect(() => {
    const data = {
      items: [product.sku],
    };
    if (isFavourite) {
      if (count > 0) {
        const addToWishlistData = dispatch(loadAddToWishlist(data,t)).then(
          (res) => {
            checkoutEvents.addToWishlist(product, screenName);
            dispatch(loadWishlistData());
          }
        );
      }
    } else {
      if (count > 0) {
        if (!isFavourite) {
          checkoutEvents.removeFromWishlist(product, screenName);
          removeFromWL(product.sku);
        }
      }
    }
  }, [isFavourite]);

  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };

    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };
  return (
    <Link
      to={`products/${product?.sku.replace(/[/]/g, "%2F")}`}
      className="col-6 col-sm-6 mh__cbp__product"
    >
      <div className="inner__mh__cbp__product">
        <span className="fav_icon d-block d-lg-none">
          <img
            onMouseEnter={() => setIsFavouriteHover(true)}
            onClick={handleFavourite}
            onMouseLeave={() => setIsFavouriteHover(false)}
            className={
              !isFavourite
                ? "productOne__favourite__icon"
                : "productOne__favourite__icon__disable"
            }
            src={isFavouriteHover ? fulfill_favourite : empty_favourite}
            alt=""
            width={24}
            height={24}
          />
          <img
            onClick={handleFavourite}
            className={
              isFavourite
                ? "productOne__favourite__icon"
                : "productOne__favourite__icon__disable"
            }
            src={fulfill_favourite}
            alt=""
            width={24}
            height={24}
          />
        </span>
        <div className="second__mh__cbp__product__image__block">
          <img
            src={product?.baseImage}
            alt=""
            className="second__md__product__image"
          />
        </div>
        <Heading7 marginBottom={10} text={titleChange(product?.name)} />
        <div className="md__product__rating__block">
          <RatingBlock rating={4.5} totalRatings={2143} />
        </div>
        <OldPrice
          oldPrice={product?.price_rounded}
          price={
            product.discounted_price_rounded === null
              ? product.price_rounded
              : product.discounted_price_rounded
          }
          size="text4"
          color="#c8c8c8"
          marginBottom={10}
          lineThrough={true}
          span={true}
          currency={product?.currency}
        />
        <Price
          price={
            product.discounted_price_rounded === null
              ? product.price_rounded
              : product.discounted_price_rounded
          }
          marginLeft={5}
          marginBottom={5}
          size="heading7"
          span={true}
          currency={product?.currency}
        />
        <div className="text-end cart_end_icon">
          <img src={shopping_cart} alt="cart" />
        </div>
      </div>
    </Link>
  );
}

export default MobileHomeCAtegoryBannerProduct;
