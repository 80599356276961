import React, { useEffect, useState } from "react";
import "./App.css";
// import {} from 'react-redux'
import { Helmet } from "react-helmet-async";
import Header from "./Components/Common/Header";
import AllRoutes from "./routes";
import Footer from "./Components/Common/Footer";
import NewsLetter from "./Components/Common/NewsLetter";
import { getHandshake } from "./services/auth";
import { useTranslation } from 'react-i18next'
import { loadAllCategoryData, loadWishlistData, toggleGeneralModal } from "./redux/appAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setHeader } from "./services/config";
import { customerDetailsSuccess, saveUserLoginData } from "./services/customer/customer";
import { getCustomerLoginDetails } from "./Components/helpers/utils/getCustomerLoginDetails";
import CartPopup from "./Components/Popup/CartPopup";
import NewLoader from "./Components/Common/newloader";
import PLPComparePopup from "./Components/Popup/PLPComparePopup";
import { compareProductsFilter } from "./Components/helpers/utils/compareProductsFilter";
// import * as services from './services/services';
import "./SCSS/_arabicViewDesigns.scss";
import "./SCSS/cookiesBanner.scss";
import { setHandsakeConfig } from "./Components/helpers/utils/handsakeConfig";
import CookieDisclaimer from "./Components/cookies/CookieDisclaimer";
import { JQUERY_CDN_URL } from "./globalConst";
import CheckoutHeader from "./Components/Common/CheckoutHeader";
import RestoreCart from "./Components/MostSharedComponent/RestoreCart";
import { useLocation } from "react-router";
import MezaPopup from "./Components/Meza/MezaPopup";

function App({ token, setToken }) {
  const customerData = getCustomerLoginDetails();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation()

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
  ];

  const { customerDetails } = useSelector((state) => state.customerReducer);
  const modalState = useSelector((state) => state.appData.toggleModal);
  const logistrationMode = useSelector((state) => state.appData.logistrationMode);

  const closeMezaModal = () => dispatch(toggleGeneralModal(false));

  const categoryDispatch = useDispatch();
  localStorage.setItem("loginMode", JSON.stringify(""));
  localStorage.setItem("loginWrapper", JSON.stringify(false));

  useEffect(() => {
    localStorage.setItem("loginPopup", JSON.stringify(false));
  }, [])

  useEffect(() => {
    if (!window.location.pathname.includes("/checkout") && !window.location.pathname.includes("/tabby")) {
      localStorage.removeItem("lod")
    }
  }, [])

  // const [homepageData, setHomepageData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const isCookies = localStorage.getItem('isCookies');
  const [iscookies, setIscookies] = useState(isCookies === 'true' ? true : false);
  const [loading, setLoading] = useState(true);
  const getCurrentPageUrl = window.location.href;
  const [reloadHeader, setReloadHeader] = useState(true);
  const [cartIconTotal, setCartIconTotal] = useState(0);
  const [restoreLoading, setRestoreLoading] = useState(false)

  useEffect(() => {
    if (!logistrationMode) return;
    localStorage.setItem("loginMode", JSON.stringify(logistrationMode));
    localStorage.setItem("loginWrapper", JSON.stringify(true));
    localStorage.setItem("loginPopup", JSON.stringify(true));
    setOpenRegister(true);
  }, [logistrationMode]);
  
  const handleChangeCartIconTotal = (total) => {
    setCartIconTotal(total);
  };
  const reloadingHandle = () => {
    setReloadHeader(!reloadHeader);
  };

  // const [token, setToken] = useState();

  useEffect(() => {	
    if (customerData !== "" && customerDetails === "") {	
      dispatch(customerDetailsSuccess(customerData));	
    }	
  }, [customerData, customerDetails, dispatch]);	

  useEffect(() => {

    const localStorageHandshakeToken = localStorage.getItem("handShakeToken");
    if (localStorageHandshakeToken === null || undefined) {
      // getHandshake().then((res) => {
      setToken(token)
      // setHandsakeConfig(res?.data);
      // });
    } else {
      setToken(localStorageHandshakeToken);
      // setHeader("X-Access-Token", localStorageHandshakeToken);

    }
    // window.scrollTo(0, 0)
  }, []);
  // Find Current language from {language} object
  const currentLanguageCode = localStorage.getItem("i18next") || "ar";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)

  // changing layout from right to left for arabic language"
  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.body.classList.remove("englishFonts");
    document.body.classList.remove("arabicFonts");
    if (currentLanguage.code === "en") {
      document.body.classList.add("englishFonts");
    } else {
      document.body.classList.add("arabicFonts");
    }
    document.title = t("Sony_World_KSA");
  }, [currentLanguage, t]);

  useEffect(() => {
    if (token) {
      setHeader("X-Access-Token", token);
      localStorage.setItem("handShakeToken", token);
      setTimeout(() => {
        let data = categoryDispatch(loadAllCategoryData());
        data
          .then((res) => dispatch(loadWishlistData()))
          .catch((err) => {
            if (err?.request?.status === 401) {
              setHeader("X-Access-Token", "");
              getHandshake().then((res) => {
                setHeader("X-Access-Token", res?.data?.token);
                localStorage.setItem("handShakeToken", res?.data?.token);
                setHandsakeConfig(res?.data);
                setToken(res?.data?.token);
                dispatch(loadWishlistData())
                dispatch(saveUserLoginData(res?.data?.user?.userLoggedIn))
              });
            }
          });
      }, 3000)
    }
  }, [categoryDispatch, dispatch, setToken, token]);

  // const getAppStarted = async () => {
  //   await getHandshake();
  //   await homeDispatch(loadHomePageData());
  //   await categoryDispatch(loadAllCategoryData());
  //   setLoading(false);
  // };
  // const data = useSelector((state) => state.appData.homepageData);
  const allCategoryData = useSelector((state) => state.appData.categoryData);

  useEffect(() => {
    if (Object.keys(allCategoryData).length !== 0) {
      setCategoryData(allCategoryData);
      setLoading(false);
    }
  }, [allCategoryData]);
  useEffect(() => {
    // wishlistDispatch(loadWishlistData())
  })
  const [cartPopup, setCartPopup] = useState(false);
  useEffect(() => {
    if (cartPopup) {
      document.body.classList.add("modal_back_stop");
    } else {
      document.body.classList.remove("modal_back_stop");
    }
  }, [cartPopup]);

  const closeCartPopup = () => {
    setCartPopup(false);
  };
  const handleChangeCartPopup = (value) => {
    setCartPopup(value);
    // setPopupProduct(product);
  };

  const [comparePopup, setComparePopup] = useState(false);
  const [refreshComparePopup, setRefreshcomparePopup] = useState(false);
  const closeComparePopup = () => {
    setComparePopup(false);
  };
  const handleChangeComparePopup = (value) => {
    setComparePopup(value);
  };

  const updateCompareProduct = (value, product, disablePopup) => {
    // let compare_products = JSON.parse(localStorage.getItem("compare_products"));
    // if (compare_products !== null) {
    //   let prodCheck = compare_products && compare_products.filter(item => item.id === product.id);
    //   if (prodCheck.length > 0) {
    //     dispatch(services.notifyError({ message: 'Product already added in compare' }))
    //   }
    // }

    compareProductsFilter(product);
    if (!disablePopup) {
      handleChangeComparePopup(value);
      setRefreshcomparePopup(true);
    }
  };

  const allow = () => {
    localStorage.setItem('isCookies', true);
    setIscookies(true);
  }
  // useEffect(() => {
  //   dispatch(loadConfigurationSettingData())
  // }, [])

  useEffect(() => {
    if (!window.location.pathname.includes("checkout")) {
      localStorage.removeItem("user_mode")
    }
  }, [])

  if (loading) {
    return <NewLoader />;
  }

  return (
    <>
      <Helmet>
        <link rel="canonical" href={getCurrentPageUrl} />
        <link rel="preconnect" href={process.env.MAIN_API_URL} />
        <link rel="dns-prefetch" href={process.env.MAIN_API_URL} />
        <link rel="preconnect" href={process.env.M2_API_URL} />
        <link rel="dns-prefetch" href={process.env.M2_API_URL} />
      </Helmet>
      <Helmet>
        <script async src={JQUERY_CDN_URL} type="text/javascript"></script>
      </Helmet>
          <Helmet>
        <script
          async
          type="text/javascript"
          src="https://mpsnare.iesnare.com/snare.js"
        />
        <script>
          {`
            var io_bbout_element_id = 'device_fingerprint';
            var io_install_stm = false;
            var io_exclude_stm = 0;
            var io_install_flash = false;
            var io_enable_rip = true;
          `}
        </script>
      </Helmet>
      <input
        id="device_fingerprint"
        name="device_fingerprint"
        autoComplete="device_fingerprint"
        inputMode="hidden"
        type="hidden"
      />
      <div className="main_header">
        {/* <Header
          handleChangeCartIconTotal={handleChangeCartIconTotal}
          cartIconTotal={cartIconTotal}
          handleChangeCartPopup={handleChangeCartPopup}
          reloadingHandle={reloadingHandle}
          categoryData={categoryData}
          reloadHeader={reloadHeader}
          openLogin={openLogin}
          openRegister={openRegister}
          setOpenLogin={setOpenLogin}
          setOpenRegister={setOpenRegister}
        /> */}
        {!(pathname.includes("/ps5-elite-gamers/registration") || pathname.includes("/tabby/result/cancel") || pathname.includes("/tabby/result/failure") || pathname.includes("/tabby/result/success") || pathname.includes("/tabby/result/summary") || pathname.includes("/checkout/success") || pathname.includes("/checkout/failure")) &&
          <>
            {pathname.includes("/checkout") ?
              <CheckoutHeader />
              : <Header
                handleChangeCartIconTotal={handleChangeCartIconTotal}
                cartIconTotal={cartIconTotal}
                handleChangeCartPopup={handleChangeCartPopup}
                reloadingHandle={reloadingHandle}
                categoryData={categoryData}
                reloadHeader={reloadHeader}
                openLogin={openLogin}
                openRegister={openRegister}
                setOpenLogin={setOpenLogin}
                setOpenRegister={setOpenRegister}
              />
            }
          </>
        }
      </div>
      {/* cookies  */}
      {iscookies === false && (window.location.pathname === "/ar-sa/" || window.location.pathname === "/ar-sa" || window.location.pathname === "/en-sa/" || window.location.pathname === "/en-sa" || window.location.pathname === "/") && (
        <CookieDisclaimer t={t} allow={allow} />
      )}
      {/* cookies */}
      <div className="main_wrapper" role="main">
        {window.location.pathname.includes("/checkout/delivery") || window.location.pathname.includes("/checkout/payment") ?
          <RestoreCart restoreLoading={restoreLoading} setRestoreLoading={setRestoreLoading} />
          : null}
        <AllRoutes
          handleChangeCartPopup={handleChangeCartPopup}
          // homepageData={homepageData}
          categoryData={categoryData}
          reloadingHandle={reloadingHandle}
          reloadHeader={reloadHeader}
          closeComparePopup={closeComparePopup}
          handleChangeComparePopup={handleChangeComparePopup}
          updateCompareProduct={updateCompareProduct}
          setRefreshcomparePopup={setRefreshcomparePopup}
          handleChangeCartIconTotal={handleChangeCartIconTotal}
          setOpenLogin={setOpenLogin}
          setOpenRegister={setOpenRegister}
        />
      </div>

      {!(pathname.includes("/ps5-elite-gamers/registration")) &&
          <div role="contentinfo">
           <NewsLetter />
           <Footer />
          </div>
      }
    
      <div
        className={
          cartPopup
            ? "container-fluid cart__popup__container"
            : "container-fluid cart__popup__container__disable"
        }
      >
        <CartPopup
          handleChangeCartIconTotal={handleChangeCartIconTotal}
          // cartData={cartData}
          closeCartPopup={closeCartPopup}
          handleChangeCartPopup={handleChangeCartPopup}
        />
      </div>
      <div
        className={
          comparePopup
            ? "container-fluid cart__popup__container plp__compare"
            : "container-fluid cart__popup__container__disable"
        }
      >
        <PLPComparePopup
          updateCompareProduct={updateCompareProduct}
          closeComparePopup={closeComparePopup}
          handleChangeComparePopup={handleChangeComparePopup}
          refreshComparePopup={refreshComparePopup}
          setRefreshcomparePopup={setRefreshcomparePopup}
        />
      </div>
      <MezaPopup t={t} show={modalState} onClose={closeMezaModal} />
    </>
  );
}

export default App;
