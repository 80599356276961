import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
// import { checkoutEvents } from 'lib/helpers/ga/checkoutEvents';
import ProcessingPayment from '../../Pages/ProcessingPayment';
import { getOrderDetails } from '../../services/order.service';
import { addToCart, createBillingAddress, createCartDetails, deleteCart, getCartData, setRandomValue, updateShippingInformation } from '../../services/cart.service';
import { useNavigate } from 'react-router';
import { loaderEnd, loaderStart } from '../../services/services';

const RestoreCart = ({ restoreLoading, setRestoreLoading }) => {
  const lastOrderId = localStorage.getItem('lod') ? JSON.parse(localStorage.getItem('lod')) : "";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const LoadingState = (data) => dispatch(setRandomValue(data));

  useEffect(() => {
    const isOrderFailed = localStorage.getItem('isOrderFailed');
    const shippingAsBilling = localStorage.getItem('shippingAsBilling');
    console.log(lastOrderId, typeof (lastOrderId), "lastOrderId")
    if (lastOrderId !== "") {
      setRestoreLoading(true)
      LoadingState(true)
      dispatch(loaderStart())
      localStorage.setItem('lastOId', lastOrderId);
      localStorage.removeItem('isOrderFailed');
      localStorage.removeItem('lod');
      const restoreCart = async () => {
        const data = await getOrderDetails(lastOrderId, isOrderFailed);
        dispatch(loaderStart())
        await deleteCart();
        dispatch(loaderStart())
        let childItems = {};
        const remainingItems = [];
        for (let index = 0; index < data.items.length; index++) {
          const item = data.items[index];
          if (item.parent_item_id) {
            const previousItems = childItems[item.parent_item_id] || [];
            childItems = {
              ...childItems,
              [item.parent_item_id]: [...previousItems, item.sku],
            };
          } else {
            remainingItems.push(item);
          }
        }
        const cartItems = [];
        remainingItems.forEach((item) => {
          if (item.product_type === 'bundle') {
            // eslint-disable-next-line array-callback-return
            const bundle_option = item.product.bundle_product_options.map((value) => {
              let option_selection = [];
              // eslint-disable-next-line array-callback-return
              value.product_links.map((select_opt) => {
                const childBundleProducts = childItems[item.item_id];
                // eslint-disable-next-line array-callback-return
                childBundleProducts.map((option) => {
                  if (select_opt.sku === option) {
                    option_selection = [select_opt.id];
                  }
                });
              });
              return { option_id: value.option_id, option_qty: 1, option_selections: option_selection };
            });

            cartItems.push({
              sku: item?.sku,
              qty: item?.qty_ordered,
              id: typeof item?.product?.id !== 'undefined' ? item?.product?.id : item?.product_id,
              bundle_options: bundle_option,
              extension_attributes: {
                restore_id: Number(lastOrderId),
              },
            });
          } else {
            cartItems.push({
              sku: item?.sku,
              id: typeof item?.product?.id !== 'undefined' ? item?.product?.id : item?.product_id,
              qty: item?.qty_ordered,
              extension_attributes: {
                restore_id: Number(lastOrderId),
              },
            });
          }
        });
        const createCarts = await createCartDetails();
        dispatch(loaderStart())
        setTimeout(async () => {
          await addToCart({ items: cartItems });
          dispatch(loaderStart())
          const allCartItems = await getCartData();
          dispatch(loaderStart())
          allCartItems.data.billing_address = data.billing_address;
          allCartItems.data.extension_attributes.shipping_assignments = data.extension_attributes.shipping_assignments;
          const billingAddress = {
            firstName: data?.billing_address?.firstname,
            lastName: data?.billing_address?.lastname,
            telephone: data?.billing_address?.telephone,
            countryId: data?.billing_address?.country_id,
            addressLine1: data?.billing_address?.street.join(' '),
            city: data?.billing_address?.city,
            email: data?.billing_address?.email,
            postCode: data?.billing_address?.postcode,
            parent_id: data?.billing_address?.parent_id,

          };
          let shippingAsBill = false;
          if (shippingAsBilling === 'true') {
            shippingAsBill = true;
          } else {
            shippingAsBill = false;
          }
          const addresses = data?.extension_attributes?.shipping_assignments[0].shipping.address;
          console.log(addresses, "REstore addresses")
          let shippingAddress;
          if (addresses) {
            //   shippingAddress = {
            //     "useAsBilling": true,
            //     "firstName": "Bhojani",
            //     "lastName": "Bhavya",
            //     "email": "bbhojani@sigmasolve.net",
            //     "telephone": "966563823921",
            //     "city": "Khobar",
            //     "postCode": "",
            //     "countryId": "SA",
            //     "street": "Khobar - King Abdullah Street",
            //     "shippingCarrierCode": "storepickup",
            //     "pickup_store": "KS05",
            //     "region_id": "76"
            // }
            shippingAddress = {
              useAsBilling: shippingAsBill,
              firstName: addresses.firstname,
              lastName: addresses.lastname,
              email: addresses.email,
              telephone: addresses.telephone,
              city: addresses.city,
              postCode: addresses.postcode || "",
              countryId: addresses.country_id,
              street: addresses?.street.join(' '),
              shippingCarrierCode: localStorage.getItem('last-dt') ? localStorage.getItem('last-dt') : 'flatrate',
              region_id: localStorage.getItem('last-dt') === 'storepickup' ? '76' : (
                addresses.region_id === undefined || addresses.region_id === ""
                  ? 0
                  : addresses.region_id
              ),
            };
            if (localStorage.getItem('last-dt') === 'storepickup') {
              shippingAddress.pickup_store = localStorage.getItem('pickup_store');
            }
          }
          await dispatch(createBillingAddress(billingAddress));
          dispatch(loaderStart())
          if (addresses) {
            await dispatch(updateShippingInformation(shippingAddress));
            dispatch(loaderStart())
          }
          setRestoreLoading(false);
          LoadingState(false)
          navigate('/checkout/payment');
        }, 5000)
      };
      restoreCart();
    } else {
      // navigate('/checkout/delivery');
    }
  }, []);

  useEffect(() => {
    if (!restoreLoading) {
      dispatch(loaderEnd())
    } else {
      dispatch(loaderStart())
    }
  }, [restoreLoading])

  return (
    <>
      {restoreLoading && <ProcessingPayment dispatch={dispatch} text="restore_cart" />}
    </>
  );
};

export default RestoreCart;
