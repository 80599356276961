import React, { useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import StoresInfoWindow from "./StoresInfoWindow";

function Store_Map({ filterStoreList, selectedLocation, setSelectedLocation }) {

  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedCenter, setSelectedCenter] = useState(null);

  useEffect(() => {
    if (selectedLocation) {
      handleActiveMarker(selectedLocation);
    }
  }, [selectedLocation]);

  const handleActiveMarker = (marker) => {
    // if (marker.id === activeMarker) {
    //   return;
    // }
    setActiveMarker(marker.id);
    setSelectedCenter({ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) })
  };

  const handleOnLoad = (map) => {
    // const bounds = new google.maps.LatLngBounds();
    // markers.forEach(({ position }) => bounds.extend(position));
    // map.fitBounds(bounds);
  };

  const onCloseClick = () => {
    setActiveMarker(null);
    setSelectedLocation(null);
  }

  return (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: "100vw", height: "100vh" }}
      zoom={selectedCenter ? 10 : 5}
      center={selectedCenter || { lat: 23.8859, lng: 45.0792 }}
      options={{
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
      }}
    >
      {filterStoreList && filterStoreList.map((val, i) => {
        return (
          <React.Fragment key={val.id}>
            <Marker
              key={val.id}
              position={{ lat: parseFloat(val.latitude), lng: parseFloat(val.longitude) }}
              onClick={() => handleActiveMarker(val)}
            >
              {activeMarker === val.id ? (
                <>
                  {/* <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <div>{val.name}</div>
                  </InfoWindow> */}
                  <StoresInfoWindow position={{ lat: parseFloat(val.latitude), lng: parseFloat(val.longitude) }} data={val} onCloseClick={onCloseClick} />
                </>
              ) : null}
            </Marker>
          </React.Fragment>
        )
      })}
    </GoogleMap>
  );
}

export default Store_Map;
