import React, { useEffect, useMemo, useState, lazy } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./../SCSS/_allCategory.scss";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import CategoryBanner from "../Components/CategoryComponent/CategoryBanner";
// import CategoryByScreenSize from "../Components/CategoryComponent/CategoryByScreenSize";
// import CategoryThreeBanner from "../Components/CategoryComponent/CategoryThreeBanner";
// import SimilarProducts from "../Components/SimilarProducts";
import * as services from "../services/services";
import { useSelector, useDispatch } from "react-redux";
import MetaComponent from "../Components/Common/MetaComponent";
import { productUrlHelper } from "../Components/helpers/utils/productUrlHelper";
import NewLoader from "../Components/Common/newloader";
import ReviewTestFreaks from "./ReviewTestFreaks";
import { categoryAvailableData, loadApplyFilterProductsData, loadConfigurationSettingData, productOutOfStockStatusNotification } from "../redux/appAction";
import ProductCategory from "../Components/ProductCategory";
import { categoryDetailsSuccess } from "../services/category/category";
import TealiumTag from "../Components/helpers/utils/tealium";
import CmsRichText from "../Components/MostSharedComponent/CmsRichText";
import { impressionEvents } from "../Components/helpers/utils/ga/impressionEvents";

const CategoryByScreenSize = lazy(() => import("../Components/CategoryComponent/CategoryByScreenSize"))
const CategoryThreeBanner = lazy(() => import("../Components/CategoryComponent/CategoryThreeBanner"));
const PLPProductPopupModal = lazy(() => import("../Components/Popup/PLPProductPopupModal"));
// const SimilarProducts = lazy(() => import("../Components/SimilarProducts"));

const All_Category_Page = ({ handleChangeCartPopup, updateCompareProduct }) => {

  const { categoryId } = useParams();
  const category = categoryId.split("-").slice(-1)[0]
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [categoryData, setCategoryData] = useState([]);
  const [plpProductPopup, setPlpProductPopup] = useState(false);
  const [popupProduct, setPopupProduct] = useState({})
  const [productIdData, setProductIdData] = useState([]);
  const [availablityData,setAvailablityData] = useState([])
  // const getCategoryData = async () => {
  //   if (category !== undefined) {
  //     setCategoryData([]);
  //   }

  //   const params = { categoryId: category }
  //   const result = await apiHelper(
  //     `/V1/category/${category}`,
  //     "get",
  //     params,
  //     {}
  //   )

  //   if (result?.data?.length === 0) {
  //     window.location.href = "/";
  //     return
  //   }
  //   return result?.data
  // }

  // const { data: categoryDetails, isLoading, error } = useQuery(
  //   ["categoryDetails", category],
  //   () => getCategoryData(),
  //   {
  //     enabled: !!category,
  //     refetchOnWindowFocus: false,
  //     cacheTime: 1000 * 60 * 10,
  //     staleTime: Infinity,
  //   }
  // );

  // useEffect(() => {
  //   if (error) {
  //     window.location.href = "/";
  //   }
  // }, [error])

  const { categoryDetails } = useSelector((state) => state.categoryReducer);
  const { loading } = useSelector((state) => state.commonReducer);
  const { configurationSettingData } = useSelector((state) => state.appData);
  const { customerDetails } = useSelector((state) => state.customerReducer);
  const currentLang = localStorage.getItem("i18next") || "en";
  useEffect(() => {
    if (category !== undefined) {
      let params = {
        categoryId: category
      }
      setCategoryData([]);
      dispatch(services.getCategoryDetails(params));
    }
  }, [categoryId])

  const [outOfStockConfiguration, setOutOfStockConfiguration] = useState({});

    useEffect(() => {
        if (configurationSettingData?.store_pickup !== undefined) {
          setOutOfStockConfiguration(configurationSettingData?.out_of_stock_configuration || {})
        }
    }, [configurationSettingData])

  useEffect(() => {
    if (categoryDetails !== "") {
      if (Array.isArray(categoryDetails)) {
        setCategoryData(categoryDetails)
      } else {
        setCategoryData(categoryDetails?.content)
      }
    }
  }, [categoryDetails]);

  useEffect(() => {
    if (categoryData && categoryData.length > 0) {
      async function fetchData() {
        const data = categoryData.flatMap(obj => obj?.products?.filter(product => product?.hasOwnProperty('id')).map(product => product?.id))
        const productIDs = data.filter((e) => e);
        const productData = await productOutOfStockStatusNotification(productIDs);
        setProductIdData(productData?.data)
        const availablityData = await categoryAvailableData(productIDs);
        setAvailablityData(availablityData?.data || [])
      }
      fetchData();
    }
  }, [categoryData])

  useEffect(() => {
    dispatch(loadConfigurationSettingData())
    return () => {
      setCategoryData([]);
      dispatch(categoryDetailsSuccess([]))
    }
  }, [])
  useEffect(() => {
    impressionEvents.impressionGlobalVariable(
      currentLang, "All Category Page"
    );
  }, [categoryData]);
  const closeProductPopup = () => {
    setPlpProductPopup(false);
  };

  const handleChangeProductPopup = (value, product) => {
    setPlpProductPopup(value);
    setPopupProduct(product);
  };

  const redirectToPdpPage = (url_key, sku) => {
    navigate(productUrlHelper(url_key, sku));
  }

  const metatitle = useMemo(() =>
    categoryId.split("-")[0].charAt(0).toUpperCase() + categoryId.split("-")[0].slice(1),
    [categoryId]
  )

  const tealiumName = useMemo(() =>
    {
      return "category:"+categoryId.replace(/\d+/g,"","").replace("-mc-","");
    }
  )

  if (loading) {
    return <>
      <NewLoader />
      <div className="skeleton-loader-comp">
        <Skeleton width={"100%"} height={"318px"} />
        <br />
        <Skeleton width={"40%"} height={"25px"} />
        <br />
        <div className="skeleton-card-container">
          {Array(4).fill().map(() =>
            <Skeleton
              key={Math.ceil(Math.random() * 9999)}
              height="105px"
              width="248px"
            />
          )}
        </div>
      </div>
    </>
  }

  return (
    <>
      <ReviewTestFreaks />
      <MetaComponent
        title={categoryDetails?.seo?.name || ''}
        url_key={categoryDetails?.seo?.url_key}
        meta_title={categoryDetails?.seo?.meta_title}
        meta_keywords={categoryDetails?.seo?.meta_keywords}
        meta_description={categoryDetails?.seo?.meta_description}
      />
      <TealiumTag name={tealiumName} template={metatitle} />
      {categoryData && categoryData.length > 0 &&
        <div className="container-fluid allCategory">
          {categoryData.map((val, index) => {
            return (
              <React.Fragment key={index}>
                {val.type === "single_banner" && val.element_id === "top_banner_one" &&
                  <CategoryBanner bannerImage={val.items[0]?.imageUrl} {...val.items?.[0] || {}} LCP />
                }
                {val.type === "multiple_banner" && val.element_id === "size_by_category" &&
                  <CategoryByScreenSize screenSizeList={val} />
                }
                {val?.type === "rich_text" && val?.content &&
                  <CmsRichText data={val} />
                }
                <div className="main__bestSellers__block">
                  {val.type === "slider" && val.element_id === "category_bestseller_one" &&
                    <ProductCategory
                      productDetailPage={false}
                      sectionTitle={val.title}
                      // carouselData={getBestSellersData(val)}
                      carouselData={val.products}
                      containerClassName={`bestSellers__block bestSellers__block__${index}`}
                      productType="productNine"
                      // productType="productTwo"
                      // productType="productSeven"
                      handleChangeCartPopup={handleChangeCartPopup}
                      updateCompareProduct={updateCompareProduct}
                      handleChangeProductPopup={handleChangeProductPopup}
                      redirectToPdpPage={redirectToPdpPage}
                      containerClassNameForSlider={`bestSellers__block__${index}`}
                      outOfStockConfiguration={outOfStockConfiguration}
                      screenName="Category"
                      productIdData={productIdData}
                      availablityData={availablityData}
                      customerDetails={customerDetails}
                    />
                  }
                  {val.type === "single_banner" && val.element_id === "second_top_banner" &&
                    <CategoryBanner bannerImage={val.items[0]?.imageUrl} {...val.items?.[0] || {}} />
                  }
                </div>
                {val.type === "multiple_banner" && val.element_id === "multiple_image_one" &&
                  <CategoryThreeBanner bannerImage={val} />
                }
                <div className="main__budgetTv__block">
                  {val.type === "slider" && val.element_id === "category_budget_product" &&
                    <ProductCategory
                      productDetailPage={false}
                      sectionTitle={val.title}
                      // carouselData={getBestSellersData(val)}
                      carouselData={val.products}
                      containerClassName={`budgetTV__block budgetTV__block__${index}`}
                      productType="productNine"
                      // productType="productSeven"
                      handleChangeCartPopup={handleChangeCartPopup}
                      updateCompareProduct={updateCompareProduct}
                      handleChangeProductPopup={handleChangeProductPopup}
                      redirectToPdpPage={redirectToPdpPage}
                      containerClassNameForSlider={`budgetTV__block__${index}`}
                      outOfStockConfiguration={outOfStockConfiguration}
                      screenName="Category"
                      productIdData={productIdData}
                      availablityData={availablityData}
                      customerDetails={customerDetails}
                    />
                  }
                  {val.type === "single_banner" && val.element_id === "category_last_banner" &&
                    <CategoryBanner bannerImage={val.items[0]?.imageUrl}  {...val.items?.[0] || {}} />
                  }
                  {val.type === "multiple_banner" && val.element_id === "category_accessories_product" &&
                    <div className="pb-5">
                      <CategoryThreeBanner
                        bannerImage={val}
                        title={val.title}
                      />
                    </div>
                  }
                </div>
              </React.Fragment>
            )
          })}
          {/* <div className="main__bestSellers__block">
            <SimilarProducts
              productDetailPage={false}
              sectionTitle="Best Sellers"
              carouselData={bestSellersData}
              containerClassName="bestSellers__block"
              productType="productSeven"
            />
            <CategoryBanner bannerImage={bannerImg2} />
          </div>
          <CategoryThreeBanner bannerImage={[adImage1, adImage2, adImage3]} />
          <div className="main__budgetTv__block">
            <SimilarProducts
              productDetailPage={false}
              sectionTitle="Budget TVs"
              carouselData={bestSellersData}
              containerClassName="budgetTV__block"
              productType="productSeven"
            />
            <CategoryBanner bannerImage={bannerImg3} />
          </div>
          <div className="pb-5">
            <CategoryThreeBanner
              bannerImage={[
                tvAccessoriesImage1,
                tvAccessoriesImage2,
                tvAccessoriesImage3,
              ]}
              title="TV Accessories"
            />
          </div> */}

        </div>
      }
      {plpProductPopup === true &&
        <PLPProductPopupModal
          show={plpProductPopup}
          handleChangeCartPopup={handleChangeCartPopup}
          product={popupProduct}
          closeProductPopup={closeProductPopup}
          redirectToPdpPage={redirectToPdpPage}
          outOfStockConfiguration={outOfStockConfiguration}
        />
      }
    </>
  );
};

export default All_Category_Page;
