import React, { useState, useEffect } from "react";
import * as services from "../../services/services";
import { Spinner } from "../../Components/helpers/utils/spinner";
import { useDispatch, useSelector } from "react-redux";
import Price from "../Font/Price";
import { loadCartData, resetNiqatyFlow } from "../../redux/appAction";

const CartApplyStoreCredit = ({ t, cart, user }) => {
  const dispatch = useDispatch();

  const [pending, setPending] = useState(false);
  const [storeCreditsDetails, setStoreCreditsDetails] = useState("");
  const { storeCredits } = useSelector((state) => state.customerReducer);

  useEffect(() => {
    if (user) {
      const getData = async () => {
        setPending(true);
        dispatch(services.getStoreCredits());
        setPending(false);
      };
      getData();
    }
  }, [user]);

  useEffect(() => {
    if (storeCredits) {
      setStoreCreditsDetails(storeCredits);
    }
  }, [storeCredits]);

  const applyHandler = async ({ target }) => {
    try {
      setPending(true);
      if (target.checked) {
        const amount =
          storeCreditsDetails.amount > cart.totals_data.base_grand_total
            ? cart.totals_data.base_grand_total
            : storeCreditsDetails.amount;
        dispatch(services.applyStoreCredit({ amount })).then((res) => {
          dispatch(loadCartData()).then(() => {
            setPending(false);
          });
          dispatch(services.notifySuccess({ message: "Store credit applied" }));
        });
      } else {
        dispatch(services.removeStoreCredit()).then(() => {
          dispatch(loadCartData()).then(() => {
            setPending(false);
          });
          dispatch(services.notifySuccess({ message: "Store credit removed" }));
        });
      }
      dispatch(resetNiqatyFlow(true));
      // setCartHandler(await getCart(token));
    } catch (error) {
      error?.name !== "AbortError" &&
        dispatch(
          services.notifyError({
            message:
              error.name === "TypeError"
                ? t("No internet connection")
                : t(error.message),
          })
        );
      setPending(false);
    }
  };

  return (
    storeCreditsDetails?.amount > 0 && (
      <div className="d-flex cart_apply_credit_wrapper">
        {pending && (
          <div className="cart_apply_credit_pending">
            <Spinner type="dark" />
          </div>
        )}
        <label className="cart_apply_credit_inner">
          <input
            className="payment__input__check"
            type="checkbox"
            onChange={applyHandler}
            defaultChecked={
              cart &&
              cart.totals_data.total_segments.find(
                (x) => x.code === "bss_storecredit"
              )
            }
          />
          <div className="store_credit_title">
            <div>{t("Pay with your balance")}</div>
            {cart &&
            cart.totals_data.total_segments.find(
              (x) => x.code === "bss_storecredit"
            ) ? (
              <div className="d-flex store_credit_current store_credit_applied">
                <Price
                  price={
                    storeCreditsDetails.amount +
                    cart?.totals_data?.total_segments?.find(
                      (x) => x?.code === "bss_storecredit"
                    ).value
                  }
                  size="heading7"
                  currency={cart?.totals_data?.base_currency_code}
                />{" "}
                &nbsp;
                <label>{t("Remaining")}</label>
              </div>
            ) : (
              <div className="store_credit_current">
                <Price
                  price={storeCreditsDetails.amount}
                  size="heading7"
                  currency={cart?.totals_data?.base_currency_code}
                />
              </div>
            )}
          </div>
        </label>
      </div>
    )
  );
};

export default CartApplyStoreCredit;
