import React, { useEffect, useState, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../Components/BreadCrumbs";
import { Link, useNavigate } from "react-router-dom";
import checkout_white_right_arrow from "./../../assets/Icon/checkout_white_right_arrow.svg";
import Heading3 from "../../Components/Font/Heading3";
import Text3 from "../../Components/Font/Text3";
import Price from "../../Components/Font/Price";
import Heading6 from "../../Components/Font/Heading6";
import Text1 from "../../Components/Font/Text1";
import "./../../SCSS/MobilePages/_mobileCartPage.scss";
import { useTranslation } from "react-i18next";
import LoyaltyPointBlock from "../../Components/MostSharedComponent/LoyaltyPointBlock";
import mezzacard from "./../../assets/Icon/mezzacard.png";
import apiHelper from "./../../Components/helpers/utils/apiHelper";
import * as services from "./../../services/services";
import {
  loadCartData,
  getLoyaltyEarnTypesData,
} from "./../../redux/appAction";
import { getAvailablePaymentMethods } from "./../../services/cart.service";
import closeicon from "./../../assets/Icon/cancel_grey.svg";
import empty_Cart from "./../../assets/Icon/empty-cart@2x.png";
import Text31 from "../../Components/Font/Text3.1";
import { getCartTotalSegments } from "../../Components/helpers/utils/getCartTotalSegments";
import { Spinner } from "../../Components/helpers/utils/spinner";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import TabbyWidget from "../../Components/MostSharedComponent/TabbyWidget";
import { getTamaraValue } from "../../Components/helpers/utils/handsakeConfig";
import TamaraProductWidget from "../TamaraProductWidget";
import { impressionEvents } from "../../Components/helpers/utils/ga/impressionEvents";
import TamaraInstallmentPlanWidget from "../TamaraInstallmentPlanWidget";
const ShoppipngCartProduct = lazy(() => import("../../Components/MostSharedComponent/ShoppipngCartProduct"));
const RecommandProducts = lazy(() => import("../../Components/ProductType/RecommandProducts"));


function Mobile_Cart_Page({
  cartProductData,
  cartItem,
  topTrendingData,
  handleChangeCartPopup,
  cartTotalData,
  bundleStatus,
  storeConfig,
  noDelivery,
  isShipping,
  isTabbyEnabled,
  currentLanguageCode,
  isTamaraEnabled,
  isTamaraEnabled6,
  tamara3MonthStatus,
  tamara6MonthStatus,
  availablityData,
  cashbackAmount,
  isTamaraEnabled4
}) {
  const dispatch = useDispatch();
  const [qtyIsDisabled, setQtyIsDisabled] = useState(false);
  // const [disableLeftArrow, setDisableLeftArrow] = useState(true);
  // const [disableRightArrow, setDisableRightArrow] = useState(false);
  // const [arrowState, setArrowState] = useState(true);
  // const [selectCategoryIndex, setSelectCategoryIndex] = useState(0);
  const [noCartProduct, setNoCartProduct] = useState(false);
  const [loyaltyEarnType, setLoyaltyEarnType] = useState([]);
  const [loyaltyEarnTypeId, setLoyaltyEarnTypeId] = useState("");
  const [isBundleExist, setIsBundleExist] = useState(false);
  // const [storePickupGlobal, setStorePickupGlobal] = useState(true);
  const [outOfStockConfiguration, setOutOfStockConfiguration] = useState({});

  const cartData = useSelector((state) => state.appData.cartData);
  const { configurationSettingData } = useSelector((state) => state.appData);

  let getData = JSON.parse(localStorage.getItem("handShakeConfig"));
  const userloginData = useSelector((state) => state.appData.userLoggedIn);

  useEffect(() => {
    if (Object.values(configurationSettingData).length !== 0) {
      const getOutOfStock = configurationSettingData?.out_of_stock_configuration || {};
      setOutOfStockConfiguration(getOutOfStock);
      // const getStorePickup = configurationSettingData?.store_pickup || '0';
      // setStorePickupGlobal(getStorePickup);
    }
  }, [configurationSettingData]);

  useEffect(() => {
    if (Object.values(cartData).length !== 0) {
      if (cartData?.items) {

        if (cartData.conflict_status === 1) {
          setIsBundleExist(true);
        } else {
          setIsBundleExist(false);

        }
        // console.log('data', data);
      }
      // window.scrollTo(0, 0);
    }
  }, [cartData]);
  // const [cartTotalData, setCartTotalData] = useState(
  //   cartProductData.totals_data
  // );
  const [coupenCode, setCoupenCode] = useState("");
  const [isMore, setIsMore] = useState(false);
  // const [isSubscribed, setIsSubscribed] = useState(true);

  const [coupenLoading, setCoupenLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const handleChange = (e) => {
    setCoupenCode(e.target.value);
  };
  const { t } = useTranslation();
  // const productCategorySelect = (categoryIndex, category) => {
  //   // console.log(categoryIndex, category);
  //   setSelectCategoryIndex(categoryIndex);
  // };

  const navigate = useNavigate();
  const cartItems = cartProductData.items_count;

  const error = {};
  error.error_1 = t('storePickupConfictErrorMsg1');
  error.error_2 = t('storePickupConfictErrorMsg1');
  error.error_3 = t('storePickupConfictErrorMsg1');
  error.error_4 = t('storePickupConfictErrorMsg2');
  error.error_5 = t('storePickupConfictErrorMsg3');
  error.error_6 = t('storePickupConfictErrorMsg3');
  error.error_7 = t('storePickupConfictErrorMsg3');
  error.error_8 = t('storePickupConfictErrorMsg3');
  error.error_9 = t('storePickupConfictErrorMsg4');
  error.error_10 = t('storePickupConfictErrorMsg4');
  // error.error_11 = 'case 11';
  // error.error_12 = 'case 12';
  error.error_13 = t('storePickupConfictErrorMsg5');

  useEffect(() => {
    if (cartItems !== 0) {
      setNoCartProduct(true);
    } else {
      setNoCartProduct(false);
    }
  }, [cartItems]);

  // const leftSide = (e) => {
  //   setArrowState(!arrowState);
  //   setDisableRightArrow(false);
  //   e.preventDefault();
  //   document.querySelector(".sc__page__button__block").scrollLeft =
  //     document.querySelector(".sc__page__button__block").scrollLeft -
  //     document.querySelector(".sc__page__button__block").clientWidth -
  //     800;
  //   let scroll = document.querySelector(".sc__page__button__block").scrollLeft;
  // };
  const selectedLoyaltyPointsProgram = useSelector(
    (state) => state.appData.selectedLoyaltyPointsProgram
  );
  const loyaltyEarnTypeList = useSelector(
    (state) => state.appData.loyaltyEarnTypeList
  );
  // const rightSide = (e) => {
  //   setArrowState(!arrowState);
  //   setDisableLeftArrow(false);
  //   e.preventDefault();

  //   document.querySelector(".sc__page__button__block").scrollLeft =
  //     document.querySelector(".sc__page__button__block").scrollLeft +
  //     document.querySelector(".sc__page__button__block").clientWidth -
  //     800;
  // };
  // const handleButton = () => {
  //   window.replace("/");
  // };
  useEffect(() => {
    if (selectedLoyaltyPointsProgram !== "") {
      setLoyaltyEarnTypeId(selectedLoyaltyPointsProgram);
    }
  }, [selectedLoyaltyPointsProgram]);

  const handleApplyCode = async () => {
    if (coupenCode !== "") {
      setCoupenLoading(true);
      let params = {
        couponCode: coupenCode,
      };
      try {
        let response = await apiHelper(
          `/V1/cart/coupon-code`,
          "put",
          params,
          {}
        );
      
        impressionEvents.impressionCouponApply(
            coupenCode,
            "Coupon code is applied"
        );
          
        dispatch(
          services.notifySuccess({ message: t("Coupon code is applied") })
        );
        dispatch(loadCartData());
        dispatch(getAvailablePaymentMethods());
        setCoupenCode('');
        setCoupenLoading(false);
      } catch (error) {
        setCoupenLoading(false);
        
          impressionEvents.impressionCouponApply(
            coupenCode, t(error?.response?.data?.message)
          );
       
        dispatch(
          services.notifyError({ message: t(error?.response?.data?.message) })
        );
        dispatch(loadCartData());
        dispatch(getAvailablePaymentMethods());
      }
    }
  };

  const removeCouponCode = async () => {
    setRemoveLoading(true);
    try {
      let response = await apiHelper(
        `/V1/cart/coupon-code`,
        "delete",
        {},
        {}
      );
      dispatch(
        services.notifySuccess({ message: t("Remove successfully") })
      );
      dispatch(loadCartData());
      dispatch(getLoyaltyEarnTypesData());
      dispatch(getAvailablePaymentMethods());
      setRemoveLoading(false);
    } catch (error) {
      // console.log("error ", error);
      dispatch(
        services.notifyError({ message: t(error?.response?.data?.message) })
      );
      dispatch(loadCartData());
      dispatch(getLoyaltyEarnTypesData());
      dispatch(getAvailablePaymentMethods());
      setRemoveLoading(false);
    }
  };

  useEffect(() => {
    if (loyaltyEarnTypeList) {
      setLoyaltyEarnType(loyaltyEarnTypeList);
    }
  }, [loyaltyEarnTypeList]);

  // const handleChangeSub = event => {
  //   setIsSubscribed(true);
  // };
  // console.log('isBundleExist', isBundleExist);

  const currentLang = localStorage.getItem("i18next") || "en";
  let quitafcard = require(`./../../assets/Icon/icon-qitaf-${currentLang}.png`)

  return (
    <>
      <BreadCrumbs
        back="/"
        firstBread="/cart"
        firstBreadTitle={t('Shopping_cart')}
        highlight="firstBreadTitle"
      />
      <div className="container-fluid shopping__cart__page__container">
        <div className="shopping__cart__page__block">
          <div className="cart__notice__area">
            <Text31
              text={t('Dear_customer_all_the_products_you_purchase_through')}
              text2={`${t('Disclaimer')}`}
              textAlign="center"
            />
          </div>
          <div className="sc__page__title">
            {" "}
            <Heading3 text={t('Shopping_cart')} span={true} />{" "}
            <Text1
              text={`(${cartItem && cartItem} Items)`}
              color="#FF4F04"
              span={true}
            />
          </div>
          {noCartProduct === false ? (
            <>
              <div className="shopping__cart__emptyBox mb__cart__emptybox">
                <img src={empty_Cart} />
                <Text3 text={t('You_don_t_have_any_items_in_your_cart_yet')} />
                <Link to="/" className="continue_shopping_btn">
                  {t("CONTINUE_SHOPPING")}
                </Link>
              </div>
            </>
          ) : (
            <div className="row shopping__cart__page__inner__block">
              <div className={`col-md-12  shopping__cart__left__block ${qtyIsDisabled === true ? "is_disabled_product_qty" : ""}`}>
                {storeConfig === '1' && cartProductData?.error && cartProductData?.error?.error && cartProductData?.error?.code && cartProductData?.error?.code !== 13 && (
                  <div className="pickupWarning">
                    {error[`error_${cartProductData?.error?.code}`]}
                  </div>
                )}
                {
                  bundleStatus === 1 && cartData?.totals_data?.extension_attributes?.is_bundle && (
                    <div class="alert alert-success  green-alert-info margin-alert" role="alert">
                      {t("success_alert")}
                    </div>
                  )
                }
                {
                  bundleStatus === 2 && !cartData?.totals_data?.extension_attributes?.is_bundle && (
                    <div class="alert alert-danger red-alert-info margin-alert" role="alert">
                      {t("failed_alert")}
                    </div>
                  )
                }
                {cartProductData &&
                  cartProductData.items.map((pro, proIndex) => {
                    // console.log("cartproduct",pro);
                    // console.log("Cart Pageproduct.qty",pro.qty);
                    return (
                      <ShoppipngCartProduct key={pro.item_id} totals={cartProductData?.totals_data?.items.find((t) => t.item_id === pro.item_id)} totalData={cartProductData?.totals_data} product={pro} setQtyIsDisabled={setQtyIsDisabled} screenName="Cart" storeConfig={storeConfig} />
                    );
                  })}
                {/* <ShoppipngCartProduct product={product} /> */}

                <hr className="sc__page__horizontal__line"></hr>
                {/* <ShoppipngCartProduct product={product} /> */}
                {/* <div className="sc__newArrival__block">
                  <Heading4
                    text="People Who Bought Also Bought"
                    marginLeft={0}
                    marginBottom={0}
                    align="center"
                  />
                  <ProductContainer
                  productDetailPage={true}
                  // sectionTitle="People Who Bought Also Bought"
                  carouselData={newArrivalData}
                  containerClassName="people__also__bought__block"
                />
                </div> */}
              </div>
              {topTrendingData &&
                <div className="main__recommand__product__block">
                  <Heading3
                    text={t('Recommendations_for_all_products')}
                    marginBottom={20}
                  />
                  {topTrendingData.filter((val, i) => isMore === true ? i : i < 2).map((product, productIndex) => {
                    return (
                      <RecommandProducts
                        key={product?.id}
                        product={product}
                        handleChangeCartPopup={handleChangeCartPopup}
                        outOfStockConfiguration={outOfStockConfiguration}
                        productIndex={productIndex}
                      />
                    );
                  })}
                  <div className="view__more__address__block">
                    <button
                      onClick={() =>
                        setIsMore(!isMore)
                      }
                      className="view__more__address__button"
                    >
                      <Heading6
                        text={
                          isMore
                            ? t("View_Less")
                            : t("View_More")
                        }
                        marginBottom={0}
                        color="#000000"
                      />
                      {isMore === true ? <BsChevronUp /> : <BsChevronDown />}
                    </button>
                  </div>
                </div>
              }
              {/* package Summary */}

              {cartItem !== 0 && (
                <>
         
                    <div className="mt-3 mb-2 p-0">
                      {isTamaraEnabled && tamara3MonthStatus === true && (
                        <div className="product__offer__block">
                          <TamaraInstallmentPlanWidget
                            months={3}
                            price={
                              cartData?.totals_data
                                ?.base_grand_total
                            }
                            currency={
                              cartData?.totals_data
                                ?.base_currency_code
                            }
                            minLimit={getTamaraValue('tamara3_min')}
                            maxLimit={getTamaraValue('tamara3_max')}
                            currentLanguageCode={currentLanguageCode}
                            inlineType={2}
                          />
                        </div>
                      )}
                      {isTamaraEnabled6 && tamara6MonthStatus === true && (
                        <div className="product__offer__block">
                          <TamaraInstallmentPlanWidget
                            months={6}
                            price={
                              cartData?.totals_data
                                ?.base_grand_total
                            }
                            currency={
                              cartData?.totals_data
                                ?.base_currency_code
                            }
                            minLimit={getTamaraValue('tamara6_min')}
                            maxLimit={getTamaraValue('tamara6_max')}
                            currentLanguageCode={currentLanguageCode}
                            inlineType={2}
                          />
                        </div>
                      )}
                      {isTamaraEnabled4 && (
                        <div className="product__offer__block">
                          <TamaraInstallmentPlanWidget
                            months={4}
                            price={
                              cartData?.totals_data
                                ?.base_grand_total
                            }
                            currency={
                              cartData?.totals_data
                                ?.base_currency_code
                            }
                            minLimit={getTamaraValue('tamara6_min')}
                            maxLimit={getTamaraValue('tamara6_max')}
                            currentLanguageCode={currentLanguageCode}
                            inlineType={2}
                          />
                        </div>
                      )}
                      {isTabbyEnabled &&
                        <div className="product__offer__block">
                          <TabbyWidget
                            price={
                              cartData?.totals_data
                                ?.base_grand_total
                            }
                            currency={
                              cartData?.totals_data
                                ?.base_currency_code
                            }
                            widgetType="TabbyPromo"
                            currentLanguageCode={currentLanguageCode}
                            timeOut={500}
                          />
                        </div>
                      }
                    </div>
             
                  <div className="col-md-12  shopping__cart__right__block">
                    <div className="sc__package__summary__block">
                      <div className="sc__ps__title">
                        {" "}
                        <Heading3 text={t('Order_Summary')} />
                      </div>
                      <div className="sc__ps__detail__block">
                        <div className="sc__ps__detail__inner__block">
                          <Text3 text={t('Sub_Total_Without_Tax')} color="#000000" />
                          <Price
                            price={
                              cartTotalData && cartTotalData.items_qty !== 0
                                ? cartTotalData?.base_subtotal
                                : 0
                            }
                            size="heading7"
                            currency={
                              cartTotalData && cartTotalData.base_currency_code
                            }
                          />
                        </div>
                        {cashbackAmount > 0 && <div className="sc__ps__detail__inner__block">
                          <Text3 text={t('Cashback')} color="#168F00" />
                          <Price
                            price={
                              cashbackAmount || 0
                            }
                            size="heading7"
                            color="#168F00" 
                            currency={
                              cartTotalData && cartTotalData.base_currency_code
                            }
                          />
                        </div>}
                        <div className="sc__ps__detail__inner__block">
                          <Text3 text={t('Shipping_Handling')} color="#727272" />
                          <Price
                            price={
                              cartTotalData && cartTotalData.items_qty !== 0
                                ? cartTotalData?.base_shipping_amount
                                : 0
                            }
                            size="heading7"
                            currency={
                              cartTotalData && cartTotalData?.base_currency_code
                            }
                          />
                        </div>
                        {getCartTotalSegments(cartTotalData?.total_segments, 'discount').length > 0 &&
                          <div className="sc__ps__detail__inner__block discount_info_row">
                            {removeLoading &&
                              <div className="discount_remove_spinner">
                                <Spinner type="dark" />
                              </div>
                            }
                            <div className="d-flex flex-row">
                              <Text3 text={t('Discount')} color="#727272" />
                              {
                                !cartData?.totals_data?.extension_attributes?.is_bundle && (
                                  <button
                                    className="btn btn-link p-0 ms-1"
                                    type="button"
                                    id="button-addon2"
                                    onClick={() => removeCouponCode()}
                                  >
                                    {t("remove")}
                                  </button>
                                )
                              }
                            </div>
                            <Price
                              price={
                                cartTotalData && cartTotalData.items_qty !== 0
                                  ? cartTotalData?.discount_amount
                                  : 0
                              }
                              size="heading7"
                              currency={
                                cartTotalData && cartTotalData?.base_currency_code
                              }
                            />
                          </div>
                        }
                        <div className="sc__ps__detail__inner__block">
                          <Text3 text={t('Tax')} color="#727272" />
                          <Price
                            price={
                              cartTotalData && cartTotalData.items_qty !== 0
                                ? cartTotalData?.base_tax_amount
                                : 0
                            }
                            size="heading7"
                            currency={
                              cartTotalData && cartTotalData?.base_currency_code
                            }
                          />
                        </div>
                        <ul>
                          {loyaltyEarnType &&
                            loyaltyEarnTypeId &&
                            loyaltyEarnType
                              .filter(
                                (item, i) => item.program === loyaltyEarnTypeId
                              )
                              .map((item, i) => {
                                let imageUrl =
                                  item.program === "meza" ? mezzacard : quitafcard;
                                return (
                                  <>
                                    <li className="d-flex  pt-1 align-items-center w-100 justify-content-between">
                                      <p className="m-0">
                                        <img src={imageUrl} />
                                      </p>
                                      <span className="sum__points d-block">
                                        +{`${item.points}`} {t("points")}
                                      </span>
                                    </li>
                                    <hr />
                                  </>
                                );
                              })}
                        </ul>
                        <LoyaltyPointBlock
                          loyaltyEarnType={loyaltyEarnType}
                          loyaltyEarnTypeId={loyaltyEarnTypeId}
                          currency={
                            cartTotalData && cartTotalData?.base_currency_code
                          }
                          cartDetail={cartProductData}
                        />
                        <div className="sc__ps__detail__total__order__block">
                          <Heading6 text={t('Grand_Total')} color="#000000" />
                          <Price
                            price={
                              cartTotalData && cartTotalData?.base_grand_total
                            }
                            size="heading5"
                            currency={
                              cartTotalData && cartTotalData?.base_currency_code
                            }
                          />
                        </div>
                        {
                          !isBundleExist && (
                            <div className="checkout__coupon__block mt-2">
                              <p>
                                <strong>{t("Coupon_code_or_gift_card")}</strong>
                              </p>
                              <div className="cart_coupon_code_inputbox">
                                {coupenLoading &&
                                  <div className="cart_coupon_spinnerbox">
                                    <Spinner type="dark" />
                                  </div>
                                }
                                <div className="input-group d-inline-block me-1">
                                  <input
                                    type="text"
                                    value={coupenCode}
                                    className="form-control w-100"
                                    placeholder={t('Enter_Code')}
                                    onChange={handleChange}
                                  />
                                  <button
                                    className=""
                                    type="button"
                                    id="button-addon2"
                                    onClick={() => handleApplyCode()}
                                  >
                                    {t("Apply")}
                                  </button>
                                </div>
                                {coupenCode !== "" && (
                                  <div
                                    onClick={(e) => setCoupenCode("")}
                                    className="d-inline-block remove_coupen_code_btn"
                                  >
                                    <img src={closeicon} alt="close" />
                                  </div>
                                )}
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                    <div className="checkout__button__link" >
                      <button className="col-6 sc__addToCart__button__block border-0" disabled={(!noDelivery && !cartProductData?.is_virtual) || isShipping}
                        onClick={() => {
                          localStorage.removeItem("last-dt");
                          { userloginData === true || getData?.user?.userLoggedIn === true ? navigate("/checkout/delivery") : navigate("/checkout/signin")}
                        }}
                      >
                        <div className="sc__addToCart__button">
                          {isShipping ? <Spinner /> :
                            <>
                              <span>{t("Proceed_to_Checkout")}</span>
                              <img
                                src={checkout_white_right_arrow}
                                alt=""
                                className="sc__addToCart__icon"
                              />
                            </>
                          }
                        </div>
                      </button>
                    </div>
                    {/* <div className="mb__sc__fixed__btn d-flex justify-content-between">
                    <div>
                      <Price
                        price={cartTotalData && cartTotalData?.base_grand_total}
                        size="heading5"
                        currency={
                          cartTotalData && cartTotalData?.base_currency_code
                        }
                      />
                      <Text3
                        text={`(${cartItem && cartItem} Items)`}
                        color="#fff"
                      />
                    </div>
                    <div>
                      <button
                        className="btn btn__primary__white"
                        onClick={() => {
                          navigate("/checkout");
                        }}
                      >
                        {t("Proceed_to_Checkout")}
                      </button>
                    </div>
                  </div> */}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {/* <NewsLetter /> */}
      {/* <Footer /> */}
    </>
  );
}

export default Mobile_Cart_Page;