import React, { useEffect, useState, lazy, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ProductCarousel from "../../Components/ProductCarousel";
import "./../../SCSS/MobilePages/_mobileProductDetail.scss";
import Heading3 from "../../Components/Font/Heading3";
import RatingBlock from "../../Components/MostSharedComponent/RatingBlock";
import Heading7 from "../../Components/Font/Heading7";
import Text3 from "../../Components/Font/Text3";
import Heading5 from "../../Components/Font/Heading5";
import PriceBlock from "../../Components/MostSharedComponent/PriceBlock";
import Heading6 from "../../Components/Font/Heading6";
import shopping_cart from "./../../assets/Icon/shopping_cart.svg";
import { addToCart, updateCompareProductList, getCartData } from "../../services/cart.service";
import { loadCartData } from "../../redux/appAction";
import { useDispatch, useSelector } from "react-redux";
import * as services from "./../../services/services";
import { useTranslation } from "react-i18next";
import ProductCategoryLevel from "../../Components/Products/ProductCategoryLevel";
import AvailableOffers from "../../Components/MostSharedComponent/AvailableOffers";
import return_period from "./../../assets/Icon/return_period.svg";
import htmlParseHelper from "../../Components/helpers/utils/htmlParseHelper";
import { titleChange } from "../../Components/helpers/utils/titleNameChange";
import { getProductIndexByVarient } from "../../Components/MostSharedComponent/ProductVariantOptions";
import CountdownBox from "../../Components/MostSharedComponent/CountdownBox";
import DiscountOfferBox from "../../Components/MostSharedComponent/DiscountOfferBox";
import NotifyMeBtn from "../../Components/MostSharedComponent/NotifyMeBtn";
import BundleOffer from "../../Components/BundelOffer";
import BuyNow from "../../Components/Buttons/BuyNow";
import BundleView from "../../Components/BundleView";
import MizaAndQitafPDP from "../../Components/Pdp/MizaAndQitafPDP";
import { BsChevronDown, BsChevronLeft, BsChevronUp } from "react-icons/bs";
import { cartEvents } from "../../Components/helpers/utils/ga/cartEvents";
import { Spinner } from "../../Components/helpers/utils/spinner";
import useCartErrorMessage from "../../Components/helpers/hooks/useCartErrorMessage";
import RelatedProduct from "../../Components/MostSharedComponent/RelatedProduct";
import MainStorePickup from "../../Components/Pdp/MainStorePickup";
import MainStoreDelivery from "../../Components/Pdp/MainStoreDelivery";
// import { checkoutEvents } from "../../Components/helpers/utils/ga/checkoutEvents";
// import PdpAccordianMobile from "../../Components/Pdp/PdpAccordianMobile";
import SoldOutProductLabel from "../../Components/MostSharedComponent/SoldOutProductLabel";
// import VisaHolderNote from "../../Components/ApplePay/VisaHolderNote";
// import ApplePay from "../../Components/ApplePay/ApplePay";
import { impressionEvents } from "../../Components/helpers/utils/ga/impressionEvents";
import MoreServices from "../../Components/Pdp/MoreServices";
import SubscribeSection from "../../Components/Meza/SubscribeSection";
import SubsSectionContent from "../../Components/Meza/SubsSectionContent";

const CmsContentModal = lazy(() => import("../../Components/MostSharedComponent/CmsContentModal"));
const PdpAccordianMobile = lazy(() => import("../../Components/Pdp/PdpAccordianMobile"));


function Mobile_Product_Detail_Page({
  product,
  productSpecificationData,
  handleChangeCartPopup,
  productOverviewData,
  firstBread,
  firstBreadTitle,
  getOptionKeyInfo,
  returnPolicyData,
  discountPercentage,
  discountedPrice,
  sizeButtonClick,
  sizeButtonIndex,
  isOutOfStock,
  isPreOrder,
  outOfStockConfiguration,
  productOutOfStockStatus,
  copyToClipboard,
  updateCompareProduct,
  selectedCompareProductStatus,
  PRODUCT_COUNT_ARRAY,
  qtyDropBtn,
  screenName,
  productReviewData,
  isMobile,
  closeComparePopup,
  setRefreshcomparePopup,
  storePickupGlobalConfiguration,
  relatedProductList,
  isApplePayAvailable,
  setFirstImage,
  firstImage,
  isExWarrantyAvailable,
  warrantyProduct,
  customerDetails
}) {
  const dispatch = useDispatch();

  const { setCartErr } = useCartErrorMessage();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);
  // const [loaderBuyOn, setLoaderBuyOn] = useState(false);
  const [count, setCount] = useState(1);
  // const [isDescription, setIsDecription] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [finalData, setFinalData] = useState([]);
  const [isMore, setIsMore] = useState(false);

  const productData = useSelector((state) => state.appData.productData);

  useEffect(() => {
    if (count > product?.stock) {
      dispatch(services.notifyError({ message: t('Sellable stock is %1').replace('%1', product?.stock) }));
      setCount(product?.stock);
    }
  }, [count]);

  // const [storeCodes, setStoreCodes] = useState(null);
  // const [productProtection, setProtection] = useState([
  //   {
  //     id: 1,
  //     protectionText: "2-Year Standard Geek Squad Protection",
  //     price: 79,
  //     month: 12,
  //   },
  //   {
  //     id: 2,
  //     protectionText: "1-Year Standard Geek Squad Protection",
  //     price: 89,
  //     month: 12,
  //   },
  // ]);

  const useImageLoaded = () => {
    const [loaded, setLoaded] = useState(false);
    const ref = useRef();

    const onLoad = () => {
      setLoaded(true);
    };

    useEffect(() => {
      if (ref.current && ref.current.complete) {
        onLoad();
      }
    });
    return [ref, loaded];
  };

  const [carousel, setcarousel] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isCartData, setIsCartData] = useState(false);
  const [clickData, setClickData] = useState();
  const [clickSkuData, setClickSkuData] = useState();
  const [warrantyDataArray, setWarrantyDataArray] = useState([]);
  const currentLang = localStorage.getItem("i18next") || "en";

  // const [accordionItemIndex, setAccordionItemIndex] = useState(null);
  // const [accordionStatus, setAccordionStatus] = useState(false);

  const removeProductFromCompare = (productId) => {
    const comparePopupLocalData = JSON.parse(localStorage.getItem("compare_products"));
    let removeData = comparePopupLocalData && comparePopupLocalData.filter(item => item.id !== productId);
    localStorage.setItem('compare_products', JSON.stringify(removeData));
    localStorage.setItem('compare_products_max_3', JSON.stringify(false));
    dispatch(updateCompareProductList(removeData))
    // setPageRender(true);
    if (removeData.length === 0) {
      closeComparePopup();
    }
    setRefreshcomparePopup(true)
  }

  const handleCompare = (compareStatus, productData) => {
    if (compareStatus === false) {
      updateCompareProduct(true, productData)
    } else {
      removeProductFromCompare(productData?.id)
    }
  }

  useEffect(() => {
    setFinalData(
      product?.description && product?.description.split(`<div id="specs">`)
    );
  }, [product?.description]);

  // useEffect(async () => {
  //   const externalVideo =
  //     product && product?.media && product?.media?.["external-video"].length > 0
  //       ? product?.media?.["external-video"]
  //       : [];

  //   const allImgData =
  //     product &&
  //       product?.media &&
  //       product?.media?.image &&
  //       product?.media?.image?.screenshots !== undefined
  //       ? [...product?.media?.image?.screenshots, ...externalVideo]
  //       : [...externalVideo];

  //   if (allImgData) {
  //     let filterImageData = [];
  //     for (let index = 0; index < allImgData?.length; index++) {
  //       const ban = allImgData[index];
  //       await checkIfImageExists(ban.image).then((exists) => {
  //         if (exists) {
  //           filterImageData.push(ban);
  //         }
  //       });
  //     }
  //     setcarousel(filterImageData);
  //     setLoading(false);
  //   }
  // }, [product]);

  // const handleFavourite = () => {
  //   if (isFavourite) {
  //     setIsFavourite(false);
  //     setWislistCount(wislistCount + 1);
  //   } else {
  //     setIsFavourite(true);
  //     setWislistCount(wislistCount + 1);
  //   }
  // };
  const buyNow = (sku, id) => {
    // setLoaderBuyOn(true);
    AddProductToCart(sku, id, true);
  };
  function compareArraysBySingleKey(arr1, arr2, key) {
    const set2 = new Set(arr2.map(item => item[key]));
    const result = arr1.filter(item => !set2.has(item[key]));
    return result;
  }
  //let warrantyDataArray = [];
  const warrentyData = (param) => {
    setWarrantyDataArray(param)
  }
  const AddProductToCart = async (sku, id, buynow = false) => {
    // impressionEvents.impressionAddToCart(
    //   product,
    //   count,"product detail"
    // );
    setLoaderOn(true);
    if (!count) {
      const message = t('Please enter quantity');
      dispatch(services.notifyError({ message }));
      setLoaderOn(false);
      setIsCartData(false);
      return false;
    }
    if (
      product?.variants &&
      product?.variants.length > 0 &&
      sizeButtonIndex &&
      sizeButtonIndex >= 0
    ) {
      sku = product?.variants[sizeButtonIndex].sku;
    }
    const allCartItems = await getCartData();
          let updatedWarrantyData = warrantyDataArray.map((val)=>{return { sku: val.sku,
            id: val.id,
            qty: 1,}});
          updatedWarrantyData = compareArraysBySingleKey(updatedWarrantyData,allCartItems?.data?.items,"sku")

    const data = {
      items: [
        {
          sku: sku,
          id: product?.id,
          qty: count,
        },
      ].concat(updatedWarrantyData),
    };
    addToCart(data)
      .then((res) => {
        // setCount(res.data.filter((pro) => pro.sku === product?.sku)[0].qty);
        if (buynow) {
          dispatch(loadCartData()).then((item) => {
            cartEvents.addToCart(product, item?.id, "Product Detail")
            navigate("/checkout/delivery");
            setIsCartData(false);
          });
        } else {
          setSpinnerOn(true)
          dispatch(loadCartData()).then((item) => {
            cartEvents.addToCart(product, item?.id, "Product Detail")
            handleChangeCartPopup(true);
            setSpinnerOn(false)
          });
        }
      })
      .catch((error) => {
        setCartErr({ stock: product?.stock, error: error?.response?.data });
      })
      .finally(() => {
        setLoaderOn(false);
        setIsCartData(false);
      });
  };

  const [isCheckBoxHover, setIsCheckBoxHover] = useState(false);
  const [isCheckBox, setIsCheckBox] = useState(false);
 
  // const buyNow = (sku, id) => {
  //   // setLoaderBuyOn(true);
  //   AddProductToCart(sku, id, true);
  // };

  const redirectBundlePage = (sku) => {
    let redUrl = `/create-bundle/${sku.replace(/[/]/g, "%2F")}`;
    navigate(redUrl);
  };

  const numberOfBtnCheck = () => {
    let defaulClass = "oneBtnOption";
    if (product?.stock > 0 && product?.bundle_products[0]?.length > 0) {
      defaulClass = "";
    }
    return defaulClass;
  };

  const [modalShow, setModalShow] = React.useState(false);

  const handleChangeSub = (event) => {
    setIsSubscribed(true);
  };

  // product dropdown count change
  const handleChangeCount = (event) => {
    setCount(event.target.value)
  }
  useEffect(() => {
    impressionEvents.impressionProductDetails(
      product
    );
  }, [product]);
  useEffect(() => {
    impressionEvents.impressionGlobalVariable(
      currentLang, "PDP Mobile Page"
    );
  }, [product]);

  const targetPrice = product?.price_rounded;
  const matchedProduct = product?.variants?.filter(product => product?.price_rounded === targetPrice) || [];
  const newMatchPrice = matchedProduct?.length > 1 ? matchedProduct?.[0] : matchedProduct?.[0];
  const newMatchPriceMatch = matchedProduct?.length > 1 ? matchedProduct?.[0] : matchedProduct;
  const screenSize = matchedProduct?.length > 1 ? newMatchPrice?.options?.screen_size : newMatchPrice?.[0]?.options?.screen_size;
  const seletcedScreenSize = product?.variantOptions?.[0].attribute?.options.filter(product => product.id === screenSize);

  const screenSizeMatch = matchedProduct?.length > 1 ? newMatchPriceMatch?.options?.screen_size : newMatchPriceMatch[0]?.options?.screen_size;
  const seletcedScreenSizeMatch = product?.variantOptions?.[0].attribute?.options.filter(product => product.id === screenSizeMatch);
  const handleClick = () =>{
      const targetSKU = product?.sku;
      const matchedSKU = product?.variants?.filter(product => product.sku === targetSKU);
      const matchedSKUScreenSize = matchedSKU && matchedSKU[0]?.options?.screen_size;
      const matchedSKUSeletcedScreenSize = product?.variantOptions?.[0].attribute?.options.filter(product => product.id === matchedSKUScreenSize);
      return matchedSKUSeletcedScreenSize;
  }
  const result = handleClick();
  const stringToCheck = product?.name;
  const checkSKU = product?.sku;
  const regex = /-(.+)/;
  const regex2 = /\d+$/;
  const hasAnythingAfterDash = regex.test(stringToCheck);
  const hasAnythingAfterSku = regex2.test(checkSKU);

  useEffect(() => {
    if (product?.variants && product?.variants.length > 0 && sizeButtonIndex >= 0) {
      setClickData(product?.variants[sizeButtonIndex]?.name);
      setClickSkuData(product?.variants[sizeButtonIndex]?.sku);
    }
  }, [product, sizeButtonIndex]);
  useEffect(() => {
    if (product?.id) {
      impressionEvents.impressionProductDetails(
        product,
        "product Detail"
      );
      impressionEvents.impressionGlobalVariable(
        currentLang, "PDP Page"
      );
    }
   
  }, [product?.id]);
  return (
    <>
      {modalShow && <div className="bg-white">
        <CmsContentModal
          title={t("7_Days_Return_Policy")}
          modalShow={modalShow}
          content={
            returnPolicyData &&
            htmlParseHelper(returnPolicyData?.content?.[0]?.content)
          }
          onHide={() => setModalShow(false)}
          size="lg"
          scrollable={true}
        />
      </div>}
      <div className="mb__pdp__topbar__section">
        <Link to={firstBread} className="mb__pdp__back__btn"><BsChevronLeft /> {firstBreadTitle}</Link>
        {product &&
          <div className="mb__pdp__compare__block">
            <input
              type="checkbox"
              className="mb__pdp__compare__input__check"
              name="compare"
              checked={selectedCompareProductStatus(product?.id)}
              onChange={() => handleCompare(selectedCompareProductStatus(product.id), product)}
              id={product.id}
            />
            <label for={product.id} className="mb__pdp__compare__text">{t("Compare")}</label>
          </div>
        }
      </div>
      <div>
        <div className="container-fluid">
          <div className="row mb__product__detail__block">
            <div className="p-0">
              <SoldOutProductLabel
                t={t}
                product={product}
                outOfStockConfiguration={outOfStockConfiguration}
              />
              <div className="mb__product__details__titlebox">
                {/* <button className="pd__favourite__button">
                  <img
                    onMouseEnter={() => setIsFavouriteHover(true)}
                    onClick={handleFavourite}
                    onMouseLeave={() => setIsFavouriteHover(false)}
                    className={
                      !isFavourite
                        ? "pd__favourite__icon"
                        : "pd__favourite__icon__disable"
                    }
                    src={isFavouriteHover ? fulfill_favourite : empty_favourite}
                    alt=""
                  />
                  <img
                    onClick={handleFavourite}
                    className={
                      isFavourite
                        ? "pd__favourite__icon"
                        : "pd__favourite__icon__disable"
                    }
                    src={fulfill_favourite}
                    alt=""
                  />
                </button> */}
                <div className="mb__product__namebox">
                {/* {!hasAnythingAfterDash ? newMatchPrice ? <Heading3 text={titleChange(newMatchPrice?.name)} /> :  <Heading3 text={titleChange(product?.name)} />  : <Heading3 text={titleChange(product?.name)} /> } */}
                {clickData ? <Heading5 text={clickData} />  : newMatchPrice ? <Heading5 text={newMatchPrice?.name} /> : <Heading5 text={product?.name} /> }
                  <div className="pdp__sticky__counter__area mb__pdp__qty__sku ">
                    <div className=" mb__sku__infobox d-flex align-items-center">
                      <Heading6 text={`${t("SKU")} : `} />
                      {" "}&nbsp;
                      {/* {!hasAnythingAfterSku ? newMatchPrice ? <Text3 text={newMatchPrice?.sku} /> : <Text3 text={product?.sku} /> : <Text3 text={product?.sku} /> } */}
                      {clickSkuData ? <Text3 text={clickSkuData} />  : newMatchPrice ? <Text3 text={newMatchPrice?.sku} /> : <Text3 text={product?.sku} /> }
                      <a href="#" className="mb__pro__sku__copy" onClick={(e) => copyToClipboard(product?.sku)}>{t("Copy")}</a>
                    </div>
                  </div>
                  {product?.is_bundle_enable === 1 &&
                  <div className="row pd__product__price__block mt-4">
                    <div className="col-12 col-sm-12 col-md-12 mt-2 d-flex justify-content-end">
                      <BundleOffer
                        bundleEnable={product?.is_bundle_enable}
                        bundleProducts={product.user_bundle_products}
                        t={t}
                      />
                    </div>
                  </div>
                }
                </div>
              </div>
              <DiscountOfferBox product={product} />

              <div className="mb__product__detail__carousel">
                <ProductCarousel
                  productImageData={product?.media?.image?.screenshots}
                  product={product}
                  updateCompareProduct={updateCompareProduct}
                  view="mobile"
                  setFirstImage={setFirstImage}
                  firstImage={firstImage}
                />
              </div>
              {product?.cart_rule?.title && product?.cart_rule?.title !== '' && (
                <div className="productOfferTagMobile">
                  <p>{product?.cart_rule?.title}</p>
                </div>
              )}
            </div>
          </div>
          <div className="row mb__product__detail__block">
            <div className="col-12">

              {discountPercentage < 100 && discountPercentage > 0 && (
                <>
                  {!product?.cart_rule?.title
                    && product?.discounted_price_from
                    && Date.now() < new Date(product?.discounted_price_to).getTime()
                    && product?.is_count_down && (
                      <CountdownBox
                        product={product}
                        t={t}
                        current={product?.current_time}
                        start={product.discounted_price_from}
                        end={product?.discounted_price_to}
                        discountedPrice={discountedPrice}
                        price={product?.price_rounded}
                        discountPercentage={discountPercentage}
                      />
                    )}
                </>
              )}
              <div className="pd__category__favourite__button__block">
                <ProductCategoryLevel product={product} />
              </div>

              {/* <Rating PriceBlock */}
              {product?.reviewSummary && (
                <RatingBlock
                  size={22}
                  rating={product?.reviewSummary?.avg}
                  totalRatings={Object.values(
                    product?.reviewSummary?.totals === undefined
                      ? 0
                      : product?.reviewSummary?.totals
                  ).reduce((a, b) => a + b)}
                  // totalRatings={0}
                  fillColor="#DC3A1A"
                  emptyColor="#C8C8C8"
                />
              )}

              {/* Price Block */}

            </div>
          </div>
        </div>
        {/* m_pdp banner and detail sections ends here */}
        {/* <hr className="mb__block__bottom__line" /> */}

        {/* size section start */}
        
        <div className="container-fluid mb__pdp__size__sec">
          <div className="row mb__pd__size__block">
            {product &&
            product?.variantOptions &&
            product?.variantOptions[0]?.attribute &&
            product?.variantOptions[0]?.attribute?.options && (
              <div className="col-7 col-md-6">
                <p className="col-12 mb__pd__size__title">{t("Select TV size")}</p>
                <div className="col-12 mb__pd__size__button__block">
                  {product?.variantOptions[0]?.attribute?.options.map(
                    (size, sizeIndex) => {
                      let indexOfVariants = getProductIndexByVarient(product, size.id)
                      let currentActive;
                          if(result.length == 0){
                            currentActive =
                            seletcedScreenSizeMatch?.[0]?.label === size.label
                          ? "mb__pd__size__button__active"
                          : "mb__pd__size__button";
                          }
                          else{
                            currentActive = result[0]?.label && 
                          result[0]?.label === size.label ? "mb__pd__size__button__active"  : "mb__pd__size__button";

                          }
                      return (
                        <button
                          key={size.id}
                          onClick={() => sizeButtonClick(indexOfVariants, size)}
                          className={currentActive}
                            // className={
                            //   sizeButtonIndex === indexOfVariants
                            //     ? "mb__pd__size__button__active"
                            //     : "mb__pd__size__button"
                            // }
                          >{`${size.label}`}</button>
                      );
                    }
                  )}
                </div>
              </div>
            )}
            <div className="col-5 col-md-6 mb__pd__price__col">
              <div className="row pd__product__price__block ">
                <div className="pd__product__price__offer">
                  {!product?.is_price_hide && (
                    <PriceBlock
                      oldPrice={product?.price_rounded}
                      price={
                        product?.discounted_price_rounded === null
                          ? product?.price_rounded
                          : product?.discounted_price_rounded
                      }
                      currency={product?.currency}
                      monthlySavingTagline="get it for SAR 500 in 6 equal installments"
                      product={product}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* size section end */}

        {/* change address popup start */}
        {/* <!-- Modal --> */}
        <div
          className="modal mb__bottom_popup"
          id="changeAddressModal"
          tabIndex="-1"
          aria-labelledby="changeAddressModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog mb__dialog__end modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <Heading5 text={t("SELECT_DELIVERY_ADDRESS")} />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="custom__checkbox mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    onChange={handleChangeSub}
                    checked={isSubscribed}
                  />
                  <label
                    htmlFor="exampleCheck1"
                    className="custom__checkmark p-3"
                  >
                    <p className="p-1 d-inline-block mb__adress__tag">
                      {t("Default")}
                    </p>
                    <Heading7 text="John Doe" />
                    <address className="mb-3 text-wrap">
                      {t("21_West_52nd_Street_New_York")}
                    </address>
                    <p>{t("mobile_number")}</p>
                  </label>
                </div>
                <div className="custom__checkbox">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck2"
                  />
                  <label
                    htmlFor="exampleCheck2"
                    className="custom__checkmark p-3"
                  >
                    <Heading7 text="John Doe" />
                    <address className="mb-3 text-wrap">
                      {t("21_West_52nd_Street_New_York")}
                    </address>
                    <p>{t("mobile_number")}</p>
                  </label>
                </div>
              </div>
              <div className="modal-footer border-top-0">
                <button
                  type="button"
                  className="btn btn-border btn__border__black w-100 d-block mb-2"
                  data-bs-target="#addAddressModal"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                >
                  {t("ADD_NEW_ADDRESS")}
                </button>
                <button
                  type="button"
                  className="btn__primary__orange btn btn-primary w-100 d-block mb-3"
                >
                  {t("SUBMIT")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Modal end--> */}
        {/* change address popup end */}

        {/* add new address popup  start*/}
        {/* <!-- Modal --> */}
        <div
          className="modal mb__bottom_popup"
          id="addAddressModal"
          tabIndex="-1"
          aria-labelledby="addAddressModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog mb__dialog__end modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <Heading5 text="Add New Delivery Address" />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="Name" className="form-label">
                    <strong>{t("Name")}</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Name"
                    placeholder="John"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="Mobile" className="form-label">
                    <strong>{t("Mobile_Number")}</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Mobile"
                    placeholder="+966 50 655 2835"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="Mobile" className="form-label">
                    <strong>{t("Address_Line_1")}</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Mobile"
                    placeholder="21 West 52nd Street New York"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="Mobile" className="form-label">
                    <strong>{t("Address_Line_2")}</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Mobile"
                    placeholder="21 West 52nd Street New York"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="Mobile" className="form-label">
                    <strong>{t("City/Town")}</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Mobile"
                    placeholder="Hamilton"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="Mobile" className="form-label">
                    <strong>{t("State")}</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Mobile"
                    placeholder="Newyork"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="Mobile" className="form-label">
                    <strong>{t("Landmark")}</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Mobile"
                    placeholder="Newyork"
                  />
                </div>
              </div>
              <div className="modal-footer border-top-0 justify-content-center">
                <button
                  type="button"
                  className="btn__primary__orange btn btn-primary w-25 "
                >
                  {t("SAVE")}
                </button>
                <button
                  type="button"
                  className="btn btn-border btn__border__black  w-25"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                >
                  {t("CANCEL")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Modal end--> */}

        {/* Meza subscription */}
        {!product?.hide_subscriber_price && (
          <SubscribeSection>
            <SubsSectionContent
              isPDP={true}
              t={t}
              meza_subscriber_price={product?.meza_subscriber_price}
              user={customerDetails}
              discounted_price={product.discounted_price_rounded}
              og_price={product.price_rounded}
            />
          </SubscribeSection>
        )}


        {/* Price Block */}
        <MizaAndQitafPDP product={product} type="mobile" />
        <MainStoreDelivery t={t} product={product} />
        <MainStorePickup t={t} product={product} storePickupGlobalConfiguration={storePickupGlobalConfiguration} />
        {/* <hr className="mb__block__bottom__line" /> */}
        {/* <div className="container-fluid mb__delivery__sec">
          <div className="row">
            <p className="col-12 mb__pd__size__title">{t("Deliver_By")}</p>
            <p className="text-grey">
              {t("Tomorrow_Thursday")} |{" "}
              <span className="text-orange">
                <strong>{t("Free")}</strong>
              </span>{" "}
              <s>{t("SAR30")}</s>
            </p>
            <div className="col-12 d-flex align-items-start">
              <div className="exp__rd__select__block me-2">
                <img
                  onMouseEnter={() => setIsCheckBoxHover(true)}
                  onClick={handleCheck}
                  onMouseLeave={() => setIsCheckBoxHover(false)}
                  className={
                    !isCheckBox
                      ? "exp__rd__check__icon"
                      : "exp__rd__check__icon__disable"
                  }
                  src={isCheckBoxHover ? checked : empty_check}
                  alt=""
                />
                <img
                  onClick={handleCheck}
                  className={
                    isCheckBox
                      ? "exp__rd__check__icon"
                      : "exp__rd__check__icon__disable"
                  }
                  src={checked}
                  alt=""
                />
              </div>
              <div>
                <p className="mb__pd__size__title mt-0">
                  {t("Installation_and_Demo_In")}{" "}
                </p>
                <p className="mb-0 text-grey">
                  {t("2_Days_Saturday")} |{" "}
                  <span className="text-orange">
                    <strong>{t("Free")}</strong>
                  </span>
                </p>
              </div>
            </div>
          </div> */}
        {/* Deliver By  end*/}
        {/* <div className="row">
            <div className="col-12">
              <PickupStore
                delivery={productDelivery}
                title="Pick Up From Store"
              />
            </div>
          </div> */}
        {/* </div> */}

        {/* <hr className="mb__block__bottom__line" /> */}
        {/* 15days return sec start */}
        <div className="pd__return__block ">
          <div className="pd__icon__block">
            <img
              src={return_period}
              alt=""
              className="pd__return_period_icon"
            />
          </div>
          <div className="pd__return__text__block">
            <Heading7 text={t("7_Days_Return_Policy")} />

            <Link
              to="#"
              className="pd__learn__more__link"
              onClick={(e) => setModalShow(true)}
            >
              {`${t("Learn_more")} >`}
            </Link>
          </div>
        </div>
        {/* {product?.item_type === 'virtual' && isApplePayAvailable && (
          <VisaHolderNote t={t} />
        )} */}
         {/* More services section */}
         {(isExWarrantyAvailable && isMobile) &&
          <MoreServices
            t={t}
            title={t("Add extra warranty?")}
            product={product}
            warrantyProduct={warrantyProduct}
            handleChangeCartPopup={handleChangeCartPopup}
            isMobile={isMobile}
            isExWarrantyAvailable={isExWarrantyAvailable} 
            warrentyData={warrentyData}
            />
        }
        <BundleView
          t={t}
          parentSku={product?.sku}
          bundle_products={product.user_bundle_products}
          redirectBundlePage={redirectBundlePage}
          product={product}
        />
        {/* 15days return sec end */}

        {/* mb__available__offers__Sec Start */}

        {isMobile &&
          <AvailableOffers
            // availableOffer={productAvailableOffer}
            title={t("Available_Offers")}
            product={product}
          />
        }

        {/* mb__available__offers__Sec end */}

        {/* overview section start */}
        <div className="accordion accordion-flush mb__accordion__section" id="mb__overview__accordian">
          <PdpAccordianMobile
            product={product}
            finalData={finalData}
            productOverviewData={productOverviewData}
            productSpecificationData={productSpecificationData}
            productReviewData={productReviewData}
            getOptionKeyInfo={getOptionKeyInfo}
            isMobile={isMobile}
          />
        </div>
        {/* overview section end */}

        <div className="pd__related__product__section">
          <p className="pd__mb__block__title">{t("Related_Products")}</p>
          {relatedProductList &&
            relatedProductList.map((product, productIndex) => {
              if (isMore === false) {
                if (productIndex > 2) {
                  return;
                }
              }
              return (
                <React.Fragment key={`mobile_product_detail_${productIndex}`} >
                  <RelatedProduct
                    t={t}
                    product={product}
                    handleChangeCartPopup={handleChangeCartPopup}
                    updateCompareProduct={updateCompareProduct}
                    outOfStockConfiguration={outOfStockConfiguration}
                    handleCompare={handleCompare}
                    availablityData={productData?.availablityData || []}
                  />
                </React.Fragment>
              );
            })}
          {relatedProductList?.length > 3 &&
            <div className="view__more__address__block">
              <button
                onClick={() =>
                  setIsMore(!isMore)
                }
                className="view__more__address__button"
              >
                <Heading6
                  text={
                    isMore
                      ? t("View_Less")
                      : t("View_More")
                  }
                  marginBottom={0}
                  color="#000000"
                />
                {isMore === true ? <BsChevronUp /> : <BsChevronDown />}
              </button>
            </div>
          }
        </div>
      </div>
      <div className="mb__pdp__sticky__add__to__cart__container">
        <div
          className={`mb__pdp__sticky__add__to__cart__block ${numberOfBtnCheck()}`}
        >
          <BuyNow
            product={product}
            isOutOfStock={isOutOfStock}
            outOfStockConfiguration={outOfStockConfiguration}
            buyNow={buyNow}
            isCartData={isCartData}
            setIsCartData={setIsCartData}
            matchedProduct={matchedProduct}
          />

          {isOutOfStock &&
            outOfStockConfiguration?.module_status &&
            productOutOfStockStatus ? (
            <NotifyMeBtn product={product} />
          ) : (
            <>
              {isOutOfStock ? (
                <div className="outofStock__button">{t("Out Of Stock")}</div>
              ) : isPreOrder ? (
                <div
                  className="addToCart__button pre-order"
                  onClick={() => AddProductToCart(matchedProduct && matchedProduct?.length > 0 ? matchedProduct?.[0]?.sku : product?.sku)}
                >
                  {(loaderOn === true || spinnerOn === true) && !isCartData ?
                    <Spinner />
                    :
                    <>
                      <img src={shopping_cart} alt="" className="addToCart__icon" />
                      {t("pre_order")}
                    </>
                  }
                </div>
              ) : (
                <div
                  className="addToCart__button"
                  onClick={() => AddProductToCart(matchedProduct && matchedProduct?.length > 0 ? matchedProduct?.[0]?.sku : product?.sku)}
                >
                  {(loaderOn === true || spinnerOn === true) && !isCartData ?
                    <Spinner />
                    :
                    <>
                      <img src={shopping_cart} alt="" className="addToCart__icon" />
                      {t("Add_To_Cart")}
                    </>
                  }
                </div>
              )}
            </>
          )}
          {/* {product.bundle_products &&
            product.bundle_products[0] &&
            product.bundle_products[0].length > 0 && (
              <button
                className="buynow___button"
                // onClick={() => buyNow(product.sku, product.id)}
                onClick={() => redirectBundlePage(product.sku)}
              >
                {t("BUILD_BUNDLE")}
                {loaderBuyOn === true ? <LoaderIcone /> : ""}
              </button>
            )} */}
          {qtyDropBtn === true &&
            <div className="mb__pdp__Qty">
              <div className="field__block">
                <input
                  type="number"
                  min="1"
                  max="10"
                  className="form__field"
                  id="count"
                  name="count"
                  value={count}
                  onInput={(e) => setCount(e.target.value)}
                  aria-label="Count"
                />
              </div>
            </div>
          }

        </div>
        {/* <div
          className={`mb__pdp__sticky__add__to__cart__block mt-2 ${numberOfBtnCheck()}`}
        >
          <>
            {product &&
              isOutOfStock &&
              outOfStockConfiguration?.module_status &&
              productOutOfStockStatus ? (
              null
            ) : (
              <>
                {!['virtual', 'simple'].includes(product?.item_type) && <ApplePay sku={product?.sku} id={product?.id} t={t} qty={count} total={product?.discounted_price_rounded === null
                  ? product?.price_rounded
                  : product?.discounted_price_rounded} />}
              </>
            )}
          </>
        </div> */}
      </div>

      {/*available offer modal popup start */}
      <div
        className="modal mb__bottom_popup available_Offer_popup"
        id="availableOffer"
        tabndex="-1"
        aria-labelledby="availableOfferModal"
        aria-hidden="true"
      >
        <div className="modal-dialog mb__dialog__end modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <Heading5 text={t("Available_Offers")} />
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
            </div>
          </div>
        </div>
      </div>
      {/*available offer modal popup end */}
    </>
  );
}

export default Mobile_Product_Detail_Page;
