import React from 'react'
import { useTranslation } from 'react-i18next';

function Play_Station_Page() {
  const { t } = useTranslation();
  return (
    <>
    <div>{t("Play_Station_Page")}</div>
    </>
  )
}

export default Play_Station_Page