import axios from "axios";
import * as services from "./services";
import * as actionType from "./../redux/actionType";
import apiHelper from "../Components/helpers/utils/apiHelper";
import { loaderStartEnd } from "./services";
import { loadCartData } from "../redux/appAction";

export const setRandomValue = (data) => (dispatch) => dispatch({ type: actionType.RANDOM_VALUE, payload: data });

export const createCartDetails = async () => {
  let responseData = {};
  await axios
    .post(`${process.env.REACT_APP_PROJECT_API_URL}/V1/cart`)
    .then((res) => (responseData = res.data));
  // console.log(responseData,"product Data");
  // console.table(allCategoryData.data.children_data,"categoryData in identifier")
  return responseData;
};

export const addToCart = async (data) => {
  const productData = await axios.put(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart`,
    data
  );
  // console.log(wishlistData,"addToWishlist")
  return productData;
};
export const bundleCart = async (data) => {
  const productData = await axios.put(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/bundle`,
    data
  );
  // console.log(wishlistData,"addToWishlist")
  return productData;
};
export const deleteFromCart = (data) => {
  // console.log(data);
  //   let response = await apiHelper(`/V1/cart/items`, 'delete', data, {});
  //  console.log("response ", response);
  const deleteFromCartData = axios.delete(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/items`,
    { data: data }
  );
  // console.log(deleteFromCartData, "deleteFromCartData")
  return deleteFromCartData;
};

export const UpdateFromCart = (data) => {
  // console.log(data);
  //   let response = await apiHelper(`/V1/cart/items`, 'delete', data, {});
  //  console.log("response ", response);
  const deleteFromCartData = axios.put(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/item`,
     data 
  );
  // console.log(deleteFromCartData, "deleteFromCartData")
  return deleteFromCartData;
};

export const deleteCart = async (token) => {
  const { response } = axios.delete(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/delete-cart`
  );
  return response;
};

export const emptyCart = async () => {
  const  response  = await axios.post(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/empty-cart`
  );
  return response;
};

export const getCartData = async () => {
  // console.log("cart data >>>>>>")
  const cartData = await axios.get(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart`
  );
  // console.log(cartData,"cartData")
  return cartData;
};

const saveAvailablePaymentMethodData = (data) => ({
  type: actionType.GET_AVAILABLE_PAYMENT_METHOD__DATA,
  payload: data,
});

export const getAvailablePaymentMethodData = async () => {
  const paymentMethodData = await axios.get(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/available-payment-methods`
    );
  return paymentMethodData;
};

export const getAvailablePaymentMethods = () => {
  return async function (dispatch) {
    const paymentMethodData = await getAvailablePaymentMethodData()
      dispatch(saveAvailablePaymentMethodData(paymentMethodData.data));
    return paymentMethodData.data;
  };
};

export const getEstimateShippingMethods = async () => {
  // console.log("Payment method Data >>>>>>")
  const estimateShippingMethods = await axios.get(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/estimate-shipping-methods`
  );
  // console.log(estimateShippingMethods,"estimateShippingMethods")
  return estimateShippingMethods.data;
};

export const getPayfortInformation = async (data) => {
  // console.log("Payment method Data >>>>>>")
  const payfortInformationData = await axios.post(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/get-payfort-information`,
    data
  );
  // console.log(payfortInformationData, "payfortInformationData")
  return payfortInformationData;
};

export const getPaymentMethod = async (data) => {
  // console.log("Payment method Data >>>>>>")
  const paymentMethodData = await axios.put(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/payment-method`,
    data
  );
  return paymentMethodData;
};

export const placeOrderCOD = async (data) => {
  // console.log("Payment method Data >>>>>>")
  const paymentMethodData = await axios.put(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/place-order`,
    data
  );
  return paymentMethodData;
};

export const getTamaraData = async (data) => {
  // console.log("Payment method Data >>>>>>")
  const tamaraData = await axios.post(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/get-tamara-information`,
    data
  );
  return tamaraData;
};

export const APIsendPayfortInformation = async (data) => {
  const payfortInformationData = await axios.post(
    `https://sbcheckout.payfort.com/FortAPI/paymentPage`,
    data
  );

  return payfortInformationData;
};

// update shipping information API
export const updateShippingInformation = (params) => {
  return async (dispatch) => {
    try {
      dispatch(loaderStartEnd(true));
      let response = await apiHelper(
        `/V1/cart/shipping-information`,
        "put",
        params,
        {}
      );
      if (response.data !== "") {
        dispatch(updateShippingInformationSuccess(response.data));
        setTimeout(() => {
          dispatch(loadCartData())
        }, 2000);
      }
    } catch (error) {
      dispatch(loaderStartEnd(false));
      let msg = { message: error.response.data.message };
      dispatch(services.notifyError(msg));
    } finally {
      dispatch(loaderStartEnd(false));
    }
  };
};

export const resetShippingInformation = (params) => {
  return async (dispatch) => {
    try {
      dispatch(loaderStartEnd(true));
      let response = await apiHelper(
        `/V1/cart/shipping-information`,
        "del",
        params,
        {}
      );
      if (response.data !== "") {
        dispatch(deleteShippingInformationSuccess(response.data));
        dispatch(loadCartData())
      }
    } catch (error) {
      dispatch(loaderStartEnd(false));
      let msg = { message: error.response.data.message };
      dispatch(services.notifyError(msg));
    } finally {
      dispatch(loaderStartEnd(false));
    }
  };
};

// update shipping information reducer
export const updateShippingInformationSuccess = (data) => {
  return {
    type: actionType.UPDATE_CUSTOMER_SHIPPING_INFO,
    payload: data,
  };
};

// delete shipping information reducer
export const deleteShippingInformationSuccess = (data) => {
  return {
    type: actionType.DELETE_CUSTOMER_SHIPPING_INFO,
    payload: data,
  };
};

// create billing address
export const createBillingAddress = (params) => {
  return async (dispatch) => {
    try {
      await apiHelper(`/V1/cart/billing-address`, "post", params, {});
      return true;
    } catch (error) {}
  };
};

export const createAppleBillingAddress = (params) => {
  return async (dispatch) => {
    try {
      await apiHelper('/V1/cart/billing-address', 'post', params, {});
      return true;
    } catch (error) {
      let msg = { message: error?.response?.data?.details?.errors };
      dispatch(services.notifyError(msg));
      throw error;
    }
  };
};

export const getLoyaltyEarnTypes = async (data) => {
  const loyaltyData = await axios.get(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/loyalty-earn-types`
  );
  return loyaltyData;
};

export const verifyLoyaltyPoints = async (data) => {
  const loyaltyData = await axios.put(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/loyalty-points`,
    data
  );
  return loyaltyData;
};

// select loyalty program
export const selectLoyaltyPointsProgram = (data) => {
  return {
    type: actionType.SELECTED_LOYALTY_POINTS_PROGRAM,
    payload: data,
  };
};

export const updateLoyaltyEarnTypes = async (data) => {
  const loyaltyData = await axios.put(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/loyalty-earn-types`,
    data
  );
  return loyaltyData;
};

// compare product list
export const updateCompareProductList = (data) => {
  return {
    type: actionType.COMPARE_PRODUCT_LIST,
    payload: data,
  };
};

export const confirmTabbyPayment = async (data) => {
  const paymentMethodData = await axios.post(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/confirm-tabby-payment`,
    data
  );
  return paymentMethodData;
};

export const failTabbyPayment = async (data) => {
  const paymentMethodData = await axios.get(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/fail-tabby-payment`,
    data
  );
  return paymentMethodData;
};

export const cancelTabbyPayment = async (data) => {
  const paymentMethodData = await axios.get(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/cancel-tabby-payment`,
    data
  );
  return paymentMethodData;
};

export const getCheckoutInformation = async (payload) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/get-checkout-information`,
    payload
  );
  return data;
};

export const EligibleBinDiscount = async (card) => {
  const {data} = await axios.get(`${process.env.REACT_APP_PROJECT_API_URL}/V1/bin-discount-eligible/${card}`);
  return data;
};

export const Saveps5elite = async (requestData) => {
  const {data} = await axios.post(`${process.env.REACT_APP_PROJECT_API_URL}/V1/sony/gamers-elite-registration`,requestData.data);
  return data;
};

export const niqatyInquire = async (phone) => {
  const { data } = await axios.get(`${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/niqaty/inquire/${phone}`);
  return data;
};

export const niqatyRedeem = async (phone, payload) => {
  const {data} = await axios.post(`${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/niqaty/redeem/${phone}`, payload);
  return data;
};

export const niqatyConfirmOtp = async (phone, payload) => {
  const { data } = await axios.post(`${process.env.REACT_APP_PROJECT_API_URL}/V1/cart/niqaty/confirm-otp/${phone}`, payload);
  return data;
};
