import React, { useState } from "react";

import black_left_arrow from "./../assets/Icon/black_left_arrow.svg";
import black_right_arrow from "./../assets/Icon/black_right_arrow.svg";
import "./../SCSS/_newArrival.scss";
import useWindowDimensions from "./helpers/utils/useWindowDimensions";
import ProductNine from "./ProductType/ProductNine";
import ProductSeven from "./ProductType/ProductSeven";

import ProductTwo from "./ProductType/ProductTwo";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function ProductCategory({
  productDetailPage,
  sectionTitle,
  carouselData,
  productType,
  containerClassName,
  handleChangeCartPopup,
  updateCompareProduct,
  handleChangeProductPopup,
  redirectToPdpPage,
  containerClassNameForSlider,
  outOfStockConfiguration,
  screenName,
  productIdData,
  availablityData,
  customerDetails
}) {

  const PrevArrow = ({ style, onClick, currentSlide }) => {
    return (
      <div className="arrow left__arrow" onClick={onClick}>
        <img src={black_left_arrow} alt="" />
      </div>
    );
  };

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow right__arrow" onClick={onClick}>
        <img src={black_right_arrow} alt="" />
      </div>
    );
  };

  var settings = {
    dots: false,
    infinite: carouselData?.length > 5 ? true : false,
    slidesToScroll: 4,
    variableWidth: true,
    swipeToSlide: false,
    speed: 200,
    arrows: carouselData?.length > 5 ? true : false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: carouselData?.length > 3 ? true : false,
          arrows: carouselData?.length > 3 ? true : false,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: carouselData?.length > 2 ? true : false,
          infinite: carouselData?.length > 2 ? true : false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: carouselData?.length > 1 ? true : false,
          infinite: carouselData?.length > 2 ? true : false,
        }
      }
    ]
  };


  return (
    <>
      {carouselData && (
        <div
          className={
            !productDetailPage
              ? "container-fluid carousel__container"
              : "carousel__container"
          }
        >
          <div
            className={
              !productDetailPage
                ? "carousel__block"
                : "less__width__carousel__block"
            }
          >
            <p className="section__title">{sectionTitle}</p>

            <div className="main__product__container__block">

              <Slider {...settings}>
                {carouselData &&
                  carouselData.map((product, productIndex) => {
                    return (
                      productType === "productTwo" ? <ProductTwo
                        handleChangeCartPopup={handleChangeCartPopup}
                        productDetailPage={productDetailPage}
                        key={product.id}
                        product={product}
                        originalData={product.originalData}
                        updateCompareProduct={updateCompareProduct}
                        outOfStockConfiguration={outOfStockConfiguration}
                        productIndex={productIndex}
                        screenName={screenName}
                      /> : productType === "productSeven" ?
                        <ProductSeven
                          handleChangeCartPopup={handleChangeCartPopup}
                          productDetailPage={productDetailPage}
                          key={product.id}
                          product={product}
                          originalData={product.originalData}
                          updateCompareProduct={updateCompareProduct}
                          outOfStockConfiguration={outOfStockConfiguration}
                          productIndex={productIndex}
                          screenName={screenName}
                          availablityData={product?.availablityData}
                          productIdData={productIdData}
                        />
                        : productType === "productNine" ? <ProductNine
                          key={product.id}
                          product={product}
                          handleChangeProductPopup={handleChangeProductPopup}
                          updateCompareProduct={updateCompareProduct}
                          handleChangeCartPopup={handleChangeCartPopup}
                          redirectToPdpPage={redirectToPdpPage}
                          // onTouchStart={onTouchStart}
                          // onTouchMove={onTouchMove}
                          // onTouchEnd={() =>
                          //   onTouchEnd(productIndex, carouselData?.length)
                          // }
                          outOfStockConfiguration={outOfStockConfiguration}
                          productIndex={productIndex}
                          screenName={screenName}
                          availablityData={availablityData}
                          productIdData={productIdData}
                          customerDetails={customerDetails}
                        />
                          : ""
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(ProductCategory);
