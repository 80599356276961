import React from "react";
// import { useTranslation } from "react-i18next";


export const getProductIndexByVarient = (product, screen_size_id) => {
  let indexOfVariants = product?.variants && product?.variants.findIndex(i => i.options.screen_size === screen_size_id);
  return indexOfVariants;
}

function ProductVariantOptions({ product, sizeButtonClick, sizeButtonIndex }) {
  const targetPrice = product?.price_rounded;
  const matchedPrice = product?.variants?.filter(product => product.price_rounded === targetPrice);
  const newMatchPrice = matchedPrice.length > 1 ? matchedPrice[0] : matchedPrice;
  const screenSize = matchedPrice.length > 1 ? newMatchPrice?.options?.screen_size : newMatchPrice[0]?.options?.screen_size;
  const seletcedScreenSize = product?.variantOptions?.[0].attribute?.options.filter(product => product.id === screenSize);
  const handleClick = () =>{
      const targetSKU = product?.sku;
      const matchedSKU = product?.variants?.filter(product => product.sku === targetSKU);
      const matchedSKUScreenSize = matchedSKU && matchedSKU[0]?.options?.screen_size;
      const matchedSKUSeletcedScreenSize = product?.variantOptions?.[0].attribute?.options.filter(product => product.id === matchedSKUScreenSize);
      return matchedSKUSeletcedScreenSize;
  }
  const result = handleClick();
  // const { t } = useTranslation();
  return (
    <>
      {product && product?.variantOptions[0] && product?.variantOptions[0]?.attribute && product?.variantOptions[0]?.attribute?.options && product?.variantOptions[0]?.attribute?.options.map((size, sizeIndex) => {
        let indexOfVariants = getProductIndexByVarient(product, size.id)
        let currentActive;
        if(result.length == 0){
          currentActive =
        seletcedScreenSize?.[0].label === size.label
        ? "pd__size__button__active"
        : "pd__size__button";
        }
        else{
          currentActive = result[0]?.label && 
        result[0]?.label === size.label ? "pd__size__button__active"  : "pd__size__button";

        }
        return (
          <button
            key={size.id}
            onClick={() =>
              {
                sizeButtonClick(indexOfVariants, size)
                handleClick();
              }
            }
            className={currentActive}
            // className={
            //   sizeButtonIndex === indexOfVariants
            //     ? "pd__size__button__active"
            //     : "pd__size__button"
            // }
          >
            {/* {`${size.label * 2.54} cm (${size.label})`} */}
            {`${size.label}`}
          </button>
        );
      })}
    </>
  );
}

export default React.memo(ProductVariantOptions);
