import React from "react";
import BraviaForm from "../Components/Form/BraviaForm";
import '../SCSS/_homeEntertainment.scss';
import { getHomeEntertainmentPageData } from "../services/homeEntertainment.service";
import MagentoRenderer from "../Components/Cms/MagentoRenderer";

function Home_Entertainment_Page({handleChangeCartPopup}) {
  //page layer

  return (
    <div className="homePage d-lg-block">
      <MagentoRenderer pageName = "Home Entertainment" getPageData = {getHomeEntertainmentPageData} handleChangeCartPopup={handleChangeCartPopup}/>  
      <div className="container">
        <div className='formContainer'>
          <BraviaForm />
        </div>
      </div>
    </div>
  );
}

export default Home_Entertainment_Page;