import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// export const utag = window.utag || { link: () => {}, view: () => {} };

const TealiumTag = ({ name, template }) => {
    const environment = "prod"
    const splitUrl = window.location.pathname.split('/');
    const getCurrentUrlLngCode = splitUrl[1];
    const location = useLocation();

    useEffect(() => {
        // Tealium Sync Script (#B)
        // const tealiumSyncScript = document.createElement('script');
        // tealiumSyncScript.src = `https://tags.tiqcdn.com/utag/sony-marketing/gna/${environment}/utag.sync.js`;
        // tealiumSyncScript.async = true;
        // document.head.appendChild(tealiumSyncScript);
        // console.log('Location 2', location);
        // window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
        // window.utag_cfg_ovrd.noview = true;

 

        // Data Layer (#C)
        if (typeof window.buildSonyDataLayer === 'undefined') {
           
        }
       
       
        window.buildSonyDataLayer = function () {
            // console.log('path' , location.pathname);
            var sony = {};
            sony.digitalData = {};
            sony.digitalData.version = '1.0';
            sony.digitalData.page = {};
            sony.digitalData.page.name = name;
            sony.digitalData.page.country = 'SA';
            sony.digitalData.page.language = getCurrentUrlLngCode === "en-sa" ? "en" : "ar";
            sony.digitalData.page.template = template;
            sony.digitalData.page.section = 'sso';
            sony.digitalData.page.currency = 'SAR';
            // window.pageStateUpdated();

           

            return sony;
        };

        const custom_event = new CustomEvent("page_state_updated");
        document.dispatchEvent(custom_event);
        // console.log('--------custom event-----');

       

        // if (typeof window.buildSonyDataLayer !== 'undefined') {    
        //     const custom_event = new CustomEvent("page_state_updated");
        //     document.dispatchEvent(custom_event);
        // }

        // window.pageStateUpdated = function () {
        //     if (typeof utag !== 'undefined') {
        //         utag.view({
        //             "tealium_event": "page_state_updated",
        //             "page_name"    : location.pathname,
        //         },
        //         function () {console.log('Triggered tags based on event!')});
        //     }
        // }

        // Data Layer (#C) - V2
        // if (typeof window.SonyAnalytics === 'undefined') {
        //     window.SonyAnalytics = function () {
        //         this.build = function () {
        //             var dataLayer = {};
        //             dataLayer.analytics = {};
        //             dataLayer.analytics.version = '1.0';
        //             dataLayer.analytics.page = {};
        //             dataLayer.analytics.page.name = name;
        //             dataLayer.analytics.page.country = 'SA';
        //             dataLayer.analytics.page.language = getCurrentUrlLngCode === "en-sa" ? "en" : "ar";
        //             dataLayer.analytics.page.template = template;
        //             dataLayer.analytics.page.section = 'sso';

        //             return dataLayer;
        //         };
        //     };
        // }

        // Tealium Async Script (#D)
        // const tealiumAsyncScript = document.createElement('script');
        // tealiumAsyncScript.src = `https://tags.tiqcdn.com/utag/sony-marketing/gna/${environment}/utag.js`;
        // tealiumAsyncScript.async = true;
        // tealiumAsyncScript.id = "tiqcdn";
        // document.body.appendChild(tealiumAsyncScript);
        
        // const utagDataScript = `const utag_data = { website_platform : "regional:ecommerce","page_name": "${location.pathname}"  };`;
        // //const uTagScriptTag = `<script id="uTagScriptTag" type="text/javascript" dangerouslySetInnerHTML={{ __html: "${utagDataScript}"} }} />`;

        // utagDataScriptTag.setAttribute("dangerouslySetInnerHTML",`{{ __html: "${utagDataScript}"} }} `);
        const utagDataScriptTag = document.createElement("script");
        utagDataScriptTag.id = "utagDataScriptTag";
        utagDataScriptTag.innerHTML = " window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};window.utag_cfg_ovrd.noview = true; (function(a,b,c,d){ a='https://tags.tiqcdn.com/utag/sony-marketing/gna/prod/utag.js';    b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a); })();";
        document.body.appendChild(utagDataScriptTag);

    
        return () => {
           
            //document.head.removeChild(tealiumSyncScript);
            //document.body.removeChild(tealiumAsyncScript);
            document.body.removeChild(utagDataScriptTag);
            // delete window.SonyAnalytics;

            delete window.buildSonyDataLayer;
        }
    }, [environment, location]);


    // Tealium Platform Type (#A)
    //const utagDataScript = `var utag_data = { website_platform : "regional:ecommerce" };`;
    //return <script id="utagDataScriptTag" type="text/javascript" dangerouslySetInnerHTML={{ __html: utagDataScript }} />;
    return "";
};

export default TealiumTag;