import React from "react";

function SoldOutLabel({ productIdData, t, product, outOfStockConfiguration }) {
    let productOutOfStockStatus, isOutOfStock
    const isExist = product.hasOwnProperty("productOutOfStockStatus")

    if (productIdData && productIdData?.length !== 0) {
        productOutOfStockStatus = product && productIdData?.[product?.id]?.productOutOfStockStatus ? productIdData?.[product?.id]?.productOutOfStockStatus : false
        isOutOfStock = productIdData?.[product?.id]?.orderType === 'out-of-stock' || productIdData?.[product?.id]?.stock === 0;
    } else if(isExist) {
        productOutOfStockStatus = product && product?.productOutOfStockStatus ? product?.productOutOfStockStatus : false
        isOutOfStock = product?.orderType === 'out-of-stock' || product?.stock === 0;
    }

    return (
        <React.Fragment>
            {((productIdData && productIdData?.length !== 0) || isExist) && isOutOfStock && outOfStockConfiguration?.module_status && productOutOfStockStatus ?
                <div className="soldOutLable">
                    {t('SOLD OUT')}
                </div> : null
            }
        </React.Fragment>
    );
}

export default React.memo(SoldOutLabel);