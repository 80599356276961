import React, { useEffect } from "react";
import "./../SCSS/_featureProducts.scss";
import SingleFeatureProductBlock from "./Home/SingleFeatureProductBlock";
import { impressionEvents } from "./helpers/utils/ga/impressionEvents";

function MainFeatureProducts({
  featureData,
  handleChangeCartPopup,
  outOfStockConfiguration,
  screenName,
  sectionTitle,
  availablityData,
  sectionId,
  customerDetails
}) {
  useEffect(() => {
    if(featureData.length){
      impressionEvents.impressionViewProduct(
        featureData,
        sectionTitle
      );
    }
  //  console.log('featureData', featureData);
  }, []);
  return (
    <>
      {featureData && featureData.length > 0 && (
        <div className="container-fluid feature__category__container d-none d-lg-block">
          <div className=" feature__category__block">
            <p className="section__title py-3">
              {sectionTitle}
            </p>
            <div className=" inner__feature__category__block">
              <div className="row second__category__area">
                {featureData && featureData.map((val, i) => {
                  return (
                    <div key={val?.id} className="col-md-4 second__feature__category__block">
                      <SingleFeatureProductBlock
                        product={val}
                        type={i}
                        handleChangeCartPopup={handleChangeCartPopup}
                        outOfStockConfiguration={outOfStockConfiguration}
                        screenName={screenName}
                        availablityData={availablityData}
                        sectionTitle={sectionTitle}
                        sectionId={sectionId}
                        customerDetails={customerDetails}
                      />
                    </div>
                  )
                })}
              </div>
            </div >
          </div >
        </div >
      )
      }
    </>
  );
}

export default MainFeatureProducts;
