import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading7 from "../Font/Heading7";
import Text4 from "../Font/Text4";
import Text5 from "../Font/Text5";
import "./../../SCSS/Form/_addressForm.scss";
import delete_box_white from "./../../assets/Icon/delete_box_white.svg";
import edit_box_white from "./../../assets/Icon/edit_box_white.svg";
import { isMobileNumber } from "../helpers/utils/validators";
import Heading3 from "../Font/Heading4";
import Heading2 from "../Font/Heading2";
import Text3 from "../Font/Text3";
import * as services from "./../../services/services";
import {
  loadCountriesLocationData,
  loadCitiesLocationData,
} from "../../redux/appAction";
import { updateCustomerAddressSuccess } from "../../services/customerAddress/customerAddress";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { acceptOnlyString } from "./../helpers/utils/regexp";
import { Spinner } from "../helpers/utils/spinner";
import AddressPopupModal from "../Popup/AddressPopupModal";
import { impressionEvents } from "../helpers/utils/ga/impressionEvents";

function AddressForm({ handleAddressPopup }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const T_REQ_NAME = t("Name_is_required");
  const T_REQ_FIRST_NAME = t("First_Name_is_required");
  const T_REQ_LAST_NAME = t("Last_Name_is_required");
  const T_REQ_COUNTRY = t("Country_is_required");
  const T_REQ_MOBILE_NUMBER = t("Mobile_Number_is_required");
  const T_REQ_ADDRESS_LINE_1 = t("Address_Line_1 is_required");
  const T_REQ_ADDRESS_LINE_2 = t("Address_Line_2_is_required");
  const T_REQ_CITY_TOWN = t("City/Town_is_required");
  const T_REQ_STATE = t("State_is_required");
  const T_REQ_LANDMARK = t("Landmark_is_required");
  const T_REQ_POST_CODE = t("Post_Code_is_required");
  const T_INVALID_MOBILE_NUMBER = t("Invalid_Mobile_Number");
  const T_REQ_DISTRICT = t("District_is_required");

  // const [loaderOn, setLoaderOn] = useState(false);
  const countriesLocationData = useSelector(
    (state) => state.appData.countriesLocationData
  );
  const cityLocationData = useSelector(
    (state) => state.appData.cityLocationData
  );
  const { loadingStartEnd } = useSelector((state) => state.commonReducer);

  const { customerAddressList, customerAddUpdateManage } = useSelector(
    (state) => state.customerAddressReducer
  );

  const [addressData, setAddressData] = useState([]);
  const [selectedAddressId, setSelectedAddressID] = useState(0);
  const [addressPopup, setAddressPopup] = useState(false);
  const [addressPopupType, setAddressPopupType] = useState("");
  const [editAddressData, setEditAddressData] = useState("");
  const [storeCitiesLocationData, setStoreCitiesLocationData] = useState([]);
  const [storeCountriesLocationData, setStoreCountriesLocationData] = useState(
    []
  );
  const [latestDistrictList, setLatestDistrictList] = useState([]);

  const selectAddress = (addIndex, addId, add) => {
    setSelectedAddressID(addIndex);
  };

  const [address, setAddress] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    addressLine1: "",
    // addressLine2: "",
    city: "",
    state: "",
    postCode: "",
    country: "",
    primary: false,
    landmark: "",
    myAddressType: "1",
    addressTypeTiming: "",
  });

  const [errMsg, setErrMsg] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    addressLine1: "",
    // addressLine2: "",
    city: "",
    state: "",
    postCode: "",
    country: "",
    primary: false,
    landmark: "",
  });

  const resetFormValue = () => {
    setAddress({
      ...address,
      firstName: "",
      lastName: "",
      mobileNumber: "",
      addressLine1: "",
      // addressLine2: "",
      city: "",
      state: "",
      postCode: "",
      country: "",
      primary: false,
      landmark: "",
      myAddressType: "1",
      addressTypeTiming: "",
    });
  };

  const resetFormErr = () => {
    let formErr = {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      addressLine1: "",
      // addressLine2: "",
      city: "",
      state: "",
      postCode: "",
      country: "",
      primary: false,
      landmark: "",
    };
    setErrMsg(formErr);
  };
  
  useEffect(() => {
    // getAvailablePaymentMethods();
    dispatch(services.getCustomerAddressList());
    dispatch(loadCountriesLocationData());
    dispatch(loadCitiesLocationData());
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (customerAddUpdateManage) {
      if (customerAddUpdateManage.success === true) {
        resetFormValue();
        resetFormErr();
        dispatch(updateCustomerAddressSuccess(""));
        setLatestDistrictList([])
      }
    }
  }, [customerAddUpdateManage]);

  useEffect(() => {
    if (countriesLocationData) {
      let countryList = [];
      countriesLocationData &&
        countriesLocationData.map((val, i) => {
          let countryData = {
            id: val.id,
            label: val.full_name_english,
          };
          countryList.push(countryData);
        });
      setStoreCountriesLocationData(countryList);
    }
  }, [countriesLocationData]);

  useEffect(() => {
    if (cityLocationData) {
      let cityList = [];
      cityLocationData &&
        cityLocationData.map((val, i) => {
          let cityData = {
            value: val.cityCode,
            label: val.cityCode,
            allData: val,
          };
          cityList.push(cityData);
        });
      setStoreCitiesLocationData(cityList);
    }
  }, [cityLocationData]);

  const openNewAddressPopup = (popupType, addIndex, addId, add) => {
    dispatch(loadCitiesLocationData());
    setAddressPopup(true);
    setAddressPopupType(popupType);
    setSelectedAddressID(addIndex);
    setEditAddressData(add);
  };

  const closeLoginPopup = () => {
    if (document.querySelector(".address__popup__container")) {
      // reloadingHeader()
      const element = document.querySelector(".address__popup__container");
      element.classList.remove("address__popup__container");
      element.classList.add("address__popup__container__disable");
    }
    setAddressPopup(false);
  };

  const deleteAddress = (deleteId) => {
    let params = {
      addressId: deleteId,
    };
    dispatch(services.deleteCustomerAddress(params));
  };

  useEffect(() => {
    if (customerAddressList) {
      let updateAddressData = [];
      customerAddressList &&
        customerAddressList.map((val, i) => {
          let landmarkData = "";
          let addressTypeData = "";
          if (val.custom_attributes !== undefined) {
            let getLandmark =
              val.custom_attributes &&
              val.custom_attributes.filter(
                (val) => val.attribute_code === "landmark"
              );
            if (getLandmark && getLandmark.length > 0) {
              landmarkData = getLandmark[0].value;
            }
            let getAddressType =
              val.custom_attributes &&
              val.custom_attributes.filter(
                (val) => val.attribute_code === "address_types"
              );
            if (getAddressType.length > 0) {
              addressTypeData =
                getAddressType[0].value === "1" ? t("Home") : t("Work");
            }
          }

          let addreDetails = {
            id: val.id,
            isDefault: val.primary,
            userName: `${val.firstname} ${val.lastname}`,
            adddress: `${val.street[0]}, ${val.city}, ${landmarkData} ${val.postcode !== undefined ? val.postcode : ""
              }, ${val.country_id}`,
            contact: val.telephone,
            details: val,
            addressTypeData: addressTypeData,
          };
          updateAddressData.push(addreDetails);
          if (val.primary === true) {
            setSelectedAddressID(i);
          }
        });
      setAddressData(updateAddressData);
    }
  }, [customerAddressList]);

  // const getLatestDistrictList = (cityName) => {
  //   return cityLocationData.filter((val, i) => val.cityCode === cityName)?.[0]?.districts
  // }

  useEffect(() => {
    let districts = storeCitiesLocationData.filter(
      (val, i) => val.allData.cityCode === address.city
    )?.[0]?.allData?.districts;
    if (districts !== undefined) {
      let newArray = [];
      districts &&
        districts.map((item) => {
          let newObj = { label: item.label, value: item.code };
          newArray.push(newObj);
        });
      setLatestDistrictList(newArray);
    }
  }, [address.city, address.state]);

  const validateForm = (event, newErrObj, name, value) => {
    //A function to validate each input values
    switch (name) {
      case "firstName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_FIRST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "lastName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_LAST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "mobileNumber":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_MOBILE_NUMBER };
        } else {
          let isValidNumber = isMobileNumber(value);
          if (isValidNumber === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_MOBILE_NUMBER };
          } else {
            newErrObj = { ...newErrObj, [name]: "" };
          }
        }
        break;
      // case 'country':
      //   if (value === "") {
      //     newErrObj = { ...newErrObj, [name]: T_REQ_COUNTRY }
      //   } else {
      //     newErrObj = { ...newErrObj, [name]: '' }
      //   }
      //   break;
      case "addressLine1":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_ADDRESS_LINE_1 };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      // case 'addressLine2':
      //   if (value === "") {
      //     newErrObj = { ...newErrObj, [name]: T_REQ_ADDRESS_LINE_2 }
      //   } else {
      //     newErrObj = { ...newErrObj, [name]: '' }
      //   }
      //   break;
      case "city":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_CITY_TOWN, state: "" };
        } else {
          newErrObj = { ...newErrObj, [name]: "", state: "" };
        }
        break;
      case "state":
        // let getDistrictList = getLatestDistrictList(address.city);
        if (value === "" && address.city !== "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_DISTRICT };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      // case 'state':
      //   if (value === "") {
      //     newErrObj = { ...newErrObj, [name]: T_REQ_STATE }
      //   } else {
      //     newErrObj = { ...newErrObj, [name]: '' }
      //   }
      //   break;
      // case 'postCode':
      //   if (value === "") {
      //     newErrObj = { ...newErrObj, [name]: T_REQ_POST_CODE }
      //   } else {
      //     newErrObj = { ...newErrObj, [name]: '' }
      //   }
      //   break;
      case "landmark":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_LANDMARK };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      default:
        break;
    }
    return newErrObj;
  };

  const handleChange = async (event, keyName) => {
    let value = event;
    let name = event;
    if (keyName === "primary") {
      value = event.target.checked;
      name = "primary";
    } else if (keyName === "mobileNumber") {
      value = event;
      name = "mobileNumber";
    } else if (keyName === "city" || keyName === "state") {
      value = event.value;
      name = keyName;
    } else if (
      event.target.name === "firstName" ||
      event.target.name === "lastName"
    ) {
      value = acceptOnlyString(event.target.value, true);
      name = event.target.name;
    } else {
      value = event.target.value;
      name = event.target.name;
    }
    setAddress({ ...address, [name]: value });
    let manageErrMsg = validateForm(event, errMsg, name, value);
    setErrMsg(manageErrMsg);
  };

  const allFeildValidate = (validateFeild, allErrMsg) => {
    let checkValueStatus = [];
    let checkErrStatus = [];

    validateFeild &&
      validateFeild.map((val, i) => {
        let keyVal = address[val];
        let errVal = errMsg[val];

        allErrMsg = validateForm("", allErrMsg, val, keyVal);
        if (keyVal !== "") {
          checkValueStatus.push("suc");
        }
        if (errVal === "") {
          checkErrStatus.push("err");
        }
      });

    let checkAddressStatus = false;
    if (
      checkValueStatus.length === validateFeild.length &&
      checkErrStatus.length === validateFeild.length
    ) {
      checkAddressStatus = true;
    }

    let returnData = {
      allErrMsg: allErrMsg,
      checkAddressStatus: checkAddressStatus,
    };

    return returnData;
  };

  const handleSubmit = () => {
    let validateFeild = [
      "firstName",
      "lastName",
      "mobileNumber",
      "addressLine1",
      // "addressLine2",
      "city",
      // "state",
      // "country",
      // "postCode",
      "landmark",
    ];

    let getDistrictList = latestDistrictList;
    if (getDistrictList.length > 0) {
      validateFeild.push("state");
    }

    let formStatus = allFeildValidate(validateFeild, errMsg);
    setErrMsg(formStatus.allErrMsg);

    let distDataManage = "";
    if (getDistrictList.length > 0 && address.state !== "") {
      distDataManage = address.state;
    }

    if (formStatus.checkAddressStatus === true) {
      let params = {
        firstName: address.firstName,
        lastName: address.lastName,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        city: address.city,
        telephone: address.mobileNumber,
        primary: address.primary,
        // countryId: address.country ? address.country : "SA",
        countryId: "SA",
        postCode: distDataManage,
        regionId: 0,
        custom_attributes: [
          {
            attribute_code: "landmark",
            value: address.landmark,
          },
          {
            attribute_code: "address_types",
            value: address.myAddressType === "" ? "1" : address.myAddressType,
          },
        ],
      };
      impressionEvents.impressionShippingAddress(
        "add_shipping_address",
        params
      );
      dispatch(services.createCustomerAddress(params));
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      {addressPopup === true && addressPopupType === "update" && (
          <AddressPopupModal
            show={addressPopup}
            closeLoginPopup={closeLoginPopup}
            editAddressData={editAddressData}
            popupType={addressPopupType}
          />
      )}
      <div className="row address__select__block">
        {addressData.map((add, addIndex) => {
          return (
            <div key={add.id} className="col-12 col-sm-4 address__block">
              <div
                className={
                  selectedAddressId === addIndex
                    ? "selected__address__inner__block"
                    : "address__inner__block"
                }
                onClick={() => selectAddress(addIndex, add.id, add)}
              >
                {add.isDefault ? (
                  <div className="address__title__block">
                    <div className="address__tag">
                      <Heading7 text={t("Default")} span={true} />
                    </div>
                    <div className="inner__address__button__block">
                      <button
                        // onClick={() => handleAddressPopup(true)}
                        onClick={() =>
                          openNewAddressPopup("update", addIndex, add.id, add)
                        }
                        className="edit__button"
                      >
                        <img src={edit_box_white} alt="edit" />
                      </button>
                      <button
                        className="delete__button"
                        onClick={() => deleteAddress(add.id)}
                      >
                        <img src={delete_box_white} alt="delete" />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="address__title__block">
                    <div className="white__address__tag">
                      <Text5 text={t("NONE")} span={true} color="#ffffff" />
                    </div>
                    <div className="inner__address__button__block">
                      <button
                        // onClick={() => handleAddressPopup(true)}
                        onClick={() =>
                          openNewAddressPopup("update", addIndex, add.id, add)
                        }
                        className="edit__button"
                      >
                        <img src={edit_box_white} alt="edit" />
                      </button>
                      <button
                        className="delete__button"
                        onClick={() => deleteAddress(add.id)}
                      >
                        <img src={delete_box_white} alt="delete" />
                      </button>
                    </div>
                  </div>
                )}
                <Heading7 text={add.userName} />
                <div className="full__address">
                  <Text4 text={add.adddress} marginBottom={20} />
                </div>
                <div className="full__address">
                  <Text4
                    text={add.addressTypeData}
                    marginLeft={0}
                    span={true}
                  />
                </div>
                <Text4 text={add.contact} marginLeft={0} span={true} />
              </div>
            </div>
          );
        })}
      </div>
      <div className="newAddress__block">
        <div className="newAddress__title__block">
          <Heading3 text={t("Add_New_Address")} color="#000000" />
        </div>

        <div className="row newAddress__form__field__row">
          <div className="col-sm-12 col-md-6 main__form__field__block">
            {/* <p className="form__label">First Name</p> */}
            <Heading7 text={t("First_Name")} marginBottom={10} />
            <sup className="inputs_validation">*</sup>
            <div className="field__block">
              <input
                type="text"
                onKeyDown={handleKeyDown}
                placeholder={t("First_Name")}
                className="form__field"
                id="name"
                name="firstName"
                value={address.firstName}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {errMsg.firstName && (
              <p className="invalid__message">{errMsg.firstName}</p>
            )}
          </div>
          <div className="col-sm-12 col-md-6 main__form__field__block">
            {/* <p className="form__label">Mobile Number</p> */}
            <Heading7 text={t("Last_Name")} marginBottom={10} />
            <sup className="inputs_validation">*</sup>
            <div className="field__block">
              <input
                type="text"
                placeholder={t("Last_Name")}
                onKeyDown={handleKeyDown}
                className="form__field"
                id="lastName"
                name="lastName"
                value={address.lastName}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {errMsg.lastName && (
              <p className="invalid__message">{errMsg.lastName}</p>
            )}
          </div>
        </div>
        <div className="row newAddress__form__field__row">
          <div className="col-sm-12 col-md-6 main__form__field__block">
            <Heading7 text={t("Mobile_Number")} marginBottom={10} />
            <sup className="inputs_validation">*</sup>
            <PhoneInput
              inputProps={{
                name: "mobileNumber",
                required: true,
                className: "profile__mobile__form__field",
              }}
              onKeyDown={handleKeyDown}
              country="sa"
              onlyCountries={["sa"]}
              masks={{ sa: ".. ... ...." }}
              countryCodeEditable={false}
              disableDropdown={true}
              value={address.mobileNumber ? address.mobileNumber : "+966"}
              onChange={(e) => handleChange(e, "mobileNumber")}
              className=""
            />
            {errMsg.mobileNumber && (
              <p className="invalid__message">{errMsg.mobileNumber}</p>
            )}
          </div>
          <div className="col-sm-12 col-md-6 main__form__field__block">
            {/* <p className="form__label">Mobile Number</p> */}
            <Heading7 text={t("Country")} marginBottom={10} />
            <sup className="inputs_validation">*</sup>
            <select
              name="country"
              onChange={(e) => handleChange(e)}
              value={address.country}
              placeholder={t("Country")}
              className="_customselect form-control"
              disabled={true}
            >
              {/* <option key='no' value=''>Select Country</option> */}
              {storeCountriesLocationData &&
                storeCountriesLocationData.map((val, i) => {
                  return (
                    <React.Fragment key={val.id} >
                      <option key={val.id} value={val.id}>
                        {val.label}
                      </option>
                    </React.Fragment>
                  );
                })}
            </select>
            {errMsg.country && (
              <p className="invalid__message">{errMsg.country}</p>
            )}
          </div>
        </div>
        <div className="row newAddress__form__field__row">
          <div className="col-sm-12 col-md-6 main__form__field__block">
            <Heading7 text={t("Address_Line_1")} marginBottom={10} />
            <sup className="inputs_validation">*</sup>
            <div className="field__block">
              <input
                type="text"
                onKeyDown={handleKeyDown}
                placeholder={t("Address_Line_1")}
                className="form__field"
                id="addressLine1"
                name="addressLine1"
                value={address.addressLine1}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {errMsg.addressLine1 && (
              <p className="invalid__message">{errMsg.addressLine1}</p>
            )}
          </div>
          <div className="col-sm-12 col-md-6 main__form__field__block">
            <Heading7 text={t("Address_Line_2")} marginBottom={10} />
            <div className="field__block">
              <input
                type="text"
                onKeyDown={handleKeyDown}
                placeholder={t("Address_Line_2")}
                className="form__field"
                id="addressLine2"
                name="addressLine2"
                value={address.addressLine2}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {errMsg.addressLine2 && (
              <p className="invalid__message">{errMsg.addressLine2}</p>
            )}
          </div>
        </div>
        <div className="row newAddress__form__field__row">
          <div className="col-sm-12 col-md-6 main__form__field__block">
            <Heading7 text={t("City/Town")} marginBottom={10} />
            <sup className="inputs_validation">*</sup>
            <Select
              placeholder={t("City/Town")}
              name="city"
              onChange={(e) => handleChange(e, "city")}
              value={(storeCitiesLocationData && storeCitiesLocationData.filter((val) => val.value === address.city)?.[0]) ?? "" }
              options={storeCitiesLocationData}
            />
            {errMsg.city && <p className="invalid__message">{errMsg.city}</p>}
          </div>
          {latestDistrictList && latestDistrictList.length > 0 && (
            <div className="col-sm-12 col-md-6 main__form__field__block">
              <Heading7 text={t("District")} marginBottom={10} />
              <sup className="inputs_validation">*</sup>
              <Select
                name="state"
                onChange={(e) => handleChange(e, "state")}
                value={
                  latestDistrictList.filter(
                    (val) => val.value === address.state
                  )?.[0]
                }
                options={latestDistrictList}
              />
              {errMsg.state && (
                <p className="invalid__message">{errMsg.state}</p>
              )}
            </div>
          )}
        </div>
        {/* <div className="row newAddress__form__field__row">
          <div className="col-sm-12 col-md-6 main__form__field__block">
            <Heading7 text="Post Code" marginBottom={10} />
            <div className="field__block">
              <input
                type="text"
                placeholder=""
                className="form__field"
                id="postCode"
                name="postCode"
                value={address.postCode}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {errMsg.postCode && <p className="invalid__message">{errMsg.postCode}</p>}
          </div>
        </div> */}
        <div className="row newAddress__form__field__row">
          <div className="col-sm-12 col-md-6 main__form__field__block">
            <Heading7 text={t("Landmark")} marginBottom={10} />
            <sup className="inputs_validation">*</sup>
            <div className="field__block">
              <input
                type="text"
                placeholder={t("Landmark")}
                className="form__field"
                onKeyDown={handleKeyDown}
                id="landmark"
                name="landmark"
                value={address.landmark}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {errMsg.landmark && (
              <p className="invalid__message">{errMsg.landmark}</p>
            )}
          </div>
        </div>
        <Heading7
          text={t("Address_Type")}
          marginBottom={20}
          marginLeft={14}
          marginright={14}
        />
        <div className="addressType__block">
          <input
            type="radio"
            className="delivery__input__check"
            name="myAddressType"
            // defaultChecked={true}
            onKeyDown={handleKeyDown}
            value="1"
            id="home"
            onChange={(e) => handleChange(e)}
            checked={address?.myAddressType === "1" ? true : false}
          />
          <label className="delivery__selection__text" htmlFor="home">
            {t("Home")}
          </label>
          <input
            type="radio"
            className="delivery__input__check"
            name="myAddressType"
            // defaultChecked={false}
            value="2"
            onKeyDown={handleKeyDown}
            id="work"
            onChange={(e) => handleChange(e)}
            checked={address?.myAddressType === "2" ? true : false}
          />
          <label className="delivery__selection__text" htmlFor="work">
            {t("Work")}
          </label>
        </div>
        <div className="preferences__select__block">
          <input
            onKeyDown={handleKeyDown}
            type="checkbox"
            className="preferences__select__input__check"
            name="primary"
            value={address.primary}
            checked={address.primary}
            onChange={(e) => handleChange(e, "primary")}
            id="preference_box"
          />
          <label className="preferences__select__text" htmlFor="preference_box">
            {t("Make_this_my_default_address")}
          </label>
        </div>
        {/* <Heading2 text="Delivery Time Preferences" marginLeft={12} marginright={12} />
        <Text3 text="Preferences are used to plan your delivery. However, shipments can sometimes arrive early or later than planned." marginLeft={12} marginright={12} marginBottom={10} />
        <div className="row newAddress__form__field__row">
          <div className="col-sm-12 col-md-6 main__form__field__block">
            <Heading7 text="AddressType" marginBottom={20} />
            <div className="field__block">
              <input
                type="time"
                placeholder=""
                className="form__field"
                id="landmark"
                name="addressTypeTiming"
                value={address.addressTypeTiming}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div> */}
        <div className="newAddress__form__button__block">
          <button className="form__save__button" onClick={handleSubmit}>
            {loadingStartEnd === true ? <Spinner /> : t("SAVE")}
          </button>
          <button className="form__cancel__button" onClick={closeLoginPopup}>
            {t("CANCEL")}
          </button>
        </div>
      </div>
    </>
  );
}

export default AddressForm;
