import React, { useState } from "react";
import "./../../SCSS/Common/_newsLetter.scss";
import * as services from "../../services/services";
import { useDispatch } from "react-redux";
import { emailValidator } from "../helpers/utils/validators";
import { useTranslation } from "react-i18next";
import { newsletterSubscribe } from "../../services/customer/customer";
import { getCustomerLoginDetails } from "../helpers/utils/getCustomerLoginDetails";
import apiHelper from "../helpers/utils/apiHelper";
import { impressionEvents } from "../helpers/utils/ga/impressionEvents";

function NewsLetter() {

  const customerData = getCustomerLoginDetails();

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleBlur = (e) => {
    let obj = {
      target: {
        value: e.target.value.trim(),
        name: e.target.name
      }
    }
    handleChange(obj)
  }

  const handleSubmit = () => {
    if (email === "") {
      dispatch(services.notifyError({ message: t('Email_is_missing') }))
    } else if (email !== "") {
      let checkEmail = emailValidator(email);
      if (checkEmail === 'error') {
        dispatch(services.notifyError({ message: t('Invalid_email_address_example') }))
      } else {
        let params = {
          email: email
        }
        if (customerData) {
          subscribeNewsletter(params);
          impressionEvents.impressionNewsLetterSubcription(
            "newsletter_subscription",
            params
          );
        } else {
          guestSubscribeNewsletter(params);
          impressionEvents.impressionNewsLetterSubcription(
            "newsletter_subscription",
            params
          );
        }
      }
    }
  };

  const subscribeNewsletter = async (params) => {
    try {
      await newsletterSubscribe(params);
      dispatch(services.notifySuccess({ message: t('Thank you for your subscription') }));
      setEmail("");
    } catch (error) {
      dispatch(services.notifyError({ message: t('This email address has been already registered') }));
    }
  };

  const guestSubscribeNewsletter = async (params) => {

    try {
      const response = await apiHelper(
        `/V1/content/newsletter/subscribe`,
        "post",
        params,
        {}
      );
      if (response?.data?.success) {
        dispatch(services.notifySuccess({ message: t('Thank you for your subscription') }));
        setEmail("");
      } else {
        dispatch(services.notifyError({ message: t('This email address has been already registered') }));
      }
    } catch (error) {
      // console.log("error ", error);
    }
  };

  return (
    <div className="container-fluid newsLetter__container">
      <div className="newsLetter__block">
        <div className="row newsLetter__row">
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-3 newsLetter__text">
            <div className="newsLetter__title">{t("Newsletter_Subscriptions")}</div>
            <div className="newsLetter__description">
              {t("Signup_for_exclusive_offers")}
            </div>
          </div>
          <div className="col-sm-12 col-md-8 col-lg-8 col-xl-9 newsLetter__form__block">
            <div className="newsLetter__form">
              <div className="form__field">
                <input
                  type="text"
                  autoComplete="new-password"
                  className="input__field"
                  placeholder={t('Enter_Email')}
                  name="email"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="form__field">
                <button
                  className="input__button"
                  onClick={handleSubmit}
                >{t("SUBSCRIBE")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsLetter;
