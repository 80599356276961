import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading3 from "../Font/Heading3";
import Heading7 from "../Font/Heading7";
import Heading5 from "../Font/Heading5";
import Text3 from "../Font/Text3";
import cancel_grey from "./../../assets/Icon/cancel_grey.svg";
import "./../../SCSS/Login/_newLetterModel.scss";
import * as services from "../../services/services";
import apiHelper from "../helpers/utils/apiHelper";
import { customerSendResetPasswordEmailSuccess } from "../../services/customer/customer";
import { useTranslation } from "react-i18next";
import { loaderStartEnd } from "../../services/services";
import { Spinner } from "../helpers/utils/spinner";

const T_REQ_EMAIL = "Please Enter Email or Mobile number";

function ResetPasswordModel({ handleChangePopupMode, closeLoginPopup }) {
  const dispatch = useDispatch();
  const { loadingStartEnd } = useSelector((state) => state.commonReducer);
  const { t } = useTranslation();

  const [data, setData] = useState({
    email: "",
  });

  const [errMsg, setErrMsg] = useState({
    email: "",
  });

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    if (value) {
      setErrMsg({ ...errMsg, email: "" });
    } else {
      setErrMsg({ ...errMsg, email: T_REQ_EMAIL });
    }
    setData({ ...data, [name]: value });
  };

  const handleBlur = (e) => {
    let obj = {
      target: {
        value: e.target.value.trim(),
        name: e.target.name
      }
    }
    handleChange(obj)
  }

  const handleSubmit = async () => {
    if (data.email === "") {
      setErrMsg({ ...errMsg, email: T_REQ_EMAIL });
    } else {
      let params = {
        email: data.email,
      };
      try {
        dispatch(loaderStartEnd(true));
        let response = await apiHelper(
          `/V1/customer/send-reset-password-email`,
          "post",
          params,
          {}
        );
        dispatch(
          services.notifySuccess({ message: t("Request send successfully") })
        );
        response.data["email"] = params.email;
        localStorage.setItem(
          "forgotPasswordData",
          JSON.stringify(response.data)
        );
        // dispatch(customerSendResetPasswordEmailSuccess(response.data));
        handleChangePopupMode("reset-password-verify");
      } catch (error) {
        dispatch(loaderStartEnd(false));
        dispatch(
          services.notifyError({ message: error.response.data.message })
        );
      } finally {
        dispatch(loaderStartEnd(false));
      }
      // dispatch(services.customerSendResetPasswordEmail(params));
    }
  };

  const closeAndResetPopupData = () => {
    setData({
      ...data,
      email: "",
    });
    setErrMsg({
      ...errMsg,
      email: "",
    });
    // closeLoginPopup();
    handleChangePopupMode("signin");
  };

  return (
    <div className="newsLetterModel__container resetPassword__area">
      <div className="newsLetterModel__header">
        <Heading5 text={t("Reset_your_password")} color="#000000" />
        <img
          onClick={() => closeAndResetPopupData()}
          src={cancel_grey}
          alt="cancel"
          className="cancel__button"
        />
      </div>
      <div className="newsLetterModel__content">
        <Text3 text={t("Weve sent a One Time Password (OTP) to the mobile number or email. Please enter it to complete verification")} />
        <div className="main__form__field__block">
          {/* <p className="form__label">Email Address</p> */}
          <Heading7
            text={t("Email_Address_/_Mobile_Number")}
            marginBottom={10}
          />
          <sup className="inputs_validation">*</sup>
          <div className="field__block">
            <input
              type="text"
              placeholder={t("Email_Address_/_Mobile_Number")}
              className="form__field"
              id="email"
              name="email"
              value={data.email}
              onChange={(e) => handleChange(e)}
              onBlur={handleBlur}
            />
          </div>
          {errMsg.email && (
            <span className="invalid__message">{errMsg.email}</span>
          )}
        </div>
        <button onClick={() => handleSubmit()} className="subscribe__button">
          {loadingStartEnd === true ? <Spinner /> : t("SUBMIT")}
        </button>
      </div>
    </div>
  );
}

export default ResetPasswordModel;
