import axios from "axios";
import apiHelper from "../../Components/helpers/utils/apiHelper";
import * as actionType from "../../redux/actionType";
import { loadCartData, loadWishlistData } from "../../redux/appAction";
import { encryptHelper } from "./../../Components/helpers/utils/encryptDecryptHelper";
import * as services from "./../services";
import { loaderStartEnd } from "./../services";
import { impressionEvents } from "../../Components/helpers/utils/ga/impressionEvents";
import { containsAllNumbers, genderList } from "../../assetsConfig/utils";

export const isEmailAvailable = async (params) => {
  let returnData = "";
  try {
    let responseData = await apiHelper(
      `/V1/customer/is-email-available`,
      "post",
      params,
      {}
    );
    returnData = responseData.data;
  } catch (error) {
    returnData = error.response.data;
  }
  return returnData;
};

//is-username-available - Check username availability.
export const isUsernameAvailable = async (params) => {
  let returnData = "";
  try {
    let responseData = await apiHelper(
      `/V1/customer/is-username-available`,
      "post",
      params,
      {}
    );
    returnData = responseData.data;
  } catch (error) {
    returnData = error.response.data;
  }
  return returnData;
};

// check-password-strength - Check password strength
export const checkPasswordStrength = async (params) => {
  let returnData = "";
  try {
    let responseData = await apiHelper(
      `/V1/customer/check-password-strength`,
      "post",
      params,
      {}
    );
    returnData = responseData.data;
  } catch (error) {
    returnData = error.response.data;
  }
  return returnData;
};

// signup API
export const customerSignUp = (params) => {
  return async (dispatch) => {
    try {
      dispatch(loaderStartEnd(true));
      let response = await apiHelper(`/V1/customer`, "post", params, {});
      let userObj = {
        user_id: response?.data?.id || "", //Leave it empty if failed
        email: params?.email,
        phone: params?.phone_number,
        first_name: params?.firstName,
        last_name: params?.lastName,
        gender: "Male",
      };
      impressionEvents.impressionSignup(
        "sign_up",
        "Email",
        "Signup Success",
        userObj
      );
      // let detailsParams = {
      //   id: response.data.id
      // }

      // let userInfo = await services.getCustomerDetails(detailsParams);
      // dispatch(customerDetailsSuccess(userInfo));

      // let encryptData = encryptHelper(JSON.stringify(userInfo))
      // localStorage.setItem("custDetails", encryptData);
      // let notifyMsg = { message: 'Customer signup successfully.' }

      // dispatch(services.notifySuccess(notifyMsg));
      // response.data["mobileNumber"] = params.phone_number;
      let newResponse = { ...response.data, mobileNumber: params.phone_number };
      dispatch(customerSignUpOtpSuccess(newResponse));

      let signUserData = {
        username: params.email,
        password: params.password,
        onboarding: false,
        rememberMe: false,
      };
      localStorage.setItem("signupUser", JSON.stringify(signUserData));
    } catch (error) {
      dispatch(loaderStartEnd(false));
      let userObj = {
        user_id: "", //Leave it empty if failed
        email: params?.email,
        phone: params?.phone_number,
        first_name: params?.firstName || "",
        last_name: params?.lastName || "",
        gender: "Male",
      };
      impressionEvents.impressionSignup(
        "sign_up",
        "Email",
        `Signup Failed: ${error?.response?.data?.message || ""}`,
        userObj
      );
      let notifyMsg = { message: error.response.data.message };
      dispatch(services.notifyError(notifyMsg));

      // dispatch(customerSignUpOtpSuccess(error.response.data.message));
    } finally {
      dispatch(loaderStartEnd(false));
    }
  };
};

// signup otp data
export const customerSignUpOtpSuccess = (data) => {
  return {
    type: actionType.CUSTOMER_SIGN_UP_OTP,
    payload: data,
  };
};

export const saveUserLoginData = (data) => ({
  type: actionType.USER_LOGIN_DATA,
  payload: data,
});

// signin API
export const customerSignIn = (params, t) => {
  return async (dispatch) => {
    try {
      dispatch(loaderStartEnd(true));
      let response = await apiHelper(`/V1/customer/login`, "post", params, {});
      let userData = response?.data?.user;
      let userObj = {
        user_id: response?.status === 200 ? userData?.data?.id : "", //Leave it empty if failed
        email: userData?.data?.email,
        phone: userData?.data?.custom_attributes?.phone_number,
        first_name: userData?.data?.firstname,
        last_name: userData?.data?.lastname,
        gender:
          genderList.find((gl) => gl.selectOption === userData?.data?.gender)
            ?.label || "Male",
        createdDate: userData?.data?.created_at?.substring(0, 10)
      };
      impressionEvents.impressionMeasureLogin(
        "login",
        containsAllNumbers(params.username) ? "Mobile" : "Email",
        "Login Success",
        userObj
      );
      if (response?.data?.otp_code === undefined) {
        // verify user login
        dispatch(services.customerProfileDetails());
        dispatch(services.notifySuccess({ message: t("Login successfully") }));
        dispatch(saveUserLoginData(response?.data?.user?.userLoggedIn));
        document.body.classList.remove("modal_back_stop");
        localStorage.removeItem("user_mode");
        dispatch(loadCartData());
        dispatch(loadWishlistData());
        localStorage.setItem("rememberMe", params.rememberMe);
        localStorage.setItem(
          "rememberedUser",
          params.rememberMe === true ? params.username : ""
        );
        dispatch(loaderStartEnd(false));
      } else if (response?.data?.otp_code !== "") {
        //mobile number not verify
        response.data["mobileNumber"] = response.data.phone_number;
        dispatch(customerSignUpOtpSuccess(response.data));
        let signUserData = {
          username: params.username,
          password: params.password,
          onboarding: false,
          rememberMe: false,
        };
        localStorage.setItem("signupUser", JSON.stringify(signUserData));
        dispatch(loaderStartEnd(false));
      }
    } catch (error) {
      let userObj = {
        user_id: "", //Leave it empty if failed
        email: !containsAllNumbers(params.username) ? params?.username : "",
        phone: containsAllNumbers(params.username) ? params?.username : "",
        first_name: params?.firstname || "",
        last_name: params?.lastname || "",
        gender: "Male",
      };
      impressionEvents.impressionMeasureLogin(
        "login",
        containsAllNumbers(params.username) ? "Mobile" : "Email",
        `Login Failed: ${error?.response?.data?.message || ""}`,
        userObj
      );
      dispatch(loaderStartEnd(false));
      if (error.request.status === 401) {
        dispatch(
          services.notifyError({ message: error.response.data.message })
        );
      }
    }
  };
};

// auth reducer
export const customerSignInSuccess = (data) => {
  return {
    type: actionType.CUSTOMER_SIGN_IN_MSG,
    payload: data,
  };
};

// check-password-strength - Get customer details.
export const getCustomerDetails = async (params) => {
  let returnData = "";
  try {
    let responseData = await apiHelper(
      `/V1/customer/${params.id}`,
      "get",
      params,
      {}
    );
    returnData = responseData.data;
  } catch (error) {
    returnData = error.response.data;
  }
  return returnData;
};

// auth reducer
export const customerDetailsSuccess = (data) => {
  return {
    type: actionType.CUSTOMER_DETAILS,
    payload: data,
  };
};

// signin API
export const customerLogout = (params, t) => {
  return async (dispatch) => {
    const currentUrl = window.location.pathname;
    try {
      let response = await apiHelper(`/V1/customer/logout`, "post", params, {});
      if (response.data === "") {
        let notifyMsg = { message: t("Logout successfully") };
        dispatch(services.notifySuccess(notifyMsg));
        dispatch(customerSignInSuccess(""));
        // setTimeout(function () {
        // }, 2000);
        window.location.replace(currentUrl);
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("custDetails");
        localStorage.removeItem("handShakeToken");
        localStorage.removeItem("handShakeConfig");
        dispatch(customerDetailsSuccess(""));
        window.location.replace(currentUrl);
      }
      // let notifyMsg = { message: error.response.data.message }
      // // dispatch(customerSignInSuccess(error.response.data.message));
      // dispatch(services.notifyError(notifyMsg));
    }
  };
};

// auth reducer
export const customerLogoutSuccess = (data) => {
  return {
    type: actionType.CUSTOMER_DETAILS,
    payload: data,
  };
};

// update password API
export const customerUpdatePassword = (params) => {
  return async (dispatch) => {
    try {
      dispatch(loaderStartEnd(true));
      let response = await apiHelper(
        `/V1/customer/update-password`,
        "put",
        params,
        {}
      );
      if (response.data.success === true) {
        dispatch(loaderStartEnd(false));
        dispatch(
          services.notifySuccess({ message: "Password updated successfully" })
        );
        dispatch(customerUpdatePasswordSuccess(true));
      }
    } catch (error) {
      dispatch(loaderStartEnd(false));
      if (error.response.status === 401 || error.response.status === 400) {
        dispatch(
          services.notifyError({ message: error.response.data.message })
        );
      }
    } finally {
      dispatch(loaderStartEnd(false));
    }
  };
};

// auth reducer
export const customerUpdatePasswordSuccess = (data) => {
  return {
    type: actionType.CUSTOMER_UPDATE_PASSWORD,
    payload: data,
  };
};

// profile details API
export const customerProfileDetails = (params) => {
  return async (dispatch) => {
    try {
      let response = await apiHelper(`/V1/customer`, "get", params, {});
      dispatch(customerProfileDetailsSuccess(response.data));

      let encryptData = encryptHelper(JSON.stringify(response.data));
      localStorage.setItem("custDetails", encryptData);

      dispatch(customerDetailsSuccess(response.data));
      dispatch(customerSignUpOtpSuccess(""));
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("custDetails");
        localStorage.removeItem("handShakeToken");
        localStorage.removeItem("handShakeConfig");
        dispatch(customerDetailsSuccess(""));
        window.location.reload();
      }
    }
  };
};

// profile details reducer
export const customerProfileDetailsSuccess = (data) => {
  return {
    type: actionType.CUSTOMER_PROFILE_DETAILS,
    payload: data,
  };
};

// update profile API
export const customerUpdateProfile = (params, t) => {
  return async (dispatch) => {
    try {
      dispatch(loaderStartEnd(true));
      await apiHelper(`/V1/customer`, "put", params, {});
      dispatch(services.customerProfileDetails());
      dispatch(customerUpdateProfileSuccess(true));
      dispatch(
        services.notifySuccess({ message: t("Your profile has been updated!") })
      );
    } catch (error) {
      dispatch(loaderStartEnd(false));
    } finally {
      dispatch(loaderStartEnd(false));
    }
  };
};

// update profile reducer
export const customerUpdateProfileSuccess = (data) => {
  return {
    type: actionType.CUSTOMER_UPDATE_PROFILE,
    payload: data,
  };
};

// verify mobile otp
export const customerVerifyMobileOTP = (params, t) => {
  return async (dispatch) => {
    try {
      dispatch(loaderStartEnd(true));
      let response = await apiHelper(
        `/V1/customer/verify-mobile/${params.otp_token}/${params.otp_code}`,
        "get",
        params,
        {}
      );
      if (response.data.status === false) {
        dispatch(loaderStartEnd(false));
        dispatch(
          services.notifyError({
            message: t("You've entered an invalid verification code"),
          })
        );
      } else {
        // Check if "signupTimestamp" sent from the BE is the same when calculated with the data in the FE
        if (response.data.signupTimestamp !== parseInt(params.otp_token + params.otp_code, 12)) {
          dispatch(loaderStartEnd(false));
          dispatch(
            services.notifyError({
              message: t("You've entered an invalid verification code"),
            })
          );
        }

        dispatch(
          services.notifySuccess({
            message: t("Mobile number verification successful"),
          })
        );
        // dispatch(services.customerProfileDetails());
        let localSignUpData = JSON.parse(localStorage.getItem("signupUser"));
        let signInParams = {
          username: localSignUpData.username,
          password: localSignUpData.password,
          onboarding: false,
        };
        dispatch(services.customerSignIn(signInParams, t));
      }
      dispatch(customerVerifyMobileOTPSuccess(response.data));
    } catch (error) {
      dispatch(loaderStartEnd(false));
    }
  };
};

// verify mobile otp reducer
export const customerVerifyMobileOTPSuccess = (data) => {
  return {
    type: actionType.CUSTOMER_VERIFY_MOBILE_OTP,
    payload: data,
  };
};

// resend mobile otp
export const customerResendMobileOTP = (params, t) => {
  return async (dispatch) => {
    try {
      let response = await apiHelper(
        `/V1/customer/verify-mobile/resend`,
        "post",
        params,
        {}
      );
      dispatch(
        services.notifySuccess({ message: t("Request send successfully") })
      );
      response.data["mobileNumber"] = params.phone_number;
      dispatch(customerSignUpOtpSuccess(response.data));
    } catch (error) {}
  };
};

// reset password email
export const customerSendResetPasswordEmail = (params, t) => {
  return async (dispatch) => {
    dispatch(customerSendResetPasswordEmailSuccess(""));
    try {
      let response = await apiHelper(
        `/V1/customer/send-reset-password-email`,
        "post",
        params,
        {}
      );
      dispatch(
        services.notifySuccess({ message: t("Request send successfully") })
      );
      response.data["email"] = params.email;
      dispatch(customerSendResetPasswordEmailSuccess(response.data));
    } catch (error) {}
  };
};

// verify mobile otp reducer
export const customerSendResetPasswordEmailSuccess = (data) => {
  return {
    type: actionType.CUSTOMER_RESET_PASSWORD_OTP,
    payload: data,
  };
};

// reset password with token
export const customerChangePasswordWithToken = (params, t) => {
  return async (dispatch) => {
    try {
      dispatch(loaderStartEnd(true));
      let response = await apiHelper(
        `/V1/customer/change-password-with-token`,
        "put",
        params,
        {}
      );
      if (response.data) {
        let paramsData = {
          username: params.email,
          password: params.newPassword,
          onboarding: false,
          rememberMe: false,
        };
        dispatch(services.customerSignIn(paramsData, t));
        dispatch(
          services.notifySuccess({
            message: t("Password updated successfully"),
          })
        );
        dispatch(customerSendResetPasswordEmailSuccess(""));
      }
    } catch (error) {
      dispatch(loaderStartEnd(false));
      dispatch(
        services.notifyError({
          message: t("The password token is mismatched. Reset and try again."),
        })
      );
    } finally {
      dispatch(loaderStartEnd(false));
    }
  };
};

export const createLoyaltyOtp = async (data) => {
  const loyaltyData = await axios.post(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/customer/loyalty-otp`,
    data
  );
  return loyaltyData;
};

// store-credits-history
export const getStoreCreditsHistory = (params) => {
  return async (dispatch) => {
    dispatch(storeCreditsHistorySuccess(""));
    try {
      let response = await apiHelper(
        `/V1/customer/store-credits-history`,
        "get",
        params,
        {}
      );
      dispatch(storeCreditsHistorySuccess(response.data));
    } catch (error) {
      dispatch(services.notifyError({ message: error.response.data.message }));
    }
  };
};

// store credits history reducer
export const storeCreditsHistorySuccess = (data) => {
  return {
    type: actionType.GET_STORE_CREDITS_HISTORY,
    payload: data,
  };
};

// store credits
export const getStoreCredits = (params) => {
  return async (dispatch) => {
    dispatch(storeCreditsSuccess(""));
    try {
      let response = await apiHelper(
        `/V1/customer/store-credits`,
        "get",
        params,
        {}
      );
      dispatch(storeCreditsSuccess(response.data));
    } catch (error) {
      dispatch(services.notifyError({ message: error.response.data.message }));
    }
  };
};

// apply store credits to cart
export const applyStoreCredit = (params) => {
  return async (dispatch) => {
    try {
      let response = await apiHelper(
        `/V1/cart/store-credit`,
        "put",
        params,
        {}
      );
    } catch (error) {
      dispatch(
        services.notifyError({ message: error.response.data.message })
      );
    }
  };
};

// remove store credits from cart
export const removeStoreCredit = (params) => {
  return async (dispatch) => {
    try {
      await apiHelper(
        `/V1/cart/store-credit`,
        "delete",
        params,
        {}
      );
    } catch (error) {
      dispatch(
        services.notifyError({ message: error.response.data.message })
      );
    }
  };
};

// store credits reducer
export const storeCreditsSuccess = (data) => {
  return {
    type: actionType.GET_STORE_CREDITS_DETAILS,
    payload: data,
  };
};

export const newsletterSubscribe = async (params) => {
  const data = await apiHelper(
    `/V1/content/newsletter/subscribe`,
    "post",
    params,
    {}
  );
  if (data?.data?.success) {
    return data?.data?.message;
  }
  throw Error(data?.data?.message);
};

export const newsletterUnsubscribe = async (params) => {
  const { response, status } = await apiHelper(
    `/V1/customer/newsletter-subscriptions`,
    "delete",
    params,
    {}
  );

  if (status === 200) {
    return response;
  }
  throw Error(response.message);
};

// contact Us API
export const contactUsForm = (params, t) => {
  return async (dispatch) => {
    try {
      let response = await apiHelper(`/V1/sony/contact-us`, "post", params, {});
      dispatch(
        services.notifySuccess({
          message: t("Support team will contact you as soon as possible"),
        })
      );
    } catch (error) {
      services.notifyError({ message: error?.response?.data?.message });
    }
  };
};
