 import React from 'react'
import { useTranslation } from 'react-i18next';

 function Registration_Page() {
  const { t } = useTranslation();
   return (
     <div>{t("Registration_Page")}</div>
   )
 }
 
 export default Registration_Page