import React, { useState, useEffect } from "react";
import "./../../SCSS/ProductType/_productEleven.scss";
import Price from "../Font/Price";
import Text4 from "../Font/Text4";
import { loadCartData } from "../../redux/appAction";
import { addToCart } from "../../services/cart.service";
import * as services from "./../../services/services";
import { useDispatch } from "react-redux";
import { titleChange } from "../helpers/utils/titleNameChange";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { ImageResize } from "../helpers/utils/imageResize";
import LazyImageForProduct from "../MostSharedComponent/LazyImageForProduct";
import AddToCartNotify from "../Buttons/AddToCartNotify";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";

function ProductEleven({ product, outOfStockConfiguration, productIndex, screenName }) {

  const dispatch = useDispatch();
  const { setCartErr } = useCartErrorMessage();

  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);

  useEffect(() => {
    product.is_price_hide = product.options.find((x) => {
      if (x.code === 'is_price_hide') {
        return x.visibility;
      }
      return false;
    });
  }, [product]);

  const AddProductToCart = (sku, id) => {
    setLoaderOn(true);
    // deleteProductFromCart(id)
    const data = {
      items: [
        {
          sku: sku,
          qty: 1,
          id: product?.id
        },
      ],
    };
    addToCart(data)
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id)
          setSpinnerOn(false)
        });
        // setCount(res.data.filter((pro) => pro.sku === product.sku)[0].qty);
      })
      .catch((err) => {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
      })
      .finally(() => {
        setLoaderOn(false);
      });
  };

  return (
    <div key={product.indx} className="productEleven__block">
      <div className=" productEleven__image__block">
        <LazyImageForProduct
          product={product}
          srcSetReSize={ImageResize}
          alt=""
          className="productEleven__image"
        />
      </div>
      <Text4 text={titleChange(product.name)} marginBottom10 />
      {
        !product?.is_price_hide && (
          <Price currency={product.currency} price={product.discounted_price_rounded === null ? product.price_rounded : product.discounted_price_rounded} size="heading6" marginBottom={10} />
        )}
      <>
        <AddToCartNotify
          product={product}
          AddProductToCart={AddProductToCart}
          outOfStockConfiguration={outOfStockConfiguration}
          loaderOn={loaderOn || spinnerOn}
        />
      </>
    </div>
  );
}

export default ProductEleven;
