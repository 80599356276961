import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import {
  categoryAvailableData,
  loadConfigurationSettingData,
  saveConfigurationSettingData,
} from '../../redux/appAction';
import { useTranslation } from 'react-i18next';
import OutStockHelper from '../helpers/utils/outStockHelper';
import { get } from 'idb-keyval';
import { loaderEnd, loaderStart } from '../../services/services';
import { impressionEvents } from '../helpers/utils/ga/impressionEvents';
import CmsHomeWeb from './HomeWeb';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';





function MagentoRenderer({ handleChangeCartPopup, pageName, getPageData }) {
  const [outOfStockConfiguration, setOutOfStockConfiguration] = useState({});
  const [availablityData, setAvailablityData] = useState([]);
  const [isCache, setIsCache] = useState(false);
  const [loading, setLoading] = useState(true);

  const reduxDispatch = useDispatch();
  const { t } = useTranslation();
  const configurationSettingData  = useSelector((state) => state.appData.configurationSettingData);

  useEffect(() => {
    reduxDispatch(loaderStart());
    reduxDispatch(loadConfigurationSettingData()).then(() => {
      reduxDispatch(loaderEnd());
    });
  }, [reduxDispatch]);


  const currentLanguageCode = localStorage.getItem('i18next') || 'en';

  const { data: cmsDataRaw = {} } = useQuery(
    ['homeEntertainmentpageData', currentLanguageCode],
    () => getPageData(),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 2,
      staleTime: Infinity,
    }
  );
  useEffect(() => {
    // check cached data
    const cached = async () => {
    const queryData = await get("sonyDB")
    setIsCache(queryData?.clientState?.queries?.length > 0)
    }
    cached();
  }, []);

  useEffect(() => {
    async function init() {
      if (Object.values(cmsDataRaw).length !== 0) {
        // oos config
        let _outOfStockConfiguration = {};
        if (Object.values(outOfStockConfiguration).length !== 0) {
          reduxDispatch(saveConfigurationSettingData(configurationSettingData));
          _outOfStockConfiguration =
            configurationSettingData?.out_of_stock_configuration || {};
          setOutOfStockConfiguration(_outOfStockConfiguration);
        }

        const productData = [];
        cmsDataRaw?.content
          ?.filter((content) => content?.products)
          .forEach((items) => {
            productData.push(...items.products);
          });
        if (isCache) {
          await OutStockHelper(
            reduxDispatch,
            productData,
            _outOfStockConfiguration
          );
        } else {
          const availablityData = await categoryAvailableData(
            productData && productData.map((item) => item.id)
          );
          setAvailablityData(availablityData?.data || []);
        }
        setLoading(false);
        window.scrollTo(0, 0);
      }
    }
    let mounted = true;
    if (mounted)  init();
    return () => mounted = false;
  }, [
    cmsDataRaw,
    isCache,
    configurationSettingData,
    outOfStockConfiguration,
    reduxDispatch,
  ]);

    const bannerClick = (img, index, bannerFullData) => {
    impressionEvents.impressionBannerClick(
      img,
      pageName,
      index,
      bannerFullData
    );
  };

  const rendererWeb = (item, index) => <div key={`m2_renderer_${index}_index`}>
    {
      CmsHomeWeb(
        item,
        index,
        handleChangeCartPopup,
        outOfStockConfiguration,
        availablityData,
        bannerClick,
        currentLanguageCode,
        t
      )
    }
  </div>;

  if (loading) {
    return (
      <div className="skeleton-container">
        <Skeleton height="calc(100vw * (800/960))" width="100%" />
      </div>
    );
  }
  
  return (
    <>
      {cmsDataRaw?.content?.map(rendererWeb)}
    </>
  );
}

export default MagentoRenderer;