import { useState, memo, useEffect } from 'react';
import { LazyLoadComponent, trackWindowScroll } from 'react-lazy-load-image-component';
import  cdnImageUrl  from '../lib/utils/cdnImageUrl';


const defaultPlaceholder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=';
const isEqual = (checkProps) => (prev, next) => !checkProps.some((prop) => prev[prop] !== next[prop]);



/**
 * Renders a cdn optimized image with optional placeholder and lazy loading functionality.
 *
 * @param {Object} props - The component props same plus more than img props.
 * @param {string} props.src - The source URL of the image.
 * @param {string} props.alt - The alternative text for the image.
 * @param {string} [props.placeHolderImg] - The URL of the placeholder image to display while the main image is loading.
 * @param {string} [props.className] - The CSS class name for the image element.
 * @param {number} [props.height] - The height of the image in pixels.
 * @param {number} [props.width] - The width of the image in pixels.
 * @param {Function} [props.afterLoad] - [lazy only]A callback function to be called after the image has finished loading.
 * @param {Function} [props.onErrorS] - A callback function to be called if an error occurs while loading the image.
 * @param {Function} [props.onClick] - A callback function to be called when the image is clicked.
 * @param {number} [props.responsiveWidth] - The responsive width of the image.
 * @param {number} [props.responsiveHeight] - The responsive height of the image.
 * @param {boolean} [props.preload=false] - Determines whether the image should be preloaded.
 * @param {boolean} [props.visibleByDefault=false] - Determines whether the image should be visible by default.
 * @param {boolean} [props.compress] -[0-100 default=70] Determines whether the image should be compressed .
 * @param {string} [props.fit] - The fit mode for the image.
 * @param {string} [props.noLazy] - load behavior defaults to browsers behavior .
 * @returns {JSX.Element} The rendered ImageRender component.
 */
const ImageRender = ({
  src: srcProp,
  alt,
  placeHolderImg = defaultPlaceholder,
  className,
  height,
  width,
  afterLoad,
  onErrorS,
  responsiveWidth,
  responsiveHeight,
  preload = false,
  visibleByDefault = false,
  compress,
  fit,
  noLazy,
  ...props
}) => {
  const isNullOrUndefined = (obj) => {
    if (typeof obj === 'undefined' || obj === undefined || obj === null) return true;
    return false;
  };
  const [{ error, ...loadingState }, setState] = useState({ loading: false, success: false, error: false });

  const src = isNullOrUndefined(srcProp) || error ? placeHolderImg : cdnImageUrl({
    src: srcProp, responsiveWidth, responsiveHeight, compress, height, width, fit,
  });
  useEffect(() => {
    setState({});
  }, [srcProp]);

  const onError = (e) => {if(src !== placeHolderImg){
    onErrorS && onErrorS(e);
    console.log({
      srcProp, src, target: e.currentTarget,
    });
    setState({ error: true })
  }}

  const onLoad = () => {
    if (src !== placeHolderImg && !loadingState.success) {
      setState({ success: true });
      afterLoad && afterLoad();
    }
  };
  const imgElement = (
    <img
      {...{
        src, alt, height, width, onError, onLoad, className,
        ...(preload ? { loading: "eager" } : {}),
        ...props,
      }}
    />
  );
  if(noLazy) return imgElement;
  return preload
    ? imgElement
    : (
      <LazyLoadingImage afterLoad={onLoad} visibleByDefault={visibleByDefault}>
        {imgElement}
      </LazyLoadingImage>
    );
};

const LazyLoadingImage = trackWindowScroll(({ children, ...props }) => (
  <LazyLoadComponent {...props}>
    {children}
  </LazyLoadComponent>
));
export default memo(ImageRender, isEqual(['src', 'responsiveWidth', 'responsiveHeight', 'width', 'height', 'preload', 'className']));
