import React from "react";
import "./../../SCSS/ProductType/_productThree.scss";
import { Link } from "react-router-dom";
import RatingBlock from "./../MostSharedComponent/RatingBlock";
import Heading6 from "./../Font/Heading6";
import PickupStore from "./../MostSharedComponent/PickupStore";
import Price from "./../Font/Price";
import ShippingBlock from "./../MostSharedComponent/ShippingBlock";
import { loadCartData } from "../../redux/appAction";
import { addToCart } from "../../services/cart.service";
import * as services from "./../../services/services";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";

function ProductThree({ product, productDetails, handleChangeCartPopup, cartDetail, parentData }) {

  const { setCartErr } = useCartErrorMessage();
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const AddProductToCart = (sku, id) => {

    let checkParentSku = cartDetail && cartDetail.items && cartDetail.items.filter((item) => item.sku === parentData.sku);

    let skuList = [
      {
        sku: sku,
        qty: 1,
        id: id
      }
    ]

    if (checkParentSku.length === 0) {
      skuList.push({
        sku: parentData.sku,
        id : parentData?.id,
        qty: 1
      })
    }

    const data = {
      items: skuList,
    };

    addToCart(data)
      .then((res) => {
        dispatch(loadCartData()).then((item) => {
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
      });
  };

  return (
    <div className="row productThree__product__block">
      <div className="col-12 col-sm-3 productThree__product__left__block">
        <div className="productThree__product__image__block">
          <img
            src={productDetails.baseImage}
            alt=""
            className="productThree__product__image"
          />
        </div>
      </div>
      <div className="col-12 col-sm-6 productThree__product__middle__block">
        <img
          src={product.logo}
          alt=""
          className="productThree__product__company__logo" width="90px"
        />
        {/* <p className="productThree__product__productName">{titleChange(product.name)}</p> */}
        <Link
          className="pd__mb__product__name"
          to={productUrlHelper(product?.english_name, product?.sku)}
        >
          <Heading6 text={titleChange(productDetails.name)} marginBottom={10} />
        </Link>
        <RatingBlock
          size={22}
          rating={product.rating}
          totalRatings={product.totalRatings}
          fillColor="#DC3A1A"
          emptyColor="#C8C8C8"
        />
        {/* <PickupStore delivery={product.delivery} title="Get it today" /> */}
        {/* <ShippingBlock shipment={product.delivery}/> */}
      </div>
      <div className="col-12 col-sm-3 productThree__product__right__block">
        <Price price={productDetails.discounted_price_rounded === null ? productDetails.price_rounded : productDetails.discounted_price_rounded} size="heading3" currency={productDetails?.currency} />
        <button onClick={() => AddProductToCart(productDetails.sku, productDetails.id)} className="productThree__addToPackage__button">
          {t("Add_To_Bundle")}
        </button>
        {/* <div className="productThree__compare__block">
          <input
            type="checkbox"
            className="productThree__compare__input__check"
            name="compare"
          />
          <p className="productThree__compare__text">{t('Compare')}</p>
        </div> */}
      </div>
    </div>
  );
}

export default ProductThree;
