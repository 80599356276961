import axios from "axios";

export const getProductDetail = async (sku) => {
  let responseData = {}
  const productDetailData = await axios.get(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/products/${sku}`
  ).then(res => responseData = res.data)
    .catch(err => responseData = err)

  // const product = [responseData];
  // const productStatus = []
  // const productStatus = await productOutOfStockStatus(product);
  // responseData.productOutOfStockStatus = productStatus?.data?.[responseData.id] ? productStatus?.data?.[responseData.id] : false;
  return responseData;
};

export const getAllWarrantyProductList = async (productList) => {
  const allWraProd = [];
  if (productList) {
    let getAllWarrantyProduct = productList?.options.filter(val => val.code === 'extended_warranty');
    if (getAllWarrantyProduct) {
      let labelArr = getAllWarrantyProduct[0]?.selected_option?.labelArr
      for (let index = 0; index < labelArr?.length; index++) {
        const productDataDetails = await getProductDetail(labelArr[index]);
        allWraProd.push(productDataDetails)
      }
    }
  }
  return allWraProd;
}
