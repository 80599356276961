import React from 'react';
import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function CmsContentModal({ title, modalShow, content, onHide, size, scrollable }) {

  const { t } = useTranslation();

  return (
    <Modal
      show={modalShow}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size={size}
      scrollable={scrollable}
    >
      <Modal.Header closeButton >
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div className="wrapper" style={{ backgroundColor: "white" }}>
          <div className="item name">
            {content ? content : t('Content_Not_Found')}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default React.memo(CmsContentModal);
