import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import * as services from "../../services/services";
import "../../SCSS/MobilePages/_mobileCheckoutPage.scss"


import signin_initial from "../../assets/Icon/signin_inprogress.svg";
import signin_inprogress from "../../assets/Icon/signin_inprogress.svg";
import signin_done from "../../assets/Icon/signin_done.svg";
import delivery_initial from "../../assets/Icon/delivery_initial.svg";
import delivery_inprogress from "../../assets/Icon/delivery_inprogress.svg";
import delivery_done from "../../assets/Icon/delivery_done.svg";
import payment_initial from "./../../assets/Icon/payment_initial.svg";
import payment_inprogress from "./../../assets/Icon/payment_inprogress.svg";
import payment_done from "./../../assets/Icon/payment_done.svg";
import done from "../../assets/Icon/done.svg";
import shipping from "../../assets/Icon/shipping.svg";
import pickup_store from "../../assets/Icon/pickup_store.svg";
import edit_box_white from "../../assets/Icon/edit_box_white.svg";
import delete_box_white from "../../assets/Icon/delete_box_white.svg";

import Heading7 from '../../Components/Font/Heading7';
import Text4 from '../../Components/Font/Text4';
import Order_Summary_New from '../Order_Summary_New';
import OpeningHoursModal from '../../Components/Common/OpeningHoursModal';
import Virtual_Product_Checkout_Page from '../Virtual_Product_Checkout_Page';
import { addToCart, deleteFromCart, getEstimateShippingMethods, updateShippingInformation, updateShippingInformationSuccess } from '../../services/cart.service';
import Heading6 from '../../Components/Font/Heading6';
import { loadCartData, loadCitiesLocationData } from '../../redux/appAction';
import { checkoutEvents } from '../../Components/helpers/utils/ga/checkoutEvents';
import Text5 from '../../Components/Font/Text5';
import { BsPlusLg, BsSearch } from 'react-icons/bs';
import { FaCaretDown } from 'react-icons/fa';
import Price from '../../Components/Font/Price';
import Guest_Checkout_Page from '../Guest_Checkout_Page';
import Heading3 from '../../Components/Font/Heading4';
import Heading4 from '../../Components/Font/Heading4';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { getCustomerLoginDetails } from '../../Components/helpers/utils/getCustomerLoginDetails';
import Heading5 from '../../Components/Font/Heading5';
import { acceptOnlyString } from '../../Components/helpers/utils/regexp';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import { emailValidator, isMobileNumber } from '../../Components/helpers/utils/validators';
import { Spinner } from '../../Components/helpers/utils/spinner';
import AddressPopupModal from '../../Components/Popup/AddressPopupModal';
import { impressionEvents } from '../../Components/helpers/utils/ga/impressionEvents';

const MobileDelivery = ({
  cartDetail,
  shippingCustomMsg,
  setShippingCustomMsg,
  handleSetOrderComment,
  cartTotalData,
  loyaltyEarnType,
  loyaltyEarnTypeId,
  storeConfig,
  authRandom,
  userloginData,
  getData
}) => {

  const [iconType, setIconType] = useState({
    signin: "done",
    delivery: "inprogress",
    payment: "initial",
  });

  const [modalShow, setModalShow] = React.useState(false);
  const [openingHoursData, setOpeningHoursData] = React.useState("");
  const [custEmailAddress, setCustEmailAddress] = useState("");
  const [deliveryType, setDeliveryType] = useState([]);
  const [preferenceTab, setPreferenceTab] = useState("");
  const [errMsg, setErrMsg] = useState({
    deliveryAddressList: "",
    deliveryPreferencesType: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    addressLine1: "",
    // addressLine2: "",
    city: "",
    state: "",
    // postCode: "",
    country: "",
    primary: false,
    landmark: "",
  });
  const [storePickupError, setStorePickupError] = useState("");
  const [deliveryPreferencesType, setDeliveryPreferencesType] = useState("");
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [addressData, setAddressData] = useState(false);
  const [viewMoreAddressBtn, setViewMoreAddressBtn] = useState(false);
  const [selectedAddressId, setSelectedAddressID] = useState(0);
  const [editAddressData, setEditAddressData] = useState("");
  const [addressPopup, setAddressPopup] = useState(false);
  const [addressPopupType, setAddressPopupType] = useState("add");
  const [storeCitiesLocationData, setStoreCitiesLocationData] = useState([]);
  const [storeStoresLocationData, setStoreStoresLocationData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [filteredStores, setFilteredStores] = useState([]);
  const [loaderOn, setLoaderOn] = useState(false);
  const [address, setAddress] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    addressLine1: "",
    // addressLine2: "",
    city: "",
    state: "",
    // postCode: "",
    country: "",
    primary: false,
    landmark: "",
    addressType: "1",
  });
  const [storeCountriesLocationData, setStoreCountriesLocationData] = useState([]);
  const [latestDistrictList, setLatestDistrictList] = useState([]);
  const [editId, setEditId] = useState("");
  const [renderPage, setRenderPage] = useState(false);

    const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
  });
    const [addressError, setAddressError] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
  });

  const dispatch = useDispatch()
  const { t } = useTranslation();
  const navigate = useNavigate();
  const closeDialogBox = useRef();

  const T_REQ_FIRST_NAME = t("First_Name_is_required");
  const T_REQ_LAST_NAME = t("Last_Name_is_required");
  // const T_REQ_COUNTRY = t("Country_is_required");
  const T_REQ_MOBILE_NUMBER = t("Mobile_Number_is_required");
  const T_REQ_ADDRESS_LINE_1 = t("Address_Line_1 is_required");
  // const T_REQ_ADDRESS_LINE_2 = t("Address_Line_2_is_required");
  const T_REQ_CITY_TOWN = t("City/Town_is_required");
  // const T_REQ_STATE = t("State_is_required");
  const T_REQ_LANDMARK = t("Landmark_is_required");
  // const T_REQ_POST_CODE = t("Post_Code_is_required");
  const T_INVALID_MOBILE_NUMBER = t("Invalid_Mobile_Number");
  const T_REQ_DISTRICT = t("District_is_required");
  const T_REQ_EMAIL = t("Email_is_missing");
  const T_INVALID_EMAIL = t("Invalid_email_address_example");

  const deliveryShippingInfo = useSelector((state) => state.appData.deliveryShippingInfo);
  const { customerDetails } = useSelector((state) => state.customerReducer);
  const cityLocationData = useSelector((state) => state.appData.cityLocationData);
  const cartData = useSelector((state) => state.appData.cartData);
  const { loadingStartEnd } = useSelector((state) => state.commonReducer);
  const storesLocationData = useSelector((state) => state.appData.storesLocationData);
  const { customerAddressList } = useSelector((state) => state.customerAddressReducer);
  const countriesLocationData = useSelector((state) => state.appData.countriesLocationData);

  const contact_prefill = localStorage.getItem("contact_prefill");

  useEffect(() => {
    if (customerDetails === "") {
      if (contact_prefill !== null) {
        setCustEmailAddress(JSON.parse(contact_prefill).email);
      } else {
        // setCustEmailAddress("");
      }
    } else {
      setCustEmailAddress(customerDetails.email);
    }
  }, [customerDetails, contact_prefill]);

  useEffect(() => {
    if (deliveryShippingInfo !== "") {
      // setIconType({ ...iconType, delivery: "done", payment: "inprogress" });
      // navigate("/checkout/payment")
      dispatch(updateShippingInformationSuccess(""));
    }
  }, [deliveryShippingInfo]);

  useEffect(() => {
    if (storesLocationData) {
      let commonStores,filteredStoresData
      const storesSets = cartData?.items && cartData?.items.map(elem => new Set(elem.extension_attributes.available_stores));
      if(storesSets && storesSets.length > 0){
         commonStores = [...storesSets.reduce((acc, curr) => new Set([...acc].filter(x => curr.has(x))))];
      }
      if(commonStores){
       filteredStoresData = storesLocationData && storesLocationData.filter((store) =>
        commonStores.includes(store?.storeCode.toLowerCase())
      );
      }
      const queryValue = searchData.trim();
      let filterdata = filteredStoresData && filteredStoresData.filter((item) => item.name.toLowerCase().includes(queryValue.toLowerCase()));
      setFilteredStores(filterdata)
    }
  }, [cartData?.items, searchData, storesLocationData]);

  useEffect(() => {
    if (cityLocationData) {
      let cityList = [];
      cityLocationData &&
        cityLocationData.map((val, i) => {
          let cityData = {
            value: val.cityCode,
            label: val.cityCode,
            allData: val,
          };
          cityList.push(cityData);
        });
      setStoreCitiesLocationData(cityList);
    }
  }, [cityLocationData]);

  useEffect(() => {
    if (countriesLocationData) {
      let countryList = [];
      countriesLocationData &&
        countriesLocationData.map((val, i) => {
          let countryData = {
            id: val.id,
            label: val.full_name_english,
          };
          countryList.push(countryData);
        });
      setStoreCountriesLocationData(countryList);
    }
  }, [countriesLocationData]);

  useEffect(() => {
    let districts = storeCitiesLocationData.filter(
      (val, i) => val.allData.cityCode === address.city
    )?.[0]?.allData?.districts;
    if (districts !== undefined) {
      let newArray = [];
      districts &&
        districts.map((item) => {
          let newObj = { label: item.label, value: item.code };
          newArray.push(newObj);
        });
      setLatestDistrictList(newArray);
    }
  }, [address.city, address.state]);

  useEffect(() => {
    if (customerAddressList) {
      let updateAddressData = [];
      customerAddressList &&
        customerAddressList.map((val, i) => {
          let addreDetails = {
            id: val.id,
            isDefault: val.primary,
            userName: `${val.firstname} ${val.lastname}`,
            adddress: `${val.street[0]} ${val.street[1]} ${val.city} ${val.postcode} ${val.country_id}`,
            contact: val.telephone,
            details: val,
          };
          updateAddressData.push(addreDetails);
          if (val.primary === true) {
            setSelectedAddressID(i);
          }
        });
      setAddressData(updateAddressData);
    }
  }, [customerAddressList]);

  useEffect(() => {
    if (customerDetails === "") {
      if (localStorage.getItem("user_mode") === 'guest_user') {
        localStorage.setItem("loginWrapper", JSON.stringify(false));
        localStorage.setItem("loginMode", JSON.stringify(""));
        localStorage.setItem("loginPopup", JSON.stringify(false));
      } else {
        openLoginWrapperFromAnywhere();
      }
      setIconType({ ...iconType, signin: "inprogress" });
    } else {
      dispatch(services.getCustomerAddressList());
      setIconType({ ...iconType, signin: "done" });
    }
  }, [customerDetails, userloginData]);

  useEffect(async () => {
    if (storeConfig !== undefined && storeConfig !== "") {
      dispatch(services.loaderStart())
      const data = await getEstimateShippingMethods();
      if (data) {
        let shippingMethods = data["shipping-methods"];
        const propertyNames = Object.keys(shippingMethods);
        let deliveryData = [];
        propertyNames &&
          propertyNames.map((val, i) => {
            let deliveryInfo = {
              id: shippingMethods[val].shipping_method_code,
              type: shippingMethods[val].title,
              protectionText: shippingMethods[val].head_line,
              price: shippingMethods[val].shipping_cost,
              is_available: shippingMethods[val]?.is_available
            };
            if (shippingMethods[val].shipping_method_code === 'storepickup' && storeConfig === "1") {
              deliveryData.push(deliveryInfo);
            } else if (shippingMethods[val].shipping_method_code !== 'storepickup') {
              deliveryData.push(deliveryInfo);
            }
          });
        setDeliveryType(deliveryData);
        const deliveryDataIds = deliveryData?.filter((item) => item?.is_available).map((item) => item?.id)
        let lastDt = localStorage.getItem("last-dt");
        if ((deliveryDataIds).includes(lastDt)) {
          setDeliveryPreferencesType(lastDt)
        } else {
          setDeliveryPreferencesType(deliveryData[0].id)
        }
      }
      dispatch(services.loaderEnd())
    }
  }, [storeConfig]);

  useEffect(() => {
    const deliveryDataIds = deliveryType?.filter((item) => item?.is_available).map((item) => item?.id)
    let lastDt = localStorage.getItem("last-dt");
    if (deliveryType && deliveryType.length > 0 && cartData && !deliveryDataIds.includes(lastDt)) {
      if (deliveryType &&
        deliveryType.filter((dev) => dev.id === "flatrate" && dev?.is_available)
          .length > 0) {
        setPreferenceTab("home")
        localStorage.setItem("last-dt", "flatrate");
      } else if (deliveryType &&
        deliveryType.filter((dev) => dev.id === "storepickup" && dev?.is_available)
          .length > 0) {
        setPreferenceTab("storepickup")
        setDeliveryPreferencesType("storepickup")
        localStorage.setItem("last-dt", "storepickup");
      } else if (deliveryType && deliveryType.length > 0 &&
        (deliveryType.filter((dev) => dev.id === "flatrate" && dev?.is_available)
          .length < 1) && (deliveryType &&
            deliveryType.filter((dev) => dev.id === "storepickup" && dev?.is_available)
              .length < 1) && !cartData?.is_virtual) {
        navigate('/cart')
      }
    }
  }, [deliveryType, cartData])

  useEffect(() => {
    let lastDt = localStorage.getItem("last-dt");
    if (lastDt === "flatrate") {
      lastDt = "home";
    } else if (lastDt === "storepickup") {
      lastDt = "storepickup";
    } else {
      lastDt = "";
    }
    setPreferenceTab(lastDt);
    const deliveryRef = localStorage.getItem("deliveryPref");
    if (deliveryRef) {
      setDeliveryPreferencesType(deliveryRef);
    }
  }, [])

  useEffect(() => {
    if (editAddressData && addressPopupType === "update") {
      let landmarkData = "";
      let addressTypeData = "1";
      if (editAddressData?.details?.custom_attributes !== undefined) {
        let getLandmark = editAddressData?.details?.custom_attributes.filter(
          (val) => val.attribute_code === "landmark"
        );
        if (getLandmark.length > 0) {
          landmarkData = getLandmark[0].value;
        }
        let getAddressType = editAddressData?.details?.custom_attributes.filter(
          (val) => val.attribute_code === "address_types"
        );
        if (getAddressType.length > 0) {
          addressTypeData = getAddressType[0].value;
        }
      }
      setAddress({
        ...address,
        firstName: editAddressData.details.firstname,
        lastName: editAddressData.details.lastname,
        mobileNumber: editAddressData.details.telephone,
        addressLine1: editAddressData.details.street?.[0],
        addressLine2: editAddressData.details.street?.[1],
        city: editAddressData.details.city,
        state:
          editAddressData?.details?.postcode !== undefined
            ? editAddressData.details.postcode
            : "",
        // postCode: editAddressData.details.postcode,
        primary:
          editAddressData?.details?.primary !== undefined
            ? editAddressData.details.primary
            : false,
        landmark: landmarkData,
        addressType: addressTypeData,
      });
      setEditId(editAddressData.details.id);
      setRenderPage(true);
    }
  }, [editAddressData]);

  useEffect(() => {
    if (renderPage === true) {
      setRenderPage(!renderPage);
    }
  }, [renderPage]);

  const openLoginWrapperFromAnywhere = () => {
    // console.log(document.querySelector(".login__popup__container__disable"));
    // reloadingHeader()

    if (customerDetails === "") {
      const element = document.querySelector(
        ".signin__signup__popup__disable"
      );
      if (element !== null) {
        element.classList.remove("signin__signup__popup__disable");
        element.classList.add("signin__signup__popup");
      }
      localStorage.setItem("loginWrapper", JSON.stringify(true));
      localStorage.setItem("loginMode", JSON.stringify("signin"));
      localStorage.setItem("loginPopup", JSON.stringify(true));
      window.scrollTo(500, 0);
    }
  };

  const handleChangeCreateBillingAddress = (email) => {
    setIconType({ ...iconType, delivery: "done", payment: "inprogress" });
    // setCheckoutClassName("payment");
    // if (paymentMethods.length > 0) {
    //   let getFirstMethod = paymentMethods.filter(val => val.is_available === true);
    //   if (getFirstMethod.length > 0) {
    //     setUserPaymentMethod(getFirstMethod[0].code);
    //   }
    // }
    setCustEmailAddress(email);
    dispatch(updateShippingInformationSuccess(""));
    navigate("/checkout/payment")
  };

  const handleChangePreferenceTab = (name) => {
    const lastDt = name === "home" ? "flatrate" : "storepickup";
    localStorage.setItem("last-dt", lastDt);
    setPreferenceTab(name);
    setErrMsg({
      ...errMsg,
      deliveryPreferencesType: "",
    });
    setStorePickupError("");
    if (name === "home") {
      setDeliveryPreferencesType(deliveryType?.[0].id);
    } else {
      setDeliveryPreferencesType(name);
    }
  };

  const selectAddress = (addIndex, addId, add) => {
    setSelectedAddressID(addIndex);
    setEditAddressData(add);
    const deliveryRef = localStorage.getItem("deliveryPref");
    if (deliveryRef) {
      setDeliveryPreferencesType(deliveryRef);
    } else {
      setDeliveryPreferencesType(deliveryType[0].id);
    }
  };

  const openNewAddressPopup2 = (popupType, addIndex, addId, add) => {
    setAddressPopup(true);
    setAddressPopupType(popupType);
    if (popupType === "update") {
      setSelectedAddressID(addIndex);
      setEditAddressData(add);
    }
  };

  const deleteAddress = (deleteId) => {
    let params = {
      addressId: deleteId,
    };
    dispatch(services.deleteCustomerAddress(params));
  };

  const getDeliveryMethodByCity = (cityName, shippingType) => {
    let shippingIsEnable = false;
    if (shippingType === "express") {
      let expShipping =
        storeCitiesLocationData &&
        storeCitiesLocationData.filter(
          (val) =>
            val.allData.cityCode === cityName &&
            val.allData.expressShipping === "1"
        );
      if (expShipping.length > 0) {
        shippingIsEnable = true;
      }
    }
    if (shippingType === "lighting") {
      let lightShipping =
        storeCitiesLocationData &&
        storeCitiesLocationData.filter(
          (val) =>
            val.allData.cityCode === cityName &&
            val.allData.lightingShipping === "1"
        );
      if (lightShipping.length > 0) {
        shippingIsEnable = true;
      }
    }
    return shippingIsEnable;
  };

  const handleChangeDeliveryPref = (e, value) => {
    setDeliveryPreferencesType(value);
    localStorage.setItem("deliveryPref", value);

    setSelectedStoreId("");
    setStorePickupError("");
  };

  const handleChangePickupStore = (storeId) => {
    // console.log("storeId ", storeId);
    setSelectedStoreId(storeId);
  };

  const handleClickOpeningHour = (valData) => {
    setOpeningHoursData(valData);
    setModalShow(true);
  };

  const customerData = getCustomerLoginDetails();
  const continueFromDelivery = async (newIconType, className) => {
    setLoaderOn(true);
    // setContinueDeliveryLoading(true);
    let newErrObj = {
      deliveryPreferencesType: "",
      deliveryAddressList: "",
    };

    if (deliveryPreferencesType !== "") {
      newErrObj = { ...newErrObj, deliveryPreferencesType: "" };
    } else {
      newErrObj = {
        ...newErrObj,
        deliveryPreferencesType: t("Select_your_shipping_option"),
      };
      // setContinueDeliveryLoading(false);
    }
    if (deliveryPreferencesType !== "") {
        let getDeliveryInfo = addressData?.[selectedAddressId];
        if (
          getDeliveryInfo !== undefined &&
          selectedAddressId !== "" &&
          custEmailAddress !== ""
        ) {
          if (deliveryPreferencesType === "flatrate") {
            let params = {
              useAsBilling: true,
              firstName:
                getDeliveryInfo.details.firstname === undefined
                  ? ""
                  : getDeliveryInfo.details.firstname,
              lastName:
                getDeliveryInfo.details.lastname === undefined
                  ? ""
                  : getDeliveryInfo.details.lastname,
              email: custEmailAddress,
              telephone:
                getDeliveryInfo.details.telephone === undefined
                  ? ""
                  : getDeliveryInfo.details.telephone,
              city:
                getDeliveryInfo.details.city === undefined
                  ? ""
                  : getDeliveryInfo.details.city,
              postCode:
                getDeliveryInfo.details.postcode === undefined
                  ? ""
                  : getDeliveryInfo.details.postcode,
              countryId: getDeliveryInfo.details.country_id,
              street: `${getDeliveryInfo.details.street[0]} ${getDeliveryInfo.details.street[1]}`,
              shippingCarrierCode: deliveryPreferencesType,
              // pickup_store: '',
              region_id:
                getDeliveryInfo.details.region_id === undefined
                  ? 0
                  : getDeliveryInfo.details.region_id,
            };

            await dispatch(updateShippingInformation(params)).then(() => {
              handleSetOrderComment();
            });
          } else if (deliveryPreferencesType !== "storepickup") {
            let params = {
              useAsBilling: true,
              firstName:
                getDeliveryInfo.details.firstname === undefined
                  ? ""
                  : getDeliveryInfo.details.firstname,
              lastName:
                getDeliveryInfo.details.lastname === undefined
                  ? ""
                  : getDeliveryInfo.details.lastname,
              email: custEmailAddress,
              telephone:
                getDeliveryInfo.details.telephone === undefined
                  ? ""
                  : getDeliveryInfo.details.telephone,
              city:
                getDeliveryInfo.details.city === undefined
                  ? ""
                  : getDeliveryInfo.details.city,
              postCode:
                getDeliveryInfo.details.postcode === undefined
                  ? ""
                  : getDeliveryInfo.details.postcode,
              countryId: getDeliveryInfo.details.country_id,
              street: `${getDeliveryInfo.details.street[0]} ${getDeliveryInfo.details.street[1]}`,
              shippingCarrierCode: deliveryPreferencesType,
              // pickup_store: '',
              region_id:
                getDeliveryInfo.details.region_id === undefined
                  ? 0
                  : getDeliveryInfo.details.region_id,
            };

            await dispatch(updateShippingInformation(params)).then(() => {
              handleSetOrderComment();
            });
          }
        } else {
          if (deliveryPreferencesType === "flatrate") {
            if (addressData.length < 1) {
              newErrObj = {
                ...newErrObj,
                deliveryAddressList: t("Add_New_Address"),
              };
            } else {
              newErrObj = {
                ...newErrObj,
                deliveryAddressList: t("Please_Select_Delivery_Address"),
              };
            }
          }
          // setContinueDeliveryLoading(false);
        }

        if (deliveryPreferencesType === "storepickup") {
          if(!userData.firstName || !userData.lastName || !userData.mobileNumber || !userData.email ){
            const errorResult = allFeildValidateForStore(["firstName", "lastName", "mobileNumber", "email"], addressError);
            console.log("errorResult.allErrMsg", errorResult.allErrMsg)
            setAddressError(errorResult.allErrMsg)
            return
          } else if (selectedStoreId === "") {
            setStorePickupError(t("Please_selecte_store"));
            // setContinueDeliveryLoading(false);
            if (userData.firstName && userData.lastName && userData.mobileNumber && userData.email) {
              setAddressError({firstName: "", lastName: "",email: "",mobileNumber: ""})
            }
          } else {
            setStorePickupError("");
            setAddressError({firstName: "", lastName: "",email: "",mobileNumber: ""})
            const selectedStore = storesLocationData && storesLocationData.find((item)=> item?.id === selectedStoreId)
            let params = {
              useAsBilling: true,
              firstName: userData.firstName,
              lastName: userData.lastName,
              email: userData.email,
              telephone:
                userData?.mobileNumber ||
                customerData.custom_attributes.mobile,
              city: selectedStore?.city,
              postCode: "",
              countryId: selectedStore?.country,
              street: selectedStore?.address,
              shippingCarrierCode: deliveryPreferencesType,
              pickup_store:
              selectedStore?.storeCode,
              region_id: '76',
            };
            localStorage.setItem("shippingAsBilling", true);
            localStorage.setItem('pickup_store', selectedStore?.storeCode)
            await dispatch(updateShippingInformation(params)).then(() => {
              handleSetOrderComment();
            });
          }
        }
        await dispatch(loadCartData());
        const totalData = { currencyCode: cartData?.totals_data?.base_currency_code || 'SAR', value: Number(parseFloat(cartData?.totals_data?.base_grand_total).toFixed(2)) };
        const checkoutProducts = cartData?.items?.map((item) => ({
          item_name: item?.product?.name,
          item_id: item?.product?.id,
          price: Number(parseFloat(item?.product?.discounted_price_rounded || item?.product?.price_rounded).toFixed(2)),
          brand: item?.product?.options?.find((option) => option.code === 'brand')?.selected_option?.label || null,
          category: item?.product?.categories?.pop()?.name || 'Sony',
          variant: null,
          quantity: item?.qty,
          dimension1: item?.product?.sku,
          coupon: cartData?.totals_data?.coupon_code || null
        }));
        //checkoutEvents.addShippingInfo(cartData?.totals_data?.coupon_code || null, checkoutProducts, totalData);
        impressionEvents.impressionAddShippingInformation(
          cartData
          ,'Checkout Delivery',
          deliveryPreferencesType
        );

    }
    setErrMsg(newErrObj);
    // setIconType(newIconType);
    // setCheckoutClassName(className);
    window.scrollTo(0, 0);
    const errorArr = [...new Set(Object.values(newErrObj))]
    if (errorArr.length < 2 && errorArr[0] === "") {
      if (deliveryPreferencesType === "storepickup") {
        if (selectedStoreId !== "") {
          navigate("/checkout/payment")
        }
      } else {
        navigate("/checkout/payment")
      }
    }
  };

  const openNewAddressPopup = (popupType, addIndex, addId, add) => {
    dispatch(loadCitiesLocationData());
    setAddressPopup(true);
    setAddressPopupType(popupType);
    setSelectedAddressID(addIndex);
    setEditAddressData(add);
  };

  const closeLoginPopup = () => {
    if (document.querySelector(".address__popup__container")) {
      // reloadingHeader()
      const element = document.querySelector(".address__popup__container");
      if (element !== null) {
        element.classList.remove("address__popup__container");
        element.classList.add("address__popup__container__disable");
      }
    }
    setAddressPopup(false);
  };

  const resetFormValue = () => {
    let formValue = {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      // postCode: "",
      country: "SA",
      primary: false,
      landmark: "",
    };
    setAddress(formValue);
  };
  const resetFormErr = () => {
    let formErr = {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      addressLine1: "",
      // addressLine2: "",
      city: "",
      state: "",
      // postCode: "",
      country: "",
      primary: false,
      landmark: "",
    };
    setErrMsg(formErr);
  };

  const handleClosePopup = () => {
    closeLoginPopup();
    resetFormValue();
    resetFormErr();
  };

  const validateAddressForm = (event, newErrObj, name, value) => {
    //A function to validate each input values
    switch (name) {
      case "firstName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_FIRST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "lastName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_LAST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "mobileNumber":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_MOBILE_NUMBER };
        } else {
          let isValidNumber = isMobileNumber(value);
          if (isValidNumber === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_MOBILE_NUMBER };
          } else {
            newErrObj = { ...newErrObj, [name]: "" };
          }
        }
        break;
      // case 'country':
      //   if (value === "") {
      //     newErrObj = { ...newErrObj, [name]: T_REQ_COUNTRY }
      //   } else {
      //     newErrObj = { ...newErrObj, [name]: '' }
      //   }
      //   break;
      case "addressLine1":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_ADDRESS_LINE_1 };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      // case "addressLine2":
      //   if (value === "") {
      //     newErrObj = { ...newErrObj, [name]: T_REQ_ADDRESS_LINE_2 };
      //   } else {
      //     newErrObj = { ...newErrObj, [name]: "" };
      //   }
      //   break;
      case "city":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_CITY_TOWN, state: "" };
        } else {
          newErrObj = { ...newErrObj, [name]: "", state: "" };
        }
        break;
      case "state":
        // let getDistrictList = getLatestDistrictList(address.city);
        if (value === "" && address.city !== "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_DISTRICT };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      // case 'postCode':
      //   if (value === "") {
      //     newErrObj = { ...newErrObj, [name]: T_REQ_POST_CODE }
      //   } else {
      //     newErrObj = { ...newErrObj, [name]: '' }
      //   }
      //   break;
      case "landmark":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_LANDMARK };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      default:
        break;
    }
    return newErrObj;
  };

  const handleChange = async (event, keyName) => {
    let value = event;
    let name = event;
    if (keyName === "primary") {
      value = event.target.checked;
      name = "primary";
    } else if (keyName === "mobileNumber") {
      value = event;
      name = "mobileNumber";
    } else if (keyName === "city" || keyName === "state") {
      value = event.value;
      name = keyName;
    } else if (
      event.target.name === "firstName" ||
      event.target.name === "lastName"
    ) {
      value = acceptOnlyString(event.target.value, true);
      name = event.target.name;
    } else {
      value = event.target.value;
      name = event.target.name;
    }
    setAddress({ ...address, [name]: value });
    let manageErrMsg = validateAddressForm(event, errMsg, name, value);
    setErrMsg(manageErrMsg);
  };

    const handleChangeStorePickup = (event, keyName) => {
    let value = event;
    let name = event;
    if (
      event?.target?.name === "firstName" ||
      event?.target?.name === "lastName"
    ) {
      value = acceptOnlyString(event.target.value, true);
      name = event.target.name;
    } else if (keyName === "mobileNumber") {
      value = event.trim();
      name = "mobileNumber";
    } else {
      value = event.target.value;
      name = event.target.name;
    }
    
    setUserData({ ...userData, [name]: value });

    let manageErrMsg = validateForm(addressError, name, value);
    setAddressError(manageErrMsg);
  };

  const validateForm = (newErrObj, name, value) => {
    switch (name) {
      case "firstName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_FIRST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "lastName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_LAST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "mobileNumber":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_MOBILE_NUMBER };
        } else {
          let isValidNumber = isMobileNumber(value);
          if (isValidNumber === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_MOBILE_NUMBER };
          } else {
            newErrObj = { ...newErrObj, [name]: "" };
          }
        }
        break;
      case "email":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_EMAIL };
        } else {
          let isValidEmail = emailValidator(value);
          if (isValidEmail === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_EMAIL };
          } else {
            newErrObj = { ...newErrObj, [name]: "" };
          }
        }
        break;
      default:
        break;
    }
    return newErrObj;
  };

  const handleBlur = (e) => {
    let obj = {
      target: {
        name: e.target.name,
        value: e.target.value.trim(),
      },
    };
    handleChange(obj);
  };

  const allFeildValidate = (validateFeild, allErrMsg) => {
    let checkValueStatus = [];
    let checkErrStatus = [];

    let checkCardStatus = false;
    if (
      checkValueStatus.length === validateFeild.length &&
      checkErrStatus.length === validateFeild.length
    ) {
      checkCardStatus = true;
    }
    let returnData = {
      allErrMsg: allErrMsg,
      checkCardStatus: checkCardStatus,
    };

    return returnData;
  };

    const allFeildValidateForStore = (validateFeild, allErrMsg) => {
    let checkValueStatus = [];
    let checkErrStatus = [];

    validateFeild &&
      validateFeild.map((val, i) => {
        let keyVal = userData[val];
        let errVal = addressError[val];

        allErrMsg = validateForm(allErrMsg, val, keyVal);
        if (keyVal !== "") {
          checkValueStatus.push("suc");
        }
        if (errVal === "") {
          checkErrStatus.push("err");
        }
      });

    let checkAddressStatus = false;
    if (
      checkValueStatus.length === validateFeild.length &&
      checkErrStatus.length === validateFeild.length
    ) {
      checkAddressStatus = true;
    }

    let returnData = {
      allErrMsg: allErrMsg,
      checkAddressStatus: checkAddressStatus,
    };

    return returnData;
  };


  const handleSubmit = () => {
    setLoaderOn(true);
    let validateFeild = [
      "firstName",
      "lastName",
      "mobileNumber",
      "addressLine1",
      // "addressLine2",
      "city",
      // "state",
      // "country",
      // "postCode",
      "landmark",
    ];

    let getDistrictList = latestDistrictList;
    if (getDistrictList.length > 0) {
      validateFeild.push("state");
    }

    let formStatus = allFeildValidate(validateFeild, errMsg);
    setErrMsg(formStatus.allErrMsg);

    let distDataManage = "";
    if (getDistrictList.length > 0 && address.state !== "") {
      distDataManage = address.state;
    }
    if (formStatus.checkAddressStatus === true) {
      if (editId !== "" && addressPopupType === "update") {
        let params = {
          addressId: addressPopupType === "update" ? editId : "",
          firstName: address.firstName,
          lastName: address.lastName,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          city: address.city,
          telephone: address.mobileNumber,
          primary: address.primary,
          // countryId: address.country ? address.country : "SA",
          countryId: "SA",
          postCode: distDataManage,
          regionId: 0,
          custom_attributes: [
            {
              attribute_code: "landmark",
              value: address.landmark,
            },
            {
              attribute_code: "address_types",
              value: address.addressType === "" ? "1" : address.addressType,
            },
          ],
        };
        dispatch(services.updateCustomerAddress(params));
      } else {
        let params = {
          firstName: address.firstName,
          lastName: address.lastName,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          city: address.city,
          telephone: address.mobileNumber,
          primary: address.primary,
          // countryId: address.country ? address.country : "SA",
          countryId: "SA",
          postCode: distDataManage,
          regionId: 0,
          custom_attributes: [
            {
              attribute_code: "landmark",
              value: address.landmark,
            },
            {
              attribute_code: "address_types",
              value: address.addressType === "" ? "1" : address.addressType,
            },
          ],
        };
        dispatch(services.createCustomerAddress(params));
      }
    }
  };

  function Position(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return currenttop > 200 ? [currenttop - 200] : [0];
    }
  }

  useEffect(() => {
    if (customerData && !userData?.firstName && !userData?.lastName && !userData?.email && !userData?.mobileNumber) {
      setUserData({
        firstName: customerData.firstname,
        lastName: customerData.lastname,
        email: customerData.email,
        mobileNumber: customerData?.custom_attributes?.phone_number
      })
    }
  }, [customerData])
  


  useEffect(() => {
    const errorElement = document.querySelector(".invalid__message");
    if (document.activeElement.tagName !== "INPUT" && errorElement) {
      window.scrollTo(0, Position(errorElement));
    }

  }, [errMsg, storePickupError, addressError])

  return (
    <>
    {!authRandom ?
    <div className="row checkout__login__main__block">
      <div
        onClick={() => openLoginWrapperFromAnywhere()}
        className="col-2 checkout__signin__button"
      >
        <img
          src={
            iconType.signin === "inprogress"
              ? signin_inprogress
              : iconType.signin === "done"
                ? signin_done
                : signin_initial
          }
          alt=""
        />
        {iconType.signin === "done" ? (
          <img className="done__icon" src={done} alt="done" />
        ) : (
          ""
        )}
      </div>
      <div className="col-3 checkout__middle__line__block"></div>
      <div
        // onClick={() => handleChangeClassName("delivery")}
        className="col-2 checkout__delivery__button"
      >
        <img
          src={
            iconType.delivery === "inprogress"
              ? delivery_inprogress
              : iconType.delivery === "done"
                ? delivery_done
                : delivery_initial
          }
          alt=""
        />
        {iconType.delivery === "done" ? (
          <img className="done__icon" src={done} alt="done" />
        ) : (
          ""
        )}
      </div>
      <div className="col-3 checkout__middle__line__block"></div>
      <div
        className="col-2 checkout__payment__button"
      >
        <img
          src={
            iconType.payment === "inprogress"
              ? payment_inprogress
              : iconType.payment === "done"
                ? payment_done
                : payment_initial
          }
          alt=""
        />
        {iconType.payment === "done" ? (
          <img className="done__icon" src={done} alt="done" />
        ) : (
          ""
        )}
      </div>
      {modalShow &&
        <OpeningHoursModal
          modalShow={modalShow}
          val={openingHoursData}
          onHide={() => setModalShow(false)}
        />
      }
      <div className="user__delivery__address__block">
        {cartDetail?.is_virtual === true ? (
          <Virtual_Product_Checkout_Page
            handleChangeCreateBillingAddress={handleChangeCreateBillingAddress}
            setCustEmailAddress={setCustEmailAddress}
          />
        ) : (
          <>
            {deliveryType && deliveryType.length > 0 ?
              <>
                <div className="delivery__address__block">
                  <div className="delivery__address__title__block">
                    <img src={shipping} alt="" className="user__icon" />
                    <Heading6
                      text={t("WHAT_DO_YOU_PREFER")}
                      color="#000000"
                      marginLeft={10}
                      marginBottom={0}
                    />
                  </div>
                  <div className="col-12 col-md-12 px-3 delivery__address__option__area">
                    <div className="row">
                      {deliveryType &&
                        deliveryType.filter((dev) => dev.id === "flatrate" && dev?.is_available).length >
                        0 && preferenceTab !== "" && (
                          <div
                            className="col-12 col-sm-12 col-md-5  delivery__option__item"
                          >
                            <div
                              className={`${preferenceTab === "home"
                                ? "delivery__option__btn active"
                                : "delivery__option__btn"
                                }`}
                              onClick={() => handleChangePreferenceTab("home")}
                            >
                              <input
                                type="radio"
                                className="delivery__input__check"
                                name="deliveryType"
                                // value={delivery.id}
                                onChange={(e) => handleChangePreferenceTab("home")}
                                checked={preferenceTab === "home" ? true : false}
                              />
                              <img src={shipping} alt="" className="user__icon " />
                              {t("Home_Delivery")}
                            </div>
                          </div>
                        )}
                      {deliveryType &&
                        deliveryType.filter((dev) => dev.id === "storepickup" && dev?.is_available)
                          .length > 0 && preferenceTab !== "" && (
                          <div
                            className="col-12 col-sm-12 col-md-5  delivery__option__item"
                          >
                            <div
                              className={`${preferenceTab === "storepickup"
                                ? "delivery__option__btn active"
                                : "delivery__option__btn"
                                }`}
                              onClick={() => {
                                handleChangePreferenceTab("storepickup")
                              }}
                            >
                              <input
                                type="radio"
                                className="delivery__input__check"
                                name="deliveryType"
                                // value={delivery.id}
                                onChange={(e) => {
                                  handleChangePreferenceTab("storepickup");
                                }}
                                checked={
                                  preferenceTab === "storepickup" ? true : false
                                }
                              />
                              <img
                                src={pickup_store}
                                alt=""
                                className="user__icon "
                              />
                              {t("Store_Pickup")}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  {(userloginData === true || getData?.user?.userLoggedIn === true) && customerDetails !== "" && preferenceTab === "home" && deliveryType &&
                    deliveryType.filter((dev) => dev.id === "flatrate" && dev?.is_available).length >
                    0 && (
                      <>
                        <div className="delivery__address__title__block select__address__block">
                          <img src={shipping} alt="" className="user__icon" />
                          <Heading6
                            text={t("SELECT_DELIVERY_ADDRESS")}
                            color="#000000"
                            marginLeft={10}
                            marginBottom={0}
                          />
                        </div>
                        <div className="checkout__address__add__area">
                          <div className="row address__select__block">
                            {addressData &&
                              addressData
                                .filter((val, i) =>
                                  viewMoreAddressBtn === false
                                    ? i < 2
                                    : addressData.length
                                )
                                .map((add, addIndex) => {
                                  return (
                                    <div
                                      key={add.id}
                                      className="col-12 col-md-6 col-lg-4 col-xl-5 address__block"
                                    >
                                      <div
                                        className={
                                          selectedAddressId === addIndex
                                            ? "selected__address__inner__block"
                                            : "address__inner__block"
                                        }
                                        onClick={() =>
                                          selectAddress(addIndex, add.id, add)
                                        }
                                      >
                                        <div className="user__address__info__area">
                                          <Heading7 text={add.userName} />
                                          <div className="full__address">
                                            <Text4
                                              text={add.adddress}
                                              marginBottom={20}
                                            />
                                          </div>
                                          <Text4
                                            text={add.addressTypeData}
                                            marginBottom={5}
                                          />
                                          {/* <Heading7
                                              text="Phone Number:"
                                              color="#808080"
                                              span={true}
                                            />{" "} */}
                                          <Text4
                                            text={add.contact}
                                            marginLeft={10}
                                            span={true}
                                          />
                                        </div>
                                        <div className="address__action__area">
                                          <div className="inner__address__button__block">
                                            <button
                                              onClick={() =>
                                                openNewAddressPopup2(
                                                  "update",
                                                  addIndex,
                                                  add.id,
                                                  add
                                                )
                                              }
                                              className="edit__button"
                                            >
                                              <img src={edit_box_white} alt="edit" />
                                              {/* {t("EDIT")} */}
                                            </button>
                                            <button
                                              className="delete__button"
                                              onClick={() => deleteAddress(add.id)}
                                            >
                                              <img
                                                src={delete_box_white}
                                                alt="delete"
                                              />
                                              {/* {t("DELETE")} */}
                                            </button>
                                          </div>
                                          {add.isDefault ? (
                                            <div className="address__tag">
                                              <Heading7
                                                text={t("Default")}
                                                span={true}
                                              />
                                            </div>
                                          ) : (
                                            <div className="white__address__tag">
                                              <Text5
                                                text={t("NONE")}
                                                span={true}
                                                color="#ffffff"
                                              />
                                            </div>
                                          )}
                                        </div>
                                        <div className="address__button__block">
                                          <button className="delivery__button">
                                            {t("DELIVER_TO_THIS_ADDRESS")}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            {errMsg.deliveryAddressList && (
                              <p
                                className="invalid__message"
                                style={{
                                  color: "red",
                                  margin: "5px 0px 0px",
                                }}
                              >
                                {errMsg.deliveryAddressList}
                              </p>
                            )}
                          </div>
                          <div className=" add__new__address__block">
                            <button
                              onClick={() => openNewAddressPopup2("add")}
                              className="location__button"
                            >
                              {/* <img
                            src={black_location}
                            alt=""
                            className="location__icon"
                          /> */}
                              <BsPlusLg />
                              <Heading7
                                text={t("Add_More")}
                                marginBottom={0}
                                color="#000000"
                              />
                            </button>
                          </div>
                        </div>
                        {/* <hr className="checkout__page__horizontal__line"></hr> */}
                        {addressData && addressData.length > 2 && (
                          <div className="view__more__address__block">
                            <button
                              onClick={() =>
                                setViewMoreAddressBtn(!viewMoreAddressBtn)
                              }
                              className="view__more__address__button"
                            >
                              <Heading6
                                text={
                                  viewMoreAddressBtn ? t("View_Less") : t("View_More")
                                }
                                marginBottom={0}
                                color="#000000"
                              />
                              <FaCaretDown />
                            </button>
                          </div>
                        )}

                        <hr className="checkout__page__horizontal__line"></hr>
                        <div className="row delivery__selcetion__pickup__store">
                          <div className="col-12 col-sm-12 col-md-12 delivery__preferences__block">
                            <div className="delivery__preferences__title__block">
                              <Heading6
                                text={t(
                                  "SELECT_HOW_FAST_YOU_WANT_TO_RECEIVE_YOUR_SHIPMENT"
                                )}
                                color="#000000"
                              />
                            </div>
                            <div className="delivery__selection__block col-md-7">
                              {deliveryType &&
                                deliveryType.map((delivery, deliveryIndex) => {
                                  let cityName =
                                    addressData &&
                                    addressData[selectedAddressId] &&
                                    addressData[selectedAddressId].details &&
                                    addressData[selectedAddressId].details.city;
                                  let shippingIsEnable = true;
                                  if (delivery.id === "lighting") {
                                    shippingIsEnable = getDeliveryMethodByCity(
                                      cityName,
                                      "lighting"
                                    );
                                  } else if (delivery.id === "express") {
                                    shippingIsEnable = getDeliveryMethodByCity(
                                      cityName,
                                      "express"
                                    );
                                  } else if (delivery.id === "storepickup") {
                                    shippingIsEnable = false;
                                  }
                                  return (
                                    <React.Fragment key={delivery?.id}>
                                      {shippingIsEnable === true && (
                                        <div
                                          key={delivery.id}
                                          className="delivery__selection"
                                        >
                                          <div className="delivery__selection__form__block">
                                            <input
                                              type="radio"
                                              className="delivery__input__check"
                                              id={`deliveryType_radio_${delivery?.id}`}
                                              name="subDeliveryType"
                                              checked={
                                                deliveryPreferencesType == delivery.id
                                                  ? true
                                                  : false
                                              }
                                              value={delivery.id}
                                              onChange={(e) =>
                                                handleChangeDeliveryPref(
                                                  e,
                                                  delivery.id
                                                )
                                              }
                                            />
                                            <div className="delivery__selection__text">
                                              <label
                                                htmlFor={`deliveryType_radio_${delivery?.id}`}
                                              >
                                                <Heading6 text={delivery.type} />
                                              </label>
                                              <Text4
                                                text={delivery.protectionText}
                                                color="#3b3b3b"
                                                marginBottom={0}
                                              />
                                            </div>
                                          </div>
                                          <div className="delivery__price__block">
                                            <div className="delivery__price">
                                              {delivery.price <= 0 ? (
                                                <Heading6
                                                  text={t("Free")}
                                                  color="#FF4F04"
                                                />
                                              ) : (
                                                <Price
                                                  price={delivery?.price}
                                                  size="heading7"
                                                  currency={"SAR"}
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              {errMsg.deliveryPreferencesType && (
                                <p
                                  className="invalid__message"
                                  style={{
                                    color: "red",
                                    margin: "5px 0px 0px",
                                  }}
                                >
                                  {errMsg.deliveryPreferencesType}
                                </p>
                              )}
                            </div>
                            <div className="delivery__selection__block col-md-6 delivery__order_note">
                              <Heading7 text={t("Order Note")} marginBottom={10} />
                              <textarea
                                rows={4}
                                className="form-control"
                                id="shippingCustomMsg"
                                name="shippingCustomMsg"
                                value={shippingCustomMsg}
                                onChange={(e) => setShippingCustomMsg(e.target.value)}
                              />
                            </div>
                          </div>
                          {/* {deliveryPreferencesType === "storepickup" && (
                          <div className="col-12 col-sm-12 col-md-5  delivery__pickup__store">
                            <PickupStoreCheckout
                              title="Pick Up From Store"
                              pickupStore={storeStoresLocationData}
                              selectedStoreId={selectedStoreId}
                              handleChangePickupStore={handleChangePickupStore}
                            />
                          </div>
                        )} */}
                        </div>
                      </>
                    )}
                  {!(userloginData === true || getData?.user?.userLoggedIn === true) && (
                    <>
                      <Guest_Checkout_Page
                        deliveryTypeList={deliveryType}
                        // delivery={product.delivery}
                        title="Pick Up From Store"
                        pickupStore={storesLocationData}
                        selectedStoreId={selectedStoreId}
                        handleChangePickupStore={handleChangePickupStore}
                        preferenceTab={preferenceTab}
                        getDeliveryMethodByCity={getDeliveryMethodByCity}
                        storesLocationData={storesLocationData}
                        handleClickOpeningHour={handleClickOpeningHour}
                        mode="mob"
                        setEditAddressData={setEditAddressData}
                        setCustEmailAddress={setCustEmailAddress}
                        handleSetOrderComment={handleSetOrderComment}
                      />
                    </>
                  )}
                </div>
                {(userloginData === true || getData?.user?.userLoggedIn === true) && customerDetails !== "" && preferenceTab === "storepickup" && (
                  <>
                    {/* <h1>Store pickup section</h1> */}
                     <div className="newAddress__block guest__checkout">
                                    <div className="newAddress__title__block">
                                      <Heading3
                                        text={t("Personal_Information")}
                                        color="#000000"
                                      />
                                    </div>
                                    <div className="row newAddress__form__field__row">
                                      <div className="col-sm-12 col-md-6 main__form__field__block">
                                        {/* <p className="form__label">First Name</p> */}
                                        <Heading7
                                          text={t("First_Name")}
                                          marginBottom={10}
                                        />
                                        <div className="field__block">
                                          <input
                                            type="text"
                                            placeholder={t("First_Name")}
                                            className="form__field"
                                            id="name"
                                            name="firstName"
                                            key={"firstName"}
                                            value={userData.firstName}
                                            onChange={(e) => handleChangeStorePickup(e)}
                                            onBlur={handleBlur}
                                          />
                                        </div>
                                        {addressError.firstName && (
                                          <p className="invalid__message">
                                            {addressError.firstName}
                                          </p>
                                        )}
                                      </div>
                                      <div className="col-sm-12 col-md-6 main__form__field__block">
                                        {/* <p className="form__label">Mobile Number</p> */}
                                        <Heading7
                                          text={t("Last_Name")}
                                          marginBottom={10}
                                        />
                                        <div className="field__block">
                                          <input
                                            type="text"
                                            placeholder={t("Last_Name")}
                                            className="form__field"
                                            id="lastName"
                                            name="lastName"
                                            key="lastName"
                                            value={userData.lastName}
                                            onChange={(e) => handleChangeStorePickup(e)}
                                            onBlur={handleBlur}
                                          />
                                        </div>
                                        {addressError.lastName && (
                                          <p className="invalid__message">
                                            {addressError.lastName}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                      <div className="row newAddress__form__field__row">
                                        <div className="col-sm-12 col-md-6 main__form__field__block">
                                          <Heading7 text={t("Mobile_Number")} marginBottom={10} />
                                          <PhoneInput
                                            inputProps={{
                                              name: "mobileNumber",
                                              required: true,
                                              className: "profile__mobile__form__field",
                                            }}
                                            country="sa"
                                            onlyCountries={["sa"]}
                                            masks={{ sa: ".. ... ...." }}
                                            countryCodeEditable={false}
                                            disableDropdown={true}
                                            value={userData.mobileNumber}
                                            onChange={(e) => handleChangeStorePickup(e, "mobileNumber")}
                                            className=""
                                          />
                                          {addressError.mobileNumber && (
                                            <p className="invalid__message">{addressError.mobileNumber}</p>
                                          )}
                                        </div>
                                        <div className="col-sm-12 col-md-6 main__form__field__block">
                                          <Heading7
                                            text={t("Order_confirmation_email")}
                                            marginBottom={10}
                                          />
                                          <div className="field__block">
                                            <input
                                              type="text"
                                              placeholder={t("Order_confirmation_email")}
                                              className="form__field"
                                              id="email"
                                              name="email"
                                              key="email"
                                              value={userData.email}
                                              onChange={(e) => handleChangeStorePickup(e)}
                                              onBlur={handleBlur}
                                            />
                                          </div>
                                          {addressError.email && (
                                            <p className="invalid__message">{addressError.email}</p>
                                          )}
                                        </div>
                                      </div>
                                  </div>
                    <div className="store__pickup__mainarea">
                      <div className="store__pickup__heading">
                        <Heading4 text={t("Available_showrooms")} color="#424b5a" />
                        {/* <Select
                          name="city"
                          onChange={(e) => setStoreCityCode(e.allData.cityName)}
                          options={storeCitiesLocationData}
                          styles={{ marginBottom: "10px" }}
                        /> */}
                        <InputGroup className="search__store__availablebox">
                          <Form.Control
                            placeholder={t("Search city or store")}
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            onChange={(e) => setSearchData(e.target.value)}
                            value={searchData}
                          />
                          <Button variant="outline-secondary" id="button-addon2">
                            <BsSearch />
                          </Button>
                        </InputGroup>
                      </div>

                      {filteredStores && filteredStores?.length > 0 && <div className="store__pickup__options__box">
                        {filteredStores &&
                          filteredStores.map((val, i) => {
                            return (
                              <React.Fragment key={i}>
                                <div className="store__pickup__itembox">
                                  <div className="store__pickup__select__area">
                                    <input
                                      type="radio"
                                      checked={selectedStoreId === val?.id ? true : false}
                                      onChange={() => handleChangePickupStore(val?.id)}
                                    />
                                  </div>
                                  <div className="pickup__store__infobox">
                                    <p className="product__store__status">
                                      <strong>{val.name}</strong>
                                    </p>
                                    <div className="pickup__store__location">
                                      <span><b>{t("Address")} : </b>{`${val?.city} - ${val?.address}`}</span>
                                        {val?.phoneNumber &&
                                      <p className="mb-0"><b>{t("Phone")} : </b><a className="text-decoration-none" href={`tel:${val?.phoneNumber}`} target="_" rel="noopener nofollow noreferrer" dir="ltr">{`${val?.phoneNumber}`}</a></p>
                                        }
                                     </div>
                                    {/* <div className="pickup__store__time"><img src="" /> <span>Saturday to Thursday 9:00am-11:00pm|Friday: 4:00pm-11:00pm</span></div> */}
                                    <Button
                                      onClick={() => handleClickOpeningHour(val)}
                                      className="store__opening__hours__btn"
                                      style={{ backgroundColor: "#dc3a1a" }}
                                    >
                                      {t("Opening_hours")}
                                    </Button>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                      </div> 
                      }
                      {storePickupError !== "" && (
                        <p className="invalid__message">{storePickupError}</p>
                      )}
                      <div className="delivery__selection__block col-md-6 delivery__order_note">
                        <Heading7 text={t("Order Note")} marginBottom={10} />
                        <textarea
                          rows={4}
                          className="form-control"
                          id="shippingCustomMsg"
                          name="shippingCustomMsg"
                          value={shippingCustomMsg}
                          onChange={(e) => setShippingCustomMsg(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                )}

                {(userloginData === true || getData?.user?.userLoggedIn === true) && customerDetails !== "" && preferenceTab !== "" && (
                  <div className="row delivery__selcetion__pickup__store">
                    <div className="continue__button__block">
                      <div></div>
                      <button
                        id="delivery-form-mobile"
                        onClick={() => {
                          document.activeElement.blur();
                          impressionEvents.impressionAddShippingInformation(
                            cartData
                          );
                          //setTimeout(() => {
                            continueFromDelivery()
                            
                          // }, 200)
                        }}
                        className="continue___button"
                      >
                        {loadingStartEnd === true ? <Spinner /> : t("Continue")}
                      </button>
                    </div>
                  </div>
                )}
              </>
              :
              <div style={{ textAlign: 'center' }} className="my-3">
                <Spinner type='dark' />
              </div>
            }
          </>
        )}
      </div>
      {/* package Summary */}
      <div className="col-md-12 col-xl-4  checkout__right__block">
        {typeof cartDetail === "object" &&
          typeof cartTotalData === "object" && (
            <Order_Summary_New
              cartDetail={cartDetail}
              cartTotalData={cartTotalData}
              loyaltyEarnType={loyaltyEarnType}
              loyaltyEarnTypeId={loyaltyEarnTypeId}
              loyaltyRadioName="loyaltyEarnTypeMobile"
            />
          )}
      </div>
      <div className="mb__checkout__modal">
        {/* change address popup start */}
        {/* <!-- Modal --> */}
        <div
          className="modal mb__bottom_popup"
          id="changeAddressModal"
          tabIndex="-1"
          aria-labelledby="changeAddressModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog mb__dialog__end modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <Heading5 text={t("SELECT_DELIVERY_ADDRESS")} />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-footer border-top-0">
                <button
                  type="button"
                  className="btn btn-border btn__border__black w-100 d-block mb-2"
                  data-bs-target="#addAddressModal"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                >
                  {t("ADD_NEW_ADDRESS")}
                </button>
              </div>
              <div className="modal-body">
                <div className="custom__checkbox mb-3">
                  {addressData &&
                    addressData
                      .filter((val, i) =>
                        viewMoreAddressBtn === false
                          ? i < 3
                          : addressData.length
                      )
                      .map((add, addIndex) => {
                        return (
                          <label
                            key={add.id}
                            htmlFor="exampleCheck1"
                            className="custom__checkmark p-3 mb-3"
                          >
                            <div
                              className={
                                selectedAddressId === addIndex
                                  ? "selected__address__inner__block"
                                  : "address__inner__block"
                              }
                              onClick={() =>
                                selectAddress(addIndex, add.id, add)
                              }
                            >
                              {add.isDefault ? (
                                <p className="p-1 d-inline-block mb__adress__tag">
                                  {t("Default")}
                                </p>
                              ) : (
                                <p className="p-1 d-inline-block mb__adress__tag">
                                  {t("NONE")}
                                </p>
                              )}
                              <div className="row">
                                <div className="col-8">
                                  <Heading7 text={add.userName} />
                                  <address className="mb-3 text-wrap">
                                    {add.adddress}
                                  </address>
                                  <p>{add.contact}</p>
                                </div>
                                <div className="col">
                                  <button
                                    onClick={() =>
                                      openNewAddressPopup(
                                        "update",
                                        addIndex,
                                        add.id,
                                        add
                                      )
                                    }
                                    className="btn btn-outline-secondary shadow-none"
                                    data-bs-target="#addAddressModal"
                                    data-bs-toggle="modal"
                                    data-bs-dismiss="modal"
                                  >
                                    {t("Edit")}
                                  </button>
                                </div>
                              </div>
                              <button className="btn btn__primary__orange w-100">
                                {t("DELIVER_TO_THIS_ADDRESS ")}
                              </button>
                            </div>
                          </label>
                        );
                      })}
                </div>
                {/* <div className="custom__checkbox">
                  <input
										type="checkbox"
										className="form-check-input"
										id="exampleCheck2"
									/>
                  <label htmlFor="exampleCheck2" className="custom__checkmark p-3">
                    <p className="p-1 d-inline-block mb__adress__tag">
                      {t("Office")}
                    </p>
                    <div className="row">
                      <div className="col-8">
                        <Heading7 text="John Doe" />
                        <address className="mb-3 text-wrap">
                          {t("21_West_52nd_Street_New_York")}
                        </address>
                        <p>{t("mobile_number")}</p>
                      </div>
                      <div className="col">
                        <button
                          className="btn btn-outline-secondary shadow-none"
                          data-bs-target="#addAddressModal"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                        >
                          {t("Edit")}
                        </button>
                      </div>
                    </div>
                    <button className="btn btn__primary__orange w-100">
                      {t("DELIVER_TO_THIS_ADDRESS")}
                    </button>
                  </label>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Modal end--> */}
        {/* change address popup end */}

        {/* add new address popup  start*/}
        {/* <!-- Modal --> */}
        <div
          className="modal mb__bottom_popup"
          id="addAddressModal"
          tabIndex="-1"
          aria-labelledby="addAddressModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog mb__dialog__end modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <Heading5
                  text={
                    addressPopupType === "add"
                      ? t("Add_New_Address")
                      : t("Update_Address")
                  }
                  color="#000000"
                />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Open"
                  ref={closeDialogBox}
                  onClick={handleClosePopup}
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="Name" className="form-label">
                    <strong>{t("First_Name")}</strong>
                    <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                  </label>
                  <input
                    type="text"
                    placeholder={t("John")}
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={address.firstName}
                    onChange={(e) => handleChange(e)}
                  />
                  {errMsg.firstName && (
                    <p className="invalid__message">{errMsg.firstName}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="Name" className="form-label">
                    <strong>{t("Last_Name")}</strong>
                    <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Doe")}
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={address.lastName}
                    onChange={(e) => handleChange(e)}
                  />
                  {errMsg.lastName && (
                    <p className="invalid__message">{errMsg.lastName}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="Mobile" className="form-label">
                    <strong>{t("Mobile_Number")}</strong>
                    <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                  </label>
                  <PhoneInput
                    inputProps={{
                      name: "mobileNumber",
                      required: true,
                      className: "form-control",
                    }}
                    country="sa"
                    onlyCountries={["sa"]}
                    masks={{ sa: ".. ... ...." }}
                    countryCodeEditable={false}
                    disableDropdown={true}
                    value={address.mobileNumber}
                    onChange={(e) => handleChange(e, "mobileNumber")}
                    className=""
                  />
                  {errMsg.mobileNumber && (
                    <p className="invalid__message">{errMsg.mobileNumber}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="Mobile" className="form-label">
                    <strong>{t("Country")}</strong>
                    <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                  </label>
                  <select
                    name="country"
                    onChange={(e) => handleChange(e)}
                    value={address.country}
                    className="_customselect form-control"
                    disabled={true}
                  >
                    {/* <option key='no' value=''>Select Country</option> */}
                    {storeCountriesLocationData &&
                      storeCountriesLocationData.map((val, i) => {
                        return (
                          <React.Fragment key={val?.id}>
                            <option key={val.id} value={val.id}>
                              {val.label}
                            </option>
                          </React.Fragment>
                        );
                      })}
                  </select>
                  {errMsg.country && (
                    <p className="invalid__message">{errMsg.country}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="Mobile" className="form-label">
                    <strong>{t("Address_Line_1")}</strong>
                    <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                  </label>
                  <input
                    type="text"
                    placeholder={t("21_West_52nd_Street_New_York")}
                    className="form-control"
                    id="addressLine1"
                    name="addressLine1"
                    value={address?.addressLine1 || undefined}
                    onChange={(e) => handleChange(e)}
                  />
                  {errMsg.addressLine1 && (
                    <p className="invalid__message">{errMsg.addressLine1}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="Mobile" className="form-label">
                    <strong>{t("Address_Line_2")}</strong>
                  </label>
                  <input
                    type="text"
                    placeholder={t("21_West_52nd_Street_New_York")}
                    className="form-control"
                    id="addressLine2"
                    name="addressLine2"
                    value={address?.addressLine2 || undefined}
                    onChange={(e) => handleChange(e)}
                  />
                  {errMsg.addressLine2 && (
                    <p className="invalid__message">{errMsg.addressLine2}</p>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="Mobile" className="form-label">
                    <strong>{t("City_Town")}</strong>
                    <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                  </label>
                  <Select
                    name="city"
                    onChange={(e) => handleChange(e, "city")}
                    value={
                      storeCitiesLocationData &&
                      storeCitiesLocationData.filter(
                        (val) => val.value === address.city
                      )?.[0]
                    }
                    options={storeCitiesLocationData}
                  />
                  {errMsg.city && (
                    <p className="invalid__message">{errMsg.city}</p>
                  )}
                </div>
                {latestDistrictList && latestDistrictList.length > 0 && (
                  <div className="mb-3">
                    <label htmlFor="Mobile" className="form-label">
                      <strong>{t("District")}</strong>
                      <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                    </label>
                    <Select
                      name="state"
                      onChange={(e) => handleChange(e, "state")}
                      value={
                        latestDistrictList.filter(
                          (val) => val.value === address.state
                        )?.[0]
                      }
                      options={latestDistrictList}
                    />
                    {errMsg.state && (
                      <p className="invalid__message">{errMsg.state}</p>
                    )}
                  </div>
                )}
                <div className="mb-3">
                  <label htmlFor="Mobile" className="form-label">
                    <strong>{t("Landmark")}</strong>
                    <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                  </label>
                  <input
                    type="text"
                    placeholder="Newyork"
                    className="form-control"
                    id="landmark"
                    name="landmark"
                    value={address.landmark}
                    onChange={(e) => handleChange(e)}
                  />
                  {errMsg.landmark && (
                    <p className="invalid__message">{errMsg.landmark}</p>
                  )}
                </div>
                {/* <hr className="my-5" />
                <Heading5 text="Delivery Time Preferences" />
                <p>
                  {t("Preferences_are_used_to_plan_your_delivery")}
                </p> */}
                <div className="mb-3">
                  <label htmlFor="Address Type" className="form-label">
                    <strong>{t("Address_Type")}</strong>
                  </label>
                  <input
                    type="radio"
                    className="delivery__input__check"
                    name="addressType"
                    // defaultChecked={true}
                    checked={address.addressType === "1" ? true : false || true}
                    value="1"
                    id="home"
                    onChange={handleChange}
                  />
                  <label className="delivery__selection__text" htmlFor="home">
                    {t("Home")}
                  </label>
                  <input
                    type="radio"
                    className="delivery__input__check"
                    name="addressType"
                    // defaultChecked={false}
                    checked={address.addressType === "2" ? true : false}
                    value="2"
                    id="work"
                    onChange={handleChange}
                  />
                  <label className="delivery__selection__text" htmlFor="work">
                    {t("Work")}
                  </label>
                </div>
                <div className="mb-3">
                  <input
                    type="checkbox"
                    className="preferences__select__input__check"
                    name="primary"
                    value={address.primary}
                    checked={address.primary}
                    onChange={(e) => handleChange(e, "primary")}
                    id="preference_box"
                  />
                  <label className="form-label" htmlFor="preference_box">
                    {t("default_address")}
                  </label>
                </div>
              </div>
              <div className="modal-footer border-top-0 justify-content-center">
                <button
                  type="button"
                  className="btn__primary__orange btn btn-primary w-25 "
                  onClick={() => {
                    document.activeElement.blur();
                    setTimeout(() => {
                      handleSubmit()
                    }, 200)
                  }}
                >
                  {loaderOn === true ? <Spinner /> : t("SAVE")}
                </button>
                <button
                  type="button"
                  className="btn btn-border btn__border__black  w-25"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  onClick={handleClosePopup}
                >
                  {t("CANCEL")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {addressPopup === true && (
        <AddressPopupModal
          show={addressPopup}
          closeLoginPopup={closeLoginPopup}
          editAddressData={editAddressData}
          popupType={addressPopupType}
        />
      )}
    </div> : null}
    </>
  )
}

export default MobileDelivery