import moment from "moment";
import React, { } from "react";
import { Link } from "react-router-dom";
import './../../SCSS/MostSharedComponents/_storePickInfoBox.scss';

function StorePickUp({ t, selectedCity, setStoreCityModal }) {
    return (
        <>
            <div className="col-md-12">
                <div className="row store__pickup__taken__area">
                    <div className="col-6 col-md-6 delivery__to__box">
                        <label>{t("Store Pickup in")}:</label>
                        <span>{selectedCity?.city}</span>
                        <Link to="#" className="delivery__to__change__btn"
                            onClick={() => {
                                setStoreCityModal(true);
                            }}
                        >{t("Change City")}</Link>
                    </div>
                </div>
            </div>

        </>
    );
}
export default StorePickUp;