import { dataLayerPush } from "../dataLayerPush";

export const addToCartHandler = (productData, cartId, screenName, event = 'add_to_cart') => {
  let price = Number(parseFloat(productData.discounted_price_rounded || productData.price_rounded).toFixed(2));
  const categories = productData?.categories || [];
  const category = [...categories].pop()?.name || 'Sony';
  const variant = productData?.variant?.name || '';
  const quantity = productData?.qty || 1;

  if (productData?.totalPrice) price = Number(parseFloat(productData?.totalPrice).toFixed(2));

  let discount = 0;
  const discountedPrice = Number(parseFloat(productData.discounted_price_rounded).toFixed(2));
  if (discountedPrice < productData?.price_rounded) discount = price - discountedPrice;

  let data = {
    currency: productData?.currency || 'SAR',
    value: Number(parseFloat(price).toFixed(2)),
    items: [{
      item_name: productData?.name,
      item_id: productData?.id,
      price: Number(parseFloat(price).toFixed(2)),
      brand: productData?.brand,
      category,
      discount: Number(parseFloat(discount).toFixed(2)),
      variant,
      quantity,
      dimension1: productData?.sku,
      dimension2: cartId,
    }],
  };
  dataLayerPush({ event, ecommerce: data, screen_name: screenName || "Home", _clear: true });
};

export const removeToCartHandler = (productData, cartId, screenName, event) => {
  let price = Number(parseFloat(productData.discounted_price_rounded || productData.price_rounded).toFixed(2));
  const categories = productData?.categories || [];
  const category = [...categories].pop()?.name || 'Sony';
  const variant = productData?.variant?.name || '';
  const quantity = productData?.qty || 1;

  if (productData?.totalPrice) price = Number(parseFloat(productData?.totalPrice).toFixed(2));

  let discount = 0;
  const discountedPrice = Number(parseFloat(productData.discounted_price_rounded).toFixed(2));
  if (discountedPrice < productData?.price_rounded) discount = price - discountedPrice;

  let data = {
    currency: productData?.currency || 'SAR',
    value: Number(parseFloat(price).toFixed(2)),
    items: [{
      item_name: productData?.name,
      item_id: productData?.id,
      price: Number(parseFloat(price).toFixed(2)),
      brand: productData?.brand,
      category,
      discount: Number(parseFloat(discount).toFixed(2)),
      variant,
      quantity,
      dimension1: productData?.sku,
      dimension2: cartId,
    }],
};

dataLayerPush({ event, ecommerce: data, screen_name: screenName || "Home", _clear: true });
};