import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./../SCSS/ProductComparison/_productComparison.scss";
import PLPBannerAndCategorySection from "../Components/ProductListPageComponent/PLPBannerAndCategorySection";
import ProductComparisonList from "../Components/ProductComparison/ProductComparisonList";
import { updateCompareProductList } from "../services/cart.service";
import ReviewTestFreaks from "./ReviewTestFreaks";
import { configurationSetting, loadCitiesLocationData, loadConfigurationSettingData, loadEnableStoresLocationData } from "../redux/appAction";
import { getProductDetail } from "../services/pdp.service";
import _uniqBy from "lodash.uniqby";
import { loaderEnd, loaderStart } from "../services/services";
import Skeleton from "react-loading-skeleton";
import useWindowDimensions from "../Components/helpers/utils/useWindowDimensions";

const Product_Comparison = ({ handleChangeCartPopup }) => {

  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const [pageRender, setPageRender] = useState(false);
  const [loading, setLoading] = useState(true);
  const [outOfStockConfiguration, setOutOfStockConfiguration] = useState({});
  const [latestData,setLatestData] = useState([])
  const [keySpecList, setKeySpecList] = useState([]);
  const [storePickupGlobalConfiguration, setStorePickupGlobalConfiguration] = useState(0);

  const { configurationSettingData } = useSelector((state) => state.appData);


  useEffect(() => {
    dispatch(loadCitiesLocationData());
    dispatch(loadEnableStoresLocationData());
  }, []);

  useEffect(() => {
    const config = async () => {
      try {
        const configuration = await configurationSetting();
        setStorePickupGlobalConfiguration(Number(configuration?.store_pickup) || 0);
      } catch (error) {
        // console.log("error ", error);
      }
    };
    config();
  }, []);

  useEffect(() => {
    dispatch(loadConfigurationSettingData())
  }, []);

  useEffect(async () => {
    if (Object.values(configurationSettingData).length !== 0) {
      const getOutOfStock = configurationSettingData?.out_of_stock_configuration || {};
      setOutOfStockConfiguration(getOutOfStock);
    }
  }, [configurationSettingData]);

  let comparePopupLocalData = JSON.parse(localStorage.getItem("compare_products"));
  if (comparePopupLocalData === null) {
    comparePopupLocalData = [];
  }

  useEffect(() => {
    if (pageRender === true) {
      setPageRender(false);
    }
  }, [pageRender]);

  useEffect(() => {
    async function fetchData() {
      let finalProductData = [];
      if (comparePopupLocalData) {
        for (let index = 0; index < comparePopupLocalData?.length; index++) {
          let skuData = comparePopupLocalData[index]?.sku
          const responseData = await getProductDetail(encodeURIComponent(skuData));
          if (responseData?.response?.status !== 404) { // product not found then remove from list
            finalProductData.push(responseData);
          }
        }
        setLatestData(finalProductData);
        getKeySpecList(finalProductData);
        setLoading(false)
      }
    }
    fetchData()
  }, [])
  

  useEffect(() => {
    if (loading) {
      dispatch(loaderStart())
    } else {
      dispatch(loaderEnd())
    }
  }, [dispatch, loading])

  const getKeySpecList = (finalProductData) => {
    let tempKeyData = [];
    finalProductData &&
      finalProductData.map((pro, index) => {
        pro.options &&
          pro.options
            .filter(
              (item) =>
                item.code !== "is_count_down" &&
                item.code !== "store_level_stock" &&
                item.code !== "url_key" &&
                item.code !== "meza_eligible" &&
                item.code !== "qitaf_eligible" &&
                item.visibility
            )
            .map((item) => {
              let filterKeyObj = {
                id: item?.code,
                key: item?.attribute?.label,
                value: item?.selected_option?.labelArr[0],
              };
              tempKeyData.push(filterKeyObj);
            });
      });
    let uniqKeyData = _uniqBy(tempKeyData, "id");
    setKeySpecList(uniqKeyData);
  }

  const removeAllProductFromCompare = () => {
    localStorage.removeItem("compare_products");
    dispatch(updateCompareProductList([]));
    setPageRender(true);
    setLatestData([])
  };

  const removeProductFromCompare = (productId) => {
    let removeData =
      latestData &&
      latestData.filter((item) => item.id !== productId);
    setLatestData(removeData)
    localStorage.setItem("compare_products", JSON.stringify(removeData));
    dispatch(updateCompareProductList(removeData));
    setPageRender(true);
  };

  if (loading) {
    return <>
      <div className="m-4">
        <Skeleton width={"200px"} height="20px" />
      </div>
      <div className="shopping__cart__page__container">
        <div className="d-flex justify-content-center">
          <Skeleton containerClassName={`${width <= 992 ? "w-50" : "w-25"}`} height="50vh" />
          {width >= 992 && <> <div style={{ marginRight: "15px" }}></div>
            <Skeleton containerClassName="w-25" height="50vh" />
            <div style={{ marginRight: "15px" }}></div>
            <Skeleton containerClassName="w-25" height="50vh" /></>}
        </div>
      </div>
    </>;
  }

  return (
    <>
      {!loading &&
        <ReviewTestFreaks />
      }
      <div className="container-fluid product__comparison__page__container">
        <div className="product__comparison__page__block">
          <PLPBannerAndCategorySection />
          {/* <BreadCrumbs /> */}
          <ProductComparisonList
            productList={latestData}
            removeAllProductFromCompare={removeAllProductFromCompare}
            removeProductFromCompare={removeProductFromCompare}
            handleChangeCartPopup={handleChangeCartPopup}
            outOfStockConfiguration={outOfStockConfiguration}
            keySpecList={keySpecList}
            storePickupGlobalConfiguration={storePickupGlobalConfiguration}
          />
          {/* <div>Product_Comparison</div> */}
        </div>
      </div>
    </>
  );
};

export default Product_Comparison;
