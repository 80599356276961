import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading7 from "../Components/Font/Heading7";
import "./../SCSS/Form/_addressForm.scss";
import {
  emailValidator,
  isMobileNumber,
} from "../Components/helpers/utils/validators";
import Heading3 from "../Components/Font/Heading4";
import Heading4 from "../Components/Font/Heading4";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import {
  updateShippingInformation,
  createBillingAddress,
} from "../services/cart.service";
import Heading6 from "../Components/Font/Heading6";
import Price from "../Components/Font/Price";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { acceptOnlyString } from './../Components/helpers/utils/regexp';
import { useNavigate } from 'react-router';
import Text4 from "../Components/Font/Text4";
import { checkoutEvents } from "../Components/helpers/utils/ga/checkoutEvents";
import { BsSearch } from "react-icons/bs";
import { InputGroup, Form } from 'react-bootstrap';
import { customerDetailsSuccess } from "../services/customer/customer";
import { impressionEvents } from '../Components/helpers/utils/ga/impressionEvents';
import { Spinner } from "../Components/helpers/utils/spinner";

// const errMsgStyle = {
//   color: "red",
//   margin: "5px 0px 0px",
// };

function Guest_Checkout_Page({
  // handleAddressPopup,
  deliveryTypeList,
  pickupStore,
  preferenceTab,
  getDeliveryMethodByCity,
  storesLocationData,
  handleClickOpeningHour,
  shippingCustomMsg,
  setShippingCustomMsg,
  handleSetOrderComment,
  mode,
  setEditAddressData,
  setCustEmailAddress
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cartData = useSelector((state) => state.appData.cartData);

  const T_REQ_FIRST_NAME = t("First_Name_is_required");
  const T_REQ_LAST_NAME = t("Last_Name_is_required");
  const T_REQ_COUNTRY = t("Country_is_required");
  const T_REQ_MOBILE_NUMBER = t("Mobile_Number_is_required");
  const T_REQ_CITY_TOWN = t("City/Town_is_required");
  const T_INVALID_MOBILE_NUMBER = t("Invalid_Mobile_Number");
  const T_REQ_DISTRICT = t("District_is_required");
  const T_REQ_STREET_ADDRESS = t("Street_address_is_required");
  const T_REQ_EMAIL = t("Email_is_missing");
  const T_INVALID_EMAIL = t("Invalid_email_address_example");
  // const T_REQ_DELIVERY_PREFERENCE = t("Select_your_shipping_option");

  const countriesLocationData = useSelector(
    (state) => state.appData.countriesLocationData
  );
  const cityLocationData = useSelector(
    (state) => state.appData.cityLocationData
  );

  const [storeCitiesLocationData, setStoreCitiesLocationData] = useState([]);
  const [storeCountriesLocationData, setStoreCountriesLocationData] = useState(
    []
  );
  const [latestDistrictList, setLatestDistrictList] = useState([]);
  const [latestBillingDistrictList, setLatestBillingDistrictList] = useState(
    []
  );
  const [billingStatus, setBillingStatus] = useState(true);
  const [deliveryType, setDeliveryType] = useState([]);
  const [deliveryPreferencesType, setDeliveryPreferencesType] = useState("flatrate");
  const [deliveryPreferencesErr, setDeliveryPreferencesErr] = useState("");
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [storePickupError, setStorePickupError] = useState("");

  const [address, setAddress] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    city: "",
    state: "",
    country: "",
    street: "",
    region_id: "",
    pickup_store: ""
  });

  const [errMsg, setErrMsg] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    city: "",
    state: "",
    country: "",
    street: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [billingAddress, setBillingAddress] = useState({
    country: "",
    city: "",
    state: "",
    street: "",
  });

  const [billingErrMsg, setBillingErrMsg] = useState({
    country: "",
    city: "",
    state: "",
    street: "",
  });
  // const [storeCityCode, setStoreCityCode] = useState("");

  const [searchData, setSearchData] = useState("");
  const [filteredStores, setFilteredStores] = useState([]);

  useEffect(() => {
    if (storesLocationData) {
      let commonStores, filteredStoresData
      const storesSets = cartData?.items && cartData?.items.map(elem => new Set(elem.extension_attributes.available_stores));
      if (storesSets && storesSets.length > 0) {
        commonStores = [...storesSets.reduce((acc, curr) => new Set([...acc].filter(x => curr.has(x))))];
      }
      if (commonStores) {
        filteredStoresData = storesLocationData && storesLocationData.filter((store) =>
          commonStores.includes(store?.storeCode.toLowerCase())
        );
      }
      const queryValue = searchData.trim();
      let filterdata = filteredStoresData && filteredStoresData.filter((item) => item.name.toLowerCase().includes(queryValue.toLowerCase()));
      setFilteredStores(filterdata)
    }
  }, [cartData?.items, searchData, storesLocationData]);

  useEffect(() => {
    // dispatch(loadCountriesLocationData());
    // dispatch(loadCitiesLocationData());
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (deliveryTypeList) {
      setDeliveryType(deliveryTypeList);
    }
  }, [deliveryTypeList]);

  useEffect(() => {
    if (preferenceTab === "storepickup") {
      setDeliveryPreferencesType("storepickup");
    } else {
      // const deliveryRef = localStorage.getItem('deliveryPref');
      // if(deliveryRef){
      //     setDeliveryPreferencesType(deliveryRef);

      // }else{
      setDeliveryPreferencesType("flatrate");

      // }
      setDeliveryPreferencesErr("");
    }
  }, [preferenceTab]);

  useEffect(() => {
    if (countriesLocationData) {
      let countryList = [];
      countriesLocationData &&
        countriesLocationData.map((val, i) => {
          let countryData = {
            id: val.id,
            value: val.id,
            label: val.full_name_english,
          };
          countryList.push(countryData);
        });
      setStoreCountriesLocationData(countryList);
      setAddress({ ...address, country: countryList?.[0]?.value })
    }
  }, [countriesLocationData]);

  useEffect(() => {
    if (cityLocationData) {
      let cityList = [];
      cityLocationData &&
        cityLocationData.map((val, i) => {
          let cityData = {
            value: val.cityCode,
            label: val.cityCode,
            allData: val,
          };
          cityList.push(cityData);
        });
      setStoreCitiesLocationData(cityList);
    }
  }, [cityLocationData]);

  useEffect(() => {
    let districts = storeCitiesLocationData.filter(
      (val, i) => val.allData.cityCode === address.city
    )?.[0]?.allData?.districts;
    if (districts !== undefined) {
      let newArray = [];
      districts &&
        districts.map((item) => {
          let newObj = { label: item.label, value: item.code };
          newArray.push(newObj);
        });
      setLatestDistrictList(newArray);
    }
  }, [address.city, address.state]);

  useEffect(() => {
    let districts = storeCitiesLocationData.filter(
      (val, i) => val.allData.cityCode === billingAddress.city
    )?.[0]?.allData?.districts;
    if (districts !== undefined) {
      let newArray = [];
      districts &&
        districts.map((item) => {
          let newObj = { label: item.label, value: item.code };
          newArray.push(newObj);
        });
      setLatestBillingDistrictList(newArray);
    }
  }, [billingAddress.city, billingAddress.state, billingStatus]);

  const handleChangeDeliveryPref = (e) => {
    setDeliveryPreferencesType(e.target.value);
    // if (e.target.value === "flatrate") {
    //     resetHomeDeliveryData()
    // }
    // deliveryPreferencesType
    // console.log('dd');
    localStorage.setItem("deliveryPref", e.target.value);
    setDeliveryPreferencesErr("");
    setSelectedStoreId("");
    setStorePickupError("");
  };

  const handleChangePickupStore = (storeId) => {
    setSelectedStoreId(storeId);
  };

  const validateForm = (event, newErrObj, name, value) => {
    //A function to validate each input values
    switch (name) {
      case "firstName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_FIRST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "lastName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_LAST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "mobileNumber":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_MOBILE_NUMBER };
        } else {
          let isValidNumber = isMobileNumber(value);
          if (isValidNumber === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_MOBILE_NUMBER };
          } else {
            newErrObj = { ...newErrObj, [name]: "" };
          }
        }
        break;
      case "email":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_EMAIL };
        } else {
          let isValidEmail = emailValidator(value);
          if (isValidEmail === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_EMAIL };
          } else {
            newErrObj = { ...newErrObj, [name]: "" };
          }
        }
        break;
      case "country":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_COUNTRY };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "city":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_CITY_TOWN, state: "" };
        } else {
          newErrObj = { ...newErrObj, [name]: "", state: "" };
        }
        break;
      case "state":
        if (value === "" && address.city !== "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_DISTRICT };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "street":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_STREET_ADDRESS };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      default:
        break;
    }
    return newErrObj;
  };

  const handleBlur = (e) => {
    let obj = {
      target: {
        name: e.target.name,
        value: e.target.value.trim(),
      }
    }
    handleChange(obj)
  }

  const handleChange = async (event, keyName) => {
    let value = event;
    let name = event;
    if (keyName === "store") {
      setAddress({
        ...address,
        city: event?.city,
        state: "",
        country: event?.country,
        street: event?.address,
        pickup_store: event?.storeCode,
        region_id: '76',
      });
    } else {
      if (keyName === "mobileNumber") {
        value = event.trim();
        name = "mobileNumber";
      } else if (
        keyName === "country" ||
        keyName === "city" ||
        keyName === "state"
      ) {
        value = event.value;
        name = keyName;
        if (keyName === "city") {
          // const deliveryRef = localStorage.getItem('deliveryPref');
          // if(deliveryRef){
          //     setDeliveryPreferencesType(deliveryRef);

          // }else{
          setDeliveryPreferencesType(deliveryType[0].id);

          // }
          setDeliveryPreferencesErr("");
          var radio = document.querySelector(
            "input[type=radio][name=subDeliveryType]:checked"
          );
          if (radio !== null) {
            radio.checked = false;
          }
        }
      } else if (
        event?.target?.name === "firstName" ||
        event?.target?.name === "lastName"
      ) {
        value = acceptOnlyString(event.target.value, true);
        name = event.target.name;
      }
      else {
        value = event.target.value;
        name = event.target.name;
      }
      setAddress({ ...address, [name]: value });
    }
    let manageErrMsg = validateForm(event, errMsg, name, value);
    setErrMsg(manageErrMsg);
  };

  const allFeildValidate = (validateFeild, allErrMsg) => {
    let checkValueStatus = [];
    let checkErrStatus = [];

    validateFeild &&
      validateFeild.map((val, i) => {
        let keyVal = address[val];
        let errVal = errMsg[val];

        allErrMsg = validateForm("", allErrMsg, val, keyVal);
        if (keyVal !== "") {
          checkValueStatus.push("suc");
        }
        if (errVal === "") {
          checkErrStatus.push("err");
        }
      });

    let checkAddressStatus = false;
    if (
      checkValueStatus.length === validateFeild.length &&
      checkErrStatus.length === validateFeild.length
    ) {
      checkAddressStatus = true;
    }

    let returnData = {
      allErrMsg: allErrMsg,
      checkAddressStatus: checkAddressStatus,
    };

    return returnData;
  };

  const handleChangeBilling = async (event, keyName) => {
    let value = event;
    let name = event;
    if (keyName === "country" || keyName === "city" || keyName === "state") {
      value = event.value;
      name = keyName;
    } else {
      value = event.target.value;
      name = event.target.name;
    }
    setBillingAddress({ ...billingAddress, [name]: value });
    let manageErrMsg = validateFormBilling(event, billingErrMsg, name, value);
    setBillingErrMsg(manageErrMsg);
  };

  // billing address
  const validateFormBilling = (event, newErrObj, name, value) => {
    //A function to validate each input values
    switch (name) {
      case "country":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_COUNTRY };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "city":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_CITY_TOWN, state: "" };
        } else {
          newErrObj = { ...newErrObj, [name]: "", state: "" };
        }
        break;
      case "state":
        if (value === "" && billingAddress.city !== "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_DISTRICT };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "street":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_STREET_ADDRESS };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      default:
        break;
    }
    return newErrObj;
  };
  const allFeildValidateBilling = (validateFeild, allErrMsg) => {
    let checkValueStatus = [];
    let checkErrStatus = [];

    if (billingStatus === false) {
      validateFeild &&
        validateFeild.map((val, i) => {
          let keyVal = billingAddress[val];
          let errVal = billingErrMsg[val];

          allErrMsg = validateFormBilling("", allErrMsg, val, keyVal);
          if (keyVal !== "") {
            checkValueStatus.push("suc");
          }
          if (errVal === "") {
            checkErrStatus.push("err");
          }
        });
    }

    let checkBillingStatus = false;
    if (
      checkValueStatus.length === validateFeild.length &&
      checkErrStatus.length === validateFeild.length
    ) {
      checkBillingStatus = true;
    }

    let returnData = {
      allErrMsg: allErrMsg,
      checkBillingStatus: billingStatus === true ? true : checkBillingStatus,
    };

    return returnData;
  };

  const manageDeliveryData = async () => {
    let validateFeild = [
      "firstName",
      "lastName",
      "mobileNumber",
      "email",
      "city",
      // "state",
      "country",
      "street",
    ];

    let getDistrictList = latestDistrictList;
    if (getDistrictList.length > 0) {
      validateFeild.push("state");
    }

    let formStatus = allFeildValidate(validateFeild, errMsg);

    setErrMsg(formStatus.allErrMsg);

    let distDataManage = "";
    if (getDistrictList.length > 0 && address.state !== "") {
      distDataManage = address.state;
    }

    // billing address

    let validateFeildBilling = [
      "country",
      // "state",
      "city",
      "street",
    ];

    let getBillDistrictList = latestBillingDistrictList;
    if (getBillDistrictList.length > 0) {
      validateFeildBilling.push("state");
    }

    let distBillDataManage = "";
    if (getBillDistrictList.length > 0 && billingAddress.state !== "") {
      distBillDataManage = billingAddress.state;
    }

    let billingFormStatus = allFeildValidateBilling(
      validateFeildBilling,
      billingErrMsg
    );

    setBillingErrMsg(billingFormStatus.allErrMsg);
    const data =
      cityLocationData && cityLocationData.find((i) => i.cityCode === address?.city);
    const selectedCityId = data?.id;

    address["adddress"] = `${address?.state} ${address?.city}, ${address?.country}`
    setEditAddressData(address)
    if (
      formStatus.checkAddressStatus === true &&
      billingFormStatus.checkBillingStatus === true &&
      deliveryPreferencesType !== ""
    ) {

        // window.scrollTo(0, 0);
        localStorage.setItem("contact_prefill", JSON.stringify(address));
        dispatch(customerDetailsSuccess(address))
        let params = {
          useAsBilling: billingStatus,
          firstName: address.firstName.trim(),
          lastName: address.lastName.trim(),
          email: address.email.trim(),
          telephone: address.mobileNumber.trim(),
          city: address.city.trim(),
          postCode: distDataManage.trim(),
          countryId: address.country.trim(),
          street: address.street.trim(),
          shippingCarrierCode: deliveryPreferencesType.trim(),
          region_id:
            address.region_id === undefined || address.region_id === ""
              ? 0
              : address.region_id,
        };

        if (billingStatus === false) {
          let paramsBilli = {
            addressLine1: billingAddress.street,
            city: billingAddress.city,
            countryId: billingAddress.country,
            email: address.email,
            firstName: address.firstName,
            lastName: address.lastName,
            postCode: distBillDataManage,
            telephone: address.mobileNumber,
          };

          dispatch(createBillingAddress(paramsBilli));
        }
        setCustEmailAddress(address?.email)
        // shipping custome msg API
        setIsLoading(true);
        await dispatch(updateShippingInformation(params)).then(() => {
          handleSetOrderComment();
        });
        const totalData = { currencyCode: cartData?.totals_data?.base_currency_code || 'SAR', value: Number(parseFloat(cartData?.totals_data?.base_grand_total).toFixed(2)) };
        const checkoutProducts = cartData?.items?.map((item) => ({
          item_name: item?.product?.name,
          item_id: item?.product?.id,
          price: Number(parseFloat(item?.product?.discounted_price_rounded || item?.product.price_rounded).toFixed(2)),
          brand: item?.product?.options?.find((option) => option.code === 'brand')?.selected_option?.label || null,
          category: item.product?.categories?.pop()?.name || 'Sony',
          variant: null,
          quantity: item?.qty,
          dimension1: item?.product.sku,
          coupon: cartData?.totals_data?.coupon_code || null
        }));
        //checkoutEvents.addShippingInfo(cartData?.totals_data?.coupon_code || null, checkoutProducts, totalData);
        impressionEvents.impressionAddShippingInformation(
          cartData,'Checkout Delivery',deliveryPreferencesType);
    }

    let billErr = {}
    if (!billingStatus) {
      Object.entries(billingFormStatus?.allErrMsg ?? {}).forEach(([key, val]) => {
        billErr[`b${key}`] = val
      })
    } else {
      billErr = {}
    }

    return { ...formStatus.allErrMsg, ...billErr }
  };

  const storePickupValidate = async () => {
    let validateFeild = ["firstName", "lastName", "mobileNumber", "email"];

    let formStatus = allFeildValidate(validateFeild, errMsg);
    setErrMsg(formStatus.allErrMsg);

    if (selectedStoreId === "") {
      setStorePickupError(t("Please_selecte_store"));
    } else if (formStatus.checkAddressStatus === true) {
      setStorePickupError("");
      const selectedStore = pickupStore && pickupStore.find((item)=> item?.id === selectedStoreId)
      let params = {
        useAsBilling: true,
        firstName: address.firstName,
        lastName: address.lastName,
        email: address.email,
        telephone: address.mobileNumber,
        city: selectedStore?.city,
        postCode: "",
        countryId: selectedStore?.country,
        street: selectedStore?.address,
        shippingCarrierCode: deliveryPreferencesType,
        pickup_store: selectedStore?.storeCode,
        region_id: '76',
      };
      localStorage.setItem("contact_prefill", JSON.stringify(address));
      localStorage.setItem('pickup_store', selectedStore?.storeCode)
      dispatch(customerDetailsSuccess(address))
      setCustEmailAddress(address?.email)
      await dispatch(updateShippingInformation(params)).then(() => {
        handleSetOrderComment();
      });
      // window.scrollTo(0, 0);
    }

    return { ...formStatus?.allErrMsg }
  };

  const handleSubmit = async () => {    
    if (deliveryPreferencesType === "") {
      setDeliveryPreferencesErr(t("Select_your_shipping_option"));
      window.scrollTo(0, 0);
    }
    let errObj = {}    
    
    if (deliveryPreferencesType === "flatrate") {
      errObj = await manageDeliveryData();
    } else if (deliveryPreferencesType === "storepickup") {
      errObj = await storePickupValidate();
    } else {
      errObj = await manageDeliveryData();
    }
    localStorage.setItem("shippingAddress", JSON.stringify(address));
    localStorage.setItem("billingAddress", JSON.stringify(billingAddress));

    const errorArr = [...new Set(Object.values(errObj))]

    if (errorArr.length < 2 && errorArr[0] === "") {
      if (deliveryPreferencesType === "storepickup") {
        if (selectedStoreId !== "") {
          navigate("/checkout/payment")
        }
      } else {
        navigate("/checkout/payment")
      }
    }
  };

  const resetBillingForm = () => {
    setBillingAddress({
      ...billingAddress,
      country: "",
      city: "",
      state: "",
      street: "",
    });
    setBillingErrMsg({
      ...billingErrMsg,
      country: "",
      city: "",
      state: "",
      street: "",
    });
  };

  useEffect(() => {
    localStorage.setItem("shippingAsBilling", billingStatus);
  }, [billingStatus])

  const handleChangeBillingStatus = (event) => {
    setBillingStatus(event.target.checked);
    localStorage.setItem("shippingAsBilling", event.target.checked);

    if (event.target.checked) {
      setDeliveryPreferencesErr("");
    } else {
      resetBillingForm();
    }
  };
  useEffect(() => {
    // const deliveryRef = localStorage.getItem('deliveryPref');
    // if(deliveryRef){
    //   setDeliveryPreferencesType(deliveryRef);
    //   setDeliveryPreferencesErr('');
    //   setSelectedStoreId('');
    //   setStorePickupError("");
    // }
    if (localStorage.getItem("shippingAsBilling")) {
      setBillingStatus(
        localStorage.getItem("shippingAsBilling") === "true" ? true : false
      );
    }
    let billingAd = localStorage.getItem("billingAddress");
    if (billingAd && localStorage.getItem("shippingAsBilling") === "false") {
      billingAd = JSON.parse(billingAd);
      setBillingAddress(billingAd);
    }

    let shippingAd = localStorage.getItem("shippingAddress");
    if (shippingAd) {
      shippingAd = JSON.parse(shippingAd);
      setAddress(shippingAd);
    }
    // setAddress({
    //     firstName: "muddasar",
    //     lastName: "",
    //     mobileNumber: "",
    //     email: "",
    //     city: "",
    //     state: "",
    //     country: "",
    //     street: ""
    // });
    // setBillingAddress
  }, []);

  function Position(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return currenttop > 200 ? [currenttop - 200] : [0];
    }
  }

  useEffect(() => {
    const errorElement = document.querySelector(".invalid__message");
    if (document.activeElement.tagName !== "INPUT" && errorElement) {
      window.scrollTo(0, Position(errorElement));
    }
  }, [errMsg, billingErrMsg, storePickupError])

  return (
    <>
      {preferenceTab !== "" && (
        <div className="newAddress__block guest__checkout">
          <div className="newAddress__title__block">
            <Heading3 text={t("Personal_Information")} color="#000000" />
          </div>
          <div className="row newAddress__form__field__row">
            <div className="col-sm-12 col-md-6 main__form__field__block">
              {/* <p className="form__label">First Name</p> */}
              <Heading7 text={t("First_Name")} marginBottom={10} />
              <div className="field__block">
                <input
                  type="text"
                  placeholder={t("First_Name")}
                  className="form__field"
                  id="name"
                  name="firstName"
                  value={address.firstName}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                />
              </div>
              {errMsg.firstName && (
                <p className="invalid__message">{errMsg.firstName}</p>
              )}
            </div>
            <div className="col-sm-12 col-md-6 main__form__field__block">
              {/* <p className="form__label">Mobile Number</p> */}
              <Heading7 text={t("Last_Name")} marginBottom={10} />
              <div className="field__block">
                <input
                  type="text"
                  placeholder={t("Last_Name")}
                  className="form__field"
                  id="lastName"
                  name="lastName"
                  value={address.lastName}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                />
              </div>
              {errMsg.lastName && (
                <p className="invalid__message">{errMsg.lastName}</p>
              )}
            </div>
          </div>
          <div className="row newAddress__form__field__row">
            <div className="col-sm-12 col-md-6 main__form__field__block">
              <Heading7 text={t("Mobile_Number")} marginBottom={10} />
              <PhoneInput
                inputProps={{
                  name: "mobileNumber",
                  required: true,
                  className: "profile__mobile__form__field",
                }}
                country="sa"
                onlyCountries={["sa"]}
                masks={{ sa: ".. ... ...." }}
                countryCodeEditable={false}
                disableDropdown={true}
                value={address.mobileNumber}
                onChange={(e) => handleChange(e, "mobileNumber")}
                className=""
              />
              {errMsg.mobileNumber && (
                <p className="invalid__message">{errMsg.mobileNumber}</p>
              )}
            </div>
            <div className="col-sm-12 col-md-6 main__form__field__block">
              <Heading7
                text={t("Order_confirmation_email")}
                marginBottom={10}
              />
              <div className="field__block">
                <input
                  type="text"
                  placeholder={t("Order_confirmation_email")}
                  className="form__field"
                  id="email"
                  name="email"
                  value={address.email}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                />
              </div>
              {errMsg.email && (
                <p className="invalid__message">{errMsg.email}</p>
              )}
            </div>
          </div>

          {preferenceTab === "home" && (
            <>
              <div className="newAddress__title__block">
                <Heading3 text={t("Delivery_Address")} color="#000000" />
              </div>
              <div className="row newAddress__form__field__row">
                <div className="col-sm-12 col-md-6 main__form__field__block">
                  <Heading7 text={t("Country")} marginBottom={10} />
                  <Select
                    name="country"
                    onChange={(e) => handleChange(e, "country")}
                    value={
                      storeCountriesLocationData &&
                      storeCountriesLocationData.filter(
                        (val) => val.value === address.country
                      )?.[0]
                    }
                    options={storeCountriesLocationData}
                  />
                  {errMsg.country && (
                    <p className="invalid__message">{errMsg.country}</p>
                  )}
                </div>
              </div>
              <div className="row newAddress__form__field__row">
                <div className="col-sm-12 col-md-6 main__form__field__block">
                  <Heading7 text={t("City/Town")} marginBottom={10} />
                  <Select
                    name="city"
                    onChange={(e) => handleChange(e, "city")}
                    value={
                      storeCitiesLocationData &&
                      storeCitiesLocationData.filter(
                        (val) => val.value === address.city
                      )?.[0]
                    }
                    options={storeCitiesLocationData}
                  />
                  {errMsg.city && (
                    <p className="invalid__message">{errMsg.city}</p>
                  )}
                </div>
              </div>
              {latestDistrictList && latestDistrictList.length > 0 && (
                <div className="row newAddress__form__field__row">
                  <div className="col-sm-12 col-md-6 main__form__field__block">
                    <Heading7 text={t("District")} marginBottom={10} />
                    <Select
                      name="state"
                      onChange={(e) => handleChange(e, "state")}
                      value={
                        latestDistrictList.filter(
                          (val) => val.value === address.state
                        )?.[0]
                      }
                      options={latestDistrictList}
                    />
                    {errMsg.state && (
                      <p className="invalid__message">{errMsg.state}</p>
                    )}
                  </div>
                </div>
              )}

              <div className="row newAddress__form__field__row">
                <div className="col-sm-12 col-md-6 main__form__field__block">
                  <Heading7 text={t("Street_address")} marginBottom={10} />
                  <div className="field__block">
                    <input
                      type="text"
                      placeholder={t("Street_address")}
                      className="form__field"
                      id="street"
                      name="street"
                      value={address.street}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errMsg.street && (
                    <p className="invalid__message">{errMsg.street}</p>
                  )}
                </div>
              </div>

              <div className="preferences__select__block">
                <input
                  type="checkbox"
                  className="preferences__select__input__check"
                  name="primary"
                  value={billingStatus}
                  checked={billingStatus}
                  onChange={(e) => handleChangeBillingStatus(e)}
                />
                <p className="preferences__select__text">
                  {t("My_billing_address_same_as_delivery_address")}
                </p>
              </div>
              {billingStatus === false && (
                <>
                  <div className="newAddress__title__block">
                    <Heading3 text={t("Billing_Address")} color="#000000" />
                  </div>
                  <div className="row newAddress__form__field__row">
                    <div className="col-sm-12 col-md-6 main__form__field__block">
                      <Heading7 text={t("Country")} marginBottom={10} />
                      <Select
                        name="country"
                        onChange={(e) => handleChangeBilling(e, "country")}
                        value={
                          storeCountriesLocationData &&
                          storeCountriesLocationData.filter(
                            (val) => val.value === billingAddress.country
                          )?.[0]
                        }
                        options={storeCountriesLocationData}
                      />
                      {billingErrMsg.country && (
                        <p className="invalid__message">
                          {billingErrMsg.country}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row newAddress__form__field__row">
                    <div className="col-sm-12 col-md-6 main__form__field__block">
                      <Heading7 text={t("City/Town")} marginBottom={10} />
                      <Select
                        name="city"
                        onChange={(e) => handleChangeBilling(e, "city")}
                        value={
                          storeCitiesLocationData &&
                          storeCitiesLocationData.filter(
                            (val) => val.value === billingAddress.city
                          )?.[0]
                        }
                        options={storeCitiesLocationData}
                      />
                      {billingErrMsg.city && (
                        <p className="invalid__message">{billingErrMsg.city}</p>
                      )}
                    </div>
                  </div>
                  {latestBillingDistrictList &&
                    latestBillingDistrictList.length > 0 && (
                      <div className="row newAddress__form__field__row">
                        <div className="col-sm-12 col-md-6 main__form__field__block">
                          <Heading7 text={t("District")} marginBottom={10} />
                          <Select
                            name="state"
                            onChange={(e) => handleChangeBilling(e, "state")}
                            value={
                              latestBillingDistrictList.filter(
                                (val) => val.value === billingAddress.state
                              )?.[0]
                            }
                            options={latestBillingDistrictList}
                          />
                          {billingErrMsg.state && (
                            <p className="invalid__message">
                              {billingErrMsg.state}
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                  <div className="row newAddress__form__field__row">
                    <div className="col-sm-12 col-md-6 main__form__field__block">
                      <Heading7 text={t("Street_address")} marginBottom={10} />
                      <div className="field__block">
                        <input
                          type="text"
                          placeholder={t("Street_address")}
                          className="form__field"
                          id="street"
                          name="street"
                          value={billingAddress.street}
                          onChange={(e) => handleChangeBilling(e)}
                        />
                      </div>
                      {billingErrMsg.street && (
                        <p className="invalid__message">
                          {billingErrMsg.street}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="row delivery__selcetion__pickup__store">
                <div className="col-12 col-sm-12 col-md-12 delivery__preferences__block">
                  <div className="delivery__preferences__title__block">
                    <Heading6
                      text={t(
                        "SELECT_HOW_FAST_YOU_WANT_TO_RECEIVE_YOUR_SHIPMENT"
                      )}
                    />
                  </div>

                </div>
                <div className="delivery__selection__block col-md-6">
                  {deliveryType &&
                    deliveryType
                      .filter((val) => val.id !== "storepickup")
                      .map((delivery, deliveryIndex) => {
                        let cityName = address.city;
                        let shippingIsEnable = true;
                        if (delivery.id === "lighting") {
                          shippingIsEnable = getDeliveryMethodByCity(
                            cityName,
                            "lighting"
                          );
                        } else if (delivery.id === "express") {
                          shippingIsEnable = getDeliveryMethodByCity(
                            cityName,
                            "express"
                          );
                        } else if (delivery.id === "storepickup") {
                          shippingIsEnable = false;
                        }
                        return (
                          <React.Fragment key={delivery.id}>
                            {shippingIsEnable === true && (
                              <div
                                key={delivery.id}
                                className="delivery__selection d-flex justify-content-between"
                              >
                                <div className="delivery__selection__form__block">
                                  <input
                                    type="radio"
                                    className="delivery__input__check"
                                    name="subDeliveryType"
                                    value={delivery.id}
                                    onChange={(e) =>
                                      handleChangeDeliveryPref(e)
                                    }
                                    checked={
                                      deliveryPreferencesType == delivery.id
                                        ? true
                                        : false
                                    }
                                    id={`deliveryType_radio_${mode}_${delivery?.id}`}
                                  />
                                  <p className="delivery__selection__text" >
                                    <label htmlFor={`deliveryType_radio_${mode}_${delivery?.id}`}><Heading6 text={delivery.type} /></label>
                                    <Text4
                                      text={delivery.protectionText}
                                      color="#3b3b3b"
                                      marginBottom={0}
                                    />
                                  </p>
                                </div>
                                <div className="delivery__price__block">
                                  <p className="delivery__price">
                                    {delivery.price <= 0 ? (
                                      <Heading6
                                        text={t("Free")}
                                        color="#FF4F04"
                                      />
                                    ) : (
                                      <Price
                                        price={delivery?.price}
                                        size="heading6"
                                        currency={"SAR"}
                                      />
                                    )}
                                  </p>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                  {deliveryPreferencesErr !== "" && (
                    <p className="invalid_err_message">
                      {deliveryPreferencesErr}
                    </p>
                  )}
                </div>
                <div className="delivery__selection__block col-md-6 delivery__order_note">
                  <Heading7 text={t("Order Note")} marginBottom={10} />
                  <textarea
                    rows={4}
                    className="form-control"
                    id="shippingCustomMsg"
                    name="shippingCustomMsg"
                    value={shippingCustomMsg}
                    onChange={(e) => setShippingCustomMsg(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}

          {preferenceTab === "storepickup" && (
            <>
              <div className="store__pickup__mainarea">
                <div className="store__pickup__heading">
                  <Heading4 text={t("Available_showrooms")} color="#424b5a" />
                  <InputGroup className="search__store__availablebox">
                    <Form.Control
                      placeholder={t("Search city or store")}
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={(e) => setSearchData(e.target.value)}
                      value={searchData}
                    />
                    <Button variant="outline-secondary" className="store__opening__hours__btn " id="button-addon2">
                      <BsSearch />
                    </Button>
                  </InputGroup>
                </div>

                {filteredStores && filteredStores.length > 0 && <div className="store__pickup__options__box">
                  {
                    filteredStores &&
                    filteredStores.map((val, i) => {
                      return (
                        <div key={`filtered_store_${i}`} className="store__pickup__itembox">
                            <div className="store__pickup__select__area">
                              <input
                                type="radio"
                                checked={selectedStoreId === val?.id ? true : false}
                                onClick={() => handleChangePickupStore(val?.id)}
                                onChange={(e) => handleChange(val, "store")}
                              />
                            </div>
                            <div className="pickup__store__infobox">
                              <p className="product__store__status">
                                <strong>{val.name}</strong>
                              </p>
                              <div className="pickup__store__location">
                                <span><b>{t("Address")} : </b>{`${val?.city} - ${val?.address}`}</span>
                                {val?.phoneNumber &&
                                  <p className="mb-0"><b>{t("Phone")} : </b><a className="text-decoration-none" href={`tel:${val?.phoneNumber}`} target="_" rel="noopener nofollow noreferrer" dir="ltr">{`${val?.phoneNumber}`}</a></p>
                                }
                              </div>
                              <Button
                                onClick={() => handleClickOpeningHour(val)}
                                variant="danger"
                                className="store__opening__hours__btn"
                                style={{ backgroundColor: "#dc3a1a" }}
                              >
                                {t("Opening_hours")}
                              </Button>
                            </div>
                          </div>
                      );
                    })}
                </div>
                }
                {storePickupError !== "" && (
                  <p className="invalid__message">{storePickupError}</p>
                )}
                <div className="delivery__selection__block col-md-6 delivery__order_note">
                  <Heading7 text={t("Order Note")} marginBottom={10} />
                  <textarea
                    rows={4}
                    className="form-control"
                    id="shippingCustomMsg"
                    name="shippingCustomMsg"
                    value={shippingCustomMsg}
                    onChange={(e) => setShippingCustomMsg(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}

          {/* {deliveryPreferencesType === "storepickup" &&
                        <>
                            <PickupStoreCheckout
                                title="Pick Up From Store"
                                pickupStore={pickupStore}
                                selectedStoreId={selectedStoreId}
                                handleChangePickupStore={handleChangePickupStore}
                            />
                            {storePickupError !== "" &&
                                <p className="invalid__message">
                                    {storePickupError}
                                </p>
                            }
                        </>
                    } */}
          <div className="newAddress__form__button__block">
            <button className="form__save__button"
              disabled={isLoading}
              onClick={() => {
                document.activeElement.blur();
                setTimeout(() => {
                  handleSubmit()
                }, 200)
              }} id="guest_payment">
              { isLoading ? <Spinner/> : t("Next")}
            </button>
            <button className="form__cancel__button" onClick={() => navigate("/cart")} >{t("CANCEL")}</button>
          </div>
        </div>
      )}
    </>
  );
}

export default Guest_Checkout_Page;