import React, { useEffect, useMemo, useState, lazy } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "./../SCSS/_productDetailsPage.scss";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import * as services from "./../services/services";
import BreadCrumbs from "../Components/BreadCrumbs";
import ProductCarousel from "../Components/ProductCarousel";
// import sony_logo from "./../assets/Icon/sony_logo.svg";
import empty_favourite from "./../assets/Icon/hart-outline.png";
import fulfill_favourite from "./../assets/Icon/hart-red.png";
// import unlock from "./../assets/Icon/unlock.svg";
// import grey_right_arrow from "./../assets/Icon/grey_right_arrow.svg";
import return_period from "./../assets/Icon/return_period.svg";
import { Link } from "react-router-dom";
import Heading3 from "../Components/Font/Heading3";
import Heading7 from "../Components/Font/Heading7";
import AvailableOffers from "../Components/MostSharedComponent/AvailableOffers";
import Heading5 from "../Components/Font/Heading5";
import PriceBlock from "../Components/MostSharedComponent/PriceBlock";
import Heading6 from "../Components/Font/Heading6";
// import mezzaimg from "./../assets/Icon/mezzareward.png";
// import coin from "./../assets/Icon/coin.svg";
import mezzacard from "./../assets/Icon/mezzacard.png";
import PdpMetaComponent from "../Components/Pdp/PdpMetaComponent";

import { addToCart, deleteFromCart, getCartData, updateCompareProductList } from "../services/cart.service";
import {
  configurationSetting,
  loadAddToWishlist,
  loadCartData,
  loadCitiesLocationData,
  loadConfigurationSettingData,
  loadDeleteFromWishlist,
  loadEnableStoresLocationData,
  loadProductDetailData,
  loadStoresLocationData,
  loadWishlistData,
} from "../redux/appAction";
// import MobileProductDetailPage from "./MobilePages/Mobile_Product_Detail_Page";

import htmlParseHelper from "../Components/helpers/utils/htmlParseHelper";
import ProductCategoryLevel from "../Components/Products/ProductCategoryLevel";
import { selectedCompareProductStatus } from "../Components/helpers/utils/compareProductsFilter";
import { useTranslation } from "react-i18next";
import ProductVariantOptions from "../Components/MostSharedComponent/ProductVariantOptions";
import apiHelper from "../Components/helpers/utils/apiHelper";
import PickupStorePDP from "../Components/MostSharedComponent/PickupStorePDP";
import { generateCategoryUrl } from "../Components/helpers/utils/generateCategoryUrl";
// import { allNumeric } from "../Components/helpers/utils/validators";
import { getTamaraMinMaxLimit } from "../Components/helpers/utils/handsakeConfig";
import { productUrlHelper } from "../Components/helpers/utils/productUrlHelper";
import { replaceImageHelper } from "../Components/helpers/utils/replaceImageHelper";
import { checkWishList } from "../Components/helpers/utils/wishListHelper";
// import CmsContentModal from "../Components/MostSharedComponent/CmsContentModal";
import { cmsPageContentApiSuccess } from "../services/common/common";
import useWindowDimensions from "./../Components/helpers/utils/useWindowDimensions";
import { titleChange } from "../Components/helpers/utils/titleNameChange";
import CountdownBox from "../Components/MostSharedComponent/CountdownBox";
import AddToCartPDP from "../Components/Buttons/AddToCartPDP";
import { SmallImageResize } from "../Components/helpers/utils/imageResize";
import MizaAndQitafPDP from "../Components/Pdp/MizaAndQitafPDP";
import facebook from "./../assets/Icon/facebook.png";
import twitter from "./../assets/Icon/twitter.png";
import messanger from "./../assets/Icon/messanger.png";
import whatsapp from "./../assets/Icon/whatsapp.png";
import pinterest from "./../assets/Icon/pinterest.png";
import Text3 from "../Components/Font/Text3";
import BuyNow from "../Components/Buttons/BuyNow";
import ShareLinkPDP from "../Components/Pdp/ShareLinkPDP";
import { FACEBOOK_SOCIAL_URL, MESSENGER_SOCIAL_URL, PINTEREST_SOCIAL_URL, TWITTER_SOCIAL_URL, WHATSAPP_SOCIAL_URL } from "../globalConst";
import { cartEvents } from "../Components/helpers/utils/ga/cartEvents";
import { checkoutEvents } from "../Components/helpers/utils/ga/checkoutEvents";
import { pdpEvents } from "../Components/helpers/utils/ga/pdpEvents";
import BundleView from "../Components/BundleView";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import RelatedProduct from "../Components/MostSharedComponent/RelatedProduct";
import BundleOffer from "../Components/BundelOffer";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import useCartErrorMessage from "../Components/helpers/hooks/useCartErrorMessage";
import TealiumTag from "../Components/helpers/utils/tealium";
import PdpAccordian from "../Components/Pdp/PdpAccordian";
import MainStorePickup from "../Components/Pdp/MainStorePickup";
import MainStoreDelivery from "../Components/Pdp/MainStoreDelivery";
// import PdpAccordian from "../Components/Pdp/PdpAccordian";
import SoldOutProductLabel from "../Components/MostSharedComponent/SoldOutProductLabel";
// import ApplePay from "../Components/ApplePay/ApplePay";
import { isApplePaySupported } from "../Components/helpers/utils/applePay";
// import VisaHolderNote from "../Components/ApplePay/VisaHolderNote";
import { impressionEvents } from "../Components/helpers/utils/ga/impressionEvents";
import MoreServices from "../Components/Pdp/MoreServices";
import SubscribeSection from "../Components/Meza/SubscribeSection";
import SubsSectionContent from "../Components/Meza/SubsSectionContent";

const MobileProductDetailPage = lazy(() => import("./MobilePages/Mobile_Product_Detail_Page"));
const CmsContentModal = lazy(() => import("../Components/MostSharedComponent/CmsContentModal"));

const PRODUCT_COUNT_ARRAY = [1, 2, 3, 4, 5];

function Product_Details_Page({ handleChangeCartPopup, updateCompareProduct, closeComparePopup, setRefreshcomparePopup }) {

  const { t } = useTranslation();

  const { setCartErr } = useCartErrorMessage();

  const { customerDetails } = useSelector((state) => state.customerReducer);
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  // const [checkingReviews, setCheckingReviews] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [isCartData, setIsCartData] = useState(false);
  const [relatedProductList, setRelatedProductList] = useState([]);
  const [cityModal, setCityModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectCity, setSelectCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [storeCodes, setStoreCodes] = useState(null);
  const [variant, setVariant] = useState(null);
  const [loaderOnTwo, setLoaderOnTwo] = useState(false);
  const [isMore, setIsMore] = useState(false);
  const [isPriceHide, setISPriceHide] = useState(false);
  const [tabbyGlobalSetting, setTabbyGlobalSetting] = useState(0);
  const [tamaraGlobalSetting, setTamaraGlobalSetting] = useState(0);
  const [storePickupGlobalConfiguration, setStorePickupGlobalConfiguration] = useState(0);
  const [warrantyProduct, setWarrantyProduct] = useState([]);
  const [isExWarrantyAvailable, setIsExWarrantyAvailable] = useState(false);
  // const [isPriceHide, setISPriceHide] = useState(false);
  // const [tabbyGlobalSetting, setTabbyGlobalSetting] = useState(0);
  // const [tamaraGlobalSetting, setTamaraGlobalSetting] = useState(0);
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(null);
  const [buildProduct, setBuildProduct] = useState([])
  const [warranty, setWarranty] = useState({})
  const [firstImage, setFirstImage] = useState();

  const cityLocationData = useSelector(
    (state) => state.appData.cityLocationData
  );
  const navigate = useNavigate();
  const { id } = useParams();
  const memoId = useMemo(() => id, [id]);
  let arr = memoId.split("-p-");
  let urlSKU = arr[arr.length - 1];
  
  if (urlSKU.includes('-v-')) {
    urlSKU = urlSKU?.replace('-v-', '/');
  }

  const urlProductName = arr[0];
  const memoUrlSKU = useMemo(() => urlSKU, [urlSKU]);
  const dispatch = useDispatch();

  const productData = useSelector((state) => state.appData.productData);

  const productOutOfStockStatus =
    product && product?.productOutOfStockStatus
      ? product?.productOutOfStockStatus
      : false;

  useEffect(() => {
    setCities(cityLocationData);
  }, [cityLocationData]);
  useEffect(() => {
    if (product?.id) {
      // pdpEvents?.viewItem(product);
      // pdpEvents?.viewBundleProduct(product &&
      //   product?.relatedProducts &&
      //   product?.relatedProducts[0] &&
      //   product?.relatedProducts[0]?.products)
    }
  }, [product?.id])
  useEffect(() => {
    dispatch(loadConfigurationSettingData())
  }, []);

  // useEffect(() => {
  //   if (product?.installmentMethod) {
  //     setTamaraEligible(product?.installmentMethod?.eligible_for_tamara);
  //     setTabbyEligible(product?.installmentMethod?.eligible_for_tabby);

  //     setStoreCodes(
  //       product.inventory_qty
  //         ?.map((item) => Object.keys(item)[0])
  //         .filter(
  //           (code) => code !== 'RS97' && code !== 'RW10' && code !== 'RW20',
  //         ),
  //     );

  //     // impressionEvents?.impressionDetail(data, lastClickedScreenName, variant);
  //     product.labels?.pdp?.filter((item) => item.position === 'left_top').length
  //       > 0 && setHasLabel(false);
  //   } else if (product?.inventory_qty?.length < 1) {
  //     // dispatch({ type: 'SELECT_STORE', payload: null });
  //   }
  // }, [product, variant]);
  useEffect(() => {
    if (product?.id) {
      pdpEvents?.viewItem(product);
      pdpEvents?.viewBundleProduct(product &&
        product?.relatedProducts &&
        product?.relatedProducts[0] &&
        product?.relatedProducts[0]?.products)
    }
  }, [product?.id])

  useEffect(() => {
    if (product?.installmentMethod) {
      setStoreCodes(
        product.inventory_qty
          ?.map((item) => Object.keys(item)[0])
          .filter(
            (code) => code !== 'RS97' && code !== 'RW10' && code !== 'RW20',
          ),
      );
      // impressionEvents?.impressionDetail(data, lastClickedScreenName, variant);
      // product.labels?.pdp?.filter((item) => item.position === 'left_top').length
      //   > 0 && setHasLabel(false);
    }
    // else if (product.inventory_qty.length < 1) {
    //   dispatch({ type: 'SELECT_STORE', payload: null });
    // }
  }, [product, variant]);

  useEffect(() => {
    setFinalData(
      product?.description && product?.description.split(`<div id="specs">`)
    );
  }, [product?.description]);

  useEffect(() => {
    const checkApplePay = async () => {
      setIsApplePayAvailable(await isApplePaySupported());
    };
    checkApplePay();
  }, []);

  useEffect(async () => {
    if (
      product &&
      product?.relatedProducts &&
      product?.relatedProducts[0] &&
      product?.relatedProducts[0]?.products &&
      product?.hasOwnProperty("is_price_hide")
    ) {
      setRelatedProductList(product?.relatedProducts[0]?.products);
    }
  }, [product]);

  useEffect(() => {
    if (product && Object.keys(product).length !== 0) {
      if ("is_price_hide" in product === false) {
        const is_price_hide = product?.options?.find((x) => {
          if (x.code === "is_price_hide") {
            return x.visibility;
          }
          return false;
        });
        setProduct({
          ...product,
          is_price_hide: is_price_hide,
        });
      }
    }
    //  console.log('productproduct', product.user_bundle_products);
  }, [product]);

  const productReviewData = {
    title: t("Reviews"),
    description: "",
    keyValueTitle: "",
    keyValueData: [],
  };

  const { cmsPageContent } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    dispatch(loadCitiesLocationData());
    dispatch(loadEnableStoresLocationData());
  }, []);

  const [productSpecificationData, setProductSpecificationData] = useState("");
  const [productOverviewData, setProductOverviewData] = useState("");
  const [sizeButtonIndex, setSizeButtonIndex] = useState(null);
  const [pickStoreList, setPickStoreList] = useState([]);
  const [breadCrumbsData, setBreadCrumbsData] = useState({
    firstBread: "",
    firstBreadTitle: "",
  });

  const [outOfStockConfiguration, setOutOfStockConfiguration] = useState({});

  useEffect(() => {
    const config = async () => {
      try {
        const configuration = await configurationSetting();
        const getOutOfStock = configuration?.out_of_stock_configuration || {};
        setOutOfStockConfiguration(getOutOfStock);
        setStorePickupGlobalConfiguration(Number(configuration?.store_pickup) || 0);
      } catch (error) {
      }
    };
    config();
  }, []);

  const getOptionKeyInfo = (options, keyName) => {
    let checkKeyData =
      options && options?.filter((item) => item.code === keyName);
    let keyData = "";
    if (checkKeyData?.length > 0) {
      keyData = checkKeyData[0]?.selected_option?.labelArr[0];
    }
    return keyData;
  };
  
  const callApi = () => {
    if (memoUrlSKU === undefined) {
      navigate("/");
    } else {
      setLoading(true);
      dispatch(services.loaderStart());
      dispatch(services.getCmsPageContentApi("returns-and-exchange-9696"));
      dispatch(loadProductDetailData(memoUrlSKU?.replace(/[/]/g, "%2F"))).then(
        () => {
          dispatch(services.loaderEnd());
        }
      );
    }
    //cart api call reduce
    // dispatch(loadCartData());
  };
  useEffect(() => {
    if (memoUrlSKU) {
      callApi();
    }
  }, [memoUrlSKU]);

  const { loading: productLoading } = useSelector(
    (state) => state.commonReducer
  );

  useEffect(() => {
    if (Object.values(productData).length !== 0) {
      if (
        productData.response !== undefined &&
        productData.response.status === 404
      ) {
        navigate("/");
        return;
      }

      // rewrite page url to the correct product url 
      // a 301 redirect was not implemented intentionally
      if (productData?.english_name && productData?.english_name !== urlProductName) {
        navigate(productUrlHelper(productData?.english_name,memoUrlSKU));
      }

      //TODO: from here on the code needs refactoring. its been taken from previous work
      let breadData = [];
      let breadPLPCheck =
        productData?.categories &&
        productData?.categories.filter(
          (val) =>
            val.is_active === "1" &&
            val.parent_id !== "1" &&
            val.parent_id !== "2"
        );

      let breadCategoryCheck =
        productData?.categories &&
        productData?.categories.filter(
          (val) => val.is_active === "1" && val.parent_id === "2"
        );

      if (breadPLPCheck.length > 0) {
        breadData.push(breadPLPCheck[0]);
      } else {
        if (breadCategoryCheck.length > 0) {
          breadData.push(breadCategoryCheck[0]);
        }
      }

        if (breadData && breadData.length > 0) {
          // let defaultIndex = 0
          // if (breadData.length > 1) {
          //   defaultIndex = breadData.length - 1
          // }
          setBreadCrumbsData({
            ...breadCrumbsData,
            firstBread: generateCategoryUrl(
              breadData[0]?.url_key || breadData[0].name,
              breadData[0].category_id,
              breadData[0].parent_id
            ),
            firstBreadTitle: breadData[0].name,
          });
        }
        setProduct(productData);
        // set specification data
        const productSpecificationInfo = {
          title: t("Specifications"),
          description: "",
          keyValueTitle: t("Key_Specs"),
          keyValueData: [],
        };
        productData.options &&
          productData.options
            .filter(
              (item) =>
                item.code !== "is_count_down" &&
                item.code !== "store_level_stock" &&
                item.code !== "url_key" &&
                item.visibility
            )
            .map((item) => {
              productSpecificationInfo.keyValueData.push({
                id: item?.code,
                key: item?.attribute?.label,
                value: item?.selected_option?.labelArr[0],
              });
            });
      setProductSpecificationData(productSpecificationInfo);

      // set product overview description

        const productOverviewInfo = {
          title: t("Product_Overview"),
          description:
            product.description !== ""
              ? htmlParseHelper(product.description)
              : "",
          keyValueTitle: "",
          keyValueData: [],
        };
        setProductOverviewData(productOverviewInfo);

        setLoading(false);
        // window.scrollTo(0, 0);
        setWarrantyProduct(productData?.user_warranty_products);
        if (productData?.user_warranty_products?.length > 0) {
          setIsExWarrantyAvailable(true)
        }
        try {
          let response = apiHelper(
            `/V1/get-store-data/${productData.id}`,
            "get",
            {},
            {}
          );
          if (response.data) {
            let filterStoreData = response.data.filter(
              (store) =>
                store.status !== "0" && store.enable_storepickup !== "0"
            );
            setPickStoreList(filterStoreData);
          }
        } catch (error) {
          // console.log("error store", error);
        }
    }
  }, [productData]);
  const [count, setCount] = useState(1);
  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);
  const [warrantyDataArray, setWarrantyDataArray] = useState([]);
  const [clickData, setClickData] = useState();
  const [clickSkuData, setClickSkuData] = useState();
  // const [loaderOnTwo, setLoaderOnTwo] = useState(false);
  // const [loaderBuyOn, setLoaderBuyOn] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (count > product?.stock) {
      dispatch(services.notifyError({ message: t('Sellable stock is %1').replace('%1', product?.stock) }));
      setCount(product?.stock);
    }
  }, [count]);

  // useEffect(() => {
  //   dispatch(loadCartData());
  //   return () => {};
  // }, []);

  // useEffect(() => {
  //   if (Object.values(cartData).length !== 0) {
  //     let productQtyCheck = cartData.items.filter(val => val.sku === product?.sku);
  //     if (productQtyCheck.length > 0) {
  //       setCount(productQtyCheck[0].qty);
  //       console.log("productQtyCheck[0].qty ", productQtyCheck[0].qty);
  //     }
  //     setCartDetail(cartData);
  //   }
  // }, [cartData, product]);

  // const deleteProductFromCart = async (id) => {
  //   // console.log("id ", id);

  //   const data = {
  //     // items: [id],
  //     items: [id],
  //   };
  //   // console.log("data ", data);

  //   await deleteFromCart(data)
  //     .then((res) => {
  //       // console.log(res, "res>>>");
  //       dispatch(loadCartData());
  //     })
  //     .catch((err) => {
  //       // console.log(err.response.data.message, "error >>>>");
  //       // dispatch(services.notifyError({ message: err.response.data.message }));
  //     });
  // };

  // const decreaseCount = (sku) => {
  //   if (count === 0) {
  //     setCount(0);
  //   } else {
  //     // deleteProductFromCart(product?.id)
  //     setCount(count - 1);
  //     // AddProductToCart(sku,count-1)
  //   }
  // };
  // const increaseCount = (sku) => {
  //   // deleteProductFromCart(product?.id)
  //   setCount(count + 1);
  //   // AddProductToCart(sku,count+1)
  // };

  const buyNow = (sku, id) => {
    AddProductToCart(sku, id, true);
  };
  function compareArraysBySingleKey(arr1, arr2, key) {
    const set2 = new Set(arr2.map(item => item[key]));
    const result = arr1.filter(item => !set2.has(item[key]));
    return result;
  }
 // let warrantyDataArray = [];
  const warrentyData = (param) => {
    setWarrantyDataArray(param)
  }
  
  const AddProductToCart = async (sku, id, buynow = false) => {
    // impressionEvents.impressionAddToCart(
    //   product,
    //   count,"product detail"
    // );
    setLoaderOn(true);
    if (!count) {
      const message = t('Please enter quantity');
      dispatch(services.notifyError({ message }));
      setLoaderOn(false);
      setIsCartData(false);
      return false;
    }
    if (
      product?.variants &&
      product?.variants.length > 0 &&
      sizeButtonIndex &&
      sizeButtonIndex >= 0
    ) {
      sku = product?.variants[sizeButtonIndex].sku;
    }
    const allCartItems = await getCartData();
          let updatedWarrantyData = warrantyDataArray.map((val)=>{return { sku: val.sku,
            id: val.id,
            qty: 1,}});
          updatedWarrantyData = compareArraysBySingleKey(updatedWarrantyData,allCartItems?.data?.items,"sku")

    const data = {
      items: [
        {
          sku: sku,
          id: product?.id,
          qty: count,
        },
      ].concat(updatedWarrantyData),
    };
    addToCart(data)
      .then((res) => {
        // setCount(res.data.filter((pro) => pro.sku === product?.sku)[0].qty);
        if (buynow) {
          dispatch(loadCartData()).then((item) => {
            cartEvents.addToCart(product, item?.id, "Product Detail")
            navigate("/checkout/delivery");
            setIsCartData(false);
          });
        } else {
          setSpinnerOn(true)
          dispatch(loadCartData()).then((item) => {
            cartEvents.addToCart(product, item?.id, "Product Detail")
            handleChangeCartPopup(true);
            setSpinnerOn(false)
          });
        }
      })
      .catch((error) => {
        setCartErr({ stock: product?.stock, error: error?.response?.data });
      })
      .finally(() => {
        setLoaderOn(false);
        setIsCartData(false);
      });
  };

  const sizeButtonClick = (sizeIndex, size) => {
    setProduct({
      ...product,
      sku: product?.variants[sizeIndex]?.sku,
      name: product?.variants[sizeIndex]?.name,
      description: product?.variants[sizeIndex]?.description,
      price: product?.variants[sizeIndex]?.price,
      currency: product?.variants[sizeIndex]?.currency,
      price_rounded: product?.variants[sizeIndex]?.price_rounded,
      discounted_price: product?.variants[sizeIndex]?.discounted_price,
      discounted_price_rounded:
        product?.variants[sizeIndex]?.discounted_price_rounded,
      discounted_price_from:
        product?.variants[sizeIndex]?.discounted_price_from,
      discounted_price_to: product?.variants[sizeIndex]?.discounted_price_to,
      is_count_down: product?.variants[sizeIndex]?.is_count_down,
      stock: product?.variants[sizeIndex]?.stock,
      item_type: product?.variants[sizeIndex]?.item_type,
      meza: product?.variants[sizeIndex]?.meza,
      qitaf: product?.variants[sizeIndex]?.qitaf,
      eligible_for_tabby: product?.variants[sizeIndex]?.eligible_for_tabby,
      enable_shipping: product?.variants[sizeIndex]?.enable_shipping,
      additional_estimated_day:
        product?.variants[sizeIndex]?.additional_estimated_day,
      current_time: product?.variants[sizeIndex]?.current_time,
      cart_rule: product?.variants[sizeIndex]?.cart_rule,
    });
    setSizeButtonIndex(sizeIndex);
  };
  useEffect(() => {
    if (product?.variants && product?.variants.length > 0 && sizeButtonIndex >= 0) {
      setClickData(product?.variants[sizeButtonIndex]?.name);
      setClickSkuData(product?.variants[sizeButtonIndex]?.sku);
    }
  }, [product, sizeButtonIndex]);
  
  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [wislistCount, setWislistCount] = useState(0);

  const wishlistData = useSelector((state) => state.appData.wishlistData);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, product?.sku));
  }, [product]);

  const handleFavourite = () => {
    if (isFavourite) {
      setIsFavourite(false);
      setWislistCount(wislistCount + 1);
      
    } else {
      setIsFavourite(true);
      setWislistCount(wislistCount + 1);
     
    }
  };
  useEffect(() => {
    const data = {
      items: [product.sku],
    };
    if (isFavourite) {
      if (wislistCount > 0) {
        const addToWishlistData = dispatch(loadAddToWishlist(data, t)).then(
          (res) => {
            checkoutEvents.addToWishlist(product, "Product Detail");
            dispatch(loadWishlistData());
          }
        );
      }
    } else {
      if (wislistCount > 0) {
        if (!isFavourite) {
          checkoutEvents.removeFromWishlist(product, "Product Detail");
          removeFromWL(product?.sku);
        }
      }
    }
  }, [isFavourite]);

  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };
    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data, t)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };

  const redirectBundlePage = (sku) => {
    let redUrl = `/create-bundle/${sku.replace(/[/]/g, "%2F")}`;
    navigate(redUrl);
  };

  const copyToClipboard = (skuData) => {
    navigator.clipboard.writeText(skuData).then(() => {
      dispatch(services.notifySuccess({ message: t("Copied!") }));
    });
  };

  let latestPrice =
    product?.discounted_price_rounded === null
      ? product?.price_rounded
      : product?.discounted_price_rounded;
  let getTamaraCheck3Month = getTamaraMinMaxLimit(latestPrice, 3);

  const [modalShow, setModalShow] = React.useState(false);
  const [returnPolicyData, setReturnPolicyData] = React.useState("");
  const currentLang = localStorage.getItem("i18next") || "en";
  // avoid multiple render
  const getInfo = useMemo(() => {
    let de = cmsPageContent && cmsPageContent;
    return de;
  }, [cmsPageContent]);

  useEffect(() => {
    if (getInfo.response && getInfo.response.status === 404) {
      dispatch(cmsPageContentApiSuccess(""));
      setReturnPolicyData("");
    } else {
      setReturnPolicyData(getInfo);
    }
  }, [cmsPageContent]);


  const AddProductToCartRelatedProd = (sku) => {
    setLoaderOnTwo(true);
    const data = {
      items: [
        {
          sku: sku,
          qty: 1,
          id: product?.id,
        },
      ],
    };

    addToCart(data)
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => cartEvents.addToCart(product, item?.id, "Product Detail"));
        setSpinnerOn(false)
        handleChangeCartPopup(true);
      })
      .catch((err) => {
        dispatch(services.notifyError({ message: err.response.data.message }));
        setLoaderOnTwo(false);
      })
      .finally(() => {
        setLoaderOnTwo(false);
      });
  };

  // product dropdown count change
  const handleChangeCount = (event) => {
    setCount(event.target.value);
  };

  const [qtyDropBtn, setQtyDropBtn] = React.useState(false);

  useEffect(() => {
    let isOutOfStock =
      product?.orderType === "out-of-stock" || product?.stock === 0;
    let isPreOrder = product?.orderType === "pre-order";
    let showDropBtn = false;
    if (
      isOutOfStock &&
      outOfStockConfiguration?.module_status &&
      product?.productOutOfStockStatus
    ) {
      showDropBtn = false;
    } else {
      if (isOutOfStock) {
        showDropBtn = false;
      } else if (isPreOrder) {
        showDropBtn = true;
      } else {
        showDropBtn = true;
      }
    }
    setQtyDropBtn(showDropBtn);
  }, [product, outOfStockConfiguration]);

  useEffect(() => {
    if (product?.id) {
      impressionEvents.impressionProductDetails(
        product,
        "product Detail"
      );
      impressionEvents.impressionGlobalVariable(
        currentLang, "PDP Page"
      );
    }
   
  }, [product?.id]);

  const isMobile = width <= 992;

  if (loading) {
    return <>
      {!isMobile ? <>
        <div className="breadcrumbs__block mx-auto my-2">
          <Skeleton width="80%" height="20px" />
        </div>
        <div className="product__details__page__block d-flex mx-auto">
          <div className="col-12 col-sm-12 col-md-12 col-lg-9 row">
            <div className="col-12 col-sm-12 col-md-5">
              <Skeleton width={"100%"} height={"60vh"} />
            </div>
            <div className="col-12 col-sm-12 col-md-7">
              <Skeleton width={"100%"} height={"60vh"} />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12  col-lg-3 product__details__right__block">
            <Skeleton width={"100%"} height={"60vh"} />
          </div>
        </div>
      </> :
        <>
          <div className="px-5 py-2">
            <Skeleton width="80%" height="20px" />
            <br />
            <Skeleton width={"100%"} height="50px" />
            <Skeleton width={"40%"} />
            <br />
            <br />
            <Skeleton containerClassName="d-flex justify-content-center" height={"40vh"} width={"60%"} />
          </div>
        </>
      }
    </>;
  }

  const discountedPrice = product?.discounted_price_rounded;
  const price = product?.price_rounded;
  const discountPercentage = Math.round(100 - (discountedPrice / price) * 100);
  // const discountedAmount = Math.round(price - discountedPrice);

  const isOutOfStock =
    product?.orderType === "out-of-stock" || product?.stock === 0;
  let isShippingEnabled = product?.enable_shipping;
  let productType = product?.item_type;

  const isDeliveryEnabled =
    !isOutOfStock && isShippingEnabled && productType === "simple";
  let additionalEstimationDate = product?.additional_estimated_day;

  const removeProductFromCompare = (productId) => {
    const comparePopupLocalData = JSON.parse(localStorage.getItem("compare_products"));
    let removeData = comparePopupLocalData && comparePopupLocalData.filter(item => item.id !== productId);
    localStorage.setItem('compare_products', JSON.stringify(removeData));
    localStorage.setItem('compare_products_max_3', JSON.stringify(false));
    dispatch(updateCompareProductList(removeData))
    // setPageRender(true);
    if (removeData.length === 0) {
      closeComparePopup();
    }
    setRefreshcomparePopup(true)
  }

  const getTealiumName = (product) => {
    return "pdp:" + titleChange(product?.name);
  }


  const handleCompare = (compareStatus, productData) => {
    if (compareStatus === false) {
      updateCompareProduct(true, productData)
    } else {
      removeProductFromCompare(productData?.id)
    }
  }

  const targetPrice = product?.price_rounded;
  const matchedProduct = product?.variants?.filter(product => product.price_rounded === targetPrice);
  const newMatchPrice = matchedProduct?.length > 1 ? matchedProduct?.[0] : matchedProduct?.[0];
  const screenSize = matchedProduct && matchedProduct[0]?.options?.screen_size;
  const seletcedScreenSize = product?.variantOptions?.[0].attribute?.options.filter(product => product.id === screenSize);
  const defaultSku = matchedProduct && matchedProduct[0]?.sku;
  
  // const currentLang = localStorage.getItem("i18next") || "en";
  // let quitafcard = require(`./../assets/Icon/icon-qitaf-${currentLang}.png`)
    const stringToCheck = product?.name;
    const checkSKU = product?.sku;
    const regex = /-(.+)/;
    const regex2 = /\d+$/;
    const hasAnythingAfterDash = regex.test(stringToCheck);
    const hasAnythingAfterSku = regex2.test(checkSKU);

    console.log("product?.item_type", product?.item_type);
  return (
    <>
      <PdpMetaComponent
        productName={titleChange(product?.name)}
        categoryName={product?.categories.filter((item) => item?.category_id !== 2)?.[0]?.name.toLowerCase() || ""}
        meta_title={product?.meta_title}
        meta_description={product?.meta_description}
        meta_keywords={product?.meta_keywords}
        firstImage={firstImage}
        product_slug={product?.english_name}
        product_sku={product?.sku}
      />
      {product?.name &&
        <TealiumTag name={getTealiumName(product)} template="pdp" />
      }
      {!isMobile && <div className="bg-white d-none d-lg-block">
        <BreadCrumbs
          // back="/"
          firstBread={breadCrumbsData?.firstBread}
          firstBreadTitle={breadCrumbsData?.firstBreadTitle}
          secondBread={productUrlHelper(product?.english_name, product?.sku)}
          secondBreadTitle={titleChange(product?.name)}
          highlight="firstBreadTitle"
          matchedProduct={matchedProduct}
          product={product}
          clickData={clickData}
        />
        <CmsContentModal
          title={t("7_Days_Return_Policy")}
          modalShow={modalShow}
          content={
            returnPolicyData &&
            htmlParseHelper(returnPolicyData?.content?.[0]?.content)
          }
          onHide={() => setModalShow(false)}
          size="lg"
          scrollable={true}
        />
      </div>}
      {/* mobile pdp page */}
      {isMobile && (
        <div className="mobile__product__detail__page d-block d-lg-none">
          <MobileProductDetailPage
            product={product}
            handleChangeCartPopup={handleChangeCartPopup}
            buyNow={buyNow}
            productOverviewData={productOverviewData}
            AddProductToCart={AddProductToCart}
            productSpecificationData={productSpecificationData}
            loaderOn={loaderOn || spinnerOn}
            firstBread={breadCrumbsData?.firstBread}
            firstBreadTitle={breadCrumbsData?.firstBreadTitle}
            getOptionKeyInfo={getOptionKeyInfo}
            getTamaraCheck3Month={getTamaraCheck3Month}
            returnPolicyData={returnPolicyData}
            discountPercentage={discountPercentage}
            discountedPrice={discountedPrice}
            sizeButtonClick={sizeButtonClick}
            sizeButtonIndex={sizeButtonIndex}
            isOutOfStock={isOutOfStock}
            isPreOrder={product?.orderType === "pre-order"}
            outOfStockConfiguration={outOfStockConfiguration}
            productOutOfStockStatus={product?.productOutOfStockStatus}
            copyToClipboard={copyToClipboard}
            updateCompareProduct={updateCompareProduct}
            selectedCompareProductStatus={selectedCompareProductStatus}
            productReviewData={productReviewData}
            PRODUCT_COUNT_ARRAY={PRODUCT_COUNT_ARRAY}
            qtyDropBtn={qtyDropBtn}
            // user={user}
            screenName="Product Detail"
            isMobile={isMobile}
            closeComparePopup={closeComparePopup}
            setRefreshcomparePopup={setRefreshcomparePopup}
            relatedProductList={relatedProductList}
            storePickupGlobalConfiguration={storePickupGlobalConfiguration}
            isApplePayAvailable={isApplePayAvailable}
            setFirstImage={setFirstImage}
            firstImage={firstImage}
            isExWarrantyAvailable={isExWarrantyAvailable}
            warrantyProduct={warrantyProduct}
            customerDetails={customerDetails}
          />
        </div>
      )}
      {/* mobile pdp page end */}

      {!isMobile && <div className="container-fluid product__details__page__container d-none d-lg-block">
        <div className="row product__details__page__block">
          <div className="col-12 col-sm-12 col-md-12 col-lg-9 row product__details__left__block__new">
            <div className="row products__details__inner__block">
              <div className="col-12 col-sm-12 col-md-5 product__carousel__main__block">
                <div className="product__carousel__block">
                  <ProductCarousel
                    productImageData={product?.media?.image?.screenshots}
                    product={product}
                    // updateCompareProduct={updateCompareProduct}
                    view="desktop"
                    setFirstImage={setFirstImage}
                    firstImage={firstImage}

                  />
                  {product?.cart_rule?.title && product?.cart_rule?.title !== '' && (
                    <div className="productOfferTag">
                      <p>{product?.cart_rule?.title}</p>
                    </div>
                  )}
                  <div className="pd__social__media__compare__block">
                    <div className="pd__social__media__icon__block">
                      <p className="pd__share__text">{t("Share")} :</p>
                      <a href={`${FACEBOOK_SOCIAL_URL}${encodeURI(window.location.href)}`} target="_">
                        <img
                          src={facebook}
                          alt=""
                          className="pd__social__media__icon"
                        />
                      </a>
                      <a href={`${WHATSAPP_SOCIAL_URL}${encodeURI(window.location.href)}`} target="_">
                        <img
                          src={whatsapp}
                          alt=""
                          className="pd__social__media__icon"
                        />
                      </a>
                      <a href={`${TWITTER_SOCIAL_URL}${encodeURI(window.location.href)}`} target="_">
                        <img
                          src={twitter}
                          alt=""
                          className="pd__social__media__icon"
                        />
                      </a>
                      <a href={`${MESSENGER_SOCIAL_URL}${encodeURI(window.location.href)}`} target="_">
                        <img
                          src={messanger}
                          alt=""
                          className="pd__social__media__icon"
                        />
                      </a>
                      <a href={`${PINTEREST_SOCIAL_URL}${encodeURI(window.location.href)}`} target="_">
                        <img
                          src={pinterest}
                          alt=""
                          className="pd__social__media__icon"
                        />
                      </a>
                    </div>
                    {product !== undefined && (
                      <div className="pd__compare__block">
                        <input
                          type="checkbox"
                          className="pd__compare__input__check"
                          name="compare"
                          checked={selectedCompareProductStatus(product?.id)}
                          id="pdpCompare"
                          onChange={() => handleCompare(selectedCompareProductStatus(product.id), product)}
                        />
                        <label htmlFor="pdpCompare" className="pd__compare__text">{t("Compare")}</label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-7 product__details__block">
                {/* <img
                  src={sony_logo}
                  alt=""
                  className="pd__product__company__logo"
                /> */}
                <SoldOutProductLabel
                  t={t}
                  product={product}
                  outOfStockConfiguration={outOfStockConfiguration}
                />
                <div className="product__details__titlebox">
                  <button className="pd__favourite__button">
                    <img
                      onMouseEnter={() => setIsFavouriteHover(true)}
                      onClick={handleFavourite}
                      onMouseLeave={() => setIsFavouriteHover(false)}
                      className={
                        !isFavourite
                          ? "pd__favourite__icon"
                          : "pd__favourite__icon__disable"
                      }
                      src={
                        isFavouriteHover ? fulfill_favourite : empty_favourite
                      }
                      alt=""
                    />
                    <img
                      onClick={handleFavourite}
                      className={
                        isFavourite
                          ? "pd__favourite__icon"
                          : "pd__favourite__icon__disable"
                      }
                      src={fulfill_favourite}
                      alt=""
                    />
                  </button>
                  {/* product={product} */}
                  <ShareLinkPDP t={t} urlLink={window.location.href} />
                  <div className="product__namebox">
                  {/* {!hasAnythingAfterDash ? newMatchPrice ? <Heading5 text={newMatchPrice?.name} /> : <Heading5 text={product?.name} /> : <Heading5 text={product?.name} /> } */}
                  {clickData ? <Heading5 text={clickData} />  : newMatchPrice ? <Heading5 text={newMatchPrice?.name} /> : <Heading5 text={product?.name} /> }
                  </div>
                </div>
                <div className="row rating__and__sku__area align-items-center">
                  <div className="col-8 product__sku__box">
                    <Heading7 text={`${t("SKU")} : `} />{" "}
                    {/* {!hasAnythingAfterSku ? newMatchPrice ? <Heading5 text={newMatchPrice?.sku} /> : <Heading5 text={product?.sku} /> : <Heading5 text={product?.sku} /> } */}
                    {clickSkuData ? <Heading5 text={clickSkuData} />  : newMatchPrice ? <Heading5 text={newMatchPrice?.sku} /> : <Heading5 text={product?.sku} /> }
                    <a
                      href="#"
                      className="pro__sku__copy"
                      onClick={(e) => copyToClipboard(product?.sku)}
                    >
                      {t("Copy")}
                    </a>
                  </div>
                  {/* <div className="col-4">
                      <div className="d-flex w-100 justify-content-end">
                        <div
                          onClick={() =>
                            decreaseCount(product?.sku, product?.id)
                          }
                          className="col-4 counter__decrease__block"
                        >
                          <img src={minus} alt="minus" />
                        </div> */}
                  {/* <div className="col-4 counter">{count}</div> */}
                  {/* <div className="col-4 counter">
                          <input
                            type="text"
                            className="product_qty_counter"
                            onChange={(e) => handleChangeQty(e)}
                            value={count}
                          />
                        </div>
                        <div
                          onClick={() =>
                            increaseCount(product?.sku, product?.id)
                          }
                          className="col-4 counter__increase__block"
                        >
                          <img src={plus} alt="plus" />
                        </div>
                      </div>
                    </div> */}
                  {/* <div className="col-1">
                      <BsClipboard
                        style={{ cursor: "pointer" }}
                        onClick={() => copyToClipboard()}
                      />
                    </div> */}
                </div>
                {discountPercentage < 100 && discountPercentage > 0 && (
                  <>
                    {!product?.cart_rule?.title &&
                      product?.discounted_price_from &&
                      Date.now() <
                      new Date(product?.discounted_price_to).getTime() &&
                      product?.is_count_down && (
                        <CountdownBox
                          product={product}
                          t={t}
                          current={product?.current_time}
                          start={product.discounted_price_from}
                          end={product?.discounted_price_to}
                          discountedPrice={discountedPrice}
                          price={product?.price_rounded}
                        // discountPercentage={discountPercentage}
                        />
                      )}
                  </>
                )}
                <div className="pd__category__favourite__button__block">
                  <ProductCategoryLevel product={product} />
                </div>

                {/* <div className="pd__category__favourite__button__block">
                  <ProductCategoryLevel product={product} />
                  <button className="pd__favourite__button">
                    <img
                      onMouseEnter={() => setIsFavouriteHover(true)}
                      onClick={handleFavourite}
                      onMouseLeave={() => setIsFavouriteHover(false)}
                      className={
                        !isFavourite
                          ? "pd__favourite__icon"
                          : "pd__favourite__icon__disable"
                      }
                      src={
                        isFavouriteHover ? fulfill_favourite : empty_favourite
                      }
                      alt=""
                    />
                    <img
                      onClick={handleFavourite}
                      className={
                        isFavourite
                          ? "pd__favourite__icon"
                          : "pd__favourite__icon__disable"
                      }
                      src={fulfill_favourite}
                      alt=""
                    />
                  </button>
                </div> */}
                {/* <hr className="pd__block__bottom__line" /> */}
                <div className="row pdp__sticky__counter__block">
                  <div className="col-8">
                    {/* {product.item_type === "simple" && (
                      <>
                        {(product.options.filter(
                          (val) => val.code === "express_shipping_eligible"
                        ).length > 0 ||
                          product.options.filter(
                            (val) => val.code === "lighting_shipping_eligible"
                          ).length > 0) && (
                            <>
                              {getCurrentHours < 12 ? (
                                <>
                                  <Heading6
                                    text={t("Order_with_lightening_delivery")}
                                  />
                                  <Text3
                                    text={t("gets_it_by_today")}
                                    color="#ff9b04"
                                  />
                                </>
                              ) : (
                                <>
                                  <Heading6
                                    text={t("Order_with_express_delivery")}
                                  />
                                  <Text3
                                    text={t("gets_it_by_tomorrow")}
                                    color="#ff9b04"
                                  />
                                </>
                              )}
                            </>
                          )}
                      </>
                    )} */}
                    {/* {product.item_type === "virtual" && (
                      <>
                        <Heading6 text={t("Order_today")} />
                        <Text3
                          text={t("get_instant_delivery")}
                          color="#ff9b04"
                        />
                      </>
                    )} */}
                  </div>
                  {/* <div className="col-4">
                    <div className="row pdp__sticky__counter__block"> */}
                  {/* <div
                        onClick={() => decreaseCount(product.sku, product.id)}
                        className="col-4 counter__decrease__block"
                      >
                        <img src={minus} alt="minus" />
                      </div> */}
                  {/* <div className="col-4 counter">{count}</div> */}
                  {/* <div className="col-4 counter">
                        <input
                          type="text"
                          className="product_qty_counter"
                          onChange={(e) => handleChangeQty(e)}
                          value={count}
                        />
                      </div>
                      <div
                        onClick={() => increaseCount(product.sku, product.id)}
                        className="col-4 counter__increase__block"
                      >
                        <img src={plus} alt="plus" />
                      </div> */}
                  {/* </div>
                  </div> */}
                </div>
                {/* <Text5
                  // text={product.categoryTagline}
                  text="Experience the brilliance of big-screen Sony 8K HDR"
                  color="#808080"
                  marginBottom={10}
                /> */}
                {/* <Rating PriceBlock */}
                {/* <RatingBlock
                  size={22}
                  rating={product.reviewSummary.avg}
                  totalRatings={Object.values(
                    product.reviewSummary.totals
                  ).reduce((a, b) => a + b)}
                  // totalRatings={0}
                  fillColor="#DC3A1A"
                  emptyColor="#C8C8C8"
                /> */}
                {/* <div className="row pdp__sticky__counter__block">
                  <div
                    onClick={() => decreaseCount()}
                    className="col-4 counter__decrease__block"
                  >
                    <img src={minus} alt="minus" />
                  </div>
                  <div className="col-4 counter">{count}</div>
                  <div
                    onClick={() => increaseCount()}
                    className="col-4 counter__increase__block"
                  >
                    <img src={plus} alt="plus" />
                  </div>
                </div> */}
                {/* <hr className="pd__block__bottom__line" /> */}

                {/* Price Block */}
                {!product?.is_price_hide && (
                  <div className="row pd__product__price__block ">
                    <div className="col-12 col-sm-12 col-md-12 pd__product__price__offer">
                      <PriceBlock
                        oldPrice={product?.price_rounded}
                        price={
                          product?.discounted_price_rounded === null
                            ? product?.price_rounded
                            : product?.discounted_price_rounded
                        }
                        currency={product?.currency}
                        monthlySavingTagline="get it for SAR 500 in 6 equal installments"
                        product={product}
                      />
                    </div>
                  </div>
                )}
                {product?.is_bundle_enable === 1 &&
                  <div className="row pd__product__price__block ">
                    <div className="col-12 col-sm-12 col-md-12 mt-2 d-flex justify-content-end">
                      <BundleOffer
                        bundleEnable={product?.is_bundle_enable}
                        bundleProducts={product.user_bundle_products}
                        t={t}
                      />
                    </div>
                  </div>
                }
                {/* Available Offer */}
                {/* {product.eligible_for_tamara === true && */}
                <>
                  {/* <hr className="pd__block__bottom__line" /> */}
                  {/* <hr className="pd__block__bottom__line" /> */}
                  <MizaAndQitafPDP product={product} type="desktop" />
                  <MainStoreDelivery t={t} product={product} />
                  <MainStorePickup t={t} product={product} storePickupGlobalConfiguration={storePickupGlobalConfiguration} />

                  {/* More services section */}
                  {((isExWarrantyAvailable && !isMobile)) &&
                    <MoreServices
                       t={t}
                       title={t("Add extra warranty?")}
                       product={product}
                       warrantyProduct={warrantyProduct}
                       handleChangeCartPopup={handleChangeCartPopup}
                       isExWarrantyAvailable={isExWarrantyAvailable} 
                       warrentyData={warrentyData}
                    />
                  }

                  <div className="row pd__prod__size__install__area">
                    {product &&
                      product?.variantOptions &&
                      product?.variantOptions[0] &&
                      product?.variantOptions[0]?.attribute &&
                      product?.variantOptions[0]?.attribute?.options && (
                        <div className="col-md-5 pd__size__Option__area">
                          <Heading6
                            text={t("Select your favourite size")}
                            color="#000000"
                          />
                          <div className="pd__warranty__size__block pd__common__main__block">
                            <div className="row pd__size__block">
                              <div className="pd__size__button__block">
                                <ProductVariantOptions
                                  product={product}
                                  sizeButtonClick={sizeButtonClick}
                                  sizeButtonIndex={sizeButtonIndex}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                 
                  {/* Meza subscription */}
                  {!product?.hide_subscriber_price && (
                    <SubscribeSection>
                      <SubsSectionContent
                        isPDP={true}
                        t={t}
                        meza_subscriber_price={product?.meza_subscriber_price}
                        user={customerDetails}
                        discounted_price={product.discounted_price_rounded}
                        og_price={product.price_rounded}
                      />
                    </SubscribeSection>
                  )}

                  <div className="row pd__Allbuttons__area pd__common__main__block">
                    {/* {product?.stock > 0 && (
                      <>
                        {product?.bundle_products &&
                          product?.bundle_products?.[0] &&
                          product?.bundle_products?.[0].length > 0 && (
                            <div className="col-lg-5 col-xl-5 mb-1  pe-0 pe-xl-1 pd__bundle__button__block">
                              <div
                                className="pd__bundle__button"
                                onClick={() => redirectBundlePage(product.sku)}
                              >
                                {t("Build_A_Bundle")}
                              </div>
                            </div>
                          )}
                      </>
                    )} */}
                    {/* {product?.item_type === 'virtual' && isApplePayAvailable && (
                      <VisaHolderNote t={t} />
                    )} */}
                    <div className="col-5 col-sm-5 col-md-4 pd__buy__button__block ps-0">
                      <BuyNow
                        product={product}
                        isOutOfStock={isOutOfStock}
                        outOfStockConfiguration={outOfStockConfiguration}
                        buyNow={buyNow}
                        isCartData={isCartData}
                        setIsCartData={setIsCartData}
                        matchedProduct={matchedProduct}
                      />
                    </div>
                    <div className="col-5 col-sm-5 col-md-6 ps-0 pd__addToCart__button__block">
                      <AddToCartPDP
                        product={product}
                        AddProductToCart={AddProductToCart}
                        isOutOfStock={isOutOfStock}
                        isPreOrder={product?.orderType === "pre-order"}
                        outOfStockConfiguration={outOfStockConfiguration}
                        productOutOfStockStatus={
                          product?.productOutOfStockStatus
                        }
                        loaderOn={loaderOn || spinnerOn}
                        isCartData={isCartData}
                        matchedProduct={matchedProduct}
                      />
                    </div>
                    {qtyDropBtn === true && (
                      <div className="col-2 col-sm-2 col-md-2 pe-0 pd__qty__block">
                        <div className="field__block">
                          <input
                            type="number"
                            min="1"
                            max="10"
                            className="form__field"
                            id="count"
                            name="count"
                            value={count}
                            onInput={(e) => setCount(e.target.value)}
                            aria-label="Count"
                          />
                        </div>
                      </div>
                    )}
                    {/* <>
                      {product &&
                        isOutOfStock &&
                        outOfStockConfiguration?.module_status &&
                        productOutOfStockStatus ? (
                        null
                      ) : (
                        <div className="col-5 col-sm-5 col-md-5 col-lg-5 col-xl-4 pd__buy__button__block for_apple mt-2 ps-0">
                          {!['virtual', 'simple'].includes(product?.item_type) &&
                            <ApplePay sku={product?.sku} id={product?.id} t={t} qty={count} total={product?.discounted_price_rounded === null
                              ? product?.price_rounded
                              : product?.discounted_price_rounded} />
                          }
                        </div>
                      )}
                    </> */}
                  </div>

                </>
                {/* } */}

                {/* {product.meza && (
                  <RewardBlock
                    rewardType="meza"
                    money_equivalent={product.meza.money_equivalent}
                    currency={product.currency}
                    points={product.meza.points}
                  />
                )}
                {product.qitaf && (
                  <RewardBlock
                    rewardType="qitaf"
                    money_equivalent={product.qitaf.money_equivalent}
                    currency={product.currency}
                    points={product.qitaf.points}
                  />
                )} */}
                {/* <div className="row super__coin__itembox common__main__block">
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5 super__coin__image__block">
                    <img src={mezzaimg} alt="" className="super__coin__image" />
                  </div>
                  <div className="col-8 col-sm-6 col-md-8 col-lg-8 col-xl-7 super__coin__text__block ps-2">
                    <Heading7 text="SAR200" />
                    <span className="sum__points d-block">+1689 points</span>
                    <span className="sum__eq d-block">equals to SAR 24.00</span>
                    <p className="super__coin__title">
                      you earn <img src={coin} alt="" className="coin" /> 2 SuperCoins
                    </p>
                    <p className="super__coin__condition">Max 50 coins per order</p>
                  </div>
                </div>
                <div className="row super__coin__itembox common__main__block">
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5 super__coin__image__block">
                    <img src={quitafcard} alt="" className="super__coin__image" />
                  </div>
                  <div className="col-8 col-sm-6 col-md-8 col-lg-8 col-xl-7 super__coin__text__block ps-2">
                    <Heading7 text="SAR200" />
                    <p className="super__coin__title">
                      you earn <img src={coin} alt="" className="coin" /> 2 SuperCoins
                    </p>
                    <p className="super__coin__condition">Max 50 coins per order</p>
                  </div>
                </div> */}
                {/* Unlock MemberShip Block */}
                {/* <div className="pd__unlock__membership__block mt-3">
                  <div className="pd__unlock__membership">
                    <div className="pd__icon__block">
                      <img
                        src={unlock}
                        alt=""
                        className="pd__unlock__icon"
                      />
                    </div>

                    <p className="pd__unlock__membership__text">
                      {t("Unlock_up_to_24_months")}
                    </p>
                  </div>
                  <img
                    src={grey_right_arrow}
                    alt=""
                    className="pd__gret__right__arrow__icon"
                  />
                </div> */}

                {/* Warranty Block */}
                {/* <div className="pd__warranty__size__block pd__common__main__block">
                  <div className="row pd__warranty__block">
                    <p className="col-3 pd__warranty__title">{t("Warranty")} :</p>
                    <p className="col-9 pd__warranty__text">
                      {productWarrentyBlock.warrantyText}
                    </p>
                  </div>
                  {product && product.variantOptions && product.variantOptions[0] && product.variantOptions[0].attribute && product.variantOptions[0].attribute.options &&
                    <div className="row pd__size__block">
                      <p className="col-3 col-sm-3 col-md-12 col-lg-3 pd__size__title">
                        {t("Size")} :
                      </p>
                      <div className="col-9 col-sm-9 col-md-12 col-lg-9 pd__size__button__block">
                        <ProductVariantOptions
                          product={product}
                          sizeButtonClick={sizeButtonClick}
                          sizeButtonIndex={sizeButtonIndex}
                        />
                      </div>
                    </div>
                  }
                </div> */}

                {/* Delivery Block % Pickup Store */}
                <div className="pd__delivery__block pd__common__main__block">
                  {/* <div className="pd__form__block">
                    <div className="pd__form__field">
                      <input
                        type="number"
                        inputMode="numeric"
                        className="pd__input__field"
                        placeholder="Enter Delivery Code"
                        name="pincode"
                        value={pincode}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="pd__form__field">
                      <button
                        type="submit"
                        className="pd__input__button"
                        onClick={handleSubmit}
                      >
                        {t("CHECK")}
                      </button>
                    </div>
                  </div> */}
                  {/* {(product.lighting_shipping_eligible === true || product.express_shipping_eligible === true) &&
                    <>
                      <hr className="pd__block__bottom__line" />
                      <Heading6 text="Delivery" marginBottom={15} />
                      <Heading7
                        text="Buy before 12 Pm & get it as soon as Today/Tomorrow"
                        marginBottom={0}
                      />
                    </>
                  } */}
                  {/* <Text4
                    text=" Enter pincode for exact delivery dates/charges"
                    color="#808080"
                    marginBottom={0}
                  /> */}
                  {pickStoreList && pickStoreList.length > 0 && (
                    <>
                      {/* <hr className="pd__block__bottom__line" /> */}
                      <PickupStorePDP
                        // delivery={productDelivery}
                        pickupStore={pickStoreList}
                        title="Pick Up From Store"
                      />
                    </>
                  )}
                </div>
                {/* <hr className="pd__block__bottom__line" /> */}

                {/* Bundle Block */}
                {!isMobile && (
                  <BundleView
                    t={t}
                    parentSku={product?.sku}
                    bundle_products={product.user_bundle_products}
                    redirectBundlePage={redirectBundlePage}
                    product={product}
                  />
                )}

                {/* Pretection Block */}
                {/* <Protecttion
                  title="Protect Your TV"
                  tagline="Most popular protection plan for your product"
                  rating={product.reviewSummary.avg}
                  totalRatings={Object.values(
                    product.reviewSummary.totals
                  ).reduce((a, b) => a + b)}
                  protection={productProtection}
                /> */}

                {/* <hr className="pd__block__bottom__line" /> */}
                {/* Super Coin Block */}
                {/* <SuperCoin /> */}

                {/* <hr className="pd__block__bottom__line" /> */}

                {/* Button Block */}

                {/* <div className="row pd__bundle__cart__button__block pd__common__main__block">
                  <div className="col-xl-6 mb-1  pe-0 pe-xl-1 pd__bundle__button__block">
                    {product.bundle_products &&
                      product.bundle_products[0] &&
                      product.bundle_products[0].length > 0 && (
                        <div
                          className="pd__bundle__button"
                          onClick={() => redirectBundlePage(product.sku)}
                        >
                          {t("Build_A_Bundle")}
                        </div>
                      )}
                  </div>
                  <div className="col-xl-6 mb-1 ps-0 ps-xl-1 pd__addToCart__button__block">
                    {product?.stock > 0 ? (
                      <div
                        className="pd__addToCart__button"
                        onClick={() => AddProductToCart(product.sku)}
                      >
                        <img
                          src={shopping_cart}
                          alt=""
                          className="pd__addToCart__icon"
                        />
                        {t("Add_To_Cart")}
                        {loaderOn === true ? <LoaderIcone /> : ""}
                      </div>
                    ) : (
                      <div className="pd__outofStock__button">
                        {t("Out_Of_Stock")}
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="col-12 exp__rd__block">
              <Heading3
                text="Our experts Recommendation"
                marginBottom={15}
              />
              <div className="row exp__rd__main__block">
                <div className="col-12 col-lg-12 col-xl-9 row exp__rd__left__block">
                  {product.relatedProducts[0].products
                    .slice(0, 4)
                    .map((product, productIndex) => {
                      return (
                        <div
                          key={product.id}
                          className="col-6 col-sm-6 col-xxl-3"
                        >
                          <ExpertProducts product={product} />
                        </div>
                      );
                    })}
                </div>
                <div className="col-12 col-lg-12 col-xl-3 exp__rd__package__block">
                  <div className="exp__rd__package__inner__block">
                    <div className="exp__rd__package__saving__block">
                      <p className="package__saving__text">
                        {" "}
                        <Text4 text="Package Savings" marginBottom={0} />
                      </p>
                      <p className="package__saving__price">
                        <Price
                          currency={product.currency}
                          price={99}
                          size="heading6"
                        />
                      </p>
                    </div>
                    <div className="exp__rd__package__total__block">
                      <p className="package__total__text">
                        {" "}
                        <Heading6 text="Package Total" marginBottom={0} />
                      </p>
                      <p className="package__total__price">
                        {" "}
                        <Price
                          currency={product.currency}
                          price={1999}
                          size="heading6"
                        />
                      </p>
                    </div>

                    <div className="exp__rd__addToCart__button">
                      <img
                        src={shopping_cart}
                        alt=""
                        className="exp__rd__addToCart__icon"
                      />
                      {t("ADD_4_ITEMS_TO_CART")}
                    </div>

                    <p className="exp__rd__package__link__text">
                      <Link
                        to="/products/1"
                        className="exp__rd__package__link"
                      >
                        {t("Build_your_own_package")}{" "}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="col-12 col-sm-12 col-md-12  col-lg-3 product__details__right__block">
            {/* Return Policy Block  */}
            <div className="pd__return__block ">
              <div className="pd__icon__block">
                <img
                  src={return_period}
                  alt=""
                  className="pd__return_period_icon"
                />
              </div>
              <div className="pd__return__text__block">
                <Heading7 text={t("7_Days_Return_Policy")} />
                {/* <Text5
                      text={`${t(
                        "If_received_today_the_last_day_to_return_this_item_would_be"
                      )} Apr 15`}
                      marginBottom={0}
                    /> */}
                <Link
                  to="#"
                  className="pd__learn__more__link"
                  onClick={(e) => setModalShow(true)}
                >
                  {`${t("Learn_more")} >`}
                </Link>
              </div>
            </div>

            {!isMobile && (
              <AvailableOffers
                // availableOffer={productAvailableOffer}
                // title={t("Available_Offers")}
                product={product}
              // tabbyEligible={tabbyEligible}
              // tamaraEligible={tamaraEligible}
              // isPriceHide={isPriceHide}
              // tabbyGlobalSetting={tabbyGlobalSetting}
              // tamaraGlobalSetting={tamaraGlobalSetting}
              />
            )}
            <Heading3 price="People Ultimately Bought" />
            <div className="pd__related__product__section">
              <p className="pd__mb__block__title">{t("Related_Products")}</p>
              {relatedProductList &&
                relatedProductList.map((product, productIndex) => {
                  if (isMore === false) {
                    if (productIndex > 2) {
                      return;
                    }
                  }
                  return (
                    <React.Fragment key={`related_products_${productIndex}`} >
                      <RelatedProduct
                        t={t}
                        productIndex={productIndex}
                        product={product}
                        handleChangeCartPopup={handleChangeCartPopup}
                        updateCompareProduct={updateCompareProduct}
                        outOfStockConfiguration={outOfStockConfiguration}
                        handleCompare={handleCompare}
                        availablityData={productData?.availablityData || []}
                    />
                    </React.Fragment>
                  );
                })}
              {relatedProductList?.length > 3 &&
                <div className="view__more__address__block">
                  <button
                    onClick={() =>
                      setIsMore(!isMore)
                    }
                    className="view__more__address__button"
                  >
                    <Heading6
                      text={
                        isMore
                          ? t("View_Less")
                          : t("View_More")
                      }
                      marginBottom={0}
                      color="#000000"
                    />
                    {isMore === true ? <BsChevronUp /> : <BsChevronDown />}
                  </button>
                </div>
              }
            </div>

          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 pd__accordian__section my-4 product__details__left__block">
            <PdpAccordian
              product={product}
              finalData={finalData}
              productOverviewData={productOverviewData}
              productSpecificationData={productSpecificationData}
              productReviewData={productReviewData}
              getOptionKeyInfo={getOptionKeyInfo}
            />
          </div>
        </div>
      </div>}
      {/* <NewsLetter /> */}
      {/* <Footer /> */}
    </>
  );
}

export default Product_Details_Page;
