import React, { useEffect, useState } from "react";
import {  useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "./../SCSS/_createBundlePage.scss";
import BreadCrumbs from "../Components/BreadCrumbs";
import sony_logo from "./../assets/Icon/sony_logo.svg";
import shopping_cart from "./../assets/Icon/shopping_cart.svg";
import left_arrow from "./../assets/Icon/left_arrow.svg";
import right_arrow from "./../assets/Icon/right_arrow.svg";
import ProductThree from "../Components/ProductType/ProductThree";
import Heading3 from "../Components/Font/Heading3";
import Heading4 from "../Components/Font/Heading4";
import Heading5 from "../Components/Font/Heading5";
import Text3 from "../Components/Font/Text3";
import Protection from "../Components/MostSharedComponent/Protection";
import Price from "../Components/Font/Price";
import Heading6 from "../Components/Font/Heading6";
import {
  loadProductDetailData
} from "../redux/appAction";
import { getProductDetail } from "../services/pdp.service";
import { useTranslation } from "react-i18next";
import _uniqBy from "lodash.uniqby";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { productUrlHelper } from "../Components/helpers/utils/productUrlHelper";
import { titleChange } from "../Components/helpers/utils/titleNameChange";
import { loaderEnd, loaderStart } from "../services/services";

const product = {
  id: 1,
  logo: sony_logo,
  name: "Z8H | Full Array LED | 8K | High Dynamic Range (HDR) | Smart TV (Android TV)",
  categoryTagline: "Experience the brilliance of big-screen Sony 8K HDR",
  rating: 4.6,
  totalRatings: 6183,
  price: 799,
  oldPrice: 1050,
  saving: 10,
  monthlySavingTagline: "get it for SAR 500 in 6 equal installments",
  returnPeriod: 15,
  availableOffer: [
    {
      id: 1,
      offerType: "",
      offerText: "Save $50-$300 on a sound bar with TV",
      termsAndConditions: "",
    },
    {
      id: 2,
      offerType: "Bank Offer",
      offerText: "5% Unlimited Cashback on Axis Bank Credit Card",
      termsAndConditions: "T&C",
    },
    {
      id: 3,
      offerType: "Credit Card Offer",
      offerText: "5% Unlimited Cashback on Sony Credit Card",
      termsAndConditions: "T&C",
    },
  ],
  warrantyText: "1 Year Warranty on Product",
  size: [
    {
      id: 1,
      cm: 139,
      inch: 55,
    },
    {
      id: 2,
      cm: 164,
      inch: 65,
    },
    {
      id: 3,
      cm: 195,
      inch: 77,
    },
  ],
  delivery: {
    deliveryText: "Buy in next 2 hours and receive the TV by Tomorrow",
    pickupStore: [
      {
        id: 1,
        pickupText:
          "Available today at Riyadh Act Fast – Only 3 left at your store!>",
      },
      {
        id: 2,
        pickupText:
          "Available today at Riyadh Act Fast – Only 3 left at your store!>",
      },
      {
        id: 3,
        pickupText:
          "Available today at Riyadh Act Fast – Only 3 left at your store!>",
      },
    ],
  },
  protection: [
    {
      id: 1,
      protectionText: "2-Year Standard Geek Squad Protection",
      price: 79,
      month: 12,
    },
    {
      id: 2,
      protectionText: "1-Year Standard Geek Squad Protection",
      price: 89,
      month: 12,
    },
  ],
};
const productCategory = [
  "ExperPicks",
  "ExpertPicks",
  "Surge Protectors",
  "Soundbars",
  "Universal Remotes",
  "Expert Picks",
  "Expert Picks",
  "Surge Protectors",
  "Soundbars",
  "Universal Remotes",
  "Expert Picks",
  "Expert Picks",
  "Surge Protectors",
  "Soundbars",
  "Universal Remotes",
];

function Create_Bundle_Page({ handleChangeCartPopup }) {
  const { id } = useParams();

  let arr = id.split("-p-")
  let urlSKU = arr[arr.length - 1];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cartData = useSelector((state) => state.appData.cartData);
  const productData = useSelector((state) => state.appData.productData);

  const [disableLeftArrow, setDisableLeftArrow] = useState(true);
  const [disableRightArrow, setDisableRightArrow] = useState(false);
  const [arrowState, setArrowState] = useState(true);
  const [selectCategoryId, setSelectCategoryId] = useState('all');

  const [productDetails, setProductDetails] = useState('');
  const [bundleProductList, setBundleProductList] = useState([]);
  const [cartTotalData, setCartTotalData] = useState();
  const [cartDetail, setCartDetail] = useState();
  const [productCategoryList, setProductCategoryList] = useState([]);

  useEffect(() => {
    dispatch(loaderStart());
    dispatch(loadProductDetailData(urlSKU.replace(/[/]/g, "%2F"))).then(()=>{
      dispatch(loaderEnd());
    });
  }, [id]);

  useEffect(async () => {
    if (Object.values(productData).length !== 0) {
      if (productData.response !== undefined && productData.response.status === 404) {
        navigate('/')
      } else {
        let bundleList = []
        let bundle_products = productData.bundle_products && productData.bundle_products[0] !== undefined ? productData.bundle_products[0] : []
        for (const skuData of bundle_products) {
          const response = await getProductDetail(skuData);
          bundleList.push(response)
        }
        let createCategoryList = [
          {
            category_id: 'all',
            name: 'All'
          }
        ];

        bundleList.map((item, i) => {
          item.categories.filter(val => val.is_active === '1' && val.name !== 'All Categories').map((item2, j) => {
            createCategoryList.push(item2)
          })
        })
        let uniqCategory = _uniqBy(createCategoryList, 'category_id');
        setProductCategoryList(uniqCategory);
        setBundleProductList(bundleList)
        setProductDetails(productData)
      }
    }
  }, [productData]);

  // console.log(cartData);
  useEffect(() => {
    if (Object.values(cartData).length !== 0) {
      setCartTotalData(cartData.totals_data);
      setCartDetail(cartData);
      window.scrollTo(0, 0);
    }
  }, [cartData]);


  const { t } = useTranslation()
  const productCategorySelect = (category) => {
    setSelectCategoryId(category.category_id);
  };
  const handleChange = (e) => {
    // console.log(e.target.value);
  };

  const leftSide = (e) => {
    setArrowState(!arrowState);
    setDisableRightArrow(false);
    e.preventDefault();
    document.querySelector(".cb__page__button__block").scrollLeft =
      document.querySelector(".cb__page__button__block").scrollLeft -
      document.querySelector(".cb__page__button__block").clientWidth -
      800;
    let scroll = document.querySelector(".cb__page__button__block").scrollLeft;
  };
  const rightSide = (e) => {
    setArrowState(!arrowState);
    setDisableLeftArrow(false);
    e.preventDefault();

    document.querySelector(".cb__page__button__block").scrollLeft =
      document.querySelector(".cb__page__button__block").scrollLeft +
      document.querySelector(".cb__page__button__block").clientWidth -
      800;
  };
  return (
    <>
      {/* <BreadCrumbs title="Create Bundle"/> */}
      {productDetails !== "" &&
        <div className="container-fluid create__bundle__page__container">
          <div className="create__bundle__page__block">
            <div className="cb__page__title">
              {" "}
              <Heading3 text="Build a bundle that works for you." />
            </div>
            <div className="cb__page__description">
              <Text3
                text="These protection plans and accessories are popular with customers
            who bought this item."
                color="#808080"
              />
            </div>
            <div className="row create__bundle__page__inner__block">
              <div className="col-md-12 col-xl-9 row create__bundle__left__block">
                <div className="row cb__product__block">
                  <div className="col-12 col-sm-3 cb__product__left__block">
                    <div className="cb__product__image__block">
                      <img
                        src={productDetails.baseImage}
                        alt=""
                        className="cb__product__image"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-9 cb__product__right__block">
                    <img
                      src={product.logo}
                      alt=""
                      className="cb__product__company__logo"
                    />
                    <Link
                      className="pd__mb__product__name"
                      to={productUrlHelper(productDetails?.url_key, productDetails?.sku)}
                    >
                      <Heading4 text={titleChange(productDetails.name)} marginBottom={0} />
                    </Link>
                    <Protection
                      title="Protect your TV"
                      tagline="Most popular protection plan for your product"
                      rating={product.rating}
                      totalRatings={product.totalRatings}
                      protection={product.protection}
                    />
                  </div>
                </div>
                <hr className="cb__page__horizontal__line"></hr>
                <div className="cb__page__button__block__title">
                  <Heading3
                    text="Best-selling items to go with it"
                    marginBottom={0}
                  />
                </div>
                <div className="cb__page__category__button__block">
                  <div className="cb__page__button__main__block">
                    <div className="cb__page__button__block">
                      {productCategoryList && productCategoryList.map((category, categoryIndex) => {
                        return (
                          <button
                            key={categoryIndex}
                            onClick={() =>
                              productCategorySelect(category)
                            }
                            className={
                              selectCategoryId === category.category_id
                                ? "cb__page__button__active"
                                : "cb__page__button"
                            }
                          >
                            {category.name}
                          </button>
                        );
                      })}
                    </div>
                    <div className="arrow left__arrow" onClick={leftSide}>
                      <img src={left_arrow} alt="" />
                    </div>
                    <div className="arrow right__arrow" onClick={rightSide}>
                      <img src={right_arrow} alt="" />
                    </div>
                  </div>
                </div>
                <hr className="cb__page__horizontal__line"></hr>

                <div className="cb__page__block__title">
                  <Heading3
                    text="Our experts recommend the following products:"
                    marginBottom={0}
                  />
                </div>
                {bundleProductList && bundleProductList.map((item, i) => {
                  return (
                    <>
                      {selectCategoryId === 'all' ?
                        <ProductThree product={product} productDetails={item} handleChangeCartPopup={handleChangeCartPopup} cartDetail={cartDetail} parentData={productDetails} />
                        :
                        <>
                          {item.categories.filter(val => val.category_id === selectCategoryId).length > 0 &&
                            <>
                              <ProductThree product={product} productDetails={item} handleChangeCartPopup={handleChangeCartPopup} cartDetail={cartDetail} parentData={productDetails} />
                              <hr className="cb__page__horizontal__line"></hr>
                            </>
                          }
                        </>
                      }
                    </>
                  )
                })}
                {/* <ProductThree product={product} productDetails={productDetails} /> */}
              </div>
              {/* package Summary */}
              <div className="col-md-12 col-xl-3  create__bundle__right__block">
                <div className="cb__package__summary__block">
                  <div className="cb__ps__title">
                    {" "}
                    <Heading5 text="Package Summary" />
                  </div>
                  <div className="cb__ps__detail__block">
                    <div className="cb__ps__detail__inner__block">
                      <Text3 text={t('Shipping_Handling')} color="#727272" />
                      <Price price={cartTotalData && cartTotalData.items_qty !== 0 ? cartTotalData?.base_shipping_amount : 0} currency={cartTotalData && cartTotalData?.base_currency_code} size="heading7" />
                    </div>
                    <div className="cb__ps__detail__inner__block">
                      <Text3 text={t('Sub_Total')} color="#727272" />
                      <Price price={cartTotalData && cartTotalData.items_qty !== 0 ? cartTotalData?.base_subtotal : 0} currency={cartTotalData && cartTotalData?.base_currency_code} size="heading7" />
                    </div>
                    <div className="cb__ps__detail__inner__block">
                      <Text3 text="Discount" color="#727272" />
                      <Price price={cartTotalData && cartTotalData.items_qty !== 0 ? cartTotalData?.discount_amount : 0} currency={cartTotalData && cartTotalData?.base_currency_code} size="heading7" />
                    </div>
                    <div className="cb__ps__detail__inner__block">
                      <Text3 text={t('Tax')} color="#727272" />
                      <Price price={cartTotalData && cartTotalData?.base_tax_amount} currency={cartTotalData && cartTotalData?.base_currency_code} size="heading7" />
                    </div>
                    <div className="cb__ps__detail__total__order__block">
                      <Heading6 text="Order Total" color="#000000" />
                      <Price price={cartTotalData && cartTotalData?.base_grand_total} currency={cartTotalData && cartTotalData?.base_currency_code} size="heading5" />
                    </div>
                    {/* <div className="col-6 cb__addToCart__button__block">
                      <div className="cb__addToCart__button">
                        <img
                          src={shopping_cart}
                          alt=""
                          className="cb__addToCart__icon"
                        />
                        {t("Add_To_Cart")}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {/* <NewsLetter /> */}
      {/* <Footer /> */}
    </>
  );
}

export default Create_Bundle_Page;
