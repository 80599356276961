import React, { useEffect, useState } from 'react';

const StoreCitySelection = ({
    t,
    cities,
    onStoreCitySelect,
    cityModal,
    setSelectStoreCity,
    selectStoreCity
}) => {
    const [query, setQuery] = useState('');
    const [filteredCities, setFilteredCities] = useState(cities);

    useEffect(() => {
        if (!cityModal) {
            setQuery('');
        }
    }, [cityModal]);

    useEffect(() => {
        if (cities) {
            const uniqueStoresArray = [
                ...new Map(cities?.map((item) => [item.city?.trim(), item]))?.values(),
            ];
            setFilteredCities(uniqueStoresArray?.filter((item) => item.city?.toLowerCase().indexOf(query.toLowerCase()) > -1));
        }
    }, [query, cities]);

    const select = (item) => {
        setSelectStoreCity(item);
    };

    return (
        <div className="bundle_city_wrapper" key="bundle_city_wrapper">
            <div className="col-md-12 search__input__block">
                <input
                    id="city_search"
                    name="city_search"
                    placeholder={`${t("search_cities")}`}
                    onFocus={({ target }) =>
                        target.parentElement.classList.add("focused")
                    }
                    onBlur={({ target }) =>
                        target.parentElement.classList.remove("focused")
                    }
                    value={query}
                    onChange={({ target }) => setQuery(target.value)}
                />
                <img
                    // onClick={() => clearSearchInput()}
                    // src={cancel_grey}
                    alt=""
                    className="cancel__button"
                />
            </div>
            <div className="items">
                {filteredCities?.map((item) => (
                    <div onClick={() => select(item)} className="loaction-item-box">
                        <label>
                            <span>{item?.city || ''}</span>
                        </label>
                        <input
                            style={{ float: 'right' }}
                            type="radio"
                            className="delivery__input__check"
                            name="addressTypeBtn"
                            value={item}
                            id="home"
                            checked={
                                selectStoreCity?.city === item?.city ? true : false
                            }
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StoreCitySelection;
