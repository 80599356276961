import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import * as services from "../../services/services";
import "../../SCSS/MobilePages/_mobileCheckoutPage.scss"


import signin_initial from "../../assets/Icon/signin_inprogress.svg";
import signin_inprogress from "../../assets/Icon/signin_inprogress.svg";
import signin_done from "../../assets/Icon/signin_done.svg";
import delivery_initial from "../../assets/Icon/delivery_initial.svg";
import delivery_inprogress from "../../assets/Icon/delivery_inprogress.svg";
import delivery_done from "../../assets/Icon/delivery_done.svg";
import payment_initial from "./../../assets/Icon/payment_initial.svg";
import payment_inprogress from "./../../assets/Icon/payment_inprogress.svg";
import payment_done from "./../../assets/Icon/payment_done.svg";
import done from "../../assets/Icon/done.svg";
import shipping from "../../assets/Icon/shipping.svg";
import pickup_store from "../../assets/Icon/pickup_store.svg";
import edit_box_white from "../../assets/Icon/edit_box_white.svg";
import delete_box_white from "../../assets/Icon/delete_box_white.svg";

import Heading7 from '../../Components/Font/Heading7';
import Text4 from '../../Components/Font/Text4';
import BundleModal from '../../Components/MostSharedComponent/BundleModal';
import Order_Summary_New from '../Order_Summary_New';
import OpeningHoursModal from '../../Components/Common/OpeningHoursModal';
import Virtual_Product_Checkout_Page from '../Virtual_Product_Checkout_Page';
import { addToCart, deleteFromCart, getEstimateShippingMethods, updateShippingInformation, updateShippingInformationSuccess } from '../../services/cart.service';
import Heading6 from '../../Components/Font/Heading6';
import { loadCartData, loadCitiesLocationData } from '../../redux/appAction';
import { checkoutEvents } from '../../Components/helpers/utils/ga/checkoutEvents';
import Text5 from '../../Components/Font/Text5';
import { BsPlusLg, BsSearch } from 'react-icons/bs';
import { FaCaretDown } from 'react-icons/fa';
import Price from '../../Components/Font/Price';
import Guest_Checkout_Page from '../Guest_Checkout_Page';
import Heading4 from '../../Components/Font/Heading4';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { getCustomerLoginDetails } from '../../Components/helpers/utils/getCustomerLoginDetails';
import Heading5 from '../../Components/Font/Heading5';
import { acceptOnlyString } from '../../Components/helpers/utils/regexp';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import { isMobileNumber } from '../../Components/helpers/utils/validators';
import { Spinner } from '../../Components/helpers/utils/spinner';
import AddressPopupModal from '../../Components/Popup/AddressPopupModal';
import SignSignupTabModal from '../../Components/Common/SignSignupTabModal';
import "./../../SCSS/_checkoutSignIn.scss";


let NoInsallment = false;

const MobileCheckoutSignin = ({
  cartDetail,
  shippingCustomMsg,
  setShippingCustomMsg,
  bundleCity,
  handleSetOrderComment,
  cartTotalData,
  loyaltyEarnType,
  loyaltyEarnTypeId,
  storeConfig,
  authRandom,
  handleChangeCartIconTotal
}) => {

  const [iconType, setIconType] = useState({
    signin: "done",
    delivery: "inprogress",
    payment: "initial",
  });

  const [modalShow, setModalShow] = React.useState(false);
  const [openingHoursData, setOpeningHoursData] = React.useState("");
  const [custEmailAddress, setCustEmailAddress] = useState("");
  const [deliveryType, setDeliveryType] = useState([]);
  const [preferenceTab, setPreferenceTab] = useState("");
  const [errMsg, setErrMsg] = useState({
    deliveryAddressList: "",
    deliveryPreferencesType: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    addressLine1: "",
    // addressLine2: "",
    city: "",
    state: "",
    // postCode: "",
    country: "",
    primary: false,
    landmark: "",
  });
  const [storePickupError, setStorePickupError] = useState("");
  const [deliveryPreferencesType, setDeliveryPreferencesType] = useState("");
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [addressData, setAddressData] = useState(false);
  const [viewMoreAddressBtn, setViewMoreAddressBtn] = useState(false);
  const [selectedAddressId, setSelectedAddressID] = useState(0);
  const [editAddressData, setEditAddressData] = useState("");
  const [addressPopup, setAddressPopup] = useState(false);
  const [addressPopupType, setAddressPopupType] = useState("add");
  const [storeCitiesLocationData, setStoreCitiesLocationData] = useState([]);
  const [cityServices, setCityServices] = useState();
  const [selectedBundleService, setSelectedBundleService] = useState({});
  const [bundleEligible, setBundleEligible] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [filteredStores, setFilteredStores] = useState([]);
  const [loaderOn, setLoaderOn] = useState(false);
  const [address, setAddress] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    addressLine1: "",
    // addressLine2: "",
    city: "",
    state: "",
    // postCode: "",
    country: "",
    primary: false,
    landmark: "",
    addressType: "1",
  });
  const [storeCountriesLocationData, setStoreCountriesLocationData] = useState([]);
  const [latestDistrictList, setLatestDistrictList] = useState([]);
  const [editId, setEditId] = useState("");
  const [renderPage, setRenderPage] = useState(false);
  const [loginMode, setLoginMode] = useState("");
  const [loginWrapper, setLoginWrapper] = useState(false);
  const [loginPopup, setLoginPopup] = useState(false);
  const [userLoginPopup, setUserLoginPopup] = useState(false);
  const [categoryPopup, setCategoryPopup] = useState(false);

  useEffect(() => {
    // debugger

    function init() {
      const latestLoginWrapper = localStorage.getItem("loginWrapper");
      const latestLoginPopup = localStorage.getItem("loginPopup");

      setLoginWrapper(JSON.parse(latestLoginWrapper));
      setLoginPopup(JSON.parse(latestLoginPopup));
    }
    init();
  }, [
    JSON.parse(localStorage.getItem("loginWrapper")),
    JSON.parse(localStorage.getItem("loginPopup")),
    JSON.parse(localStorage.getItem("loginMode")),
  ]);

  const openLoginWrapper = (mode) => {
    // desktop
    setLoginMode(mode);
    setLoginWrapper(true);
    setUserLoginPopup(false);
    localStorage.setItem("loginMode", JSON.stringify(mode));
    localStorage.setItem("loginWrapper", JSON.stringify(true));
    localStorage.setItem("loginPopup", JSON.stringify(true));
  };
  useEffect(() => {
    setLoginMode(loginMode);
  }, [loginWrapper]);

  const openLoginPopup = () => {
    setLoginPopup(userLoginPopup ? false : true);
    localStorage.setItem("loginPopup", JSON.stringify(true));
    setCategoryPopup(false);
    setLoginMode("");
  };

  const openProductPopup = () => {
    setCategoryPopup(!categoryPopup);
    setLoginPopup(false);
  };

  const allSelectedServices = [];
  const bundleProductData = [];
  let bundleData = [];
  const data_bundle_id = [];
  const bundle_item_counts = [];

  const dispatch = useDispatch()
  const { t } = useTranslation();
  const navigate = useNavigate();
  const closeDialogBox = useRef();

  const T_REQ_FIRST_NAME = t("First_Name_is_required");
  const T_REQ_LAST_NAME = t("Last_Name_is_required");
  // const T_REQ_COUNTRY = t("Country_is_required");
  const T_REQ_MOBILE_NUMBER = t("Mobile_Number_is_required");
  const T_REQ_ADDRESS_LINE_1 = t("Address_Line_1 is_required");
  // const T_REQ_ADDRESS_LINE_2 = t("Address_Line_2_is_required");
  const T_REQ_CITY_TOWN = t("City/Town_is_required");
  // const T_REQ_STATE = t("State_is_required");
  const T_REQ_LANDMARK = t("Landmark_is_required");
  // const T_REQ_POST_CODE = t("Post_Code_is_required");
  const T_INVALID_MOBILE_NUMBER = t("Invalid_Mobile_Number");
  const T_REQ_DISTRICT = t("District_is_required");

  const deliveryShippingInfo = useSelector((state) => state.appData.deliveryShippingInfo);
  const { customerDetails } = useSelector((state) => state.customerReducer);
  const cityLocationData = useSelector((state) => state.appData.cityLocationData);
  const cartData = useSelector((state) => state.appData.cartData);
  const { loadingStartEnd } = useSelector((state) => state.commonReducer);
  const storesLocationData = useSelector((state) => state.appData.storesLocationData);
  const { customerAddressList } = useSelector((state) => state.customerAddressReducer);
  const countriesLocationData = useSelector((state) => state.appData.countriesLocationData);

  const contact_prefill = localStorage.getItem("contact_prefill");

  useEffect(() => {
    if (customerDetails === "") {
      if (contact_prefill !== null) {
        setCustEmailAddress(JSON.parse(contact_prefill).email);
      } else {
        // setCustEmailAddress("");
      }
    } else {
      setCustEmailAddress(customerDetails.email);
    }
  }, [customerDetails, contact_prefill]);

  useEffect(() => {
    if (deliveryShippingInfo !== "") {
      // setIconType({ ...iconType, delivery: "done", payment: "inprogress" });
      // navigate("/checkout/payment")
      dispatch(updateShippingInformationSuccess(""));
    }
  }, [deliveryShippingInfo]);

  useEffect(() => {
    if (storesLocationData) {
      let commonStores, filteredStoresData
      const storesSets = cartData?.items && cartData?.items.map(elem => new Set(elem.extension_attributes.available_stores));
      if (storesSets && storesSets.length > 0) {
        commonStores = [...storesSets.reduce((acc, curr) => new Set([...acc].filter(x => curr.has(x))))];
      }
      if (commonStores) {
        filteredStoresData = storesLocationData && storesLocationData.filter((store) =>
          commonStores.includes(store?.storeCode.toLowerCase())
        );
      }
      const queryValue = searchData.trim();
      let filterdata = filteredStoresData && filteredStoresData.filter((item) => item.name.toLowerCase().includes(queryValue.toLowerCase()));
      setFilteredStores(filterdata)
    }
  }, [cartData?.items, searchData, storesLocationData]);

  useEffect(() => {
    if (cityLocationData) {
      let cityList = [];
      cityLocationData &&
        cityLocationData.map((val, i) => {
          let cityData = {
            value: val.cityCode,
            label: val.cityCode,
            allData: val,
          };
          cityList.push(cityData);
        });
      setStoreCitiesLocationData(cityList);
    }
  }, [cityLocationData]);

  useEffect(() => {
    if (countriesLocationData) {
      let countryList = [];
      countriesLocationData &&
        countriesLocationData.map((val, i) => {
          let countryData = {
            id: val.id,
            label: val.full_name_english,
          };
          countryList.push(countryData);
        });
      setStoreCountriesLocationData(countryList);
    }
  }, [countriesLocationData]);

  useEffect(() => {
    let districts = storeCitiesLocationData.filter(
      (val, i) => val.allData.cityCode === address.city
    )?.[0]?.allData?.districts;
    if (districts !== undefined) {
      let newArray = [];
      districts &&
        districts.map((item) => {
          let newObj = { label: item.label, value: item.code };
          newArray.push(newObj);
        });
      setLatestDistrictList(newArray);
    }
  }, [address.city, address.state]);

  useEffect(() => {
    if (customerAddressList) {
      let updateAddressData = [];
      customerAddressList &&
        customerAddressList.map((val, i) => {
          let addreDetails = {
            id: val.id,
            isDefault: val.primary,
            userName: `${val.firstname} ${val.lastname}`,
            adddress: `${val.street[0]} ${val.street[1]} ${val.city} ${val.postcode} ${val.country_id}`,
            contact: val.telephone,
            details: val,
          };
          updateAddressData.push(addreDetails);
          if (val.primary === true) {
            setSelectedAddressID(i);
          }
        });
      setAddressData(updateAddressData);
    }
  }, [customerAddressList]);

  useEffect(() => {
    if (customerDetails === "") {
      if (localStorage.getItem("user_mode") === 'guest_user') {
        localStorage.setItem("loginWrapper", JSON.stringify(false));
        localStorage.setItem("loginMode", JSON.stringify(""));
        localStorage.setItem("loginPopup", JSON.stringify(false));
      } else {
        openLoginWrapperFromAnywhere();
      }
      setIconType({ ...iconType, signin: "inprogress" });
    } else {
      dispatch(services.getCustomerAddressList());
      setIconType({ ...iconType, signin: "done" });
    }
  }, [customerDetails]);

  useEffect(async () => {
    if (storeConfig !== undefined && storeConfig !== "") {
      dispatch(services.loaderStart())
      const data = await getEstimateShippingMethods();
      if (data) {
        let shippingMethods = data["shipping-methods"];
        const propertyNames = Object.keys(shippingMethods);
        let deliveryData = [];
        propertyNames &&
          propertyNames.map((val, i) => {
            let deliveryInfo = {
              id: shippingMethods[val].shipping_method_code,
              type: shippingMethods[val].title,
              protectionText: shippingMethods[val].head_line,
              price: shippingMethods[val].shipping_cost,
              is_available: shippingMethods[val]?.is_available
            };
            if (shippingMethods[val].shipping_method_code === 'storepickup' && storeConfig === "1") {
              deliveryData.push(deliveryInfo);
            } else if (shippingMethods[val].shipping_method_code !== 'storepickup') {
              deliveryData.push(deliveryInfo);
            }
          });
        setDeliveryType(deliveryData);
        const deliveryDataIds = deliveryData?.filter((item) => item?.is_available).map((item) => item?.id)
        let lastDt = localStorage.getItem("last-dt");
        if ((deliveryDataIds).includes(lastDt)) {
          setDeliveryPreferencesType(lastDt)
        } else {
          setDeliveryPreferencesType(deliveryData[0].id)
        }
      }
      dispatch(services.loaderEnd())
    }
  }, [storeConfig]);

  useEffect(() => {
    if (bundleData) {
      bundleData = cartDetail?.items?.filter(
        (item) => item.product_type === "bundle"
      );
      for (let index = 0; index < bundleData?.length; index++) {
        data_bundle_id.push(bundleData[index]?.item_id);
        bundle_item_counts.push(bundleData[index]);
      }
      bundleData?.map((item1) =>
        item1?.product?.bundle_product_options?.map((itm) =>
          bundleProductData.push(itm)
        )
      );
    }
  }, [bundleData]);

  useEffect(() => {
    const deliveryDataIds = deliveryType?.filter((item) => item?.is_available).map((item) => item?.id)
    let lastDt = localStorage.getItem("last-dt");
    if (deliveryType && deliveryType.length > 0 && cartData && !deliveryDataIds.includes(lastDt)) {
      if (deliveryType &&
        deliveryType.filter((dev) => dev.id === "flatrate" && dev?.is_available)
          .length > 0) {
        setPreferenceTab("home")
        localStorage.setItem("last-dt", "flatrate");
      } else if (deliveryType &&
        deliveryType.filter((dev) => dev.id === "storepickup" && dev?.is_available)
          .length > 0) {
        setPreferenceTab("storepickup")
        setDeliveryPreferencesType("storepickup")
        localStorage.setItem("last-dt", "storepickup");
      } else if (deliveryType && deliveryType.length > 0 &&
        (deliveryType.filter((dev) => dev.id === "flatrate" && dev?.is_available)
          .length < 1) && (deliveryType &&
            deliveryType.filter((dev) => dev.id === "storepickup" && dev?.is_available)
              .length < 1) && !cartData?.is_virtual) {
        navigate('/cart')
      }
    }
  }, [deliveryType, cartData])

  useEffect(() => {
    let lastDt = localStorage.getItem("last-dt");
    if (lastDt === "flatrate") {
      lastDt = "home";
    } else if (lastDt === "storepickup") {
      lastDt = "storepickup";
    } else {
      lastDt = "";
    }
    setPreferenceTab(lastDt);
    const deliveryRef = localStorage.getItem("deliveryPref");
    if (deliveryRef) {
      setDeliveryPreferencesType(deliveryRef);
    }
  }, [])

  useEffect(() => {
    if (cartData) {
      setBundleEligible(
        cartData?.items?.filter((item) => item?.product_type === "bundle")
      );
    }
    const totals_items = cartData?.totals_data?.items;
    const cartItems = cartData?.items?.map((item) =>
      totals_items?.find((t) => t.item_id === item.item_id)
    );
    cartItems?.map((itm) =>
      itm?.extension_attributes?.options_bundle
        ?.filter((itm) => itm.title.toLowerCase().includes("service"))
        .map((service) => {
          allSelectedServices.push(service);
          setSelectedBundleService(service);
          return service;
        })
    );
    setCityServices(allSelectedServices);
  }, [
    cartData,
    deliveryType,
    // selectedShippingAddress,
    // selectedBillingAddress,
    // deliveryStore,
  ]);

  useEffect(() => {
    if (editAddressData && addressPopupType === "update") {
      let landmarkData = "";
      let addressTypeData = "1";
      if (editAddressData?.details?.custom_attributes !== undefined) {
        let getLandmark = editAddressData?.details?.custom_attributes.filter(
          (val) => val.attribute_code === "landmark"
        );
        if (getLandmark.length > 0) {
          landmarkData = getLandmark[0].value;
        }
        let getAddressType = editAddressData?.details?.custom_attributes.filter(
          (val) => val.attribute_code === "address_types"
        );
        if (getAddressType.length > 0) {
          addressTypeData = getAddressType[0].value;
        }
      }
      setAddress({
        ...address,
        firstName: editAddressData.details.firstname,
        lastName: editAddressData.details.lastname,
        mobileNumber: editAddressData.details.telephone,
        addressLine1: editAddressData.details.street?.[0],
        addressLine2: editAddressData.details.street?.[1],
        city: editAddressData.details.city,
        state:
          editAddressData?.details?.postcode !== undefined
            ? editAddressData.details.postcode
            : "",
        // postCode: editAddressData.details.postcode,
        primary:
          editAddressData?.details?.primary !== undefined
            ? editAddressData.details.primary
            : false,
        landmark: landmarkData,
        addressType: addressTypeData,
      });
      setEditId(editAddressData.details.id);
      setRenderPage(true);
    }
  }, [editAddressData]);

  useEffect(() => {
    if (renderPage === true) {
      setRenderPage(!renderPage);
    }
  }, [renderPage]);

  const openLoginWrapperFromAnywhere = () => {
    // console.log(document.querySelector(".login__popup__container__disable"));
    // reloadingHeader()

    if (customerDetails === "") {
      const element = document.querySelector(
        ".signin__signup__popup__disable"
      );
      if (element !== null) {
        element.classList.remove("signin__signup__popup__disable");
        element.classList.add("signin__signup__popup");
      }
      localStorage.setItem("loginWrapper", JSON.stringify(true));
      localStorage.setItem("loginMode", JSON.stringify("signin"));
      localStorage.setItem("loginPopup", JSON.stringify(true));
      window.scrollTo(500, 0);
    }
  };

  const handleChangeCreateBillingAddress = (email) => {
    setIconType({ ...iconType, delivery: "done", payment: "inprogress" });
    // if (paymentMethods.length > 0) {
    //   let getFirstMethod = paymentMethods.filter(val => val.is_available === true);
    //   if (getFirstMethod.length > 0) {
    //     setUserPaymentMethod(getFirstMethod[0].code);
    //   }
    // }
    setCustEmailAddress(email);
    dispatch(updateShippingInformationSuccess(""));
  };

  const handleChangePreferenceTab = (name) => {
    const lastDt = name === "home" ? "flatrate" : "storepickup";
    localStorage.setItem("last-dt", lastDt);
    setPreferenceTab(name);
    setErrMsg({
      ...errMsg,
      deliveryPreferencesType: "",
    });
    setStorePickupError("");
    if (name === "home") {
      setDeliveryPreferencesType(deliveryType?.[0].id);
    } else {
      setDeliveryPreferencesType(name);
    }
  };

  const selectAddress = (addIndex, addId, add) => {
    setSelectedAddressID(addIndex);
    setEditAddressData(add);
    const deliveryRef = localStorage.getItem("deliveryPref");
    if (deliveryRef) {
      setDeliveryPreferencesType(deliveryRef);
    } else {
      setDeliveryPreferencesType(deliveryType[0].id);
    }
  };

  const openNewAddressPopup2 = (popupType, addIndex, addId, add) => {
    setAddressPopup(true);
    setAddressPopupType(popupType);
    if (popupType === "update") {
      setSelectedAddressID(addIndex);
      setEditAddressData(add);
    }
  };

  const setNoInsallment = (val) => {
    NoInsallment = val
  }

  const deleteAddress = (deleteId) => {
    let params = {
      addressId: deleteId,
    };
    dispatch(services.deleteCustomerAddress(params));
  };

  const getDeliveryMethodByCity = (cityName, shippingType) => {
    let shippingIsEnable = false;
    if (shippingType === "express") {
      let expShipping =
        storeCitiesLocationData &&
        storeCitiesLocationData.filter(
          (val) =>
            val.allData.cityCode === cityName &&
            val.allData.expressShipping === "1"
        );
      if (expShipping.length > 0) {
        shippingIsEnable = true;
      }
    }
    if (shippingType === "lighting") {
      let lightShipping =
        storeCitiesLocationData &&
        storeCitiesLocationData.filter(
          (val) =>
            val.allData.cityCode === cityName &&
            val.allData.lightingShipping === "1"
        );
      if (lightShipping.length > 0) {
        shippingIsEnable = true;
      }
    }
    return shippingIsEnable;
  };

  const handleChangeDeliveryPref = (e, value) => {
    setDeliveryPreferencesType(value);
    localStorage.setItem("deliveryPref", value);

    setSelectedStoreId("");
    setStorePickupError("");
  };

  const handleChangePickupStore = (storeId) => {
    // console.log("storeId ", storeId);
    setSelectedStoreId(storeId);
  };

  const handleClickOpeningHour = (valData) => {
    setOpeningHoursData(valData);
    setModalShow(true);
  };

  const removeServiceFromCart = async () => {
    await deleteFromCart({ items: data_bundle_id });
    const payload = [];
    const filteredProductData = bundleProductData?.filter(
      (item) => item?.required === "1"
    );
    for (let index = 0; index < filteredProductData?.length; index++) {
      const quantity = bundle_item_counts[index];
      const object = {
        sku: filteredProductData[index].sku,
        qty: quantity?.qty,
        bundle_options: [
          {
            option_id: +filteredProductData[index]?.product_links[0]?.option_id,
            option_qty: 1,
            option_selections: [
              +filteredProductData[index]?.product_links[0]?.id,
            ],
          },
        ],
      };
      payload.push(object);
    }

    await addToCart({ items: payload });
  }

  const proceedPayments = async () => {
    NoInsallment = true;
    await removeServiceFromCart();
    setShowModal(false);
    const form = document.getElementById("delivery-form-mobile");
    form.click();
  };

  const guestPayment = async () => {
    NoInsallment = true;
    await removeServiceFromCart();
    setShowModal(false);
    const form = document.getElementById('guest_payment');
    form.click();
  };

  const customerData = getCustomerLoginDetails();

  const openNewAddressPopup = (popupType, addIndex, addId, add) => {
    dispatch(loadCitiesLocationData());
    setAddressPopup(true);
    setAddressPopupType(popupType);
    setSelectedAddressID(addIndex);
    setEditAddressData(add);
  };

  const closeLoginPopup = () => {
    if (document.querySelector(".address__popup__container")) {
      // reloadingHeader()
      const element = document.querySelector(".address__popup__container");
      if (element !== null) {
        element.classList.remove("address__popup__container");
        element.classList.add("address__popup__container__disable");
      }
    }
    setAddressPopup(false);
  };

  const resetFormValue = () => {
    let formValue = {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      // postCode: "",
      country: "SA",
      primary: false,
      landmark: "",
    };
    setAddress(formValue);
  };
  const resetFormErr = () => {
    let formErr = {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      addressLine1: "",
      // addressLine2: "",
      city: "",
      state: "",
      // postCode: "",
      country: "",
      primary: false,
      landmark: "",
    };
    setErrMsg(formErr);
  };

  const handleClosePopup = () => {
    closeLoginPopup();
    resetFormValue();
    resetFormErr();
  };

  const closeModal = () => {
    setShowModal(false);
    window.scrollTo(0, 0);
  };

  const validateAddressForm = (event, newErrObj, name, value) => {
    //A function to validate each input values
    switch (name) {
      case "firstName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_FIRST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "lastName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_LAST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "mobileNumber":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_MOBILE_NUMBER };
        } else {
          let isValidNumber = isMobileNumber(value);
          if (isValidNumber === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_MOBILE_NUMBER };
          } else {
            newErrObj = { ...newErrObj, [name]: "" };
          }
        }
        break;
      // case 'country':
      //   if (value === "") {
      //     newErrObj = { ...newErrObj, [name]: T_REQ_COUNTRY }
      //   } else {
      //     newErrObj = { ...newErrObj, [name]: '' }
      //   }
      //   break;
      case "addressLine1":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_ADDRESS_LINE_1 };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      // case "addressLine2":
      //   if (value === "") {
      //     newErrObj = { ...newErrObj, [name]: T_REQ_ADDRESS_LINE_2 };
      //   } else {
      //     newErrObj = { ...newErrObj, [name]: "" };
      //   }
      //   break;
      case "city":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_CITY_TOWN, state: "" };
        } else {
          newErrObj = { ...newErrObj, [name]: "", state: "" };
        }
        break;
      case "state":
        // let getDistrictList = getLatestDistrictList(address.city);
        if (value === "" && address.city !== "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_DISTRICT };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      // case 'postCode':
      //   if (value === "") {
      //     newErrObj = { ...newErrObj, [name]: T_REQ_POST_CODE }
      //   } else {
      //     newErrObj = { ...newErrObj, [name]: '' }
      //   }
      //   break;
      case "landmark":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_LANDMARK };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      default:
        break;
    }
    return newErrObj;
  };

  const handleChange = async (event, keyName) => {
    let value = event;
    let name = event;
    if (keyName === "primary") {
      value = event.target.checked;
      name = "primary";
    } else if (keyName === "mobileNumber") {
      value = event;
      name = "mobileNumber";
    } else if (keyName === "city" || keyName === "state") {
      value = event.value;
      name = keyName;
    } else if (
      event.target.name === "firstName" ||
      event.target.name === "lastName"
    ) {
      value = acceptOnlyString(event.target.value, true);
      name = event.target.name;
    } else {
      value = event.target.value;
      name = event.target.name;
    }
    setAddress({ ...address, [name]: value });
    let manageErrMsg = validateAddressForm(event, errMsg, name, value);
    setErrMsg(manageErrMsg);
  };

  const allFeildValidate = (validateFeild, allErrMsg) => {
    let checkValueStatus = [];
    let checkErrStatus = [];

    let checkCardStatus = false;
    if (
      checkValueStatus.length === validateFeild.length &&
      checkErrStatus.length === validateFeild.length
    ) {
      checkCardStatus = true;
    }
    let returnData = {
      allErrMsg: allErrMsg,
      checkCardStatus: checkCardStatus,
    };

    return returnData;
  };

  const handleSubmit = () => {
    setLoaderOn(true);
    let validateFeild = [
      "firstName",
      "lastName",
      "mobileNumber",
      "addressLine1",
      // "addressLine2",
      "city",
      // "state",
      // "country",
      // "postCode",
      "landmark",
    ];

    let getDistrictList = latestDistrictList;
    if (getDistrictList.length > 0) {
      validateFeild.push("state");
    }

    let formStatus = allFeildValidate(validateFeild, errMsg);
    setErrMsg(formStatus.allErrMsg);

    let distDataManage = "";
    if (getDistrictList.length > 0 && address.state !== "") {
      distDataManage = address.state;
    }
    if (formStatus.checkAddressStatus === true) {
      if (editId !== "" && addressPopupType === "update") {
        let params = {
          addressId: addressPopupType === "update" ? editId : "",
          firstName: address.firstName,
          lastName: address.lastName,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          city: address.city,
          telephone: address.mobileNumber,
          primary: address.primary,
          // countryId: address.country ? address.country : "SA",
          countryId: "SA",
          postCode: distDataManage,
          regionId: 0,
          custom_attributes: [
            {
              attribute_code: "landmark",
              value: address.landmark,
            },
            {
              attribute_code: "address_types",
              value: address.addressType === "" ? "1" : address.addressType,
            },
          ],
        };
        dispatch(services.updateCustomerAddress(params));
      } else {
        let params = {
          firstName: address.firstName,
          lastName: address.lastName,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          city: address.city,
          telephone: address.mobileNumber,
          primary: address.primary,
          // countryId: address.country ? address.country : "SA",
          countryId: "SA",
          postCode: distDataManage,
          regionId: 0,
          custom_attributes: [
            {
              attribute_code: "landmark",
              value: address.landmark,
            },
            {
              attribute_code: "address_types",
              value: address.addressType === "" ? "1" : address.addressType,
            },
          ],
        };
        dispatch(services.createCustomerAddress(params));
      }
    }
  };

  function Position(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return currenttop > 200 ? [currenttop - 200] : [0];
    }
  }

  useEffect(() => {
    const errorElement = document.querySelector(".invalid__message");
    if (document.activeElement.tagName !== "INPUT" && errorElement) {
      window.scrollTo(0, Position(errorElement));
    }

  }, [errMsg, storePickupError])

  return (
    <>
      {!authRandom ?
        <div className="row checkout__login__main__block">
          <div
            onClick={() => openLoginWrapperFromAnywhere()}
            className="col-2 checkout__signin__button"
          >
            <img
              src={
                iconType.signin === "inprogress"
                  ? signin_inprogress
                  : iconType.signin === "done"
                    ? signin_done
                    : signin_initial
              }
              alt=""
            />
            {iconType.signin === "done" ? (
              <img className="done__icon" src={done} alt="done" />
            ) : (
              ""
            )}
          </div>
          <div className="col-3 checkout__middle__line__block"></div>
          <div
            // onClick={() => handleChangeClassName("delivery")}
            className="col-2 checkout__delivery__button"
          >
            <img
              src={
                iconType.delivery === "inprogress"
                  ? delivery_initial
                  : iconType.delivery === "done"
                    ? delivery_done
                    : delivery_initial
              }
              alt=""
            />
            {iconType.delivery === "done" ? (
              <img className="done__icon" src={done} alt="done" />
            ) : (
              ""
            )}
          </div>
          <div className="col-3 checkout__middle__line__block"></div>
          <div
            className="col-2 checkout__payment__button"
          >
            <img
              src={
                iconType.payment === "inprogress"
                  ? payment_inprogress
                  : iconType.payment === "done"
                    ? payment_done
                    : payment_initial
              }
              alt=""
            />
            {iconType.payment === "done" ? (
              <img className="done__icon" src={done} alt="done" />
            ) : (
              ""
            )}
          </div>
          {modalShow &&
            <OpeningHoursModal
              modalShow={modalShow}
              val={openingHoursData}
              onHide={() => setModalShow(false)}
            />
          }
          {/* checkout signin form section */}
          <div className="col-md-12">
          <div className='mb_checkout__signin__form__area'>
            {/* <h2>mobile sign in&up form</h2> */}
            
            <SignSignupTabModal
              handleChangeCartIconTotal={handleChangeCartIconTotal}
              loginMode={loginMode}
              loginWrapper={loginWrapper}
              // loginPopup={loginPopup}
              setLoginMode={setLoginMode}
            /> 
            </div>
          </div>
          {/* package Summary */}
          <div className="col-md-12 col-xl-4  checkout__right__block">
            {typeof cartDetail === "object" &&
              typeof cartTotalData === "object" && (
                <Order_Summary_New
                  cartDetail={cartDetail}
                  cartTotalData={cartTotalData}
                  loyaltyEarnType={loyaltyEarnType}
                  loyaltyEarnTypeId={loyaltyEarnTypeId}
                  loyaltyRadioName="loyaltyEarnTypeMobile"
                />
              )}
          </div>
          <div className="mb__checkout__modal">
            {/* change address popup start */}
            {/* <!-- Modal --> */}
            <div
              className="modal mb__bottom_popup"
              id="changeAddressModal"
              tabIndex="-1"
              aria-labelledby="changeAddressModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog mb__dialog__end modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <Heading5 text={t("SELECT_DELIVERY_ADDRESS")} />
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-footer border-top-0">
                    <button
                      type="button"
                      className="btn btn-border btn__border__black w-100 d-block mb-2"
                      data-bs-target="#addAddressModal"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                    >
                      {t("ADD_NEW_ADDRESS")}
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="custom__checkbox mb-3">
                      {addressData &&
                        addressData
                          .filter((val, i) =>
                            viewMoreAddressBtn === false
                              ? i < 3
                              : addressData.length
                          )
                          .map((add, addIndex) => {
                            return (
                              <label
                                key={add.id}
                                htmlFor="exampleCheck1"
                                className="custom__checkmark p-3 mb-3"
                              >
                                <div
                                  className={
                                    selectedAddressId === addIndex
                                      ? "selected__address__inner__block"
                                      : "address__inner__block"
                                  }
                                  onClick={() =>
                                    selectAddress(addIndex, add.id, add)
                                  }
                                >
                                  {add.isDefault ? (
                                    <p className="p-1 d-inline-block mb__adress__tag">
                                      {t("Default")}
                                    </p>
                                  ) : (
                                    <p className="p-1 d-inline-block mb__adress__tag">
                                      {t("NONE")}
                                    </p>
                                  )}
                                  <div className="row">
                                    <div className="col-8">
                                      <Heading7 text={add.userName} />
                                      <address className="mb-3 text-wrap">
                                        {add.adddress}
                                      </address>
                                      <p>{add.contact}</p>
                                    </div>
                                    <div className="col">
                                      <button
                                        onClick={() =>
                                          openNewAddressPopup(
                                            "update",
                                            addIndex,
                                            add.id,
                                            add
                                          )
                                        }
                                        className="btn btn-outline-secondary shadow-none"
                                        data-bs-target="#addAddressModal"
                                        data-bs-toggle="modal"
                                        data-bs-dismiss="modal"
                                      >
                                        {t("Edit")}
                                      </button>
                                    </div>
                                  </div>
                                  <button className="btn btn__primary__orange w-100">
                                    {t("DELIVER_TO_THIS_ADDRESS ")}
                                  </button>
                                </div>
                              </label>
                            );
                          })}
                    </div>
                    {/* <div className="custom__checkbox">
                  <input
										type="checkbox"
										className="form-check-input"
										id="exampleCheck2"
									/>
                  <label htmlFor="exampleCheck2" className="custom__checkmark p-3">
                    <p className="p-1 d-inline-block mb__adress__tag">
                      {t("Office")}
                    </p>
                    <div className="row">
                      <div className="col-8">
                        <Heading7 text="John Doe" />
                        <address className="mb-3 text-wrap">
                          {t("21_West_52nd_Street_New_York")}
                        </address>
                        <p>{t("mobile_number")}</p>
                      </div>
                      <div className="col">
                        <button
                          className="btn btn-outline-secondary shadow-none"
                          data-bs-target="#addAddressModal"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                        >
                          {t("Edit")}
                        </button>
                      </div>
                    </div>
                    <button className="btn btn__primary__orange w-100">
                      {t("DELIVER_TO_THIS_ADDRESS")}
                    </button>
                  </label>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Modal end--> */}
            {/* change address popup end */}

            {/* add new address popup  start*/}
            {/* <!-- Modal --> */}
            <div
              className="modal mb__bottom_popup"
              id="addAddressModal"
              tabIndex="-1"
              aria-labelledby="addAddressModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog mb__dialog__end modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <Heading5
                      text={
                        addressPopupType === "add"
                          ? t("Add_New_Address")
                          : t("Update_Address")
                      }
                      color="#000000"
                    />
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Open"
                      ref={closeDialogBox}
                      onClick={handleClosePopup}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="mb-3">
                      <label htmlFor="Name" className="form-label">
                        <strong>{t("First_Name")}</strong>
                        <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                      </label>
                      <input
                        type="text"
                        placeholder={t("John")}
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        value={address.firstName}
                        onChange={(e) => handleChange(e)}
                      />
                      {errMsg.firstName && (
                        <p className="invalid__message">{errMsg.firstName}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Name" className="form-label">
                        <strong>{t("Last_Name")}</strong>
                        <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                      </label>
                      <input
                        type="text"
                        placeholder={t("Doe")}
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        value={address.lastName}
                        onChange={(e) => handleChange(e)}
                      />
                      {errMsg.lastName && (
                        <p className="invalid__message">{errMsg.lastName}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Mobile" className="form-label">
                        <strong>{t("Mobile_Number")}</strong>
                        <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                      </label>
                      <PhoneInput
                        inputProps={{
                          name: "mobileNumber",
                          required: true,
                          className: "form-control",
                        }}
                        country="sa"
                        onlyCountries={["sa"]}
                        masks={{ sa: ".. ... ...." }}
                        countryCodeEditable={false}
                        disableDropdown={true}
                        value={address.mobileNumber}
                        onChange={(e) => handleChange(e, "mobileNumber")}
                        className=""
                      />
                      {errMsg.mobileNumber && (
                        <p className="invalid__message">{errMsg.mobileNumber}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Mobile" className="form-label">
                        <strong>{t("Country")}</strong>
                        <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                      </label>
                      <select
                        name="country"
                        onChange={(e) => handleChange(e)}
                        value={address.country}
                        className="_customselect form-control"
                        disabled={true}
                      >
                        {/* <option key='no' value=''>Select Country</option> */}
                        {storeCountriesLocationData &&
                          storeCountriesLocationData.map((val, i) => {
                            return (
                              <React.Fragment key={val?.id}>
                                <option key={val.id} value={val.id}>
                                  {val.label}
                                </option>
                              </React.Fragment>
                            );
                          })}
                      </select>
                      {errMsg.country && (
                        <p className="invalid__message">{errMsg.country}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Mobile" className="form-label">
                        <strong>{t("Address_Line_1")}</strong>
                        <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                      </label>
                      <input
                        type="text"
                        placeholder={t("21_West_52nd_Street_New_York")}
                        className="form-control"
                        id="addressLine1"
                        name="addressLine1"
                        value={address?.addressLine1 || undefined}
                        onChange={(e) => handleChange(e)}
                      />
                      {errMsg.addressLine1 && (
                        <p className="invalid__message">{errMsg.addressLine1}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Mobile" className="form-label">
                        <strong>{t("Address_Line_2")}</strong>
                      </label>
                      <input
                        type="text"
                        placeholder={t("21_West_52nd_Street_New_York")}
                        className="form-control"
                        id="addressLine2"
                        name="addressLine2"
                        value={address?.addressLine2 || undefined}
                        onChange={(e) => handleChange(e)}
                      />
                      {errMsg.addressLine2 && (
                        <p className="invalid__message">{errMsg.addressLine2}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Mobile" className="form-label">
                        <strong>{t("City_Town")}</strong>
                        <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                      </label>
                      <Select
                        name="city"
                        onChange={(e) => handleChange(e, "city")}
                        value={
                          storeCitiesLocationData &&
                          storeCitiesLocationData.filter(
                            (val) => val.value === address.city
                          )?.[0]
                        }
                        options={storeCitiesLocationData}
                      />
                      {errMsg.city && (
                        <p className="invalid__message">{errMsg.city}</p>
                      )}
                    </div>
                    {latestDistrictList && latestDistrictList.length > 0 && (
                      <div className="mb-3">
                        <label htmlFor="Mobile" className="form-label">
                          <strong>{t("District")}</strong>
                          <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                        </label>
                        <Select
                          name="state"
                          onChange={(e) => handleChange(e, "state")}
                          value={
                            latestDistrictList.filter(
                              (val) => val.value === address.state
                            )?.[0]
                          }
                          options={latestDistrictList}
                        />
                        {errMsg.state && (
                          <p className="invalid__message">{errMsg.state}</p>
                        )}
                      </div>
                    )}
                    <div className="mb-3">
                      <label htmlFor="Mobile" className="form-label">
                        <strong>{t("Landmark")}</strong>
                        <sup style={{ fontSize: "12px", color: "red" }}>*</sup>
                      </label>
                      <input
                        type="text"
                        placeholder="Newyork"
                        className="form-control"
                        id="landmark"
                        name="landmark"
                        value={address.landmark}
                        onChange={(e) => handleChange(e)}
                      />
                      {errMsg.landmark && (
                        <p className="invalid__message">{errMsg.landmark}</p>
                      )}
                    </div>
                    {/* <hr className="my-5" />
                <Heading5 text="Delivery Time Preferences" />
                <p>
                  {t("Preferences_are_used_to_plan_your_delivery")}
                </p> */}
                    <div className="mb-3">
                      <label htmlFor="Address Type" className="form-label">
                        <strong>{t("Address_Type")}</strong>
                      </label>
                      <input
                        type="radio"
                        className="delivery__input__check"
                        name="addressType"
                        // defaultChecked={true}
                        checked={address.addressType === "1" ? true : false || true}
                        value="1"
                        id="home"
                        onChange={handleChange}
                      />
                      <label className="delivery__selection__text" htmlFor="home">
                        {t("Home")}
                      </label>
                      <input
                        type="radio"
                        className="delivery__input__check"
                        name="addressType"
                        // defaultChecked={false}
                        checked={address.addressType === "2" ? true : false}
                        value="2"
                        id="work"
                        onChange={handleChange}
                      />
                      <label className="delivery__selection__text" htmlFor="work">
                        {t("Work")}
                      </label>
                    </div>
                    <div className="mb-3">
                      <input
                        type="checkbox"
                        className="preferences__select__input__check"
                        name="primary"
                        value={address.primary}
                        checked={address.primary}
                        onChange={(e) => handleChange(e, "primary")}
                        id="preference_box"
                      />
                      <label className="form-label" htmlFor="preference_box">
                        {t("default_address")}
                      </label>
                    </div>
                  </div>
                  <div className="modal-footer border-top-0 justify-content-center">
                    <button
                      type="button"
                      className="btn__primary__orange btn btn-primary w-25 "
                      onClick={() => {
                        document.activeElement.blur();
                        setTimeout(() => {
                          handleSubmit()
                        }, 200)
                      }}
                    >
                      {loaderOn === true ? <Spinner /> : t("SAVE")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-border btn__border__black  w-25"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      onClick={handleClosePopup}
                    >
                      {t("CANCEL")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {addressPopup === true && (
            <AddressPopupModal
              show={addressPopup}
              closeLoginPopup={closeLoginPopup}
              editAddressData={editAddressData}
              popupType={addressPopupType}
            />
          )}
        </div> : null}
    </>
  )
}

export default MobileCheckoutSignin