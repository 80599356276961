import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import home_delivery_icon from "./../../assets/Icon/home-delivery.svg";
import store_black_icon from "./../../assets/Icon/store-black.svg";
import "./../../SCSS/Common/_productDeliveryType.scss";

function ProductDeliveryType({ product, availablityData, t }) {
    let productOutOfStockStatus;
    const [storeConfig, setStoreConfig] = useState("");
    const { configurationSettingData } = useSelector((state) => state.appData);

    useEffect(() => {
        if (configurationSettingData?.store_pickup !== undefined) {
            setStoreConfig(configurationSettingData?.store_pickup);
        }
    }, [configurationSettingData]);

    if (availablityData && availablityData["availablityData"] !== undefined) {
        productOutOfStockStatus =
            availablityData &&
            availablityData?.productOutOfStockStatus &&
            availablityData &&
            availablityData?.productOutOfStockStatus[product?.id];
    } else {
        productOutOfStockStatus = product?.productOutOfStockStatus
            ? product?.productOutOfStockStatus
            : false;
    }

    return (
        <>
            <div
                className="product__delivery__option__area"
                key={`type_${product?.id}`}
            >
                {(storeConfig === "1" || storeConfig === 1) &&
                    product?.item_type !== "virtual" &&
                    productOutOfStockStatus && (
                        <>
                            {availablityData &&
                                availablityData["availablityData"] !== undefined ? (
                                <>
                                    {availablityData &&
                                        availablityData?.availablityData?.length > 0 &&
                                        availablityData?.availablityData?.map(
                                            (item, index) =>
                                                item?.data?.eligible_for_home_delivery[product?.id] && (
                                                    <div key={`product_delivery_type_1_${index}`} className="product__delivery__itembox home__delivery_on">
                                                        <img src={home_delivery_icon} alt="delivery" />
                                                        <span>{t("Home_Delivery")}</span>
                                                    </div>
                                                )
                                        )}
                                </>
                            ) : (
                                <>
                                    {availablityData &&
                                        availablityData?.length > 0 &&
                                        availablityData?.map(
                                            (item, index) =>
                                                item?.data?.eligible_for_home_delivery[product?.id] && (
                                                    <div key={`product_delivery_type_2_${index}`} className="product__delivery__itembox home__delivery_on">
                                                        <img src={home_delivery_icon} alt="delivery" />
                                                        <span>{t("Home_Delivery")}</span>
                                                    </div>
                                                )
                                        )}
                                </>
                            )}

                            {availablityData &&
                                availablityData["availablityData"] !== undefined && product.orderType !== 'pre-order' ? (
                                <>
                                    {availablityData &&
                                        availablityData?.availablityData?.length > 0 &&
                                        availablityData?.availablityData?.map(
                                            (item, index) =>
                                                item?.data?.eligible_for_store_pickup[product?.id] &&
                                                productOutOfStockStatus && (
                                                    <div key={`product_delivery_type_3_${index}`} className="product__delivery__itembox store_on">
                                                        <img src={store_black_icon} alt="store-pickup" />
                                                        <span>{t("Store_Pickup")}</span>
                                                    </div>
                                                )
                                        )}
                                </>
                            ) : (
                                <>
                                    {availablityData &&
                                        availablityData?.length > 0 &&
                                        product.orderType !== 'pre-order' &&
                                        availablityData?.map(
                                            (item, index) =>
                                                item?.data?.eligible_for_store_pickup[product?.id] &&
                                                productOutOfStockStatus && (
                                                    <div key={`product_delivery_type_4_${index}`} className="product__delivery__itembox store_on">
                                                        <img src={store_black_icon} alt="store-pickup" />
                                                        <span>{t("Store_Pickup")}</span>
                                                    </div>
                                                )
                                        )}
                                </>
                            )}
                        </>
                    )}
            </div>
        </>
    );
}

export default ProductDeliveryType;
