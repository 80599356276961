export const languageList = [
  {
    code: "en",
    name: "English",
    urlKey: "en-sa"
  },
  {
    code: "ar",
    name: "العربية",
    urlKey: "ar-sa"
  },
];

export const outOfOrderNotifyProduct=(products)=>{
  return products?.filter((product)=>product?.stock>0 &&product?.orderType==='in-stock' )
}

export  const DEFAULT_SORT = "desc"
