import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
/* eslint-disable */
const TabbyWidget = ({timeOut = 2000, currency, price, widgetType, currentLanguageCode }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      if (typeof TabbyPromo !== 'undefined' && widgetType === "TabbyPromo") {
        new TabbyPromo({
          selector: "#TabbyPromo",
          currency: currency,
          price: price,
          installmentsCount: 4,
          lang: currentLanguageCode,
          source: "product",
          publicKey: "pk_test_ba71acb3-40e9-437d-a4b2-0428a82f5d41",
          merchantCode: "sony_world"
        });
      }
    }, timeOut);
    setTimeout(() => {
      if (typeof TabbyCard !== 'undefined' && widgetType === "TabbyCard") {
        new TabbyCard({
          selector: '#tabbyCard', // empty div for TabbyCard
          currency: currency, // or SAR, BHD, KWD, EGP
          lang: currentLanguageCode, // or ar
          price: price,
          size: 'narrow', // or wide/narrow, depending on the width
          theme: 'default', // or can be black/default
          header: false, // if there is a Payment method name already
        });
      }
    }, timeOut);
  }, []);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for(let mutation of mutationsList) {
        if(mutation.addedNodes.length) {
          const container = document.getElementById('TabbyDialogContainer');
          if (container && !document.getElementById('tabbyInfoMessageSonyInput')) {
            const lastChild = container.lastElementChild;
            if (lastChild) {
              const jsx = `
              <div id="tabbyInfoMessageSonyInput" style="display: flex; flex-direction:column; width: 100%;">
                <div style="height: 1px; margin: 10px 0px 20px 0px; background-color: #eceaee; width: 100%;"></div>
                <div style="display: grid; grid-template-columns: auto 1fr; gap: 10px; width: 100%;">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#jkidevyaua)" fill="#55535D">
                        <path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18.182c-4.512 0-8.182-3.67-8.182-8.182 0-4.511 3.67-8.182 8.182-8.182 4.511 0 8.182 3.67 8.182 8.182 0 4.511-3.67 8.182-8.182 8.182z"/>
                        <path d="M9.999 4.242a1.213 1.213 0 1 0 1.212 1.213c0-.669-.544-1.213-1.212-1.213zM10 8.485a.91.91 0 0 0-.908.909v5.454a.91.91 0 1 0 1.818 0V9.395a.91.91 0 0 0-.91-.91z"/>
                    </g>
                    <defs>
                        <clipPath id="jkidevyaua">
                            <path fill="#fff" d="M0 0h20v20H0z"/>
                        </clipPath>
                    </defs>
                  </svg>
                  <p style="color: #55535d; font-family: Inter; font-size: 13px; font-weight: 600;">
                    ${t('The installment plan may change based on the customer’s credit status at the payment stage')}
                  </p>
                </div>
                <div style="height: 1px; margin: 3px 0px; background-color: #eceaee; width: 100%;"></div>
              </div>
              `;
              const tempDiv = document.createElement('div');
              tempDiv.innerHTML = jsx;
              container.insertBefore(tempDiv, lastChild);
              observer.disconnect(); // Stop observing once we've added the JSX
            }
          }
        }
      }
    });
  
    // Start observing the document with the configured parameters
    observer.observe(document.body, { childList: true, subtree: true });
    // Clean up on component unmount
    return () => observer.disconnect();
  }, []);



  return (
    <React.Fragment>
      <input type="hidden" id="tabbyTotalPrice" value={price} />
      <input type="hidden" id="tabbyLanguage" value={currentLanguageCode} />
      {(widgetType === "TabbyPromo") && (
        <React.Fragment>
          <Helmet>
            <script src="https://checkout.tabby.ai/tabby-promo.js" type="text/javascript" />
            {/* <script src="/tabby-script.js" /> */}
            {/* <script>
              {`${`
                  new TabbyPromo({
                    selector: '#TabbyPromo', // required, content of tabby Promo Snippet will be placed in element with that selector.
                    currency: 'SAR', // required, currency of your product. AED|SAR|KWD|BHD|EGP only supported, with NO spaces or lowercase.
                    price: '${price}', // required, price or your product. 2 decimals max for AED|SAR|EGP and 3 decimals max for KWD|BHD supported.
                    installmentsCount: 4, // Optional - custom installments number for tabby promo snippet (if not downpayment + 3 installments)
                    lang: '${currentLanguageCode}', // Optional, language of snippet and popups, if the property is not set, then it is based on the attribute 'lang' of your html tag
                    source: 'product', // Optional, snippet placement; 'product' for product page and 'cart' for cart page
                    publicKey: 'pk_test_ba71acb3-40e9-437d-a4b2-0428a82f5d41', // required, public key which identifies your account when communicating with tabby. Test or Production depending on the environment
                    merchantCode: 'sony_world', // required
                  })
              `}`}
            </script> */}
          </Helmet>
          <div id="TabbyPromo" className="installment__offer__box" />
        </React.Fragment>
      )}
      {(widgetType === "TabbyCard") && (
        <React.Fragment>
          <Helmet>
            <script src="https://checkout.tabby.ai/tabby-card.js" type="text/javascript" />
            {/* <script src="/tabby-card-script.js" /> */}
            {/* <script>
              {`${`
                  new TabbyCard({
                    selector: '#tabbyCard', // empty div for TabbyCard
                    currency: 'SAR', // or SAR, BHD, KWD, EGP
                    lang: '${currentLanguageCode}', // or ar
                    price: '${price}',
                    size: 'narrow', // or wide, depending on the width
                    theme: 'default', // or can be black
                    header: false // if there is a Payment method name already 
                  })
                `}`}
            </script> */}
          </Helmet>
          <div id="tabbyCard" />
          <div style={{ height: '1px', margin: ' 2px 0px 20px 0px', backgroundColor: '#eceaee' }} />
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            gap: '10px',
            margin: '0px 0px -10px 0px',
            width: '100%',
          }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#jkidevyaua)" fill="#55535D">
                  <path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18.182c-4.512 0-8.182-3.67-8.182-8.182 0-4.511 3.67-8.182 8.182-8.182 4.511 0 8.182 3.67 8.182 8.182 0 4.511-3.67 8.182-8.182 8.182z"/>
                  <path d="M9.999 4.242a1.213 1.213 0 1 0 1.212 1.213c0-.669-.544-1.213-1.212-1.213zM10 8.485a.91.91 0 0 0-.908.909v5.454a.91.91 0 1 0 1.818 0V9.395a.91.91 0 0 0-.91-.91z"/>
              </g>
              <defs>
                  <clipPath id="jkidevyaua">
                      <path fill="#fff" d="M0 0h20v20H0z"/>
                  </clipPath>
              </defs>
            </svg>
            <p style={{
              color: '#55535d', fontFamily: 'Inter', fontSize: '13px', fontWeight: '600',
            }}>
              {t('The installment plan may change based on the customer’s credit status at the payment stage')}
            </p>
          </div>
          <div style={{ height: '1px', margin: '12px  0px 0px 0px ', backgroundColor: '#eceaee' }} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default React.memo(TabbyWidget);