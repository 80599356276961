import React from "react";
import { productAttributeGet } from "../helpers/utils/productAttributeGet";

function FeatureOptionBox({ options, color_resolution, screen_size }) {

  let color_resolution_0 = ""
  let screen_size_0 = ""
  if (options && options.length > 0) {
    color_resolution_0 = productAttributeGet(options, color_resolution);
    screen_size_0 = productAttributeGet(options, screen_size);
  }

  return (
    <>
      {/* {color_resolution_0 !== "" &&
        <p className="pro__4k_option">{color_resolution_0}</p>
      } */}
      {screen_size_0 !== "" && screen_size_0 !== 'undefined"' &&
        <p className="pro__4k_option">{screen_size_0}</p>
      }
    </>
  );
}

export default FeatureOptionBox;
