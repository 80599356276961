import React, { useState } from "react";
import BundleModal from "../MostSharedComponent/BundleModal";
import shareIcon from "./../../assets/Icon/share.png";
// import twitter from "./../../assets/Icon/twitter.png";
// import messanger from "./../../assets/Icon/messanger.png";
// import whatsapp from "./../../assets/Icon/whatsapp.png";
// import pinterest from "./../../assets/Icon/pinterest.png";
import { FaFacebookF,FaTwitter,FaWhatsapp,FaFacebookMessenger,FaPinterestP } from "react-icons/fa";
import { FACEBOOK_SOCIAL_URL, MESSENGER_SOCIAL_URL, PINTEREST_SOCIAL_URL, TWITTER_SOCIAL_URL, WHATSAPP_SOCIAL_URL } from "../../globalConst";

function ShareLinkPDP({ t, product, urlLink }) {
  const [shareModal, setShareModal] = useState(false);

  // const redirectLink = () =>{

  // }
  return (
    <>
      <BundleModal
        title={t("Select the environment that you want to share")}
        modalShow={shareModal}
        onClose={() => setShareModal(false)}
        t={t}
        content={<div className="socialShareModal">
          <a href={`${FACEBOOK_SOCIAL_URL}${encodeURI(urlLink)}`} target="_" className="social__Share__btn facebook"><FaFacebookF /> Facebook</a>
          <a href={`${WHATSAPP_SOCIAL_URL}${encodeURI(urlLink)}`} target="_" className="social__Share__btn whatsapp"><FaWhatsapp /> Whatsapp</a>
          <a href={`${TWITTER_SOCIAL_URL}${encodeURI(urlLink)}`} target="_" className="social__Share__btn twitter"><FaTwitter /> Twitter</a>          
          <a href={`${MESSENGER_SOCIAL_URL}${encodeURI(urlLink)}`} target="_" className="social__Share__btn messenger"><FaFacebookMessenger /> Messenger</a>
          <a href={`${PINTEREST_SOCIAL_URL}${encodeURI(urlLink)}`} target="_" className="social__Share__btn pinterest"><FaPinterestP /> Pinterest</a>
        </div>}
        onHide={() => {
          setShareModal(false);
        }}
        isFooter={false}
        size="md"
        scrollable={true}
      ></BundleModal>
      <button className="pd__share__button" onClick={() => setShareModal(true)}>
        <img src={shareIcon} className="sahre-icon-pic" />
      </button>
    </>
  );
}

export default ShareLinkPDP;
