import React from "react";
import product_01 from "./../../assets/Product/product_01.jpg";
import sony_logo from "./../../assets/Icon/sony_logo.svg";
import "./../../SCSS/MobilePages/Mobile_Product_List_ListDetail.scss";
import MobileListViewProduct from "../../Components/ProductType/MobileListViewProduct";
import { useTranslation } from "react-i18next";
import { titleChange } from "../../Components/helpers/utils/titleNameChange";

const product = {
  id: 1,
  logo: sony_logo,
  image: product_01,
  productName:
    "Z8H | Full Array LED | 8K | High Dynamic Range (HDR) | Smart TV (Android TV)",
  categoryTagline: "Experience the brilliance of big-screen Sony 8K HDR",
  rating: 4.6,
  totalRatings: 6183,
  price: 799,
  oldPrice: 1050,
  saving: 10,
  monthlySavingTagline: "get it for SAR 500 in 6 equal installments",
  returnPeriod: 15,
  availableOffer: [
    {
      id: 1,
      offerType: "",
      offerText: "Save $50-$300 on a sound bar with TV",
      termsAndConditions: "",
    },
    {
      id: 2,
      offerType: "Bank Offer",
      offerText: "5% Unlimited Cashback on Axis Bank Credit Card",
      termsAndConditions: "T&C",
    },
    {
      id: 3,
      offerType: "Credit Card Offer",
      offerText: "5% Unlimited Cashback on Sony Credit Card",
      termsAndConditions: "T&C",
    },
  ],
  warrantyText: "1 Year Warranty on Product",
  size: [
    {
      id: 1,
      cm: 139,
      inch: 55,
    },
    {
      id: 2,
      cm: 164,
      inch: 65,
    },
    {
      id: 3,
      cm: 195,
      inch: 77,
    },
  ],
  delivery: {
    deliveryText: "Buy in next 2 hours and receive the TV by Tomorrow",
    pickupStore: [
      {
        id: 1,
        pickupText:
          "Available today at Riyadh Act Fast – Only 3 left at your store!>",
      },
      {
        id: 2,
        pickupText:
          "Available today at Riyadh Act Fast – Only 3 left at your store!>",
      },
      {
        id: 3,
        pickupText:
          "Available today at Riyadh Act Fast – Only 3 left at your store!>",
      },
    ],
  },
  protection: [
    {
      id: 1,
      protectionText: "2-Year Standard Geek Squad Protection",
      price: 79,
      month: 12,
    },
    {
      id: 2,
      protectionText: "1-Year Standard Geek Squad Protection",
      price: 89,
      month: 12,
    },
  ],
  productFeatures: [
    {
      id: 1,
      text: "Netflix|Prime Video|Disney+Hotstar|Youtube",
    },
    {
      id: 1,
      text: "Multi-position stand for versatile TV placement",
    },
    {
      id: 1,
      text: "HD Smart 2048 x 1366 Pixels",
    },
  ],
};

function Mobile_Product_List_ListDetail({
  filteredProductsData,
  updateCompareProduct,
  handleChangeCartPopup,
  outOfStockConfiguration,
  screenName,
}) {
  const { t } = useTranslation();

  const availablityData = filteredProductsData?.availablityData || []

  return (
    <>
      {(filteredProductsData?.total_count?.value || filteredProductsData?.total_count) !== 0 &&
        filteredProductsData?.items.length !== 0 ? (
        filteredProductsData?.items.map((product, productIndex) => {
          return (
            <React.Fragment key={`mobile_product_list_productIndex`} >
              <MobileListViewProduct
                updateCompareProduct={updateCompareProduct}
                product={product}
                key={titleChange(product.name)}
                handleChangeCartPopup={handleChangeCartPopup}
                outOfStockConfiguration={outOfStockConfiguration}
                productIndex={productIndex}
                screenName={screenName}
                availablityData={availablityData}
            />
            </React.Fragment>
          );
        })
      ) : (
        <div className="PLP_noProduct_box">
          <h1>{t("No_Products_Available")}</h1>
        </div>
      )}
    </>
  );
}
export default Mobile_Product_List_ListDetail;
