import React from 'react';
import styles from '../../SCSS/components/Meza/SubscribeSection.module.scss';

const SubscribeSection = ({
  isPDP, children,
}) => (
  <div className={isPDP ? styles.subscribeWrapperPDP : styles.subscribeWrapper}>
    <div className={styles.subscribeSection}>
      {children}
    </div>
  </div>
);

export default SubscribeSection;
