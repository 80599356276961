import React, { useEffect, useState } from "react";
import empty_favourite from "./../../assets/Icon/empty_favourite.svg";
import fulfill_favourite from "./../../assets/Icon/fulfill_favourite.svg";
import "./../../SCSS/ProductType/_productEight.scss";
import OldPrice from "./../Font/OldPrice";
import Price from "./../Font/Price";
import RatingBlock from "../MostSharedComponent/RatingBlock";
import { Link } from "react-router-dom";
import {
  loadCartData,
  loadDeleteFromWishlist,
  loadWishlistData,
} from "../../redux/appAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import VariantOptions from "./VariantOptions";
import * as services from "./../../services/services";
import { addToCart } from "../../services/cart.service";
import Slider from "react-slick";
import { useNavigate } from "react-router";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import { checkWishList } from "../helpers/utils/wishListHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
import { MobileImageResize } from "../helpers/utils/imageResize";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { checkoutEvents } from "../helpers/utils/ga/checkoutEvents";
import LazyImageForProduct from "../MostSharedComponent/LazyImageForProduct";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";
import AddToCart from "../Buttons/AddToCart";
import SoldOutLabel from "../MostSharedComponent/SoldOutLabel";
import ProductDeliveryType from "../MostSharedComponent/ProductDeliveryType";
import { impressionEvents } from "../helpers/utils/ga/impressionEvents";
import SubscribeSection from "../Meza/SubscribeSection";
import SubsSectionContent from "../Meza/SubsSectionContent";

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  arrows: false
};

function ProductEight({ product, handleChangeCartPopup, outOfStockConfiguration, productIndex, screenName, availablityData, customerDetails }) {

  const { setCartErr } = useCartErrorMessage();
  const navigate = useNavigate();

  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(true);
  const [rating, setRating] = useState(0);
  const [sizeButtonIndex, setSizeButtonIndex] = useState(null);
  const [count, setCount] = useState(0);
  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const [loading, setLoading] = useState(true);

  const wishlistData = useSelector((state) => state.appData.wishlistData);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, product?.sku));
  }, [product]);

  const handleFavourite = () => {

    if (isFavourite) {
      setIsFavourite(false);
      setCount(count + 1);
    
    } else {
      setIsFavourite(true);
     
    }
  };
  
  useEffect(() => {
    if (count > 0) {
      if (!isFavourite) {
        checkoutEvents.removeFromWishlist(product, screenName);
        removeFromWL(product?.sku);
      }
    }
  }, [count]);
  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };
    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data,t)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };
  const handleRating = (score) => {
    setRating(score);
  };

  const sizeButtonClick = (sizeIndex, cm, inch) => {
    product.sku = product?.variants[sizeIndex]?.sku;
    product.name = product?.variants[sizeIndex]?.name;
    product.description = product?.variants[sizeIndex]?.description;
    product.price = product?.variants[sizeIndex]?.price;
    product.currency = product?.variants[sizeIndex]?.currency;
    product.price_rounded = product?.variants[sizeIndex]?.price_rounded;
    product.discounted_price = product?.variants[sizeIndex]?.discounted_price;
    product.discounted_price_rounded = product?.variants[sizeIndex]?.discounted_price_rounded;
    product.discounted_price_from = product?.variants[sizeIndex]?.discounted_price_from;
    product.discounted_price_to = product?.variants[sizeIndex]?.discounted_price_to;
    product.is_count_down = product?.variants[sizeIndex]?.is_count_down;
    product.stock = product?.variants[sizeIndex]?.stock;
    product.item_type = product?.variants[sizeIndex]?.item_type;
    product.meza = product?.variants[sizeIndex]?.meza;
    product.qitaf = product?.variants[sizeIndex]?.qitaf;
    product.eligible_for_tabby = product?.variants[sizeIndex]?.eligible_for_tabby;
    product.enable_shipping = product?.variants[sizeIndex]?.enable_shipping;
    product.additional_estimated_day = product?.variants[sizeIndex]?.additional_estimated_day;
    product.current_time = product?.variants[sizeIndex]?.current_time
    setSizeButtonIndex(sizeIndex);
  };

  const AddProductToCart = (sku) => {
    // impressionEvents.impressionAddToCart(
    //   product, 1,"home"
    // );
    setLoaderOn(true);
    if (product?.variants && product?.variants.length > 0 && sizeButtonIndex && sizeButtonIndex >= 0) {
      sku = product?.variants[sizeButtonIndex].sku;
    }

    const data = {
      items: [
        {
          sku: sku,
          id: product?.id,
          qty: 1,
        },
      ],
    };

    addToCart(data)
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
        setLoaderOn(false);
      })
      .finally(() => {
        setLoaderOn(false);
      });
  };

  const redirectToPdpPage = (url_key, sku) => {
    navigate(productUrlHelper(url_key, sku));
  }

  // if (loading) {
  //   return <h1>wishlist product Loading...</h1>;
  // }
  return (
    <>
    {product ?
    <div key={product?.sku} className="productEight__block">
      <div className="productEight__header__block">
        <div style={{ display: "flex", justifyContent: "space-between" }}>          
          <div className="productEight__quality__favourite__block">
          </div>
          <div className="sc__fav__sec me-3 pt-1">
            <button className="common__favourite__button">
              <img
                onMouseEnter={() => setIsFavouriteHover(true)}
                onClick={handleFavourite}
                onMouseLeave={() => setIsFavouriteHover(false)}
                className={
                  !isFavourite
                    ? "pd__favourite__icon"
                    : "pd__favourite__icon__disable"
                }
                src={isFavouriteHover ? fulfill_favourite : empty_favourite}
                alt=""
              />
              <img
                onClick={handleFavourite}
                className={
                  isFavourite
                    ? "pd__favourite__icon"
                    : "pd__favourite__icon__disable"
                }
                src={fulfill_favourite}
                alt=""
              />
            </button>
          </div>
        </div>
        <div className="product__pics__area">
      <div
        className="productNine__image__slider"
        to={productUrlHelper(product?.english_name, product?.sku)}
      >
       <Link to={productUrlHelper(product?.english_name, product?.sku)}>
            <Slider {...settings}>
              {[product?.baseImage]?.map((img, index) => (
                <div key={index} className="">
                  <LazyImageForProduct
                    product={product}
                    srcSetReSize={MobileImageResize}
                    alt={img + index}
                    className=""
                    onClickEvent={true}
                    redirectToPdpPage={redirectToPdpPage}
                  />
                </div>
              ))}
            </Slider>
        </Link>
      </div>
      <SoldOutLabel
        t={t}
        product={product}
        outOfStockConfiguration={outOfStockConfiguration}
      />
      </div>
      </div>
      <Link
        to={productUrlHelper(product?.english_name, product?.sku)}
        className="productEight__name__link"
      >
        <p className="productEight__name"> {titleChange(product?.name)}</p>
      </Link>
      <ProductDeliveryType
        product={product}
        availablityData={availablityData}
        t={t}
      />
      <div className="rating__block">
        <RatingBlock rating={6} totalRatings={2222} />
      </div>
      <div className="size__button__block">
      {product?.variantOptions && (
        <VariantOptions
          sizeButtonClick={sizeButtonClick}
          sizeButtonIndex={sizeButtonIndex}
          variantOptions={product?.variantOptions}
          product={product}
        />
      )}
      {!product?.hide_subscriber_price && (
        <SubscribeSection>
          <SubsSectionContent
            t={t}
            meza_subscriber_price={product?.meza_subscriber_price}
            user={customerDetails}
            discounted_price={product.discounted_price_rounded}
            og_price={product.price_rounded}
          />
        </SubscribeSection>
      )}
      </div>
      <div className="prize__block">
        <OldPrice
          price={
            product?.discounted_price_rounded === null
              ? product?.price_rounded
              : product?.discounted_price_rounded
          }
          oldPrice={product?.price_rounded}
          size="text3"
          color="#444444"
          marginBottom={8}
          // lineThrough={true}
          span={true}
          currency={product?.currency}
        />
        <Price
          price={
            product?.discounted_price_rounded === null
              ? product?.price_rounded
              : product?.discounted_price_rounded
          }
          // marginLeft={5}
          marginBottom={8}
          size="heading6"
          span={true}
          currency={product?.currency}
        />
      </div>
      {product?.is_bundle_enable===1 ? (
        <Link
          to={productUrlHelper(product?.english_name, product?.sku)}
          style={{ textDecoration: 'none' }}
        >
          <AddToCart 
           product={product}
           AddProductToCart={AddProductToCart}
           outOfStockConfiguration={outOfStockConfiguration}
           loaderOn={loaderOn || spinnerOn} 
         />
        </Link>
      ) : (
        <AddToCart
          product={product}
          AddProductToCart={AddProductToCart}
          outOfStockConfiguration={outOfStockConfiguration}
          loaderOn={loaderOn || spinnerOn}      
        />
      )}
    </div>
     :null}
     </>
  );
}

export default ProductEight;