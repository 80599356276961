import React, { useEffect, useState } from 'react';
import "./../../SCSS/components/Cards/_bundleCityList.scss";
import { useTranslation } from 'react-i18next';

// import { selectEvents } from 'lib/helpers/ga/selectEvents';

const CitySelection = ({
    // productId,
    cities,
    onCitySelect,
    onCancel,
    currentLang,
    // user,
    cityModal,
    selectCity,
    setSelectCity
}) => {
    const [query, setQuery] = useState('');
    const [filteredCities, setFilteredCities] = useState(cities);

    const { t } = useTranslation();

    useEffect(() => {
        if (!cityModal) {
            setQuery('');
        }
    }, [cityModal]);

    useEffect(() => {
        if (cities) {
            setFilteredCities(cities.filter((item) => item.cityName.toLowerCase().indexOf(query.toLowerCase()) > -1));
        }
    }, [query, cities]);

    const select = (item) => {
        // onCitySelect(item);
        setSelectCity(item)
    };

    return (
        <div className="bundle_city_wrapper">
            <div className="col-md-12 search__input__block">
                <input
                    id="city_search"
                    name="city_search"
                    placeholder={`${t("search_cities")}`}
                    onFocus={({ target }) =>
                        target.parentElement.classList.add("focused")
                    }
                    onBlur={({ target }) =>
                        target.parentElement.classList.remove("focused")
                    }
                    value={query}
                    onChange={({ target }) => setQuery(target.value)}
                />
                <img
                    // onClick={() => clearSearchInput()}
                    // src={cancel_grey}
                    alt=""
                    className="cancel__button"
                />
            </div>
            <div className="items">
                {filteredCities?.length == 0 ? <p className="location_no_data p-2 m-0">
                    {t('No Location available')}
                </p> :
                    <div>
                        {filteredCities?.map((item) => (
                            <div onClick={() => select(item)} className="loaction-item-box">
                                <label>
                                    <span>{item?.cityName || ''}</span>
                                </label>
                                <input
                                    style={{ float: 'right' }}
                                    type="radio"
                                    className="delivery__input__check"
                                    name="addressTypeBtn"
                                    value={item}
                                    id="home"
                                    checked={
                                        selectCity?.cityName === item?.cityName ? true : false
                                    }
                                />
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
};

export default CitySelection;
