import React from 'react';
import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Heading3 from '../Font/Heading3';
import Text3 from '../Font/Text3';
import cancel_grey from "./../../assets/Icon/cancel_grey.svg";
import { FaRegCheckCircle } from "react-icons/fa";

function NotifyMeSuccessModal({ modalShow, onHide, size }) {

  const { t } = useTranslation();

  return (
    <Modal
      show={modalShow}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size={size}
      className='notify__modal'
    >
      <Modal.Header >
        <Modal.Title></Modal.Title>
        <img
          onClick={onHide}
          src={cancel_grey}
          alt="cancel"
          className="cancel__button"
        />
      </Modal.Header>
      <Modal.Body className='notity__get__request__modal'>
        <center>
          <FaRegCheckCircle className='notify__success__icon' />
          <Heading3 text={t("Request Received")} />
          <Text3 text={t("Thank You!")} />
          <Text3 text={t("We will notify you when product is available")} />
        </center>

        <div className="d-grid gap-3 mt-3 notify__form__btnBox">
          <Button onClick={onHide} className='notify__add__btn notify__complete' variant='link' size="lg" >
            {t("Complete")}
          </Button>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t("Close")}
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default NotifyMeSuccessModal;
