import React from "react";
import Banner from "../../Components/Banner";
import "./../../SCSS/_home.scss";
import "./../../SCSS/MobilePages/_mobileHome.scss";
import ProductContainer from "../../Components/ProductContainer";
import DealsOfTheWeek from "../../Components/DealsOfTheWeek";
import HomePageCategoryBlock from "../../Components/HomePageCategoryBlock";
// import RecentlyViewedProducts from "../../Components/RecentlyViewedProducts";
import MobileMainFeatureProductsBlock from "../../Components/MobileView/MobileMainFeatureProductsBlock";
// import { useTranslation } from "react-i18next";
import { MagnifyImageResize } from "../../Components/helpers/utils/imageResize";
// import { defaultImageHelper } from "../../Components/helpers/utils/defaultImageHelper";
import LazyImage from "../../Components/MostSharedComponent/LazyImage";
import DealsOfTheWeekMobile from "../../Components/DealsOfTheWeekMobile";

function Mobile_Home_Page({
  bannerData,
  featureProductsData,
  recentlyViewedProductsData,
  // recentlyViewedProductsBanner,
  newArrivalData,
  topTrendingData,
  topRatedData,
  dealsOfTheWeekData,
  homePageBottomSingleBanner,
  homePagePlayStationSingleBanner,
  handleChangeCartPopup,
  categorySevenData,
  outOfStockConfiguration,
  topTVData,
  availablityData,
  customerDetails
}) {

  // const { t } = useTranslation();

  //   if (loading) {
  //     return <h1>Home Loading...</h1>;
  //   }

  return (
    <>
      <div>
        {bannerData && (
          <Banner
            bannerData={bannerData?.items !== undefined ? bannerData?.items : []}
            bannerFullData={bannerData}
          />
        )}

        <DealsOfTheWeekMobile
          dealsOfTheWeekData={dealsOfTheWeekData?.items}
          sectionTitle={dealsOfTheWeekData?.title}
        />

        <HomePageCategoryBlock />
        {featureProductsData !== "" && (
          <MobileMainFeatureProductsBlock
            handleChangeCartPopup={handleChangeCartPopup}
            backgroundColor="#FFFBE4"
            sectionTitle={featureProductsData?.title}
            featureProductsData={featureProductsData?.products}
            featureOptionsShow={true}
            outOfStockConfiguration={outOfStockConfiguration}
            screenName="Home"
            availablityData={availablityData}
            customerDetails={customerDetails}
          />
        )}
        <ProductContainer />
        {/* <RecentlyViewedProducts
          recentlyViewedProductsData={
            recentlyViewedProductsData !== ""
              ? recentlyViewedProductsData.products
              : []
          }
          recentlyViewedProductsBanner={
            recentlyViewedProductsBanner !== ""
              ? recentlyViewedProductsBanner.products
              : []
          }
          sectionTitle={recentlyViewedProductsData?.title}
          handleChangeCartPopup={handleChangeCartPopup}
          isMobile={true}
          outOfStockConfiguration={outOfStockConfiguration}
        /> */}
        {topTVData !== "" && (
          <div className="top__trending__section mt-3">
            <ProductContainer
              handleChangeCartPopup={handleChangeCartPopup}
              sectionTitle={topTVData?.title}
              carouselData={topTVData?.products}
              containerClassName="top__trending__pc__block_mob"
              cartIconOnly={true}
              outOfStockConfiguration={outOfStockConfiguration}
              availablityData={availablityData}
            />
          </div>
        )}
        {recentlyViewedProductsData !== "" && (
          <div className={`top__trending__section mt-3 ele_${recentlyViewedProductsData?.element_id}`}>
            <ProductContainer
              handleChangeCartPopup={handleChangeCartPopup}
              sectionTitle={recentlyViewedProductsData?.title}
              carouselData={recentlyViewedProductsData?.products}
              containerClassName="top__trending__pc__block"
              cartIconOnly={true}
              outOfStockConfiguration={outOfStockConfiguration}
              screenName="Home"
              availablityData={availablityData}
              customerDetails={customerDetails}
            />
          </div>
        )}
        {homePagePlayStationSingleBanner !== "" && (
          <div className="container-fluid adImage__container playStation_bottom_banner">
            <div className=" adImage">
              {homePagePlayStationSingleBanner?.items[0]?.deeplinkUrl !== '' && homePagePlayStationSingleBanner?.items[0]?.deeplinkUrl !== undefined && homePagePlayStationSingleBanner?.items[0]?.deeplinkUrl !== null ?
                <a
                  href={homePagePlayStationSingleBanner?.items[0]?.deeplinkUrl || "#"}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Product Ad"
                >
                  <div className="adImage__block">
                    {/* <img
                      // src={banner_01}
                      src={MagnifyImageResize(homePagePlayStationSingleBanner?.items[0]?.imageUrl)[0]?.img}
                      srcSet={MagnifyImageResize(homePagePlayStationSingleBanner?.items[0]?.imageUrl)[1]?.imgSrc.join(",")}
                      // src={homePagePlayStationSingleBanner?.items[0]?.imageUrl}
                      alt="Bottom Ad Banner"
                      className="adImage__image"
                      onError={(e) => defaultImageHelper(e)}
                    /> */}
                    <LazyImage
                      imgSrc={homePagePlayStationSingleBanner?.items[0]?.imageUrl}
                      srcSetReSize={MagnifyImageResize}
                      alt="Bottom Ad Banner"
                      className="adImage__image"
                    />
                  </div>
                </a> :
                <div className="adImage__block">
                  {/* <img
                    // src={banner_01}
                    src={MagnifyImageResize(homePagePlayStationSingleBanner?.items[0]?.imageUrl)[0]?.img}
                    srcSet={MagnifyImageResize(homePagePlayStationSingleBanner?.items[0]?.imageUrl)[1]?.imgSrc.join(",")}
                    // src={homePagePlayStationSingleBanner?.items[0]?.imageUrl}
                    alt="Bottom Ad Banner"
                    className="adImage__image"
                    onError={(e) => defaultImageHelper(e)}
                  /> */}
                  <LazyImage
                    imgSrc={homePagePlayStationSingleBanner?.items[0]?.imageUrl}
                    srcSetReSize={MagnifyImageResize}
                    alt="Bottom Ad Banner"
                    className="adImage__image"
                  />
                </div>}
            </div>
          </div>
        )}
        {topTrendingData !== "" && (
          <div className="top__trending__section mt-3">
            <ProductContainer
              handleChangeCartPopup={handleChangeCartPopup}
              sectionTitle={topTrendingData?.title}
              carouselData={topTrendingData?.products}
              containerClassName="top__trending__pc__block_mob"
              cartIconOnly={true}
              outOfStockConfiguration={outOfStockConfiguration}
              availablityData={availablityData}

            />
          </div>
        )}
        {newArrivalData !== "" && (
          <div className="top__trending__section mt-3">
            <ProductContainer
              handleChangeCartPopup={handleChangeCartPopup}
              sectionTitle={newArrivalData?.title}
              carouselData={newArrivalData?.products}
              containerClassName="new__arrival__pc__block_mob"
              cartIconOnly={true}
              outOfStockConfiguration={outOfStockConfiguration}
              availablityData={availablityData}
              customerDetails={customerDetails}
            />
          </div>
        )}
        {categorySevenData !== "" && (
          <div className="top__trending__section mt-3">
            <ProductContainer
              handleChangeCartPopup={handleChangeCartPopup}
              sectionTitle={categorySevenData?.title}
              carouselData={categorySevenData?.products}
              containerClassName="new__arrival__pc__block_mob"
              cartIconOnly={true}
              outOfStockConfiguration={outOfStockConfiguration}
              availablityData={availablityData}
              customerDetails={customerDetails}
            />
          </div>
        )}
        {/* category end           */}
        {topRatedData !== "" && (
          <MobileMainFeatureProductsBlock
            handleChangeCartPopup={handleChangeCartPopup}
            backgroundColor="#FFF4F2 "
            sectionTitle={topRatedData?.title}
            featureProductsData={topRatedData?.products}
            outOfStockConfiguration={outOfStockConfiguration}
            screenName="Home"
            availablityData={availablityData}
            customerDetails={customerDetails}
          />
        )}
        {/* <TopRatedProducts productData={topRatedProductsData} /> */}
        {homePageBottomSingleBanner !== "" && (
          <div className="container-fluid adImage__container">
            <div className=" adImage">
              {homePageBottomSingleBanner?.items[0]?.deeplinkUrl !== '' && homePageBottomSingleBanner?.items[0]?.deeplinkUrl !== undefined && homePageBottomSingleBanner?.items[0]?.deeplinkUrl !== null ?
                <a
                  href={homePageBottomSingleBanner?.items[0]?.deeplinkUrl || "#"}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Product Ad"
                >
                  <div className="adImage__block">
                    {/* <img
                      // src={banner_01}
                      // src={homePageBottomSingleBanner?.items[0]?.imageUrl}
                      src={MagnifyImageResize(homePageBottomSingleBanner?.items[0]?.imageUrl)[0]?.img}
                      srcSet={MagnifyImageResize(homePageBottomSingleBanner?.items[0]?.imageUrl)[1]?.imgSrc.join(",")}
                      alt="Bottom Ad Banner"
                      className="adImage__image"
                      onError={(e) => defaultImageHelper(e)}
                    /> */}
                    <LazyImage
                      imgSrc={homePageBottomSingleBanner?.items[0]?.imageUrl}
                      srcSetReSize={MagnifyImageResize}
                      alt="Bottom Ad Banner"
                      className="adImage__image"
                    />
                  </div>
                </a> :
                <div className="adImage__block">
                  {/* <img
                    // src={banner_01}
                    // src={homePageBottomSingleBanner?.items[0]?.imageUrl}
                    src={MagnifyImageResize(homePageBottomSingleBanner?.items[0]?.imageUrl)[0]?.img}
                    srcSet={MagnifyImageResize(homePageBottomSingleBanner?.items[0]?.imageUrl)[1]?.imgSrc.join(",")}
                    alt="Bottom Ad Banner"
                    className="adImage__image"
                    onError={(e) => defaultImageHelper(e)}
                  /> */}
                  <LazyImage
                    imgSrc={homePageBottomSingleBanner?.items[0]?.imageUrl}
                    srcSetReSize={MagnifyImageResize}
                    alt="Bottom Ad Banner"
                    className="adImage__image"
                  />
                </div>
              }
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default React.memo(Mobile_Home_Page);
