import React, { useEffect, useMemo } from 'react'
import { Helmet } from "react-helmet-async";

function FlixMedia({
  ean, sku, mpn,
}) {
  const currentLang = localStorage.getItem("i18next") || "en";
  const newSku = useMemo(() => sku, [sku])
  useEffect(() => {
    const elementById = document.getElementById('flix-inpage')
    elementById.innerHTML = ""
  }, [newSku])
  return (
    <>
      <div id="flix-minisite"></div>
      <div id="flix-inpage"></div>
      <Helmet>
        {/* <script
        type="text/javascript"
        src="//media.flixfacts.com/js/loader.js"
        data-flix-distributor="13724"
        data-flix-language={currentLang === 'en' ? 'd2' : 'ar'}
        data-flix-brand=""
        data-flix-mpn={mpn}
        data-flix-ean={ean}
        data-flix-sku={sku}
        data-flix-button="flix-minisite"
        data-flix-inpage="flix-inpage"
        data-flix-button-image=""
        data-flix-fallback-language={currentLang === 'en' ? 'u1' : 'ae'}
        data-flix-price=""
        async
      /> */}
        {/* <script
        type="text/javascript"
        src="//media.flixfacts.com/js/loader.js"
        xml="space"
        data-flix-distributor="15416"
        data-flix-language="en"
        data-flix-brand="lg"
        data-flix-mpn="OLED77C8LLA"
        data-flix-ean="8806098204656"
        data-flix-sku=""
        data-flix-button="flix-minisite"
        data-flix-inpage="flix-inpage"
        data-flix-button-image=""
        data-flix-fallback-language=""
        data-flix-price=""
        async
      /> */}
        <script
          type="text/javascript"
          src="//media.flixfacts.com/js/loader.js"
          data-flix-distributor="18210"
          data-flix-language={currentLang === 'en' ? 'd2' : 'ar'}
          data-flix-brand=""
          data-flix-mpn={mpn}
          data-flix-ean={ean}
          data-flix-sku={sku}
          data-flix-button="flix-minisite"
          data-flix-inpage="flix-inpage"
          data-flix-button-image=""
          data-flix-fallback-language={currentLang === 'en' ? 'u1' : 'ae'}
          data-flix-price=""
          async
        />
      </Helmet>
    </>
  )
};


export default FlixMedia;
