
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { compareListUpdateSuccess } from "../../services/common/common";
import { notifyError } from "../../services/services";
import { selectedCompareProductStatus } from "../helpers/utils/compareProductsFilter";

function MobileCompareBtn({ product, updateCompareProduct, inputName }) {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [updateCompare, setUpdateCompareState] = useState(false);

    const comparePopupLocalData = JSON.parse(localStorage.getItem("compare_products")) || [];

    useEffect(() => {
        setUpdateCompareState(false);
    }, [product, updateCompare, inputName]);

    useEffect(() => {
        if (updateCompare) {
            let compareProductErrMsg = JSON.parse(localStorage.getItem("compare_products_max_3"));
            if (compareProductErrMsg && comparePopupLocalData && comparePopupLocalData.length === 3) {
                dispatch(notifyError({ message: t('You_have_selected_the_maximum_3_products') }));
            }
            setUpdateCompareState(false);
        }
    }, [comparePopupLocalData, updateCompare]);

    const removeProductFromCompare = (productId) => {
        let removeData = comparePopupLocalData && comparePopupLocalData.filter(item => item.id !== productId);
        localStorage.setItem('compare_products', JSON.stringify(removeData));
        localStorage.setItem('compare_products_max_3', JSON.stringify(false));
    }

    const handleCompare = (compareStatus, productData) => {
        if (compareStatus === false) {
            updateCompareProduct(false, productData)
        } else {
            removeProductFromCompare(productData?.id)
        }
        setTimeout(() => {
            setUpdateCompareState(true);
            dispatch(compareListUpdateSuccess(true))
        }, [100])
    }

    return (
        <div className="mb_campare_selectbox" onClick={(e) => { e.stopPropagation(); e.preventDefault(); handleCompare(comparePopupLocalData && comparePopupLocalData.some(item => item.id === product.id), product) }}>
            <input
                type="checkbox"
                className="pd__compare__input__check"
                name={inputName}
                checked={comparePopupLocalData && comparePopupLocalData.some(item => item.id === product.id)}
                id={`plp_compare_${product?.id}`}
            // onChange={(e) => handleCompare(comparePopupLocalData && comparePopupLocalData.some(item => item.id === product.id), product)}
            />
            <label className="mb_campare_label" htmlFor={`plp_compare_${product?.id}`}>{t("Select")}</label>
        </div>
    );
}

export default MobileCompareBtn;
