import axios from "axios";

const GENERIC_ERROR_MESSAGES = {
    429: 'Too many requests, please try again later.',
    500: 'Something went wrong, please try again later.',
}

const API_URL = process.env.REACT_APP_PROJECT_API_URL; // base url

const returnResponse = async (r) => {
    let json;
    try {
        json = await r.json();
    } catch (e) {
        json = { message: GENERIC_ERROR_MESSAGES[r.status], status: r.status };
        // errorEvents.apiError(window.location.pathname, json.message, json.status);
    }

    if (r.status === 429 || r.status === 500) {
        json = { message: GENERIC_ERROR_MESSAGES[r.status], status: r.status };
        // errorEvents.apiError(window.location.pathname, json.message, json.status);
    } else if (r.status === 422) {
        // errorEvents.apiError(window.location.pathname, json.message, json.status);
        for (const i in json.details.errors) {
            json = { message: json.details.errors[i][0].msg || '', status: r.status };
        }
    }

    return ({
        status: r.status,
        response: json,
    });
};

export const post = async (url, bodyParams, headers = {}) => await axios(`${API_URL}${url}`, {
    method: 'POST',
    body: bodyParams ? JSON.stringify(bodyParams) : null,
}).then(async (r) => await returnResponse(r));

export const get = async (url, bodyParams, headers = {}) => await axios(`${API_URL}${url}`, {
    method: 'GET',
    body: bodyParams ? JSON.stringify(bodyParams) : null,
}).then(async (r) => await returnResponse(r));

export const put = async (url, bodyParams, headers = {}) => await axios(`${API_URL}${url}`, {
    method: 'PUT',
    body: bodyParams ? JSON.stringify(bodyParams) : null,
}).then(async (r) => await returnResponse(r));

export const del = async (url, bodyParams, headers = {}) => await axios(`${API_URL}${url}`, {
    method: 'DELETE',
    body: bodyParams ? JSON.stringify(bodyParams) : null,
}).then(async (r) => await returnResponse(r));
