import React from "react";
import timedDiscountIcon from "../../../src/assets/Product/timedDiscountIcon.png";
import '../../SCSS/MostSharedComponents/_PLPdiscountBox.scss';

function PLPdiscountBox({ product, t }) {

  const discountedPrice = product?.discounted_price_rounded;
  const price = product?.price_rounded;
  const discountPercentage = Math.round(100 - (discountedPrice / price) * 100);
  const discountedAmount = Math.round(price - discountedPrice);

  const isTimedDiscount = (product?.discounted_price_from || product?.discounted_price_to) && product?.is_count_down;
  const now = new Date();
  const timedDiscountWillStart = isTimedDiscount && new Date(product?.discounted_price_from) > now;
  const timedDiscountStarted = isTimedDiscount && !timedDiscountWillStart && new Date(product?.discounted_price_to) > now;
  const showLabel = (timedDiscountWillStart || timedDiscountStarted) && !(product?.labels?.plp.some((i) => i.position === 'right_top'));

  return (
    <div>
      {discountPercentage < 100 > 0 ? (
        <div className="Probox__discount__labelBox">
          {showLabel && product?.item_type !== 'configurable' && isTimedDiscount
              && (
          <div className="timer-iconBox">            
                <img src={timedDiscountIcon} alt="" />
          </div>
          )}
          {discountPercentage && discountPercentage > 9 ? (
            <span>{`${discountPercentage}% ${t('OFF')}`}</span>
          ) : ''}
          {discountPercentage < 10 && discountPercentage > 0 ? (
            <span>{`${t('SAR')} ${discountedAmount} ${t('OFF')}`}</span>
          ) : ''}
        </div>
      ) : null}
      {/* <div className="Probox__discount__labelBox">
        <div className="timer-iconBox">
          <img src={timedDiscountIcon} alt="" />
        </div>
        <span>30% OFF</span>
      </div> */}
    </div>
  );

}
export default PLPdiscountBox;