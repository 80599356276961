import axios from "axios";

export const setHeader = (name, value) => {
  if (name) {
    axios.defaults.headers.common['X-Frame-Options'] = "DENY";
    axios.defaults.headers.common['x-app-version'] = "1.4.1";
    axios.defaults.headers.common['x-device-os-type'] = "web";
    axios.defaults.headers.common['Document-Policy'] = "js-profiling";
    axios.defaults.headers.common[name] = value;
  }
};
