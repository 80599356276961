import React, { useState } from "react";
import black_left_arrow from "./../../assets/Icon/black_left_arrow.svg";
import black_right_arrow from "./../../assets/Icon/black_right_arrow.svg";
import "./../../SCSS/_newArrival.scss";
import "./../../SCSS/ProductListPage/_plpSimilarProduct.scss";
import * as services from "./../../services/services";
import OldPrice from "../Font/OldPrice";
import Price from "../Font/Price";
import Heading6 from "../Font/Heading6";
import RatingBlock from "../MostSharedComponent/RatingBlock";
import shopping_cart from "./../../assets/Icon/shopping_cart.svg";
import { loadCartData } from "../../redux/appAction";
import { addToCart } from "../../services/cart.service";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { Spinner } from "../helpers/utils/spinner";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";

function ProductListingPageSimilarProduct({
  productDetailPage,
  sectionTitle,
  carouselData,
  productType,
  containerClassName,
  handleChangeCartPopup,
}) {

  const { setCartErr } = useCartErrorMessage();

  const [disableLeftArrow, setDisableLeftArrow] = useState(true);
  const [disableRightArrow, setDisableRightArrow] = useState(false);
  const [arrowState, setArrowState] = useState(true);
  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const leftSide = (e) => {
    setArrowState(!arrowState);
    setDisableRightArrow(false);
    e.preventDefault();
    document.querySelector(`.${containerClassName}`).scrollLeft =
      document.querySelector(`.${containerClassName}`).scrollLeft -
      document.querySelector(`.${containerClassName}`).clientWidth -
      100;
    let scroll = document.querySelector(`.${containerClassName}`).scrollLeft;
  };
  const rightSide = (e) => {
    setArrowState(!arrowState);
    setDisableLeftArrow(false);
    e.preventDefault();

    document.querySelector(`.${containerClassName}`).scrollLeft =
      document.querySelector(`.${containerClassName}`).scrollLeft +
      document.querySelector(`.${containerClassName}`).clientWidth -
      100;
  };

  const AddProductToCart = (sku, id, product, productIndex) => {
    setLoaderOn(true);
    const data = {
      items: [
        {
          sku: sku,
          qty: 1,
          id: id
        },
      ],
    };

    addToCart(data)
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
        setLoaderOn(false);
      })
      .finally(() => {
        setLoaderOn(false);
      });
  };
  return (
    <>
      <div>
        <div
          className={
            !productDetailPage
              ? "container-fluid carousel__container"
              : "carousel__container"
          }
        >
          <div
            className={
              !productDetailPage
                ? "carousel__block"
                : "less__width__carousel__block"
            }
          >
            <p className="section__title">{t("You_Can_Also_Purchase")}</p>
            <div className="arrow left__arrow" onClick={leftSide}>
              <img src={black_left_arrow} alt="" />
            </div>
            <div className="arrow right__arrow" onClick={rightSide}>
              <img src={black_right_arrow} alt="" />
            </div>
            <div className="productList__similar__product__container">
              {carouselData.map((product, productIndex) => {
                return (
                  <div className="productListPage__similar__product__card">
                    <div className="productListPage__similar__product__header__block">
                      <div className="productListPage__similar__product__image__block">
                        <img
                          src={product.image}
                          alt=""
                          className="productList__similar__product__image"
                        />
                      </div>
                    </div>
                    <p className="productList__similar__product__name">
                      <Heading6 text={product.productName} marginBottom={10} />
                    </p>
                    <div className="productList__similar__product__prize__block">
                      <OldPrice
                        oldPrice={product.oldPrice}
                        size="text3"
                        color="#c8c8c8"
                        marginBottom={10}
                        lineThrough={true}
                        span={true}
                      />
                      <Price
                        price={product.price}
                        marginLeft={5}
                        marginBottom={10}
                        size="heading6"
                        span={true}
                      />
                    </div>
                    <div className="productList__similar__product__rating__block">
                      <RatingBlock
                        rating={product.rating}
                        totalRatings={product.totalRatings}
                      />
                    </div>

                    {product?.stock > 0 ? (
                      <div
                        className="productList__similar__product__addToCart__button"
                        onClick={() => AddProductToCart(product.sku, product.id, product, productIndex)}
                      >
                        <img
                          src={shopping_cart}
                          alt=""
                          className="productList__similar__product__addToCart__icon"
                        />
                        {loaderOn === true || spinnerOn === true ? <Spinner /> : t("Add_To_Cart")}
                      </div>
                    ) : (
                      <div className="productList__similar__product__outofStock__button">
                        {t("Out_Of_Stock")}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductListingPageSimilarProduct;
