import * as actionType from "./../../actionType";

const initialState = {
  customerDetails: '',
  customerSignUpOtpData: '',
  customerLogout: '',
  customerSignInMsg: false,
  customerUpdatePasswordStatus: false,
  customerProfileDetails: '',
  customerUpdateProfileStatus: false,
  customerVerifyMobileOTP: "",
  customerResetPasswordOTP: "",
  storeCreditsHistoryList: [],
  storeCredits: ""
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CUSTOMER_DETAILS:
      return { ...state, customerDetails: action.payload };
    case actionType.CUSTOMER_SIGN_UP_OTP:
      return { ...state, customerSignUpOtpData: action.payload };
    case actionType.CUSTOMER_LOG_OUT:
      return { ...state, customerLogout: action.payload };
    case actionType.CUSTOMER_SIGN_IN_MSG:
      return { ...state, customerSignInMsg: action.payload };
    case actionType.CUSTOMER_UPDATE_PASSWORD:
      return { ...state, customerUpdatePasswordStatus: action.payload };
    case actionType.CUSTOMER_PROFILE_DETAILS:
      return { ...state, customerProfileDetails: action.payload };
    case actionType.CUSTOMER_UPDATE_PROFILE:
      return { ...state, customerUpdateProfileStatus: action.payload };
    case actionType.CUSTOMER_VERIFY_MOBILE_OTP:
      return { ...state, customerVerifyMobileOTP: action.payload };
    case actionType.CUSTOMER_RESET_PASSWORD_OTP:
      return { ...state, customerResetPasswordOTP: action.payload };
    case actionType.GET_STORE_CREDITS_HISTORY:
      return { ...state, storeCreditsHistoryList: action.payload };
    case actionType.GET_STORE_CREDITS_DETAILS:
      return { ...state, storeCredits: action.payload };
    default:
      return state;
  }
};

export default customerReducer;
