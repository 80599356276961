import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Payfort_Merchant_Page() {
  const params = useParams();
  const { t } = useTranslation();
 
  useEffect(() => {
    if (params) {
      localStorage.setItem("params", params);
    }
  }, []);
  return (
    <>
      <div>{t("Payfort_Merchant_Page")}</div>
      <div>{t("Payfort_Merchant_Page")}</div>
      <div>{t("Payfort_Merchant_Page")}</div>
      <div>{t("Payfort_Merchant_Page")}</div>
      <div>{t("Payfort_Merchant_Page")}</div>
      <div>{t("Payfort_Merchant_Page")}</div>
    </>
  );
}

export default Payfort_Merchant_Page;
