import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import empty_favourite from "./../../assets/Icon/empty_favourite.svg";
import fulfill_favourite from "./../../assets/Icon/fulfill_favourite.svg";
import "./../../SCSS/ProductType/_productOne.scss";
import Price from "./../Font/Price";
import OldPrice from "./../Font/OldPrice";
import RatingBlock from "../MostSharedComponent/RatingBlock";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAddToWishlist,
  loadCartData,
  loadDeleteFromWishlist,
  loadWishlistData,
} from "../../redux/appAction";
import { addToCart } from "../../services/cart.service";
import { useTranslation } from "react-i18next";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import TamaraLogoBox from "../MostSharedComponent/TamaraLogoBox";
import { checkWishList } from "../helpers/utils/wishListHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
import ProductLabel from "../MostSharedComponent/ProductLabel";
import PLPdiscountBox from "../MostSharedComponent/PLPdiscountBox";
import AddToCart from "../Buttons/AddToCart";
import { ImageResize } from "../helpers/utils/imageResize";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { checkoutEvents } from "../helpers/utils/ga/checkoutEvents";
import SoldOutLabel from "../MostSharedComponent/SoldOutLabel";
import LazyImageForProduct from "../MostSharedComponent/LazyImageForProduct";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";
import BundleOffer from "../BundelOffer";
import ProductDeliveryType from "../MostSharedComponent/ProductDeliveryType";
import { useQuery } from "react-query";
import { getHomePageData } from "../../services/homepage.service";
import { impressionEvents } from "../helpers/utils/ga/impressionEvents";
import HomeVariantOptions from "./HomeVariantOptions";
import SubscribeSection from "../Meza/SubscribeSection";
import SubsSectionContent from "../Meza/SubsSectionContent";

function ProductOne({
  productDetailPage,
  product,
  handleChangeCartPopup,
  outOfStockConfiguration,
  screenName,
  availablityData,
  sectionId,
  sectionTitle,
  customerDetails
}) {

  const { setCartErr } = useCartErrorMessage();

  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [count, setCount] = useState(0);
  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);
  const [sizeButtonIndex, setSizeButtonIndex] = useState(null);
  const wishlistData = useSelector((state) => state.appData.wishlistData);
  const { homepageData } = useSelector((state) => state.appData);

  useEffect(() => {
    product.is_price_hide = product.options.find((x) => {
      if (x.code === 'is_price_hide') {
        return x.visibility;
      }
      return false;
    });
  }, [product]);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, product?.sku));
  }, [wishlistData]);

  const handleFavourite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isFavourite) {
      setIsFavourite(false);
      setCount(count + 1);
      
    } else {
      setIsFavourite(true);
      setCount(count + 1);
      
    }
  };
  useEffect(() => {
    const data = {
      items: [product.sku],
    };
    if (isFavourite) {
      if (count > 0) {
        const addToWishlistData = dispatch(loadAddToWishlist(data, t)).then(
          (res) => {
            checkoutEvents.addToWishlist(product, screenName);
            dispatch(loadWishlistData());
          }
        );
      }
    } else {
      if (count > 0) {
        if (!isFavourite) {
          checkoutEvents.removeFromWishlist(product, screenName);
          removeFromWL(product.sku);
        }
      }
    }
  }, [isFavourite]);

  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };
    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data, t)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };

  const AddProductToCart = (sku) => {
    // impressionEvents.impressionAddToCart(
    //   product, 1,"home"
    // );
    setLoaderOn(true);
    if (product?.variants && product?.variants.length > 0 && sizeButtonIndex && sizeButtonIndex >= 0) {
      sku = product.variants[sizeButtonIndex].sku;
    }
    const data = {
      items: [
        {
          sku: sku,
          qty: 1,
          id: product?.id
        },
      ],
    };

    addToCart(data)
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id, sectionTitle,sectionId)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
        setLoaderOn(false);
      })
      .finally(() => {
        setLoaderOn(false);
      });
  };
  // useEffect(() => {
  //   impressionEvents.impressionViewProduct(
  //     product
  //   );
  // }, [product]);
  const targetPrice = product?.price_rounded;
  const matchedProduct = product?.variants?.filter(product => product.price_rounded === targetPrice);
  const newMatchPrice = matchedProduct?.length > 1 ? matchedProduct?.[0] : matchedProduct?.[0];
  const screenSize = matchedProduct && matchedProduct[0]?.options?.screen_size;
  const seletcedScreenSize = product?.variantOptions?.[0].attribute?.options.filter(product => product.id === screenSize);
  const defaultSku = matchedProduct && matchedProduct[0]?.sku;
  const sizeButtonClick = (sizeIndex) => {
    product.sku = product?.variants[sizeIndex]?.sku;
    product.name = product?.variants[sizeIndex]?.name;
    product.description = product?.variants[sizeIndex]?.description;
    product.price = product?.variants[sizeIndex]?.price;
    product.currency = product?.variants[sizeIndex]?.currency;
    product.price_rounded = product?.variants[sizeIndex]?.price_rounded;
    product.discounted_price = product?.variants[sizeIndex]?.discounted_price;
    product.discounted_price_rounded = product?.variants[sizeIndex]?.discounted_price_rounded;
    product.discounted_price_from = product?.variants[sizeIndex]?.discounted_price_from;
    product.discounted_price_to = product?.variants[sizeIndex]?.discounted_price_to;
    product.is_count_down = product?.variants[sizeIndex]?.is_count_down;
    product.stock = product?.variants[sizeIndex]?.stock;
    product.item_type = product?.variants[sizeIndex]?.item_type;
    product.meza = product?.variants[sizeIndex]?.meza;
    product.qitaf = product?.variants[sizeIndex]?.qitaf;
    product.eligible_for_tabby = product?.variants[sizeIndex]?.eligible_for_tabby;
    product.enable_shipping = product?.variants[sizeIndex]?.enable_shipping;
    product.additional_estimated_day = product?.variants[sizeIndex]?.additional_estimated_day;
    product.current_time = product?.variants[sizeIndex]?.current_time
    setSizeButtonIndex(sizeIndex);
  };
  const stringToCheck = product?.name;
  const regex = /\d+$/;
  const hasAnythingAfterDash = regex.test(stringToCheck);
  const productName = (() => {
    if (!hasAnythingAfterDash && newMatchPrice?.name !== undefined) {
    return newMatchPrice.name;
  }
    return product.name;
  })();
  useEffect(() => {
    const boxes = document.querySelectorAll('.productOne__name');
    let maxHeight = 0;

    // Calculate the maximum height among all the divs with class '.productNine__name'
    boxes.forEach((box) => {
      const boxHeight = box.offsetHeight;
      maxHeight = Math.max(maxHeight, boxHeight);
    });

    // Set the calculated maxHeight to all divs with class '.productNine__name'
    boxes.forEach((box) => {
      box.style.height = `${maxHeight}px`;
    });
  }, []);
  useEffect(() => {
    if(product){
      // impressionEvents.impressionViewProduct(
      //   product,
      //   "Home"
      // );
    }
   
  }, [product]);
  return (
    <div
      key={product.id}
      className={
        !productDetailPage ? "productOne__block" : "pd__productOne__block"
      }
      onClick={() => {navigate(productUrlHelper(product?.english_name, product?.sku));
        impressionEvents.impressionProductClick(
        "select_item", product,sectionTitle,sectionId
      );}}
    >
      
      <PLPdiscountBox product={product} t={t} />
      <div className="product__pics__area">
        <Link
          to={productUrlHelper(product?.english_name, product?.sku)}
          aria-label={`${titleChange(product.name)}`}
        >
          <div className="productOne__image__block">
            <LazyImageForProduct
              product={product}
              srcSetReSize={ImageResize}
              alt="Product Detail"
              className="productOne__image"
            />
          </div>
        </Link>
        <ProductLabel product={product} labelType="plp" />
        <SoldOutLabel
          t={t}
          product={product}
          outOfStockConfiguration={outOfStockConfiguration}
        />
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="plp__tamara__box mb-2">
          <TamaraLogoBox
            product={product}
          />
        </div>
      </div>
      <BundleOffer bundleEnable={product?.is_bundle_enable} t={t} />
      <div className="productOne__name__favourite">
        <Link
          to={productUrlHelper(product?.english_name, product?.sku)}
          className="productOne__name"
          aria-label={product?.name}
        >
          {titleChange(productName)}
        </Link>

        {/* <Heading7 text={titleChange(product.name)} /> */}
        <img
          onMouseEnter={() => setIsFavouriteHover(true)}
          onClick={handleFavourite}
          onMouseLeave={() => setIsFavouriteHover(false)}
          className={
            !isFavourite
              ? "productOne__favourite__icon"
              : "productOne__favourite__icon__disable"
          }
          width={24}
          height={24}
          src={isFavouriteHover ? fulfill_favourite : empty_favourite}
          alt="Product Favorite"
        />
        <img
          onClick={handleFavourite}
          className={
            isFavourite
              ? "productOne__favourite__icon"
              : "productOne__favourite__icon__disable"
          }
          width={24}
          height={24}
          src={fulfill_favourite}
          alt="Product Favorite"
        />
      </div>    
      <ProductDeliveryType  
        product={product}
        availablityData={availablityData || homepageData?.availablityData}
        t={t}
      />  
      <RatingBlock rating={4.2} totalRatings={2183} />
      <div className="prize__block">
        {
          !product?.is_price_hide &&
          <>
            <OldPrice
              oldPrice={product?.price_rounded}
              price={
                product.discounted_price_rounded === null
                  ? product.price_rounded
                  : product.discounted_price_rounded
              }
              size="text4"
              color="#c8c8c8"
              marginBottom={0}
              // lineThrough={true}
              span={true}
              currency={product?.currency}
            />
            <Price
              price={
                product.discounted_price_rounded === null
                  ? product.price_rounded
                  : product.discounted_price_rounded
              }
              // marginLeft={5}
              size="heading7"
              span={true}
              currency={product?.currency}
            />
          </>
        }
      </div>
      <div className="size__button__block">
          {product?.variantOptions && (
            <HomeVariantOptions
              sizeButtonClick={sizeButtonClick}
              sizeButtonIndex={sizeButtonIndex}
              variantOptions={product?.variantOptions}
              product={product}
            />
          )}
        </div>
      {/* <button onClick={()=>removeFromWL(product.sku)}>remove</button> */}
      {!product?.hide_subscriber_price && (
          <SubscribeSection>
            <SubsSectionContent
              t={t}
              meza_subscriber_price={product?.meza_subscriber_price}
              user={customerDetails}
              discounted_price={product.discounted_price_rounded}
              og_price={product.price_rounded}
            />
          </SubscribeSection>
        )}
      {productDetailPage ? (
        <span onClick={e => { e.stopPropagation(); e.preventDefault(); }}>
          {product?.is_bundle_enable === 1 ? (
            <Link
              to={productUrlHelper(product?.english_name, product?.sku)}
              aria-label={`${titleChange(product.name)}`}
              style={{ textDecoration: 'none' }}
            >
              <AddToCart
                product={product}
                AddProductToCart={AddProductToCart}
                outOfStockConfiguration={outOfStockConfiguration}
                loaderOn={loaderOn || spinnerOn}
                matchedProduct = {matchedProduct}
              />
            </Link>
          ) : (
            <AddToCart
              product={product}
              AddProductToCart={AddProductToCart}
              outOfStockConfiguration={outOfStockConfiguration}
              loaderOn={loaderOn || spinnerOn}
              matchedProduct = {matchedProduct}
            />
          )}
        </span>
      ) : (
        ""
      )}
    </div>
  );
}

export default ProductOne;
