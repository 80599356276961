import React from "react";
import "./../SCSS/_dealsOfTheWeek.scss";
// import { defaultImageHelper } from "./helpers/utils/defaultImageHelper";
import { HalfBannerImageResize } from "./helpers/utils/imageResize";
import LazyImage from "./MostSharedComponent/LazyImage";

const getRandonId = () =>  Math.ceil(Math.random() * 9999) 

function DealsOfTheWeek({ dealsOfTheWeekData, sectionTitle }) {
  return (
    <div className="container-fluid dealsOfTheWeek__container">
      <div className="dealsOfTheWeek__block">
        <p className="section__title">{sectionTitle}</p>
        <div className="row">
          {dealsOfTheWeekData &&
            dealsOfTheWeekData?.map((deals, dealsIndex) => {
              return (
                <div
                  key={getRandonId()}
                  className="col-sm-12 col-md-4 deals__block"
                >
                  {deals?.deeplinkUrl !== '' && deals?.deeplinkUrl !== undefined && deals?.deeplinkUrl !== null ?
                    <a
                      href={
                        deals?.deeplinkUrl || "#"
                      }
                      target="_blank"
                      rel="noreferrer"
                      aria-label={`Deals Of The Week ${dealsIndex}`}
                    >
                      <LazyImage
                        imgSrc={deals.imageUrl}
                        srcSetReSize={HalfBannerImageResize}
                        alt="Product Deals"
                        className="deals__image"
                      />
                    </a> : <LazyImage
                      imgSrc={deals.imageUrl}
                      srcSetReSize={HalfBannerImageResize}
                      alt="Product Deals"
                      className="deals__image"
                    />}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default DealsOfTheWeek;
