import React from "react";
import ProductComparisonBlock from "./ProductComparisonBlock";
import ProductHighlights from "./ProductHighlights";
import BreadCrumbs from "../BreadCrumbs";
import shipped from "./../../assets/Icon/shipped.svg";
import return_box from "./../../assets/Icon/return_box.svg";
import payment_method from "./../../assets/Icon/payment_method.svg";
import wrench from "./../../assets/Icon/wrench.svg";
import remove_comparison from "./../../assets/Icon/remove_comparison.svg";
import sony_logo from "./../../assets/Icon/sony_logo.svg";
import product_01 from "./../../assets/Product/product_01.png";
import product_02 from "./../../assets/Product/product_02.png";
import product_03 from "./../../assets/Product/product_03.png";
import product_04 from "./../../assets/Product/product_04.png";
import Heading3 from "../Font/Heading3";
import Text3 from "../Font/Text3";
import "./../../SCSS/ProductComparison/_productComparisonList.scss";
import { useTranslation } from "react-i18next";
import empty_Cart from './../../assets/Icon/empty-cart@2x.png';
import { Link } from "react-router-dom";

const ProductHighlightsData = [
  {
    image: shipped,
    title: "Free Delivery",
    text: "Lorem ipsum dolor sit amet, consectetur ",
  },
  {
    image: wrench,
    title: "Easy Installation",
    text: "Lorem ipsum dolor sit amet, consectetur ",
  },
  {
    image: return_box,
    title: "Hassle Free Returns",
    text: "Lorem ipsum dolor sit amet, consectetur ",
  },
  {
    image: payment_method,
    title: "Hassle Free Payment",
    text: "Lorem ipsum dolor sit amet, consectetur ",
  },
];
const ProductComparisonList = ({ productList, removeAllProductFromCompare, removeProductFromCompare, handleChangeCartPopup, outOfStockConfiguration, keySpecList, storePickupGlobalConfiguration }) => {
  const { t } = useTranslation();
  return (
    <div className="product__comparison__list__container" >
      <div className="product__comparison__list__title__block d-flex justify-content-lg-between ">
        <Heading3 text="" />
        {productList && productList.length > 0 &&
          <div className="remove__comparison__block" onClick={() => removeAllProductFromCompare()}>
            <p className="remove__comparison">{t("Remove_Comparison")}</p>
            <div className="remove__comparison__icon">
              <img src={remove_comparison} alt="" />
            </div>
          </div>
        }
      </div>
      <div className="product__comparison__list bg-white py-3">
        <BreadCrumbs back="/" backButton={true} firstBread="/products/compare" firstBreadTitle={t('Compare')} highlight="firstBreadTitle" />
        {productList && productList.length > 0 ?
          <ProductComparisonBlock productList={productList} removeProductFromCompare={removeProductFromCompare} handleChangeCartPopup={handleChangeCartPopup} outOfStockConfiguration={outOfStockConfiguration} keySpecList={keySpecList} storePickupGlobalConfiguration={storePickupGlobalConfiguration} />
          :
          <div className="shopping__compare__emptyBox">
            <img src={empty_Cart} />
            <Text3 text={t("you_don_t_have_any_items_in_compare_yet")} />
            <Link to="/" className="continue_shopping_btn">{t("START_SHOPPING")}</Link>
          </div>
        }
      </div>
      {productList && productList.length > 0 &&
        <ProductHighlights ProductHighlightsData={ProductHighlightsData} />
      }
    </div>
  );
};

export default ProductComparisonList;
