export const GET__HOME__PAGE__DATA = "GET__HOME__PAGE__DATA"
export const GET_AVAILABLE_PAYMENT_METHOD__DATA = "GET_AVAILABLE_PAYMENT_METHOD__DATA"

export const RANDOM_VALUE = "RANDOM_VALUE"

export const SET__PRODUCT__STATUS__DATA = "SET__PRODUCT__STATUS__DATA"

export const GET__ALL__CATEGORY__DATA = "GET__ALL__CATEGORY__DATA"

export const GET__PRODUCT__DETAIL__DATA = "GET__PRODUCT__DETAIL__DATA"
export const GET__APPLY__FILTER__PRODUCTS__DATA = "GET__APPLY__FILTER__PRODUCTS__DATA"

export const SET__SELECTED__CATEGORY = "SET__SELECTED__CATEGORY"
export const GET__FILTER__DATA = "GET__FILTER__DATA"


export const SET__WISHLIST__COUNT = "SET__WISHLIST__COUNT"
export const GET__WISHLIST__DATA = "GET__WISHLIST__DATA"
export const SET__WISHLIST__AVAILABILITY__DATA = "SET__WISHLIST__AVAILABILITY__DATA"

export const SET__ORDER__ID__DATA = "SET__ORDER__ID__DATA"
export const GET__ORDER__DETAIL__DATA = "GET__ORDER__DETAIL__DATA"

export const GET__CITY__LOCATION__DATA = "GET__CITY__LOCATION__DATA"
export const GET__COUNTRIES__LOCATION__DATA = "GET__COUNTRIES__LOCATION__DATA"
export const GET__STORES__LOCATION__DATA = "GET__STORES__LOCATION__DATA"
export const GET__ENABLE__STORES__LOCATION__DATA = "GET__ENABLE__STORES__LOCATION__DATA"


export const LOADING = "LOADING"
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"

export const USERLOGGEDIN = "USERLOGGEDIN"
export const GET_HANDSHAKE = "GET_HANDSHAKE"
export const COMPARE_PRODUCT_LIST = "COMPARE_PRODUCT_LIST"

// common 
export const LOADER_START = "LOADER_START";
export const LOADER_START_END = "LOADER_START_END";
export const LOADER_END = "LOADER_END";
export const NOTIFY_SUCCESS_MSG = "NOTIFY_SUCCESS_MSG";
export const NOTIFY_ERROR_MSG = "NOTIFY_ERROR_MSG";
export const NOTIFY_CLEAR_MSG = "NOTIFY_CLEAR_MSG";
export const CMS_PAGE_CONTENT = "CMS_PAGE_CONTENT";
export const COMPARE_LIST_UPDATE = "COMPARE_LIST_UPDATE";

// customer 
export const CUSTOMER_DETAILS = "CUSTOMER_DETAILS"
export const CUSTOMER_SIGN_UP_OTP = "CUSTOMER_SIGN_UP_OTP"
export const CUSTOMER_LOG_OUT = "CUSTOMER_LOG_OUT"
export const CUSTOMER_SIGN_IN_MSG = "CUSTOMER_SIGN_IN_MSG"
export const CUSTOMER_UPDATE_PASSWORD = "CUSTOMER_UPDATE_PASSWORD"
export const CUSTOMER_PROFILE_DETAILS = "CUSTOMER_PROFILE_DETAILS"
export const CUSTOMER_UPDATE_PROFILE = "CUSTOMER_UPDATE_PROFILE"
export const CUSTOMER_VERIFY_MOBILE_OTP = "CUSTOMER_VERIFY_MOBILE_OTP"
export const CUSTOMER_RESEND_MOBILE_OTP = "CUSTOMER_RESEND_MOBILE_OTP"
export const CUSTOMER_RESET_PASSWORD_OTP = "CUSTOMER_RESET_PASSWORD_OTP"
export const GET_STORE_CREDITS_HISTORY = "GET_STORE_CREDITS_HISTORY"
export const GET_STORE_CREDITS_DETAILS = "GET_STORE_CREDITS_DETAILS"

// customer addresses
export const CUSTOMER_ADDRESS_LIST = "CUSTOMER_ADDRESS_LIST"

// cart 
export const GET__CART__QUOTE__ID = "GET__CART__QUOTE__ID"
export const GET__CART__DATA = "GET__CART__DATA"
export const CUSTOMER_ADDRESS_ADD_UPDATE = "CUSTOMER_ADDRESS_ADD_UPDATE"
export const CUSTOMER_ADDRESS_ADD_UPDATE_ERR_MSG = "CUSTOMER_ADDRESS_ADD_UPDATE_ERR_MSG"
export const CUSTOMER_ADDRESS_DELETE = "CUSTOMER_ADDRESS_DELETE"
export const UPDATE_CUSTOMER_SHIPPING_INFO = "UPDATE_CUSTOMER_SHIPPING_INFO"
export const GET_LOYALTY_EARN_TYPE_DATA = "GET_LOYALTY_EARN_TYPE_DATA"
export const SELECTED_LOYALTY_POINTS_PROGRAM = "SELECTED_LOYALTY_POINTS_PROGRAM"
export const DELETE_CUSTOMER_SHIPPING_INFO = "DELETE_CUSTOMER_SHIPPING_INFO"

// customer-orders 
export const CUSTOMER_ORDER_LIST = "CUSTOMER_ORDER_LIST"
export const CUSTOMER_ORDER_DETAILS = "CUSTOMER_ORDER_DETAILS"
export const CUSTOMER_ORDER_LOADING_START = "CUSTOMER_ORDER_LOADING_START"
export const CUSTOMER_ORDER_LOADING_STOP = "CUSTOMER_ORDER_LOADING_STOP"



// other 
export const GET_FLYER_PDF = "GET_FLYER_PDF"

// customer-newsletter 
export const CUSTOMER_NEWS_LETTER_SUB_MSG = "CUSTOMER_NEWS_LETTER_SUB_MSG"

// category 
export const CATEGORY_DETAILS = "CATEGORY_DETAILS"
export const GET__CONFIGURATION__SETTING__DATA = "GET__CONFIGURATION__SETTING__DATA"
export const USER_LOGIN_DATA = "USER_LOGIN_DATA"

export const NIQATY_RESET_FLOW = 'NIQATY_RESET_FLOW'

export const MODAL_DATA = "MODAL_DATA"

export const LOGISTRATION_MODE = "LOGISTRATION_MODE"

export const TOGGLE_MODAL = "TOGGLE_MODAL"

export const LOGISTRATION_STATE = "LOGISTRATION_STATE"
