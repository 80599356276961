import moment from "moment-timezone"

const isAfterTimeLimit = (date, limit) => {
    const time = moment.tz(date, 'Asia/Riyadh');
    const limitTime = time.clone().startOf('day').add(limit, 'hours');

    return time.isAfter(limitTime);
};

export const getEstimatedDelivery = ({
    orderDate,
    additionalEstimatedDays,
    cityData,
    format,
}) => {
    if (!orderDate || !cityData) return '';

    const deliveryDate = moment(orderDate).add(+cityData.estimatedDays, 'days');
    if (isAfterTimeLimit(deliveryDate, cityData.hourLimit)) deliveryDate.add(1, 'day');
    if (additionalEstimatedDays) deliveryDate.add(additionalEstimatedDays, 'days');
    if (deliveryDate.day() === 5) deliveryDate.add(1, 'day');
    return deliveryDate.format(format);
};