import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../helpers/utils/useWindowDimensions";
import "./../../SCSS/Font/_font.scss";
function Text31({ text, text2, color, marginBottom, marginLeft, lineThrough, span, textAlign, textTransform, isFull, device }) {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  let sliceNumber
  if (width <= 400) {
    sliceNumber = 4
  } else if (width >= 400 && width <= 550) {
    sliceNumber = 12
  } else if (width >= 550 && width <= 768) {
    sliceNumber = 25
  } else if (width >= 769 && width <= 988) {
    sliceNumber = 45
  } else if (width >= 989 && width <= 1051) {
    sliceNumber = 60
  } else if (width >= 1051) {
    sliceNumber = 70
  }

  return (
    <>
      {!span ? (
        <p
          style={{
            textAlign: `${textAlign}`,
            textTransform: `${textTransform}`,
            color: `${color}`,
            textDecoration: `${lineThrough ? "line-through" : "none"}`,
            margin: `0px 0px ${marginBottom ? marginBottom : 0}px ${marginLeft ? marginLeft : 0}px`,
          }}
          className="text3"
        ><b>{text2}</b>&nbsp;
          {isFull ?
            text
            :
            <>
              {isReadMore ? text.slice(0, sliceNumber) : text}
              <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? t("Read more") : <p>{t("Show less")}</p>}
              </span>
            </>
          }
        </p>
      ) : (
        <span
          style={{
            textAlign: `${textAlign}`,
            textTransform: `${textTransform}`,
            color: `${color}`,
            textDecoration: `${lineThrough ? "line-through" : "none"}`,
            margin: `0px 0px ${marginBottom ? marginBottom : 0}px ${marginLeft ? marginLeft : 0}px`,
          }}
          className="text3"
        >
          {text}
        </span>
      )}
    </>
  );
}

export default Text31;
