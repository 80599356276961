import React from "react";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
// import Heading5 from "../Font/Heading5";
import Heading6 from "../Font/Heading6";
import OldPrice from "../Font/OldPrice";
import Price from "../Font/Price";
// import Text5 from "../Font/Text5";
import { discountOfferHelper } from "../helpers/utils/discountOfferHelper";
import "./../../SCSS/MostSharedComponents/_priceBlock.scss"
import RatingBlock from "./RatingBlock";

function PriceBlock({ oldPrice, price, currency, monthlySavingTagline, product }) {
  let savePrice = (oldPrice - price).toFixed(2);
  const { t } = useTranslation();
  
  let getDiscount = discountOfferHelper(t,product);

  return (
    <>
      {/* <div className="row pd__product__price__block "> */}
      <div className="pd__total__price__block">
        <div className="inner_price_section">
          <OldPrice
            oldPrice={oldPrice && oldPrice.toFixed(2)}
            price={price}
            size="text3"
            color="#7D7D7D"
            marginBottom={0}            
            currency={currency}
          />
          <Price currency={currency} price={price} size="heading4" marginBottom={5} />
        </div>
                 
          {savePrice > 0 &&
          <div className="save-amount-box"> 
            <h6 className="pd__save__price__button">{`${t("save")} ${t(currency)} ${savePrice}`}</h6>
          </div>
          }
          {getDiscount &&
            <div className="offer__persentage__box">
              <Heading6 text={getDiscount} />
            </div>
          }
        <div className="save_price_rating">
          
          {product?.reviewSummary &&
            <div className="rating_block_pdp">
              <RatingBlock
                size={22}
                rating={product?.reviewSummary?.avg}
                totalRatings={Object.values(
                  product?.reviewSummary?.totals
                ).reduce((a, b) => a + b)}
                // totalRatings={0}
                fillColor="#DC3A1A"
                emptyColor="#C8C8C8"
              />
            </div>
          }
        </div>
      </div>
      {/* <div className="col-2 col-sm-2 col-md-2 pd__or__block">
        <div className="pd__or__text__block">
          <p className="pd__or__text">{t("OR")}</p>
        </div>
        <div className="pd__mid__line"></div>
      </div>
      <div className="col-5 col-sm-6 col-md-5 pd__product__monthly__price__block">
        <Price currency={currency} price={price} size="heading4" />
        <Text5 text={monthlySavingTagline} color="#808080" marginBottom={0} />
        <Link to="/knowmore" className="pd__know__more">
          {"know more >"}
        </Link>
      </div> */}
      {/* </div> */}
    </>
  );
}

export default React.memo(PriceBlock);
