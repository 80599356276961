import React from "react";
import { useTranslation } from "react-i18next";
import Text5 from "../Font/Text5";
import mezzacard from "./../../assets/Icon/mezzacard.png";

function MizaAndQitaf({ product }) {

  const { t } = useTranslation();
  const currentLang = localStorage.getItem("i18next") || "en";
  let quitafcard = require(`./../../assets/Icon/icon-qitaf-${currentLang}.png`)

  return (
    <>
      {product.item_type !== "virtual" && 
        <React.Fragment>
        {product?.meza && product?.meza?.money_equivalent > 0 && (
          <div>
            <img src={mezzacard} alt="mezza" />
            <Text5
              className="mb-0"
              text={`(${t(product?.currency)} ${product?.meza?.money_equivalent ? product?.meza?.money_equivalent : 0})`}
            />
          </div>
        )}
        {product?.meza && product?.qitaf && product?.meza?.money_equivalent > 0 && product?.qitaf?.money_equivalent > 0 && (
          <span className="mx-1">{t('or')}</span>
        )}
        {product?.qitaf && product?.qitaf?.money_equivalent > 0 && (
          <div>
            <img src={quitafcard} alt="quitafcard" />
            <Text5
              className="mb-0"
              text={`(${t(product?.currency)} ${product?.qitaf?.money_equivalent ? product?.qitaf?.money_equivalent : 0})`}
            />
          </div>
        )}
        </React.Fragment>
      }
    </>
  );
}

export default MizaAndQitaf;