import React from "react";
import "./../../SCSS/MobileView/_mobileHomeCategoryBannerProductsBlock.scss";
import categoryImg from "./../../assets/MobilePages/MobileHome/categoryhome.png";
import MobileHomeCAtegoryBannerProduct from "../ProductType/MobileHomeCAtegoryBannerProduct";

function MobileHomeCategoryBannerProductsBlock({ featureProductsData, screenName }) {

  return (
    <div className="mobile__home__category__banner__product__container my-3 py-2 container-fluid">
      <div className="mobile__home__category__banner__product__block mb-4">
        <img
          className="mh__cbp__banner"
          src={categoryImg}
          alt="category image"
          width="100%"
        />
      </div>
      <div className="row mh__cbp__row">
        {featureProductsData && featureProductsData.map((product, productIndex) => {
          return (
            <MobileHomeCAtegoryBannerProduct product={product} key={product.sku} screenName={screenName || "Home"} />
          )
        })}

      </div>
    </div>
  );
}

export default MobileHomeCategoryBannerProductsBlock;
