export const TAMARA_INSTALLMENT_PLAN_URL = "https://cdn.tamara.co/widget-v2/tamara-widget.js"
export const TAMARA_PRODUCT_WIDGET_URL = "https://cdn.tamara.co/widget/product-widget.min.js"
export const TABBY_PROMO_URL = "https://checkout.tabby.ai/tabby-promo.js"
export const TABBY_CARD_URL = "https://checkout.tabby.ai/tabby-card.js"
export const FACEBOOK_SOCIAL_URL = "https://facebook.com/sharer/sharer.php?u="
export const WHATSAPP_SOCIAL_URL = "https://api.whatsapp.com/send?text="
export const TWITTER_SOCIAL_URL = "https://twitter.com/intent/tweet/?text=&url="
export const TELEGRAM_SOCIAL_URL = "https://telegram.me/share/url?text=&url="
export const MESSENGER_SOCIAL_URL = "https://facebook.com/sharer/sharer.php?u="
export const PINTEREST_SOCIAL_URL = "http://pinterest.com/pin/create/button/?url="
export const JQUERY_CDN_URL = "https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"
export const FOOTER_URL = "http://sonyworld.sa"