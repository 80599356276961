import React from "react";
import shopping_cart from "../../assets/Icon/shopping_cart.svg";
import NotifyMeBtn from "../MostSharedComponent/NotifyMeBtn";
import { useTranslation } from "react-i18next";
import { LoaderIcone } from "../../Pages/Loader";
import { Spinner } from "../helpers/utils/spinner";

function AddtoCartCategory({ product, AddProductToCart, outOfStockConfiguration, loaderOn, type, productIdData, matchedProduct }) {
    const { t } = useTranslation();
    let productOutOfStockStatus, isOutOfStock, isPreOrder
    const isExist = product.hasOwnProperty("productOutOfStockStatus")

    if (productIdData && productIdData?.length !== 0) {
        productOutOfStockStatus = product && productIdData?.[product?.id]?.productOutOfStockStatus ? productIdData?.[product?.id]?.productOutOfStockStatus : false
        isOutOfStock = productIdData?.[product?.id]?.orderType === 'out-of-stock' || productIdData?.[product?.id]?.stock === 0;
        isPreOrder = productIdData?.[product?.id]?.orderType === 'pre-order';
    } else if (isExist) {
        productOutOfStockStatus = product && product?.productOutOfStockStatus ? product?.productOutOfStockStatus : false
        isOutOfStock = product?.orderType === 'out-of-stock' || product?.stock === 0;
        isPreOrder = product?.orderType === 'pre-order';
    }

    return (
        <>
            {((productIdData && productIdData?.length !== 0) || isExist) && <>
                {isOutOfStock && outOfStockConfiguration?.module_status && productOutOfStockStatus ? (
                    <NotifyMeBtn product={product} />
                ) : (
                    <>
                        {isOutOfStock ? (
                            <div className="outofStock__button">{t("Out_Of_Stock")}</div>
                        ) : isPreOrder ? (
                            <div
                                className="addToCart__button pre-order"
                                onClick={() =>
                                    AddProductToCart(matchedProduct ? matchedProduct && matchedProduct?.[0]?.sku : product?.sku, type && type)
                                }
                            >
                                {loaderOn === true ?
                                    <Spinner />
                                    :
                                    <>
                                        <img src={shopping_cart} alt="" className="addToCart__icon pre-order" />
                                        {t("pre_order")}
                                    </>
                                }
                            </div>
                        ) : (
                            <div
                                className="addToCart__button"
                                onClick={() =>
                                    AddProductToCart(matchedProduct ? matchedProduct && matchedProduct?.[0]?.sku : product?.sku, type && type)
                                }
                            >
                                {loaderOn === true ?
                                    <Spinner />
                                    :
                                    <>
                                        <img src={shopping_cart} alt="" className="addToCart__icon" />
                                        {t("Add_To_Cart")}
                                    </>
                                }
                            </div>
                        )}
                    </>
                )}
            </>}
        </>
    );
}

export default AddtoCartCategory;