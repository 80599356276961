import React, { useState, useEffect } from "react";
import "./../../SCSS/MostSharedComponents/_availableOffers.scss";
import offer_tag from "./../../assets/Icon/offer_tag.svg";
import Heading6 from "../Font/Heading6";
import { useTranslation } from "react-i18next";
import TamaraProductWidget from "../../Pages/TamaraProductWidget";
import TamaraInstallmentPlanWidget from "../../Pages/TamaraInstallmentPlanWidget";

import TabbyWidget from "./TabbyWidget";
import { getTabbyMinMaxLimit, getTamaraMinMaxLimit, getTamaraValue, TamaraGlobalEnable, TabbyGlobalEnable } from "../helpers/utils/handsakeConfig";
import install_alive from "./../../assets/Icon/install-availbil.gif";
import Heading5 from "../Font/Heading5";


function AvailableOffers({ availableOffer, title, product, id }) {

  const { t } = useTranslation();
  const currentLanguageCode = localStorage.getItem("i18next") || "en";

  let price =
    product?.discounted_price_rounded === null
      ? product?.price_rounded
      : product?.discounted_price_rounded;

  let currency = product?.currency;

  const [isDefaultActive, setDefaultActive] = useState(false);

  const [isTamara, setIsTamara] = useState(false);
  const [isTabby, setIsTabby] = useState(false);
  const [minLimit, setMinLimit] = useState(0);
  const [maxLimit, setMaxLimit] = useState(0);
  const [months, setMonths] = useState(3);
  const item_type = product?.item_type;

  const ecom = JSON.parse(localStorage.getItem("handShakeConfig"));

  useEffect(() => {
    let getTamaraCheck3Month = getTamaraMinMaxLimit(price, months);
    let checkTamara = product?.installmentMethod?.eligible_for_tamara;
    let checkTabby = product?.installmentMethod?.eligible_for_tabby;
    if (checkTamara && getTamaraCheck3Month === true && item_type === "simple" && TamaraGlobalEnable() === 1) {
      setIsTamara(true);
      setDefaultActive(true);
    }
    if (checkTabby && item_type === "simple" && TabbyGlobalEnable() === 1) {
      setIsTabby(true);
      setDefaultActive(true);
    }
    if (months === 3) {
      let getMinLimit3 = getTamaraValue('tamara3_min');
      let getMaxLimit3 = getTamaraValue('tamara3_max');
      setMinLimit(getMinLimit3)
      setMaxLimit(getMaxLimit3)
    }
    if (months === 6) {
      let getMinLimit6 = getTamaraValue('tamara6_min');
      let getMaxLimit6 = getTamaraValue('tamara6_max');
      setMinLimit(getMinLimit6)
      setMaxLimit(getMaxLimit6)
    }
  }, [product]);

  return (
    <React.Fragment>
      {isDefaultActive &&
        <div className="product__avilable__offers__block product__common__main__block installment__availbility">
          <div className="installment__availbility__infobox">
            <img src={install_alive} className="" />
            <div className="install__contentbox">
              <Heading5 text={t("Installment availbility")} />
              <p className="mb-0">{t("Have it split payment into 3 or even 4 by our Installment partners")}</p>
            </div>
          </div>
          {ecom?.featureFlags?.ecom?.bootstraper?.tamara === 1 && isTamara && (
            <div className="product__offer__block">
              <TamaraInstallmentPlanWidget inlineType={2} price={price} currency={currency} months={3} minLimit={minLimit} maxLimit={maxLimit} currentLanguageCode={currentLanguageCode} />
            </div>
          )}
          {ecom?.featureFlags?.ecom?.bootstraper?.tabby === 1 && isTabby && (
            <div className="product__offer__block">
              <TabbyWidget
                price={price}
                currency={currency}
                widgetType="TabbyPromo"
                currentLanguageCode={currentLanguageCode}
              />
            </div>
          )}
          {/* {availableOffer.map((offer, offerIndex) => {
        return (
          <div key={offer.id} className="product__offer__block">
            <img src={offer_tag} alt="" className="product__offer__icon" />
            <div className="product__offer__text__box">
              <p className="product__offertype">
                {`${offer.offerType}${"    "}`}
                <span className="product__offerText">{offer.offerText}</span>
                <Link
                  to="/termsAndConditions"
                  className="product__termsAndConditions__link"
                >
                  {offer.termsAndConditions}
                </Link>
              </p>
            </div>
          </div>
        );
      })} */}
        </div>
      }
    </React.Fragment>
  );
}

export default AvailableOffers;