import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Heading7 from "../Font/Heading7";
import { emailValidator, isMobileNumber } from "../helpers/utils/validators";
import hide_password from "./../../assets/Icon/hide_password.svg";
import see_password from "./../../assets/Icon/see_password.svg";
import "./../../SCSS/Login/_signupModel.scss";
import * as services from "./../../services/services";
import PhoneInput from "react-phone-input-2";
import { customerSignUpOtpSuccess } from "../../services/customer/customer";
import { useTranslation } from "react-i18next";
import { Spinner } from "../helpers/utils/spinner";
import  usePassCheck  from "../helpers/hooks/usePassCheck";

function SignUpModel({ handleChangePopupMode, closeLoginPopup }) {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { customerSignUpOtpData } = useSelector(
    (state) => state.customerReducer
  );

  useEffect(() => {
    if (customerSignUpOtpData?.mobileNumber) {
      handleChangePopupMode("otp");
    }
  }, [customerSignUpOtpData]);

  // const T_REQ_FIRSTNAME = t("Firstname_is_missing");
  // const T_REQ_LASTNAME = t("Lastname_is_missing");
  const T_REQ_EMAIL = t("Email_is_missing");
  const T_INVALID_EMAIL = t("Invalid_email_address_example");
  const T_REQ_PASSWORD = t("Password_is_missing");
  const T_REQ_CONFIRM_PASSWORD = t("Confirm_Password_is_missing");
  const T_WEAK_PASSWORD = t(
    "Password_must_contain_1_uppercase_1_lowercase_1_number_and_at_least_8_characters_Do_not_add_more_than_5_consecutive_characters_123456_qwerty"
  );
  const T_PASSWORD_NOT_MATCHED = t("Password_not_matched");
  const T_REQ_MOBILE_NUMBER = t("Mobile Number is missing");
  const T_REQ_USERNAME = t("Username_is_required");
  const T_INVALID_MOBILE_NUMBER = t("Invalid_Mobile_Number");

  const [isCheckBoxHover, setIsCheckBoxHover] = useState(false);
  const [isCheckBox, setIsCheckBox] = useState(true);
  const handleFavourite = (e) => {
    setIsCheckBox(e.target.checked);
  };
  const [loaderOn, setLoaderOn] = useState(false);
  const [isPassword, setIsPassword] = useState(true);
  const [isConfirmPassword, setIsConfirmPassword] = useState(true);
  const [updateErrMsg, setUpdateErrMsg] = useState(false);
  const { loadingStartEnd } = useSelector((state) => state.commonReducer);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    username: "",
    password: "",
    confirmPassword: "",
    policyChecked: true,
  });
  const [errMsg, setErrMsg] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    policyChecked: true,
  });

  useEffect(() => {
    if (updateErrMsg === true) {
      setErrMsg(errMsg);
      setUpdateErrMsg(!updateErrMsg);
    }
  }, [updateErrMsg,errMsg]);
  
  const validateForm = async (event, name, value, FieldData) => {
    //A function to validate each input values
    switch (name) {
      // case "firstName":
      //   if (value === "") {
      //     setErrMsg({ ...errMsg, [name]: T_REQ_FIRSTNAME });
      //   } else {
      //     setErrMsg({ ...errMsg, [name]: "" });
      //   }
      //   break;
      // case "lastName":
      //   if (value === "") {
      //     setErrMsg({ ...errMsg, [name]: T_REQ_LASTNAME });
      //   } else {
      //     setErrMsg({ ...errMsg, [name]: "" });
      //   }
      //   break;
      case "email":
        let emailStatus = emailValidator(value);
        if (value === "") {
          setErrMsg({ ...errMsg, [name]: T_REQ_EMAIL });
        } else {
          if (emailStatus === "error") {
            setErrMsg({ ...errMsg, [name]: T_INVALID_EMAIL });
          } else {
            setErrMsg({ ...errMsg, [name]: "" });
          }
        }
        break;
      case "mobileNumber":
        if (value === "") {
          setErrMsg({ ...errMsg, [name]: T_REQ_MOBILE_NUMBER });
        } else {
          let isValidNumber = isMobileNumber(value);
          if (isValidNumber === "error") {
            setErrMsg({ ...errMsg, [name]: T_INVALID_MOBILE_NUMBER });
          } else {
            setErrMsg({ ...errMsg, [name]: "" });
          }
        }
        break;
      case "username":
        if (value === "") {
          setErrMsg({ ...errMsg, [name]: T_REQ_USERNAME });
        } else {
          let params = {
            username: value,
          };
          let usernameAvailable = await services.isUsernameAvailable(params);
          if (usernameAvailable.success === true) {
            setErrMsg({ ...errMsg, [name]: "" });
          } else {
            setErrMsg({ ...errMsg, [name]: usernameAvailable.message });
          }
        }
        break;
      case "password":
        if (value === "") {
          setErrMsg({ ...errMsg, [name]: T_REQ_PASSWORD });
        } else {
          let params = {
            password: value,
            username: "",
          };
          let pwdStrength = await services.checkPasswordStrength(params);

          if( usePassCheck(value) === false){ //eslint-disable-line
            setErrMsg({ ...errMsg, [name]: T_WEAK_PASSWORD});
          }
          if (pwdStrength?.strength === "fair" || pwdStrength?.strength === "strong") {
            if (FieldData?.confirmPassword) {
              if (value === FieldData?.confirmPassword) {
                setErrMsg({ ...errMsg, confirmPassword: "", password: "" });
              } else {
                setErrMsg({ ...errMsg, confirmPassword: T_PASSWORD_NOT_MATCHED, password: "" });
              }
            } else {
              setErrMsg({ ...errMsg, password: "" });
            }
          } else {
            setErrMsg({ ...errMsg, [name]: T_WEAK_PASSWORD });
          }
        }
        break;
      case "confirmPassword":
        if (value === "") {
          setErrMsg({ ...errMsg, [name]: T_REQ_CONFIRM_PASSWORD });
        } else {
          if (value === FieldData?.password) {
            setErrMsg({ ...errMsg, [name]: "" });
          } else {
            
            setErrMsg({ ...errMsg, [name]: T_PASSWORD_NOT_MATCHED });
          }
        }
        break;
      default:
        break;
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };
  const validateFn = React.useCallback(debounce(validateForm), [errMsg, updateErrMsg]);

  const handleChange = async (event, keyName) => {
    let value = event;
    let name = event;
    if (keyName === "mobileNumber") {
      value = event;
      name = "mobileNumber";
    } else {
      value = event.target.value;
      name = event.target.name;
    }
    setData({ ...data, [name]: value });
    validateFn(event, name, value, { ...data, [name]: value });
  };

  const handleBlur = (e) => {
    let obj = {
      target: {
        value: e.target.value.trim(),
        name: e.target.name
      }
    }
    handleChange(obj)
  }

  const allFeildValidate = () => {
    let validateFeild = [
      // {
      //   keyName: "firstName",
      //   defaultMsg: T_REQ_FIRSTNAME,
      // },
      // {
      //   keyName: "lastName",
      //   defaultMsg: T_REQ_LASTNAME,
      // },
      {
        keyName: "email",
        defaultMsg: T_REQ_EMAIL,
      },
      {
        keyName: "username",
        defaultMsg: T_REQ_USERNAME,
      },
      {
        keyName: "mobileNumber",
        defaultMsg: T_REQ_MOBILE_NUMBER,
      },
      {
        keyName: "password",
        defaultMsg: T_REQ_PASSWORD,
      },
      {
        keyName: "confirmPassword",
        defaultMsg: T_REQ_CONFIRM_PASSWORD,
      },
    ];

    let checkValueStatus = [];
    let checkErrStatus = [];

    let blankErrMsg = errMsg;
    validateFeild &&
      validateFeild.map((val, i) => {
        let keyVal = data[val.keyName];
        let errVal = errMsg[val.keyName];
        if (keyVal === "") {
          blankErrMsg[val.keyName] = val.defaultMsg;
        }

        if (keyVal !== "") {
          checkValueStatus.push("suc");
        }
        if (errVal === "") {
          checkErrStatus.push("err");
        }
      });
    setErrMsg(blankErrMsg);
    let checkSignUpStatus = false;
    if (
      checkValueStatus.length === validateFeild.length &&
      checkErrStatus.length === validateFeild.length
    ) {
      checkSignUpStatus = true;
    }
    setUpdateErrMsg(true);

    return checkSignUpStatus;
  };

  const onSignUp = () => {
    // setLoaderOn(true);
    let checkSignUpStatus = allFeildValidate();
    if (checkSignUpStatus === true) {
      let params = {
        // firstName: data.firstName,
        // lastName: data.lastName,
        firstName: "",
        lastName: "",
        email: data.email.trim(),
        phone_number: data.mobileNumber.trim(),
        password: data.password.trim(),
        username: data.username.trim(),
      };
      dispatch(services.customerSignUp(params));
    }
  };

  const closeAndResetPopupData = () => {
    setData({
      ...data,
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      username: "",
      password: "",
      confirmPassword: "",
      policyChecked: true,
    });

    setErrMsg({
      ...errMsg,
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      mobileNumber: "",
      password: "",
      confirmPassword: "",
      policyChecked: true,
    });
    dispatch(customerSignUpOtpSuccess(""));
    closeLoginPopup();
  };

  const redirectToCartPage = () => {
    closeLoginPopup();
    navigate("/cart");
  };

  const togglePassword = () => setIsPassword(!isPassword);
  const toggleConfirmPassword = () => setIsConfirmPassword(!isConfirmPassword);
  const [errors, setErrors] = useState([]);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // setLoaderOn(true)
      onSignUp();
    }
  };
  return (
    <div className="signupModel__container">
      {/* <div className="signupModel__header">
        <Heading4 text={t("Sign_Up_to_Sony")} color="#000000" />        
      </div> */}
      {/* <SocialLinkBox t={t} /> */}
      <div className="signupModel__content">
        {/* <div className="main__form__field__block">
          <Heading7 text={t("First_Name")} marginBottom={10} />
          <sup className="inputs_validation">*</sup>
          <div className="field__block">
            <input
              type="text"
              placeholder={t("First_Name")}
              className="form__field"
              id="firstName"
              name="firstName"
              value={data.firstName}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {errMsg.firstName && (
            <p className="invalid__message">{errMsg.firstName}</p>
          )}
        </div> */}
        {/* <div className="main__form__field__block">
          <Heading7 text={t("Last_Name")} marginBottom={10} />
          <sup className="inputs_validation">*</sup>
          <div className="field__block">
            <input
              type="text"
              placeholder={t("Last_Name")}
              className="form__field"
              id="lastName"
              name="lastName"
              value={data.lastName}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {errMsg.lastName && (
            <p className="invalid__message">{errMsg.lastName}</p>
          )}
        </div> */}
        <div className="main__form__field__block">
          {/* <p className="form__label">Email Address</p> */}
          <div className="field__itembox">
            <input
              type="text"
              // placeholder={t("Email_Address")}                
              className={`form__field ${data.email === "" ? "" : "focus_in"} `}
              onKeyDown={handleKeyDown}
              id="email"
              name="email"
              value={data.email || ""}
              onChange={(e) => handleChange(e)}
              onBlur={handleBlur}
            />
            <div className="field__labelbox">
              <Heading7 text={t("Email_Address")} marginBottom={0} />
              {/* <sup className="inputs_validation">*</sup> */}
            </div>
          </div>
          {errMsg.email && <p className="invalid__message">{errMsg.email}</p>}
        </div>
        <div className="main__form__field__block">
          {/* <p className="form__label">Mobile Number</p> */}
          <div className="field__itembox mobileNumber">
            <PhoneInput
              inputProps={{
                name: "mobileNumber",
                required: true,
                className: "profile__mobile__form__field form__field focus_in",
              }}
              onKeyDown={handleKeyDown}
              country="sa"
              onlyCountries={["sa"]}
              masks={{ sa: ".. ... ...." }}
              countryCodeEditable={false}
              disableDropdown={true}
              value={data.mobileNumber}
              onChange={(e) => handleChange(e, "mobileNumber")}
              className=""
            />
            <div className="field__labelbox">
              <Heading7 text={t("Mobile_Number")} marginBottom={0} />
              {/* <sup className="inputs_validation">*</sup> */}
            </div>
          </div>
          {errMsg.mobileNumber && (
            <p className="invalid__message">{errMsg.mobileNumber}</p>
          )}
        </div>
        <div className="main__form__field__block">
          {/* <p className="form__label">Mobile Number</p> */}
          <div className="field__itembox">
            <input
              type="text"
              // placeholder={t("Username")}                
              className={`form__field ${data.username === "" ? "" : "focus_in"} `}
              onKeyDown={handleKeyDown}
              id="username"
              name="username"
              value={data.username || ""}
              onChange={(e) => handleChange(e)}
              onBlur={handleBlur}
            />
            <div className="field__labelbox">
              <Heading7 text={t("Username")} marginBottom={0} />
              {/* <sup className="inputs_validation">*</sup> */}
            </div>
          </div>
          {errMsg.username && (
            <p className="invalid__message">{errMsg.username}</p>
          )}
        </div>
        <div className="main__form__field__block">
          {/* <p className="form__label">Password</p> */}
          <div className="field__itembox">
            <input
              type={isPassword ? "password" : "text"}
              // placeholder={t("Password")}                
              className={`form__field sentry-mask ${data.password === "" ? "" : "focus_in"} `}
              id="password"
              onKeyDown={handleKeyDown}
              name="password"
              value={data.password || ""}
              onChange={(e) => handleChange(e)}
              autoComplete="new-password"
            />
            <a onClick={() => togglePassword()}>
              {isPassword ? (
                <img src={see_password} alt="" />
              ) : (
                <img src={hide_password} alt="" />
              )}
            </a>
            <div className="field__labelbox">
              <Heading7 text={t("Password")} marginBottom={0} />
              {/* <sup className="inputs_validation">*</sup> */}
            </div>
          </div>
          {errMsg.password && (
            <p className="invalid__message">{errMsg.password}</p>
          )}
        </div>
        <div className="main__form__field__block">
          {/* <p className="form__label">Confirm Password</p> */}
          <div className="field__itembox">
            <input
              type={isConfirmPassword ? "password" : "text"}
              // placeholder={t("Confirm_Password")}
              onKeyDown={handleKeyDown}
              className={`form__field sentry-mask ${data.confirmPassword === "" ? "" : "focus_in"} `}
              id="confirmPassword"
              name="confirmPassword"
              value={data.confirmPassword || ""}
              onChange={(e) => handleChange(e)}
              autoComplete="new-password"
            />
            <a onClick={() => toggleConfirmPassword()}>
              {isConfirmPassword ? (
                <img src={see_password} alt="" />
              ) : (
                <img src={hide_password} alt="" />
              )}
            </a>
            <div className="field__labelbox">
              <Heading7 text={t("Confirm_Password")} marginBottom={0} />
              {/* <sup className="inputs_validation">*</sup> */}
            </div>
          </div>
          {errMsg.confirmPassword && (
            <p className="invalid__message">{errMsg.confirmPassword}</p>
          )}
        </div>
        <div className="main__policy__check__block">
          <div className="policy__check__block">
            <input type="checkbox" name="registerPolicy" checked={isCheckBox} onChange={(e) => handleFavourite(e)} id="registerPolicy" className="policy_check__icon" />
          </div>
          <p className="signUP__policy__label">
            {t("I_agree_to_our")}
            <Link 
            className="policy__link"
             to="/cms/terms-and-conditions"
             onClick={() => {
              closeLoginPopup()
            }}>
              {" "}
              {t("Terms_and_Conditions")}{" "}
            </Link>
            {t("&")}
            <Link className="policy__link" to="/cms/privacy-policy"
            onClick={() => {
              closeLoginPopup()
            }}>
              {" "}
              {t("Privacy_Policy")}{" "}
            </Link>
          </p>
        </div>

        <button
          className={`signup__button ${isCheckBox === false ? "cursor_not_allowed" : ""
            }`}
          disabled={isCheckBox === false ? true : false}
          onClick={() => onSignUp()}
        >
          {loadingStartEnd === true || loaderOn === true ? <Spinner /> : t("Register")}
        </button>
        {location.pathname === "/checkout" && (
          <React.Fragment>
            <div className="signup__or__block">
              <div className="signup__or__text__block">
                <p className="signup__or__text">{t("OR")}</p>
              </div>
              <div className="signup__mid__line"></div>
            </div>

            {location.pathname === "/checkout" && (
              <button onClick={() => closeLoginPopup()} className="getOtp__button">
                {t("Continue_As_Guest")}
              </button>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default SignUpModel;
