import React from "react";
import { useTranslation } from "react-i18next";
import "./../../SCSS/_productDetailsPage.scss";
import { Spinner } from "../helpers/utils/spinner";

function BuyNow({
    product,
    buyNow,
    outOfStockConfiguration,
    isCartData,
    type,
    setIsCartData,
    matchedProduct
}) {
    const sku = matchedProduct && matchedProduct.length > 0 ? matchedProduct[0]?.sku : product?.sku;
    const { t } = useTranslation();

    const productOutOfStockStatus =
        product && product?.productOutOfStockStatus
            ? product?.productOutOfStockStatus
            : false;
    const isOutOfStock =
        product?.orderType === "out-of-stock" || product?.stock === 0;
    const isPreOrder = product?.orderType === "pre-order";

    return (
        <>
            {product &&
                isOutOfStock &&
                outOfStockConfiguration?.module_status &&
                productOutOfStockStatus ? (
                null
            ) : (
                <>
                    {!isOutOfStock && !isPreOrder ? (
                        <div
                            className="pd__buy__button"
                            onClick={() => {
                                setIsCartData(true);
                                buyNow( sku, product?.id);
                            }}
                        >
                            {/* <img
                            src={shopping_cart}
                            alt=""
                            className="pd__addToCart__button"
                        /> */}
                            {isCartData === true ? (
                                <Spinner />
                            ) : (
                                `${t("Buy_Now")}`
                            )}
                        </div>
                    ) : null}
                </>
            )}
        </>
    );
}

export default BuyNow;
