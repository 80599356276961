import { phoneNumberRegExpSa, redeemMezaPhoneRegExp, redeemMezaValueRegExp, allNumericExp, promoRegex } from './regexp';

export const emailValidator = (email) => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        return 'error';
    } else {
        return 'success';
    }
}

export const isMobileNumber = (value) => {
    const re = new RegExp(phoneNumberRegExpSa);
    return (value?.length < 10 || !re.test(value)) ? 'error' : 'success';
};

export const isRedeemMezaPhone = (value) => {
    const re = new RegExp(redeemMezaPhoneRegExp);
    return (value?.length < 10 || !re.test(value)) ? 'error' : 'success';
};

export const isRedeemMezaPoint = (value) => {
    const re = new RegExp(redeemMezaValueRegExp);
    return (value?.length < 10 || !re.test(value)) ? 'error' : 'success';
};

export const allNumeric = (number) => {
    const re = new RegExp(allNumericExp);
    if (!re.test(number)) {
        return 'error';
    } else {
        return 'success';
    }
}
export const isPromoRegex = (number) => {
    const re = new RegExp(promoRegex);
    if (!re.test(number)) {
        return 'error';
    } else {
        return 'success';
    }
}
