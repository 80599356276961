import React from "react";
import { Link } from "react-router-dom";
import './../../SCSS/MostSharedComponents/_pickupStore.scss'
import pickup_store from "./../../assets/Icon/pickup_store.svg";
import Heading5 from "../Font/Heading5";
import Heading6 from "../Font/Heading6";
import pinIcon from '../../assets/Icon/pin.svg';
import pinMiles from '../../assets/Icon/pin-miles.svg';
import Heading7 from "../Font/Heading7";
import Text4 from "../Font/Text4";
import { useTranslation } from "react-i18next";

function PickupStorePDP({ title, pickupStore }) {
	const { t } = useTranslation()
	return (
		<div className="product__pickup__block">
			<div className="product__offer__block my_reward_icon">
				<img src={pickup_store} alt="" className="product__offer__icon" />
				<Heading6 text={title} marginBottom={15} />
			</div>

			{pickupStore && pickupStore.filter((val, i) => i < 1).map((store, storeIndex) => {
				return (
					<div key={store.id} className="product__store__block">
						{/* <img src={pickup_store} alt="" className="product__store__icon" /> */}
						<div className="pickup__text__block">
							<p className="product__pickup__text">
								<span className="product__pickup__only__text">{t("Pickup")}:</span>
								{`${store.name}, ${store.address}`}
							</p>
							{storeIndex === 0 && pickupStore.length > 1 && (
								<Link
									className="product__store__location__link"
									to="/store" data-bs-toggle="modal" data-bs-target="#PickupModalPDP"
								>{`See all pickup locations >`}</Link>
							)}
						</div>
					</div>
				);
			})}

			{/* mobile modal popup for store */}
			<div className="modal mb__bottom_popup" id="PickupModalPDP" tabindex="-1" aria-labelledby="pickupModalLabel" aria-hidden="true">
				<div className="modal-dialog mb__dialog__end modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-header">
							<Heading5 text="See All Pick Up Location" />
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							{pickupStore && pickupStore.map((store, i) => {
								return (
									<>
										<div className="d-flex pickup__box">
											<span>
												<img src={pinIcon} alt="pinIcon" />
											</span>
											<div className="ms-2">
												<Heading7 text={store.name} />
												<Text4 text={store.address} />
												{/* <button className="btn btn__primary__orange py-2 mt-3">
										{t("CONFIRM_LOCATION")}
									</button> */}
											</div>
											<span className="ms-sm-5 ps-2 text-center mb__pin__right">
												<img src={pinMiles} alt="miles" />
												{/* <Heading7 text="2 miles" /> */}
											</span>
										</div>
									</>
								)
							})}
						</div>

					</div>
				</div>
			</div>
		</div>

	);
}

export default PickupStorePDP;
