import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../Components/BreadCrumbs";
import Heading3 from "../Components/Font/Heading3";
import "./../SCSS/_myWishlistPage.scss";
import ProductEight from "../Components/ProductType/ProductEight";
import { loadConfigurationSettingData } from "../redux/appAction";
import NewLoader from "../Components/Common/newloader";
import empty_wishlist from './../assets/Icon/empty-wishlist@2x.png';
import Text3 from "../Components/Font/Text3";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MetaComponent from "../Components/Common/MetaComponent";
import ReviewTestFreaks from "./ReviewTestFreaks";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import OutStockHelper from "../Components/helpers/utils/outStockHelper";
import TealiumTag from "../Components/helpers/utils/tealium";

function My_Wishlists_Page({ handleChangeCartPopup }) {

  const { t } = useTranslation();
  const { configurationSettingData } = useSelector((state) => state.appData);
  const { wishlistAvailabilityData } = useSelector((state) => state.appData);
  const { customerDetails } = useSelector((state) => state.customerReducer);
  
  const [wishlistPageData, setWishlistPageData] = useState()
  const [loading, setLoading] = useState(true)
  // const handleChange = (e) => {
  //   // console.log(e.target.value);
  // };

  const dispatch = useDispatch();
  const [outOfStockConfiguration, setOutOfStockConfiguration] = useState({});

  useEffect(() => {
    dispatch(loadConfigurationSettingData())
    // dispatch(loadWishlistData());
  }, []);

  useEffect(() => {
    (async () => {
      const productList = wishlistPageData?.map((item) => item?.product)
      if (productList?.length) {
        await OutStockHelper(dispatch, productList, outOfStockConfiguration);
      }
    })()
  }, [outOfStockConfiguration, wishlistPageData])

  useEffect(() => {
    if (Object.values(configurationSettingData).length !== 0) {
      const getOutOfStock = configurationSettingData?.out_of_stock_configuration || {};
      setOutOfStockConfiguration(getOutOfStock);
    }
  }, [configurationSettingData]);

  const wishlistData = useSelector((state) => state.appData.wishlistData);
 
  useEffect(async () => {
    if (wishlistData) {

      let productList = [];
      wishlistData && wishlistData.map((val, i) => {
        productList.push(val.product);
      })
      wishlistData.map((val, i) => {
        val.product = productList[i];
      })

      setWishlistPageData(wishlistData)
      setLoading(false)
      window.scrollTo(0, 0)
    }
  }, [wishlistData])
  if (loading) {
    return (
      <>
        <NewLoader />
        <div className="container-fluid breadcrumbs__container breadcrumbs__block bg-transparent">
          <Skeleton height={"18px"} width="200px" />
        </div>
        <div className="wishlist__page__block mx-auto px-3">
          <Skeleton height={"40px"} width="100%" />
          <Skeleton height={"60vh"} width="100%" />
        </div>
      </>
    )
  }
  return (
    <>
      {!loading &&
        <ReviewTestFreaks />
      }
      <MetaComponent
        title={t("My_Whishlist")}
        meta_title={t("My_Whishlist")}
        meta_keywords={t("My_Whishlist")}
        meta_description={t("My_Whishlist")}
      />
      <TealiumTag name={t("My_Whishlist")} template={t("My_Whishlist")} />
      <BreadCrumbs back="/user" firstBread="/user" firstBreadTitle="My Account" secondBread="/user/wishlist" secondBreadTitle={t("My_Whishlist")} highlight="secondBreadTitle" />
      <div className="container-fluid wishlist__page__container ">
        <div className="wishlist__page__block">
          <div className="wishlist__title__block">
            <Heading3 text={t("My_Whishlist")} />
          </div>
          <div className="row wishlist__page__product__block">
            {wishlistPageData && wishlistPageData.length > 0 && wishlistPageData.map((item, productIndex) => {
              return (
                <>
                  {item?.product?.id ?
                    <div
                      key={item?.wishlist_item_id}
                      className="col-6 col-md-4  col-xl-3 wishlist__product__block"
                    >
                      <ProductEight
                        product={item?.product}
                        availablityData={wishlistAvailabilityData}
                        handleChangeCartPopup={handleChangeCartPopup}
                        outOfStockConfiguration={outOfStockConfiguration}
                        productIndex={productIndex}
                        screenName="Wishlist"
                        customerDetails={customerDetails}
                      />
                    </div>
                    : null}
                </>
              );
            })}
            {wishlistPageData.length === 0 && (
              <>
                <div className="wishlist__product__emptyBox">
                  <img src={empty_wishlist} />
                  <Text3 text={t('you_haven_t_add_any_items_to_your_wishlist_yet')} />
                  <Link to="/" className="continue_shopping_btn">{t("START_SHOPPING")}</Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(My_Wishlists_Page);
