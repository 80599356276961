import React from "react";
// import { useProductImageHelper } from "../helpers/utils/useProductImageHelper";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { defaultImageHelper } from "../helpers/utils/defaultImageHelper";
import DefaultImage from "../../assets/Icon/default_image.png"
import ImageRender from "../ImageRender";

// const Placeholder = () => (
//   <img src="https://via.placeholder.com/200x200/ffffff/ffffff" alt="placeholder" />
// );

export default function LazyImageForProduct({ product, srcSetReSize, width, height, alt, className, style, onClickEvent, redirectToPdpPage }) {
  // const ref = useRef(null);
  // const imageUrl = useProductImageHelper(product);
  return (
    <React.Fragment>
      {product &&
        <>
          <ImageRender
            noLazy={true}
            src={srcSetReSize !== undefined ? srcSetReSize((product?.media?.image?.featured?.baseImage !== "" && product?.media?.image?.featured?.baseImage !== null && product?.media?.image?.featured?.baseImage !== undefined) ? product?.media?.image?.featured?.baseImage : product?.media?.image?.featured?.image || DefaultImage)[0]?.img : (product?.media?.image?.featured?.baseImage !== "" && product?.media?.image?.featured?.baseImage !== null && product?.media?.image?.featured?.baseImage !== undefined) ? product?.media?.image?.featured?.baseImage : product?.media?.image?.featured?.image || DefaultImage}
            // src={(product?.media?.image?.featured?.baseImage !== "" && product?.media?.image?.featured?.baseImage !== null && product?.media?.image?.featured?.baseImage !== undefined) ? product?.media?.image?.featured?.baseImage : product?.media?.image?.featured?.image || DefaultImage}
            alt={alt ? alt : "Sony"}
            className={className}
            width={srcSetReSize !== undefined ? ((product?.media?.image?.featured?.baseImage !== "" && product?.media?.image?.featured?.baseImage !== null && product?.media?.image?.featured?.baseImage !== undefined) ? product?.media?.image?.featured?.baseImage : product?.media?.image?.featured?.image || DefaultImage)[0]?.width : width}
            height={height || 'auto'}
            style={style}
            onError={(e) => defaultImageHelper(e)}
          />
        </>
        // <LazyLoadImage
        //   // placeholder={<Placeholder />}
        //   ref={ref}
        //   src={srcSetReSize(imageUrl)[0]?.img}
        //   srcSet={srcSetReSize(imageUrl)[1]?.imgSrc.join(",")}
        //   alt={alt ? alt : product?.name}
        //   className={className}
        //   width={width}
        //   height={height}
        //   effect="blur"
        //   onClick={() => {
        //     if (onClickEvent) {
        //       redirectToPdpPage(product?.url_key, product?.sku)
        //     }
        //   }}
        //   style={style}
        // />
      }
    </React.Fragment >
  );
}