import * as types from "./actionType";
import * as services from "./../services/services";
import { getHomePageData } from "../services/homepage.service";
import {
  getAllCategoryData,
  getSingleCategoryData,
} from "../services/category.service";
import { getProductDetail } from "../services/pdp.service";
import {
  addToWishlist,
  deleteFromWishlist,
  getWishlistData,
} from "../services/wishlist.services";
import {
  getCitiesLocationData,
  getEnableStoresLocationData,
} from "../services/storeLocation.service";
import { getCountriesLocationData } from "../services/storeLocation.service";
import { getStoresLocationData } from "../services/storeLocation.service";
import { getApplyFilterData, getFilterData } from "../services/plp.service";
import { addToCart, createCartDetails, getCartData, getPayfortInformation, getLoyaltyEarnTypes } from "../services/cart.service";
import { getOrderDetails } from "../services/order.service";
import apiHelper from "../Components/helpers/utils/apiHelper";
import axios from "axios";

// Loading Home Page Data //

export const saveHomePageData = (data) => ({
  type: types.GET__HOME__PAGE__DATA,
  payload: data,
});

const saveAllCategoryData = (data) => ({
  type: types.GET__ALL__CATEGORY__DATA,
  payload: data,
});

export const loadHomePageData = () => {
  return async function (dispatch) {
    const homepagedata = await getHomePageData();
    dispatch(
      saveHomePageData(homepagedata?.data || { title: "Sony Web Home" })
    );

    return homepagedata.data;
  };
};

export const loadAllCategoryData = () => {
  return async function (dispatch) {
    const categorydata = await getAllCategoryData();
    dispatch(saveAllCategoryData(categorydata.data));
    dispatch({
      type: types.SET__SELECTED__CATEGORY,
      payload: categorydata.data.children_data[0],
    });

    return categorydata.data;
  };
};

export const loadSingleCategoryData = (id) => {
  return async function (dispatch) {
    const categorydata = await getSingleCategoryData(id);
    dispatch({
      type: types.SET__SELECTED__CATEGORY,
      payload: categorydata.data,
    });

    return categorydata.data;
  };
};

// Loading Product Details Page Data //

export const saveProductDetailData = (data) => ({
  type: types.GET__PRODUCT__DETAIL__DATA,
  payload: data,
});

export const loadProductDetailData = (id) => {
  return async function (dispatch) {
    try {
      const productData = await getProductDetail(id);
      dispatch(saveProductDetailData(productData));
      return productData;
    } catch (e) {
      // dispatch(services.loaderEnd());
    } finally {
      // dispatch(services.loaderEnd());
    }
  };
};

// check out of stock status
export const productOutOfStockStatus = async (products) => {
  const idx = [];
  products && products.forEach((product) => {
    const id = Number(product.id);
    idx.push(id);
  });
  if (idx.length > 0) {
    const response = await apiHelper(
      `/V1/product-stock-status/${idx}`,
      "get",
      {},
      {}
    );
    return response;
  }
};

const saveProductStatusData = (data) => ({
  type: types.SET__PRODUCT__STATUS__DATA,
  payload: data,
});

export const loadProductStatusData = (products) => {
  return async function (dispatch) {
    try {
      const productData = await productOutOfStockStatusNotification(products);
      dispatch(saveProductStatusData(productData));
      return productData;
    } catch (e) {
    } finally {
    }
  };
};

// out of stock notification
export const productOutOfStockStatusNotification = async (products) => {
  if (products.length > 0) {
    const response = await apiHelper(
      `/V1/out-of-stock-notification/${products}`,
      "get",
      {},
      {}
    );
    return response;
  }
};

export const categoryAvailableData = async (products) => {
  if (products.length > 0) {
    const response = await apiHelper(
      `/V1/category/get-availability-data/${products}`,
      "get",
      {},
      {}
    );
    return response;
  }
};

// Loading Product Details Page Data //

const saveFilterData = (data) => ({
  type: types.GET__FILTER__DATA,
  payload: data,
});

export const loadFilterData = (filterDetails) => {
  return async function (dispatch) {
    const filterData = await getFilterData(filterDetails);
    dispatch(saveFilterData(filterData));
    return filterData;
  };
};

// Loading Product Details Page Data //

export const saveApplyFilterProductData = (data) => ({
  type: types.GET__APPLY__FILTER__PRODUCTS__DATA,
  payload: data,
});

export const loadApplyFilterProductsData = (filterDetails) => {
  return async function (dispatch) {
    if (
      filterDetails &&
      Object?.keys(filterDetails)?.length > 0 &&
      filterDetails?.filterDetails?.category
    ) {
      dispatch(services.loaderStart());
      const applyFilterProductsData = await getApplyFilterData(filterDetails);
      dispatch(saveApplyFilterProductData(applyFilterProductsData));
      dispatch(services.loaderEnd());
      return applyFilterProductsData;
    }
    return { items: [], total_count: 0 };
  };
};

// Adding Cart Page Data //

export const loadAddToCart = (data) => {
  return async function (dispatch) {
    const addToCartData = await addToCart(data);

    return addToCartData.data;
  };
};
// Removing Cart Page Data //

export const loa = (data) => {
  return async function (dispatch) {
    const addToCartData = await addToCart(data);

    return addToCartData.data;
  };
};

// Adding Wishlist  Page Data //

export const loadAddToWishlist = (data,t) => {
  return async function (dispatch) {
    const addWishlistData = await addToWishlist(data);
    dispatch(services.notifySuccess({ message: t("Added to your wishlist") }));
    return addWishlistData.data;
  };
};
// Deleting Wishlist  Page Data //

export const loadDeleteFromWishlist = (data,t) => {
  return async function (dispatch) {
    const deleteWishlistData = await deleteFromWishlist(data);
    dispatch(services.notifySuccess({ message: t("Removed from your wishlist") }));
    return deleteWishlistData.data;
  };
};

// Loading Wishlist  Page Data //

const saveWishlistData = (data) => ({
  type: types.GET__WISHLIST__DATA,
  payload: data,
});
const saveWishlistAvailabilityData = (data) => ({
  type: types.SET__WISHLIST__AVAILABILITY__DATA,
  payload: data,
});
const saveWishlistCount = (data) => ({
  type: types.SET__WISHLIST__COUNT,
  payload: data,
});

export const loadWishlistData = () => {
  return async function (dispatch) {
    const wishlistData = await getWishlistData();
    const filterData = wishlistData?.data && wishlistData?.data?.products && wishlistData?.data?.products.filter((item) => item?.product?.id !== undefined &&item?.product?.id !== null)
    let newFilterData = filterData ? filterData : []; 
    dispatch(saveWishlistData(newFilterData));
    dispatch(saveWishlistCount(newFilterData?.length));
    dispatch(saveWishlistAvailabilityData(wishlistData?.data?.availablityData))
    return filterData;
  };
};

// Loading Store  Page Data //

// cities //

const saveCitiesLocationData = (data) => ({
  type: types.GET__CITY__LOCATION__DATA,
  payload: data,
});

export const loadCitiesLocationData = () => {
  return async function (dispatch) {
    const cityLocationData = await getCitiesLocationData();
    dispatch(saveCitiesLocationData(cityLocationData.data));
    return cityLocationData.data;
  };
};

// countries //

const saveCountriesLocationData = (data) => ({
  type: types.GET__COUNTRIES__LOCATION__DATA,
  payload: data,
});

export const loadCountriesLocationData = () => {
  return async function (dispatch) {
    const countriesLocationData = await getCountriesLocationData();
    dispatch(saveCountriesLocationData(countriesLocationData.data));
    return countriesLocationData.data;
  };
};

// Store //

const saveStoresLocationData = (data) => ({
  type: types.GET__STORES__LOCATION__DATA,
  payload: data,
});

const saveEnableStoresLocationData = (data) => ({
  type: types.GET__ENABLE__STORES__LOCATION__DATA,
  payload: data,
});

export const loadStoresLocationData = () => {
  return async function (dispatch) {
    const storesLocationData = await getStoresLocationData();
    dispatch(saveStoresLocationData(storesLocationData.data));
    return storesLocationData.data;
  };
};

export const loadEnableStoresLocationData = () => {
  return async function (dispatch) {
    const storesLocationData = await getEnableStoresLocationData();
    dispatch(saveEnableStoresLocationData(storesLocationData.data));
    return storesLocationData.data;
  };
};

const saveCartQuoteId = (data) => ({
  type: types.GET__CART__QUOTE__ID,
  payload: data,
});

export const loadCreateCart = () => {
  return async function (dispatch) {
    const createCartData = await createCartDetails();
    dispatch(saveCartQuoteId(createCartData.quoteId));
  };
};

const saveCartData = (data) => ({
  type: types.GET__CART__DATA,
  payload: data,
});

export const loadCartData = () => {
  return async function (dispatch) {
    // dispatch(services.loaderStart())
    const cartData = await getCartData();
    dispatch(saveCartData(cartData.data));
    // dispatch(services.loaderEnd())
    return cartData.data;
  };
};

const savePayfortInformationData = (data) => ({
  type: types.SET__ORDER__ID__DATA,
  payload: data,
});

export const loadPayfortInformation = (data) => {
  return async function (dispatch) {
    const payfortInformationData = await getPayfortInformation(data);
    if (payfortInformationData.success) {
      dispatch(savePayfortInformationData(payfortInformationData));
    }
    return payfortInformationData;
  };
};

//  load Order ID Detaild Data
const saveOrderDetailsData = (data) => ({
  type: types.GET__ORDER__DETAIL__DATA,
  payload: data,
});

export const loadOrderDetailsData = (id) => {
  return async function (dispatch) {
    const orderDetailsData = await getOrderDetails(id);
    dispatch(saveOrderDetailsData(orderDetailsData));
    return orderDetailsData;
  };
};

export const getLoyaltyEarnTypesData = (id) => {
  return async function (dispatch) {
    const loyaltyData = await getLoyaltyEarnTypes();
    dispatch(loyaltyEarnTypesData(loyaltyData.data));
    return loyaltyData;
  };
};

//  loyalty-earn-types Data
const loyaltyEarnTypesData = (data) => ({
  type: types.GET_LOYALTY_EARN_TYPE_DATA,
  payload: data,
});

// config API
export const configurationSetting = async () => {
  const currentStore = localStorage.getItem("i18next") || "en";
  let params = { store: currentStore }
  let responseData = {}
  const productDetailData = await axios.post(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/configuration-setting`, params
  ).then(res => responseData = res.data)
    .catch(err => responseData = err)
  return responseData;
};

export const loadConfigurationSettingData = (id) => {
  return async function (dispatch) {
    const responseData = await configurationSetting();
    dispatch(saveConfigurationSettingData(responseData));
    return responseData;
  };
};

export const configSetting = async () => {
  try {
    const configuration = await configurationSetting();
    return configuration?.out_of_stock_configuration || {};
    // const getOutOfStock = configuration?.out_of_stock_configuration || {};
    // setOutOfStockConfiguration(getOutOfStock);
  } catch (error) {
    console.log("error ", error);
  }
  return null
};

export const saveConfigurationSettingData = (data) => ({
  type: types.GET__CONFIGURATION__SETTING__DATA,
  payload: data,
});

export const resetNiqatyFlow = (data) => (dispatch) => dispatch({ type: types.NIQATY_RESET_FLOW, payload: data });

export const setGeneralModalData = (data) => (dispatch) => dispatch({ type: types.MODAL_DATA, payload: data });

export const toggleLogistrationMode = (status) => (dispatch) => dispatch({ type: types.LOGISTRATION_MODE, payload: status });

export const toggleGeneralModal = (status) => (dispatch) => dispatch({ type: types.TOGGLE_MODAL, payload: status });

export const toggleLogistrationState = (status) => (dispatch) => dispatch({ type: types.LOGISTRATION_STATE, payload: status });
