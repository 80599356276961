import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import * as services from "./../../services/services";
import { addToCart } from "../../services/cart.service";
import { useDispatch } from "react-redux";
import { loadCartData } from "../../redux/appAction";
import Heading7 from "../Font/Heading7";
import './../../SCSS/_extendedWarranty.scss';
import shopping_cart from "./../../assets/Icon/shopping_cart.svg";
import Heading5 from "../Font/Heading5";
import { defaultImageHelper } from "../helpers/utils/defaultImageHelper";
import { replaceImageHelper } from "../helpers/utils/replaceImageHelper";
import DefaultImage from "./../../assets/Icon/default_image.png";
import { Spinner } from "../helpers/utils/spinner";

const ExtendedWarranty = ({ t, title, product, warrantyProduct, handleChangeCartPopup, warrentyDataMain }) => {

  const [productArray, setProductArray] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [prodIndex, setProdIndex] = useState();
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (warrantyProduct) {
      setProductArray(warrantyProduct);
    }
  }, [warrantyProduct]);
  const addToCartExtendWarranty = async (productData, index) => {

    let createItemArray = [
      // {
      //   sku: product?.sku,
      //   id: product?.id,
      //   qty: 1
      // },
      {
        sku: productData?.sku,
        id: productData?.id,
        qty: 1
      }
    ];

    setBtnLoading(true);
    setProdIndex(index);
    await addToCart({
      items: createItemArray,
    }).then((res) => {
      dispatch(loadCartData());
      handleChangeCartPopup(true)
      setBtnLoading(false);
      setProdIndex();
    })
      .catch((err) => {
        setBtnLoading(false);
        setProdIndex();
        dispatch(
          services.notifyError({ message: err.response.data.message })
        );
      })
      .finally(() => {
        setBtnLoading(false);
        setProdIndex();
      });
  }
  const handleCheckboxChange = (eve,item) => {
    const updatedCheckedItems = checkedItems.includes(item) 
      ? checkedItems.filter((checkedItem) => checkedItem !== item)
      : [...checkedItems, item];
    
    setCheckedItems(updatedCheckedItems);
    warrentyDataMain(updatedCheckedItems);
  };
  return (
    <React.Fragment>
      {productArray && productArray?.length > 0 ?
        <div className="pd__extended__warranty__block">
          <Heading7 text={title} />
          <div className="row warranty__listingBox">
            {productArray && productArray.map((item, i) => {
              let price = item?.discounted_price_rounded === null ? item?.price_rounded : item?.discounted_price_rounded;
              return (
                <div className="col-sm-4 col-lg-6 col-xl-6 col-xxl-4">
                  <div className="extended__warranty__item__box">
                    <input
                      type="checkbox"
                      checked={checkedItems.includes(item)}
                      onChange={(eve) => handleCheckboxChange(eve,item)}
                    />
                    <div className="warranty__yearArea">
                      <img
                        src={item?.media?.image?.featured?.image ? item?.media?.image?.featured?.image : DefaultImage}
                        onError={(e) => defaultImageHelper(e)}
                        alt=""
                      />
                    </div>
                    <div className="warranty__contentBox">
                      <p className="warranty__disc__text">{item?.name}</p>
                      <div className="warranty__priceBox">
                        <Heading5 text={`${t(item?.currency)} ${price}`} />
                      </div>
                      {/* <button
                        disabled={btnLoading}
                        className="warranty__addToCart__btn s"
                        onClick={() => addToCartExtendWarranty(item, i)}
                      >
                        {btnLoading === true && prodIndex === i ? <Spinner /> : <><img src={shopping_cart} alt="" className="warty__addToCart__icon" />{t("Add_To_Cart")}
                        </>}
                      </button> */}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        :
        ""
      }
    </React.Fragment>
  );
};

export default ExtendedWarranty;