import React from 'react';
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import navbarLogo from '../../assets/Logo/navbar_logo.png'

function MetaComponent({ page, title, url_key, meta_title, meta_keywords, meta_description }) {
  const getCurrentPageURL = window.location.href;
  const pageType = getCurrentPageURL?.includes('/products/') ? 'Product' : 'Webpage'

  const { t } = useTranslation();
  let mainTitle = t('Sony_World_KSA')
  if (page === "home") {
    mainTitle = title === "" || title === null || title === undefined ? t('KSA_Official_Store') : `${title} - ${t('KSA_Official_Store')}`
  } else {
    mainTitle = title === "" || title === null || title === undefined ? mainTitle : `${title} - ${mainTitle}`
  }

  return (
    <>
      <Helmet>
        <title>{mainTitle}</title>
        <meta name="title" content={meta_title ? meta_title : mainTitle} />
        <meta name="key" content={url_key ? url_key : mainTitle} />
        <meta name="keyword" content={meta_keywords ? meta_keywords : mainTitle} />
        <meta name="description" content={meta_description ? meta_description : mainTitle} />
        {/* og:{property name} meta data*/}
        <meta property="og:title" content={meta_title ? meta_title : mainTitle}/>
        <meta property="og:site_name" content={meta_title ? meta_title : "SonyWorld"}/>
        <meta property="og:url" content={getCurrentPageURL}/>
        <meta property="og:description" content={meta_description ? meta_description : mainTitle}/>
        <meta property="og:type" content={pageType}/>
        <meta property="og:image" content={navbarLogo}/>
        <script>{`window.history.scrollRestoration = "manual"`}</script>
      </Helmet>
    </>
  );
}

export default MetaComponent;
