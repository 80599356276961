import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as services from "./../../services/services";
import Price from "../Font/Price";
import Text3 from "../Font/Text3";
import plus from "./../../assets/Icon/plus.svg";
import minus from "./../../assets/Icon/minus.svg";
import "./../../SCSS/MostSharedComponents/_shoppingCartProduct.scss";
import delete_icon from "./../../assets/Icon/delete.svg";
import {
    loadAddToWishlist,
    loadCartData,
    loadDeleteFromWishlist,
    loadWishlistData,
} from "../../redux/appAction";
import {
    deleteFromCart,
    UpdateFromCart,
} from "../../services/cart.service";
import { useTranslation } from "react-i18next";
import { checkWishList } from "../helpers/utils/wishListHelper";
import useWindowDimensions from "../helpers/utils/useWindowDimensions";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { checkoutEvents } from "../helpers/utils/ga/checkoutEvents";
import { useCallback } from "react";
import { Spinner } from "../helpers/utils/spinner";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";
import { impressionEvents } from "../helpers/utils/ga/impressionEvents";

function CartPopupProduct({
    product,
    totalData,
    totals,
    item,
    screenName,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const [pricess, setPricess] = useState(null);
    const [bundleProducts, setBundleProducts] = useState([]);
    const [count, setCount] = useState(product.qty);
    const [isChild, setIsChild] = useState(false);
    const [qtyIsDisabled, setQtyIsDisabled] = useState(false);

    const { setCartErr } = useCartErrorMessage();

    useEffect(() => {
        if (totals?.extension_attributes?.options_bundle) {
            setBundleProducts(
                totals.extension_attributes.options_bundle
                    .filter((itm) => !itm.title.toLowerCase().includes("tv"))
                    .map((item) => ({
                        option_id: item.option_id,
                        title: item.title,
                        price: item.price,
                    }))
            );
        }
    }, [product]);

    useEffect(() => {
        if (totalData?.items) {
            const prices = totalData?.items.find((item) => {
                return item.item_id === product.item_id;
            });
            setPricess(prices);
        }
        setCount(product.qty);
    }, [totalData, product]);

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };

    const cartData = useSelector((state) => state.appData.cartData);

    // useEffect(() => {
    //     let checkQty = cartData?.items?.filter((pro) => pro.sku === product?.sku);
    //     // if (checkQty?.length) {
    //     //     // localStorage.setItem("updatecartdata", checkQty[0].qty);
    //     // }
    // }, [cartData])

    const decreaseCount = (sku, qty, itemId) => {
        if (qty <= 0) {
            setCount(0);
            deleteProductFromCart(product.item_id);
        } else {
            const array = cartData?.items?.filter((item) => item?.extension_attributes?.bundle_parent_id === itemId)
            const finalArray = [product, ...array]

            finalArray?.map((item, index) => {
                const isLastElement = index === finalArray.length - 1;
                AddAfterDeleteFunction(item?.sku, qty, item.item_id, item, isLastElement)
            })
        }
        // impressionEvents.impressionDeleteFromCart(
        //         product,
        //         1,
        //         "Cart Window"
        //     );
    };
    const optimizedFndecrease = useCallback(debounce(decreaseCount), []);

    const increaseCount = (sku, qty, product) => {
        if (qty > product?.product?.stock) {
            dispatch(services.notifyError({ message: t('Sellable stock is %1').replace('%1', product?.product?.stock) }));
            setCount(product?.product?.stock);
        } else if (qty) {
            AddAfterDeleteFunction(sku, qty, product.item_id, product);
        }
    };
   // const optimizedFndecrease = useCallback(debounce(decreaseCount), []);

    // const increaseCount = (sku, qty) => {
    //     if(qty > product?.product?.stock) {
    //       dispatch(services.notifyError({ message: t('Sellable stock is %1').replace('%1', product?.product?.stock) }));
    //       setCount(product?.product?.stock);
    //     } else if (qty){
    //       AddAfterDeleteFunction(sku, qty, product.item_id, product);
    //     }    
    //   };
    const optimizedFnincrease = useCallback(debounce(increaseCount), []);

    const handleChangeinput = (sku, event ) => {
        if (event > product?.product?.stock) {
            setQtyIsDisabled(true);
            dispatch(loadCartData()).then((item) => {
                setCount(item && item.length > 0 && item.filter((pro) => pro.sku === product?.sku)[0].qty);
                setQtyIsDisabled(false);
            }).finally((err) => {
                setQtyIsDisabled(false);
            });
            dispatch(services.notifyError({ message: t('Sellable stock is %1').replace('%1', product?.product?.stock) }));
        } else if (event){
          AddAfterDeleteFunction(sku, parseInt(event), product?.item_id, product);
        }
      }
      const optimizedFninput = useCallback(debounce(handleChangeinput), []);

    const AddAfterDeleteFunction = (sku, count, id, product) => {
        setQtyIsDisabled(true);
        
        const updateData = {
            // items: [id],
            id: product?.product?.id,
            itemId: id,
            qty: count,
            sku:product?.product?.sku,
        };
        UpdateFromCart(updateData)
            .then((res) => {
                setQtyIsDisabled(true);
                dispatch(loadCartData()).then((item) => {
                    setQtyIsDisabled(false);
                }).finally((err) => {
                    setQtyIsDisabled(false);
                });
                // dispatch(services.notifySuccess({ message: "Added in Cart" }));
                // });
                // dispatch(loadCartData());
            })
            .catch((err) => {
                setQtyIsDisabled(false);
                setCartErr({ stock: product?.product?.stock, error: err?.response?.data });
                setCount(product.qty)
            });
    };

    const wishlistData = useSelector((state) => state.appData.wishlistData);

    const deleteProductFromCart = (id) => {
        setQtyIsDisabled(true);
        const data = {
            // items: [id],
            items: [id],
        };
        deleteFromCart(data)
            .then((res) => {
                cartEvents.removeFromCart({ ...product, qty1: count || 1, totalPrice: product?.price * product?.qty || 0 }, cartData?.id, "Cart");
                // dispatch(services.notifyError({ message: "Removed From Cart" }));
                setQtyIsDisabled(true);
                dispatch(loadCartData()).then((item) => {
                    setQtyIsDisabled(false);
                }).finally((err) => {
                    setQtyIsDisabled(false);
                });
            })
            .catch((err) => {
                dispatch(services.notifyError({ message: err.response.data.message }));
            });

            // impressionEvents.impressionDeleteFromCart(
            //     product,1);     
    };

    let priceValue =
        product.product.discounted_price_rounded === null
            ? product.product.price_rounded
            : product.product.discounted_price_rounded;

    let oldPriceValue = product.product?.price_rounded;

    let savePriceValue = (oldPriceValue - priceValue).toFixed(2);

    let percentageValue = (savePriceValue * 100) / oldPriceValue;
    let percentageValue2Digit = percentageValue.toFixed(2);

    useEffect(() => {
        if (item?.isParentExist === 1 && item?.allChildExist === 1 && item?.isbundleParent === 0) {
            setIsChild(true);
        } else {
            setIsChild(false);
        }
    }, [item]);

    return (
        <>
            {
                qtyIsDisabled &&
                <div className="cart__popup__loading">
                    <Spinner type={'dark'} />
                </div>
            }
            <div className="sc__counter__block d-flex mt-3 mb-3  align-items-center">
                <div className="row sc__counter__box">
                    <div
                        onClick={() => {
                            setCount(count - 1); optimizedFndecrease(product.sku, count - 1);
                            
                        }}
                        className="col-4 counter__decrease__block"
                    >
                        <img src={minus} alt="minus" />
                    </div>
                    <input
                        type="number"
                        min="1"
                        value={count}
                        onChange={(event) => { setCount(parseInt(event.target.value)); optimizedFninput(product.sku, event.target.value) }}
                        onBlur={(()=>{
                            if(isNaN(count)){
                              setCount(1)
                            }
                          })}
                    />
                    {/* <div className="col-4 counter">{count}</div> */}
                    <div
                         onClick={() => { setCount(count + 1); optimizedFnincrease(product.sku, count + 1, product); impressionEvents.impressionAddToCart(
                            product, count,"cart window"
                          );
                          }}
                        className="col-4 counter__increase__block"
                    >
                          {/* impressionEvents.impressionAddToCart(
                            product, count,"cart window"
                          ); */}
                        <img src={plus} alt="plus" />
                    </div>
                </div>


                <div
                    className="delete__icon__button__block"
                    onClick={() => {deleteProductFromCart(product.item_id, product);
                    }}
                >
                    <img src={delete_icon} alt="delete" />
                </div>
            </div>
        </>

    );
}

export default CartPopupProduct;
