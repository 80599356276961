export function discountOfferHelper(t, product) {
  const discountedPrice = product?.discounted_price_rounded;
  const price = product?.price_rounded;
  const discountPercentage = Math.round(100 - (discountedPrice / price) * 100);
  const discountedAmount = Math.round(price - discountedPrice);

  let newLabel = "";
  if (discountPercentage > 0 && discountPercentage < 100) {
    if (discountPercentage && discountPercentage > 9) {
      newLabel = `${discountPercentage}%`;
    }
    // if (discountPercentage < 10 && discountPercentage > 0) {
    //   newLabel = `${t("SAR")} ${discountedAmount}`;
    // }
  }
  return newLabel;
}