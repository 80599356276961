import React, { useState, useEffect } from 'react';
import mokafa2x from "./../../assets/Icon/mokafa@2x.png";
import mezzacard from "./../../assets/Icon/mezzacard.png";
import Text4 from '../Font/Text4';
const niqaty = require("./../../assets/Icon/niqaty.png")

const NIQATY_THRESHOLD = 65;

const CartRedeemLoyalty = ({
    t,
    handleChangePaymentMethod,
    type,
    // cartDetail,
    cartTotalData,
    hideSection,
    ecom,
    userPaymentMethod,
    cartData
}) => {
    
    const [mezaApplied, setMezaApplied] = useState(false);
    const [mokafaApplied, setMokafaApplied] = useState(false);
    const [qitafApplied, setQitafApplied] = useState(false);
    // const [yggApplied, setYggApplied] = useState(false);
    const [niqatyApplied, setNiqatyApplied] = useState(false);
    const [belowNiqatyThreshold, setBelowNiqatyThreshold] = useState(true);

    useEffect(() => {
        if (cartTotalData) {
            setMezaApplied(Object.values(cartTotalData?.total_segments)?.map((item) => item.code).includes('mestores_points'));
            setMokafaApplied(Object.values(cartTotalData?.total_segments)?.map((item) => item.code).includes('mestores_alrajhi'));
            // setYggApplied(Object.values(cartTotalData?.total_segments)?.map((item) => item.code).includes('mestores_ygg'));
            setQitafApplied(Object.values(cartTotalData?.total_segments)?.map((item) => item.code).includes('mestores_qitaf'));
            setNiqatyApplied(Object.values(cartTotalData?.total_segments)?.map((item) => item.code).includes('mestores_niqaty'));
        }
    }, [cartTotalData]);

    useEffect(() => {
        if (cartTotalData?.grand_total >= NIQATY_THRESHOLD) {
            setBelowNiqatyThreshold(false)
        }
    }, [cartTotalData])

    const currentLang = localStorage.getItem("i18next") || "en";
    let quitafcard = require(`./../../assets/Icon/icon-qitaf-${currentLang}.png`)
    return (
        <div className="d-flex pay__with__item__listBox">
            {!hideSection && ecom?.featureFlags?.ecom?.bootstraper?.loyalty_status?.meza && !mezaApplied ?
                <label
                    htmlFor={`${type}_payment_with_meza`}
                    className="payment__form__block flex-column align-items-start align-self-stretch meza"
                >
                    <div className="payment__selection__text">
                        <Text4 text={t("PAY_WITH")} />
                    </div>
                    <div className="d-flex align-items-center">
                        <input
                            id={`${type}_payment_with_meza`}
                            type="radio"
                            className="payment__input__check"
                            name="paymentType"
                            value="meza"
                            onChange={(e) => handleChangePaymentMethod(e)}
                        />
                        <img src={mezzacard} alt="card" />
                    </div>
                </label>
                :
                ""
            }
            {!hideSection && ecom?.featureFlags?.ecom?.bootstraper?.loyalty_status?.qitaf && !qitafApplied ?
                <label
                    htmlFor={`${type}_payment_with_qitaf`}
                    className="payment__form__block flex-column align-items-start align-self-stretch qitaf"
                >
                    <div className="payment__selection__text ">
                        <Text4 text={t("PAY_WITH")} />
                    </div>
                    <div className="d-flex align-items-center">
                        <input
                            id={`${type}_payment_with_qitaf`}
                            type="radio"
                            className="payment__input__check"
                            name="paymentType"
                            value="qitaf"
                            onChange={(e) => handleChangePaymentMethod(e)}
                        />
                        <img src={quitafcard} alt="card" />
                    </div>
                </label>
                :
                ""
            }
            {!hideSection && ecom?.featureFlags?.ecom?.bootstraper?.loyalty_status?.mokafa && !mokafaApplied ?
                <label
                    htmlFor={`${type}_payment_with_mokafa`}
                    className="payment__form__block flex-column align-items-start align-self-stretch "
                >
                    <div className="payment__selection__text ">
                        <Text4 text={t("PAY_WITH")} />
                    </div>
                    <div className="d-flex align-items-center">
                        <input
                            id={`${type}_payment_with_mokafa`}
                            type="radio"
                            className="payment__input__check"
                            name="paymentType"
                            value="mokafa"
                            onChange={(e) => handleChangePaymentMethod(e)}
                        />
                        <img src={mokafa2x} alt="card" />
                    </div>
                </label>
                :
                ""
            }
            {ecom?.featureFlags?.ecom?.bootstraper?.loyalty_status?.niqaty && !cartData?.is_virtual && !niqatyApplied && !belowNiqatyThreshold ?
                <label
                    htmlFor={`${type}_payment_with_niqaty`}
                    className="payment__form__block flex-column align-items-start align-self-stretch niqaty"
                >
                    <div className="payment__selection__text ">
                        <Text4 text={t("PAY_WITH")} />
                    </div>
                    <div className="d-flex align-items-center">
                        <input
                            id={`${type}_payment_with_niqaty`}
                            type="radio"
                            className="payment__input__check"
                            name="paymentType"
                            value="niqaty"
                            onChange={(e) => handleChangePaymentMethod(e)}
                            checked={userPaymentMethod === "niqaty"}
                        />
                        <img src={niqaty} alt="card" />
                    </div>
                </label>
                :
                ""
            }
        </div>
    );
};

export default React.memo(CartRedeemLoyalty);
