import apiHelper from "../../Components/helpers/utils/apiHelper";
import * as actionType from "../../redux/actionType";
import * as services from "../services";

export const getCategoryDetails = (params) => {
  return async (dispatch) => {
    try {
      dispatch(services.loaderStart());
      let response = await apiHelper(
        `/V1/category/${params.categoryId}`,
        "get",
        params,
        {}
      );
      if (response?.data?.length === 0) {
        window.location.href = "/";
      } else {
        dispatch(categoryDetailsSuccess(response.data));
      }
    } catch (error) {
      // if(error?.response?.status)
      window.location.href = "/";
    } finally {
      dispatch(services.loaderEnd());
    }
  };
};

// category details reducer
export const categoryDetailsSuccess = (data) => {
  return {
    type: actionType.CATEGORY_DETAILS,
    payload: data,
  };
};

export const getCustomerOrderDetails = (params) => {
  return async (dispatch) => {
    try {
      let response = await apiHelper(
        `/V1/customer/orders/${params.id}`,
        "get",
        params,
        {}
      );
      dispatch(getCustomerOrderDetailsSuccess(response.data));
    } catch (error) {
      if (error.response.status === 404) {
        dispatch(
          services.notifyError({ message: error.response.data.message })
        );
      }
    }
  };
};

// get addresses
export const getCustomerOrderDetailsSuccess = (data) => {
  return {
    type: actionType.CUSTOMER_ORDER_DETAILS,
    payload: data,
  };
};
