import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Text4 from "../Font/Text4";
import Text5 from "../Font/Text5";
import BundleModal from "../MostSharedComponent/BundleModal";
import StoreCitySelection from "./StoreCitySelection";
import StorePickUp from "./StorePickUp";
import StoresLocation from "./StoresLocation";

function MainStorePickup({ t, product, storePickupGlobalConfiguration, pageType }) {

    const [storeCityModal, setStoreCityModal] = useState(false);
    const [selectedStoreCity, setSelectedStoreCity] = useState(null);
    const [selectStoreCity, setSelectStoreCity] = useState(null);
    const [availableLocation, setAvailableLocations] = useState([]);
    const [cityAvailableStores, setCityAvailableStores] = useState([]);
    const [showStores, setShowStores] = useState(false);
    const [globalStores, setGlobalStores] = useState([]);
    const [storeCodes, setStoreCodes] = useState(null);
    const [storeEnableMsg, setStoreEnableMsg] = useState(false);

    const isOutOfStock =
        product?.orderType === "out-of-stock" || product?.stock === 0;
    let isShippingEnabled = product?.enable_shipping;
    let productType = product?.item_type;

    const isDeliveryEnabled =
        !isOutOfStock && isShippingEnabled && productType === "simple";

    const storesEnableLocationData = useSelector(
        (state) => state.appData.storesEnableLocationData
    );

    useEffect(() => {
        setGlobalStores(storesEnableLocationData)
    }, [storesEnableLocationData]);

    useEffect(() => {
        if (product?.installmentMethod) {
            setStoreCodes(
                product.inventory_qty
                    ?.map((item) => Object.keys(item)[0])
                    .filter(
                        (code) => code !== 'RS97' && code !== 'RW10' && code !== 'RW20',
                    ),
            );
        }
    }, [product]);

    const getAllStores = (availablityData) => {
        const locationData = [];
        if (availablityData?.length) {
            availablityData.forEach((itm) => {
                const value = itm?.data?.available_stores[product.id];
                if (value !== undefined) {
                    Object.keys(value).forEach((item) => {
                        const location = globalStores?.find(
                            (storeListItem) => storeListItem.storeCode === item,
                        );
                        if (location) {
                            locationData.push(location);
                        }
                    });
                }
                setAvailableLocations(
                    locationData.filter((item) => item?.city?.trim() === selectedStoreCity?.city?.trim())
                );
            });
        }
        return locationData;
    };

    useEffect(() => {
        getAllStores(product?.availablityData);
    }, [selectedStoreCity]);

    useEffect(() => {
        const storesList = getAllStores(product?.availablityData);
        setCityAvailableStores(storesList);
        if (storesList.length > 0) {
            const riyadCity = storesList?.find((item) => item?.city === 'Riyadh' || item?.city === 'الرياض');
            setSelectedStoreCity(riyadCity || storesList[0]);
            setSelectStoreCity(riyadCity || storesList[0]);
        }
    }, [globalStores, product]);

    useEffect(() => {
        if (storePickupGlobalConfiguration === 1 && (storeCodes?.length > 0 || isDeliveryEnabled)) {
            setStoreEnableMsg(true);
        }
    }, [storeCodes, product, storePickupGlobalConfiguration]);

    return (
        <div className="delivery__wrapper">
            {pageType === "compare" && !storeEnableMsg &&
                <div className="row delivery__taken__area">
                    <div className="col-md-12 mb-2">
                        <Text5 text={t("Eligible For Store Pickup")} marginLeft={10} color="#9b9b9b" />
                        <Text4 text={t("Not Available")} marginLeft={10} />
                    </div>
                </div>
            }
            {storePickupGlobalConfiguration === 1 && product?.orderType !== 'pre-order' && (storeCodes?.length > 0 || isDeliveryEnabled) && (
                <React.Fragment>
                    <StorePickUp
                        setStoreCityModal={setStoreCityModal}
                        t={t}
                        selectedCity={selectedStoreCity}
                    />
                    {availableLocation?.length > 0 && (
                        <div className="row store__pickup__location__listing">
                            <StoresLocation
                                stores={availableLocation?.slice(0, 1)}
                                t={t}
                            />
                            {availableLocation?.length > 1 && (
                                <button
                                    className="location__show__more__button"
                                    onClick={() => setShowStores(true)}
                                >
                                    {t('Show More')}
                                </button>
                            )}
                        </div>
                    )}
                </React.Fragment>
            )}
            {(storeCodes?.length > 0 || isDeliveryEnabled) && (
                <BundleModal
                    title={`${t("Store Pickup Availability in")} ${selectedStoreCity?.city}`}
                    modalShow={storeCityModal}
                    onClose={() => setStoreCityModal(false)}
                    t={t}
                    content={
                        <div className="bundleCity store__city__selection">
                            <StoreCitySelection
                                cityModal={storeCityModal}
                                cities={cityAvailableStores.filter((item) => item?.city)}
                                onCancel={() => setStoreCityModal(false)}
                                t={t}
                                selectedStoreCity={selectedStoreCity}
                                setSelectStoreCity={setSelectStoreCity}
                                selectStoreCity={selectStoreCity}
                            />
                        </div>
                    }
                    onConfirm={() => {
                        setStoreCityModal(false);
                        setSelectedStoreCity(selectStoreCity);
                    }}
                    onHide={() => {
                        setStoreCityModal(false);
                        setSelectStoreCity(selectedStoreCity);
                    }}
                    size="md"
                    scrollable={true}
                ></BundleModal>
            )}
            {showStores && (
                <BundleModal
                    title={`${t("Store Pickup Availability in")} ${selectedStoreCity?.city}`}
                    modalShow={showStores}
                    onClose={() => setShowStores(false)}
                    t={t}
                    content={
                        <div className="bundleCity stores__location__modal">
                            <StoresLocation
                                stores={availableLocation}
                                t={t}
                            />
                        </div>
                    }
                    onConfirm={() => {
                        setShowStores(false);
                    }}
                    onHide={() => {
                        setShowStores(false);
                    }}
                    size="md"
                    scrollable={true}
                ></BundleModal>
            )}
        </div>
    );
}

export default MainStorePickup;