import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PhoneInput from "react-phone-input-2";
import { Button, Form, InputGroup } from "react-bootstrap";
import { BsPlusLg, BsSearch } from 'react-icons/bs';
import { FaCaretDown } from 'react-icons/fa';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import "../../SCSS/_checkoutPage.scss";
import * as services from "../../services/services";
import { addToCart, deleteFromCart, getEstimateShippingMethods, updateShippingInformation, updateShippingInformationSuccess } from '../../services/cart.service';
import { getLoyaltyEarnTypesData, loadCartData, loadCitiesLocationData, loadConfigurationSettingData, loadCountriesLocationData, loadStoresLocationData } from '../../redux/appAction';
import useWindowDimensions from '../../Components/helpers/utils/useWindowDimensions';
import { getCustomerLoginDetails } from "../../Components/helpers/utils/getCustomerLoginDetails";
import apiHelper from '../../Components/helpers/utils/apiHelper';
import { checkoutEvents } from '../../Components/helpers/utils/ga/checkoutEvents';

import BreadCrumbs from '../../Components/BreadCrumbs';
import MetaComponent from '../../Components/Common/MetaComponent'
import OpeningHoursModal from '../../Components/Common/OpeningHoursModal';
import Heading6 from '../../Components/Font/Heading6';
import Virtual_Product_Checkout_Page from '../Virtual_Product_Checkout_Page';
import Text5 from '../../Components/Font/Text5';
import Heading4 from '../../Components/Font/Heading4';
import Price from '../../Components/Font/Price';
import GuestCheckoutPage from '../Guest_Checkout_Page';
import NewLoader from '../../Components/Common/newloader';

import signin_initial from "../../assets/Icon/signin_inprogress.svg";
import signin_inprogress from "../../assets/Icon/signin_inprogress.svg";
import signin_done from "../../assets/Icon/signin_done.svg";
import delivery_initial from "../../assets/Icon/delivery_initial.svg";
import delivery_inprogress from "../../assets/Icon/delivery_inprogress.svg";
import delivery_done from "../../assets/Icon/delivery_done.svg";
import payment_initial from "../../assets/Icon/payment_initial.svg";
import payment_inprogress from "../../assets/Icon/payment_inprogress.svg";
import payment_done from "../../assets/Icon/payment_done.svg";
import done from "../../assets/Icon/done.svg";
import shipping from "../../assets/Icon/shipping.svg";
import pickup_store from "../../assets/Icon/pickup_store.svg";
import Heading7 from '../../Components/Font/Heading7';
import Text4 from '../../Components/Font/Text4';
import edit_box_white from "../../assets/Icon/edit_box_white.svg";
import delete_box_white from "../../assets/Icon/delete_box_white.svg";
import Order_Summary_New from '../Order_Summary_New';
import MobileDelivery from './MobileDelivery';
import { Spinner } from '../../Components/helpers/utils/spinner';
import AddressPopupModal from '../../Components/Popup/AddressPopupModal';
import { acceptOnlyString } from '../../Components/helpers/utils/regexp';
import { emailValidator } from '../../Components/helpers/utils/validators';
import { isMobileNumber } from '../../Components/helpers/utils/validators';
import Heading3 from '../../Components/Font/Heading3';
import { impressionEvents } from '../../Components/helpers/utils/ga/impressionEvents';
const errMsgStyle = {
  color: "red",
  margin: "5px 0px 0px",
};

const Delivery = () => {
  const [modalShow, setModalShow] = useState(false);
  const [openingHoursData, setOpeningHoursData] = useState("");
  const [iconType, setIconType] = useState({
    signin: "done",
    delivery: "inprogress",
    payment: "initial",
  });

  const [userData, setUserData] = useState({
    mobileNumber: "",
    email: "",
    firstName: "",
    lastName: "",
  });

  const [addressError, setAddressError] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
  });

  const [storeConfig, setStoreConfig] = useState('');
  const [cartDetail, setCartDetail] = useState("");
  const [custEmailAddress, setCustEmailAddress] = useState("");
  const [cartTotalData, setCartTotalData] = useState();
  const [loading, setLoading] = useState(true);
  const [deliveryType, setDeliveryType] = useState([]);
  const [preferenceTab, setPreferenceTab] = useState("");
  const [errMsg, setErrMsg] = useState({
    deliveryAddressList: "",
    deliveryPreferencesType: "",
  });
  const [deliveryPreferencesType, setDeliveryPreferencesType] = useState("");
  const [storePickupError, setStorePickupError] = useState("");
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [viewMoreAddressBtn, setViewMoreAddressBtn] = useState(false);
  const [selectedAddressId, setSelectedAddressID] = useState(0);
  const [editAddressData, setEditAddressData] = useState("");
  const [addressPopup, setAddressPopup] = useState(false);
  const [addressPopupType, setAddressPopupType] = useState("add");
  const [storeCitiesLocationData, setStoreCitiesLocationData] = useState([]);
  const [shippingCustomMsg, setShippingCustomMsg] = useState("");
  const [storeStoresLocationData, setStoreStoresLocationData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [filteredStores, setFilteredStores] = useState([]);
  const [loyaltyEarnType, setLoyaltyEarnType] = useState([]);
  const [loyaltyEarnTypeId, setLoyaltyEarnTypeId] = useState("");

  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const T_REQ_FIRST_NAME = t("First_Name_is_required");
  const T_REQ_LAST_NAME = t("Last_Name_is_required");
  const T_REQ_MOBILE_NUMBER = t("Mobile_Number_is_required");
  const T_INVALID_MOBILE_NUMBER = t("Invalid_Mobile_Number");
  const T_REQ_EMAIL = t("Email_is_missing");
  const T_INVALID_EMAIL = t("Invalid_email_address_example");

  const { customerDetails } = useSelector((state) => state.customerReducer);
  const deliveryShippingInfo = useSelector((state) => state.appData.deliveryShippingInfo);
  const cartData = useSelector((state) => state.appData.cartData);
  const cityLocationData = useSelector((state) => state.appData.cityLocationData);
  const { loadingStartEnd } = useSelector((state) => state.commonReducer);
  const { configurationSettingData } = useSelector((state) => state.appData);
  const storesLocationData = useSelector((state) => state.appData.storesLocationData);
  const { customerAddressList } = useSelector((state) => state.customerAddressReducer);
  const loyaltyEarnTypeList = useSelector((state) => state.appData.loyaltyEarnTypeList);
  const selectedLoyaltyPointsProgram = useSelector((state) => state.appData.selectedLoyaltyPointsProgram);
  const authRandom = useSelector((state) => state.appData.random);
  const userloginData = useSelector((state) => state.appData.userLoggedIn);
  const getData = JSON.parse(localStorage.getItem("handShakeConfig"));
  const currentLang = localStorage.getItem("i18next") || "en";
  useEffect(() => {
    impressionEvents.impressionGlobalVariable(
      currentLang, "Delivery Page"
    );
  }, []);
  useEffect(() => {
    if (loyaltyEarnTypeList) {
      setLoyaltyEarnType(loyaltyEarnTypeList);
    } else {
      dispatch(getLoyaltyEarnTypesData());
    }
  }, [loyaltyEarnTypeList]);

  useEffect(() => {
    if (selectedLoyaltyPointsProgram !== "") {
      setLoyaltyEarnTypeId(selectedLoyaltyPointsProgram);
    }
  }, [selectedLoyaltyPointsProgram]);

  const handleClickOpeningHour = (valData) => {
    setOpeningHoursData(valData);
    setModalShow(true);
  };

  const openLoginWrapperFromAnywhere = () => {
    // reloadingHeader()

    if (customerDetails === "") {
      const element = document.querySelector(
        ".signin__signup__popup__disable"
      );
      if (element !== null) {
        element.classList.remove("signin__signup__popup__disable");
        element.classList.add("signin__signup__popup");
      }
      localStorage.setItem("loginWrapper", JSON.stringify(true));
      localStorage.setItem("loginMode", JSON.stringify("signin"));
      localStorage.setItem("loginPopup", JSON.stringify(true));
      window.scrollTo(500, 0);
    }
  };

  const customerData = getCustomerLoginDetails();

  useEffect(() => {
    // dispatch(getAvailablePaymentMethods());
    dispatch(services.getCustomerAddressList());
    dispatch(loadCountriesLocationData());
    dispatch(loadCitiesLocationData());
    dispatch(loadStoresLocationData());
    dispatch(getLoyaltyEarnTypesData());
    window.scrollTo(0, 0);
  }, [userloginData]);

  useEffect(() => {
    dispatch(loadConfigurationSettingData())
  }, []);

  useEffect(() => {
    if (cityLocationData) {
      let cityList = [];
      cityLocationData &&
        cityLocationData.map((val, i) => {
          let cityData = {
            value: val.cityCode,
            label: val.cityCode,
            allData: val,
          };
          cityList.push(cityData);
        });
      setStoreCitiesLocationData(cityList);
    }
  }, [cityLocationData])

  useEffect(() => {
    if (storesLocationData) {
      let commonStores, filteredStoresData
      const storesSets = cartData?.items && cartData?.items.map(elem => new Set(elem.extension_attributes.available_stores));
      if (storesSets && storesSets.length > 0) {
        commonStores = [...storesSets.reduce((acc, curr) => new Set([...acc].filter(x => curr.has(x))))];
      }
      if (commonStores) {
        filteredStoresData = storesLocationData && storesLocationData.filter((store) =>
          commonStores.includes(store?.storeCode.toLowerCase())
        );
      }
      const queryValue = searchData.trim();
      let filterdata = filteredStoresData && filteredStoresData.filter((item) => item.name.toLowerCase().includes(queryValue.toLowerCase()));
      setFilteredStores(filterdata)
    }
  }, [cartData?.items, searchData, storesLocationData]);

  const contact_prefill = localStorage.getItem("contact_prefill");
  useEffect(() => {
    if (customerDetails === "") {
      if (contact_prefill !== null) {
        setCustEmailAddress(JSON.parse(contact_prefill).email);
      } else {
        // setCustEmailAddress("");
      }
    } else {
      setCustEmailAddress(customerDetails.email);
    }
  }, [customerDetails, contact_prefill]);

  useEffect(() => {
    if (!loading) {
      const totalData = {
        currencyCode: cartData?.totals_data?.base_currency_code || 'SAR',
        value: Number(parseFloat(cartData?.totals_data?.base_grand_total).toFixed(2)),
      };
      const checkoutProducts = cartData?.items?.map((item) => ({
        item_name: item?.product?.name,
        item_id: item?.product?.id,
        price: Number(parseFloat(
          item?.product?.discounted_price_rounded || item?.product?.price_rounded,
        ).toFixed(2)),
        brand:
          item?.product?.options?.find((option) => option.code === 'brand')
            ?.selected_option?.label || null,
        category: item?.product?.categories?.pop()?.name || 'Sony',
        variant: null,
        quantity: item?.qty,
        dimension1: item?.product.sku,
        coupon: cartData?.totals_data?.coupon_code || null
      }));
      // checkoutEvents.beginCheckout(cartData?.totals_data?.coupon_code || null, checkoutProducts, totalData);
    }
  }, [loading])

  useEffect(() => {
    if (deliveryShippingInfo !== "") {
      // setIconType({ ...iconType, delivery: "done", payment: "inprogress" });
      // navigate("/checkout/payment")
      // setCheckoutClassName("payment");
      // setPaymentMethods(deliveryShippingInfo.payment_methods);
      // setUserPaymentMethod(deliveryShippingInfo.payment_methods[0].code);
      dispatch(updateShippingInformationSuccess(""));
    }
  }, [deliveryShippingInfo]);

  useEffect(() => {
    if (customerDetails === "") {
      if (localStorage.getItem("user_mode") === 'guest_user') {
        localStorage.setItem("loginWrapper", JSON.stringify(false));
        localStorage.setItem("loginMode", JSON.stringify(""));
        localStorage.setItem("loginPopup", JSON.stringify(false));
      } else {
        openLoginWrapperFromAnywhere();
      }

      setIconType({ ...iconType, signin: "inprogress" });
    } else {
      dispatch(services.getCustomerAddressList());
      setIconType({ ...iconType, signin: "done" });
    }
  }, [customerDetails, userloginData]);

  useEffect(() => {
    if (Object.values(cartData).length !== 0) {
      setCartTotalData(cartData.totals_data);
      setCartDetail(cartData);
      setLoading(false);
      window.scrollTo(0, 0);
    }
  }, [cartData]);

  useEffect(() => {
    if (customerAddressList) {
      let updateAddressData = [];
      customerAddressList &&
        customerAddressList.map((val, i) => {
          let landmarkData = "";
          let addressTypeData = "";
          if (val.custom_attributes !== undefined) {
            let getLandmark =
              val.custom_attributes &&
              val.custom_attributes.filter(
                (val) => val.attribute_code === "landmark"
              );
            if (getLandmark.length > 0) {
              landmarkData = getLandmark[0].value;
            }

            let getAddressType =
              val.custom_attributes &&
              val.custom_attributes.filter(
                (val) => val.attribute_code === "address_types"
              );
            if (getAddressType.length > 0) {
              addressTypeData =
                getAddressType[0].value === "1" ? t("Home") : t("Work");
            }
          }

          let addreDetails = {
            id: val.id,
            isDefault: val.primary,
            userName: `${val.firstname} ${val.lastname}`,
            adddress: `${val.street[0]}, ${val.street[1] !== undefined ? val.street[1] : ""
              } ${val.city}, ${landmarkData}, ${val.postcode !== undefined ? val.postcode : ""
              } ${val.country_id}`,
            contact: val.telephone,
            details: val,
            addressTypeData: addressTypeData,
          };
          updateAddressData.push(addreDetails);
          if (val.primary === true) {
            setSelectedAddressID(i);
          }
        });
      setAddressData(updateAddressData);
    }
  }, [customerAddressList]);

  useEffect(() => {
    setStoreConfig(configurationSettingData?.store_pickup)
  }, [configurationSettingData])

  // Delivery Preferences
  useEffect(async () => {
    if (storeConfig !== undefined && storeConfig !== "" && width > 992) {
      dispatch(services.loaderStart())
      const data = await getEstimateShippingMethods();
      if (data) {
        let shippingMethods = data["shipping-methods"];
        const propertyNames = Object.keys(shippingMethods);
        let deliveryData = [];
        propertyNames &&
          propertyNames.map((val, i) => {
            let deliveryInfo = {
              id: shippingMethods[val].shipping_method_code,
              type: shippingMethods[val].title,
              protectionText: shippingMethods[val].head_line,
              price: shippingMethods[val].shipping_cost,
              is_available: shippingMethods[val]?.is_available
            };
            if (shippingMethods[val].shipping_method_code === 'storepickup' && storeConfig === "1") {
              deliveryData.push(deliveryInfo);
            } else if (shippingMethods[val].shipping_method_code !== 'storepickup') {
              deliveryData.push(deliveryInfo);
            }
          });
        setDeliveryType(deliveryData);

        const deliveryDataIds = deliveryData?.filter((item) => item?.is_available).map((item) => item?.id)
        let lastDt = localStorage.getItem("last-dt");
        if ((deliveryDataIds).includes(lastDt)) {
          setDeliveryPreferencesType(lastDt)
        } else {
          setDeliveryPreferencesType(deliveryData[0].id)
        }
      }
      dispatch(services.loaderEnd())
    }
  }, [storeConfig]);

  useEffect(() => {
    const deliveryDataIds = deliveryType?.filter((item) => item?.is_available).map((item) => item?.id)
    let lastDt = localStorage.getItem("last-dt");

    if (deliveryType && deliveryType.length > 0 && cartData && !deliveryDataIds.includes(lastDt)) {
      if (deliveryType &&
        deliveryType.filter((dev) => dev.id === "flatrate" && dev?.is_available)
          .length > 0) {
        setPreferenceTab("home")
        localStorage.setItem("last-dt", "flatrate");
      } else if (deliveryType &&
        deliveryType.filter((dev) => dev.id === "storepickup" && dev?.is_available)
          .length > 0) {
        setPreferenceTab("storepickup")
        setDeliveryPreferencesType("storepickup")
        localStorage.setItem("last-dt", "storepickup");
      } else if (deliveryType &&
        (deliveryType.filter((dev) => dev.id === "flatrate" && dev?.is_available)
          .length < 1) && (deliveryType &&
            deliveryType.filter((dev) => dev.id === "storepickup" && dev?.is_available)
              .length < 1) && !cartData?.is_virtual) {
        navigate('/cart')
      }
    }
  }, [deliveryType, cartData])

  useEffect(() => {
    let lastDt = localStorage.getItem("last-dt");
    if (lastDt === "flatrate") {
      lastDt = "home";
    } else if (lastDt === "storepickup") {
      lastDt = "storepickup";
    } else {
      lastDt = "";
    }
    setPreferenceTab(lastDt);
  }, []);

  const handleChangeCreateBillingAddress = (email) => {
    setIconType({ ...iconType, delivery: "done", payment: "inprogress" });
    // setCheckoutClassName("payment");
    // if (paymentMethods.length > 0) {
    //   let getFirstMethod = paymentMethods.filter(val => val.is_available === true);
    //   if (getFirstMethod.length > 0) {
    //     setUserPaymentMethod(getFirstMethod[0].code);
    //   }
    // }
    setCustEmailAddress(email);
    dispatch(updateShippingInformationSuccess(""));
    navigate("/checkout/payment")
  };

  const handleChangePreferenceTab = (name) => {
    const lastDt = name === "home" ? "flatrate" : "storepickup";
    localStorage.setItem("last-dt", lastDt);
    setPreferenceTab(name);
    setErrMsg({
      ...errMsg,
      deliveryPreferencesType: "",
    });
    setStorePickupError("");
    if (name === "home") {
      setDeliveryPreferencesType(deliveryType?.[0].id);
    } else {
      setDeliveryPreferencesType(name);
    }
  };

  const selectAddress = (addIndex, addId, add) => {
    setSelectedAddressID(addIndex);
    setEditAddressData(add);
    // setDeliveryPreferencesType("");
  };

  const openNewAddressPopup = (popupType, addIndex, addId, add) => {
    setAddressPopup(true);
    setAddressPopupType(popupType);
    if (popupType === "update") {
      setSelectedAddressID(addIndex);
      setEditAddressData(add);
    }
  };

  const deleteAddress = (deleteId) => {
    let params = {
      addressId: deleteId,
    };
    dispatch(services.deleteCustomerAddress(params));
  };

  const getDeliveryMethodByCity = (cityName, shippingType) => {
    let shippingIsEnable = false;
    if (shippingType === "express") {
      let expShipping =
        storeCitiesLocationData &&
        storeCitiesLocationData.filter(
          (val) =>
            val.allData.cityCode === cityName &&
            val.allData.expressShipping === "1"
        );
      if (expShipping.length > 0) {
        shippingIsEnable = true;
      }
    }
    if (shippingType === "lighting") {
      let lightShipping =
        storeCitiesLocationData &&
        storeCitiesLocationData.filter(
          (val) =>
            val.allData.cityCode === cityName &&
            val.allData.lightingShipping === "1"
        );
      if (lightShipping.length > 0) {
        shippingIsEnable = true;
      }
    }
    return shippingIsEnable;
  };

  const handleChangeDeliveryPref = (e, value) => {
    setDeliveryPreferencesType(value);
    localStorage.setItem("deliveryPref", value);
    setSelectedStoreId("");
    setStorePickupError("");
  };

  const handleChangePickupStore = (storeId) => {
    setSelectedStoreId(storeId);
  };

  const handleSetOrderComment = async () => {
    if (shippingCustomMsg) {
      try {
        // const response = 
        await apiHelper(
          `/V1/set-order-comment?comment=${shippingCustomMsg}&cartId=${cartDetail?.id}`,
          "put",
          {},
          {}
        );
      } catch (error) {
      }
    }
  };

  let bundleData = [];
  const data_bundle_id = [];
  const bundleProductData = [];
  const bundle_item_counts = [];

  useEffect(() => {
    if (bundleData) {
      bundleData = cartDetail?.items?.filter(
        (item) => item.product_type === 'bundle',
      );
      for (let index = 0; index < bundleData?.length; index++) {
        data_bundle_id.push(bundleData[index]?.item_id);
        bundle_item_counts.push(bundleData[index]);
      }
      bundleData?.map((item1) => item1?.product?.bundle_product_options?.map((itm) => bundleProductData.push(itm)));
    }
  }, [bundleData]);

  const removeServiceFromCart = async () => {
    await deleteFromCart({ items: data_bundle_id });
    const payload = [];
    const filteredProductData = bundleProductData?.filter(
      (item) => item?.required === '1',
    );
    for (let index = 0; index < filteredProductData?.length; index++) {
      const quantity = bundle_item_counts[index];
      const object = {
        sku: filteredProductData[index].sku,
        qty: quantity?.qty,
        bundle_options: [
          {
            option_id: +filteredProductData[index]?.product_links[0]?.option_id,
            option_qty: 1,
            option_selections: [
              +filteredProductData[index]?.product_links[0]?.id,
            ],
          },
        ],
      };
      payload.push(object);
    }

    await addToCart({ items: payload });
  };

  const continueFromDelivery = async (newIconType, className) => {
    // setLoaderOn(true);
    // setContinueDeliveryLoading(true);
    let newErrObj = {
      deliveryPreferencesType: "",
      deliveryAddressList: "",
    };

    if (deliveryPreferencesType !== "") {
      newErrObj = { ...newErrObj, deliveryPreferencesType: "" };
    } else {
      newErrObj = {
        ...newErrObj,
        deliveryPreferencesType: t("Select_your_shipping_option")

      };
      // setContinueDeliveryLoading(false);
    }
    if (deliveryPreferencesType !== "") {
        let getDeliveryInfo = addressData?.[selectedAddressId];
        if (
          getDeliveryInfo !== undefined &&
          selectedAddressId !== "" &&
          custEmailAddress !== ""
        ) {
          if (deliveryPreferencesType === "flatrate") {
            let params = {
              useAsBilling: true,
              firstName:
                getDeliveryInfo.details.firstname === undefined
                  ? ""
                  : getDeliveryInfo.details.firstname,
              lastName:
                getDeliveryInfo.details.lastname === undefined
                  ? ""
                  : getDeliveryInfo.details.lastname,
              email: custEmailAddress,
              telephone:
                getDeliveryInfo.details.telephone === undefined
                  ? ""
                  : getDeliveryInfo.details.telephone,
              city:
                getDeliveryInfo.details.city === undefined
                  ? ""
                  : getDeliveryInfo.details.city,
              postCode:
                getDeliveryInfo.details.postcode === undefined
                  ? ""
                  : getDeliveryInfo.details.postcode,
              countryId: getDeliveryInfo.details.country_id,
              street: `${getDeliveryInfo.details.street[0]} ${getDeliveryInfo.details.street[1]}`,
              shippingCarrierCode: deliveryPreferencesType,
              // pickup_store: '',
              region_id:
                getDeliveryInfo.details.region_id === undefined
                  ? 0
                  : getDeliveryInfo.details.region_id,
            };

            await dispatch(updateShippingInformation(params)).then(() => {
              handleSetOrderComment();
            });
            // setContinueDeliveryLoading(false);            
          } else if (deliveryPreferencesType !== "storepickup") {
            let params = {
              useAsBilling: true,
              firstName:
                getDeliveryInfo.details.firstname === undefined
                  ? ""
                  : getDeliveryInfo.details.firstname,
              lastName:
                getDeliveryInfo.details.lastname === undefined
                  ? ""
                  : getDeliveryInfo.details.lastname,
              email: custEmailAddress,
              telephone:
                getDeliveryInfo.details.telephone === undefined
                  ? ""
                  : getDeliveryInfo.details.telephone,
              city:
                getDeliveryInfo.details.city === undefined
                  ? ""
                  : getDeliveryInfo.details.city,
              postCode:
                getDeliveryInfo.details.postcode === undefined
                  ? ""
                  : getDeliveryInfo.details.postcode,
              countryId: getDeliveryInfo.details.country_id,
              street: `${getDeliveryInfo.details.street[0]} ${getDeliveryInfo.details.street[1]}`,
              shippingCarrierCode: deliveryPreferencesType,
              // pickup_store: '',
              region_id:
                getDeliveryInfo.details.region_id === undefined
                  ? 0
                  : getDeliveryInfo.details.region_id,
            };
            await dispatch(updateShippingInformation(params)).then(() => {
              handleSetOrderComment();
            });  
          }
        } else {
          if (deliveryPreferencesType === "flatrate") {
            if (addressData.length < 1) {
              newErrObj = {
                ...newErrObj,
                deliveryAddressList: t("Add_New_Address"),
              };
            } else {
              newErrObj = {
                ...newErrObj,
                deliveryAddressList: t("Please_Select_Delivery_Address"),
              };
            }
          }
          // setContinueDeliveryLoading(false);
        }

        if (deliveryPreferencesType === "storepickup") {
          if(!userData.firstName || !userData.lastName || !userData.mobileNumber || !userData.email ){
            const errorResult = allFeildValidate(["firstName", "lastName", "mobileNumber", "email"], addressError);
            setAddressError(errorResult.allErrMsg)
            return
          } else if (selectedStoreId === "") {
            setStorePickupError(t("Please_selecte_store"));
            if (userData.firstName && userData.lastName && userData.mobileNumber && userData.email) {
              setAddressError({firstName: "", lastName: "",email: "",mobileNumber: ""})
            }
            // setContinueDeliveryLoading(false);
          } else {
            setStorePickupError("");
            setAddressError({firstName: "", lastName: "",email: "",mobileNumber: ""})
            const selectedStore = storesLocationData && storesLocationData.find((item)=> item?.id === selectedStoreId)
            let params = {
              useAsBilling: true,
              firstName: userData.firstName,
              lastName: userData.lastName,
              email: userData.email,
              telephone:
                userData?.mobileNumber ||
                customerData.custom_attributes.mobile,
              city: selectedStore?.city,
              postCode: "",
              countryId: selectedStore?.country,
              street: selectedStore?.address,
              shippingCarrierCode: deliveryPreferencesType,
              pickup_store: selectedStore?.storeCode,
              region_id: '76',
            };
            localStorage.setItem("shippingAsBilling", true);
            localStorage.setItem('pickup_store', selectedStore?.storeCode)
            await dispatch(updateShippingInformation(params)).then(() => {
              handleSetOrderComment();
            });
          }
        }
        await dispatch(loadCartData());
        const totalData = { currencyCode: cartData?.totals_data?.base_currency_code || 'SAR', value: Number(parseFloat(cartData?.totals_data?.base_grand_total).toFixed(2)) };
        const checkoutProducts = cartData?.items?.map((item) => ({
          item_name: item?.product?.name,
          item_id: item?.product?.id,
          price: Number(parseFloat(item?.product?.discounted_price_rounded || item?.product.price_rounded).toFixed(2)),
          brand: item?.product?.options?.find((option) => option.code === 'brand')?.selected_option?.label || null,
          category: item.product?.categories?.pop()?.name || 'Sony',
          variant: null,
          quantity: item?.qty,
          dimension1: item?.product.sku,
          coupon: cartData?.totals_data?.coupon_code || null
        }));
        //checkoutEvents.addShippingInfo(cartData?.totals_data?.coupon_code || null, checkoutProducts, totalData);
        impressionEvents.impressionAddShippingInformation(
          cartData,'Checkout Delivery',deliveryPreferencesType);

    }
    setErrMsg(newErrObj);
    // setIconType(newIconType);
    // setCheckoutClassName(className);
    window.scrollTo(0, 0);
    const errorArr = [...new Set(Object.values(newErrObj))]
    if (errorArr.length < 2 && errorArr[0] === "") {
      if (deliveryPreferencesType === "storepickup") {
        if (selectedStoreId !== "") {
          navigate("/checkout/payment")
        }
      } else {
        navigate("/checkout/payment")
      }
    }
  };
  
  const closeLoginPopup = () => {
    // if (document.querySelector(".address__popup__container")) {
    //   // reloadingHeader()
    //   const element = document.querySelector(".address__popup__container");
    //   if (element !== null) {
    //     element.classList.remove("address__popup__container");
    //     element.classList.add("address__popup__container__disable");
    //   }
    // }
    setAddressPopup(false);
  };

  function Position(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return currenttop > 200 ? [currenttop - 200] : [0];
    }
  }

  useEffect(() => {
    if (customerData && !userData?.firstName && !userData?.lastName && !userData?.email && !userData?.mobileNumber) {
      setUserData({
        firstName: customerData.firstname,
        lastName: customerData.lastname,
        email: customerData.email,
        mobileNumber: customerData?.custom_attributes?.phone_number
      })
    }
  }, [customerData])
  

  useEffect(() => {
    const errorElement = document.querySelector(".invalid__message");
    if (errorElement && document.activeElement.tagName !== "INPUT") {
      window.scrollTo(0, Position(errorElement));
    }
  }, [errMsg, storePickupError, addressError])

  const validateForm = (newErrObj, name, value) => {
    switch (name) {
      case "firstName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_FIRST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "lastName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_LAST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "mobileNumber":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_MOBILE_NUMBER };
        } else {
          let isValidNumber = isMobileNumber(value);
          if (isValidNumber === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_MOBILE_NUMBER };
          } else {
            newErrObj = { ...newErrObj, [name]: "" };
          }
        }
        break;
      case "email":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_EMAIL };
        } else {
          let isValidEmail = emailValidator(value);
          if (isValidEmail === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_EMAIL };
          } else {
            newErrObj = { ...newErrObj, [name]: "" };
          }
        }
        break;
      default:
        break;
    }
    return newErrObj;
  };

  const allFeildValidate = (validateFeild, allErrMsg) => {
    let checkValueStatus = [];
    let checkErrStatus = [];

    validateFeild &&
      validateFeild.map((val, i) => {
        let keyVal = userData[val];
        let errVal = addressError[val];

        allErrMsg = validateForm(allErrMsg, val, keyVal);
        if (keyVal !== "") {
          checkValueStatus.push("suc");
        }
        if (errVal === "") {
          checkErrStatus.push("err");
        }
      });

    let checkAddressStatus = false;
    if (
      checkValueStatus.length === validateFeild.length &&
      checkErrStatus.length === validateFeild.length
    ) {
      checkAddressStatus = true;
    }

    let returnData = {
      allErrMsg: allErrMsg,
      checkAddressStatus: checkAddressStatus,
    };

    return returnData;
  };



   const handleChange = (event, keyName) => {
    let value = event;
    let name = event;
    if (
      event?.target?.name === "firstName" ||
      event?.target?.name === "lastName"
    ) {
      value = acceptOnlyString(event.target.value, true);
      name = event.target.name;
    } else if (keyName === "mobileNumber") {
      value = event.trim();
      name = "mobileNumber";
    } else {
      value = event.target.value;
      name = event.target.name;
    }
    
    setUserData({ ...userData, [name]: value });

    let manageErrMsg = validateForm(addressError, name, value);
    setAddressError(manageErrMsg);
  };

  const handleBlur = (e) => {
    let obj = {
      target: {
        name: e.target.name,
        value: e.target.value.trim(),
      },
    };
    handleChange(obj);
  };

  if (loading && !authRandom) {
    return <>
      <NewLoader />
      {width > 992 ? <div className="container-fluid  breadcrumbs__block bg-transparent">
        <Skeleton width={"210px"} height="18px" />
        <br />
      </div> :
        <div className="px-3">
          <Skeleton width={"100%"} height="50px" />
        </div>
      }
      <div className={`container-fluid ${width > 992 ? "checkout__page__block" : "px-3"}`}>
        <div className="row checkout__page__inner__block">
          <div className="col-md-12 col-xl-8  checkout__left__block">
            <Skeleton width={"100%"} height="60px" />
            <Skeleton width={"100%"} height="400px" />
          </div>
          <div className="col-md-12 col-xl-4  checkout__right__block">
            <Skeleton width={"100%"} height="400px" />
          </div>
        </div>
      </div>
    </>
  }

  return (<>
    <MetaComponent
      title={t("Checkout")}
      meta_title={t("Checkout")}
      meta_keywords={t("Checkout")}
      meta_description={t("Checkout")}
    />
    {modalShow && <OpeningHoursModal
      modalShow={modalShow}
      val={openingHoursData}
      onHide={() => setModalShow(false)}
    />}
    {width <= 992 &&
      <div className="d-block d-lg-none">
        <MobileDelivery
          cartDetail={cartDetail}
          shippingCustomMsg={shippingCustomMsg}
          setShippingCustomMsg={setShippingCustomMsg}
          handleSetOrderComment={handleSetOrderComment}
          cartTotalData={cartTotalData}
          loyaltyEarnType={loyaltyEarnType}
          loyaltyEarnTypeId={loyaltyEarnTypeId}
          storeConfig={storeConfig}
          authRandom={authRandom}
          userloginData={userloginData}
          getData={getData}
        />
      </div>
    }
    {width > 992 && !authRandom ? <>
      <div className="d-lg-block d-none">
        <BreadCrumbs
          back="/cart"
          firstBread="/cart"
          firstBreadTitle={t("Shopping_cart")}
          secondBread="/checkout/delivery"
          secondBreadTitle={t("DELIVERY")}
          highlight="secondBreadTitle"
        />
      </div>
      <div className="container-fluid checkout__page__container d-lg-block d-none">
        <div className="checkout__page__block">
          <div className="row checkout__page__inner__block">
            <div className="col-md-12 col-xl-8  checkout__left__block">
              <div className="row checkout__login__main__block">
                <div
                  onClick={() => openLoginWrapperFromAnywhere()}
                  className="col-2 checkout__signin__button"
                >
                  <img
                    src={
                      iconType.signin === "inprogress"
                        ? signin_inprogress
                        : iconType.signin === "done"
                          ? signin_done
                          : signin_initial
                    }
                    alt=""
                  />
                  <Heading6
                    text={t("Sign_In")}
                    marginLeft={10}
                    color={
                      iconType.signin === "inprogress"
                        ? "#DC3A1A"
                        : iconType.signin === "done"
                          ? "#585858"
                          : "#C8C8C8"
                    }
                    span={true}
                  />
                  {iconType.signin === "done" ? (
                    <img className="done__icon" src={done} alt="done" />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-3 checkout__middle__line__block"></div>
                <div
                  // onClick={() => handleChangeClassName("delivery")}
                  className="col-2 checkout__delivery__button"
                >
                  <img
                    src={
                      iconType.delivery === "inprogress"
                        ? delivery_inprogress
                        : iconType.delivery === "done"
                          ? delivery_done
                          : delivery_initial
                    }
                    alt=""
                  />
                  <Heading6
                    text={t("DELIVERY")}
                    marginLeft={10}
                    color={
                      iconType.delivery === "inprogress"
                        ? "#DC3A1A"
                        : iconType.delivery === "done"
                          ? "#585858"
                          : "#C8C8C8"
                    }
                    span={true}
                  />
                  {iconType.delivery === "done" ? (
                    <img className="done__icon" src={done} alt="done" />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-3 checkout__middle__line__block"></div>
                <div
                  // onClick={() => handleChangeClassName("payment")}
                  className="col-2 checkout__payment__button"
                >
                  <img
                    src={
                      iconType.payment === "inprogress"
                        ? payment_inprogress
                        : iconType.payment === "done"
                          ? payment_done
                          : payment_initial
                    }
                    alt=""
                  />
                  <Heading6
                    text={t("PAYMENT")}
                    marginLeft={10}
                    color={
                      iconType.payment === "inprogress"
                        ? "#DC3A1A"
                        : iconType.payment === "done"
                          ? "#585858"
                          : "#C8C8C8"
                    }
                    span={true}
                  />
                  {iconType.payment === "done" ? (
                    <img className="done__icon" src={done} alt="done" />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="user__delivery__address__block">
                {cartDetail?.is_virtual === true ? (
                  <Virtual_Product_Checkout_Page
                    handleChangeCreateBillingAddress={
                      handleChangeCreateBillingAddress
                    }
                    setCustEmailAddress={setCustEmailAddress}
                  />
                ) : (
                  <>
                    {deliveryType && deliveryType.length > 0 ?
                      <>
                        <div className="delivery__address__block">
                          <div className="delivery__address__title__block">
                            <img src={shipping} alt="" className="user__icon" />
                            <Heading6
                              text={t("WHAT_DO_YOU_PREFER")}
                              color="#000000"
                              marginLeft={10}
                              marginBottom={0}
                            />
                          </div>

                          {preferenceTab !== null ?
                            <div className="col-12 col-md-12 px-3 delivery__address__option__area">
                              <div className="row">
                                {deliveryType &&
                                  deliveryType.filter((dev) => dev.id === "flatrate" && dev?.is_available)
                                    .length > 0 && (
                                    <div
                                      className="col-12 col-sm-12 col-md-5  delivery__option__item"
                                    >
                                      <div
                                        className={`${preferenceTab === "home"
                                          ? "delivery__option__btn active"
                                          : "delivery__option__btn"
                                          }`}
                                        onClick={() => {
                                          handleChangePreferenceTab("home")
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          className="delivery__input__check"
                                          name="deliveryType"
                                          // value={delivery.id}
                                          onChange={(e) =>
                                            handleChangePreferenceTab("home")
                                          }
                                          checked={
                                            preferenceTab === "home" ? true : false
                                          }
                                          id="home_delivery_checkout"
                                        />
                                        <img
                                          src={shipping}
                                          alt=""
                                          className="user__icon "
                                        />
                                        <label htmlFor="home_delivery_checkout">
                                          {t("Home_Delivery")}
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                {deliveryType &&
                                  deliveryType.filter(
                                    (dev) => dev.id === "storepickup" && dev?.is_available
                                  ).length > 0 && (
                                    <div
                                      className="col-12 col-sm-12 col-md-5  delivery__option__item"
                                    >
                                      <div
                                        className={`${preferenceTab === "storepickup"
                                          ? "delivery__option__btn active"
                                          : "delivery__option__btn"
                                          }`}
                                        onClick={() => handleChangePreferenceTab("storepickup")}
                                      >
                                        <input
                                          type="radio"
                                          className="delivery__input__check"
                                          name="deliveryType"
                                          // value={delivery.id}
                                          onChange={(e) => handleChangePreferenceTab("storepickup")}
                                          checked={
                                            preferenceTab === "storepickup"
                                              ? true
                                              : false
                                          }
                                          id="store_pickup_checkout"
                                        />
                                        <img
                                          src={pickup_store}
                                          alt=""
                                          className="user__icon "
                                        />
                                        <label htmlFor="store_pickup_checkout">
                                          {t("Store_Pickup")}
                                        </label>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                            : null}

                          {(userloginData === true || getData?.user?.userLoggedIn === true) && customerDetails !== "" && preferenceTab === "home" && deliveryType &&
                            deliveryType.filter((dev) => dev.id === "flatrate" && dev?.is_available)
                              .length > 0 && (
                              <>
                                <div className="delivery__address__title__block select__address__block">
                                  <img src={shipping} alt="" className="user__icon" />
                                  <Heading6
                                    text={t("SELECT_DELIVERY_ADDRESS")}
                                    color="#000000"
                                    marginLeft={10}
                                    marginBottom={0}
                                  />
                                </div>
                                <div className="checkout__address__add__area">
                                  <div className="row address__select__block">
                                    {addressData &&
                                      addressData
                                        .filter((val, i) =>
                                          viewMoreAddressBtn === false
                                            ? i < 2
                                            : addressData.length
                                        )
                                        .map((add, addIndex) => {
                                          return (
                                            <div
                                              key={add.id}
                                              className="col-12 col-md-6 col-lg-6 col-xl-6 address__block"
                                            >
                                              <div
                                                className={
                                                  selectedAddressId === addIndex
                                                    ? "selected__address__inner__block"
                                                    : "address__inner__block"
                                                }
                                                onClick={() =>
                                                  selectAddress(addIndex, add.id, add)
                                                }
                                              >
                                                <div className="user__address__info__area">
                                                  <Heading7 text={add.userName} />
                                                  <div className="full__address">
                                                    <Text4
                                                      text={add.adddress}
                                                      marginBottom={20}
                                                    />
                                                  </div>
                                                  <Text4
                                                    text={add.addressTypeData}
                                                    marginBottom={5}
                                                  />
                                                  {/* <Heading7
                                              text="Phone Number:"
                                              color="#808080"
                                              span={true}
                                            />{" "} */}
                                                  <Text4
                                                    text={add.contact}
                                                    marginLeft={10}
                                                    span={true}
                                                  />
                                                </div>
                                                <div className="address__action__area">
                                                  <div className="inner__address__button__block">
                                                    <button
                                                      onClick={() =>
                                                        openNewAddressPopup(
                                                          "update",
                                                          addIndex,
                                                          add.id,
                                                          add
                                                        )
                                                      }
                                                      className="edit__button"
                                                    >
                                                      <img
                                                        src={edit_box_white}
                                                        alt="edit"
                                                      />
                                                      {/* {t("EDIT")} */}
                                                    </button>
                                                    <button
                                                      className="delete__button"
                                                      onClick={() =>
                                                        deleteAddress(add.id)
                                                      }
                                                    >
                                                      <img
                                                        src={delete_box_white}
                                                        alt="delete"
                                                      />
                                                      {/* {t("DELETE")} */}
                                                    </button>
                                                  </div>
                                                  {add.isDefault ? (
                                                    <div className="address__tag">
                                                      <Heading7
                                                        text={t("Default")}
                                                        span={true}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div className="white__address__tag">
                                                      <Text5
                                                        text={t("NONE")}
                                                        span={true}
                                                        color="#ffffff"
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="address__button__block">
                                                  <button className="delivery__button">
                                                    {t("DELIVER_TO_THIS_ADDRESS")}
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    {errMsg.deliveryAddressList && (
                                      <p
                                        className="invalid__message"
                                        style={errMsgStyle}
                                      >
                                        {errMsg.deliveryAddressList}
                                      </p>
                                    )}
                                  </div>
                                  <div className=" add__new__address__block">
                                    <button
                                      onClick={() => openNewAddressPopup("add")}
                                      className="location__button"
                                    >
                                      <BsPlusLg />
                                      <Heading7
                                        text={t("Add_More")}
                                        marginBottom={0}
                                        color="#000000"
                                      />
                                    </button>
                                  </div>
                                </div>
                                {/* <hr className="checkout__page__horizontal__line"></hr> */}
                                {addressData && addressData.length > 2 && (
                                  <div className="view__more__address__block">
                                    <button
                                      onClick={() =>
                                        setViewMoreAddressBtn(!viewMoreAddressBtn)
                                      }
                                      className="view__more__address__button"
                                    >
                                      <Heading6
                                        text={
                                          viewMoreAddressBtn
                                            ? t("View_Less")
                                            : t("View_More")
                                        }
                                        marginBottom={0}
                                        color="#000000"
                                      />
                                      <FaCaretDown />
                                    </button>
                                  </div>
                                )}

                                <hr className="checkout__page__horizontal__line"></hr>
                                <div className="row delivery__selcetion__pickup__store">
                                  <div className="col-12 col-sm-12 col-md-12 delivery__preferences__block">
                                    <div className="delivery__preferences__title__block">
                                      <Heading6
                                        text={t(
                                          "SELECT_HOW_FAST_YOU_WANT_TO_RECEIVE_YOUR_SHIPMENT"
                                        )}
                                        color="#000000"
                                      />
                                    </div>
                                  </div>
                                  <div className="delivery__selection__block col-md-6">
                                    {deliveryType &&
                                      deliveryType.map((delivery, deliveryIndex) => {
                                        let cityName =
                                          addressData &&
                                          addressData[selectedAddressId] &&
                                          addressData[selectedAddressId].details &&
                                          addressData[selectedAddressId].details.city;
                                        let shippingIsEnable = true;
                                        if (delivery.id === "lighting") {
                                          shippingIsEnable = getDeliveryMethodByCity(
                                            cityName,
                                            "lighting"
                                          );
                                        } else if (delivery.id === "express") {
                                          shippingIsEnable = getDeliveryMethodByCity(
                                            cityName,
                                            "express"
                                          );
                                        } else if (delivery.id === "storepickup") {
                                          shippingIsEnable = false;
                                        }
                                        return (
                                          <React.Fragment key={delivery?.id}>
                                            {shippingIsEnable === true && (
                                              <div
                                                key={delivery.id}
                                                className="delivery__selection d-flex justify-content-between "
                                              >
                                                <div className="delivery__selection__form__block d-flex">
                                                  <input
                                                    type="radio"
                                                    className="delivery__input__check"
                                                    id={`user_login_deliveryType_${delivery?.id}`}
                                                    name="subDeliveryType"
                                                    checked={
                                                      deliveryPreferencesType ==
                                                        delivery.id
                                                        ? true
                                                        : false
                                                    }
                                                    value={delivery.id}
                                                    onChange={(e) =>
                                                      handleChangeDeliveryPref(
                                                        e,
                                                        delivery.id
                                                      )
                                                    }
                                                  />
                                                  <div className="delivery__selection__text">
                                                    <label
                                                      htmlFor={`user_login_deliveryType_${delivery?.id}`}
                                                    >
                                                      <Heading6
                                                        text={delivery.type}
                                                      />
                                                    </label>
                                                    <Text4
                                                      text={delivery.protectionText}
                                                      color="#3b3b3b"
                                                      marginBottom={0}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="delivery__price__block">
                                                  <div className="delivery__price">
                                                    {delivery.price <= 0 ? (
                                                      <Heading6
                                                        text={t("Free")}
                                                        color="#FF4F04"
                                                      />
                                                    ) : (
                                                      <Price
                                                        price={delivery?.price}
                                                        size="heading7"
                                                        currency={"SAR"}
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </React.Fragment>
                                        );
                                      })}
                                    {errMsg.deliveryPreferencesType && (
                                      <p
                                        className="invalid__message"
                                        style={errMsgStyle}
                                      >
                                        {errMsg.deliveryPreferencesType}
                                      </p>
                                    )}
                                  </div>
                                  <div className="delivery__selection__block col-md-6 delivery__order_note">
                                    <Heading7
                                      text={t("Order Note")}
                                      marginBottom={10}
                                    />
                                    <textarea
                                      rows={4}
                                      className="form-control"
                                      id="shippingCustomMsg"
                                      name="shippingCustomMsg"
                                      value={shippingCustomMsg}
                                      onChange={(e) =>
                                        setShippingCustomMsg(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          {!(userloginData === true || getData?.user?.userLoggedIn === true) && (
                            <>
                              <GuestCheckoutPage
                                deliveryTypeList={deliveryType}
                                // delivery={product.delivery}
                                title="Pick Up From Store"
                                pickupStore={storesLocationData}
                                selectedStoreId={selectedStoreId}
                                handleChangePickupStore={handleChangePickupStore}
                                preferenceTab={preferenceTab}
                                getDeliveryMethodByCity={getDeliveryMethodByCity}
                                storesLocationData={storesLocationData}
                                handleClickOpeningHour={handleClickOpeningHour}
                                shippingCustomMsg={shippingCustomMsg}
                                setShippingCustomMsg={setShippingCustomMsg}
                                handleSetOrderComment={handleSetOrderComment}
                                mode="desktop"
                                setEditAddressData={setEditAddressData}
                                setCustEmailAddress={setCustEmailAddress}
                              />
                            </>
                          )}
                        </div>
                        {(userloginData === true || getData?.user?.userLoggedIn === true) && customerDetails !== "" &&
                          preferenceTab === "storepickup" && (
                            <>
                              {/* <h1>Store pickup section</h1> */}
                              {<div className="newAddress__block guest__checkout">
                                    <div className="newAddress__title__block">
                                      <Heading3
                                        text={t("Personal_Information")}
                                        color="#000000"
                                      />
                                    </div>
                                    <div className="row newAddress__form__field__row">
                                      <div className="col-sm-12 col-md-6 main__form__field__block">
                                        {/* <p className="form__label">First Name</p> */}
                                        <Heading7
                                          text={t("First_Name")}
                                          marginBottom={10}
                                        />
                                        <div className="field__block">
                                          <input
                                            type="text"
                                            placeholder={t("First_Name")}
                                            className="form__field"
                                            id="name"
                                            name="firstName"
                                            key={"firstName"}
                                            value={userData.firstName}
                                            onChange={(e) => handleChange(e)}
                                            onBlur={handleBlur}
                                          />
                                        </div>
                                        {addressError.firstName && (
                                          <p className="invalid__message">
                                            {addressError.firstName}
                                          </p>
                                        )}
                                      </div>
                                      <div className="col-sm-12 col-md-6 main__form__field__block">
                                        {/* <p className="form__label">Mobile Number</p> */}
                                        <Heading7
                                          text={t("Last_Name")}
                                          marginBottom={10}
                                        />
                                        <div className="field__block">
                                          <input
                                            type="text"
                                            placeholder={t("Last_Name")}
                                            className="form__field"
                                            id="lastName"
                                            name="lastName"
                                            key="lastName"
                                            value={userData.lastName}
                                            onChange={(e) => handleChange(e)}
                                            onBlur={handleBlur}
                                          />
                                        </div>
                                        {addressError.lastName && (
                                          <p className="invalid__message">
                                            {addressError.lastName}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                      <div className="row newAddress__form__field__row">
                                        <div className="col-sm-12 col-md-6 main__form__field__block">
                                          <Heading7 text={t("Mobile_Number")} marginBottom={10} />
                                          <PhoneInput
                                            inputProps={{
                                              name: "mobileNumber",
                                              required: true,
                                              className: "profile__mobile__form__field",
                                            }}
                                            country="sa"
                                            onlyCountries={["sa"]}
                                            masks={{ sa: ".. ... ...." }}
                                            countryCodeEditable={false}
                                            disableDropdown={true}
                                            value={userData.mobileNumber}
                                            onChange={(e) => handleChange(e, "mobileNumber")}
                                            className=""
                                          />
                                          {addressError.mobileNumber && (
                                            <p className="invalid__message">{addressError.mobileNumber}</p>
                                          )}
                                        </div>
                                        <div className="col-sm-12 col-md-6 main__form__field__block">
                                          <Heading7
                                            text={t("Order_confirmation_email")}
                                            marginBottom={10}
                                          />
                                          <div className="field__block">
                                            <input
                                              type="text"
                                              placeholder={t("Order_confirmation_email")}
                                              className="form__field"
                                              id="email"
                                              name="email"
                                              key="email"
                                              value={userData.email}
                                              onChange={(e) => handleChange(e)}
                                              onBlur={handleBlur}
                                            />
                                          </div>
                                          {addressError.email && (
                                            <p className="invalid__message">{addressError.email}</p>
                                          )}
                                        </div>
                                      </div>
                                  </div>}
                              <div className="store__pickup__mainarea">
                                <div className="store__pickup__heading">
                                  <Heading4
                                    text={t("Available_showrooms")}
                                    color="#424b5a"
                                  />
                                  {/* <Form.Select aria-label="Default select example">
                          <option>Riyadh</option>
                          <option value="1">goa</option>
                          <option value="2">turki</option>
                          <option value="3">israil</option>
                        </Form.Select> */}
                                  {/* <Select
                                name="city"
                                onChange={(e) =>
                                  setStoreCityCode(e.allData.cityName)
                                }
                                // value={storeCitiesLocationData && storeCitiesLocationData.filter(val => val.value === address.city)?.[0]}
                                options={storeCitiesLocationData}
                                styles={{ marginBottom: "10px" }}
                              /> */}
                                  <InputGroup className="search__store__availablebox">
                                    <Form.Control
                                      placeholder={t("Search city or store")}
                                      aria-label="Recipient's username"
                                      aria-describedby="basic-addon2"
                                      onChange={(e) => setSearchData(e.target.value)}
                                      value={searchData}
                                    />
                                    <Button variant="outline-secondary" id="button-addon2">
                                      <BsSearch />
                                    </Button>
                                  </InputGroup>
                                </div>

                                {filteredStores && filteredStores.length > 0 && <div className="store__pickup__options__box">
                                  {
                                    filteredStores &&
                                    filteredStores.map((val, i) => {
                                      return (
                                        <React.Fragment key={val?.id}>
                                          <div className="store__pickup__itembox">
                                            <div className="store__pickup__select__area">
                                              <input
                                                type="radio"
                                                checked={
                                                  selectedStoreId === val?.id
                                                    ? true
                                                    : false
                                                }
                                                onChange={() =>
                                                  handleChangePickupStore(val?.id)
                                                }
                                              />
                                            </div>
                                            <div className="pickup__store__infobox">
                                              <p className="product__store__status">
                                                <strong>{val.name}</strong>
                                              </p>
                                              <div className="pickup__store__location">
                                                <span><b>{t("Address")} : </b>{`${val?.city} - ${val?.address}`}</span>
                                                {val?.phoneNumber &&
                                                  <p className="mb-0"><b>{t("Phone")} : </b><a className="text-decoration-none" href={`tel:${val?.phoneNumber}`} target="_" rel="noopener nofollow noreferrer" dir="ltr">{`${val?.phoneNumber}`}</a></p>
                                                }
                                              </div>
                                              {/* <div className="pickup__store__time"><img src="" /> <span>Saturday to Thursday 9:00am-11:00pm|Friday: 4:00pm-11:00pm</span></div> */}
                                              <Button
                                                onClick={() =>
                                                  handleClickOpeningHour(val)
                                                }
                                                className="store__opening__hours__btn"
                                                style={{
                                                  backgroundColor: "#dc3a1a",
                                                }}
                                              >
                                                {t("Opening_hours")}
                                              </Button>
                                            </div>
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                </div>
                                }
                                {storePickupError !== "" && (
                                  <p className="invalid__message">
                                    {storePickupError}
                                  </p>
                                )}
                                <div className="delivery__selection__block col-md-6 delivery__order_note">
                                  <Heading7
                                    text={t("Order Note")}
                                    marginBottom={10}
                                  />
                                  <textarea
                                    rows={4}
                                    className="form-control"
                                    id="shippingCustomMsg"
                                    name="shippingCustomMsg"
                                    value={shippingCustomMsg}
                                    onChange={(e) =>
                                      setShippingCustomMsg(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}

                        {(userloginData === true || getData?.user?.userLoggedIn === true) && customerDetails !== "" && preferenceTab !== "" && (
                          <div className="row delivery__selcetion__pickup__store">
                            <div className="continue__button__block">
                              <div></div>
                              <button
                                id="delivery-form"
                                onClick={
                                  () => {continueFromDelivery();}
                                  // { ...iconType, delivery: "done", payment: "inprogress" },
                                  // "payment"
                                }
                                className="continue___button"
                              // disabled={continueDeliveryLoading}
                              >
                                {/* {continueDeliveryLoading === true && (
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )} */}
                                {loadingStartEnd === true ? <Spinner /> : t("Continue")}
                              </button>
                            </div>
                          </div>
                        )}
                      </>
                      :
                      <div style={{ textAlign: 'center' }} className="my-3">
                        <Spinner type='dark' />
                      </div>
                    }
                  </>
                )}
              </div>
            </div>
            {/* package Summary */}
            <div className="col-md-12 col-xl-4  checkout__right__block">
              {typeof cartDetail === "object" &&
                typeof cartTotalData === "object" && (
                  <Order_Summary_New
                    cartDetail={cartDetail}
                    cartTotalData={cartTotalData}
                    loyaltyEarnType={loyaltyEarnType}
                    loyaltyEarnTypeId={loyaltyEarnTypeId}
                    loyaltyRadioName="loyaltyEarnTypeDesk"
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </>: null}
    {addressPopup === true && width > 992 && (
      <AddressPopupModal
        show={addressPopup}
        closeLoginPopup={closeLoginPopup}
        editAddressData={editAddressData}
        popupType={addressPopupType}
      />
    )}
  </>
  )
}

export default Delivery