import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import './../../SCSS/MostSharedComponents/ship__green__step__block.scss';

function ShippingGreenSteps({ orderDetails }) {
    const { t } = useTranslation();

    const getHistoryStatus = (status) => {
        let defaultStatus = false;
        let hisDate = "";
        let getHistory = orderDetails.status_histories.filter(val => val.status === status);
        if (getHistory.length > 0) {
            defaultStatus = true;
            hisDate = moment(getHistory[0].created_at).format('ddd, Do MMMM')
        }
        let returnData = { isActive: defaultStatus, historyDate: hisDate }
        return returnData;
    }

    const deliveryHistory = [
        {
            label: t("Ordered"),
            isActive: true,
            historyDate: moment(orderDetails.created_at).format('ddd, Do MMMM')
        },
        {
            label: t("Ready_to_Ship"),
            isActive: getHistoryStatus('shipped').isActive,
            historyDate: getHistoryStatus('shipped').historyDate
        },
        {
            label: t("Out_of_Delivery"),
            isActive: getHistoryStatus('ready_to_ship').isActive,
            historyDate: getHistoryStatus('ready_to_ship').historyDate
        },
        {
            label: t("Delivered"),
            isActive: getHistoryStatus('delivered').isActive,
            historyDate: getHistoryStatus('delivered').historyDate
        },
    ];

    return (
        <>
            <div className="ship__green__step__block">
                <ul className="d-flex">
                    {deliveryHistory && deliveryHistory.map((history, i) => {
                        return (
                            <React.Fragment key={history.label}>
                                <li className={`${history.isActive === true ? `active` : ``}`} key={history.label}>
                                    <label>{history.label}</label>
                                    <div className="circle"></div>
                                    <span className="ship__date">{history.historyDate}</span>
                                </li>
                            </React.Fragment>
                        )
                    })}
                </ul>
            </div>
        </>
    );
}
export default ShippingGreenSteps;