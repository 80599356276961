import React, { useRef } from "react";
// import { useImageHelper } from "../helpers/utils/useProductImageHelper";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { defaultImageHelper } from "../helpers/utils/defaultImageHelper";
import DefaultImage from "../../assets/Icon/default_image.png"

// const Placeholder = () => (
//   <img src="https://via.placeholder.com/200x200/ffffff/ffffff" alt="placeholder" />
// );

export default function LazyImage({ imgSrc, srcSetReSize, width, height, alt, className, style }) {
  const ref = useRef(null);

  // const imageUrl = useImageHelper(imgSrc);
  return (
    <React.Fragment>
      <img
        // src={imgSrc || DefaultImage}
        src={srcSetReSize ? srcSetReSize(imgSrc || DefaultImage)[0]?.img : imgSrc || DefaultImage}
        alt={alt ? alt : "Sony"}
        className={className}
        width={srcSetReSize ? srcSetReSize(imgSrc || DefaultImage)[0]?.width : width}
        height={height || 'auto'}
        style={style}
        onError={(e) => defaultImageHelper(e)}
      />
      {/* <LazyLoadImage
          // placeholder={<Placeholder />}
          ref={ref}
          src={srcSetReSize(imageUrl)[0]?.img}
          srcSet={srcSetReSize(imageUrl)[1]?.imgSrc.join(",")}
          alt={alt ? alt : "Sony"}
          className={className}
          width={width}
          height={height}
          effect="blur"
          // onClick={() => {
          //   if (onClickEvent) {
          //     redirectToPdpPage(product?.url_key, product?.sku)
          //   }
          // }}
          style={style}
        /> */}
    </React.Fragment >
  );
}