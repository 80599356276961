import { dataLayerPush } from './dataLayerPush';

export const mainEvents = {
  main_navigation: (tabSelected, eventScreen) => dataLayerPush({ event: 'main_navigation', tab_selected: tabSelected, screen_name: eventScreen }),
  menu_click: (mainMenu, subMenu1) => dataLayerPush({ event: 'menuClick', mainMenu, subMenu1 }),
  submenu_click: (mainMenu, subMenu1, subMenu2) => dataLayerPush({
    event: 'menuClick', mainMenu, subMenu1, subMenu2,
  }),
  category_click: (mainCategory, clickedCategory) => dataLayerPush({ event: 'buttonClick', mainCategory, clickedCategory }),
  searchStart: (screenName) => dataLayerPush({ event: 'search_start', screen_name: screenName }),
  searchCancel: (screenName) => dataLayerPush({ event: 'search_cancel', screen_name: screenName }),
  searchDeleteQuery: (screenName) => dataLayerPush({ event: 'search_delete_query', screen_name: screenName }),
  searchSuccessful: (screenName) => dataLayerPush({ event: 'search_successful', screen_name: screenName }),
  search: (term) => dataLayerPush({ event: 'search', search_term: term }),
  viewSearchResults: (term) => dataLayerPush({ event: 'view_search_results', search_term: term }),
  shareProduct: (productData, user, screenName) => {
    const data = {
      logged_in: !!user,
      guest_user: !user,
      sku: productData.sku,
      item_name: productData.name,
      item_id: productData.id,
      screen_name: screenName,
    };

    dataLayerPush({ event: 'share_product', ...data });
  },
};
