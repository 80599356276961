/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-danger */
import React from 'react';
import htmlParseHelper from '../helpers/utils/htmlParseHelper';
import "./../../SCSS/Cms/_richText.scss";

const CmsRichText = ({ data }) => {
    return (
        <div className='cms_rich_text richTextWrapper' key={data?.type}>{htmlParseHelper(data?.content)}</div>
    );
};

export default CmsRichText;
