import i18next from "i18next";
import { getHandshakeAfterLngChange } from "../../../services/auth";
import { setHeader } from "../../../services/config";
import { setHandsakeConfig } from "./handsakeConfig";

export const handleLanguageChangeHelper = (langCode, currentLanguageCode) => {
    i18next.changeLanguage(langCode);
    localStorage.setItem("i18next", langCode);

    if (langCode !== currentLanguageCode) {
        getHandshakeAfterLngChange(langCode, currentLanguageCode).then((res) => {
            setHeader("X-Access-Token", res?.data?.token);
            localStorage.setItem("handShakeToken", res?.data?.token);
            setHandsakeConfig(res?.data);
            // setTimeout(() => {
            //     window.location.reload();
            // }, 500);
        });
    }
}