import React from "react";
import { Helmet } from "react-helmet-async";

function ReviewTestFreaks({ reviewsDiv }) {

  const currentLang = localStorage.getItem("i18next") || "en";

  const elements = document.querySelectorAll('.testfreaks-videos');
  if (elements.length > 1) {
    for (let i = 1; i < elements.length; i++) {
      elements[i].remove();
    }
  }

  return (
    <>
      {reviewsDiv === true &&
        <div id="testfreaks-reviews"></div>
      }
      <Helmet>
        <script async type="text/javascript" src={`https://js.testfreaks.com/onpage/sonyworld.com.sa-${currentLang}/head.js`} />
      </Helmet>
    </>
  )
}

export default ReviewTestFreaks
