import React from "react";
import black_left_arrow from "./../assets/Icon/black_left_arrow.svg";
import black_right_arrow from "./../assets/Icon/black_right_arrow.svg";
import "./../SCSS/_newArrival.scss";
import ProductOne from "./ProductType/ProductOne";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function ProductContainer({
  productDetailPage,
  sectionTitle,
  carouselData,
  handleChangeCartPopup,
  cartIconOnly,
  outOfStockConfiguration,
  screenName,
  availablityData,
  sectionId,
  customerDetails
}) {

  const PrevArrow = ({ style, onClick, currentSlide }) => {
    return (
      <div className="arrow left__arrow" onClick={onClick}>
        <img src={black_left_arrow} alt="" />
      </div>
    );
  };

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow right__arrow" onClick={onClick}>
        <img src={black_right_arrow} alt="" />
      </div>
    );
  };

  // const currentLanguageAr = (localStorage.getItem("i18next") || "en") === "ar";

  var settings = {
    dots: false,
    infinite: carouselData?.length > 5 ? true : false,
    slidesToScroll: 4,
    variableWidth: true,
    swipeToSlide: false,
    speed: 200,
    // rtl: currentLanguageAr,
    arrows: carouselData?.length > 5 ? true : false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: carouselData?.length > 3 ? true : false,
          arrows: carouselData?.length > 3 ? true : false,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: carouselData?.length > 2 ? true : false,
          infinite: carouselData?.length > 2 ? true : false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: carouselData?.length > 1 ? true : false,
          infinite: carouselData?.length > 2 ? true : false,
        }
      }
    ]
  };

  return (
    <>
      {carouselData !== undefined && carouselData && carouselData.length > 0 && (
        <div
          className={
            !productDetailPage
              ? "container-fluid carousel__container"
              : "carousel__container"
          }
        >
          <div
            className={
              !productDetailPage ? "pc__block" : "less__width__carousel__block"
            }
          >
            <p className="section__title">{sectionTitle}</p>
            <div className="main__product__container__block">
              <Slider {...settings}>
                {carouselData &&
                  carouselData.map((product, productIndex) => {
                    return (
                      <ProductOne
                        handleChangeCartPopup={handleChangeCartPopup}
                        productDetailPage={
                          cartIconOnly === true ? true : productDetailPage
                        }
                        key={product.id}
                        product={product}
                        cartIconOnly={cartIconOnly}
                        outOfStockConfiguration={outOfStockConfiguration}
                        productIndex={productIndex}
                        screenName={screenName}
                        availablityData={availablityData}
                        sectionId={sectionId}
                        sectionTitle={sectionTitle}
                        customerDetails={customerDetails}
                      />
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProductContainer;
