import React, { useState} from 'react'
import { Link, useLocation } from "react-router-dom";
import { acceptOnlyString } from '../helpers/utils/regexp';
import { emailValidator, isMobileNumber } from '../helpers/utils/validators';
import { Saveps5elite } from '../../services/cart.service';
import PhoneInput from "react-phone-input-2";
import { Spinner } from "../helpers/utils/spinner";
import { impressionEvents } from "../helpers/utils/ga/impressionEvents";
import { Helmet } from "react-helmet-async";

function GamersEliteForm({ t, submitAction }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const params = queryParams?.get('param') ? JSON.parse(atob(queryParams?.get('param'))) : {};
  const T_REQ_NAME = t("Name_is_required");
  const T_REQ_MOBILE_NUMBER = t("Mobile_Number_is_required");
  const T_INVALID_MOBILE_NUMBER = t("Invalid_Mobile_Number");
  const T_REQ_EMAIL = t("Email_is_missing");
  const T_INVALID_EMAIL = t("Invalid_email_address_example");
  const T_REGION = t("Region is required");
  const T_SHOWROOM= t("Showroom is required");
  const T_TERMSCONDITON= t("Please select terms and conditions");

  const currentLanguageCode = localStorage.getItem("i18next") || "ar";

  const [loadingStartEnd, setLoadingStartEnd] = useState(false);
  const [isCheckBox, setIsCheckBox] = useState(true);


  const [isSuccessAlert, setIsSuccessAlert] = useState(false);
  
  const [addressError, setAddressError] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    region: "",
    company: "",
    readTermCondition: "",
  });

  const [userData, setUserData] = useState({
    name: params?.name || "",
    email: params?.email || "",
    mobileNumber: params?.phone || "",
    region: params?.region || "",
    company: params?.bought_from || "",
    readTermCondition: "",
  });
  const validateForm = (newErrObj, name, value) => {
    switch (name) {
      case "name":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "region":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REGION };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "company":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_SHOWROOM };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
        case "readTermCondition":
          if (value === "") {
            newErrObj = { ...newErrObj, [name]: T_TERMSCONDITON};
          } else {
            newErrObj = { ...newErrObj, [name]: "" };
          }
          break;
      case "mobileNumber":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_MOBILE_NUMBER };
        } else {
          let isValidNumber = isMobileNumber(value);
          if (isValidNumber === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_MOBILE_NUMBER };
          } else {
            newErrObj = { ...newErrObj, [name]: "" };
          }
        }
        break;
      case "email":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_EMAIL };
        } else {
          let isValidEmail = emailValidator(value);
          if (isValidEmail === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_EMAIL };
          } else {
            newErrObj = { ...newErrObj, [name]: "" };
          }
        }
        break;
      default:
        break;
    }
    return newErrObj;
  };
  const handleChange = (event, keyName) => {
    let value = event;
    let name = event;
    if (
      event?.target?.name === "name" 
    ) {
      value = acceptOnlyString(event.target.value, true);
      name = event.target.name;
    } else if (keyName === "mobileNumber") {
      value = event?.trim();
      name = "mobileNumber";
    } else {
      value = event.target.value;
      name = event.target.name;
    }
    setUserData({ ...userData, [name]: value });

    let manageErrMsg = validateForm(addressError, name, value);
    setAddressError(manageErrMsg);
  };
  const allFeildValidate = (validateFeild, allErrMsg) => {
    let checkValueStatus = [];
    let checkErrStatus = [];

    validateFeild &&
      validateFeild.map((val, i) => {
        let keyVal = userData[val];
        let errVal = addressError[val];

        allErrMsg = validateForm(allErrMsg, val, keyVal);
        if (keyVal !== "") {
          checkValueStatus.push("suc");
        }
        if (errVal === "") {
          checkErrStatus.push("err");
        }
      });

    let checkAddressStatus = false;
    if (
      checkValueStatus.length === validateFeild.length &&
      checkErrStatus.length === validateFeild.length
    ) {
      checkAddressStatus = true;
    }

    let returnData = {
      allErrMsg: allErrMsg,
      checkAddressStatus: checkAddressStatus,
    };

    return returnData;
  };
  const registerPs5 = async (e) => {
    setIsSuccessAlert(false);
    let validateFeild = [
      "name",
      "mobileNumber",
      "email",
      "region",
      "company",
      "readTermCondition",
    ];

    let formStatus = allFeildValidate(validateFeild, addressError);
    setAddressError(formStatus.allErrMsg);
    if(formStatus.checkAddressStatus === true){
        const requestObject = {
            name : userData.name,
            email : userData.email,
            phone : userData.mobileNumber,
            region : userData.region,
            boughtFrom: userData.company,
            customerId: params?.customer_id || "",
            registerType: params?.customer_id ? 'Online' : 'Offline',
        };
        setLoadingStartEnd(true);
        setIsCheckBox(false);
      
        let response;
        try {
          response = await Saveps5elite({ data: requestObject });
          
        } catch (error) {
          console.log("error", error, error?.response?.data?.message); 
          setAddressError({
              name: error?.errors?.name?.msg || error?.response?.data?.errors?.name?.msg,
              email: error?.errors?.email?.msg || error?.response?.data?.errors?.email?.msg,
              mobileNumber: error?.errors?.phone?.msg || error?.response?.data?.errors?.phone?.msg,
              region: error?.errors?.region?.msg || error?.response?.data?.errors?.region?.msg,
              company: error?.errors?.boughtFrom?.msg || error?.response?.data?.errors?.boughtFrom?.msg,
              readTermCondition: t(error?.response?.data?.message || error?.message),
          });
          setLoadingStartEnd(false);
          setIsCheckBox(true);
        }
        if (response) {
          if(response.success === true){
            setIsSuccessAlert(true);
            setTimeout(() => {
              setIsSuccessAlert(false);
            }, 6000);

             // here pending to send data to datalayer
            
            impressionEvents.impressionCustomerReferral(requestObject);
            setAddressError({
              name: "",
              email: "",
              mobileNumber: "",
              region: "",
              company: "",
              readTermCondition: "",
            });
            setUserData({
              name: "",
              email: "",
              mobileNumber: "966",
              region: "",
              company: "",
              readTermCondition: "",
            });
            var checkboxElement = document.getElementById('flexCheckDefault');
            if (checkboxElement) {
              checkboxElement.checked = false;
            }
            window.scrollTo(0, 0);
            submitAction();

          } else {
            setAddressError({
              name: response?.errors?.name?.msg,
              email: response?.errors?.email?.msg,
              mobileNumber: response?.errors?.phone?.msg,
              region: response?.errors?.region?.msg,
              company: response?.errors?.boughtFrom?.msg,
              readTermCondition: t(response?.message),
            });
            setLoadingStartEnd(false);
            setIsCheckBox(true);
          }
        }
        setLoadingStartEnd(false);
        setIsCheckBox(true);


    }
  };

  const hideAlert = () => {
    setIsSuccessAlert(false);

  }

    return (
      <>
          <Helmet>
           <link rel="stylesheet" href='/ps5-elite-gamers.css' />
          </Helmet>
        <div class="pb-5 pt-5">
            <div class="container container-remove-display">
              <div class="row justify-content-center">
              
                <div class="registration-box p-5">
                  {isSuccessAlert && (
                  <div className='col-12'>
                    <div class="alert alert-success alert-dismissible fade show" style={{position:'relative'}} role="alert">
                      {t("success_message")}
                        <button type="button" onClick={(e)=>hideAlert()} style={{position:"absolute",top:"0",right:"0",background:"none",border:"none"}} class="close" data-dismiss="alert" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                  </div>
                  )}
                  <h1 class="mt-4 mb-0">{t('Registration')}</h1>
                  <p class="para-text mt-0">{t('Please fill the below form for registration')}</p>

                  <form>
                    <div class="mb-3">
                      <input type="name" name='name' value={userData.name} onChange={(e) => handleChange(e)} class="form-control" id="exampleFormControlInput1" placeholder={t("Name")} />
                      {addressError.name && (
                        <p className="invalid__message">
                          {addressError.name}
                        </p>
                      )}
                    </div>
                    <div class="mb-3">
                      <input type="email" name='email' value={userData.email} onChange={(e) => handleChange(e)} class="form-control" id="exampleFormControlInput2" placeholder={t("email")} />
                      {addressError.email && (
                        <p className="invalid__message">
                          {addressError.email}
                        </p>
                      )}
                    </div>
                    <div class="mb-3">
                      <PhoneInput
                        inputProps={{
                          name: "mobileNumber",
                          required: true,
                          className: `profile__mobile__form__field form-control ${currentLanguageCode === "ar" ? 'custom-style-ps5-input' : ''} width-100`
                        }}
                        country="sa"
                        onlyCountries={["sa"]}
                        masks={{ sa: ".. ... ...." }}
                        countryCodeEditable={false}
                        disableDropdown={true}
                        value={userData.mobileNumber}
                        onChange={(e) => handleChange(e, "mobileNumber")}
                        className=""
                      />
                      <p className="invalid__message">
                          {addressError.mobileNumber}
                      </p>
                  
                    </div>
                    <div class="mb-3">
                      <select class="form-select" name="region" onChange={(e) => handleChange(e)} aria-label="Default select example">
                        <option value="" disabled selected={userData.region === "" ? true : false}>{t('Region')}</option>
                        <option value="Central Region" selected={userData.region === "Central Region" ? true : false}>{t('Central Region')}</option>
                        <option value="Eastern Region" selected={userData.region === "Eastern Region" ? true : false}>{t('Eastern Region')}</option>
                        <option value="Western Region" selected={userData.region === "Western Region" ? true : false}>{t('Western Region')}</option>
                      </select>
                      <p className="invalid__message">
                          {addressError.region}
                      </p>
                    </div>
                    <div class="mb-3">
                      <select class="form-select" name="company" onChange={(e) => handleChange(e)} aria-label="Default select example" aria-placeholder="PS5 Bought from">
                        <option value="" disabled selected={userData.company === "" ? true : false}>{t('PS5 Bought from')}</option>
                        <option value="Sony World Showroom" selected={userData.company === "Sony World Showroom" ? true : false}>{t('Sony World Showroom')}</option>
                        <option value="MeStores Websites" selected={userData.company === "MeStores Websites" ? true : false}>{t('MeStores Website')}</option>
                        <option value="Sony World Website" selected={userData.company === "Sony World Website" ? true : false}>{t('Sony World Website')}</option>
                        <option value="Others" selected={userData.company === "Others" ? true : false}>{t('Others')}</option>
                      </select>
                      <p className="invalid__message">
                          {addressError.company}
                      </p>
                    </div>
                    <div class="mb-3">
                      <div class="form-check">
                        <input class="form-check-input" style={{float:currentLanguageCode === 'ar' ? 'inherit' : 'left'}} onChange={(e) => handleChange(e)} name='readTermCondition' type="checkbox" value={userData.readTermCondition ? '' : '1'} id="flexCheckDefault" />
                        <label class="form-check-label para-text" for="flexCheckDefault">
                          <Link to="/cms/ps5/terms-and-conditions">{t('terms_conditions_full')}</Link>
                        </label>
                        <p className="invalid__message">
                          {addressError.readTermCondition}
                        </p>
                      </div>
                    </div>
                    <div class="mb-3 d-grid gap-2">
    
                      <button type="button" onClick={(e)=>registerPs5(e)} className= {`btn elite-btn ${isCheckBox === false ? "cursor_not_allowed" : ""}`}> {loadingStartEnd === true  ? <Spinner /> : t("Register")}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        </div>         
      </>
    )
  
}

export default GamersEliteForm;