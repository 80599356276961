import axios from 'axios';
import sha256 from 'crypto-js/sha256';

const {
    REACT_APP_REQUEST_PHRASE, REACT_APP_APPLE_PAY_ACCESS_CODE, REACT_APP_PROJECT_API_URL, REACT_APP_DOMAIN
} = process.env;

export const isApplePaySupported = async () => {
    if (window.ApplePaySession) {
        return true;
    } else {
        return false
    }
};

export const validateApplePaySession = (appleUrl, callback) => {
    axios.post(
        `${REACT_APP_PROJECT_API_URL}/V1/validateSession`,
        {
            appleUrl,
            domainName: REACT_APP_DOMAIN
        },
        {
            headers: { 'Access-Control-Allow-Origin': '*' },
        },
    )
        .then((response) => {
            callback(response?.data);
        })
        .catch((err) => {
            console.log(err.message, "err.message")
            throw Error(err.message);
        });
};

export const validateCheckoutApplePaySession = (appleUrl, callback) => {
    axios.post(
        `${REACT_APP_PROJECT_API_URL}/V1/validateCheckoutSession`,
        {
            appleUrl,
            domainName: REACT_APP_DOMAIN
        },
        {
            headers: { 'Access-Control-Allow-Origin': '*' },
        },
    )
        .then((response) => {
            callback(response.data);
        })
        .catch((err) => {
            console.log(err.message, "err.message")
            throw Error(err.message);
        });
};

export const getAppleRequest = (t, sku, checkout, config) => {
    const applePayRequest = {
        countryCode: 'SA',
        currencyCode: 'SAR',
        merchantCapabilities: [
            'supports3DS', 'supportsDebit', 'supportsCredit',
        ],
        shippingType: 'shipping',
        supportedNetworks: ['amex', 'masterCard', 'maestro', 'visa', 'mada'],
        lineItems: [
            {
                label: t('SUBTOTAL'),
                amount: sku ? 0 : config?.shop?.subtotal,
            },
            {
                label: t('TAX'),
                amount: sku ? 0 : config?.shop?.tax_price,
            },
        ],
        total: {
            label: t('SONY'),
            amount: sku ? 0 : config?.shop?.total,
            type: 'final',
        },
        // supportedCountries: ['SA', 'AE'],
    };

    if (!checkout) {
        applePayRequest.requiredShippingContactFields = ['name', 'postalAddress'];
        applePayRequest.requiredShippingContactFields = ['name', 'phone', 'email', 'postalAddress'];
    }

    return applePayRequest;
};

const getIp = async () => {
    const response = await fetch('https://geolocation-db.com/json/');
    const data = await response.json();
    return data?.IPv4;
};

const getSignature = (requestObject) => {
    let signatureString = '';
    signatureString = "$2y$10$zvMhf4TEc"+`access_code=${requestObject.access_code}amount=${requestObject.amount}apple_data=${requestObject.apple_data}apple_header={apple_transactionId=${requestObject.apple_header.apple_transactionId}, apple_ephemeralPublicKey=${requestObject.apple_header.apple_ephemeralPublicKey}, apple_publicKeyHash=${requestObject.apple_header.apple_publicKeyHash}}apple_paymentMethod={apple_displayName=${requestObject.apple_paymentMethod.apple_displayName}, apple_network=${requestObject.apple_paymentMethod.apple_network}, apple_type=${requestObject.apple_paymentMethod.apple_type}}apple_signature=${requestObject.apple_signature}command=${requestObject.command}currency=${requestObject.currency}customer_email=${requestObject.customer_email}customer_ip=${requestObject.customer_ip}`;
    if (typeof requestObject.device_fingerprint !== 'undefined') {
        signatureString += `device_fingerprint=${requestObject.device_fingerprint}`;
    }
    signatureString += `digital_wallet=${requestObject.digital_wallet}`;
    if (typeof requestObject.fraud_extra1 !== 'undefined') {
        signatureString += `fraud_extra1=${requestObject.fraud_extra1}`;
    }
    signatureString += `language=${requestObject.language}merchant_identifier=${requestObject.merchant_identifier}merchant_reference=${requestObject.merchant_reference}`+"$2y$10$zvMhf4TEc";

    const hash = sha256(signatureString).toString().toUpperCase();
    return hash;
};


export const performTransaction = async (appleToken, payfortInfo) => {
    const ip = await getIp();

    const payfortRequestObject = {
        digital_wallet: 'APPLE_PAY',
        command: payfortInfo?.params?.command,
        access_code: REACT_APP_APPLE_PAY_ACCESS_CODE,
        merchant_identifier: payfortInfo?.params?.merchant_identifier,
        merchant_reference: payfortInfo?.params?.merchant_reference,
        amount: payfortInfo?.params?.amount,
        currency: payfortInfo?.params?.currency,
        language: payfortInfo?.params?.language,
        customer_email: payfortInfo?.params?.customer_email,
        customer_ip: ip,
        apple_data: appleToken?.paymentData?.data,
        // device_fingerprint: finger_print,
        ...(payfortInfo.params?.device_fingerprint && { device_fingerprint: payfortInfo.params?.device_fingerprint }),
        ...(payfortInfo.params?.fraud_extra1 && { fraud_extra1: payfortInfo.params?.fraud_extra1 }),
        apple_signature: appleToken?.paymentData?.signature,
        apple_header: {
            apple_transactionId: appleToken?.paymentData?.header?.transactionId,
            apple_ephemeralPublicKey: appleToken?.paymentData?.header?.ephemeralPublicKey,
            apple_publicKeyHash: appleToken?.paymentData?.header?.publicKeyHash,
        },
        apple_paymentMethod: {
            apple_displayName: appleToken?.paymentMethod?.displayName,
            apple_network: appleToken?.paymentMethod?.network,
            apple_type: appleToken?.paymentMethod?.type,
        },
    };
    console.log(payfortRequestObject)
    const signature = getSignature(payfortRequestObject);
    payfortRequestObject.signature = signature;
    

    const { data } = await axios.post(
        `${REACT_APP_PROJECT_API_URL}/V1/pay`,
        {
            payfort_API: "https://paymentservices.payfort.com/FortAPI/paymentApi", // PROD URL "https://sbpaymentservices.payfort.com/FortAPI/paymentApi",
            payfortRequestObject
        },
        {
            headers: { 'Access-Control-Allow-Origin': '*' },
        },
    );

    return data;
};
