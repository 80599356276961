import axios from "axios";

export const getHomePageData = async (currentLanguageCode = 'ar') => {
  try {
    const store = currentLanguageCode === 'en' ? "sony_en" : "sony_ar";
    const homepageData = await axios.get(
      `${process.env.REACT_APP_PROJECT_API_URL}/V1/cms/sony-web-home-en-9696?store=${store}`
    );
    return homepageData.data;
  } catch (e) {
    throw new Error('Network response was not ok')
    // return { data: { title: "Sony Web Home - EN" } };
  }
};

export const promiseHelper = (promise) => {
  let responseData = {};
  promise.then((res) => (responseData = res.data));
  // console.log();
  return responseData;
};
