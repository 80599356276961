import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Heading3 from '../Font/Heading3';
import Text3 from '../Font/Text3';
import LoginWrapper from '../Login/LoginWrapper';
import { useNavigate } from 'react-router';
import useWindowDimensions from '../helpers/utils/useWindowDimensions';
import cancel_grey from "./../../assets/Icon/cancel_grey.svg";
import { FiAlertCircle } from "react-icons/fi";

function NotifyMeLoginModal({ title, modalShow, onHide, size, scrollable, handleChangeCartIconTotal }) {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [userLoginPopup, setUserLoginPopup] = useState(false);
  const [loginPopup, setLoginPopup] = useState(false);
  const [categoryPopup, setCategoryPopup] = useState(false);
  const [loginMode, setLoginMode] = useState("");
  const [loginWrapper, setLoginWrapper] = useState(false);

  useEffect(() => {
    // debugger
    function init() {
      const latestLoginWrapper = localStorage.getItem("loginWrapper");
      const latestLoginPopup = localStorage.getItem("loginPopup");
      setLoginWrapper(JSON.parse(latestLoginWrapper));
      setLoginPopup(JSON.parse(latestLoginPopup));
    }
    init();
  }, [
    JSON.parse(localStorage.getItem("loginWrapper")),
    JSON.parse(localStorage.getItem("loginPopup")),
    JSON.parse(localStorage.getItem("loginMode")),
  ]);

  const openLoginWrapper = (mode) => {
    onHide(true)
    window.scrollTo(1, 1);
    // if (width > 992) {
      // desktop
      setLoginPopup(userLoginPopup ? false : true);
      setCategoryPopup(false);
      setLoginMode(mode);
      setLoginWrapper(true);
      setUserLoginPopup(false);
      localStorage.setItem("loginMode", JSON.stringify(mode));
      localStorage.setItem("loginWrapper", JSON.stringify(true));
      localStorage.setItem("loginPopup", JSON.stringify(true));
    // } else {
    //   //mobile
    //   if (mode === "signup") {
    //     navigate("/mobile-signup");
    //   } else if (mode === "signin") {
    //     navigate("/mobile-signin");
    //   }
    // }
  };

  return (
    <>
      <Modal
        show={modalShow}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size={size}
        scrollable={scrollable}
        className='notify__modal'
      >
        <Modal.Header >
          <Modal.Title>{title}</Modal.Title>
          <img
            onClick={onHide}
            src={cancel_grey}
            alt="cancel"
            className="cancel__button"
          />
        </Modal.Header>
        <Modal.Body className='notity__login__modal'>
          <center>
            <FiAlertCircle className='notify__login__icon' />
            <Heading3 text={t("Login to use this Feature")} />
            <Text3 text={t("Please Login to get notified when products are available, Thank you")} />
          </center>

          <div className="d-grid gap-3 mt-3 notify__form__btnBox">
            <Button className='notify__add__btn notify__login' variant='link' size="lg" onClick={() => openLoginWrapper("signin")}>
              {t("Login")}
            </Button>
            <Button className='notify__close' variant='link' size="lg" onClick={onHide}>
              {t("Close")}
            </Button>
          </div>

        </Modal.Body>
      </Modal>
    </>

  );
}

export default NotifyMeLoginModal;
