import { dataLayerPush } from './dataLayerPush';
import { addToWishlistHandler, inputValidationHandler } from './handlers/checkoutEventsHandlers';
import { impressionEvents } from './impressionEvents'
export const checkoutEvents = {
  // beginCheckout: (actionField, products, totalData) => dataLayerPush({
  //   event: 'begin_checkout',
  //   ecommerce: {
  //     currency: totalData?.currencyCode || 'SAR',
  //     value:totalData?.value || null,
  //     coupon: actionField,
  //     items: products,
  //   },
  //   _clear: true
  // }),
  purchase: (actionField, products) => dataLayerPush({
    event: 'purchase',
    ecommerce: {
      transaction_id: actionField?.transaction_id,
      value: actionField?.revenue,
      tax: actionField?.tax,
      shipping: actionField?.shipping,
      currency: actionField?.currency,
      coupon: actionField?.coupon,
      items: products,
    },
    _clear: true
  }),
  addPaymentInfo: (actionField, products, totalData) => dataLayerPush({
    event: 'add_payment_info',
    ecommerce: {
      currency: totalData?.currencyCode || 'SAR',
      value: totalData?.value || null,
      payment_type: totalData?.payment_type,
      coupon: actionField,
      items: products,
    },
    _clear: true
  }),
  // addShippingInfo: (actionField, products, totalData) => dataLayerPush({
  //   event: 'add_shipping_info',
  //   ecommerce: {
  //     currency: totalData?.currencyCode || 'SAR',
  //     value: totalData?.value || null,
  //     coupon: actionField,
  //     items: products,
  //   },
  //   _clear: true
  // }),
  addShippingInfo: (actionField, products, totalData) => console.log('triggered'),
  otpVerificationFailed: (phone, lokalise_error_key, error_code) => dataLayerPush({
    event: 'verify_otp_failed', phone, lokalise_error_key, error_code,
  }),
  addToWishlist: (product, screenName) => impressionEvents.impressionAddToWishList(product, screenName),
  removeFromWishlist: (product, screenName) => impressionEvents.impressionRemoveFromWishlist(product, screenName),
  paymentMethodCreditCard: (new_card_saved, selected_card_status) => dataLayerPush({ event: 'payment_method_creditcard', new_card_saved, selected_card_status }),
  paymentFailed: (order_id, payment_method) => dataLayerPush({ event: 'payment_failed', order_id, payment_method }),
  paymentInputControl: (data) => inputValidationHandler(data),
  billingInputControl: (data) => inputValidationHandler(data),
  shippingInputControl: (data) => inputValidationHandler(data),
  badelSubmitted: () => dataLayerPush({ event: 'badel_submitted' }),
  badelShown: () => dataLayerPush({ event: 'badel_shown' }),
  orderApiFailed: (order_id, payment_method) => dataLayerPush({ event: 'order_api_failed', order_id, payment_method }),
  orderApiSuccess: (order_id, payment_method) => dataLayerPush({ event: 'order_api_success', order_id, payment_method }),
  cancelApiFailed: (order_id, payment_method) => dataLayerPush({ event: 'cancel_api_failed', order_id, payment_method }),
  cancelApiSuccess: (order_id, payment_method) => dataLayerPush({ event: 'cancel_api_success', order_id, payment_method }),
  paymentNetworkSpeed: (params) => dataLayerPush(params),
};
