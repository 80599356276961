import React from "react";
import Socialgoogle from "./../../assets/Icon/social-google.png";
import Socialapple from "./../../assets/Icon/social-apple.png";
import Socialfacebook from "./../../assets/Icon/social-facebook.png";
import "./../../SCSS/Common/_socialLinkBox.scss";

function SocialLinkBox({ t }) {

    return (
        <div className="sign_in_up_socialBox">
            <h3>{t("Social Login")}</h3>
            <ul>
                <li><button><img src={Socialgoogle} alt="" /></button></li>
                <li><button><img src={Socialapple} alt="" /></button></li>
                <li><button><img src={Socialfacebook} alt="" /></button></li>
            </ul>
        </div>
    );
}
export default SocialLinkBox;