import React from 'react'
import Banner from '../Components/Banner'

function Services() {
  return (
      <>
    <Banner/>
      </>
  )
}

export default Services