import React from 'react'
import { useTranslation } from 'react-i18next'

function Login_Page() {
  const { t } = useTranslation()

  return (
    <div>{t("Login_Page")}</div>
  )
}

export default Login_Page