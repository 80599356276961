import axios from "axios";
import { customerDetailsSuccess } from "../../../services/customer/customer";
import { getHandshake } from "../../../services/auth";
import { setHeader } from "../../../services/config";
import { loadWishlistData } from "../../../redux/appAction";
import { impressionEvents } from "./ga/impressionEvents";

const API_URL = process.env.REACT_APP_PROJECT_API_URL; // base url
const authToken = localStorage.getItem("auth-token"); // language code

const apiHelper = async (api, method, data, headers) => {

  let url = `${API_URL}${api}`;

  // headers['Authorization'] = `Bearer ${authToken}`;
  // headers['Content-Type'] = 'multipart/form-data';

  return await new Promise((resolve, reject) => {
    axios({
      method: method,
      url: url,
      data: data,
      // headers: headers
    }).catch(err => {
      // console.log('err', err);
      impressionEvents.impressionGlobalError(err?.response?.data?.message,err?.response?.config?.url,'UI Error Notification' );
      // if (err.response.status === 401 && err.response.data.message === "Unauthorized access") {
      //   customerDetailsSuccess("");
      //   localStorage.removeItem("custDetails");
      //   localStorage.removeItem("handShakeToken");
      //   setHeader("X-Access-Token", "");
      //   getHandshake().then((res) => {
      //     setHeader("X-Access-Token", res.data.token);
      //     localStorage.setItem("handShakeToken", res.data.token);
      //     loadWishlistData();
      //   });
      // }
      reject(err);
    }).then(res => {
      resolve(res)
    })
  })
}

export default apiHelper;