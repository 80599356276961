import React, { useState, useEffect } from "react";
import "./../../SCSS/MostSharedComponents/_availableOffers.scss";
import TamaraProductWidget from "../../Pages/TamaraProductWidget";
import { getTamaraMinMaxLimit } from "../helpers/utils/handsakeConfig";
import TabbyWidget from "./TabbyWidget";

function AvailableOffersMobile({ product }) {
  let price =
  product?.discounted_price_rounded === null
  ? product?.price_rounded
  : product?.discounted_price_rounded;
  
  let getTamaraCheck3Month = getTamaraMinMaxLimit(price, 3);
  let currency = product?.currency;

  return (
    <div className="product__avilable__offers__block product__common__main__block">
      <div className="product__offer__block">
        {product?.options &&
          product?.options.filter(
            (val) =>
              val.code === "eligible_for_tamara" && val.visibility === true
          ).length >
            0 ===
            true &&
          getTamaraCheck3Month === true && (
            <TamaraProductWidget price={price} currency={currency} months={3} />
          )}
      </div>
      <div className="product__offer__block">
        {product?.options &&
          product?.options.filter(
            (val) =>
              val.code === "eligible_for_tabby" &&
              val.visibility === true
          ).length >
          0 && (
            <TabbyWidget
              price={price}
              currency={currency}
              widgetType="TabbyPromo"
            />
          )}
      </div>
    </div>
  );
}

export default AvailableOffersMobile;
