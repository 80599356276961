export const getCoupanPercentage = (product) => {

    if(product){
    const price = product?.price_rounded || product?.product?.price_rounded;
    const discount = product?.discounted_price_rounded || product?.product?.discounted_price_rounded;
 
    if(!discount){
      
       return "0%";
    }else{
       let dicountValue = (parseInt(price) - parseInt(discount)) / parseInt(price);
       dicountValue = dicountValue * 100;
       dicountValue = dicountValue.toFixed();
       dicountValue = dicountValue + "% discount";
       
       return dicountValue;
    }
    }
   
 };