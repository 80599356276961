import React from "react";
import { useTranslation } from "react-i18next";
import Text4 from "../Font/Text4";
import Heading7 from "../Font/Heading7";
import moment from "moment";

function DeliveryTimeArea({ additional_estimated_day }) {

  const { t } = useTranslation();

  const getSpecificDayDate = (day) => {
    const tomorrow = moment().add(day, 'days');
    return tomorrow.format('DD MMMM, ');
  }

  return (
    <>
      <Text4 text={`${t("Free delivery by")}`} span={true} />
      &nbsp;
      {additional_estimated_day === 1 ?
        (
          <Heading7 text={`${t('Tomorrow')},`} span={true} />
        ) : additional_estimated_day === 2 ? (
          <Heading7 text={`${t('Day_After_Tomorrow')},`} span={true} />
        ) :
          ""
      }
      <Heading7 text={`${getSpecificDayDate(additional_estimated_day)} 7:00 am - 9:00 pm`} marginBottom={0} />
    </>
  );
}

export default DeliveryTimeArea;
