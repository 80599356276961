import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import NotifyMeLoginModal from "../Modal/NotifyMeLoginModal";
import NotifyMeModal from "../Modal/NotifyMeModal";
import { getCustomerLoginDetails } from "../helpers/utils/getCustomerLoginDetails";
import NotifyMeSuccessModal from "../Modal/NotifyMeSuccessModal";
import "./../../SCSS/MostSharedComponents/_notifyModals.scss";
import notifyBell from "./../../assets/Icon/notify-bell.png";

function NotifyMeBtn({ product }) {

  const customerData = getCustomerLoginDetails();

  const { t } = useTranslation();
  const [firstModalShow, setFirstModalShow] = useState(false);
  const [secondModalShow, setSecondModalShow] = useState(false);
  const [thirdModalShow, setThirdModalShow] = useState(false);

  const openNotifyModal = () => {
    // window.scrollTo(0, 0);
    if (customerData) {
      if (customerData.email) {
        setSecondModalShow(true)
      }
    } else {
      setFirstModalShow(true)
    }
    // setFirstModalShow(true)
    // setSecondModalShow(true)
    // setThirdModalShow(true)
  }

  const allBtnOpenClose = (type, status) => {
    // window.scrollTo(0, 0);
    if (type === 1) {
      setFirstModalShow(status)
    } else if (type === 2) {
      setSecondModalShow(status)
    } else if (type === 3) {
      setThirdModalShow(status)
    }
  }

  return (
    <>
      <NotifyMeLoginModal
        modalShow={firstModalShow}
        onHide={() => allBtnOpenClose(1, false)}
        size="lg"
      />
      <NotifyMeModal
        modalShow={secondModalShow}
        onHide={() => allBtnOpenClose(2, false)}
        size="lg"
        product={product}
        openSuccessModal={allBtnOpenClose}
      />
      <NotifyMeSuccessModal
        modalShow={thirdModalShow}
        onHide={() => allBtnOpenClose(3, false)}
        size="lg"
      />
      <div className="notifyMe__button" onClick={(e) => openNotifyModal()}>
        <img src={notifyBell} />{t("Notify Me When Re-Stocked")}
      </div>
    </>
  );
}

export default NotifyMeBtn;
