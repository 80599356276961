import React, { useState, useEffect } from "react";
import "./../../SCSS/_paymentStatus.scss";
import "./../../SCSS/_confirmOrderPage.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmTabbyPayment } from "../../services/cart.service";
import TransactionFailed from "../TransactionFailed";
import ProcessingPayment from "../ProcessingPayment";
import { useDispatch } from "react-redux";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const TabbySuccess = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const dispatch = useDispatch();
  const payment_id = query.get("payment_id");

  const [paymentError, setPaymentError] = useState(false);
  const [checkoutApiOrderId, setCheckoutApiOrderId] = useState(null);

  useEffect(() => {
    const lod = localStorage.getItem("lod");
    if (payment_id) {
      setCheckoutApiOrderId(lod);
      localStorage.removeItem("contact_prefill");
      localStorage.removeItem("method");
      localStorage.removeItem("lod");

      const confirm = async () => {
        try {
          let params = {
            paymentId: payment_id,
          };
          await confirmTabbyPayment(params)
            .then((res) => {
              const { orderId } = res?.data;
              if (orderId === false) {
                setPaymentError(true);
                return false;
              } else {
                navigate("/checkout/success");
              }
            })
            .catch((error) => {
              setPaymentError(true);
            });
        } catch (error) {
          setPaymentError(true);
        }
      };

      confirm();
    } else {
      navigate("/");
    }
  }, []);

  if (paymentError) {
    if (checkoutApiOrderId) {
      navigate("/checkout/failure");
    } else {
      return <TransactionFailed />;
    }
  }

  return <ProcessingPayment dispatch={dispatch}/>;
};

export default TabbySuccess;
