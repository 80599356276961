import MobilePayment from '../../Pages/Checkout/MobilePayment';
import BreadCrumbs from '../BreadCrumbs';
import CheckoutPaymentWrapper from './CheckoutPaymentWrapper';

const CheckoutPayment = ({
  t,
  setRedirectPageUrl,
  setPayfortData,
  setPending,
  showApplePay,
  setCartTotalData,
  hideSection,
  customerDetails,
  dispatch,
  services,
  currentLang,
  pending,
  width,
  authRandom,
  BASE_PAYMENT_REDIRECT_URL,
  cartTotalData,
  ecom,
  mokafaApplied,
  cartDetail,
  checkVirtualItemsInCart,
  currentLanguageCode,
  loyaltyEarnTypeId,
  tamara3MonthStatus,
  tamara6MonthStatus,
  cartData,
  custEmailAddress,
  isAppleDevice,
  allStoreConfig,
}) => {

  if (width <= 992 && !authRandom) {
    return (
    <MobilePayment
      BASE_PAYMENT_REDIRECT_URL={BASE_PAYMENT_REDIRECT_URL}
      cartTotalData={cartTotalData}
      ecom={ecom}
      mokafaApplied={mokafaApplied}
      cartDetail={cartDetail}
      checkVirtualItemsInCart={checkVirtualItemsInCart}
      currentLanguageCode={currentLanguageCode}
      loyaltyEarnTypeId={loyaltyEarnTypeId}
      tamara3MonthStatus={tamara3MonthStatus}
      tamara6MonthStatus={tamara6MonthStatus}
      cartData={cartData}
      custEmailAddress={custEmailAddress}
      isAppleDevice={isAppleDevice}
      allStoreConfig={allStoreConfig}
    />
  )
}

  return (
    <>
      {width > 992 && !authRandom && (
        <>
        <div className="d-lg-block d-none">
          <BreadCrumbs
            back="/cart"
            firstBread="/cart"
            firstBreadTitle={t('Shopping_cart')}
            secondBread="/checkout/delivery"
            secondBreadTitle={t('DELIVERY')}
            thirdBread={'/checkout/payment'}
            thirdBreadTitle={t('PAYMENT')}
            highlight="thirdBreadTitle"
          />
        </div>
        <CheckoutPaymentWrapper
          isAppleDevice={isAppleDevice}
          tamara6MonthStatus={tamara6MonthStatus}
          tamara3MonthStatus={tamara3MonthStatus}
          checkVirtualItemsInCart={checkVirtualItemsInCart}
          currentLanguageCode={currentLanguageCode}
          setRedirectPageUrl={setRedirectPageUrl}
          setPayfortData={setPayfortData}
          setPending={setPending}
          showApplePay={showApplePay}
          pending={pending}
          loyaltyEarnTypeId={loyaltyEarnTypeId}
          cartDetail={cartDetail}
          mokafaApplied={mokafaApplied}
          BASE_PAYMENT_REDIRECT_URL={BASE_PAYMENT_REDIRECT_URL}
          ecom={ecom}
          cartTotalData={cartTotalData}
          setCartTotalData={setCartTotalData}
          custEmailAddress={custEmailAddress}
          hideSection={hideSection}
          customerDetails={customerDetails}
          dispatch={dispatch}
          services={services}
          t={t}
          currentLang={currentLang}
          cartData={cartData}
        />
        </>
      )}
    </>
  );
};

export default CheckoutPayment;
