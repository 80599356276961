import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Heading7 from "../Font/Heading7";
import { phoneNumberRegExpSa } from "../helpers/utils/regexp";
import hide_password from "./../../assets/Icon/hide_password.svg";
import see_password from "./../../assets/Icon/see_password.svg";
import "./../../SCSS/Login/_signupModel.scss";
import * as services from "./../../services/services";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { Spinner } from "../helpers/utils/spinner";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function SignUpModel2({ handleChangePopupMode, closeLoginPopup }) {
  
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const T_WEAK_PASSWORD = t(
    "Password_must_contain_1_uppercase_1_lowercase_1_number_and_at_least_8_characters_Do_not_add_more_than_5_consecutive_characters_123456_qwerty"
  );
  
  const { loadingStartEnd } = useSelector((state) => state.commonReducer);
  const { customerSignUpOtpData } = useSelector((state) => state.customerReducer);
  const [lastCheckedUsername, setLastCheckedUsername] = useState('');
  const [lastCheckedPass, setLastCheckedPass] = useState('');
  const [isPassword, setIsPassword] = useState(true);
  const [isConfirmPassword, setIsConfirmPassword] = useState(true);
  const [usernameError, setUsernameError] = useState(null);
  const [passError, setPassError] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [passTimeoutId, setPassTimeoutId] = useState(null);

  const togglePassword = () => setIsPassword(!isPassword);
  const toggleConfirmPassword = () => setIsConfirmPassword(!isConfirmPassword);

  useEffect(() => {
    if (customerSignUpOtpData?.mobileNumber) {
      handleChangePopupMode("otp");
    }
  }, [customerSignUpOtpData,handleChangePopupMode]);

  const checkUsername = (value) => {
    return new Promise((resolve) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      const newTimeoutId = setTimeout(async () => {
        const currentUsername = value;
        if (currentUsername !== lastCheckedUsername) {
          setLastCheckedUsername(currentUsername);
          let usernameAvailable = await services.isUsernameAvailable({ username: currentUsername });
          if (usernameAvailable.success !== true) {
            setUsernameError(t(usernameAvailable.message));
            resolve(t(usernameAvailable.message));
          } else {
            setUsernameError(null);
            resolve(null);
          }
        } else {
          resolve(usernameError);
        }
      }, 600);
  
      setTimeoutId(newTimeoutId);
    });
  };

  const checkPass = (value) => {   
    return new Promise((resolve) => {
      if (passTimeoutId) {
        clearTimeout(passTimeoutId);
      }
      const newTimeoutId = setTimeout(async () => {
        const currentPass = value;
        if (currentPass !== lastCheckedPass) {
          setLastCheckedPass(currentPass);
          let pwdStrength = await services.checkPasswordStrength({ username: '', password: currentPass });
          if (!(pwdStrength?.strength === "fair" || pwdStrength?.strength === "strong")) {
            setPassError(T_WEAK_PASSWORD);
            resolve(T_WEAK_PASSWORD);
          } else {
            setPassError(null);
            resolve(null);
          }
        } else {
          resolve(passError);
        }
      }, 600);
      setPassTimeoutId(newTimeoutId);
    });
  };


  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t("Username_is_required")).test('isUsernameAvailable', t('Usernameadsds is not available'), async (value,testContext) => {
      const error = await checkUsername(value);
      if (error) {
        return testContext.createError({ message: error });
      }
      return true;
    }),
    email: Yup.string().email(t("Invalid_email_address_example")).required(t("Email_is_missing")),
    password: Yup.string()
      .required(t("Password_is_missing"))
      .test('isPassOK', T_WEAK_PASSWORD, async (value,testContext) => {
        const error = await checkPass(value);
        if (error) {
          return testContext.createError({ message: error });
        }
        return true;
      }),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], t("Password_not_matched")).required(t("Confirm_Password_is_missing")),
    mobileNumber:Yup.string().required(t("Mobile Number is missing")).matches(phoneNumberRegExpSa,t("Invalid_Mobile_Number")),
    terms: Yup.bool().oneOf([true], t('Accept Terms & Conditions is required')),
  });


  const onSignUp = (values) => {
      let params = {
        firstName: "",
        lastName: "",
        email: values.email.trim(),
        password: values.password.trim(),
        username: values.username.trim(),
        phone_number: values.mobileNumber.trim(), 
      };
      dispatch(services.customerSignUp(params));
  };

  return (
    <div className="signupModel__container">
      <Formik
       initialValues={{ username: '', email: '', password: '', confirmPassword: '', registerPolicy: true, mobileNumber: ''  }}
       validationSchema={validationSchema}
       onSubmit={(values, { setSubmitting }) => {
        onSignUp(values);
        setSubmitting(false);
      }}
     >
      {({ isSubmitting, isValid, setFieldValue, values, errors })  => (
        <Form >
        <div className="signupModel__content">  
          <div className="main__form__field__block">
            <Field type="email" name="email">
            {({ field }) => (
              <div className="field__itembox">
                <input
                  type="text"            
                  className={`form__field ${values.email === "" ? "" : "focus_in"} `}
                  {...field}
                />
                <div className="field__labelbox">
                  <Heading7 text={t("Email_Address")} marginBottom={0} />
                </div>
              </div>
              )}
            </Field> 
              <ErrorMessage name="email" component="div" className="invalid__message"/>
          </div>
          <div className="main__form__field__block">
            <div className="field__itembox mobileNumber">
              <PhoneInput
                name
                inputProps={{
                  name: "mobileNumber",
                  required: true,
                  className: "profile__mobile__form__field form__field focus_in",
                }}
                country="sa"
                onlyCountries={["sa"]}
                masks={{ sa: ".. ... ...." }}
                countryCodeEditable={false}
                disableDropdown={true}
                value={values.mobileNumber}
                onChange={(value) => setFieldValue("mobileNumber", value)}
                className=""
              />
              <div className="field__labelbox">
                <Heading7 text={t("Mobile_Number")} marginBottom={0} className="invalid__message"/>
              </div>
            </div>
            {errors.mobileNumber && (
              <p className="invalid__message">{errors.mobileNumber}</p>
            )}
          </div>
          <div className="main__form__field__block">
            <Field type="text" name="username">
              {({ field }) => (
                <div className="field__itembox">
                  <input
                    autoComplete="username"
                    className={`form__field ${values.username === "" ? "" : "focus_in"} `}
                    type="text"             
                    {...field}
                    />
                  <div className="field__labelbox">
                    <Heading7 text={t("Username")} marginBottom={0} />
                    {/* <sup className="inputs_validation">*</sup> */}
                  </div>
              </div>
              )}
            </Field>
            <ErrorMessage name="username" component="div" className="invalid__message"/>
          </div>
          <div className="main__form__field__block">
            <Field type={isPassword ? "password" : "text"} name="password" >
            {({ field }) => (
              <div className="field__itembox">
                <input
                  type={isPassword ? "password" : "text"} 
                  className={`form__field sentry-mask ${values.password === "" ? "" : "focus_in"} `}
                  autoComplete="new-password"
                  {...field}
                />
                <a onClick={() => togglePassword()} >
                  {isPassword ? (
                    <img src={see_password} alt="" />
                  ) : (
                    <img src={hide_password} alt="" />
                  )}
                </a>
                <div className="field__labelbox">
                  <Heading7 text={t("Password")} marginBottom={0} />
                  {/* <sup className="inputs_validation">*</sup> */}
                </div>
              </div>
            )}
            </Field>
            <ErrorMessage name="password" component="div" className="invalid__message"/>
          </div>
          <div className="main__form__field__block">
            <Field type={isConfirmPassword ? "password" : "text"} name="confirmPassword" >
            {({ field }) => (
              <div className="field__itembox">
                <input
                  type={isConfirmPassword ? "password" : "text"}
                  className={`form__field sentry-mask ${values.confirmPassword === "" ? "" : "focus_in"} `}
                  autoComplete="new-password"
                  {...field}
                />
                <a onClick={() => toggleConfirmPassword()}>
                  {isConfirmPassword ? (
                    <img src={see_password} alt="" />
                  ) : (
                    <img src={hide_password} alt="" />
                  )}
                </a>
                <div className="field__labelbox">
                  <Heading7 text={t("Confirm_Password")} marginBottom={0} />
                  {/* <sup className="inputs_validation">*</sup> */}
                </div>
              </div>
            )}
            </Field>
            <ErrorMessage name="confirmPassword" component="div" className="invalid__message"/>
          </div>
          <div className="main__policy__check__block">
              <Field type="checkbox" name="registerPolicy" >
              {({ field }) => (
              <>
                <div className="policy__check__block">
                  <input type="checkbox" checked={values.terms}  className="policy_check__icon" {...field}/>
                </div>
                <p className="signUP__policy__label">
                  {t("I_agree_to_our")}
                  <Link 
                  className="policy__link"
                  to="/cms/terms-and-conditions"
                  onClick={() => {
                    closeLoginPopup()
                  }}>
                    {" "}
                    {t("Terms_and_Conditions")}{" "}
                  </Link>
                  {t("&")}
                  <Link className="policy__link" to="/cms/privacy-policy"
                  onClick={() => {
                    closeLoginPopup()
                  }}>
                    {" "}
                    {t("Privacy_Policy")}{" "}
                  </Link>
                </p>
              </>            
              )}
              </Field>
          </div>
          <button type="submit" disabled={!values.registerPolicy || !isValid || isSubmitting}
            className={`signup__button ${!values.registerPolicy || !isValid || isSubmitting ? "cursor_not_allowed" : ""
              }`}
          >
            {loadingStartEnd === true ? <Spinner /> : t("Register")}
          </button>
          {location.pathname === "/checkout" && (
            <React.Fragment>
              <div className="signup__or__block">
                <div className="signup__or__text__block">
                  <p className="signup__or__text">{t("OR")}</p>
                </div>
                <div className="signup__mid__line"></div>
              </div>

              {location.pathname === "/checkout" && (
                <button onClick={() => closeLoginPopup()} className="getOtp__button">
                  {t("Continue_As_Guest")}
                </button>
              )}
            </React.Fragment>
          )}
        </div>
         </Form>
       )}
     </Formik>
    </div>
  );
}

export default SignUpModel2;
