import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import empty_favourite from "./../../assets/Icon/empty_favourite.svg";
import fulfill_favourite from "./../../assets/Icon/fulfill_favourite.svg";
import "./../../SCSS/ProductType/_productTwo.scss";
import Text4 from "./../Font/Text4";
import OldPrice from "./../Font/OldPrice";
import Price from "./../Font/Price";
import RatingBlock from "../MostSharedComponent/RatingBlock";
import { Link } from "react-router-dom";
import { addToCart } from "./../../services/cart.service";
import {
  loadAddToWishlist,
  loadCartData,
  loadDeleteFromWishlist,
  loadWishlistData,
} from "./../../redux/appAction";
import * as services from "./../../services/services";
import {
  selectedCompareProductStatus,
} from "../helpers/utils/compareProductsFilter";
import { useTranslation } from "react-i18next";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import { checkWishList } from "../helpers/utils/wishListHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { checkoutEvents } from "../helpers/utils/ga/checkoutEvents";
import AddToCartNotify from "../Buttons/AddToCartNotify";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";

function ProductTwo({
  productDetailPage,
  product,
  handleChangeCartPopup,
  updateCompareProduct,
  outOfStockConfiguration,
  productIndex,
  screenName
}) {

  const { setCartErr } = useCartErrorMessage();
  const [rating, setRating] = useState(0);
  const [sizeButtonIndex, setSizeButtonIndex] = useState(0);

  const [productWarrentyBlock, setProductWarrentyBlock] = useState({
    warrantyText: "1 Year Warranty on Product",
    size: [
      {
        id: 1,
        cm: 139,
        inch: 55,
      },
      {
        id: 2,
        cm: 164,
        inch: 65,
      },
      {
        id: 3,
        cm: 195,
        inch: 77,
      },
    ],
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getCompareProductList = useSelector(
    (state) => state.appData.getCompareProductList
  );

  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [count, setCount] = useState(0);
  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);

  const wishlistData = useSelector((state) => state.appData.wishlistData);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, product?.sku));
  }, []);

  const handleFavourite = () => {
    if (isFavourite) {
      setIsFavourite(false);
      setCount(count + 1);
    } else {
      setIsFavourite(true);
      setCount(count + 1);
    }
  };
  useEffect(() => {
    const data = {
      items: [product.sku],
    };
    if (isFavourite) {
      if (count > 0) {
        const addToWishlistData = dispatch(loadAddToWishlist(data,t)).then(
          (res) => {
            checkoutEvents.addToWishlist(product, screenName);
            dispatch(loadWishlistData());
          }
        );
      }
    } else {
      if (count > 0) {
        if (!isFavourite) {
          checkoutEvents.removeFromWishlist(product, screenName);
          removeFromWL(product.sku);
        }
      }
    }
  }, [isFavourite]);

  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };
    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data,t)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };

  const handleRating = (score) => {
    setRating(score);
  };

  const sizeButtonClick = (sizeIndex, cm, inch) => {
    setSizeButtonIndex(sizeIndex);
  };

  const AddProductToCart = (sku, id) => {
    setLoaderOn(true);

    const data = {
      items: [
        {
          sku: sku,
          qty: 1,
          id:id
        },
      ],
    };

    addToCart(data)
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id, screenName)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
      }).finally(() => {
        setLoaderOn(false);
      });
  };

  return (
    <div
      to={productUrlHelper(product?.english_name, product?.sku)}
      key={product.id}
      className="productTwo__block"
    >
      <div className="productTwo__header__block">
        <div className="productTwo__quality__favourite__block">
          {/* <img
            src={productTwo_quality_icon}
            alt=""
            className="productTwo__quality__icon"
          /> */}
          <img
            onMouseEnter={() => setIsFavouriteHover(true)}
            onClick={handleFavourite}
            onMouseLeave={() => setIsFavouriteHover(false)}
            className={
              !isFavourite
                ? "productTwo__favourite__icon"
                : "productTwo__favourite__icon__disable"
            }
            src={isFavouriteHover ? fulfill_favourite : empty_favourite}
            alt=""
          />
          <img
            onClick={handleFavourite}
            className={
              isFavourite
                ? "productTwo__favourite__icon"
                : "productTwo__favourite__icon__disable"
            }
            src={fulfill_favourite}
            alt=""
          />
        </div>
        <Link to={productUrlHelper(product?.english_name, product?.sku)}>
          <div className="productTwo__image__block">
            <img src={product.baseImage} alt="" className="productTwo__image" />
          </div>
        </Link>
        <div className="productTwo__compare__block">
          <input
            type="checkbox"
            className="productTwo__compare__input__check"
            name="compare"
            checked={selectedCompareProductStatus(product.id)}
            onChange={() => updateCompareProduct(true, product.originalData)}
          />
          <p className="productTwo__compare__text">{t("Select_to_Compare")}</p>
        </div>
      </div>
      <Link
        to={productUrlHelper(product?.english_name, product?.sku)}
        className="productTwo__name"
      >
        <p className="productTwo__name">{titleChange(product.name)}</p>
      </Link>
      <Text4 text="Z8H SERIES" color="#808080" marginBottom={10} />
      <div className="rating__block">
        <RatingBlock rating={4.5} totalRatings={4124} />
      </div>
      <p className="productTwo__series">{t("Model_and_Price")}</p>
      <div className="productTwo__size__button__block">
        {productWarrentyBlock.size.map((size, sizeIndex) => {
          return (
            <button
              key={size.id}
              onClick={() => sizeButtonClick(sizeIndex, size.cm, size.inch)}
              className={
                sizeButtonIndex === sizeIndex
                  ? "productTwo__size__button__active"
                  : "productTwo__size__button"
              }
            >
              {/* {`${size.cm} cm (${size.inch})`} */}
              {`${size.inch}"`}
            </button>
          );
        })}
      </div>
      <div className="prize__block">
        <OldPrice
          oldPrice={product.price_rounded}
          price={
            product.discounted_price_rounded === null
              ? product.price_rounded
              : product.discounted_price_rounded
          }
          size="text4"
          color="#c8c8c8"
          marginBottom={10}
          // lineThrough={true}
          span={true}
          currency={product?.currency}
        />
        <Price
          price={
            product.discounted_price_rounded === null
              ? product.price_rounded
              : product.discounted_price_rounded
          }
          marginLeft={5}
          marginBottom={10}
          size="heading7"
          span={true}
          currency={product?.currency}
        />
      </div>
      <AddToCartNotify
        product={product}
        AddProductToCart={AddProductToCart}
        outOfStockConfiguration={outOfStockConfiguration}
        loaderOn={loaderOn || spinnerOn}
      />
    </div>
  );
}

export default ProductTwo;
