import React from "react";
import { Link } from "react-router-dom";
import { loadCartData } from "../../redux/appAction";
import AddToCartRelatedProduct from "../Buttons/AddToCartRelatedProduct";
import OldPrice from "../Font/OldPrice";
import Price from "../Font/Price";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
import RatingBlock from "./RatingBlock";
import * as services from "./../../services/services";
import { addToCart } from "../../services/cart.service";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { useDispatch } from "react-redux";
import { useState } from "react";
import Heading7 from "../Font/Heading7";
import { selectedCompareProductStatus } from "../helpers/utils/compareProductsFilter";
import LazyImageForProduct from "./LazyImageForProduct";
import { useTranslation } from "react-i18next";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";
import SoldOutProductLabel from "./SoldOutProductLabel";
import { SmallImageResize } from "../helpers/utils/imageResize";
import ProductDeliveryType from "./ProductDeliveryType";

function RelatedProduct({product, handleChangeCartPopup, updateCompareProduct, outOfStockConfiguration, handleCompare, availablityData }) {

    const { setCartErr } = useCartErrorMessage();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loaderOn, setLoaderOn] = useState(false);
    const [spinnerOn, setSpinnerOn] = useState(false);

    // related product add to cart
    const AddProductToCartRelatedProd = (sku) => {
        setLoaderOn(true);
        const data = {
            items: [
                {
                    sku: sku,
                    qty: 1,
                    id: product?.id,
                },
            ],
        };

        addToCart(data)
            .then((res) => {
                setSpinnerOn(true)
                dispatch(loadCartData()).then((item) => {
                    cartEvents.addToCart(product, item?.id)
                    setSpinnerOn(false)
                    handleChangeCartPopup(true);
                });
            })
            .catch((err) => {
                dispatch(services.notifyError({ message: err.response.data.message }));
                setLoaderOn(false);
            })
            .finally(() => {
                setLoaderOn(false);
            });
    };

    return (
        <div key={product?.id} className="row pd__mb__product__block">
            <Link
                to={productUrlHelper(product?.english_name, product?.sku)}
                className="col-md-4 col-xxl-4 p-lg-0 pd__mb__product__image__block"
            >
                <LazyImageForProduct
                    product={product}
                    srcSetReSize={SmallImageResize}
                    alt=""
                    className="pd__mb__product__image"
                />
                <SoldOutProductLabel
                    t={t}
                    product={product}
                    outOfStockConfiguration={outOfStockConfiguration}
                />
            </Link>
            <div className="col-md-8 col-xxl-8 pd__mb__product__detail__block">
                <Link
                    className="pd__mb__product__name"
                    to={productUrlHelper(product?.english_name, product?.sku)}
                >
                    <Heading7 text={titleChange(product?.name)} />
                    <ProductDeliveryType
                    product={product}
                    availablityData={availablityData}
                    t={t}
                    />
                    <RatingBlock
                        size={15}
                        rating={4.5}
                        totalRatings={4199}
                        fillColor="#DC3A1A"
                        emptyColor="#C8C8C8"
                    />
                    <div className="mb__pd__related__pricebox">
                        <Price
                            price={
                                product?.discounted_price_rounded === null
                                    ? product?.price_rounded
                                    : product?.discounted_price_rounded
                            }
                            currency={product?.currency}
                            size="heading7"
                            span={true}
                        />
                        <OldPrice
                            oldPrice={product?.price_rounded}
                            price={
                                product?.discounted_price_rounded === null
                                    ? product?.price_rounded
                                    : product?.discounted_price_rounded
                            }
                            size="text4"
                            color="#808080"
                            marginLeft={5}
                            marginBottom={0}
                            lineThrough={true}
                            span={true}
                            currency={product?.currency}
                        />
                    </div>
                </Link>
            </div>
            <div className="col-md-12 col-xxl-12 pd__mb__product__detail__block compare__cart_box">
                <div className="pd__compare__block">
                    <input
                        type="checkbox"
                        className="pd__compare__input__check"
                        name="compare"
                        // onChange={handleChange}
                        checked={selectedCompareProductStatus(product?.id)}
                        onChange={() => handleCompare(selectedCompareProductStatus(product.id), product)}
                        id={product?.id}
                    />
                    <label for={product?.id} className="pd__compare__text">{t("Compare")}</label>
                </div>
                <div className="pd__relatedPro__item__buttonBox">
                    <AddToCartRelatedProduct
                        product={product}
                        AddProductToCart={AddProductToCartRelatedProd}
                        outOfStockConfiguration={outOfStockConfiguration}
                        loaderOn={loaderOn || spinnerOn}
                    />
                </div>
            </div>
        </div>
    );
}

export default RelatedProduct;
