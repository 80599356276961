import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import * as services from "./../../services/services";

function Contact_Form() {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const formRef = useRef(null);

    const [updateErrMsg, setUpdateErrMsg] = useState(false);
    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        type_query: "",
        message: "",
    });
    const [errMsg, setErrMsg] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        type_query: "",
        message: "",
    });

    useEffect(() => {
        if (updateErrMsg === true) {
            setErrMsg(errMsg);
            setUpdateErrMsg(!updateErrMsg);
        }
    }, [updateErrMsg]);

    const [errors, setErrors] = useState([]);
    const handleChange = async (event) => {
        let value = event.target.value;
        let name = event.target.name;
        let getErr = errors.filter((val, i) => val !== name);
        if (value === "") {
            getErr.push(name);
        }
        setErrors(getErr);
        setData({ ...data, [name]: value });
    };

    const contactUsForm = (e) => {
        e.preventDefault()
        let validateFeild = [
            {
                name: "first_name",
            },
            {
                name: "last_name",
            },
            {
                name: "email",
            },
            {
                name: "phone",
            },
            {
                name: "type_query",
            },
            {
                name: "message",
            },
        ];
        let defaultErr = [];
        validateFeild.map((val, i) => {
            if (data[val.name] === "") {
                defaultErr.push(val.name);
            }
        });
        setErrors(defaultErr);
        if (defaultErr.length === 0) {
            if (data.first_name !== "" && data.last_name !== "" && data.email !== "" && data.phone !== "" && data.type_query !== "" && data.message !== "") {
                let params = {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    phone: data.phone,
                    type_query: data.type_query,
                    message: data.message,
                };
                dispatch(services.contactUsForm(params,t)).then(() => {
                    setData({
                        first_name: "",
                        last_name: "",
                        email: "",
                        phone: "",
                        type_query: "",
                        message: "",
                    })
                    formRef.current.reset();
                });
            }
        }
    };

    return (
        <>
            <div className="contact__form__section">
                <Form ref={formRef} onSubmit={(e) => contactUsForm(e)}>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="form__itembox" controlId="formGridFname">
                                <Form.Label>{t("First_Name")}</Form.Label>
                                <Form.Control type="text" value={data?.first_name || ""} placeholder="" name="first_name" onChange={(e) => handleChange(e)} />
                                {errors.includes("first_name") && (
                                    <p className="invalid__message">
                                        {t("Firstname_is_missing")}
                                    </p>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="form__itembox" controlId="formGridLname">
                                <Form.Label>{t("Last_Name")}</Form.Label>
                                <Form.Control type="text"  value={data?.last_name || ""} placeholder="" name="last_name" onChange={(e) => handleChange(e)} />
                                {errors.includes("last_name") && (
                                    <p className="invalid__message">
                                        {t("Lastname_is_missing")}
                                    </p>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="form__itembox" controlId="formGridEmail">
                                <Form.Label>{t("email")}</Form.Label>
                                <Form.Control type="email" value={data?.email || ""} placeholder="" name="email" onChange={(e) => handleChange(e)} />
                                {errors.includes("email") && (
                                    <p className="invalid__message">
                                        {t("Email_is_missing")}
                                    </p>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="form__itembox" controlId="formGridphone">
                                <Form.Label>{t("Phone_Number")}</Form.Label>
                                <Form.Control type="text" value={data?.phone || ""} placeholder="" name="phone" onChange={(e) => handleChange(e)} />
                                {errors.includes("phone") && (
                                    <p className="invalid__message">
                                        {t("Mobile_Number_is_missing")}
                                    </p>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="form__itembox" controlId="formGridquery">
                                <Form.Label>{t("Type of query")}</Form.Label>
                                <div className="select_custombox">
                                    <Form.Select defaultValue="Choose..." name="type_query" onChange={(e) => handleChange(e)}>
                                        <option>{t("Type of query")}</option>
                                        <option>{t("Price & Order")}</option>
                                        <option>{t("Service, Warranty & Repair")}</option>
                                    </Form.Select>
                                </div>
                                {errors.includes("type_query") && (
                                    <p className="invalid__message">
                                        {t("type_query_is_missing")}
                                    </p>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-12">
                            <Form.Group className="form__itembox" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>{t("message")}</Form.Label>
                                <Form.Control as="textarea" rows={6} name="message" onChange={(e) => handleChange(e)} />
                                {errors.includes("message") && (
                                    <p className="invalid__message">
                                        {t("message_is_missing")}
                                    </p>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-12 text-right">
                            <Button variant="dark" type="submit" className="send_message_btn" onClick={(e) => contactUsForm(e)}>{t("send_message")}</Button>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
}
export default Contact_Form;