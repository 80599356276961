import React, { useEffect, useState } from "react";
import * as services from "./../../services/services";
import { useDispatch, useSelector } from "react-redux";
import Heading3 from "./../../Components/Font/Heading3";
import Heading7 from "./../../Components/Font/Heading7";
import "./../../SCSS/MobilePages/_mb__signIn__page.scss";
import google_white from "./../../assets/Icon/google_white.svg";
import facebook_white from "./../../assets/Icon/facebook_white.svg";
import hide_password from "./../../assets/Icon/hide_password.svg";
import see_password from "./../../assets/Icon/see_password.svg";
import check_orange from "./../../assets/Icon/check_orange.svg";
import empty_check_orange from "./../../assets/Icon/empty_check_orange.svg";
import apple_white from "./../../assets/Icon/apple_white.svg";
import { useNavigate } from "react-router-dom";
import {
  customerSignUpOtpSuccess,
  customerVerifyMobileOTPSuccess,
} from "../../services/customer/customer";
import { useTranslation } from "react-i18next";
import { LoginLoaderIcone } from "../Loader";
import ResetPasswordModel from "../../Components/Login/ResetPasswordModel";

function Mobile_Sign_In({ }) {
  const dispatch = useDispatch();
  const { loadingStartEnd } = useSelector((state) => state.commonReducer);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { customerSignUpOtpData, customerDetails } = useSelector(
    (state) => state.customerReducer
  );

  const [isPassword, setIsPassword] = useState(true);
  const [isCheckBoxHover, setIsCheckBoxHover] = useState(false);
  const [isCheckBox, setIsCheckBox] = useState(false);
  // const [loaderOn, setLoaderOn] = useState(false);
  const [popupMode, setPopupMode] = useState("");
  const handleFavourite = () => {
    setIsCheckBox(!isCheckBox);
  };
  const [data, setData] = useState({
    username: "",
    password: "",
    onboarding: "",
  });
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (customerDetails !== "") {
      navigate("/");
    }
  }, [customerDetails]);

  useEffect(() => {
    if (customerSignUpOtpData !== "") {
      navigate("/mobile-otp-page");
    }
  }, [customerSignUpOtpData]);

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    let getErr = errors.filter((val, i) => val !== name);
    if (value === "") {
      getErr.push(name);
    }
    setErrors(getErr);
    setData({ ...data, [name]: value });
  };

  // login user api
  // const onSignIn = () => {
  //   setLoaderOn(true);
  //   if (errors.length === 0 && data.username !== "" && data.password !== "") {
  //     let params = {
  //       username: data.username,
  //       password: data.password,
  //       onboarding: false,
  //       rememberMe: isCheckBox,
  //     };
  //     dispatch(services.customerSignIn(params));
  //   } else {
  //     setErrors(["username", "password"]);
  //     setLoaderOn(false);
  //   }
  // };

  const onSignIn = () => {
    let validateFeild = [
      {
        keyName: "username"
      },
      {
        keyName: "password"
      }
    ];
    let defaultErr = []
    validateFeild.map((val, i) => {
      if (data[val.keyName] === "") {
        defaultErr.push(val.keyName);
      }
    })
    setErrors(defaultErr);
    if (defaultErr.length === 0) {
      if (data.username !== "" && data.password !== "") {
        let params = {
          username: data.username,
          password: data.password,
          onboarding: false,
          rememberMe: isCheckBox,
        };
        dispatch(services.customerSignIn(params));
      }
    }
  }

  const redirectToSignup = () => {
    dispatch(customerSignUpOtpSuccess(""));
    dispatch(customerVerifyMobileOTPSuccess(""));
    navigate("/mobile-signup");
  };


  useEffect(() => {
    if (
      window.location.pathname.includes("/checkout") &&
      popupMode === "resetpassword"
    ) {
      setPopupMode("resetpassword");
    } else if (
      window.location.pathname.includes("/checkout") &&
      popupMode === "reset-password-verify"
    ) {
      setPopupMode("reset-password-verify");
    }
  }, [popupMode]);
  const handleChangePopupMode = (mode) => {
    setPopupMode(mode);
  };
  if (popupMode === "resetpassword") {
    return (
      <ResetPasswordModel
        // closeLoginPopup={closeLoginPopup}
        handleChangePopupMode={handleChangePopupMode}
      />
    );
  }
  const togglePassword = () => setIsPassword(!isPassword);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSignIn();
    }
  };
  return (
    <>
      <div className="mb__signIn__page d-lg-none d-block">
        <div className="container-fluid  ">
          <div className="row justify-content-center">
            <div className="col-sm-11">
              <div className="mb__sign__content my-3 my-sm-5 ">
                <div className="px-3 px-sm-4 pt-3">
                  <Heading3 text={t("Sign_In_to_Sony")} color="#000000" />
                </div>
                <hr />
                <div className="px-3 px-sm-4">
                  <div className="main__form__field__block">
                    {/* <p className="form__label">Email Address</p> */}
                    <Heading7
                      text={t("Email_Address_/_Mobile_Number")}
                      marginBottom={10}
                    />
                    <sup className="inputs_validation">*</sup>
                    <div className="field__block">
                      <input
                        type="text"
                        onKeyDown={handleKeyDown}
                        placeholder={t("Email_Address_/_Mobile_Number")}
                        className="form__field form-control"
                        id="email"
                        name="username"
                        value={data.username}
                        onChange={(e) => handleChange(e)}
                      // defaultValue={
                      //   localStorage.getItem("rememberedUser")
                      //     ? localStorage.getItem("rememberedUser")
                      //     : ""
                      // }
                      />
                    </div>
                    {errors.includes("username") && (
                      <p className="invalid__message text-danger">
                        {t("Please_Enter_Email_Address_Mobile_Number")}
                      </p>
                    )}
                  </div>
                  <div className="main__form__field__block mt-3 pwd-field">
                    {/* <p className="form__label">Password</p> */}
                    <Heading7 text={t("Password")} marginBottom={10} />
                    <sup className="inputs_validation">*</sup>
                    <div className="field__block">
                      <input
                        onKeyDown={handleKeyDown}
                        type={isPassword ? "password" : "text"}
                        placeholder={t("Password")}
                        className="form__field form-control"
                        id="password"
                        name="password"
                        value={data.password}
                        onChange={(e) => handleChange(e)}
                      />
                      <a onClick={() => togglePassword()} className="pwd-show">
                        {isPassword ? (
                          <img src={see_password} alt="" />
                        ) : (
                          <img src={hide_password} alt="" />
                        )}
                      </a>
                    </div>
                    {errors.includes("password") && (
                      <p className="invalid__message text-danger">
                        {t("Please_Enter_Password")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="remember__and__forget__password__block px-3 px-sm-4 mt-2">
                  <div className="main__remember__block">
                    <div className="remember__block">
                      <div className="inner__Remember__block">
                        <img
                          onMouseEnter={() => setIsCheckBoxHover(true)}
                          onClick={handleFavourite}
                          onMouseLeave={() => setIsCheckBoxHover(false)}
                          className={
                            !isCheckBox ? "check__icon" : "check__icon__disable"
                          }
                          src={
                            isCheckBoxHover ? check_orange : empty_check_orange
                          }
                          alt=""
                        />
                        <img
                          onClick={handleFavourite}
                          className={
                            isCheckBox ? "check__icon" : "check__icon__disable"
                          }
                          src={check_orange}
                          alt=""
                        />
                      </div>
                      <p>{t("Remember_me")}</p>
                    </div>
                  </div>
                  <div className="forgot__password__block">
                    <p
                      className="forgot__password__text"
                      onClick={() => handleChangePopupMode("resetpassword")}
                    >
                      {t("forgot_password?")}
                    </p>
                  </div>
                </div>
                <div className="px-3 px-sm-4">
                  <button
                    className="signin__button btn__primary__orange w-100 "
                    onClick={() => onSignIn()}
                  >
                    {t("SIGN_IN")}
                    {loadingStartEnd === true ? <LoginLoaderIcone /> : ""}
                  </button>
                </div>

                {/* <div className="or__block">
                  <div className="or__text__block">
                    <p className="or__text">{t("OR")}</p>
                  </div>
                  <div className="mid__line"></div>
                </div> */}
                {/* <div className="form px-3 px-sm-4">
                  <button className="getOtp__button">
                    {t("Get_an_OTP_on_your_Phone")}
                  </button> */}

                {/* <button className="google__signin__button">
                    <img
                      src={google_white}
                      alt="google_icon"
                      className="signin__button__icon"
                    />
                    {t("Sign_In_With_Google")}
                  </button>
                  <button className="facebook__signin__button">
                    <img
                      src={facebook_white}
                      alt="google_icon"
                      className="signin__button__icon"
                    />
                    {t("Sign_In_With_Facebook")}
                  </button>
                  <button className="apple__signin__button">
                    <img
                      src={apple_white}
                      alt="google_icon"
                      className="signin__button__icon"
                    />
                    {t("Sign_In_With_Apple")}
                  </button> */}
                <div className="loginModel__footer">
                  <p className="footer__text mb-3">
                    {t("New_User_to_Sony?")}{" "}
                    <span
                      onClick={() => redirectToSignup()}
                      className="footer__link__text "
                    >
                      {t("Create_an_Account")}
                    </span>
                  </p>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Mobile_Sign_In;
