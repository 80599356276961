import React from "react";
import { Link } from "react-router-dom";
import callIcon from "./../../assets/Icon/call.svg";
import mailIcon from "./../../assets/Icon/email.svg";
import twitterWhite from "./../../assets/Icon/twitter-white.svg";
import instaWhite from "./../../assets/Icon/instagram-white.svg";
import socialWhite from "./../../assets/Icon/social-white.svg";
import { useTranslation } from "react-i18next";

function Contact_Info(){
    const { t } = useTranslation();

    return(
        <>
        <div className="contact__info_section">
            <h2>{t('Contact Information')}</h2>
            <p className="disc_for_mob d-block d-lg-none">{t("Say something to start a live chat!")}</p>
            <div className="contact__data__area">
                <div className="contact__data__itembox">
                    <div className="contact__iconBox">
                        <img src={callIcon} alt="" />
                    </div>
                    <a href="tel:8001207669" className="contact__link call">8001207669</a>
                </div>
                <div className="contact__data__itembox">
                    <div className="contact__iconBox">
                        <img src={mailIcon} alt="" />
                    </div>
                    <a href="mailto:sonyworldsa@modern-electronics.com" className="contact__link">sonyworldsa@modern-electronics.com</a>
                </div>
            </div>

            <div className="social__contact__area">
                {/* <ul>
                    <li><a href="https://twitter.com/SonyWorldSA" target="_blank"><img src={twitterWhite} /></a></li>
                    <li><a href=""><img src={instaWhite} /></a></li>
                    <li><a href=""><img src={socialWhite} /></a></li>
                </ul> */}
            </div>
        </div>
        </>
    );
}

export default Contact_Info;