import React, { useState, useEffect } from "react";
import "./../../SCSS/Popup/_plpProductPopup.scss";
import { useDispatch, useSelector } from "react-redux";

import { addToCart } from "../../services/cart.service";
import { loadAddToWishlist, loadCartData, loadDeleteFromWishlist, loadWishlistData } from "../../redux/appAction";
import * as services from "../../services/services";
import cancel_grey from "./../../assets/Icon/cancel_grey.svg";
import empty_favourite from "./../../assets/Icon/empty_favourite.svg";
import fulfill_favourite from "./../../assets/Icon/fulfill_favourite.svg";
import Heading6 from "../Font/Heading6";
import OldPrice from "../Font/OldPrice";
import Price from "../Font/Price";
import RatingBlock from "../MostSharedComponent/RatingBlock";

import ProductCategoryLevel from "../Products/ProductCategoryLevel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import VariantOptions from "../ProductType/VariantOptions";
import { Link } from "react-router-dom";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import { checkWishList } from "../helpers/utils/wishListHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
// import AddToCart from "../Buttons/AddToCart";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { checkoutEvents } from "../helpers/utils/ga/checkoutEvents";
import DefaultImage from './../../assets/Icon/default_image.png';
import LazyImageForProduct from "../MostSharedComponent/LazyImageForProduct";
import { ImageResize } from "../helpers/utils/imageResize";
import AddToCartNotify from "../Buttons/AddToCartNotify"
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";
import { Modal } from "react-bootstrap";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  arrows: true,
  // slidesToScroll: 1
};

const randomKey = () => Math.ceil(Math.random() * 9999)

function PLPProductPopupModal({
  show,
  product,
  closeProductPopup,
  handleChangeCartPopup,
  redirectToPdpPage,
  outOfStockConfiguration
}) {

  const { setCartErr } = useCartErrorMessage();

  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [sizeButtonIndex, setSizeButtonIndex] = useState(null);
  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);
  const [wishlistCount, setWishlistCount] = useState(0);

  const { t } = useTranslation();

  const wishlistData = useSelector((state) => state.appData.wishlistData);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, product?.sku));
  }, [product, wishlistData]);

  const handleFavourite = () => {
    if (isFavourite) {
      setIsFavourite(false);
      setWishlistCount(wishlistCount + 1);
    } else {
      setIsFavourite(true);
      setWishlistCount(wishlistCount + 1);
    }
  };

  useEffect(() => {
    const data = {
      items: [product?.sku],
    };
    if (isFavourite) {
      if (wishlistCount > 0) {
        const addToWishlistData = dispatch(loadAddToWishlist(data, t)).then(
          (res) => {
            checkoutEvents.addToWishlist(product, "Product List");
            dispatch(loadWishlistData());
          }
        );
      }
    } else {
      if (wishlistCount > 0) {
        if (!isFavourite) {
          checkoutEvents.removeFromWishlist(product, "Product List");
          removeFromWL(product?.sku);
        }
      }
    }
  }, [isFavourite]);

  //multiple wishlist api call remove
  // useEffect(() => {
  //   console.log(isFavourite,"isFavourite")
  //   const data = {
  //     items: [product?.sku],
  //   };
  //   if (isFavourite) {
  //     addToWishlist(data);
  //   } else {
  //     removeFromWL(product?.sku);
  //   }
  // }, [isFavourite]);

  const dispatch = useDispatch();

  const AddProductToCart = (sku) => {
    setLoaderOn(true);

    if (product?.variants && product?.variants.length > 0 && sizeButtonIndex && sizeButtonIndex >= 0) {
      sku = product.variants[sizeButtonIndex].sku;
    }

    const data = {
      items: [
        {
          sku: sku,
          qty: 1,
          id: product?.id
        },
      ],
    };

    addToCart(data)
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
      })
      .finally(() => {
        setLoaderOn(false);
      });
  };

  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };
    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data, t)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };

  const sizeButtonClick = (sizeIndex) => {
    product.sku = product?.variants[sizeIndex]?.sku;
    product.name = product?.variants[sizeIndex]?.name;
    product.description = product?.variants[sizeIndex]?.description;
    product.price = product?.variants[sizeIndex]?.price;
    product.currency = product?.variants[sizeIndex]?.currency;
    product.price_rounded = product?.variants[sizeIndex]?.price_rounded;
    product.discounted_price = product?.variants[sizeIndex]?.discounted_price;
    product.discounted_price_rounded = product?.variants[sizeIndex]?.discounted_price_rounded;
    product.discounted_price_from = product?.variants[sizeIndex]?.discounted_price_from;
    product.discounted_price_to = product?.variants[sizeIndex]?.discounted_price_to;
    product.is_count_down = product?.variants[sizeIndex]?.is_count_down;
    product.stock = product?.variants[sizeIndex]?.stock;
    product.item_type = product?.variants[sizeIndex]?.item_type;
    product.meza = product?.variants[sizeIndex]?.meza;
    product.qitaf = product?.variants[sizeIndex]?.qitaf;
    product.eligible_for_tabby = product?.variants[sizeIndex]?.eligible_for_tabby;
    product.enable_shipping = product?.variants[sizeIndex]?.enable_shipping;
    product.additional_estimated_day = product?.variants[sizeIndex]?.additional_estimated_day;
    product.current_time = product?.variants[sizeIndex]?.current_time
    setSizeButtonIndex(sizeIndex);
  };

  return (
    <Modal
      show={show}
      centered
      onHide={() => closeProductPopup()}
      scrollable={true}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      size='lg'
    >
      <Modal.Body className="px-3 py-0">
        <div className="row plp__product__popup__block">
          <div className="plp__product__popup__header">
            <img
              onClick={() => closeProductPopup()}
              src={cancel_grey}
              alt="cancel"
              className="cancel__button"
            />
          </div>
          <div className="col-md-3 plpProduct__popup__left__block">
            <div className="productNine__image__slider">
              {/* <AwesomeSlider transitionDelay={0.2}>
            {product?.media?.image?.screenshots?.map((img, index) => (
              <div key={index} className="_product__container_image">
                <img src={img.image} alt={img.image + index} />
              </div>
            ))}
          </AwesomeSlider> */}
              {product?.media?.image?.screenshots !== undefined ?
                <Slider {...settings}>
                  {[product?.baseImage]?.map((img, index) => (
                    <div key={randomKey()} className="">
                      <LazyImageForProduct
                        product={product}
                        // srcSetReSize={ImageResize}
                        alt={img + index}
                        className=""
                        onClickEvent={true}
                        redirectToPdpPage={redirectToPdpPage}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  ))}
                </Slider>
                :
                <img
                  className=""
                  src={DefaultImage}
                  width="200px"
                  alt="default_image"
                  onClick={() => redirectToPdpPage(product?.english_name, product?.sku)}
                  style={{ cursor: 'pointer' }}
                />
              }
            </div>
          </div>
          <div className="col-md-9 plpProduct__popup__right__block">
            <div className="plpProduct__popup__titlebox">
              <Link
                className="productNine__name"
                to={productUrlHelper(product?.english_name, product?.sku)}
              >
                <Heading6 text={titleChange(product?.name)} marginBottom={10} />
              </Link>
              {/* <p className="productNine__name"> {titleChange(product.name)}</p> */}
              <div className="pd__category__favourite__button__block">
                <ProductCategoryLevel product={product} />
                {/* <button className="pd__category__button">ALL TV's</button> */}
                <button className="pd__favourite__button">
                  <img
                    onMouseEnter={() => setIsFavouriteHover(true)}
                    onClick={handleFavourite}
                    onMouseLeave={() => setIsFavouriteHover(false)}
                    className={
                      !isFavourite
                        ? "pd__favourite__icon"
                        : "pd__favourite__icon__disable"
                    }
                    src={isFavouriteHover ? fulfill_favourite : empty_favourite}
                    alt=""
                  />
                  <img
                    onClick={handleFavourite}
                    className={
                      isFavourite
                        ? "pd__favourite__icon"
                        : "pd__favourite__icon__disable"
                    }
                    src={fulfill_favourite}
                    alt=""
                  />
                </button>
              </div>
            </div>

            <div className="plp_quickPopup_pricebox">
              <OldPrice
                oldPrice={product.price_rounded}
                price={
                  product.discounted_price_rounded === null
                    ? product.price_rounded
                    : product.discounted_price_rounded
                }
                size="text3"
                color="#444444"
                marginBottom={0}
                // lineThrough={true}
                span={true}
                currency="SAR"
              />
              <Price
                price={
                  product.discounted_price_rounded === null
                    ? product.price_rounded
                    : product.discounted_price_rounded
                }
                // marginLeft={5}
                marginBottom={0}
                size="heading6"
                span={true}
                currency="SAR"
              />
            </div>
            <RatingBlock rating={6} totalRatings={2222} />
            <div className="size__button__block">
              {product?.variantOptions && (
                <VariantOptions
                  sizeButtonClick={sizeButtonClick}
                  sizeButtonIndex={sizeButtonIndex}
                  variantOptions={product?.variantOptions}
                  product={product}
                />
              )}
            </div>
            <AddToCartNotify
              product={product}
              AddProductToCart={AddProductToCart}
              outOfStockConfiguration={outOfStockConfiguration}
              loaderOn={loaderOn || spinnerOn}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PLPProductPopupModal;