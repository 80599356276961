import React from 'react';
import { Link } from "react-router-dom";


const CookieDisclaimer = ({ t, allow }) => (
  <div className="cookieDisclaimer">
    <div className="inner">
      <div className="text">
        {t('CookieDisclaimer')}
        <Link  to='/cms/privacy-policy' title={t('CookiePolicy')} prefetch={false}>
          <a>{t('CookiePolicy')}</a>
        </Link>
      </div>
      <button className="btn btn-dark btn-lg btn-fill" onClick={() => allow()}>
        {t('Accept')}
      </button>
    </div>
  </div>
);

export default CookieDisclaimer;
