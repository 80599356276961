import React, { useState, useEffect } from "react";
import * as services from "./../../services/services";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Heading4 from "../Font/Heading4";
import Heading7 from "../Font/Heading7";
import cancel_grey from "./../../assets/Icon/cancel_grey.svg";
import google_white from "./../../assets/Icon/google_white.svg";
import facebook_white from "./../../assets/Icon/facebook_white.svg";
import hide_password from "./../../assets/Icon/hide_password.svg";
import see_password from "./../../assets/Icon/see_password.svg";
import check_orange from "./../../assets/Icon/check_orange.svg";
import empty_check_orange from "./../../assets/Icon/empty_check_orange.svg";
import apple_white from "./../../assets/Icon/apple_white.svg";
import "./../../SCSS/Login/_loginModel.scss";
import { customerSignUpOtpSuccess } from "../../services/customer/customer";
import { useTranslation } from "react-i18next";
import SocialLinkBox from "../Common/SocialLinkBox";
import { Spinner } from "../helpers/utils/spinner";
import { loadCartData } from "../../redux/appAction";

function LoginModel({
  handleChangePopupMode,
  closeLoginPopup,
  handleChangeCartIconTotal,

}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { customerDetails } = useSelector((state) => state.customerReducer);
  const { loadingStartEnd } = useSelector((state) => state.commonReducer);
  const userloginData = useSelector((state) => state.appData.userLoggedIn);
 
   // useEffect(() => {
  //   if (customerDetails !== "") {
  //     closeLoginPopup();
  //   }
  // }, [customerDetails]);

  const [isPassword, setIsPassword] = useState(true);
  const [isCheckBoxHover, setIsCheckBoxHover] = useState(false);
  const [isCheckBox, setIsCheckBox] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const handleFavourite = () => {
    setIsCheckBox(!isCheckBox);
  };
  const [data, setData] = useState({
    email: "",
    password: "",
    onboarding: "",
  });
  const [errors, setErrors] = useState([]);

  const storageData = localStorage.getItem("handShakeConfig") ? JSON.parse(localStorage.getItem("handShakeConfig"))?.user?.userLoggedIn : false

  useEffect(() => {
    if (window.location.pathname.includes("/checkout/signin")  && (userloginData || storageData) ){
      dispatch(loadCartData()).then((item) => {
        navigate("/checkout/delivery")
      });       
    }
  }, [window.location.pathname,userloginData])

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    let getErr = errors.filter((val, i) => val !== name);
    if (value === "") {
      getErr.push(name);
    }
    setErrors(getErr);
    setData({ ...data, [name]: value });
  };

  const handleBlur = (e) => {
    let obj = {
      target: {
        value: e.target.value.trim(),
        name: e.target.name
      }
    }
    handleChange(obj)
  }

  // login user api
  // const onSignIn = () => {
  //   if (errors.length === 0 && data.username !== "" && data.password !== "") {
  //     let params = {
  //       username: data.username,
  //       password: data.password,
  //       onboarding: false,
  //       rememberMe: isCheckBox,
  //     };
  //     dispatch(services.customerSignIn(params));

  //   } else {
  //     setErrors(["username", "password"]);
  //   }
  // }

  // login user api
  const onSignIn = () => {
    let validateFeild = [
      {
        keyName: "email",
      },
      {
        keyName: "password",
      },
    ];
    let defaultErr = [];
    validateFeild.map((val, i) => {
      if (data[val.keyName] === "") {
        defaultErr.push(val.keyName);
      }
    });
    setErrors(defaultErr);
    if (defaultErr.length === 0) {
      if (data.email !== "" && data.password !== "") {
        let params = {
          username: data.email.trim(),
          password: data.password.trim(),
          onboarding: false,
          rememberMe: isCheckBox,
        };
        dispatch(services.customerSignIn(params, t));
        if (window.location.pathname.includes("/checkout/signin")  && (userloginData || storageData) ){
          dispatch(loadCartData()).then((item) => {
            navigate("/checkout/delivery")
          });
        }
      }
    }
  };


  const cartData = useSelector((state) => state.appData.cartData);
  useEffect(() => {
    if (Object.values(cartData).length !== 0) {
      handleChangeCartIconTotal(cartData.items.length);
    }
  }, [cartData]);



  const togglePassword = () => setIsPassword(!isPassword);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSignIn();
    }
  };
  return (
    <div className="loginModel__container">
      {/* <div className="loginModel__header">
        <Heading4 text={t("Sign_In_to_Sony")} color="#000000" />        
      </div> */}
      {/* <SocialLinkBox t={t} /> */}
      <Heading7 text={t('Already_have_an_account?')} color="#000000" />
      <div className="loginModel__content">
        <div className="main__form__field__block">
          {/* <p className="form__label">Email Address</p> */}

          <div className="field__itembox">
            <input
              type="text"
              // placeholder={t("Email_Address_/_Mobile_Number")}
              onKeyDown={handleKeyDown}
              className={`form__field ${data.email === "" ? "" : "focus_in"} `}
              id="email"
              name="email"
              value={data.email || ""}
              onChange={(e) => handleChange(e)}
              onBlur={handleBlur}
            // defaultValue={
            //   localStorage.getItem("rememberedUser")
            //     ? localStorage.getItem("rememberedUser")
            //     : ""
            // }
            />
            <div className="field__labelbox">
              <Heading7
                text={t("Email/Mobile")}
                marginBottom={0}
              />
              {/* <sup className="inputs_validation">*</sup> */}
            </div>
          </div>
          {errors.includes("email") && (
            <p className="invalid__message">
              {t("Please_Enter_Email_Address_Mobile_Number")}
            </p>
          )}
        </div>
        <div className="main__form__field__block">
          {/* <p className="form__label">Password</p> */}
          <div className="field__itembox">
            <input
              onKeyDown={handleKeyDown}
              type={isPassword ? "password" : "text"}
              // placeholder={t("Password")}
              className={`form__field sentry-mask ${data.password === "" ? "" : "focus_in"} `}
              id="password"
              name="password"
              value={data.password || ""}
              onChange={(e) => handleChange(e)}
              autoComplete="new-password"
            />
            <a onClick={() => togglePassword()}>
              {isPassword ? (
                <img src={see_password} alt="" />
              ) : (
                <img src={hide_password} alt="" />
              )}
            </a>
            <div className="field__labelbox">
              <Heading7 text={t("Password")} marginBottom={0} />
              {/* <sup className="inputs_validation">*</sup> */}
            </div>
          </div>
          {errors.includes("password") && (
            <p className="invalid__message">{t("Please_Enter_Password")}</p>
          )}
        </div>
        <div className="remember__and__forget__password__block">
          <div className="main__remember__block">
            {/* <div className="remember__block">
              <div className="inner__Remember__block">
                <img
                  onMouseEnter={() => setIsCheckBoxHover(true)}
                  onClick={handleFavourite}
                  onMouseLeave={() => setIsCheckBoxHover(false)}
                  className={
                    !isCheckBox ? "check__icon" : "check__icon__disable"
                  }
                  src={isCheckBoxHover ? check_orange : empty_check_orange}
                  alt=""
                />
                <img
                  onClick={handleFavourite}
                  className={
                    isCheckBox ? "check__icon" : "check__icon__disable"
                  }
                  src={check_orange}
                  alt=""
                />
              </div>
              <p className="Remember__label">{t("Remember_me")}</p>
            </div> */}
          </div>
          <div className="forgot__password__block">
            <p
              className="forgot__password__text"
              onClick={() => handleChangePopupMode("resetpassword")}
            >
              {t("forgot_password?")}
            </p>
          </div>
        </div>
        <button className="signin__button" onClick={() => {onSignIn();}}>
          {loadingStartEnd === true ? <Spinner /> : t("Login")}
        </button>
        {location.pathname === "/checkout/signin" && (
          <>
            <div className="or__block">
              <div className="or__text__block">
                <p className="or__text">{t("OR")}</p>
              </div>
              <div className="mid__line"></div>
            </div>

            <div className="main__policy__check__block">
              <div className="policy__check__block">
                <input type="checkbox" name="registerPolicy" checked={isTermsAccepted} onChange={(e) => { setIsTermsAccepted(e.target.checked) }} id="registerPolicy" className="policy_check__icon" />
              </div>
              <p className="signUP__policy__label">
                {t("I_agree_to_our")}
                <Link
                  className="policy__link"
                  to="/cms/terms-and-conditions"
                >
                  {" "}
                  {t("Terms_and_Conditions")}{" "}
                </Link>
                {t("&")}
                <Link className="policy__link" to="/cms/privacy-policy"
                >
                  {" "}
                  {t("Privacy_Policy")}{" "}
                </Link>
              </p>
            </div>

            <button
              onClick={() => {
                localStorage.setItem("user_mode", "guest_user")
                // closeLoginPopup()
                navigate("/checkout/delivery")
              }
              }
              className="getOtp__button"
              disabled={!isTermsAccepted}
            >
              {t("Continue_As_Guest")}
            </button>
          </>
        )}

        {/* <button
          onClick={() => handleChangePopupMode("otp")}
          className="getOtp__button"
        >
          Get an OTP on your Phone
        </button> */}

        {/* <button className="google__signin__button">
          <img
            src={google_white}
            alt="google_icon"
            className="signin__button__icon"
          />
          {t("Sign_In_With_Google")}
        </button>
        <button className="facebook__signin__button">
          <img
            src={facebook_white}
            alt="google_icon"
            className="signin__button__icon"
          />
          {t("Sign_In_With_Facebook")}
        </button>
        <button className="apple__signin__button">
          <img
            src={apple_white}
            alt="google_icon"
            className="signin__button__icon"
          />
          {t("Sign_In_With_Apple")}
        </button> */}

        {/* <div className="loginModel__footer">
          <p className="footer__text">
            {t("New_User_to_Sony?")}{" "}
            <span
              onClick={() => handleChangePopupMode("signup")}
              className="footer__link__text"
            >
              {t("Create_an_Account")}
            </span>
          </p>
        </div> */}

      </div>
    </div>
  );
}

export default LoginModel;
