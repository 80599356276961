import React from "react";
import { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FlixMedia from "../../Pages/FlixMedia";
import ReviewTestFreaks from "../../Pages/ReviewTestFreaks";
import htmlParseHelper from "../helpers/utils/htmlParseHelper";

function PdpAccordianMobile({ product, finalData, productSpecificationData, getOptionKeyInfo, isMobile }) {
    const { t } = useTranslation();

    const [isOpenOne, setIsOpenOne] = useState(false);
    const [isOpenTwo, setIsOpenTwo] = useState(false);
    const [isOpenThree, setIsOpenThree] = useState(false);
    const [isOpenFour, setIsOpenFour] = useState(false);

    return (
        <div className="container-fluid">
            <div className="row">
                <Accordion alwaysOpen={true}>
                    {finalData && finalData[0] && (
                        <Accordion.Item eventKey="0">
                            <Accordion.Header onClick={(e) => setIsOpenOne(!isOpenOne)}>{t("Description")} <span className="accodian-active-status">{isOpenOne ? t("Hide") : t("Show")}</span></Accordion.Header>
                            <Accordion.Body>
                                {finalData && finalData[0] &&
                                    <React.Fragment>
                                        {htmlParseHelper(finalData && finalData[0])}
                                    </React.Fragment>
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={(e) => setIsOpenTwo(!isOpenTwo)}>{t("Product_Overview")} <span className="accodian-active-status">{isOpenTwo ? t("Hide") : t("Show")}</span></Accordion.Header>
                        <Accordion.Body>
                            <React.Fragment>
                                {isMobile &&
                                    <FlixMedia
                                        sku={getOptionKeyInfo(product?.options, 'sku')}
                                        ean={getOptionKeyInfo(product?.options, 'ean')}
                                        mpn={getOptionKeyInfo(product?.options, 'mpn')}
                                    />
                                }
                            </React.Fragment>
                        </Accordion.Body>
                    </Accordion.Item>
                    {productSpecificationData !== "" && finalData && finalData[1] && (
                        <Accordion.Item eventKey="2">
                            <Accordion.Header onClick={(e) => setIsOpenThree(!isOpenThree)}>{t("Specifications")} <span className="accodian-active-status">{isOpenThree ? t("Hide") : t("Show")}</span></Accordion.Header>
                            <Accordion.Body>
                                {finalData && finalData[1]
                                    ? htmlParseHelper(finalData[1].substring(finalData[1].indexOf('<table')))
                                    : "" || ''}
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                    <Accordion.Item eventKey="3">
                        <Accordion.Header id="flush-headingThree" onClick={(e) => setIsOpenFour(!isOpenFour)}>{t("Reviews")} <span className="accodian-active-status">{isOpenFour ? t("Hide") : t("Show")}</span></Accordion.Header>
                        <Accordion.Body>
                            {isMobile &&
                                <ReviewTestFreaks reviewsDiv={true} />
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    );
}

export default PdpAccordianMobile;
