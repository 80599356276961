import React from "react";
import { Link } from "react-router-dom";
import BreadCrumbs from "../Components/BreadCrumbs";
import "./../SCSS/_dashboardPage.scss";
import my_orders from "./../assets/Icon/my_orders.svg";
import my_profile from "./../assets/Icon/my_profile.svg";
import my_payments from "./../assets/Icon/my_payments.svg";
import my_wishlist from "./../assets/Icon/my_wishlist.svg";
import my_wallet from "./../assets/Icon/my_wallet.svg";
import my_tickets from "./../assets/Icon/my_tickets.svg";
import my_returns from "./../assets/Icon/my_returns.svg";
import gift_card from "./../assets/Icon/gift_card.svg";
import my_subscriptions from "./../assets/Icon/my_subscriptions.svg";
import my_reward_points from "./../assets/Icon/my_reward_points.svg";
import extended_warrenty from "./../assets/Icon/extended_warrenty.svg";
import free_installations from "./../assets/Icon/free_installations.svg";
import Heading6 from "../Components/Font/Heading6";
import MetaComponent from "../Components/Common/MetaComponent";
import { useTranslation } from "react-i18next";
import TealiumTag from "../Components/helpers/utils/tealium";


function Dashboard() {
  const { t } = useTranslation();

  const dashboardData = [
    {
      id: 0,
      name: t("My_Orders"),
      icon: my_orders,
      link: "/user/orders",
    },
    {
      id: 1,
      name: t("My_Profile"),
      icon: my_profile,
      link: "/user/profile",
    },
    // {
    //   id: 2,
    //   name: t("My_Payments"),
    //   icon: my_payments,
    //   link: "",
    // },
    {
      id: 3,
      name: t("My_Wishlists"),
      icon: my_wishlist,
      link: "/user/wishlist",
    },
    {
      id: 4,
      name: t("My_Wallet"),
      icon: my_wallet,
      link: "/user/credits",
    },
    // {
    //   id: 5,
    //   name: t("My_Tickets"),
    //   icon: my_tickets,
    //   link: "",
    // },
    // {
    //   id: 6,
    //   name: t("My_Returns"),
    //   icon: my_returns,
    //   link: "",
    // },
    // {
    //   id: 7,
    //   name: t("Gift_Cards"),
    //   icon: gift_card,
    //   link: "",
    // },
    // {
    //   id: 8,
    //   name: t("My_Subscriptions"),
    //   icon: my_subscriptions,
    //   link: "",
    // },
    // {
    //   id: 9,
    //   name: t("My_Reward_Points"),
    //   icon: my_reward_points,
    //   link: "",
    // },
    // {
    //   id: 10,
    //   name: t("Extended_Warrenty"),
    //   icon: extended_warrenty,
    //   link: "",
    // },
    // {
    //   id: 11,
    //   name: t("Free_Installations"),
    //   icon: free_installations,
    //   link: "",
    // },
  ];
  return (
    <>
      <MetaComponent
        title={t("my_account")}
        meta_title={t("my_account")}
        meta_keywords={t("my_account")}
        meta_description={t("my_account")}
      />
      <TealiumTag name={t("my_account")} template={t("my_account")} />
      <BreadCrumbs back="/" firstBread="/user" firstBreadTitle={t("my_account")} highlight="firstBreadTitle" />
      <div className="container-fluid dashboard__page__container">
        <div className="dashboard__page__block">
          <div className="row  dashboard__page__inner__block">
            {dashboardData.map((dash, dashIndex) => {
              return (
                <Link
                  key={dashIndex}
                  to={dash.link}
                  className="col-6 col-sm-6 col-md-4 col-lg-3  dash__block"
                >
                  <div className="dash__inner__block">
                    <img src={dash.icon} alt="" className="dash__icon" />

                    <Heading6 text={dash.name} textAlign="center" />
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      {/* <NewsLetter/> */}
      {/* <Footer/> */}
    </>
  );
}

export default Dashboard;
