import React, { } from "react";
import { } from "react-router-dom";
import './../../SCSS/MostSharedComponents/_storeLocationCard.scss';
import { FaMapMarkerAlt } from 'react-icons/fa';

function StoresLocation({ stores, t }) {

    return (
        <React.Fragment>
            {stores?.map((item) => (
                <div className="locationCard" key={item?.name}>
                    <div>
                        <div className="cardTitle">{item.name}</div>
                        <div className="cardDesc">{`${item.address === null ? '' : item.address} ${item.city === null ? '' : item.city} ${item.country === null ? '' : item.country}`}</div>
                    </div>
                    <div className="location">
                        <a target="_blank" rel="noreferrer" href={stores?.mapUrl || `https://www.google.com/maps?q=${item?.latitude},${item?.longitude}`}>
                            <FaMapMarkerAlt color="#dc3a1a" />
                            <p className="text">{t('Location')}</p>
                        </a>
                    </div>
                </div>
            ))}
        </React.Fragment>
    );
}
export default StoresLocation;