import React from 'react';
import "./../../SCSS/_loader.scss";

function Loader(props) {

    return (
        <>
            <div className="text-center loader-mainbox">
                <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        </>
    );
}

export default Loader;