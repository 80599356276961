import * as types from "./actionType";

const initialState = {
  homepageData: {},
  categoryData: {},
  productData: {},
  wishlistCount: 0,
  wishlistData: [],
  wishlistAvailabilityData: [],
  cityLocationData: [],
  countriesLocationData: [],
  storesLocationData: [],
  storesEnableLocationData: [],
  filterOptionData: {},
  applyFilterProductsData: {},
  selectedCategory: {},
  loading: true,
  cartQuoteId: "",
  cartData: [],
  payfortInformationData: {},
  orderDetailsData: {},
  deliveryShippingInfo: "",
  loyaltyEarnTypeList: "",
  selectedLoyaltyPointsProgram: "",
  getCompareProductList: [],
  configurationSettingData: {},
  availablePaymentMethodData: "",
  random: false,
  userLoggedIn:false,
  niqatyResetFlow: false,
  modalData: {},
  logistrationMode: false,
  toggleModal: false,
  logistrationState: 'login',
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET__HOME__PAGE__DATA:
      return {
        ...state,
        homepageData: action.payload,
      };
    case types.RANDOM_VALUE:
      return {
        ...state,
        random: action.payload,
      };
    case types.SET__PRODUCT__STATUS__DATA:
      return {
        ...state,
        productStatusData: action.payload,
      };
    case types.GET__ALL__CATEGORY__DATA:
      return {
        ...state,
        categoryData: action.payload,
      };
    case types.SET__SELECTED__CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload,
      };
    case types.GET__PRODUCT__DETAIL__DATA:
      return {
        ...state,
        productData: action.payload,
      };
    case types.GET__FILTER__DATA:
      return {
        ...state,
        filterOptionData: action.payload,
      };
    case types.GET__APPLY__FILTER__PRODUCTS__DATA:
      return {
        ...state,
        applyFilterProductsData: action.payload,
      };
    case types.SET__ORDER__ID__DATA:
      return {
        ...state,
        payfortInformationData: action.payload,
      };
    case types.GET__ORDER__DETAIL__DATA:
      return {
        ...state,
        orderDetailsData: action.payload,
      };
    case types.SET__WISHLIST__COUNT:
      return {
        ...state,
        wishlistCount: action.payload,
      };
    case types.SET__WISHLIST__AVAILABILITY__DATA:
      return {
        ...state,
        wishlistAvailabilityData: action.payload,
      };
    case types.GET__WISHLIST__DATA:
      return {
        ...state,
        wishlistData: action.payload,
      };
    case types.GET__CITY__LOCATION__DATA:
      return {
        ...state,
        cityLocationData: action.payload,
      };
    case types.GET__COUNTRIES__LOCATION__DATA:
      return {
        ...state,
        countriesLocationData: action.payload,
      };
    case types.GET__STORES__LOCATION__DATA:
      return {
        ...state,
        storesLocationData: action.payload,
      };
    case types.GET__ENABLE__STORES__LOCATION__DATA:
      return {
        ...state,
        storesEnableLocationData: action.payload,
      };
    case types.GET__CART__QUOTE__ID:
      return {
        ...state,
        cartQuoteId: action.payload,
      };
    case types.GET__CART__DATA:
      return {
        ...state,
        cartData: action.payload,
      };
    case types.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.UPDATE_CUSTOMER_SHIPPING_INFO:
      return {
        ...state,
        deliveryShippingInfo: action.payload,
      };
    case types.GET_LOYALTY_EARN_TYPE_DATA:
      return {
        ...state,
        loyaltyEarnTypeList: action.payload,
      };
    case types.SELECTED_LOYALTY_POINTS_PROGRAM:
      return {
        ...state,
        selectedLoyaltyPointsProgram: action.payload,
      };
    case types.COMPARE_PRODUCT_LIST:
      return {
        ...state,
        getCompareProductList: action.payload,
      };
    case types.GET_AVAILABLE_PAYMENT_METHOD__DATA:
        return {
          ...state,
          availablePaymentMethodData: action.payload,
        };
    case types.GET__CONFIGURATION__SETTING__DATA:
      return {
        ...state,
        configurationSettingData: action.payload,
      };
    case types.USER_LOGIN_DATA:
        return {
          ...state,
          userLoggedIn: action.payload,
        };
    case types.NIQATY_RESET_FLOW:
      return {
        ...state,
        niqatyResetFlow: action.payload,
      };
    case types.MODAL_DATA:
      return {
        ...state,
        modalData: action.payload,
      };
    case types.LOGISTRATION_MODE:
      return {
        ...state,
        logistrationMode: action.payload,
      };
    case types.TOGGLE_MODAL:
      return {
        ...state,
        toggleModal: action.payload,
      };
    case types.LOGISTRATION_STATE:
      return {
        ...state,
        logistrationState: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
