import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import empty_favourite from "./../../assets/Icon/empty_favourite.svg";
import fulfill_favourite from "./../../assets/Icon/fulfill_favourite.svg";
import shopping_cart from "./../../assets/Icon/shopping-cart-red.svg";
import compare from "./../../assets/Icon/compare.svg";
import Heading7 from "./../../Components/Font/Heading7";
import Text4 from "./../../Components/Font/Text4";
import Text5 from "./../../Components/Font/Text5";
import OldPrice from "./../../Components/Font/OldPrice";
import Price from "./../../Components/Font/Price";
import Heading6 from "./../../Components/Font/Heading6";
import RatingBlock from "./../../Components/MostSharedComponent/RatingBlock";
import AwesomeSlider from "react-awesome-slider";
import "./../../SCSS/MobilePages/_mobileProductGridSec.scss";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAddToWishlist,
  loadDeleteFromWishlist,
  loadWishlistData,
  loadCartData,
} from "../../redux/appAction";
import * as services from "../../services/services";
import { addToCart } from "../../services/cart.service";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { selectedCompareProductStatus } from "./../../Components/helpers/utils/compareProductsFilter";
import moment from "moment";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import { checkWishList } from "../helpers/utils/wishListHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
import MizaAndQitaf from "../MostSharedComponent/MizaAndQitaf";
import ProductLabel from "../MostSharedComponent/ProductLabel";
import PLPdiscountBox from "../MostSharedComponent/PLPdiscountBox";
import { ImageResize } from "../helpers/utils/imageResize";
import TamaraLogoBox from "../MostSharedComponent/TamaraLogoBox";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { checkoutEvents } from "../helpers/utils/ga/checkoutEvents";
import LazyImageForProduct from "../MostSharedComponent/LazyImageForProduct";
import AddToCartNotify from "../Buttons/AddToCartNotify";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";
import MobileCompareBtn from "../Plp/MobileCompareBtn";
import SoldOutProductLabel from "../MostSharedComponent/SoldOutProductLabel";
import ProductDeliveryType from "../MostSharedComponent/ProductDeliveryType";
import VariantOptions from "./VariantOptions";

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  arrows: false,
  // slidesToScroll: 1
};
function MobileGridViewProduct({
  product,
  updateCompareProduct,
  handleChangeCartPopup,
  outOfStockConfiguration,
  productIndex,
  screenName,
  availablityData
}) {

  const { setCartErr } = useCartErrorMessage();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [sizeButtonIndex, setSizeButtonIndex] = useState(0);
  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);
  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [clickedProduct, setClickedProduct] =  useState();

  const wishlistData = useSelector((state) => state.appData.wishlistData);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, product?.sku));
  }, []);
  useEffect(() => {
    product.is_price_hide = product.options.find((x) => {
      if (x.code === 'is_price_hide') {
        return x.visibility;
      }
      return false;
    });
  }, [product]);
  const handleFavourite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isFavourite) {
      setIsFavourite(false);
      setCount(count + 1);
    } else {
      setIsFavourite(true);
      setCount(count + 1);
    }
  };
  const AddProductToCart = (sku) => {
    setLoaderOn(true);
    if (product.variants && product.variants.length > 0) {
      sku = product.variants[sizeButtonIndex].sku;
    }

    const data = {
      items: [
        {
          sku: sku,
          qty: 1,
          id: product?.id
        },
      ],
    };

    addToCart(data)
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
        setLoaderOn(false);
      })
      .finally(() => {
        setLoaderOn(false);
      });
  };
  useEffect(() => {
    const data = {
      items: [product.sku],
    };
    if (isFavourite) {
      if (count > 0) {
        const addToWishlistData = dispatch(loadAddToWishlist(data, t)).then(
          (res) => {
            checkoutEvents.addToWishlist(product, screenName);
            dispatch(loadWishlistData());
          }
        );
      }
    } else {
      if (count > 0) {
        if (!isFavourite) {
          checkoutEvents.removeFromWishlist(product, screenName);
          removeFromWL(product.sku);
        }
      }
    }
  }, [isFavourite]);

  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };
    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };
  const redirectToPdpPage = (url_key, sku) => {
    navigate(productUrlHelper(url_key, sku));
  }

  const getSpecificDayDate = (day) => {
    const tomorrow = moment().add(day, "days");
    return tomorrow.format("DD MMMM, ");
  };

  const isOutOfStock = product?.orderType === 'out-of-stock' || product?.stock === 0;

  const targetPrice = product?.price_rounded;
  const matchedProduct = product?.variants?.filter(product => product.price_rounded === targetPrice);
  const newMatchPrice = matchedProduct?.length > 1 ? matchedProduct?.[0] : matchedProduct?.[0];
  const screenSize = matchedProduct && matchedProduct[0]?.options?.screen_size;
  const seletcedScreenSize = product?.variantOptions?.[0].attribute?.options.filter(product => product.id === screenSize);
  const defaultSku = matchedProduct && matchedProduct[0]?.sku;

  const sizeButtonClick = (sizeIndex) => {
    product.sku = product?.variants[sizeIndex]?.sku;
    product.name = product?.variants[sizeIndex]?.name;
    product.description = product?.variants[sizeIndex]?.description;
    product.price = product?.variants[sizeIndex]?.price;
    product.currency = product?.variants[sizeIndex]?.currency;
    product.price_rounded = product?.variants[sizeIndex]?.price_rounded;
    product.discounted_price = product?.variants[sizeIndex]?.discounted_price;
    product.discounted_price_rounded = product?.variants[sizeIndex]?.discounted_price_rounded;
    product.discounted_price_from = product?.variants[sizeIndex]?.discounted_price_from;
    product.discounted_price_to = product?.variants[sizeIndex]?.discounted_price_to;
    product.is_count_down = product?.variants[sizeIndex]?.is_count_down;
    product.stock = product?.variants[sizeIndex]?.stock;
    product.item_type = product?.variants[sizeIndex]?.item_type;
    product.meza = product?.variants[sizeIndex]?.meza;
    product.qitaf = product?.variants[sizeIndex]?.qitaf;
    product.eligible_for_tabby = product?.variants[sizeIndex]?.eligible_for_tabby;
    product.enable_shipping = product?.variants[sizeIndex]?.enable_shipping;
    product.additional_estimated_day = product?.variants[sizeIndex]?.additional_estimated_day;
    product.current_time = product?.variants[sizeIndex]?.current_time
    setSizeButtonIndex(sizeIndex);
  };
  // function productClicked(param){
  //   setClickedProduct(param);
  // }
  // console.log('clickedProduct',clickedProduct)
  const stringToCheck = product?.name;
  const regex = /\d+$/;
  const hasAnythingAfterDash = regex.test(stringToCheck);

  const productName = (() => {
    if (!hasAnythingAfterDash && newMatchPrice?.name !== undefined) {
    return newMatchPrice.name;
  }
    return product.name;
  })();
  return (
    <div
      key={product.id}
      className="mb__product__grid__block ss"
      onClick={() => redirectToPdpPage(product?.english_name, product?.sku)}
    >
      <div className="productNine__header mb__product__header">
        {/* <div className="productNine__new__sticker__block">
          <p className="productNine__new__sticker__text">{t("New")}</p>
        </div> */}
        <PLPdiscountBox product={product} t={t} />
        <div className="quality__favourite__block">
          <img
            onMouseEnter={() => setIsFavouriteHover(true)}
            onClick={handleFavourite}
            onMouseLeave={() => setIsFavouriteHover(false)}
            className={
              !isFavourite ? "favourite__icon" : "favourite__icon__disable"
            }
            src={isFavouriteHover ? fulfill_favourite : empty_favourite}
            alt=""
          />
          <img
            onClick={handleFavourite}
            className={
              isFavourite ? "favourite__icon" : "favourite__icon__disable"
            }
            src={fulfill_favourite}
            alt=""
          />
        </div>
      </div>
      <div className="productNine__content">
        <Link
          className="productNine__name__link"
          to={productUrlHelper(product?.english_name, product?.sku)}
        >
          <div className="product__pics__area">
            <div className="productNine__image__slider">
              {/* <AwesomeSlider
              transitionDelay={0.2}
              mobileTouch={true}
              infinite={true}
            >
              {product?.media?.image?.screenshots?.map(
                (img, index) => (
                  <div
                    key={index}
                    className="_product__container_image"
                  >
                    <img src={img.image} alt={img.image + index} />
                  </div>
                )
              )}
            </AwesomeSlider> */}
              <Slider {...settings}>
                {[product?.baseImage]?.map((img, index) => (
                  <div key={index} className="">
                    <LazyImageForProduct
                      product={product}
                      srcSetReSize={ImageResize}
                      alt={img + index}
                      className=""
                      onClickEvent={true}
                      redirectToPdpPage={redirectToPdpPage}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <ProductLabel product={product} labelType="plp" />
            <SoldOutProductLabel
              t={t}
              product={product}
              outOfStockConfiguration={outOfStockConfiguration}
            />
          </div>

          <p className="productNine__name">{titleChange(productName)}</p>

          <ProductDeliveryType
            product={product}
            availablityData={availablityData}
            t={t}
          />
          <div className="plp__tamara__box mb-2">
            <TamaraLogoBox product={product} />
          </div>
          {/* <Heading6 text={titleChange(product.name)} marginBottom={10} /> */}
          <div className="plp__mezza__reward d-flex justify-content-between align-items-center mb-2">
            <MizaAndQitaf product={product} />
          </div>
          <div className="mb__product-grid-view-pricebox">
            {
              !product?.is_price_hide &&
              <>
                <OldPrice
                  oldPrice={product?.price_rounded}
                  price={
                    product.discounted_price_rounded === null
                      ? product.price_rounded
                      : product.discounted_price_rounded
                  }
                  size="text4"
                  color="#444444"
                  marginBottom={0}
                  // lineThrough={true}
                  span={true}
                  currency="SAR"
                />
                <Price
                  price={
                    product.discounted_price_rounded === null
                      ? product.price_rounded
                      : product.discounted_price_rounded
                  }
                  // marginLeft={5}
                  marginBottom={0}
                  size="heading6"
                  span={true}
                  currency="SAR"
                />
                <div className="size__button__block">
                  {product?.variantOptions && (
                    <VariantOptions
                      sizeButtonClick={sizeButtonClick}
                      sizeButtonIndex={sizeButtonIndex}
                      variantOptions={product?.variantOptions}
                      product={product}
                      // productClicked = {productClicked}
                    />
                  )}
                </div>
              </>
            }
          </div>
        </Link>
        <RatingBlock rating={6} totalRatings={2222} />

        <div className="productNine__feature__block">
          {product.productFeatures && (
            <>
              {product.productFeatures.map((feature, featureIndex) => {
                return (
                  <div key={featureIndex} className="feature__text__block">
                    <div className="feature__bullet"></div>
                    <Text4 text={feature.text} />
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className="delivery-time-area">
          {product?.additional_estimated_day !== undefined && (
            <>
              <Text4 text={`${t("Free delivery by")}`} span={true} />
              &nbsp;
              {product?.additional_estimated_day === 1 ? (
                <Heading7 text={`${t('Tomorrow')},`} span={true} />
              ) : product?.additional_estimated_day === 2 ? (
                <Heading7 text={`${t('Day_After_Tomorrow')},`} span={true} />
              ) : (
                ""
              )}
              <Heading7
                text={`${getSpecificDayDate(
                  product?.additional_estimated_day
                )} 7:00 am - 9:00 pm`}
                marginBottom={10}
              />
            </>
          )}
        </div>
        <div className="mb_cart_com_btn"
          onClick={(e) => { e.stopPropagation(); e.preventDefault() }}
        >
          {product?.is_bundle_enable === 1 ? (
            <Link
              to={productUrlHelper(product?.english_name, product?.sku)}
              aria-label={`${titleChange(product.name)}`}
              style={{ textDecoration: 'none' }}
              className=""
            >
              <AddToCartNotify
                product={product}
                AddProductToCart={AddProductToCart}
                outOfStockConfiguration={outOfStockConfiguration}
                loaderOn={loaderOn || spinnerOn} />
            </Link>
          ) : (
            <AddToCartNotify
              product={product}
              AddProductToCart={AddProductToCart}
              outOfStockConfiguration={outOfStockConfiguration}
              loaderOn={loaderOn || spinnerOn}
            />
          )}
          {/* {
            product?.orderType === "pre-order" ? (
              <div
                className="addToCart__button pre-order pre-order-mbl"
                onClick={() => AddProductToCart(product.sku)}
              >
                {t("pre_order")}

              </div>
            ) : (
              <div
                onClick={() => AddProductToCart(product.sku)}
                className="addToCart__button"
              >
                <img src={shopping_cart} alt="" className="addToCart__icon" />
              </div>
            )
          } */}

          {/* <button
            onClick={() => updateCompareProduct(true, product)}
            className={`productNine__button__block ${selectedCompareProductStatus(product.id) === true
                ? `active_compare_btn`
                : ``
              }`}
          >
            <HiOutlineSwitchHorizontal className="compare_icon shortlink_icon" />
          </button> */}
        </div>
        <MobileCompareBtn
          product={product}
          updateCompareProduct={updateCompareProduct}
          inputName="gridCompare"
        />
      </div>
    </div>
  );
}

export default MobileGridViewProduct;
