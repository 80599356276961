import skuEncoder from "../../../lib/utils/skuEncoder";


export const productUrlHelper = (slug, sku, isBundle = false) => {
    let skuEncoded = sku ? skuEncoder(sku) : '';
    let defaultUrl = `/${isBundle ? "create-bundle" : "products"}/${encodeURIComponent((slug || 'sonyworld').trim().replace(/\s+/g, '-'))}-p-${skuEncoded}`;

    if (defaultUrl) {
        defaultUrl.replace('-p-p', '-p')
    }
    return defaultUrl.replace('-p-p', '-p');
};
export const getPageName = () => {
     const path =  window.location.pathname;
     if(path.includes('search')){
        return "Search Page";
     }else if (path.includes('product')){
        return "Product Detail Page";
     }else if (path.includes('cart')){
        return "Cart Page";
     }else if (path.includes('checkout/payment')){
        return "Checkout Payment Page";
     }else if (path.includes('checkout/delivery')){
        return "Checkout Delivery Page";
     }else if (path.includes('user/wishlist')){
        return "Wishlist Page"; 
     }
     else{
        return "Home page";
     }
};
