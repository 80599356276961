import React from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "../helpers/utils/spinner";
import WhiteCartIcon from "./../../assets/Icon/cart_top.svg";
import NotifyMeBtnRelatedProd from "./NotifyMeBtnRelatedProd";

function AddToCartRelatedProduct({
  product,
  AddProductToCart,
  outOfStockConfiguration,
  loaderOn,
}) {
  const { t } = useTranslation();

  const productOutOfStockStatus =
    product && product?.productOutOfStockStatus
      ? product?.productOutOfStockStatus
      : false;
  const isOutOfStock =
    product?.orderType === "out-of-stock" || product?.stock === 0;
  const isPreOrder = product?.orderType === "pre-order";

  return (
    <>
      {isOutOfStock &&
        outOfStockConfiguration?.module_status &&
        productOutOfStockStatus ? (
        <NotifyMeBtnRelatedProd product={product} />
      ) : (
        <>
          {isOutOfStock ? (
            <button
              className="outofStock__button pd__related__action__btn"
            >
              {t("Out_Of_Stock")}
            </button>
          ) : isPreOrder ? (
            <button
              className="pre-order pd__related__action__btn"
              onClick={() => AddProductToCart(product?.sku)}
            >
              {loaderOn === true ?
                <Spinner />
                :
                <>
                  <img src={WhiteCartIcon} alt="" />
                  {t("pre_order")}
                </>
              }
            </button>
          ) : (
            <button
              className="pd__addToCart__button pd__related__action__btn"
              onClick={() => AddProductToCart(product?.sku)}
            >
              {loaderOn === true ?
                <Spinner />
                :
                <>
                  <img src={WhiteCartIcon} alt="" />
                  {t("Add_To_Cart")}
                </>
              }
            </button>
          )}
        </>
      )}
    </>
  );
}

export default AddToCartRelatedProduct;
