import React from "react";
import Slider from "react-slick";
import "./../SCSS/_dealsOfTheWeek.scss";
// import { defaultImageHelper } from "./helpers/utils/defaultImageHelper";
import { HalfBannerImageResize } from "./helpers/utils/imageResize";
import LazyImage from "./MostSharedComponent/LazyImage";

const getRandonId = () =>  Math.ceil(Math.random() * 9999) 

function DealsOfTheWeekMobile({ dealsOfTheWeekData, sectionTitle }) {
  const currentLanguageAr = (localStorage.getItem("i18next") || "en") === "ar";

  var settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: '6px',
    arrows: true,
    rtl: currentLanguageAr,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: dealsOfTheWeekData?.length > 2 ? true : false,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 992,
        settings: {
          arrows: true,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div className="container-fluid dealsOfTheWeek__container">
      <div className="dealsOfTheWeek__block">
        <p className="section__title">{sectionTitle}</p>
        <div className="row">
        <Slider {...settings}>
          {dealsOfTheWeekData &&
            dealsOfTheWeekData?.map((deals, dealsIndex) => {
              return (
                <div
                  key={getRandonId()}
                  className="col-sm-12 col-md-6 deals__block"
                >
                  {deals?.deeplinkUrl !== '' && deals?.deeplinkUrl !== undefined && deals?.deeplinkUrl !== null ?
                    <a
                      href={
                        deals?.deeplinkUrl || "#"
                      }
                      target="_blank"
                      rel="noreferrer"
                      aria-label={`Deals Of The Week ${dealsIndex}`}
                    >
                      <LazyImage
                        imgSrc={deals.imageUrl}
                        // srcSetReSize={HalfBannerImageResize}
                        alt="Product Deals"
                        className="deals__image"
                      />
                    </a> : <LazyImage
                      imgSrc={deals.imageUrl}
                      // srcSetReSize={HalfBannerImageResize}
                      alt="Product Deals"
                      className="deals__image"
                    />}
                </div>
              );
            })}
            </Slider>
        </div>
      </div>
    </div>
  );
}

export default DealsOfTheWeekMobile;