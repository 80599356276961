import React from "react";
import { useTranslation } from "react-i18next";
import Accordian from "../Accordian";
import htmlParseHelper from "../helpers/utils/htmlParseHelper";

function PdpAccordian({ product, finalData, productOverviewData, productSpecificationData, productReviewData, getOptionKeyInfo }) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {finalData && finalData[0] && (
                <Accordian
                    data={{
                        title: t("Description"),
                        description:
                            finalData && finalData[0]
                                ? htmlParseHelper(finalData && finalData[0])
                                : "",
                        keyValueTitle: "",
                        keyValueData: [],
                    }}
                    accordianName="description"
                />
            )}

            <Accordian
                data={productOverviewData}
                accordianName="product_overview"
                sku={getOptionKeyInfo(product?.options, "sku")}
                ean={getOptionKeyInfo(product?.options, "ean")}
                mpn={getOptionKeyInfo(product?.options, "mpn")}
                product_overview={getOptionKeyInfo(product?.options, "product_overview")}
            />

            {productSpecificationData !== "" && finalData && finalData[1] && (
                <Accordian
                    // data={productSpecificationData}
                    data={{
                        title: t("Specifications"),
                        description:
                            finalData && finalData[1]
                                ? htmlParseHelper(
                                    finalData[1].substring(
                                        finalData[1].indexOf("<table")
                                    )
                                )
                                : "",
                        keyValueTitle: "",
                        keyValueData: [],
                    }}
                    accordianName="specification"
                />
            )}
            <Accordian data={productReviewData} isReview={true} />
        </React.Fragment>
    );
}

export default PdpAccordian;
