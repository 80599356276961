import React from 'react'
import { useTranslation } from 'react-i18next';

function Search_Details() {
  const { t } = useTranslation();
  return (
    <div>{t("Search_Details")}</div>
  )
}

export default Search_Details