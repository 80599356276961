import React from "react";
// import { useNavigate } from "react-router-dom";

function ProductCategoryLevel({ product }) {

  // const navigate = useNavigate();

  // const redirectPage = (urlKey) => {
  //   navigate(urlKey);
  // }

  return (
    <>
      {product && product?.categories &&
        product?.categories.filter(item => item.name !== "All Categories" && item.name !== "Main Categories" && item.name !== "Default Category" && item.name !== "B2B" && item.is_active === "1" && item.include_in_menu === "1").map((item, index) => {
          let urlKey = `/${item.name.toLowerCase().trim().replace(/ /g, "-")}-c-${item.category_id}-mc-${item.parent_id}`
          if (item.parent_id === "2") {
            urlKey = `/category/${item.name.toLowerCase().trim().replace(/ /g, "-")}-mc-${item.category_id}`
          }
          // return (
          //   <button onClick={() => redirectPage(urlKey)} href={urlKey} className="pd__category__button">{item.name}</button>
          // )
        })
      }
    </>
  );
}

export default React.memo(ProductCategoryLevel);