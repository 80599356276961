import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./../../SCSS/MobileView/_mobileMainFeatureProductsBlock.scss";
import Heading7 from "../Font/Heading7";
import RatingBlock from "../MostSharedComponent/RatingBlock";
import OldPrice from "../Font/OldPrice";
import Price from "../Font/Price";
import empty_favourite from "./../../assets/Icon/empty_favourite.svg";
import fulfill_favourite from "./../../assets/Icon/fulfill_favourite.svg";
// import shopping_cart from "./../../assets/Icon/shopping-cart.svg";
import {
  loadAddToCart,
  loadAddToWishlist,
  loadCartData,
  loadDeleteFromWishlist,
  loadWishlistData,
} from "../../redux/appAction";
import { useDispatch, useSelector } from "react-redux";
import MobileMainFeatureProduct from "../ProductType/MobileMainFeatureProduct";
import { useTranslation } from "react-i18next";
// import { replaceImageHelper } from "../helpers/utils/replaceImageHelper";
// import { productAttributeGet } from "../helpers/utils/productAttributeGet";
import { checkWishList } from "../helpers/utils/wishListHelper";
// import { LoaderIcone } from "../../Pages/Loader";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { checkoutEvents } from "../helpers/utils/ga/checkoutEvents";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";

function MobileMainFeatureProductsBlock({
  sectionTitle,
  featureProductsData,
  backgroundColor,
  handleChangeCartPopup,
  featureOptionsShow,
  outOfStockConfiguration,
  screenName,
  availablityData,
  customerDetails
}) {

  const { setCartErr } = useCartErrorMessage();

  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    featureProductsData.forEach(function callback(value, index) {
      featureProductsData[index].is_price_hide = value?.options?.find((x) => {
        if (x.code === 'is_price_hide') {
          return x.visibility;
        }
        return false;
      });
    });


  }, [featureProductsData]);
  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [count, setCount] = useState(0);
  const { t } = useTranslation();
  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);

  const wishlistData = useSelector((state) => state.appData.wishlistData);
  const { homepageData } = useSelector((state) => state.appData);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, featureProductsData && featureProductsData.length > 0 && featureProductsData[0].sku));
  }, []);

  const handleFavourite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isFavourite) {
      setIsFavourite(false);
      setCount(count + 1);
    } else {
      setIsFavourite(true);
      setCount(count + 1);
    }
  };
  useEffect(() => {
    if (featureProductsData && featureProductsData.length > 0) {
      const data = {
        items: [featureProductsData[0].sku],
      };
      if (isFavourite) {
        if (count > 0) {
          const addToWishlistData = dispatch(loadAddToWishlist(data, t)).then(
            (res) => {
              checkoutEvents.addToWishlist(featureProductsData[0], screenName);
              dispatch(loadWishlistData());
            }
          );
        }
      } else {
        if (count > 0) {
          if (!isFavourite) {
            checkoutEvents.removeFromWishlist(featureProductsData[0], screenName);
            removeFromWL(featureProductsData[0]?.sku);
          }
        }
      }
    }
  }, [isFavourite]);

  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };

    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };

  const AddProductToCart = (sku,type) => {
    // console.log('typetypetype', type);
  
    setLoaderOn(true);
    const data = {
      items: [
        {
          sku: sku,
          id:featureProductsData[type]?.id,
          qty: 1,
        },
      ],
    };

    const loadAddToCartDAta = dispatch(loadAddToCart(data))
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(featureProductsData[0], item?.id)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
        setLoaderOn(false);
      })
      .catch((err) => {
        setLoaderOn(false);
        setCartErr({ stock: featureProductsData[type]?.stock, error: err?.response?.data });
      });
  };

  // let color_resolution_0 = ""
  // let screen_size_0 = ""
  // if (featureProductsData !== undefined && featureProductsData.length > 0) {
  //   color_resolution_0 = productAttributeGet(featureProductsData[0]?.options, 'color_resolution');
  //   screen_size_0 = productAttributeGet(featureProductsData[0]?.options, 'screen_size');
  // }

  return (
    <>
      {featureProductsData !== undefined && featureProductsData.length > 0 && (
        <>
          <div
            className="container-fluid mobile__dealsOfTheDay__container feature__category__container"
            style={{ backgroundColor: `${backgroundColor}` }}
          >
            <div className=" mobile__dealsOfTheDay__block">
              {!featureOptionsShow && (
                <h1 className="section__title ">{sectionTitle}</h1>
              )}
              <div className=" inner__mobile__dealsOfTheDay__block">
                <div className="row second__md__product__block">
                  {featureProductsData && featureProductsData.map((val, i) => {
                    return (
                      <div key={`mobile_main_feature_${i}`} className="col-6 mb__feature__col">
                        <MobileMainFeatureProduct
                          key={val?.sku}
                          handleChangeCartPopup={handleChangeCartPopup}
                          product={val}
                          featureOptionsShow={featureOptionsShow}
                          outOfStockConfiguration={outOfStockConfiguration}
                          productIndex={i}
                          screenName={screenName}
                          availablityData={availablityData || []}
                          customerDetails={customerDetails}
                        />
                      </div>
                    )
                  })}                  
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default MobileMainFeatureProductsBlock;
