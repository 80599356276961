import React from 'react';
import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Heading3 from '../Font/Heading3';
import Heading6 from '../Font/Heading6';
import Text3 from '../Font/Text3';
import PhoneInput from 'react-phone-input-2';
import cancel_grey from "./../../assets/Icon/cancel_grey.svg";
import { useFormik } from 'formik'
import { emailValidator, isMobileNumber } from '../helpers/utils/validators';
import { productStockNotification } from '../../services/common/common';
import { getCustomerLoginDetails } from '../helpers/utils/getCustomerLoginDetails';
import * as services from "./../../services/services"
import { useDispatch } from "react-redux";

function NotifyMeModal({ modalShow, onHide, size, product, openSuccessModal }) {

  const customerData = getCustomerLoginDetails();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const T_REQ_EMAIL = t("Email_is_missing");
  const T_INVALID_EMAIL = t("Invalid_email_address_example");
  const T_REQ_MOBILE_NUMBER = t("Mobile Number is missing");
  const T_INVALID_MOBILE_NUMBER = t("Invalid_Mobile_Number");

  const formik = useFormik({
    initialValues: {
      mobileNumber: '',
      email: '',
    },
    onSubmit: values => {
      notifyProduct()
    },
    validate: values => {
      let errors = {};
      let isValidNumber = isMobileNumber(values.mobileNumber);

      if (!values.mobileNumber) {
        errors.mobileNumber = T_REQ_MOBILE_NUMBER
      } else if (isValidNumber === "error") {
        errors.mobileNumber = T_INVALID_MOBILE_NUMBER
      }

      let emailStatus = emailValidator(values.email);
      if (!values.email) {
        errors.email = T_REQ_EMAIL
      } else if (emailStatus === "error") {
        errors.email = T_INVALID_EMAIL
      }

      return errors
    }
  })

  const handleChange = (e, name) => {
    let value;
    if (name === "mobileNumber") {
      value = e
    } else {
      value = e.target.value
    }
    let ev = {
      target: {
        value: value,
        name: name,
        id: name
      }
    }
    formik.handleChange(ev)
  }

  const notifyProduct = () => {
    let reqParams = {
      customerId: customerData?.id,
      customerName: customerData?.firstname,
      email: formik.values.email,
      phone: formik.values.mobileNumber,
      productId: product.id,
      sku: product.sku
    }
    const reqData = productStockNotification(reqParams);
    reqData.then((res) => {
      if (!res.data.status) {
        dispatch(services.notifyError({ message: res?.data?.message }));
      } else {
        openSuccessModal(2, false);
        openSuccessModal(3, true);
      }
    }).catch((err) => {
      dispatch(services.notifyError({ message: err?.response?.data?.message }));
      // console.log("err ", err);
    })
  }

  const resetAndCloseForm = () => {
    formik.resetForm()
    onHide()
  }

  return (
    <Modal
      show={modalShow}
      onHide={(e) => resetAndCloseForm(e)}
      backdrop="static"
      keyboard={false}
      size={size}
      className='notify__modal'
    >
      <Modal.Header >
        <Modal.Title></Modal.Title>
        <img
          onClick={(e) => resetAndCloseForm(e)}
          src={cancel_grey}
          alt="cancel"
          className="cancel__button"
        />
      </Modal.Header>
      <Modal.Body className='notity__me__modal'>
        <Heading3 text={t("Notify Me When Product Is back on Stock")} />
        <Text3 text={t("Enter your Email/Mobile number and we will notify you when the product is available again")} marginBottom={25} />
        <div className='notify__formbox'>
          <form onSubmit={formik.handleSubmit}>
            <div className="main__form__field__block notify__email__block">
              <Heading6 text={t("Email_Address")} marginBottom={10} />
              <div className="field__block">
                <input
                  type="text"
                  placeholder={t("Email_Address")}
                  className="form__field"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={(e) => handleChange(e, "email")}
                />
              </div>
              {formik.errors.email && <p className="invalid__message">{formik.errors.email}</p>}
            </div>
            <div className="main__form__field__block notify__mobile__block">
              <Heading6 text={t("Mobile_Number")} marginBottom={10} />
              <PhoneInput
                inputProps={{
                  name: "mobileNumber",
                  required: true,
                  className: "profile__mobile__form__field",
                }}
                country="sa"
                onlyCountries={["sa"]}
                masks={{ sa: ".. ... ...." }}
                countryCodeEditable={false}
                disableDropdown={true}
                id="mobileNumber"
                name="mobileNumber"
                className=""
                value={formik.values.mobileNumber}
                onChange={(e) => handleChange(e, "mobileNumber")}
              />
              {formik.errors.mobileNumber && <p className="invalid__message">{formik.errors.mobileNumber}</p>}
            </div>
            <div className="d-grid gap-3 mt-3 notify__form__btnBox">
              <Button type="submit" className='notify__add__btn notify__me' variant='link' size="lg" onClick={formik.handleSubmit}>
                {t('Notify_Me')}
              </Button>
              <Button className='notify__close' variant='link' size="lg" onClick={(e) => resetAndCloseForm(e)} >
                {t('Close')}
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default NotifyMeModal;
