import React from "react";
import star from "./../../assets/Icon/orange-review.svg";
import "./../../SCSS/ProductListPage/_plpFilter.scss";
import Heading6 from "./../../Components/Font/Heading6";
import Text2 from "./../../Components/Font/Text2";
import { useTranslation } from "react-i18next";
import Accordion from "react-bootstrap/Accordion";
// import BrandFacet from "./Facets/BrandFacet";
import GenreFacet from "./Facets/GenreFacet";
import PriceFacet from "./Facets/PriceFacet";
import ColorFacet from "./Facets/ColorFacet";
import HdmiFacet from "./Facets/HdmiFacet";
import ProcessorFacet from "./Facets/ProcessorFacet";
import OperatingSystemFacet from "./Facets/OperatingSystemFacet";
import ScreenSizeFacet from "./Facets/ScreenSizeFacet";
import MotionFlowFacet from "./Facets/MotionFlowFacet";
import InterfaceFacet from "./Facets/InterfaceFacet";
import MegaPixelsFacet from "./Facets/MegaPixelsFacet";
import AutoFocusFacet from "./Facets/AutoFocusFacet";
import TypeFacet from "./Facets/TypeFacet";
import ColorResolutionFacet from "./Facets/ColorResolutionFacet";
import { useLocation } from "react-router";

const PLPFilter = ({
  onFilter,
  filterOptionData,
  filterInitData,
  handleClearFilter,
  categoryType,
  filterHide,
}) => {
  const { t } = useTranslation();
  const location = useLocation()

  return filterOptionData?.facets?.priceRange.max > 0 ? (
    <Accordion alwaysOpen={true} defaultActiveKey={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}>
      <p className={`main__facets__clear_filter`}
        onClick={() => {
          if (location?.search) {
            handleClearFilter()
          }
        }}
      >
        {t("clear_filter")}
      </p>
      {(filterOptionData?.total_count?.value || filterOptionData?.total_count) !== 0 &&
        filterOptionData &&
        Object.keys(filterOptionData.facets).map((key, index) => {
          return (
            <div key={`facet_group_${key}${index}`}>
              {/* {key === "brand" && (
                <BrandFacet
                  onFilter={onFilter}
                  facetData={filterOptionData.facets}
                  facetKey={key}
                  values={filterInitData?.brand}
                />
              )} */}
              {key === "price" && (
                <PriceFacet
                  onFilter={onFilter}
                  facetData={filterOptionData.facets}
                  facetKey={key}
                  values={filterInitData?.display_price}
                  desktop={true}
                  eventKey={0}
                />
              )}
              {key === "type" && categoryType === "Televisions" && (
                <TypeFacet
                  onFilter={onFilter}
                  facetData={filterOptionData.facets}
                  facetKey={key}
                  values={filterInitData?.type}
                  desktop={true}
                  eventKey={11}
                />
              )}
              {key === "color_resolution" && categoryType === "Televisions" && (
                <ColorResolutionFacet
                  onFilter={onFilter}
                  facetData={filterOptionData.facets}
                  facetKey={key}
                  values={filterInitData?.color_resolution}
                  desktop={true}
                  eventKey={12}
                />
              )}
              {key === "color" && !filterHide && (
                <ColorFacet
                  onFilter={onFilter}
                  facetData={filterOptionData.facets}
                  facetKey={key}
                  values={filterInitData?.color}
                  desktop={true}
                  eventKey={1}
                />
              )}
              {key === "genre" && !filterHide && (
                <GenreFacet
                  onFilter={onFilter}
                  facetData={filterOptionData.facets}
                  facetKey={key}
                  desktop={true}
                  eventKey={2}
                />
              )}
              {key === "hdmi_input" && !filterHide && (
                <HdmiFacet
                  onFilter={onFilter}
                  facetData={filterOptionData.facets}
                  facetKey={key}
                  values={filterInitData?.hdmi_input}
                  desktop={true}
                  eventKey={3}
                />
              )}
              {key === "processor" && !filterHide && (
                <ProcessorFacet
                  onFilter={onFilter}
                  facetData={filterOptionData.facets}
                  facetKey={key}
                  values={filterInitData?.processor}
                  desktop={true}
                  eventKey={4}
                />
              )}
              {key === "operating_system" && !filterHide && (
                <OperatingSystemFacet
                  onFilter={onFilter}
                  facetData={filterOptionData.facets}
                  facetKey={key}
                  values={filterInitData?.operating_system}
                  desktop={true}
                  eventKey={5}
                />
              )}
              {key === "screen_size" && categoryType === "Televisions" && (
                <ScreenSizeFacet
                  onFilter={onFilter}
                  facetData={filterOptionData.facets}
                  facetKey={key}
                  values={filterInitData?.screen_size}
                  desktop={true}
                  eventKey={6}
                />
              )}
              {key === "motionflow" && !filterHide && (
                <MotionFlowFacet
                  onFilter={onFilter}
                  facetData={filterOptionData.facets}
                  facetKey={key}
                  values={filterInitData?.motionflow}
                  name="motionflow_desktop"
                  desktop={true}
                  eventKey={7}
                />
              )}
              {key === "interface" && !filterHide && (
                <InterfaceFacet
                  onFilter={onFilter}
                  facetData={filterOptionData.facets}
                  facetKey={key}
                  values={filterInitData?.interface}
                  desktop={true}
                  eventKey={8}
                />
              )}
              {key === "megapixels" && !filterHide && (
                <MegaPixelsFacet
                  onFilter={onFilter}
                  facetData={filterOptionData.facets}
                  facetKey={key}
                  values={filterInitData?.megapixels}
                  desktop={true}
                  eventKey={9}
                />
              )}
              {key === "autofocus_type" && !filterHide && (
                <AutoFocusFacet
                  onFilter={onFilter}
                  facetData={filterOptionData.facets}
                  facetKey={key}
                  values={filterInitData?.autofocus_type}
                  desktop={true}
                  eventKey={10}
                />
              )}
              {/* <HdmiFacet />
              <ProcessorFacet /> */}
              {/* {key === 'price' && <PriceFacet t={t} filterOptionData={filterOptionData.facets} dataKey={key} appliedFilters={appliedFilters} toggleFilter={toggleFilter} />}
          {key === 'priceRange' && <PriceRange t={t} filterOptionData={filterOptionData} appliedFilters={appliedFilters} toggleFilter={toggleFilter} />}
          {key === 'color' && <ColorFacet t={t} filterOptionData={filterOptionData} dataKey={key} appliedFilters={appliedFilters} toggleFilter={toggleFilter} />}
        {key === 'genre' && <GenreFacet t={t} filterOptionData={filterOptionData} dataKey={key} appliedFilters={appliedFilters} toggleFilter={toggleFilter} />} */}
            </div>
          );
        })}
    </Accordion>
  ) : (
    ""
  );
};
export default PLPFilter;
