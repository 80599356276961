import React, { useState, useEffect } from 'react'
import Price from '../Font/Price'
import Text3 from '../Font/Text3';

export default function OrderItems({ index, item, titleChange, cartTotalData, t, cartDetail }) {
    const [bundleProducts, setBundleProducts] = useState([]);

    const totals = cartDetail?.totals_data?.items?.find(
        (prod) => prod.item_id === item.item_id,
        );
    const unitPrice = cartDetail?.totals_data?.items?.find(
        (prod) => prod.item_id === item.item_id,
        )?.base_row_total_incl_tax;   
        
    useEffect(() => {
        if (totals?.extension_attributes?.options_bundle) {
            setBundleProducts(
                totals.extension_attributes.options_bundle
                    .filter((itm) => itm.title.toLowerCase().includes('service'))
                    .map((item) => ({
                        title: item.title,
                        price: item.price,
                        quantity: item.qty,
                    })),
            );
        }
    }, [totals]);

    return (
         <li className="d-flex  align-items-start w-100" key={index}>
                <p className="m-0 summary__pro__name">
                <span className="order__count">{`X${item.qty}`}</span>
                {titleChange(item?.name)}
                <span >
                    {item?.extension_attributes?.cashback_amount > 0 && (
                        <div className="pd__mb__cashback" >
                            {t('GET')}
                            &nbsp;
                            <Price
                                price={
                                    item?.extension_attributes?.cashback_amount || 0
                                }
                                size="text5"
                                color="#168F00" 
                                currency={
                                    item.product.currency
                                }
                            />
                            &nbsp;
                            {t('Cashback')}
                        </div>
                    )}
                </span>
                {bundleProducts.length > 0 ? (
                    bundleProducts.map((itm) => (
                        // <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className="service">
                                {`${t('includes:')} ${itm.title}`}
                            </span>
                        // </div>
                    ))
                ) : (
                    <>
                        {item?.product_type === 'bundle' && (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className="service">
                                    {t('without_installation_service')}
                                </div>
                            </div>
                        )}
                    </>
                )}
                </p>
             
                <div className="summary__pro__price">
                    {totals?.discount_percent < 100 && (
                        <Price
                            price={
                                totals?.base_discount_amount
                                ? (item.price  - totals?.base_discount_amount) + totals?.base_tax_amount
                                : (item.price * item.qty) + totals?.base_tax_amount
                            }
                            size="heading7"
                            currency={cartTotalData?.base_currency_code}
                        />
                    )}
                     {totals?.discount_percent >= 100 && (
                        <Text3
                            text={`Free`}
                            color="#07A41B"
                        />
                    )}
                    {totals?.base_discount_amount > 0 && (
                        <div className='cross-price'>
                            <div className="summary__discount_price">{`${t('SAR')} ${unitPrice}`}</div>
                        </div>
                    )}
                </div>
            </li>
    )
}
