import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import valid from "card-validator";


import "../../SCSS/MobilePages/_mobileCheckoutPage.scss"
import * as services from "../../services/services";


import signin_initial from "../../assets/Icon/signin_inprogress.svg";
import signin_inprogress from "../../assets/Icon/signin_inprogress.svg";
import signin_done from "../../assets/Icon/signin_done.svg";
import delivery_initial from "../../assets/Icon/delivery_initial.svg";
import delivery_inprogress from "../../assets/Icon/delivery_inprogress.svg";
import delivery_done from "../../assets/Icon/delivery_done.svg";
import payment_initial from "./../../assets/Icon/payment_initial.svg";
import payment_inprogress from "./../../assets/Icon/payment_inprogress.svg";
import payment_done from "./../../assets/Icon/payment_done.svg";
import done from "../../assets/Icon/done.svg";
import mezzacard from "./../../assets/Icon/mezzacard.png";
import visacard from "./../../assets/Icon/visacard.png";
import madacard from "./../../assets/Icon/madacard.png";
import mastercard2 from "./../../assets/Icon/mastercard-2.png";
import macicon from "./../../assets/Icon/macicon.png";
import tamaracard from "./../../assets/Icon/tamara.png";
import tabbybadge from "./../../assets/Icon/tabby-badge.png";


import Heading7 from '../../Components/Font/Heading7';
import CartRedeemLoyalty from '../../Components/Checkout/CartRedeemLoyalty';
import { useTranslation } from 'react-i18next';
import { getAvailablePaymentMethods, getPayfortInformation, getPaymentMethod, getTamaraData, placeOrderCOD, updateLoyaltyEarnTypes, verifyLoyaltyPoints, EligibleBinDiscount } from '../../services/cart.service';
import CartLoyaltyBonus from '../../Components/Checkout/CartLoyaltyBonus';
import { isRedeemMezaPhone } from '../../Components/helpers/utils/validators';
import { createLoyaltyOtp } from '../../services/customer/customer';
import { getLoyaltyEarnTypesData, loadCartData, resetNiqatyFlow } from '../../redux/appAction';
import Text4 from '../../Components/Font/Text4';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from '../../Components/helpers/utils/cardValidator';
import { getTamaraValue } from '../../Components/helpers/utils/handsakeConfig';
import TamaraInstallmentPlanWidget from '../TamaraInstallmentPlanWidget';
import TabbyWidget from '../../Components/MostSharedComponent/TabbyWidget';
import { Link, useNavigate } from 'react-router-dom';
import ProcessingPayment from '../ProcessingPayment';
import { Spinner } from '../../Components/helpers/utils/spinner';
import useWindowDimensions from '../../Components/helpers/utils/useWindowDimensions';
import ApplePay from '../../Components/ApplePay/ApplePay';
import VisaHolderNote from '../../Components/ApplePay/VisaHolderNote';
import { impressionEvents } from '../../Components/helpers/utils/ga/impressionEvents';
import CartApplyStoreCredit from '../../Components/Checkout/CartApplyStoreCredit';
import AmwalMagentoReactButton from 'amwal-magento-react-button';
import LoyaltyNiqatyProgram from '../../Components/Checkout/LoyaltyNiqatyProgram';

const MobilePayment = ({
  BASE_PAYMENT_REDIRECT_URL,
  cartTotalData,
  ecom,
  mokafaApplied,
  cartDetail,
  checkVirtualItemsInCart,
  currentLanguageCode,
  loyaltyEarnTypeId,
  tamara3MonthStatus,
  tamara6MonthStatus,
  cartData,
  custEmailAddress,
  allStoreConfig,
  isAppleDevice
}) => {

  const [iconType, setIconType] = useState({
    signin: "done",
    delivery: "done",
    payment: "initial",
  });
  const [card, setCard] = useState({
    cardNumber: "",
    cardHolder: "",
    month: "",
    year: "",
    cvv: "",
  });
  const [cardErrMsg, setCardErrMsg] = useState({
    cardNumber: "",
    cardHolder: "",
    month: "",
    year: "",
    cvv: "",
  });
  const [userPaymentMethod, setUserPaymentMethod] = useState();
  const [paymentMethodForPayfort, setPaymentMethodForPayfort] = useState({
    method: "",
    email: "",
    referer_url: "",
  });
  const [payWithLoyalty, setPayWithLoyalty] = useState({
    phone: "",
    code: "",
    points: "",
    step: 1,
  });
  const [payWithLoyaltyErr, setPayWithLoyaltyErr] = useState({
    phone: "",
    code: "",
    points: "",
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [preferenceTab, setPreferenceTab] = useState("");
  const [cardIsDisabled, setCardIsDisabled] = useState(true);
  const [pending, setPending] = useState(false);
  const [payfortData, setPayfortData] = useState(null);
  const [redirectPageUrl, setRedirectPageUrl] = useState("");
  const [isError, setIsError] = useState(false);
  const [loyaltyLoading, setLoyaltyLoading] = useState(false);
  const [showApplePay, setShowApplePay] = useState(false);
  const [hideSection, setHideSection] = useState(false);
  const [note, setNote] = useState('');
  const [amwalState, setAmwalState] = useState();
  const [forceRender, setForceRender] = useState(false);
  const isAmwalEnabled = ecom?.featureFlags?.ecom?.bootstraper?.amwal;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const payfortForm = useRef();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const { customerDetails } = useSelector((state) => state.customerReducer);
  const { availablePaymentMethodData } = useSelector((state) => state.appData);
  const loadingData = useSelector((state) => state.commonReducer);
  const niqatyShouldReset = useSelector((state) => state.appData.niqatyResetFlow);

  const T_REQ_MOBILE_NUMBER = t("Mobile_Number_is_required");
  const T_REQ_INVALID_MOBILE_NUMBER = t("Invalid_Mobile_Number");
  const T_REQ_CODE = t("Code_is_missing");
  const T_REQ_POINTS = t("Points_is_missing");
  const safari = navigator.userAgent.indexOf("Safari") !== -1;
  const localStorageHandshakeToken = localStorage.getItem("handShakeToken");

  useEffect(() => {
    if (userPaymentMethod === 'niqaty') {
      setUserPaymentMethod(null);
    }
    dispatch(resetNiqatyFlow(false));
  }, [niqatyShouldReset]);

  useEffect(() => {
    let lastDt = localStorage.getItem("last-dt");
    if (lastDt === "flatrate") {
      lastDt = "home";
    } else if (lastDt === "storepickup") {
      lastDt = "storepickup";
    } else {
      lastDt = "";
    }
    setPreferenceTab(lastDt);

    const lastPayment = localStorage.getItem("last-setPaymentMethod");
    if (lastPayment) {
      reserCardData();
      if (lastPayment !== "meza" && lastPayment !== "qitaf" && lastPayment !== "mokafa") {
        // setUserPaymentMethod(lastPayment);
        setPaymentMethodForPayfort({
          method: lastPayment,
          referer_url:
            process.env.NODE_ENV === "production"
              ? BASE_PAYMENT_REDIRECT_URL
              : BASE_PAYMENT_REDIRECT_URL,
          email: custEmailAddress,
        });

        let params = {
          email: custEmailAddress,
          paymentMethod: {
            method: lastPayment,
          },
        };
        getPaymentMethodOrderData(params);
      } else {
        setPayWithLoyalty({
          ...payWithLoyalty,
          phone: "",
          code: "",
          points: "",
          step: 1,
        });
        setPayWithLoyaltyErr({
          ...payWithLoyaltyErr,
          phone: "",
          code: "",
          points: "",
        });
      }
    }

    // const deliveryRef = localStorage.getItem("deliveryPref");
    // if (deliveryRef) {
    //   setDeliveryPreferencesType(deliveryRef);
    // }
  }, []);
  
  useEffect(() => {
    if (redirectPageUrl !== "") {
      navigate(redirectPageUrl);
      setRedirectPageUrl("");
    }
  }, [redirectPageUrl]);
  useEffect(() => {
    setAmwalState(cartData?.totals_data?.grand_total);
  }, [cartData?.totals_data?.grand_total]);
  useEffect(() => {
    setForceRender((prevState) => !prevState);
  }, [amwalState]);

  const contact_prefill = localStorage.getItem("contact_prefill");
  // useEffect(() => {
  //   if (customerDetails === "") {
  //     if (contact_prefill !== null) {
  //       setCustEmailAddress(JSON.parse(contact_prefill).email);
  //     } else {
  //       // setCustEmailAddress("");
  //     }
  //   } else {
  //     setCustEmailAddress(customerDetails.email);
  //   }
  // }, [customerDetails, contact_prefill]);

  // useEffect(() => {
  //   setPaymentMethods(availablePaymentMethodData?.paymentMethods ? availablePaymentMethodData?.paymentMethods : []);
  // }, [availablePaymentMethodData])

  useEffect(() => {
    if (custEmailAddress && custEmailAddress !== "" && cartData?.id !== undefined && cartData?.billing_address?.email !== null && width <= 992) {
      // setPaymentMethods([])
      dispatch(services.loaderStart())
      dispatch(getAvailablePaymentMethods()).then((item) => {
        if (item && custEmailAddress) {
          setPaymentMethods(item?.paymentMethods);
          // setUserPaymentMethod(data.paymentMethods[0].code);
          var radios = document.getElementsByName("paymentType");
          var selected = Array.from(radios).find((radio) => radio.checked);
          setPaymentMethodForPayfort({
            method: selected === undefined ? "" : selected.value,
            referer_url:
              process.env.NODE_ENV === "production"
                ? BASE_PAYMENT_REDIRECT_URL
                : BASE_PAYMENT_REDIRECT_URL,
            email: custEmailAddress,
          });

          if (selected !== undefined) {
            let params = {
              email: custEmailAddress,
              paymentMethod: {
                method: selected.value,
              },
            };
            getPaymentMethodOrderData(params);
          }
        }
      });
      dispatch(services.loaderEnd())
    }
    // dispatch(services.getCustomerAddressList());
    // dispatch(loadCountriesLocationData());
    // dispatch(loadCitiesLocationData());
  }, [cartData]);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length > 0) {
      dispatch(services.loaderEnd())
    } else {
      dispatch(services.loaderStart())
    }
  }, [paymentMethods])

  useEffect(() => {
    if(cartDetail?.totals_data?.base_grand_total <= 0) {
      setHideSection(true)
    } else {
      setHideSection(false)
    }
  }, [cartDetail])

  useEffect(() => {
    if (customerDetails === "") {
      //   openLoginWrapperFromAnywhere();
      setIconType({ ...iconType, signin: "inprogress" });
    } else {
      dispatch(services.getCustomerAddressList());
      setIconType({ ...iconType, signin: "done" });
    }
  }, [customerDetails]);

  const openLoginWrapperFromAnywhere = () => {
    // console.log(document.querySelector(".login__popup__container__disable"));
    // reloadingHeader()

    if (customerDetails === "") {
      const element = document.querySelector(
        ".signin__signup__popup__disable"
      );
      if (element !== null) {
        element.classList.remove("signin__signup__popup__disable");
        element.classList.add("signin__signup__popup");
      }
      localStorage.setItem("loginWrapper", JSON.stringify(true));
      localStorage.setItem("loginMode", JSON.stringify("signin"));
      localStorage.setItem("loginPopup", JSON.stringify(true));
      window.scrollTo(500, 0);
    }
  };

  const reserCardData = () => {
    setCard({
      ...card,
      cardNumber: "",
      cardHolder: "",
      monthYear: "",
      month: "",
      year: "",
      cvv: "",
    });
    setCardErrMsg({
      ...cardErrMsg,
      cardNumber: "",
      cardHolder: "",
      monthYear: "",
      month: "",
      year: "",
      cvv: "",
    });
  };

  const getPaymentMethodOrderData = async (params) => {
    await getPaymentMethod(params)
      .then((res) => {
        // if (res?.data) {
        //   setCartTotalData(res?.data);
        // }
      })
      .catch((error) => {
        // console.log("error ", error);
      });
  };

  const handleChangePaymentMethod = (e) => {
    setIsError(false)
    reserCardData();
    setUserPaymentMethod(e.target.value);
    if (e.target.value !== "meza" && e.target.value !== "qitaf" && e.target.value !== "mokafa") {
      setPaymentMethodForPayfort({
        method: e.target.value,
        referer_url:
          process.env.NODE_ENV === "production"
            ? BASE_PAYMENT_REDIRECT_URL
            : BASE_PAYMENT_REDIRECT_URL,
        email: custEmailAddress,
      });

      let params = {
        email: custEmailAddress,
        paymentMethod: {
          method: e.target.value,
        },
      };
      getPaymentMethodOrderData(params);
    } else {
      setPayWithLoyalty({
        ...payWithLoyalty,
        phone: "",
        code: "",
        points: "",
        step: 1,
      });
      setPayWithLoyaltyErr({
        ...payWithLoyaltyErr,
        phone: "",
        code: "",
        points: "",
      });
    }
  };

  const handleNiqatyComplete = () => {
    setLoyaltyLoading(true);
    setPayWithLoyalty({
      ...payWithLoyalty,
      phone: '',
      code: '',
      points: '',
      step: 1,
    });
    setPayWithLoyaltyErr({
      phone: '',
      code: '',
      points: '',
    });
    setUserPaymentMethod();
    dispatch(loadCartData());
    dispatch(services.loaderStart());
    dispatch(getAvailablePaymentMethods());
    dispatch(services.loaderEnd());
    dispatch(getLoyaltyEarnTypesData());
    setLoyaltyLoading(false);
};

  const handleChangeLoyalty = (event) => {
    let value = event.target.value.replace(/[^0-9]/g, "");
    let name = event.target.name;
    setPayWithLoyalty({
      ...payWithLoyalty,
      [name]: value,
    });
    if (value) {
      setPayWithLoyaltyErr({ ...payWithLoyaltyErr, [name]: "" });
      if (name === "phone") {
        let checkPhone = isRedeemMezaPhone(value);
        if (checkPhone === "error") {
          setPayWithLoyaltyErr({
            ...payWithLoyaltyErr,
            [name]: T_REQ_INVALID_MOBILE_NUMBER,
          });
        }
      }
    } else {
      if (name === "phone") {
        setPayWithLoyaltyErr({
          ...payWithLoyaltyErr,
          [name]: T_REQ_MOBILE_NUMBER,
        });
      } else if (name === "code") {
        setPayWithLoyaltyErr({ ...payWithLoyaltyErr, [name]: T_REQ_CODE });
      } else if (name === "points") {
        setPayWithLoyaltyErr({ ...payWithLoyaltyErr, [name]: T_REQ_POINTS });
      }
    }
  };

  const applyForLoyaltyOtp = () => {
    if (payWithLoyalty.phone === "") {
      setPayWithLoyaltyErr({
        ...payWithLoyaltyErr,
        phone: T_REQ_MOBILE_NUMBER,
      });
    } else {
      let checkPhone = isRedeemMezaPhone(payWithLoyalty.phone);
      if (checkPhone === "error") {
        setPayWithLoyaltyErr({
          ...payWithLoyaltyErr,
          phone: T_REQ_INVALID_MOBILE_NUMBER,
        });
      } else {
        setPayWithLoyaltyErr({ ...payWithLoyaltyErr, phone: "" });
        setLoyaltyLoading(true);
        let params = {
          phone: payWithLoyalty.phone,
          program: userPaymentMethod,
        };
        let respData = createLoyaltyOtp(params);
        respData
          .then((res) => {
            setLoyaltyLoading(false);
            setPayWithLoyalty({
              ...payWithLoyalty,
              step: 2,
            });
          })
          .catch((err) => {
            setLoyaltyLoading(false);
            dispatch(
              services.notifyError({ message: err?.response?.data?.message })
            );
          });
      }
    }
  };

  const confirmLoyaltyPointsPayment = () => {
    if (payWithLoyalty.points === "" && payWithLoyalty.code === "") {
      let newErrObj = payWithLoyaltyErr;
      if (payWithLoyalty.points === "") {
        newErrObj = { ...newErrObj, points: T_REQ_POINTS };
      }
      if (payWithLoyalty.code === "") {
        newErrObj = { ...newErrObj, code: T_REQ_CODE };
      }
      setPayWithLoyaltyErr(newErrObj);
    } else {
      setLoyaltyLoading(true);
      let params = {
        phone: payWithLoyalty.phone,
        amount: payWithLoyalty.points,
        program: userPaymentMethod,
        otp: payWithLoyalty.code,
      };
      let respData = verifyLoyaltyPoints(params);
      respData
        .then((res) => {
          setLoyaltyLoading(false);
          setPayWithLoyalty({
            ...payWithLoyalty,
            step: "",
          });
          dispatch(loadCartData());
          dispatch(getAvailablePaymentMethods());
          dispatch(getLoyaltyEarnTypesData());
        })
        .catch((err) => {
          setLoyaltyLoading(false);
          dispatch(
            services.notifyError({ message: err?.response?.data?.message })
          );
        });
    }
  };

  const validateForm = (event, newErrObj, name, value) => {
    //A function to validate each input values
    switch (name) {
      case "cardNumber":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: t("Card_Number_is_missing") };
        } else {
          let numberValidation = valid.number(value);
          if (
            numberValidation.isPotentiallyValid === true &&
            numberValidation.isValid === true
          ) {
            setCardIsDisabled(false);
            newErrObj = { ...newErrObj, [name]: "", month: "", year: "" };
          } else {
            setCardIsDisabled(true);
            newErrObj = { ...newErrObj, [name]: t("Card_Number_is_invalid") };
          }
        }
        break;
      case "cardHolder":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: t("Card_Holder_is_missing") };
        } else {
          let holderValidation = valid.cardholderName(value);
          if (
            holderValidation.isPotentiallyValid === true &&
            holderValidation.isValid === true
          ) {
            newErrObj = { ...newErrObj, [name]: "" };
          } else {
            newErrObj = { ...newErrObj, [name]: t("Card_Holder_is_invalid") };
          }
        }
        break;
      case "monthYear":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: t("Date_is_missing") };
        } else {
          let monthValidation = valid.expirationDate(value);
          if (
            monthValidation.isPotentiallyValid === true &&
            monthValidation.isValid === true
          ) {
            newErrObj = {
              ...newErrObj,
              [name]: "",
              month: monthValidation.month,
              year: monthValidation.year,
            };
          } else {
            newErrObj = { ...newErrObj, [name]: t("Date_is_invalid") };
          }
        }
        break;
      case "month":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: t("Date_is_missing") };
        } else {
          let monthValidation = valid.expirationMonth(value);
          if (
            monthValidation.isPotentiallyValid === true &&
            monthValidation.isValid === true
          ) {
            newErrObj = { ...newErrObj, [name]: "" };
          } else {
            newErrObj = { ...newErrObj, [name]: t("Date_is_invalid") };
          }
        }
        break;
      case "year":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: "Year is missing" };
        } else {
          let yearValidation = valid.expirationYear(value);
          if (
            yearValidation.isPotentiallyValid === true &&
            yearValidation.isValid === true
          ) {
            newErrObj = { ...newErrObj, [name]: "" };
          } else {
            newErrObj = { ...newErrObj, [name]: "invalid" };
          }
        }
        break;
      case "cvv":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: t("CVV_is_missing") };
        } else {
          let cvvValidation = valid.cvv(value);
          if (
            cvvValidation.isPotentiallyValid === true &&
            cvvValidation.isValid === true
          ) {
            newErrObj = { ...newErrObj, [name]: "" };
          } else {
            newErrObj = { ...newErrObj, [name]: t("CVV_is_invalid") };
          }
        }
        break;
      default:
        break;
    }
    return newErrObj;
  };
  const EligbleBinDiscount = async (trimmedValue) => {
    if (trimmedValue) {
      const response = await EligibleBinDiscount(trimmedValue);
      if (response?.isEligible) {
        setNote({__html : response?.note});
        
      } else {
        setNote('');
      }
    }
  };
  const handleChangeCard = async (event) => {
    let value = event.target.value;
    let name = event.target.name;
    if (name === "cardNumber") {
      value = formatCreditCardNumber(value);
    } else if (name === "monthYear") {
      value = formatExpirationDate(value);
    } else if (name === "cvc") {
      value = formatCVC(value);
    }

    let manageErrMsg = validateForm(event, cardErrMsg, name, value);
    if(name === "cardNumber"){
      if(!manageErrMsg.cardNumber && card.cardNumber){
          EligbleBinDiscount(card.cardNumber.replace(/\s/g, ''));
      } else {
        setNote('');
      }
    }
    setCardErrMsg(manageErrMsg);
    setCard({ ...card, [name]: value });
  };

  const allFeildValidate = (validateFeild, allErrMsg) => {
    let checkValueStatus = [];
    let checkErrStatus = [];

    validateFeild &&
      validateFeild.map((val, i) => {
        let keyVal = card[val];
        let errVal = cardErrMsg[val];

        allErrMsg = validateForm("", allErrMsg, val, keyVal);
        if (keyVal !== "") {
          checkValueStatus.push("suc");
        }
        if (errVal === "") {
          checkErrStatus.push("err");
        }
      });

    let checkCardStatus = false;
    if (
      checkValueStatus.length === validateFeild.length &&
      checkErrStatus.length === validateFeild.length
    ) {
      checkCardStatus = true;
    }
    let returnData = {
      allErrMsg: allErrMsg,
      checkCardStatus: checkCardStatus,
    };

    return returnData;
  };

  useEffect(() => {
    const inputs = document?.getElementsByClassName("payment__input__check")
    const res = [...inputs]?.filter((item) => item.checked);
    setIsError(res.length < 1 || !userPaymentMethod)
  }, [userPaymentMethod])

  const makePayment = async () => {
    if (paymentMethodForPayfort.method === "payfort_fort_cc") {
      let validateFeild = ["cardNumber", "cardHolder", "monthYear", "cvv"];

      let formStatus = allFeildValidate(validateFeild, cardErrMsg);
      setCardErrMsg(formStatus.allErrMsg);

      if (formStatus.checkCardStatus === true) {
        const newPaymentMethodForPayfort = {
          paymentMethod: paymentMethodForPayfort,
        };
        // console.log(newPaymentMethodForPayfort);

        // console.log("loyaltyEarnTypeId", loyaltyEarnTypeId);
        if (loyaltyEarnTypeId !== "") {
          let loyaltyParams = {
            type: loyaltyEarnTypeId,
          };
          updateLoyaltyEarnTypes(loyaltyParams);
        }
        newPaymentMethodForPayfort.paymentMethod.number = card.cardNumber.replace(/ /g, "");
        setPending(true);
        const data = await getPayfortInformation(newPaymentMethodForPayfort)
          .then((res) => {
            dispatch(loadCartData());
            localStorage.setItem("OrderID", JSON.stringify(res.data.entity_id));
            localStorage.setItem("lod", JSON.stringify(res.data.entity_id));
            setPayfortData({
              ...res.data,
              card_number: card.cardNumber.replace(/ /g, ""),
              card_holder_name: card.cardHolder,
              card_security_code: card.cvv,
              expiry_date: `${card.monthYear.slice(3, 5)}${card.monthYear.slice(
                0,
                2
              )}`,
            });
            payfortForm.current.submit();
            // console.log("Entity", res?.data?.entity_id);

            // console.log("payfort");
            // let newSendPayfortInformation = {
            //   card_number: card.cardNumber.replace(/ /g,""),
            //   card_holder_name: card.cardHolder,
            //   card_security_code: card.cvv,
            //   expiry_date: `${card.monthYear.slice(3,5)}${card.monthYear.slice(0,2)}`,
            //   merchant_identifier: res.data.params.merchant_identifier,
            //   access_code: res.data.params.access_code,
            //   merchant_reference: res.data.params.merchant_reference,
            //   language: "en",
            //   service_command: res.data.params.service_command,
            //   return_url: `https://alpha-api.mestores.com/payfort/merchant?lang=en`,
            //   signature: res.data.params.signature,
            // };
            // console.log(
            //   "newSendPayfortInformation payfort_fort_cc",
            //   newSendPayfortInformation
            // );
          })
          .catch((error) => {
            dispatch(
              services.notifyError({ message: error?.response?.data?.message })
            );
            setPending(false);
          });
      }
    } else if (paymentMethodForPayfort.method === "payfort_fort_installments") {
      const newPaymentMethodForPayfort = {
        paymentMethod: paymentMethodForPayfort,
      };
      // console.log(newPaymentMethodForPayfort);
      if (loyaltyEarnTypeId !== "") {
        let loyaltyParams = {
          type: loyaltyEarnTypeId,
        };
        updateLoyaltyEarnTypes(loyaltyParams);
      }

      setPending(true);
      newPaymentMethodForPayfort.paymentMethod.number = card.cardNumber.replace(/ /g, "");

      const data = await getPayfortInformation(newPaymentMethodForPayfort)
        .then((res) => {
          // console.log("res ", res);
          dispatch(loadCartData());
          // console.log("payfort Information", res.data);
          localStorage.setItem("OrderID", JSON.stringify(res.data.entity_id));
          localStorage.setItem("lod", JSON.stringify(res.data.entity_id));

          setPayfortData({
            ...res.data,
            card_number: card.cardNumber.replace(/ /g, ""),
            card_holder_name: card.cardHolder,
            card_security_code: card.cvv,
            expiry_date: `${card.monthYear.slice(3, 5)}${card.monthYear.slice(
              0,
              2
            )}`,
          });
          payfortForm.current.submit();
          // let newSendPayfortInformation = {
          //   card_number: card.cardNumber.replace(/ /g,""),
          //   card_holder_name: card.cardHolder,
          //   card_security_code: card.cvv,
          //   expiry_date: `${card.monthYear.slice(3,5)}${card.monthYear.slice(0,2)}`,
          //   merchant_identifier: res.data.params.merchant_identifier,
          //   access_code: res.data.params.access_code,
          //   merchant_reference: res.data.params.merchant_reference,
          //   language: "en",
          //   service_command: res.data.params.service_command,
          //   return_url: `https://alpha-api.mestores.com/payfort/merchant?lang=en`,
          //   signature: res.data.params.signature,
          // };
          // console.log("newSendPayfortInformation ", newSendPayfortInformation);

          // setCheckoutClassName("payment");
        })
        .catch((error) => {
          dispatch(
            services.notifyError({ message: error?.response?.data?.message })
          );
          setPending(false);
        });
    } else if (paymentMethodForPayfort.method === "cashondelivery") {
      const params = {
        paymentMethod: {
          method: paymentMethodForPayfort.method,
        },
      };
      setPending(true);

      if (loyaltyEarnTypeId !== "") {
        let loyaltyParams = {
          type: loyaltyEarnTypeId,
        };
        updateLoyaltyEarnTypes(loyaltyParams);
      }

      await placeOrderCOD(params)
        .then((res) => {
          if (res.data.orderId !== undefined) {
            dispatch(loadCartData());
            localStorage.setItem("OrderID", JSON.stringify(res.data.orderId));
            localStorage.setItem("lod", JSON.stringify(res.data.orderId));

            // let redirecPage = `/user/orders/${res.data.orderId}/confirm`;
            let redirecPage = `/checkout/success`;
            setRedirectPageUrl(redirecPage);
          }
        })
        .catch((error) => {
          dispatch(
            services.notifyError({ message: error?.response?.data?.message })
          );
          setPending(false);
        });
    } else if (paymentMethodForPayfort.method === "free") {
      const params = {
        paymentMethod: {
          method: paymentMethodForPayfort.method,
        },
      };

      if (loyaltyEarnTypeId !== "") {
        let loyaltyParams = {
          type: loyaltyEarnTypeId,
        };
        updateLoyaltyEarnTypes(loyaltyParams);
      }

      setPending(true);
      await placeOrderCOD(params)
        .then((res) => {
          if (res.data.orderId !== undefined) {
            dispatch(loadCartData());
            let redirecPage = `/user/orders/${res.data.orderId}/confirm`;
            setRedirectPageUrl(redirecPage);
          }
        })
        .catch((error) => {
          dispatch(
            services.notifyError({ message: error?.response?.data?.message })
          );
          setPending(false);
        });
    } else if (
      paymentMethodForPayfort.method === "tamara_pay_by_instalments_6" ||
      paymentMethodForPayfort.method === "tamara_pay_by_instalments" ||
      paymentMethodForPayfort.method === "tamara_pay_by_instalments_2" || 
      paymentMethodForPayfort.method === "tamara_pay_by_instalments_4"
    ) {
      const tamaraParams = {
        paymentMethod: paymentMethodForPayfort,
      };

      if (loyaltyEarnTypeId !== "") {
        let loyaltyParams = {
          type: loyaltyEarnTypeId,
        };
        updateLoyaltyEarnTypes(loyaltyParams);
      }

      setPending(true);
      await getTamaraData(tamaraParams)
        .then((res) => {
          localStorage.setItem("OrderID", JSON.stringify(res.data.entity_id));
          localStorage.setItem("lod", JSON.stringify(res.data.entity_id));

          dispatch(loadCartData());
          window.location.href = res.data.url;
          // setCheckoutClassName("payment");
        })
        .catch((error) => {
          dispatch(
            services.notifyError({ message: error?.response?.data?.message })
          );
          setPending(false);
        });
    } else if (
      paymentMethodForPayfort.method === "tabby_checkout" ||
      paymentMethodForPayfort.method === "tabby_installments"
    ) {
      const params = {
        paymentMethod: {
          method: paymentMethodForPayfort.method,
          development: process.env.NODE_ENV === 'development'
        },
      };
      setPending(true);
      await placeOrderCOD(params)
        .then((res) => {
          const { status, message, orderId, url } = res?.data;
          let path = '';
          localStorage.setItem("OrderID", JSON.stringify(orderId));
          localStorage.setItem("lod", JSON.stringify(orderId));
          setTimeout(() => {
            if (orderId) {
              path = '/checkout/failure';
            } else {
              path = '/';
              dispatch(
                services.notifyError({ message: message })
              );
              setPending(false);
            }
            if (status === true) {
              window.location.replace(url)
            } else {
              navigate(path, { replace: true })
            }
          }, 1000);
        })
        .catch((error) => {
          dispatch(
            services.notifyError({ message: error?.response?.data?.message })
          );
          setPending(false);
        });
    }
    impressionEvents.impressionAddPaymentInfo(cartData, 'Checkout Payment', paymentMethodForPayfort.method);
    //  history.push("/user/order")
  };

  const currentLang = localStorage.getItem("i18next") || "en";
  let quitafcard = require(`./../../assets/Icon/icon-qitaf-${currentLang}.png`)
  useEffect(() => {
    impressionEvents.impressionGlobalVariable(
      currentLang, "Payment Page"
    );
  }, []);
  function Position(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return currenttop > 200 ? [currenttop - 200] : [0];
    }
  }

  useEffect(() => {
    const errorElement = document.querySelector(".invalid__message");
    if (errorElement && document.activeElement.tagName !== "INPUT") {
      window.scrollTo(0, Position(errorElement));
    }

  }, [cardErrMsg])
  useEffect(()=>{
    const cartDataItem = cartData?.items;
    const shouldShowApplePay = cartDataItem?.some(product => product?.sku?.startsWith("ESD"));
    //console.log("shouldShowApplePay",shouldShowApplePay)
    setShowApplePay(shouldShowApplePay);
  },[cartData])
  useEffect(() => {
    const isMac = navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
    if (isMac) {
      document.body.classList.add('mac-device');
    }
    return () => {
      document.body.classList.remove('mac-device');
    };
  }, [cartData]);
  return (<>
    {payfortData && (
      <form
        id="f"
        name="f"
        method="post"
        action={payfortData.url}
        ref={payfortForm}
      >
        <input
          type="hidden"
          name="card_number"
          value={payfortData.card_number}
        />
        <input
          type="hidden"
          name="card_holder_name"
          value={payfortData.card_holder_name}
        />
        <input
          type="hidden"
          name="card_security_code"
          value={payfortData.card_security_code}
        />
        <input
          type="hidden"
          name="expiry_date"
          value={payfortData.expiry_date}
        />
        <input
          type="hidden"
          name="merchant_identifier"
          value={payfortData.params.merchant_identifier}
        />
        <input
          type="hidden"
          name="access_code"
          value={payfortData.params.access_code}
        />
        <input
          type="hidden"
          name="merchant_reference"
          value={payfortData.order_id}
        />
        <input
          type="hidden"
          name="language"
          value={payfortData.params.language}
        />
        <input
          type="hidden"
          name="service_command"
          value={payfortData.params.service_command}
        />
        <input
          type="hidden"
          name="return_url"
          value={payfortData.params.return_url}
        />
        <input
          type="hidden"
          name="signature"
          value={payfortData.params.signature}
        />
      </form>
    )}
    {pending ? <ProcessingPayment dispatch={dispatch} /> :
      <div className="row checkout__login__main__block">
        <div
          onClick={() => openLoginWrapperFromAnywhere()}
          className="col-2 checkout__signin__button"
        >
          <img
            src={
              iconType.signin === "inprogress"
                ? signin_inprogress
                : iconType.signin === "done"
                  ? signin_done
                  : signin_initial
            }
            alt=""
          />
          {iconType.signin === "done" ? (
            <img className="done__icon" src={done} alt="done" />
          ) : (
            ""
          )}
        </div>
        <div className="col-3 checkout__middle__line__block"></div>
        <div
          // onClick={() => handleChangeClassName("delivery")}
          className="col-2 checkout__delivery__button"
        >
          <img
            src={
              iconType.delivery === "inprogress"
                ? delivery_inprogress
                : iconType.delivery === "done"
                  ? delivery_done
                  : delivery_initial
            }
            alt=""
          />
          {iconType.delivery === "done" ? (
            <img className="done__icon" src={done} alt="done" />
          ) : (
            ""
          )}
        </div>
        <div className="col-3 checkout__middle__line__block"></div>
        <div
          className="col-2 checkout__payment__button"
        >
          <img
            src={
              iconType.payment === "inprogress"
                ? payment_inprogress
                : iconType.payment === "done"
                  ? payment_done
                  : payment_initial
            }
            alt=""
          />
          {iconType.payment === "done" ? (
            <img className="done__icon" src={done} alt="done" />
          ) : (
            ""
          )}
        </div>
        <div className="user__payment__block">
          <div className="payment__title__block">
            <img src={payment_done} alt="" className="user__icon" />
            <Heading7
              text={t("PAYMENT_OPTIONS")}
              color="#808080"
              marginLeft={10}
              marginBottom={0}
            />
          </div>
          <div className="payment__block">
            <CartApplyStoreCredit
              t={t} 
              currentLang={currentLang} 
              // token={token}
              cart={cartData}
              user={customerDetails} 
              // setCartHandler={setCartHandler} 
            />
            <CartRedeemLoyalty
              t={t}
              handleChangePaymentMethod={handleChangePaymentMethod}
              type="mobile"
              // cartDetail={cartDetail}
              cartTotalData={cartTotalData}
              hideSection={hideSection}
              cartData={cartData}
              ecom={ecom}
            />
            {userPaymentMethod === "mokafa" && !mokafaApplied && (
              <>
                {payWithLoyalty.step === 1 && (
                  <CartLoyaltyBonus t={t} program="mokafa" type="mobile" />
                )}
              </>
            )}
            
            {userPaymentMethod === "meza" && (
              <>
                {payWithLoyalty.step === 1 && (
                  <>
                    <div className="payment__form__main__block">
                      <div className="payment__detail__form__block">
                        {loyaltyLoading &&
                          <div className="payment_process_loading">
                          </div>
                        }
                        <div className="address__content__block">
                          <div className="payment__card__block mezza__text">
                            <div className="row payment__form__field__row">
                              <div className="col-sm-12 col-md-2 main__form__field__block">
                                <div className="field__block">
                                  <img src={mezzacard} alt="card" />
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-3 main__form__field__block ">
                                <Heading7
                                  text={t("Mobile_phone_number")}
                                  marginBottom={10}
                                />
                                <div className="field__block">
                                  <input
                                    type="text"
                                    placeholder="i.e. 0512345678"
                                    className="form__field"
                                    id="name"
                                    value={payWithLoyalty.phone}
                                    name="phone"
                                    onChange={(e) => handleChangeLoyalty(e)}
                                  />
                                </div>
                                {payWithLoyaltyErr.phone && (
                                  <p className="invalid__message">
                                    {payWithLoyaltyErr.phone}
                                  </p>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-3 main__form__field__block">
                                <div className="field__block">
                                  <button
                                    onClick={() => applyForLoyaltyOtp()}
                                    className="btn apply_btn w-100 h-20"
                                    disabled={loyaltyLoading ? true : false}
                                  >
                                    {loyaltyLoading ? <Spinner /> : t("Apply")}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="row payment__form__field__row"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {payWithLoyalty.step === 2 && (
                  <>
                    <div className="payment__form__main__block">
                      <div className="payment__detail__form__block">
                        {loyaltyLoading &&
                          <div className="payment_process_loading">
                          </div>
                        }
                        <div className="address__content__block">
                          <div className="payment__card__block mezza__text">
                            <div className="row payment__form__field__row">
                              <div className="col-sm-12 col-md-3 main__form__field__block">
                                <Heading7 text={t("Code")} marginBottom={10} />
                                <div className="field__block">
                                  <input
                                    type="text"
                                    className="form__field"
                                    id="name"
                                    placeholder={t("Code")}
                                    value={payWithLoyalty.code}
                                    name="code"
                                    onChange={(e) => handleChangeLoyalty(e)}
                                  />
                                </div>
                                {payWithLoyaltyErr.code && (
                                  <p className="invalid__message">
                                    {payWithLoyaltyErr.code}
                                  </p>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-3 main__form__field__block">
                                <Heading7 text={t("points")} marginBottom={10} />
                                <div className="field__block">
                                  <input
                                    type="text"
                                    className="form__field"
                                    id="name"
                                    placeholder={t("points")}
                                    value={payWithLoyalty.points}
                                    name="points"
                                    onChange={(e) => handleChangeLoyalty(e)}
                                  />
                                </div>
                                {payWithLoyaltyErr.points && (
                                  <p className="invalid__message">
                                    {payWithLoyaltyErr.points}
                                  </p>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-3 main__form__field__block">
                                <div className="field__block">
                                  <button
                                    onClick={() => confirmLoyaltyPointsPayment()}
                                    className="btn apply_btn w-100"
                                    disabled={loyaltyLoading ? true : false}
                                  >
                                    {loyaltyLoading ? <Spinner /> : t("Confirm")}
                                  </button>
                                </div>
                              </div>
                            </div>
                            {cartDetail?.extension_attributes?.meza_details && (
                              <p className="text-center">
                                {
                                  cartDetail.extension_attributes.meza_details
                                    .points
                                }{" "}
                                {`${t("Meza_points_equal_to")}`}{" "}
                                {
                                  cartDetail.extension_attributes.meza_details
                                    .money_equivalent
                                }{" "}
                                {cartDetail.currency.base_currency_code}
                              </p>
                            )}
                            <div className="row payment__form__field__row"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {userPaymentMethod === "qitaf" && (
              <>
                {payWithLoyalty.step === 1 && (
                  <>
                    <div className="payment__form__main__block">
                      <div className="payment__detail__form__block">
                        {loyaltyLoading &&
                          <div className="payment_process_loading">
                          </div>
                        }
                        <div className="address__content__block">
                          <div className="payment__card__block">
                            <div className="row payment__form__field__row mezza__text">
                              <div className="col-sm-12 col-md-2 main__form__field__block">
                                <div className="field__block">
                                  <img src={quitafcard} alt="card" />
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-3 main__form__field__block">
                                <Heading7
                                  text={t("Mobile_phone_number")}
                                  marginBottom={10}
                                />
                                <div className="field__block">
                                  <input
                                    type="text"
                                    placeholder="i.e. 0512345678"
                                    className="form__field"
                                    id="name"
                                    value={payWithLoyalty.phone}
                                    name="phone"
                                    onChange={(e) => handleChangeLoyalty(e)}
                                  />
                                </div>
                                {payWithLoyaltyErr.phone && (
                                  <p className="invalid__message">
                                    {payWithLoyaltyErr.phone}
                                  </p>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-3 main__form__field__block">
                                <div className="field__block">
                                  <button
                                    onClick={() => applyForLoyaltyOtp()}
                                    className="btn apply_btn w-100"
                                    disabled={loyaltyLoading ? true : false}
                                  >
                                    {loyaltyLoading ? <Spinner /> : t("Apply")}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="row payment__form__field__row"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {payWithLoyalty.step === 2 && (
                  <>
                    <div className="payment__form__main__block">
                      <div className="payment__detail__form__block">
                        {loyaltyLoading &&
                          <div className="payment_process_loading">
                          </div>
                        }
                        <div className="address__content__block">
                          <div className="payment__card__block">
                            <div className="row payment__form__field__row">
                              <div className="col-sm-12 col-md-3 main__form__field__block">
                                <Heading7 text={t("Code")} marginBottom={10} />
                                <div className="field__block">
                                  <input
                                    type="text"
                                    className="form__field"
                                    id="name"
                                    placeholder={t("Code")}
                                    value={payWithLoyalty.code}
                                    name="code"
                                    onChange={(e) => handleChangeLoyalty(e)}
                                  />
                                </div>
                                {payWithLoyaltyErr.code && (
                                  <p className="invalid__message">
                                    {payWithLoyaltyErr.code}
                                  </p>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-3 main__form__field__block">
                                <Heading7 text={t("points")} marginBottom={10} />
                                <div className="field__block">
                                  <input
                                    type="text"
                                    className="form__field"
                                    id="name"
                                    placeholder={t("points")}
                                    value={payWithLoyalty.points}
                                    name="points"
                                    onChange={(e) => handleChangeLoyalty(e)}
                                  />
                                </div>
                                {payWithLoyaltyErr.points && (
                                  <p className="invalid__message">
                                    {payWithLoyaltyErr.points}
                                  </p>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-3 main__form__field__block">
                                <div className="field__block">
                                  <button
                                    onClick={() => confirmLoyaltyPointsPayment()}
                                    className="btn apply_btn w-100"
                                    disabled={loyaltyLoading ? true : false}
                                  >
                                    {loyaltyLoading ? <Spinner /> : t("Confirm")}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="row payment__form__field__row"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {userPaymentMethod === 'niqaty' && 
              <LoyaltyNiqatyProgram
                t={t}
                services={services}
                stepDetails={payWithLoyalty}
                setPayWithLoyalty={setPayWithLoyalty}
                loyaltyLoading={loyaltyLoading}
                handleNiqatyComplete={handleNiqatyComplete} />
              }

              {isAmwalEnabled === 1 && (
              <div className="paymentMethodAmwall">
                <AmwalMagentoReactButton
                  key={forceRender}
                  baseUrl={`${process.env.REACT_APP_PROJECT_API_URL}/V1`}
                  triggerContext="regular-checkout"
                  // overrideQuoteId={cartData?.id}
                  overrideCartId={cartData?.maskId === '' ? cartData?.id : cartData?.maskId}
                  amwalState={amwalState}
                  locale={currentLanguageCode}
                  extraHeaders={{
                    'x-access-token': localStorageHandshakeToken
                  }}
                  // redirectURL={`http://localhost:43449/checkout/success`}
                  performSuccessRedirection={(order_id)=>{
                    localStorage.setItem('OrderID',order_id)
                    window.location.href = `${BASE_PAYMENT_REDIRECT_URL}/checkout/success`;
                  }}
                  />
              </div>
            )}

            {(!paymentMethods || paymentMethods?.length === 0 || loadingData?.loading) ?
              <Spinner type='dark' /> :
              paymentMethods &&
              paymentMethods
                .filter(
                  (val, i) =>
                     cartDetail?.totals_data?.base_grand_total <= 0 ? (val?.code === 'free' && val?.is_available) : val.code !== "mestores_applepay" &&
                     val?.is_available === true
                )
                .map((payment, paymentIndex) => {
                  let showPaymentMethod = true;
                  if (
                    preferenceTab === "storepickup" &&
                    payment.code === "cashondelivery"
                  ) {
                    showPaymentMethod = false;
                  } else if (checkVirtualItemsInCart(cartDetail.items) === true) {
                    if (
                      payment.code === "tamara_pay_by_instalments" ||
                      payment.code === "tamara_pay_by_instalments_6" ||
                      payment.code === "tamara_pay_by_instalments_4" 
                    ) {
                      showPaymentMethod = false;
                    }
                  }
                  return (
                    <React.Fragment key={paymentIndex}>
                      {showPaymentMethod === true && (
                        <>
                          {payment.code === "payfort_fort_cc" ? (
                            <div className="payment__form__main__block">
                              <label
                                key={payment.code}
                                className="payment__form__block"
                                htmlFor={`m_payment_with_${payment.code}`}
                              >
                                <input
                                  id={`m_payment_with_${payment.code}`}
                                  type="radio"
                                  className="payment__input__check"
                                  name="paymentType"
                                  value={payment.code}
                                  onClick={handleChangePaymentMethod}
                                />
                                <div className="payment__selection__text d-flex justify-content-between w-100">
                                  <Text4
                                    text={
                                      currentLang === "en"
                                        ? payment.english_name
                                        : payment.arabic_name
                                    }
                                  />
                                  <div className="card__block">
                                    <img src={visacard} className="card" />
                                    <img src={mastercard2} className="card" />
                                    <img src={madacard} className="card" />
                                  </div>
                                </div>
                              </label>
                              {userPaymentMethod === payment.code ? (
                                <div className="payment__detail__form__block">
                                  {userPaymentMethod === "payfort_fort_cc" ? (
                                    <div className="address__content__block">
                                      <div className="payment__card__block">
                                        <div className="row payment__form__field__row">
                                          <div className="col-sm-12 col-md-3 main__form__field__block">
                                            {/* <p className="form__label">First Name</p> */}
                                            <Heading7
                                              text={t("Credit_Card_Number")}
                                              marginBottom={10}
                                            />
                                            <div className="field__block">
                                              <input
                                                type="text"
                                                placeholder="xxxx-xxxx-xxxx-xxxx"
                                                className="form__field sentry-mask"
                                                id="name"
                                                name="cardNumber"
                                                value={card.cardNumber}
                                                onChange={(e) =>
                                                  handleChangeCard(e)
                                                }
                                              />
                                            </div>
                                            {cardErrMsg.cardNumber && (
                                              <p className="invalid__message">
                                                {cardErrMsg.cardNumber}
                                              </p>
                                            )}
                                          </div>
                                          <div className="col-sm-12 col-md-3 main__form__field__block">
                                            {/* <p className="form__label">Mobile Number</p> */}
                                            <Heading7
                                              text={t("Card_Holder_Name")}
                                              marginBottom={10}
                                            />
                                            <div className="field__block sentry-mask">
                                              <input
                                                type="text"
                                                placeholder={t(
                                                  "Card_Holder_Name"
                                                )}
                                                className="form__field card__holder__field"
                                                id="cardHolder"
                                                name="cardHolder"
                                                value={card.cardHolder}
                                                onChange={(e) =>
                                                  handleChangeCard(e)
                                                }
                                              />
                                            </div>
                                            {cardErrMsg.cardHolder && (
                                              <p className="invalid__message">
                                                {cardErrMsg.cardHolder}
                                              </p>
                                            )}
                                          </div>

                                          <div className="col-sm-12 col-md-3 main__form__field__block ">
                                            {/* <p className="form__label">First Name</p> */}
                                            <Heading7
                                              text={t("Month/Year")}
                                              marginBottom={10}
                                            />
                                            <div className="field__block sentry-mask">
                                              <input
                                                type="text"
                                                placeholder={t("Month/Year")}
                                                dateformat="MM/YYYY"
                                                className="form__field"
                                                controls={["date"]}
                                                id="monthYear"
                                                name="monthYear"
                                                value={card.monthYear}
                                                onChange={(e) =>
                                                  handleChangeCard(e)
                                                }
                                                disabled={cardIsDisabled}
                                              />
                                            </div>
                                            {cardErrMsg.monthYear && (
                                              <p className="invalid__message">
                                                {cardErrMsg.monthYear}
                                              </p>
                                            )}
                                          </div>
                                          {/* <div className="col-sm-12 col-md-4 ">
                                              <Heading7
                                                text="Year"
                                                marginBottom={10}
                                              />
                                              <div className="field__block">
                                                <input
                                                  type="text"
                                                  placeholder="YY"
                                                  className="form__field"
                                                  id="year"
                                                  name="year"
                                                  value={card.year}
                                                  onChange={(e) =>
                                                    handleChangeCard(e)
                                                  }
                                                />
                                              </div>
                                              {cardErrMsg.year && (
                                                <p className="invalid__message">
                                                  {cardErrMsg.year}
                                                </p>
                                              )}
                                            </div> */}

                                          <div className="col-sm-12 col-md-3 main__form__field__block">
                                            {/* <p className="form__label">First Name</p> */}
                                            <Heading7
                                              text={t("CVV")}
                                              marginBottom={10}
                                            />
                                            <div className="field__block">
                                              <input
                                                type="number"
                                                placeholder={t("CVV")}
                                                className="form__field sentry-mask"
                                                id="cvv"
                                                name="cvv"
                                                value={card.cvv}
                                                onChange={(e) =>
                                                  handleChangeCard(e)
                                                }
                                                disabled={cardIsDisabled}
                                              />
                                            </div>
                                            {cardErrMsg.cvv && (
                                              <p className="invalid__message">
                                                {cardErrMsg.cvv}
                                              </p>
                                            )}
                                                 {
                                                  note && !cardErrMsg?.cardNumber && (
                                                    <div className='col-sm-12' style = {{marginTop: '10px'}}>
                                                      <p  dangerouslySetInnerHTML={note}></p>
                                                    </div>
                                                  )
                                                }
                                          </div>
                                        </div>
                                        <div className="row payment__form__field__row"></div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : payment.code === "payfort_fort_installments" ? (
                            <label
                              key={payment.code}
                              className="payment__form__block"
                              htmlFor={`m_payment_with_${payment.code}`}
                            >
                              <input
                                id={`m_payment_with_${payment.code}`}
                                type="radio"
                                className="payment__input__check"
                                name="paymentType"
                                value={payment.code}
                                onClick={handleChangePaymentMethod}
                              />
                              <div className="payment__selection__text">
                                <Text4
                                  text={
                                    currentLang === "en"
                                      ? payment.english_name
                                      : payment.arabic_name
                                  }
                                />
                              </div>
                            </label>
                          ) : payment.code === "tamara_pay_by_instalments" ? (
                            <>
                              {tamara3MonthStatus === true &&
                                <div className="payment__form__main__block">
                                  <label
                                    key={payment.code}
                                    className="payment__form__block"
                                    htmlFor={`m_payment_with_${payment.code}`}
                                  >
                                    <input
                                      id={`m_payment_with_${payment.code}`}
                                      type="radio"
                                      className="payment__input__check"
                                      name="paymentType"
                                      value={payment.code}
                                      onClick={handleChangePaymentMethod}
                                    />
                                    <div className="payment__selection__text d-flex justify-content-between w-100">
                                      <Text4
                                        text={
                                          currentLang === "en"
                                            ? payment.english_name
                                            : payment.arabic_name
                                        }
                                      />

                                      <div className="card__block tamara__logobox">
                                        <img src={tamaracard} className="card" />
                                      </div>
                                    </div>
                                  </label>
                                  {userPaymentMethod === payment.code ? (
                                    <div className="payment__detail__form__block tamara__paybox">
                                      {userPaymentMethod ===
                                        "tamara_pay_by_instalments" ? (
                                        <TamaraInstallmentPlanWidget
                                          months={3}
                                          price={
                                            cartDetail?.totals_data?.base_grand_total
                                          }
                                          // currency={
                                          //   cartDetail?.totals_data
                                          //     ?.base_currency_code
                                          // }
                                          minLimit={getTamaraValue('tamara3_min')}
                                          maxLimit={getTamaraValue('tamara3_max')}
                                          currentLanguageCode={currentLanguageCode}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              }
                            </>
                          )
                          : payment.code === "tamara_pay_by_instalments_4" ? (
                            <>
                              
                                <div className="payment__form__main__block">
                                  <label
                                    key={payment.code}
                                    className="payment__form__block"
                                    htmlFor={`m_payment_with_${payment.code}`}
                                  >
                                    <input
                                      id={`m_payment_with_${payment.code}`}
                                      type="radio"
                                      className="payment__input__check"
                                      name="paymentType"
                                      value={payment.code}
                                      onClick={handleChangePaymentMethod}
                                    />
                                    <div className="payment__selection__text d-flex justify-content-between w-100">
                                      <Text4
                                        text={
                                          currentLang === "en"
                                            ? payment.english_name
                                            : payment.arabic_name
                                        }
                                      />

                                      <div className="card__block tamara__logobox">
                                        <img src={tamaracard} className="card" />
                                      </div>
                                    </div>
                                  </label>
                                  {userPaymentMethod === payment.code ? (
                                    <div className="payment__detail__form__block tamara__paybox">
                                      {userPaymentMethod ===
                                        "tamara_pay_by_instalments_4" ? (
                                        <TamaraInstallmentPlanWidget
                                          months={4}
                                          price={
                                            cartDetail?.totals_data?.base_grand_total
                                          }
                                          // currency={
                                          //   cartDetail?.totals_data
                                          //     ?.base_currency_code
                                          // }
                                          minLimit={getTamaraValue('tamara3_min')}
                                          maxLimit={getTamaraValue('tamara3_max')}
                                          currentLanguageCode={currentLanguageCode}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              
                            </>
                          )
                          : payment.code === "tamara_pay_by_instalments_6" ? (
                            <>
                              {tamara6MonthStatus === true &&
                                <div className="payment__form__main__block">
                                  <label
                                    key={payment.code}
                                    className="payment__form__block"
                                    htmlFor={`m_payment_with_${payment.code}`}
                                  >
                                    <input
                                      id={`m_payment_with_${payment.code}`}
                                      type="radio"
                                      className="payment__input__check"
                                      name="paymentType"
                                      value={payment.code}
                                      onClick={handleChangePaymentMethod}
                                    />
                                    <div className="payment__selection__text d-flex justify-content-between w-100">
                                      <Text4
                                        text={
                                          currentLang === "en"
                                            ? payment.english_name
                                            : payment.arabic_name
                                        }
                                      />

                                      <div className="card__block tamara__logobox">
                                        <img src={tamaracard} className="card" />
                                      </div>
                                    </div>
                                  </label>
                                  {userPaymentMethod === payment.code ? (
                                    <div className="payment__detail__form__block tamara__paybox">
                                      {userPaymentMethod ===
                                        "tamara_pay_by_instalments_6" ? (
                                        <TamaraInstallmentPlanWidget
                                          months={6}
                                          price={
                                            cartDetail?.totals_data?.base_grand_total
                                          }
                                          currency={
                                            cartDetail?.totals_data
                                              ?.base_currency_code
                                          }
                                          minLimit={getTamaraValue('tamara6_min')}
                                          maxLimit={getTamaraValue('tamara6_max')}
                                          currentLanguageCode={currentLanguageCode}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              }
                            </>
                          ) : payment.code ===
                            "tabby_checkout" ? (
                            <div className="payment__form__main__block">
                              <label
                                key={payment.code}
                                className="payment__form__block"
                                htmlFor={`m_payment_with_${payment.code}`}
                              >
                                <input
                                  id={`m_payment_with_${payment.code}`}
                                  type="radio"
                                  className="payment__input__check"
                                  name="paymentType"
                                  checked={userPaymentMethod === payment.code ? 'checked' : ''}
                                  value={payment.code}
                                  onChange={handleChangePaymentMethod}
                                />
                                <div className="payment__selection__text d-flex justify-content-between w-100">
                                  <Text4
                                    text={
                                      currentLang === "en"
                                        ? payment.english_name
                                        : payment.arabic_name
                                    }
                                  />

                                  <div className="card__block tamara__logobox">
                                    <img
                                      src={tabbybadge}
                                      className="card"
                                    />
                                  </div>
                                </div>
                              </label>
                              {userPaymentMethod === payment.code ? (
                                <div className="payment__detail__form__block tamara__paybox">
                                  {userPaymentMethod ===
                                    "tabby_checkout" && (
                                      <TabbyWidget
                                        price={
                                          cartDetail?.totals_data
                                            ?.base_grand_total
                                        }
                                        // currency={
                                        //   cartDetail?.totals_data
                                        //     ?.base_currency_code
                                        // }
                                        widgetType="TabbyCard"
                                        currentLanguageCode={currentLanguageCode}
                                      />
                                    )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : payment.code ===
                            "tabby_installments" ? (
                            <div className="payment__form__main__block">
                              <label
                                key={payment.code}
                                className="payment__form__block"
                                htmlFor={`m_payment_with_${payment.code}`}
                              >
                                <input
                                  id={`m_payment_with_${payment.code}`}
                                  type="radio"
                                  className="payment__input__check"
                                  name="paymentType"
                                  checked={userPaymentMethod === payment.code ? 'checked' : ''}
                                  value={payment.code}
                                  onChange={handleChangePaymentMethod}
                                />
                                <div className="payment__selection__text d-flex justify-content-between w-100">
                                  <Text4
                                    text={
                                      currentLang === "en"
                                        ? payment.english_name
                                        : payment.arabic_name
                                    }
                                  />

                                  <div className="card__block tamara__logobox">
                                    <img
                                      src={tabbybadge}
                                      className="card"
                                    />
                                  </div>
                                </div>
                              </label>
                              {userPaymentMethod === payment.code ? (
                                <div className="payment__detail__form__block tamara__paybox">
                                  {userPaymentMethod ===
                                    "tabby_installments" && (
                                      <TabbyWidget
                                        price={
                                          cartDetail?.totals_data
                                            ?.base_grand_total
                                        }
                                        // currency={
                                        //   cartDetail?.totals_data
                                        //     ?.base_currency_code
                                        // }
                                        widgetType="TabbyCard"
                                        currentLanguageCode={currentLanguageCode}
                                      />
                                    )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <label
                              key={payment.code}
                              className="payment__form__block"
                              htmlFor={`m_payment_with_${payment.code}`}
                            >
                              <input
                                id={`m_payment_with_${payment.code}`}
                                type="radio"
                                className="payment__input__check"
                                name="paymentType"
                                value={payment.code}
                                onClick={handleChangePaymentMethod}
                              />
                              <div className="payment__selection__text">
                                <Text4
                                  text={
                                    currentLang === "en"
                                      ? payment.english_name
                                      : payment.arabic_name
                                  }
                                />
                              </div>
                            </label>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
            {/* <div>
                    <div
                      // key={payment.code}
                      className="payment__form__block"
                    >
                      <input
                        type="radio"
                        name="paymentType"
                        className="payment__input__check"
                      />
                      <p className="payment__selection__text d-flex justify-content-between w-100">
                        <Text4 text="Pay with Card" />
                        <div className="card__block">
                          <img src={visacard} className="card" />
                          <img src={madacard} className="card" />
                        </div>
                      </p>
                    </div>
                  </div> */}
            {/* {isAppleDevice && (
              <VisaHolderNote t={t} />
            )} */}
            <div className="continue__button__block row mt-5">
              {isAppleDevice && ((Number(allStoreConfig?.applePay) || ecom?.featureFlags?.ecom?.bootstraper?.apple_pay_checkout) &&
                <div className="col-12 col-sm-6">
                  { !showApplePay && <ApplePay checkout t={t} total={cartData?.totals_data?.base_grand_total}/> }
                </div>
              )}
              <div className={isAppleDevice && (Number(allStoreConfig?.applePay) || ecom?.featureFlags?.ecom?.bootstraper?.apple_pay_checkout) ? "col-12 col-sm-6" : "col-12 "}>
                {/* <Link
                  className="continue___button__link d-flex align-items-center justify-content-center p-0"
                  // to="/checkout/delivery"
                  style={{ cursor: paymentMethods && paymentMethods?.length > 0 ? "" : "not-allowed" }}
                > */}
                <button
                  onClick={() => {
                    document.activeElement.blur();
                    setTimeout(() => {
                      makePayment()
                      
                    }, 200)
                  }}
                  className=" btn btn__primary__orange w-100"
                  disabled={(paymentMethods && paymentMethods?.length === 0 || pending === true) ? true : false || isError}
                >
                  {pending === true ? <Spinner /> : t("makeThePayment")}
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </>
  )
}

export default MobilePayment