// import BannerCard from 'components/cards/BannerCard';
import React from 'react';
import styles from '../../SCSS/components/Banner.module.scss';
import clsx from 'clsx';
import Slider from '../Slider';
import BannerCard from "./BannerCard";


const Banners = ({
  items, innerType, className,
}) => {
  const currentLang = localStorage.getItem("i18next") || "ar";

  return (
    <div className={clsx(styles.wrapper, innerType === 'slider' && styles.isFullWidth)} >
      <Slider isFullWidth type={innerType ? 'carousel' : 'hero'} interval={innerType === 'slider' ? 5000 : 0} currentLang={currentLang}>
        {items.map((data, idx) => <BannerCard data={data} idx={idx} innerType={innerType} className={ className} />)}
      </Slider>
    </div>
  );
};

export default Banners;
