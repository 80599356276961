import React from "react";
import "./../../SCSS/components/Cards/_bundleProductCard.scss";

const CurrencyDisplay = ({
    t,
    price,
    free,
    negative,
    className: extraClassName,
}) =>
    free ? (
        t("Free")
    ) : (
        //   <div className={clsx(styles.currency, extraClassName, 'header-active')}>
        <div className="currency_display_block extraClassName header-active">
            <span>{t("SAR")}</span> &nbsp;
            <span>{`${negative ? "-" : ""}${parseFloat(price).toFixed(2)}`}</span>
        </div>
    );

export default CurrencyDisplay;