import React, { useEffect } from "react";
import "./../SCSS/_banner.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import banner_02 from "./../assets/Banner/banner_01.jpg";
// import { defaultImageHelper } from "./helpers/utils/defaultImageHelper";
import { defaultImageHelper } from "./helpers/utils/defaultImageHelper";
import { impressionEvents } from "./helpers/utils/ga/impressionEvents";
import ImageRender  from "./ImageRender"
var settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const getRandonId = () => Math.ceil(Math.random() * 9999);
function Banner({ bannerData, bannerFullData, isHome=true }) {
  useEffect(() => {
    if (isHome) {
      impressionEvents.impressionBannerView(
        bannerData,
        "Home Page",
        bannerFullData
      );
    }
  }, [bannerData]);
  
  const bannerClick = (img, index) => {
    if (isHome) {
      impressionEvents.impressionBannerClick(
        img,
        "Home Page",
        index,
        bannerFullData
      );
    }
  };
  return (
    <>
      <div className="home__banner__slick">
        <Slider {...settings}>
          {bannerData &&
            bannerData?.map((img, index) => (
              <div key={getRandonId()} className="">
                {img?.deeplinkUrl !== '' && img?.deeplinkUrl !== undefined && img?.deeplinkUrl !== null ?
                  <a
                    href={img?.deeplinkUrl || "#"}
                    target="_blank"
                    rel="noreferrer"
                    onClick={()=>bannerClick(img,index)}
                  >
                    <ImageRender
                      preload={index === 0}
                      noLazy= {index === 1}
                      src={img.imageUrl}
                      alt={img.imageUrl + index}
                      onErrorS={(e) => defaultImageHelper(e)}
                      className="banner"
                      onClick={()=>bannerClick(img,index)}
                    />
                  </a>
                  :
                  <ImageRender
                    preload={index === 0}
                    noLazy= {index === 1}
                    src={img.imageUrl}
                    alt={img.imageUrl + index}
                    onErrorS={(e) => defaultImageHelper(e)}
                    className="banner"
                    onClick={()=>bannerClick(img,index)}
                  />
                }
              </div>
            ))}
        </Slider>
      </div>
    </>
  );
}

export default React.memo(Banner);
