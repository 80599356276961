import { productUrlHelper , getPageName} from "../productUrlHelper";
import { getCoupanPercentage } from "../coupanChecker";

import { dataLayerPush, dataLayerGroupPush } from "./dataLayerPush";
import { getCustomerLoginDetails } from '../getCustomerLoginDetails';
const parseCategory = (data) => {
  const object = {};
  if(data){
   data?.forEach((element,i) => {
     i = i + 1;
    
     if(i === 1){
        object['item_category'] = element;
     }else{
       object['item_category'+i] = element;

     }
    });
  }
  
  return object;  
 
};
export const impressionEvents = {
  impression: (productData, list, position) => {
    const price = Number(
      parseFloat(
        productData.discounted_price_rounded || productData.price_rounded
      ).toFixed(2)
    );
    const categories = productData?.categories || [];
    const category = [...categories].pop()?.name || "Sony";
    const brand =
      productData.options?.find((option) => option.code === "brand")
        ?.selected_option?.label || null;

    let discount = 0;
    const discountedPrice = Number(
      parseFloat(productData.discounted_price_rounded).toFixed(2)
    );
    if (discountedPrice < productData?.price_rounded)
      discount = price - discountedPrice;

    const impressions = [
      {
        name: productData.name,
        id: productData.id,
        price,
        brand,
        category,
        list,
        discount,
        position: position + 1,
        dimension1: productData.sku,
      },
    ];

    dataLayerGroupPush(
      {
        event: "impressions",
        ecommerce: {
          currencyCode: productData?.currency || "SAR",
          impressions,
        },
      },
      list
    );
  },
  impressionMeasureLogin: (eventName, method, status, userData) => {
    let obj = {
      event: eventName,
      method: method || "Mobile", //or Email... etc.
      status: status, //If Login Failed, include the reason i.e. ‘Login Failed: Incorrect password’
      user_id: userData?.user_id || "", //Leave it empty if failed
      email: userData?.email,
      phone: userData?.phone,
      first_name: userData?.first_name || "",
      last_name: userData?.last_name || "",
      gender: userData.gender,
      account_creation_date: userData?.createdDate || '',
      total_purchases: '', // Total number of transactions
      total_revenue: '', //Total of transactions value
      last_purchase_date: '',
    };
    //console.log("OBJECT", obj);
    dataLayerPush(obj);
  },
  impressionSignup: (eventName, method, status, userData) => {
    let obj = {
      event: eventName,
      method: method || "Mobile", //or Email... etc.
      status: status, //If Login Failed, include the reason i.e. ‘Login Failed: Incorrect password’
      user_id: userData?.user_id || "", //Leave it empty if failed
      email: userData?.email,
      phone: userData?.phone,
      first_name: userData?.first_name || "",
      last_name: userData?.last_name || "",
   
    };
    //console.log("OBJECT", obj);
    dataLayerPush(obj);
  },
  impressionMeasure: (eventName, method, status, userData) => {
    let obj = {
      event: eventName,
      method: method || "Mobile", //or Email... etc.
      status: status, //If Login Failed, include the reason i.e. ‘Login Failed: Incorrect password’
      user_id: userData?.user_id || "", //Leave it empty if failed
      email: userData?.email,
      phone: userData?.phone,
      first_name: userData?.first_name || "",
      last_name: userData?.last_name || "",
      gender: userData.gender,
    };
    //console.log("OBJECT", obj);
    dataLayerPush(obj);
  },
  impressionGlobalVariable: (language, pageType) => {
    const userData = getCustomerLoginDetails();
    let obj = {
      event: 'global_variables',
      language: language,
      user_id: userData?.id || null,
      page_type: pageType
    };
    //console.log("OBJECT", obj);
    dataLayerPush(obj);
  },
  impressionBannerView: (data,location, bannerFullData) => {
    const mappedData = data?.map((link, id) => ({
      
      items: {
        Creative_name: link.imageUrl, // Replace with the actual image URL
        creative_slot: id,
        location_id: location, // Replace with the actual image ID
        promotion_id: bannerFullData?.title_id,
        promotion_name: bannerFullData?.title,
      }
    }));
    let obj = {
      event: "view_promotion",
        ecommerce: {
        mappedData
      }
    };
    //console.log("view_promotion", obj);
    dataLayerPush(obj);
  },
  impressionBannerClick: (data,location , index, bannerFullData) => {
    data = [data];
    const mappedData = data?.map((link, id) => ({
      items: {
        Creative_name: link.imageUrl, // Replace with the actual image URL
        creative_slot: index,
        location_id: location, // Replace with the actual image ID
        promotion_id: bannerFullData?.title_id,
        promotion_name: bannerFullData?.title,
      }
    }));
    let obj = {
      event: "select_promotion",
        ecommerce: {
        mappedData
      }
    };
    //console.log("select_promotion", obj);
    dataLayerPush(obj);
  },
  impressionViewProduct: (product, title = "", item_id = "") => {
    const transformedItems = product?.map((item,idx) => ({
      item_id: item?.id || item?.product?.id,
      item_sku: item?.sku || item?.product?.sku,
      item_name: item?.name || item?.product?.name,
      coupon: getCoupanPercentage(item), // You can add the appropriate coupon information here.
      shelf_price: item?.price?.toFixed(2) || item?.product?.price?.toFixed(2), //Original price without discount.
      price:     (item?.discounted_price?.toFixed(2) || item?.product?.discounted_price?.toFixed(2)) || ( item?.price?.toFixed(2) || item?.product?.price?.toFixed(2)), 
      discount: ( item?.price?.toFixed(2) || item?.product?.price?.toFixed(2)) - (item?.discounted_price?.toFixed(2) || item?.product?.discounted_price?.toFixed(2)), // Fixed to 2 decimal places.
      item_brand: "SONY", // You can add the brand information here.
      ...(parseCategory(item?.categories ? (item?.categories).map(category => category.name) : "")),
      item_list_id: item_id || null,
      item_list_name: title || getPageName(),
      index:idx,
      item_availability: item?.orderType || '',
      item_link: window.location.origin + "/en-sa" + productUrlHelper(item?.english_name, item?.sku),
      item_image_link: item?.baseImage || item?.product?.baseImage,
    }));
    let obj = {
      event: "view_item_list",
          ecommerce: {
          item_list_id: item_id || null, //The unique id of the list if it exists.
          item_list_name: title || getPageName(), // i.e. "TVs" or "Cameras" or “PlayStations”
          currency: 'SAR',
          items: transformedItems
        }
    };
   // console.log("OBJECT", obj);
    dataLayerPush(obj);
    
  },
  impressionProductListView: (items,title = '') => {
    const transformedItems = items?.map(item => ({
      item_id: item?.id || item?.product?.id,
      item_sku: item?.sku || item?.product?.sku,
      item_name: item?.name || item?.product?.name,
      coupon: getCoupanPercentage(item), // You can add the appropriate coupon information here.
      shelf_price: item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2), //Original price without discount.
      price:     (item?.product?.discounted_price?.toFixed(2) || item?.product?.product?.discounted_price?.toFixed(2)) || ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)), 
      discount: ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)) - (item?.product?.discounted_price?.toFixed(2) || item?.product?.product?.discounted_price?.toFixed(2)) || ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)), // Fixed to 2 decimal places.
      item_brand: "SONY", // You can add the brand information here.
      ...(parseCategory(item?.product?.categories ? (item?.product?.categories).map(category => category.name) : "")),
      item_list_id: item?.item_id,
      item_list_name: getPageName(),
      quantity:item?.qty || 1,
      item_availability: item?.product?.orderType || '',
      item_link: window.location.origin + "/en-sa" + productUrlHelper(item?.english_name, item?.sku),
      item_image_link: item?.baseImage || item?.product?.baseImage,
    }));
    let obj = { 
      event: "view_item_list",
        ecommerce: {
          items: transformedItems
        }
      };
   // console.log("view_item_list", obj);
    dataLayerPush(obj);
  },
  impressionSearch: (product,searchText) => {
    let obj = {
      'event': 'search',
      search_term: searchText, //The search term used
      search_results_count: product?.length, //The Count of Products returned to the user.
    };
   // console.log("SEARCH", obj);
    product && searchText && (dataLayerPush(obj))
  },
  impressionCategoryClick: (catObj,selectedId) => {
    const categoryList = catObj?.children_data?.map(category => ({
      category_id: category.id,
      category: category.name
    }));
    let obj = {
      'event': 'category_click',
      category_id: catObj?.id, //Main category ID.
      category: catObj?.name, //Main category name.
    };
    if(selectedId !== catObj?.id){
  
      categoryList.forEach((category, index) => {
        if(category.category_id == selectedId) {
          obj[`category_id2`] = category.category_id;
          obj[`category2`] = category.category;
        }
        
        
      });
    }else{
      obj[`category_id2`] = '';
      obj[`category2`] = '';
    }
    
 
    dataLayerPush(obj);
  },
  impressionProductDetails: (product,title = "") => {
    const selfPrice    =  product?.price || product?.product?.price;
    const price =  (product?.discounted_price || product?.product?.discounted_price) || (product?.price || product?.product?.price);
    const category =  product?.categories?.map(category => category.name) || product?.product?.categories?.map(category => category.name)
    const object = {};
    let obj = {
      event: "view_item",
      ecommerce: {
      currency: product?.currency || product?.product?.currency ,
      value: product?.price || product?.product?.price, // price
      items: [{
      item_id: product?.id || product?.product?.id,
      item_sku: product?.sku || product?.product?.sku,
      item_name: product?.name || product?.product?.name,
      coupon: getCoupanPercentage(product), //product coupon or offer or 2 for 3or buy one get one free.
      shelf_price: selfPrice, //Original price without discount.
      price: price, // Numeric float value 2 decimal digits
      discount: parseInt(selfPrice) - parseInt(price), // shelf_price - price
      item_brand: "SONY",
      ...(
        category?.forEach((element,i) => {
          i = i + 1;
         
          if(i === 1){
             object['item_category'] = element;
          }else{
            object['item_category'+i] = element;

          }
         })
        ),
      ...object,
      item_list_id: product?.item_id || getPageName(),
      item_list_name: getPageName(),
      item_availability: product?.orderType || '', //OR “Out Of Stock”
      item_link: window.location.origin + "/en-sa" + productUrlHelper(
        product?.english_name,
        product?.sku
      ),
      item_image_link: product?.baseImage || product?.product?.baseImage,
      }]}
    };
    
    if(Object.keys(product).length){
      dataLayerPush(obj);
    }
    
  },
  impressionProductClick: (eventName, product,title='',item_id='') => {
    const category=  product?.categories?.map(category => category.name) || product?.product?.categories?.map(category => category.name);
    const object = {};
    let obj = { 
      event: eventName,
          ecommerce: {
            currency: product?.currency || product?.product?.currency ,
            value: product?.price || product?.product?.price, // price
            items: [{
            item_id: product?.id || product?.product?.id,
            item_sku: product?.sku || product?.product?.sku,
            item_name: product?.name || product?.product?.name,
            coupon: getCoupanPercentage(product), //product coupon or offer or 2 for 3or buy one get one free.
            shelf_price: product?.price || product?.product?.price, //Original price without discount.
            price: product?.price || product?.product?.price, // Numeric float value 2 decimal digits
            discount: product?.discounted_price || product?.product?.discounted_price, // shelf_price - price
            item_brand: "SONY",
            ...(
              category?.forEach((element,i) => {
                i = i + 1;
               
                if(i === 1){
                   object['item_category'] = element;
                }else{
                  object['item_category'+i] = element;

                }
               })
              ),
            ...object,
            item_list_id: item_id || product?.item_id,
            item_list_name: title || getPageName(),
            item_availability: product?.orderType || '', //OR “Out Of Stock”
            item_link: window.location.origin + "/en-sa" + productUrlHelper(
              product?.english_name,
              product?.sku
            ),
            item_image_link: product?.baseImage || product?.product?.baseImage,
            }]}
      };
    dataLayerPush(obj);
  },
  impressionAddToCart: (product, count, title = "",item_id = "") => {
    if(product?.qty){
      count = product?.qty;
    }
    
    const category = product?.categories?.map(category => category.name) || product?.product?.categories?.map(category => category.name);
    const object = {};
    const selfPrice    =  product?.price|| product?.product?.price;
    const price =  (product?.discounted_price|| product?.product?.discounted_price) || (product?.price || product?.product?.price);
    let obj = { 
      event: "add_to_cart",
        ecommerce: {
          currency: product?.currency || product?.product?.currency ,
          value: product?.price || product?.product?.price, // price
          items: [{
          item_id: product?.id || product?.product?.id,
          item_sku: product?.sku || product?.product?.sku,
          item_name: product?.name.trim() || product?.product?.name.trim(),
          coupon: getCoupanPercentage(product), //product coupon or offer or 2 for 3or buy one get one free.
          shelf_price: selfPrice, //Original price without discount.
          price: price, // Numeric float value 2 decimal digits
          discount: selfPrice - price, // shelf_price - price
          item_brand: "SONY",
          ...(
            category?.forEach((element,i) => {
              i = i + 1;
             
              if(i === 1){
                 object['item_category'] = element;
              }else{
                object['item_category'+i] = element;
    
              }
             })
            ),
          ...object,
          item_list_id: item_id || product?.item_id || getPageName(),
          item_list_name: title || getPageName(),
          item_availability: product?.orderType || product?.product?.orderType, //OR “Out Of Stock”
          item_link: window.location.origin + "/en-sa" + productUrlHelper(
            product?.english_name,
            product?.sku
          ),
          item_image_link: product?.baseImage || product?.product?.baseImage,
          quantity: parseInt(count), // Numeric integer value
        }]
        }
      };
    dataLayerPush(obj);
  },
  impressionDeleteFromCart: (product, count, title = "") => {
    const category = product?.categories?.map(category => category.name) || product?.product?.categories?.map(category => category.name);
    const object = {};
    let obj = { 
      event: "remove_from_cart",
        ecommerce: {
          currency: product?.currency || product?.product?.currency ,
          value: product?.price || product?.product?.price, // price
          items: [{
          item_id: product?.id || product?.product?.id,
          item_sku: product?.sku || product?.product?.sku,
          item_name: product?.name || product?.product?.name,
          coupon: getCoupanPercentage(product), //product coupon or offer or 2 for 3or buy one get one free.
          shelf_price: product?.price?.toFixed(2) || product?.product?.price?.toFixed(2), //Original price without discount.
          price: product?.price?.toFixed(2) || product?.product?.price?.toFixed(2),  // Numeric float value 2 decimal digits
          discount: product?.discounted_price || product?.product?.discounted_price, // shelf_price - price
          item_brand: "SONY",
          ...(
            category?.forEach((element,i) => {
              i = i + 1;
             
              if(i === 1){
                 object['item_category'] = element;
              }else{
                object['item_category'+i] = element;
        
              }
             })
            ),
          ...object,
          item_list_id: product?.item_id,
          item_list_name: getPageName(),
          item_availability: product?.orderType || product?.product?.orderType, //OR “Out Of Stock”
          item_link: window.location.origin + "/en-sa" + productUrlHelper(
            product?.english_name,
            product?.sku
          ),
          item_image_link: product?.baseImage || product?.product?.baseImage,
          quantity: count, // Numeric integer value
        }]
        }
      };
    //console.log("remove_from_cart", obj);
    dataLayerPush(obj);
  },

  impressionViewCart: (product,title = "") => {
    //console.log('product',product);
    const transformedItems = product?.items?.map(item => ({
      item_id: item?.id || item?.product?.id,
      item_sku: item?.sku || item?.product?.sku,
      item_name: item?.name || item?.product?.name,
      coupon: getCoupanPercentage(item), // You can add the appropriate coupon information here.
      shelf_price: item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2), //Original price without discount.
      price:     (item?.product?.discounted_price?.toFixed(2) || item?.product?.product?.discounted_price?.toFixed(2)) || ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)), 
      discount: ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)) - (item?.product?.discounted_price?.toFixed(2) || item?.product?.product?.discounted_price?.toFixed(2)) || ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)), // Fixed to 2 decimal places.
      item_brand: "SONY", // You can add the brand information here.
      ...(parseCategory(item?.product?.categories ? (item?.product?.categories).map(category => category.name) : "")),
      item_list_id: item?.item_id,
      item_list_name: getPageName(),
      item_availability: item?.product?.orderType || '', //? 'In-stock' : 'Out of Stock',
      item_link: window.location.origin + "/en-sa" + productUrlHelper(item?.english_name, item?.sku),
      item_image_link: item?.baseImage || item?.product?.baseImage,
      quantity: item?.product?.items_qty || item?.qty
    }));
    let obj = { 
      event: "view_cart",
        ecommerce: {
          currency: product?.currency?.base_currency_code || product?.product?.currency,
          value: product?.totals_data?.grand_total || product?.product?.price, // price
          items: transformedItems
        }
      };
    if(typeof product!=='undefined' && product){
      dataLayerPush(obj);
    }
    
  },
  impressionBeginCheckout: (product,title = "") => {
    //console.log('product',product);
    const transformedItems = product?.items?.map(item => ({
      item_id: item?.id || item?.product?.id,
      item_sku: item?.sku || item?.product?.sku,
      item_name: item?.name || item?.product?.name,
      coupon: getCoupanPercentage(item), // You can add the appropriate coupon information here.
      shelf_price: item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2), //Original price without discount.
      price:     (item?.product?.discounted_price?.toFixed(2) || item?.product?.product?.discounted_price?.toFixed(2)) || ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)), 
      discount: ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)) - (item?.product?.discounted_price?.toFixed(2) || item?.product?.product?.discounted_price?.toFixed(2)) || ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)), // Fixed to 2 decimal places.
      item_brand: "SONY", // You can add the brand information here.
      ...(parseCategory(item?.product?.categories ? (item?.product?.categories).map(category => category.name) : "")),
      item_list_id: item?.item_id,
      item_list_name: getPageName(),
      quantity:item?.qty || 1,
      item_availability: item?.product?.orderType || '',
      item_link: window.location.origin + "/en-sa" + productUrlHelper(item?.english_name, item?.sku),
      item_image_link: item?.baseImage || item?.product?.baseImage,
    }));
    let obj = { 
      event: "begin_checkout",
        ecommerce: {
          currency: product?.currency?.base_currency_code || product?.product?.currency ,
          value: product?.totals_data?.grand_total || product?.product?.price, // price
          coupon:product?.totals_data?.coupon_code || 'null',
          items: transformedItems
        }
      };
    dataLayerPush(obj);
  },
  impressionAddToWishList: (product, title = "") => {
    const category = product?.categories?.map(category => category.name) || product?.product?.categories?.map(category => category.name);
    const object = {};
    const selfPrice    =  product?.price|| product?.product?.price;
    const price =  (product?.discounted_price|| product?.product?.discounted_price) || (product?.price || product?.product?.price);
    let obj = {
      event: "add_to_wishlist",
      ecommerce: {
      currency: product?.currency || product?.product?.currency ,
      value: product?.price || product?.product?.price, // price
      items: [{
      item_id: product?.id || product?.product?.id,
      item_sku: product?.sku || product?.product?.sku,
      item_name: product?.name || product?.product?.name,
      coupon: getCoupanPercentage(product), //product coupon or offer or 2 for 3or buy one get one free.
      shelf_price: selfPrice, //Original price without discount.
      price: price, // Numeric float value 2 decimal digits
      discount: selfPrice - price, // shelf_price - price
      item_brand: "SONY",
      ...(
        category?.forEach((element,i) => {
          i = i + 1;
         
          if(i === 1){
             object['item_category'] = element;
          }else{
            object['item_category'+i] = element;
    
          }
         })
        ),
      ...object,
      item_list_id: product?.item_id,
      item_list_name: getPageName(),
      item_availability: product?.orderType  || product?.product?.orderType || '', //OR “Out Of Stock”
      item_link: window.location.origin + "/en-sa" + productUrlHelper(
        product?.english_name,
        product?.sku
      ),
      item_image_link: product?.baseImage || product?.product?.baseImage,
      }]}
    };
    //console.log("add_to_wishlist", obj);
    dataLayerPush(obj);
  },
  impressionRemoveFromWishlist: (product,title = "") => {
    const category = product?.categories?.map(category => category.name) || product?.product?.categories?.map(category => category.name);
    const object = {};
    const selfPrice    =  product?.price|| product?.product?.price;
    const price =  (product?.discounted_price|| product?.product?.discounted_price) || (product?.price || product?.product?.price);
    let obj = {
      event: "remove_from_wishlist",
      ecommerce: {
      currency: product?.currency || product?.product?.currency ,
      value: product?.price || product?.product?.price, // price
      items: [{
        item_id: product?.id || product?.product?.id,
        item_sku: product?.sku || product?.product?.sku,
        item_name: product?.name || product?.product?.name,
        coupon: getCoupanPercentage(product), //product coupon or offer or 2 for 3or buy one get one free.
        shelf_price: selfPrice, //Original price without discount.
        price: price, // Numeric float value 2 decimal digits
        discount: selfPrice - price, // shelf_price - price
        item_brand: "SONY",
        ...(
          category?.forEach((element,i) => {
            i = i + 1;
           
            if(i === 1){
               object['item_category'] = element;
            }else{
              object['item_category'+i] = element;
      
            }
           })
          ),
        ...object,
        item_list_id: product?.item_id,
        item_list_name: getPageName(),
        item_availability: product?.orderType  || product?.product?.orderType || '', //OR “Out Of Stock”
        item_link: window.location.origin + "/en-sa" + productUrlHelper(
          product?.english_name,
          product?.sku
        ),
        item_image_link: product?.baseImage || product?.product?.baseImage,
        }]}
    };
    //console.log("remove_from_wishlist", obj);
    dataLayerPush(obj);
  },
  impressionAddShippingInformation: (product, title = "", deliveryPreferencesType = "Home Delivery") => {
    //console.log('product',product);
    const transformedItems = product?.items?.map(item => ({
      item_id: item?.id || item?.product?.id,
      item_sku: item?.sku || item?.product?.sku,
      item_name: item?.name || item?.product?.name,
      coupon: getCoupanPercentage(item), // You can add the appropriate coupon information here.
      shelf_price: item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2), //Original price without discount.
      price:     (item?.product?.discounted_price?.toFixed(2) || item?.product?.product?.discounted_price?.toFixed(2)) || ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)), 
      discount: ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)) - (item?.product?.discounted_price?.toFixed(2) || item?.product?.product?.discounted_price?.toFixed(2)) || ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)), // Fixed to 2 decimal places.
      item_brand: "SONY", // You can add the brand information here.
      ...(parseCategory(item?.product?.categories ? (item?.product?.categories).map(category => category.name) : "")),
      item_list_id: item?.item_id,
      item_list_name: getPageName(),
      quantity:item?.qty || 1,
      item_availability: item?.product?.orderType || '',
      item_link: window.location.origin + "/en-sa" + productUrlHelper(item?.english_name, item?.sku),
      item_image_link: item?.baseImage || item?.product?.baseImage,
    }));
    let obj = { 
      event: "add_shipping_info",
        ecommerce: {
          currency: product?.currency?.base_currency_code || product?.product?.currency ,
          value: product?.totals_data?.grand_total || product?.product?.price, // price
          coupon:product?.totals_data?.coupon_code || 'null',
          shipping_tier : deliveryPreferencesType === 'flatrate' ?'Home Delivery' : 'StorePickup',
          items: transformedItems
        }
      };
    dataLayerPush(obj);
  },
  impressionAddPaymentInfo: (product,title = "", method) => {
    //console.log('product',product);
    const transformedItems = product?.items?.map(item => ({
      item_id: item?.id || item?.product?.id,
      item_sku: item?.sku || item?.product?.sku,
      item_name: item?.name || item?.product?.name,
      coupon: getCoupanPercentage(item), // You can add the appropriate coupon information here.
      shelf_price: item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2), //Original price without discount.
      price:     (item?.product?.discounted_price?.toFixed(2) || item?.product?.product?.discounted_price?.toFixed(2)) || ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)), 
      discount: ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)) - (item?.product?.discounted_price?.toFixed(2) || item?.product?.product?.discounted_price?.toFixed(2)) || ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)), // Fixed to 2 decimal places.
      item_brand: "SONY", // You can add the brand information here.
      ...(parseCategory(item?.product?.categories ? (item?.product?.categories).map(category => category.name) : "")),
      item_list_id: item?.item_id,
      item_list_name: getPageName(),
      quantity:item?.qty || 1,
      item_availability: item?.product?.orderType || '',
      item_link: window.location.origin + "/en-sa" + productUrlHelper(item?.english_name, item?.sku),
      item_image_link: item?.baseImage || item?.product?.baseImage,
    }));
    let obj = { 
      event: "add_payment_info",
        ecommerce: {
          currency: product?.currency?.base_currency_code || product?.product?.currency ,
          value: product?.totals_data?.grand_total || product?.product?.price, // price
          coupon:product?.totals_data?.coupon_code || 'null',
          payment_type: method,
          items: transformedItems,
          
        }
      };
    //console.log("add_payment_info", obj);
    dataLayerPush(obj);
  },
  impressionPurchases: (product,title = "") => {
    //console.log('product',product);
    const transformedItems = product?.items?.map(item => ({
      item_id: item?.id || item?.product?.id,
      item_sku: item?.sku || item?.product?.sku,
      item_name: item?.name || item?.product?.name,
      coupon: getCoupanPercentage(item), // You can add the appropriate coupon information here.
      shelf_price: item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2), //Original price without discount.
      price:     (item?.product?.discounted_price?.toFixed(2) || item?.product?.product?.discounted_price?.toFixed(2)) || ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)), 
      discount: ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)) - (item?.product?.discounted_price?.toFixed(2) || item?.product?.product?.discounted_price?.toFixed(2)) || ( item?.product?.price?.toFixed(2) || item?.product?.product?.price?.toFixed(2)), // Fixed to 2 decimal places.
      item_brand: "SONY", // You can add the brand information here.
      ...(parseCategory(item?.product?.categories ? (item?.product?.categories).map(category => category.name) : "")),
      item_list_id: item?.item_id,
      item_list_name: getPageName(),
      quantity:item?.qty || 1,
      item_availability: item?.product?.orderType || '',
      item_link: window.location.origin + "/en-sa" + productUrlHelper(item?.english_name, item?.sku),
      item_image_link: item?.baseImage || item?.product?.baseImage,
      item_base_price: item?.extension_attributes?.price_without_tax_shipping
    }));
    let obj = { 
      event: 'purchase',
        ecommerce: {
          transaction_id: product?.increment_id?.toString(),
          value: product?.grand_total, // The Total value of the Cart
          tax: product?.tax_amount,
          shipping: product?.shipping_amount, // Shipping Cost
          currency: product?.order_currency_code,
          coupon: product?.coupon_code || null, 
          items: transformedItems
        }
      };
   // console.log("purchase", obj);
    dataLayerPush(obj);
  },
  impressionCouponApply: (couponcode,result) => {
    let obj = {
      'event': 'coupon_redemption',
      'code': couponcode, // The coupon code.
      'redemption_result': result //If coupon Failed, include the reason ‘Coupon Failed: the coupon is expired’
    };
    //console.log("coupon_redemption", obj);
    dataLayerPush(obj);
  },
  impressionPaymentFail: (method,reason) => {
    let obj = {
      'event': 'payment_failed',
      'method': method, // The coupon code.
      'reason': reason ? reason : 'payment failed due to wrong card'  //If coupon Failed, include the reason ‘Coupon Failed: the coupon is expired’
    };
    //console.log("coupon_redemption", obj);
    dataLayerPush(obj);
  },
  impressionShippingAddress: (eventName, userData) => {
    const user = getCustomerLoginDetails();
    let obj = {
      event: eventName,
      user_id: userData?.user_id || user?.id ||"", //Leave it empty if failed
      email: userData?.email || user?.email ||"",
      phone: userData?.telephone,
      first_name: userData?.firstName || "",
      last_name: userData?.lastName || "",
      country: userData?.countryId,
      city: userData?.city,
      address: userData?.addressLine1,
    };
    //console.log("OBJECT", obj);
    dataLayerPush(obj);
  },
  impressionNewsLetterSubcription: (eventName, userData) => {
    const user = getCustomerLoginDetails();
    let obj = {
      event: eventName,
      email: userData?.email || user?.email || "",
    };
    //console.log("OBJECT", obj);
    dataLayerPush(obj);
  },
  impressionGlobalError: (message="",url="",type="") => {
    let obj = {
      event: 'errors',
      error_massage: message || "null ",
      Error_url:url || null,
      error_type: type || null,
    };
    //console.log("OBJECT", obj);
    dataLayerPush(obj);
  },
  impressionCustomerReferral: (data) => {
    let obj = {
      event: 'customer_referral_and_cash_back',
      ...data
    };
    //console.log("OBJECT", obj);
    dataLayerPush(obj);
  },
  impressionClick: (productData, list, position) => {
    const price = Number(
      parseFloat(
        productData.discounted_price_rounded || productData.price_rounded
      ).toFixed(2)
    );
    const categories = productData?.categories || [];
    const category = [...categories].pop()?.name || "Sony";

    let discount = 0;
    const discountedPrice = Number(
      parseFloat(productData.discounted_price_rounded).toFixed(2)
    );
    if (discountedPrice < productData?.price_rounded)
      discount = price - discountedPrice;

    const products = [
      {
        name: productData.name,
        id: productData.id,
        price,
        category,
        discount,
        position: position + 1,
        dimension1: productData.sku,
      },
    ];

    if (list) {
      dataLayerPush({
        event: "productClick",
        ecommerce: {
          currencyCode: productData?.currency || "SAR",
          click: {
            actionField: { list },
            products,
          },
        },
      });
    } else {
      dataLayerPush({
        event: "productClick",
        ecommerce: {
          currencyCode: productData?.currency || "SAR",
          click: {
            products,
          },
        },
      });
    }
  },

  impressionDetail: (productData, list, variant) => {
    const price = Number(
      parseFloat(
        productData.discounted_price_rounded || productData.price_rounded
      ).toFixed(2)
    );
    const categories = productData?.categories || [];
    const category = [...categories].pop()?.name || "Sony";
    const stockStatus = productData.orderType.replace(/-/g, "_");
    const variantName = variant?.name || "";
    const brand ="Sony";

    let discount = 0;
    const discountedPrice = Number(
      parseFloat(productData.discounted_price_rounded).toFixed(2)
    );
    if (discountedPrice < productData?.price_rounded)
      discount = price - discountedPrice;

    const products = [
      {
        name: productData.name,
        id: productData.id,
        price,
        brand,
        category,
        discount,
        variant: variantName,
        dimension1: productData.sku,
        dimension4: stockStatus,
      },
    ];

    if (list) {
      dataLayerPush({
        event: "view_item",
        ecommerce: {
          currencyCode: productData?.currency || "SAR",
          detail: {
            actionField: { list },
            products,
          },
        },
      });
    } else {
      dataLayerPush({
        event: "view_item",
        ecommerce: {
          currencyCode: productData?.currency || "SAR",
          detail: {
            products,
          },
        },
      });
    }
  },
};
