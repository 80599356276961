import React from 'react';
import { InfoWindow } from '@react-google-maps/api';
import "./../../SCSS/_storesInfoWindow.scss"
import { useTranslation } from 'react-i18next';

const StoresInfoWindow = ({ position, data, onCloseClick }) => {

  const constructHour = (item) => {
    const [start, end] = item.split(' - ');
    const [startHour, startPeriod] = start.split(' ');
    const [endHour, endPeriod] = end.split(' ');
    return `${startHour} ${startPeriod} - ${endHour} ${endPeriod}`;
  };

  const { t } = useTranslation()

  return (
    <InfoWindow position={position} onCloseClick={onCloseClick}>
      <div className="wrapper">
        <div className="item name">{data.name}</div>
        <div className="item">
          <span>{t("Address")}</span>
          <label>{data.address}</label>
        </div>
        <div className="item">
          <span>{t("Phone")}</span>
          <a href={`tel:${data.phoneNumber}`} target="_" rel="noopener nofollow noreferrer" dir="ltr">{data.phoneNumber}</a>
        </div>
        {data.openingHours && (
          <div className="hours">
            <div className="title">{t("Opening_hours")}</div>
            <div className="items">
              {Object.keys(data.openingHours).map((item) => (
                <div key={item} className="item">
                  <span>{item}</span>
                  <div className="hourItems">
                    {[...data.openingHours[item]].map((hour) => <label key={hour}>{constructHour(hour)}</label>)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </InfoWindow>
  );
};

export default StoresInfoWindow;
