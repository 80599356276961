import DefaultImage from '../../../assets/Icon/default_image.png';
const mediaApiUrl = process.env.REACT_APP_PROJECT_MEDIA_API_URL
const isCDNUrl = process.env.REACT_APP_MEDIA_CDN
const isCDN = isCDNUrl === 'true' ? true : false
const mediaUrl = mediaApiUrl.replace("/pub/media/", "");
const responsiveUrl = "cdn-cgi/image"

const imgSize = [200]

export const ImageResize = (img) => {
    let arr = imgSize.map((item) => {
        return (
            img ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/fit=contain,width=${item}`).concat(` ${item}w`)
                : DefaultImage
        )
    })
    return (
        [
            { "img": img ? isCDN ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/width=${imgSize[0]},q=90,f=auto`) : img : DefaultImage, width: imgSize[0] },
            // { "imgSrc": isCDN ? arr : [img] }
        ]
    )
}

const bannerImgSize = [2200]

export const BannerImageResize = (img) => {

    let arr = bannerImgSize.map((item) => {
        return (
            img ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/fit=contain,width=${item}`).concat(` ${item}w`)
                : DefaultImage
        )
    })
    return (
        [
            { "img": img ? isCDN ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/width=${bannerImgSize[0]},q=90,f=auto`) : img : DefaultImage, width: bannerImgSize[0] },
            // { "imgSrc": isCDN ? arr : [img] }
        ]
    )
}

const halfBannerImgSize = [570, 640, 700]

export const HalfBannerImageResize = (img) => {

    let arr = halfBannerImgSize.map((item) => {
        return (
            img ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/fit=contain,width=${item}`).concat(` ${item}w`)
                : DefaultImage
        )
    })
    return (
        [
            { "img": img ? isCDN ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/width=${halfBannerImgSize[0]},q=90,f=auto`) : img : DefaultImage, width: halfBannerImgSize[0] },
            // { "imgSrc": isCDN ? arr : [img] }
        ]
    )
}

const bottomBannerImgSize = [1480]

export const BottomBannerImageResize = (img) => {

    let arr = bottomBannerImgSize.map((item) => {
        return (
            img ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/fit=contain,width=${item}`).concat(` ${item}w`)
                : DefaultImage
        )
    })
    return (
        [
            { "img": img ? isCDN ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/width=${bottomBannerImgSize[0]},q=100,f=auto`) : img : DefaultImage, width: bottomBannerImgSize[0] },
            // { "imgSrc": isCDN ? arr : [img] }
        ]
    )
}

const magnifyImgSize = [500, 800]

export const MagnifyImageResize = (img) => {
    let arr = magnifyImgSize.map((item) => {
        return (
            img ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/fit=contain,width=${item}`).concat(` ${item}w`)
                : DefaultImage
        )
    })
    return (
        [
            { "img": img ? isCDN ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/width=${magnifyImgSize[0]},q=90,f=auto`) : img : DefaultImage, width: magnifyImgSize[0] },
            // { "imgSrc": isCDN ? arr : [img] }
        ]
    )
}


const mobileImgSize = [280]

export const MobileImageResize = (img) => {

    let arr = mobileImgSize.map((item) => {
        return (
            img ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/fit=contain,width=${item}`).concat(` ${item}w`)
                : DefaultImage
        )
    })
    return (
        [
            { "img": img ? isCDN ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/width=${mobileImgSize[0]},q=70,f=auto`) : img : DefaultImage, width: mobileImgSize[0] },
            // { "imgSrc": isCDN ? arr : [img] }
        ]
    )
}

const mobileBlockImgSize = [400]

export const MobileBlockImageResize = (img) => {
    let arr = mobileBlockImgSize.map((item) => {
        return (
            img ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/fit=contain,width=${item}`).concat(` ${item}w`)
                : DefaultImage
        )
    })
    return (
        [
            { "img": img ? isCDN ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/width=${mobileBlockImgSize[0]},q=70,f=auto`) : img : DefaultImage, width: mobileBlockImgSize[0] },
            // { "imgSrc": isCDN ? arr : [img] }
        ]
    )
}

const smallImgResize = [180]

export const SmallImageResize = (img) => {
    let arr = smallImgResize.map((item) => {
        return (
            img ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/fit=contain,width=${item}`).concat(` ${item}w`)
                : DefaultImage
        )
    })

    return (
        [
            { "img": img ? isCDN ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/width=${smallImgResize[0]},q=80,f=auto`) : img : DefaultImage, width: smallImgResize[0] },
            // { "imgSrc": isCDN ? arr : [img] }
        ]
    )
}


const searchSmallImgResize = [550]

export const SearchSmallImageResize = (img) => {
    let arr = searchSmallImgResize.map((item) => {
        return (
            img ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/fit=contain,width=${item}`).concat(` ${item}w`)
                : DefaultImage
        )
    })

    return (
        [
            { "img": img ? isCDN ? img.replace(mediaUrl, `${mediaUrl}/${responsiveUrl}/width=${searchSmallImgResize[0]},q=90,f=auto`) : img : DefaultImage, width: searchSmallImgResize[0] },
            // { "imgSrc": isCDN ? arr : [img] }
        ]
    )
}
