import React from "react";
import Heading6 from "../Font/Heading6";
import Text3 from "../Font/Text3";
import { useTranslation } from "react-i18next";

function ExpressAndLightingDeliveryMsg({ options }) {

  const { t } = useTranslation();

  const currentTime = new Date();
  const getCurrentHours = currentTime.getHours();

  return (
    <>
      {(options && options.filter(val => val.code === "express_shipping_eligible").length > 0 || options.filter(val => val.code === "lighting_shipping_eligible").length > 0) &&
        <>
          {getCurrentHours < 12 ?
            <>
              <Heading6 text={t('Order_with_lightening_delivery')} />
              <Text3 text={t('gets_it_by_today')} color="#ff9b04" />
            </>
            :
            <>
              <Heading6 text={t('Order_with_express_delivery')} />
              <Text3 text={t('gets_it_by_tomorrow')} color="#ff9b04" />
            </>
          }
        </>
      }
    </>
  );
}

export default ExpressAndLightingDeliveryMsg;
