/* eslint-disable no-undef */
/* eslint-disable quote-props */
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { loadCartData } from '../../redux/appAction';
import { addToCart, createAppleBillingAddress, deleteCart, emptyCart, getCheckoutInformation, getPayfortInformation, getPaymentMethod, resetShippingInformation, updateShippingInformation } from '../../services/cart.service';
import { getAppleRequest, isApplePaySupported, performTransaction, validateApplePaySession, validateCheckoutApplePaySession } from '../helpers/utils/applePay';
import * as services from "./../../services/services";
import { getHandsakeConfig } from '../helpers/utils/handsakeConfig';
import { shippingAddressSubmit } from './AppleCheckout';
import { BsApple } from 'react-icons/bs';
import { cartEvents } from '../helpers/utils/ga/cartEvents';
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";

const ApplePay = ({
    id, sku, t, checkout, qty, closeCartPopup, total, paymentMethod
}) => {
    const { REACT_APP_PAYMENT_REDIRECT_URL } = process.env;
    let success, paymentData;
    const [isApplePayAvailable, setIsApplePayAvailable] = useState(null);
    const [applePayConfig, setApplePayConfig] = useState({});
    const [isAppleEnable, setIsAppleEnable] = useState(false);
    const [ecom, setEcom] = useState()

    const cart = useSelector((state) => state.appData.cartData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setCartErr } = useCartErrorMessage();

    const currentLang = localStorage.getItem("i18next") || "en";

    useEffect(() => {
        let getConfigData = getHandsakeConfig();
        if (getConfigData) {
            setEcom(getConfigData)
        }
    }, []);

    useEffect(() => {
        setIsAppleEnable(parseInt(ecom?.featureFlags?.ecom?.bootstraper?.applePay, 10) || ecom?.featureFlags?.ecom?.bootstraper?.apple_pay_checkout);
    }, [ecom]);

    useEffect(() => {
        const checkApplePay = async () => {
            setIsApplePayAvailable(await isApplePaySupported());
        };
        checkApplePay();
    }, []);


    useEffect(() => {
        if (cart) {
            setApplePayConfig({
                ...applePayConfig,
                shop: {
                    subtotal: cart?.totals_data?.subtotal_incl_tax,
                    product_price: cart?.totals_data?.base_subtotal,
                    shipping_price: cart?.totals_data?.shipping_incl_tax,
                    discount_price: cart?.totals_data?.discount_amount,
                    discount_code: cart?.totals_data?.coupon_code || null,
                    total: cart?.totals_data?.base_grand_total,
                    tax_price: cart?.totals_data?.tax_amount,
                },
            });
        }
    }, [cart]);

    const prepareCart = async (appleSession) => {
        try {
            let cartData;

            if (sku) {
                await emptyCart()
                try {
                    await addToCart({
                        items: [
                            {
                                sku,
                                id,
                                qty: qty ? qty : 1
                            },
                        ],
                    })
                    const items = await dispatch(loadCartData());
                    cartData = items;
                    cartEvents.addToCart(cartData?.items[0], cartData?.items[0]?.id, "Product Detail");
                } catch (err) {
                    setCartErr({ stock: 0, error: err?.response?.data });
                    appleSession.completePayment(appleSession.STATUS_FAILURE);
                }
            }

            if (cart?.extension_attributes?.shipping_assignments[0]?.shipping.method) {
                const resetShipment = async () => {
                    await resetShippingInformation();
                    await getPaymentMethod({ paymentMethod: { method: cart?.totals_data?.base_grand_total > 0 ? 'mestores_applepay' : 'free' }, email: cart.billing_address.email });
                };
                resetShipment();
            }
            return cartData
        } catch (error) {
            dispatch(services.notifyError({ message: error.name === 'TypeError' ? t('No internet connection') : error.message }));
        }
    };

    const handleApplePayEvents = async (appleSession) => {
        appleSession.onvalidatemerchant = async (event) => {
            if (paymentMethod === 'mestores_applepay') {
                validateApplePaySession(event.validationURL, (merchantSession) => {
                    appleSession.completeMerchantValidation(merchantSession);
                });
            } else {
                validateCheckoutApplePaySession(event.validationURL, (merchantSession) => {
                    appleSession.completeMerchantValidation(merchantSession);
                });
            }
        };

        if (sku) {
            appleSession.oncancel = async () => {
                await deleteCart();
            };

            appleSession.onpaymentmethodselected = async (event) => {
                const cartConfig = await prepareCart(appleSession);
                const updatedSessionObject = {
                    newTotal: {
                        label: t('SONY'),
                        amount: cartConfig?.totals_data?.base_grand_total,
                        type: 'final',
                    },

                    newLineItems: [
                        {
                            label: t('SUBTOTAL'),
                            amount: cartConfig?.totals_data?.subtotal_incl_tax,
                        },
                        {
                            label: t('TAX'),
                            amount: cartConfig?.totals_data?.tax_amount,
                        },
                    ],
                };

                if (cartConfig?.totals_data?.coupon_code) {
                    updatedSessionObject.newLineItems.push({
                        label: t(`DISCOUNT (${cartConfig?.totals_data?.coupon_code})`),
                        amount: cartConfig?.totals_data?.discount_amount,
                    });
                }

                appleSession.completePaymentMethodSelection(updatedSessionObject);
            };
        }

        appleSession.onpaymentauthorized = async (event) => {
            const applePaymentToken = event.payment.token;
            let pickup_store = '';
            if (typeof cart?.extension_attributes?.shipping_assignments[0] !== 'undefined' && typeof cart?.extension_attributes?.shipping_assignments[0]?.shipping?.address?.extension_attributes?.pickup_store !== 'undefined') {
              pickup_store = cart?.extension_attributes?.shipping_assignments[0]?.shipping?.address?.extension_attributes?.pickup_store;
            }
            if (!pickup_store) {
              if (localStorage.getItem('last-dt') === 'storepickup') {
                pickup_store = localStorage.getItem('pickup_store');
              }
            }
            try {
                if (!checkout) {
                    if (cart?.is_virtual || sku) {
                        try {
                            await dispatch(createAppleBillingAddress({
                                firstName: event?.payment?.shippingContact?.givenName.trim(),
                                lastName: event?.payment?.shippingContact?.familyName.trim(),
                                telephone: event?.payment?.shippingContact?.phoneNumber,
                                email: event?.payment?.shippingContact?.emailAddress,
                                countryId: 'SA', // TODO,
                                save: false,
                            })).then(() => {
                                success = true
                            }).catch((error) => {
                                console.error('Error dispatching billingAddressSubmit:', error);
                            });
                        } catch (error) {
                            console.error('Error submitting billing address:', error);
                        }
                    } else {
                        try {
                            await shippingAddressSubmit({
                                firstName: event?.payment?.shippingContact?.givenName.trim(),
                                lastName: event?.payment?.shippingContact?.familyName.trim(),
                                email: event?.payment?.shippingContact?.emailAddress,
                                telephone: event?.payment?.shippingContact?.phoneNumber,
                                city: event?.payment?.shippingContact?.subLocality || event?.payment?.shippingContact?.administrativeArea,
                                postCode: event?.payment?.shippingContact?.postalCode,
                                countryId: 'SA', // TODO,
                                street: event?.payment?.shippingContact?.addressLines[0].trim(),
                                save: false,
                                useAsBilling: true,
                                ...(pickup_store && { pickup_store, shippingCarrierCode: 'storepickup' }),
                            }, dispatch).then(() => {
                                success = true
                            }).catch((error) => {
                                console.error('Error dispatching billingAddressSubmit:', error);
                            });
                        } catch (error) {
                            console.error('Error submitting billing address:', error);
                        }
                    }
                } else if (checkout) {
                    if (cart?.is_virtual) {
                        try {
                            await dispatch(createAppleBillingAddress({
                                firstName: cart?.billing_address?.firstname.trim(),
                                lastName: cart?.billing_address?.lastname.trim(),
                                telephone: cart?.billing_address?.telephone,
                                email: cart?.billing_address?.email,
                                countryId: cart?.billing_address?.country_id,
                                save: false,
                            })).then(() => {
                                success = true
                            }).catch((error) => {
                                console.error('Error dispatching billingAddressSubmit:', error);
                            });
                        } catch (error) {
                            console.error('Error submitting billing address:', error);
                        }
                    } else {
                        try {
                            await shippingAddressSubmit({
                                firstName: cart?.billing_address?.firstname.trim(),
                                lastName: cart?.billing_address?.lastname.trim(),
                                email: cart?.billing_address?.email,
                                telephone: cart?.billing_address?.telephone,
                                city: cart?.billing_address?.city,
                                postCode: cart?.billing_address?.postcode,
                                countryId: cart?.billing_address?.country_id,
                                street: cart?.billing_address?.street[0].trim(),
                                save: false,
                                useAsBilling: true,
                                ...(pickup_store && { pickup_store, shippingCarrierCode: 'storepickup' }),
                            }, dispatch).then(() => {
                                success = true
                            }).catch((error) => {
                                console.error('Error dispatching billingAddressSubmit:', error);
                            });
                        } catch (error) {
                            console.error('Error submitting billing address:', error);
                        }
                    }
                }
                console.log(success,"success")
                if (success) {
                    await getPaymentMethod({ paymentMethod: { method: paymentMethod }, email: !checkout ? event?.payment?.shippingContact?.emailAddress : cart?.billing_address?.email })
                        .then((res) => { paymentData = res })
                        .catch((error) => {
                            dispatch(services.notifyError({ message: error?.response?.data?.message }));
                            appleSession.completePayment(appleSession.STATUS_FAILURE);
                        });

                    if (paymentData) {
                        const device_fingerprint = document.getElementById('device_fingerprint')?.value;
                        if (paymentMethod === 'mestores_applepay') {
                            const payfortResponse = await getPayfortInformation({
                                paymentMethod: {
                                    method: paymentMethod,
                                    email: !checkout ? event?.payment?.shippingContact?.emailAddress : cart?.billing_address?.email,
                                    referer_url: process.env.NODE_ENV !== 'production' ? REACT_APP_PAYMENT_REDIRECT_URL : REACT_APP_PAYMENT_REDIRECT_URL,
                                    device_fingerprint: device_fingerprint,
                                },
                            });
                            const transactionResponse = await performTransaction(applePaymentToken, payfortResponse?.data);

                            if (transactionResponse) {
                                if (transactionResponse?.response_code === '14000' && (transactionResponse?.response_message === "Success" || transactionResponse?.response_message === "عملية ناجحة")) {
                                    localStorage.setItem("OrderID", JSON.stringify(payfortResponse?.data?.entity_id));
                                    localStorage.setItem("lod", JSON.stringify(payfortResponse?.data?.entity_id));
                                    appleSession.completePayment(appleSession.STATUS_SUCCESS);
                                    navigate("/checkout/success")
                                } else {
                                    localStorage.setItem("OrderID", JSON.stringify(payfortResponse?.data?.entity_id));
                                    localStorage.setItem("lod", JSON.stringify(payfortResponse?.data?.entity_id));
                                    dispatch(services.notifyError({ message: transactionResponse.response_message }));
                                    appleSession.completePayment(appleSession.STATUS_FAILURE);
                                    navigate("/checkout/failure");
                                }
                            }
                        } else {
                            const applePayCheckoutResponse  = await getCheckoutInformation({
                                paymentMethod: {
                                    '___store': currentLang,
                                    payment_method: paymentMethod,
                                    save_card: 0,
                                    success_url: '',
                                    failure_url: '',
                                    token_data: applePaymentToken.paymentData,
                                },
                            });

                            localStorage.setItem("OrderID", JSON.stringify(applePayCheckoutResponse.order_id));
                            localStorage.setItem("lod", JSON.stringify(applePayCheckoutResponse.order_id));

                            if (applePayCheckoutResponse.success) {
                                appleSession.completePayment(appleSession.STATUS_SUCCESS);
                                navigate("/checkout/success")
                            }  else {
                                dispatch(services.notifyError({ message: transactionResponse.response_message }));
                                appleSession.completePayment(appleSession.STATUS_FAILURE);
                                navigate("/checkout/failure");
                            }
                        }  
                    }
                } else {
                    appleSession.completePayment(appleSession.STATUS_FAILURE);
                }
            } catch (error) {
                console.log(error, "errorerror")
                appleSession.completePayment(appleSession.STATUS_FAILURE);
                dispatch(services.notifyError({ message: error.name === 'TypeError' ? t('No internet connection') : error.message }));
            }
        };
    };

    const startApplePaySession = async () => {
        try {
            const applePayRequest = await getAppleRequest(t, sku, checkout, applePayConfig);
            // eslint-disable-next-line
            const applePaySession = new ApplePaySession(6, applePayRequest);
            handleApplePayEvents(applePaySession);
            applePaySession.begin();
        } catch (error) {
            dispatch(services.notifyError({ message: error.name === 'TypeError' ? t('No internet connection') : error.message }));
        }
    };

    return (
        <>
            {(isApplePayAvailable && isAppleEnable) ? (
                <>
                    <button onClick={() => {
                        startApplePaySession()
                        closeCartPopup && closeCartPopup()
                    }}
                        disabled={!(total > 0)}
                        className="pd__buy__button pd__apple__buy__btn">
                        <BsApple />
                        Pay
                    </button>
                </>
            ) : null}
            <input
                id="device_fingerprint"
                name="device_fingerprint"
                autoComplete="device_fingerprint"
                inputMode="hidden"
                type="hidden"
            />
        </>
    );
};

export default ApplePay;