import React from 'react'
import { useTranslation } from 'react-i18next'

function Find_Stores() {
  const { t } = useTranslation()
  return (
    <div>{t("Find_Stores")}</div>
  )
}

export default Find_Stores