import apiHelper from '../../Components/helpers/utils/apiHelper'
import * as actionType from '../../redux/actionType';
import * as services from '../services';

export const customerNewsletterSubscriptions = (params) => {
  return async dispatch => {
    try {
      let response = await apiHelper(`/V1/customer/newsletter-subscriptions`, 'post', params, {});
      if (response.data.register === true) {
        dispatch(services.notifySuccess({ message: "Thank you for your subscription" }))
        dispatch(customerNewsletterSubscriptionsSuccess(true))
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(services.notifyError({ message: error.response.data.message }))
      }
    }
  }
};

// get addresses
export const customerNewsletterSubscriptionsSuccess = (data) => {
  return {
    type: actionType.CUSTOMER_NEWS_LETTER_SUB_MSG,
    payload: data
  }
}
