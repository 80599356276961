import React, { useEffect, useState } from "react";
import "./../SCSS/_breadcrumbs.scss";
import { Link } from "react-router-dom";
import BreadCrumbs from "../Components/BreadCrumbs";
import { BiWallet } from "react-icons/bi";
import Heading4 from "./Font/Heading4";
import Heading3 from "./Font/Heading3";
import CreditCash from "./../assets/Icon/credit-cash.svg";
import CreditOrder from "./../assets/Icon/credit-order.svg";
import CreditUpdate from "./../assets/Icon/credit-update.svg";
import { useTranslation } from "react-i18next";
import './../SCSS/_userCredit.scss';
import Heading7 from "./Font/Heading7";
import * as services from "../services/services";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Price from "./Font/Price";

function UserCredits() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { storeCreditsHistoryList, storeCredits } = useSelector((state) => state.customerReducer);

    const [creditsHistoryList, setCreditsHistoryList] = useState([]);
    const [storeCreditsDetails, setStoreCreditsDetails] = useState("");

    useEffect(() => {
        dispatch(services.getStoreCreditsHistory());
        dispatch(services.getStoreCredits());
    }, []);

    useEffect(() => {
        if (storeCreditsHistoryList) {
            setCreditsHistoryList(storeCreditsHistoryList);
        }
    }, [storeCreditsHistoryList]);

    useEffect(() => {
        if (storeCredits) {
            setStoreCreditsDetails(storeCredits);
        }
    }, [storeCredits]);

    return (
        <>
            <BreadCrumbs back="/user" firstBread="/user" firstBreadTitle={t("my_account")} secondBread="/user/credit" secondBreadTitle={t("My_Wallet")} highlight="secondBreadTitle" />
            <div className="container-fluid user__credit__page__container">
                <div className='user__credit__main__block'>
                    <div className='user__credit__header'>
                        <Heading3 text={t('My_Wallet_Credits')} />
                    </div>
                    <div className="user__wallet__area">
                        <div className="user__wallet__labelBox">
                            <BiWallet />
                            <p className="user__wallet__labelText">{t('Your_wallet_balance')}:</p>
                        </div>
                        <div className="user__wallet__balanceAmount">
                            <Price
                                price={storeCreditsDetails?.amount}
                                size="heading4"
                                span={true}
                                currency={storeCreditsDetails?.currency === undefined ? 'SAR' : storeCreditsDetails?.currency}
                            />
                            {/* <Heading4 text={`${storeCreditsDetails?.amount} ${storeCreditsDetails?.currency}`} color='#ffffff' /> */}
                        </div>
                    </div>

                    <div className="user__credit__listbox">
                        {creditsHistoryList && creditsHistoryList.map((val, i) => {
                            let dateOne = moment(val.created_time).format('ddd, MMMM DD');
                            let timeOne = moment(val.created_time).format('h:mm A');
                            let finalDate = `${dateOne} at ${timeOne}`
                            let imageName = CreditOrder;
                            if (val.type === "update") {
                                imageName = CreditUpdate;
                            } else if (val.type === "cashback") {
                                imageName = CreditCash;
                            } else if (val.type === "order") {
                                imageName = CreditOrder;
                            }
                            return (
                                <div className="user__credit__itembox">
                                    <p className="user__credit__timeArea">{finalDate}</p>
                                    <div className="user__credit__details">
                                        <div className="user__credit__info__itembox">
                                            <div className="credit_iconBox credit_cashtype ">
                                                <img src={imageName} alt="" />
                                            </div>
                                        </div>
                                        {val.type === "update" ? (
                                            <div className="user__credit__info__itembox orderNo">
                                                <label>{t('Comment')}</label>
                                                <Link to="#" className="credit__orderNo__btn" >{val?.comment_content}</Link>
                                            </div>
                                        ) : (
                                            <div className="user__credit__info__itembox orderNo">
                                                <label>{t('Order_Number')}</label>
                                                <Link to={`/user/orders/${val?.order_id}`} className="credit__orderNo__btn" >#{val?.order_id}</Link>
                                            </div>
                                        )

                                        }
                                        <div className="user__credit__info__itembox orderType">
                                            <label>{t('Type')}</label>
                                            <Heading7 text={val.type} color="#1b860a" />
                                        </div>
                                        <div className="user__credit__info__itembox orderChange">
                                            <label>{t('Balance_change')}</label>
                                            <Price
                                                price={val.change_amount}
                                                size="heading7"
                                                span={true}
                                                currency={val.currency}
                                                color={val.change_amount > 0 ? "#1b860a" : "#ff0000"}
                                            />
                                        </div>
                                        <div className="user__credit__info__itembox orderTotal">
                                            <label>{t('Total')}</label>
                                            <Price
                                                price={val.balance_amount}
                                                size="heading7"
                                                span={true}
                                                currency={val.currency}
                                                color={val.balance_amount > 0 ? "#1b860a" : "#ff0000"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}
export default UserCredits;
