import React from "react";
import Heading3 from "../Font/Heading4";
import Heading6 from "../Font/Heading6";
import Heading7 from "../Font/Heading7";
import Price from "../Font/Price";
import Text3 from "../Font/Text3";
import rating_star from "./../../assets/Icon/rating_star.svg";
import product_01 from "./../../assets/Product/product_01.png";
import "./../../SCSS/MostSharedComponents/_myOrderProductNew.scss";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { replaceImageHelper } from "../helpers/utils/replaceImageHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
import { MobileBlockImageResize, MobileImageResize } from "../helpers/utils/imageResize";
import useWindowDimensions from "../helpers/utils/useWindowDimensions";
import LazyImageForProduct from "./LazyImageForProduct";

function MyOrderProduct(props) {
  const { product, pageType } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const redirectOrderDetailPage = (orderId) => {
    navigate(`/user/orders/${orderId}`);
  }

  return (
    <div className="mo__product__block__new">
      <div className="row ">
        <div className="col-12">
          <div className="row mo__order__details__row ">
            <div className="col-lg-9">
              {pageType === "order-details" &&
                <ul className="align-align-items-lg-start list-unstyled order__detail__list d-flex">
                  <li>
                    <Heading7 text={`${t("ORDER_#")}:`} color="#727272" span={true} />
                    <Text3 text={product?.increment_id} span={true} />
                  </li>
                  <li>
                    <Heading7 text={`${t("Order_Placed")}:`} color="#727272" span={true} />
                    <Text3 text={moment(product?.created_at).format('DD MMMM YYYY')} span={true} />
                  </li>
                  <li>
                    <Heading7 text={`${t("Total_Amount")}:`} color="#727272" span={true} />
                    <Price price={product?.grand_total} currency={product?.order_currency_code} span={true} size="text3" />
                  </li>
                  <li>
                    <Heading7 text={`${t("Order_Status")}:`} color="#727272" span={true} />
                    <Text3 text={`${t(`order.status.${product.status}`)}`} span={true} />
                  </li>
                  <li>
                    <Heading7 text={`${t("Payment_Method")}:`} color="#727272" span={true} />
                    <Text3 text={t(product?.payment?.additional_information?.message)} span={true} />
                  </li>
                </ul>
              }
              {pageType === "order-list" &&
                <ul className="align-align-items-lg-start list-unstyled order__detail__list d-flex">
                  <li>
                    <Text3 text={`#${product?.increment_id}`} span={true} />
                  </li>
                  <li>
                    <Text3 text={moment(product?.created_at).format('DD MMMM YYYY')} span={true} />
                  </li>
                  <li>
                    <Price price={product?.grand_total} currency={product?.order_currency_code} span={true} size="text3" />
                  </li>
                  <li>
                    <Text3 text={`${t(`order.status.${product.status}`)}`} span={true} />
                  </li>
                  <li>
                    <Text3 text={product?.payment?.additional_information?.message} span={true} />
                  </li>
                </ul>
              }

            </div>
            {pageType === "order-list" &&
              <div className="col-lg-3 mo__product__right__block">
                <button onClick={() => redirectOrderDetailPage(product.entity_id)} className="mo__order__detail__button">{t("ORDER_DETAIL")}</button>
                {/* <div className="write__reviewBox">
                  <img src={rating_star} alt="" />
                  <Link className="write_Review__link" to="#">
                    {t("Write_review_of_this_product")}
                  </Link>
                </div> */}
              </div>
            }
          </div>
        </div>
        {pageType === "order-details" &&
          <div className="col-12">
            <div className="row ">
              {product.items && product.items.map((val, i) => {
                let checkBorderBottom = product.items.length !== (i + 1) ? 'border-bottom' : '';
                let digital_codes = val.extension_attributes && val.extension_attributes.digital_codes && val.extension_attributes.digital_codes[0].code
                return (
                  <React.Fragment key={i}>
                    <div className={`col-12 mb-3 pb-3 ${checkBorderBottom}`}>
                      <div className="row">
                        <div className="col-12 col-md-2 mo__product__left__block">
                          <div className="mo__product__image__block">
                            <LazyImageForProduct
                              product={val?.product}
                              srcSetReSize={width <= 992 ? MobileBlockImageResize : MobileImageResize}
                              alt=""
                              className="mo__product__image"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-10 mo__product__middle__block">
                          <Heading6 text={titleChange(val.name)} marginBottom={10} />
                          <ul className="align-align-items-lg-start list-unstyled order__detail__list d-flex">
                            <li>
                              <Heading7 text={`${t('SKU')} :`} color="#727272" span={true} />
                              <Text3 text={val.product.sku} span={true} />
                            </li>
                            {/* </ul>
                          <ul className="align-align-items-lg-start list-unstyled order__detail__list d-flex"> */}
                            <li>
                              <Heading7 text={`${t("Price")} : `} color="#727272" span={true} />
                              <Price price={val.base_price} currency={product.order_currency_code} span={true} size="text3" />
                            </li>
                            {/* </ul>
                          <ul className="align-align-items-lg-start list-unstyled order__detail__list d-flex"> */}
                            <li>
                              <Heading7 text={`${t("Quantity")} : `} color="#727272" span={true} />
                              <Text3 text={val.qty_ordered} span={true} />
                            </li>
                            {digital_codes !== "" && digital_codes !== undefined &&
                              <li>
                                <Heading7 text={`${t('Product_Key')} :`} color="#727272" span={true} />
                                <Text3 text={digital_codes} span={true} />
                              </li>
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })}

              {/* <div className="col-12 mb-3 pb-3">
              <div className="row">
                <div className="col-12 col-sm-2 mo__product__left__block">
                  <div className="mo__product__image__block">
                    <img src={product_01} alt="" className="mo__product__image" />
                  </div>
                </div>
                <div className="col-12 col-sm-10 mo__product__middle__block">
                  <Heading3 text={"Z8H | Full Array LED | 8K | High Dynamic Range (HDR) | Smart TV (Android TV)"} marginBottom={10} />
                  <ul className="align-align-items-lg-start list-unstyled order__detail__list d-flex">
                    <li>
                      <Heading7 text="SKU :" color="#727272" span={true} />
                      <Text3 text={"KD-85Z8H IN5"} span={true} />
                    </li>
                  </ul>

                </div>

              </div>
            </div> */}
            </div>
          </div>
        }
      </div>
    </div>

  );
}

export default MyOrderProduct;
