const MyOrderData = {
  items: [
    {
      base_currency_code: "SAR",
      base_discount_amount: 0,
      base_grand_total: 2499,
      base_discount_tax_compensation_amount: 0,
      base_shipping_amount: 0,
      base_shipping_discount_amount: 0,
      base_shipping_discount_tax_compensation_amnt: 0,
      base_shipping_incl_tax: 0,
      base_shipping_tax_amount: 0,
      base_subtotal: 2173.04,
      base_subtotal_incl_tax: 2499,
      base_tax_amount: 325.96,
      base_total_due: 2499,
      base_to_global_rate: 1,
      base_to_order_rate: 1,
      billing_address_id: 192166,
      created_at: "2022-10-13T07:49:20Z",
      customer_email: "akadivar@sigmasolve.net",
      customer_firstname: "akadivar@sigmasolve.net",
      customer_group_id: 1,
      customer_id: 232638,
      customer_is_guest: 0,
      customer_lastname: "akadivar@sigmasolve.net",
      customer_note: "https://beta.sonyworld.com.sa",
      customer_note_notify: 1,
      discount_amount: 0,
      entity_id: 148537,
      global_currency_code: "SAR",
      grand_total: 2499,
      discount_tax_compensation_amount: 0,
      increment_id: "swb000000000439",
      is_virtual: 0,
      order_currency_code: "SAR",
      protect_code: "66fc2414ba6f6816c321cbc4b6f6ea22",
      quote_id: 2188220,
      remote_ip: "18.158.205.245",
      shipping_amount: 0,
      shipping_description: "Standard Delivery - Home Delivery",
      shipping_discount_amount: 0,
      shipping_discount_tax_compensation_amount: 0,
      shipping_incl_tax: 0,
      shipping_tax_amount: 0,
      state: "new",
      status: "payment_auth",
      store_currency_code: "SAR",
      store_id: 27,
      store_name: "Sony\nSony\nEnglish",
      store_to_base_rate: 0,
      store_to_order_rate: 0,
      subtotal: 2173.04,
      subtotal_incl_tax: 2499,
      tax_amount: 325.96,
      total_due: 2499,
      total_item_count: 1,
      total_qty_ordered: 1,
      updated_at: "2022-10-13T07:49:25Z",
      weight: 12.4,
      x_forwarded_for: "162.158.91.236",
      items: [
        {
          amount_refunded: 0,
          base_amount_refunded: 0,
          base_discount_amount: 0,
          base_discount_invoiced: 0,
          base_discount_tax_compensation_amount: 0,
          base_original_price: 2173.04,
          base_price: 2173.04,
          base_price_incl_tax: 2499,
          base_row_invoiced: 0,
          base_row_total: 2173.04,
          base_row_total_incl_tax: 2499,
          base_tax_amount: 325.96,
          base_tax_invoiced: 0,
          created_at: "2022-10-13T07:49:20Z",
          discount_amount: 0,
          discount_invoiced: 0,
          discount_percent: 0,
          free_shipping: 0,
          discount_tax_compensation_amount: 0,
          is_qty_decimal: 0,
          is_virtual: 0,
          item_id: 169604,
          name: "SONY X80H Smart TV 49” 4K Ultra HD High Dynamic Range (HDR)(Android TV)",
          no_discount: 0,
          order_id: 148537,
          original_price: 2173.04,
          price: 2173.04,
          price_incl_tax: 2499,
          product_id: 12166,
          product_type: "simple",
          qty_canceled: 0,
          qty_invoiced: 0,
          qty_ordered: 1,
          qty_refunded: 0,
          qty_shipped: 0,
          quote_item_id: 901299,
          row_invoiced: 0,
          row_total: 2173.04,
          row_total_incl_tax: 2499,
          row_weight: 12.4,
          sku: "KD-49X7500H",
          store_id: 27,
          tax_amount: 325.96,
          tax_invoiced: 0,
          tax_percent: 15,
          updated_at: "2022-10-13T07:49:20Z",
          weight: 12.4,
          product: {
            id: "12166",
            sku: "KD-49X7500H",
            labels: {
              plp: [],
              pdp: [],
            },
            name: "SONY X80H Smart TV 49” 4K Ultra HD High Dynamic Range (HDR)(Android TV)",
            categories: [
              {
                category_id: 2,
                is_virtual: "false",
                name: "All Categories",
                parent_id: "1",
                is_active: "1",
                include_in_menu: "1",
              },
              {
                category_id: 270,
                is_parent: true,
                is_virtual: "false",
                position: 13,
                name: "Televisions",
                parent_id: "2",
                is_active: "1",
                include_in_menu: "1",
              },
              {
                category_id: 278,
                is_parent: true,
                is_virtual: "false",
                name: "Smart 4K TV's",
                parent_id: "270",
                is_active: "1",
                include_in_menu: "1",
              },
              {
                category_id: 359,
                is_virtual: "false",
                name: "B2B",
                parent_id: "1",
                is_active: "1",
                include_in_menu: "1",
              },
              {
                category_id: 366,
                is_virtual: "false",
                name: "SONY TV",
                parent_id: "359",
                is_active: "1",
                include_in_menu: "1",
              },
            ],
            price: 2498.996001,
            price_rounded: 2499,
            discounted_price: null,
            discounted_price_rounded: null,
            discounted_price_from: "",
            discounted_price_to: "",
            is_count_down: true,
            baseImage:
              "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-49x7500h.png",
            description: "",
            system_requirements: "",
            delivery_details: "",
            media: {
              image: {
                packshot: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_sil_sil_chevron_st-mid_1.png",
                  url: null,
                  position: "0",
                  width: "1200",
                  height: "800",
                  type: "image",
                },
                featured: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_sil_sil_chevron_st-mid_1.png",
                  url: null,
                  position: "0",
                  width: "1200",
                  height: "800",
                  type: "image",
                },
                smallsquare: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_sil_sil_chevron_st-mid_1.png",
                  url: null,
                  position: "0",
                  width: "1200",
                  height: "800",
                  type: "image",
                },
                plpPackshot: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_sil_sil_chevron_st-mid_1.png",
                  url: null,
                  position: "0",
                  width: "1200",
                  height: "800",
                  type: "image",
                },
                screenshots: [
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_sil_sil_chevron_st-mid_1.png",
                    url: null,
                    position: "0",
                    width: "1200",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-49x7500h.png",
                    url: null,
                    position: "1",
                    width: "1080",
                    height: "1080",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_sil_sil_chevron_front-mid_1.png",
                    url: null,
                    position: "2",
                    width: "1200",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/i/n/in-situation_65_x75h_xh75_blk_sil_sil_chevron-mid_1.png",
                    url: null,
                    position: "3",
                    width: "1200",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_blk_chevron_bz-mid_1.png",
                    url: null,
                    position: "4",
                    width: "1200",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_sil_sil_chevron_ccw-mid_1.png",
                    url: null,
                    position: "5",
                    width: "1200",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_blk_chevron_cw-mid_1.png",
                    url: null,
                    position: "6",
                    width: "1200",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-49x7500h_2_.png",
                    url: null,
                    position: "7",
                    width: "1080",
                    height: "1080",
                    type: "image",
                  },
                ],
              },
              "external-video": [],
            },
            stock: 989,
            orderType: "in-stock",
            currency: "SAR",
            options: [
              {
                code: "sku",
                visibility: true,
                attribute: {
                  label: "SKU",
                  id: -1,
                },
                selected_option: {
                  label: "KD-49X7500H",
                  labelArr: ["KD-49X7500H"],
                  id: -1,
                },
              },
              {
                filterable: true,
                code: "url_key",
                visibility: false,
                attribute: {
                  label: "URL Key",
                  id: 122,
                },
                selected_option: {
                  label:
                    "sony-x80h-smart-tv-49-4k-ultra-hd-high-dynamic-range-hdr-android-tv-kd-49x7500h",
                  labelArr: [
                    "sony-x80h-smart-tv-49-4k-ultra-hd-high-dynamic-range-hdr-android-tv-kd-49x7500h",
                  ],
                  id: "sony-x80h-smart-tv-49-4k-ultra-hd-high-dynamic-range-hdr-android-tv-kd-49x7500h",
                },
              },
              {
                filterable: false,
                code: "ean",
                visibility: false,
                attribute: {
                  label: "EAN",
                  id: 199,
                },
                selected_option: {
                  label: "4548736116030",
                  labelArr: ["4548736116030"],
                  id: "4548736116030",
                },
              },
              {
                filterable: false,
                code: "mpn",
                visibility: false,
                attribute: {
                  label: "MPN",
                  id: 200,
                },
                selected_option: {
                  label: "KD-49X7500H_AF1",
                  labelArr: ["KD-49X7500H_AF1"],
                  id: "KD-49X7500H_AF1",
                },
              },
              {
                filterable: true,
                code: "google_product_category",
                visibility: false,
                attribute: {
                  label: "Google Product Category",
                  id: 322,
                },
                selected_option: {
                  label: "222",
                  labelArr: ["222"],
                  id: "222",
                },
              },
              {
                filterable: false,
                code: "fb_product_brand",
                visibility: false,
                attribute: {
                  label: "Brand",
                  id: 323,
                },
                selected_option: {
                  label: "Sony",
                  labelArr: ["Sony"],
                  id: "Sony",
                },
              },
              {
                filterable: true,
                code: "product_event_reporting",
                visibility: false,
                attribute: {
                  label: "Product Event Reporting",
                  id: 335,
                },
                selected_option: {
                  label:
                    "SONY X80H Smart TV 49” 4K Ultra HD High Dynamic Range (HDR)(Android TV)",
                  labelArr: [
                    "SONY X80H Smart TV 49” 4K Ultra HD High Dynamic Range (HDR)(Android TV)",
                  ],
                  id: "SONY X80H Smart TV 49” 4K Ultra HD High Dynamic Range (HDR)(Android TV)",
                },
              },
              {
                filterable: false,
                code: "express_shipping_eligible",
                visibility: true,
                attribute: {
                  label: "Express Delivery Eligible",
                  id: 410,
                },
                selected_option: {
                  label: "Express Delivery Eligible",
                  labelArr: ["Express Delivery Eligible"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "color",
                visibility: false,
                attribute: {
                  label: "Color",
                  id: 90,
                },
                selected_option: {
                  label: "Black",
                  labelArr: ["Black"],
                  id: "49",
                },
              },
              {
                filterable: false,
                code: "lighting_shipping_eligible",
                visibility: true,
                attribute: {
                  label: "Lighting Delivery Eligible",
                  id: 411,
                },
                selected_option: {
                  label: "Lighting Delivery Eligible",
                  labelArr: ["Lighting Delivery Eligible"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "tax_class_id",
                visibility: false,
                attribute: {
                  label: "Tax Class",
                  id: 129,
                },
                selected_option: {
                  label: "SH",
                  labelArr: ["SH"],
                  id: "19",
                },
              },
              {
                filterable: false,
                code: "meza_eligible",
                visibility: false,
                attribute: {
                  label: "Meza Eligible",
                  id: 328,
                },
                selected_option: {
                  label: "Meza Eligible",
                  labelArr: ["Meza Eligible"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "is_count_down",
                visibility: true,
                attribute: {
                  label: "Is Count Down",
                  id: 340,
                },
                selected_option: {
                  label: "Is Count Down",
                  labelArr: ["Is Count Down"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "estimated_time",
                visibility: false,
                attribute: {
                  label: "Estimated Days",
                  id: 341,
                },
                selected_option: {
                  label: "4",
                  labelArr: [4],
                  id: "4",
                },
              },
              {
                filterable: false,
                code: "qitaf_eligible",
                visibility: false,
                attribute: {
                  label: "Qitaf Eligible",
                  id: 388,
                },
                selected_option: {
                  label: "Qitaf Eligible",
                  labelArr: ["Qitaf Eligible"],
                  id: "true",
                },
              },
              {
                filterable: true,
                code: "inches",
                visibility: false,
                attribute: {
                  label: "Size By Inches",
                  id: 396,
                },
                selected_option: {
                  label: "55",
                  labelArr: ["55"],
                  id: "831",
                },
              },
              {
                filterable: false,
                code: "eligible_for_tamara",
                visibility: true,
                attribute: {
                  label: "Eligible For Tamara Installment",
                  id: 397,
                },
                selected_option: {
                  label: "Eligible For Tamara Installment",
                  labelArr: ["Eligible For Tamara Installment"],
                  id: "true",
                },
              },
            ],
            item_type: "simple",
            attribute_set_id: "21",
            meza: {
              points: 2174,
              money_equivalent: 66,
            },
            qitaf: {
              points: 125,
              money_equivalent: 37,
            },
            custom_related_products: [
              "DAV-DZ650",
              "KDL-43W660F",
              "KD-49X8000H",
              "KD-43X7500H",
            ],
            enable_shipping: true,
            cross_sell_products: [],
            bundle_products: [[]],
            inventory_qty: [],
            hex_code: ["#000000"],
            eligible_for_tabby: false,
            meta_keywords:
              "tv,شاشات, تلفاز, شاشة, تلفزيون ,تلفزيونات ,KD-49X7500H , بوصة , شاشة k,شاشات سوني , شاشات بلايستيشن, شاشات ps5 , شاشات,شاشه , تي في , شاشات افلام , تلفزيونات افلام , شاشات كبيرة , شاشة سمارت , سمارت تي في , عروض الشاشات , شاشة بلازما, شاشة سبو,KD-49X7500H",
            url_key: [
              "sony-x80h-smart-tv-49-4k-ultra-hd-high-dynamic-range-hdr-android-tv-kd-49x7500h",
            ],
            bundle_discount_products: [],
            additional_estimated_day: 4,
          },
        },
      ],
      billing_address: {
        address_type: "billing",
        city: "Abha- أبها",
        country_id: "SA",
        email: "akadivar@sigmasolve.net",
        entity_id: 192166,
        firstname: "ak",
        lastname: "ak",
        parent_id: 148537,
        postcode: "Al-Dabab - الضباب",
        street: ["Rj Raj"],
        telephone: "966588888888",
      },
      payment: {
        account_status: null,
        additional_information: {
          raw: ["PAY WITH CARD"],
          message: "PAY WITH CARD",
        },
        amount_ordered: 2499,
        base_amount_ordered: 2499,
        base_shipping_amount: 0,
        cc_exp_year: "0",
        cc_last4: null,
        cc_ss_start_month: "0",
        cc_ss_start_year: "0",
        entity_id: 147990,
        method: "payfort_fort_cc",
        parent_id: 148537,
        shipping_amount: 0,
      },
      status_histories: [
        {
          comment:
            "PayfortFort :: redirecting to payment page with Method: payfort_fort_cc",
          created_at: "2022-10-13 07:49:25",
          entity_id: 4099462,
          entity_name: "order",
          is_customer_notified: null,
          is_visible_on_front: 0,
          parent_id: 148537,
          status: "payment_auth",
        },
      ],
      extension_attributes: {
        shipping_assignments: [
          {
            shipping: {
              address: {
                address_type: "shipping",
                city: "Abha- أبها",
                country_id: "SA",
                email: "akadivar@sigmasolve.net",
                entity_id: 192165,
                firstname: "ak",
                lastname: "ak",
                parent_id: 148537,
                postcode: "Al-Dabab - الضباب",
                street: ["Rj Raj"],
                telephone: "966588888888",
              },
              method: "flatrate_flatrate",
              total: {
                base_shipping_amount: 0,
                base_shipping_discount_amount: 0,
                base_shipping_discount_tax_compensation_amnt: 0,
                base_shipping_incl_tax: 0,
                base_shipping_tax_amount: 0,
                shipping_amount: 0,
                shipping_discount_amount: 0,
                shipping_discount_tax_compensation_amount: 0,
                shipping_incl_tax: 0,
                shipping_tax_amount: 0,
              },
            },
            items: [
              {
                amount_refunded: 0,
                base_amount_refunded: 0,
                base_discount_amount: 0,
                base_discount_invoiced: 0,
                base_discount_tax_compensation_amount: 0,
                base_original_price: 2173.04,
                base_price: 2173.04,
                base_price_incl_tax: 2499,
                base_row_invoiced: 0,
                base_row_total: 2173.04,
                base_row_total_incl_tax: 2499,
                base_tax_amount: 325.96,
                base_tax_invoiced: 0,
                created_at: "2022-10-13T07:49:20Z",
                discount_amount: 0,
                discount_invoiced: 0,
                discount_percent: 0,
                free_shipping: 0,
                discount_tax_compensation_amount: 0,
                is_qty_decimal: 0,
                is_virtual: 0,
                item_id: 169604,
                name: "SONY X80H Smart TV 49” 4K Ultra HD High Dynamic Range (HDR)(Android TV)",
                no_discount: 0,
                order_id: 148537,
                original_price: 2173.04,
                price: 2173.04,
                price_incl_tax: 2499,
                product_id: 12166,
                product_type: "simple",
                qty_canceled: 0,
                qty_invoiced: 0,
                qty_ordered: 1,
                qty_refunded: 0,
                qty_shipped: 0,
                quote_item_id: 901299,
                row_invoiced: 0,
                row_total: 2173.04,
                row_total_incl_tax: 2499,
                row_weight: 12.4,
                sku: "KD-49X7500H",
                store_id: 27,
                tax_amount: 325.96,
                tax_invoiced: 0,
                tax_percent: 15,
                updated_at: "2022-10-13T07:49:20Z",
                weight: 12.4,
              },
            ],
          },
        ],
        cash_on_delivery_fee: 0,
        base_cash_on_delivery_fee: 0,
        mokafa_points: {
          money_equivalent: null,
        },
        ygg_points: {
          money_equivalent: null,
        },
        meza_points: {
          points: null,
          money_equivalent: 0,
          currency: "SAR",
        },
        qitaf_points: {
          qitaf: null,
        },
      },
    },
    {
      base_currency_code: "SAR",
      base_discount_amount: 0,
      base_discount_canceled: 0,
      base_grand_total: 2499,
      base_discount_tax_compensation_amount: 0,
      base_shipping_amount: 0,
      base_shipping_canceled: 0,
      base_shipping_discount_amount: 0,
      base_shipping_discount_tax_compensation_amnt: 0,
      base_shipping_incl_tax: 0,
      base_shipping_tax_amount: 0,
      base_subtotal: 2173.04,
      base_subtotal_canceled: 2173.04,
      base_subtotal_incl_tax: 2499,
      base_tax_amount: 325.96,
      base_tax_canceled: 325.96,
      base_total_canceled: 2499,
      base_total_due: 2499,
      base_to_global_rate: 1,
      base_to_order_rate: 1,
      billing_address_id: 192164,
      created_at: "2022-10-13T06:17:36Z",
      customer_email: "akadivar@sigmasolve.net",
      customer_firstname: "akadivar@sigmasolve.net",
      customer_group_id: 1,
      customer_id: 232638,
      customer_is_guest: 0,
      customer_lastname: "akadivar@sigmasolve.net",
      customer_note: "https://beta.sonyworld.com.sa",
      customer_note_notify: 1,
      discount_amount: 0,
      discount_canceled: 0,
      entity_id: 148536,
      global_currency_code: "SAR",
      grand_total: 2499,
      discount_tax_compensation_amount: 0,
      increment_id: "swb000000000438",
      is_virtual: 0,
      order_currency_code: "SAR",
      protect_code: "3e75b9316d7f6c28073092225606bf17",
      quote_id: 2188219,
      remote_ip: "18.158.205.245",
      shipping_amount: 0,
      shipping_canceled: 0,
      shipping_description: "Standard Delivery - Home Delivery",
      shipping_discount_amount: 0,
      shipping_discount_tax_compensation_amount: 0,
      shipping_incl_tax: 0,
      shipping_tax_amount: 0,
      state: "canceled",
      status: "canceled_cc_attempt",
      store_currency_code: "SAR",
      store_id: 27,
      store_name: "Sony\nSony\nEnglish",
      store_to_base_rate: 0,
      store_to_order_rate: 0,
      subtotal: 2173.04,
      subtotal_canceled: 2173.04,
      subtotal_incl_tax: 2499,
      tax_amount: 325.96,
      tax_canceled: 325.96,
      total_canceled: 2499,
      total_due: 2499,
      total_item_count: 1,
      total_qty_ordered: 1,
      updated_at: "2022-10-13T06:50:08Z",
      weight: 12.4,
      x_forwarded_for: "172.70.246.145",
      items: [
        {
          amount_refunded: 0,
          base_amount_refunded: 0,
          base_discount_amount: 0,
          base_discount_invoiced: 0,
          base_discount_tax_compensation_amount: 0,
          base_original_price: 2173.04,
          base_price: 2173.04,
          base_price_incl_tax: 2499,
          base_row_invoiced: 0,
          base_row_total: 2173.04,
          base_row_total_incl_tax: 2499,
          base_tax_amount: 325.96,
          base_tax_invoiced: 0,
          created_at: "2022-10-13T06:17:36Z",
          discount_amount: 0,
          discount_invoiced: 0,
          discount_percent: 0,
          free_shipping: 0,
          discount_tax_compensation_amount: 0,
          discount_tax_compensation_canceled: 0,
          is_qty_decimal: 0,
          is_virtual: 0,
          item_id: 169603,
          name: "SONY X80H Smart TV 49” 4K Ultra HD High Dynamic Range (HDR)(Android TV)",
          no_discount: 0,
          order_id: 148536,
          original_price: 2173.04,
          price: 2173.04,
          price_incl_tax: 2499,
          product_id: 12166,
          product_type: "simple",
          qty_canceled: 1,
          qty_invoiced: 0,
          qty_ordered: 1,
          qty_refunded: 0,
          qty_shipped: 0,
          quote_item_id: 901285,
          row_invoiced: 0,
          row_total: 2173.04,
          row_total_incl_tax: 2499,
          row_weight: 12.4,
          sku: "KD-49X7500H",
          store_id: 27,
          tax_amount: 325.96,
          tax_canceled: 325.96,
          tax_invoiced: 0,
          tax_percent: 15,
          updated_at: "2022-10-13T06:40:20Z",
          weight: 12.4,
          product: {
            id: "12166",
            sku: "KD-49X7500H",
            labels: {
              plp: [],
              pdp: [],
            },
            name: "SONY X80H Smart TV 49” 4K Ultra HD High Dynamic Range (HDR)(Android TV)",
            categories: [
              {
                category_id: 2,
                is_virtual: "false",
                name: "All Categories",
                parent_id: "1",
                is_active: "1",
                include_in_menu: "1",
              },
              {
                category_id: 270,
                is_parent: true,
                is_virtual: "false",
                position: 13,
                name: "Televisions",
                parent_id: "2",
                is_active: "1",
                include_in_menu: "1",
              },
              {
                category_id: 278,
                is_parent: true,
                is_virtual: "false",
                name: "Smart 4K TV's",
                parent_id: "270",
                is_active: "1",
                include_in_menu: "1",
              },
              {
                category_id: 359,
                is_virtual: "false",
                name: "B2B",
                parent_id: "1",
                is_active: "1",
                include_in_menu: "1",
              },
              {
                category_id: 366,
                is_virtual: "false",
                name: "SONY TV",
                parent_id: "359",
                is_active: "1",
                include_in_menu: "1",
              },
            ],
            price: 2498.996001,
            price_rounded: 2499,
            discounted_price: null,
            discounted_price_rounded: null,
            discounted_price_from: "",
            discounted_price_to: "",
            is_count_down: true,
            baseImage:
              "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-49x7500h.png",
            description: "",
            system_requirements: "",
            delivery_details: "",
            media: {
              image: {
                packshot: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_sil_sil_chevron_st-mid_1.png",
                  url: null,
                  position: "0",
                  width: "1200",
                  height: "800",
                  type: "image",
                },
                featured: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_sil_sil_chevron_st-mid_1.png",
                  url: null,
                  position: "0",
                  width: "1200",
                  height: "800",
                  type: "image",
                },
                smallsquare: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_sil_sil_chevron_st-mid_1.png",
                  url: null,
                  position: "0",
                  width: "1200",
                  height: "800",
                  type: "image",
                },
                plpPackshot: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_sil_sil_chevron_st-mid_1.png",
                  url: null,
                  position: "0",
                  width: "1200",
                  height: "800",
                  type: "image",
                },
                screenshots: [
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_sil_sil_chevron_st-mid_1.png",
                    url: null,
                    position: "0",
                    width: "1200",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-49x7500h.png",
                    url: null,
                    position: "1",
                    width: "1080",
                    height: "1080",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_sil_sil_chevron_front-mid_1.png",
                    url: null,
                    position: "2",
                    width: "1200",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/i/n/in-situation_65_x75h_xh75_blk_sil_sil_chevron-mid_1.png",
                    url: null,
                    position: "3",
                    width: "1200",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_blk_chevron_bz-mid_1.png",
                    url: null,
                    position: "4",
                    width: "1200",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_sil_sil_chevron_ccw-mid_1.png",
                    url: null,
                    position: "5",
                    width: "1200",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/4/9/49_x75h_xh75_blk_blk_chevron_cw-mid_1.png",
                    url: null,
                    position: "6",
                    width: "1200",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-49x7500h_2_.png",
                    url: null,
                    position: "7",
                    width: "1080",
                    height: "1080",
                    type: "image",
                  },
                ],
              },
              "external-video": [],
            },
            stock: 989,
            orderType: "in-stock",
            currency: "SAR",
            options: [
              {
                code: "sku",
                visibility: true,
                attribute: {
                  label: "SKU",
                  id: -1,
                },
                selected_option: {
                  label: "KD-49X7500H",
                  labelArr: ["KD-49X7500H"],
                  id: -1,
                },
              },
              {
                filterable: true,
                code: "url_key",
                visibility: false,
                attribute: {
                  label: "URL Key",
                  id: 122,
                },
                selected_option: {
                  label:
                    "sony-x80h-smart-tv-49-4k-ultra-hd-high-dynamic-range-hdr-android-tv-kd-49x7500h",
                  labelArr: [
                    "sony-x80h-smart-tv-49-4k-ultra-hd-high-dynamic-range-hdr-android-tv-kd-49x7500h",
                  ],
                  id: "sony-x80h-smart-tv-49-4k-ultra-hd-high-dynamic-range-hdr-android-tv-kd-49x7500h",
                },
              },
              {
                filterable: false,
                code: "ean",
                visibility: false,
                attribute: {
                  label: "EAN",
                  id: 199,
                },
                selected_option: {
                  label: "4548736116030",
                  labelArr: ["4548736116030"],
                  id: "4548736116030",
                },
              },
              {
                filterable: false,
                code: "mpn",
                visibility: false,
                attribute: {
                  label: "MPN",
                  id: 200,
                },
                selected_option: {
                  label: "KD-49X7500H_AF1",
                  labelArr: ["KD-49X7500H_AF1"],
                  id: "KD-49X7500H_AF1",
                },
              },
              {
                filterable: true,
                code: "google_product_category",
                visibility: false,
                attribute: {
                  label: "Google Product Category",
                  id: 322,
                },
                selected_option: {
                  label: "222",
                  labelArr: ["222"],
                  id: "222",
                },
              },
              {
                filterable: false,
                code: "fb_product_brand",
                visibility: false,
                attribute: {
                  label: "Brand",
                  id: 323,
                },
                selected_option: {
                  label: "Sony",
                  labelArr: ["Sony"],
                  id: "Sony",
                },
              },
              {
                filterable: true,
                code: "product_event_reporting",
                visibility: false,
                attribute: {
                  label: "Product Event Reporting",
                  id: 335,
                },
                selected_option: {
                  label:
                    "SONY X80H Smart TV 49” 4K Ultra HD High Dynamic Range (HDR)(Android TV)",
                  labelArr: [
                    "SONY X80H Smart TV 49” 4K Ultra HD High Dynamic Range (HDR)(Android TV)",
                  ],
                  id: "SONY X80H Smart TV 49” 4K Ultra HD High Dynamic Range (HDR)(Android TV)",
                },
              },
              {
                filterable: false,
                code: "express_shipping_eligible",
                visibility: true,
                attribute: {
                  label: "Express Delivery Eligible",
                  id: 410,
                },
                selected_option: {
                  label: "Express Delivery Eligible",
                  labelArr: ["Express Delivery Eligible"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "color",
                visibility: false,
                attribute: {
                  label: "Color",
                  id: 90,
                },
                selected_option: {
                  label: "Black",
                  labelArr: ["Black"],
                  id: "49",
                },
              },
              {
                filterable: false,
                code: "lighting_shipping_eligible",
                visibility: true,
                attribute: {
                  label: "Lighting Delivery Eligible",
                  id: 411,
                },
                selected_option: {
                  label: "Lighting Delivery Eligible",
                  labelArr: ["Lighting Delivery Eligible"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "tax_class_id",
                visibility: false,
                attribute: {
                  label: "Tax Class",
                  id: 129,
                },
                selected_option: {
                  label: "SH",
                  labelArr: ["SH"],
                  id: "19",
                },
              },
              {
                filterable: false,
                code: "meza_eligible",
                visibility: false,
                attribute: {
                  label: "Meza Eligible",
                  id: 328,
                },
                selected_option: {
                  label: "Meza Eligible",
                  labelArr: ["Meza Eligible"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "is_count_down",
                visibility: true,
                attribute: {
                  label: "Is Count Down",
                  id: 340,
                },
                selected_option: {
                  label: "Is Count Down",
                  labelArr: ["Is Count Down"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "estimated_time",
                visibility: false,
                attribute: {
                  label: "Estimated Days",
                  id: 341,
                },
                selected_option: {
                  label: "4",
                  labelArr: [4],
                  id: "4",
                },
              },
              {
                filterable: false,
                code: "qitaf_eligible",
                visibility: false,
                attribute: {
                  label: "Qitaf Eligible",
                  id: 388,
                },
                selected_option: {
                  label: "Qitaf Eligible",
                  labelArr: ["Qitaf Eligible"],
                  id: "true",
                },
              },
              {
                filterable: true,
                code: "inches",
                visibility: false,
                attribute: {
                  label: "Size By Inches",
                  id: 396,
                },
                selected_option: {
                  label: "55",
                  labelArr: ["55"],
                  id: "831",
                },
              },
              {
                filterable: false,
                code: "eligible_for_tamara",
                visibility: true,
                attribute: {
                  label: "Eligible For Tamara Installment",
                  id: 397,
                },
                selected_option: {
                  label: "Eligible For Tamara Installment",
                  labelArr: ["Eligible For Tamara Installment"],
                  id: "true",
                },
              },
            ],
            item_type: "simple",
            attribute_set_id: "21",
            meza: {
              points: 2174,
              money_equivalent: 66,
            },
            qitaf: {
              points: 125,
              money_equivalent: 37,
            },
            custom_related_products: [
              "DAV-DZ650",
              "KDL-43W660F",
              "KD-49X8000H",
              "KD-43X7500H",
            ],
            enable_shipping: true,
            cross_sell_products: [],
            bundle_products: [[]],
            inventory_qty: [],
            hex_code: ["#000000"],
            eligible_for_tabby: false,
            meta_keywords:
              "tv,شاشات, تلفاز, شاشة, تلفزيون ,تلفزيونات ,KD-49X7500H , بوصة , شاشة k,شاشات سوني , شاشات بلايستيشن, شاشات ps5 , شاشات,شاشه , تي في , شاشات افلام , تلفزيونات افلام , شاشات كبيرة , شاشة سمارت , سمارت تي في , عروض الشاشات , شاشة بلازما, شاشة سبو,KD-49X7500H",
            url_key: [
              "sony-x80h-smart-tv-49-4k-ultra-hd-high-dynamic-range-hdr-android-tv-kd-49x7500h",
            ],
            bundle_discount_products: [],
            additional_estimated_day: 4,
          },
        },
      ],
      billing_address: {
        address_type: "billing",
        city: "Abha- أبها",
        country_id: "SA",
        email: "akadivar@sigmasolve.net",
        entity_id: 192164,
        firstname: "ak",
        lastname: "ak",
        parent_id: 148536,
        postcode: "Al-Dabab - الضباب",
        street: ["Rj Raj"],
        telephone: "966588888888",
      },
      payment: {
        account_status: null,
        additional_information: {
          raw: ["PAY WITH CARD"],
          message: "PAY WITH CARD",
        },
        amount_ordered: 2499,
        base_amount_ordered: 2499,
        base_shipping_amount: 0,
        cc_exp_year: "0",
        cc_last4: null,
        cc_ss_start_month: "0",
        cc_ss_start_year: "0",
        entity_id: 147989,
        method: "payfort_fort_cc",
        parent_id: 148536,
        shipping_amount: 0,
      },
      status_histories: [
        {
          comment:
            "Order was set to canceled_cc_attempt by our automation tool",
          created_at: "2022-10-13 06:40:20",
          entity_id: 4099460,
          entity_name: "order",
          is_customer_notified: null,
          is_visible_on_front: 0,
          parent_id: 148536,
          status: "canceled_cc_attempt",
        },
        {
          comment:
            "PayfortFort :: redirecting to payment page with Method: payfort_fort_cc",
          created_at: "2022-10-13 06:17:41",
          entity_id: 4099459,
          entity_name: "order",
          is_customer_notified: null,
          is_visible_on_front: 0,
          parent_id: 148536,
          status: "payment_auth",
        },
        {
          comment: "order has not been cancel in Fastco as there is no AWB",
          created_at: "0000-00-00 00:00:00",
          entity_id: 4099461,
          is_customer_notified: 0,
          is_visible_on_front: 0,
          parent_id: 148536,
          status: "canceled",
        },
      ],
      extension_attributes: {
        shipping_assignments: [
          {
            shipping: {
              address: {
                address_type: "shipping",
                city: "Abha- أبها",
                country_id: "SA",
                email: "akadivar@sigmasolve.net",
                entity_id: 192163,
                firstname: "ak",
                lastname: "ak",
                parent_id: 148536,
                postcode: "Al-Dabab - الضباب",
                street: ["Rj Raj"],
                telephone: "966588888888",
              },
              method: "flatrate_flatrate",
              total: {
                base_shipping_amount: 0,
                base_shipping_canceled: 0,
                base_shipping_discount_amount: 0,
                base_shipping_discount_tax_compensation_amnt: 0,
                base_shipping_incl_tax: 0,
                base_shipping_tax_amount: 0,
                shipping_amount: 0,
                shipping_canceled: 0,
                shipping_discount_amount: 0,
                shipping_discount_tax_compensation_amount: 0,
                shipping_incl_tax: 0,
                shipping_tax_amount: 0,
              },
            },
            items: [
              {
                amount_refunded: 0,
                base_amount_refunded: 0,
                base_discount_amount: 0,
                base_discount_invoiced: 0,
                base_discount_tax_compensation_amount: 0,
                base_original_price: 2173.04,
                base_price: 2173.04,
                base_price_incl_tax: 2499,
                base_row_invoiced: 0,
                base_row_total: 2173.04,
                base_row_total_incl_tax: 2499,
                base_tax_amount: 325.96,
                base_tax_invoiced: 0,
                created_at: "2022-10-13T06:17:36Z",
                discount_amount: 0,
                discount_invoiced: 0,
                discount_percent: 0,
                free_shipping: 0,
                discount_tax_compensation_amount: 0,
                discount_tax_compensation_canceled: 0,
                is_qty_decimal: 0,
                is_virtual: 0,
                item_id: 169603,
                name: "SONY X80H Smart TV 49” 4K Ultra HD High Dynamic Range (HDR)(Android TV)",
                no_discount: 0,
                order_id: 148536,
                original_price: 2173.04,
                price: 2173.04,
                price_incl_tax: 2499,
                product_id: 12166,
                product_type: "simple",
                qty_canceled: 1,
                qty_invoiced: 0,
                qty_ordered: 1,
                qty_refunded: 0,
                qty_shipped: 0,
                quote_item_id: 901285,
                row_invoiced: 0,
                row_total: 2173.04,
                row_total_incl_tax: 2499,
                row_weight: 12.4,
                sku: "KD-49X7500H",
                store_id: 27,
                tax_amount: 325.96,
                tax_canceled: 325.96,
                tax_invoiced: 0,
                tax_percent: 15,
                updated_at: "2022-10-13T06:40:20Z",
                weight: 12.4,
              },
            ],
          },
        ],
        cash_on_delivery_fee: 0,
        base_cash_on_delivery_fee: 0,
        mokafa_points: {
          money_equivalent: null,
        },
        ygg_points: {
          money_equivalent: null,
        },
        meza_points: {
          points: null,
          money_equivalent: 0,
          currency: "SAR",
        },
        qitaf_points: {
          qitaf: null,
        },
      },
    },
    {
      base_currency_code: "SAR",
      base_discount_amount: 0,
      base_grand_total: 399,
      base_discount_tax_compensation_amount: 0,
      base_shipping_amount: 0,
      base_shipping_discount_amount: 0,
      base_shipping_discount_tax_compensation_amnt: 0,
      base_shipping_incl_tax: 0,
      base_shipping_tax_amount: 0,
      base_subtotal: 346.96,
      base_subtotal_incl_tax: 399,
      base_tax_amount: 52.04,
      base_total_due: 0,
      base_total_paid: 399,
      base_to_global_rate: 1,
      base_to_order_rate: 1,
      billing_address_id: 190543,
      created_at: "2022-09-20T17:16:19Z",
      customer_email: "abadreshiya@sigmasolve.net",
      customer_firstname: "Test",
      customer_group_id: 1,
      customer_id: 232503,
      customer_is_guest: 0,
      customer_lastname: "Test",
      customer_note: "https://beta-web.mestores.com",
      customer_note_notify: 1,
      discount_amount: 0,
      entity_id: 147679,
      global_currency_code: "SAR",
      grand_total: 399,
      discount_tax_compensation_amount: 0,
      increment_id: "b000001822488",
      is_virtual: 0,
      order_currency_code: "SAR",
      protect_code: "7d01c9b7164c60e4408b768fd1e286dc",
      quote_id: 2184654,
      remote_ip: "18.158.205.245",
      shipping_amount: 0,
      shipping_description: "Home Delivery - Home Delivery",
      shipping_discount_amount: 0,
      shipping_discount_tax_compensation_amount: 0,
      shipping_incl_tax: 0,
      shipping_tax_amount: 0,
      state: "new",
      status: "processing",
      store_currency_code: "SAR",
      store_id: 24,
      store_name: "Main Website\nMestores\nEnglish",
      store_to_base_rate: 0,
      store_to_order_rate: 0,
      subtotal: 346.96,
      subtotal_incl_tax: 399,
      tax_amount: 52.04,
      total_due: 0,
      total_item_count: 1,
      total_paid: 399,
      total_qty_ordered: 1,
      updated_at: "2022-10-09T00:02:16Z",
      weight: 0.35,
      x_forwarded_for: "162.158.90.211",
      items: [
        {
          amount_refunded: 0,
          base_amount_refunded: 0,
          base_discount_amount: 0,
          base_discount_invoiced: 0,
          base_discount_tax_compensation_amount: 0,
          base_original_price: 346.96,
          base_price: 346.96,
          base_price_incl_tax: 399,
          base_row_invoiced: 0,
          base_row_total: 346.96,
          base_row_total_incl_tax: 399,
          base_tax_amount: 52.04,
          base_tax_invoiced: 0,
          created_at: "2022-09-20T17:16:19Z",
          discount_amount: 0,
          discount_invoiced: 0,
          discount_percent: 0,
          free_shipping: 0,
          discount_tax_compensation_amount: 0,
          is_qty_decimal: 0,
          is_virtual: 0,
          item_id: 168598,
          name: "SONY WF-C500 Truly Wireless Headphones Black ",
          no_discount: 0,
          order_id: 147679,
          original_price: 346.96,
          price: 346.96,
          price_incl_tax: 399,
          product_id: 20187,
          product_type: "simple",
          qty_canceled: 0,
          qty_invoiced: 0,
          qty_ordered: 1,
          qty_refunded: 0,
          qty_shipped: 0,
          quote_item_id: 898411,
          row_invoiced: 0,
          row_total: 346.96,
          row_total_incl_tax: 399,
          row_weight: 0.35,
          sku: "WF-C500/B",
          store_id: 24,
          tax_amount: 52.04,
          tax_invoiced: 0,
          tax_percent: 15,
          updated_at: "2022-09-20T17:16:19Z",
          weight: 0.35,
          product: {
            id: "20187",
            sku: "WF-C500/B",
            labels: {
              plp: [],
              pdp: [],
            },
            name: "SONY WF-C500 Truly Wireless Headphones Black ",
            categories: [
              {
                category_id: 2,
                is_virtual: "false",
                name: "All Categories",
                parent_id: "1",
                is_active: "1",
                include_in_menu: "1",
              },
              {
                category_id: 274,
                is_parent: true,
                is_virtual: "false",
                name: "Headphones",
                parent_id: "2",
                is_active: "1",
                include_in_menu: "1",
              },
              {
                category_id: 305,
                is_parent: true,
                is_virtual: "false",
                name: "Wireless Earphone",
                parent_id: "422",
                is_active: "1",
                include_in_menu: "1",
              },
              {
                category_id: 422,
                is_parent: true,
                is_virtual: "false",
                name: "Earphone",
                parent_id: "274",
                is_active: "1",
                include_in_menu: "1",
              },
              {
                category_id: 430,
                is_parent: true,
                is_virtual: "false",
                position: 2,
                name: "New Arrivals",
                parent_id: "2",
                is_active: "0",
                include_in_menu: "1",
              },
              {
                category_id: 435,
                is_virtual: "false",
                name: "Homepage",
                parent_id: "2",
                is_active: "0",
                include_in_menu: "0",
              },
              {
                category_id: 483,
                is_parent: true,
                is_virtual: "false",
                position: 1,
                name: "Best Items on the offer ",
                parent_id: "435",
                is_active: "1",
                include_in_menu: "1",
              },
              {
                category_id: 665,
                is_virtual: "false",
                position: 12,
                name: "Mega Sale ",
                parent_id: "2",
                is_active: "0",
                include_in_menu: "1",
              },
              {
                category_id: 669,
                is_parent: true,
                is_virtual: "false",
                name: "HeadPhones & speakers Offers ",
                parent_id: "665",
                is_active: "1",
                include_in_menu: "1",
              },
            ],
            price: 399.004001,
            price_rounded: 399,
            discounted_price: null,
            discounted_price_rounded: null,
            discounted_price_from: "",
            discounted_price_to: "",
            is_count_down: true,
            baseImage:
              "https://beta-m2.mestores.com/pub/media/catalog/productno_selection",
            description: "",
            system_requirements: "",
            delivery_details: "",
            media: {
              image: {
                packshot: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/9/7/97788978-289c-46b7-8213-cbb625e06e5e.jpg",
                  url: null,
                  position: "0",
                  width: "872",
                  height: "581",
                  type: "image",
                },
                featured: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/9/7/97788978-289c-46b7-8213-cbb625e06e5e.jpg",
                  url: null,
                  position: "0",
                  width: "872",
                  height: "581",
                  type: "image",
                },
                smallsquare: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/9/7/97788978-289c-46b7-8213-cbb625e06e5e.jpg",
                  url: null,
                  position: "0",
                  width: "872",
                  height: "581",
                  type: "image",
                },
                plpPackshot: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/9/7/97788978-289c-46b7-8213-cbb625e06e5e.jpg",
                  url: null,
                  position: "0",
                  width: "872",
                  height: "581",
                  type: "image",
                },
                screenshots: [
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/9/7/97788978-289c-46b7-8213-cbb625e06e5e.jpg",
                    url: null,
                    position: "0",
                    width: "872",
                    height: "581",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/b/u/buds_test_en.jpeg",
                    url: null,
                    position: "0",
                    width: "872",
                    height: "581",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/p/r/products_0003_75z9f_blk_blk_chevron_ccw_2.jpg",
                    url: null,
                    position: "0",
                    width: "800",
                    height: "800",
                    type: "image",
                  },
                ],
              },
              "external-video": [],
            },
            stock: 473,
            orderType: "in-stock",
            currency: "SAR",
            options: [
              {
                code: "sku",
                visibility: true,
                attribute: {
                  label: "SKU",
                  id: -1,
                },
                selected_option: {
                  label: "WF-C500/B",
                  labelArr: ["WF-C500/B"],
                  id: -1,
                },
              },
              {
                filterable: true,
                code: "google_product_category",
                visibility: false,
                attribute: {
                  label: "Google Product Category",
                  id: 322,
                },
                selected_option: {
                  label: "222",
                  labelArr: ["222"],
                  id: "222",
                },
              },
              {
                filterable: false,
                code: "fb_product_brand",
                visibility: false,
                attribute: {
                  label: "Brand",
                  id: 323,
                },
                selected_option: {
                  label: "Sony",
                  labelArr: ["Sony"],
                  id: "Sony",
                },
              },
              {
                filterable: true,
                code: "product_event_reporting",
                visibility: false,
                attribute: {
                  label: "Product Event Reporting",
                  id: 335,
                },
                selected_option: {
                  label: "SONY WF-C500 Truly Wireless Headphones Black ",
                  labelArr: ["SONY WF-C500 Truly Wireless Headphones Black "],
                  id: "SONY WF-C500 Truly Wireless Headphones Black ",
                },
              },
              {
                filterable: true,
                code: "url_key",
                visibility: false,
                attribute: {
                  label: "URL Key",
                  id: 122,
                },
                selected_option: {
                  label:
                    "sony-wf-c500-truly-wireless-headphones-black-wf-c500-b",
                  labelArr: [
                    "sony-wf-c500-truly-wireless-headphones-black-wf-c500-b",
                  ],
                  id: "sony-wf-c500-truly-wireless-headphones-black-wf-c500-b",
                },
              },
              {
                filterable: false,
                code: "ean",
                visibility: false,
                attribute: {
                  label: "EAN",
                  id: 199,
                },
                selected_option: {
                  label: "4548736130890",
                  labelArr: ["4548736130890"],
                  id: "4548736130890",
                },
              },
              {
                filterable: false,
                code: "express_shipping_eligible",
                visibility: true,
                attribute: {
                  label: "Express Delivery Eligible",
                  id: 410,
                },
                selected_option: {
                  label: "Express Delivery Eligible",
                  labelArr: ["Express Delivery Eligible"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "lighting_shipping_eligible",
                visibility: true,
                attribute: {
                  label: "Lighting Delivery Eligible",
                  id: 411,
                },
                selected_option: {
                  label: "Lighting Delivery Eligible",
                  labelArr: ["Lighting Delivery Eligible"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "meza_eligible",
                visibility: false,
                attribute: {
                  label: "Meza Eligible",
                  id: 328,
                },
                selected_option: {
                  label: "Meza Eligible",
                  labelArr: ["Meza Eligible"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "is_count_down",
                visibility: true,
                attribute: {
                  label: "Is Count Down",
                  id: 340,
                },
                selected_option: {
                  label: "Is Count Down",
                  labelArr: ["Is Count Down"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "qitaf_eligible",
                visibility: false,
                attribute: {
                  label: "Qitaf Eligible",
                  id: 388,
                },
                selected_option: {
                  label: "Qitaf Eligible",
                  labelArr: ["Qitaf Eligible"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "eligible_for_tamara",
                visibility: true,
                attribute: {
                  label: "Eligible For Tamara Installment",
                  id: 397,
                },
                selected_option: {
                  label: "Eligible For Tamara Installment",
                  labelArr: ["Eligible For Tamara Installment"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "color",
                visibility: false,
                attribute: {
                  label: "Color",
                  id: 90,
                },
                selected_option: {
                  label: "Black",
                  labelArr: ["Black"],
                  id: "49",
                },
              },
              {
                filterable: false,
                code: "eligible_for_tabby",
                visibility: true,
                attribute: {
                  label: "Eligible For Tabby Installment",
                  id: 409,
                },
                selected_option: {
                  label: "Eligible For Tabby Installment",
                  labelArr: ["Eligible For Tabby Installment"],
                  id: true,
                },
              },
              {
                filterable: false,
                code: "tax_class_id",
                visibility: false,
                attribute: {
                  label: "Tax Class",
                  id: 129,
                },
                selected_option: {
                  label: "SH",
                  labelArr: ["SH"],
                  id: "19",
                },
              },
            ],
            item_type: "simple",
            attribute_set_id: "22",
            meza: {
              points: 347,
              money_equivalent: 11,
            },
            qitaf: {
              points: 20,
              money_equivalent: 5,
            },
            custom_related_products: [],
            enable_shipping: true,
            cross_sell_products: [],
            bundle_products: [[]],
            inventory_qty: [
              {
                RS03: "2",
              },
              {
                RS04: "5",
              },
              {
                RS06: "2",
              },
              {
                RS09: "3",
              },
              {
                JS06: "3",
              },
            ],
            hex_code: ["#000000"],
            eligible_for_tabby: true,
            url_key: ["sony-wf-c500-truly-wireless-headphones-black-wf-c500-b"],
            bundle_discount_products: [],
          },
        },
      ],
      billing_address: {
        address_type: "billing",
        city: "Al Adari-الادري",
        country_id: "SA",
        email: "abadreshiya@sigmasolve.net",
        entity_id: 190543,
        firstname: "Test",
        lastname: "Test",
        parent_id: 147679,
        postcode: null,
        street: ["test"],
        telephone: "966545465645",
      },
      payment: {
        account_status: null,
        additional_information: {
          raw: [
            "MADA & Credit Card",
            '{"amount":"39900","response_code":"14000","card_number":"400555******0001","card_holder_name":"Test","signature":"bf9a6534900eb585cab33060dc72e11e3bf13b7ac81e1ba506f8d9c2eed63ad6","merchant_identifier":"DWwucZTK","access_code":"jAAToVShFrNVjuedoxYX","payment_option":"VISA","expiry_date":"2505","customer_ip":"18.158.205.245","language":"ar","eci":"ECOMMERCE","fort_id":"169996200006938822","command":"PURCHASE","response_message":"\\u0639\\u0645\\u0644\\u064a\\u0629 \\u0646\\u0627\\u062c\\u062d\\u0629","merchant_reference":"b000001822488","authorization_code":"267955","fraud_extra1":"high","customer_email":"abadreshiya@sigmasolve.net","token_name":"367d5d00a5b8499e99a490011e3947c9","currency":"SAR","customer_name":"Test Test","acquirer_response_code":"00","status":"14"}',
          ],
          message: "MADA & Credit Card",
        },
        amount_ordered: 399,
        base_amount_ordered: 399,
        base_shipping_amount: 0,
        cc_exp_year: "0",
        cc_last4: null,
        cc_ss_start_month: "0",
        cc_ss_start_year: "0",
        entity_id: 147141,
        last_trans_id: "169996200006938822",
        method: "payfort_fort_cc",
        parent_id: 147679,
        shipping_amount: 0,
      },
      status_histories: [
        {
          comment: '39900 رقم المعاملة: "169996200006938822"',
          created_at: "2022-09-20 17:16:40",
          entity_id: 4096847,
          entity_name: "order",
          is_customer_notified: null,
          is_visible_on_front: 0,
          parent_id: 147679,
          status: "payment_auth",
        },
        {
          comment:
            "PayfortFort :: redirecting to payment page with Method: payfort_fort_cc",
          created_at: "2022-09-20 17:16:22",
          entity_id: 4096846,
          entity_name: "order",
          is_customer_notified: null,
          is_visible_on_front: 0,
          parent_id: 147679,
          status: "payment_auth",
        },
      ],
      extension_attributes: {
        shipping_assignments: [
          {
            shipping: {
              address: {
                address_type: "shipping",
                city: "Al Adari-الادري",
                country_id: "SA",
                email: "abadreshiya@sigmasolve.net",
                entity_id: 190542,
                firstname: "Test",
                lastname: "Test",
                parent_id: 147679,
                postcode: null,
                street: ["test"],
                telephone: "966545465645",
              },
              method: "flatrate_flatrate",
              total: {
                base_shipping_amount: 0,
                base_shipping_discount_amount: 0,
                base_shipping_discount_tax_compensation_amnt: 0,
                base_shipping_incl_tax: 0,
                base_shipping_tax_amount: 0,
                shipping_amount: 0,
                shipping_discount_amount: 0,
                shipping_discount_tax_compensation_amount: 0,
                shipping_incl_tax: 0,
                shipping_tax_amount: 0,
              },
            },
            items: [
              {
                amount_refunded: 0,
                base_amount_refunded: 0,
                base_discount_amount: 0,
                base_discount_invoiced: 0,
                base_discount_tax_compensation_amount: 0,
                base_original_price: 346.96,
                base_price: 346.96,
                base_price_incl_tax: 399,
                base_row_invoiced: 0,
                base_row_total: 346.96,
                base_row_total_incl_tax: 399,
                base_tax_amount: 52.04,
                base_tax_invoiced: 0,
                created_at: "2022-09-20T17:16:19Z",
                discount_amount: 0,
                discount_invoiced: 0,
                discount_percent: 0,
                free_shipping: 0,
                discount_tax_compensation_amount: 0,
                is_qty_decimal: 0,
                is_virtual: 0,
                item_id: 168598,
                name: "SONY WF-C500 Truly Wireless Headphones Black ",
                no_discount: 0,
                order_id: 147679,
                original_price: 346.96,
                price: 346.96,
                price_incl_tax: 399,
                product_id: 20187,
                product_type: "simple",
                qty_canceled: 0,
                qty_invoiced: 0,
                qty_ordered: 1,
                qty_refunded: 0,
                qty_shipped: 0,
                quote_item_id: 898411,
                row_invoiced: 0,
                row_total: 346.96,
                row_total_incl_tax: 399,
                row_weight: 0.35,
                sku: "WF-C500/B",
                store_id: 24,
                tax_amount: 52.04,
                tax_invoiced: 0,
                tax_percent: 15,
                updated_at: "2022-09-20T17:16:19Z",
                weight: 0.35,
              },
            ],
          },
        ],
        cash_on_delivery_fee: 0,
        base_cash_on_delivery_fee: 0,
        mokafa_points: {
          money_equivalent: null,
        },
        ygg_points: {
          money_equivalent: null,
        },
        meza_points: {
          points: null,
          money_equivalent: 0,
          currency: "SAR",
        },
        qitaf_points: {
          qitaf: null,
        },
      },
    },
    {
      base_currency_code: "SAR",
      base_discount_amount: 0,
      base_discount_canceled: 0,
      base_grand_total: 1150,
      base_discount_tax_compensation_amount: 0,
      base_shipping_amount: 0,
      base_shipping_canceled: 0,
      base_shipping_discount_amount: 0,
      base_shipping_discount_tax_compensation_amnt: 0,
      base_shipping_incl_tax: 0,
      base_shipping_tax_amount: 0,
      base_subtotal: 1000,
      base_subtotal_canceled: 1000,
      base_subtotal_incl_tax: 1150,
      base_tax_amount: 150,
      base_tax_canceled: 150,
      base_total_canceled: 1150,
      base_total_due: 1150,
      base_to_global_rate: 1,
      base_to_order_rate: 1,
      billing_address_id: 189779,
      created_at: "2022-08-29T09:20:50Z",
      customer_email: "abadreshiya@sigmasolve.net",
      customer_firstname: "Test",
      customer_group_id: 0,
      customer_id: 232503,
      customer_is_guest: 0,
      customer_lastname: "Test",
      customer_note: "https://beta-web.mestores.com",
      customer_note_notify: 1,
      discount_amount: 0,
      discount_canceled: 0,
      entity_id: 147274,
      global_currency_code: "SAR",
      grand_total: 1150,
      discount_tax_compensation_amount: 0,
      increment_id: "b000001822262",
      is_virtual: 0,
      order_currency_code: "SAR",
      protect_code: "c28eead866bc92ba305fbdc7d79f55ee",
      quote_id: 2182714,
      remote_ip: "18.158.205.245",
      shipping_amount: 0,
      shipping_canceled: 0,
      shipping_description: "Home Delivery - Home Delivery",
      shipping_discount_amount: 0,
      shipping_discount_tax_compensation_amount: 0,
      shipping_incl_tax: 0,
      shipping_tax_amount: 0,
      state: "canceled",
      status: "canceled_cc_attempt",
      store_currency_code: "SAR",
      store_id: 24,
      store_name: "Main Website\nMestores\nEnglish",
      store_to_base_rate: 0,
      store_to_order_rate: 0,
      subtotal: 1000,
      subtotal_canceled: 1000,
      subtotal_incl_tax: 1150,
      tax_amount: 150,
      tax_canceled: 150,
      total_canceled: 1150,
      total_due: 1150,
      total_item_count: 1,
      total_qty_ordered: 1,
      updated_at: "2022-08-29T09:51:22Z",
      weight: 14,
      x_forwarded_for: "162.158.92.19",
      items: [
        {
          amount_refunded: 0,
          base_amount_refunded: 0,
          base_discount_amount: 0,
          base_discount_invoiced: 0,
          base_discount_tax_compensation_amount: 0,
          base_original_price: 1216.52,
          base_price: 1000,
          base_price_incl_tax: 1150,
          base_row_invoiced: 0,
          base_row_total: 1000,
          base_row_total_incl_tax: 1150,
          base_tax_amount: 150,
          base_tax_invoiced: 0,
          created_at: "2022-08-29T09:20:50Z",
          discount_amount: 0,
          discount_invoiced: 0,
          discount_percent: 0,
          free_shipping: 0,
          discount_tax_compensation_amount: 0,
          discount_tax_compensation_canceled: 0,
          is_qty_decimal: 0,
          is_virtual: 0,
          item_id: 168070,
          name: "SONY Home Cinema | Wireless Rear Speakers |5.1ch | HT-S40R",
          no_discount: 0,
          order_id: 147274,
          original_price: 1216.52,
          price: 1000,
          price_incl_tax: 1150,
          product_id: 17295,
          product_type: "simple",
          qty_canceled: 1,
          qty_invoiced: 0,
          qty_ordered: 1,
          qty_refunded: 0,
          qty_shipped: 0,
          quote_item_id: 897307,
          row_invoiced: 0,
          row_total: 1000,
          row_total_incl_tax: 1150,
          row_weight: 14,
          sku: "HT-S40R",
          store_id: 24,
          tax_amount: 150,
          tax_canceled: 150,
          tax_invoiced: 0,
          tax_percent: 15,
          updated_at: "2022-08-29T09:40:19Z",
          weight: 14,
          product: {},
        },
      ],
      billing_address: {
        address_type: "billing",
        city: "Abha- أبها",
        country_id: "SA",
        email: "abadreshiya@sigmasolve.net",
        entity_id: 189779,
        firstname: "Test",
        lastname: "Test",
        parent_id: 147274,
        postcode: "Al-Shamasan-الشمسان",
        street: ["Test 1"],
        telephone: "966546646546",
      },
      payment: {
        account_status: null,
        additional_information: {
          raw: ["Installment ( Order over 1000 SAR )"],
          message: "Installment ( Order over 1000 SAR )",
        },
        amount_ordered: 1150,
        base_amount_ordered: 1150,
        base_shipping_amount: 0,
        cc_exp_year: "0",
        cc_last4: null,
        cc_ss_start_month: "0",
        cc_ss_start_year: "0",
        entity_id: 146736,
        method: "payfort_fort_installments",
        parent_id: 147274,
        shipping_amount: 0,
      },
      status_histories: [
        {
          comment:
            "Order was set to canceled_cc_attempt by our automation tool",
          created_at: "2022-08-29 09:40:19",
          entity_id: 4095593,
          entity_name: "order",
          is_customer_notified: null,
          is_visible_on_front: 0,
          parent_id: 147274,
          status: "canceled_cc_attempt",
        },
        {
          comment:
            "PayfortFort :: redirecting to payment page with Method: payfort_fort_installments",
          created_at: "2022-08-29 09:20:56",
          entity_id: 4095592,
          entity_name: "order",
          is_customer_notified: null,
          is_visible_on_front: 0,
          parent_id: 147274,
          status: "payment_auth",
        },
        {
          comment: "Guest order converted to a customer order automatically",
          created_at: "2022-08-29 09:20:53",
          entity_id: 4095591,
          entity_name: "order",
          is_customer_notified: null,
          is_visible_on_front: 0,
          parent_id: 147274,
          status: "payment_auth",
        },
        {
          comment: "order has not been cancel in Fastco as there is no AWB",
          created_at: "0000-00-00 00:00:00",
          entity_id: 4095594,
          is_customer_notified: 0,
          is_visible_on_front: 0,
          parent_id: 147274,
          status: "canceled",
        },
      ],
      extension_attributes: {
        shipping_assignments: [
          {
            shipping: {
              address: {
                address_type: "shipping",
                city: "Abha- أبها",
                country_id: "SA",
                email: "abadreshiya@sigmasolve.net",
                entity_id: 189778,
                firstname: "Test",
                lastname: "Test",
                parent_id: 147274,
                postcode: "Al-Shamasan-الشمسان",
                street: ["Test 1"],
                telephone: "966546646546",
              },
              method: "flatrate_flatrate",
              total: {
                base_shipping_amount: 0,
                base_shipping_canceled: 0,
                base_shipping_discount_amount: 0,
                base_shipping_discount_tax_compensation_amnt: 0,
                base_shipping_incl_tax: 0,
                base_shipping_tax_amount: 0,
                shipping_amount: 0,
                shipping_canceled: 0,
                shipping_discount_amount: 0,
                shipping_discount_tax_compensation_amount: 0,
                shipping_incl_tax: 0,
                shipping_tax_amount: 0,
              },
            },
            items: [
              {
                amount_refunded: 0,
                base_amount_refunded: 0,
                base_discount_amount: 0,
                base_discount_invoiced: 0,
                base_discount_tax_compensation_amount: 0,
                base_original_price: 1216.52,
                base_price: 1000,
                base_price_incl_tax: 1150,
                base_row_invoiced: 0,
                base_row_total: 1000,
                base_row_total_incl_tax: 1150,
                base_tax_amount: 150,
                base_tax_invoiced: 0,
                created_at: "2022-08-29T09:20:50Z",
                discount_amount: 0,
                discount_invoiced: 0,
                discount_percent: 0,
                free_shipping: 0,
                discount_tax_compensation_amount: 0,
                discount_tax_compensation_canceled: 0,
                is_qty_decimal: 0,
                is_virtual: 0,
                item_id: 168070,
                name: "SONY Home Cinema | Wireless Rear Speakers |5.1ch | HT-S40R",
                no_discount: 0,
                order_id: 147274,
                original_price: 1216.52,
                price: 1000,
                price_incl_tax: 1150,
                product_id: 17295,
                product_type: "simple",
                qty_canceled: 1,
                qty_invoiced: 0,
                qty_ordered: 1,
                qty_refunded: 0,
                qty_shipped: 0,
                quote_item_id: 897307,
                row_invoiced: 0,
                row_total: 1000,
                row_total_incl_tax: 1150,
                row_weight: 14,
                sku: "HT-S40R",
                store_id: 24,
                tax_amount: 150,
                tax_canceled: 150,
                tax_invoiced: 0,
                tax_percent: 15,
                updated_at: "2022-08-29T09:40:19Z",
                weight: 14,
              },
            ],
          },
        ],
        cash_on_delivery_fee: 0,
        base_cash_on_delivery_fee: 0,
        mokafa_points: {
          money_equivalent: null,
        },
        ygg_points: {
          money_equivalent: null,
        },
        meza_points: {
          points: null,
          money_equivalent: 0,
          currency: "SAR",
        },
        qitaf_points: {
          qitaf: null,
        },
      },
    },
    {
      base_currency_code: "SAR",
      base_discount_amount: 0,
      base_grand_total: 542.5,
      base_discount_tax_compensation_amount: 0,
      base_shipping_amount: 0,
      base_shipping_discount_amount: 0,
      base_shipping_discount_tax_compensation_amnt: 0,
      base_shipping_incl_tax: 0,
      base_shipping_tax_amount: 0,
      base_subtotal: 450,
      base_subtotal_incl_tax: 517.5,
      base_tax_amount: 67.5,
      base_total_due: 542.5,
      base_to_global_rate: 1,
      base_to_order_rate: 1,
      billing_address_id: 192168,
      created_at: "2022-10-13T07:58:05Z",
      customer_email: "abadreshiya@sigmasolve.net",
      customer_firstname: "Test",
      customer_group_id: 1,
      customer_id: 232503,
      customer_is_guest: 0,
      customer_lastname: "Test",
      customer_note_notify: 1,
      discount_amount: 0,
      email_sent: 1,
      entity_id: 148538,
      global_currency_code: "SAR",
      grand_total: 542.5,
      discount_tax_compensation_amount: 0,
      increment_id: "swb000000000440",
      is_virtual: 0,
      order_currency_code: "SAR",
      protect_code: "da115c4cfd87386a47d3bb397cb3c34d",
      quote_id: 2188201,
      remote_ip: "18.158.205.245",
      shipping_amount: 0,
      shipping_description: "Standard Delivery - Home Delivery",
      shipping_discount_amount: 0,
      shipping_discount_tax_compensation_amount: 0,
      shipping_incl_tax: 0,
      shipping_tax_amount: 0,
      state: "new",
      status: "pending_cod",
      store_currency_code: "SAR",
      store_id: 27,
      store_name: "Sony\nSony\nEnglish",
      store_to_base_rate: 0,
      store_to_order_rate: 0,
      subtotal: 450,
      subtotal_incl_tax: 517.5,
      tax_amount: 67.5,
      total_due: 542.5,
      total_item_count: 1,
      total_qty_ordered: 1,
      updated_at: "2022-10-13T07:58:15Z",
      weight: 49,
      items: [
        {
          amount_refunded: 0,
          base_amount_refunded: 0,
          base_discount_amount: 0,
          base_discount_invoiced: 0,
          base_discount_tax_compensation_amount: 0,
          base_original_price: 500,
          base_price: 450,
          base_price_incl_tax: 517.5,
          base_row_invoiced: 0,
          base_row_total: 450,
          base_row_total_incl_tax: 517.5,
          base_tax_amount: 67.5,
          base_tax_invoiced: 0,
          created_at: "2022-10-13T07:58:05Z",
          discount_amount: 0,
          discount_invoiced: 0,
          discount_percent: 0,
          free_shipping: 0,
          discount_tax_compensation_amount: 0,
          is_qty_decimal: 0,
          is_virtual: 0,
          item_id: 169605,
          name: "SONY X85G Smart TV 85” 4K Ultra HD High Dynamic Range (HDR)(Google TV)",
          no_discount: 0,
          order_id: 148538,
          original_price: 500,
          price: 450,
          price_incl_tax: 517.5,
          product_id: 5279,
          product_type: "simple",
          qty_canceled: 0,
          qty_invoiced: 0,
          qty_ordered: 1,
          qty_refunded: 0,
          qty_shipped: 0,
          quote_item_id: 901307,
          row_invoiced: 0,
          row_total: 450,
          row_total_incl_tax: 517.5,
          row_weight: 49,
          sku: "KD-85X8500G",
          store_id: 27,
          tax_amount: 67.5,
          tax_invoiced: 0,
          tax_percent: 15,
          updated_at: "2022-10-13T07:58:05Z",
          weight: 49,
          product: {
            id: "5279",
            sku: "KD-85X8500G",
            labels: {
              plp: [],
              pdp: [],
            },
            name: "SONY X85G Smart TV 85” 4K Ultra HD High Dynamic Range (HDR)(Google TV)",
            categories: [
              {
                category_id: 2,
                is_virtual: "false",
                name: "All Categories",
                parent_id: "1",
                is_active: "1",
                include_in_menu: "1",
              },
              {
                category_id: 270,
                is_virtual: "false",
                position: 43,
                name: "Televisions",
                parent_id: "2",
                is_active: "1",
                include_in_menu: "1",
              },
              {
                category_id: 278,
                is_parent: true,
                is_virtual: "false",
                name: "Smart 4K TV's",
                parent_id: "270",
                is_active: "1",
                include_in_menu: "1",
              },
            ],
            price: 575.000001,
            price_rounded: 575,
            discounted_price: 517.500001,
            discounted_price_rounded: 517.5,
            discounted_price_from: "2020-12-01 00:00:00",
            discounted_price_to: "2030-12-19 00:00:00",
            is_count_down: true,
            baseImage:
              "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0004_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_front_1.jpg",
            description:
              '<p><span style="display: none;">description</span></p>',
            system_requirements: "",
            delivery_details: "",
            media: {
              image: {
                packshot: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0004_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_front_1.jpg",
                  url: null,
                  position: "1",
                  width: "800",
                  height: "800",
                  type: "image",
                },
                featured: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0004_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_front_1.jpg",
                  url: null,
                  position: "1",
                  width: "800",
                  height: "800",
                  type: "image",
                },
                smallsquare: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0004_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_front_1.jpg",
                  url: null,
                  position: "1",
                  width: "800",
                  height: "800",
                  type: "image",
                },
                plpPackshot: {
                  image:
                    "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0004_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_front_1.jpg",
                  url: null,
                  position: "1",
                  width: "800",
                  height: "800",
                  type: "image",
                },
                screenshots: [
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0004_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_front_1.jpg",
                    url: null,
                    position: "1",
                    width: "800",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0002_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_wm_1.jpg",
                    url: null,
                    position: "2",
                    width: "800",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0003_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_st_1.jpg",
                    url: null,
                    position: "3",
                    width: "800",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0006_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_ccw_1.jpg",
                    url: null,
                    position: "4",
                    width: "800",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0005_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_cw_1.jpg",
                    url: null,
                    position: "5",
                    width: "800",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0007_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_bz_1.jpg",
                    url: null,
                    position: "6",
                    width: "800",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0010_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_btyb1_1.jpg",
                    url: null,
                    position: "7",
                    width: "800",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0011_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_btya1_1.jpg",
                    url: null,
                    position: "8",
                    width: "800",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0009_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_btyc_1.jpg",
                    url: null,
                    position: "9",
                    width: "800",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0012_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_back_1.jpg",
                    url: null,
                    position: "10",
                    width: "800",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0008_65_x85xxg_xg85xx_xg87xx_blk_dark-sil_slim-chevron_btyd_1.jpg",
                    url: null,
                    position: "11",
                    width: "800",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0001_x8500g_1.jpg",
                    url: null,
                    position: "12",
                    width: "800",
                    height: "800",
                    type: "image",
                  },
                  {
                    image:
                      "https://beta-m2.mestores.com/pub/media/catalog/product/k/d/kd-75x8500g_0000__fy19graphic__in-situation_65_x85g_xg85_xg87_xg83_black_darksilver_wm-large_1.jpg",
                    url: null,
                    position: "13",
                    width: "800",
                    height: "800",
                    type: "image",
                  },
                ],
              },
              "external-video": [],
            },
            stock: 911,
            orderType: "in-stock",
            currency: "SAR",
            options: [
              {
                code: "sku",
                visibility: true,
                attribute: {
                  label: "SKU",
                  id: -1,
                },
                selected_option: {
                  label: "KD-85X8500G",
                  labelArr: ["KD-85X8500G"],
                  id: -1,
                },
              },
              {
                filterable: true,
                code: "url_key",
                visibility: false,
                attribute: {
                  label: "URL Key",
                  id: 122,
                },
                selected_option: {
                  label:
                    "sony-x85g-smart-tv-85-4k-ultra-hd-high-dynamic-range-hdrgoogle-tv-kd-85x8500g",
                  labelArr: [
                    "sony-x85g-smart-tv-85-4k-ultra-hd-high-dynamic-range-hdrgoogle-tv-kd-85x8500g",
                  ],
                  id: "sony-x85g-smart-tv-85-4k-ultra-hd-high-dynamic-range-hdrgoogle-tv-kd-85x8500g",
                },
              },
              {
                filterable: false,
                code: "ean",
                visibility: false,
                attribute: {
                  label: "EAN",
                  id: 199,
                },
                selected_option: {
                  label: "4548736098596",
                  labelArr: ["4548736098596"],
                  id: "4548736098596",
                },
              },
              {
                filterable: false,
                code: "mpn",
                visibility: false,
                attribute: {
                  label: "MPN",
                  id: 200,
                },
                selected_option: {
                  label: "KD-85X8500G_AF1",
                  labelArr: ["KD-85X8500G_AF1"],
                  id: "KD-85X8500G_AF1",
                },
              },
              {
                filterable: true,
                code: "google_product_category",
                visibility: false,
                attribute: {
                  label: "Google Product Category",
                  id: 322,
                },
                selected_option: {
                  label: "222",
                  labelArr: ["222"],
                  id: "222",
                },
              },
              {
                filterable: false,
                code: "fb_product_brand",
                visibility: false,
                attribute: {
                  label: "Brand",
                  id: 323,
                },
                selected_option: {
                  label: "Sony",
                  labelArr: ["Sony"],
                  id: "Sony",
                },
              },
              {
                filterable: true,
                code: "product_event_reporting",
                visibility: false,
                attribute: {
                  label: "Product Event Reporting",
                  id: 335,
                },
                selected_option: {
                  label:
                    "SONY X85G Smart TV 85” 4K Ultra HD High Dynamic Range (HDR)(Google TV)",
                  labelArr: [
                    "SONY X85G Smart TV 85” 4K Ultra HD High Dynamic Range (HDR)(Google TV)",
                  ],
                  id: "SONY X85G Smart TV 85” 4K Ultra HD High Dynamic Range (HDR)(Google TV)",
                },
              },
              {
                filterable: false,
                code: "express_shipping_eligible",
                visibility: true,
                attribute: {
                  label: "Express Delivery Eligible",
                  id: 410,
                },
                selected_option: {
                  label: "Express Delivery Eligible",
                  labelArr: ["Express Delivery Eligible"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "color",
                visibility: false,
                attribute: {
                  label: "Color",
                  id: 90,
                },
                selected_option: {
                  label: "Black",
                  labelArr: ["Black"],
                  id: "49",
                },
              },
              {
                filterable: false,
                code: "lighting_shipping_eligible",
                visibility: true,
                attribute: {
                  label: "Lighting Delivery Eligible",
                  id: 411,
                },
                selected_option: {
                  label: "Lighting Delivery Eligible",
                  labelArr: ["Lighting Delivery Eligible"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "tax_class_id",
                visibility: false,
                attribute: {
                  label: "Tax Class",
                  id: 129,
                },
                selected_option: {
                  label: "SH",
                  labelArr: ["SH"],
                  id: "19",
                },
              },
              {
                filterable: false,
                code: "meza_eligible",
                visibility: false,
                attribute: {
                  label: "Meza Eligible",
                  id: 328,
                },
                selected_option: {
                  label: "Meza Eligible",
                  labelArr: ["Meza Eligible"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "is_count_down",
                visibility: true,
                attribute: {
                  label: "Is Count Down",
                  id: 340,
                },
                selected_option: {
                  label: "Is Count Down",
                  labelArr: ["Is Count Down"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "estimated_time",
                visibility: false,
                attribute: {
                  label: "Estimated Days",
                  id: 341,
                },
                selected_option: {
                  label: "1",
                  labelArr: [1],
                  id: "1",
                },
              },
              {
                filterable: false,
                code: "qitaf_eligible",
                visibility: false,
                attribute: {
                  label: "Qitaf Eligible",
                  id: 388,
                },
                selected_option: {
                  label: "Qitaf Eligible",
                  labelArr: ["Qitaf Eligible"],
                  id: "true",
                },
              },
              {
                filterable: true,
                code: "inches",
                visibility: false,
                attribute: {
                  label: "Size By Inches",
                  id: 396,
                },
                selected_option: {
                  label: "50",
                  labelArr: ["50"],
                  id: "830",
                },
              },
              {
                filterable: false,
                code: "eligible_for_tamara",
                visibility: true,
                attribute: {
                  label: "Eligible For Tamara Installment",
                  id: 397,
                },
                selected_option: {
                  label: "Eligible For Tamara Installment",
                  labelArr: ["Eligible For Tamara Installment"],
                  id: "true",
                },
              },
              {
                filterable: false,
                code: "is_price_hide",
                visibility: true,
                attribute: {
                  label: "Hide Price",
                  id: 402,
                },
                selected_option: {
                  label: "Hide Price",
                  labelArr: ["Hide Price"],
                  id: "true",
                },
              },
            ],
            item_type: "simple",
            attribute_set_id: "21",
            meza: {
              points: 450,
              money_equivalent: 14,
            },
            qitaf: {
              points: 26,
              money_equivalent: 7,
            },
            custom_related_products: [
              "HT-RT3",
              "KD-55X8577G",
              "KD-65X7000G",
              "KD-85X9000H",
            ],
            enable_shipping: true,
            cross_sell_products: [],
            bundle_products: [[]],
            inventory_qty: [],
            hex_code: ["#000000"],
            eligible_for_tabby: false,
            meta_title:
              "تلفزيون سوني 85 بوصة ذكي (Android) 4K Ultra HD - HDR - LED - أسود",
            meta_keywords:
              "KD-85X8500G, شاشات, تلفاز, شاشة, تلفزيون , تلفزيونات , شاشات كي 4 , شاشة 4k, بوصة , شاشة k , شاشات سوني , شاشات بلايستيشن, شاشات ps5 , شاشات , شاشه , تي في , شاشات افلام , تلفزيونات افلام , شاشات كبيرة , شاشة سمارت , سمارت تي في , عروض الشاشات , شاشة بلاز",
            url_key: [
              "sony-x85g-smart-tv-85-4k-ultra-hd-high-dynamic-range-hdrgoogle-tv-kd-85x8500g",
            ],
            meta_description:
              "اشتّر تلفزيون سوني 85 بوصة ذكي (Android) 4K Ultra HD - HDR - LED - أسود من mestores.com",
            bundle_discount_products: [],
            additional_estimated_day: 1,
          },
        },
      ],
      billing_address: {
        address_type: "billing",
        city: "Al Adari-الادري",
        country_id: "SA",
        email: "abadreshiya@sigmasolve.net",
        entity_id: 192168,
        firstname: "Test",
        lastname: "Test",
        parent_id: 148538,
        postcode: null,
        street: ["test undefined"],
        telephone: "966545465645",
      },
      payment: {
        account_status: null,
        additional_information: {
          raw: ["Cash On Delivery", ""],
          message: "Cash On Delivery",
        },
        amount_ordered: 542.5,
        base_amount_ordered: 542.5,
        base_shipping_amount: 0,
        cc_exp_year: "0",
        cc_last4: null,
        cc_ss_start_month: "0",
        cc_ss_start_year: "0",
        entity_id: 147991,
        method: "cashondelivery",
        parent_id: 148538,
        shipping_amount: 0,
      },
      status_histories: [],
      extension_attributes: {
        shipping_assignments: [
          {
            shipping: {
              address: {
                address_type: "shipping",
                city: "Al Adari-الادري",
                country_id: "SA",
                email: "abadreshiya@sigmasolve.net",
                entity_id: 192167,
                firstname: "Test",
                lastname: "Test",
                parent_id: 148538,
                postcode: null,
                street: ["test undefined"],
                telephone: "966545465645",
              },
              method: "flatrate_flatrate",
              total: {
                base_shipping_amount: 0,
                base_shipping_discount_amount: 0,
                base_shipping_discount_tax_compensation_amnt: 0,
                base_shipping_incl_tax: 0,
                base_shipping_tax_amount: 0,
                shipping_amount: 0,
                shipping_discount_amount: 0,
                shipping_discount_tax_compensation_amount: 0,
                shipping_incl_tax: 0,
                shipping_tax_amount: 0,
              },
            },
            items: [
              {
                amount_refunded: 0,
                base_amount_refunded: 0,
                base_discount_amount: 0,
                base_discount_invoiced: 0,
                base_discount_tax_compensation_amount: 0,
                base_original_price: 500,
                base_price: 450,
                base_price_incl_tax: 517.5,
                base_row_invoiced: 0,
                base_row_total: 450,
                base_row_total_incl_tax: 517.5,
                base_tax_amount: 67.5,
                base_tax_invoiced: 0,
                created_at: "2022-10-13T07:58:05Z",
                discount_amount: 0,
                discount_invoiced: 0,
                discount_percent: 0,
                free_shipping: 0,
                discount_tax_compensation_amount: 0,
                is_qty_decimal: 0,
                is_virtual: 0,
                item_id: 169605,
                name: "SONY X85G Smart TV 85” 4K Ultra HD High Dynamic Range (HDR)(Google TV)",
                no_discount: 0,
                order_id: 148538,
                original_price: 500,
                price: 450,
                price_incl_tax: 517.5,
                product_id: 5279,
                product_type: "simple",
                qty_canceled: 0,
                qty_invoiced: 0,
                qty_ordered: 1,
                qty_refunded: 0,
                qty_shipped: 0,
                quote_item_id: 901307,
                row_invoiced: 0,
                row_total: 450,
                row_total_incl_tax: 517.5,
                row_weight: 49,
                sku: "KD-85X8500G",
                store_id: 27,
                tax_amount: 67.5,
                tax_invoiced: 0,
                tax_percent: 15,
                updated_at: "2022-10-13T07:58:05Z",
                weight: 49,
              },
            ],
          },
        ],
        cash_on_delivery_fee: 25,
        base_cash_on_delivery_fee: 25,
        mokafa_points: {
          money_equivalent: null,
        },
        ygg_points: {
          money_equivalent: null,
        },
        meza_points: {
          points: null,
          money_equivalent: 0,
          currency: "SAR",
        },
        qitaf_points: {
          qitaf: null,
        },
      },
    },
  ],
};

export default MyOrderData;
