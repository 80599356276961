import React, { useEffect, useState } from "react";
import LoginModel from "./LoginModel";
import NewsLetterModel from "./NewsLetterModel";
import OtpModel from "./OtpModel";
// import SignUpModel from "./SignUpModel";
import ResetPasswordModel from "./ResetPasswordModel";
import ResetPasswordVerifyModel from "./ResetPasswordVerifyModel";
import SignUpModel2 from "./SignUpModel2";

function LoginWrapper({ loginMode, closeLoginPopup,handleChangeCartIconTotal }) {
  const [popupMode, setPopupMode] = useState(loginMode);
  useEffect(() => {
    function init() {
      const data = JSON.parse(localStorage.getItem("loginMode"));
      setPopupMode(data.slice(1, data.length - 1));
    }
    init();
  }, []);
  // useEffect(() => {
  //   //

  //     setPopupMode(");

  // }, [JSON.parse(localStorage.getItem('loginMode'))]);

  useEffect(() => {
    setPopupMode(loginMode);
  }, [loginMode]);
  useEffect(() => {
    ;
    if (
      window.location.pathname.includes("/checkout") &&
      popupMode === "signin"
    ) {
      setPopupMode("signin");
    } else if (
      window.location.pathname.includes("/checkout") &&
      popupMode === "signup"
    ) {
      setPopupMode("signup");
    } else if (
      window.location.pathname.includes("/checkout") &&
      popupMode === "otp"
    ) {
      setPopupMode("otp");
    } else if (
      window.location.pathname.includes("/checkout") &&
      popupMode === "newsletter"
    ) {
      setPopupMode("newsletter");
    } else if (
      window.location.pathname.includes("/checkout") &&
      popupMode === "resetpassword"
    ) {
      setPopupMode("resetpassword");
    } else if (
      window.location.pathname.includes("/checkout") &&
      popupMode === "reset-password-verify"
    ) {
      setPopupMode("reset-password-verify");
    }
  }, [popupMode]);
  const handleChangePopupMode = (mode) => {
    setPopupMode(mode);
  };
  if (popupMode === "signin") {
    return (
      <LoginModel
      handleChangeCartIconTotal={handleChangeCartIconTotal}
        closeLoginPopup={closeLoginPopup}
        handleChangePopupMode={handleChangePopupMode}
      />
    );
  }
  if (popupMode === "signup") {
    return (
      <SignUpModel2
        closeLoginPopup={closeLoginPopup}
        handleChangePopupMode={handleChangePopupMode}
      />
    );
  }
  if (popupMode === "otp") {
    return (
      <OtpModel
        closeLoginPopup={closeLoginPopup}
        handleChangePopupMode={handleChangePopupMode}
      />
    );
  }
  if (popupMode === "newsletter") {
    return (
      <NewsLetterModel
        closeLoginPopup={closeLoginPopup}
        handleChangePopupMode={handleChangePopupMode}
      />
    );
  }
  if (popupMode === "resetpassword") {
    return (
      <ResetPasswordModel
        closeLoginPopup={closeLoginPopup}
        handleChangePopupMode={handleChangePopupMode}
      />
    );
  }
  if (popupMode === "reset-password-verify") {
    return (
      <ResetPasswordVerifyModel
        closeLoginPopup={closeLoginPopup}
        handleChangePopupMode={handleChangePopupMode}
      />
    );
  }
  return <LoginModel
  handleChangeCartIconTotal={handleChangeCartIconTotal}
    closeLoginPopup={closeLoginPopup}
    handleChangePopupMode={handleChangePopupMode}
  />;
}

export default LoginWrapper;
