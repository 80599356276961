import React from 'react';
import style from '../../SCSS/components/Meza/MezaPopup.module.scss';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleGeneralModal } from '../../redux/appAction';
import Modal from 'react-bootstrap/Modal';

function MezaPopup({ t, show, onClose }) {
  const dispatch = useDispatch();
  const toggleModalState = () => dispatch(toggleGeneralModal(false));

  const handleLinkClick = () => {
    toggleModalState();
  };

  if (!show) {
    return null;
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onClose}
      scrollable={false}
      size="m"
      className="bundleModal__comman"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('MezaPopUpTitle')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={style.popupContainer}>
          <div>
            <p>{t('MezaPopUpDescription')}</p>
            {t('More Details')}
            &nbsp;
            <Link to="/cms/meza-subscription" title={t('Read More')}>
              <span className={style.readMore} onClick={handleLinkClick}>
                {t('Click Here')}
              </span>
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default MezaPopup;
