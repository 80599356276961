import * as actionType from "../../actionType";

const initialState = {
  customerNewsletterSubMsg: '',
};

const customerNewsletterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CUSTOMER_NEWS_LETTER_SUB_MSG:
      return { ...state, customerNewsletterSubMsg: action.payload };
    default:
      return state;
  }
};

export default customerNewsletterReducer;
