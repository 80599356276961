import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import * as services from "../services/services";
import BreadCrumbs from "../Components/BreadCrumbs";
import Heading2 from "../Components/Font/Heading2";
import Heading4 from "../Components/Font/Heading4";
import Heading6 from "../Components/Font/Heading6";
import Text2 from "../Components/Font/Text2";
import Text3 from "../Components/Font/Text3";
import success_check from "./../assets/Icon/success_check.svg";
import "./../SCSS/_confirmOrderPage.scss";
import { useTranslation } from "react-i18next";
function Confirm_Order_Page() {
  const { t } = useTranslation()
  const { order_id } = useParams();

  const dispatch = useDispatch();

  const { customerOrderDetails } = useSelector((state) => state.customerOrdersReducer);

  const [orderDetails, setOrderDetails] = useState('');

  useEffect(() => {
    let params = {
      id: order_id
    }
    dispatch(services.getCustomerOrderDetails(params))
  }, [order_id]);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    if (customerOrderDetails) {
      setOrderDetails(customerOrderDetails);
    }
  }, [customerOrderDetails]);

  return (
    <>
      {/* <BreadCrumbs title="My Order" /> */}
      {orderDetails !== "" &&
        <div className="container-fluid confirm__order__page__container">
          <div className="confirm__order__page__block">
            <div className="inner__confirm__order__block">
              <img className="success_check_icon" src={success_check} alt="" />
              <Heading4 text={`Hey ${orderDetails.billing_address.firstname} ${orderDetails.billing_address.lastname}`} textAlign="center" />

              <Heading2
                text="Your Order is Confirmed!"
                color="#DC3A1A"
                marginBottom={20}
                textAlign="center"
              />
              <Text3 text={`Order number is : ${order_id}`} marginBottom={20} textAlign="center" />
              <p className="order__success__text">
                <Text2
                  text={orderDetails?.extension_attributes?.shipping_assignments && orderDetails?.extension_attributes?.shipping_assignments[0]?.shipping?.method === "storepickup_storepickup"
                  ? t('storepickup_message') : t('Your_item_has_been_on_the_way_expected_arriving_date_is')}
                  span={true}
                //   textAlign="center"
                />
                <Heading6
                  text="Sunday, 19th May 2022"
                  span={true}
                  //   textAlign="center"
                  marginLeft={10}
                />

                <Text2
                  text="& Shiiping Address Is"
                  span={true}
                  //   textAlign="center"
                  marginLeft={10}
                />
                <Heading6
                  text={`${orderDetails.billing_address.street[0]} ${orderDetails.billing_address.city} ${orderDetails.billing_address.postcode} ${orderDetails.billing_address.country_id}`}
                  span={true}
                  //   textAlign="center"
                  marginLeft={10}
                />
              </p>
              <p className="order__success__email__text">
                <Text2
                  text={`${t("Your_will_receive_an_email_confirmation_shortly_at")} ${customerOrderDetails.customer_email}`}
                  span={true}
                  textAlign="center"
                />
              </p>
              <Link className="continue__shopping__button" to="/">
                {t("Continue_Shopping")}
              </Link>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Confirm_Order_Page;
