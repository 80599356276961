export const genderList = [
  {
    label: "Male",
    value: "m",
    selectOption: 1,
  },
  {
    label: "Female",
    value: "f",
    selectOption: 2,
  },
  {
    label: "Other",
    value: "na",
    selectOption: 3,
  },
];

export const containsAllNumbers = (inputString) => {
  // Define a regular expression pattern to match all numbers
  const pattern = /^\d+$/;

  // Use the test() method of the RegExp object to check if the inputString matches the pattern
  return pattern.test(inputString);
};
