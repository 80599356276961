import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./../../SCSS/MostSharedComponents/_BundleModal.scss";

export default function BundleModal({ isFooter = true, title, modalShow, content, t, onConfirm, onHide, size, scrollable }) {
    return (
        <div>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={onHide}
                scrollable={scrollable}
                size='lg'
                className='bundleModal__comman'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="item name">
                        {content ? content : t('Content_Not_Found')}
                    </div>
                </Modal.Body>
                {isFooter ?
                    <Modal.Footer>
                        <Button onClick={() => onConfirm()}>
                            {t("Confirm")}
                        </Button>
                    </Modal.Footer>
                    : null}
            </Modal>
        </div>
    )
}
