import React from "react";
import shopping_cart from "../../assets/Icon/shopping_cart.svg";
import NotifyMeBtn from "../MostSharedComponent/NotifyMeBtn";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Spinner } from "../helpers/utils/spinner";

function AddToCart({ product, AddProductToCart, outOfStockConfiguration, loaderOn, type, matchedProduct }) {
  const productStatusData = useSelector((state) => state.appData.productStatusData);
  const { t } = useTranslation();

  let productOutOfStockStatus, isOutOfStock, isPreOrder

  if (productStatusData) {
    productOutOfStockStatus = product && productStatusData?.data?.[product?.id]?.productOutOfStockStatus ? productStatusData?.data?.[product?.id]?.productOutOfStockStatus : false
    isOutOfStock = productStatusData?.data?.[product?.id]?.orderType === 'out-of-stock' || productStatusData?.data?.[product?.id]?.stock === 0;
    isPreOrder = productStatusData?.data?.[product?.id]?.orderType === 'pre-order';
  } else {
    productOutOfStockStatus = product?.productOutOfStockStatus ? product?.productOutOfStockStatus : false
    isOutOfStock = product?.orderType === 'out-of-stock' || product?.stock === 0;
    isPreOrder = product?.orderType === 'pre-order';
  }

  return (
    <>
      {(productStatusData || product) && <>
        {product && isOutOfStock && outOfStockConfiguration?.module_status && productOutOfStockStatus ? (
          <NotifyMeBtn product={product} />
        ) : (
          <>
            {isOutOfStock ? (
              <div className="outofStock__button">{t("Out_Of_Stock")}</div>
            ) : isPreOrder ? (
              <div
                className="addToCart__button pre-order"
                onClick={() => {
                  // if (product?.bundle_child_products?.length > 0 && cartData?.items?.find((item) => item?.product?.bundle_child_products?.length > 0 && item?.sku !== product?.sku)) {
                  //   setBundlePopup(true)
                  // } else if ((buildProduct?.item_id && (Boolean(findData?.extension_attributes?.bundle_parent_id === buildProduct?.item_id))) || (findData?.product?.bundle_child_products?.length > 0)) {
                  //   setAlreadyAdd(true)
                  // } 
                  // else {
                    AddProductToCart(matchedProduct ? matchedProduct && matchedProduct[0]?.sku : product?.sku)
                  // }
                }
                }
              >
                {loaderOn === true ?
                  <Spinner />
                  :
                  <>
                    <img
                      src={shopping_cart}
                      alt=""
                      className="addToCart__icon"
                    />
                    {t("pre_order")}
                  </>}
              </div>
            ) : (
              <div
                className="addToCart__button"
                onClick={() => {
                  // if (product?.bundle_child_products?.length > 0 && cartData?.items?.find((item) => item?.product?.bundle_child_products?.length > 0 && item?.sku !== product?.sku)) {
                  //   setBundlePopup(true)
                  // } else if ((buildProduct?.item_id && (Boolean(findData?.extension_attributes?.bundle_parent_id === buildProduct?.item_id))) || (findData?.product?.bundle_child_products?.length > 0)) {
                  //   setAlreadyAdd(true)
                  // } else {
                    AddProductToCart(matchedProduct ? matchedProduct && matchedProduct[0]?.sku : product?.sku)
                  // }
                }
                }
              >
                {loaderOn === true ?
                  <Spinner />
                  :
                  <>
                    <img
                      src={shopping_cart}
                      alt=""
                      className="addToCart__icon"
                    />
                    {t("Add_To_Cart")}
                  </>
                }
              </div>
            )}
          </>
        )}
      </>}
    </>
  );
}

export default AddToCart;