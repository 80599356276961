import { useState, useEffect } from 'react';

function useInViewPort(ref, rootMargin = '0px') {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      }, { rootMargin });
      ref.current && observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [ref, rootMargin]);

  return isIntersecting;
}

export const useInViewPortMultiple = (refs) => {
  const initialState = Array(refs.length).fill(false);

  const [intersectionTable, setIntersecting] = useState(initialState);
  const refCheck = refs.map((ref) => !!ref?.current).toString();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      setIntersecting((current) => {
        const elms = refs.map((i) => i.current);
        const tmp = [...current];
        entries.forEach((i) => {
          tmp[elms.indexOf(i.target)] = i.isIntersecting;
        });
        return tmp;
      });
    });
    refs.map((ref) => ref.current && observer.observe(ref.current));
    return () => {
      observer.disconnect();
    };
  }, [refs, refCheck]);

  return intersectionTable;
};

export default useInViewPort;
