import React, { useState } from "react";
import "./../../SCSS/ProductType/_expertProducts.scss";
import checked from "./../../assets/Icon/checked.svg";
import empty_check from "./../../assets/Icon/empty_check.svg";
import RatingBlock from "../MostSharedComponent/RatingBlock";
import Price from "../Font/Price";
import OldPrice from "../Font/OldPrice";
import { Link } from "react-router-dom";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import LazyImageForProduct from "../MostSharedComponent/LazyImageForProduct";
import { ImageResize } from "../helpers/utils/imageResize";
function ExpertProducts({ product }) {
  const [isCheckBoxHover, setIsCheckBoxHover] = useState(false);
  const [isCheckBox, setIsCheckBox] = useState(false);
  const handleFavourite = () => {
    setIsCheckBox(!isCheckBox);
  };

  return (
    <div  key={product.id} className="expert__rd__product__block">
      <Link to={productUrlHelper(product?.english_name, product?.sku)}>
       
      <div className="col-4 exp__product__image__block">
        <LazyImageForProduct
          product={product}
          srcSetReSize={ImageResize}
          alt=""
          className="exp__product__image"
        />
      </div>
      </Link>
      <div className="exp__rd__rating__block">
        <RatingBlock
          rating={4.5}
          totalRatings={4333}
        />
      </div>

      <Price
        currency={product.currency}
        price={product.discounted_price_rounded === null ? product.price_rounded : product.discounted_price_rounded}
        size="heading6"
      />
      <OldPrice
        oldPrice={product.price_rounded}
        price={product.discounted_price_rounded === null ? product.price_rounded : product.discounted_price_rounded}
        size="text3"
        color="#808080"
        marginBottom={10}
        lineThrough={true}
        currency={product.currency}
      />
      <div className="exp__rd__select__block mt-2">
        <img
          onMouseEnter={() => setIsCheckBoxHover(true)}
          onClick={handleFavourite}
          onMouseLeave={() => setIsCheckBoxHover(false)}
          className={
            !isCheckBox
              ? "exp__rd__check__icon"
              : "exp__rd__check__icon__disable"
          }
          src={isCheckBoxHover ? checked : empty_check}
          alt=""
        />
        <img
          onClick={handleFavourite}
          className={
            isCheckBox
              ? "exp__rd__check__icon"
              : "exp__rd__check__icon__disable"
          }
          src={checked}
          alt=""
        />
      </div>
    </div>
  );
}

export default ExpertProducts;
