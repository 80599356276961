import { dataLayerPush } from "../dataLayerPush";

export const addToWishlistHandler = (product, screenName, event = 'add_to_wishlist') => {
  const data = {
    currency: product?.currency || 'SAR',
    value: Number(parseFloat(product?.price).toFixed(2)),
    items: [{
      item_id: product?.product?.id,
      item_name: product?.name,
      sku: product?.sku,
      price: Number(parseFloat(product?.price).toFixed(2)),
      quantity: product?.qty,
      item_category: product?.product?.categories[0]?.name || "",
      item_category2: product?.product?.categories[1]?.name || "",
      item_category3: product?.product?.categories[2]?.name || "",
      item_category4: product?.product?.categories[3]?.name || "",
      item_category5: product?.product?.categories[4]?.name || "",
      // category: product?.categories,
      dimension1: product?.sku,
    }],
  };
  dataLayerPush({ event, ecommerce: data, screen_name: screenName || 'Home', _clear: true });
};

export const inputValidationHandler = (data) => dataLayerPush({ event: 'input_validation', ...data, field_name: data.input_name });
