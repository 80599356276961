export const productAttributeGet = (options, keyname) => {
    let name = "";
    let getAllOptions = options.filter(val => val.code === keyname);
    if (getAllOptions.length > 0) {
        name = getAllOptions[0]?.selected_option?.labelArr[0]
        if(keyname === "screen_size"){
            name = `${getAllOptions[0]?.selected_option?.labelArr[0]}"`
        }
    }
    return name;
}