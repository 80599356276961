import React from "react";
import { useTranslation } from "react-i18next";
import "./../../../SCSS/ProductListPage/_facets.scss";
import Accordion from "react-bootstrap/Accordion";

function HdmiFacet({
  facetData,
  facetKey,
  onFilter,
  mobileView = false,
  values = [],
  desktop = false,
  eventKey = 2,
}) {
  const constructParamFn = (item) => `${item.key}`;
  const { t } = useTranslation();
  const childrenFn = (item) => (
    <>
      <span className="me-2">{item.label}</span>
      <span>{`(${item.count})`}</span>
    </>
  );
  const handleChange = (facetKey, item) => {
    onFilter(
      facetKey,
      constructParamFn(item),
      facetData[facetKey].multiple_selectable
    );
  };
  return (
    <div className="main__facets__block">
      {desktop ? (
        <Accordion.Item eventKey={eventKey}>
          {!mobileView && (
            <Accordion.Header>
              {" "}
              <h1 className="main__facets__title">{t("HDMI_Input")}</h1>
            </Accordion.Header>
          )}
          <Accordion.Body>
            {facetData[facetKey]?.collection?.map((item, itemIndex) => {
              const hdmiChecked = values?.includes(constructParamFn(item));

              return (
                // <SingleFacet
                //   constructParamFn={constructParamFn}
                //   childrenFn={childrenFn}
                //   // key={`${Math.random(Math.random() * 100) + 1}${facetKey}`}
                //   key={itemIndex}
                //   facetData={facetData}
                //   facetKey={facetKey}
                //   item={item}
                //   onFilter={onFilter}
                // />
                <div className="facet__block" key={itemIndex}>
                  <input
                    // key={`${Math.random(Math.random() * 100) + 1}${facetKey}`}
                    type="checkbox"
                    className="preferences__select__input__check"
                    name="hdmi_input"
                    // value={item.key}
                    defaultChecked={false}
                    onChange={() => handleChange(facetKey, item)}
                    checked={hdmiChecked}
                    id={`${item.query_filter_param}__${itemIndex}`}
                  />
                  <label
                    className="facet__item__label"
                    for={`${item.query_filter_param}__${itemIndex}`}
                  // onClick={() => onFilter(facetKey, constructParamFn(item), facetData[facetKey].multiple_selectable)}
                  >
                    {childrenFn(item)}
                  </label>
                </div>
              );
            })}
          </Accordion.Body>
        </Accordion.Item>
      ) : (
        <>
          <h1 className="main__facets__title">{t("HDMI_Input")}</h1>
          {facetData[facetKey]?.collection?.map((item, itemIndex) => {
            const hdmiChecked = values?.includes(constructParamFn(item));

            return (
              // <SingleFacet
              //   constructParamFn={constructParamFn}
              //   childrenFn={childrenFn}
              //   // key={`${Math.random(Math.random() * 100) + 1}${facetKey}`}
              //   key={itemIndex}
              //   facetData={facetData}
              //   facetKey={facetKey}
              //   item={item}
              //   onFilter={onFilter}
              // />
              <div className="facet__block" key={itemIndex}>
                <input
                  // key={`${Math.random(Math.random() * 100) + 1}${facetKey}`}
                  type="checkbox"
                  className="preferences__select__input__check"
                  name="hdmi_input"
                  // value={item.key}
                  defaultChecked={false}
                  onChange={() => handleChange(facetKey, item)}
                  checked={hdmiChecked}
                  id={`${item.query_filter_param}__${itemIndex}`}
                />
                <label
                  className="facet__item__label"
                  for={`${item.query_filter_param}__${itemIndex}`}
                // onClick={() => onFilter(facetKey, constructParamFn(item), facetData[facetKey].multiple_selectable)}
                >
                  {childrenFn(item)}
                </label>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

export default HdmiFacet;
