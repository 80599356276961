import React, { useEffect, useState } from "react";
import "./../SCSS/_bundleOffer.scss";

const BundleOffer = ({ t, bundleEnable, bundleProducts }) => {
  const [conflictStatus, setConflictStatus] = useState(0);
  useEffect(() =>{
    if(bundleProducts){
      if(bundleProducts[0]){
        setConflictStatus(bundleProducts[0].conflict_status); 
      }
    }
  }, [bundleProducts]);

  useEffect(() => {
    if (bundleEnable && bundleEnable === 2) {
      setConflictStatus(3);
    }
  }, [bundleEnable]);

  return (
    <>
      <div className="bundle__card__area">
        {bundleEnable === 1 || bundleEnable === 2 ? (        
          <div className={`bundle__card ${conflictStatus === 3 ? 'disableBundled' : ''} text-center`}>
            {t('Bundle_Offer')}
          </div>
        ) : <div className="bundle__blank" />
        }
      </div>
    </>
  )
}

export default BundleOffer;