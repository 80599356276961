// This is a cdn url replacer for optimizing images

const baseUrl = () => {
  if(process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'prod') return 'https://m2.me-retail.com/';
  if (process.env.NODE_ENV === 'beta') return 'https://beta-m2.mestores.com/';
  return 'https://alpha-m2.mestores.com/';
}

const cleanUrl = (url) => {

  if (url.includes('var/www/html/sm_market4')) {
    url = url.replace('var/www/html/sm_market4/', '');
  }
  !url.includes('.com') && url.includes('/pub') && (url = (baseUrl() + url).replace('//', '/'));
  

  if (url.includes('sonyworld.com')) {
    if (url.includes('cdn-cgi')) {
      url = url.replace('.com/cdn-cgi/image', '.com').replace('f=auto', '');
    }
    url.includes('=') && (url = url.replace(/([,]?[a-z]+=[a-z0-9]+[,]?)/g, ''));
    url.includes('.com/media/') && (url = url.replace('.com/media/', '.com/pub/media/'));
  }
  return url;
};
const cdnImageUrl = ({
  src, compress = 70, height, width, responsiveWidth, responsiveHeight, fit,
}) => {
  if (typeof src === 'string' && !src.includes('cdn-cgi')) {
    const cdnProps = [];
    src = cleanUrl(src);
    if (!src.includes('sonyworld.com')) {
      compress && (cdnProps.push(`q=${compress}`));
      if (responsiveWidth) {
        (`${responsiveWidth}`).match(/^[0-9]+(px)?$/) && cdnProps.push(`width=${responsiveWidth * 2}`);
      } else {
        (`${width}`).match(/^[0-9]+(px)?$/) && cdnProps.push(`width=${width}`);
      }
      if (responsiveHeight) {
        (`${responsiveHeight}`).match(/^[0-9]+(px)?$/) && cdnProps.push(`height=${responsiveHeight * 2}`);
      } else {
        (`${height}`).match(/^[0-9]+(px)?$/) && cdnProps.push(`height=${height}`);
      }
      fit && cdnProps.push(`fit=${fit}`);
      cdnProps.push('f=auto');

      const { origin, pathname } = new URL(src);
      return `${origin}/cdn-cgi/image/${cdnProps.join(',')}${pathname}`;
    }
  }
  return src;
};

export default cdnImageUrl;
