import React from "react";
import "./../SCSS/_feeds.scss";
import newsfeed from "./../assets/Feed/newsfeed.png"
import instafeed from "./../assets/Feed/instafeed.png"
import { useTranslation } from "react-i18next";

function Feeds() {
  const { t } = useTranslation()
  return (
    <div className="container-fluid feed__container">
      <div className="feed__block">
        <div className="row feed__row">
          <div className="col-12 col-sm-12 col-md-4 feed">
            <p className="feed__title">{t("Latest_News")}</p>
            <div className="feed__image__block">

              <img src={newsfeed} alt="" className="feed__image" />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 feed">
            <p className="feed__title">{("Facebook_Feeds")}</p>
            <div className="feed__image__block">

              <div
                className="fb-page facebook__feed__image"
                data-href="https://www.facebook.com/facebook"
                data-tabs="timeline"
                width="100%"
                height="385"
                data-small-header="false"
                data-adapt-container-width="true"
                hide_cover="false"
                data-show-facepile="true"
                data-lazy="true"
              >
                <blockquote
                  cite="https://www.facebook.com/facebook"
                  className="fb-xfbml-parse-ignore"
                >
                  <a href="https://www.facebook.com/facebook">{t("Meta")}</a>
                </blockquote>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 feed">
            <p className="feed__title">{t("Instagram_Feeds")}</p>
            <div className="feed__image__block">

              <img src={instafeed} alt="" className="feed__image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feeds;
