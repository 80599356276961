import React, { useEffect, useState } from "react";
import { TamaraCalculate } from "./TamaraCalculate";
import tamaracard from "./../assets/Icon/tamara.png";
import { useTranslation } from "react-i18next";
// import { TamaraCalculate } from 'lib/helpers/tamaraCalculate';
// import styles from '@/sass/components/TamaraInstallments.module.scss';
// import { useTranslation } from 'lib/i18n';
// import TamaraCircle from './TamaraCircle';

const TamaraInstallments = ({ months, total, currentLang }) => {
  const { t } = useTranslation();
  const [data, setData] = useState();

  useEffect(() => {
    setData(TamaraCalculate(months, total));
  }, []);
  // console.log("data====>", data);
  return (
    <>
      <div className="tamara__installments__block">
        <center>
          <p className="tamara__installments__label">
            {t("Tamara_is_a_buy_now_pay_later_feature")}
          </p>
        </center>
        <div className="tamara__installments__progressbox">
          <center>
            <img src={tamaracard} className="tamara__logo__pic" />
          </center>
          <div>
            {data?.length < 4 ? (
              <>
                <ul>
                  {data?.map((item, index) => (
                    <li>
                      <p className="tamara_amt_box">
                        {" "}
                        {item.amount} {"SAR"}
                      </p>
                      <div className="circle-box"></div>
                      <span className="tamara_date_label">
                        {" "}
                        {typeof item.label === "number"
                          ? new Intl.DateTimeFormat(currentLang, {
                              month: "long",
                              day: "numeric",
                            }).format(new Date(item?.label))
                          : item?.label}
                      </span>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                <ul>
                  {data?.slice(0,3).map((item, index)=> (
                    <li>
                      <p className="tamara_amt_box">
                        {" "}
                        {item.amount} {"SAR"}
                      </p>
                      <div className="circle-box"></div>
                      <span className="tamara_date_label">
                        {" "}
                        {typeof item.label === "number"
                          ? new Intl.DateTimeFormat(currentLang, {
                              month: "long",
                              day: "numeric",
                            }).format(new Date(item?.label))
                          : item?.label}
                      </span>
                    </li>
                  ))}
                </ul>
                <ul>
                  {data?.slice(3,6).map((item, index)=> (
                    <li>
                      <p className="tamara_amt_box">
                        {" "}
                        {item.amount} {"SAR"}
                      </p>
                      <div className="circle-box"></div>
                      <span className="tamara_date_label">
                        {" "}
                        {typeof item.label === "number"
                          ? new Intl.DateTimeFormat(currentLang, {
                              month: "long",
                              day: "numeric",
                            }).format(new Date(item?.label))
                          : item?.label}
                      </span>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
        {/* <h1>6 Tamara Installments</h1> */}
      </div>
    </>
  );
};

export default TamaraInstallments;
