import moment from "moment";
import React, { } from "react";
import { Link } from "react-router-dom";
import './../../SCSS/MostSharedComponents/_storePickInfoBox.scss';

function StorePickUp({ t, selectedCity, setStoreCityModal }) {
    return (
        <React.Fragment>
            {selectedCity && (
                <div className="col-md-12" key={selectedCity?.city}>
                    <div className="row store__pickup__taken__area">
                        <div className="col-6 col-md-7 delivery__to__box">
                            <label>{t("Store Pickup in")} : </label>
                            <strong>{selectedCity?.city || ''}</strong>
                        </div>
                        <div className="col-6 col-md-5 delivery__day__box">
                            <Link to="#" className="delivery__to__change__btn"
                                onClick={() => {
                                    setStoreCityModal(true);
                                }}
                            >{t("Change City")}</Link>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
export default StorePickUp;