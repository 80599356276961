import React from 'react';
import styles from '../SCSS/components/GamersEliteHero.module.scss';

const GamersEliteHero = ({ t, submit }) => (
  <div className={styles.HeroWrapper} style={{ alignItems: submit ? 'center' : 'unset' }}>
    {!submit ? (
      <>
        <h1>
          {t('GAMERS ELITE')}
        </h1>
        <h1 className={styles.purple}>
          {t('MEMBERSHIP')}
        </h1>
        <div className={styles.info}>
          <p>{t('GamersEliteTextP1')}</p>
          <p>{t('GamersEliteTextP2')}</p>
        </div>
      </>
    ) : (
      <>
        <h1 className={styles.purple} style={{ textAlign: 'center' }}>
          {t('Gamer_Elite_Congrats_Title')}
        </h1>
        <div className={styles.info} style={{ justifyContent: 'center' }}>
          <p style={{ textAlign: 'center' }}>
            {t('Gamer_Elite_Congrats_Text')}
          </p>
        </div>
      </>
    )}
  </div>
);

export default GamersEliteHero;
