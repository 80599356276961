import React from "react";
import "./../../SCSS/MostSharedComponents/_superCoin.scss";
import mezzacard from './../../assets/Icon/mezzacard.png';
import coin from "./../../assets/Icon/coin.svg";
import { useTranslation } from "react-i18next";

function RewardBlock({ rewardType, money_equivalent, currency, points }) {
  const { t } = useTranslation()
  const currentLang = localStorage.getItem("i18next") || "en";
  let quitafcard = require(`./../../assets/Icon/icon-qitaf-${currentLang}.png`)
  return (
    <div className="row super__coin__itembox common__main__block">
      <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5 super__coin__image__block">
        <img src={rewardType === "meza" ? mezzacard : quitafcard} alt="" className="super__coin__image" />
      </div>
      <div className="col-8 col-sm-6 col-md-8 col-lg-8 col-xl-7 super__coin__text__block ps-2">
        {/* <Heading7 text="SAR200" /> */}
        {/* <span class="sum__points d-block">{`${points}`} points</span> */}
        <span className="sum__points d-block">{t("Buy_and_Get")}<strong>{` ${points}`} {t("points")}</strong></span>
        <span className="sum__eq d-block">({t("equals_to")} {` ${money_equivalent} ${currency}`})</span>
        {/* <p className="super__coin__title">
                      you earn <img src={coin} alt="" className="coin" /> 2 SuperCoins
                    </p>
                    <p className="super__coin__condition">Max 50 coins per order</p> */}
      </div>
    </div>
  );
}

export default RewardBlock;