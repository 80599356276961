import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import home_delivery_icon from "./../../assets/Icon/home-delivery.svg";
import store_black_icon from "./../../assets/Icon/store-black.svg";
import "./../../SCSS/Common/_productDeliveryType.scss";

function ProductDeliveryTypeCart({ product, t, productData }) {
    const [storeConfig, setStoreConfig] = useState('');
    const { configurationSettingData } = useSelector((state) => state.appData);
    const [isStorePickupAvailable, setIsStorePickupAvailable] = useState(false);

    useEffect(() => {
        if (configurationSettingData?.store_pickup !== undefined) {
            setStoreConfig(configurationSettingData?.store_pickup)
        }
    }, [configurationSettingData])

    useEffect(() => {
        if (productData.extension_attributes?.inventory_stocks) {
            setIsStorePickupAvailable(
                !!(Object.entries(productData?.extension_attributes?.inventory_stocks || [])
                    ?.map((item) => ({ store: item[0], qty: item[1] }))
                    .filter((stor) => stor.store !== 'rs97' && stor.store !== 'rw20')
                    .filter((final) => Number(final.qty) !== 0)).length,
            );
        }
    }, [productData]);

    const isOutOfStock = product?.orderType === 'out-of-stock' || product?.stock === 0;

    return (
        <>
            <div className="product__delivery__option__area" key={`type_${product?.id}`}>
                {(storeConfig === "1" || storeConfig === 1) && product?.item_type !== 'virtual' && !isOutOfStock && (
                    <>
                        <div className={`${productData?.extension_attributes?.is_home_delivery_available ? "product__delivery__itembox home__delivery_on active" : "product__delivery__itembox home__delivery_on disabled"}`}  >
                            <img
                                src={home_delivery_icon}
                                alt="delivery"
                            />
                            <span>{t('Home_Delivery')}</span>
                        </div>
                        
                    
                            <div className={`${isStorePickupAvailable && product.orderType !== 'pre-order'  ? "product__delivery__itembox store_on active" : "product__delivery__itembox store_on disabled"}`}  >
                                <img
                                    src={store_black_icon}
                                    alt="store-pickup"
                                />
                                <span>{t('Store_Pickup')}</span>
                            </div>
                        
                     
                    </>
                )}
            </div>
        </>
    );
}

export default ProductDeliveryTypeCart;
