import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import countrylist from '../../lib/static/countries.json';
import '../../SCSS/Form/_braviaForm.scss';
import { useNavigate } from 'react-router';
import { registerForBraviaLaunch } from "../../services/homeEntertainment.service";
import { Spinner } from "../helpers/utils/spinner";
import * as services from "../../services/services";
import { phoneNumberRegExpSa } from '../helpers/utils/regexp';

const usages = ["Movies", "Sports", "Gaming", "News", "OTT Platforms like Netflix"]; // replace with actual questions
const countries = countrylist.map((country) => country.name);

const BraviaForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const validationSchema = Yup.object({

    firstName: Yup.string().matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\u0600-\u06FF\s]*$/, t('Only alphabets are allowed')).required(t('Required')),
    lastName: Yup.string().matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\u0600-\u06FF\s]*$/, t('Only alphabets are allowed')).required(t('Required')),
    phone: Yup.string().matches(phoneNumberRegExpSa, t('Phone number is not valid')).required(t('Required')),
    email: Yup.string().email(t('Invalid email address')).required(t('Required')),
    country: Yup.string().required(t('Required')),
    nationality: Yup.string().matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\u0600-\u06FF\s]*$/, t('Only alphabets are allowed')).required(t('Required')),
    usage: Yup.array().min(1, t('At least one question should be selected')).required(t('Required')),
    termsAndConditions: Yup.bool().oneOf([true], t('Must Accept Terms and Conditions')).required(t('Required')),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await registerForBraviaLaunch(values);
      if (response?.success) {
        dispatch(services.notifySuccess({ message: t('Registered succesfully') }));
      } else {
        dispatch(services.notifyError({ message: t(response?.message) }))
      }
    } catch (error) {
      dispatch(services.notifyError({ message: t("Error occured when registering") }))
    }
    setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        country: '',
        nationality: '',
        usage: [],
        termsAndConditions: false,
        subscribe: false,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, errors, touched }) => (
        <Form className='braviaForm'>
          <div className='field'>
            <label htmlFor="firstName">{t('First Name')}<span className='errorMessage'>*</span></label>
            <Field name="firstName" type="text" placeholder={t('First Name')} className={errors.firstName && touched.firstName ? 'error' : ''}/>
            <ErrorMessage name="firstName" component="div" className="errorMessage" />
          </div>
          <div className='field'>
            <label htmlFor="lastName">{t('Last Name')}<span className='errorMessage'>*</span></label>
            <Field name="lastName" type="text" placeholder={t('Last Name')} className={errors.lastName && touched.lastName ? 'error' : ''}/>
            <ErrorMessage name="lastName" component="div" className="errorMessage" />
          </div>
          <div className='field'>
            <label htmlFor="phone">{t('Phone')}<span className='errorMessage'>*</span></label>
            <Field name="phone" type="text" placeholder={t('Phone')} />
            <ErrorMessage name="phone" component="div" className="errorMessage" />
          </div>
          <div className='field'>
            <label htmlFor="email">{t('Email')}<span className='errorMessage'>*</span></label>
            <Field name="email" type="email" placeholder={t('Email')} className={errors.email && touched.email ? 'error' : ''}/>
            <ErrorMessage name="email" component="div" className="errorMessage" />
          </div>
          <div className='field'>
            <label htmlFor="country">{t('Country')}<span className='errorMessage'>*</span></label>
            <Field name="country" as="select" className={errors.country && touched.country ? 'error' : ''}>
              <option value="">{t('Select...')}</option>
              {countries.map((country, index) => (
                <option key={index} value={country}>
                  {t(country)}
                </option>
              ))}
            </Field>
            <ErrorMessage name="country" component="div" className="errorMessage" />
          </div>
          <div className='field'>
            <label htmlFor="nationality">{t('Nationality')}<span className='errorMessage'>*</span></label>
            <Field name="nationality" type="text" placeholder={t('Nationality')} className={errors.nationality && touched.nationality ? 'error' : ''}/>
            <ErrorMessage name="nationality" component="div" className="errorMessage" />
          </div>
          <div className='field'>
          <p>{t('What do you use your TV for?')}<span className='errorMessage'>*</span></p>
            <div className='checkBoxField'>
              {usages.map((usage, index) => (
                <div key={index}>
                  <label>
                    <span className="custom-checkbox">
                      <Field type="checkbox" name="usage" value={usage} />
                      <span className="checkmark"></span>
                    </span>
                    {t(usage)}
                  </label>
                </div>
              ))}
            </div>
            <ErrorMessage name="question" component="div" className="errorMessage" />
          </div>
          <div className='field'>
            <p className='subsText' style={{ fontSize: '14px', marginBottom:'4px' }}>
              {t('Terms and Conditions')}<span className='errorMessage'>*</span>
            </p>
            <label className='subsText'>
              <Field type="checkbox" name="termsAndConditions" />
              {t('i have read and accept the')}
              &nbsp;
              <span className='termsNcondi' onClick={()=>navigate("/cms/terms-and-conditions")}>{t('Terms and Conditions')}</span>
              <span className='errorMessage'>*</span>
            </label>
            <ErrorMessage name="termsAndConditions" component="div" className="errorMessage" />
          </div>
          <div className='field'>
            <label className='subsText'>
              <Field type="checkbox" name="subscribe" />
              {t('Subscribe to our newsletter')}
            </label>
          </div>
          <button type="submit" disabled={isSubmitting || !isValid} style={{height:'44px'}}>
            {isSubmitting ? <Spinner /> : t('Submit')}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default BraviaForm;