import React, { useState } from "react";
import Heading3 from "../Font/Heading3";
import Heading7 from "../Font/Heading7";
import { emailValidator, isMobileNumber } from "../helpers/utils/validators";
import cancel_grey from "./../../assets/Icon/cancel_grey.svg";
import google_white from "./../../assets/Icon/google_white.svg";
import facebook_white from "./../../assets/Icon/facebook_white.svg";
import apple_white from "./../../assets/Icon/apple_white.svg";
import "./../../SCSS/Login/_signupModel.scss";
import * as services from './../../services/services'
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from "react-i18next";

function GuestLoginModel({ handleChangePopupMode, closeLoginPopup }) {
  const { t } = useTranslation()

  const T_REQ_FIRSTNAME = t("First_Name_is_required");
  const T_REQ_LASTNAME = t("Last_Name_is_required");
  const T_REQ_EMAIL = t("Email_is_missing");
  const T_INVALID_EMAIL = t("Invalid_email_address_example");
  const T_REQ_MOBILE_NUMBER = t("Mobile_Number_is_missing");
  const T_INVALID_MOBILE_NUMBER = t("Invalid_Mobile_Number");

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    telephone: ""
  });
  const [errMsg, setErrMsg] = useState({
    firstName: "",
    lastName: "",
    email: "",
    telephone: ""
  });


  const validateForm = (event, newErrObj, name, value) => {

    //A function to validate each input values
    switch (name) {
      case 'firstName':
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_FIRSTNAME }
        } else {
          newErrObj = { ...newErrObj, [name]: '' }
        }
        break;
      case 'lastName':
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_LASTNAME }
        } else {
          newErrObj = { ...newErrObj, [name]: '' }
        }
        break;
      case 'telephone':
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_MOBILE_NUMBER }
        } else {
          let isValidNumber = isMobileNumber(value);
          if (isValidNumber === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_MOBILE_NUMBER }
          } else {
            newErrObj = { ...newErrObj, [name]: '' }
          }
        }
        break;
      case 'email':
        let emailStatus = emailValidator(value);
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_EMAIL }
        } else {
          if (emailStatus === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_EMAIL }
          } else {
            newErrObj = { ...newErrObj, [name]: '' }
          }
        }
        break;
      default:
        break;
    }
    return newErrObj;
  }

  const handleChange = async (event, keyName) => {
    let value = event;
    let name = event;
    if (keyName === 'telephone') {
      value = event
      name = 'telephone'
    } else {
      value = event.target.value;
      name = event.target.name;
    }
    setData({ ...data, [name]: value });
    let manageErrMsg = validateForm(event, errMsg, name, value);
    setErrMsg(manageErrMsg);
  };

  const handleBlur = (e) => {
    let obj = {
      target: {
        value: e.target.value.trim(),
        name: e.target.name
      }
    }
    handleChange(obj)
  }

  const allFeildValidate = (validateFeild, allErrMsg) => {

    let checkValueStatus = [];
    let checkErrStatus = [];

    validateFeild && validateFeild.map((val, i) => {
      let keyVal = data[val];
      let errVal = errMsg[val];

      allErrMsg = validateForm('', allErrMsg, val, keyVal);
      if (keyVal !== "") {
        checkValueStatus.push('suc')
      }
      if (errVal === "") {
        checkErrStatus.push('err')
      }

    })

    let checkGuestStatus = false;
    if (checkValueStatus.length === validateFeild.length && checkErrStatus.length === validateFeild.length) {
      checkGuestStatus = true;
    }

    let returnData = {
      allErrMsg: allErrMsg,
      checkGuestStatus: checkGuestStatus
    }

    return returnData;
  };

  const onGuestSignIn = () => {

    let validateFeild = [
      "firstName",
      "lastName",
      "telephone",
      "email"
    ];
    let formStatus = allFeildValidate(validateFeild, errMsg);
    setErrMsg(formStatus.allErrMsg);
    if (formStatus.checkGuestStatus === true) {
      
    } else {
     
    }
  }

  return (
    <div className="signupModel__container">
      <div className="signupModel__header">
        <Heading3 text={t("Guest_to_Sony")} color="#000000" />
        <img
          onClick={() => closeLoginPopup()}
          src={cancel_grey}
          alt="cancel"
          className="cancel__button"
        />
      </div>
      <div className="signupModel__content">
        <div className="main__form__field__block">
          {/* <p className="form__label">First Name</p> */}
          <Heading7 text={t("First_Name")} marginBottom={10} />
          <div className="field__block">
            <input
              type="text"
              placeholder={t("First_Name")}
              className="form__field"
              id="firstName"
              name="firstName"
              value={data.firstName}
              onChange={(e) => handleChange(e)}
              onBlue={handleBlur}
            />
          </div>
          {errMsg.firstName && <p className="invalid__message">{errMsg.firstName}</p>}
        </div>
        <div className="main__form__field__block">
          {/* <p className="form__label">Last Name</p> */}
          <Heading7 text={t("Last_Name")} marginBottom={10} />
          <div className="field__block">
            <input
              type="text"
              placeholder={t("Last_Name")}
              className="form__field"
              id="lastName"
              name="lastName"
              value={data.lastName}
              onChange={(e) => handleChange(e)}
              onBlue={handleBlur}
            />
          </div>
          {errMsg.lastName && <p className="invalid__message">{errMsg.lastName}</p>}
        </div>
        <div className="main__form__field__block">
          {/* <p className="form__label">Email Address</p> */}
          <Heading7 text={t("Email_Address")} marginBottom={10} />
          <div className="field__block">
            <input
              type="text"
              placeholder={t("Email_Address")}
              className="form__field"
              id="email"
              name="email"
              value={data.email}
              onChange={(e) => handleChange(e)}
              onBlue={handleBlur}
            />
          </div>
          {errMsg.email && <p className="invalid__message">{errMsg.email}</p>}
        </div>
        <div className="main__form__field__block">
          <Heading7 text={t("Mobile_Number")} marginBottom={10} />
          <PhoneInput
            inputProps={{
              name: "telephone",
              required: true,
              className: "profile__mobile__form__field"
            }}
            country="sa"
            onlyCountries={['sa']}
            masks={{ sa: '.. ... ....' }}
            countryCodeEditable={false}
            disableDropdown={true}
            value={data.telephone}
            onChange={(e) => handleChange(e, 'telephone')}
            className=""
          />
          {errMsg.telephone && <p className="invalid__message">{errMsg.telephone}</p>}
        </div>

        <button className="signup__button" onClick={() => onGuestSignIn()}>GUEST SIGN IN</button>
        <div className="signup__or__block">
          <div className="signup__or__text__block">
            <p className="signup__or__text">{t("OR")}</p>
          </div>
          <div className="signup__mid__line"></div>
        </div>

        <button className="google__signin__button">
          <img
            src={google_white}
            alt="google_icon"
            className="signin__button__icon"
          />
          {t("Sign_In_With_Google")}
        </button>
        <button className="facebook__signin__button">
          <img
            src={facebook_white}
            alt="google_icon"
            className="signin__button__icon"
          />
          {t("Sign_In_With_Facebook")}
        </button>
        <button className="apple__signin__button">
          <img
            src={apple_white}
            alt="google_icon"
            className="signin__button__icon"
          />
          {t("Sign_In_With_Apple")}
        </button>
        <div className="signupModel__footer">
          <p className="footer__text">
            {t("Already_have_an_account")}{" "}
            <span
              onClick={() => handleChangePopupMode("signin")}
              className="footer__link__text"
            >
              {t("Sign_In")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default GuestLoginModel;
