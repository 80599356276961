import * as actionType from "./../../redux/actionType";
import apiHelper from "../../Components/helpers/utils/apiHelper";

// loader start
export const loaderStartEnd = (data) => {
  return {
    type: actionType.LOADER_START_END,
    payload: data
  }
}

// loader start
export const loaderStart = (data) => {
  return {
    type: actionType.LOADER_START,
    payload: true
  }
}

// loader end
export const loaderEnd = (data) => {
  return {
    type: actionType.LOADER_END,
    payload: false
  }
}

// notify success for api response is success
export const notifySuccess = (data) => {
  data.status = true;
  return {
    type: actionType.NOTIFY_SUCCESS_MSG,
    payload: data
  }
}

// notify error for api response is error
export const notifyError = (data) => {
  data.status = false;
  return {
    type: actionType.NOTIFY_ERROR_MSG,
    payload: data
  }
}

// notify message clear
export const notifyClear = (data) => {
  data.status = false;
  return {
    type: actionType.NOTIFY_ERROR_MSG,
    payload: ''
  }
}

// cms page details
export const getCmsPageContentApi = (identifier) => {
  return async dispatch => {
    dispatch(cmsPageContentApiSuccess(''));
    try {
      let response = await apiHelper(`/V1/cms/${identifier}`, 'get', null, {});
      dispatch(cmsPageContentApiSuccess(response.data));
    } catch (error) {
      dispatch(cmsPageContentApiSuccess(error));
    }
  }
}

// organization structure details reducer
export const cmsPageContentApiSuccess = (data) => {
  return {
    type: actionType.CMS_PAGE_CONTENT,
    payload: data
  }
}

export const compareListUpdateSuccess = (data) => {
  return {
    type: actionType.COMPARE_LIST_UPDATE,
    payload: data
  }
}

// stock-notification
export const productStockNotification = async (data) => {
  let response = await apiHelper(`/V1/stock-notification/`, 'post', data, {});
  return response;
};