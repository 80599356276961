import React, { useState } from 'react';
import AddToCartNotify from '../Buttons/AddToCartNotify';
import { addToCart } from '../../services/cart.service';
import { useDispatch } from 'react-redux';
import { loadCartData } from '../../redux/appAction';
import { cartEvents } from '../helpers/utils/ga/cartEvents';
import { useTranslation } from 'react-i18next';
import useCartErrorMessage from '../helpers/hooks/useCartErrorMessage';
import { impressionEvents } from '../helpers/utils/ga/impressionEvents';

function AddToCartButton({
  product,
  outOfStockConfiguration,
  matchedProduct,
  handleChangeCartPopup
}) {
  const { setCartErr } = useCartErrorMessage();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);
  const [sizeButtonIndex, setSizeButtonIndex] = useState(0);

  const newMatchPrice =
    matchedProduct?.length > 1 ? matchedProduct?.[0] : matchedProduct?.[0];

  const AddProductToCart = (sku) => {
    setLoaderOn(true);
    // impressionEvents.impressionAddToCart(
    //     product, 1,"Home"
    //   );
    if (
      product?.variants &&
      product?.variants.length > 0 &&
      sizeButtonIndex &&
      sizeButtonIndex >= 0
    ) {
      sku = product.variants[sizeButtonIndex].sku;
    }
    sku =
      matchedProduct && matchedProduct?.length > 0
        ? newMatchPrice?.sku
        : product?.sku;
    const data = {
      items: [
        {
          sku: sku,
          qty: 1,
          id: product?.id
        }
      ]
    };

    addToCart(data)
      .then((res) => {
        setSpinnerOn(true);
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id);
          setSpinnerOn(false);
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
      })
      .finally(() => {
        setLoaderOn(false);
      });
  };

  return (
    <>
      <AddToCartNotify
        product={product}
        AddProductToCart={AddProductToCart}
        outOfStockConfiguration={outOfStockConfiguration}
        loaderOn={loaderOn || spinnerOn}
      />
    </>
  );
}

export default AddToCartButton;
