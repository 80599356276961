import React, { useState } from "react";
import { getTabbyMinMaxLimit, getTamaraMinMaxLimit,TabbyGlobalEnable,TamaraGlobalEnable } from "../helpers/utils/handsakeConfig";
import tamara from "./../../assets/Icon/tamara.png";
import tamaraAR from "./../../assets/Icon/tamara-badge-ar.png";
import tabbybadge from "./../../assets/Icon/tabby-badge.png";
import { useEffect } from "react";

function TamaraLogoBox({ product }) {
  const price =
    product?.discounted_price_rounded === null
      ? product?.price_rounded
      : product?.discounted_price_rounded;

  const getTamaraCheck3Month = getTamaraMinMaxLimit(price, 3);
  const item_type = product?.item_type;

  const ecom = JSON.parse(localStorage.getItem("handShakeConfig"));
  
  const currentLanguageCode = localStorage.getItem("i18next") || "en";

  const [isDefaultActive, setDefaultActive] = useState(false);

  const [isTamara, setIsTamara] = useState(false);
  const [isTabby, setIsTabby] = useState(false);

  useEffect(() => {
    let checkTamara = product?.installmentMethod?.eligible_for_tamara;
    let checkTabby = product?.installmentMethod?.eligible_for_tabby;
    if (checkTamara && getTamaraCheck3Month === true && item_type === "simple" && TamaraGlobalEnable() === 1) {
      setIsTamara(true);
      setDefaultActive(true);
    }
    if (checkTabby && item_type === "simple" && TabbyGlobalEnable() === 1) {
      setIsTabby(true);
      setDefaultActive(true);
    }
  }, [product]);


  return (
    <React.Fragment>
      {isDefaultActive &&
        <>
          {ecom?.featureFlags?.ecom?.bootstraper?.tamara === 1 && isTamara && (
            <div className="tamara__logobox d-inline-block">
              <img
                src={currentLanguageCode !== "en" ? tamaraAR : tamara}
                alt="tamara"
              />
            </div>
          )}
          {ecom?.featureFlags?.ecom?.bootstraper?.tabby === 1 && isTabby && (
            <div className="tamara__logobox d-inline-block mx-2">
              <img src={tabbybadge} alt="tabby" />
            </div>
          )}
        </>
      }
    </React.Fragment>
  );
}

export default TamaraLogoBox;
