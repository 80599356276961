import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import "../../SCSS/_checkoutPage.scss";
import * as services from "../../services/services";
import { updateLoyaltyEarnTypes } from '../../services/cart.service';
import { loadConfigurationSettingData } from '../../redux/appAction';
import { getHandsakeConfig } from '../../Components/helpers/utils/handsakeConfig';

import MetaComponent from '../../Components/Common/MetaComponent';
// import ProcessingPayment from '../../Components/ProcessingPayment';
import useWindowDimensions from '../../Components/helpers/utils/useWindowDimensions';
import NewLoader from '../../Components/Common/newloader';

import ProcessingPayment from '../ProcessingPayment';
import { isApplePaySupported } from '../../Components/helpers/utils/applePay';
import { impressionEvents } from '../../Components/helpers/utils/ga/impressionEvents';
import CheckoutPayment from '../../Components/Checkout/CheckoutPayment';

const Payment = () => {
  const contact_prefill = localStorage.getItem('contact_prefill');
  const currentLanguageCode = localStorage.getItem('i18next') || 'en';
  const currentLang = localStorage.getItem('i18next') || 'en';

  let BASE_PAYMENT_REDIRECT_URL = process.env.REACT_APP_PAYMENT_REDIRECT_URL;
  if (currentLanguageCode === 'ar') {
    BASE_PAYMENT_REDIRECT_URL = BASE_PAYMENT_REDIRECT_URL + '/ar-sa';
  } else {
    BASE_PAYMENT_REDIRECT_URL = BASE_PAYMENT_REDIRECT_URL + '/en-sa';
  }

  const [custEmailAddress, setCustEmailAddress] = useState('');
  const [cartTotalData, setCartTotalData] = useState();
  const [ecom, setEcom] = useState('');
  const [mokafaApplied, setMokafaApplied] = useState(false);
  const [cartDetail, setCartDetail] = useState('');
  const [isAppleDevice, setIsAppleDevice] = useState(false);
  const [pending, setPending] = useState(false);
  const [redirectPageUrl, setRedirectPageUrl] = useState('');
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const { customerDetails } = useSelector((state) => state.customerReducer);
  const selectedLoyaltyPointsProgram = useSelector((state) => state.appData.selectedLoyaltyPointsProgram);
  const cartData = useSelector((state) => state.appData.cartData);
  const { configurationSettingData } = useSelector((state) => state.appData);
  const authRandom = useSelector((state) => state.appData.random);

  const [hideSection, setHideSection] = useState(false);
  const [tamara3MonthStatus, setTamara3MonthStatus] = useState(false);
  const [tamara6MonthStatus, setTamara6MonthStatus] = useState(false);
  const [showApplePay, setShowApplePay] = useState(false);
  //console.log('localStorageHandshakeToken',localStorageHandshakeToken);
  
  useEffect(() => {
    if (
      cartTotalData?.base_grand_total <
        configurationSettingData?.tamara_limits?.tamara3_max &&
      cartTotalData?.base_grand_total >
        configurationSettingData?.tamara_limits?.tamara3_min
    ) {
      setTamara3MonthStatus(true);
    }
    if (
      cartTotalData?.base_grand_total <
        configurationSettingData?.tamara_limits?.tamara6_max &&
      cartTotalData?.base_grand_total >
        configurationSettingData?.tamara_limits?.tamara6_min
    ) {
      setTamara6MonthStatus(true);
    }
  }, [cartData, configurationSettingData, cartTotalData]);

  useEffect(() => {
    dispatch(loadConfigurationSettingData());
    let getConfigData = getHandsakeConfig();
    if (getConfigData) {
      setEcom(getConfigData);
    }
    const checkApplePay = async () => {
      setIsAppleDevice(await isApplePaySupported());
    };
    checkApplePay();
  }, []);

  useEffect(() => {
    if (Object.values(cartData).length !== 0) {
      setCartTotalData(cartData.totals_data);
      setCartDetail(cartData);
      setLoading(false);
      window.scrollTo(0, 0);
    }
  }, [cartData]);

  useEffect(() => {
    if (customerDetails === '') {
      if (contact_prefill !== null) {
        setCustEmailAddress(JSON.parse(contact_prefill).email);
      }
    } else {
      setCustEmailAddress(customerDetails.email);
    }
  }, [customerDetails, contact_prefill]);

  useEffect(() => {
    if (cartDetail?.totals_data?.base_grand_total <= 0) {
      setHideSection(true);
    } else {
      setHideSection(false);
    }
  }, [cartDetail]);

  // TODO: not sure why we do this?
  useEffect(() => {
    if (selectedLoyaltyPointsProgram !== '') {
      let loyaltyParams = {
        type: selectedLoyaltyPointsProgram,
      };
      updateLoyaltyEarnTypes(loyaltyParams);
    }
  }, [selectedLoyaltyPointsProgram]);

  useEffect(() => {
    if (redirectPageUrl !== '') {
      navigate(redirectPageUrl);
      setRedirectPageUrl('');
    }
  }, [redirectPageUrl]);

  useEffect(() => {
    if (cartTotalData) {
      setMokafaApplied(Object.values(cartTotalData?.total_segments)?.map((item) => item.code).includes('mestores_alrajhi'));
    }
  }, [cartTotalData]);

  const checkVirtualItemsInCart = (items) => {
    let isVirtual = false;
    let checkVirtualItems =
      items && items.filter((val) => val.product_type === 'virtual');
    if (checkVirtualItems?.length > 0) {
      isVirtual = true;
    }
    return isVirtual;
  };

  useEffect(() => {
    impressionEvents.impressionGlobalVariable(currentLang, 'Payment Page');
  }, []);
  
  useEffect(()=>{
      const cartDataItem = cartData?.items;
      const shouldShowApplePay = cartDataItem?.some(product => product?.sku?.startsWith("ESD"));
      setShowApplePay(shouldShowApplePay);
  },[cartData])

  useEffect(() => {
    const isMac = navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
    if (isMac) {
      document.body.classList.add('mac-device');
    }
    return () => {
      document.body.classList.remove('mac-device');
    };
  }, [cartData]);

  if (loading && !authRandom) {
    return <>
      <NewLoader />
      {width > 992 ? <div className="container-fluid  breadcrumbs__block bg-transparent">
        <Skeleton width={"210px"} height="18px" />
        <br />
      </div> :
        <div className="px-3">
          <Skeleton width={"100%"} height="50px" />
        </div>
      }
      <div className={`container-fluid ${width > 992 ? "checkout__page__block" : "px-3"}`}>
        <div className="row checkout__page__inner__block">
          <div className="col-md-12 col-xl-8  checkout__left__block">
            <Skeleton width={"100%"} height="60px" />
            <Skeleton width={"100%"} height="400px" />
          </div>
          <div className="col-md-12 col-xl-4  checkout__right__block">
            <Skeleton width={"100%"} height="400px" />
          </div>
        </div>
      </div>
    </>
  }


  return (
    <>
      <MetaComponent
        title={t('Checkout')}
        meta_title={t('Checkout')}
        meta_keywords={t('Checkout')}
        meta_description={t('Checkout')}
      />
      {pending && <ProcessingPayment dispatch={dispatch} />}
      <div style={{ display: pending ? 'none' : 'block' }}>
        <CheckoutPayment
          t={t}
          pending={pending}
          width={width}
          authRandom={authRandom}
          BASE_PAYMENT_REDIRECT_URL={BASE_PAYMENT_REDIRECT_URL}
          cartTotalData={cartTotalData}
          ecom={ecom}
          mokafaApplied={mokafaApplied}
          cartDetail={cartDetail}
          checkVirtualItemsInCart={checkVirtualItemsInCart}
          currentLanguageCode={currentLanguageCode}
          loyaltyEarnTypeId={selectedLoyaltyPointsProgram}
          tamara3MonthStatus={tamara3MonthStatus}
          tamara6MonthStatus={tamara6MonthStatus}
          cartData={cartData}
          custEmailAddress={custEmailAddress}
          isAppleDevice={isAppleDevice}
          allStoreConfig={configurationSettingData}
          setRedirectPageUrl={setRedirectPageUrl}
          setPending={setPending}
          showApplePay={showApplePay}
          setCartTotalData={setCartTotalData}
          hideSection={hideSection}
          customerDetails={customerDetails}
          dispatch={dispatch}
          services={services}
          currentLang={currentLang}
        />
      </div>
    </>
  )
}

export default Payment