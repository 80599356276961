import React from "react";
import "./../../SCSS/_categoryBanner.scss";
import { defaultImageHelper } from "../helpers/utils/defaultImageHelper";
import ImageRender  from "../ImageRender"

const Category_Banner = (props) => {
  return (
    <>
      {props.bannerImage && props.bannerImage !== "" && (
        <div className="category__banner__container">
          <div className="category__banner__block">
            {/* <div className="banner__title">High Quality Televisions</div>
      <div className="banner__description">High Quality Televisions</div> */}
            {/* <div className="image__block"> */}
            <div className="banner__image__block">
              {props?.deeplinkUrl !== '' && props?.deeplinkUrl !== undefined && props?.deeplinkUrl !== null ?
                <a
                  href={props?.deeplinkUrl || "#"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ImageRender
                    preload={props?.LCP}
                    noLazy={true}
                    src={props.bannerImage}
                    alt={props?.image_id || "Banner"}
                    className="banner__image"
                    onError={(e) => defaultImageHelper(e)}
                  />
                </a> :
                <ImageRender
                  preload={props?.LCP}
                  noLazy={true}
                  src={props.bannerImage}
                  alt={props?.image_id || "Banner"}
                  className="banner__image"
                  onError={(e) => defaultImageHelper(e)}
                />
              }
            </div>
            {/* </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(Category_Banner);
