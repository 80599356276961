import React from 'react'
import { useTranslation } from 'react-i18next';
import Banner from '../Components/Banner'

function Support() {
  const { t } = useTranslation();
  return (
    <>
      <Banner />
      <div>{t("Support")}</div>
    </>
  )
}

export default Support