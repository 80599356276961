import apiHelper from "../../Components/helpers/utils/apiHelper";
import * as actionType from "../../redux/actionType";
import * as services from "../services";

export const getCustomerOrdersList = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionType.CUSTOMER_ORDER_LOADING_START,
      });
      let response = await apiHelper(
        `/V1/customer/orders?sort=${params.sort}&order=${params.order}&limit=${params.limit}&offset=${params.offset}`,
        "get",
        params,
        {}
      );
      dispatch(getCustomerOrdersListSuccess(response.data));
    } catch (error) {
    } finally {
      dispatch({
        type: actionType.CUSTOMER_ORDER_LOADING_STOP,
      });
    }
  };
};

// get addresses
export const getCustomerOrdersListSuccess = (data) => {
  return {
    type: actionType.CUSTOMER_ORDER_LIST,
    payload: data,
  };
};

export const getCustomerOrderDetails = (params) => {
  return async (dispatch) => {
    try {
      dispatch(services.loaderStart())
      let response = await apiHelper(
        `/V1/customer/orders/${params.id}/false`,
        "get",
        params,
        {}
      );
      dispatch(getCustomerOrderDetailsSuccess(response.data));
      dispatch(services.loaderEnd())
    } catch (error) {
      if (error.response.status === 404) {
        dispatch(
          services.notifyError({ message: error.response.data.message })
        );
        dispatch(services.loaderEnd())
      }
    }
  };
};


// get addresses
export const getCustomerOrderDetailsSuccess = (data) => {
  return {
    type: actionType.CUSTOMER_ORDER_DETAILS,
    payload: data,
  };
};
