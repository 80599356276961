import { createAppleBillingAddress, createBillingAddress, getEstimateShippingMethods, updateShippingInformation } from "../../services/cart.service";
import * as services from "../../services/services";

export const shippingAddressSubmit = async (data, dispatch) => {
    let shippingMethods;
    let shippingCarrierCode;
    if (!data.shippingCarrierCode) {
        shippingMethods = (await getEstimateShippingMethods())['shipping-methods'];
        shippingCarrierCode = shippingMethods
            ? shippingMethods['home-delivery']?.is_available
                ? shippingMethods['home-delivery'].shipping_carrier_code
                : shippingMethods['store-pickup']?.is_available
                    ? shippingMethods['store-pickup'].shipping_carrier_code
                    : 'flatrate'
            : 'flatrate';

        data.shippingCarrierCode = shippingCarrierCode;
    }

    await dispatch(updateShippingInformation(data))
    // if (user && data.save) {
    //     await services.createCustomerAddress({
    //         firstName: data.firstName,
    //         lastName: data.lastName,
    //         telephone: data.telephone,
    //         countryId: data.countryId,
    //         city: data.city,
    //         postCode: data.postCode,
    //         addressLine1: data.street,
    //         regionId: 0,
    //         primary: false,
    //     });
    // }
};

export const billingAddressSubmit = (data) => {
    return async (dispatch) => {
        try {
             await dispatch(createAppleBillingAddress(data));
            //   await services.createCustomerAddress( {
            //     //     firstName: data.firstName,
            //     //     lastName: data.lastName,
            //     //     telephone: data.telephone,
            //     //     countryId: data.countryId,
            //     //     city: data.city,
            //     //     postCode: data.postCode,
            //     //     addressLine1: data.addressLine1,
            //     //     regionId: 0,
            //     //     primary: false,
            //     //   });
            //     // }
        } catch (error) {
            console.error('Error submitting billing address:', error);
        }
    };
};

