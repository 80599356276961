import React, { useEffect, useState, useMemo } from 'react';
import * as services from './../services/services';
import { useDispatch, useSelector } from 'react-redux';
import htmlParseHelper from '../Components/helpers/utils/htmlParseHelper';
import {
    useNavigate
} from "react-router-dom";

import { cmsPageContentApiSuccess } from '../services/common/common';
import MetaComponent from '../Components/Common/MetaComponent';
import TealiumTag from '../Components/helpers/utils/tealium';
import SingleBanner from '../Components/SingleBanner';
import Banners from '../Components/Banners/Banners';

function CmsPage(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    let cmsId = props.cmsId;


    const { cmsPageContent } = useSelector((state) => state.commonReducer);
    const [details, setDetails] = useState('');

    useEffect(() => {
        dispatch(cmsPageContentApiSuccess(""));
        dispatch(services.loaderStart())
        dispatch(services.getCmsPageContentApi(cmsId)).then(() => {
            dispatch(services.loaderEnd())
        })
    }, [cmsId,dispatch]);

    // // avoid multiple render
    const getInfo = useMemo(() => {
        let de = cmsPageContent && cmsPageContent;
        return de;
    }, [cmsPageContent])

    useEffect(() => {
        if (getInfo.response && getInfo.response.status === 404) {
            dispatch(cmsPageContentApiSuccess(""));
            navigate('/');
        } else {
            setDetails(getInfo);
        }
    }, [cmsPageContent, dispatch, getInfo, navigate]);
    const imageStyle={display:'flex', justifyContent:'center'}
    return (
        <div style={{paddingLeft : '20px',paddingRight :  '20px'}}>
            <MetaComponent
                title={details.title !== undefined ? details.title : ""}
                meta_title={details.meta_title !== undefined ? details.meta_title : ""}
                meta_keywords={details.meta_keywords !== undefined ? details.meta_keywords : ""}
                meta_description={details.meta_description !== undefined ? details.meta_description : ""}
            />
            <TealiumTag name={details.title !== undefined ? details.title : ""} template={details.title !== undefined ? details.title : ""} />
            {details && details.content &&
                <>
                    {details.content.map((cmsItem, index) => {
                        switch (cmsItem.type) {
                            case 'rich_text':
                                return cmsItem.content !== "" ? <div className='cms_container' key={index}>{htmlParseHelper(cmsItem.content)}</div> : ""
                            case 'single_banner':
                                return <SingleBanner key={`cms_${index}`} index={index} bannerClick={()=>null} item={cmsItem} cmsStyle={imageStyle} />;
                            case 'multiple_banner':
                                return <Banners key={`cms_${index}`} items={cmsItem?.items} innerType={cmsItem?.inner_type} />;
                            // case 'category':
                            //     return <Category key={`cms_${index}`} data={cmsItem} screenName={identifier} currentLang={currentLang} categoryList={categoryList.children_data} catName={catData?.name} />;
                            default:
                                return null;
                        }
                    })}
                </>
            }
        </div>
    );
}
export default CmsPage;