import { dataLayerPush } from './dataLayerPush';

export const promoCodeEvents = {
  promoCodeFailed: (cartId, promoType, coupon, screenName) => {
    const data = {
      cart_id: cartId,
      promo_type: promoType,
      coupon,
      screen_name: screenName,
    };
    dataLayerPush({ event: 'promo_code_failed', ...data });
  },
  appliedPromoCode: (cartId, promoType, coupon, screenName) => {
    const data = {
      cart_id: cartId,
      promo_type: promoType,
      coupon,
      screen_name: screenName,
    };
    dataLayerPush({ event: 'promo_applied', ...data });
  },
};
