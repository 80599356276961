import React, { useState } from "react";

import black_left_arrow from "./../assets/Icon/black_left_arrow.svg";
import black_right_arrow from "./../assets/Icon/black_right_arrow.svg";
import "./../SCSS/_newArrival.scss";
import useWindowDimensions from "./helpers/utils/useWindowDimensions";
import ProductNine from "./ProductType/ProductNine";
import ProductSeven from "./ProductType/ProductSeven";

import ProductTwo from "./ProductType/ProductTwo";
function SimilarProducts({
  productDetailPage,
  sectionTitle,
  carouselData,
  productType,
  containerClassName,
  handleChangeCartPopup,
  updateCompareProduct,
  handleChangeProductPopup,
  redirectToPdpPage,
  containerClassNameForSlider,
  outOfStockConfiguration,
  screenName,
  productIdData,
  customerDetails
}) {

  const { width } = useWindowDimensions();
  // const [disableLeftArrow, setDisableLeftArrow] = useState(true);
  // const [disableRightArrow, setDisableRightArrow] = useState(false);
  const [arrowState, setArrowState] = useState(true);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const leftSide = () => {
    setArrowState(!arrowState);
    // setDisableRightArrow(false);
    document.querySelector(`.${containerClassNameForSlider}`).scrollLeft =
      document.querySelector(`.${containerClassNameForSlider}`).scrollLeft -
      document.querySelector(`.${containerClassNameForSlider}`).clientWidth -
      100;
    // let scroll = document.querySelector(`.${containerClassNameForSlider}`).scrollLeft;
  };
  const rightSide = (isMobile = false) => {
    setArrowState(!arrowState);
    // setDisableLeftArrow(false);
    if (
      !isMobile &&
      document.querySelector(`.${containerClassNameForSlider}`).clientWidth <
      document.querySelector(`.${containerClassNameForSlider}`).scrollLeft
    ) {
      document.querySelector(`.${containerClassNameForSlider}`).scrollLeft = 0;
      // document.querySelector(`.${containerClassNameForSlider}`).scrollLeft -
      // document.querySelector(`.${containerClassNameForSlider}`).clientWidth -
      // 100;
      let scroll = document.querySelector(`.${containerClassNameForSlider}`).scrollLeft;
    } else {
      document.querySelector(`.${containerClassNameForSlider}`).scrollLeft =
        document.querySelector(`.${containerClassNameForSlider}`).scrollLeft +
        document.querySelector(`.${containerClassNameForSlider}`).clientWidth -
        25;
    }
  };

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = (index, totalData) => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      if (isRightSwipe) {
        leftSide();
      } else {
        rightSide(true);
        if (totalData - 1 <= index) {
          document.querySelector(`.${containerClassName}`).scrollLeft = 0;
        }
      }
    }
    // add your conditional logic here
  };

  const isMobileLength = width <= 992 ? 1 : 4;

  return (
    <>
      {carouselData && (
        <div
          className={
            !productDetailPage
              ? "container-fluid carousel__container"
              : "carousel__container"
          }
        >
          <div
            className={
              !productDetailPage
                ? "carousel__block"
                : "less__width__carousel__block"
            }
          >
            <p className="section__title">{sectionTitle}</p>
            {carouselData?.length > isMobileLength ? (
              <div className="arrow left__arrow" onClick={leftSide}>
                <img src={black_left_arrow} alt="" />
              </div>
            ) : null}
            {carouselData?.length > isMobileLength ? (
              <div className="arrow right__arrow" onClick={rightSide}>
                <img src={black_right_arrow} alt="" />
              </div>
            ) : null}

            {productType === "productTwo" ? (
              <div className={containerClassName}>
                {carouselData.map((product, productIndex) => {
                  return (
                    <ProductTwo
                      handleChangeCartPopup={handleChangeCartPopup}
                      productDetailPage={productDetailPage}
                      key={product.id}
                      product={product}
                      originalData={product.originalData}
                      updateCompareProduct={updateCompareProduct}
                      outOfStockConfiguration={outOfStockConfiguration}
                      productIndex={productIndex}
                      screenName={screenName}
                    />
                  );
                })}
              </div>
            ) : productType === "productSeven" ? (
              <div className={containerClassName}>
                {carouselData.map((product, productIndex) => {
                  return (
                    <ProductSeven
                      handleChangeCartPopup={handleChangeCartPopup}
                      productDetailPage={productDetailPage}
                      key={product.id}
                      product={product}
                      originalData={product.originalData}
                      updateCompareProduct={updateCompareProduct}
                      outOfStockConfiguration={outOfStockConfiguration}
                      productIndex={productIndex}
                      screenName={screenName}
                      availablityData={product?.availablityData}
                    />
                  );
                })}
              </div>
            ) : productType === "productNine" ? (
              <div className={containerClassName}>
                {carouselData.map((product, productIndex) => {
                  return (
                    <ProductNine
                      key={product.id}
                      product={product}
                      handleChangeProductPopup={handleChangeProductPopup}
                      updateCompareProduct={updateCompareProduct}
                      handleChangeCartPopup={handleChangeCartPopup}
                      redirectToPdpPage={redirectToPdpPage}
                      onTouchStart={onTouchStart}
                      onTouchMove={onTouchMove}
                      onTouchEnd={() =>
                        onTouchEnd(productIndex, carouselData?.length)
                      }
                      outOfStockConfiguration={outOfStockConfiguration}
                      productIndex={productIndex}
                      screenName={screenName}
                      productIdData={productIdData}
                      availablityData={product?.availablityData}
                      customerDetails={customerDetails}
                    />
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(SimilarProducts);
