import React from "react";
import Text4 from "../Font/Text4";
import "./../../SCSS/ProductListPage/_plpBannerAndCategorySection.scss";
import PLPBannerSectionOne from "./PLPBannerSectionOne";

import white_tv from "./../../assets/Icon/white_tv.svg";
// import red_tv from "./../../assets/Icon/red_tv.svg";
import MetaComponent from "../Common/MetaComponent";
import TealiumTag from "../helpers/utils/tealium";
const PLPCategorySection = ({ selectedMainCategory, updateSelectedSubCategoryId, selectedCategoryId }) => {
  // const [selectedSubCategory, setSelectedSubCategory] = useState({});

  // useEffect(() => {
  //   if (selectedMainCategory) {
  //     setSelectedSubCategory(selectedMainCategory.children_data[0]);
  //   }
  // }, []);


  const onSelectCategory = (item) => {
    updateSelectedSubCategoryId(item)
    // setSelectedSubCategory(item);
    // setSelectedSubCategoryId(item.id)
  };

  const getMetaData = (selectedCategoryId, keyName) => {
    let returnData = ""
    if (selectedMainCategory.children_data !== undefined) {
      let checkData = selectedMainCategory.children_data.filter(item => item.id == selectedCategoryId);
      if (checkData.length > 0) {
        returnData = checkData[0][keyName];
      } else {
        returnData = selectedMainCategory[keyName];
      }
    }
    return returnData;
  }
  const getTealiumMetaData = (selectedCategoryId, keyName) => {
    let returnData = "category:"+getMetaData(selectedCategoryId, keyName)
    return returnData;
  }

  return (
    <>

      {selectedMainCategory !== undefined &&
        <>
          <MetaComponent
            title={getMetaData(selectedCategoryId, 'name')}
            meta_title={getMetaData(selectedCategoryId, 'meta_title')}
            meta_keywords={getMetaData(selectedCategoryId, 'meta_keywords')}
            meta_description={getMetaData(selectedCategoryId, 'meta_description')}
          />
          <TealiumTag name={getTealiumMetaData(selectedCategoryId, 'name')} template={getMetaData(selectedCategoryId, 'name')} />
          <PLPBannerSectionOne
            bannerImage={selectedMainCategory?.categorybanner}
            title={selectedMainCategory.name}
          />
          {selectedMainCategory?.children_data.length !== 0 ? (
            <div className="plp__main__category__section">
              <div className="plp__inner__category__section">
                <div className="plp__category__section">
                  {selectedMainCategory?.children_data.map((cat, catIndex) => {
                    return (
                      <div
                        key={cat.id}
                        className={
                          selectedCategoryId == cat.id
                            ? "selected__plp__category__block"
                            : "plp__category__block"
                        }
                        onClick={() => onSelectCategory(cat)}
                      >
                        <img
                          className="plp__category__image"
                          src={`${process.env.REACT_APP_PROJECT_MEDIA_API_URL}${cat.categoryicon}`}
                          onError={event => {
                            event.target.src = white_tv
                            event.onerror = null
                          }}
                          // src={
                          //   selectedCategoryId == cat.id
                          //     ? red_tv
                          //     : white_tv
                          // }
                          alt={cat.name}
                        />
                        <Text4
                          text={cat.name}
                          color={
                            selectedCategoryId == cat.id
                              ? "#DC3A1A"
                              : "#ffffff"
                          }
                        />
                      </div>

                      // <div
                      //   className={`category__section__block ${
                      //     selectedSubCategory.title === item.title ? "active__category" : ""
                      //   }`}
                      //   onClick={() => onSelectCategory(item)}
                      // >
                      //   <div
                      //     className={`category__section__image__block ${
                      //       selectedSubCategory.title === item.title ? "active__image" : ""
                      //     }`}
                      //   >
                      //     <img
                      //       className="category__section__image"
                      //       src={item.image}
                      //       alt="television"
                      //     />
                      //   </div>
                      //   <div className="category__section__title__block">
                      //     <p className="category__section__title">{item.title}</p>
                      //   </div>
                      // </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : ""}
        </>
      }
    </>
  );
};

export default React.memo(PLPCategorySection);
