import React from "react";
import "./../../SCSS/_paymentStatus.scss";
import "./../../SCSS/_confirmOrderPage.scss";
import Payment_Success_Page from "../Payment_Success_Page";
import TealiumTag from '../../Components/helpers/utils/tealium';

const TabbySummaryPage = () => {
  return (
    <>
        <TealiumTag name="thank_you" template="checkout" />
        <Payment_Success_Page />
    </>  
  );
};

export default TabbySummaryPage;