import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./../../SCSS/MobilePages/_mobileFilterModal.scss";
import "./../../SCSS/MobilePages/_mobileProductList.scss";
import gridunselect from "./../../assets/Icon/grid_unselected.svg";
import listunselect from "./../../assets/Icon/list_black.svg";
import MobileProductListGrid from "./Mobile_Product_List_Grid";
import MobileProductListListDetail from "./Mobile_Product_List_ListDetail";
import compareIcon from "./../../assets/Icon/compare-white.svg";
import filterIcon from "./../../assets/Icon/filter-white.svg";
import sortbyIcon from "./../../assets/Icon/sortby-white.svg";
import Heading5 from "../../Components/Font/Heading5";
import "./../../SCSS/ProductListPage/_facets.scss";
import ColorFacet from "../../Components/ProductListPageComponent/Facets/ColorFacet";
import PriceFacet from "../../Components/ProductListPageComponent/Facets/PriceFacet";
import GenreFacet from "../../Components/ProductListPageComponent/Facets/GenreFacet";
import HdmiFacet from "../../Components/ProductListPageComponent/Facets/HdmiFacet";
import ProcessorFacet from "../../Components/ProductListPageComponent/Facets/ProcessorFacet";
import OperatingSystemFacet from "../../Components/ProductListPageComponent/Facets/OperatingSystemFacet";
import ScreenSizeFacet from "../../Components/ProductListPageComponent/Facets/ScreenSizeFacet";
import MotionFlowFacet from "../../Components/ProductListPageComponent/Facets/MotionFlowFacet";
import InterfaceFacet from "../../Components/ProductListPageComponent/Facets/InterfaceFacet";
import MegaPixelsFacet from "../../Components/ProductListPageComponent/Facets/MegaPixelsFacet";
import AutoFocusFacet from "../../Components/ProductListPageComponent/Facets/AutoFocusFacet";
import { useTranslation } from "react-i18next";
import white_tv from "./../../assets/Icon/white_tv.svg";
import Text4 from "../../Components/Font/Text4";
import useInfiniteScroll from "../../lib/useInfiniteScroll";
import { DEFAULT_SORT } from './../../Components/helpers/utils/commonHelper';
import TypeFacet from "../../Components/ProductListPageComponent/Facets/TypeFacet";
import ColorResolutionFacet from "../../Components/ProductListPageComponent/Facets/ColorResolutionFacet";
import { notifyError } from "../../services/services";
import { useDispatch, useSelector } from "react-redux";
import { compareListUpdateSuccess } from "../../services/common/common";
import { FaChevronCircleLeft } from "react-icons/fa";

function Mobile_Product_List_Page({
  handleActiveBtnClass,
  onFilter,
  filteredProductsData = {},
  filterOptionData,
  // handleChangeProductPopup,
  // handleChangeComparePopup,
  updateCompareProduct,
  selectedMainCategory,
  updateSelectedSubCategoryId,
  selectedCategoryId,
  handleChangeCartPopup,
  filterInitData,
  handleClearFilter,
  loadMore,
  setPagNumber,
  isLoading,
  sortByPrice,
  categoryType,
  filterHide,
  outOfStockConfiguration,
  screenName,
  activeBtnName
}) {
  const { t } = useTranslation();
  const location = useLocation()

  const dispatch = useDispatch();
  const [productListView, setProductListView] = useState("grid");
  const [sortBy, setSortBy] = useState(DEFAULT_SORT);
  // const [selectedSubCategory, setSelectedSubCategory] = useState("");
  // const [selectedOption, setSelectedOption] = useState(DEFAULT_SORT);
  const [isDone, setIsDone] = useState(true);

  const { compareListUpdate } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    if (compareListUpdate) {
      dispatch(compareListUpdateSuccess(false));
    }
  }, [compareListUpdate]);

  useEffect(() => {
    // onFilter("order", sortBy);
    setSortBy(sortByPrice);
  }, [sortByPrice]);
  const onClickGridList = (type) => {
    if (type === "grid") {
      setProductListView("list");
    }
    if (type === "list") {
      setProductListView("grid");
    }
  };
  const comparePopupLocalData = JSON.parse(localStorage.getItem("compare_products")) || [];

  const onSelectSortby = (e) => {
    onFilter("order", sortBy);
    // setSelectedOption(sortBy);
  };
  const fetchMoreData = () => {
    if (
      (filteredProductsData?.total_count?.value || filteredProductsData?.total_count) === filteredProductsData?.items?.length
    ) {
      setIsDone(false);
      setPagNumber(1);
      return;
    } else {
      if (isLoading === true && (filteredProductsData?.total_count?.value || filteredProductsData?.total_count) !== 0) {
        loadMore();
      }
    }
  };

  const handleClickCompareBtn = (btnName) => {
    handleActiveBtnClass(btnName);
  };
  const onSelectCategory = (item) => {
    updateSelectedSubCategoryId(item);
    // setSelectedSubCategory(item);
    // setSelectedSubCategoryId(item.id)
  };
  // const constructParamFn = (item) => `${item.key}`;
  // useEffect(() => {
  //   if (selectedMainCategory) {
  //     setSelectedSubCategory(selectedMainCategory.children_data[0]);
  //   }
  // }, []);
  useInfiniteScroll(isDone, fetchMoreData);

  const handleCloseFilterModal = (e) => {
    document?.body?.removeAttribute('style');
  }

  return (
    <>
      <div className="container-fluid mb__filter__list">
        <div className="row" style={{ justifyContent: "flex-end" }}>
          {/* banner start */}
          <div className="row banner__section__one">
            <div className="col-12 col-md-7 banner__section__one__title__block">
              <p className="banner__section__one__title">
                {selectedMainCategory?.name}
              </p>
            </div>
            <div className="col-12 col-md-5 banner__section__one__image__block">
              <img
                className="banner__section__one__image"
                src={`${process.env.REACT_APP_PROJECT_MEDIA_API_URL}${selectedMainCategory?.categorybanner}`}
                alt={selectedMainCategory?.name}
              />
            </div>
          </div>
          {/* banner end */}

          {/* submenu start */}
          {selectedMainCategory?.children_data.length !== 0 ? (
            <div className="plp__main__category__section">
              <div className="plp__inner__category__section">
                <div className="plp__category__section">
                  {selectedMainCategory?.children_data.map(
                    (cat, catIndex) => {
                      return (
                        <div
                          key={cat.id}
                          className={
                            selectedCategoryId == cat.id
                              ? "selected__plp__category__block"
                              : "plp__category__block"
                          }
                          onClick={() => onSelectCategory(cat)}
                        >
                          <img
                            className="plp__category__image"
                            src={`${process.env.REACT_APP_PROJECT_MEDIA_API_URL}${cat.categoryicon}`}
                            onError={(event) => {
                              event.target.src = white_tv;
                              event.onerror = null;
                            }}
                            // src={
                            //   selectedCategoryId == cat.id
                            //     ? red_tv
                            //     : white_tv
                            // }
                            alt={cat.name}
                          />
                          <Text4
                            text={cat.name}
                            color={
                              selectedCategoryId == cat.id
                                ? "#DC3A1A"
                                : "#ffffff"
                            }
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* submenu end */}
          {(filteredProductsData?.total_count?.value || filteredProductsData?.total_count) !== 0 ?
            <>
              {/* col end */}
              <div className="col-5 col-sm-4 text-center">
                <div
                  className={
                    productListView === "list"
                      ? "grid__button mb__grid__active d-flex align-items-center justify-content-center h-100"
                      : " grid__button"
                  }
                  onClick={() => onClickGridList("list")}
                >
                  <img src={gridunselect} alt="grid" className="me-2" />
                  <strong>{t("Grid_View")}</strong>
                </div>
                <div
                  className={
                    productListView === "grid"
                      ? "grid__button mb__grid__active d-flex align-items-center justify-content-center h-100"
                      : "grid__button  "
                  }
                  onClick={() => onClickGridList("grid")}
                >
                  <img src={listunselect} alt="grid" className="me-2" />
                  <strong>{t("List_View")}</strong>
                </div>
                {isLoading === false && isDone === true ? (
                  <h4 className="text-center">Loading... </h4>
                ) : (
                  ""
                )}
              </div>
              {/* list detail end */}
              <div
                className={productListView === "grid" ? " col-12" : " d-none"}
              >
                <MobileProductListGrid
                  handleChangeCartPopup={handleChangeCartPopup}
                  filteredProductsData={filteredProductsData}
                  updateCompareProduct={updateCompareProduct}
                  outOfStockConfiguration={outOfStockConfiguration}
                  screenName={screenName}
                />
              </div>
              <div
                className={productListView === "list" ? " col-12" : " d-none"}
              >
                <MobileProductListListDetail
                  handleChangeCartPopup={handleChangeCartPopup}
                  updateCompareProduct={updateCompareProduct}
                  filteredProductsData={filteredProductsData}
                  outOfStockConfiguration={outOfStockConfiguration}
                  screenName={screenName}
                />
              </div>
            </>
            :
            <div className="PLP_dsk_noProduct_box">
              <h1>{t("No_Products_Available")}</h1>
            </div>
          }

        </div>
        {isLoading === false && isDone === true ? (
          <h4 className="text-center">Loading... </h4>
        ) : (
          ""
        )}
      </div>
      {/* list detail end */}
      {(filteredProductsData?.total_count?.value || filteredProductsData?.total_count) !== 0 &&
        filteredProductsData?.items?.length !== 0 && (
          <div
            className={`mb__product__list__bottom__filter ${activeBtnName === "compare" ? "campare__btn__active" : ""
              }`}
          >
            <ul className="ps-0">
              <li className="">
                <button
                  className=" btn btn__primary__black "
                  onClick={() => handleClickCompareBtn("compare")}
                >
                  <img src={compareIcon} alt="compare" />
                  {t("Compare")}
                </button>
              </li>
              <li>
                <button
                  className=" btn btn__primary__orange"
                  data-bs-toggle="modal"
                  data-bs-target="#filterByModal"
                >
                  <img src={filterIcon} alt="Filter" />
                  {t("Filter")}
                </button>
              </li>
              <li>
                <button
                  className=" btn btn__primary__orange"
                  data-bs-toggle="modal"
                  data-bs-target="#SortByModal"
                >
                  <img src={sortbyIcon} alt="Filter" />
                  {t("Sort_By")}
                </button>
              </li>
            </ul>
            {activeBtnName &&
              <button className=" btn btn__primary__black plp_compare_back_btn" onClick={(e) => handleClickCompareBtn("")}>
                <FaChevronCircleLeft />
              </button>
            }
            {comparePopupLocalData && comparePopupLocalData.length >= 2 ?
              <Link to="/products/compare" className=" btn btn__primary__black mb_compare_pro_btn">
                <img src={compareIcon} alt="compare" />
                {t("Compare_Products")}
              </Link>
              :
              <button className=" btn btn__primary__black mb_compare_pro_btn" onClick={(e) => dispatch(notifyError({ message: t('Please_select_at_least_2_products_to_compare') }))}>
                <img src={compareIcon} alt="compare" />
                {t("Compare_Products")}
              </button>
            }
          </div>
        )}

      {/* filter modal start */}

      {/* <!-- Modal --> */}
      <div
        className="modal mb__bottom_popup mb__filter__related__popup"
        id="filterByModal"
        tabIndex="-1"
        aria-labelledby="filterByModalLabel"
        aria-hidden="true"
        onClick={(e) => handleCloseFilterModal(e)}
      >
        <div className="modal-dialog mb__dialog__end modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <Heading5 text="Filter By" />
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p
                className={`main__facets__clear_filter`}
                onClick={() => {
                  if (location?.search) {
                    handleClearFilter()
                  }
                }}
              >
                {t("clear_filter")}
              </p>
              {/* <Heading6 text="Sort By" />
              <SortByFacet onFilter={onFilter} mobileView={true} /> */}
              {filterOptionData?.facets?.priceRange.max > 0 ? (
                <>
                  {(filterOptionData?.total_count?.value || filterOptionData?.total_count) !== 0 &&
                    filterOptionData &&
                    Object.keys(filterOptionData.facets).map(
                      (key, index) => {
                        return (
                          <div key={`facet_group_${key}${index}`}>
                            {/* {key === "brand" && !filterHide && (
                            <BrandFacet
                              onFilter={onFilter}
                              facetData={filterOptionData.facets}
                              facetKey={key}
                              values={filterInitData?.brand}

                            />
                          )} */}
                            {key === "price" && (
                              <PriceFacet
                                onFilter={onFilter}
                                facetData={filterOptionData.facets}
                                facetKey={key}
                                values={filterInitData?.display_price}
                              />
                            )}
                            {key === "type" && categoryType === "Televisions" && (
                              <TypeFacet
                                onFilter={onFilter}
                                facetData={filterOptionData.facets}
                                facetKey={key}
                                values={filterInitData?.type}
                              />
                            )}
                            {key === "color_resolution" && categoryType === "Televisions" && (
                              <ColorResolutionFacet
                                onFilter={onFilter}
                                facetData={filterOptionData.facets}
                                facetKey={key}
                                values={filterInitData?.color_resolution}
                              />
                            )}
                            {key === "color" && !filterHide && (
                              <ColorFacet
                                onFilter={onFilter}
                                facetData={filterOptionData.facets}
                                facetKey={key}
                                values={filterInitData?.color}
                              />
                            )}
                            {key === "genre" && !filterHide && (
                              <GenreFacet
                                onFilter={onFilter}
                                facetData={filterOptionData.facets}
                                facetKey={key}
                              />
                            )}
                            {key === "hdmi_input" && !filterHide && (
                              <HdmiFacet
                                onFilter={onFilter}
                                facetData={filterOptionData.facets}
                                facetKey={key}
                                values={filterInitData?.hdmi_input}
                              />
                            )}
                            {key === "processor" && !filterHide && (
                              <ProcessorFacet
                                onFilter={onFilter}
                                facetData={filterOptionData.facets}
                                facetKey={key}
                                values={filterInitData?.processor}
                              />
                            )}
                            {key === "operating_system" && !filterHide && (
                              <OperatingSystemFacet
                                onFilter={onFilter}
                                facetData={filterOptionData.facets}
                                facetKey={key}
                                values={filterInitData?.operating_system}
                              />
                            )}
                            {key === "screen_size" &&
                              categoryType === "Televisions" && (
                                <ScreenSizeFacet
                                  onFilter={onFilter}
                                  facetData={filterOptionData.facets}
                                  facetKey={key}
                                  values={filterInitData?.screen_size}
                                />
                              )}
                            {key === "motionflow" && !filterHide && (
                              <MotionFlowFacet
                                onFilter={onFilter}
                                facetData={filterOptionData.facets}
                                facetKey={key}
                                values={filterInitData?.motionflow}
                              />
                            )}
                            {key === "interface" && !filterHide && (
                              <InterfaceFacet
                                onFilter={onFilter}
                                facetData={filterOptionData.facets}
                                facetKey={key}
                                values={filterInitData?.interface}
                              />
                            )}
                            {key === "megapixels" && !filterHide && (
                              <MegaPixelsFacet
                                onFilter={onFilter}
                                facetData={filterOptionData.facets}
                                facetKey={key}
                                values={filterInitData?.megapixels}
                              />
                            )}
                            {key === "autofocus_type" && !filterHide && (
                              <AutoFocusFacet
                                onFilter={onFilter}
                                facetData={filterOptionData.facets}
                                facetKey={key}
                                values={filterInitData?.autofocus_type}
                              />
                            )}
                          </div>
                        );
                      }
                    )}
                </>
              ) : (
                ""
              )}

              {/* <Heading6 text="Price" />
              <ul className="d-flex list-unstyled">
                <li className="mb-2">
                  <input type="checkbox" id="SAR1" />
                  <label className="ms-2" htmlFor="SAR1">
                    {t("SAR1_SAR2")}
                  </label>
                </li>
                <li className="mb-2">
                  <input type="checkbox" id="Under" />
                  <label className="ms-2" htmlFor="Under">
                    {t("Under_SAR1,000")}
                  </label>
                </li>
              </ul> */}
            </div>
            <div className="modal-footer mb__static__btn__group border-top-0">
              <button
                type="button"
                className="btn btn__grey__solid mb-2"
                data-bs-target="#filterByModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                {t("cancel")}
              </button>
              <button
                type="button"
                className="btn__primary__orange btn btn-primary mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {t("apply")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal end--> */}

      {/* sort by modal start */}
      {/* <!-- Modal --> */}
      <div
        className="modal mb__bottom_popup mb__filter__related__popup"
        id="SortByModal"
        tabIndex="-1"
        aria-labelledby="SortByModalModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mb__dialog__end modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <Heading5 text={`${t("Sort_By")}`} />
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <input
                type="radio"
                id="age1"
                name="sortBy"
                value="asc"
                checked={sortBy === "asc"}
                onChange={() => setSortBy("asc")}
              />
              <label htmlFor="sortBy">{t("Low_to_High")}</label>
              <input
                type="radio"
                id="age2"
                name="sortBy"
                value="desc"
                checked={sortBy === "desc"}
                onChange={() => setSortBy("desc")}
              />
              <label htmlFor="sortBy">{t("High_to_Low")}</label>
              {/* <li className="mb-2">
                  <input type="checkbox" id="plh" />
                  <label className="ms-2" htmlFor="plh">
                    Price -- Low to High
                  </label>
                </li>
                <li className="mb-2">
                  <input type="checkbox" id="n" />
                  <label className="ms-2" htmlFor="n">
                    Newest First
                  </label>
                </li>
                <li className="mb-2">
                  <input type="checkbox" id="c" />
                  <label className="ms-2" htmlFor="c">
                    Price -- High to Low
                  </label>
                </li>
                <li className="mb-2">
                  <input type="checkbox" id="e" />
                  <label className="ms-2" htmlFor="e">
                    Popularity
                  </label>
                </li>
                <li className="mb-2">
                  <input type="checkbox" id="a" />
                  <label className="ms-2" htmlFor="a">
                    Via Customer Reviews
                  </label>
                </li> */}
            </div>
            <div className="modal-footer mb__static__btn__group border-top-0">
              <button
                type="button"
                className="btn btn__grey__solid mb-2"
                data-bs-target="#SortByModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                {t("cancel")}
              </button>
              <button
                type="button"
                className="btn__primary__orange btn btn-primary mb-3"
                onClick={onSelectSortby}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {t("apply")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal end--> */}
    </>
  );
}

export default React.memo(Mobile_Product_List_Page);