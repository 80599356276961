import React from 'react'
import { useTranslation } from 'react-i18next';
import "./../SCSS/_threeProducts.scss"

function ThreeProducts({ threeProductsData }) {
  const { t } = useTranslation()
  return (
    <div className="container-fluid three__products__container">
      <div className="three__products__block">
        <div className="row three__products__row">
          {threeProductsData.slice(0, 3).map((product, productIndex) => {
            return (
              <div key={product.id} className="col-12 col-sm-4 col-md-4 three__product__block">
                <div className="ineer__three__product__block">
                  <div className="three__product__image__block">
                    <img
                      src={product.image}
                      alt=""
                      className="three__product__image"
                    />
                  </div>
                  <p className="three__product__name">
                    {product.productName}
                  </p>
                  <p className="three__product__description">
                    {t("Find_out_Why_This_Is_the_Go_To_Lens_for_So_many_Photographers")}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  )
}

export default ThreeProducts