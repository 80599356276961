import axios from "axios";
import { setHeader } from "./config";

export const getHandshake = async () => {
  const hostname = window?.location?.hostname;
  const languageCodeFromUrl = hostname?.split('-')?.[0];
  const currentLanguageCode = languageCodeFromUrl || localStorage.getItem("i18next") || "en";
  let storeLng = currentLanguageCode === "en" ? "sony_en" : "sony_ar";
  try {
    setHeader("x-device-os-type", "web");
    setHeader("x-app-version", "1.4.1");
    return await axios.post(
      `${process.env.REACT_APP_PROJECT_API_URL}/V1/handshake`,
      {
        store: storeLng,
        // store: "sony_ar",
      }
    );
    // return res
    // res.then(res=>console.log(res))
    // if (res.data && res.data.token) {
    //   setHeader("X-Access-Token", res.data.token);
    //   return res.data;
    // }
  } catch (e) {
    // console.log(e, "error in handshake");
  }
};

export const getHandshakeAfterLngChange = async (lagCode, prevLng) => {
  let storeLng = lagCode === "en" ? "sony_en" : "sony_ar";
  const localStorageHandshakeToken = localStorage.getItem("handShakeToken");
  try {
    setHeader("x-device-os-type", "web");
    setHeader("x-app-version", "1.4.1");
    if (lagCode !== prevLng) {
      setHeader("X-Access-Token", localStorageHandshakeToken);
    } else {
      setHeader("X-Access-Token", "");
    }
    return await axios.post(
      `${process.env.REACT_APP_PROJECT_API_URL}/V1/handshake`,
      {
        store: storeLng,
      }
    );
  } catch (e) {
    // console.log(e, "error in handshake");
  }
};

export const refreshHandshake = async () => {
  await axios.post(
    `${process.env.REACT_APP_PROJECT_API_URL}/V1/handshake/refresh`,
    {
      store: "sony_en",
      //   store: "en",
    }
  );
};
