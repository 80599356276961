import React from 'react';
import "./../../SCSS/_loader.scss";
// import loaderImg from "./../../assets/Icon/loader.gif";
// import logoLoding from "./../../assets/Logo/navbar_logo.png";

function NewLoader() {

    return (
        <>
            <div className="text-center loader-mainbox">
                <div className="loader-box" >
                    {/* <h3>Sony World</h3> */}
                    {/* <img src={logoLoding} />                     */}
                    {/* <div className='loading_line'></div> */}
                    {/* <img src={loaderImg} /> */}
                </div>
            </div>
        </>
    );
}

export default NewLoader;