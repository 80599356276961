import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../Components/BreadCrumbs";
import Heading6 from "./../Components/Font/Heading6";
import Heading7 from "./../Components/Font/Heading7";
import Text4 from "./../Components/Font/Text4";
import left_location from "./../assets/Icon/left_location.svg";
import right_location_pin from "./../assets/Icon/right_location_pin.svg";
import "./../SCSS/_sonyStorePage.scss";
import find_store_banner from "./../assets/SonyStore/find_store_banner.jpg";
import find_service_center_banner from "./../assets/SonyStore/find_service_center_banner.jpg";
import find_service_center_banner_arabic from "./../assets/SonyStore/find_service_center_banner_arabic.jpg";
import find_store_banner_arabic from "./../assets/SonyStore/find_store_banner_arabic.jpg";
import { loadCitiesLocationData, loadEnableStoresLocationData } from "../redux/appAction";
import { loadCountriesLocationData } from "../redux/appAction";
import Select from "react-select";
import { useLoadScript } from '@react-google-maps/api';
import Store_Map from "./StorePages/Store_Map";
import NewLoader from "../Components/Common/newloader";
import { useTranslation } from "react-i18next";
import MetaComponent from "../Components/Common/MetaComponent";
import { useLocation } from "react-router-dom";
import TealiumTag from "../Components/helpers/utils/tealium";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Sony_Store_Page() {

  const query = useQuery();
  const getId = query.get("id");

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY // Add your API key
  });

  const [location, setLocation] = useState({
    province: "",
    city: "",
    area: "",
  });

  const [storeCitiesLocationData, setStoreCitiesLocationData] = useState();
  const [storeCountriesLocationData, setStoreCountriesLocationData] =
    useState();
  const [storeStoresLocationData, setStoreStoresLocationData] = useState([]);
  const [filterStoreList, setFilterStoreList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const handleChange = (e, keyName) => {
    // let value = e.target.value
    // let name = e.target.name
    setLocation({ ...location, [keyName]: e })
  };

  // cities //

  const dispatch = useDispatch();

  const storesLocationData = useSelector(
    (state) => state.appData.storesEnableLocationData
  );

  useEffect(() => {
    if (storeStoresLocationData) {
      let dropCityData = [
        {
          label: t("Select_City"),
          value: ""
        }
      ];
      const cities = [...new Set(storeStoresLocationData && storeStoresLocationData.map(store => store.city))];
      cities && cities.map((val, i) => {
        dropCityData.push({
          label: val,
          value: val
        })
      })
      setStoreCitiesLocationData(dropCityData);
      setLoading(false);
      window.scrollTo(0, 0);
    }
  }, [storeStoresLocationData]);

  // countries //

  useEffect(() => {
    dispatch(loadCountriesLocationData());
  }, []);

  const countriesLocationData = useSelector(
    (state) => state.appData.countriesLocationData
  );

  useEffect(() => {
    if (countriesLocationData) {
      let dropCountriesData = [
        {
          label: t("Select_Province"),
          value: ""
        }
      ];
      countriesLocationData && countriesLocationData.map((val, i) => {
        dropCountriesData.push({
          label: val.full_name_locale,
          value: val.id
        })
      })
      setStoreCountriesLocationData(dropCountriesData);
      setLoading(false);
      window.scrollTo(0, 0);
    }
  }, [countriesLocationData]);

  // stores //

  useEffect(() => {
    dispatch(loadEnableStoresLocationData());
  }, []);

  useEffect(() => {
    if (storesLocationData) {
      let onlyStoreList = [];
      if (getId === "stores" || getId === null) {
        onlyStoreList = storesLocationData && storesLocationData.filter(item => item?.is_store === true);
      } else if (getId === "centers") {
        onlyStoreList = storesLocationData && storesLocationData.filter(item => item.is_service_center === true);
      }
      // let onlyStoreList = storesLocationData && storesLocationData.filter(item => item.is_service_center === false);
      setStoreStoresLocationData(onlyStoreList);
      setFilterStoreList(onlyStoreList);
      setLoading(false);
      window.scrollTo(0, 0);
    }
  }, [storesLocationData, getId]);

  const onLocationSearch = () => {
    let provinceValue = location.province?.value !== undefined ? location.province?.value : ""
    let cityValue = location.city?.value !== undefined ? location.city?.value : ""
    let getFilterStore = storeStoresLocationData
    if (storeStoresLocationData && storeStoresLocationData.length > 0) {
      if (provinceValue !== "" && cityValue === "") {
        getFilterStore = storeStoresLocationData.filter((val, i) => val.country === provinceValue);
      } else if (cityValue !== "" && provinceValue === "") {
        getFilterStore = storeStoresLocationData.filter((val, i) => val.city === cityValue);
      } else if (cityValue !== "" && provinceValue !== "") {
        getFilterStore = storeStoresLocationData.filter((val, i) => val.country === provinceValue && val.city === cityValue);
      }
      setFilterStoreList(getFilterStore);
    }
  }

  const handleCheckLocationInfo = (locationInfo) => {
    setSelectedLocation(locationInfo)
  }

  if (loading) {
    return (
      <NewLoader />
    );
  }

  let firstBreadTitle = getId === "stores" || getId === null ? t("Sony_Store") : t("Sony_Service_Centers")
  const currentLang = localStorage.getItem("i18next") || "en";

  let defaultBannerImg = find_store_banner;
  if (getId === "stores" || getId === null) {
    if (currentLang === "en") {
      defaultBannerImg = find_store_banner
    } else {
      defaultBannerImg = find_store_banner_arabic
    }
  } else {
    if (currentLang === "en") {
      defaultBannerImg = find_service_center_banner
    } else {
      defaultBannerImg = find_service_center_banner_arabic
    }
  }
  return (
    <>
      <MetaComponent
        title={firstBreadTitle}
        meta_title={firstBreadTitle}
        meta_keywords={firstBreadTitle}
        meta_description={firstBreadTitle}
      />
      <TealiumTag name={firstBreadTitle} template={firstBreadTitle} />
      <BreadCrumbs back="/" firstBread="findstore" firstBreadTitle={firstBreadTitle} highlight="firstBreadTitle" />
      <div className="container-fluid sony__store__page__container">
        <div className="sony__store__page__block">
          <div className="sony__store__page__banner__block">
            <img
              className="sony__store__page__image"
              src={defaultBannerImg}
              alt=""
            />
          </div>
          <div className="row location__search__block">
            {/* <div className="col-md-3 select__field__block">
              <Heading7 text={t("Select_Province")} marginBottom={5} />
              <Select
                placeholder={t("Select_Province")}
                id="province"
                name="province"
                onChange={(e) => handleChange(e, 'province')}
                value={location.province}
                options={storeCountriesLocationData}
              />
            </div> */}
            <div className="col-md-3 select__field__block">
              <Heading7 text={t("Select_City")} marginBottom={5} />
              <Select
                placeholder={t("Select_City")}
                id="city"
                name="city"
                onChange={(e) => handleChange(e, 'city')}
                value={location.city}
                options={storeCitiesLocationData}
              />
            </div>
            {/* <div className="col-md-3 select__field__block">
              <Heading7 text={t("Select_Area")} marginBottom={5} />
              <Select
                placeholder={t("Select_Area")}
                id="area"
                name="area"
                onChange={(e) => handleChange(e, 'area')}
                value={location.area}
                options={areaOptions}
              />
            </div> */}
            <div className="col-md-3">
              <button className="search__button" onClick={() => onLocationSearch()}>{t("Search")}</button>
            </div>
          </div>
          <div className="sony__store__page__map__block">
            <div className="row inner__sony__store__page__map__block">
              <div className="col-md-5 col-lg-4 map__location__list__block">
                {filterStoreList && filterStoreList.length > 0 ?
                  filterStoreList.map((storesget) => {
                    return (
                      <div className="location__block" key={storesget.id}>
                        <img src={left_location} alt="" />
                        <div className="location__info__block">
                          <div className="location__info__text">
                            <Heading6 text={storesget.name} color="#DC3A1A" marginBottom={5} />
                            <Heading7 text={`${t("Address")} : `} />
                            <Text4 text={storesget.address} />
                            <Heading7 text={`${t("Phone")} : `} />
                            <a className="footer__mobile" href={`tel:${storesget.phoneNumber}`}>
                              {storesget.phoneNumber}
                            </a>
                          </div>
                          <img src={right_location_pin} alt="" onClick={() => handleCheckLocationInfo(storesget)} className="store_find_location" />
                        </div>
                      </div>
                    );
                  })
                  :
                  (
                    <Heading6 text={t('No_store_available')} color="#DC3A1A" />
                  )
                }
              </div>
              <div className="col-md-7 col-lg-8">
                <div className="map__location__area">
                  {isLoaded ? <Store_Map filterStoreList={filterStoreList} selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sony_Store_Page;
