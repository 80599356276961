import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import icons_discount from './../assets/Icon/icons-discount.svg';
import dummy_product from './../assets/Product/product_02.png';
import * as services from "./../services/services";
import "./../SCSS/BundleView.scss";
import { bundleCart } from "../services/cart.service";
// import { LoaderIcone } from "../Pages/Loader";
import NewLoader from "./Common/newloader";
import { useNavigate } from "react-router-dom";
import Loader from './Common/loader.js';
import ImageRender from "./ImageRender";
import { useDispatch } from "react-redux";
import { loadCartData } from "../redux/appAction";
import { Spinner } from "./helpers/utils/spinner";
const BundleView = ({ t,parentSku, bundle_products, redirectBundlePage, product, isMobile }) => {
  let indexing = 2;
  const [bundleArray,setBundleArray] = useState([]);
  const [discountArray,setDiscountArray] = useState([]);
  const [prices,setPrices] = useState(null);
  const [isShowPrices,setIsShowPrices] = useState(false);
  const [isBundleAddedToCart,setIsbundleAddedToCart] = useState(false);
  const [btnLoading,setBtnLoading] = useState(false);


  const navigate = useNavigate();

  const [loaderOn, setLoaderOn] = useState(false);
  const [numButton, setNumButton] = useState(0);
  const [bundleStatus, setBundleStatus] = useState(0);
  const [btnText, setBtnText] = useState('');

  useEffect(()=>{
   if(bundleArray.length){
    setNumButton(bundleArray.length);
   }
  }, [bundleArray]);
  const dispatch = useDispatch();
  useEffect(()=>{
     if(bundle_products){
       if(bundle_products[0]){
        setBundleArray(bundle_products[0].items);
        setBundleStatus(bundle_products[0].conflict_status);

        const discountBundledProductDetail = bundle_products[0].discountBundle;
        setDiscountArray(discountBundledProductDetail);
        const itemAddedTocart = discountBundledProductDetail.filter((item) => {
          return item.item_id !== 0;
        });
        setIsbundleAddedToCart(itemAddedTocart.length === discountBundledProductDetail.length ? true : false);
        if(numButton === bundle_products[0].discountBundle.length){
          setIsShowPrices(true);
          showDiscount(bundle_products[0].discountBundle);
        }else{
          var checkboxes = document.getElementsByName('bundle[]');
          let data = [];
          const bundleData = bundle_products[0].discountBundle;
          for (var i=0, n=checkboxes.length;i<n;i++) 
          {
            const value = checkboxes[i].value;
      
            if (checkboxes[i].checked) 
            {
              const productData = bundleData.find(e => e.product_id  === value.toString());
              data.push(productData);
            }
          }  
          showDiscount(data);
          setIsShowPrices(false);

        }
      }
     }
  },[bundle_products, numButton]);
  useEffect(()=>{
    if(bundleStatus === 0) {
      setBtnText('add_selected_to_cart');
    } else if(bundleStatus === 1){
      setBtnText('added_to_cart');

    } else if (bundleStatus === 2){
      setBtnText('your_cart_has_bundle');

    }
  }, [bundleStatus]);
  const showDiscount = (discountData) => {
    var totalPrice = 0;
    var discountPrice = 0;
    var afterDiscountPrice = 0;

    discountData.forEach(element => {
      totalPrice += parseFloat(element.base_price);
      discountPrice += parseFloat(element.discount_price);
      afterDiscountPrice += parseFloat(element.after_discount_price);
    });
    setPrices({totalPrice:totalPrice,discountPrice:discountPrice,afterDiscountPrice:afterDiscountPrice});
  } 

  const ValueChecked = (selectedItem = null) => {
    var checkboxes = document.getElementsByName('bundle[]');
    let isChecked = false;
    var checkedItem = 0;
    for (var i=0, n=checkboxes.length;i<n;i++) 
    {
      if (checkboxes[i].checked) 
      {
        isChecked = true;
        checkedItem++;
        // break;
      }
    }
    // console.log('checkedItemcheckedItem', checkedItem);
    setNumButton(checkedItem);
    if(selectedItem === true){
      return checkedItem;
    }
    if(isChecked === true) {
      
    }
  }
  const cartProduct = () => {
    setLoaderOn(true);
    setBtnLoading(true);
    var checkboxes = document.getElementsByName('bundle[]');
    let data = {items:[]};
    let checkedStatus = [];
    for (var i=0, n=checkboxes.length;i<n;i++) 
    {
      const value = checkboxes[i].value;
      const productData = bundleArray.find(e => e.id  === value);

      // if (checkboxes[i].checked) 
      // {
        data.items.push(
          {
            id:productData.id,
            sku:productData.sku,
            qty:1,
            extension_attributes: {
              bundle_key: parentSku
            }
          }
        );
        checkedStatus.push(
          {
            sku:productData.sku,
            status : true
          }
        );
      // } else {
      //   checkedStatus.push(
      //     {
      //       sku: productData.sku,
      //       status : false
      //     }
      //   );
      // }
    }
    data.detail = {
      bundle_key: parentSku,
      checkedStatus:checkedStatus
    };
    bundleCart(data)
    .then((res) => {
      // setCount(res.data.filter((pro) => pro.sku === product?.sku)[0].qty);
      dispatch(loadCartData());
      setBtnLoading(false);

      navigate("/cart");

    
    })
    .catch((err) => {
      dispatch(services.notifyError({ message: err.response.data.message }));
    })
    .finally(() => {
      setLoaderOn(false);
    });
  }

  // console.log('bundleArray.length', bundleArray.length);
  if(bundleArray.length && bundleStatus!==3){
    return (
      <div className="pd__bundle__block" style={{ display: bundleArray.length < 3 && 'inline-block' }}>
        <div className="pd__bundle__title__block">
          <img
            src={icons_discount}
            alt=""
            className="pd__bundle_title_icon"
          />
          <span>{t("Frequently bought together")}</span>
        </div>
        <div className="row pd__bundle_item__block">
          {bundleArray.map((item,index) => (
            <div className={bundleArray.length === 2 ? "col-6 col-md-6" : (bundleArray.length === 1 ? "col-6 col-md-10" : "col-6 col-md-4")}>
              <div className={`pd__bundle_img__block ${index === indexing ? `${indexing=indexing+3} removeclass` : ''} ${index=== bundleArray.length - 1  ? 'removeclass' : ''}`}>
                <input type="hidden" name="bundle[]" value={item?.id} />
                {/* <input className="pd__bundle_checkbox bundleProductCheckbox" onChange={ValueChecked} defaultChecked={true} name="bundle[]" value={item?.id} type="checkbox" id={`checkbox_${item?.id}`} /> */}
                <label for={`checkbox_${item?.id}`}>
                  <ImageRender
                    src={item?.baseImage}
                    alt=""
                    className={`pd__bundle_item_img`}
                    height= "65px"
                    width= "80px"
                    />
                </label>
              </div>
              <p className="pd__bundle_img__desc">{item?.name}</p>
              <div className="pd__bundle_img__price">{
                               item?.discounted_price_rounded === null
                                ? item?.price_rounded
                                : item?.discounted_price_rounded} Sar </div>
            </div>
          ))}
        </div>
    
        {
          numButton > 0 && (
          <div>
            <p className="d-flex justify-content-center" style={{marginTop:"14px",marginBottom:"5px"}}>
              <b>Total:<span className={ numButton === discountArray.length ? 'cross-line' : '' } >{ Math.round(prices?.totalPrice)}</span></b>&nbsp;&nbsp;
              {
                numButton === discountArray.length ? (
                  <>
                  <b style={{color:'#dc3a1a'}}>{prices?.afterDiscountPrice}</b>&nbsp;&nbsp;
                   <div className="discountAmount">SAVE {prices?.discountPrice}</div>
                  </>
                ):(
                  <></>
                )
              }
              
            </p>
           </div>
          )
        }
        {/* || bundleStatus!==0 */}
        <button className="pd__bundle_buy__btn" disabled={numButton === 0  ? true : false } onClick={cartProduct}> 
    
         { btnLoading ? <Spinner/> : t(btnText)} 
         
         {/* {
          btnLoading && (
            <>
            <div className="spinner-border" role="status" style={{width:'1rem',height:'1rem', marginLeft:"6px"}}>
                    <span className="sr-only"></span>
            </div>
            </>
          )
         } */}
          
        </button>
        {loaderOn === true ? <NewLoader /> : ""}
        {
           bundleStatus!==0 ? (
            <div className="disable-bundle"></div>

          ) : (
             <></>
          )
        }
      </div>
    );
  }
  return <></>;
};

export default BundleView;