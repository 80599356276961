import React from "react";
import ReactImageMagnify from "react-image-magnify";
// import { MagnifyImageResize } from "../helpers/utils/imageResize";
import DefaultImage from './../../assets/Icon/default_image.png';


export const ImageMagnify = (props) => {
    
    const getCdnImageUrl = (src, q, w, h) => {
        if (src.includes("cdn-cgi")) {
            return src;
        } else {
            return src.replace("/pub/media", `/cdn-cgi/image/q=${q},width=${w},height=${h},f=auto/pub/media`);
        }
    };

    console.log(props?.src);
    console.log(getCdnImageUrl(props?.src, 70, 950, 950));
    let imgPropsDesktop = {
        className: "carousel_zoomBox",
        imageClassName: "carousel_zoom_img",
        smallImage: {
            isFluidWidth: true,
            src: getCdnImageUrl(props?.src,70,950,950),
            srcSet: getCdnImageUrl(props?.src,70,950,950),
            sizes: '(max-width: 480px) 1712vw, (max-width: 1200px) 30vw, 360px',
            onError: (event) => { event.target.src = DefaultImage; },
        },
        largeImage: {
            src: `${getCdnImageUrl(props?.src,70,950,950)}`,
            width: 950,
            height: 950,
            onError: (event) => { event.target.src = DefaultImage },
        },
        enlargedImagePosition: 'over',
    }

    let imgPropsMobile = {
        className: "carousel_zoomBox",
        imageClassName: "carousel_zoom_img",
        smallImage: {
            isFluidWidth: true,
            src: getCdnImageUrl(props?.src,70,550,550),
            srcSet: getCdnImageUrl(props?.src,70,550,550),
            onError: (event) => { event.target.src = DefaultImage },
        },
        largeImage: {
            src: `${getCdnImageUrl(props?.src,70,550,550)}`,
            width: 550,
            height: 550,
            onError: (event) => { event.target.src = DefaultImage },
        },
        enlargedImagePosition: 'over',
        isActivatedOnTouch: true,
        isHintEnabled: true,
        hintTextTouch: 'Long-Touch to Zoom'
    }

    return (
        <>
            {props?.view === 'mobile' ?
                <ReactImageMagnify
                    {...imgPropsMobile}
                />
                : <ReactImageMagnify
                    {...imgPropsDesktop}
                />
            }
        </>

    );
};
