import React, { useState, useEffect } from "react";
import Heading5 from "../Font/Heading5";
import Heading7 from "../Font/Heading7";
import { isMobileNumber } from "../helpers/utils/validators";
import cancel_grey from "./../../assets/Icon/cancel_grey.svg";
import "./../../SCSS/Popup/_addressPopup.scss";
import * as services from "../../services/services";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerAddressSuccess } from "../../services/customerAddress/customerAddress";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { acceptOnlyString } from '../helpers/utils/regexp';
import { Spinner } from "../helpers/utils/spinner";
import { Button, Modal } from "react-bootstrap";
import { impressionEvents } from "../helpers/utils/ga/impressionEvents";

function AddressPopupModal({ show, closeLoginPopup, editAddressData, popupType }) {
  const dispatch = useDispatch();
  const { loadingStartEnd } = useSelector((state) => state.commonReducer);
  const { t } = useTranslation();

  // const T_REQ_NAME = t("Name_is_required");
  const T_REQ_FIRST_NAME = t("First_Name_is_required");
  const T_REQ_LAST_NAME = t("Last_Name_is_required");
  // const T_REQ_COUNTRY = t("Country_is_required");
  const T_REQ_MOBILE_NUMBER = t("Mobile_Number_is_required");
  const T_REQ_ADDRESS_LINE_1 = t("Address_Line_1 is_required");
  // const T_REQ_ADDRESS_LINE_2 = t("Address_Line_2_is_required");
  const T_REQ_CITY_TOWN = t("City/Town_is_required");
  // const T_REQ_STATE = t("State_is_required");
  const T_REQ_LANDMARK = t("Landmark_is_required");
  // const T_REQ_POST_CODE = t("Post_Code_is_required");
  const T_INVALID_MOBILE_NUMBER = t("Invalid_Mobile_Number");
  const T_REQ_DISTRICT = t("District_is_required");

  const countriesLocationData = useSelector(
    (state) => state.appData.countriesLocationData
  );
  const cityLocationData = useSelector(
    (state) => state.appData.cityLocationData
  );
  // customerAddressList
  const { customerAddUpdateManage } = useSelector(
    (state) => state.customerAddressReducer
  );

  const [storeCitiesLocationData, setStoreCitiesLocationData] = useState([]);
  const [storeCountriesLocationData, setStoreCountriesLocationData] = useState(
    []
  );
  const [latestDistrictList, setLatestDistrictList] = useState([]);
  const [editId, setEditId] = useState("");
  const [renderPage, setRenderPage] = useState(false);

  const [address, setAddress] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    // postCode: "",
    country: "",
    primary: false,
    landmark: "",
    addressType: "1",
  });

  const [errMsg, setErrMsg] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    addressLine1: "",
    // addressLine2: "",
    city: "",
    state: "",
    // postCode: "",
    country: "",
    primary: false,
    landmark: "",
  });

  useEffect(() => {
    if (popupType === "add") {
      resetFormValue();
      resetFormErr();
    }
    if (popupType === "update") {
      resetFormErr();
    }
  }, [popupType]);

  useEffect(() => {
    if (renderPage === true) {
      setRenderPage(!renderPage);
    }
  }, [renderPage]);

  useEffect(() => {
    if (customerAddUpdateManage) {
      if (customerAddUpdateManage.success === true) {
        resetFormValue();
        resetFormErr();
        closeLoginPopup();
        dispatch(updateCustomerAddressSuccess(""));
      }
    }
  }, [customerAddUpdateManage]);

  useEffect(() => {
    if (countriesLocationData) {
      let countryList = [];
      countriesLocationData &&
        countriesLocationData.map((val, i) => {
          let countryData = {
            id: val.id,
            label: val.full_name_english,
          };
          countryList.push(countryData);
        });
      setStoreCountriesLocationData(countryList);
    }
  }, [countriesLocationData]);

  useEffect(() => {
    if (cityLocationData) {
      let cityList = [];
      cityLocationData &&
        cityLocationData.map((val, i) => {
          let cityData = {
            value: val.cityCode,
            label: val.cityCode,
            allData: val,
          };
          cityList.push(cityData);
        });
      setStoreCitiesLocationData(cityList);
    }
  }, [cityLocationData]);

  useEffect(() => {
    if (editAddressData && popupType === "update") {
      let landmarkData = "";
      let addressTypeData = "1";
      if (editAddressData?.details?.custom_attributes !== undefined) {
        let getLandmark = editAddressData?.details?.custom_attributes.filter(
          (val) => val.attribute_code === "landmark"
        );
        if (getLandmark.length > 0) {
          landmarkData = getLandmark[0].value;
        }
        let getAddressType = editAddressData?.details?.custom_attributes.filter(
          (val) => val.attribute_code === "address_types"
        );
        if (getAddressType.length > 0) {
          addressTypeData = getAddressType[0].value;
        }
      }
      setAddress({
        ...address,
        firstName: editAddressData.details.firstname,
        lastName: editAddressData.details.lastname,
        mobileNumber: editAddressData.details.telephone,
        addressLine1: editAddressData.details.street?.[0],
        addressLine2: editAddressData.details.street?.[1],
        city: editAddressData.details.city,
        state:
          editAddressData?.details?.postcode !== undefined
            ? editAddressData.details.postcode
            : "",
        // postCode: editAddressData.details.postcode,
        primary:
          editAddressData?.details?.primary !== undefined
            ? editAddressData.details.primary
            : false,
        landmark: landmarkData,
        addressType: addressTypeData,
      });
      setEditId(editAddressData.details.id);
      setRenderPage(true);
    }
  }, [editAddressData]);

  const resetFormValue = () => {
    let formValue = {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      // postCode: "",
      country: "SA",
      primary: false,
      landmark: "",
      addressType: "1",
    };
    setAddress(formValue);
  };

  const resetFormErr = () => {
    let formErr = {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      addressLine1: "",
      // addressLine2: "",
      city: "",
      state: "",
      // postCode: "",
      country: "",
      primary: false,
      landmark: "",
    };
    setErrMsg(formErr);
  };

  useEffect(() => {
    let districts = storeCitiesLocationData.filter(
      (val, i) => val.allData.cityCode === address.city
    )?.[0]?.allData?.districts;
    if (districts !== undefined) {
      let newArray = [];
      districts &&
        districts.map((item) => {
          let newObj = { label: item.label, value: item.code };
          newArray.push(newObj);
        });
      setLatestDistrictList(newArray);
    }
  }, [address.city, address.state]);

  const validateForm = (event, newErrObj, name, value) => {
    //A function to validate each input values
    switch (name) {
      case "firstName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_FIRST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "lastName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_LAST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "mobileNumber":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_MOBILE_NUMBER };
        } else {
          let isValidNumber = isMobileNumber(value);
          if (isValidNumber === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_MOBILE_NUMBER };
          } else {
            newErrObj = { ...newErrObj, [name]: "" };
          }
        }
        break;
      // case 'country':
      //   if (value === "") {
      //     newErrObj = { ...newErrObj, [name]: T_REQ_COUNTRY }
      //   } else {
      //     newErrObj = { ...newErrObj, [name]: '' }
      //   }
      //   break;
      case "addressLine1":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_ADDRESS_LINE_1 };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      // case 'addressLine2':
      //   if (value === "") {
      //     newErrObj = { ...newErrObj, [name]: T_REQ_ADDRESS_LINE_2 }
      //   } else {
      //     newErrObj = { ...newErrObj, [name]: '' }
      //   }
      //   break;
      case "city":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_CITY_TOWN, state: "" };
        } else {
          newErrObj = { ...newErrObj, [name]: "", state: "" };
        }
        break;
      case "state":
        // let getDistrictList = getLatestDistrictList(address.city);
        if (value === "" && address.city !== "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_DISTRICT };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      // case 'postCode':
      //   if (value === "") {
      //     newErrObj = { ...newErrObj, [name]: T_REQ_POST_CODE }
      //   } else {
      //     newErrObj = { ...newErrObj, [name]: '' }
      //   }
      //   break;
      case "landmark":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_LANDMARK };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      default:
        break;
    }
    return newErrObj;
  };

  const handleChange = async (event, keyName) => {
    let value = event;
    let name = event;
    if (keyName === "primary") {
      value = event.target.checked;
      name = "primary";
    } else if (keyName === "mobileNumber") {
      value = event;
      name = "mobileNumber";
    } else if (keyName === "city" || keyName === "state") {
      value = event.value;
      name = keyName;
    } else if (
      event.target.name === "firstName" ||
      event.target.name === "lastName"
    ) {
      value = acceptOnlyString(event.target.value, true)
      name = event.target.name;
    } else {
      value = event.target.value;
      name = event.target.name;
    }
    setAddress({ ...address, [name]: value });
    let manageErrMsg = validateForm(event, errMsg, name, value);
    setErrMsg(manageErrMsg);
  };

  const handleBlur = (e) => {
    let obj = {
      target: {
        name: e.target.name,
        value: e.target.value.trim(),
      }
    }
    handleChange(obj)
  }

  const allFeildValidate = (validateFeild, allErrMsg) => {
    let checkValueStatus = [];
    let checkErrStatus = [];

    validateFeild &&
      validateFeild.map((val, i) => {
        let keyVal = address[val];
        let errVal = errMsg[val];

        allErrMsg = validateForm("", allErrMsg, val, keyVal);
        if (keyVal !== "") {
          checkValueStatus.push("suc");
        }
        if (errVal === "") {
          checkErrStatus.push("err");
        }
      });

    let checkAddressStatus = false;
    if (
      checkValueStatus.length === validateFeild.length &&
      checkErrStatus.length === validateFeild.length
    ) {
      checkAddressStatus = true;
    }

    let returnData = {
      allErrMsg: allErrMsg,
      checkAddressStatus: checkAddressStatus,
    };

    return returnData;
  };

  const handleSubmit = () => {
    let validateFeild = [
      "firstName",
      "lastName",
      "mobileNumber",
      "addressLine1",
      // "addressLine2",
      "city",
      // "state",
      // "country",
      // "postCode",
      "landmark",
    ];

    let getDistrictList = latestDistrictList;
    if (getDistrictList.length > 0) {
      validateFeild.push("state");
    }

    let formStatus = allFeildValidate(validateFeild, errMsg);
    setErrMsg(formStatus.allErrMsg);

    let distDataManage = "";
    if (getDistrictList.length > 0 && address.state !== "") {
      distDataManage = address.state;
    }
    if (formStatus.checkAddressStatus === true) {
      if (editId !== "" && popupType === "update") {
        let params = {
          addressId: popupType === "update" ? editId : "",
          firstName: address.firstName,
          lastName: address.lastName,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          city: address.city,
          telephone: address.mobileNumber,
          primary: address.primary,
          // countryId: address.country ? address.country : "SA",
          countryId: "SA",
          postCode: distDataManage,
          regionId: 0,
          custom_attributes: [
            {
              attribute_code: "landmark",
              value: address.landmark,
            },
            {
              attribute_code: "address_types",
              value: address.addressType === "" ? "1" : address.addressType,
            },
          ],
        };
        impressionEvents.impressionShippingAddress(
          "add_shipping_address",
          params
        );
        dispatch(services.updateCustomerAddress(params));
      } else {
        let params = {
          firstName: address.firstName,
          lastName: address.lastName,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          city: address.city,
          telephone: address.mobileNumber,
          primary: address.primary,
          // countryId: address.country ? address.country : "SA",
          countryId: "SA",
          postCode: distDataManage,
          regionId: 0,
          custom_attributes: [
            {
              attribute_code: "landmark",
              value: address.landmark,
            },
            {
              attribute_code: "address_types",
              value: address.addressType === "" ? "1" : address.addressType,
            },
          ],
        };
        impressionEvents.impressionShippingAddress(
          "add_shipping_address",
          params
        );
        dispatch(services.createCustomerAddress(params));
      }
    }
  };

  // const handleCancel = () => {

  // };

  const handleClosePopup = () => {
    closeLoginPopup();
    resetFormValue();
    resetFormErr();
  };

  // const addressTypeStaticList = [
  //   {
  //     label: t("Home"),
  //     value: "1",
  //     defaultCheck: true,
  //   },
  //   {
  //     label: t("Work"),
  //     value: "2",
  //     defaultCheck: false,
  //   },
  // ];
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  function Position(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return currenttop > 200 ? [currenttop - 200] : [0];
    }
  }

  useEffect(() => {
    if (Object.keys(errMsg).length && document.activeElement.tagName !== "INPUT") {
      const errorElement = document.querySelector(".invalid__message");
      if (errorElement) {
        window.scrollTo(0, Position(errorElement));
      }
    }
  }, [errMsg])

  return (
    <Modal
      show={show}
      centered
      onHide={() => handleClosePopup()}
      scrollable={true}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {popupType === "add" ? t("Add_New_Address") : t("Update_Address")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="address__popup__block">
          <div className="address__content__block">
            <div className="row address__form__field__row">
              <div className="col-sm-12 col-md-6 main__form__field__block">
                {/* <p className="form__label">First Name</p> */}
                {/* <p className="form__label">First Name</p> */}
                <Heading7 text={t("First_Name")} marginBottom={10} />
                <sup className="inputs_validation">*</sup>
                <div className="field__block">
                  <input
                    type="text"
                    placeholder={t("First_Name")}
                    className="form__field"
                    id="name"
                    onKeyDown={handleKeyDown}
                    name="firstName"
                    value={address.firstName}
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                  />
                </div>
                {errMsg.firstName && (
                  <p className="invalid__message">{errMsg.firstName}</p>
                )}
              </div>
              <div className="col-sm-12 col-md-6 main__form__field__block">
                {/* <p className="form__label">Mobile Number</p> */}
                <Heading7 text={t("Last_Name")} marginBottom={10} />
                <sup className="inputs_validation">*</sup>
                <div className="field__block">
                  <input
                    type="text"
                    placeholder={t("Last_Name")}
                    className="form__field"
                    onKeyDown={handleKeyDown}
                    id="lastName"
                    name="lastName"
                    value={address.lastName}
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                  />
                </div>
                {errMsg.lastName && (
                  <p className="invalid__message">{errMsg.lastName}</p>
                )}
              </div>
            </div>
            <div className="row address__form__field__row">
              <div className="col-sm-12 col-md-6 main__form__field__block">
                {/* <p className="form__label">Mobile Number</p> */}
                <Heading7 text={t("Mobile_Number")} marginBottom={10} />
                <sup className="inputs_validation">*</sup>
                {/* <div className="field__block">
              <input
                type="text"
                placeholder=""
                className="form__field"
                id="mobileNumber"
                name="mobileNumber"
                value={address.mobileNumber}
                onChange={(e) => handleChange(e)}
              />
            </div> */}
                <PhoneInput
                  inputProps={{
                    name: "mobileNumber",
                    required: true,
                    className: "profile__mobile__form__field",
                  }}
                  onKeyDown={handleKeyDown}
                  country="sa"
                  onlyCountries={["sa"]}
                  masks={{ sa: ".. ... ...." }}
                  countryCodeEditable={false}
                  disableDropdown={true}
                  value={address.mobileNumber}
                  onChange={(e) => handleChange(e, "mobileNumber")}
                  className=""
                />
                {errMsg.mobileNumber && (
                  <p className="invalid__message">{errMsg.mobileNumber}</p>
                )}
              </div>
              <div className="col-sm-12 col-md-6 main__form__field__block">
                {/* <p className="form__label">First Name</p> */}
                <Heading7 text={t("Country")} marginBottom={10} />
                <sup className="inputs_validation">*</sup>
                <select
                  name="country"
                  onChange={(e) => handleChange(e)}
                  value={address.country}
                  className="_customselect form-control"
                  disabled={true}
                >
                  {/* <option key='no' value=''>Select Country</option> */}
                  {storeCountriesLocationData &&
                    storeCountriesLocationData.map((val, i) => {
                      return (
                        <React.Fragment key={val.id}>
                          <option key={val.id} value={val.id}>
                            {val.label}
                          </option>
                        </React.Fragment>
                      );
                    })}
                </select>
                {errMsg.country && (
                  <p className="invalid__message">{errMsg.country}</p>
                )}
              </div>
            </div>
            <div className="row address__form__field__row">
              <div className="col-sm-12 col-md-6 main__form__field__block">
                {/* <p className="form__label">First Name</p> */}
                <Heading7 text={t("Address_Line_1")} marginBottom={10} />
                <sup className="inputs_validation">*</sup>
                <div className="field__block">
                  <input
                    type="text"
                    placeholder={t("Address_Line_1")}
                    className="form__field"
                    id="addressLine1"
                    onKeyDown={handleKeyDown}
                    name="addressLine1"
                    value={address.addressLine1}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errMsg.addressLine1 && (
                  <p className="invalid__message">{errMsg.addressLine1}</p>
                )}
              </div>
              <div className="col-sm-12 col-md-6 main__form__field__block">
                <Heading7 text={t("Address_Line_2")} marginBottom={10} />
                <div className="field__block">
                  <input
                    type="text"
                    placeholder={t("Address_Line_2")}
                    className="form__field"
                    onKeyDown={handleKeyDown}
                    id="addressLine2"
                    name="addressLine2"
                    value={address.addressLine2}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errMsg.addressLine2 && (
                  <p className="invalid__message">{errMsg.addressLine2}</p>
                )}
              </div>
            </div>
            <div className="row address__form__field__row">
              <div className="col-sm-12 col-md-6 main__form__field__block">
                <Heading7 text={t("City/Town")} marginBottom={10} />
                <sup className="inputs_validation">*</sup>
                <Select
                  name="city"
                  onChange={(e) => handleChange(e, "city")}
                  value={
                    storeCitiesLocationData &&
                    storeCitiesLocationData.filter(
                      (val) => val.value === address.city
                    )?.[0]
                  }
                  options={storeCitiesLocationData}
                />
                {errMsg.city && <p className="invalid__message">{errMsg.city}</p>}
              </div>
              {latestDistrictList && latestDistrictList.length > 0 && (
                <div className="col-sm-12 col-md-6 main__form__field__block">
                  <Heading7 text={t("District")} marginBottom={10} />
                  <sup className="inputs_validation">*</sup>
                  <Select
                    name="state"
                    onChange={(e) => handleChange(e, "state")}
                    value={
                      latestDistrictList.filter(
                        (val) => val.value === address.state
                      )?.[0]
                    }
                    options={latestDistrictList}
                  />
                  {errMsg.state && (
                    <p className="invalid__message">{errMsg.state}</p>
                  )}
                </div>
              )}
            </div>
            <div className="row address__form__field__row">
              <div className="col-sm-12 col-md-6 main__form__field__block">
                <Heading7 text={t("Landmark")} marginBottom={10} />
                <sup className="inputs_validation">*</sup>
                <div className="field__block">
                  <input
                    onKeyDown={handleKeyDown}
                    type="text"
                    placeholder={t("Landmark")}
                    className="form__field"
                    id="landmark"
                    name="landmark"
                    value={address.landmark}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errMsg.landmark && (
                  <p className="invalid__message">{errMsg.landmark}</p>
                )}
              </div>
            </div>
            {/* <div className="row address__form__field__row">
          <div className="col-sm-12 col-md-6 main__form__field__block">
            <Heading7 text="Post Code" marginBottom={10} />
            <div className="field__block">
              <input
                type="text"
                placeholder=""
                className="form__field"
                id="postCode"
                name="postCode"
                value={address.postCode}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {errMsg.postCode && <p className="invalid__message">{errMsg.postCode}</p>}
          </div>
        </div> */}
            <div className="addressType__block__fieldbox">
              <Heading7
                text={t("Address_Type")}
                marginBottom={10}
              />

              <div className="addressType__block">
                <input
                  type="radio"
                  className="delivery__input__check"
                  name="addressType"
                  // defaultChecked={true}
                  checked={address?.addressType === "1" ? true : false}
                  value="1"
                  onKeyDown={handleKeyDown}
                  id="home"
                  onChange={(e) => handleChange(e)}
                />
                <label className="delivery__selection__text me-3 ms-1" htmlFor="home">
                  {t("Home")}
                </label>
                <input
                  type="radio"
                  className="delivery__input__check"
                  name="addressType"
                  onKeyDown={handleKeyDown}
                  // defaultChecked={false}
                  checked={address?.addressType === "2" ? true : false}
                  value="2"
                  id="work"
                  onChange={(e) => handleChange(e)}
                />
                <label className="delivery__selection__text ms-1" htmlFor="work">
                  {t("Work")}
                </label>
              </div>
            </div>
            <div className="form-check form-switch">
              <input
                type="checkbox"
                name="primary"
                onKeyDown={handleKeyDown}
                value={address.primary}
                checked={address.primary}
                onChange={(e) => handleChange(e, "primary")}
                className="form-check-input"
                role="switch"
                id="flexSwitchCheckDefault"
              />
              <Heading7 text={t("Default_Address")} marginBottom={10} />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="address__form__button__block">
          <button className="form__save__button" onClick={() => handleSubmit()}>
            {loadingStartEnd === true ? <Spinner /> : t("SAVE")}
          </button>
          <button className="form__cancel__button" onClick={handleClosePopup}>
            {t("CANCEL")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default AddressPopupModal;
