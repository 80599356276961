import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./../../SCSS/_featureProducts.scss";
import { useDispatch } from "react-redux";
import { loadAddToCart, loadCartData } from "../../redux/appAction";
import RatingBlock from "../MostSharedComponent/RatingBlock";
import OldPrice from "../Font/OldPrice";
import Price from "../Font/Price";
import { useTranslation } from "react-i18next";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import FeatureOptionBox from "../MostSharedComponent/FeatureOptionBox";
import TamaraLogoBox from "../MostSharedComponent/TamaraLogoBox";
import { titleChange } from "../helpers/utils/titleNameChange";
import AddToCart from '../Buttons/AddToCart';
import BundleOffer from "../BundelOffer";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import LazyImageForProduct from "../MostSharedComponent/LazyImageForProduct";
import FavouriteProduct from "../MostSharedComponent/FavouriteProduct";
import { getHomePageData } from "../../services/homepage.service";
import { useQuery } from "react-query";
import * as services from "./../../services/services";
import SoldOutLabel from "../MostSharedComponent/SoldOutLabel";
import ProductDeliveryType from "../MostSharedComponent/ProductDeliveryType";
import { impressionEvents } from "../helpers/utils/ga/impressionEvents";
import HomeVariantOptions from "../ProductType/HomeVariantOptions";
import SubscribeSection from "../Meza/SubscribeSection";
import SubsSectionContent from "../Meza/SubsSectionContent";

function SingleFeatureProductBlock({
  product,
  handleChangeCartPopup,
  outOfStockConfiguration,
  screenName,
  type,
  availablityData,
  sectionTitle,
  sectionId,
  customerDetails,
}) {

  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);
  const [sizeButtonIndex, setSizeButtonIndex] = useState(null);

  // useEffect(() => {
  //   product?.forEach(function callback(value, index) {
  //     product[index].is_price_hide = value?.options?.find((x) => {
  //       if (x.code === 'is_price_hide') {
  //         return x.visibility;
  //       }
  //       return false;
  //     });
  //   });
  // }, [product]);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { t } = useTranslation();

  const AddProductToCart = (sku, key) => {
    // impressionEvents.impressionAddToCart(
    //   product, 1,"Home"
    // );
    setLoaderOn(true);
    let product_id = null;
    if (product) {
      product_id = product?.id
    }
    if (product?.variants && product?.variants.length > 0 && sizeButtonIndex && sizeButtonIndex >= 0) {
      sku = product.variants[sizeButtonIndex].sku;
    }
    const data = {
      items: [
        {
          sku: sku,
          id: product_id,
          qty: 1,
        },
      ],
    };

    const loadAddToCartDAta = dispatch(loadAddToCart(data))
      .then((res) => {
        setLoaderOn(false);
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id, sectionTitle,sectionId)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        setLoaderOn(false);
        dispatch(services.notifyError({ message: err.response.data.message }));
      })
      .finally(() => {
        setLoaderOn(false);
      });
  };

  const { data: homepageData = {} } = useQuery(
    ["homepageData"],
    () => getHomePageData(),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10,
      staleTime: Infinity
    }
  );
  const targetPrice = product?.price_rounded;
  const matchedProduct = product?.variants?.filter(product => product.price_rounded === targetPrice);
  const newMatchPrice = matchedProduct?.length > 1 ? matchedProduct?.[0] : matchedProduct?.[0];
  const screenSize = matchedProduct && matchedProduct[0]?.options?.screen_size;
  const seletcedScreenSize = product?.variantOptions?.[0].attribute?.options.filter(product => product.id === screenSize);
  const defaultSku = matchedProduct && matchedProduct[0]?.sku;
  const sizeButtonClick = (sizeIndex) => {
    product.sku = product?.variants[sizeIndex]?.sku;
    product.name = product?.variants[sizeIndex]?.name;
    product.description = product?.variants[sizeIndex]?.description;
    product.price = product?.variants[sizeIndex]?.price;
    product.currency = product?.variants[sizeIndex]?.currency;
    product.price_rounded = product?.variants[sizeIndex]?.price_rounded;
    product.discounted_price = product?.variants[sizeIndex]?.discounted_price;
    product.discounted_price_rounded = product?.variants[sizeIndex]?.discounted_price_rounded;
    product.discounted_price_from = product?.variants[sizeIndex]?.discounted_price_from;
    product.discounted_price_to = product?.variants[sizeIndex]?.discounted_price_to;
    product.is_count_down = product?.variants[sizeIndex]?.is_count_down;
    product.stock = product?.variants[sizeIndex]?.stock;
    product.item_type = product?.variants[sizeIndex]?.item_type;
    product.meza = product?.variants[sizeIndex]?.meza;
    product.qitaf = product?.variants[sizeIndex]?.qitaf;
    product.eligible_for_tabby = product?.variants[sizeIndex]?.eligible_for_tabby;
    product.enable_shipping = product?.variants[sizeIndex]?.enable_shipping;
    product.additional_estimated_day = product?.variants[sizeIndex]?.additional_estimated_day;
    product.current_time = product?.variants[sizeIndex]?.current_time
    setSizeButtonIndex(sizeIndex);
  };
  const stringToCheck = product?.name;
  const regex = /\d+$/;
  const hasAnythingAfterDash = regex.test(stringToCheck);
  const productName = (() => {
    if (!hasAnythingAfterDash && newMatchPrice?.name !== undefined) {
    return newMatchPrice.name;
  }
    return product.name;
  })();
  useEffect(() => {
    if(product){
      // impressionEvents.impressionViewProduct(
      //   product,
      //   "Home"
      // );
    }
  
  }, [product]);

  return (
    <>
      {product ?
        <div className="feature__category"
          onClick={() => {
            navigate(
              productUrlHelper(
                product?.english_name,
                product?.sku
              ));
              impressionEvents.impressionProductClick(
                "select_item", product,sectionTitle,
                sectionId
              );
          }}
        >
          <span className="fav_icon d-block"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <FavouriteProduct
              product={product}
            />
          </span>
          <div className="pro__feature__optionBox">
            <FeatureOptionBox
              options={product?.options}
              color_resolution="color_resolution"
              screen_size="screen_size"
            />
          </div>
          <div className="product__pics__area">
            <Link
              to={productUrlHelper(
                product?.english_name,
                product?.sku
              )}
              aria-label={product?.sku}
            >
              <div className="second__feature__category__image__block">
                <LazyImageForProduct
                  product={product}
                  // srcSetReSize={ImageResize}
                  alt="Feature Category"
                  className="second__feature__category__image"
                />
              </div>
            </Link>
            <SoldOutLabel
              t={t}
              product={product}
              outOfStockConfiguration={outOfStockConfiguration}
            />
          </div>
          <Link
            to={productUrlHelper(
              product?.english_name,
              product?.sku
            )}
            className="featureProduct__name"
            aria-label={product?.name}
          >
            {titleChange(productName)}
          </Link>
          <ProductDeliveryType
            product={product}
            availablityData={availablityData || []}
            t={t}
          />
          <div className="d-flex justify-content-between align-items-center mb-1">
            <div className="plp__tamara__box mb-2">
              <TamaraLogoBox
                product={product}
              />
            </div>
          </div>
          <BundleOffer bundleEnable={product?.is_bundle_enable} t={t} />
          <div className="featureProduct__rating__block">
            <RatingBlock rating={6} totalRatings={2222} />
          </div>
          <div className="feature__price__cartBtn__area">
            <div className="feature__Pricebox__area">
              {
                !product?.is_price_hide &&
                <>
                  <OldPrice
                    oldPrice={product?.price_rounded}
                    price={
                      product?.discounted_price_rounded === null
                        ? product?.price_rounded
                        : product?.discounted_price_rounded
                    }
                    size="text4"
                    color="#c8c8c8"
                    marginBottom={0}
                    // lineThrough={true}
                    span={true}
                    currency={product?.currency}
                  />
                  <Price
                    price={
                      product?.discounted_price_rounded === null
                        ? product?.price_rounded
                        : product?.discounted_price_rounded
                    }
                    // marginLeft={5}
                    marginBottom={0}
                    size="heading7"
                    span={true}
                    currency={product?.currency}
                  />
                </>
              }
            </div>
            <div className="size__button__block">
              {product?.variantOptions && (
                <HomeVariantOptions
                  sizeButtonClick={sizeButtonClick}
                  sizeButtonIndex={sizeButtonIndex}
                  variantOptions={product?.variantOptions}
                  product={product}
                />
              )}
            </div>
            {!product?.hide_subscriber_price && (
              <SubscribeSection>
                <SubsSectionContent
                  t={t}
                  meza_subscriber_price={product?.meza_subscriber_price}
                  user={customerDetails}
                  discounted_price={product.discounted_price_rounded}
                  og_price={product.price_rounded}
                />
              </SubscribeSection>
            )}
            <div className="text-end cart_end_icon"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {product?.is_bundle_enable === 1 ? (
                <Link
                  style={{ textDecoration: 'none' }}
                  to={productUrlHelper(
                    product?.english_name,
                    product?.sku
                  )}
                  aria-label={product?.name}
                >
                  <AddToCart
                    product={product}
                    AddProductToCart={AddProductToCart}
                    outOfStockConfiguration={outOfStockConfiguration}
                    loaderOn={loaderOn || spinnerOn}
                    type={type}
                    matchedProduct={matchedProduct}
                  />
                </Link>
              ) : (
                <AddToCart
                  product={product}
                  AddProductToCart={AddProductToCart}
                  outOfStockConfiguration={outOfStockConfiguration}
                  loaderOn={loaderOn || spinnerOn}
                  type={type}
                  matchedProduct={matchedProduct}
                />
              )}
            </div>
          </div>
        </div>
        : null}
    </>
  );
}

export default SingleFeatureProductBlock;
