import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading3 from "../Font/Heading3";
import Heading7 from "../Font/Heading7";
import Text3 from "../Font/Text3";
import cancel_grey from "./../../assets/Icon/cancel_grey.svg";
import "./../../SCSS/Login/_otpModel.scss";
import * as services from "./../../services/services";
import { useLocation } from "react-router-dom";
import { customerSignUpOtpSuccess } from "../../services/customer/customer";
import { useTranslation } from "react-i18next";
import { Spinner } from "../helpers/utils/spinner";

function OtpModel({ handleChangePopupMode, closeLoginPopup }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const T_REQ_OTP = t("Otp_is_missing");
  const { customerSignUpOtpData, customerVerifyMobileOTP } = useSelector(
    (state) => state.customerReducer
  );

  const [otp, setOtp] = useState("");
  const { loadingStartEnd } = useSelector((state) => state.commonReducer);
  const [errMsg, setErrMsg] = useState("");

  // useEffect(() => {
  //   if (customerSignUpOtpData !== "" && customerSignUpOtpData?.mobileNumber !== "") {
  //     setMobileNumer(customerSignUpOtpData?.mobileNumber)
  //   }
  // }, [customerSignUpOtpData]);

  // useEffect(() => {
  //   if (customerVerifyMobileOTP !== "" && customerVerifyMobileOTP?.status === false) {
  //     setErrMsg("You've entered an invalid verification code")
  //   }
  // }, [customerVerifyMobileOTP]);

  const handleChange = (e) => {
    if (e.target.value !== "") {
      setErrMsg("");
    } else {
      setErrMsg(T_REQ_OTP);
    }
    if (!isNaN(e.target.value)) {
      if (e.target.value.length < 7) {
        setOtp(e.target.value);
      }
    } else {
      setErrMsg(T_REQ_OTP);
    }
  };

  const onSubmit = () => {
    if (otp !== "") {
      let params = {
        otp_token: customerSignUpOtpData.otp_token,
        otp_code: otp,
      };
      dispatch(services.customerVerifyMobileOTP(params, t));
    } else {
      setErrMsg(T_REQ_OTP);
    }
  };

  const resendOtpSubmit = () => {
    let params = {
      phone_number: customerSignUpOtpData?.mobileNumber,
    };
    setCounter(20);
    dispatch(services.customerResendMobileOTP(params, t));
  };

  const closeAndResetPopupData = () => {
    setOtp("");
    setErrMsg("");
    dispatch(customerSignUpOtpSuccess(""));
    // closeLoginPopup();
    handleChangePopupMode("signup");
  };

  const [counter, setCounter] = useState(20);
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };
  return (
    <div className="otpModel__container">
      <div className="otpModel__header">
        <Heading3 text={t("Authentication required")} color="#000000" />
        {location.pathname !== "/checkout" && (
          <img
            onClick={() => closeAndResetPopupData()}
            src={cancel_grey}
            alt="cancel"
            className="cancel__button"
          />
        )}
      </div>
      <div className="otpModel__content">
        <div className="otp__number__block">
          <Heading7
            text={`+${customerSignUpOtpData?.mobileNumber}`}
            span={true}
          />
        </div>
        <Text3
          text={t("Weve sent a One Time Password (OTP) to the mobile number above. Please enter it to complete verification")}
          marginBottom={10}
        />
        <div className="main__form__field__block">
          {/* <p className="form__label">First Name</p> */}
          <Heading7 text={t("Enter_OTP")} marginBottom={10} />
          <sup className="inputs_validation">*</sup>
          <div className="field__block">
            <input
              type="text"
              placeholder={t("Enter_OTP")}
              className="form__field"
              id="otp"
              onKeyDown={handleKeyDown}
              name="otp"
              value={otp}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {errMsg && <p className="invalid__message">{errMsg}</p>}
        </div>
        <button onClick={() => onSubmit()} className="signup__button">
          {loadingStartEnd === true ? <Spinner /> : t("SIGN_UP")}
        </button>
        {counter !== 0 && (
          <div className="resendOtp__msg">
            <p>
              {t("Resend OTP in")}
              <span className="timer"> 00:{counter}</span>
            </p>
          </div>
        )}
        {counter === 0 && (
          <button
            onClick={() => resendOtpSubmit()}
            className="resendOtp__button"
          >
            {t("Resend_OTP")}
          </button>
        )}
        <div className="otp__or__block">
          <div className="otp__or__text__block">
            <p className="otp__or__text">{t("OR")}</p>
          </div>
          <div className="otp__mid__line"></div>
        </div>

        <button
          onClick={() => handleChangePopupMode("signin")}
          className="signin__with__password__button"
        >
          {t("Sign_In_with_Password")}
        </button>
      </div>
    </div>
  );
}

export default OtpModel;
