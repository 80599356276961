import React from 'react';
import './../../../SCSS/MostSharedComponents/_spinner.scss';


export const Spinner = ({ type ="light"}) => (
  <div className="spinner btn__spinner">
    <div className={`dot1 ${type}`} />
    <div className={`dot2 ${type}`}  />
    <div className={`dot3 ${type}`}  />
  </div>
);