import CurrencyDisplay from "../Cards/CurrencyDisplay";
import "./../../SCSS/MostSharedComponents/_bundleProduct.scss";
import BundleIcon from "./../../assets/BundleProduct/bundleIcon.png";
import { useEffect } from "react";
import BundleModal from "./BundleModal";

const BundleProductCard = ({
  t,
  setBundleModal,
  selectBundleCity,
  data,
  // setIsBundleChecked,
  isBundleChecked,
  isPopup,
  setIsPopup,
  setSelectedBundleCity,
  setSelectBundleCity,
}) => {
  useEffect(() => {
    if (!isPopup) {
      setSelectedBundleCity({
        id: 0,
        name: t("No Installation"),
      });
      setSelectBundleCity({
        id: 0,
        name: t("No Installation"),
      });
    }
  }, [isPopup]);

  const handleChange = (e) => {
    setIsPopup(e.target.value === "1" ? true : false);
    setBundleModal(e.target.value === "1" ? true : false);
  };

  return (
    <div className="install__option__area" onChange={(e) => handleChange(e)}>
      <div className="install__Ans">
        <input
          type="radio"
          id="yes"
          name="fav_language"
          value="1"
          checked={selectBundleCity?.id !== 0 ? true : false}
        />
        <label className="delivery__selection__text" htmlFor="yes">
          {t("Yes")}
        </label>
      </div>
      <div className="install__Ans">
        <input
          type="radio"
          id="no"
          name="fav_language"
          value="2"
          defaultChecked
          checked={selectBundleCity?.id !== 0 ? false : true}
        />
        <label className="delivery__selection__text" htmlFor="no">
          {t("No")}
        </label>
      </div>
      <div className="install__message__box">
        <span className="">
          {selectBundleCity ? selectBundleCity.name : t("select city")}&nbsp;
          {selectBundleCity?.id !== 0 ? (
            <span
              className="change_button"
              onClick={() => {
                setIsPopup(true);
                setBundleModal(true);
              }}
            >{`${t("Change")}`}</span>
          ) : (
            ""
          )}
        </span>
        {selectBundleCity?.prices_with_tax?.price ? (
          <CurrencyDisplay
            t={t}
            className="bundleProcudtPrice"
            price={selectBundleCity?.prices_with_tax.price}
          />
        ) : (
          <span>{t("*Free")}</span>
        )}
      </div>
    </div>
  );
};

export default BundleProductCard;