import React, { useState, useEffect } from "react";
import * as services from "./../../services/services";
import Heading3 from "../Font/Heading3";
import Heading6 from "../Font/Heading6";
import Heading7 from "../Font/Heading7";
import { emailValidator, isMobileNumber } from "../helpers/utils/validators";
import edit_black from "./../../assets/Icon/edit_black.svg";
import "./../../SCSS/Form/_personalInfoForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { customerUpdateProfileSuccess, newsletterSubscribe, newsletterUnsubscribe } from "../../services/customer/customer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { acceptOnlyString } from "../helpers/utils/regexp";
import { useNavigate } from "react-router";
import { Spinner } from "../helpers/utils/spinner";

function PersonalInfoForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const T_REQ_FIRSTNAME = t("First_Name_is_required");
  const T_REQ_LASTNAME = t("Last_Name_is_required");
  const T_REQ_EMAIL = t("Email_is_missing");
  const T_INVALID_EMAIL = t("Invalid_email_address_example");
  const T_REQ_MOBILE_NUMBER = t("Mobile_Number_is_missing");
  const T_INVALID_MOBILE_NUMBER = t("Invalid_Mobile_Number");
  const T_REQ_USERNAME = t("Username_is_required");
  const T_REQ_GENDER = t("Gender_is_required");

  const genderList = [
    {
      label: t("Male"),
      value: "m",
      selectOption: 1,
    },
    {
      label: t("Female"),
      value: "f",
      selectOption: 2,
    },
    {
      label: t("Other"),
      value: "na",
      selectOption: 3,
    },
  ];

  const {
    customerUpdateProfileStatus,
    customerProfileDetails,
  } = useSelector((state) => state.customerReducer);

  // const [personalData, setPersonalData] = useState({
  //   firstName: "anawab",
  //   lastName: "ali",
  //   gender: genderList.filter(val => val.selectOption === 1)[0].value,
  //   email: "anawabali@modern-electronics.com",
  //   mobileNumber: "966569858396",
  //   preferenceSettings: [],
  // });

  const [personalData, setPersonalData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    email: "",
    mobileNumber: "",
    preferenceSettings: [],
  });

  const [errMsg, setErrMsg] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    // email: "",
    // mobileNumber: "",
    // preferenceSettings: [],
  });

  const [updateErrMsg, setUpdateErrMsg] = useState(false);
  const { loadingStartEnd } = useSelector((state) => state.commonReducer);
  const [isSubscribed, setIsSubscribed] = useState(true);

  useEffect(() => {
    if (customerUpdateProfileStatus === true) {
      dispatch(customerUpdateProfileSuccess(""));
    }
  }, [customerUpdateProfileStatus]);

  useEffect(() => {
    if (updateErrMsg === true) {
      setErrMsg(errMsg);
      setUpdateErrMsg(!updateErrMsg);
    }
  }, [updateErrMsg]);

  useEffect(() => {
    dispatch(services.customerProfileDetails());
  }, []);

  useEffect(() => {
    if (customerProfileDetails !== "") {
      setPersonalData({
        ...personalData,
        firstName: customerProfileDetails?.firstname,
        lastName: customerProfileDetails?.lastname,
        gender: genderList.filter(
          (val) => val.selectOption === customerProfileDetails.gender
        )?.[0]?.value,
        email: customerProfileDetails?.email,
        mobileNumber: customerProfileDetails?.custom_attributes?.mobile || customerProfileDetails?.custom_attributes?.phone_number,
        // mobileNumber: "",
        preferenceSettings: [],
      });
      setIsSubscribed(customerProfileDetails?.extension_attributes?.is_subscribed);
    }
  }, [customerProfileDetails]);

  const validateForm = async (event, newErrObj, name, value) => {
    //A function to validate each input values
    switch (name) {
      case "firstName":
        if (!value) {
          newErrObj = { ...newErrObj, [name]: T_REQ_FIRSTNAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "lastName":
        if (!value) {
          newErrObj = { ...newErrObj, [name]: T_REQ_LASTNAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      // case "email":
      //   if (!value) {
      //     newErrObj = { ...newErrObj, [name]: T_REQ_EMAIL };
      //   } else {
      //     let emailStatus = emailValidator(value);
      //     if (emailStatus === "error") {
      //       newErrObj = { ...newErrObj, [name]: T_INVALID_EMAIL };
      //     } else {
      //       newErrObj = { ...newErrObj, [name]: "" };
      //     }
      //   }
      //   break;
      case "gender":
        if (!value) {
          newErrObj = { ...newErrObj, [name]: T_REQ_GENDER };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      // case "mobileNumber":
      //   if (!value) {
      //     newErrObj = { ...newErrObj, [name]: T_REQ_MOBILE_NUMBER };
      //   } else {
      //     let isValidNumber = isMobileNumber(value);
      //     if (isValidNumber === "error") {
      //       newErrObj = { ...newErrObj, [name]: T_INVALID_MOBILE_NUMBER };
      //     } else {
      //       newErrObj = { ...newErrObj, [name]: "" };
      //     }
      //   }
      //   break;
    }
    return newErrObj;
  };

  const handleChange = async (event, keyName, valData) => {
    let value = event;
    let name = event;
    if (keyName === "mobileNumber") {
      value = event;
      name = "mobileNumber";
    } else if (keyName === "gender") {
      value = valData.value;
      name = "gender";
    } else if (
      event.target.name === "firstName" ||
      event.target.name === "lastName"
    ) {
      value = acceptOnlyString(event.target.value, true)
      name = event.target.name;
    } else {
      value = event.target.value;
      name = event.target.name;
    }

    setPersonalData({ ...personalData, [name]: value });
    let manageErrMsg = await validateForm(event, errMsg, name, value);
    setErrMsg(manageErrMsg);
  };

  const allFeildValidate = (validateFeild, allErrMsg) => {
    let checkValueStatus = [];
    let checkErrStatus = [];

    validateFeild &&
      validateFeild.map((val, i) => {
        let keyVal = personalData[val];
        let errVal = errMsg[val];

        let allErrMsgManage = validateForm("", allErrMsg, val, keyVal);
        allErrMsgManage.then((res) => {
          allErrMsg[val] = res[val];
        });

        if (keyVal) {
          checkValueStatus.push("suc");
        }
        if (!errVal) {
          checkErrStatus.push("err");
        }
      });

    let checkProfileStatus = false;
    if (
      checkValueStatus.length === validateFeild.length &&
      checkErrStatus.length === validateFeild.length
    ) {
      checkProfileStatus = true;
    }

    let returnData = {
      allErrMsg: allErrMsg,
      checkProfileStatus: checkProfileStatus,
    };

    return returnData;
  };

  const handleSubmit = async () => {
    let validateFeild = [
      "firstName",
      "lastName",
      // "email",
      // "mobileNumber",
      "gender",
    ];

    let formStatus = await allFeildValidate(validateFeild, errMsg);
    setErrMsg(formStatus.allErrMsg);
    setUpdateErrMsg(true);
    if (formStatus.checkProfileStatus === true) {
      let params = {
        firstName: personalData.firstName,
        lastName: personalData.lastName,
        gender: personalData.gender,
        // mobile: personalData.mobileNumber,
      };
      dispatch(services.customerUpdateProfile(params, t));
      // success
    } else {
      // error
    }
  };

  const handleCancel = () => {
    navigate("/user")
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const subscribeNewsletter = async () => {
    try {
      setIsSubscribed(true);
      await newsletterSubscribe({
        email: personalData?.email,
      });
      dispatch(services.notifySuccess({ message: t('Thank you for your subscription') }));
    } catch (error) {
      dispatch(services.notifyError({ message: t('This email address has been already registered') }));
    }
  };

  const unsubscribeNewsletter = async () => {
    try {
      setIsSubscribed(false);
      await newsletterUnsubscribe({
        email: personalData?.email,
      });
      dispatch(services.notifySuccess({ message: t('Unsubscribed from Newsletter') }));
    } catch (error) {
      // console.log(error);
      dispatch(services.notifyError({ message: t('Error occured') }));
    }
  };

  const handleSubscription = (e) => {
    if (e.target.checked) {
      subscribeNewsletter();
    } else {
      unsubscribeNewsletter();
    }
  };

  return (
    <div className="inner__personal__block">
      <div className="profile__firstname__lastname">
        <div className="main__form__field__block" id="firstName__block">
          <Heading7 text={t("First_Name")} marginBottom={10} />
          <div className="field__block">
            <input
              onKeyDown={handleKeyDown}
              type="text"
              placeholder={t("First_Name")}
              className="form__field"
              id="firstName"
              name="firstName"
              value={personalData.firstName}
              onChange={(e) => handleChange(e)}
              autoFocus
            />
          </div>
          {errMsg.firstName && (
            <p className="invalid__message">{errMsg.firstName}</p>
          )}
        </div>
        <div className="main__form__field__block">
          <Heading7 text={t("Last_Name")} marginBottom={10} />
          <div className="field__block">
            <input
              type="text"
              placeholder={t("Last_Name")}
              className="form__field"
              onKeyDown={handleKeyDown}
              id="lastName"
              name="lastName"
              value={personalData.lastName}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {errMsg.lastName && (
            <p className="invalid__message">{errMsg.lastName}</p>
          )}
        </div>
      </div>
      <Heading7 text={t("Your_Gender")} marginBottom={10} />
      <div className="profile__gender__block">
        {genderList &&
          genderList.map((val, i) => {
            return (
              <React.Fragment key={val?.selectOption}>
                <input
                  onKeyDown={handleKeyDown}
                  type="radio"
                  className="delivery__input__check"
                  name="gender"
                  value={personalData.gender}
                  checked={val.value === personalData.gender ? true : false}
                  id={`gender_${val.value}`}
                  onChange={(e) => handleChange(e, "gender", val)}
                />
                <label className="delivery__selection__text" htmlFor={`gender_${val.value}`}>{val.label}</label>
              </React.Fragment>
            );
          })}
      </div>
      {errMsg.gender && <p className="invalid_err_message">{errMsg.gender}</p>}
      <div className="profile__email__block">
        <div className="main__form__field__block">
          <Heading7 text={t("Email_Address")} marginBottom={10} />
          <div className="field__block">
            <input
              type="text"
              placeholder={t("Email_Address")}
              onKeyDown={handleKeyDown}
              className="form__field"
              id="email"
              name="email"
              value={personalData.email}
              disabled={true}
              onChange={(e) => handleChange(e)}
              autoFocus
              style={{ cursor : "not-allowed" }}
            />
          </div>
          {errMsg.email && <p className="invalid__message">{errMsg.email}</p>}
        </div>
      </div>
      <div className="profile__mobile__block">
        <div className="main__form__field__block">
          <Heading7 text={t("Mobile_Number")} marginBottom={10} />
          <PhoneInput
            inputProps={{
              name: "mobileNumber",
              required: true,
              className: "profile__mobile__form__field",
            }}
            onKeyDown={handleKeyDown}
            country="sa"
            onlyCountries={["sa"]}
            masks={{ sa: ".. ... ...." }}
            countryCodeEditable={false}
            disableDropdown={true}
            id="mobileNumber"
            name="mobileNumber"
            value={personalData.mobileNumber}
            onChange={(e) => handleChange(e, "mobileNumber")}
            className=""
            disabled={true}
          />
          {errMsg.mobileNumber && (
            <p className="invalid__message">{errMsg.mobileNumber}</p>
          )}
        </div>
      </div>
      <div className="profile__preferences__block">
        <Heading3 text={t("Newsletter Subscription")} marginBottom={10} />
        <div className="preferences__select__block">
          <input
            type="checkbox"
            className="preferences__select__input__check"
            name="preferences"
            value={isSubscribed}
            onChange={(e) => handleSubscription(e)}
            checked={isSubscribed}
            id="general_sub"
          />
          <label className="preferences__select__text" htmlFor="general_sub">{t("General Subscription")}</label>
        </div>
      </div>
      {/* <div className="profile__preferences__block">
        <Heading3 text="Preferences Settings" marginBottom={10} />

        <div className="preferences__select__block">
          <input
            type="checkbox"
            className="preferences__select__input__check"
            name="preferences"
            value="Notification Preferences"
            onChange={(e) => handleChange(e)}
          />
          <p className="preferences__select__text">Notification Preferences</p>
          <input
            type="checkbox"
            className="preferences__select__input__check"
            name="preferences"
            value="Order Notifications"
            onChange={(e) => handleChange(e)}
          />
          <p className="preferences__select__text">Order Notifications</p>
        </div>
        <div className="preferences__select__block">
          <input
            type="checkbox"
            className="preferences__select__input__check"
            name="preferences"
            value="Returns and Claims Notifications"
            onChange={(e) => handleChange(e)}
          />
          <p className="preferences__select__text">Returns and Claims Notifications</p>
          <input
            type="checkbox"
            className="preferences__select__input__check"
            name="preferences"
            value="Listing Notifications"
            onChange={(e) => handleChange(e)}
          />
          <p className="preferences__select__text">Listing Notifications</p>
        </div>
        <div className="preferences__select__block">
          <input
            type="checkbox"
            className="preferences__select__input__check"
            name="preferences"
            value="Special Offers"
            onChange={(e) => handleChange(e)}
          />
          <p className="preferences__select__text">Special Offers</p>
          <input
            type="checkbox"
            className="preferences__select__input__check"
            name="preferences"
            value="Newsletter Subscriptions"
            onChange={(e) => handleChange(e)}
          />
          <p className="preferences__select__text">Newsletter Subscriptions</p>
        </div>
        <div className="preferences__select__block">
          <input
            type="checkbox"
            className="preferences__select__input__check"
            name="preferences"
            value="Email Notifications"
            onChange={(e) => handleChange(e)}
          />
          <p className="preferences__select__text">Email Notifications</p>
          <input
            type="checkbox"
            className="preferences__select__input__check"
            name="preferences"
            value="Gift Cards Notifications"
            onChange={(e) => handleChange(e)}
          />
          <p className="preferences__select__text">Gift Cards Notifications</p>
        </div>
        <div className="preferences__select__block">
          <input
            type="checkbox"
            className="preferences__select__input__check"
            name="preferences"
            value="SMS & Text Messages"
            onChange={(e) => handleChange(e)}
          />
          <p className="preferences__select__text">SMS & Text Messages</p>
        </div>
      </div> */}
      <div className="profile__form__button__block">
        <button className="form__save__button" onClick={handleSubmit}>
          {loadingStartEnd === true ? <Spinner /> : t("SAVE")}
        </button>
        <button className="form__cancel__button" onClick={handleCancel}>
          {t("CANCEL")}
        </button>
      </div>
    </div>
  );
}

export default PersonalInfoForm;
