import React from "react";
import { useSelector } from "react-redux";

function SoldOutLabel({ t, product, outOfStockConfiguration }) {
  const productStatusData = useSelector((state) => state.appData.productStatusData);

  let productOutOfStockStatus, isOutOfStock
  if (productStatusData) {
    productOutOfStockStatus = product && productStatusData?.data?.[product?.id]?.productOutOfStockStatus ? productStatusData?.data?.[product?.id]?.productOutOfStockStatus : false
    isOutOfStock = productStatusData?.data?.[product?.id]?.orderType === 'out-of-stock' || productStatusData?.data?.[product?.id]?.stock === 0;
  } else {
    productOutOfStockStatus = product?.productOutOfStockStatus ? product?.productOutOfStockStatus : false
    isOutOfStock = product?.orderType === 'out-of-stock' || product?.stock === 0;
  }

    return (
        <React.Fragment>
            {(productStatusData || product) && isOutOfStock && outOfStockConfiguration?.module_status && productOutOfStockStatus ?
                <div className="soldOutLable">
                    {t('SOLD OUT')}
                </div> : null
            }
        </React.Fragment>
    );
}

export default React.memo(SoldOutLabel);