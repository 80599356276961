import React, { useEffect } from "react";
import "./../SCSS/_paymentStatus.scss";
import Heading2 from "../Components/Font/Heading2";
import processingPaymentImg from "./../assets/Icon/processing-payment@2x.png";
import { useTranslation } from "react-i18next";
import NewLoader from "../Components/Common/newloader";
import Text3 from "../Components/Font/Text3";
import { Spinner } from "../Components/helpers/utils/spinner";
import { loaderStart } from "../services/services";
import { useLocation } from "react-router";

function ProcessingPayment({ dispatch, text = "processingPayment" }) {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return (
    <React.Fragment>
      <NewLoader />
      <div className="payment__status__container container-fluid">
        <div className="payment__status__block">
          <div className="mb-3">
            <Spinner type={'dark'} />
          </div>
          <img
            src={processingPaymentImg}
            alt=""
            className="panyment__success__icon"
          />
          <Heading2
            text={t(text)}
            marginBottom={20}
            color="#a3a1a1"
          />
          <Text3 text={t("pleaseWait")} marginBottom={20} color="#a3a1a1" />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProcessingPayment;
