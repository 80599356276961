import React from 'react';
import { useDispatch } from 'react-redux';
import { setGeneralModalData, toggleGeneralModal } from '../../redux/appAction';

const InfoMeza = ({ location, btnTitle }) => {
  const dispatch = useDispatch();
  const toggleModal = (status) => dispatch(toggleGeneralModal(status));
  const setModalData = (data) => dispatch(setGeneralModalData(data));
  const MarginSetting = location === 'pdp' ? '2px 3px' : '-2px 3px';

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setModalData({ type: 'mezaPopup' });
    toggleModal(true);
  };
  return (
    <button
      onClick={(e) => handleClick(e)}
      style={{
        border: 'none', cursor: 'pointer', position: 'absolute', margin: MarginSetting, background: 'none',
      }}
      title={btnTitle}

    >
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <g clipPath="url(#exqis8arsa)">
          <path d="M8.5 0A8.495 8.495 0 0 0 0 8.5C0 13.198 3.802 17 8.5 17S17 13.198 17 8.5 13.198 0 8.5 0zm.873 11.874c0 .269-.392.537-.873.537-.503 0-.861-.268-.861-.537V7.611c0-.313.358-.526.861-.526.481 0 .873.213.873.526v4.263zM8.5 6.056c-.514 0-.917-.38-.917-.805 0-.426.403-.795.917-.795.504 0 .907.37.907.795 0 .425-.403.805-.907.805z" fill="#9B9B9B" />
        </g>
        <defs>
          <clipPath id="exqis8arsa">
            <path fill="#fff" d="M0 0h17v17H0z" />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
};

export default InfoMeza;
