import { useEffect, useState } from 'react';

const useIsMobile = () => {
  const [width, setWidth] = useState(undefined);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return typeof width === 'number' ? width < 1200 : null;
};

export default useIsMobile;

export const RenderDesktop = ({ children, detectOwn, isMobile: prop }) => {
  const mobile = useIsMobile();
  const isMobile = detectOwn ? mobile : prop;
  return isMobile ? null : children;
};
export const RenderMobile = ({ children, detectOwn, isMobile: prop }) => {
  const mobile = useIsMobile();
  const isMobile = detectOwn ? mobile : prop;
  return isMobile ? children : null;
};


