import React from "react";
import "./../../SCSS/_categoryByScreenSize.scss";

// const categoryScreenSize = [
//   `Upto 32"`,
//   `32-40"`,
//   `40-50"`,
//   `50-60"`,
//   `60-70"`,
//   `Above 70"`,
// ];
const Category_By_ScreenSize = (props) => {
  const categoryScreenSize = props?.screenSizeList?.items;

  return (
    <>
      {categoryScreenSize && categoryScreenSize.length > 0 && (
        <div className="category__screensize__block">
          <p className="section__title">{props?.screenSizeList?.title}</p>
          <div className="row inner__category__screensize__block d-flex justify-content-center">
            {categoryScreenSize &&
              categoryScreenSize.map((item, index) => {
                return (
                  <div
                    className="col-4 col-lg-2 main__screensize__block"
                    key={index}
                  >
                    {item?.deeplinkUrl !== '' && item?.deeplinkUrl !== undefined && item?.deeplinkUrl !== null ?
                      <a
                        href={item?.deeplinkUrl || "#"}
                        target="_blank"
                        rel="noreferrer"
                        className="screensize__block">
                        <p className="screensize_text">{item.image_id}</p>
                      </a> :
                      <div className="screensize__block">
                        <p className="screensize_text">{item.image_id}</p>
                      </div>}                     
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(Category_By_ScreenSize);
