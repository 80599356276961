import React, { useEffect, useState } from "react";
import "./../SCSS/_searchPage.scss";
import SearchProduct from "./../Components/ProductType/SearchProduct";
import Heading5 from "../Components/Font/Heading5";
import TealiumTag from "../Components/helpers/utils/tealium";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import {
  useLocation
} from "react-router-dom";
import apiHelper from "../Components/helpers/utils/apiHelper";
import ReviewTestFreaks from "./ReviewTestFreaks";
import { loadConfigurationSettingData, saveConfigurationSettingData } from "../redux/appAction";
import { loaderEnd, loaderStart } from "../services/services";
import { impressionEvents } from "../Components/helpers/utils/ga/impressionEvents";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Search__Page({handleChangeCartPopup}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = useQuery();
  const getQParams = query.get("q")

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchProductData, setSearchProductData] = useState([]);
  const [availablityData, setAvailablityData] = useState([])
  const [outOfStockConfiguration, setOutOfStockConfiguration] = useState({});
  const currentLang = localStorage.getItem("i18next") || "en";
  const { configurationSettingData } = useSelector((state) => state.appData);
  const [inputValue,setInputValue] = useState()

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e?.target?.value);
  };
  const { customerDetails } = useSelector((state) => state.customerReducer);

  useEffect(async () => {
    let reqParams = getQParams === null ? '' : getQParams
    manageFilter(reqParams);
    setSearch(reqParams);
  }, [getQParams]);

  useEffect(() => {
    dispatch(loaderStart())
    dispatch(loadConfigurationSettingData()).then(()=>{
      dispatch(loaderEnd())
    })
  }, []);

  useEffect(() => {
    // if (Object.values(configurationSettingData).length !== 0 || configurationSettingData === undefined) {
      dispatch(saveConfigurationSettingData(configurationSettingData))
      const getOutOfStock = configurationSettingData?.out_of_stock_configuration || {};
      setOutOfStockConfiguration(getOutOfStock);
    // }
  }, [configurationSettingData]);
  useEffect(() => {
    impressionEvents.impressionGlobalVariable(
      currentLang, "Search Page"
    );
  }, [searchProductData]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    manageFilter(search);
    setSearch(search);
  };

  const manageFilter = async (searchFilterData) => {
    try {
      let paramsData = { filterDetails: { keyword: searchFilterData } }
      dispatch(loaderStart())
      let response = await apiHelper(`/V1/products/filter/apply`, 'post', paramsData, {});
      setSearchProductData(response?.data?.items);
      setAvailablityData(response?.data?.availablityData ?? [])
      setLoading(false);
      dispatch(loaderEnd())
      triggerEvent(searchFilterData,response?.data?.items);
    } catch (error) {
      setSearchProductData([]);
      setLoading(false);
      dispatch(loaderEnd())
    }
  }

  const clearSearchInput = () => {
    setSearch("");
    navigate(`/search?q=`);
  };
  const skusToRemove = ['ser-333', 'ser-295', 'ser-283', 'ser-23', 'ser-244', 'ser-301', 'ser-303', '34567tv'];
  const searchFilterProducts = searchProductData?.filter(product => !skusToRemove.includes(product.sku));
  useEffect(() => {
    const inputElement = document.querySelector('.search__input');
    setInputValue(inputElement.value);
  },[])
  const triggerEvent = (search, data) => {
   const filterData =  data?.filter(product => !skusToRemove.includes(product.sku));
     impressionEvents.impressionSearch(
      filterData,
      search
    );
    impressionEvents.impressionViewProduct(
      filterData,
      'Search Page',
      'Search Page',

    );
  }

  return (
    <>
      {!loading &&
        <ReviewTestFreaks />
      }
      {/* <div className="container-fluid search__page__input__container">
        <div className="search__page__input__block">
          <Heading6 color="#ffffff" text="SEARCH RESULT" marginBottom={10} />
          <div className=" main__search__input__block">
            <form
              className="row"
              autoComplete="off"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className="col-md-10 search__input__block">
                <input
                  type="text"
                  name="search"
                  className="search__input"
                  placeholder={t("Type_Your_Search")}
                  value={search}
                  onChange={(e) => handleChange(e)}
                  autocomplete="false"
                />
                <img
                  onClick={() => clearSearchInput()}
                  src={cancel_grey}
                  alt=""
                  className="cancel__button"
                />
              </div>
              <div
                className="col-md-2 search__button__block">
                <button onClick={handleSubmit} className="search__button">
                  {t("SEARCH")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
      <TealiumTag name="other:search" template="other" />
      <div className="container-fluid search__page__content__container">
        <div className="search__page__content__block">
        <div><Heading5 text={searchFilterProducts && searchFilterProducts?.length != 0 && searchFilterProducts?.length} span={true} />
          <span className="result__text">{searchFilterProducts?.length != 0 && t("Results")}</span></div>
          <div className="row search__product__container">
            <div className="col-md-8 col-lg-9 main__search__product__block">
              {searchFilterProducts &&
                searchFilterProducts.map((product, productIdex) => {
                  return <SearchProduct product={product} availablityData={availablityData} outOfStockConfiguration={outOfStockConfiguration} handleChangeCartPopup={handleChangeCartPopup} customerDetails={customerDetails}/>;
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Search__Page;
