import React, { useEffect, useState,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as services from "./../../services/services";
// import Heading3 from "../Font/Heading4";
import Heading5 from "../Font/Heading5";
// import Heading7 from "../Font/Heading7";
import Price from "../Font/Price";
import Text3 from "../Font/Text3";
// import AvailableOffers from "./AvailableOffers";
// import ProtectionPlan from "./ProtectionPlan";
// import SmallWarrantyBlock from "./SmallWarrantyBlock";
import plus from "./../../assets/Icon/plus.svg";
import minus from "./../../assets/Icon/minus.svg";
import "./../../SCSS/MostSharedComponents/_shoppingCartProduct.scss";
import empty_favourite from "./../../assets/Icon/empty_favourite.svg";
import fulfill_favourite from "./../../assets/Icon/fulfill_favourite.svg";
import delete_icon from "./../../assets/Icon/delete.svg";
import {
  loadAddToWishlist,
  loadCartData,
  loadDeleteFromWishlist,
  loadWishlistData,
} from "../../redux/appAction";
import {
  // addToCart,
  deleteFromCart,
  UpdateFromCart,
} from "../../services/cart.service";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import OldPrice from "../Font/OldPrice";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
// import { replaceImageHelper } from "../helpers/utils/replaceImageHelper";
import { checkWishList } from "../helpers/utils/wishListHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
import useWindowDimensions from "../helpers/utils/useWindowDimensions";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { checkoutEvents } from "../helpers/utils/ga/checkoutEvents";
import LazyImageForProduct from "./LazyImageForProduct";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";
import { MobileBlockImageResize, SmallImageResize } from "../helpers/utils/imageResize";
import ProductDeliveryTypeCart from "./ProductDeliveryTypeCart";
import { impressionEvents } from "../helpers/utils/ga/impressionEvents";

function ShoppipngCartProduct({
  product,
  totalData,
  totals,
  item,
  screenName,
  storeConfig,
  setQtyIsDisabled
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [wishlistCount, setWishlistCount] = useState(0);
  const [pricess, setPricess] = useState(null);
  const [bundleProducts, setBundleProducts] = useState([]);
  // const [isStorePickupAvailable, setIsStorePickupAvailable] = useState(false);
  const [count, setCount] = useState(product.qty);
  // const [plusMinusClickOn, setPlusMinusClickOn] = useState(true);
  const [isChild, setIsChild] = useState(false);
  // const [qtyIsDisabled, setQtyIsDisabled] = useState(false);

  const { setCartErr } = useCartErrorMessage();

  useEffect(() => {
    // if (product.extension_attributes?.inventory_stocks) {
    //   setIsStorePickupAvailable(
    //     !!(Object.entries(product?.extension_attributes?.inventory_stocks || [])
    //       ?.map((item) => ({ store: item[0], qty: item[1] }))
    //       .filter((stor) => stor.store !== "rs97" && stor.store !== "rw20")
    //       .filter((final) => Number(final.qty) !== 0)).length
    //   );
    // }

    if (totals?.extension_attributes?.options_bundle) {
      setBundleProducts(
        totals.extension_attributes.options_bundle
          .filter((itm) => !itm.title.toLowerCase().includes("tv"))
          .map((item) => ({
            option_id: item.option_id,
            title: item.title,
            price: item.price,
          }))
      );
    }
  }, [product]);

  useEffect(() => {
    if (totalData?.items) {
      const prices = totalData?.items.find((item) => {
        return item.item_id === product.item_id;
      });
      setPricess(prices);
      // console.log('productproduct', product);
    }
    setCount(product.qty);
    //  console.log('product', product);
  }, [totalData, product]);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const cartData = useSelector((state) => state.appData.cartData);

  //   useEffect(()=>{
  //     if (cartData?.items?.length !== 0){
  //     const updatecartdata = (cartData?.items?.filter((pro) => pro.sku === product?.sku)[0].qty);
  //     localStorage.setItem("updatecartdata", updatecartdata);
  //     }
  // },[cartData])

  const decreaseCount = (sku, qty) => {
    if (qty <= 0) {
      setCount(0);
      deleteProductFromCart(product.item_id);
    } else {
      AddAfterDeleteFunction(sku, qty, product.item_id, product);
      // deleteProductFromCart(product.item_id)
      // setCount(count - 1);
      // setPlusMinusClickOn(false)
      // AddProductToCart(sku,count-1)
      // if (count <= 1) {
      //     console.log(count,qty)
      //     deleteProductFromCart(product.item_id);
      // } else {
      //     AddAfterDeleteFunction(sku, qty, product.item_id);
      // }
    }
  };
  const optimizedFndecrease = useCallback(debounce(decreaseCount), []);

  const increaseCount = (sku, qty, product) => {
    if (qty > product?.product?.stock) {
      dispatch(services.notifyError({ message: t('Sellable stock is %1').replace('%1', product?.product?.stock) }));
      setCount(product?.product?.stock);
    } else if (qty) {
      AddAfterDeleteFunction(sku, qty, product.item_id, product);
    }
    
  };
  const optimizedFnincrease = useCallback(debounce(increaseCount), []);

  const handleChangeinput = (sku, event) => {
    if (event > product?.product?.stock) {
      setQtyIsDisabled(true);
      dispatch(loadCartData()).then((item) => {
        setCount(item && item.length > 0 && item.filter((pro) => pro.sku === product?.sku)[0].qty);
        setQtyIsDisabled(false);
      }).finally((err) => {
        setQtyIsDisabled(false);
      });
      dispatch(services.notifyError({ message: t('Sellable stock is %1').replace('%1', product?.product?.stock) }));
    } else if (event) {
      AddAfterDeleteFunction(sku, parseInt(event), product.item_id, product);
    }
  }
  const optimizedFninput = useCallback(debounce(handleChangeinput), []);

  const AddAfterDeleteFunction = (sku, count, id, product) => {
    setQtyIsDisabled(true);
    // console.log('producproductproductt',product);
    // const addData = {
    //   items: [
    //     {
    //       sku: sku,
    //       qty: count,
    //       id: product?.product?.id,
    //     },
    //   ],
    // };

    const updateData = {
      // items: [id],
      id: product?.product?.id,
      itemId: id,
      qty: count,
      sku:product?.product?.sku,
    };
    UpdateFromCart(updateData)
      .then((res) => {
        setQtyIsDisabled(true);
        dispatch(loadCartData()).then((item) => {
          setQtyIsDisabled(false);
        }).finally((err) => {
          setQtyIsDisabled(false);
        });
        // dispatch(services.notifyError({ message: "Added in Cart" }));
        // });
        // dispatch(loadCartData());
      })
      .catch((err) => {
        setQtyIsDisabled(false);
        setCartErr({ stock: product?.product?.stock, error: err?.response?.data });
        setCount(product.qty)
        // if (!product.qty) (
        //   setCount(product.qty)
        // )
      });
  };

  // const AddProductToCart = (sku, count, id) => {
  //   const data = {
  //     items: [
  //       {
  //         sku: sku,
  //         qty: count,
  //       },
  //     ],
  //   };
  //   addToCart(data)
  //     .then((res) => {
  //       setCount(res.data.filter((pro) => pro.sku === product.sku)[0].qty);
  //       // dispatch(loadCartData());
  //       // setPlusMinusClickOn(true);
  //     })
  //     .catch((err) => {
  //       dispatch(services.notifyError({ message: err.response.data.message }));
  //     });
  // };

  const wishlistData = useSelector((state) => state.appData.wishlistData);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, product?.sku));
  }, []);

  const handleFavourite = () => {
    if (isFavourite) {
      setIsFavourite(false);
      setWishlistCount(wishlistCount + 1);
     
    } else {
      setIsFavourite(true);
      setWishlistCount(wishlistCount + 1);
      
    }
  };
  useEffect(() => {
    const data = {
      items: [product.sku],
    };
    if (isFavourite) {
      if (wishlistCount > 0) {
        const addToWishlistData = dispatch(loadAddToWishlist(data, t)).then(
          (res) => {
            checkoutEvents.addToWishlist(product, screenName);
            dispatch(loadWishlistData());
          }
        );
      }
    } else {
      if (wishlistCount > 0) {
        if (!isFavourite) {
          checkoutEvents.removeFromWishlist(product, screenName);
          removeFromWL(product.sku);
        }
      }
    }
  }, [isFavourite]);

  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };
    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };

  // const handleSubmit = (code) => { };
  // const handleChange = (e) => { };
  // const remove = (id) => { };


  const deleteProductFromCart = (id) => {
    setQtyIsDisabled(true);
    const data = {
      // items: [id],
      items: [id],
    };
    deleteFromCart(data)
      .then((res) => {
        cartEvents.removeFromCart({ ...product, qty1: count || 1, totalPrice: product?.price * product?.qty || 0 }, cartData?.id, "Cart");
        // dispatch(services.notifyError({ message: "Removed From Cart" }));
        setQtyIsDisabled(true);
        dispatch(loadCartData()).then((item) => {
          setQtyIsDisabled(false);
        }).finally((err) => {
          setQtyIsDisabled(false);
        });
      })
      .catch((err) => {
        dispatch(services.notifyError({ message: err.response.data.message }));
      });
      // impressionEvents.impressionDeleteFromCart(
      //   product, 1,
      //   "Shopping Cart"
      // );
  };

  // const currentTime = new Date();
  // const getCurrentHours = currentTime.getHours();

  let priceValue =
    product.product.discounted_price_rounded === null
      ? product.product.price_rounded
      : product.product.discounted_price_rounded;

  let oldPriceValue = product.product?.price_rounded;

  let savePriceValue = (oldPriceValue - priceValue).toFixed(2);

  let percentageValue = (savePriceValue * 100) / oldPriceValue;
  let percentageValue2Digit = percentageValue.toFixed(2);

  useEffect(() => {
    if (item?.isParentExist === 1 && item?.allChildExist === 1 && item?.isbundleParent === 0) {
      setIsChild(true);
    } else {
      setIsChild(false);
    }
    // if(typeof item?.extension_attributes.bundle_key !=='undefined' && item.sku !== item?.extension_attributes.bundle_key ){
    //   setIsChild(true);

    // } else {
    //   setIsChild(false);

    // }
  }, [item]);
  // let priceValue = 80
  // let oldPriceValue = 100;
  // let savePriceValue = oldPriceValue - priceValue
  // let percentageValue = (savePriceValue * 100) / oldPriceValue
  // let percentageValue2Digit = percentageValue.toFixed(2);

  useEffect(() => {
    if (!product?.product?.sku) {
      deleteProductFromCart(product.item_id, product)
    }
  }, [product?.product?.sku])

  if (!product?.product?.sku) {
    return <></>
  }
  return (
    <>

      <div className={`row sc__product__block ${isChild ? 'isChildProduct' : ''}`}>
        {/* {
          qtyIsDisabled &&
          <div className="cart__pg__loading" >
            <Spinner type={'dark'} />
          </div>
        } */}
        <div className="col-12 col-sm-2 sc__product__left__block">
          <div className="sc__product__image__block">
            <LazyImageForProduct
              product={product?.product}
              srcSetReSize={width <= 992 ? MobileBlockImageResize : SmallImageResize }
              alt=""
              className="sc__product__image"
            />
          </div>
        </div>
        <div className="col-12 col-sm-7 sc__product__middle__block">
          <Link
            className="pd__mb__product__name"
            to={productUrlHelper(
              product?.product?.english_name,
              product?.sku,
              product?.product?.bundle_child_products?.length > 0
            )}
          >
            <Heading5
              text={titleChange(product?.name)}
              marginBottom={10}
            />
          </Link>
          {product?.extension_attributes?.cashback_amount > 0 && (
            <div className="pd__mb__cashback" >
              {t('GET')}
              &nbsp;
              <Price
                price={
                  product?.extension_attributes?.cashback_amount || 0
                }
                size="heading7"
                color="#168F00" 
                currency={
                  product.product.currency
                }
              />
              &nbsp;
              {t('Cashback')}
            </div>
          )}
         {product?.product_type !== 'virtual' && <ProductDeliveryTypeCart
            product={product?.product}
            productData={product}
            t={t}
          />}
          {
            product?.product_type === "virtual" && storeConfig === "1"
              ?
              <div className="virtualProductError">
                {t('storePickupConfictErrorMsg6')}
              </div>
              :
              null
          }

          <div className="sc__counter__block d-flex mt-3 mb-3  align-items-center">
            <div className="row sc__counter__box">
              <div
                onClick={() => {
                  setCount(count - 1); optimizedFndecrease(product.sku, count - 1,product?.item_id);
                  
                }}
                className="col-4 counter__decrease__block"
              >
                <img src={minus} alt="minus" />
              </div>
              <input
                type="number"
                min="1"
                value={count}
                onChange={(event) => { setCount(parseInt(event.target.value)); optimizedFninput(product.sku, event.target.value) }}
                onBlur={(() => {
                  if (isNaN(count)) {
                    setCount(1)
                  }
                })}
              />
              <div
                onClick={() => { setCount(count + 1); optimizedFnincrease(product.sku, count + 1, product);
                  impressionEvents.impressionAddToCart(
                    product, 1,"shopping cart"
                ); }}
                className="col-4 counter__increase__block"
              >
                <img src={plus} alt="plus" />
              </div>
            </div>

            {/* counter end  */}
            <div className="sc__fav__sec me-3 pt-1">
              <button className="common__favourite__button">
                <img
                  onMouseEnter={() => setIsFavouriteHover(true)}
                  onClick={handleFavourite}
                  onMouseLeave={() => setIsFavouriteHover(false)}
                  className={
                    !isFavourite
                      ? "pd__favourite__icon"
                      : "pd__favourite__icon__disable"
                  }
                  src={isFavouriteHover ? fulfill_favourite : empty_favourite}
                  alt=""
                />
                <img
                  onClick={handleFavourite}
                  className={
                    isFavourite
                      ? "pd__favourite__icon"
                      : "pd__favourite__icon__disable"
                  }
                  src={fulfill_favourite}
                  alt=""
                />
              </button>
            </div>
            <div
              className="delete__icon__button__block"
              onClick={() => {deleteProductFromCart(product.item_id, product)}}
            >
              <img src={delete_icon} alt="delete" />
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-3 sc__product__right__block">
          {/* product is not free */}
          {pricess?.discount_percent < 100 && (
            <Price
              price={
                pricess?.base_discount_amount
                  ? (product.price - pricess?.base_discount_amount) + pricess?.base_tax_amount
                  : (product.price * product.qty) + pricess?.base_tax_amount
              }
              accutalPrice={(product.price * product.qty) + pricess?.base_tax_amount}
              isDiscount={pricess?.base_discount_amount ? true : false}
              size="heading3"
              currency={product.product.currency}
            />
          )}
          {/* product is free */}
          {pricess?.discount_percent >= 100 && (
            <Text3
              text={`Free`}
              color="#07A41B"
            />
          )}
          {savePriceValue > 0 && (
            <Text3
              text={`${t('You Save')} - ${product.product.currency} ${savePriceValue} (${percentageValue2Digit}%)`}
              color="#07A41B"
            />
          )}
          {pricess?.base_discount_amount > 0 && (
            <h3 className="heading6 cross-price">
              Sar {pricess?.base_row_total_incl_tax}
            </h3>
          )}
          {/* <Price price={product.price} size="heading4" currency="SAR" /> */}
          {/* <Text3 text="You Save - SAR4.50 (9%)" color="#07A41B" /> */}
          {/* {product.product.price_rounded !== "" &&
        product.product.price_rounded !== 0 ? (
          <OldPrice
            oldPrice={product.product.price_rounded}
            price={"123"}
            size="text3"
            color="#808080"
            marginBottom={0}
            lineThrough={true}
            currency={"SAR"}
          />
        ) : (
          ""
        )} */}
        </div>
      </div>
    </>
  );
}

export default ShoppipngCartProduct;
