import React, { useState, useEffect } from "react";
import "./../../SCSS/_paymentStatus.scss";
import "./../../SCSS/_confirmOrderPage.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { cancelTabbyPayment } from "../../services/cart.service";
import TransactionFailed from "../TransactionFailed";
import ProcessingPayment from "../ProcessingPayment";
import { useDispatch } from "react-redux";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const TabbyCancel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const payment_id = query.get("payment_id");

  const [showTxnError, setTxnError] = useState(false);

  useEffect(() => {
    if (payment_id) {
      localStorage.removeItem("contact_prefill");
      localStorage.removeItem("method");
      localStorage.removeItem("lastOId");
      const cancel = async () => {
        try {
          await cancelTabbyPayment();
          localStorage.setItem("isOrderFailed", true);
          //   navigate("/checkout/failure");
          setTxnError(true);
        } catch (error) {
          setTxnError(true);
        }
      };
      cancel();
    } else {
      navigate("/");
    }
  }, []);
  
  if (showTxnError) return <TransactionFailed />;
  return <ProcessingPayment dispatch={dispatch}/>;
};

export default TabbyCancel;
