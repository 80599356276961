import React, { useState, useEffect } from "react";
import empty_favourite from "./../../assets/Icon/empty_favourite.svg";
import fulfill_favourite from "./../../assets/Icon/fulfill_favourite.svg";
import "./../../SCSS/ProductType/_productSeven.scss";
import Text4 from "./../Font/Text4";
import OldPrice from "./../Font/OldPrice";
import Price from "./../Font/Price";
import Heading6 from "./../Font/Heading6";
import RatingBlock from "../MostSharedComponent/RatingBlock";
import {
  loadAddToWishlist,
  loadDeleteFromWishlist,
  loadWishlistData,
} from "../../redux/appAction";
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../../services/cart.service";
import * as services from "./../../services/services";
import { loadCartData } from "../../redux/appAction";
import {
  selectedCompareProductStatus,
} from "../helpers/utils/compareProductsFilter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import { checkWishList } from "../helpers/utils/wishListHelper";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { checkoutEvents } from "../helpers/utils/ga/checkoutEvents";
import AddToCartNotify from "../Buttons/AddToCartNotify";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";
import ProductDeliveryType from "../MostSharedComponent/ProductDeliveryType";

function ProductSeven({
  productDetailPage,
  product,
  handleChangeCartPopup,
  updateCompareProduct,
  outOfStockConfiguration,
  productIndex,
  screenName,
  availablityData
}) {

  const { setCartErr } = useCartErrorMessage();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sizeButtonIndex, setSizeButtonIndex] = useState(0);

  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [count, setCount] = useState(0);
  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);

  const wishlistData = useSelector((state) => state.appData.wishlistData);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, product?.sku));
  }, []);

  const handleFavourite = () => {
    if (isFavourite) {
      setIsFavourite(false);
      setCount(count + 1);
    } else {
      setIsFavourite(true);
      setCount(count + 1);
    }
  };
  useEffect(() => {
    const data = {
      items: [product.sku],
    };
    if (isFavourite) {
      if (count > 0) {
        const addToWishlistData = dispatch(loadAddToWishlist(data, t)).then(
          (res) => {
            checkoutEvents.addToWishlist(product, screenName);
            dispatch(loadWishlistData());
          }
        );
      }
    } else {
      if (count > 0) {
        if (!isFavourite) {
          checkoutEvents.removeFromWishlist(product, screenName);
          removeFromWL(product.sku);
        }
      }
    }
  }, [isFavourite]);

  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };
    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data, t)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };

  const sizeButtonClick = (sizeIndex, cm, inch) => {
    setSizeButtonIndex(sizeIndex);
  };

  const AddProductToCart = (sku, id) => {
    setLoaderOn(true);
    // deleteProductFromCart(id)
    const data = {
      items: [
        {
          sku: sku,
          qty: 1,
          id: product?.id
        },
      ],
    };
    addToCart(data)
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id, screenName)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
      })
      .finally(() => {
        setLoaderOn(false);
      });
  };

  return (
    <div key={product.id} className="productSeven__block">
      <ProductDeliveryType
        product={product}
        availablityData={availablityData}
        t={t}
      />
      <div className="productSeven__header__block">
        <div className="productSeven__quality__favourite__block">
          <img
            onMouseEnter={() => setIsFavouriteHover(true)}
            onClick={handleFavourite}
            onMouseLeave={() => setIsFavouriteHover(false)}
            className={
              !isFavourite
                ? "productSeven__favourite__icon"
                : "productSeven__favourite__icon__disable"
            }
            src={isFavouriteHover ? fulfill_favourite : empty_favourite}
            alt=""
          />
          <img
            onClick={handleFavourite}
            className={
              isFavourite
                ? "productSeven__favourite__icon"
                : "productSeven__favourite__icon__disable"
            }
            src={fulfill_favourite}
            alt=""
          />
        </div>
        <div className="productSeven__image__block">
          <Link
            to={productUrlHelper(product?.english_name, product?.sku)}
          >
            <img src={product?.originalData?.baseImage} alt="" className="productSeven__image" />
          </Link>
        </div>
        <div className="productSeven__compare__block">
          <input
            type="checkbox"
            className="productSeven__compare__input__check"
            name="compare"
            checked={selectedCompareProductStatus(product.originalData?.id)}
            onChange={() => updateCompareProduct(true, product.originalData)}
          />
          <p className="productSeven__compare__text">
            {t("Select_to_Compare")}
          </p>
        </div>
      </div>
      <Link
        to={productUrlHelper(product?.english_name, product?.sku)}
        className="productSeven__name"
      >
        <Heading6 text={product.productName} marginBottom={10} />
      </Link>
      {/* <p className="productSeven__name">
        <Heading6 text={product.productName} marginBottom={10} />
      </p> */}
      <Text4 text="Z8H SERIES" color="#808080" marginBottom={10} />
      <div className="rating__block">
        <RatingBlock
          rating={product?.rating}
          totalRatings={product?.totalRatings ?? ''}
        />
      </div>
      <p className="productSeven__series">{t("Model_and_Price")}</p>
      <div className="productSeven__size__button__block">
        {product?.size?.map((size, sizeIndex) => {
          return (
            <button
              key={size.id}
              onClick={() => sizeButtonClick(sizeIndex, size.cm, size.inch)}
              className={
                sizeButtonIndex === sizeIndex
                  ? "productSeven__size__button__active"
                  : "productSeven__size__button"
              }
            >
              {/* {`${size.cm} cm (${size.inch})`} */}
              {`${size.inch}"`}
            </button>
          );
        })}
      </div>
      <div className="prize__block">
        {product?.oldPrice !== null && (
          <OldPrice
            oldPrice={product.oldPrice}
            price={product.price}
            size="text3"
            color="#c8c8c8"
            marginBottom={10}
            lineThrough={true}
            span={true}
            currency={product?.currency}
          />
        )}
        <Price
          price={product.price}
          marginLeft={5}
          marginBottom={10}
          size="heading6"
          span={true}
          currency={product?.currency}
        />
      </div>
      {product?.is_bundle_enable === 1 ? (
        <Link
          to={productUrlHelper(product?.english_name, product?.sku)}
          style={{ textDecoration: 'none' }}
        >
          <AddToCartNotify
            product={product}
            AddProductToCart={AddProductToCart}
            outOfStockConfiguration={outOfStockConfiguration}
            loaderOn={loaderOn || spinnerOn}
          />
        </Link>
      ) : (
        <AddToCartNotify
          product={product}
          AddProductToCart={AddProductToCart}
          outOfStockConfiguration={outOfStockConfiguration}
          loaderOn={loaderOn || spinnerOn}
        />
      )}
    </div>
  );
}

export default ProductSeven;
