import React, { useEffect } from "react";
import { useState } from "react";
import BreadCrumbs from "../Components/BreadCrumbs";
import Heading3 from "../Components/Font/Heading3";
import "./../SCSS/_myProfilePage.scss";
import PersonalInfoForm from "../Components/Form/PersonalInfoForm";
import AddressForm from "../Components/Form/AddressForm";
import PasswordForm from "../Components/Form/PasswordForm";
import { useTranslation } from "react-i18next";
import MetaComponent from "../Components/Common/MetaComponent";
import { useLocation } from "react-router-dom";
import TealiumTag from "../Components/helpers/utils/tealium";
import AddressPopupModal from "../Components/Popup/AddressPopupModal";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const addressData = [
  {
    id: 0,
    isDefault: true,
    addressType: "Home",
    userName: "John Doe",
    adddress: "21 West 52nd Street New York, New York, 10021 United States",
    contact: "+1123456789",
  },
  {
    id: 1,
    isDefault: false,
    addressType: "Office",
    userName: "Martin Smith",
    adddress: "21 West 52nd Street New York, New York, 10021 United States",
    contact: "+1123456789",
  },
];
function My_Profile_Page() {

  const query = useQuery();
  const getPage = query.get("page");
  //  console.log("getPage ", getPage);

  const [tab, setTab] = useState("personal");
  const [addressPopup, setAddressPopup] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let getTab = getPage === null || getPage === "" ? "personal" : getPage;
    setTab(getTab);
  }, [getPage]);

  // const togglePassword = () => setIsPassword(!isPassword);
  // const toggleConfirmPassword = () => setIsConfirmPassword(!isConfirmPassword);
  const closeLoginPopup = () => {
    if (document.querySelector(".address__popup__container")) {
      // reloadingHeader()
      const element = document.querySelector(".address__popup__container");
      element.classList.remove("address__popup__container");
      element.classList.add("address__popup__container__disable");
    }
    setAddressPopup(false);
  };

  const handleAddressPopup = (value) => {
    setAddressPopup(value);
  };

  return (
    <>
      <MetaComponent
        title={t("My_Profile")}
        meta_title={t("My_Profile")}
        meta_keywords={t("My_Profile")}
        meta_description={t("My_Profile")}
      />
      <TealiumTag name="my account" template="my account" />
      <BreadCrumbs
        back="/user"
        firstBread="/user"
        firstBreadTitle={t("my_account")}
        secondBread="/user/profile"
        secondBreadTitle={t("My_Profile")}
        highlight="secondBreadTitle"
      />
      <div className="container-fluid profile__page__container ">
        <div className="profile__page__block">
          <div className="profile__page__title__block">
            <Heading3 text={t("My_Profile")} />
          </div>
          <div className="profile__page__content__block">
            <div className="profile__tab__button__block">
              <button
                className={
                  tab === "personal"
                    ? "profile__page__button__active"
                    : "profile__page__button"
                }
                onClick={() => setTab("personal")}
              >
                {t("Personal_Info")}
              </button>
              <button
                className={
                  tab === "addresses"
                    ? "profile__page__button__active"
                    : "profile__page__button"
                }
                onClick={() => setTab("addresses")}
              >
                {t("Addresses")}
              </button>
              <button
                className={
                  tab === "change-password"
                    ? "profile__page__button__active"
                    : "profile__page__button"
                }
                onClick={() => setTab("change-password")}
              >
                {t("Change_Password")}
              </button>
            </div>
            <div
              className={
                tab === "personal"
                  ? "personal__block"
                  : "personal__block__disable"
              }
            >
              <PersonalInfoForm />
            </div>
            <div
              className={
                tab === "addresses" ? "address__block" : "address__block__disable"
              }
            >
              <AddressForm
                addressData={addressData}
                closeLoginPopup={closeLoginPopup}
                handleAddressPopup={handleAddressPopup}
              />
            </div>
            <div
              className={
                tab === "change-password"
                  ? "password__block"
                  : "password__block__disable"
              }
            >
              <PasswordForm />
            </div>
          </div>
        </div>
      </div>

      {addressPopup === true &&
        <AddressPopupModal closeLoginPopup={closeLoginPopup} />
      }
    </>
  );
}

export default My_Profile_Page;
