import React from "react";
import { useTranslation } from "react-i18next";
import Accordion from "react-bootstrap/Accordion";

function GenreFacet({ facetData, facetKey, desktop = false, eventKey = 3 }) {
  const constructParamFn = (item) => `${item.key}`;
  const { t } = useTranslation();
  const childrenFn = (item) => (
    <>
      <label>{item.label}</label>
      <span>{`(${item.count})`}</span>
    </>
  );
  return (
    <>
      {" "}
      {desktop ? (
        <Accordion.Item eventKey={eventKey}>
          <h1>{t("Genre")}</h1>
          <Accordion.Body>

          {facetData[facetKey]?.collection?.map((item, itemIndex) => (
            <div key={`facet_item_${facetKey}${itemIndex}`}>
              <div>
                <a
                  className="facet-category-item"
                  // onClick={() => toggleFilter(attrKey, constructParamFn(item), facetData[facetKey].multiple_selectable)}
                >
                  {childrenFn(item)}
                </a>
              </div>
            </div>
          ))}
          </Accordion.Body>
        </Accordion.Item>
      ) : (
        <>
          <h1>{t("Genre")}</h1>
          {facetData[facetKey]?.collection?.map((item, itemIndex) => (
            <div key={`facet_item_${facetKey}${itemIndex}`}>
              <div>
                <a
                  className="facet-category-item"
                  // onClick={() => toggleFilter(attrKey, constructParamFn(item), facetData[facetKey].multiple_selectable)}
                >
                  {childrenFn(item)}
                </a>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default GenreFacet;
