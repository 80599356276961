import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { TAMARA_INSTALLMENT_PLAN_URL } from "../globalConst";

const TamaraProductWidget = ({ price, currency, months, minLimit, maxLimit, currentLanguageCode }) => {

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (window.TamaraProductWidget) {
  //       window.TamaraProductWidget.init({ lang: "en" })
  //       window.TamaraProductWidget.render()
  //     }
  //   }, 500)
  // }, []);

  return (
    <React.Fragment>
      <input type="hidden" id="languageInput" value={currentLanguageCode} />
      <Helmet>
        <script src="/tamara-card-script.js" />
        <script src={TAMARA_INSTALLMENT_PLAN_URL} /> 
      </Helmet>
      <div >
        <tamara-widget type="tamara-summary" inline-type={2} amount={price} />
      </div>
      {/* <div
        className="tamara-installment-plan-widget"
        data-lang={currentLanguageCode}
        data-price={price}
        data-currency="SAR"
        data-color-type="default"
        data-country-code="SA"
        data-number-of-installments={months}
        data-installment-minimum-amount={minLimit}
        data-installment-maximum-amount={maxLimit}
      >
      </div> */}
  </React.Fragment>
  );
};

export default TamaraProductWidget;