import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  // let basename = "ar-sa";
  // if (localStorage.getItem("i18next") === "en") {
  //   basename = "en-sa";
  // }
  const handleClearMobileFilterModalUI = () => {
    document.querySelectorAll(".modal-backdrop").forEach(el => el.remove());
    const filterByModal = document.getElementById('filterByModal')
    filterByModal?.classList?.remove('show');
    if (filterByModal) {
      filterByModal.style.display = 'none'
      filterByModal.ariaHidden = true
    }
    document?.body?.removeAttribute('style');
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    handleClearMobileFilterModalUI();
  }, [pathname]);

  return null;
}