import { useState } from 'react';
import Heading7 from '../Font/Heading7';
import { Spinner } from '../helpers/utils/spinner';
import {
  niqatyConfirmOtp,
  niqatyInquire,
  niqatyRedeem,
} from '../../services/cart.service';
import { useForm } from 'react-hook-form';
import {
  redeemMezaOTPRegExp,
  redeemMezaPhoneRegExp,
} from '../helpers/utils/regexp';
import { useDispatch } from 'react-redux';
const niqaty = require("./../../assets/Icon/niqaty.png")

const LoyaltyNiqatyProgram = ({
  t,
  services,
  stepDetails,
  setPayWithLoyalty,
  loyaltyLoading,
  handleNiqatyComplete,
}) => {
  const { step } = stepDetails;
  const [pending, setPending] = useState(false);
  const dispatch = useDispatch();

  const { register, errors, handleSubmit, getValues } = useForm({
    mode: 'onSubmit',
    defaultValues: { phone: '' },
    shouldUnregister: false,
  });

  const {
    register: registerRedeemForm,
    errors: errorsRedeemForm,
    handleSubmit: handleRedeemFormSubmit,
    watch: watchRedeemForm,
    setValue: setRedeemFormValue,
    getValues: getRedeemFormValues,
    reset: resetRedeemForm,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      redeemCodes: [],
      redeemCode: '',
      redeemAmount: 0,
      token: '',
    },
    shouldUnregister: false,
  });

  const {
    register: registerConfirmOtp,
    errors: errorsConfirmOtp,
    handleSubmit: handleConfirmOtpSubmit,
    setValue: setConfirmOtpValue,
    getValues: getConfirmOtpValues,
    reset: resetConfirmOtp,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: { transactionId: '', otp: '' },
    shouldUnregister: false,
  });

  const redeemCodes = watchRedeemForm('redeemCodes');

  const handleNiqatyInquire = async (data) => {
    try {
      setPending(true);
      const response = await niqatyInquire(data?.phone);
      const _redeemCodes = Array.isArray(response)
        ? response
        : Object.values(response?.options);
      setRedeemFormValue('redeemCodes', _redeemCodes);
      setRedeemFormValue(
        'redeemCode',
        Object.values(response?.options)[0]?.redeemCode
      );
      setRedeemFormValue(
        'redeemAmount',
        Object.values(response?.options)[0]?.redeemAmount
      );
      setRedeemFormValue('token', response?.token);
      setPayWithLoyalty({
        ...stepDetails,
        step: 2,
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.message_localized ? t(error?.response?.data?.message_localized) : error?.response?.data?.message ? error?.response?.data?.message : t('Something went wrong.');
      dispatch(services.notifyError({ message: errorMessage }));
    } finally {
      setPending(false);
    }
  };

  const handleNiqatyRedeem = async () => {
    try {
      setPending(true);
      const redeemCode = getRedeemFormValues('redeemCode');
      const redeemAmount = getRedeemFormValues('redeemAmount');
      const redeemToken = getRedeemFormValues('token');
      const phone = getValues('phone');
      const payload = {
        redeemCode,
        redeemAmount,
        token: redeemToken,
      };
      const response = await niqatyRedeem(phone, payload);
      setConfirmOtpValue('transactionId', response?.transactionId);
      setPayWithLoyalty({
        ...stepDetails,
        points: redeemAmount,
        code: redeemCode,
        step: 3,
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.message_localized ? t(error?.response?.data?.message_localized) : error?.response?.data?.message ? error?.response?.data?.message : t('Something went wrong.');
      dispatch(services.notifyError({ message: errorMessage }));
      setPayWithLoyalty({
        ...stepDetails,
        step: 1,
      });
      resetRedeemForm();
    } finally {
      setPending(false);
    }
  };

  const handleNiqatyConfirmOtp = async (data) => {
    try {
      setPending(true);
      const phone = getValues('phone');
      const transactionId = getConfirmOtpValues('transactionId');
      const otp = getConfirmOtpValues('otp');
      const payload = {
        otp,
        transactionId,
      };
      await niqatyConfirmOtp(phone, payload);
      handleNiqatyComplete();
      dispatch(services.notifySuccess({ message: 'Points applied' }));
    } catch (error) {
      const errorMessage = error?.response?.data?.message_localized ? t(error?.response?.data?.message_localized) : error?.response?.data?.message ? error?.response?.data?.message : t('Something went wrong.');
      dispatch(services.notifyError({ message: errorMessage }));
      setPayWithLoyalty({
        ...stepDetails,
        step: 1,
      });
      resetConfirmOtp();
    } finally {
      setPending(false);
    }
  };

  return (
    <div className="payment__form__main__block">
      <div className="payment__detail__form__block">
        {step === 1 && (
          <form onSubmit={handleSubmit(handleNiqatyInquire)}>
            {(loyaltyLoading || pending) && <div className="payment_process_loading"></div>}
            <div className="address__content__block">
              <div className="payment__card__block mezza__text">
                <div className="row payment__form__field__row">
                  <div className="col-sm-12 col-md-2 main__form__field__block">
                    <div className="field__block">
                      <img src={niqaty} alt="card" />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3 main__form__field__block ">
                    <Heading7
                      text={t('Mobile_phone_number')}
                      marginBottom={10}
                    />
                    <div className="field__block">
                      <input
                        id="phone"
                        name="phone"
                        placeholder={t('ie. 0512345678')}
                        ref={register({
                          required: t('Phone number is missing'),
                          pattern: {
                            value: redeemMezaPhoneRegExp,
                            message: t('Invalid phone number'),
                          },
                        })}
                        className="form__field"
                        onFocus={({ target }) =>
                          target.parentElement.classList.add('focused')
                        }
                        onBlur={({ target }) =>
                          target.parentElement.classList.remove('focused')
                        }
                        autoFocus
                        autoComplete="off"
                        onKeyUp={(data) => {
                          if (!data.target.value.length) {
                            data.target.parentElement.classList.add('focused');
                          }
                        }}
                      />
                    </div>
                    {errors.phone && (
                      <p className="invalid__message">{errors.phone.message}</p>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-3 main__form__field__block">
                    <div className="field__block">
                      <button
                        type="submit"
                        //   onClick={() => applyForLoyaltyOtp()}
                        className="btn apply_btn w-100 h-20"
                        disabled={(loyaltyLoading || pending) ? true : false}
                      >
                        {(loyaltyLoading || pending) ? <Spinner /> : t('Apply')}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row payment__form__field__row"></div>
              </div>
            </div>
          </form>
        )}

        {step === 2 && (
          <form onSubmit={handleRedeemFormSubmit(handleNiqatyRedeem)}>
            {(loyaltyLoading || pending) && <div className="payment_process_loading"></div>}
            <div className="address__content__block">
              <div className="payment__card__block mezza__text">
                <div className="row payment__form__field__row">
                  <div className="col-sm-12 col-md-2 main__form__field__block">
                    <div className="field__block">
                      <img src={niqaty} alt="card" />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3 main__form__field__block">
                    <Heading7
                      text={t('Select Redeem Option')}
                      marginBottom={10}
                    />
                    <select
                      id="redeemCode"
                      name="redeemCode"
                      ref={registerRedeemForm({ required: t('Required') })}
                      onChange={(e) => {
                        const redeemOption = redeemCodes.find(
                          (option) =>
                            option?.redeemCode === Number(e.target.value)
                        );
                        setRedeemFormValue(
                          'redeemCode',
                          Number(e.target.value)
                        );
                        setRedeemFormValue(
                          'redeemAmount',
                          Number(redeemOption.redeemAmount)
                        );
                      }}
                    >
                      {redeemCodes.map((option) => (
                        <option
                          key={option?.redeemCode}
                          value={option?.redeemCode}
                        >
                          {`${option?.redeemAmount} SAR - ${option?.redeemPoints} points`}
                        </option>
                      ))}
                    </select>

                    {errorsRedeemForm.redeemCode && (
                      <p className="invalid__message">
                        {errorsRedeemForm.redeemCode.message}
                      </p>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-3 main__form__field__block">
                    <div className="field__block">
                      <button
                        type="submit"
                        className="btn apply_btn w-100"
                        disabled={(loyaltyLoading || pending) ? true : false}
                      >
                        {(loyaltyLoading || pending) ? <Spinner /> : t('Confirm')}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row payment__form__field__row"></div>
              </div>
            </div>
          </form>
        )}

        {step === 3 && (
          <form onSubmit={handleConfirmOtpSubmit(handleNiqatyConfirmOtp)}>
            {(loyaltyLoading || pending) && <div className="payment_process_loading"></div>}
            <div className="address__content__block">
              <div className="payment__card__block mezza__text">
                <div className="row payment__form__field__row">
                  <div className="col-sm-12 col-md-2 main__form__field__block">
                    <div className="field__block">
                      <img src={niqaty} alt="card" />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3 main__form__field__block">
                    <Heading7 text={t('Code')} marginBottom={10} />
                    <div className="field__block">
                      <input
                        type="text"
                        id="otp"
                        name="otp"
                        ref={registerConfirmOtp({
                          required: t('OTP is missing'),
                          pattern: {
                            value: redeemMezaOTPRegExp,
                            message: t('Invalid OTP'),
                          },
                        })}
                        onFocus={({ target }) =>
                          target.parentElement.classList.add('focused')
                        }
                        onBlur={({ target }) =>
                          target.parentElement.classList.remove('focused')
                        }
                        autoFocus
                        autoComplete="off"
                        onKeyUp={(data) => {
                          if (!data.target.value.length) {
                            data.target.parentElement.classList.add('focused');
                          }
                        }}
                        onChange={(e) =>
                          setConfirmOtpValue('otp', e.target.value)
                        }
                      />
                    </div>
                    {errorsConfirmOtp.otp && (
                      <p className="invalid__message">
                        {errorsConfirmOtp.otp.message}
                      </p>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-3 main__form__field__block">
                    <div className="field__block">
                      <button
                        type="submit"
                        className="btn apply_btn w-100"
                        disabled={(loyaltyLoading || pending) ? true : false}
                      >
                        {(loyaltyLoading || pending) ? <Spinner /> : t('Confirm')}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row payment__form__field__row"></div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default LoyaltyNiqatyProgram;
