import React from "react";
import "./../../SCSS/ProductListPage/_plpBannerSectionOne.scss"
const PLPBannerSectionOne = (props) => {
  return (
    <div>
      <div className="row banner__section__one">
        <div className="col-7 banner__section__one__title__block">
          <p className="banner__section__one__title">{props.title}</p>
        </div>
        <div className="col-5 banner__section__one__image__block">
          <img
            className="banner__section__one__image"
            src={`${process.env.REACT_APP_PROJECT_MEDIA_API_URL}${props.bannerImage}`}
            alt={props.title}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(PLPBannerSectionOne)
