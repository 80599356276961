import React, { useState, useMemo, useEffect } from "react";
// import grey_down_arrow from "./../assets/Icon/grey_down_arrow.svg";
// import grey_up_arrow from "./../assets/Icon/grey_up_arrow.svg";
import "./../SCSS/_accordian.scss";
import Heading5 from "./Font/Heading5";
import Heading6 from "./Font/Heading6";
// import Heading7 from "./Font/Heading7";
// import Text4 from "./Font/Text4";
import { useTranslation } from "react-i18next";
import FlixMedia from "../Pages/FlixMedia";
import useWindowDimensions from "./helpers/utils/useWindowDimensions";
// import ReviewTestFreaks from "../Pages/ReviewTestFreaks";

// const FlixMedia = React.lazy(() => import('../Pages/FlixMedia'), { ssr: false });
const ReviewTestFreaks = React.lazy(() => import("../Pages/ReviewTestFreaks"), {
  ssr: false,
});

function Accordian({ data, accordianName, sku, ean, mpn, isReview, product_overview }) {
  const currentLang = localStorage.getItem("i18next") || "en";
  
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const newSku = useMemo(() => sku, [sku]);

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(false);
  }, [newSku]);
  const Product_Overview_data = product_overview ? product_overview.slice(3, -4) : width > 992 && <FlixMedia sku={sku} ean={ean} mpn={mpn} />;
  return (
    <div className="accordian__block">
      <div
        className="accordian__header__block"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Heading5 text={data.title} />        
        <span className="accodian-active-status" >{isOpen ? t("Hide") : t("Show")}</span>
      </div>
      <div
        className={
          isOpen
            ? "accordian__detail__block"
            : "accordian__detail__block__disable"
        }
      >
        {accordianName === "description" && (
          <div className="accordian__description__block">
            {data.description}
          </div>
        )}
        {accordianName === "specification" && (
          <div className="accordian__description__block">
            {data.description || ''}
          </div>
        )}
        {accordianName === "product_overview" && (
          <div className="accordian__description__block">
            {/* <Heading6 text={t('Pdp_Description')} marginBottom={10} /> */}
            {/* <Text4 text={product_overview} marginBottom={20} /> */}
            {Product_Overview_data}
          </div>
        )}
        {isReview === true && (
          <div className="accordian__description__block">
            {width > 992 && <ReviewTestFreaks reviewsDiv={true} />}
          </div>
        )}
        <div className={`accordian__data__block review${currentLang}`}>
          <Heading6 text={data?.keyValueTitle} marginBottom={20} />
          <div className="accordian__keyValue__block">
            {/* {data?.keyValueData.map((keyValue, keyValueIndex) => {
              return (
                <div className="keyValue row" key={keyValue.id}>
                  <div className="col-3 key">
                    <Heading7 text={keyValue.key} marginBottom={10} />
                  </div>
                  <div className="col-9 value">
                    <Text4 text={keyValue.value} marginBottom={10} />
                  </div>
                </div>
              );
            })} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accordian;
