import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import * as services from './../../../services/services';

const useCartErrorMessage = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [err, setCartErr] = useState({
        error: "",
        stock: ""
    });

    const { error, stock } = err;

    useEffect(() => {
        if (error) {
            const firstErrorMessage = error?.message?.split(',')?.[0];
            if (firstErrorMessage === 'exceedMaxQuantity') {
                const message = t('Sellable stock is %1').replace('%1', stock);
                dispatch(services.notifyError({ message }));
            } else if (firstErrorMessage === 'maxQuantityLimit') {
                const allowedQuantity = error?.message?.split(',')?.[1];
                const message = t('Allowed quantity for this product is %1').replace('%1', allowedQuantity);
                dispatch(services.notifyError({ message }));
            } else {
                const message = error?.name === 'TypeError' ? t('No internet connection') : t(error?.message);
                dispatch(services.notifyError({ message }));
            }
        }
    }, [error, stock]);

    return { setCartErr };
};

export default useCartErrorMessage;