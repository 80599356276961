import React from "react";

function SoldOutProductLabel({ t, product, outOfStockConfiguration }) {
    const productOutOfStockStatus = product && product?.productOutOfStockStatus ? product?.productOutOfStockStatus : false
    const isOutOfStock = product?.orderType === 'out-of-stock' || product?.stock === 0;

    return (
        <React.Fragment>
            {product && isOutOfStock && outOfStockConfiguration?.module_status && productOutOfStockStatus ?
                <div className="soldOutLable">
                    {t('SOLD OUT')}
                </div> : null
            }
        </React.Fragment>
    );
}

export default React.memo(SoldOutProductLabel);