import { loadProductStatusData } from "../../../redux/appAction";

export default function OutStockHelper(dispatch, products, outOfStockConfiguration) {
  const productStatus = dispatch(loadProductStatusData(products && products.map((item) => item.id)));

  let newArray = []
  products && products.map((item) => {
    let productOut = productStatus?.data?.[item.id]?.productOutOfStockStatus ? productStatus?.data?.[item.id]?.productOutOfStockStatus : false;
    // item['productOutOfStockStatus'] = productOut

    const isOutOfStock = item?.orderType === 'out-of-stock' || item?.stock === 0;
    const isPreOrder = item?.orderType === 'pre-order';

    if (item && isOutOfStock && outOfStockConfiguration?.module_status && productOut) {
      newArray.push(item); // notify-me
    } else {
      if (isOutOfStock) {
        newArray.push(item); // out-of-stock
      } else if (isPreOrder) {
        newArray.push(item); // pre-order
      } else {
        newArray.push(item); // add-cart
      }
    }
  });
  return newArray
}

