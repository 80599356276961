import axios from "axios";

export const getHomeEntertainmentPageData = async () => {
  try {
    const homeEntertainmentData = await axios.get(
      `${process.env.REACT_APP_PROJECT_API_URL}/V1/cms/home-entertainment`
    );
    return homeEntertainmentData.data;
  } catch (e) {
    throw new Error('Network response was not ok')
  }
}

export const registerForBraviaLaunch = async (data) => {
  try {
    const homeEntertainmentData = await axios.post(
      `${process.env.REACT_APP_PROJECT_API_URL}/V1/sony/bravia-registration`,data
    );
    return homeEntertainmentData.data;
  } catch (e) {
    if(e.response?.data?.message === "DUPLICATE_EMAIL"){
      return {success: false,  message: 'DUPLICATE_EMAIL'}
    }
    return {success: false,  message: 'Error occured when registering'}
  }
}