import React from "react";
import { useTranslation } from "react-i18next";

function Audio_Page() {
  const { t } = useTranslation();
  return (
    <>
      <div>{t("Audio_Page")}</div>
    </>
  );
}

export default Audio_Page;
