import _uniqBy from "lodash.uniqby";

export const compareProductsFilter = (product) => {

    // let returnArray = compareProductList;
    let returnArray = [];
    let compare_products = JSON.parse(localStorage.getItem("compare_products"));
    if (compare_products !== null) {
        returnArray = compare_products;
    }
    if (returnArray.length <= 2) {
        const arrayOfProduct = [...returnArray, product];
        let uniqProducts = _uniqBy(arrayOfProduct, 'id');
        if (returnArray.length === 0) {
            returnArray = uniqProducts
        } else if (returnArray.length > 0) {
            if (returnArray?.[0]?.attribute_set_id === product?.attribute_set_id) {
                returnArray = uniqProducts
            } else {
                returnArray = [product]
            }
        }
    } else {
        if (returnArray?.[0]?.attribute_set_id !== product?.attribute_set_id) {
            returnArray = [product]
        } else {
            let prodCheck = returnArray && returnArray.filter(item => item.id === product.id);
            if (prodCheck.length === 0) {
                localStorage.setItem('compare_products_max_3', JSON.stringify(true));
            } else {
                localStorage.setItem('compare_products_max_3', JSON.stringify(false));
            }
        }
    }
    localStorage.setItem('compare_products', JSON.stringify(returnArray));
    return returnArray;
}
export const selectedCompareProductStatus = (productId, compareProductList) => {

    let defaultStatus = false;
    let compare_products = JSON.parse(localStorage.getItem("compare_products"));
    if (compare_products !== null) {
        let checkProduct = compare_products && compare_products.filter((item, i) => item.id === productId);
        if (checkProduct.length > 0) {
            defaultStatus = true;
        }
    }
    return defaultStatus;
}
