import axios from "axios";

export const getFilterData = async (filterDetails) => {
  let responseData = {};
  await axios
    .post(
      `${process.env.REACT_APP_PROJECT_API_URL}/V1/products/filter/details`,
      filterDetails
    )
    .then((res) => (responseData = res));
  return responseData.data;
};
export const getApplyFilterData = async (filterDetails) => {
  let responseData = {};
  await axios
    .post(
      `${process.env.REACT_APP_PROJECT_API_URL}/V1/products/filter/apply`,
      filterDetails
    )
    .then((res) => (responseData = res))
    .catch((error) => {
      responseData = { data: { total_count: 0, items: [] } };
    });

  // const productStatus = await productOutOfStockStatus(responseData?.data?.items);
  // if (responseData?.data?.items) {
  //   responseData && responseData?.data && responseData?.data?.items.forEach((item) => {
  //     item.productOutOfStockStatus = productStatus?.data[item.id] ? productStatus?.data[item.id] : false;
  //   });
  // }
  return responseData.data;
};

export const getProductsOfCategory = async (filterData) => {
  let responseData = {};
  await axios
    .get(
      // `${process.env.REACT_APP_PROJECT_API_URL}/V1/category/${filterData.id}/products?limit=${filterData?.limit}&offset=${filterData?.offset}&sortBy=${filterData?.sortBy}`
      `${process.env.REACT_APP_PROJECT_API_URL}/V1/category/${filterData.id}/products`
    )
    .then((res) => (responseData = res));

  return responseData;
};
