import * as actionType from "./../../actionType";

const initialState = {
  loading: false,
  notifyMsg: '',
  cmsPageContent: '',
  loadingStartEnd: false,
  compareListUpdate: false,
};

function commonReducer(state = initialState, action) {
  switch (action.type) {
    case actionType.LOADER_START_END:
      return { ...state, loadingStartEnd: action.payload };
    case actionType.LOADER_START:
      return { ...state, loading: true };
    case actionType.LOADER_END:
      return { ...state, loading: false };
    case actionType.NOTIFY_SUCCESS_MSG:
      return { ...state, notifyMsg: action.payload };
    case actionType.NOTIFY_ERROR_MSG:
      return { ...state, notifyMsg: action.payload };
    case actionType.NOTIFY_CLEAR_MSG:
      return { ...state, notifyMsg: action.payload };
    case actionType.CMS_PAGE_CONTENT:
      return { ...state, cmsPageContent: action.payload };
    case actionType.COMPARE_LIST_UPDATE:
      return { ...state, compareListUpdate: action.payload };
    default:
      return state;
  }
}
export default commonReducer;