import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import './../../SCSS/MostSharedComponents/_pickupStore.scss'
import pickup_store from "./../../assets/Icon/pickup_store.svg";
import Heading5 from "../Font/Heading5";
import Heading6 from "../Font/Heading6";
import pinIcon from '../../assets/Icon/pin.svg';
import pinMiles from '../../assets/Icon/pin-miles.svg';
import Heading7 from "../Font/Heading7";
import Text4 from "../Font/Text4";
import { useTranslation } from "react-i18next";

function PickupStoreCheckout({ title, pickupStore, selectedStoreId, handleChangePickupStore }) {
	const { t } = useTranslation()
	const handleChangeStore = (i) => {
		handleChangePickupStore(i);
	}

	// const closeStorePopup = (i) => {
	// 	handleChangePickupStore('');
	// }

	// useEffect(() => {

	// });

	return (
		<div className="product__pickup__block">
			<Heading6 text={title} marginBottom={15} />
			<div className="product__store__block">
				<img src={pickup_store} alt="" className="product__store__icon" />
				<div className="pickup__text__block">
					<Link
						// onClick={() => handleOpenPop()}
						className="product__store__location__link"
						to="/store" data-bs-toggle="modal" data-bs-target="#pickupModal"
					>{`See all pickup locations >`}</Link>
				</div>
			</div>
			{selectedStoreId && pickupStore.filter((item, i) => i === selectedStoreId).map((store, storeIndex) => {
				return (
					<div key={store.id} className="product__store__block">
						<img src={pickup_store} alt="" className="product__store__icon" />
						<div className="pickup__text__block">
							<p className="product__pickup__text">
								<span className="product__pickup__only__text">Pickup:</span>
								{`${store.address}, ${store.city}, ${store.region}`}
							</p>
						</div>
					</div>
				);
			})}

			{/* mobile modal popup for store */}
			<div className="modal mb__bottom_popup" id="pickupModal" tabindex="-1" aria-labelledby="pickupModalLabel" aria-hidden="true">
				<div className="modal-dialog mb__dialog__end modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-header">
							<Heading5 text="See All Pick Up Location" />
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							// onClick={() => closeStorePopup()}
							></button>
						</div>
						<div className="modal-body">
							{pickupStore.map((store, i) => {
								return (
									<div className="d-flex pickup__box">
										<span>
											<img src={pinIcon} alt="pinIcon" />
										</span>
										<div className="ms-2">
											<Heading7 text={store.name} />
											<Text4 text={`${store.address}, ${store.city}, ${store.region}`} />
											<button data-bs-toggle="modal" data-bs-target="#pickupModal" onClick={() => handleChangeStore(i)} className="btn btn__primary__orange py-2 mt-3">
												{t("CONFIRM_LOCATION")}
											</button>
										</div>
										<span className="ms-sm-5 ps-2 text-center mb__pin__right">
											<img src={pinMiles} alt="miles" />
											{/* <Heading7 text="2 miles" /> */}
										</span>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>
		</div>

	);
}

export default PickupStoreCheckout;
