
const usePassCheck = (pass) => {
// Min 1 uppercase letter.
// Min 1 lowercase letter.
// Min 1 number.
// Min 8 characters.
  const pasRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  if(pasRegex.test(pass)) return true;

  return false
};

export default usePassCheck;
