import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import './../SCSS/_paymentStatus.scss';
import Heading2 from "../Components/Font/Heading2";
import paymentCancle from './../assets/Icon/cancle-circle.svg';
import { useTranslation } from 'react-i18next';
import { getOrderDetails } from "../services/order.service";
import { deleteCart, createCartDetails, addToCart, updateShippingInformation, getCartData, createBillingAddress } from "./../services/cart.service";
import { useNavigate } from "react-router-dom";
import { loaderEnd } from '../services/services';
import ProcessingPayment from './ProcessingPayment';
import { loadCartData } from '../redux/appAction';
import { impressionEvents } from '../Components/helpers/utils/ga/impressionEvents';

function Payment_Failure_Page() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, SetLoading] = useState(true);

  // restore cart
  useEffect(() => {
    const lastOrderId = localStorage.getItem('lod') ? JSON.parse(localStorage.getItem('lod')) : "";
    const isOrderFailed = localStorage.getItem('isOrderFailed');
    const shippingAsBilling = localStorage.getItem('shippingAsBilling');
   
    if (lastOrderId) {
      localStorage.setItem('lastOId', lastOrderId);
      localStorage.removeItem('isOrderFailed');
      localStorage.removeItem('lod');
      const restoreCart = async () => {
        const data = await getOrderDetails(lastOrderId, isOrderFailed);
        impressionEvents.impressionPaymentFail(data?.payment?.method,'Payment failed due wrong card or Insufficient Balance or internal error');
        await deleteCart();
        let childItems = {};
        const remainingItems = [];
        for (let index = 0; index < data.items.length; index++) {
          const item = data.items[index];
          if (item.parent_item_id) {
            const previousItems = childItems[item.parent_item_id] || [];
            childItems = {
              ...childItems,
              [item.parent_item_id]: [...previousItems, item.sku],
            };
          } else {
            remainingItems.push(item);
          }
        }
        const cartItems = [];
        remainingItems.forEach((item) => {
          if (item.product_type === 'bundle') {
            // eslint-disable-next-line array-callback-return
            const bundle_option = item.product.bundle_product_options.map((value) => {
              let option_selection = [];
              // eslint-disable-next-line array-callback-return
              value.product_links.map((select_opt) => {
                const childBundleProducts = childItems[item.item_id];
                // eslint-disable-next-line array-callback-return
                childBundleProducts.map((option) => {
                  if (select_opt.sku === option) {
                    option_selection = [select_opt.id];
                  }
                });
              });
              return { option_id: value.option_id, option_qty: 1, option_selections: option_selection };
            });

            cartItems.push({
              sku: item?.sku,
              qty: item?.qty_ordered,
              id: typeof item?.product?.id !== 'undefined' ? item?.product?.id : item.product_id,
              bundle_options: bundle_option,
              extension_attributes: {
                restore_id: Number(lastOrderId),
              },
            });
          } else {
            cartItems.push({
              sku: item?.sku,
              id: typeof item?.product?.id !== 'undefined' ? item?.product?.id : item.product_id,
              qty: item?.qty_ordered,
              extension_attributes: {
                restore_id: Number(lastOrderId),
              },
            });
          }
        });
        const createCarts = await createCartDetails();
        await addToCart({ items: cartItems });
        const allCartItems = await getCartData();

        allCartItems.data.billing_address = data.billing_address;
        allCartItems.data.extension_attributes.shipping_assignments = data.extension_attributes.shipping_assignments;
        const billingAddress = {
          firstName: data?.billing_address?.firstname,
          lastName: data?.billing_address?.lastname,
          telephone: data?.billing_address?.telephone,
          countryId: data?.billing_address?.country_id,
          addressLine1: data?.billing_address?.street.join(' '),
          city: data?.billing_address?.city,
          email: data?.billing_address?.email,
          postCode: data?.billing_address?.postcode,
          parent_id: data?.billing_address?.parent_id,

        };
        let shippingAsBill = false;
        if (shippingAsBilling === 'true') {
          shippingAsBill = true;
        } else {
          shippingAsBill = false;
        }
        const addresses = data?.extension_attributes?.shipping_assignments[0].shipping.address;
        let shippingAddress;
        if (addresses) {
          shippingAddress = {
            firstName: addresses.firstname,
            lastName: addresses.lastname,
            telephone: addresses.telephone,
            countryId: addresses.country_id,
            city: addresses.city,
            postCode: addresses.postcode,
            street: addresses?.street.join(' '),
            email: addresses.email,
            useAsBilling: shippingAsBill,
            shippingCarrierCode: localStorage.getItem('last-dt') ? localStorage.getItem('last-dt') : 'flatrate',
            region_id: localStorage.getItem('last-dt') === 'storepickup' ? '76' : (
              addresses.region_id === undefined || addresses.region_id === ""
                ? 0
                : addresses.region_id
            ),
          };
          if (localStorage.getItem('last-dt') === 'storepickup') {
            shippingAddress.pickup_store = localStorage.getItem('pickup_store');
          }
        }
        await dispatch(createBillingAddress(billingAddress));
        if (addresses) {
          await dispatch(updateShippingInformation(shippingAddress));
        }
        await dispatch(loadCartData());
        SetLoading(false);
        navigate('/checkout/failure?result=failure');
      };
      restoreCart();
    } else {
      navigate('/checkout');
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      dispatch(loaderEnd())
    }
  }, [loading])

  if (loading) {
    return <ProcessingPayment dispatch={dispatch} />;
  }
  // restore cart
  return (
    <>
      <div className="payment__status__container container-fluid">
        <div className="payment__status__block">
          <img src={paymentCancle} alt="" className="panyment__success__icon" />
          <Heading2 text={t("Payment Failure")} marginBottom={20} color="#a3a1a1" />
          <Link to="/checkout/delivery" className="continue__shopping__btn">{t("Try_Again")}</Link>
        </div>
      </div>

    </>
  )
}

export default Payment_Failure_Page