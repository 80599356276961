import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./../../SCSS/Popup/_plpComparePopup.scss";
import cancel_grey from "./../../assets/Icon/cancel_grey.svg";
import compare_white from "./../../assets/Icon/compare_white.svg";
import Heading6 from "../Font/Heading6";
import Heading7 from "../Font/Heading7";
import { Link } from "react-router-dom";
import { updateCompareProductList } from "../../services/cart.service";
import { useTranslation } from "react-i18next";
import { titleChange } from "../helpers/utils/titleNameChange";
import LazyImageForProduct from "../MostSharedComponent/LazyImageForProduct";
import { ImageResize } from "../helpers/utils/imageResize";

function PLPComparePopup({ closeComparePopup, refreshComparePopup, setRefreshcomparePopup }) {

  const dispatch = useDispatch();
  const { t } = useTranslation()
  const [pageRender, setPageRender] = useState(false);

  useEffect(() => {
    if (pageRender === true || refreshComparePopup === true) {
      setPageRender(false);
      setRefreshcomparePopup(false);
    }
  }, [pageRender, refreshComparePopup]);

  const comparePopupLocalData = JSON.parse(localStorage.getItem("compare_products"));
  const compareProductErrMsg = JSON.parse(localStorage.getItem("compare_products_max_3"));

  const removeProductFromCompare = (productId) => {
    let removeData = comparePopupLocalData && comparePopupLocalData.filter(item => item.id !== productId);
    localStorage.setItem('compare_products', JSON.stringify(removeData));
    localStorage.setItem('compare_products_max_3', JSON.stringify(false));
    dispatch(updateCompareProductList(removeData))
    setPageRender(true);
    if (removeData.length === 0) {
      closeComparePopup();
    }
  }

  const removeAllProductFromCompare = () => {
    localStorage.removeItem("compare_products");
    localStorage.setItem('compare_products_max_3', JSON.stringify(false));
    dispatch(updateCompareProductList([]))
    closeComparePopup()
    setPageRender(true)
  }

  return (
    <div className="plp__compare__popup__block">
      {comparePopupLocalData !== null &&
        <>
          <div className="plp__compare__popup__header">
            <Heading6 text={`${t("Compare_Products")} (${comparePopupLocalData.length}/3)`} color="#000000" />
            <img
              onClick={() => closeComparePopup()}
              src={cancel_grey}
              alt="cancel"
              className="cancel__button"
            />
          </div>
          <div className="plp__compare__popup__content">
            {comparePopupLocalData && comparePopupLocalData?.map((product, productIndex) => {
              return (
                <div key={productIndex} className="compare__product__block">
                  <div className="compare__product__header">
                    <img
                      onClick={() => removeProductFromCompare(product.id)}
                      src={cancel_grey}
                      alt="cancel"
                      className="cancel__button"
                    />
                  </div>
                  <div className="compare__product__content">
                    <LazyImageForProduct
                      product={product}
                      srcSetReSize={ImageResize}
                      alt={product?.name}
                      className=""
                    />
                    <Heading7 text={titleChange(product?.name)} />
                  </div>
                </div>
              );
            })}
          </div>
          {compareProductErrMsg === true &&
            <p className="invalid_err_message ms-3">{t("You_have_selected_the_maximum_3_products")}</p>
          }
          {comparePopupLocalData.length === 1 &&
            <p className="invalid_err_message ms-3">{t("Please_select_at_least_2_products_to_compare")}</p>
          }
          <div className="plp__compare__popup__button__block">            
            <Link to="#" onClick={() => removeAllProductFromCompare()}>
              <button className="removeAll__button"> {t("Remove_All")}</button>
            </Link>
            {comparePopupLocalData.length > 1 &&
              <Link to="/products/compare" onClick={() => closeComparePopup()}>
                <button className="compare__button">
                  <img
                    // onClick={() => closeComparePopup()}
                    src={compare_white}
                    alt="cancel"
                    className="compare__button__icon"
                  />
                  {t("Compare")}
                </button>
              </Link>
            }
          </div>
        </>
      }
    </div>
  );
}

export default PLPComparePopup;
