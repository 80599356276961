import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./../SCSS/_paymentStatus.scss";
import Heading2 from "../Components/Font/Heading2";
import paymentSuccess from "./../assets/Icon/success_check.svg";
import * as services from "../services/services";
import moment from "moment";
import Heading4 from "../Components/Font/Heading4";
import Heading6 from "../Components/Font/Heading6";
import Text2 from "../Components/Font/Text2";
import Text3 from "../Components/Font/Text3";
import TealiumTag from '../Components/helpers/utils/tealium';
import "./../SCSS/_confirmOrderPage.scss";
import { useTranslation } from "react-i18next";
import { getEstimatedDelivery } from "../Components/helpers/utils/getEstimatedDelivery";
import { loadCartData, loadCitiesLocationData } from "../redux/appAction";
import { checkoutEvents } from "../Components/helpers/utils/ga/checkoutEvents";
import { impressionEvents } from "../Components/helpers/utils/ga/impressionEvents";

function Payment_Success_Page() {
  const [order_id, setOrderID] = useState(null);

  useEffect(() => {
    localStorage.removeItem('lod');
    localStorage.removeItem('last-setPaymentMethod');
    localStorage.removeItem('last-dt');
    localStorage.removeItem('deliveryPref');

    localStorage.removeItem('shippingAddress');
    localStorage.removeItem('billingAddress');
    localStorage.removeItem("shippingAsBilling");
    setOrderID(JSON.parse(localStorage.getItem("OrderID")));
  }, []);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { customerOrderDetails } = useSelector(
    (state) => state.customerOrdersReducer
  );

  const cityLocationData = useSelector(
    (state) => state.appData.cityLocationData
  );

  const [orderDetails, setOrderDetails] = useState("");
  const [storeCitiesLocationData, setStoreCitiesLocationData] = useState([]);
  const currentLang = localStorage.getItem("i18next") || "en";
  useEffect(() => {
    if (order_id !== null) {
      let params = {
        id: order_id,
      };
      dispatch(loadCitiesLocationData());
      dispatch(services.getCustomerOrderDetails(params));
    }
  }, [order_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadCartData())
  }, []);

  useEffect(() => {
    if (customerOrderDetails) {
      setOrderDetails(customerOrderDetails);
      setOrderActivity(customerOrderDetails)
    }
  }, [customerOrderDetails]);

  const setOrderActivity = (orderDetails) => {
    impressionEvents.impressionPurchases(
      orderDetails,
      'Order Detail'
    );
    // checkoutEvents.purchase({
    //   transaction_id: orderDetails?.increment_id.toString(),
    //   affiliation: null,
    //   revenue: Number(parseFloat(orderDetails?.grand_total).toFixed(2)),
    //   tax: Number(parseFloat(orderDetails?.tax_amount).toFixed(2)),
    //   shipping: Number(parseFloat(orderDetails?.shipping_amount).toFixed(2)),
    //   coupon: orderDetails?.coupon_code || null,
    //   metric1: Number(parseFloat(orderDetails?.extension_attributes?.bss_storecredit_amount || 0.00).toFixed(2)),
    //   dimension3: orderDetails?.payment?.last_trans_id || null,
    //   currency: orderDetails?.orderDetails?.global_currency_code || "SAR"
    // }, orderDetails.items.map((item) => ({
    //   item_name: item?.product?.name || item?.name,
    //   item_id: item?.product?.id || item?.id,
    //   price: Number(parseFloat(item?.base_price).toFixed(2)),
    //   discount: Number(parseFloat(item?.base_discount_amount).toFixed(2)),
    //   brand: item?.product?.options?.find((option) => option.code === 'brand')?.selected_option?.label || null,
    //   category: item?.product?.categories?.pop()?.name || 'Sony',
    //   variant: null,
    //   quantity: item?.qty_ordered,
    //   dimension1: item?.sku,
    // })));
  }

  useEffect(() => {
    if (cityLocationData) {
      setStoreCitiesLocationData(cityLocationData);
    }
  }, [cityLocationData]);

  const cityCode = orderDetails?.extension_attributes?.shipping_assignments[0]?.shipping?.address?.city;
  const cityData = storeCitiesLocationData.find((c) => c.cityCode === cityCode);
  const additionalEstimatedDays = orderDetails && Math.max(
    ...(orderDetails?.items?.map(item => item.product?.additional_estimated_day || 0))
  );

  const finalExpeDate = getEstimatedDelivery({
    orderDate: orderDetails?.created_at,
    additionalEstimatedDays,
    cityData,
    format: 'dddd, Do MMMM',
  });

  useEffect(() => {
    impressionEvents.impressionGlobalVariable(
      currentLang, "Payment Page"
    );
  }, [orderDetails]);
  return (
    <>
      {orderDetails !== "" && (
        
        <div className="payment__status__container container-fluid">
          
          <div className="payment__status__block">
            <img
              src={paymentSuccess}
              alt=""
              className="panyment__success__icon"
            />
            <Heading2
              text={t('Payment_Success')}
              marginBottom={20}
              color="#059405"
            />
            <Heading4
              text={`${t('Hey')} ${orderDetails.billing_address.firstname} ${orderDetails.billing_address.lastname}`}
              textAlign="center"
            />

            <Heading2
              text={t('Your_order_is_confirmed')}
              color="#DC3A1A"
              marginBottom={20}
              textAlign="center"
            />
            <Link to={`/user/orders/${order_id}`} className="orderid__link">

              <Text3
                text={`${t('Order_number_is')} : ${orderDetails.increment_id}`}
                marginBottom={20}
                textAlign="center"
              />
            </Link>
            <TealiumTag name="thank_you" template="checkout" />
            {orderDetails?.is_virtual === 0 ?
              <>
                <p className="order__success__text">
                  <Text2
                    text={orderDetails?.extension_attributes?.shipping_assignments && orderDetails?.extension_attributes?.shipping_assignments[0]?.shipping?.method === "storepickup_storepickup"
                      ? t('storepickup_message') : t('Your_item_has_been_on_the_way_expected_arriving_date_is')}
                    span={true}
                  //   textAlign="center"
                  />
                  <Heading6
                    // text="Wednesday, 21th September 2022"
                    text={finalExpeDate}
                    span={true}
                    //   textAlign="center"
                    marginLeft={10}
                  />

                  {/* <Text2
                text="& Shiiping Address Is"
                span={true}
                //   textAlign="center"
                marginLeft={10}
              />
              <Heading6
                text={`${orderDetails.billing_address.street[0]} ${orderDetails.billing_address.city} ${orderDetails.billing_address.postcode} ${orderDetails.billing_address.country_id}`}
                span={true}
                //   textAlign="center"
                marginLeft={10}
              /> */}
                </p>
                <p className="order__success__email__text">
                  <Text2
                    text={`${t('Your_will_receive_an_email_confirmation_shortly_at')} ${customerOrderDetails.customer_email}`}
                    span={true}
                    textAlign="center"
                  />
                </p>
              </>
              :
              (
                <p className="order__success__text">
                  <Text2
                    text={t('You_will_receive_the_digital_code_soon_in_your_email')}
                    span={true}
                  //   textAlign="center"
                  />
                </p>
              )
            }
            <Link to="/" className="continue__shopping__btn">
              {t("CONTINUE_SHOPPING")}
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default Payment_Success_Page;
