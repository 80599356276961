import React, { useState } from "react";
import Heading7 from "../Font/Heading7";
import Price from "../Font/Price";
import RatingBlock from "../MostSharedComponent/RatingBlock";
import "./../../SCSS/ProductType/_recommandProducts.scss";
import { getLoyaltyEarnTypesData, loadCartData } from "../../redux/appAction";
import { addToCart } from "../../services/cart.service";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import OldPrice from "../Font/OldPrice";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import LazyImageForProduct from "../MostSharedComponent/LazyImageForProduct";
import AddToCartNotify from "../Buttons/AddToCartNotify";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";
import { useTranslation } from "react-i18next";
import SoldOutProductLabel from "../MostSharedComponent/SoldOutProductLabel";
import { SmallImageResize } from "../helpers/utils/imageResize";
import { impressionEvents } from "../helpers/utils/ga/impressionEvents";

function RecommandProducts({ product, handleChangeCartPopup, outOfStockConfiguration, productIndex,screenName }) {
  const [loaderOn, setLoaderOn] = useState(false);
  const { t } = useTranslation();
  const [spinnerOn, setSpinnerOn] = useState(false);
  const { setCartErr } = useCartErrorMessage();
  // const { t } = useTranslation();
  const dispatch = useDispatch();

  const AddProductToCart = (sku, id) => {
    impressionEvents.impressionAddToCart(
      product, 1
    );
    setLoaderOn(true);

    const data = {
      items: [
        {
          sku: sku,
          qty: 1,
          id:id
        },
      ],
    };

    addToCart(data)
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id, screenName)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
        dispatch(getLoyaltyEarnTypesData());
      })
      .catch((err) => {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
        setLoaderOn(false);
      })
      .finally(() => {
        setLoaderOn(false);
      });
  };

  return (
    <div key={product.id} className="row recommand__product__block">
      <div className="col-4 recommand__product__image__block">
        <LazyImageForProduct
          product={product}
          srcSetReSize={SmallImageResize}
          alt=""
          className="recommand__product__image"
        />
        <SoldOutProductLabel
          t={t}
          product={product}
          outOfStockConfiguration={outOfStockConfiguration}
          />
      </div>
      <div className="col-8 recommand__product__detail__block">
        <Link
          className="pd__mb__product__name"
          to={productUrlHelper(product?.english_name, product?.sku)}
        >
          <Heading7 text={titleChange(product?.name)} />
        </Link>
        <RatingBlock
          size={15}
          rating={4.6}
          totalRatings={2100}
          fillColor="#DC3A1A"
          emptyColor="#C8C8C8"
        />
        <div className="prize__block">
          <OldPrice
            oldPrice={product?.price_rounded}
            price={
              product?.discounted_price_rounded === null
                ? product?.price_rounded
                : product?.discounted_price_rounded
            }
            size="text3"
            color="#c8c8c8"
            marginBottom={10}
            lineThrough={true}
            span={true}
            currency={product?.currency}
          />
          <Price
            price={
              product?.discounted_price_rounded === null
                ? product?.price_rounded
                : product?.discounted_price_rounded
            }
            marginLeft={5}
            marginBottom={10}
            size="heading6"
            span={true}
            currency={product?.currency}
          />
        </div>
        
      </div>
      <div className="col-md-12 recommand__product__detail__block">
      <AddToCartNotify
          product={product}
          AddProductToCart={AddProductToCart}
          outOfStockConfiguration={outOfStockConfiguration}
          loaderOn={loaderOn || spinnerOn}
          type={product?.id}
        />
      </div>
    </div>
  );
}

export default RecommandProducts;
