import React, { useEffect, useState } from 'react';
import signin_initial from '../../assets/Icon/signin_initial.svg';
import signin_inprogress from '../../assets/Icon/signin_inprogress.svg';
import signin_done from '../../assets/Icon/signin_done.svg';
import delivery_done from '../../assets/Icon/delivery_done.svg';
import payment_initial from '../../assets/Icon/payment_initial.svg';
import payment_inprogress from '../../assets/Icon/payment_inprogress.svg';
import payment_done from '../../assets/Icon/payment_done.svg';
import done from '../../assets/Icon/done.svg';
import mezzacard from '../../assets/Icon/mezzacard.png';
import Heading6 from '../Font/Heading6';
import Heading7 from '../Font/Heading7';
import CartRedeemLoyalty from './CartRedeemLoyalty';
import CartLoyaltyBonus from './CartLoyaltyBonus';
import CartApplyStoreCredit from './CartApplyStoreCredit';
import {
  getAvailablePaymentMethods,
  getEstimateShippingMethods,
  getPaymentMethod,
  verifyLoyaltyPoints,
} from '../../services/cart.service';
import { Spinner } from '../helpers/utils/spinner';
import { isRedeemMezaPhone } from '../helpers/utils/validators';
import { useSelector } from 'react-redux';
import { getLoyaltyEarnTypesData, loadCartData, resetNiqatyFlow } from '../../redux/appAction';
import { createLoyaltyOtp } from '../../services/customer/customer';
import AmwalMagentoReactButton from 'amwal-magento-react-button';
import { useNavigate } from 'react-router-dom';
import OrderSummary from './OrderSummary';
import CheckoutPaymentMethods from './CheckoutPaymentMethods';
import LoyaltyNiqatyProgram from './LoyaltyNiqatyProgram';

const CheckoutPaymentWrapper = ({
  t,
  isAppleDevice,
  tamara6MonthStatus,
  tamara3MonthStatus,
  checkVirtualItemsInCart,
  currentLanguageCode,
  setRedirectPageUrl,
  setPending,
  showApplePay,
  pending,
  loyaltyEarnTypeId,
  cartDetail,
  mokafaApplied,
  BASE_PAYMENT_REDIRECT_URL,
  ecom,
  cartTotalData,
  custEmailAddress,
  customerDetails,
  dispatch,
  services,
  currentLang,
  cartData,
  hideSection,
  setCartTotalData,
}) => {
  let quitafcard = require(`../../assets/Icon/icon-qitaf-${currentLang}.png`);
  let lastOrderId = localStorage.getItem('lod')
    ? JSON.parse(localStorage.getItem('lod'))
    : '';
  const localStorageHandshakeToken = localStorage.getItem('handShakeToken');

  const isAmwalEnabled = ecom?.featureFlags?.ecom?.bootstraper?.amwal;

  const [deliveryType, setDeliveryType] = useState([]);
  const [loyaltyEarnType, setLoyaltyEarnType] = useState([]);
  const [card, setCard] = useState({
    cardNumber: '',
    cardHolder: '',
    monthYear: '',
    month: '',
    year: '',
    cvv: '',
  });
  const [cardErrMsg, setCardErrMsg] = useState({
    cardNumber: '',
    cardHolder: '',
    monthYear: '',
    month: '',
    year: '',
    cvv: '',
  });
  const [forceRender, setForceRender] = useState(false);
  const [iconType, setIconType] = useState({
    signin: 'done',
    delivery: 'done',
    payment: 'initial',
  });
  const [loyaltyLoading, setLoyaltyLoading] = useState(false);
  const [payWithLoyalty, setPayWithLoyalty] = useState({
    phone: '',
    code: '',
    points: '',
    step: 1,
  });
  const [payWithLoyaltyErr, setPayWithLoyaltyErr] = useState({
    phone: '',
    code: '',
    points: '',
  });
  const [userPaymentMethod, setUserPaymentMethod] = useState();
  const [amwalState, setAmwalState] = useState();
  const { configurationSettingData } = useSelector((state) => state.appData);
  const loyaltyEarnTypeList = useSelector(
    (state) => state.appData.loyaltyEarnTypeList
  );
  const niqatyShouldReset = useSelector((state) => state.appData.niqatyResetFlow);

  const navigate = useNavigate();

  useEffect(() => {
    if (userPaymentMethod === 'niqaty') {
      setUserPaymentMethod(null);
    }
    dispatch(resetNiqatyFlow(false));
  }, [niqatyShouldReset]);

  useEffect(() => {
    setAmwalState(cartData?.totals_data?.grand_total);
  }, [cartData?.totals_data?.grand_total]);

  useEffect(() => {
    setForceRender((prevState) => !prevState);
  }, [amwalState]);

  // Note - redirection to cart page
  useEffect(() => {
    if (
      deliveryType &&
      deliveryType.length > 0 &&
      cartData &&
      cartData?.items?.length &&
      lastOrderId !== ''
    ) {
      if (
        deliveryType &&
        deliveryType.filter((dev) => dev.id === 'flatrate' && dev?.is_available)
          .length < 1 &&
        deliveryType &&
        deliveryType.filter(
          (dev) => dev.id === 'storepickup' && dev?.is_available
        ).length < 1 &&
        !cartData?.is_virtual
      ) {
        navigate('/cart');
      }
    }
  }, [deliveryType, cartData]);

  useEffect(() => {
    const lastPayment = localStorage.getItem('last-setPaymentMethod');
    if (lastPayment) {
      reserCardData();
      if (
        lastPayment !== 'meza' &&
        lastPayment !== 'qitaf' &&
        lastPayment !== 'mokafa'
      ) {
        let params = {
          email: custEmailAddress,
          paymentMethod: {
            method: lastPayment,
          },
        };
        getPaymentMethodOrderData(params);
      } else {
        setPayWithLoyalty({
          ...payWithLoyalty,
          phone: '',
          code: '',
          points: '',
          step: 1,
        });
        setPayWithLoyaltyErr({
          ...payWithLoyaltyErr,
          phone: '',
          code: '',
          points: '',
        });
      }
    }
  }, []);

  useEffect(() => {
    if (customerDetails === '') {
      setIconType({ ...iconType, signin: 'inprogress' });
    } else {
      dispatch(services.getCustomerAddressList());
      setIconType({ ...iconType, signin: 'done' });
    }
  }, [customerDetails]);

  useEffect(() => {
    const errorElement = document.querySelector('.invalid__message');
    if (errorElement && document.activeElement.tagName !== 'INPUT') {
      window.scrollTo(0, Position(errorElement));
    }
  }, [cardErrMsg]);

  useEffect(() => {
    if (loyaltyEarnTypeList) {
      setLoyaltyEarnType(loyaltyEarnTypeList);
    } else {
      dispatch(getLoyaltyEarnTypesData());
    }
  }, [loyaltyEarnTypeList]);

  // Delivery Preferences
  useEffect(async () => {
    if (
      configurationSettingData?.store_pickup !== undefined &&
      configurationSettingData?.store_pickup !== ''
    ) {
      const data = await getEstimateShippingMethods();
      if (data) {
        let shippingMethods = data['shipping-methods'];
        const propertyNames = Object.keys(shippingMethods);
        let deliveryData = [];
        propertyNames &&
          propertyNames.map((val) => {
            let deliveryInfo = {
              id: shippingMethods[val].shipping_method_code,
              type: shippingMethods[val].title,
              protectionText: shippingMethods[val].head_line,
              price: shippingMethods[val].shipping_cost,
              is_available: shippingMethods[val]?.is_available,
            };
            if (
              shippingMethods[val].shipping_method_code === 'storepickup' &&
              configurationSettingData?.store_pickup === '1'
            ) {
              deliveryData.push(deliveryInfo);
            } else if (
              shippingMethods[val].shipping_method_code !== 'storepickup'
            ) {
              deliveryData.push(deliveryInfo);
            }
          });
        setDeliveryType(deliveryData);
      }
    }
  }, [configurationSettingData?.store_pickup, cartData]);

  const openLoginWrapperFromAnywhere = () => {
    if (customerDetails === '') {
      const element = document.querySelector('.signin__signup__popup__disable');
      if (element !== null) {
        element.classList.remove('signin__signup__popup__disable');
        element.classList.add('signin__signup__popup');
      }
      localStorage.setItem('loginWrapper', JSON.stringify(true));
      localStorage.setItem('loginMode', JSON.stringify('signin'));
      localStorage.setItem('loginPopup', JSON.stringify(true));

      window.scrollTo(500, 0);
    }
  };

  const handleBlur = (e) => {
    const event = {
      target: {
        name: e.target.name,
        value: e.target.value.trim(),
      },
    };
    handleChangeLoyalty(event);
  };

  const applyForLoyaltyOtp = () => {
    if (payWithLoyalty.phone === '') {
      setPayWithLoyaltyErr({
        ...payWithLoyaltyErr,
        phone: t('Mobile_number_is_missing'),
      });
    } else {
      let checkPhone = isRedeemMezaPhone(payWithLoyalty.phone);
      if (checkPhone === 'error') {
        setPayWithLoyaltyErr({
          ...payWithLoyaltyErr,
          phone: t('Invalid_mobile_number'),
        });
      } else {
        setPayWithLoyaltyErr({ ...payWithLoyaltyErr, phone: '' });
        setLoyaltyLoading(true);
        let params = {
          phone: payWithLoyalty.phone,
          program: userPaymentMethod,
        };
        let respData = createLoyaltyOtp(params);
        respData
          .then(() => {
            setLoyaltyLoading(false);
            setPayWithLoyalty({
              ...payWithLoyalty,
              step: 2,
            });
          })
          .catch((err) => {
            setLoyaltyLoading(false);
            dispatch(
              services.notifyError({ message: err?.response?.data?.message })
            );
          });
      }
    }
  };

  const confirmLoyaltyPointsPayment = () => {
    if (payWithLoyalty.points === '' && payWithLoyalty.code === '') {
      let newErrObj = payWithLoyaltyErr;
      if (payWithLoyalty.points === '') {
        newErrObj = { ...newErrObj, points: t('Points_is_missing') };
      }
      if (payWithLoyalty.code === '') {
        newErrObj = { ...newErrObj, code: t('Code_is_missing') };
      }
      setPayWithLoyaltyErr(newErrObj);
    } else {
      setLoyaltyLoading(true);
      let params = {
        phone: payWithLoyalty.phone,
        amount: payWithLoyalty.points,
        program: userPaymentMethod,
        otp: payWithLoyalty.code,
      };
      let respData = verifyLoyaltyPoints(params);
      respData
        .then(() => {
          setLoyaltyLoading(false);
          setPayWithLoyalty({
            ...payWithLoyalty,
            step: '',
          });
          dispatch(loadCartData());
          dispatch(services.loaderStart());
          dispatch(getAvailablePaymentMethods());
          dispatch(services.loaderEnd());
          dispatch(getLoyaltyEarnTypesData());
        })
        .catch((err) => {
          setLoyaltyLoading(false);
          dispatch(
            services.notifyError({ message: err?.response?.data?.message })
          );
        });
    }
  };

  const handleNiqatyComplete = () => {
      setLoyaltyLoading(true);
      setPayWithLoyalty({
        ...payWithLoyalty,
        phone: '',
        code: '',
        points: '',
        step: 1,
      });
      setPayWithLoyaltyErr({
        phone: '',
        code: '',
        points: '',
      });
      setUserPaymentMethod();
      dispatch(loadCartData());
      dispatch(services.loaderStart());
      dispatch(getAvailablePaymentMethods());
      dispatch(services.loaderEnd());
      dispatch(getLoyaltyEarnTypesData());
      setLoyaltyLoading(false);
  };

  const handleChangeLoyalty = (event) => {
    let value = event.target.value.replace(/[^0-9]/g, '');
    let name = event.target.name;
    setPayWithLoyalty({
      ...payWithLoyalty,
      [name]: value,
    });
    if (value) {
      setPayWithLoyaltyErr({ ...payWithLoyaltyErr, [name]: '' });
      if (name === 'phone') {
        let checkPhone = isRedeemMezaPhone(value);
        if (checkPhone === 'error') {
          if (!value.trim()) {
            setPayWithLoyaltyErr({
              ...payWithLoyaltyErr,
              [name]: t('Mobile_number_is_missing'),
            });
          } else {
            setPayWithLoyaltyErr({
              ...payWithLoyaltyErr,
              [name]: t('Invalid_mobile_number'),
            });
          }
        }
      }
      // if (name === "points") {
      //   let checkPoint = isRedeemMezaPoint(value);
      //   if (checkPoint === 'error') {
      //     setPayWithLoyaltyErr({ ...payWithLoyaltyErr, [name]: "Invalid point" })
      //   }
      // }
    } else {
      if (name === 'phone') {
        setPayWithLoyaltyErr({
          ...payWithLoyaltyErr,
          [name]: t('Mobile_number_is_missing'),
        });
      } else if (name === 'code') {
        setPayWithLoyaltyErr({
          ...payWithLoyaltyErr,
          [name]: t('Code_is_missing'),
        });
      } else if (name === 'points') {
        setPayWithLoyaltyErr({
          ...payWithLoyaltyErr,
          [name]: t('Points_is_missing'),
        });
      }
    }
  };

  function Position(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return currenttop > 200 ? [currenttop - 200] : [0];
    }
  }

  const reserCardData = () => {
    setCard({
      ...card,
      cardNumber: '',
      cardHolder: '',
      monthYear: '',
      month: '',
      year: '',
      cvv: '',
    });
    setCardErrMsg({
      ...cardErrMsg,
      cardNumber: '',
      cardHolder: '',
      monthYear: '',
      month: '',
      year: '',
      cvv: '',
    });
  };

  // cart total data update when change payment method
  const getPaymentMethodOrderData = async (params) => {
    await getPaymentMethod(params)
      .then((res) => {
        if (res && res?.data && typeof res?.data === 'object') {
          setCartTotalData(res?.data);
        }
      })
      .catch(() => {});
  };

  const handleChangePaymentMethod = (e) => {
    reserCardData();
    setUserPaymentMethod(e.target.value);
    localStorage.setItem('last-setPaymentMethod', e.target.value);
    if (
      e.target.value !== 'meza' &&
      e.target.value !== 'qitaf' &&
      e.target.value !== 'mokafa' &&
      e.target.value !== 'niqaty'
    ) {
      let params = {
        email: custEmailAddress,
        paymentMethod: {
          method: e.target.value,
        },
      };
      getPaymentMethodOrderData(params);
    } else {
      setPayWithLoyalty({
        ...payWithLoyalty,
        phone: '',
        code: '',
        points: '',
        step: 1,
      });
      setPayWithLoyaltyErr({
        ...payWithLoyaltyErr,
        phone: '',
        code: '',
        points: '',
      });
    }
  };

  return (
    <div className="container-fluid checkout__page__container d-lg-block d-none">
      <div className="checkout__page__block">
        <div className="row checkout__page__inner__block">
          <div className="col-md-12 col-xl-8  checkout__left__block">
            <div className="row checkout__login__main__block">
              <div
                onClick={() => openLoginWrapperFromAnywhere()}
                className="col-2 checkout__signin__button"
              >
                <img
                  src={
                    iconType.signin === 'inprogress'
                      ? signin_inprogress
                      : iconType.signin === 'done'
                      ? signin_done
                      : signin_initial
                  }
                  alt=""
                />
                <Heading6
                  text={t('Sign_In')}
                  marginLeft={10}
                  color={
                    iconType.signin === 'inprogress'
                      ? '#DC3A1A'
                      : iconType.signin === 'done'
                      ? '#585858'
                      : '#C8C8C8'
                  }
                  span={true}
                />
                {iconType.signin === 'done' ? (
                  <img className="done__icon" src={done} alt="done" />
                ) : (
                  ''
                )}
              </div>
              <div className="col-3 checkout__middle__line__block"></div>
              <div className="col-2 checkout__delivery__button">
                <img src={delivery_done} alt="" />
                <Heading6
                  text={t('DELIVERY')}
                  marginLeft={10}
                  color={'#585858'}
                  span={true}
                />
                <img className="done__icon" src={done} alt="done" />
              </div>
              <div className="col-3 checkout__middle__line__block"></div>
              <div className="col-2 checkout__payment__button">
                <img
                  src={
                    iconType.payment === 'inprogress'
                      ? payment_inprogress
                      : iconType.payment === 'done'
                      ? payment_done
                      : payment_initial
                  }
                  alt=""
                />
                <Heading6
                  text={t('PAYMENT')}
                  marginLeft={10}
                  color={
                    iconType.payment === 'inprogress'
                      ? '#DC3A1A'
                      : iconType.payment === 'done'
                      ? '#585858'
                      : '#C8C8C8'
                  }
                  span={true}
                />
                {iconType.payment === 'done' ? (
                  <img className="done__icon" src={done} alt="done" />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className={'user__payment__block'}>
              <div className="payment__title__block">
                <img src={payment_done} alt="" className="user__icon" />
                <Heading7
                  text={t('PAYMENT_OPTIONS')}
                  color="#808080"
                  marginLeft={10}
                  marginBottom={0}
                />
              </div>
              <div className="payment__block">
                <CartApplyStoreCredit
                  t={t}
                  currentLang={currentLang}
                  cart={cartData}
                  user={customerDetails}
                />
                <CartRedeemLoyalty
                  t={t}
                  handleChangePaymentMethod={handleChangePaymentMethod}
                  type="desktop"
                  hideSection={hideSection}
                  cartTotalData={cartTotalData}
                  ecom={ecom}
                  userPaymentMethod={userPaymentMethod}
                  cartData={cartData}
                />
                {userPaymentMethod === 'mokafa' && !mokafaApplied && (
                  <>
                    {payWithLoyalty.step === 1 && (
                      <CartLoyaltyBonus t={t} program="mokafa" type="desktop" />
                    )}
                  </>
                )}
                
                {userPaymentMethod === 'meza' && (
                  <>
                    {payWithLoyalty.step === 1 && (
                      <>
                        <div className="payment__form__main__block">
                          <div className="payment__detail__form__block">
                            {loyaltyLoading && (
                              <div className="payment_process_loading"></div>
                            )}
                            <div className="address__content__block">
                              <div className="payment__card__block mezza__text">
                                <div className="row payment__form__field__row">
                                  <div className="col-sm-12 col-md-2 main__form__field__block">
                                    <div className="field__block">
                                      <img src={mezzacard} alt="card" />
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-3 main__form__field__block ">
                                    <Heading7
                                      text={t('Mobile_phone_number')}
                                      marginBottom={10}
                                    />
                                    <div className="field__block">
                                      <input
                                        type="text"
                                        placeholder="i.e. 0512345678"
                                        className="form__field"
                                        id="name"
                                        value={payWithLoyalty.phone}
                                        name="phone"
                                        onChange={(e) => handleChangeLoyalty(e)}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                    {payWithLoyaltyErr.phone && (
                                      <p className="invalid__message">
                                        {payWithLoyaltyErr.phone}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-md-3 main__form__field__block">
                                    <div className="field__block">
                                      <button
                                        onClick={() => applyForLoyaltyOtp()}
                                        className="btn apply_btn w-100 h-20"
                                        disabled={loyaltyLoading ? true : false}
                                      >
                                        {loyaltyLoading ? (
                                          <Spinner />
                                        ) : (
                                          t('Apply')
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="row payment__form__field__row"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {payWithLoyalty.step === 2 && (
                      <>
                        <div className="payment__form__main__block">
                          <div className="payment__detail__form__block">
                            {loyaltyLoading && (
                              <div className="payment_process_loading"></div>
                            )}
                            <div className="address__content__block">
                              <div className="payment__card__block mezza__text">
                                <div className="row payment__form__field__row">
                                  <div className="col-sm-12 col-md-3 main__form__field__block">
                                    <Heading7
                                      text={t('Code')}
                                      marginBottom={10}
                                    />
                                    <div className="field__block">
                                      <input
                                        type="text"
                                        className="form__field"
                                        id="name"
                                        placeholder={t('Code')}
                                        value={payWithLoyalty.code}
                                        name="code"
                                        onChange={(e) => handleChangeLoyalty(e)}
                                      />
                                    </div>
                                    {payWithLoyaltyErr.code && (
                                      <p className="invalid__message">
                                        {payWithLoyaltyErr.code}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-md-3 main__form__field__block">
                                    <Heading7
                                      text={t('points')}
                                      marginBottom={10}
                                    />
                                    <div className="field__block">
                                      <input
                                        type="text"
                                        className="form__field"
                                        id="name"
                                        placeholder={t('points')}
                                        value={payWithLoyalty.points}
                                        name="points"
                                        onChange={(e) => handleChangeLoyalty(e)}
                                      />
                                    </div>
                                    {payWithLoyaltyErr.points && (
                                      <p className="invalid__message">
                                        {payWithLoyaltyErr.points}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-md-3 main__form__field__block">
                                    <div className="field__block">
                                      <button
                                        onClick={() =>
                                          confirmLoyaltyPointsPayment()
                                        }
                                        className="btn apply_btn w-100"
                                        disabled={loyaltyLoading ? true : false}
                                      >
                                        {loyaltyLoading ? (
                                          <Spinner />
                                        ) : (
                                          t('Confirm')
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {cartDetail?.extension_attributes
                                  ?.meza_details && (
                                  <p>
                                    {
                                      cartDetail.extension_attributes
                                        .meza_details.points
                                    }{' '}
                                    {`${t('Meza_points_equal_to')}`}{' '}
                                    {
                                      cartDetail.extension_attributes
                                        .meza_details.money_equivalent
                                    }{' '}
                                    {cartDetail.currency.base_currency_code}
                                  </p>
                                )}
                                <div className="row payment__form__field__row"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                {userPaymentMethod === 'qitaf' && (
                  <>
                    {payWithLoyalty.step === 1 && (
                      <>
                        <div className="payment__form__main__block">
                          <div className="payment__detail__form__block">
                            {loyaltyLoading && (
                              <div className="payment_process_loading"></div>
                            )}
                            <div className="address__content__block">
                              <div className="payment__card__block">
                                <div className="row payment__form__field__row mezza__text">
                                  <div className="col-sm-12 col-md-2 main__form__field__block">
                                    <div className="field__block">
                                      <img src={quitafcard} alt="card" />
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-3 main__form__field__block">
                                    <Heading7
                                      text={t('Mobile_phone_number')}
                                      marginBottom={10}
                                    />
                                    <div className="field__block">
                                      <input
                                        type="text"
                                        placeholder="i.e. 0512345678"
                                        className="form__field"
                                        id="name"
                                        value={payWithLoyalty.phone}
                                        name="phone"
                                        onChange={(e) => handleChangeLoyalty(e)}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                    {payWithLoyaltyErr.phone && (
                                      <p className="invalid__message">
                                        {payWithLoyaltyErr.phone}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-md-3 main__form__field__block">
                                    <div className="field__block">
                                      <button
                                        onClick={() => applyForLoyaltyOtp()}
                                        className="btn apply_btn w-100"
                                        disabled={loyaltyLoading ? true : false}
                                      >
                                        {loyaltyLoading ? (
                                          <Spinner />
                                        ) : (
                                          t('Apply')
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="row payment__form__field__row"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {payWithLoyalty.step === 2 && (
                      <>
                        <div className="payment__form__main__block">
                          <div className="payment__detail__form__block">
                            {loyaltyLoading && (
                              <div className="payment_process_loading"></div>
                            )}
                            <div className="address__content__block">
                              <div className="payment__card__block">
                                <div className="row payment__form__field__row">
                                  <div className="col-sm-12 col-md-3 main__form__field__block">
                                    <Heading7
                                      text={t('Code')}
                                      marginBottom={10}
                                    />
                                    <div className="field__block">
                                      <input
                                        type="text"
                                        className="form__field"
                                        id="name"
                                        placeholder={t('Code')}
                                        value={payWithLoyalty.code}
                                        name="code"
                                        onChange={(e) => handleChangeLoyalty(e)}
                                      />
                                    </div>
                                    {payWithLoyaltyErr.code && (
                                      <p className="invalid__message">
                                        {payWithLoyaltyErr.code}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-md-3 main__form__field__block">
                                    <Heading7
                                      text={t('points')}
                                      marginBottom={10}
                                    />
                                    <div className="field__block">
                                      <input
                                        type="text"
                                        className="form__field"
                                        id="name"
                                        placeholder={t('points')}
                                        value={payWithLoyalty.points}
                                        name="points"
                                        onChange={(e) => handleChangeLoyalty(e)}
                                      />
                                    </div>
                                    {payWithLoyaltyErr.points && (
                                      <p className="invalid__message">
                                        {payWithLoyaltyErr.points}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-md-3 main__form__field__block">
                                    <div className="field__block">
                                      <button
                                        onClick={() =>
                                          confirmLoyaltyPointsPayment()
                                        }
                                        className="btn btn__primary__orange w-100"
                                        disabled={loyaltyLoading ? true : false}
                                      >
                                        {loyaltyLoading ? (
                                          <Spinner />
                                        ) : (
                                          t('Confirm')
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="row payment__form__field__row"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                {userPaymentMethod === 'niqaty' && 
                  <LoyaltyNiqatyProgram 
                    t={t}
                    services={services}
                    stepDetails={payWithLoyalty}
                    setPayWithLoyalty={setPayWithLoyalty}
                    loyaltyLoading={loyaltyLoading}
                    handleNiqatyComplete={handleNiqatyComplete} />
                }

                {isAmwalEnabled === 1 && (
                  <AmwalMagentoReactButton
                    key={forceRender}
                    baseUrl={`${process.env.REACT_APP_PROJECT_API_URL}/V1`}
                    triggerContext="regular-checkout"
                    overrideCartId={
                      cartData?.maskId === '' ? cartData?.id : cartData?.maskId
                    }
                    amwalState={amwalState}
                    locale={currentLanguageCode}
                    extraHeaders={{
                      'x-access-token': localStorageHandshakeToken,
                    }}
                    performSuccessRedirection={(order_id) => {
                      localStorage.setItem('OrderID', order_id);
                      window.location.href = `${BASE_PAYMENT_REDIRECT_URL}/checkout/success`;
                    }}
                  />
                )}

                <CheckoutPaymentMethods
                  t={t}
                  isAppleDevice={isAppleDevice}
                  tamara6MonthStatus={tamara6MonthStatus}
                  tamara3MonthStatus={tamara3MonthStatus}
                  checkVirtualItemsInCart={checkVirtualItemsInCart}
                  currentLanguageCode={currentLanguageCode}
                  setRedirectPageUrl={setRedirectPageUrl}
                  setPending={setPending}
                  showApplePay={showApplePay}
                  pending={pending}
                  cartDetail={cartDetail}
                  BASE_PAYMENT_REDIRECT_URL={BASE_PAYMENT_REDIRECT_URL}
                  ecom={ecom}
                  custEmailAddress={custEmailAddress}
                  dispatch={dispatch}
                  services={services}
                  currentLang={currentLang}
                  cartData={cartData}
                  setCartTotalData={setCartTotalData}
                />
              </div>
            </div>
          </div>
          <OrderSummary
            loyaltyEarnTypeId={loyaltyEarnTypeId}
            cartDetail={cartDetail}
            cartTotalData={cartTotalData}
            loyaltyEarnType={loyaltyEarnType}
          />
        </div>
      </div>
    </div>
  );
};

export default CheckoutPaymentWrapper;
