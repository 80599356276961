import React, { useEffect, useState } from "react";
import Heading6 from "../Font/Heading6";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import "./../../SCSS/ProductType/_productFive.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  loadAddToWishlist,
  loadCartData,
  loadDeleteFromWishlist,
  loadWishlistData,
} from "../../redux/appAction";
import { useDispatch, useSelector } from "react-redux";
import OldPrice from "../Font/OldPrice";
import Price from "../Font/Price";
import { addToCart } from "../../services/cart.service";
import * as services from "./../../services/services";
import TamaraLogoBox from "../MostSharedComponent/TamaraLogoBox";
import { checkWishList } from "../helpers/utils/wishListHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
import { MobileImageResize } from "../helpers/utils/imageResize";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { checkoutEvents } from "../helpers/utils/ga/checkoutEvents";
import LazyImageForProduct from "../MostSharedComponent/LazyImageForProduct";
import AddToCartNotify from "../Buttons/AddToCartNotify";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";

function ProductFive({
  product,
  handleChangeCartPopup,
  onTouchStart = () => { },
  onTouchMove = () => { },
  onTouchEnd = () => { },
  outOfStockConfiguration,
  productIndex,
  screenName
}) {

  const { setCartErr } = useCartErrorMessage();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isFavourite, setIsFavourite] = useState(false);
  const [count, setCount] = useState(0);
  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);


  const wishlistData = useSelector((state) => state.appData.wishlistData);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, product?.sku));
  }, []);

  useEffect(() => {
    product.is_price_hide = product.options.find((x) => {
      if (x.code === 'is_price_hide') {
        return x.visibility;
      }
      return false;
    });
  }, [product]);

  useEffect(() => {
    const data = {
      items: [product.sku],
    };
    if (isFavourite) {
      if (count > 0) {
        const addToWishlistData = dispatch(loadAddToWishlist(data, t)).then(
          (res) => {
            checkoutEvents.addToWishlist(product, screenName)
            dispatch(loadWishlistData());
          }
        );
      }
    } else {
      if (count > 0) {
        if (!isFavourite) {
          checkoutEvents.removeFromWishlist(product, screenName);
          removeFromWL(product.sku);
        }
      }
    }
  }, [isFavourite]);

  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };
    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data, t)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };

  const AddProductToCart = (sku) => {
    setLoaderOn(true);
    //  console.log('product', product);
    const data = {
      items: [
        {
          sku: sku,
          qty: 1,
          id: product?.id
        },
      ],
    };

    addToCart(data)
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id, screenName)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
        setLoaderOn(false);
      })
      .finally(() => {
        setLoaderOn(false);
      });
  };

  return (
    <div
      className="col-3 productFive__block"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <Link
        to={productUrlHelper(product?.english_name, product?.sku)}
        aria-label={`${titleChange(product.name)}`}
      >
        <div className="productFive__image__block">
          <LazyImageForProduct
            product={product}
            srcSetReSize={MobileImageResize}
            alt="Product Detail"
            className="productFive__image"
          />
        </div>
      </Link>
      <div className="plp__tamara__box mb-2">
        <TamaraLogoBox
          product={product}
        />
      </div>
      <Heading6
        text={`${titleChange(product.name).slice(0, 20)}...`}
        textAlign="center"
      ></Heading6>
      <div className="prize__block">
        {
          !product?.is_price_hide &&
          <>
            <OldPrice
              oldPrice={product?.price_rounded}
              price={
                product.discounted_price_rounded === null
                  ? product.price_rounded
                  : product.discounted_price_rounded
              }
              size="text4"
              color="#c8c8c8"
              marginBottom={0}
              lineThrough={true}
              span={true}
              currency={product?.currency}
            />
            <Price
              price={
                product.discounted_price_rounded === null
                  ? product.price_rounded
                  : product.discounted_price_rounded
              }
              marginLeft={5}
              size="heading7"
              span={true}
              currency={product?.currency}
            />
          </>
        }
      </div>
      <>
        {product?.is_bundle_enable === 1 ? (
          <Link
            to={productUrlHelper(product?.english_name, product?.sku)}
            aria-label={`${titleChange(product.name)}`}
            style={{ textDecoration: 'none' }}
          >
            <AddToCartNotify
              product={product}
              AddProductToCart={AddProductToCart}
              outOfStockConfiguration={outOfStockConfiguration}
              loaderOn={loaderOn || spinnerOn}
            />
          </Link>
        ) : (
          <AddToCartNotify
            product={product}
            AddProductToCart={AddProductToCart}
            outOfStockConfiguration={outOfStockConfiguration}
            loaderOn={loaderOn || spinnerOn}
          />
        )}
      </>
    </div>
  );
}

export default ProductFive;
