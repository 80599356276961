import React, { useState, useEffect } from "react";
import "./../../SCSS/ProductType/_productTen.scss";
import empty_favourite from "./../../assets/Icon/empty_favourite.svg";
import fulfill_favourite from "./../../assets/Icon/fulfill_favourite.svg";
import shopping_cart from "./../../assets/Icon/shopping_cart.svg";
import quick_view from "./../../assets/Icon/quick_view.svg";
import compare from "./../../assets/Icon/compare.svg";
import productTwo_quality_icon from "./../../assets/Product/productTwo_quality_icon.png";
import Heading7 from "../Font/Heading7";
import Text4 from "../Font/Text4";
import OldPrice from "../Font/OldPrice";
import Price from "../Font/Price";
import Heading6 from "../Font/Heading6";
import RatingBlock from "../MostSharedComponent/RatingBlock";
import AwesomeSlider from "react-awesome-slider";
import {
  loadAddToWishlist,
  loadDeleteFromWishlist,
  loadWishlistData,
} from "../../redux/appAction";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Text5 from "../Font/Text5";
import { BiSearch } from "react-icons/bi";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { selectedCompareProductStatus } from "../helpers/utils/compareProductsFilter";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import tamara from "./../../assets/Icon/tamara.png";
import tabbycard from "./../../assets/Icon/tabbycard.png";
import { addToCart } from "../../services/cart.service";
import { loadCartData } from "../../redux/appAction";
import * as services from "./../../services/services";
import VariantOptions from "./VariantOptions";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import DeliveryTimeArea from "../MostSharedComponent/DeliveryTimeArea";
import TamaraLogoBox from "../MostSharedComponent/TamaraLogoBox";
import { checkWishList } from "../helpers/utils/wishListHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
import MizaAndQitaf from "../MostSharedComponent/MizaAndQitaf";
import ProductLabel from "../MostSharedComponent/ProductLabel";
import AddToCartNotify from "../Buttons/AddToCartNotify";
import useWindowDimensions from "../helpers/utils/useWindowDimensions";
import { ImageResize, MobileImageResize } from "../helpers/utils/imageResize";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { checkoutEvents } from "../helpers/utils/ga/checkoutEvents";
import LazyImageForProduct from "../MostSharedComponent/LazyImageForProduct";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";
import SoldOutProductLabel from "../MostSharedComponent/SoldOutProductLabel";
import ProductDeliveryType from "../MostSharedComponent/ProductDeliveryType";

function ProductTen({
  product,
  handleChangeProductPopup,
  updateCompareProduct,
  handleChangeCartPopup,
  redirectToPdpPage,
  outOfStockConfiguration,
  productIndex,
  screenName,
  availablityData
}) {
  const { setCartErr } = useCartErrorMessage();
  const currentLanguageAr = (localStorage.getItem("i18next") || "en") === "ar";
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [rating, setRating] = useState(0);
  const [sizeButtonIndex, setSizeButtonIndex] = useState(null);
  const [loaderOn, setLoaderOn] = useState(false);
  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [count, setCount] = useState(0);
  const [productImages, setProductImages] = useState([]);
  const [spinnerOn, setSpinnerOn] = useState(false);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    initialSlide: currentLanguageAr ? -1 : 0,
    // slidesToScroll: 1
  };

  const wishlistData = useSelector((state) => state.appData.wishlistData);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, product?.sku));
  }, []);

  useEffect(() => {
    const productList = [...(product?.media?.image?.screenshots || [])];
    if (currentLanguageAr) {
      productList?.reverse();
    }
    setProductImages(productList);
  }, [product?.media?.image?.screenshots]);

  const handleFavourite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isFavourite) {
      setIsFavourite(false);
      setCount(count + 1);
    } else {
      setIsFavourite(true);
      setCount(count + 1);
    }
  };
  useEffect(() => {
    const data = {
      items: [product.sku],
    };
    if (isFavourite) {
      if (count > 0) {
        const addToWishlistData = dispatch(loadAddToWishlist(data, t)).then(
          (res) => {
            checkoutEvents.addToWishlist(product, screenName);
            dispatch(loadWishlistData());
          }
        );
      }
    } else {
      if (count > 0) {
        if (!isFavourite) {
          checkoutEvents.removeFromWishlist(product, screenName);
          removeFromWL(product.sku);
        }
      }
    }
  }, [isFavourite]);

  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };

    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data, t)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };

  const handleRating = (score) => {
    setRating(score);
  };
  useEffect(() => {
    product.is_price_hide = product.options.find((x) => {
      if (x.code === "is_price_hide") {
        return x.visibility;
      }
      return false;
    });
  }, [product]);
  const sizeButtonClick = (sizeIndex) => {
    product.sku = product?.variants[sizeIndex]?.sku;
    product.name = product?.variants[sizeIndex]?.name;
    product.description = product?.variants[sizeIndex]?.description;
    product.price = product?.variants[sizeIndex]?.price;
    product.currency = product?.variants[sizeIndex]?.currency;
    product.price_rounded = product?.variants[sizeIndex]?.price_rounded;
    product.discounted_price = product?.variants[sizeIndex]?.discounted_price;
    product.discounted_price_rounded = product?.variants[sizeIndex]?.discounted_price_rounded;
    product.discounted_price_from = product?.variants[sizeIndex]?.discounted_price_from;
    product.discounted_price_to = product?.variants[sizeIndex]?.discounted_price_to;
    product.is_count_down = product?.variants[sizeIndex]?.is_count_down;
    product.stock = product?.variants[sizeIndex]?.stock;
    product.item_type = product?.variants[sizeIndex]?.item_type;
    product.meza = product?.variants[sizeIndex]?.meza;
    product.qitaf = product?.variants[sizeIndex]?.qitaf;
    product.eligible_for_tabby = product?.variants[sizeIndex]?.eligible_for_tabby;
    product.enable_shipping = product?.variants[sizeIndex]?.enable_shipping;
    product.additional_estimated_day = product?.variants[sizeIndex]?.additional_estimated_day;
    product.current_time = product?.variants[sizeIndex]?.current_time
    setSizeButtonIndex(sizeIndex);
  };

  const AddProductToCart = (sku) => {
    setLoaderOn(true);
    if (product?.variants && product?.variants.length > 0 && sizeButtonIndex && sizeButtonIndex >= 0) {
      sku = product.variants[sizeButtonIndex].sku;
    }

    const data = {
      items: [
        {
          sku: sku,
          id: product?.id,
          qty: 1,
        },
      ],
    };

    addToCart(data)
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
        setLoaderOn(false);
      })
      .finally(() => {
        setLoaderOn(false);
      });
  };

  return (
    <div
      key={product.id}
      className="row productTen__block"
      onClick={() => redirectToPdpPage(product?.english_name, product?.sku)}
    >
      <div className="col-xxl-4 col-lg-6 productTen__left__block">
        <div className="productTen__header">          
          {/* <div className="productTen__new__sticker__block">
            <p className="productTen__new__sticker__text">{t("New")}</p>
          </div> */}
          <div></div>
          <div className="quality__favourite__block">
            {/* <img
              src={productTwo_quality_icon}
              alt=""
              className="quality__icon"
            /> */}
            <img
              onMouseEnter={() => setIsFavouriteHover(true)}
              onClick={handleFavourite}
              onMouseLeave={() => setIsFavouriteHover(false)}
              className={
                !isFavourite ? "favourite__icon" : "favourite__icon__disable"
              }
              src={isFavouriteHover ? fulfill_favourite : empty_favourite}
              alt=""
            />
            <img
              onClick={handleFavourite}
              className={
                isFavourite ? "favourite__icon" : "favourite__icon__disable"
              }
              src={fulfill_favourite}
              alt=""
            />
          </div>
        </div>
        <div className="product__pics__area">
          <div className="productTen__image__slider">
            {/* <AwesomeSlider
              transitionDelay={0.2}
              mobileTouch={true}
              infinite={true}
            >
              {product?.media?.image?.screenshots?.map((img, index) => (
                <div key={index} className="_product__container_image">
                  <img src={img.image} alt={img.image + index} />
                </div>
              ))}
            </AwesomeSlider> */}
            {/* <div onClick={() => redirectToPdpPage(product?.english_name,product?.sku)}> */}
            <Link to={productUrlHelper(product?.english_name, product?.sku)}>
              <Slider {...settings}>
                {[product?.baseImage]?.map((img, index) => (
                  <div key={index} className="">
                    <LazyImageForProduct
                      product={product}
                      srcSetReSize={width <= 992 ? MobileImageResize : ImageResize}
                      alt={img + index}
                      className=""
                      onClickEvent={true}
                      redirectToPdpPage={redirectToPdpPage}
                    />
                  </div>
                ))}
              </Slider>
            </Link>
            {/* </div> */}
          </div>
          <ProductLabel product={product} labelType="plp" />
          <SoldOutProductLabel
            t={t}
            product={product}
            outOfStockConfiguration={outOfStockConfiguration}
          />
        </div>
        <div className="productTen__quickView__compare__block">
          <button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleChangeProductPopup(true, product)
            }}
            className="productTen__button__block"
          >
            <BiSearch className="search_icon shortlink_icon" />
            <Heading7 text={t("Quick_View")} />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              updateCompareProduct(true, product)
            }}
            // className="productNine__button__block"
            className={`productTen__button__block ${selectedCompareProductStatus(product.id) === true
              ? `active_compare_btn`
              : ``
              }`}
          >
            <HiOutlineSwitchHorizontal className="compare_icon shortlink_icon" />
            <Heading7 text={t("Compare")} />
          </button>
        </div>
      </div>
      <div className="col-xxl-5 col-lg-6 productTen__middle__block">
        <Link
          className="productTen__name__link"
          to={productUrlHelper(product?.english_name, product?.sku)}
        >
          <p className="productTen__name"> {titleChange(product.name)}</p>
        </Link>
        <ProductDeliveryType
          product={product}
          availablityData={availablityData}
          t={t}
        />
        <div className="plp__mezza__reward mb-2">
          <div className="plp__tamara__box mb-2">
            <TamaraLogoBox
              product={product}
            />
          </div>
        </div>
        <RatingBlock rating={6} totalRatings={2222} />
        <div className="size__button__block">
          {product?.variantOptions && (
            <VariantOptions
              sizeButtonClick={sizeButtonClick}
              sizeButtonIndex={sizeButtonIndex}
              variantOptions={product?.variantOptions}
              product={product}
            />
          )}
        </div>
        {/* <div className="productNine__feature__block">
          {product.productFeatures && (
            <>
              {product.productFeatures.map((feature) => {
                return (
                  <div className="feature__text__block">
                    <div className="feature__bullet"></div>
                    <Text4 text={feature.text} />
                  </div>
                );
              })}
            </>
          )}
        </div> */}
        {product?.additional_estimated_day !== undefined && (
          <div className="delivery-time-area">
            <DeliveryTimeArea
              additional_estimated_day={product?.additional_estimated_day}
            />
          </div>
        )}
      </div>
      <div className="col-xxl-3 col-lg-12 productTen__right__block">
        <Link
          to={productUrlHelper(product?.english_name, product?.sku)}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="plp__mezza__reward d-flex justify-content-between align-items-center mb-2">
            <MizaAndQitaf product={product} />
          </div>
          <div className="product-list-view-pricebox">
            {!product?.is_price_hide &&
              <>
                <OldPrice
                  oldPrice={product?.price_rounded}
                  price={
                    product.discounted_price_rounded === null
                      ? product.price_rounded
                      : product.discounted_price_rounded
                  }
                  size="text3"
                  color="#444444"
                  // marginBottom={10}
                  // lineThrough={true}
                  span={true}
                  currency="SAR"
                />
                <Price
                  price={
                    product.discounted_price_rounded === null
                      ? product.price_rounded
                      : product.discounted_price_rounded
                  }
                  // marginLeft={5}
                  // marginBottom={10}
                  size="heading6"
                  span={true}
                  currency="SAR"
                />
              </>
            }
          </div>
        </Link>
        <span onClick={e => { e.stopPropagation(); e.preventDefault(); }}>
          {product?.is_bundle_enable === 1 ? (
            <Link
              to={productUrlHelper(product?.english_name, product?.sku)}
              style={{ textDecoration: "none" }}
            >
              <AddToCartNotify
                product={product}
                AddProductToCart={AddProductToCart}
                outOfStockConfiguration={outOfStockConfiguration}
                loaderOn={loaderOn || spinnerOn}
              />
            </Link>
          ) : (
            <AddToCartNotify
              product={product}
              AddProductToCart={AddProductToCart}
              outOfStockConfiguration={outOfStockConfiguration}
              loaderOn={loaderOn || spinnerOn}
            />
          )}
        </span>
      </div>
    </div>
  );
}

export default ProductTen;
