export const dataLayerPush = (obj, delayed = true) => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      if (!delayed) {
        window.dataLayer.push(obj);
        // console.log('datalayer.push1', obj);
      } else {
        setTimeout(() => {
          window.dataLayer.push(obj);
          // console.log('datalayer.push2', obj);
        }, 10);
      }
    }
  };
  
  const groups = {};
  const timers = {};
  
  export const dataLayerGroupPush = (obj, namespace) => {
    if (timers[namespace]) clearTimeout(timers[namespace]);
    groups[namespace] = groups[namespace] || [];
    groups[namespace].push(obj);
  
    timers[namespace] = setTimeout(() => {
      let merged = null;
  
      groups[namespace].forEach((groupItem) => {
        if (!merged) {
          merged = groupItem;
        } else {
          merged.ecommerce.impressions = [...merged?.ecommerce?.impressions, ...groupItem?.ecommerce?.impressions];
        }
      });
  
      dataLayerPush(merged);
      groups[namespace] = [];
    }, 1500);
  };
  