import React,{ useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import GamersEliteForm from '../Components/Form/GamersEliteForm';
import GamersEliteHero from '../Components/GamersEliteHero';
import Confetti from 'react-confetti';
import '../SCSS/_gamersElite.scss';


function Ps5EliteGamersPage() {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [showConfeti, setShowConfeti] = useState(true);

  useEffect(() => {
    if (submitted) {
      setTimeout(() => {
        setShowConfeti(false);
      }, 4000);
    }
  }, [submitted]);

  const handleFormSubmit = () => {
    setSubmitted(true);
  }

    return (
      <>
      
        <div className='mainEliteWrapper' >
            <div className='headerBarElite'>
              <div>
              <img src="/images/elita-logo.png"  className='logoElite' alt="Gamers Elite" />
              </div>
              <div>
                <img src="/images/logo.png" alt="Sony World"/>
              </div>  
          </div>
          {submitted && <Confetti recycle={showConfeti} />}
          <div className='eliteWrapper'>
            <div className='containerElite'>
              <div className='sectionA'>
                <GamersEliteHero t={t} submit={submitted} />
              </div>
              {!submitted && (
                <div className='sectionB'>     
                  <GamersEliteForm t={t} submitAction={handleFormSubmit}  />
                </div>  
              )}
            </div>
          </div>         
          </div>         
      </>


    )
  
}

export default Ps5EliteGamersPage