import React from 'react';
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { productUrlHelper } from '../helpers/utils/productUrlHelper';

// meta_title, meta_description
function PdpMetaComponent({ productName, categoryName, meta_keywords, firstImage, product_slug, product_sku }) {
  const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://sonyworld.sa';
  const getCurrentPageURL = window.location.href;
  const currentLang = `${localStorage.getItem("i18next") || "ar"}-sa`;
  const { t } = useTranslation();
  let meta_title_new = t('Shop For {{ productName }} - Sony KSA', { productName: productName, interpolation: { escapeValue: false } })
  let meta_description_new = t('Shop for {{ productName }} at best price from the official Sony website in Saudi Arabia. Wide selection of products available in {{ categoryName }}. Order now!', { productName: productName, categoryName: categoryName, interpolation: { escapeValue: false } })
  const productUrl =  productUrlHelper(product_slug, product_sku);

  // let sony_title = `${meta_title} - ${t('Sony KSA')}`;

  return (
    <Helmet>
      <title>{meta_title_new}</title>
      <meta name="keyword" content={meta_keywords ? meta_keywords : t('Sony_World_KSA')} />
      <meta name="description" content={meta_description_new} />
      <meta property="og:title" content={meta_title_new}/>
      <meta property="og:site_name" content="SonyWorld"/>
      <meta property="og:url" content={getCurrentPageURL}/>
      <meta property="og:description" content={meta_description_new}/>
      <meta property="og:type" content="Product" />
      <meta property="og:image" content={firstImage}/>
      <link rel="canonical" href={`${BASE_URL}/${currentLang}${productUrl}`} />
    </Helmet>
  );
}

export default React.memo(PdpMetaComponent);
