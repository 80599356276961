import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function TamaraPlan() {
  const [count, setCount] = useState(0);
  const params = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "bcs_js_snippet";
    script.src = `https://cdn.tamara.co/widget/installment-plan.min.js`;
    script.async = true;
    if (count === 0) {
      document.getElementById("tamara__plan").appendChild(script);
      setCount(count + 1);
      return () => {
        if (document.getElementById("tamara__plan")) {
          document.getElementById("tamara__plan").removeChild(script);
        }
        setCount(0);
      };
    }
  }, []);
  useEffect(()=>{
    fetch("https://cdn.tamara.co/widget/installment-plan.min.js")
  .then((response) => console.log(response))
  
  },[])
  return (
    <>
    <h1>{t("Tamara")}</h1>
      <div id="tamara__plan"></div>
      
      {/* <Helmet>
        <script async={true} type="text/javascript" src={`https://cdn.tamara.co/widget/installment-plan.min.js`} />
      </Helmet> */}
      {/* <span>Learn more </span> */}
      {/* <div id="customSearch"></div> */}
    </>
  )
}

export default TamaraPlan