import React from "react";
import "./../../SCSS/MostSharedComponents/_superCoin.scss";
import mezzacard from './../../assets/Icon/mezzacard.png';
import coin from "./../../assets/Icon/coin.svg";
import { useTranslation } from "react-i18next";

function SuperCoin() {
  const { t } = useTranslation()
  return (
    <div className="row super__coin__block common__main__block">
      <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-5 super__coin__image__block">
        <img src={mezzacard} alt="" className="super__coin__image" />
      </div>
      <div className="col-8 col-sm-6 col-md-8 col-lg-8 col-xl-7 super__coin__text__block ps-2">
        <p className="super__coin__title">{t("For_every_SAR200_Spent")}</p>
        <p className="super__coin__title">
         {t("you_earn")} <img src={coin} alt="" className="coin" /> {t("2_SuperCoins")}
        </p>
        <p className="super__coin__condition">{t("Max 50 coins per order")}</p>
      </div>
    </div>
  );
}

export default SuperCoin;
