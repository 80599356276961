import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { getTamaraValue } from "../Components/helpers/utils/handsakeConfig";
import { TAMARA_INSTALLMENT_PLAN_URL } from "../globalConst";


const TamaraInstallmentPlanWidget = ({ price, currency, months, minLimit, maxLimit, currentLanguageCode, inlineType = 3,color = "" }) => {

  useEffect(() => {
    setTimeout(() => {
      // if (window.TamaraInstallmentPlan) {
      //   window.TamaraInstallmentPlan.init({ lang: 'en' });
      //   window.TamaraInstallmentPlan.render();
      // }
    }, 500)
  }, []);

  let getMinLimit = 0;
  let getMaxLimit = 0;
  if (months === 3) {
    getMinLimit = getTamaraValue('tamara3_min');
    getMaxLimit = getTamaraValue('tamara3_max');
  } else if (months === 6) {
    getMinLimit = getTamaraValue('tamara6_min');
    getMaxLimit = getTamaraValue('tamara6_max');
  }

  return (
    <React.Fragment>
      <input type="hidden" id="languageInput" value={currentLanguageCode} />
      <Helmet>
        <script src="/tamara-card-script.js" />
        <script src={TAMARA_INSTALLMENT_PLAN_URL} /> 
      </Helmet>

      <div style={{color : color}}>
        <tamara-widget type="tamara-summary" inline-type={inlineType} amount={price} />
      </div>
      {/* <div
        className="tamara-installment-plan-widget"
        data-lang={currentLanguageCode}
        data-price={price}
        data-currency={currency}
        data-color-type="default"
        data-country-code="SA"
        data-number-of-installments={months}
        data-installment-minimum-amount={getMinLimit}
        data-installment-maximum-amount={getMaxLimit}
      >
      </div> */}
    </React.Fragment>
  );
};

export default React.memo(TamaraInstallmentPlanWidget);
