import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./../SCSS/_featureProducts.scss";
import { useDispatch, useSelector } from "react-redux";
import { loadAddToCart, loadCartData } from "./../redux/appAction";
import * as services from "./../services/services";
import Heading7 from "./Font/Heading7";
import RatingBlock from "./MostSharedComponent/RatingBlock";
import OldPrice from "./Font/OldPrice";
import Price from "./Font/Price";
import { useTranslation } from "react-i18next";
import { productUrlHelper } from "./helpers/utils/productUrlHelper";
import FeatureOptionBox from "./MostSharedComponent/FeatureOptionBox";
import TamaraLogoBox from "./MostSharedComponent/TamaraLogoBox";
import { titleChange } from "./helpers/utils/titleNameChange";
import AddToCart from '../Components/Buttons/AddToCart';
import BundleOffer from "./BundelOffer";
import { cartEvents } from "./helpers/utils/ga/cartEvents";
import LazyImageForProduct from "./MostSharedComponent/LazyImageForProduct";
import FavouriteProduct from "./MostSharedComponent/FavouriteProduct";
import { ImageResize, MobileBlockImageResize } from "./helpers/utils/imageResize";
import useCartErrorMessage from "./helpers/hooks/useCartErrorMessage";
import ProductDeliveryType from "./MostSharedComponent/ProductDeliveryType";
import { impressionEvents } from "./helpers/utils/ga/impressionEvents";

function FeatureProducts({
  featureData,
  handleChangeCartPopup,
  outOfStockConfiguration,
  screenName
}) {

  const { setCartErr } = useCartErrorMessage();

  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [loaderOn0, setLoaderOn0] = useState(false);
  const [loaderOn1, setLoaderOn1] = useState(false);
  const [loaderOn2, setLoaderOn2] = useState(false);
  const [loaderOn3, setLoaderOn3] = useState(false);
  const [loaderOn4, setLoaderOn4] = useState(false);
  const [featureProductsData, setFeatureProductsData] = useState([]);
  const [spinnerOn, setSpinnerOn] = useState(false);

  const { homepageData } = useSelector((state) => state.appData);

  const handleFavourite = () => {
    setIsFavourite(!isFavourite);
  };
  useEffect(() => {
    featureData.forEach(function callback(value, index) {
      featureData[index].is_price_hide = value?.options?.find((x) => {
        if (x.code === 'is_price_hide') {
          return x.visibility;
        }
        return false;
      });
    });
    setFeatureProductsData(featureData);
  }, [featureData]);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { t } = useTranslation();
  useEffect(() => {
    if (featureData && featureData.length > 0) { // Assuming featureData is an array and needs to be non-empty
      // impressionEvents.impressionViewProduct(featureData,
      //   "Home");
    }
  }, [featureData, impressionEvents]);

  const AddProductToCart = (sku, key) => {
    // impressionEvents.impressionAddToCart(
    //   featureData, 1,
    //   "Home"
    // );
    if (key === 0) {
      setLoaderOn0(true);
    } else if (key === 1) {
      setLoaderOn1(true);
    } else if (key === 2) {
      setLoaderOn2(true);
    } else if (key === 3) {
      setLoaderOn3(true);
    } else {
      setLoaderOn4(true);
    }
    let product_id = null;
    if (featureProductsData[key]) {
      product_id = featureProductsData[key]?.id
    }

    const data = {
      items: [
        {
          sku: sku,
          id: product_id,
          qty: 1,
        },
      ],
    };

    const loadAddToCartDAta = dispatch(loadAddToCart(data))
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(featureProductsData[key], item?.id, screenName)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        // setLoaderOn(false);
        setCartErr({ stock: featureProductsData[key]?.stock, error: err?.response?.data });
      })
      .finally(() => {
        if (key === 0) {
          setLoaderOn0(false);
        } else if (key === 1) {
          setLoaderOn1(false);
        } else if (key === 2) {
          setLoaderOn2(false);
        } else if (key === 3) {
          setLoaderOn3(false);
        } else {
          setLoaderOn4(false);
        }
      });
  };

  return (
    <>
      {featureProductsData && featureProductsData.length > 0 && (
        <div className="container-fluid feature__category__container d-none d-lg-block">
          <div className=" feature__category__block">
            <p className="section__title d-block d-lg-none py-3">
              {t("Deals_of_the_day")}
            </p>
            <div className="row inner__feature__category__block">
              {featureProductsData && featureProductsData[0] ? <div className="first__feature__category__block ">
                <span
                  className=" "
                  aria-label={featureProductsData[0]?.name}
                  onClick={() => {navigate(
                    productUrlHelper(featureProductsData[0]?.english_name,featureProductsData[0]?.sku));impressionEvents.impressionProductClick(
                      "select_item", featureData
                    );}}
                >
                  <div className="first__feature__category">
                    <div className="deal__of__day__container">
                    </div>
                    <div className="first__feature__action__area">
                      <span className="fav_icon d-block"
                        onClick={e => { e.stopPropagation(); e.preventDefault() }}
                      >
                        <FavouriteProduct
                          product={featureProductsData[0]}
                        />
                      </span>
                      <div className="pro__feature__optionBox">
                        <FeatureOptionBox
                          options={featureProductsData[0]?.options}
                          color_resolution="color_resolution"
                          screen_size="screen_size"
                        />
                      </div>
                    </div>

                    <div className="first__feature__category__image__block">
                      <LazyImageForProduct
                        product={featureProductsData[0]}
                        srcSetReSize={MobileBlockImageResize}
                        alt=""
                        className="first__feature__category__image"
                      /> 
                    </div>
                    <div className="heading__inner__text">
                      <Link
                        className="first__feature__pro__namebox"
                        to={productUrlHelper(
                          featureProductsData[0]?.english_name,
                          featureProductsData[0]?.sku
                        )}
                      >
                        <Heading7
                          marginBottom={10}
                          text={titleChange(featureProductsData[0]?.name)}
                          color="#000000"
                        />
                      </Link>
                      <ProductDeliveryType
                        product={featureProductsData[0]}
                        availablityData={homepageData?.availablityData || []}
                        t={t}
                      />
                      <div className="d-flex justify-content-between align-items-center mb-1">
                        <div className="plp__tamara__box mb-2">
                          <TamaraLogoBox
                            product={featureProductsData[0]}
                          />
                        </div>
                      </div>
                      <BundleOffer bundleEnable={featureProductsData[0]?.is_bundle_enable} t={t} />
                      <RatingBlock rating={3} totalRatings={2222} />
                      <div className="feature__Pricebox__area">
                        {
                          !featureProductsData[0]?.is_price_hide &&
                          <>
                            <OldPrice
                              oldPrice={featureProductsData[0]?.price_rounded}
                              price={
                                featureProductsData[0]?.discounted_price_rounded ===
                                  null
                                  ? featureProductsData[0]?.price_rounded
                                  : featureProductsData[0]?.discounted_price_rounded
                              }
                              size="text3"
                              color="#444444"
                              marginBottom={0}
                              // lineThrough={true}
                              span={true}
                              currency={featureProductsData[0]?.currency}
                            />
                            <Price
                              price={
                                featureProductsData[0]?.discounted_price_rounded ===
                                  null
                                  ? featureProductsData[0]?.price_rounded
                                  : featureProductsData[0]?.discounted_price_rounded
                              }
                              // marginLeft={5}
                              marginBottom={0}
                              size="heading6"
                              span={true}
                              currency={featureProductsData[0]?.currency}
                            />
                          </>
                        }
                      </div>

                      <div
                        className="text-end cart_end_icon"
                        onClick={e => {
                          e.stopPropagation();
                          e.preventDefault()
                        }}
                      >
                        {featureProductsData[0]?.is_bundle_enable === 1 ? (
                          <Link
                            style={{ textDecoration: 'none' }}
                            to={productUrlHelper(
                              featureProductsData[0]?.english_name,
                              featureProductsData[0]?.sku
                            )}
                            aria-label={featureProductsData[0]?.name}
                          >
                            <AddToCart
                              product={featureProductsData[0]}
                              AddProductToCart={AddProductToCart}
                              outOfStockConfiguration={outOfStockConfiguration}
                              loaderOn={loaderOn0 || spinnerOn}
                              type={0}
                            />
                          </Link>
                        ) : (
                          <AddToCart
                            product={featureProductsData[0]}
                            AddProductToCart={AddProductToCart}
                            outOfStockConfiguration={outOfStockConfiguration}
                            loaderOn={loaderOn0 || spinnerOn}
                            type={0}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </span>
              </div> : null}

              <div className="second__category__area">
                <div className=" second__feature__category__block">
                  {featureProductsData && featureProductsData[1] ?
                    <div className="feature__category"
                      onClick={() => {
                        navigate(
                          productUrlHelper(
                            featureProductsData[1]?.english_name,
                            featureProductsData[1]?.sku
                          ))
                      }}
                    >
                      <span className="fav_icon d-block"
                        onClick={e => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <FavouriteProduct
                          product={featureProductsData[1]}
                        />
                      </span>
                      <div className="pro__feature__optionBox">
                        <FeatureOptionBox
                          options={featureProductsData[1]?.options}
                          color_resolution="color_resolution"
                          screen_size="screen_size"
                        />
                      </div>
                      <Link
                        to={productUrlHelper(
                          featureProductsData[1]?.english_name,
                          featureProductsData[1]?.sku
                        )}
                        aria-label={featureProductsData[1]?.sku}
                      >
                        <div className="second__feature__category__image__block">
                          <LazyImageForProduct
                            product={featureProductsData[1]}
                            srcSetReSize={ImageResize}
                            alt="Feature Category"
                            className="second__feature__category__image"
                          />
                        </div>
                      </Link>
                      <Link
                        to={productUrlHelper(
                          featureProductsData[1]?.english_name,
                          featureProductsData[1]?.sku
                        )}
                        className="featureProduct__name"
                        aria-label={featureProductsData[1]?.name}
                      >
                        {titleChange(featureProductsData[1]?.name)}
                      </Link>
                      <ProductDeliveryType
                        product={featureProductsData[1]}
                        availablityData={homepageData?.availablityData || []}
                        t={t}
                      />
                      <div className="d-flex justify-content-between align-items-center mb-1">
                        <div className="plp__tamara__box mb-2">
                          <TamaraLogoBox
                            product={featureProductsData[1]}
                          />
                        </div>
                      </div>
                      <BundleOffer bundleEnable={featureProductsData[1]?.is_bundle_enable} t={t} />
                      <div className="featureProduct__rating__block">
                        <RatingBlock rating={6} totalRatings={2222} />
                      </div>
                      <div className="feature__price__cartBtn__area">
                        <div className="feature__Pricebox__area">
                          {
                            !featureProductsData[1]?.is_price_hide &&
                            <>
                              <OldPrice
                                oldPrice={featureProductsData[1]?.price_rounded}
                                price={
                                  featureProductsData[1]?.discounted_price_rounded === null
                                    ? featureProductsData[1]?.price_rounded
                                    : featureProductsData[1]?.discounted_price_rounded
                                }
                                size="text4"
                                color="#c8c8c8"
                                marginBottom={0}
                                // lineThrough={true}
                                span={true}
                                currency={featureProductsData[1]?.currency}
                              />
                              <Price
                                price={
                                  featureProductsData[1]?.discounted_price_rounded === null
                                    ? featureProductsData[1]?.price_rounded
                                    : featureProductsData[1]?.discounted_price_rounded
                                }
                                // marginLeft={5}
                                marginBottom={0}
                                size="heading7"
                                span={true}
                                currency={featureProductsData[1]?.currency}
                              />
                            </>
                          }
                        </div>
                        <div className="text-end cart_end_icon"
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          {featureProductsData[1]?.is_bundle_enable === 1 ? (
                            <Link
                              style={{ textDecoration: 'none' }}
                              to={productUrlHelper(
                                featureProductsData[1]?.english_name,
                                featureProductsData[1]?.sku
                              )}
                              aria-label={featureProductsData[1]?.name}
                            >
                              <AddToCart
                                product={featureProductsData[1]}
                                AddProductToCart={AddProductToCart}
                                outOfStockConfiguration={outOfStockConfiguration}
                                loaderOn={loaderOn1 || spinnerOn}
                                type={1}
                              />
                            </Link>
                          ) : (
                            <AddToCart
                              product={featureProductsData[1]}
                              AddProductToCart={AddProductToCart}
                              outOfStockConfiguration={outOfStockConfiguration}
                              loaderOn={loaderOn1 || spinnerOn}
                              type={1}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    : null}
                  {featureProductsData && featureProductsData[2] ?
                    <div className="feature__category"
                      onClick={() => {
                        navigate(
                          productUrlHelper(
                            featureProductsData[2]?.english_name,
                            featureProductsData[2]?.sku
                          ))
                      }}
                    >
                      <span className="fav_icon d-block" onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}>
                        <FavouriteProduct
                          product={featureProductsData[2]}
                        />
                      </span>
                      <div className="pro__feature__optionBox">
                        <FeatureOptionBox
                          options={featureProductsData[2]?.options}
                          color_resolution="color_resolution"
                          screen_size="screen_size"
                        />
                      </div>
                      <Link
                        to={productUrlHelper(
                          featureProductsData[2]?.english_name,
                          featureProductsData[2]?.sku
                        )}
                        aria-label={featureProductsData[2]?.sku}
                      >
                        <div className="second__feature__category__image__block">
                          <LazyImageForProduct
                            product={featureProductsData[2]}
                            srcSetReSize={ImageResize}
                            alt="Feature Category"
                            className="second__feature__category__image"
                          />
                        </div>
                      </Link>
                      <Link
                        to={productUrlHelper(
                          featureProductsData[2]?.english_name,
                          featureProductsData[2]?.sku
                        )}
                        className="featureProduct__name"
                        aria-label={featureProductsData[2]?.name}
                      >
                        {titleChange(featureProductsData[2]?.name)}
                      </Link>
                      <ProductDeliveryType
                        product={featureProductsData[2]}
                        availablityData={homepageData?.availablityData || []}
                        t={t}
                      />
                      <div className="d-flex justify-content-between align-items-center mb-1">
                        <div className="plp__tamara__box mb-2">
                          <TamaraLogoBox
                            product={featureProductsData[2]}
                          />
                        </div>
                      </div>
                      <BundleOffer bundleEnable={featureProductsData[2]?.is_bundle_enable} t={t} />
                      <div className="featureProduct__rating__block">
                        <RatingBlock rating={6} totalRatings={2222} />
                      </div>
                      <div className="feature__price__cartBtn__area">
                        <div className="feature__Pricebox__area">
                          {
                            !featureProductsData[2]?.is_price_hide &&
                            <>
                              <OldPrice
                                oldPrice={featureProductsData[2]?.price_rounded}
                                price={
                                  featureProductsData[2]?.discounted_price_rounded === null
                                    ? featureProductsData[2]?.price_rounded
                                    : featureProductsData[2]?.discounted_price_rounded
                                }
                                size="text4"
                                color="#c8c8c8"
                                marginBottom={0}
                                // lineThrough={true}
                                span={true}
                                currency={featureProductsData[2]?.currency}
                              />
                              <Price
                                price={
                                  featureProductsData[2]?.discounted_price_rounded === null
                                    ? featureProductsData[2]?.price_rounded
                                    : featureProductsData[2]?.discounted_price_rounded
                                }
                                // marginLeft={5}
                                marginBottom={0}
                                size="heading7"
                                span={true}
                                currency={featureProductsData[2]?.currency}
                              />
                            </>
                          }
                        </div>
                        <div className="text-end cart_end_icon"
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          {featureProductsData[2]?.is_bundle_enable === 1 ? (
                            <Link
                              style={{ textDecoration: 'none' }}
                              to={productUrlHelper(
                                featureProductsData[2]?.english_name,
                                featureProductsData[2]?.sku
                              )}
                              aria-label={featureProductsData[2]?.name}
                            >
                              <AddToCart
                                product={featureProductsData[2]}
                                AddProductToCart={AddProductToCart}
                                outOfStockConfiguration={outOfStockConfiguration}
                                loaderOn={loaderOn2 || spinnerOn}
                                type={2}
                              />
                            </Link>
                          ) : (
                            <AddToCart
                              product={featureProductsData[2]}
                              AddProductToCart={AddProductToCart}
                              outOfStockConfiguration={outOfStockConfiguration}
                              loaderOn={loaderOn2 || spinnerOn}
                              type={2}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    : null}
                </div>
                <div className=" second__feature__category__block">
                  {
                    featureProductsData && featureProductsData[3] ?
                      <div
                        to={productUrlHelper(
                          featureProductsData[3]?.english_name,
                          featureProductsData[3]?.sku
                        )}
                        className="feature__category first__in__column"
                        onClick={() => {
                          navigate(
                            productUrlHelper(
                              featureProductsData[3]?.english_name,
                              featureProductsData[3]?.sku
                            ))
                        }}
                      >
                        <span className="fav_icon d-block"
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          <FavouriteProduct
                            product={featureProductsData[3]}
                          />
                        </span>
                        <div className="pro__feature__optionBox">
                          <FeatureOptionBox
                            options={featureProductsData[3]?.options}
                            color_resolution="color_resolution"
                            screen_size="screen_size"
                          />
                        </div>
                        <Link
                          to={productUrlHelper(
                            featureProductsData[3]?.english_name,
                            featureProductsData[3]?.sku
                          )}
                          aria-label={featureProductsData[3]?.sku}
                        >
                          <div className="second__feature__category__image__block">
                            <LazyImageForProduct
                              product={featureProductsData[3]}
                              srcSetReSize={ImageResize}
                              alt="Feature Category"
                              className="second__feature__category__image"
                            />
                          </div>
                        </Link>
                        <Link
                          to={productUrlHelper(
                            featureProductsData[3]?.english_name,
                            featureProductsData[3]?.sku
                          )}
                          className="featureProduct__name"
                          aria-label={featureProductsData[3]?.name}
                        >
                          {titleChange(featureProductsData[3]?.name)}
                        </Link>
                        <ProductDeliveryType
                        product={featureProductsData[3]}
                        availablityData={homepageData?.availablityData || []}
                        t={t}
                      />
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <div className="plp__tamara__box mb-2">
                            <TamaraLogoBox
                              product={featureProductsData[3]}
                            />
                          </div>
                        </div>
                        <BundleOffer bundleEnable={featureProductsData[3]?.is_bundle_enable} t={t} />
                        <div className="featureProduct__rating__block">
                          <RatingBlock rating={6} totalRatings={2222} />
                        </div>
                        <div className="feature__price__cartBtn__area">
                          <div className="feature__Pricebox__area">
                            {
                              !featureProductsData[3]?.is_price_hide &&
                              <>
                                <OldPrice
                                  oldPrice={featureProductsData[3]?.price_rounded}
                                  price={
                                    featureProductsData[3]?.discounted_price_rounded === null
                                      ? featureProductsData[3]?.price_rounded
                                      : featureProductsData[3]?.discounted_price_rounded
                                  }
                                  size="text4"
                                  color="#c8c8c8"
                                  marginBottom={0}
                                  // lineThrough={true}
                                  span={true}
                                  currency={featureProductsData[3]?.currency}
                                />
                                <Price
                                  price={
                                    featureProductsData[3]?.discounted_price_rounded === null
                                      ? featureProductsData[3]?.price_rounded
                                      : featureProductsData[3]?.discounted_price_rounded
                                  }
                                  // marginLeft={5}
                                  marginBottom={0}
                                  size="heading7"
                                  span={true}
                                  currency={featureProductsData[3]?.currency}
                                />
                              </>
                            }
                          </div>
                          <div className="text-end cart_end_icon"
                            onClick={e => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                          >
                            {featureProductsData[3]?.is_bundle_enable === 1 ? (
                              <Link
                                style={{ textDecoration: 'none' }}
                                to={productUrlHelper(
                                  featureProductsData[3]?.english_name,
                                  featureProductsData[3]?.sku
                                )}
                                aria-label={featureProductsData[3]?.name}
                              >
                                <AddToCart
                                  product={featureProductsData[3]}
                                  AddProductToCart={AddProductToCart}
                                  outOfStockConfiguration={outOfStockConfiguration}
                                  loaderOn={loaderOn3 || spinnerOn}
                                  type={3}
                                />
                              </Link>
                            ) : (
                              <AddToCart
                                product={featureProductsData[3]}
                                AddProductToCart={AddProductToCart}
                                outOfStockConfiguration={outOfStockConfiguration}
                                loaderOn={loaderOn3 || spinnerOn}
                                type={3}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      : null}
                  {
                    featureProductsData && featureProductsData[4] ?
                      <div className="feature__category"
                        onClick={() => {
                          navigate(
                            productUrlHelper(
                              featureProductsData[4]?.english_name,
                              featureProductsData[4]?.sku
                            ))
                        }}
                      >
                        <span className="fav_icon d-block"
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          <FavouriteProduct
                            product={featureProductsData[4]}
                          />
                        </span>
                        <div className="pro__feature__optionBox">
                          <FeatureOptionBox
                            options={featureProductsData[4]?.options}
                            color_resolution="color_resolution"
                            screen_size="screen_size"
                          />
                        </div>
                        <Link
                          to={productUrlHelper(
                            featureProductsData[4]?.english_name,
                            featureProductsData[4]?.sku
                          )}
                          aria-label={featureProductsData[4]?.sku}
                        >
                          <div className="second__feature__category__image__block">
                            <LazyImageForProduct
                              product={featureProductsData[4]}
                              srcSetReSize={ImageResize}
                              alt="Feature Category"
                              className="second__feature__category__image"
                            />
                          </div>
                        </Link>
                        <Link
                          to={productUrlHelper(
                            featureProductsData[4]?.english_name,
                            featureProductsData[4]?.sku
                          )}
                          className="featureProduct__name"
                          aria-label={featureProductsData[4]?.name}
                        >
                          {titleChange(featureProductsData[4]?.name)}
                        </Link>
                        <ProductDeliveryType
                        product={featureProductsData[4]}
                        availablityData={homepageData?.availablityData || []}
                        t={t}
                      />
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <div className="plp__tamara__box mb-2">
                            <TamaraLogoBox
                              product={featureProductsData[4]}
                            />
                          </div>
                        </div>
                        <BundleOffer bundleEnable={featureProductsData[4]?.is_bundle_enable} t={t} />
                        <div className="featureProduct__rating__block">
                          <RatingBlock rating={6} totalRatings={2222} />
                        </div>
                        <div className="feature__price__cartBtn__area">
                          <div className="feature__Pricebox__area">
                            {
                              !featureProductsData[4]?.is_price_hide &&
                              <>
                                <OldPrice
                                  oldPrice={featureProductsData[4]?.price_rounded}
                                  price={
                                    featureProductsData[4]?.discounted_price_rounded === null
                                      ? featureProductsData[4]?.price_rounded
                                      : featureProductsData[4]?.discounted_price_rounded
                                  }
                                  size="text4"
                                  color="#c8c8c8"
                                  marginBottom={0}
                                  // lineThrough={true}
                                  span={true}
                                  currency={featureProductsData[4]?.currency}
                                />
                                <Price
                                  price={
                                    featureProductsData[4]?.discounted_price_rounded === null
                                      ? featureProductsData[4]?.price_rounded
                                      : featureProductsData[4]?.discounted_price_rounded
                                  }
                                  // marginLeft={5}
                                  marginBottom={0}
                                  size="heading7"
                                  span={true}
                                  currency={featureProductsData[4]?.currency}
                                />
                              </>
                            }
                          </div>
                          <div className="text-end cart_end_icon"
                            onClick={e => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                          >
                            {featureProductsData[4]?.is_bundle_enable === 1 ? (
                              <Link
                                style={{ textDecoration: 'none' }}
                                to={productUrlHelper(
                                  featureProductsData[4]?.english_name,
                                  featureProductsData[4]?.sku
                                )}
                                aria-label={featureProductsData[4]?.name}
                              >
                                <AddToCart
                                  product={featureProductsData[4]}
                                  AddProductToCart={AddProductToCart}
                                  outOfStockConfiguration={outOfStockConfiguration}
                                  loaderOn={loaderOn4 || spinnerOn}
                                  type={4}
                                />
                              </Link>
                            ) : (
                              <AddToCart
                                product={featureProductsData[4]}
                                AddProductToCart={AddProductToCart}
                                outOfStockConfiguration={outOfStockConfiguration}
                                loaderOn={loaderOn4 || spinnerOn}
                                type={4}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      : null}
                </div>
              </div>
            </div >
          </div >
        </div >
      )
      }
    </>
  );
}

export default FeatureProducts;
