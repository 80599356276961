import React from "react";
import { useTranslation } from "react-i18next";
// import Heading6 from "../Font/Heading6";
import mezzacard from "./../../assets/Icon/mezzacard.png";
// import my_reward_points from "./../../assets/Icon/my_reward_points.svg";

function MizaAndQitafPDP({ product, type }) {
  const { t } = useTranslation();

  const currentLang = localStorage.getItem("i18next") || "en";
  let quitafcard = require(`./../../assets/Icon/icon-qitaf-${currentLang}.png`)
  const imgNiqaty = require(`./../../assets/Icon/niqaty.png`)
  
  return (
    <>
      {product.item_type !== "virtual" && (
        <React.Fragment>
          {type === "desktop" &&
            <div className="row loyalty__points__area" >
              {product?.meza && product?.meza?.money_equivalent > 0 && (
                <div className="col-md-6 pe-1">
                  <div className="loyalty__item__box">
                    <img src={mezzacard} alt="Mezza card" />
                    <div className="loyalty__contentbox">
                      <p>
                      {t("Buy_and_Get")} <strong>{` ${product?.meza?.points}`} {t("points")}</strong>
                      </p>
                      <span>({t("equal to")} {`${ product?.meza?.money_equivalent ? product?.meza?.money_equivalent : 0 } ${t(product?.currency)}`})</span>
                    </div>
                  </div>
                </div>
              )}
              {product?.qitaf && product?.qitaf?.money_equivalent > 0 && (
                <div className="col-md-6 ps-1">
                  <div className="loyalty__item__box">
                    <img src={quitafcard} alt="quitafcard" />
                    <div className="loyalty__contentbox">
                      <p>
                      {t("Buy_and_Get")} <strong>{` ${product?.qitaf?.points}`} {t("points")}</strong>
                      </p>
                      <span>({t("equal to")} {`${ product?.qitaf?.money_equivalent ? product?.qitaf?.money_equivalent : 0 } ${t(product?.currency)}`})</span>
                    </div>
                  </div>
                </div>
              )}
              {product?.niqaty && product?.niqaty_eligible && (
                <div className="col-md-6 pe-1">
                  <div className="loyalty__item__box">
                    <img src={imgNiqaty} alt="niqaty" />
                    <div className="loyalty__contentbox">
                      <p>
                      {t("Buy_and_Get")} <strong>{` ${product?.niqaty?.points}`} {t("points")}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
          {type === "mobile" &&
            <div className="row loyalty__points__area" >
              {product?.meza && product?.meza?.money_equivalent > 0 && (
                <div className="col-6 ps-1 pe-1">
                  <div className="loyalty__item__box">
                    <img src={mezzacard} alt="Mezza card" />
                    <div className="loyalty__contentbox">
                      <p>{t("Buy_and_Get")} <strong>{` ${product?.meza?.points}`} {t("points")}</strong></p>
                      <span>({t("equal to")} {`${ product?.meza?.money_equivalent ? product?.meza?.money_equivalent : 0 } ${t(product?.currency)}`})</span>
                    </div>
                  </div>
                </div>
              )}
              {product?.qitaf && product?.qitaf?.money_equivalent > 0 && (
                <div className="col-6 ps-1 pe-1">
                  <div className="loyalty__item__box">
                    <img src={quitafcard} alt="quitafcard" />
                    <div className="loyalty__contentbox">
                      <p>{t("Buy_and_Get")} <strong>{` ${product?.qitaf?.points}`} {t("points")}</strong></p>
                      <span>({t("equal to")} {`${ product?.qitaf?.money_equivalent ? product?.qitaf?.money_equivalent : 0 } ${t(product?.currency)}`})</span>
                    </div>
                  </div>
                </div>
              )}
              {product?.niqaty && product?.niqaty?.money_equivalent > 0 && (
                <div className="col-6 ps-1 pe-1">
                  <div className="loyalty__item__box">
                    <img src={imgNiqaty} alt="niqaty" />
                    <div className="loyalty__contentbox">
                      <p>{t("Buy_and_Get")} <strong>{` ${product?.niqaty?.points}`} {t("points")}</strong></p>
                      <span>({t("equal to")} {`${ product?.niqaty?.money_equivalent ? product?.niqaty?.money_equivalent : 0 } ${t(product?.currency)}`})</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
          {/* <hr className="pd__block__bottom__line" />
          <div className="product__offer__block my_reward_icon">
            <img
              src={my_reward_points}
              alt=""
              className="product__offer__icon"
            />
            <Heading6
              text={t("Earn_loyalty_points_just_by_purchasing")}
              marginBottom={15}
            />
          </div>
          <div className="row pd__product__price__block earn_loyalty">
            {product?.meza && product?.meza?.money_equivalent > 0 && (
              <div className="col-5 col-sm-4 col-md-5 pd__total__price__block">
                <img src={mezzacard} alt="Mezza card" />
                <Heading6
                  text={`(${product?.currency} ${
                    product?.meza?.money_equivalent
                      ? product?.meza?.money_equivalent
                      : 0
                  })`}
                  color="#808080"
                  marginBottom={0}
                />
              </div>
            )}
            {product?.meza &&
              product?.qitaf &&
              product?.meza?.money_equivalent > 0 &&
              product?.qitaf?.money_equivalent > 0 && (
                <div className="col-2 col-sm-2 col-md-2 pd__or__block">
                  <div className="pd__or__text__block">
                    <p className="pd__or__text">{t("OR")}</p>
                  </div>
                  <div className="pd__mid__line"></div>
                </div>
              )}
            {product?.qitaf && product?.qitaf?.money_equivalent > 0 && (
              <div className="col-5 col-sm-6 col-md-5 pd__product__monthly__price__block">
                <img src={quitafcard} />
                <Heading6
                  text={`(${product?.currency} ${
                    product?.qitaf?.money_equivalent
                      ? product?.qitaf?.money_equivalent
                      : 0
                  })`}
                  color="#808080"
                  marginBottom={0}
                />
              </div>
            )}
          </div> */}
        </React.Fragment>
      )}
    </>
  );
}

export default MizaAndQitafPDP;