import React from "react";
import shopping_cart from "./../../assets/Icon/shopping_cart.svg";
import { useTranslation } from "react-i18next";
import NotifyMeBtn from "../MostSharedComponent/NotifyMeBtn";
import { Spinner } from "../helpers/utils/spinner";

function AddToCartNotifyMob({ product, AddProductToCart, outOfStockConfiguration, loaderOn }) {

  const { t } = useTranslation();

  const productOutOfStockStatus = product && product?.productOutOfStockStatus ? product?.productOutOfStockStatus : false
  const isOutOfStock = product?.orderType === 'out-of-stock' || product?.stock === 0;
  const isPreOrder = product?.orderType === 'pre-order';

  return (
    <>
      {isOutOfStock && outOfStockConfiguration?.module_status && productOutOfStockStatus ? (
        <NotifyMeBtn product={product} />
      ) : (
        <>
          {isOutOfStock ? (
            <div className="outofStock__button">{t("Out_Of_Stock")}</div>
          ) : isPreOrder ? (
            <div
              className="addToCart__button pre-order pre-order-mbl"
              onClick={() => AddProductToCart(product.sku)}
            >
              {loaderOn === true ?
                <Spinner />
                :
                <>
                  <img src={shopping_cart} alt="" className="addToCart__icon" />
                  {t("pre_order")}
                </>
              }
            </div>
          ) : (
            <div
              onClick={() => AddProductToCart(product.sku)}
              className="addToCart__button"
            >
              {loaderOn === true ?
                <Spinner />
                :
                <>
                  <img src={shopping_cart} alt="" className="addToCart__icon" />
                  {t("Add_To_Cart")}
                </>
              }
            </div>
          )}
        </>
      )}
    </>
  );
}

export default AddToCartNotifyMob;