import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { getProductIndexByVarient } from "../MostSharedComponent/ProductVariantOptions";

function VariantOptions({ variantOptions, sizeButtonClick, sizeButtonIndex, product }) {
  const targetPrice = product?.price_rounded;
  const matchedPrice = product?.variants?.filter(product => product.price_rounded === targetPrice);
  const newMatchPrice = matchedPrice.length > 1 ? matchedPrice[0] : matchedPrice;
  const screenSize = matchedPrice.length > 1 ? newMatchPrice?.options?.screen_size : newMatchPrice[0]?.options?.screen_size;
  const seletcedScreenSize = variantOptions?.[0].attribute?.options.filter(product => product.id === screenSize);
  const handleClick = () =>{
      const targetSKU = product?.sku;
      const matchedSKU = product?.variants?.filter(product => product.sku === targetSKU);
      const matchedSKUScreenSize = matchedSKU && matchedSKU[0]?.options?.screen_size;
      const matchedSKUSeletcedScreenSize = product?.variantOptions?.[0].attribute?.options.filter(product => product.id === matchedSKUScreenSize);
      return matchedSKUSeletcedScreenSize;
      
  }
  const result = handleClick();
 // const productPriceMatch = 

  // const { t } = useTranslation();
  return (
    <div className="size__button__listing">
      {variantOptions?.[0].attribute?.options.map((size, sizeIndex) => {
        let indexOfVariants = getProductIndexByVarient(product, size.id)
        let currentActive;
        if(result.length == 0){
          currentActive = seletcedScreenSize?.[0]?.label === size.label ? "size__button__active" : "size__button";
        }
        else{
          currentActive = result[0]?.label && 
          result[0]?.label === size.label ? "size__button__active"  : "size__button";
        }
        
        return (
          <span>
            {
              <button
              key={size.id}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                sizeButtonClick(indexOfVariants, size)
                handleClick();
              }}
              className={currentActive}
              // className={
              //   sizeButtonIndex === indexOfVariants
              //     ? "size__button__active"
              //     : "size__button"
              // }
            >
              {/* {`${size.label * 2.54} cm (${size.label})`} */}
              {`${size.label}"`}
            </button>
            }
          </span>
        )
      })}
    </div>
  );
}

export default VariantOptions;
