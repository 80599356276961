import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkWishList } from "../helpers/utils/wishListHelper";
import { loadAddToWishlist, loadWishlistData, loadDeleteFromWishlist, deleteFromWishlistData } from "../../redux/appAction";
import { useTranslation } from "react-i18next";
import { checkoutEvents } from "../helpers/utils/ga/checkoutEvents";
import empty_favourite from './../../assets/Icon/empty_favourite.svg';
import fulfill_favourite from './../../assets/Icon/fulfill_favourite.svg';
import { impressionEvents } from "../helpers/utils/ga/impressionEvents";


function FavouriteProduct({ product, screenName }) {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [count, setCount] = useState(0);

  const wishlistData = useSelector((state) => state.appData.wishlistData);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, product?.sku));
  }, [wishlistData]);

  const handleFavourite = () => {
    if (isFavourite) {
      setIsFavourite(false);
      setCount(count + 1);
     
    } else {
      setIsFavourite(true);
      setCount(count + 1);
     
    }
  };
  useEffect(() => {
    const data = {
      items: [product.sku],
    };
    if (isFavourite) {
      if (count > 0) {
        const addToWishlistData = dispatch(loadAddToWishlist(data, t)).then(
          (res) => {
            checkoutEvents.addToWishlist(product, screenName);
            dispatch(loadWishlistData());
          }
        );
      }
    } else {
      if (count > 0) {
        if (!isFavourite) {
          checkoutEvents.removeFromWishlist(product, screenName);
          removeFromWL(product.sku);
        }
      }
    }
  }, [isFavourite]);

  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };
    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data, t)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };

  return (
    <>
        <img
          onMouseEnter={() => setIsFavouriteHover(true)}
          onClick={handleFavourite}
          onMouseLeave={() => setIsFavouriteHover(false)}
          className={
            !isFavourite
              ? "productOne__favourite__icon"
              : "productOne__favourite__icon__disable d-none"
          }
          src={isFavouriteHover ? fulfill_favourite : empty_favourite}
          width="24"
          height="24"
          alt=""
        />
        <img
          onClick={handleFavourite}
          className={
            isFavourite
              ? "productOne__favourite__icon"
              : "productOne__favourite__icon__disable d-none"
          }
          src={fulfill_favourite}
          width="24"
          height="24"
          alt=""
        />
    </>
  );
}

export default FavouriteProduct;
