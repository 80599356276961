export const setHandsakeConfig = (response) => {
    if (response !== "") {
        localStorage.setItem("handShakeConfig", JSON.stringify(response));
    }
};

export const getHandsakeConfig = (number) => {
    let getData = localStorage.getItem("handShakeConfig");

    let returnData = "";
    if (getData !== null) {
        returnData = JSON.parse(getData)
    }
    return returnData;
}

export const getTamaraMinMaxLimit = (price, month) => {
    let getData = JSON.parse(localStorage.getItem("handShakeConfig"));
    let returnData = false;
    if (getData !== null) {
        if (month === 3) {
            let tamara3_max = getData?.featureFlags?.ecom?.bootstraper?.tamara_limits?.tamara3_max
            let tamara3_min = getData?.featureFlags?.ecom?.bootstraper?.tamara_limits?.tamara3_min
            if (price > tamara3_min && price < tamara3_max) {
                returnData = true
            }
        } else if (month === 6) {
            let tamara6_max = getData?.featureFlags?.ecom?.bootstraper?.tamara_limits?.tamara6_max
            let tamara6_min = getData?.featureFlags?.ecom?.bootstraper?.tamara_limits?.tamara6_min
            if (price > tamara6_min && price < tamara6_max) {
                returnData = true
            }
        }
    }
    return returnData;
}
export const TabbyGlobalEnable = () => {
   let getData = JSON.parse(localStorage.getItem("handShakeConfig"));
   return Number(getData?.featureFlags?.ecom?.bootstraper?.tabby);
};
export const TamaraGlobalEnable = () => {
    let getData = JSON.parse(localStorage.getItem("handShakeConfig"));
    return Number(getData?.featureFlags?.ecom?.bootstraper?.tamara);
};
export const getTabbyMinMaxLimit = (price) => {
    let getData = JSON.parse(localStorage.getItem("handShakeConfig"));

    let returnData = false;
    if (getData !== null) {
        let tabby_max = getData?.featureFlags?.ecom?.bootstraper?.tabby_limits?.tabby_max
        let tabby_min = getData?.featureFlags?.ecom?.bootstraper?.tabby_limits?.tabby_min
        if (parseFloat(price) > parseFloat(tabby_min) && parseFloat(price) < parseFloat(tabby_max)) {
            returnData = true
        }
    }
    return returnData;
}

export const getStorePickupStatus = (price, month) => {
    let getData = JSON.parse(localStorage.getItem("handShakeConfig"));

    let returnData = false;
    if (getData !== null) {
        let storePickup = getData?.featureFlags?.ecom?.bootstraper?.store_pickup
        if (storePickup === "1") {
            returnData = true;
        }
    }
    return returnData;
}

export const getTamaraValue = (keyName) => {
    let getData = JSON.parse(localStorage.getItem("handShakeConfig"));
    let returnData = 0
    if (getData !== null) {
        let getVal = getData?.featureFlags?.ecom?.bootstraper?.tamara_limits[keyName]
        returnData = parseFloat(getVal);
    }
    return returnData;
}