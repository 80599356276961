import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form, InputGroup } from "react-bootstrap";
import { BsPlusLg, BsSearch } from 'react-icons/bs';
import { FaCaretDown } from 'react-icons/fa';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import "../../SCSS/_checkoutPage.scss";
import * as services from "../../services/services";
import { addToCart, deleteFromCart, getEstimateShippingMethods, updateShippingInformation, updateShippingInformationSuccess } from '../../services/cart.service';
import { getLoyaltyEarnTypesData, loadCartData, loadCitiesLocationData, loadConfigurationSettingData, loadCountriesLocationData, loadStoresLocationData } from '../../redux/appAction';
import useWindowDimensions from '../../Components/helpers/utils/useWindowDimensions';
import { getCustomerLoginDetails } from "../../Components/helpers/utils/getCustomerLoginDetails";
import apiHelper from '../../Components/helpers/utils/apiHelper';
import { checkoutEvents } from '../../Components/helpers/utils/ga/checkoutEvents';

import BreadCrumbs from '../../Components/BreadCrumbs';
import MetaComponent from '../../Components/Common/MetaComponent'
import OpeningHoursModal from '../../Components/Common/OpeningHoursModal';
import Heading6 from '../../Components/Font/Heading6';
import Virtual_Product_Checkout_Page from '../Virtual_Product_Checkout_Page';
import Text5 from '../../Components/Font/Text5';
import Heading4 from '../../Components/Font/Heading4';
import Price from '../../Components/Font/Price';
import GuestCheckoutPage from '../Guest_Checkout_Page';
import NewLoader from '../../Components/Common/newloader';

import signin_initial from "../../assets/Icon/signin_inprogress.svg";
import signin_inprogress from "../../assets/Icon/signin_inprogress.svg";
import signin_done from "../../assets/Icon/signin_done.svg";
import delivery_initial from "../../assets/Icon/delivery_initial.svg";
import delivery_inprogress from "../../assets/Icon/delivery_inprogress.svg";
import delivery_done from "../../assets/Icon/delivery_done.svg";
import payment_initial from "../../assets/Icon/payment_initial.svg";
import payment_inprogress from "../../assets/Icon/payment_inprogress.svg";
import payment_done from "../../assets/Icon/payment_done.svg";
import done from "../../assets/Icon/done.svg";
import shipping from "../../assets/Icon/shipping.svg";
import pickup_store from "../../assets/Icon/pickup_store.svg";
import Heading7 from '../../Components/Font/Heading7';
import Text4 from '../../Components/Font/Text4';
import edit_box_white from "../../assets/Icon/edit_box_white.svg";
import delete_box_white from "../../assets/Icon/delete_box_white.svg";
import BundleModal from '../../Components/MostSharedComponent/BundleModal';
// import AddressPopup from '../../Components/Popup/AddressPopup';
import Order_Summary_New from '../Order_Summary_New';
import MobileDelivery from './MobileDelivery';
import { Spinner } from '../../Components/helpers/utils/spinner';
import "./../../SCSS/_checkoutSignIn.scss";
import SignSignupTabModal from '../../Components/Common/SignSignupTabModal';
import LoginWrapper from '../../Components/Login/LoginWrapper';
import AddressPopupModal from '../../Components/Popup/AddressPopupModal';
import MobileCheckoutSignin from '../../Pages/Checkout/MobileCheckout_Signin';

const errMsgStyle = {
  color: "red",
  margin: "5px 0px 0px",
};

const CheckoutSignIn = ({ handleChangeCartIconTotal }) => {
  const [modalShow, setModalShow] = useState(false);
  const [openingHoursData, setOpeningHoursData] = useState("");
  const [iconType, setIconType] = useState({
    signin: "done",
    delivery: "inprogress",
    payment: "initial",
  });


  const [storeConfig, setStoreConfig] = useState('');
  const [cartDetail, setCartDetail] = useState("");
  const [custEmailAddress, setCustEmailAddress] = useState("");
  const [cartTotalData, setCartTotalData] = useState();
  const [loading, setLoading] = useState(true);
  const [deliveryType, setDeliveryType] = useState([]);
  const [preferenceTab, setPreferenceTab] = useState("");
  const [errMsg, setErrMsg] = useState({
    deliveryAddressList: "",
    deliveryPreferencesType: "",
  });
  const [deliveryPreferencesType, setDeliveryPreferencesType] = useState("");
  const [storePickupError, setStorePickupError] = useState("");
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [viewMoreAddressBtn, setViewMoreAddressBtn] = useState(false);
  const [selectedAddressId, setSelectedAddressID] = useState(0);
  const [editAddressData, setEditAddressData] = useState("");
  const [addressPopup, setAddressPopup] = useState(false);
  const [addressPopupType, setAddressPopupType] = useState("add");
  const [storeCitiesLocationData, setStoreCitiesLocationData] = useState([]);
  const [shippingCustomMsg, setShippingCustomMsg] = useState("");
  const [storeStoresLocationData, setStoreStoresLocationData] = useState([]);
  const [cityServices, setCityServices] = useState();
  const [selectedBundleService, setSelectedBundleService] = useState({});
  const [NoInsallment, setNoInsallment] = useState(false)
  const [bundleEligible, setBundleEligible] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [filteredStores, setFilteredStores] = useState([]);
  const [bundleCity, setBundleCity] = useState();
  const [loyaltyEarnType, setLoyaltyEarnType] = useState([]);
  const [loyaltyEarnTypeId, setLoyaltyEarnTypeId] = useState("");
  const [loginMode, setLoginMode] = useState("");
  const [loginWrapper, setLoginWrapper] = useState(false);
  const [loginPopup, setLoginPopup] = useState(false);
  const [userLoginPopup, setUserLoginPopup] = useState(false);
  const [categoryPopup, setCategoryPopup] = useState(false);

  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    // debugger

    function init() {
      const latestLoginWrapper = localStorage.getItem("loginWrapper");
      const latestLoginPopup = localStorage.getItem("loginPopup");

      setLoginWrapper(JSON.parse(latestLoginWrapper));
      setLoginPopup(JSON.parse(latestLoginPopup));
    }
    init();
  }, [
    JSON.parse(localStorage.getItem("loginWrapper")),
    JSON.parse(localStorage.getItem("loginPopup")),
    JSON.parse(localStorage.getItem("loginMode")),
  ]);

  const openLoginWrapper = (mode) => {
    // desktop
    setLoginMode(mode);
    setLoginWrapper(true);
    setUserLoginPopup(false);
    localStorage.setItem("loginMode", JSON.stringify(mode));
    localStorage.setItem("loginWrapper", JSON.stringify(true));
    localStorage.setItem("loginPopup", JSON.stringify(true));
  };
  useEffect(() => {
    setLoginMode(loginMode);
  }, [loginWrapper]);

  // useEffect(() => {
  //   if (loginPopup) {
  //     document.body.classList.add("modal_back_stop");
  //   } else {
  //     document.body.classList.remove("modal_back_stop");
  //   }
  // }, [loginPopup]);

  const openLoginPopup = () => {
    setLoginPopup(userLoginPopup ? false : true);
    localStorage.setItem("loginPopup", JSON.stringify(true));
    setCategoryPopup(false);
    setLoginMode("");
  };

  const openProductPopup = () => {
    setCategoryPopup(!categoryPopup);
    setLoginPopup(false);
  };

  const { customerDetails } = useSelector((state) => state.customerReducer);
  const deliveryShippingInfo = useSelector((state) => state.appData.deliveryShippingInfo);
  const cartData = useSelector((state) => state.appData.cartData);
  const cityLocationData = useSelector((state) => state.appData.cityLocationData);
  const { loadingStartEnd } = useSelector((state) => state.commonReducer);
  const { configurationSettingData } = useSelector((state) => state.appData);
  const storesLocationData = useSelector((state) => state.appData.storesLocationData);
  const { customerAddressList } = useSelector((state) => state.customerAddressReducer);
  const loyaltyEarnTypeList = useSelector((state) => state.appData.loyaltyEarnTypeList);
  const selectedLoyaltyPointsProgram = useSelector((state) => state.appData.selectedLoyaltyPointsProgram);

  useEffect(() => {
    if (addressPopup) {
      document.body.classList.add("modal_back_stop");
    } else {
      document.body.classList.remove("modal_back_stop");
    }
  }, [addressPopup]);

  useEffect(() => {
    if (loyaltyEarnTypeList) {
      setLoyaltyEarnType(loyaltyEarnTypeList);
    } else {
      dispatch(getLoyaltyEarnTypesData());
    }
  }, [loyaltyEarnTypeList]);

  useEffect(() => {
    if (selectedLoyaltyPointsProgram !== "") {
      setLoyaltyEarnTypeId(selectedLoyaltyPointsProgram);
    }
  }, [selectedLoyaltyPointsProgram]);

  const handleClickOpeningHour = (valData) => {
    setOpeningHoursData(valData);
    setModalShow(true);
  };

  const openLoginWrapperFromAnywhere = () => {
    // reloadingHeader()

    if (customerDetails === "") {
      const element = document.querySelector(
        ".signin__signup__popup__disable"
      );
      if (element !== null) {
        element.classList.remove("signin__signup__popup__disable");
        element.classList.add("signin__signup__popup");
      }
      localStorage.setItem("loginWrapper", JSON.stringify(true));
      localStorage.setItem("loginMode", JSON.stringify("signin"));
      localStorage.setItem("loginPopup", JSON.stringify(true));
      window.scrollTo(500, 0);
    }
  };

  const customerData = getCustomerLoginDetails();

  useEffect(() => {
    // dispatch(getAvailablePaymentMethods());
    dispatch(services.getCustomerAddressList());
    dispatch(loadCountriesLocationData());
    dispatch(loadCitiesLocationData());
    dispatch(loadStoresLocationData());
    dispatch(getLoyaltyEarnTypesData());
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(loadConfigurationSettingData())
  }, []);

  useEffect(() => {
    setBundleCity(cartData?.totals_data?.items && cartData?.totals_data?.items.length > 0 && cartData?.totals_data?.items.map((item) => item?.extension_attributes).filter(Boolean));
  }, [cartData]);

  useEffect(() => {
    if (cityLocationData) {
      let cityList = [];
      cityLocationData &&
        cityLocationData.map((val, i) => {
          let cityData = {
            value: val.cityCode,
            label: val.cityCode,
            allData: val,
          };
          cityList.push(cityData);
        });
      setStoreCitiesLocationData(cityList);
    }
  }, [cityLocationData])

  useEffect(() => {
    if (storesLocationData) {
      const queryValue = searchData.trim();
      let filterdata = storesLocationData.filter((item) => item.name.toLowerCase().includes(queryValue.toLowerCase()));
      setFilteredStores(filterdata)
    }
  }, [searchData, storesLocationData]);

  const contact_prefill = localStorage.getItem("contact_prefill");
  useEffect(() => {
    if (customerDetails === "") {
      if (contact_prefill !== null) {
        setCustEmailAddress(JSON.parse(contact_prefill).email);
      } else {
        // setCustEmailAddress("");
      }
    } else {
      setCustEmailAddress(customerDetails.email);
    }
  }, [customerDetails, contact_prefill]);

  useEffect(() => {
    if (!loading) {
      const totalData = {
        currencyCode: cartData?.totals_data?.base_currency_code || 'SAR',
        value: Number(parseFloat(cartData?.totals_data?.base_grand_total).toFixed(2)),
      };
      const checkoutProducts = cartData?.items?.map((item) => ({
        item_name: item?.product?.name,
        item_id: item?.product?.id,
        price: Number(parseFloat(
          item?.product?.discounted_price_rounded || item?.product?.price_rounded,
        ).toFixed(2)),
        brand:
          item?.product?.options?.find((option) => option.code === 'brand')
            ?.selected_option?.label || null,
        category: item?.product?.categories?.pop()?.name || 'Sony',
        variant: null,
        quantity: item?.qty,
        dimension1: item?.product.sku,
        coupon: cartData?.totals_data?.coupon_code || null
      }));
      // checkoutEvents.beginCheckout(cartData?.totals_data?.coupon_code || null, checkoutProducts, totalData);
    }
  }, [loading])

  useEffect(() => {
    if (deliveryShippingInfo !== "") {
      // setIconType({ ...iconType, delivery: "done", payment: "inprogress" });
      // navigate("/checkout/payment")
      // setCheckoutClassName("payment");
      // setPaymentMethods(deliveryShippingInfo.payment_methods);
      // setUserPaymentMethod(deliveryShippingInfo.payment_methods[0].code);
      dispatch(updateShippingInformationSuccess(""));
    }
  }, [deliveryShippingInfo]);

  useEffect(() => {
    if (customerDetails === "") {
      if (localStorage.getItem("user_mode") === 'guest_user') {
        localStorage.setItem("loginWrapper", JSON.stringify(false));
        localStorage.setItem("loginMode", JSON.stringify(""));
        localStorage.setItem("loginPopup", JSON.stringify(false));
      } else {
        openLoginWrapperFromAnywhere();
      }

      setIconType({ ...iconType, signin: "inprogress" });
    } else {
      dispatch(services.getCustomerAddressList());
      setIconType({ ...iconType, signin: "done" });
    }
  }, [customerDetails]);

  useEffect(() => {
    if (Object.values(cartData).length !== 0) {
      setCartTotalData(cartData.totals_data);
      setCartDetail(cartData);
      setLoading(false);
      window.scrollTo(0, 0);
    }
  }, [cartData]);

  useEffect(() => {
    if (customerAddressList) {
      let updateAddressData = [];
      customerAddressList &&
        customerAddressList.map((val, i) => {
          let landmarkData = "";
          let addressTypeData = "";
          if (val.custom_attributes !== undefined) {
            let getLandmark =
              val.custom_attributes &&
              val.custom_attributes.filter(
                (val) => val.attribute_code === "landmark"
              );
            if (getLandmark.length > 0) {
              landmarkData = getLandmark[0].value;
            }

            let getAddressType =
              val.custom_attributes &&
              val.custom_attributes.filter(
                (val) => val.attribute_code === "address_types"
              );
            if (getAddressType.length > 0) {
              addressTypeData =
                getAddressType[0].value === "1" ? t("Home") : t("Work");
            }
          }

          let addreDetails = {
            id: val.id,
            isDefault: val.primary,
            userName: `${val.firstname} ${val.lastname}`,
            adddress: `${val.street[0]}, ${val.street[1] !== undefined ? val.street[1] : ""
              } ${val.city}, ${landmarkData}, ${val.postcode !== undefined ? val.postcode : ""
              } ${val.country_id}`,
            contact: val.telephone,
            details: val,
            addressTypeData: addressTypeData,
          };
          updateAddressData.push(addreDetails);
          if (val.primary === true) {
            setSelectedAddressID(i);
          }
        });
      setAddressData(updateAddressData);
    }
  }, [customerAddressList]);

  useEffect(() => {
    if (storesLocationData) {
      let onlyStoreList =
        storesLocationData
      // storesLocationData.filter((item) => item.is_service_center === false);
      // let onlyStoreList = storesLocationData;
      setStoreStoresLocationData(onlyStoreList);
    }
  }, [storesLocationData]);

  useEffect(() => {
    setStoreConfig(configurationSettingData?.store_pickup)
  }, [configurationSettingData])

  // Delivery Preferences
  useEffect(async () => {
    if (storeConfig !== undefined && storeConfig !== "" && width > 992) {
      dispatch(services.loaderStart())
      const data = await getEstimateShippingMethods();
      if (data) {
        let shippingMethods = data["shipping-methods"];
        const propertyNames = Object.keys(shippingMethods);
        let deliveryData = [];
        propertyNames &&
          propertyNames.map((val, i) => {
            let deliveryInfo = {
              id: shippingMethods[val].shipping_method_code,
              type: shippingMethods[val].title,
              protectionText: shippingMethods[val].head_line,
              price: shippingMethods[val].shipping_cost,
              is_available: shippingMethods[val]?.is_available
            };
            if (shippingMethods[val].shipping_method_code === 'storepickup' && storeConfig === "1") {
              deliveryData.push(deliveryInfo);
            } else if (shippingMethods[val].shipping_method_code !== 'storepickup') {
              deliveryData.push(deliveryInfo);
            }
          });
        setDeliveryType(deliveryData);

        const deliveryDataIds = deliveryData?.filter((item) => item?.is_available).map((item) => item?.id)
        let lastDt = localStorage.getItem("last-dt");
        if ((deliveryDataIds).includes(lastDt)) {
          setDeliveryPreferencesType(lastDt)
        } else {
          setDeliveryPreferencesType(deliveryData[0].id)
        }
      }
      dispatch(services.loaderEnd())
    }
  }, [storeConfig]);

  useEffect(() => {
    const deliveryDataIds = deliveryType?.filter((item) => item?.is_available).map((item) => item?.id)
    let lastDt = localStorage.getItem("last-dt");

    if (deliveryType && deliveryType.length > 0 && cartData && !deliveryDataIds.includes(lastDt)) {
      if (deliveryType &&
        deliveryType.filter((dev) => dev.id === "flatrate" && dev?.is_available)
          .length > 0) {
        setPreferenceTab("home")
        localStorage.setItem("last-dt", "flatrate");
      } else if (deliveryType &&
        deliveryType.filter((dev) => dev.id === "storepickup" && dev?.is_available)
          .length > 0) {
        setPreferenceTab("storepickup")
        setDeliveryPreferencesType("storepickup")
        localStorage.setItem("last-dt", "storepickup");
      } else if (deliveryType &&
        (deliveryType.filter((dev) => dev.id === "flatrate" && dev?.is_available)
          .length < 1) && (deliveryType &&
            deliveryType.filter((dev) => dev.id === "storepickup" && dev?.is_available)
              .length < 1) && !cartData?.is_virtual) {
        navigate('/cart')
      }
    }
  }, [deliveryType, cartData])

  useEffect(() => {
    let lastDt = localStorage.getItem("last-dt");
    if (lastDt === "flatrate") {
      lastDt = "home";
    } else if (lastDt === "storepickup") {
      lastDt = "storepickup";
    } else {
      lastDt = "";
    }
    setPreferenceTab(lastDt);
  }, []);

  const handleChangeCreateBillingAddress = (email) => {
    setIconType({ ...iconType, delivery: "done", payment: "inprogress" });
    // setCheckoutClassName("payment");
    // if (paymentMethods.length > 0) {
    //   let getFirstMethod = paymentMethods.filter(val => val.is_available === true);
    //   if (getFirstMethod.length > 0) {
    //     setUserPaymentMethod(getFirstMethod[0].code);
    //   }
    // }
    setCustEmailAddress(email);
    dispatch(updateShippingInformationSuccess(""));
    navigate("/checkout/payment")
  };

  const handleChangePreferenceTab = (name) => {
    const lastDt = name === "home" ? "flatrate" : "storepickup";
    localStorage.setItem("last-dt", lastDt);
    setPreferenceTab(name);
    setErrMsg({
      ...errMsg,
      deliveryPreferencesType: "",
    });
    setStorePickupError("");
    if (name === "home") {
      setDeliveryPreferencesType(deliveryType?.[0].id);
    } else {
      setDeliveryPreferencesType(name);
    }
  };

  const selectAddress = (addIndex, addId, add) => {
    setSelectedAddressID(addIndex);
    setEditAddressData(add);
    // setDeliveryPreferencesType("");
  };

  const openNewAddressPopup = (popupType, addIndex, addId, add) => {
    setAddressPopup(true);
    setAddressPopupType(popupType);
    if (popupType === "update") {
      setSelectedAddressID(addIndex);
      setEditAddressData(add);
    }
  };

  const deleteAddress = (deleteId) => {
    let params = {
      addressId: deleteId,
    };
    dispatch(services.deleteCustomerAddress(params));
  };

  const getDeliveryMethodByCity = (cityName, shippingType) => {
    let shippingIsEnable = false;
    if (shippingType === "express") {
      let expShipping =
        storeCitiesLocationData &&
        storeCitiesLocationData.filter(
          (val) =>
            val.allData.cityCode === cityName &&
            val.allData.expressShipping === "1"
        );
      if (expShipping.length > 0) {
        shippingIsEnable = true;
      }
    }
    if (shippingType === "lighting") {
      let lightShipping =
        storeCitiesLocationData &&
        storeCitiesLocationData.filter(
          (val) =>
            val.allData.cityCode === cityName &&
            val.allData.lightingShipping === "1"
        );
      if (lightShipping.length > 0) {
        shippingIsEnable = true;
      }
    }
    return shippingIsEnable;
  };

  const handleChangeDeliveryPref = (e, value) => {
    setDeliveryPreferencesType(value);
    localStorage.setItem("deliveryPref", value);
    setSelectedStoreId("");
    setStorePickupError("");
  };

  const handleChangePickupStore = (storeId) => {
    setSelectedStoreId(storeId);
  };

  const handleSetOrderComment = async () => {
    if (shippingCustomMsg) {
      try {
        // const response = 
        await apiHelper(
          `/V1/set-order-comment?comment=${shippingCustomMsg}&cartId=${cartDetail?.id}`,
          "put",
          {},
          {}
        );
      } catch (error) {
        // console.log("error ", error);
      }
    }
  };

  let bundleData = [];
  const data_bundle_id = [];
  const bundleProductData = [];
  const bundle_item_counts = [];
  const allSelectedServices = [];

  useEffect(() => {
    if (bundleData) {
      bundleData = cartDetail?.items?.filter(
        (item) => item.product_type === 'bundle',
      );
      for (let index = 0; index < bundleData?.length; index++) {
        data_bundle_id.push(bundleData[index]?.item_id);
        bundle_item_counts.push(bundleData[index]);
      }
      bundleData?.map((item1) => item1?.product?.bundle_product_options?.map((itm) => bundleProductData.push(itm)));
    }
  }, [bundleData]);


  useEffect(() => {
    if (cartData) {
      // setPickupCustomerDetails(
      //   cartData.customer?.addresses?.filter((item) => item.default_shipping)
      // );
      setBundleEligible(
        cartData?.items?.filter((item) => item?.product_type === "bundle")
      );
    }
    // let result = false;
    // if (deliveryType) {
    //   if (deliveryType === "flatrate") {
    //     result = true;
    //   } else if (deliveryType === "storepickup" && deliveryStore) {
    //     result = true;
    //   }
    // } else {
    //   result = cartData?.is_virtual;
    // }
    // setIsValid(result);
    const totals_items = cartData?.totals_data?.items;
    const cartItems = cartData?.items?.map((item) =>
      totals_items?.find((t) => t.item_id === item.item_id)
    );
    cartItems?.map((itm) =>
      itm?.extension_attributes?.options_bundle
        ?.filter((itm) => itm.title.toLowerCase().includes("service"))
        .map((service) => {
          allSelectedServices.push(service);
          setSelectedBundleService(service);
          return service;
        })
    );
    setCityServices(allSelectedServices);
  }, [
    cartData,
    deliveryType,
    // selectedShippingAddress,
    // selectedBillingAddress,
    // deliveryStore,
  ]);



  const removeServiceFromCart = async () => {
    await deleteFromCart({ items: data_bundle_id });
    const payload = [];
    const filteredProductData = bundleProductData?.filter(
      (item) => item?.required === '1',
    );
    for (let index = 0; index < filteredProductData?.length; index++) {
      const quantity = bundle_item_counts[index];
      const object = {
        sku: filteredProductData[index].sku,
        qty: quantity?.qty,
        bundle_options: [
          {
            option_id: +filteredProductData[index]?.product_links[0]?.option_id,
            option_qty: 1,
            option_selections: [
              +filteredProductData[index]?.product_links[0]?.id,
            ],
          },
        ],
      };
      payload.push(object);
    }

    await addToCart({ items: payload });
  };

  const guestPayment = async () => {
    setNoInsallment(true)
    await removeServiceFromCart();
    setShowModal(false);
    const form = document.getElementById('guest_payment');
    form.click();
  };

  const continueFromDelivery = async (newIconType, className) => {
    // setLoaderOn(true);
    // setContinueDeliveryLoading(true);
    let newErrObj = {
      deliveryPreferencesType: "",
      deliveryAddressList: "",
    };

    if (deliveryPreferencesType !== "") {
      newErrObj = { ...newErrObj, deliveryPreferencesType: "" };
    } else {
      newErrObj = {
        ...newErrObj,
        deliveryPreferencesType: t("Select_your_shipping_option")

      };
      // setContinueDeliveryLoading(false);
      setShowModal(false)
    }
    const bundleCityId = selectedBundleService?.sku?.replace(/[^\d.]/g, "");
    const address = addressData?.[selectedAddressId]?.details?.city;
    const data =
      cityLocationData && cityLocationData.find((i) => i.cityCode === address);
    const selectedCityId = data?.id;

    if (deliveryPreferencesType !== "") {
      if (
        (cityServices?.length === 1 && selectedCityId === bundleCityId) ||
        // (cityServices?.length === 1 && loggedInCityId === bundleCityId) ||
        !bundleEligible?.length || // if no bundle product
        Object.getOwnPropertyNames(selectedBundleService).length === 0 // if object is empty
        || NoInsallment === true
      ) {
        let getDeliveryInfo = addressData?.[selectedAddressId];
        if (
          getDeliveryInfo !== undefined &&
          selectedAddressId !== "" &&
          custEmailAddress !== ""
        ) {
          if (deliveryPreferencesType === "flatrate") {
            let params = {
              useAsBilling: true,
              firstName:
                getDeliveryInfo.details.firstname === undefined
                  ? ""
                  : getDeliveryInfo.details.firstname,
              lastName:
                getDeliveryInfo.details.lastname === undefined
                  ? ""
                  : getDeliveryInfo.details.lastname,
              email: custEmailAddress,
              telephone:
                getDeliveryInfo.details.telephone === undefined
                  ? ""
                  : getDeliveryInfo.details.telephone,
              city:
                getDeliveryInfo.details.city === undefined
                  ? ""
                  : getDeliveryInfo.details.city,
              postCode:
                getDeliveryInfo.details.postcode === undefined
                  ? ""
                  : getDeliveryInfo.details.postcode,
              countryId: getDeliveryInfo.details.country_id,
              street: `${getDeliveryInfo.details.street[0]} ${getDeliveryInfo.details.street[1]}`,
              shippingCarrierCode: deliveryPreferencesType,
              // pickup_store: '',
              region_id:
                getDeliveryInfo.details.region_id === undefined
                  ? 0
                  : getDeliveryInfo.details.region_id,
            };

            dispatch(updateShippingInformation(params));
            // setContinueDeliveryLoading(false);
            handleSetOrderComment();
          } else if (deliveryPreferencesType !== "storepickup") {
            let params = {
              useAsBilling: true,
              firstName:
                getDeliveryInfo.details.firstname === undefined
                  ? ""
                  : getDeliveryInfo.details.firstname,
              lastName:
                getDeliveryInfo.details.lastname === undefined
                  ? ""
                  : getDeliveryInfo.details.lastname,
              email: custEmailAddress,
              telephone:
                getDeliveryInfo.details.telephone === undefined
                  ? ""
                  : getDeliveryInfo.details.telephone,
              city:
                getDeliveryInfo.details.city === undefined
                  ? ""
                  : getDeliveryInfo.details.city,
              postCode:
                getDeliveryInfo.details.postcode === undefined
                  ? ""
                  : getDeliveryInfo.details.postcode,
              countryId: getDeliveryInfo.details.country_id,
              street: `${getDeliveryInfo.details.street[0]} ${getDeliveryInfo.details.street[1]}`,
              shippingCarrierCode: deliveryPreferencesType,
              // pickup_store: '',
              region_id:
                getDeliveryInfo.details.region_id === undefined
                  ? 0
                  : getDeliveryInfo.details.region_id,
            };

            dispatch(updateShippingInformation(params));
            // setContinueDeliveryLoading(false);
            handleSetOrderComment();
          }
        } else {
          if (deliveryPreferencesType === "flatrate") {
            if (addressData.length < 1) {
              newErrObj = {
                ...newErrObj,
                deliveryAddressList: t("Add_New_Address"),
              };
            } else {
              newErrObj = {
                ...newErrObj,
                deliveryAddressList: t("Please_Select_Delivery_Address"),
              };
            }
          }
          // setContinueDeliveryLoading(false);
        }

        if (deliveryPreferencesType === "storepickup") {
          if (selectedStoreId === "") {
            setStorePickupError(t("Please_selecte_store"));
            // setContinueDeliveryLoading(false);
          } else {
            setStorePickupError("");
            let params = {
              useAsBilling: true,
              firstName: customerData.firstname,
              lastName: customerData.lastname,
              email: customerData.email,
              telephone: customerData.custom_attributes.mobile,
              city: storeStoresLocationData?.[selectedStoreId]?.city,
              postCode: "",
              countryId: storeStoresLocationData?.[selectedStoreId]?.country,
              street: storeStoresLocationData?.[selectedStoreId]?.address,
              shippingCarrierCode: deliveryPreferencesType,
              pickup_store: storeStoresLocationData?.[selectedStoreId]?.storeCode,
              region_id: '76',
            };
            dispatch(updateShippingInformation(params));
            // setContinueDeliveryLoading(false);
            handleSetOrderComment();
          }
        }
        await dispatch(loadCartData());
        setNoInsallment(false)
        const totalData = { currencyCode: cartData?.totals_data?.base_currency_code || 'SAR', value: Number(parseFloat(cartData?.totals_data?.base_grand_total).toFixed(2)) };
        const checkoutProducts = cartData?.items?.map((item) => ({
          item_name: item?.product?.name,
          item_id: item?.product?.id,
          price: Number(parseFloat(item?.product?.discounted_price_rounded || item?.product.price_rounded).toFixed(2)),
          brand: item?.product?.options?.find((option) => option.code === 'brand')?.selected_option?.label || null,
          category: item.product?.categories?.pop()?.name || 'Sony',
          variant: null,
          quantity: item?.qty,
          dimension1: item?.product.sku,
          coupon: cartData?.totals_data?.coupon_code || null
        }));
        checkoutEvents.addShippingInfo(cartData?.totals_data?.coupon_code || null, checkoutProducts, totalData);
      } else {
        setShowModal(true);
        setNoInsallment(false)
      }
    }
    setErrMsg(newErrObj);
    // setIconType(newIconType);
    // setCheckoutClassName(className);
    window.scrollTo(0, 0);
    const errorArr = [...new Set(Object.values(newErrObj))]
    if (errorArr.length < 2 && errorArr[0] === "") {
      if (deliveryPreferencesType === "storepickup") {
        if (selectedStoreId !== "") {
          navigate("/checkout/payment")
        }
      } else {
        navigate("/checkout/payment")
      }
    }
  };

  const proceedPayment = async () => {
    setNoInsallment(true)
    await removeServiceFromCart();
    setShowModal(false);
    const form = document.getElementById('delivery-form');
    form.click();
  };

  const closeModal = () => {
    setShowModal(false);
    window.scrollTo(0, 0);
  };

  const closeLoginPopup = () => {
    if (document.querySelector(".address__popup__container")) {
      // reloadingHeader()
      const element = document.querySelector(".address__popup__container");
      if (element !== null) {
        element.classList.remove("address__popup__container");
        element.classList.add("address__popup__container__disable");
      }
    }
    setAddressPopup(false);
  };

  function Position(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return currenttop > 200 ? [currenttop - 200] : [0];
    }
  }

  useEffect(() => {
    const errorElement = document.querySelector(".invalid__message");
    if (errorElement && document.activeElement.tagName !== "INPUT") {
      window.scrollTo(0, Position(errorElement));
    }
  }, [errMsg, storePickupError])

  if (loading) {
    return <>
      <NewLoader />;
      {width > 992 ? <div className="container-fluid  breadcrumbs__block bg-transparent">
        <Skeleton width={"210px"} height="18px" />
        <br />
      </div> :
        <div className="px-3">
          <Skeleton width={"100%"} height="50px" />
        </div>
      }
      <div className={`container-fluid ${width > 992 ? "checkout__page__block" : "px-3"}`}>
        <div className="row checkout__page__inner__block">
          <div className="col-md-12 col-xl-8  checkout__left__block">
            <Skeleton width={"100%"} height="60px" />
            <Skeleton width={"100%"} height="400px" />
          </div>
          <div className="col-md-12 col-xl-4  checkout__right__block">
            <Skeleton width={"100%"} height="400px" />
          </div>
        </div>
      </div>
    </>
  }

  return (<>
    <MetaComponent
      title={t("Checkout")}
      meta_title={t("Checkout")}
      meta_keywords={t("Checkout")}
      meta_description={t("Checkout")}
    />
    {modalShow && <OpeningHoursModal
      modalShow={modalShow}
      val={openingHoursData}
      onHide={() => setModalShow(false)}
    />}
    {width <= 992 &&
      <div className="d-block d-lg-none">
        <MobileDelivery
          cartDetail={cartDetail}
          shippingCustomMsg={shippingCustomMsg}
          setShippingCustomMsg={setShippingCustomMsg}
          bundleCity={bundleCity}
          handleSetOrderComment={handleSetOrderComment}
          cartTotalData={cartTotalData}
          loyaltyEarnType={loyaltyEarnType}
          loyaltyEarnTypeId={loyaltyEarnTypeId}
          storeConfig={storeConfig}
        />
        <MobileCheckoutSignin
          handleChangeCartIconTotal={handleChangeCartIconTotal}
          cartDetail={cartDetail}
          cartTotalData={cartTotalData}
          loyaltyEarnType={loyaltyEarnType}
          loyaltyEarnTypeId={loyaltyEarnTypeId}
          cartDetail={cartDetail}
          cartTotalData={cartTotalData}
          loyaltyEarnType={loyaltyEarnType}
          loyaltyEarnTypeId={loyaltyEarnTypeId}
        />
      </div>
    }
    {width > 992 && <>
      <div className="d-lg-block d-none">
        <BreadCrumbs
          back="/cart"
          firstBread="/cart"
          firstBreadTitle={t("Shopping_cart")}
          secondBread="/checkout/delivery"
          secondBreadTitle={t("Delivery")}
          highlight="secondBreadTitle"
        />
      </div>
      <div className="container-fluid checkout__page__container d-lg-block d-none">
        <div className="checkout__page__block">
          <div className="row checkout__page__inner__block">
            <div className="col-md-12 col-xl-8  checkout__left__block">
              <div className="row checkout__login__main__block">
                <div
                  onClick={() => openLoginWrapperFromAnywhere()}
                  className="col-2 checkout__signin__button"
                >
                  <img
                    src={
                      iconType.signin === "inprogress"
                        ? signin_inprogress
                        : iconType.signin === "done"
                          ? signin_done
                          : signin_initial
                    }
                    alt=""
                  />
                  <Heading6
                    text={t("Sign_In")}
                    marginLeft={10}
                    color={
                      iconType.signin === "inprogress"
                        ? "#DC3A1A"
                        : iconType.signin === "done"
                          ? "#585858"
                          : "#C8C8C8"
                    }
                    span={true}
                  />
                  {iconType.signin === "done" ? (
                    <img className="done__icon" src={done} alt="done" />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-3 checkout__middle__line__block"></div>
                <div
                  // onClick={() => handleChangeClassName("delivery")}
                  className="col-2 checkout__delivery__button"
                >
                  <img
                    src={
                      iconType.delivery === "inprogress"
                        ? delivery_initial
                        : iconType.delivery === "done"
                          ? delivery_done
                          : delivery_initial
                    }
                    alt=""
                  />
                  <Heading6
                    text={t("DELIVERY")}
                    marginLeft={10}
                    color={
                      iconType.delivery === "inprogress"
                        ? "#C8C8C8"
                        : iconType.delivery === "done"
                          ? "#585858"
                          : "#C8C8C8"
                    }
                    span={true}
                  />
                  {iconType.delivery === "done" ? (
                    <img className="done__icon" src={done} alt="done" />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-3 checkout__middle__line__block"></div>
                <div
                  // onClick={() => handleChangeClassName("payment")}
                  className="col-2 checkout__payment__button"
                >
                  <img
                    src={
                      iconType.payment === "inprogress"
                        ? payment_inprogress
                        : iconType.payment === "done"
                          ? payment_done
                          : payment_initial
                    }
                    alt=""
                  />
                  <Heading6
                    text={t("PAYMENT")}
                    marginLeft={10}
                    color={
                      iconType.payment === "inprogress"
                        ? "#DC3A1A"
                        : iconType.payment === "done"
                          ? "#585858"
                          : "#C8C8C8"
                    }
                    span={true}
                  />
                  {iconType.payment === "done" ? (
                    <img className="done__icon" src={done} alt="done" />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* <h1>sign in form</h1> */}
              <div className='checkout__signin__form__area'>
                <SignSignupTabModal
                  handleChangeCartIconTotal={handleChangeCartIconTotal}
                  loginMode={loginMode}
                  loginWrapper={loginWrapper}
                  // loginPopup={loginPopup}
                  setLoginMode={setLoginMode}
                />
              </div>



            </div>
            {/* package Summary */}
            <div className="col-md-12 col-xl-4  checkout__right__block">
              {typeof cartDetail === "object" &&
                typeof cartTotalData === "object" && (
                  <Order_Summary_New
                    cartDetail={cartDetail}
                    cartTotalData={cartTotalData}
                    loyaltyEarnType={loyaltyEarnType}
                    loyaltyEarnTypeId={loyaltyEarnTypeId}
                    loyaltyRadioName="loyaltyEarnTypeDesk"
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </>}
    {addressPopup === true &&
      <AddressPopupModal
        closeLoginPopup={closeLoginPopup}
        editAddressData={editAddressData}
        popupType={addressPopupType}
      />
    }


    {/* {addressPopup === true && width > 992 && (
      <div
        className={
          addressPopup
            ? "container-fluid address__popup__container"
            : "container-fluid address__popup__container__disable"
        }
      >
        <AddressPopup
          closeLoginPopup={closeLoginPopup}
          editAddressData={editAddressData}
          popupType={addressPopupType}
        />
      </div>
    )} */}
  </>
  )
}

export default CheckoutSignIn