import React from "react";
import { useTranslation } from "react-i18next";
import "./../../../SCSS/ProductListPage/_facets.scss";
import SingleFacet from "./SingleFacet";
function BrandFacet({
  facetData,
  facetKey,
  onFilter,
  mobileView = false,
  values = [],
}) {
  const constructParamFn = (item) => `${item.key}`;
  const { t } = useTranslation();

  const childrenFn = (item) => (
    <>
      <span className="me-2">{item.label}</span>
      <span>{`(${item.count})`}</span>
    </>
  );
  const handleChange = (facetKey, item) => {
    onFilter(
      facetKey,
      constructParamFn(item),
      facetData[facetKey].multiple_selectable
    );
  };
  return (
    <div className="main__facets__block">
      {!mobileView && <h1 className="main__facets__title">{t("Brand")}</h1>}
      {facetData[facetKey]?.collection?.map((item, itemIndex) => {
        const brandChecked = values?.includes(constructParamFn(item));
        return (
          // <SingleFacet
          //   constructParamFn={constructParamFn}
          //   childrenFn={childrenFn}
          //   key={`${Math.random(Math.random() * 100) + 1}${facetKey}`}
          //   // key={itemIndex}
          //   facetData={facetData}
          //   facetKey={facetKey}
          //   item={item}
          //   onFilter={onFilter}
          // />
          <div className="facet__block" key={itemIndex}>
            <input
              // key={`${Math.random(Math.random() * 100) + 1}${facetKey}`}
              type="checkbox"
              className="preferences__select__input__check"
              name="color"
              // value={item.key}
              defaultChecked={false}
              onChange={() => handleChange(facetKey, item)}
              checked={brandChecked}
              id={`${item.query_filter_param}__${itemIndex}`}
            />
            <label
              className="facet__item__label"
              for={`${item.query_filter_param}__${itemIndex}`}
            // onClick={() => onFilter(facetKey, constructParamFn(item), facetData[facetKey].multiple_selectable)}
            >
              {childrenFn(item)}
            </label>
          </div>
        );
      })}
    </div>
  );
}

export default BrandFacet;
