import React from "react";
import { selectLoyaltyPointsProgram } from "../../services/cart.service";
import { useDispatch } from "react-redux";
import mezzacard from './../../assets/Icon/mezzacard.png';
import './../../SCSS/Common/loyalty__points__block.scss';
import Text4 from "../Font/Text4";
import { useTranslation } from "react-i18next";
const niqaty = require("./../../assets/Icon/niqaty.png")

function LoyaltyPointBlock({ loyaltyEarnType, loyaltyEarnTypeId, currency, loyaltyRadioName, cartDetail }) {

  const dispatch = useDispatch();
  const { t } = useTranslation()
  const handleChangeLoyaltyEarnType = (event) => {
    dispatch(selectLoyaltyPointsProgram(event.target.value))
  }

  const checkVirtualItemsInCart = (items) => {
    let isVirtual = false;
    let checkVirtualItems = items && items.filter(
      (val) => val.product_type === "virtual"
    );
    if (checkVirtualItems.length > 0) {
      if (checkVirtualItems.length === items.length) {
        isVirtual = true;
      }
    }
    return isVirtual;
  }

  const currentLang = localStorage.getItem("i18next") || "en";
  let quitafcard = require(`./../../assets/Icon/icon-qitaf-${currentLang}.png`)

  return (
    <>
      {checkVirtualItemsInCart(cartDetail.items) === false && loyaltyEarnType && loyaltyEarnType.filter((item, i) => item.available === true).length > 0 &&
        <>
          <div className="pt-2 loyalty__points__block">
            <div className="pb-2">
              <Text4 text={t('Choose_your_favorite_loyalty_points_program')} />
            </div>
            <ul>
            {loyaltyEarnType && loyaltyEarnType.filter((item, i) => item.available === true).map((item, i) => {
              let imageUrl = item.program === "meza" ? mezzacard : item.program === "niqaty" ? niqaty : quitafcard
              return (
                <li className="d-flex align-items-start w-100 justify-content-between" key={i}>
                  <div className="align-items-center d-flex">
                    <input
                      type="radio"
                      name={loyaltyRadioName}
                      value={item.program}
                      id={item.program}
                      onChange={(e) =>
                        handleChangeLoyaltyEarnType(e)
                      }
                      checked={loyaltyEarnTypeId === item.program ? true : false}
                    />
                    <label className="m-0 p-0" htmlFor={item.program}><img src={imageUrl} /></label>
                  </div>

                  <p>
                    <span className="sum__points d-block">+{`${item.points}`} {t("points")}</span>
                    {/* <span className="sum__eq">{t("equals_to")} {`${currency} ${item.money_equivalent === undefined ? 0 : item.money_equivalent}`}</span> */}
                  </p>
                </li>
              )
            })}
            </ul>
          </div>
          <hr className="mb-0" />
        </>
      }
    </>
  );
}

export default LoyaltyPointBlock;