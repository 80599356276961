import React, { useEffect, useState, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./../SCSS/_cartPage.scss";
import BreadCrumbs from "../Components/BreadCrumbs";
import { Link, useNavigate } from "react-router-dom";
import checkout_white_right_arrow from "./../assets/Icon/checkout_white_right_arrow.svg";
import Heading3 from "../Components/Font/Heading3";
import Heading4 from "../Components/Font/Heading4";
import Text3 from "../Components/Font/Text3";
import Price from "../Components/Font/Price";
import Heading6 from "../Components/Font/Heading6";
import Text1 from "../Components/Font/Text1";
import Heading5 from "../Components/Font/Heading5";
import {
  loadCartData,
  getLoyaltyEarnTypesData,
  loadConfigurationSettingData,
} from "../redux/appAction";
import LoyaltyPointBlock from "../Components/MostSharedComponent/LoyaltyPointBlock";
import mezzacard from "./../assets/Icon/mezzacard.png";
import empty_Cart from "./../assets/Icon/empty-cart@2x.png";
import closeicon from "./../assets/Icon/cancel_grey.svg";
import apiHelper from "../Components/helpers/utils/apiHelper";
import * as services from "./../services/services";
import { useTranslation } from "react-i18next";
import { getAvailablePaymentMethods, getEstimateShippingMethods } from "../services/cart.service";
import MetaComponent from "../Components/Common/MetaComponent";
import Text31 from "../Components/Font/Text3.1";
import { getCartTotalSegments } from "../Components/helpers/utils/getCartTotalSegments";
import NewLoader from "../Components/Common/newloader";
import { cartEvents } from "../Components/helpers/utils/ga/cartEvents";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import useWindowDimensions from "../Components/helpers/utils/useWindowDimensions";
import { getHomePageData } from "../services/homepage.service";
import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { Spinner } from "../Components/helpers/utils/spinner";
import TabbyWidget from "../Components/MostSharedComponent/TabbyWidget";
import TamaraProductWidget from "./TamaraProductWidget";
import TamaraInstallmentPlanWidget from "./TamaraInstallmentPlanWidget";
import { getTamaraValue } from "../Components/helpers/utils/handsakeConfig";
import { impressionEvents } from "../Components/helpers/utils/ga/impressionEvents";
const niqaty = require("./../assets/Icon/niqaty.png")

const MobileCartPage = lazy(() => import("./MobilePages/Mobile_Cart_Page"));
const RecommandProducts = lazy(() => import("../Components/ProductType/RecommandProducts"));
const ShoppipngCartProduct = lazy(() => import("../Components/MostSharedComponent/ShoppipngCartProduct"));

function Cart_Page({ handleChangeCartPopup }) {
  const [qtyIsDisabled, setQtyIsDisabled] = useState(false);
  const [cartProductData, setCartProductData] = useState();
  const [loading, setLoading] = useState(true);
  const [cartTotalData, setCartTotalData] = useState();
  const [cartItem, setCartItem] = useState(0);
  const [loyaltyEarnType, setLoyaltyEarnType] = useState([]);
  const [loyaltyEarnTypeId, setLoyaltyEarnTypeId] = useState("");
  const { t } = useTranslation();
  const [topTrendingData, setTopTrendingData] = useState("");
  const [isMore, setIsMore] = useState(false);
  const [outOfStockConfiguration, setOutOfStockConfiguration] = useState({});
  const [isBundleExist, setIsBundleExist] = useState(false);
  // const [storePickupGlobal, setStorePickupGlobal] = useState(true);
  const [bundleStatus, setBundleStatus] = useState(0);
  const [storeConfig, setStoreConfig] = useState('');
  const [noDelivery, setNoDelivery] = useState(false);
  const [isShipping, setIsShipping] = useState(false);
  const [coupenLoading, setCoupenLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [isTabbyEnabled, setTabbyEnabled] = useState(false)
  const [isTamaraEnabled, setIsTamaraEnabled] = useState(false)
  const [isTamaraEnabled6, setIsTamaraEnabled6] = useState(false)
  const [isTamaraEnabled4, setIsTamaraEnabled4] = useState(false)
  const [tamara3MonthStatus, setTamara3MonthStatus] = useState(false);
  const [tamara6MonthStatus, setTamara6MonthStatus] = useState(false);
  const [allStoreConfig, setAllStoreConfig] = useState('');
  const [availablityData, setAvailablityData] = useState([])
  const [cashbackAmount, setCashbackAmount] = useState(0);
  
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const navigate = useNavigate()
  const cartData = useSelector((state) => state.appData.cartData);
  const authRandom = useSelector((state) => state.appData.random);
  // const { homepageData } = useSelector((state) => state.appData);

  useEffect(() => {
    dispatch(loadConfigurationSettingData())
    dispatch(getLoyaltyEarnTypesData());
  }, []);

  let getData = JSON.parse(localStorage.getItem("handShakeConfig"));
  const userloginData = useSelector((state) => state.appData.userLoggedIn);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!cartData?.id) {
        dispatch(services.loaderStart())
        dispatch(loadCartData()).then(() => {
          dispatch(services.loaderEnd())
        });
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [cartData]);

  const currentLanguageCode = localStorage.getItem("i18next") || "en";
  const { data: homepageData = {} } = useQuery(
    ["homepageData", currentLanguageCode],
    () => getHomePageData(currentLanguageCode),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10,
      staleTime: Infinity
    }
  );

  // useEffect(() => {
  //   if(Object.keys(homepageData).length === 0){
  //     dispatch(loadHomePageData());
  //   }
  // }, [homepageData])

  const { configurationSettingData } = useSelector((state) => state.appData);

  useEffect(() => {
    if (configurationSettingData?.store_pickup !== undefined) {
      setStoreConfig(configurationSettingData?.store_pickup)
    }
    setAllStoreConfig(configurationSettingData);
  }, [configurationSettingData])
  
  useEffect(() => {
    console.log('cartData', cartData);
    if (cartData?.id) {
      setCartTotalData(cartData.totals_data);
      cartData?.items?.length > 0 && setCashbackAmount(
        cartData?.items
          ?.map((item) => item?.extension_attributes?.cashback_amount)
          ?.reduce((arr, it) => arr + it)
          ?.toFixed(2),
      );
      const fetchData = async () => {
        let storeData = allStoreConfig;
        if(!storeData){
          storeData = configurationSettingData;
        }
        if (cartData?.totals_data?.base_grand_total < storeData?.tamara_limits?.tamara3_max && cartData?.totals_data?.base_grand_total > storeData?.tamara_limits?.tamara3_min) {
          setTamara3MonthStatus(true);
        }else{
          setTamara3MonthStatus(false);
          console.log('comming on false');
        }
        if (cartData?.totals_data?.base_grand_total < storeData?.tamara_limits?.tamara6_max && cartData?.totals_data?.base_grand_total > storeData?.tamara_limits?.tamara6_min) {
          setTamara6MonthStatus(true);
        }else{
          setTamara6MonthStatus(false);
        }
        dispatch(getAvailablePaymentMethods()).then((item) => {
          setTabbyEnabled(item?.paymentMethods.find((i) => i.code === "tabby_installments" && i.is_available) !== undefined ? true : false)
          setIsTamaraEnabled(item?.paymentMethods.find((i) => i.code === "tamara_pay_by_instalments" && i.is_available) !== undefined ? true : false)
          setIsTamaraEnabled6(item?.paymentMethods.find((i) => i.code === "tamara_pay_by_instalments_6" && i.is_available) !== undefined ? true : false)
          setIsTamaraEnabled4(item?.paymentMethods.find((i) => i.code === "tamara_pay_by_instalments_4" && i.is_available) !== undefined ? true : false)
          console.log('item?.paymentMethods', item?.paymentMethods);
        });
        setIsShipping(true)
        const data = await getEstimateShippingMethods();
        setIsShipping(false)
        let shippingMethods = data["shipping-methods"];
        setNoDelivery((shippingMethods["home-delivery"]?.is_available || shippingMethods["store-pickup"]?.is_available) && cartData?.error?.code !== 6)
      }
      fetchData()
    }
  }, [cartData]);

  useEffect(() => {
    if (Object.values(configurationSettingData).length !== 0) {
      const getOutOfStock = configurationSettingData?.out_of_stock_configuration || {};
      setOutOfStockConfiguration(getOutOfStock);
      // const getStorePickup = configurationSettingData?.store_pickup || '0';
      // setStorePickupGlobal(getStorePickup);
    }
  }, [configurationSettingData]);

  useEffect(() => {
    impressionEvents.impressionGlobalVariable(
      currentLang, "Cart Page"
    );
  }, [cartData]);
  const loyaltyEarnTypeList = useSelector(
    (state) => state.appData.loyaltyEarnTypeList
  );

  const selectedLoyaltyPointsProgram = useSelector(
    (state) => state.appData.selectedLoyaltyPointsProgram
  );

  useEffect(() => {
    if (!loading) {
      cartEvents.viewCart(cartData && cartData?.items.length > 0 && cartData.items.map((item) => ({
        item_name: item?.product?.name,
        item_id: item?.product?.id,
        price: Number(parseFloat(item?.product?.discounted_price_rounded || item?.product.price_rounded).toFixed(2)),
        item_category: item?.product?.categories?.pop()?.name || 'Sony',
        item_brand: 'SONY',
        quantity: item?.qty,
        coupon: cartData?.totals_data?.coupon_code || null
      })), Number(parseFloat(cartData?.totals_data?.base_grand_total).toFixed(2)), cartData?.currency?.base_currency_code);
    }
  }, [loading])

  useEffect(async () => {
    if (Object.values(homepageData).length !== 0) {
      let getTopTrendingData = homepageData && homepageData?.content && homepageData?.content.filter(data => data?.type === "slider" && data?.element_id === "homepage_category_five");
      if (getTopTrendingData?.length > 0) {
        let firstFiveData = getTopTrendingData[0].products.filter(
          (val, i) => i < 5
        );
        setTopTrendingData(firstFiveData);
      }
    }
  }, [homepageData]);

  useEffect(() => {
    if (selectedLoyaltyPointsProgram !== "") {
      setLoyaltyEarnTypeId(selectedLoyaltyPointsProgram);
    }
  }, [selectedLoyaltyPointsProgram]);

  useEffect(() => {
    if (Object.values(cartData).length !== 0) {
      setCartProductData(cartData);
      setBundleStatus(cartData.conflict_status);
      setCartTotalData(cartData.totals_data);
      setCartItem(cartData.items && cartData.items.length);
      setLoading(false);
      if (cartData?.items) {

        if (cartData.conflict_status === 1) {
          setIsBundleExist(true);
        } else {
          setIsBundleExist(false);

        }
        // console.log('data', data);
      }
      // window.scrollTo(0, 0);
    }
  }, [cartData]);

  useEffect(() => {
    if (loyaltyEarnTypeList) {
      setLoyaltyEarnType(loyaltyEarnTypeList);
    }
  }, [loyaltyEarnTypeList]);
  useEffect(() => {
    impressionEvents.impressionViewCart(
      cartProductData,
      "Cart"
    );
  }, [cartProductData]);
  const proceddToChekoutClick = () =>{
    impressionEvents.impressionBeginCheckout(
      cartProductData,
      "Cart"
    );
  }
  const [coupenCode, setCoupenCode] = useState("");
  const handleChange = (e) => {
    setCoupenCode(e.target.value);
  };

  const error = {};
  error.error_1 = t('storePickupConfictErrorMsg1');
  error.error_2 = t('storePickupConfictErrorMsg1');
  error.error_3 = t('storePickupConfictErrorMsg1');
  error.error_4 = t('storePickupConfictErrorMsg2');
  error.error_5 = t('storePickupConfictErrorMsg3');
  error.error_6 = t('storePickupConfictErrorMsg3');
  error.error_7 = t('storePickupConfictErrorMsg3');
  error.error_8 = t('storePickupConfictErrorMsg3');
  error.error_9 = t('storePickupConfictErrorMsg4');
  error.error_10 = t('storePickupConfictErrorMsg4');
  // error.error_11 = 'case 11';
  // error.error_12 = 'case 12';
  error.error_13 = t('storePickupConfictErrorMsg5');

  const handleApplyCode = async () => {
    if (coupenCode !== "") {
      setCoupenLoading(true);
      let params = {
        couponCode: coupenCode,
      };
      try {
        let response = await apiHelper(
          `/V1/cart/coupon-code`,
          "put",
          params,
          {}
        );
        impressionEvents.impressionCouponApply(
          coupenCode,
          "Coupon code is applied"
        );
        dispatch(
          services.notifySuccess({ message: t("Coupon code is applied") })
        );
        dispatch(loadCartData());
        dispatch(getLoyaltyEarnTypesData());
        dispatch(getAvailablePaymentMethods());
        setCoupenCode('');
        setCoupenLoading(false);
      } catch (error) {
        setCoupenLoading(false);
        impressionEvents.impressionCouponApply(
          coupenCode, error?.response?.data?.message
        );
        dispatch(
          services.notifyError({ message: t(error?.response?.data?.message) })
        );
        dispatch(loadCartData());
        dispatch(getLoyaltyEarnTypesData());
        dispatch(getAvailablePaymentMethods());
      }
    }
  };
  const removeCouponCode = async () => {
    setRemoveLoading(true);
    try {
      let response = await apiHelper(
        `/V1/cart/coupon-code`,
        "delete",
        {},
        {}
      );
      dispatch(
        services.notifySuccess({ message: t("Remove successfully") })
      );
      dispatch(loadCartData());
      dispatch(getLoyaltyEarnTypesData());
      dispatch(getAvailablePaymentMethods());
      setRemoveLoading(false);
    } catch (error) {
      dispatch(
        services.notifyError({ message: t(error.response.data.message) })
      );
      dispatch(loadCartData());
      dispatch(getLoyaltyEarnTypesData());
      dispatch(getAvailablePaymentMethods());
      setRemoveLoading(false);
    }
  };

  const currentLang = localStorage.getItem("i18next") || "en";
  let quitafcard = require(`./../assets/Icon/icon-qitaf-${currentLang}.png`)

  // console.log('cartProductData', cartProductData?.totals_data);
  if (loading && !authRandom) {
    return <>
      <NewLoader />
      <div className="m-4">
        <Skeleton width={"200px"} height="20px" />
      </div>
      <div className="shopping__cart__page__container">
        <div className="d-flex justify-content-center">
          <Skeleton containerClassName="w-75" height="20vh" />
        </div>
        <br />
        <Skeleton width={"280px"} height="32px" />
        <br />
        {width > 992 ?
          <div className="row shopping__cart__page__inner__block">
            <div className="col-md-8 col-xl-9  shopping__cart__left__block">
              <div className="row">
                <div className="col-12 col-sm-2 sc__product__left__block">
                  <Skeleton width="100%" height={"20vh"} />
                </div>
                <div className="col-12 col-sm-7">
                  <Skeleton width="100%" height={"20vh"} />
                </div>
                <div className="col-12 col-sm-3">
                  <Skeleton width="100%" height={"20vh"} />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-2 sc__product__left__block">
                  <Skeleton width="100%" height={"20vh"} />
                </div>
                <div className="col-12 col-sm-7">
                  <Skeleton width="100%" height={"20vh"} />
                </div>
                <div className="col-12 col-sm-3">
                  <Skeleton width="100%" height={"20vh"} />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xl-3 ">
              <Skeleton width="100%" height={"40vh"} />
            </div>
          </div> :
          <>
            <Skeleton height={"132px"} width="100%" />
            <div className="my-4">
              <Skeleton height={"24px"} width="600px" />
              <Skeleton height={"20px"} width="200px" />
            </div>
          </>
        }
      </div>
    </>;
  }
    return (
    <>
      <MetaComponent
        title={t('Shopping_cart')}
        meta_title={t('Shopping_cart')}
        meta_keywords={t('Shopping_cart')}
        meta_description={t('Shopping_cart')}
      />
      {width <= 992 && !authRandom && <div className="d-block d-lg-none mb__cart__page">
        <MobileCartPage
          cartItem={cartItem}
          cartProductData={cartProductData}
          cartTotalData={cartTotalData}
          topTrendingData={topTrendingData}
          bundleStatus={bundleStatus}
          isTabbyEnabled={isTabbyEnabled}
          handleChangeCartPopup={handleChangeCartPopup}
          outOfStockConfiguration={outOfStockConfiguration}
          storeConfig={storeConfig}
          noDelivery={noDelivery}
          isShipping={isShipping}
          currentLanguageCode={currentLanguageCode}
          isTamaraEnabled={isTamaraEnabled}
          isTamaraEnabled6={isTamaraEnabled6}
          tamara3MonthStatus={tamara3MonthStatus}
          tamara6MonthStatus={tamara6MonthStatus}
          availablityData={availablityData}
          cashbackAmount={cashbackAmount}
          isTamaraEnabled4={isTamaraEnabled4}
        />
      </div>}
      {width > 992 && !authRandom && <div className="container-fluid shopping__cart__page__container  d-none d-lg-block">
        <BreadCrumbs
          back="/"
          firstBread="/cart"
          firstBreadTitle={t('Shopping_cart')}
          highlight="firstBreadTitle"
        />

        <div className="shopping__cart__page__block">
          <div className="cart__notice__area">
            <Text31
              text={t('Dear_customer_all_the_products_you_purchase_through')}
              text2={`${t('Disclaimer')}`}
              isFull={false}
              textAlign="center"
            />

          </div>
          <div className="sc__page__title">
            {" "}
            <Heading3 text={t('Shopping_cart')} span={true} />{" "}
            <Text1
              text={`(${cartItem && cartItem} ${t('Items')})`}
              color="#FF4F04"
              span={true}
            />
          </div>

          <div className="row shopping__cart__page__inner__block">
            <div className={`col-md-8 col-xl-9  shopping__cart__left__block ${qtyIsDisabled === true ? "is_disabled_product_qty" : ""}`}>
              {storeConfig === '1' && cartProductData?.error && cartProductData?.error?.error && cartProductData?.error?.code && cartProductData?.error?.code !== 13 && (
                <div className="pickupWarning">
                  {error[`error_${cartProductData?.error?.code}`]}
                </div>
              )}

              {
                bundleStatus === 1 && cartData?.totals_data?.extension_attributes?.is_bundle && (
                  <div class="alert alert-success  green-alert-info margin-alert" role="alert">
                    {t("success_alert")}
                  </div>
                )
              }
              {
                bundleStatus === 2 && !cartData?.totals_data?.extension_attributes?.is_bundle && (
                  <div class="alert alert-danger red-alert-info margin-alert" role="alert">
                    {t("failed_alert")}
                  </div>
                )
              }
              {cartItem === 0 && (
                <>
                  <div className="shopping__cart__emptyBox">
                    <img src={empty_Cart} />
                    <Text3 text={t('You_don_t_have_any_items_in_your_cart_yet')} />
                    <Link to="/" className="continue_shopping_btn">
                      {t("CONTINUE_SHOPPING")}
                    </Link>
                  </div>
                </>
              )}              
              {cartProductData &&
                cartProductData.items.map((pro, proIndex) => {
                  return (
                    <ShoppipngCartProduct item={pro} key={pro.item_id} totals={cartProductData?.totals_data?.items.find((t) => t.item_id === pro.item_id)} totalData={cartProductData?.totals_data} product={pro} setQtyIsDisabled={setQtyIsDisabled} screenName="Cart" storeConfig={storeConfig} />
                  );
                })}
              {/* <hr className="sc__page__horizontal__line"></hr>
              <ShoppipngCartProduct product={product} /> */}
              <div className="sc__newArrival__block d-none">
                {/* <Heading2
                  text="People Who Bought Also Bought"
                  marginLeft={40}
                  marginBottom={0}
                /> */}
                {/* <ProductContainer
                  productDetailPage={true}
                  // sectionTitle="People Who Bought Also Bought"
                  carouselData={peopleUltimatelyBoughtData}
                  containerClassName="people__also__bought__block"
                /> */}
              </div>
            </div>
            {/* package Summary */}
            <div className={`col-md-4 col-xl-3  shopping__cart__right__block`}>
              {isTamaraEnabled && tamara3MonthStatus === true && (
                <div className="product__offer__block">
                  <TamaraInstallmentPlanWidget
                    months={3}
                    price={
                      cartData?.totals_data
                        ?.base_grand_total
                    }
                    currency={
                      cartData?.totals_data
                        ?.base_currency_code
                    }
                    minLimit={getTamaraValue('tamara3_min')}
                    maxLimit={getTamaraValue('tamara3_max')}
                    currentLanguageCode={currentLanguageCode}
                    inlineType={2}
                  />
                </div>
              )}
            
            
              {isTamaraEnabled4 && (
                <div className="product__offer__block">
                  <TamaraInstallmentPlanWidget
                    months={4}
                    price={
                      cartData?.totals_data
                        ?.base_grand_total
                    }
                    currency={
                      cartData?.totals_data
                        ?.base_currency_code
                    }
                    minLimit={getTamaraValue('tamara3_min')}
                    maxLimit={getTamaraValue('tamara3_max')}
                    currentLanguageCode={currentLanguageCode}
                    inlineType={2}
                  />
                </div>
              )}
              {isTamaraEnabled6 && tamara6MonthStatus === true && (
                <div className="product__offer__block">
                  <TamaraInstallmentPlanWidget
                    months={6}
                    price={
                      cartData?.totals_data
                        ?.base_grand_total
                    }
                    currency={
                      cartData?.totals_data
                        ?.base_currency_code
                    }
                    minLimit={getTamaraValue('tamara6_min')}
                    maxLimit={getTamaraValue('tamara6_max')}
                    currentLanguageCode={currentLanguageCode}
                    inlineType={2}
                  />
                </div>
              )}
              {isTabbyEnabled &&
                <div className="product__offer__block">
                  <TabbyWidget
                    price={
                      cartData?.totals_data
                        ?.base_grand_total
                    }
                    currency={
                      cartData?.totals_data
                        ?.base_currency_code
                    }
                    widgetType="TabbyPromo"
                    currentLanguageCode={currentLanguageCode}
                    timeOut={500}
                  />
                </div>
              }
              {cartItem !== 0 && (
                <>
                  <div className="sc__package__summary__block">
                    <div className="sc__ps__title">
                      {" "}
                      <Heading4 text={t('Order_Summary')} />
                    </div>

                    <div className="sc__ps__detail__block">
                      {/* {cartTotalData?.total_segments
                    .slice(1, cartTotalData?.total_segments.length + 1)
                    .map((segment, segmentIndex) => {
  
                      return (
                        <div className="sc__ps__detail__inner__block">
                          {segment.code === "grand_total" ? (
                            <Heading6 text={segment.title} color="#000000" />
                          ) : (
                            <Text3 text={segment.title} color="#000000" />
                          )}
                          <Price
                            price={segment.value ===null ? 0:segment.value}
                            size="heading7"
                            currency={cartTotalData.base_currency_code}
                          />
                        </div>
                      );
                    })} */}

                      <div className="sc__ps__detail__inner__block">
                        <Text3 text={t('Sub_Total_Without_Tax')} color="#000000" />
                        <Price
                          price={
                            cartTotalData && cartTotalData.items_qty !== 0
                              ? cartTotalData?.base_subtotal
                              : 0
                          }
                          size="heading7"
                          currency={
                            cartTotalData && cartTotalData.base_currency_code
                          }
                        />
                      </div>
                      {cashbackAmount > 0 && <div className="sc__ps__detail__inner__block">
                        <Text3 text={t('Cashback')} color="#168F00" />
                        <Price
                          price={
                            cashbackAmount || 0
                          }
                          size="heading7"
                          color="#168F00" 
                          currency={
                            cartTotalData && cartTotalData.base_currency_code
                          }
                        />
                      </div>}
                      <div className="sc__ps__detail__inner__block">
                        <Text3 text={t('Shipping_Handling')} color="#000000" />
                        <Price
                          price={
                            cartTotalData && cartTotalData.items_qty !== 0
                              ? cartTotalData?.base_shipping_amount
                              : 0
                          }
                          size="heading7"
                          currency={
                            cartTotalData && cartTotalData?.base_currency_code
                          }
                        />
                      </div>
                      {getCartTotalSegments(cartTotalData?.total_segments, 'discount').length > 0 &&
                        <div className="sc__ps__detail__inner__block discount_info_row">
                          {removeLoading &&
                            <div className="discount_remove_spinner">
                              <Spinner type="dark" />
                            </div>
                          }
                          <div className="d-flex flex-row">
                            <Text3 text={t('Discount')} color="#000000" />
                            {
                              !cartData?.totals_data?.extension_attributes?.is_bundle && (
                                <button
                                  className="btn btn-link p-0 ms-1 discount_remove_btn"
                                  type="button"
                                  id="button-addon2"
                                  onClick={() => removeCouponCode()}
                                >
                                  {t("remove")}
                                </button>
                              )
                            }

                          </div>
                          <Price
                            // price={
                            //   cartTotalData && cartTotalData.items_qty !== 0
                            //     ? cartTotalData?.discount_amount
                            //     : 0
                            // }
                            price={cartTotalData?.discount_amount - (-cartTotalData?.discount_amount * 0.15)}
                            size="heading7"
                            currency={
                              cartTotalData && cartTotalData?.base_currency_code
                            }
                          />
                        </div>
                      }
                      <div className="sc__ps__detail__inner__block">
                        <Text3 text={t('Tax')} color="#000000" />
                        <Price
                          price={
                            cartTotalData && cartTotalData.items_qty !== 0
                              ? cartTotalData?.base_tax_amount
                              : 0
                          }
                          size="heading7"
                          currency={
                            cartTotalData && cartTotalData?.base_currency_code
                          }
                        />
                      </div>
                      <ul>
                        {loyaltyEarnType &&
                          loyaltyEarnTypeId &&
                          loyaltyEarnType
                            .filter(
                              (item, i) => item.program === loyaltyEarnTypeId
                            )
                            .map((item, i) => {
                              let imageUrl =
                                item.program === "meza" ? mezzacard :  item.program === "niqaty" ? niqaty : quitafcard;
                              return (
                                <React.Fragment key={i}>
                                  <li className="d-flex  pt-1 align-items-center w-100 justify-content-between">
                                    <p className="m-0">
                                      <img src={imageUrl} />
                                    </p>
                                    <span className="sum__points d-block">
                                      +{`${item.points}`} {t("points")}
                                    </span>
                                  </li>
                                  <hr />
                                </React.Fragment>
                              );
                            })}
                      </ul>
                      <LoyaltyPointBlock
                        loyaltyEarnType={loyaltyEarnType}
                        loyaltyEarnTypeId={loyaltyEarnTypeId}
                        // currency={
                        //   cartTotalData && cartTotalData?.base_currency_code
                        // }
                        cartDetail={cartProductData}
                      />
                      <div className="sc__ps__detail__inner__block">
                        <Heading6 text={t('Grand_Total')} color="#000000" />
                        <Price
                          price={
                            cartTotalData && cartTotalData?.base_grand_total
                          }
                          size="heading7"
                          currency={
                            cartTotalData && cartTotalData?.base_currency_code
                          }
                        />
                      </div>
                      {
                        !isBundleExist && (
                          <div className="checkout__coupon__block mt-2">
                            <p>
                              <strong>{t("Coupon_code_or_gift_card")}</strong>
                            </p>
                            <div className="cart_coupon_code_inputbox">
                              {coupenLoading &&
                                <div className="cart_coupon_spinnerbox">
                                  <Spinner type="dark" />
                                </div>
                              }
                              <div className="input-group d-inline-block me-1">
                                <input
                                  type="text"
                                  value={coupenCode}
                                  className="form-control w-100"
                                  placeholder={t('Enter_Code')}
                                  onChange={handleChange}
                                />
                                <button
                                  className=""
                                  type="button"
                                  id="button-addon2"
                                  onClick={() => handleApplyCode()}
                                >
                                  {t("Apply")}
                                </button>
                              </div>
                              {coupenCode !== "" && (
                                <div
                                  onClick={(e) => setCoupenCode("")}
                                  className="d-inline-block remove_coupen_code_btn"
                                >
                                  <img src={closeicon} alt="close" />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="checkout__button__link">
                    <button className="col-6 sc__addToCart__button__block border-0" disabled={(!noDelivery && !cartProductData?.is_virtual) || isShipping}
                      onClick={() => {
                        localStorage.removeItem("last-dt");
                        { userloginData === true || getData?.user?.userLoggedIn === true ? navigate("/checkout/delivery") : navigate("/checkout/signin") }
                        proceddToChekoutClick();
                      }}
                    >
                      <div className="sc__addToCart__button">
                        {isShipping ? <Spinner /> :
                          <>
                            <span>{t("Proceed_to_Checkout")}</span>
                            <img
                              src={checkout_white_right_arrow}
                              alt=""
                              className="sc__addToCart__icon"
                            />
                          </>
                        }
                      </div>
                    </button>
                  </div>
                  <Link to="/">
                    <button className="signup__button">
                      {t("CONTINUE_SHOPPING")}
                    </button>
                  </Link>
                  <hr />
                </>
              )}
              {topTrendingData &&
                <div className="main__recommand__product__block">
                  <Heading5
                    text={t('Recommendations_for_all_products')}
                    marginBottom={20}
                  />
                  {topTrendingData.filter((val, i) => isMore === true ? i : i < 2).map((product, productIndex) => {
                    return (
                      <RecommandProducts
                        key={product?.id}
                        product={product}
                        handleChangeCartPopup={handleChangeCartPopup}
                        outOfStockConfiguration={outOfStockConfiguration}
                        productIndex={productIndex}
                        screenName="Cart"
                      />
                    );
                  })}
                  <div className="view__more__address__block">
                    <button
                      onClick={() =>
                        setIsMore(!isMore)
                      }
                      className="view__more__address__button"
                    >
                      <Heading6
                        text={
                          isMore
                            ? t("View_Less")
                            : t("View_More")
                        }
                        marginBottom={0}
                        color="#000000"
                      />
                      {isMore === true ? <BsChevronUp /> : <BsChevronDown />}
                    </button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>}
      {/* <NewsLetter /> */}
      {/* <Footer /> */}
    </>
  );
}

export default Cart_Page;