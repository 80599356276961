import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "./../SCSS/_productDetailsPage.scss";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import * as services from "../services/services";
import BreadCrumbs from "../Components/BreadCrumbs";
import empty_favourite from "./../assets/Icon/hart-outline.png";
import fulfill_favourite from "./../assets/Icon/hart-red.png";
import return_period from "./../assets/Icon/return_period.svg";
import { Link } from "react-router-dom";
import Heading3 from "../Components/Font/Heading3";
import Heading7 from "../Components/Font/Heading7";
import AvailableOffers from "../Components/MostSharedComponent/AvailableOffers";
import Heading5 from "../Components/Font/Heading5";
// import SuperCoin from "../Components/MostSharedComponent/SuperCoin";
import PriceBlock from "../Components/MostSharedComponent/PriceBlock";
import Heading6 from "../Components/Font/Heading6";
import { addToCart, deleteFromCart, updateCompareProductList } from "../services/cart.service";
import {
  configurationSetting,
  loadAddToWishlist,
  loadCartData,
  loadCitiesLocationData,
  loadDeleteFromWishlist,
  loadEnableStoresLocationData,
  loadProductDetailData,
  loadWishlistData,
} from "../redux/appAction";
import htmlParseHelper from "../Components/helpers/utils/htmlParseHelper";
import NewLoader from "../Components/Common/newloader";
import { selectedCompareProductStatus } from "../Components/helpers/utils/compareProductsFilter";
import { useTranslation } from "react-i18next";
import apiHelper from "../Components/helpers/utils/apiHelper";
import MetaComponent from "../Components/Common/MetaComponent";
import { generateCategoryUrl } from "../Components/helpers/utils/generateCategoryUrl";
import { allNumeric } from "../Components/helpers/utils/validators";
import { getTamaraMinMaxLimit } from "../Components/helpers/utils/handsakeConfig";
import { productUrlHelper } from "../Components/helpers/utils/productUrlHelper";
import { checkWishList } from "../Components/helpers/utils/wishListHelper";
import CmsContentModal from "../Components/MostSharedComponent/CmsContentModal";
import { cmsPageContentApiSuccess } from "../services/common/common";
import useWindowDimensions from "../Components/helpers/utils/useWindowDimensions";
import { titleChange } from "../Components/helpers/utils/titleNameChange";
import Text3 from "../Components/Font/Text3";
import ShareLinkPDP from "../Components/Pdp/ShareLinkPDP";
import { cartEvents } from "../Components/helpers/utils/ga/cartEvents";
import { checkoutEvents } from "../Components/helpers/utils/ga/checkoutEvents";
import { pdpEvents } from "../Components/helpers/utils/ga/pdpEvents";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import RelatedProduct from "../Components/MostSharedComponent/RelatedProduct";
import PdpAccordian from "../Components/Pdp/PdpAccordian";
import TealiumTag from "../Components/helpers/utils/tealium";
import useCartErrorMessage from "../Components/helpers/hooks/useCartErrorMessage";
import skuEncoder from "../lib/utils/skuEncoder";

// const productOverviewData = {
//   title: "Product Overview",
//   description: "",
//   keyValueTitle: "",
//   keyValueData: []
// }

// const productSpecificationData = {
//   title: "Specifications",
//   description: "",
//   keyValueTitle: "Key Specs",
//   keyValueData: [
//     {
//       id: "sku",
//       key: "SKU",
//       value: "SC-VR-BLOODTRUTH"
//     },
//     {
//       id: "brand",
//       key: "Brand",
//       value: "Sony"
//     },
//     {
//       id: "tax_class_id",
//       key: "Qitaf Eligible",
//       value: "Qitaf Eligible"
//     }
//   ]
// }

function Build_Bundle_Page({ handleChangeCartPopup, updateCompareProduct, closeComparePopup, setRefreshcomparePopup }) {
  const { t } = useTranslation();
  const { setCartErr } = useCartErrorMessage();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [checkingReviews, setCheckingReviews] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [isCartData, setIsCartData] = useState(false);
  const [bundleProducts, setBundleProducts] = useState([]);
  const [requiredBundle, setRequiredBundle] = useState([]);
  const [isBundleChecked, setIsBundleChecked] = useState(false);
  const [selectedBundleCity, setSelectedBundleCity] = useState({
    id: 0,
    name: t("No Installation"),
  });
  const [selectBundleCity, setSelectBundleCity] = useState(selectedBundleCity);
  const [bundleModal, setBundleModal] = useState(false);
  const [relatedProductList, setRelatedProductList] = useState([]);
  const [isPopup, setIsPopup] = useState(true);
  const [cityModal, setCityModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectCity, setSelectCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [storeCodes, setStoreCodes] = useState(null);
  const [tamaraEligible, setTamaraEligible] = useState(null);
  const [tabbyEligible, setTabbyEligible] = useState(null);
  const [hasLabel, setHasLabel] = useState(true);
  const [variant, setVariant] = useState(null);
  const [warrantyProduct, setWarrantyProduct] = useState([]);
  const [isExWarrantyAvailable, setIsExWarrantyAvailable] = useState(false);
  const [isMore, setIsMore] = useState(false);
  const [storePickupGlobalConfiguration, setStorePickupGlobalConfiguration] = useState(0);

  const [globalStores, setGlobalStores] = useState([]);

  const storesEnableLocationData = useSelector(
    (state) => state.appData.storesEnableLocationData
  );

  useEffect(() => {
    setGlobalStores(storesEnableLocationData)
  }, [storesEnableLocationData]);

  useEffect(() => {
    if (product?.id) {
      pdpEvents?.viewItem(product);
      pdpEvents?.viewBundleProduct(product &&
        product?.relatedProducts &&
        product?.relatedProducts[0] &&
        product?.relatedProducts[0]?.products)
    }
  }, [product?.id])

  useEffect(() => {
    if (product?.installmentMethod) {
      setStoreCodes(
        product.inventory_qty
          ?.map((item) => Object.keys(item)[0])
          .filter(
            (code) => code !== 'RS97' && code !== 'RW10' && code !== 'RW20',
          ),
      );
      // impressionEvents?.impressionDetail(data, lastClickedScreenName, variant);
      // product.labels?.pdp?.filter((item) => item.position === 'left_top').length
      //   > 0 && setHasLabel(false);
    }
    // else if (product.inventory_qty.length < 1) {
    //   dispatch({ type: 'SELECT_STORE', payload: null });
    // }
  }, [product, variant]);

  useEffect(() => {
    setFinalData(
      product?.description && product?.description.split(`<div id="specs">`)
    );
  }, [product?.description]);

  useEffect(() => {
    if (cities.length <= 0) return;

    const defaultCity = cities.find(
      (item) =>
        item.estimatedDays &&
        item.deliveryDate &&
        ((item.id === "23" && !item.isDefaultForDelivery) ||
          item.isDefaultForDelivery)
    );
    if (defaultCity?.estimatedDays) {
      setSelectedCity(defaultCity);
      setSelectCity(defaultCity);
    } else {
      setSelectedCity(cities[0]);
      setSelectCity(cities[0]);
    }

    return () => setSelectedCity(null);
  }, [cities]);

  useEffect(() => {
    setRequiredBundle(
      product && product?.bundle_product_options && product?.bundle_product_options.filter((itm) => itm?.required === "1")
    );
    setBundleProducts(
      product && product?.bundle_product_options && product?.bundle_product_options.filter((itm) => itm?.required !== "1")
    );
  }, [product]);
  useEffect(async () => {
    if (
      product &&
      product?.relatedProducts &&
      product?.relatedProducts[0] &&
      product?.relatedProducts[0]?.products
    ) {
      setRelatedProductList(product?.relatedProducts[0]?.products);
    }
  }, [product]);

  useEffect(() => {
    if (product) {
      if ("is_price_hide" in product === false) {
        const is_price_hide = product?.options?.find((x) => {
          if (x.code === "is_price_hide") {
            return x.visibility;
          }
          return false;
        });
        setProduct({
          ...product,
          is_price_hide: is_price_hide,
        });
      }
    }
    //  console.log('productproduct', product.user_bundle_products);
  }, [product]);
  // useEffect(()=>{
  //   const element = document.getElementsByClassName("testfreaks-badge");
  //   console.log('element?.length', element?.length);
  //   if(element?.length >  1 ){
  //     const element2 = document.getElementsByClassName("testfreaks-reviews");
  //     element[0].innerHTML = '';
  //     if(element2?.length >  1){
  //       element2[0].innerHTML = '';

  //     }
  //     setCheckingReviews(true);
  //   }
  //   if(checkingReviews !== true){
  //     setCheckingReviews(Math.random());
  //   }
  // },[checkingReviews]);
  const productReviewData = {
    title: t("Reviews"),
    description: "",
    keyValueTitle: "",
    keyValueData: [],
  };

  const { cmsPageContent } = useSelector((state) => state.commonReducer);
  const cityLocationData = useSelector(
    (state) => state.appData.cityLocationData
  );

  useEffect(() => {
    dispatch(loadCitiesLocationData());
    dispatch(loadEnableStoresLocationData());
  }, []);

  useEffect(() => {
    setCities(cityLocationData);
  }, [cityLocationData]);

  const getCompareProductList = useSelector(
    (state) => state.appData.getCompareProductList
  );
  const cartData = useSelector((state) => state.appData.cartData);

  const [productAvailableOffer, setProductAvailableOffer] = useState([
    {
      id: 1,
      offerType: "",
      offerText: "Save $50-$300 on a sound bar with TV",
      termsAndConditions: "",
    },
    {
      id: 2,
      offerType: "Bank Offer",
      offerText: "5% Unlimited Cashback on Axis Bank Credit Card",
      termsAndConditions: "T&C",
    },
    {
      id: 3,
      offerType: "Credit Card Offer",
      offerText: "5% Unlimited Cashback on Sony Credit Card",
      termsAndConditions: "T&C",
    },
  ]);
  const [productWarrentyBlock, setProductWarrentyBlock] = useState({
    warrantyText: "1 Year Warranty on Product",
    size: [
      {
        id: 1,
        cm: 139,
        inch: 55,
      },
      {
        id: 2,
        cm: 164,
        inch: 65,
      },
      {
        id: 3,
        cm: 195,
        inch: 77,
      },
    ],
  });
  const [productDelivery, setProductDelivery] = useState({
    deliveryText: "Buy in next 2 hours and receive the TV by Tomorrow",
    pickupStore: [
      {
        id: 1,
        pickupText:
          "Available today at Riyadh Act Fast – Only 3 left at your store!>",
      },
      {
        id: 2,
        pickupText:
          "Available today at Riyadh Act Fast – Only 3 left at your store!>",
      },
      {
        id: 3,
        pickupText:
          "Available today at Riyadh Act Fast – Only 3 left at your store!>",
      },
    ],
  });
  const [productProtection, setProtection] = useState([
    {
      id: 1,
      protectionText: "2-Year Standard Geek Squad Protection",
      price: 79,
      month: 12,
    },
    {
      id: 2,
      protectionText: "1-Year Standard Geek Squad Protection",
      price: 89,
      month: 12,
    },
  ]);
  const [productSpecificationData, setProductSpecificationData] = useState("");
  const [productOverviewData, setProductOverviewData] = useState("");
  const [sizeButtonIndex, setSizeButtonIndex] = useState(null);
  const [pickStoreList, setPickStoreList] = useState([]);
  const [showAddCartSticky, setShowAddCartSticky] = useState(false);
  const [breadCrumbsData, setBreadCrumbsData] = useState({
    firstBread: "",
    firstBreadTitle: "",
  });

  const [outOfStockConfiguration, setOutOfStockConfiguration] = useState({});
  const [pageRender, setPageRender] = useState(false);

  useEffect(() => {
    const config = async () => {
      try {
        const configuration = await configurationSetting();
        const getOutOfStock = configuration?.out_of_stock_configuration || {};
        setOutOfStockConfiguration(getOutOfStock);
        setStorePickupGlobalConfiguration(Number(configuration?.store_pickup) || 0);
      } catch (error) {
        console.log("error ", error);
      }
    };
    config();
  }, []);

  const controlNavbar = () => {
    if (window.scrollY > 430) {
      setShowAddCartSticky(false);
    } else {
      setShowAddCartSticky(false);
    }
  };

  const getOptionKeyInfo = (options, keyName) => {
    let checkKeyData =
      options && options?.filter((item) => item.code === keyName);
    let keyData = "";
    if (checkKeyData?.length > 0) {
      keyData = checkKeyData[0]?.selected_option?.labelArr[0];
    }
    return keyData;
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  // const dataLocation = useLocation()
  // console.log(dataLocation);
  const navigate = useNavigate();
  // console.log(dataNavigate);
  const { id } = useParams();
  const memoId = useMemo(() => id, [id]);
  let arr = memoId.split("-p-");
  let urlSKU = arr[arr.length - 1];

  if (urlSKU.includes('/') || urlSKU.includes('%2F')) {
    urlSKU = skuEncoder(urlSKU);
  }

  const memoUrlSKU = useMemo(() => urlSKU, [urlSKU]);
  const dispatch = useDispatch();

  const callApi = () => {
    if (memoUrlSKU === undefined) {
     //  navigate("/");
    } else {
      setLoading(true);
      dispatch(services.loaderStart());
      dispatch(services.getCmsPageContentApi("returns-and-exchange-9696"));
      dispatch(loadProductDetailData(memoUrlSKU.replace(/[/]/g, "%2F")));
    }
    //cart api call reduce
    // dispatch(loadCartData());
  };
  useEffect(() => {
    if (memoUrlSKU) {
      callApi();
    }
  }, [memoUrlSKU]);
  const productData = useSelector((state) => state.appData.productData);
  // console.log(Object.values(productData).length);
  const { loading: productLoading } = useSelector(
    (state) => state.commonReducer
  );

  useEffect(async () => {
    if (Object.values(productData).length !== 0) {
      if (
        productData?.response !== undefined &&
        productData?.response?.status === 404
      ) {
    //    navigate("/");
      } else {
        // product is out-of-stock then redirect to home page
        if ([productData]?.length === 0) {
    //      navigate("/");
        }
        let breadData = [];
        let breadPLPCheck =
          productData?.categories &&
          productData?.categories.filter(
            (val) =>
              val.is_active === "1" &&
              val.parent_id !== "1" &&
              val.parent_id !== "2"
          );

        let breadCategoryCheck =
          productData?.categories &&
          productData?.categories.filter(
            (val) => val.is_active === "1" && val.parent_id === "2"
          );

        if (breadPLPCheck.length > 0) {
          breadData.push(breadPLPCheck[0]);
        } else {
          if (breadCategoryCheck.length > 0) {
            breadData.push(breadCategoryCheck[0]);
          }
        }

        if (breadData && breadData.length > 0) {
          // let defaultIndex = 0
          // if (breadData.length > 1) {
          //   defaultIndex = breadData.length - 1
          // }
          setBreadCrumbsData({
            ...breadCrumbsData,
            firstBread: generateCategoryUrl(
              breadData[0]?.url_key || breadData[0]?.name,
              breadData[0]?.category_id,
              breadData[0]?.parent_id
            ),
            firstBreadTitle: breadData[0]?.name,
          });
        }
        setProduct(productData);
        // set specification data
        const productSpecificationInfo = {
          title: t("Specifications"),
          description: "",
          keyValueTitle: t("Key_Specs"),
          keyValueData: [],
        };
        productData.options &&
          productData.options
            .filter(
              (item) =>
                item.code !== "is_count_down" &&
                item.code !== "store_level_stock" &&
                item.code !== "url_key" &&
                item.visibility
            )
            .map((item) => {
              productSpecificationInfo.keyValueData.push({
                id: item?.code,
                key: item?.attribute?.label,
                value: item?.selected_option?.labelArr[0],
              });
            });
        setProductSpecificationData(productSpecificationInfo);

        // set product overview description

        const productOverviewInfo = {
          title: t("Product_Overview"),
          description:
            product?.description !== ""
              ? htmlParseHelper(product?.description)
              : "",
          keyValueTitle: "",
          keyValueData: [],
        };
        setProductOverviewData(productOverviewInfo);

        setLoading(false);
        // window.scrollTo(0, 0);

        // warranty product list
        setWarrantyProduct(productData?.user_warranty_products);
        if (productData?.user_warranty_products?.length > 0) {
          setIsExWarrantyAvailable(true)
        }

        try {
          let response = apiHelper(
            `/V1/get-store-data/${productData.id}`,
            "get",
            {},
            {}
          );
          if (response?.data) {
            let filterStoreData = response?.data.filter(
              (store) =>
                store.status !== "0" && store.enable_storepickup !== "0"
            );
            setPickStoreList(filterStoreData);
          }
        } catch (error) {
          // console.log("error store", error);
        }
      }
    }
  }, [productData]);

  // console.log("useEffect",productData);
  // console.log(product?.reviewSummary.totals);

  const [pincode, setPincode] = useState("");
  const [count, setCount] = useState(1);
  const [cartProductData, setCartProductData] = useState();
  const [cartDetail, setCartDetail] = useState();
  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);
  const [loaderOnTwo, setLoaderOnTwo] = useState(false);
  const [loaderBuyOn, setLoaderBuyOn] = useState(false);
  const { height, width } = useWindowDimensions();
  // useEffect(() => {
  //   dispatch(loadCartData());
  //   return () => {};
  // }, []);

  // useEffect(() => {
  //   if (Object.values(cartData).length !== 0) {
  //     let productQtyCheck = cartData.items.filter(val => val.sku === product?.sku);
  //     if (productQtyCheck.length > 0) {
  //       setCount(productQtyCheck[0].qty);
  //       console.log("productQtyCheck[0].qty ", productQtyCheck[0].qty);
  //     }
  //     setCartDetail(cartData);
  //   }
  // }, [cartData, product]);

  const deleteProductFromCart = async (id) => {
    // console.log("id ", id);

    const data = {
      // items: [id],
      items: [id],
    };
    // console.log("data ", data);

    await deleteFromCart(data)
      .then((res) => {
        // console.log(res, "res>>>");
        dispatch(loadCartData());
      })
      .catch((err) => {
        // console.log(err.response.data.message, "error >>>>");
        // dispatch(services.notifyError({ message: err.response.data.message }));
      });
  };

  const decreaseCount = (sku) => {
    if (count === 0) {
      setCount(0);
    } else {
      // deleteProductFromCart(product?.id)
      setCount(count - 1);
      // AddProductToCart(sku,count-1)
    }
  };
  const increaseCount = (sku) => {
    // deleteProductFromCart(product?.id)
    setCount(count + 1);
    // AddProductToCart(sku,count+1)
  };

  const buyNow = (sku, id) => {
    setLoaderBuyOn(true);
    AddProductToCart(sku, id, true);
  };

  const AddProductToCart = async (sku, id, buynow = false) => {
    if (
      product?.item_type === "bundle" &&
      !isBundleChecked &&
      !selectBundleCity
    ) {
      setBundleModal(true);
    } else {
      try {
        if (
          product?.item_type === "bundle" &&
          isBundleChecked &&
          selectBundleCity
        ) {
          setLoaderOn(true);
          await addToCart({
            items: [
              {
                sku,
                id: product?.id,
                qty: count,
                bundle_options: [
                  {
                    option_id: +requiredBundle[0].product_links[0].option_id,
                    option_qty: 1,
                    option_selections: [+requiredBundle[0].product_links[0].id],
                  },
                  {
                    option_id: +selectBundleCity?.option_id,
                    option_qty: 1,
                    option_selections: [+selectBundleCity?.id],
                  },
                ],
                selectBundleCity,
              },
            ],
          }).then((res) => {
            setCount(
              res.data.filter((pro) => pro.sku === product?.sku)[0].qty
            );
            if (buynow) {
              dispatch(loadCartData()).then(() => {
                navigate("/checkout/delivery");
                setIsCartData(false);
              });
            } else {
              setSpinnerOn(true)
              dispatch(loadCartData()).then((item) => {
                cartEvents.addToCart(product, item?.id)
                setSpinnerOn(false)
                handleChangeCartPopup(true);
              });
            }
          })
            .catch((err) => {
              setCartErr({ stock: product?.stock, error: err?.response?.data });
              setLoaderOn(false);
            })
            .finally(() => {
              setLoaderOn(false);
              setIsCartData(false);
            });;
        } else if (product?.item_type === "bundle" && !isBundleChecked) {
          setLoaderOn(true);
          await addToCart({
            items: [
              {
                sku,
                id: product?.id,
                qty: count,
                bundle_options: [
                  {
                    option_id: +requiredBundle[0].product_links[0].option_id,
                    option_qty: 1,
                    option_selections: [+requiredBundle[0].product_links[0].id],
                  },
                ],
              },
            ],
          }).then((res) => {
            setCount(
              res.data.filter((pro) => pro.sku === product?.sku)[0].qty
            );
            if (buynow) {
              dispatch(loadCartData()).then(() => {
                navigate("/checkout/delivery");
                setIsCartData(false);
              });
            } else {
              dispatch(loadCartData()).then((item) => {
                cartEvents.addToCart(product, item?.id)
                handleChangeCartPopup(true);
              });
            }
          })
            .catch((err) => {
              setCartErr({ stock: product?.stock, error: err?.response?.data });
            })
            .finally(() => {
              setIsCartData(false);
              setLoaderOn(false);
            });;
        } else {
          setLoaderOn(true);
          if (
            product?.variants &&
            product?.variants.length > 0 &&
            sizeButtonIndex &&
            sizeButtonIndex >= 0
          ) {
            sku = product?.variants[sizeButtonIndex].sku;
          }

          const data = {
            items: [
              {
                sku: sku,
                id: product?.id,
                qty: count === "" ? 1 : count,
              },
            ],
          };
          await addToCart(data)
            .then((res) => {
              setCount(
                res.data.filter((pro) => pro.sku === product?.sku)[0].qty
              );
              if (buynow) {
                dispatch(loadCartData()).then(() => {
                  navigate("/checkout/delivery");
                  setIsCartData(false);
                });
              } else {
                dispatch(loadCartData()).then((item) => {
                  cartEvents.addToCart(product, item?.id)
                  handleChangeCartPopup(true);
                });
              }
            })
            .catch((err) => {
              setCartErr({ stock: product?.stock, error: err?.response?.data });
            })
            .finally(() => {
              setIsCartData(false);
              setLoaderOn(false);
            });
        }
      } catch (err) {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
      }
    }
  };

  const handleChange = (e) => {
    setPincode(e.target.value);
  };
  const handleSubmit = () => { };

  const sizeButtonClick = (sizeIndex, size) => {
    setProduct({
      ...product,
      sku: product?.variants[sizeIndex]?.sku,
      name: product?.variants[sizeIndex]?.name,
      description: product?.variants[sizeIndex]?.description,
      price: product?.variants[sizeIndex]?.price,
      currency: product?.variants[sizeIndex]?.currency,
      price_rounded: product?.variants[sizeIndex]?.price_rounded,
      discounted_price: product?.variants[sizeIndex]?.discounted_price,
      discounted_price_rounded:
        product?.variants[sizeIndex]?.discounted_price_rounded,
      discounted_price_from:
        product?.variants[sizeIndex]?.discounted_price_from,
      discounted_price_to: product?.variants[sizeIndex]?.discounted_price_to,
      is_count_down: product?.variants[sizeIndex]?.is_count_down,
      stock: product?.variants[sizeIndex]?.stock,
      item_type: product?.variants[sizeIndex]?.item_type,
      meza: product?.variants[sizeIndex]?.meza,
      qitaf: product?.variants[sizeIndex]?.qitaf,
      eligible_for_tabby: product?.variants[sizeIndex]?.eligible_for_tabby,
      enable_shipping: product?.variants[sizeIndex]?.enable_shipping,
      additional_estimated_day:
        product?.variants[sizeIndex]?.additional_estimated_day,
      current_time: product?.variants[sizeIndex]?.current_time,
      cart_rule: product?.variants[sizeIndex]?.cart_rule,
    });
    setSizeButtonIndex(sizeIndex);
  };

  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [wislistCount, setWislistCount] = useState(0);

  const wishlistData = useSelector((state) => state.appData.wishlistData);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, product?.sku));
  }, [product]);

  const handleFavourite = () => {
    if (isFavourite) {
      setIsFavourite(false);
      setWislistCount(wislistCount + 1);
    } else {
      setIsFavourite(true);
      setWislistCount(wislistCount + 1);
    }
  };
  useEffect(() => {
    const data = {
      items: [product?.sku],
    };
    if (isFavourite) {
      if (wislistCount > 0) {
        const addToWishlistData = dispatch(loadAddToWishlist(data, t)).then(
          (res) => {
            checkoutEvents.addToWishlist(product, "Product Detail");
            dispatch(loadWishlistData());
          }
        );
      }
    } else {
      if (wislistCount > 0) {
        if (!isFavourite) {
          checkoutEvents.removeFromWishlist(product, "Product Detail");
          removeFromWL(product?.sku);
        }
      }
    }
  }, [isFavourite]);

  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };
    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data, t)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };

  const redirectBundlePage = (sku) => {
    let redUrl = `/create-bundle/${sku.replace(/[/]/g, "%2F")}`;
    navigate(redUrl);
  };

  // const optionsObj = {};
  // product?.options?.forEach((option) => {
  //   optionsObj[option.code] = option;
  // });

  const copyToClipboard = (skuData) => {
    // var targetDiv = document.getElementById("copy-sku").getElementsByClassName("text2")[0];
    // let copyText = targetDiv.textContent
    navigator.clipboard.writeText(skuData).then(() => {
      dispatch(services.notifySuccess({ message: t("Copied!") }));
    });
  };

  // const currentTime = new Date(product?.current_time);
  const currentTime = new Date();
  const getCurrentHours = currentTime.getHours();

  // if (getCurrentHours < 12) {
  //   console.log("Order with lightening delivery & get it by today ",);
  // } else {
  //   console.log("Order with Express delivery & gets it by tomorrow ",);
  // }

  const handleChangeQty = (event) => {
    let value = event.target.value;
    let numberStatus = allNumeric(value);
    if (numberStatus === "success") {
      setCount(parseInt(value));
    } else {
      setCount("");
    }
  };

  let latestPrice =
    product?.discounted_price_rounded === null
      ? product?.price_rounded
      : product?.discounted_price_rounded;
  let getTamaraCheck3Month = getTamaraMinMaxLimit(latestPrice, 3);

  const [modalShow, setModalShow] = React.useState(false);
  const [returnPolicyData, setReturnPolicyData] = React.useState("");

  // avoid multiple render
  const getInfo = useMemo(() => {
    let de = cmsPageContent && cmsPageContent;
    return de;
  }, [cmsPageContent]);

  useEffect(() => {
    if (getInfo.response && getInfo.response.status === 404) {
      dispatch(cmsPageContentApiSuccess(""));
      setReturnPolicyData("");
    } else {
      setReturnPolicyData(getInfo);
    }
  }, [cmsPageContent]);

  // related product add to cart
  const AddProductToCartRelatedProd = (sku) => {
    setLoaderOnTwo(true);
    // console.log('product', product?.id);
    const data = {
      items: [
        {
          sku: sku,
          qty: 1,
          id: product?.id,
        },
      ],
    };

    addToCart(data)
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        dispatch(services.notifyError({ message: err.response.data.message }));
        setLoaderOnTwo(false);
      })
      .finally(() => {
        setLoaderOnTwo(false);
      });
  };

  // product dropdown count change
  const handleChangeCount = (event) => {
    setCount(event.target.value);
  };

  const [qtyDropBtn, setQtyDropBtn] = React.useState(false);

  useEffect(() => {
    let isOutOfStock =
      product?.orderType === "out-of-stock" || product?.stock === 0;
    let isPreOrder = product?.orderType === "pre-order";
    let showDropBtn = false;
    if (
      isOutOfStock &&
      outOfStockConfiguration?.module_status &&
      product?.productOutOfStockStatus
    ) {
      showDropBtn = false;
    } else {
      if (isOutOfStock) {
        showDropBtn = false;
      } else if (isPreOrder) {
        showDropBtn = true;
      } else {
        showDropBtn = true;
      }
    }
    setQtyDropBtn(showDropBtn);
  }, [product, outOfStockConfiguration]);

  if (loading || productLoading) {
    return <NewLoader />;
  }
  const isMobile = width <= 992;

  const discountedPrice = product?.discounted_price_rounded;
  const price = product?.price_rounded;
  const discountPercentage = Math.round(100 - (discountedPrice / price) * 100);
  const discountedAmount = Math.round(price - discountedPrice);

  const isOutOfStock =
    product?.orderType === "out-of-stock" || product?.stock === 0;
  let isShippingEnabled = product?.enable_shipping;
  let productType = product?.item_type;

  const isDeliveryEnabled =
    !isOutOfStock && isShippingEnabled && productType === "simple";
  let additionalEstimationDate = product?.additional_estimated_day;

  const removeProductFromCompare = (productId) => {
    const comparePopupLocalData = JSON.parse(localStorage.getItem("compare_products"));
    let removeData = comparePopupLocalData && comparePopupLocalData.filter(item => item.id !== productId);
    localStorage.setItem('compare_products', JSON.stringify(removeData));
    localStorage.setItem('compare_products_max_3', JSON.stringify(false));
    dispatch(updateCompareProductList(removeData))
    setPageRender(true);
    if (removeData.length === 0) {
      closeComparePopup();
    }
    setRefreshcomparePopup(true)
  }

  const handleCompare = (compareStatus, productData) => {
    if (compareStatus === false) {
      updateCompareProduct(true, productData)
    } else {
      removeProductFromCompare(productData?.id)
    }
  }

  return (
    <>
      <div className="bg-white d-none d-lg-block">
        <BreadCrumbs
          back="/"
          firstBread={breadCrumbsData?.firstBread}
          firstBreadTitle={breadCrumbsData?.firstBreadTitle}
          secondBread={productUrlHelper(product?.english_name, product?.sku)}
          secondBreadTitle={titleChange(product?.name)}
          highlight="firstBreadTitle"
        />
        <MetaComponent
          title={titleChange(product?.name)}
          meta_title={product?.meta_title}
          meta_keywords={product?.meta_keywords}
          meta_description={product?.meta_description}
        />
        <TealiumTag name={titleChange(product?.name)} template={titleChange(product?.name)} />
        <CmsContentModal
          title={t("7_Days_Return_Policy")}
          modalShow={modalShow}
          content={
            returnPolicyData &&
            htmlParseHelper(returnPolicyData?.content?.[0]?.content)
          }
          onHide={() => setModalShow(false)}
          size="lg"
          scrollable={true}
        />
      </div>
      {/* mobile pdp page */}
      {/* mobile pdp page end */}

      <div className="container-fluid product__details__page__container d-none d-lg-block build__bundle__page__container">
        <div className="row product__details__page__block">
          <div className="col-12 col-sm-12 col-md-12 col-lg-9 row product__details__left__block__new">
            <div className="row products__details__inner__block">
              <div className="col-12 col-sm-12 col-md-5 product__carousel__main__block">
                <div className="product__carousel__block">
                  <img
                    className="carousel__image"
                    src={product?.baseImage}
                    alt={`product carousel`}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-7 product__details__block">
                <div className="product__details__titlebox">
                  <button className="pd__favourite__button">
                    <img
                      onMouseEnter={() => setIsFavouriteHover(true)}
                      onClick={handleFavourite}
                      onMouseLeave={() => setIsFavouriteHover(false)}
                      className={
                        !isFavourite
                          ? "pd__favourite__icon"
                          : "pd__favourite__icon__disable"
                      }
                      src={
                        isFavouriteHover ? fulfill_favourite : empty_favourite
                      }
                      alt=""
                    />
                    <img
                      onClick={handleFavourite}
                      className={
                        isFavourite
                          ? "pd__favourite__icon"
                          : "pd__favourite__icon__disable"
                      }
                      src={fulfill_favourite}
                      alt=""
                    />
                  </button>
                  <ShareLinkPDP t={t} product={product} urlLink={window.location.href} />
                  <div className="product__namebox">
                    <Heading5 text={titleChange(product?.name)} />
                  </div>
                </div>
                <div className="row rating__and__sku__area align-items-center">
                  <div className="col-8 product__sku__box">
                    <Heading7 text={`${t("SKU")} : `} />{" "}
                    <Text3 text={product?.sku} />
                    <a
                      href="#"
                      className="pro__sku__copy"
                      onClick={(e) => copyToClipboard(product?.sku)}
                    >
                      {t("Copy")}
                    </a>
                  </div>
                </div>
                <div className="row pd__product__price__block ">
                  {!product?.is_price_hide && (
                    <div className="col-md-12 pd__product__price__offer">
                      <PriceBlock
                        oldPrice={product?.price_rounded}
                        price={
                          product?.discounted_price_rounded === null
                            ? product?.price_rounded
                            : product?.discounted_price_rounded
                        }
                        currency={product?.currency}
                        monthlySavingTagline="get it for SAR 500 in 6 equal installments"
                        product={product}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12  col-lg-3 product__details__right__block">
            {/* Return Policy Block  */}
            <div className="pd__return__block ">
              <div className="pd__icon__block">
                <img
                  src={return_period}
                  alt=""
                  className="pd__return_period_icon"
                />
              </div>
              <div className="pd__return__text__block">
                <Heading7 text={t("7_Days_Return_Policy")} />
                <Link
                  to="#"
                  className="pd__learn__more__link"
                  onClick={(e) => setModalShow(true)}
                >
                  {`${t("Learn_more")} >`}
                </Link>
              </div>
            </div>

            {!isMobile && (
              <AvailableOffers
                availableOffer={productAvailableOffer}
                title={t("Available_Offers")}
                product={product}
              />
            )}
            <Heading3 price="People Ultimately Bought" />
            <div className="pd__related__product__section">
              <p className="pd__mb__block__title">{t("Related_Products")}</p>
              {relatedProductList &&
                relatedProductList.map((product, productIndex) => {
                  if (isMore === false) {
                    if (productIndex > 2) {
                      return;
                    }
                  }
                  return (
                    <RelatedProduct
                      t={t}
                      product={product}
                      handleChangeCartPopup={handleChangeCartPopup}
                      updateCompareProduct={updateCompareProduct}
                      outOfStockConfiguration={outOfStockConfiguration}
                      handleCompare={handleCompare}
                    />
                  );
                })}
              {relatedProductList?.length > 3 &&
                <div className="view__more__address__block">
                  <button
                    onClick={() =>
                      setIsMore(!isMore)
                    }
                    className="view__more__address__button"
                  >
                    <Heading6
                      text={
                        isMore
                          ? t("View_Less")
                          : t("View_More")
                      }
                      marginBottom={0}
                      color="#000000"
                    />
                    {isMore === true ? <BsChevronUp /> : <BsChevronDown />}
                  </button>
                </div>
              }
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 pd__accordian__section my-4 product__details__left__block">
            <PdpAccordian
              product={product}
              finalData={finalData}
              productOverviewData={productOverviewData}
              productSpecificationData={productSpecificationData}
              productReviewData={productReviewData}
              getOptionKeyInfo={getOptionKeyInfo}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Build_Bundle_Page;
