import React, { useCallback, useEffect, useState, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as types from "./../../redux/actionType";
import navbar_logo from "./../../assets/Logo/navbar_logo.png";
import white_side_menu_icon from "./../../assets/Icon/white_side_menu_icon.svg";
import navbar_white_down_arrow from "./../../assets/Icon/navbar_white_down_arrow.svg";
import popup_right_arrow from "./../../assets/Icon/popup_right_arrow.svg";
import close_icon from "./../../assets/Icon/cancel-white.png";
import user_icon from "./../../assets/Icon/user.png";
import "./../../SCSS/Common/_header.scss";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
// import "./../../SCSS/_variables.scss"
import search from "./../../assets/Icon/search.svg";
import white_search from "./../../assets/Icon/white_search.svg";
import language from "./../../assets/Icon/language.svg";
import backarrow from "./../../assets/Icon/backarrow-mb_black.svg";
import favourite from "./../../assets/Icon/heart_top.svg";
import location from "./../../assets/Icon/location_top.svg";
import shopping_cart from "./../../assets/Icon/cart_top.svg";
import user from "./../../assets/Icon/profile_top.svg";
import profile_top_orange from "./../../assets/Icon/profile_top_orange.svg";
import favourite_orange from "./../../assets/Icon/favourite_orange.svg";
import location_orange from "./../../assets/Icon/location_orange.svg";
import shopping_cart_orange from "./../../assets/Icon/shopping_cart_orange.svg";
import menu from "./../../assets/Icon/menu.svg";
import MobilePopup from "../MobilePopup";
import RatingBlock from "../MostSharedComponent/RatingBlock";
import Heading7 from "../Font/Heading7";
import NotifySnackbar from "./notifySnackbar";
import { customerDetailsSuccess } from "../../services/customer/customer";
import {
  saveProductDetailData,
  toggleLogistrationMode,
  toggleLogistrationState
} from "../../redux/appAction";
import * as services from "./../../services/services";
import {
  customerSignInSuccess
} from "../../services/customer/customer";
import db_my_order from "./../../assets/Icon/db_my_order.svg";
import db_my_profile from "./../../assets/Icon/db_my_profile.svg";
import db_my_wishlist from "./../../assets/Icon/db_my_wishlist.svg";
import db_my_wallet from "./../../assets/Icon/db_my_wallet.svg";
import NewLoader from "./newloader";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import apiHelper from "../helpers/utils/apiHelper";
import { saveApplyFilterProductData } from "../../redux/appAction";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
import { getCustomerLoginDetails } from "../helpers/utils/getCustomerLoginDetails";
import SignSignupTabModal from "./SignSignupTabModal";
import LazyImageForProduct from "../MostSharedComponent/LazyImageForProduct";
import { ImageResize } from "../helpers/utils/imageResize";
import { impressionEvents } from "../helpers/utils/ga/impressionEvents";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

class OutsideClickHandler extends React.Component {
  wrapperRef = createRef()

  static defaultProps = {
    onOutsideClick: () => { },
    width: () => { },
    height: () => { },
    noZIndex: false,
  }

  componentDidMount() {
    document?.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = event => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event?.target)) {
      this.props.onOutsideClick()
    }
  }

  render() {
    const { children } = this.props

    return (
      <div className='innermodal'
        style={{
          width: `${this.props.width}` || 'auto',
          height: `${this.props.height}` || 'auto',
          // zIndex: !this.props.noZIndex && 999999,
          //position: `${this.props.position ? this.props.position : 'relative'}`
        }}
        ref={this.wrapperRef}
      >
        {children}
      </div>
    )
  }
}

function Header({
  reloadHeader,
  categoryData,
  handleChangeCartPopup,
  cartIconTotal,
  handleChangeCartIconTotal,
}) {

  const customerData = getCustomerLoginDetails();
  const { t } = useTranslation();

  const dashboardDropdownData = [
    {
      id: 1,
      icon: db_my_order,
      title: t("My_Orders"),
      link: "/user/orders",
    },
    {
      id: 2,
      icon: db_my_profile,
      title: t("My_Profile"),
      link: "/user/profile",
    },
    // {
    //   id: 3,
    //   icon: db_my_payments,
    //   title: t("My_Payments"),
    //   link: "#",
    // },
    {
      id: 4,
      icon: db_my_wishlist,
      title: t("My_Wishlists"),
      link: "/user/wishlist",
    },
    {
      id: 5,
      icon: db_my_wallet,
      title: t("My_Wallet"),
      link: "/user/credits",
    },
    // {
    //   id: 6,
    //   icon: db_my_tickets,
    //   title: t("My_Tickets"),
    //   link: "#",
    // },
    // {
    //   id: 7,
    //   icon: db_my_returns,
    //   title: t("My_Returns"),
    //   link: "#",
    // },
    // {
    //   id: 8,
    //   icon: db_my_giftcards,
    //   title: t("Gift_Cards"),
    //   link: "#",
    // },
  ];

  const {
    customerSignUpOtpData,
    customerVerifyMobileOTP,
    customerDetails,
    customerResetPasswordOTP,
  } = useSelector((state) => state.customerReducer);
  const { customerNewsletterSubMsg } = useSelector(
    (state) => state.customerNewsletterReducer
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLocation, setIsLocation] = useState(false);
  const [isWishlist, setIsWishlist] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isCart, setIsCart] = useState(false);

  const handleChangeIsLocation = (value) => {
    setIsLocation(value);
  };
  const handleChangeIsWishlist = (value) => {
    setIsWishlist(value);
  };
  const handleChangeIsUser = (value) => {
    setIsUser(value);
  };
  const handleChangeIsCart = (value) => {
    setIsCart(value);
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  // check user login
  useEffect(async () => {
    if (customerData) {
      try {
        let response = await apiHelper(`/V1/customer`, "get", {}, {});
      } catch (error) {
        if (error?.response?.status === 401 && error?.response?.data?.message === "Unauthorized access") {
          localStorage.removeItem("custDetails");
          dispatch(customerDetailsSuccess(""));
        }
      }
    }
  }, []);

  useEffect(() => {
    if (cartIconTotal > 0) {
      setIsCart(true);
      handleChangeIsCart(true);
    }
    else if (cartIconTotal == 0) {
      handleChangeIsCart(false);
    }
  }, [cartIconTotal]);

  const { wishlistCount } = useSelector((state) => state.appData);
  const loadingData = useSelector((state) => state.commonReducer);

  useEffect(() => {
    if (wishlistCount > 0) {
      setIsWishlist(true);
      handleChangeIsWishlist(true);
    }
    else if (wishlistCount == 0) {
      handleChangeIsWishlist(false);
    }
  }, [wishlistCount]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // language changing in project //

  // const [allCategoryData,setAllCategoryData] = useState(categoryData)
  const [loading, setLoading] = useState(false);
  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
  ];

  useEffect(() => {
    if (customerDetails === "") {
      setUserLoginPopup(false);
      customerSignInSuccess("");
    }
  }, [customerDetails]);

  // Find Current language from {language} object
  const currentLanguageCode = localStorage.getItem("i18next") || "ar";
  const [currentLanguage, setCurentLanguage] = useState(
    languages.find((l) => l.code === currentLanguageCode)
  );

  // changing layout from right to left for arabic language"
  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.body.classList.remove("englishFonts");
    document.body.classList.remove("arabicFonts");
    if (currentLanguage.code === "en") {
      document.body.classList.add("englishFonts");
    } else {
      document.body.classList.add("arabicFonts");
    }
    document.title = t("Sony_World_KSA");
  }, [currentLanguage, t]);

  // language dropdown open and close
  const [isEnglish, setIsEnglish] = useState(
    currentLanguageCode === "en" ? true : false
  );
  // useEffect(() => {
  //   if (isEnglish) {
  //     let lang = {
  //       code: "en",
  //       name: "English",
  //       country_code: "gb",
  //     };
  //     handleLanguageChange(lang);
  //   } else {
  //     let lang = {
  //       code: "ar",
  //       name: "العربية",
  //       dir: "rtl",
  //       country_code: "sa",
  //     };
  //     handleLanguageChange(lang);
  //   }
  // }, [isEnglish]);

  const createRedirectLngUrl = (currentLanguageCode) => {
    let basename = "ar-sa";
    if (currentLanguageCode === "en") {
      basename = "en-sa";
    }
    window.location.replace(`/${basename}${pathname}`);
  };

  const handleChangeHeaderLng = () => {
    let getCode = currentLanguageCode === "en" ? "en" : "ar";
    if (getCode === "ar") {
      let lang = {
        code: "en",
        name: "English",
        country_code: "gb",
      };
      handleLanguageChange(lang);
    } else {
      let lang = {
        code: "ar",
        name: "العربية",
        dir: "rtl",
        country_code: "sa",
      };
      handleLanguageChange(lang);
    }
  };

  const handleLanguageChange = (lang) => {
    setCurentLanguage(lang);
    createRedirectLngUrl(lang.code);
    // handleLanguageChangeHelper(lang.code, currentLanguageCode)
    // i18next.changeLanguage(lang.code);
    // localStorage.setItem("i18next", lang.code);

    // if (lang.code !== currentLanguageCode) {
    //   getHandshakeAfterLngChange(lang.code, currentLanguageCode).then((res) => {
    //     setHeader("X-Access-Token", res.data.token);
    //     localStorage.setItem("handShakeToken", res.data.token);
    //     setHandsakeConfig(res.data);
    //     createRedirectLngUrl(lang.code);
    //     setTimeout(() => {
    //       window.location.reload();
    //     }, 500);
    //   });
    // }
  };

  const [height, setHeight] = useState(0);
  const { pathname } = useLocation();
  const [loginPopup, setLoginPopup] = useState(false);
  const [categoryPopup, setCategoryPopup] = useState(false);
  const [loginMode, setLoginMode] = useState("");
  const [loginWrapper, setLoginWrapper] = useState(false);
  const [userLoginPopup, setUserLoginPopup] = useState(false);
  const [searchPopup, setSearchPopup] = useState(false);
  const [filterDetails, setFilterDetails] = useState({ filterDetails: {} });
  const [searchProductData, setSearchProductData] = useState();
  // const [searchData,setSearchData] = useState()
  // when page switch then reset product reducer data
  useEffect(() => {
    dispatch(saveProductDetailData({}));
    if(!(pathname + search).includes("/search?q=")) {
      setSearchText("")
    } else {
      const searchProduct = search.split("q=");
      setSearchText(searchProduct?.[1] ?? "")
    }
  }, [pathname, search]);


  useEffect(() => {
    if (customerSignUpOtpData !== "") {
      openLoginWrapper("otp");
    }
    if (customerVerifyMobileOTP?.status === true) {
      // openLoginWrapper("newsletter");
      closeLoginPopup();
    }
    // if (customerNewsletterSubMsg === true) {
    //   closeLoginPopup();
    // }
  }, [customerSignUpOtpData, customerVerifyMobileOTP]);

  const userloginData = useSelector((state) => state.appData.userLoggedIn);
  const setLogistrationMode = (status) => dispatch(toggleLogistrationMode(status));
  const setLogistrationState = () => dispatch(toggleLogistrationState('register'));
  
  const getData = JSON.parse(localStorage.getItem("handShakeConfig"));

  useEffect(() => {
      document.body.classList.remove("modal_back_stop");
  }, [userloginData , getData]);

  useEffect(() => {
    if (loginPopup) {
      document.body.classList.add("modal_back_stop");
    } else {
      document.body.classList.remove("modal_back_stop");
    }
  }, [loginPopup]);

  useEffect(() => {
    if (searchPopup) {
      document.body.classList.add("modal_back_stop");
    } else {
      document.body.classList.remove("modal_back_stop");
    }
  }, [searchPopup]);
  // useEffect(() => {
  //   if (customerResetPasswordOTP?.success === true) {
  //     openLoginWrapper("reset-password-verify");
  //   }
  // }, [customerResetPasswordOTP]);

  // mobile-mode then close signin/signup close
  // useEffect(() => {
  //   if (windowDimensions.width < 992) {
  //     closeLoginPopup();
  //   }
  // }, [windowDimensions]);

  const [searchText, setSearchText] = useState("");
  const handleChange = (e) => {
    setSearchText(e.target.value);
    if (!e.target.value.trim()) {
      setSearchProductData([])
    }
  };
  const redirectToSearchPage = (e) => {
    e.preventDefault();
    const searchTextData = searchText?.trimStart()
    if (searchTextData) {
      localStorage.setItem("searchText", JSON.stringify(searchTextData));
      setFilterDetails({ filterDetails: { keyword: searchTextData } });
      navigate(`/search?q=${searchTextData}`);
      // setSearchText("");
    }
    // optimizedFn(e)
  };
  
  useEffect(() => {
    if (searchText.trim() !== ""  && document.activeElement.className === "search__input") {
      setSearchPopup(true);
    }
    if (searchText.trim() === "") {
      setSearchPopup(false);
    }
  }, [searchText]);

  const openSearchPopup = (e) => {
    e.preventDefault();
    // debugger

    // setSearchPopup(true);
    if (searchText.trim() !== "") {
      setSearchPopup(true);
    }
    if (e.target.value.trim() === "") {
      setSearchPopup(false);
      setSearchProductData([])
    } else {
      setFilterDetails({ filterDetails: { keyword: e.target.value.trim() } });
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };
  const optimizedFn = useCallback(debounce(openSearchPopup), []);

  useEffect(async () => {
    if (
      filterDetails.filterDetails.keyword &&
      filterDetails.filterDetails.keyword.length > 2
    ) {
      try {
        let response = await apiHelper(
          `/V1/products/filter/apply`,
          "post",
          filterDetails,
          {}
        );
        dispatch(saveApplyFilterProductData(response.data));
      } catch (error) {
        setSearchPopup(false);
        dispatch(saveApplyFilterProductData({}));
      }
      // dispatch(loadApplyFilterProductsData(filterDetails));
    }
  }, [filterDetails]);
  const applyFilterData = useSelector(
    (state) => state.appData.applyFilterProductsData
  );
  // useEffect(() => {
  //   impressionEvents.impressionSearch(
  //     applyFilterData,
  //     searchText
  //   );
  // }, [applyFilterData]);
  useEffect(() => {
    if (applyFilterData) {
      setSearchProductData(applyFilterData.items);
    }
  }, [applyFilterData]);
  const closeSearchPopup = () => {
    setSearchPopup(false);
  };
  // const [cartPopup, setCartPopup] = useState(false);
  // const closeCartPopup = () => {
  //   setCartPopup(false);
  // };
  // const handleChangeCartPopup = (value) => {
  //   setCartPopup(value);
  //   // setPopupProduct(product);
  // };
  //   useEffect(() => {
  //     // debugger
  //     if(localStorage.getItem('loginWrapper') ){

  //       async function init() {
  //         const latestLoginWrapper = await localStorage.getItem('loginWrapper');
  //         const latestLoginPopup = await localStorage.getItem('loginPopup');
  //         setLoginWrapper(JSON.parse(latestLoginWrapper));
  //         setLoginPopup(JSON.parse(latestLoginPopup));
  //       }
  //       init();
  //     }
  // }, [])

  useEffect(() => {
    // debugger

    function init() {
      const latestLoginWrapper = localStorage.getItem("loginWrapper");
      const latestLoginPopup = localStorage.getItem("loginPopup");

      setLoginWrapper(JSON.parse(latestLoginWrapper));
      setLoginPopup(JSON.parse(latestLoginPopup));
    }
    init();
  }, [
    JSON.parse(localStorage.getItem("loginWrapper")),
    JSON.parse(localStorage.getItem("loginPopup")),
    JSON.parse(localStorage.getItem("loginMode")),
  ]);

  //   useEffect(() => {
  //     // debugger
  //     if(localStorage.getItem('loginWrapper') ){

  //       async function init() {
  //         const latestLoginWrapper = await localStorage.getItem('loginWrapper');
  //         const latestLoginPopup = await localStorage.getItem('loginPopup');

  //         setLoginWrapper(JSON.parse(latestLoginWrapper));
  //         setLoginPopup(JSON.parse(latestLoginPopup));
  //       }
  //       init();
  //     }
  // }, [reloadHeader])
  const openLoginPopupAndSetToSignin = () => {
    openLoginPopup();
    setLoginMode("login");
  };
  const openLoginPopup = () => {
    setLoginPopup(userLoginPopup ? false : true);
    localStorage.setItem("loginPopup", JSON.stringify(true));
    setCategoryPopup(false);
    setLoginMode("");
  };
  const openProductPopup = () => {
    setCategoryPopup(!categoryPopup);
    setLoginPopup(false);
  };

  const openLoginWrapper = (mode) => {
    // desktop
    setLoginMode(mode);
    setLoginWrapper(true);
    setUserLoginPopup(false);
    localStorage.setItem("loginMode", JSON.stringify(mode));
    localStorage.setItem("loginWrapper", JSON.stringify(true));
    localStorage.setItem("loginPopup", JSON.stringify(true));
  };
  const closeLoginPopup = () => {
    if (document.querySelector(".signin__signup__popup")) {
      // reloadingHeader()
      const element = document.querySelector(".signin__signup__popup");
      element.classList.remove("signin__signup__popup");
      element.classList.add("signin__signup__popup__disable");
    }
    localStorage.setItem("loginMode", JSON.stringify("signin"));
    localStorage.setItem("loginWrapper", JSON.stringify(false));
    localStorage.setItem("loginPopup", JSON.stringify(false));
    setLoginMode("");
    setLoginPopup(false);
    setLoginWrapper(false);
    setLogistrationMode(false);
    setLogistrationState('login');
  };
  useEffect(() => {
    setLoginMode(loginMode);
  }, [loginWrapper]);
  const [selectedCategory, setSelectedCategory] = useState(
    categoryData && categoryData?.children_data?.[0]
  );
  const [reducerSelectedCategory, setReducerSelectedCategory] = useState(
    categoryData && categoryData?.children_data?.[0]
  );
  const [mobileSelectedCategory, setMobileSelectedCategory] = useState();

  const [navIndex, setNavIndex] = useState("");
  const [menuIndex, setMenuIndex] = useState(0);
  const [className, setClassName] = useState({
    allCategories: "navbar__link active__navbar__link",
    topDeals: "navbar__link",
    dealsOfTheDay: "navbar__link",
    digitalCards: "navbar__link",
    topSellers: "navbar__link",
    others: "navbar__link",
    services: "navbar__link",
  });
  const [mobileClassName, setMobileClassName] = useState({
    home: "mobile__navbar__link active__mobile__navbar__link",
    playstation: "mobile__navbar__link",
    television: "mobile__navbar__link",
    camera: "mobile__navbar__link",
    laptops: "mobile__navbar__link",
    services: "mobile__navbar__link",
  });
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileShowPopup, setMobileShowPopup] = useState(false);
  const ref = React.useRef(null);
  useEffect(() => {
    const onScroll = () => setHeight(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [window.pageYOffset]);

  useEffect(() => {
    if (mobileNavbar) {
      document.body.classList.add("modal_back_stop");
    } else {
      document.body.classList.remove("modal_back_stop");
    }
  }, [mobileNavbar]);

  useEffect(() => {
    if (document.querySelector(".main__navbar__container")) {
      if (height > 112) {
        // document.querySelector(".main__navbar__container").style.position =
        //   "fixed";
        document
          .querySelector(".main__navbar__container")
          .classList.add("header_fix");
      } else if (height < 112) {
        // document.querySelector(".main__navbar__container").style.position =
        //   "relative";
        document
          .querySelector(".main__navbar__container")
          .classList.remove("header_fix");
      }
    }
  }, [height]);
  useEffect(() => {
    let path = {
      allCategories: "navbar__link",
      topDeals: "navbar__link",
      dealsOfTheDay: "navbar__link",
      digitalCards: "navbar__link",
      topSellers: "navbar__link",
      others: "navbar__link",
      services: "navbar__link",
    };
    let mobilepath = {
      home: "mobile__navbar__link ",
      playstation: "mobile__navbar__link",
      television: "mobile__navbar__link",
      camera: "mobile__navbar__link",
      laptops: "mobile__navbar__link",
      others: "mobile__navbar__link",
      services: "mobile__navbar__link",
      allCategories: "mobile__navbar__link",
    };
    if (pathname.includes("/playstation")) {
      // path.playstation = "navbar__link active__navbar__link";
      mobilepath.playstation =
        "mobile__navbar__link active__mobile__navbar__link";
    } else if (pathname.includes("/television")) {
      // path.television = "navbar__link active__navbar__link";
      mobilepath.television =
        "mobile__navbar__link active__mobile__navbar__link";
    } else if (pathname.includes("/camera")) {
      // path.camera = "navbar__link active__navbar__link";
      mobilepath.camera = "mobile__navbar__link active__mobile__navbar__link";
    } else if (pathname.includes("/laptops")) {
      // path.laptops = "navbar__link active__navbar__link";
      mobilepath.laptops = "mobile__navbar__link active__mobile__navbar__link";
    } else if (pathname.includes("/top-deals")) {
      path.topDeals = "navbar__link active__navbar__link";
      // mobilepath.topDeals = "mobile__navbar__link active__mobile__navbar__link";
    } else if (pathname.includes("/deals-of-the-day")) {
      path.dealsOfTheDay = "navbar__link active__navbar__link";
      // mobilepath.dealsOfTheDay = "mobile__navbar__link active__mobile__navbar__link";
    } else if (pathname.includes("/digital-cards")) {
      path.digitalCards = "navbar__link active__navbar__link";
      // mobilepath.digitalCards = "mobile__navbar__link active__mobile__navbar__link";
    } else if (pathname.includes("/top-sellers")) {
      path.topSellers = "navbar__link active__navbar__link";
      // mobilepath.topSellers = "mobile__navbar__link active__mobile__navbar__link";
    } else if (pathname.includes("/others")) {
      path.others = "navbar__link active__navbar__link";
      mobilepath.others = "mobile__navbar__link active__mobile__navbar__link";
    } else if (pathname.includes("/services")) {
      path.services = "navbar__link active__navbar__link";
      mobilepath.services = "mobile__navbar__link active__mobile__navbar__link";
    } else if (pathname.includes("/allCategories")) {
      path.allCategories = "navbar__link active__navbar__link";
      mobilepath.allCategories =
        "mobile__navbar__link active__mobile__navbar__link";
    } else {
      path = {
        allCategories: "navbar__link",
        topDeals: "navbar__link",
        dealsOfTheDay: "navbar__link",
        digitalCards: "navbar__link",
        topSellers: "navbar__link",
        others: "navbar__link",
        services: "navbar__link",
      };
      mobilepath = {
        allCategories: "mobile__navbar__link",
        topDeals: "mobile__navbar__link",
        dealsOfTheDay: "mobile__navbar__link",
        digitalCards: "mobile__navbar__link",
        topSellers: "mobile__navbar__link",
        others: "mobile__navbar__link",
        services: "mobile__navbar__link",
        allCategories: "mobile__navbar__link",
      };
    }
    setClassName(path);
    setMobileClassName(mobilepath);
  }, [pathname]);

  const navbarTab__mouseTab = (popupValue, navIndex, menuIndex) => {
    setCategoryPopup(popupValue);
    setNavIndex(navIndex);
    setMenuIndex(menuIndex);
  };
  useEffect(() => {
    const handleMouseCheck = (e) => {
      if (e.target.className === "row category__popup__block") {
        navbarTab__mouseTab(false, "");
      }
    };
    document.addEventListener("mousemove", handleMouseCheck);
    return () => {
      document.removeEventListener("mousemove", handleMouseCheck);
    };
  }, []);

  const clickonSubCategoryInMobilePopup = () => {
    closeMobileSidebarPopup();
    // backtoMainMobilePopup()
  };

  const mobileNavbarTab__mouseTab = (
    popupValue,
    navIndex,
    newMenuIndex,
    currentCategory
  ) => {
    if (menuIndex === newMenuIndex) {
      setMobileShowPopup(popupValue);
    } else if (menuIndex !== newMenuIndex) {
      setMobileShowPopup(true);
    }
    setNavIndex(navIndex);
    setMenuIndex(newMenuIndex);
    setMobileSelectedCategory(currentCategory);
  };

  const changeSelectedCategory = (catObj) => {
    setSelectedCategory(catObj);
  };
  const changeReducerSelectedCategory = (catObj,id = "") => {
    impressionEvents.impressionCategoryClick(
      catObj,
      id
    );
    dispatch({
      type: types.SET__SELECTED__CATEGORY,
      payload: catObj,
    });
  };
  const customerLogout = () => {
    setLoading(true);
    localStorage.removeItem("custDetails");
    localStorage.removeItem("handShakeToken");
    localStorage.removeItem("handShakeConfig");
    dispatch(customerDetailsSuccess(""));
    let params = {
      id: customerDetails.id,
    };
    dispatch(services.customerLogout(params, t));
  };

  const openLogoutPopup = () => {
    setUserLoginPopup(!userLoginPopup);
  };

  const closeMobileSidebarPopup = () => {
    setMobileNavbar(false);
  };

  const backtoMainMobilePopup = () => {
    setMobileShowPopup(!mobileShowPopup);
  };

  const popover = (
    <Popover id="popover-basic">
      {/* <Popover.Body> */}
      <span>{t("our_stores")}</span>
      {/* </Popover.Body> */}
    </Popover>
  );

  // if (loading) {
  //   return <NewLoader />;
  // }
  return (
    <>
      <NotifySnackbar />
      <div className="container-fluid main__navbar__container">
        {loadingData?.loading ? <NewLoader /> : null}
        <div
          className="header__container"
          onMouseEnter={() => navbarTab__mouseTab(false, "")}
        >
          <div className="header" role="banner">
            <div className="header__right__text text-end">
              <p>{t("Site_operated_and_items_sold")}</p>
            </div>
            <div className="row header__row">
              <span className="col-4  col-sm-6 col-lg-3  col-xl-2 header__link">
                <img
                  src={white_side_menu_icon}
                  alt="header__sidemenu__icon"
                  className="header__sidemenu__icon"
                  onClick={() => setMobileNavbar(!mobileNavbar)}
                />
                <img src={navbar_logo} alt="logo" className="header__logo"
                  onClick={() => navigate("/")}
                />
              </span>
              <div className="col-0  col-sm-0  col-lg-5 col-xl-6  search__box__block">
                <form
                  autoComplete="off"
                  onSubmit={(e) => redirectToSearchPage(e)}
                >
                  <div className="search__box">
                    <input
                      type="text"
                      name="search"
                      className="search__input"
                      placeholder={t("Type_Your_Search")}
                      value={searchText}
                      onChange={(e) => handleChange(e)}
                      // onFocus={(e) => openSearchPopup(e)}
                      // onClickCapture={(e) => setSearchPopup(true)}
                      onKeyUp={(e) => optimizedFn(e)}
                      autoComplete="off"
                    />
                    <button className="search__icon__button">
                      <img
                        src={search}
                        alt="Search Button"
                        onSubmit={(e) => redirectToSearchPage(e)}
                        className="search__icon header__icon"
                      />
                    </button>
                  </div>
                </form>

                <OutsideClickHandler onOutsideClick={() => setSearchPopup(false)}>
                  <div
                    className={
                      searchPopup && !!searchText.trim()
                        ? "row search__box__result__popup"
                        : "row search__box__result__popup__disable"
                    }
                    // onMouseLeave={() => setSearchPopup(false)}
                  >
                    {searchProductData &&
                      searchProductData.map((product, productIndex) => {
                        if (productIndex % 2 === 0) {
                          return (
                            <div key={`search_result_${productIndex}`} className="col-xl-6 search__result__right__part" onClick={() => setSearchPopup(false)}>
                              <Link
                                key={product?.sku}
                                className="row search__result__product__block mb-1"
                                to={productUrlHelper(
                                  product?.english_name,
                                  product?.sku
                                )}
                              >
                                <div className="col-2 col-md-1 col-lg-2 search__result__product__image__block">
                                  <LazyImageForProduct
                                    product={product}
                                    // srcSetReSize={ImageResize}
                                    alt={titleChange(product?.name)}
                                    className="search__result__product__image"
                                  />
                                </div>
                                <div className="col-10 col-md-11 col-lg-10 search__result__product__text">
                                  <Heading7 text={titleChange(product?.name)} />
                                  <RatingBlock rating={6} totalRatings={2222} />
                                </div>
                              </Link>
                            </div>
                          );
                        } else {
                          return (
                            <div key={`search_result_${productIndex}`} className="col-xl-6 search__result__right__part" onClick={() => setSearchPopup(false)} >
                              <Link
                                key={product?.id}
                                className="row search__result__product__block mb-1"
                                to={productUrlHelper(
                                  product?.english_name,
                                  product?.sku
                                )}
                              >
                                <div className="col-2 col-md-1 col-lg-2 search__result__product__image__block">
                                  <LazyImageForProduct
                                    product={product}
                                    // srcSetReSize={ImageResize}
                                    alt={titleChange(product?.name)}
                                    className="search__result__product__image"
                                  />
                                </div>
                                <div className="col-10 col-md-11 col-lg-10 search__result__product__text">
                                  <Heading7 text={titleChange(product?.name)} />
                                  <RatingBlock rating={6} totalRatings={2222} />
                                </div>
                              </Link>
                            </div>
                          );
                        }
                      }
                      )}
                  </div>
                </OutsideClickHandler>
              </div>
              <div className="col-8 col-sm-6 col-lg-4  col-xl-4 header__icons__linkbox ">
                <div className="header__icon__block">
                  <div></div>
                  <div className="header__icon__inner__block">
                    <img
                      src={white_search}
                      alt=""
                      className="header__search__icon header__icon"
                    />
                    <div
                      onClick={() => {
                        // setIsEnglish(!isEnglish);
                        handleChangeHeaderLng();
                      }}
                      className="main__language__selection"
                    >
                      <img
                        src={language}
                        alt=""
                        className="header__language__icon header__icon"
                        onClick={() => {
                          // setIsEnglish(!isEnglish);
                          handleChangeHeaderLng();
                        }}
                      />
                      <p className="language">
                        {currentLanguage.name === "English"
                          ? "العربية"
                          : currentLanguage.name === "العربية"
                            ? "English"
                            : "العربية"}{" "}
                      </p>
                    </div>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 150, hide: 300 }}
                      overlay={popover}
                    >
                      <Link
                        to="/stores?id=stores"
                        className="location__icon__block"
                        onMouseEnter={() => handleChangeIsLocation(true)}
                        onMouseLeave={() => handleChangeIsLocation(false)}
                        aria-label="Store Location Link"
                      >
                        {isLocation ? (
                          <img
                            src={location_orange}
                            alt="Location"
                            className="location header__icon"
                          />
                        ) : (
                          <img
                            src={location}
                            alt="Location"
                            className="location header__icon"
                          />
                        )}
                      </Link>
                    </OverlayTrigger>
                    <Link
                      to="/user/wishlist"
                      className="wishlist__icon__block"
                      // onMouseEnter={() => handleChangeIsWishlist(true)}
                      // onMouseLeave={() => {
                      //   wishlistCount > 0
                      //     ? handleChangeIsWishlist(true)
                      //     : handleChangeIsWishlist(false);
                      // }}
                      aria-label="Wishlist Link"
                    >
                      {isWishlist ? (
                        <img
                          src={favourite_orange}
                          alt="Wishlist"
                          className="favourite header__icon"
                        />
                      ) : (
                        <img
                          src={favourite}
                          alt="Wishlist"
                          className="favourite header__icon"
                        />
                      )}
                      {wishlistCount > 0 && (
                        <p className="wishlist__item__count">{wishlistCount}</p>
                      )}
                    </Link>
                    {!(userloginData === true || getData?.user?.userLoggedIn === true) ? (
                      <div
                        className={`header__user__block ${loginPopup ? 'user__login__active' : ''}`}
                      // onMouseEnter={() => handleChangeIsUser(true)}
                      // onMouseLeave={() => handleChangeIsUser(false)}
                      >
                        <img
                          src={isUser ? profile_top_orange : user}
                          alt=""
                          className="user header__icon "
                          onClick={() => {
                            if (!loginPopup) {
                              openLoginPopupAndSetToSignin();
                            }
                          }}
                        />
                        {loginPopup &&
                          <React.Fragment>
                            <div className="signin__signup__popup">
                              <SignSignupTabModal
                                handleChangeCartIconTotal={handleChangeCartIconTotal}
                                loginMode={loginMode}
                                closeLoginPopup={closeLoginPopup}
                                loginWrapper={loginWrapper}
                                loginPopup={loginPopup}
                                setLoginMode={setLoginMode}
                              />
                            </div>
                            <div className="signin__signup__bg__area"></div>
                          </React.Fragment>
                        }
                      </div>
                    ) : (
                      <>
                        <div className="header__user__block">
                          <div
                            className="header__username__block"
                            onClick={() => openLogoutPopup()}
                          >
                            <p className="header__username">
                              {(customerDetails?.firstname !== "" &&
                                customerDetails?.firstname !== null &&
                                customerDetails?.firstname !== undefined &&
                                customerDetails?.firstname?.length > 10
                                ? customerDetails?.firstname?.substring(0, 10)
                                : customerDetails?.firstname) ||
                                customerDetails?.custom_attributes?.username}
                            </p>
                            <img
                              src={navbar_white_down_arrow}
                              alt=""
                              className="user__down__arrow__icon d-none d-md-block"
                            />
                            {/* <img
                              src={user}
                              alt=""
                              width="16px"
                              className="user__down__arrow__icon user_icon_mb d-md-none"
                            /> */}
                            <div className="user_avatar user_icon_mb d-md-none user__down__arrow__icon">
                              {customerDetails?.firstname ? customerDetails?.firstname[0]?.toUpperCase() : ''}
                            </div>
                          </div>
                          <div
                            className={
                              userLoginPopup
                                ? "user__dropdown__block"
                                : "signin__signup__popup__disable"
                            }
                            onMouseLeave={() => setUserLoginPopup(false)}
                            onClick={() => setUserLoginPopup(false)}
                          >
                            <div className="user__dropdown__title__block">
                              <Link
                                className="user__dropdown__title"
                                to="/user"
                              >
                                <Heading7 text={t("my_account")} />
                              </Link>
                            </div>
                            {/* {`${
                              customerDetails.firstname !== null
                                ? customerDetails.firstname
                                : ""
                            } ${
                              customerDetails.lastname !== null
                                ? customerDetails.lastname
                                : ""
                            }`} */}
                            {dashboardDropdownData.map((item, itemIndex) => {
                              return (
                                <Link
                                  key={item.title}
                                  to={item.link}
                                  className="user__db__menu__block"
                                >
                                  <img
                                    className="user__db__icon"
                                    src={item.icon}
                                    alt={item.title}
                                  />
                                  <p className="user__db__menu__title">
                                    {item.title}
                                  </p>
                                </Link>
                              );
                            })}
                            <div className="user__db__signout__button__block">
                              <button
                                onClick={() => customerLogout("signin")}
                                className="logout__button"
                              >
                                {t("Sign_Out")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <Link
                      to="/cart"
                      // onClick={() => handleChangeCartPopup(true)}
                      className="cart__icon__block"
                      // onMouseEnter={() => handleChangeIsCart(true)}
                      // onMouseLeave={() => {
                      //   cartIconTotal > 0
                      //     ? handleChangeIsCart(true)
                      //     : handleChangeIsCart(false);
                      // }}
                      aria-label="Cart Link"
                    >
                      {isCart ? (
                        <img
                          src={shopping_cart_orange}
                          alt="Shopping Cart"
                          className="shopping_cart header__icon"
                        />
                      ) : (
                        <img
                          src={shopping_cart}
                          alt="Shopping Cart"
                          className="shopping_cart header__icon"
                        />
                      )}
                      {cartIconTotal > 0 && (
                        <p className="cart__item__count">{cartIconTotal}</p>
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" navbar__container" role="navigation">
          <div className=" navbar__row">
            <div className=" navbar">
              <div className="navbar__link__block">
                <div
                  onMouseEnter={() => openProductPopup()}
                  // onMouseLeave={() => openProductPopup()}
                  className="navbar__allCategories"
                >
                  {/* <img src={menu} alt="" className="navbar__menu__icon" /> */}
                  <HiOutlineMenuAlt1 />
                  <span className="all__categoriy__navabar__link">
                    {t("all_catetegories")}
                  </span>
                </div>
                {/* <Link
                  className={className.dealsOfTheDay}
                  to="/category/televisions-mc-270"
                >
                  {t("Televisions")}
                </Link>
                <Link className={className.digitalCards} to="/category/home-cinema-mc-671">
                  {t("home_cinema")}
                </Link>
                <Link className={className.topSellers} to="/category/playstation-mc-275">
                  {t("play_stations")}
                </Link> */}
                <Link
                  className={className.topDeals}
                  to="/category/top-deals-mc-880"
                >
                  {t("top_deals")}
                </Link>
                {/* <Link className={className.topDeals} to="/category/clearance-sales-mc-898">
                  {t("Clearance_Sales")}
                </Link> */}
                <Link className={className.topDeals} to="/stores?id=centers">
                  {t("service_centers")}
                </Link>
                <Link className={className.topDeals} to="/category/audio-promotion-mc-1188">
                  {t("Audio_Promotion")}
                </Link>
                {/* <Link className={className.topDeals} to="#">
                  {t("top_deals")}
                </Link>
                <Link className={className.others} to="#">
                  {t("upcoming")}
                </Link>
                <Link className={className.others} to="#">
                  {t("others")}
                </Link> */}
                {/* <Link className={className.services} to="/services">
                  {t("services")}
                  <HiOutlineChevronDown className="down__arrow__icon" />
                </Link> */}
              </div>
              <div className="icon__block">
                <img src={menu} alt="" className="menu__icon" />
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            mobileNavbar ? "mobile__navbar" : "mobile__navbar__disable"
          }
        >
          <div
            className={
              mobileShowPopup
                ? "mobile__navbar__link__block__disable"
                : "mobile__navbar__link__block"
            }
          >
            <div className="mobile_top_header">
              <Link className="mobile_user_name" to="/">
                <span>
                  <img src={user_icon} alt="user" />
                </span>
                <p className="d-inline-block mb-0">
                  <strong>
                    {customerDetails.firstname !== "" &&
                      customerDetails.firstname}
                  </strong>
                  {/* John Doe */}
                </p>
              </Link>
              <div
                className="mobile_close_icon"
                onClick={() => setMobileNavbar(!mobileNavbar)}
              >
                <img src={close_icon} alt="close" />
              </div>
            </div>
            {categoryData?.children_data
              ?.filter((val) => val.include_in_menu === true)
              .map((catObj, catIndex) => {
                return (
                  <span
                    key={catObj.id}
                    // to="/"
                    onClick={() =>
                      mobileNavbarTab__mouseTab(
                        !mobileShowPopup,
                        catObj.name,
                        catObj.id,
                        catObj
                      )
                    }
                    className={
                      navIndex === catObj.name
                        ? "active__mobile__navbar__link mobile__navbar__link"
                        : mobileClassName.television
                    }
                  >
                    {catObj.name}
                    <img
                      src={navbar_white_down_arrow}
                      alt=""
                      className="down__arrow__icon"
                    />
                  </span>
                );
              })}
            {/* <Link
              to="/"
              onClick={() =>
                mobileNavbarTab__mouseTab(!mobileShowPopup, "services", 7, {})
              }
              className={
                navIndex === "services"
                  ? "active__mobile__navbar__link mobile__navbar__link"
                  : mobileClassName.services
              }
            >
              {t("Services")}
              <img
                src={navbar_white_down_arrow}
                alt=""
                className="down__arrow__icon"
              />
            </Link> */}
            {/* <Link
              to="/"
              onClick={() =>
                mobileNavbarTab__mouseTab(
                  !mobileShowPopup,
                  "allCategories",
                  7,
                  {}
                )
              }
              className={
                navIndex === "allCategories"
                  ? "active__mobile__navbar__link mobile__navbar__link"
                  : mobileClassName.allCategories
              }
            >
              {t("All_Categories")}
              <img
                src={navbar_white_down_arrow}
                alt=""
                className="down__arrow__icon"
              />
            </Link> */}
            <hr />
            <Link
              className="mobile__navbar__link"
              to="/category/top-deals-mc-880"
              onClick={() =>
                // changeReducerSelectedCategory(currentCategoryData)
                clickonSubCategoryInMobilePopup()
              }
            >
              {t("top_deals")}
            </Link>
            <Link
              className="mobile__navbar__link"
              to="/stores?id=centers"
              onClick={() =>
                // changeReducerSelectedCategory(currentCategoryData)
                clickonSubCategoryInMobilePopup()
              }
            >
              {t("service_centers")}
            </Link>
            <Link
              className="mobile__navbar__link"
              to="/category/audio-promotion-mc-1188"
              onClick={() =>
                // changeReducerSelectedCategory(currentCategoryData)
                clickonSubCategoryInMobilePopup()
              }
            >
              {t("Audio_Promotion")}
            </Link>
            {/* <Link className="mobile__navbar__link" to="#">
              {t("delas_of_the_day")}
            </Link>
            <Link className="mobile__navbar__link" to="#">
              {t("digital_cards")}
            </Link>
            <Link className="mobile__navbar__link" to="#">
              {t("top_sellers")}
            </Link> */}
            {/* <hr /> */}
            {/* <Link className="mobile__navbar__link" to="/user/profile">
              {t("My_Profile")}
            </Link>
            <Link className="mobile__navbar__link" to="/user/orders">
              {t("My_Orders")}
            </Link>
            <Link className="mobile__navbar__link" to="/user/credits">
              {t("My_Wallet")}
            </Link>
            <Link className="mobile__navbar__link" to="/user/wishlist">
              {t("My_Whishlist")}
            </Link> */}
            <hr />
            <Link
              className="mobile__navbar__link"
              to="/cms/about-us"
              onClick={() =>
                // changeReducerSelectedCategory(currentCategoryData)
                clickonSubCategoryInMobilePopup()
              }
            >
              {t("About_Us")}
            </Link>
            <Link
              className="mobile__navbar__link"
              to="/cms/terms-and-conditions"
              onClick={() =>
                // changeReducerSelectedCategory(currentCategoryData)
                clickonSubCategoryInMobilePopup()
              }
            >
              {t("Terms_and_Conditions")}
            </Link>
            <Link
              className="mobile__navbar__link"
              to="/stores?id=stores"
              onClick={() =>
                // changeReducerSelectedCategory(currentCategoryData)
                clickonSubCategoryInMobilePopup()
              }
            >
              {t("Find_Stores")}
            </Link>

            <Link
              className="mobile__navbar__link"
              to="/cms/privacy-policy"
              onClick={() =>
                // changeReducerSelectedCategory(currentCategoryData)
                clickonSubCategoryInMobilePopup()
              }
            >
              {t("Privacy_Policy")}
            </Link>
            {/* <Link className="mobile__navbar__link" to="#"> */}
            {customerDetails ? (
              <div
                style={{ padding: "10px 20px", fontWeight: "bold" }}
                onClick={() => customerLogout("signin")}
              // className="logout__button"
              >
                {t("Sign_Out")}
              </div>
            ) : null}

            {/* </Link> */}
          </div>
          <div
            className={
              mobileShowPopup
                ? "mobile__navbar__link__nested__block"
                : "mobile__navbar__link__nested__block__disable"
            }
          >
            <button
              onClick={() => backtoMainMobilePopup()}
              className="back__mobile__icon"
            >
              <img src={backarrow} />
            </button>
            <MobilePopup
              backtoMainMobilePopup={backtoMainMobilePopup}
              closeMobileSidebarPopup={closeMobileSidebarPopup}
              changeReducerSelectedCategory={changeReducerSelectedCategory}
              currentCategoryData={mobileSelectedCategory}
              menuIndex={menuIndex}
            />
          </div>
        </div>

        <div
          className={
            categoryPopup
              ? "container-fluid category__popup__container"
              : "container-fluid category__popup__container__disable"
          }
        >
          <div
            className={
              categoryPopup
                ? "row category__popup__block"
                : "row category__popup__block__disable"
            }
            onMouseLeave={() => navbarTab__mouseTab(false, "")}
            ref={ref}
          >
            <div className="col-6 col-sm-6 col-md-3 category__popup__left__block">
              {categoryData?.children_data
                ?.filter((val) => val.include_in_menu === true)
                .map((catObj, catIndex) => {
                  let catObjUrl = `category/${catObj.url_key}-mc-${catObj.id}`;
                  // let catObjUrl = `category/${catObj.id}`
                  return (
                    <Link
                      to={catObjUrl}
                      key={catObj.id}
                      onMouseOver={() => changeSelectedCategory(catObj)}
                      onClick={() => {
                        changeReducerSelectedCategory(catObj,catObj.id);
                        setCategoryPopup(false);
                      }}
                      className={
                        selectedCategory.name === catObj.name
                          ? "selected__mainCategory__block"
                          : "mainCategory__block"
                      }
                    >
                      <p className="mainCategory__left__block">{catObj.name}</p>
                      <img
                        src={popup_right_arrow}
                        alt=""
                        className="popup__right__arrow"
                      />
                    </Link>
                  );
                })}
            </div>
            <div className="col-6 col-sm-6 col-md-5 category__popup__right__block">
              <div className="mainCategory__title__block">
                <p className="mainCategory__right__block">
                  {selectedCategory?.name}
                </p>
              </div>
              <div className="subCategory__block">
                {selectedCategory?.children_data
                  ?.filter((val) => val.include_in_menu === true)
                  .map((subcat, subcatIndex) => {
                    return (
                      <Link
                        key={subcatIndex}
                        className="subcategory"
                        to={`${subcat.url_key}-c-${subcat.id}-mc-${selectedCategory.id}`}
                        onClick={() => {
                          changeReducerSelectedCategory(selectedCategory,subcat.id);
                          setCategoryPopup(false);
                        }}
                      >
                        <p>{subcat.name}</p>
                      </Link>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className={
            loginPopup
              ? "container-fluid login__popup__container"
              : "container-fluid login__popup__container__disable"
          }
        >
          {loginWrapper ? (
            <LoginWrapper
              handleChangeCartIconTotal={handleChangeCartIconTotal}
              loginMode={loginMode}
              closeLoginPopup={closeLoginPopup}
            />
          ) : (
            <LoginWrapper
              handleChangeCartIconTotal={handleChangeCartIconTotal}
              loginMode={loginMode}
              closeLoginPopup={closeLoginPopup}
            />
          )}
        </div> */}
      </div>
    </>
  );
}

export default Header;