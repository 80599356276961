import React from "react";
import shopping_cart from "./../../assets/Icon/shopping_cart.svg";
import { useTranslation } from "react-i18next";
import NotifyMeBtn from "../MostSharedComponent/NotifyMeBtn";
import { Spinner } from "../helpers/utils/spinner";

function AddToCartPDP({ product, AddProductToCart, isOutOfStock, isPreOrder, outOfStockConfiguration, productOutOfStockStatus, loaderOn, isCartData, matchedProduct }) {

  const { t } = useTranslation();

  return (
    <>
      {isOutOfStock && outOfStockConfiguration?.module_status && productOutOfStockStatus ? (
        <NotifyMeBtn product={product} />
      ) : (
        <>
          {isOutOfStock ? (
            <button
              className="outofStock__button"
            >
              {t("Out_Of_Stock")}
            </button>
          ) : isPreOrder ? (
            <button
              className="pd__addToCart__button pre-order"
              onClick={() => AddProductToCart(matchedProduct ? matchedProduct && matchedProduct?.[0]?.sku : product?.sku)}
            >
              {loaderOn === true ?
                <Spinner />
                :
                <>
                  <img
                    src={shopping_cart}
                    alt=""
                    className="pd__addToCart__icon "
                  />
                  {t("pre_order")}
                </>
              }
            </button>
          ) : (
            <button
              className="pd__addToCart__button"
              onClick={() => AddProductToCart(matchedProduct ? matchedProduct && matchedProduct?.[0]?.sku : product?.sku)}
            >
              {loaderOn === true && isCartData === false ?
                <Spinner />
                :
                <>
                  <img
                    src={shopping_cart}
                    alt=""
                    className="pd__addToCart__icon "
                  />
                  {t("Add_To_Cart")}
                </>
              }
            </button>
          )}
        </>
      )}
    </>
  );
}

export default AddToCartPDP;