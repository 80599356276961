import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Heading7 from "../Components/Font/Heading7";
import "./../SCSS/Form/_addressForm.scss";
import {
  emailValidator,
  isMobileNumber,
} from "../Components/helpers/utils/validators";
import Heading3 from "../Components/Font/Heading4";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import * as services from "./../services/services";
import apiHelper from "../Components/helpers/utils/apiHelper";
import { getCustomerLoginDetails } from "../Components/helpers/utils/getCustomerLoginDetails";
import { acceptOnlyString } from "./../Components/helpers/utils/regexp";
import { customerDetailsSuccess } from "../services/customer/customer";
import { loadCartData } from "../redux/appAction";
import { impressionEvents } from "../Components/helpers/utils/ga/impressionEvents";

function Virtual_Product_Checkout_Page({ handleChangeCreateBillingAddress, setCustEmailAddress }) {
  const customerData = getCustomerLoginDetails();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const T_REQ_FIRST_NAME = t("First_Name_is_required");
  const T_REQ_LAST_NAME = t("Last_Name_is_required");
  const T_REQ_MOBILE_NUMBER = t("Mobile_Number_is_missing");
  const T_INVALID_MOBILE_NUMBER = t("Invalid_Mobile_Number");
  const T_REQ_EMAIL = t("Email_is_missing");
  const T_INVALID_EMAIL = t("Invalid_email_address_example");

  const [address, setAddress] = useState({
    firstName: customerData?.firstname,
    lastName: customerData?.lastname,
    mobileNumber: customerData?.custom_attributes?.phone_number,
    email: customerData?.email,
  });

  const [errMsg, setErrMsg] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
  });

  const validateForm = (event, newErrObj, name, value) => {
    //A function to validate each input values
    switch (name) {
      case "firstName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_FIRST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "lastName":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_LAST_NAME };
        } else {
          newErrObj = { ...newErrObj, [name]: "" };
        }
        break;
      case "mobileNumber":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_MOBILE_NUMBER };
        } else {
          let isValidNumber = isMobileNumber(value);
          if (isValidNumber === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_MOBILE_NUMBER };
          } else {
            newErrObj = { ...newErrObj, [name]: "" };
          }
        }
        break;
      case "email":
        if (value === "") {
          newErrObj = { ...newErrObj, [name]: T_REQ_EMAIL };
        } else {
          let isValidEmail = emailValidator(value);
          if (isValidEmail === "error") {
            newErrObj = { ...newErrObj, [name]: T_INVALID_EMAIL };
          } else {
            newErrObj = { ...newErrObj, [name]: "" };
          }
        }
        break;
      default:
        break;
    }
    return newErrObj;
  };

  const handleChange = async (event, keyName) => {
    let value = event;
    let name = event;
    if (keyName === "mobileNumber") {
      value = event;
      name = "mobileNumber";
    } else if (
      event.target.name === "firstName" ||
      event.target.name === "lastName"
    ) {
      value = acceptOnlyString(event.target.value, true);
      name = event.target.name;
    } else {
      value = event.target.value;
      name = event.target.name;
    }
    setAddress({ ...address, [name]: value });
    let manageErrMsg = validateForm(event, errMsg, name, value);
    setErrMsg(manageErrMsg);
  };

  const allFeildValidate = (validateFeild, allErrMsg) => {
    let checkValueStatus = [];
    let checkErrStatus = [];

    validateFeild &&
      validateFeild.map((val, i) => {
        let keyVal = address[val];
        let errVal = errMsg[val];

        allErrMsg = validateForm("", allErrMsg, val, keyVal);
        if (keyVal !== "") {
          checkValueStatus.push("suc");
        }
        if (errVal === "") {
          checkErrStatus.push("err");
        }
      });

    let checkAddressStatus = false;
    if (
      checkValueStatus.length === validateFeild.length &&
      checkErrStatus.length === validateFeild.length
    ) {
      checkAddressStatus = true;
    }

    let returnData = {
      allErrMsg: allErrMsg,
      checkAddressStatus: checkAddressStatus,
    };

    return returnData;
  };

  const handleSubmit = () => {
    let validateFeild = ["firstName", "lastName", "mobileNumber", "email"];

    let formStatus = allFeildValidate(validateFeild, errMsg);

    setErrMsg(formStatus.allErrMsg);

    if (formStatus.checkAddressStatus === true) {
      let params = {
        countryId: "SA",
        email: address.email,
        firstName: address.firstName,
        lastName: address.lastName,
        telephone: address.mobileNumber,
      };
      try {
        let response = apiHelper(
          `/V1/cart/billing-address`,
          "post",
          params,
          {}
        );
        response
          .then((res) => {
            window.scrollTo(0, 0);
            impressionEvents.impressionShippingAddress(
              "add_shipping_address",
              params
            );
            if (res.status === 204) {
              handleChangeCreateBillingAddress(address.email);
              setCustEmailAddress(address.email)
              localStorage.setItem("contact_prefill", JSON.stringify(address));
              dispatch(customerDetailsSuccess(address))
              dispatch(loadCartData())
            }
          })
          .catch((err) => {
            dispatch(
              services.notifyError({ message: err.response.data.message })
            );
          });
      } catch (err) {
        dispatch(services.notifyError({ message: err.response.data.message }));
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  function Position(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return currenttop > 200 ? [currenttop - 200] : [0];
    }
  }

  React.useEffect(() => {
    if (Object.keys(errMsg).length) {
      const errorElement = document.querySelector(".invalid__message");
      if (errorElement && document.activeElement.tagName !== "INPUT") {
        window.scrollTo(0, Position(errorElement));
      }
    }
  }, [errMsg])

  return (
    <div className="newAddress__block guest__checkout">
      <div className="newAddress__title__block">
        <Heading3 text={t("Personal_Information")} color="#000000" />
      </div>
      <div className="row newAddress__form__field__row">
        <div className="col-sm-12 col-md-6 main__form__field__block">
          {/* <p className="form__label">First Name</p> */}
          <Heading7 text={t("First_Name")} marginBottom={10} />
          <div className="field__block">
            <input
              type="text"
              placeholder={t("First_Name")}
              className="form__field"
              id="name"
              onKeyDown={handleKeyDown}
              name="firstName"
              value={address.firstName}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {errMsg.firstName && (
            <p className="invalid__message">{errMsg.firstName}</p>
          )}
        </div>
        <div className="col-sm-12 col-md-6 main__form__field__block">
          {/* <p className="form__label">Mobile Number</p> */}
          <Heading7 text={t("Last_Name")} marginBottom={10} />
          <div className="field__block">
            <input
              type="text"
              placeholder={t("Last_Name")}
              className="form__field"
              onKeyDown={handleKeyDown}
              id="lastName"
              name="lastName"
              value={address.lastName}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {errMsg.lastName && (
            <p className="invalid__message">{errMsg.lastName}</p>
          )}
        </div>
      </div>
      <div className="row newAddress__form__field__row">
        <div className="col-sm-12 col-md-6 main__form__field__block">
          <Heading7 text={t("Mobile_Number")} marginBottom={10} />
          <PhoneInput
            inputProps={{
              name: "mobileNumber",
              required: true,
              className: "profile__mobile__form__field",
            }}
            onKeyDown={handleKeyDown}
            country="sa"
            onlyCountries={["sa"]}
            masks={{ sa: ".. ... ...." }}
            countryCodeEditable={false}
            disableDropdown={true}
            value={address.mobileNumber}
            onChange={(e) => handleChange(e, "mobileNumber")}
            className=""
          />
          {errMsg.mobileNumber && (
            <p className="invalid__message">{errMsg.mobileNumber}</p>
          )}
        </div>
        <div className="col-sm-12 col-md-6 main__form__field__block">
          <Heading7 text={t("Order_confirmation_email")} marginBottom={10} />
          <div className="field__block">
            <input
              type="text"
              placeholder={t("Order_confirmation_email")}
              className="form__field"
              onKeyDown={handleKeyDown}
              id="email"
              name="email"
              value={address.email}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {errMsg.email && <p className="invalid__message">{errMsg.email}</p>}
        </div>
      </div>
      <div className="newAddress__form__button__block">
        <button className="form__save__button" onClick={handleSubmit}>
          {t("Next")}
        </button>
        <button className="form__cancel__button">{t("CANCEL")}</button>
      </div>
    </div>
  );
}

export default React.memo(Virtual_Product_Checkout_Page);