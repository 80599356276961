import React from "react";
import { useTranslation } from "react-i18next";
import Heading6 from "../Font/Heading6";

function InstallationService({ product, title }) {
  const { t } = useTranslation();

  return (
    <>
      <Heading6 text={title} color="#000000" marginBottom={20} />
      <label className="w-50">
        <input type="checkbox" className="" />
        &nbsp;
        <strong>Yes</strong>
      </label>
      <label className="w-50">
        <input type="checkbox" className="" />
        &nbsp;
        <strong>No</strong>
      </label>
    </>
  );
}

export default React.memo(InstallationService);
