import * as actionType from "../../actionType";

const initialState = {
  customerOrderList: "",
  customerOrderDetails: "",
  loading: false,
};

const customerOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CUSTOMER_ORDER_LIST:
      return { ...state, customerOrderList: action.payload };
    case actionType.CUSTOMER_ORDER_DETAILS:
      return { ...state, customerOrderDetails: action.payload };
    case actionType.CUSTOMER_ORDER_LOADING_START:
      return { ...state, loading: true };
    case actionType.CUSTOMER_ORDER_LOADING_STOP:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default customerOrdersReducer;
