import React, { useState } from "react";
import "./../SCSS/ProductType/_productEleven.scss";
import black_left_arrow from "./../assets/Icon/black_left_arrow.svg";
import black_right_arrow from "./../assets/Icon/black_right_arrow.svg";
import "./../SCSS/_carouselTypeTwo.scss";
import ProductOne from "./ProductType/ProductOne";
import ProductFive from "./ProductType/ProductFive";
import ProductEleven from "./ProductType/ProductEleven";

function CarouselTypeTwo({
  productDetailPage,
  sectionTitle,
  carouselData,
  containerClassName,
  productType,
  recentlyViewedProductsData,
  handleChangeCartPopup,
  outOfStockConfiguration,
  screenName
}) {
  // const [disableLeftArrow, setDisableLeftArrow] = useState(true);
  // const [disableRightArrow, setDisableRightArrow] = useState(false);
  const [arrowState, setArrowState] = useState(true);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const leftSide = (e) => {
    setArrowState(!arrowState);
    // setDisableRightArrow(false);
    // e.preventDefault();
    document.querySelector(`.${containerClassName}`).scrollLeft =
      document.querySelector(`.${containerClassName}`).scrollLeft -
      document.querySelector(`.${containerClassName}`).clientWidth +
      50;
    let scroll = document.querySelector(`.${containerClassName}`).scrollLeft;
  };
  const rightSide = (e) => {
    setArrowState(!arrowState);
    // setDisableLeftArrow(false);
    // e.preventDefault();
    document.querySelector(`.${containerClassName}`).scrollLeft =
      document.querySelector(`.${containerClassName}`).scrollLeft +
      document.querySelector(`.${containerClassName}`).clientWidth -
      100;
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      if (isRightSwipe) {
        leftSide();
      } else {
        rightSide();
      }
    }
    // add your conditional logic here
  };

  return (
    <>
      <div className="container-fluid carouselTypeTwo__container">
        <p className="section__title">{sectionTitle}</p>
        <div className="carouselTypeTwo__block">
          <div className="arrow__block">
            {carouselData?.length > 6 ? (
              <div className="arrow left__arrow" onClick={leftSide}>
                <img src={black_left_arrow} alt="" />
              </div>
            ) : null}
            {carouselData?.length > 6 ? (
              <div className="arrow right__arrow" onClick={rightSide}>
                <img src={black_right_arrow} alt="" />
              </div>
            ) : null}
          </div>
          {/* {?:?:} */}
          {productType === "productOne" ? (
            <div className={containerClassName}>
              {carouselData &&
                carouselData.map((product, productIndex) => {
                  return (
                    <ProductOne
                      handleChangeCartPopup={handleChangeCartPopup}
                      key={product.id}
                      product={product}
                      productDetailPage={productDetailPage}
                      outOfStockConfiguration={outOfStockConfiguration}
                      productIndex={productIndex}
                      screenName={screenName}
                    />
                  );
                })}
            </div>
          ) : productType === "productFive" ? (
            <div className={containerClassName}>
              {recentlyViewedProductsData &&
                recentlyViewedProductsData.map((product, productIndex) => {
                  return (
                    <ProductFive
                      onTouchStart={onTouchStart}
                      onTouchMove={onTouchMove}
                      onTouchEnd={onTouchEnd}
                      handleChangeCartPopup={handleChangeCartPopup}
                      key={product.id}
                      product={product}
                      outOfStockConfiguration={outOfStockConfiguration}
                      productIndex={productIndex}
                      screenName={screenName}
                    />
                  );
                })}
            </div>
          ) : productType === "productEleven" ? (
            <div className={containerClassName}>
              {carouselData &&
                carouselData.map((product, productIndex) => {
                  return (
                    <ProductEleven
                      handleChangeCartPopup={handleChangeCartPopup}
                      key={product.id}
                      product={product}
                      outOfStockConfiguration={outOfStockConfiguration}
                      productIndex={productIndex}
                      screenName={screenName}
                    />
                  );
                })}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default CarouselTypeTwo;
