import React, { useState, useEffect } from "react";
// import "./../../SCSS/ProductType/_productTwelve.scss";
import shopping_cart from "./../../assets/Icon/shopping_cart.svg";
import quick_view from "./../../assets/Icon/quick_view.svg";
import compare from "./../../assets/Icon/compare.svg";
import Heading7 from "../Font/Heading7";
import Text4 from "../Font/Text4";
import Text5 from "../Font/Text5";
import OldPrice from "../Font/OldPrice";
import Price from "../Font/Price";
import Heading6 from "../Font/Heading6";
import RatingBlock from "../MostSharedComponent/RatingBlock";
import AwesomeSlider from "react-awesome-slider";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  loadAddToWishlist,
  loadDeleteFromWishlist,
  loadWishlistData,
} from "../../redux/appAction";
import { BiSearch } from "react-icons/bi";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import {
  selectedCompareProductStatus,
} from "../helpers/utils/compareProductsFilter";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import tamara from "./../../assets/Icon/tamara.png";
import tabbycard from "./../../assets/Icon/tabbycard.png";
import { addToCart } from "../../services/cart.service";
import { loadCartData } from "../../redux/appAction";
import * as services from "../../services/services";
import VariantOptions from "./VariantOptions";
import { useNavigate } from "react-router";
import cancel_grey from "./../../assets/Icon/cancel_grey.svg";
import { productUrlHelper } from "../helpers/utils/productUrlHelper";
import TamaraLogoBox from "../MostSharedComponent/TamaraLogoBox";
import { checkWishList } from "../helpers/utils/wishListHelper";
import { titleChange } from "../helpers/utils/titleNameChange";
import MizaAndQitaf from "../MostSharedComponent/MizaAndQitaf";
import { cartEvents } from "../helpers/utils/ga/cartEvents";
import { checkoutEvents } from "../helpers/utils/ga/checkoutEvents";
import DeliveryTimeArea from "../MostSharedComponent/DeliveryTimeArea";
import { Spinner } from "../helpers/utils/spinner";
import useCartErrorMessage from "../helpers/hooks/useCartErrorMessage";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  arrows: true,
  // slidesToScroll: 1
};
function ProductTwelve({
  product,
  // handleChangeProductPopup,
  // updateCompareProduct,
  handleChangeCartPopup,
  // redirectToPdpPage,
  removeProductFromCompare,
  screenName
}) {

  const { setCartErr } = useCartErrorMessage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getCompareProductList = useSelector(
    (state) => state.appData.getCompareProductList
  );

  const [rating, setRating] = useState(0);
  const [sizeButtonIndex, setSizeButtonIndex] = useState(null);
  const [productWarrentyBlock, setProductWarrentyBlock] = useState({
    warrantyText: "1 Year Warranty on Product",
    size: [
      {
        id: 1,
        cm: 139,
        inch: 55,
      },
      {
        id: 2,
        cm: 164,
        inch: 65,
      },
      {
        id: 3,
        cm: 195,
        inch: 77,
      },
    ],
  });

  const [isFavouriteHover, setIsFavouriteHover] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [count, setCount] = useState(0);
  const [loaderOn, setLoaderOn] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);

  const { t } = useTranslation();

  const wishlistData = useSelector((state) => state.appData.wishlistData);

  useEffect(() => {
    setIsFavourite(checkWishList(wishlistData, product?.sku));
  }, []);

  const handleFavourite = () => {
    if (isFavourite) {
      setIsFavourite(false);
      setCount(count + 1);
    } else {
      setIsFavourite(true);
      setCount(count + 1);
    }
  };
  useEffect(() => {
    const data = {
      items: [product.sku],
    };
    if (isFavourite) {
      if (count > 0) {
        const addToWishlistData = dispatch(loadAddToWishlist(data,t)).then(
          (res) => {
            checkoutEvents.addToWishlist(product, screenName);
            dispatch(loadWishlistData());
          }
        );
      }
    } else {
      if (count > 0) {
        if (!isFavourite) {
          checkoutEvents.removeFromWishlist(product, screenName);
          removeFromWL(product.sku);
        }
      }
    }
  }, [isFavourite]);

  const removeFromWL = (sku) => {
    const data = {
      items: [sku],
    };
    const deleteFromWishlistData = dispatch(loadDeleteFromWishlist(data,t)).then(
      (res) => {
        dispatch(loadWishlistData());
      }
    );
  };

  const handleRating = (score) => {
    setRating(score);
  };

  const sizeButtonClick = (sizeIndex) => {
    product.sku = product?.variants[sizeIndex]?.sku;
    product.name = product?.variants[sizeIndex]?.name;
    product.description = product?.variants[sizeIndex]?.description;
    product.price = product?.variants[sizeIndex]?.price;
    product.currency = product?.variants[sizeIndex]?.currency;
    product.price_rounded = product?.variants[sizeIndex]?.price_rounded;
    product.discounted_price = product?.variants[sizeIndex]?.discounted_price;
    product.discounted_price_rounded = product?.variants[sizeIndex]?.discounted_price_rounded;
    product.discounted_price_from = product?.variants[sizeIndex]?.discounted_price_from;
    product.discounted_price_to = product?.variants[sizeIndex]?.discounted_price_to;
    product.is_count_down = product?.variants[sizeIndex]?.is_count_down;
    product.stock = product?.variants[sizeIndex]?.stock;
    product.item_type = product?.variants[sizeIndex]?.item_type;
    product.meza = product?.variants[sizeIndex]?.meza;
    product.qitaf = product?.variants[sizeIndex]?.qitaf;
    product.eligible_for_tabby = product?.variants[sizeIndex]?.eligible_for_tabby;
    product.enable_shipping = product?.variants[sizeIndex]?.enable_shipping;
    product.additional_estimated_day = product?.variants[sizeIndex]?.additional_estimated_day;
    product.current_time = product?.variants[sizeIndex]?.current_time
    setSizeButtonIndex(sizeIndex);
  };

  const AddProductToCart = (sku) => {
    setLoaderOn(true);
    if (product?.variants && product?.variants.length > 0 && sizeButtonIndex && sizeButtonIndex >= 0) {
      sku = product?.variants[sizeButtonIndex]?.sku;
    }

    const data = {
      items: [
        {
          sku: sku,
          qty: 1,
          id: product?.id
        },
      ],
    };

    addToCart(data)
      .then((res) => {
        setSpinnerOn(true)
        dispatch(loadCartData()).then((item) => {
          cartEvents.addToCart(product, item?.id)
          setSpinnerOn(false)
          handleChangeCartPopup(true);
        });
      })
      .catch((err) => {
        setCartErr({ stock: product?.stock, error: err?.response?.data });
        setLoaderOn(false);
      })
      .finally(() => {
        setLoaderOn(false);
      });
  };

  const redirectToPdpPage = (url_key, sku) => {
    navigate(productUrlHelper(url_key, sku));
  }

  return (
    <div key={product.id} className="productTwelve__block">
      <div className="productTwelve__header">
        {/* <div className="productTwelve__new__sticker__block">
          <p className="productTwelve__new__sticker__text">{t("New")}</p>
        </div> */}
        <div></div>
        <img
          onClick={() =>
            removeProductFromCompare(product.id)
          }
          className="productOne__favourite__icon"
          src={cancel_grey}
          alt=""
        />
      </div>
      <div className="productTwelve__content">
        <div
          className="productTwelve__image__slider"
        // to={productUrlHelper(product?.english_name, product?.sku)}
        >
          {/* <div onClick={()=>redirectToPdpPage(product.url_key,product.sku)}> */}
          <Slider {...settings}>
            {product?.media?.image?.screenshots?.map((img, index) => (
              <div key={index} className="">
                <img
                  className=""
                  src={img.image}
                  alt={img.image + index}
                  onClick={() => redirectToPdpPage(product?.english_name, product.sku)}
                />
              </div>
            ))}
          </Slider>
          {/* </div> */}
          {/* <AwesomeSlider transitionDelay={0.2} mobileTouch={true} infinite={true}>
            {product?.media?.image?.screenshots?.map((img, index) => (
              <div key={index} className="_product__container_image">
                <img src={img.image} alt={img.image + index} />
              </div>
            ))}
          </AwesomeSlider> */}
        </div>
        <div className="plp__mezza__reward d-flex justify-content-between align-items-center mb-2">
          <div className="plp__tamara__box mb-2">
            <TamaraLogoBox product={product} />
          </div>
        </div>
        <Link
          className="productTwelve__name__link"
          to={productUrlHelper(product?.english_name, product?.sku)}
        >
          <p className="productTwelve__name"> {titleChange(product.name)}</p>
        </Link>
        {/* <Heading6 text={titleChange(product.name)} marginBottom={10} /> */}
        <div className="plp__mezza__reward d-flex justify-content-between align-items-center mb-2">
          <MizaAndQitaf product={product} />
        </div>
        <OldPrice
          oldPrice={product?.price_rounded}
          price={
            product.discounted_price_rounded === null
              ? product.price_rounded
              : product.discounted_price_rounded
          }
          size="text3"
          color="#c8c8c8"
          marginBottom={10}
          lineThrough={true}
          span={true}
          currency="SAR"
        />
        <Price
          price={
            product.discounted_price_rounded === null
              ? product.price_rounded
              : product.discounted_price_rounded
          }
          marginLeft={5}
          marginBottom={15}
          size="heading6"
          span={true}
          currency="SAR"
        />

        <RatingBlock rating={6} totalRatings={2222} className="mt-1" />
        {/* <div className="mt-1">
          <RatingBlock rating={6} totalRatings={2222} />
        </div> */}
        {product?.variantOptions && (
          <VariantOptions
            sizeButtonClick={sizeButtonClick}
            sizeButtonIndex={sizeButtonIndex}
            variantOptions={product?.variantOptions}
          />
        )}
        {/* <div className="size__button__block">
          {product?.variantOptions?.[0].attribute?.options.map((size, sizeIndex) => {
            return (
              <button
                key={size.id}
                onClick={() => sizeButtonClick(sizeIndex)}
                className={
                  sizeButtonIndex === sizeIndex
                    ? "size__button__active"
                    : "size__button"
                }
              >{`${size.label * 2.54} cm (${size.label})`}</button>
            );
          })}
        </div> */}
        <div className="productTwelve__feature__block">
          {product.productFeatures && (
            <>
              {product.productFeatures.map((feature, featureIndex) => {
                return (
                  <div key={featureIndex} className="feature__text__block">
                    <div className="feature__bullet"></div>
                    <Text4 text={feature.text} />
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className="delivery-time-area">
            {product?.additional_estimated_day !== undefined && (
              <DeliveryTimeArea
                additional_estimated_day={product?.additional_estimated_day}
              />
            )}
        </div>
        {product?.stock > 0 ? (
          // <Link to={productUrlHelper(product?.english_name, product?.sku)} className="addToCart__button">
          //   <img src={shopping_cart} alt="" className="addToCart__icon" />
          //   {t("Add_To_Cart")}
          // </Link>
          <div
            className="addToCart__button"
            onClick={() => AddProductToCart(product.sku)}
          >
            <img src={shopping_cart} alt="" className="addToCart__icon" />
            {loaderOn === true || spinnerOn === true ? <Spinner /> : t("Add_To_Cart")}
          </div>
        ) : (
          <div className="notifyMe__button">{t("Notify_Me")}</div>
        )}

        {/* <div className="productTwelve__quickView__compare__block">
          <button
            onClick={() => handleChangeProductPopup(true, product)}
            className="productTwelve__button__block"
          >
            <BiSearch className="search_icon shortlink_icon" />
            <Heading7 text={t('Quick_View')} />
          </button>
          <button
            onClick={() => updateCompareProduct(true, product)}
            className={`productTwelve__button__block ${selectedCompareProductStatus(product.id) === true
                ? `active_compare_btn`
                : ``
              }`}
          >
            <HiOutlineSwitchHorizontal className="compare_icon shortlink_icon" />
            <Heading7 text={t('Compare')} />
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default ProductTwelve;
