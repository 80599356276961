import React, { useState, useEffect } from 'react';
import Heading7 from '../Font/Heading7';
import { useFormik } from 'formik'
import { isPromoRegex } from '../helpers/utils/validators';
import mokafa2x from "./../../assets/Icon/mokafa@2x.png";
import { getLoyaltyEarnTypesData, loadCartData } from '../../redux/appAction';
import { getAvailablePaymentMethods } from '../../services/cart.service';
import apiHelper from '../helpers/utils/apiHelper';
import * as services from "./../../services/services";
import { useDispatch } from 'react-redux';
import { Spinner } from '../helpers/utils/spinner';

const CartLoyaltyBonus = ({
    t,
    program
}) => {
    const dispatch = useDispatch();
    const [pending, setPending] = useState(false);
    // const [step, setStep] = useState(1);

    const formik = useFormik({
        initialValues: {
            couponCode: '',
        },
        onSubmit: values => {
            submitHandler()
        },
        validate: values => {
            let errors = {};
            let isValidNumber = isPromoRegex(values.couponCode);

            if (!values.couponCode) {
                errors.couponCode = t('Promo code is missing')
            } else if (isValidNumber === "error") {
                errors.couponCode = t('Invalid voucher')
            }

            return errors
        }
    })

    const handleChange = (e, name) => {
        let value = e.target.value
        let ev = {
            target: {
                value: value,
                name: name,
                id: name
            }
        }
        formik.handleChange(ev)
    }

    // const resetSteps = () => {
    //     // setStep(1);
    //     // reset();
    // };

    const submitHandler = async (data) => {
        let params = {
            couponCode: formik.values.couponCode
        }
        if (program === 'mokafa') {
            await applyMokafa(params);
        } else {
            await applyYgg(params);
        }
        // resetSteps();
    };


    const applyMokafa = async (params) => {
        setPending(true);
        try {
            let response = await apiHelper(`/V1/cart/mokaffat/apply`, 'post', params, {});
            if (response?.data?.status === "1") {
                dispatch(services.notifySuccess({ message: t('Voucher code applied') }));
                dispatch(loadCartData());
                dispatch(getAvailablePaymentMethods());
                dispatch(getLoyaltyEarnTypesData());
            } else {
                dispatch(services.notifyError({ message: response?.data?.message }));
            }
            setPending(false);
        } catch (error) {
            setPending(false);
            dispatch(services.notifyError({ message: error?.response?.data?.message }));
        }
    };

    const applyYgg = async (params) => {
        setPending(true);
        try {
            let response = await apiHelper(`/V1/cart/ygg/apply`, 'post', params, {});
            dispatch(services.notifySuccess({ message: t('Voucher code applied') }));
            dispatch(loadCartData());
            dispatch(getAvailablePaymentMethods());
            dispatch(getLoyaltyEarnTypesData());
            setPending(false);
        } catch (error) {
            setPending(false);
            dispatch(services.notifyError({ message: error?.response?.data?.message }));
        }
    };


    return (
        <>
            <div className="payment__form__main__block">
                <div className="payment__detail__form__block">
                    {pending &&
                        <div className="payment_process_loading">
                        </div>
                    }
                    <div className="address__content__block">
                        <div className="payment__card__block">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row payment__form__field__row mezza__text">
                                    <div className="col-sm-12 col-md-2 main__form__field__block">
                                        <div className="field__block">
                                            <img src={mokafa2x} alt="Mokafa" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-3 main__form__field__block">
                                        <Heading7
                                            text={t('Mokafa promo code')}
                                            marginBottom={10}
                                        />
                                        <div className="field__block">
                                            <input
                                                type="text"
                                                placeholder={t("XXXX-XXXX-XXXX")}
                                                className="form__field"
                                                id="couponCode"
                                                name="couponCode"
                                                value={formik.values.couponCode}
                                                onChange={(e) => handleChange(e, "couponCode")}
                                            />
                                        </div>
                                        {formik.errors.couponCode && <p className="invalid__message">{formik.errors.couponCode}</p>}
                                    </div>
                                    <div className="col-sm-12 col-md-3 main__form__field__block">
                                        <div className="field__block">
                                            <button
                                                type="submit"
                                                // onClick={() => applyForLoyaltyOtp()}
                                                className="btn apply_btn w-100 promo__applyBtn"
                                                disabled={pending ? true : false}
                                            >
                                                {pending ? <Spinner /> : t("Apply")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="row payment__form__field__row"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CartLoyaltyBonus;
